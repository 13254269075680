<template>
  <card-modal
    icon=""
    :title="$t('voucher.force_close_action.title')"
    context="forceClosureVoucher"
    @submit="submit"
    @cancel="cancel"
    :submit_working="working"
    cancel_text="voucher.force_close_action.button_cancel"
    submit_text="voucher.force_close_action.button_save"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <template>
          <v-layout row pad-form-row-elements>
            <v-flex sm12 xs12>
              <v-text-field
                filled
                ref="reason"
                name="reason"
                v-model="reason"
                :label="$t('voucher.force_close_action.reason') + '*'"
                v-validate="{ required: true, max: 1024 }"
                :error-messages="errors.collect('reason')"
                data-vv-name="reason"
                :data-vv-as="$t('voucher.force_close_action.reason')"
                data-test="update-voucher--reason"
              ></v-text-field>
              <v-checkbox
                ref="skipTransferToOpenWallet"
                name="skipTransferToOpenWallet"
                v-if="refundToOpenWalletIsAvailable"
                v-model="transferToOpenWallet"
                :label="$t('voucher.force_close_action.skip_transfer_to_open_wallet')"
                :error-messages="errors.collect('skip_transfer_to_open_wallet')"
                data-vv-name="skip_transfer_to_open_wallet"
                :data-vv-as="$t('voucher.force_close_action.skip_transfer_to_open_wallet')"
                data-test="update-voucher--skip-transfer-to-open-wallet"
              ></v-checkbox>
            </v-flex>
          </v-layout>
        </template>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import OfferRefundType from '@/enums/offer-refund-type';
import _ from 'lodash';

export default {
  name: 'force-close-voucher',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    voucher: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentVoucher: {},
      offer: {
        incentive: {
          refund_method: null,
        },
      },
      reason: '',
      transferToOpenWallet: false,
    };
  },
  async mounted() {
    this.currentVoucher = _.cloneDeep(this.voucher);

    // get offer for resolve "transfer_to_open_wallet" checkbox condition
    this.offer = await this.executeAction({ action: 'offers/getOffer', name: 'forceClosureVoucher' }, this.currentVoucher.offer_id);
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),

    refundToOpenWalletIsAvailable() {
      return this.offer.incentive.refund_method === OfferRefundType.OPEN_WALLET;
    },
  },
  methods: {
    clear() {
      this.currentVoucher = {};
      this.$validator.reset();
    },
    success() {
      this.clear();
      this.$emit('updated');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (!validationComplete) {
        return;
      }

      await this.executeAction(
        {
          action: 'voucher/forceClose',
          name: 'forceClosureVoucher',
          success: this.success,
        },
        {
          id: this.currentVoucher.id,
          reason: this.reason.trim(),
          transfer_to_open_wallet: !this.transferToOpenWallet,
        },
      );
    },
  },
};
</script>
