<template>
  <v-card v-if="currentTaskExists()" data-test="aml-assessment-check-task-card">
    <v-card-text>
      <user-list-item-part v-if="currentTask.account_id" :account-id="currentTask.account_id" data-test="user" />

      <card-list-item-part
        v-if="currentTask.relations && currentTask.relations.public_token && !currentTask.relations.card_application_id"
        :public-token="currentTask.relations.public_token"
        data-test="card"
      />

      <aml-profile-part
        v-if="currentTask.relations && currentTask.relations.public_token"
        :public-token="currentTask.relations.public_token"
        data-test="aml-profile"
      />

      <aml-assessment-check-hits-part
        :aml-assessment-check-key="currentTask.relations.aml_assessment_check_key"
        :public-token="_get(currentTask, 'relations.public_token')"
        :hit-type="hitType"
        data-test="pep"
      />

      <aml-assessment-check-hit-resolution-part v-if="currentTask && currentTask.status === 'resolved'" :task-id="currentTask && currentTask.id" />
    </v-card-text>
  </v-card>
</template>

<script>
import _get from 'lodash/get';
import { actionErrorTrackable, security } from '@/mixins';
import UserListItemPart from './parts/UserListItemPart';
import CardListItemPart from './parts/CardListItemPart';
import AmlAssessmentCheckHitsPart from './parts/AmlAssessmentCheckHitsPart';
import AmlAssessmentCheckHitResolutionPart from './resolution-parts/AmlAssessmentCheckHitResolutionPart';
import AmlProfilePart from './parts/AmlProfilePart';

export default {
  name: 'aml-assessment-check-hit-list-item',
  mixins: [actionErrorTrackable, security],
  components: {
    UserListItemPart,
    CardListItemPart,
    AmlAssessmentCheckHitsPart,
    AmlAssessmentCheckHitResolutionPart,
    AmlProfilePart,
  },
  props: {
    taskId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    currentTask: {},
  }),
  computed: {
    hitType() {
      return this.currentTask && this.currentTask.type === 'aml_status_possible_pep' ? 'pep' : 'sanction';
    },
  },
  async mounted() {
    this.currentTask = await this.executeAction({ action: 'task/getTask' }, this.taskId);
  },
  methods: {
    _get,
    currentTaskExists() {
      return !!(this.currentTask && this.currentTask.id);
    },
  },
};
</script>
