<template>
  <v-card>
    <v-card-title class="modal-card--card-title text-xs-center">
      <v-layout justify-center>
        <v-icon color="primary" data-fa-transform="down-1">far fa-exclamation-triangle</v-icon>
        <h3 class="primary--text">ASSIGN TASK "{{ currentTask.id }}"</h3>
      </v-layout>
    </v-card-title>

    <v-card-actions>
      <v-layout justify-center> <button-modal-cancel :text="$t('task.assign_action.button_cancel')" @click="cancel"></button-modal-cancel> </v-layout>
    </v-card-actions>
  </v-card>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'assign-task',
  $_veeValidate: {
    validator: 'new',
  },
  mixins: [actionErrorTrackable, security],
  props: {
    taskId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentTask: {},
    };
  },
  computed: {
    ...mapGetters('task', ['task']),
  },
  mounted() {
    this.currentTask = this.task(this.taskId);
  },
  methods: {
    clear() {
      this.$validator.reset();
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    success() {
      this.clear();
      this.$emit('action');
    },

    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        await this.executeAction(
          {
            action: 'task/assignTask',
            success: this.success,
          },
          { task_key: this.task.key },
        );
        this.$emit('close');
      }
    },
  },
};
</script>
