<template>
  <div>
    <table class="responsive-table" id="voucher-operations-table">
      <thead>
        <tr>
          <td>{{ $t('purse.operations_table.id') }}</td>
          <td>{{ $t('purse.operations_table.created') }}</td>
          <td>{{ $t('purse.operations_table.type') }}</td>
          <td style="text-align: right">{{ $t('purse.operations_table.amount') }}</td>
          <td>{{ $t('purse.operations_table.card_activity_id') }}</td>
          <td>{{ $t('purse.operations_table.source_of_funds') }}</td>
          <td>{{ $t('purse.operations_table.reason') }}</td>
          <td>{{ $t('purse.operations_table.agent') }}</td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index" v-bind:style="{ color: getTrColorFromOperation(item) }">
          <td>
            <h5>{{ $t('purse.operations_table.id') }}:</h5>
            <span>{{ item.id }}</span>
          </td>
          <td>
            <h5>{{ $t('purse.operations_table.created') }}:</h5>
            <span>{{ item.operation_date | formatShortDateTimeInverted }}</span>
          </td>
          <td>
            <h5>{{ $t('purse.operations_table.type') }}:</h5>
            <span>{{ $t(`purse.operations.types.${item.type}`) }}</span>
          </td>
          <td align="right">
            <h5>{{ $t('purse.operations_table.amount') }}:</h5>
            <span v-if="item.amount >= 0">{{ item.amount | currency | dollarSignI18n }}</span>
            <span v-else-if="!item.affect_available" style="color: darkgray">{{ item.amount | currency | dollarSignI18n }}</span>
            <span v-else style="color: darkred">{{ item.amount | currency | dollarSignI18n }}</span>
          </td>
          <td>
            <h5>{{ $t('purse.operations_table.card_activity_id') }}:</h5>
            <router-link
              target="_blank"
              v-if="extractCardActivityIdFromItem(item)"
              :to="{ name: 'card_activities_dashboard', query: { activity_id: item.card_activity_id, timerange: '~' } }"
            >
              {{ extractCardActivityIdFromItem(item) }}
            </router-link>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('purse.operations_table.source_of_funds') }}:</h5>
            <span v-if="extractSourceOfFunds(item)">{{ $t(`purse.source_of_funds.${extractSourceOfFunds(item)}`) }}</span>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('purse.operations_table.reason') }}:</h5>
            <span v-if="extractReason(item)">{{ extractReason(item) }}</span>
            <span v-else-if="item.card_transaction">{{ item.card_transaction.transaction_merchant_name }}</span>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('purse.operations_table.agent') }}:</h5>
            <span><app-name :app_key="item.creation_by_app_key"></app-name></span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-xs-center">
      <v-pagination v-if="purseOperationsPageCount > 1" v-model="pagination" :length="purseOperationsPageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import AppName from '@/components/system/AppName';
import { mapGetters } from 'vuex';
import PurseOperationType from '@/enums/purse-operation-type';
import _ from 'lodash';

export default {
  name: 'purse-operations-table',
  components: { AppName },
  mixins: [security, actionErrorTrackable, disableWithError],
  props: {
    items: {
      type: Array,
    },
  },
  data() {
    return {
      pagination: 1,
    };
  },
  mounted() {
    this.pagination = this.purseOperationsPageNumber;
  },
  computed: {
    ...mapGetters('purse', ['purse', 'purseOperationsPageCount', 'purseOperationsPageNumber']),
  },
  watch: {
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'purse/listPurseOperations',
        },
        {
          id: this.purse.id,
          page: newValue,
          excluded_type: PurseOperationType.excludedByDefault,
        },
      );
    },
  },
  methods: {
    getTrColorFromOperation(operation) {
      return operation.type.substring(0, 5) === 'card_' ||
        (operation.type.substring(0, 12) === 'transaction_' && operation.affect_actual && !operation.affect_available)
        ? 'darkgray'
        : 'inherit';
    },
    extractCardActivityIdFromItem(item) {
      return _.get(item, 'card_transaction.card_activity_id') || item.card_activity_id;
    },
    extractSourceOfFunds(item) {
      return _.get(item, 'load.source_of_funds.type', null);
    },
    extractReason(item) {
      return _.get(item, 'reason', '') + _.get(item, 'load.source_of_funds.note', '');
    },
  },
};
</script>
