<template>
  <card-modal
    icon="fal fa-store-alt"
    :title="$t('offers.details.activation_codes_section.create_action.title')"
    context="createActivationCode"
    @submit="submit"
    @cancel="cancel"
    cancel_text="offers.details.activation_codes_section.create_action.cancel_text"
    submit_text="offers.details.activation_codes_section.create_action.submit_text"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <modal-info-block :heading="$t('offers.details.activation_codes_section.create_action.offer')" :text="offerInfo"></modal-info-block>
      </v-layout>
      <v-layout row wrap pad-form-row-elements>
        <v-flex sm4 xs12>
          <v-text-field
            autofocus
            :label="$t('offers.details.activation_codes_section.create_action.payment_amount') + '*'"
            name="payment_amount"
            v-validate="{
              required: true,
              integer: true,
              min_value: 0,
              max_value: 1000,
            }"
            type="number"
            :error-messages="errors.collect('payment_amount')"
            v-model="activationCodeToAdd.payment_amount"
            data-vv-name="payment_amount"
            maxlength="255"
            :data-vv-as="$t('offers.details.activation_codes_section.create_action.payment_amount')"
          ></v-text-field>
        </v-flex>
        <v-flex sm4 xs12>
          <v-text-field
            :label="$t('offers.details.activation_codes_section.create_action.bonus_amount') + '*'"
            name="bonus_amount"
            v-validate="{
              required: true,
              integer: true,
              min_value: 0,
              max_value: 1000,
            }"
            type="number"
            :error-messages="errors.collect('bonus_amount')"
            v-model="activationCodeToAdd.bonus_amount"
            data-vv-name="bonus_amount"
            maxlength="255"
            :data-vv-as="$t('offers.details.activation_codes_section.create_action.bonus_amount')"
          ></v-text-field>
        </v-flex>
        <v-flex sm4 xs12>
          <label-text
            :label="$t('offers.details.activation_codes_section.create_action.amount')"
            :value="totalAmount | currency | dollarSignI18n"
          ></label-text>
        </v-flex>
      </v-layout>
      <v-layout row wrap pad-form-row-elements>
        <v-flex sm8 xs12>
          <v-text-field
            autofocus
            :label="$t('offers.details.activation_codes_section.create_action.email')"
            name="email"
            v-validate="{
              required: false,
              email: true,
              max: 255,
            }"
            :error-messages="errors.collect('email')"
            v-model="activationCodeToAdd.recipient.email"
            data-vv-name="email"
            maxlength="255"
            :data-vv-as="$t('offers.details.activation_codes_section.create_action.email')"
          ></v-text-field>
        </v-flex>
        <v-flex sm4 xs12>
          <v-select
            :items="languageOptions"
            name="recipient_language"
            v-validate="'required'"
            v-model="activationCodeToAdd.recipient.language"
            :label="$t('offers.details.activation_codes_section.create_action.recipient_language') + '*'"
            :error-messages="errors.collect('recipient_language')"
            data-vv-name="recipient_language"
            :data-vv-as="$t('offers.details.activation_codes_section.create_action.recipient_language')"
            item-text="name"
            item-value="id"
            data-test="add-activation-code--recipient_language"
          ></v-select>
        </v-flex>
      </v-layout>
      <v-layout row wrap pad-form-row-elements>
        <v-flex sm8 xs12>
          <v-text-field
            autofocus
            :label="$t('offers.details.activation_codes_section.create_action.mobile_number')"
            name="mobile_number"
            v-maska="'1-###-###-####'"
            v-validate="{ regex: /^1-[0-9]{3}-[0-9]{3}-[0-9]{4}$/ }"
            :error-messages="errors.collect('mobile_number')"
            hint="1-999-999-9999"
            v-model="activationCodeToAdd.recipient.mobile_number"
            data-vv-name="mobile_number"
            maxlength="255"
            :data-vv-as="$t('offers.details.activation_codes_section.create_action.mobile_number')"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row wrap pad-form-row-elements>
        <v-flex sm6 xs12>
          <v-text-field
            :label="$t('offers.details.activation_codes_section.create_action.first_name')"
            name="first_name"
            v-validate="{
              max: 50,
            }"
            :error-messages="errors.collect('first_name')"
            v-model="activationCodeToAdd.recipient.first_name"
            data-vv-name="first_name"
            maxlength="50"
            :data-vv-as="$t('offers.details.activation_codes_section.create_action.first_name')"
          ></v-text-field>
        </v-flex>
        <v-flex sm6 xs12>
          <v-text-field
            :label="$t('offers.details.activation_codes_section.create_action.last_name')"
            name="last_name"
            v-validate="{
              max: 50,
            }"
            :error-messages="errors.collect('last_name')"
            v-model="activationCodeToAdd.recipient.last_name"
            data-vv-name="last_name"
            maxlength="50"
            :data-vv-as="$t('offers.details.activation_codes_section.create_action.last_name')"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row wrap pad-form-row-elements>
        <v-flex sm6 xs12>
          <h-autocomplete
            v-model="activationCodeToAdd.referenced_by_business_id"
            :label="$t('offers.details.activation_codes_section.create_action.business') + '*'"
            v-validate="'required'"
            :error-messages="errors.collect('contributor')"
            name="contributor"
            data-vv-name="contributor"
            data-vv-as="contributor"
            search-action="business/autocompleteSearch"
            data-test="add-activation-code--contributor"
          >
          </h-autocomplete>
        </v-flex>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import Vue from 'vue';

export default {
  name: 'add-offers-activation-code-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    offerObj: {
      type: Object,
    },
    helloShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activationCodeToAdd: {
        recipient: { language: 'fr' },
        referenced_by_business_id: null,
        bonus_amount: this.offerObj.incentive.bonus_amount,
        payment_amount: this.offerObj.incentive.payment_amount,
      },
    };
  },
  watch: {
    offerObj() {
      this.activationCodeToAdd = {
        recipient: { language: 'fr' },
        referenced_by_business_id: null,
        bonus_amount: this.offerObj.incentive.bonus_amount,
        payment_amount: this.offerObj.incentive.payment_amount,
      };
    },
  },
  computed: {
    ...mapGetters('activationCode', ['recipientLanguages']),
    totalAmount() {
      return Number(this.activationCodeToAdd.payment_amount || 0) + Number(this.activationCodeToAdd.bonus_amount || 0);
    },
    offerInfo() {
      return `${this.offerObj.branding.name} (${this.offerObj.id})`;
    },
    languageOptions() {
      return _.map(this.recipientLanguages, (language) => ({
        name: this.$t(`offers.details.activation_codes_section.languages.${language}`),
        id: language,
      }));
    },
  },
  methods: {
    clear() {
      this.activationCodeToAdd = {
        recipient: { language: 'fr' },
        referenced_by_business_id: null,
        bonus_amount: this.offerObj.incentive.bonus_amount,
        payment_amount: this.offerObj.incentive.payment_amount,
      };
      this.$validator.reset();
    },
    success() {
      this.clear();
      this.$emit('created');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        const payload = {
          offer_id: this.offerObj.id,
          referenced_by_business_id: this.activationCodeToAdd.referenced_by_business_id,
          recipient: {
            language: this.activationCodeToAdd.recipient.language,
          },
          bonus_amount: this.activationCodeToAdd.bonus_amount,
          payment_amount: this.activationCodeToAdd.payment_amount,
        };

        if (this.activationCodeToAdd.recipient.first_name) {
          payload.recipient.first_name = this.activationCodeToAdd.recipient.first_name;
        }
        if (this.activationCodeToAdd.recipient.last_name) {
          payload.recipient.last_name = this.activationCodeToAdd.recipient.last_name;
        }
        if (this.activationCodeToAdd.source_quantity !== '') {
          payload.source_quantity = Number(this.activationCodeToAdd.source_quantity);
        }
        if (this.activationCodeToAdd.recipient.email) {
          payload.recipient.email = this.activationCodeToAdd.recipient.email;
        }
        if (this.activationCodeToAdd.recipient.mobile_number) {
          payload.recipient.mobile_number = this.activationCodeToAdd.recipient.mobile_number;
        }

        await this.executeAction(
          {
            action: 'activationCode/createActivationCode',
            name: 'createActivationCode',
            success: this.success,
          },
          payload,
        );
      }
    },
  },
};
</script>
