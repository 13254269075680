import Vue from 'vue';
import InstantCardProjectOfferStatus from '@/enums/instant-card-project-offer-status';
import InstantCardProjectType from '@/enums/instant-card-project-type';
import InstantCardProjectStructure from '@/enums/instant-card-project-structure';

const defaultPage = 1;
const paginationLimit = 20;
const projectGiftCertificatesPaginationLimit = 20;
const projectBatchesPaginationLimit = 20;
const projectCardsPaginationLimit = 20;
const projectNotesPaginationLimit = 20;

export default {
  namespaced: true,
  state: {
    project: {},
    projects: [],
    projectCards: [],
    project_notes: [],
    list: {
      keyword: '',
      partner_id: '',
      pageNumber: 1,
      pageCount: 0,
      itemCount: 0,
    },
    project_cards_list: {
      keyword: '',
      status: '',
      pageNumber: 1,
      pageCount: 0,
      itemCount: 0,
    },
    project_notes_list: {
      pageNumber: 1,
      pageCount: 0,
      itemCount: 0,
    },
    projectGiftCertificates: [],
    projectGiftCertificatesPageNumber: defaultPage,
    projectGiftCertificatesPageCount: 0,
    projectGiftCertificatesItemCount: 0,

    projectBatches: [],
    projectBatchesPageNumber: defaultPage,
    projectBatchesPageCount: 0,
    projectBatchesItemCount: 0,
  },
  getters: {
    project: (state) => state.project,
    projectType: (state) => _.get(state, 'project.type', null),
    projects: (state) => state.projects,
    listKeyword: (state) => state.list.keyword,
    listPartnerId: (state) => state.list.partner_id,
    listPageCount: (state) => state.list.pageCount,
    listItemCount: (state) => state.list.itemCount,
    listPageNumber: (state) => state.list.pageNumber,
    inventoryOffers: (state) => state.inventoryOffers,

    projectGiftCertificates: (state) => state.projectGiftCertificates,
    projectGiftCertificatesPageCount: (state) => state.projectGiftCertificatesPageCount,
    projectGiftCertificatesItemCount: (state) => state.projectGiftCertificatesItemCount,
    projectGiftCertificatesPageNumber: (state) => state.projectGiftCertificatesPageNumber,
    projectGiftCertificatesAvailable: (state) =>
      state.projectGiftCertificates.filter((projectGiftCertificates) => projectGiftCertificates.status === InstantCardProjectOfferStatus.AVAILABLE)
        .length,

    projectBatches: (state) => state.projectBatches,
    projectBatchesPageCount: (state) => state.projectBatchesPageCount,
    projectBatchesItemCount: (state) => state.projectBatchesItemCount,
    projectBatchesPageNumber: (state) => state.projectBatchesPageNumber,

    projectCards: (state) => state.projectCards,
    listProjectCardsKeyword: (state) => state.project_cards_list.keyword,
    listProjectCardsStatus: (state) => state.project_cards_list.status,
    listProjectCardsPageCount: (state) => state.project_cards_list.pageCount,
    listProjectCardsItemCount: (state) => state.project_cards_list.itemCount,
    listProjectCardsPageNumber: (state) => state.project_cards_list.pageNumber,

    projectNotes: (state) => state.project_notes,
    listProjectNotesPageCount: (state) => state.project_notes_list.pageCount,
    listProjectNotesItemCount: (state) => state.project_notes_list.itemCount,
    listProjectNotesPageNumber: (state) => state.project_notes_list.pageNumber,
  },
  mutations: {
    project(state, project) {
      state.project = project;
    },
    projects(state, projects) {
      state.projects = projects;
    },
    listKeyword(state, keyword) {
      state.list.keyword = keyword;
    },
    listPartnerId(state, partner_id) {
      state.list.partner_id = partner_id;
    },
    listPageCount(state, pageCount) {
      state.list.pageCount = pageCount;
    },
    listItemCount(state, itemCount) {
      state.list.itemCount = itemCount;
    },
    listPage(state, page) {
      state.list.pageNumber = page;
    },

    projectGiftCertificates(state, projectGiftCertificates) {
      state.projectGiftCertificates = projectGiftCertificates;
    },
    projectGiftCertificatesPageCount(state, pageCount) {
      state.projectGiftCertificatesPageCount = pageCount;
    },
    projectGiftCertificatesItemCount(state, itemCount) {
      state.projectGiftCertificatesItemCount = itemCount;
    },
    projectGiftCertificatesPage(state, page) {
      state.projectGiftCertificatesPageNumber = page;
    },

    projectBatches(state, projectBatches) {
      state.projectBatches = projectBatches;
    },
    projectBatchesPageCount(state, pageCount) {
      state.projectBatchesPageCount = pageCount;
    },
    projectBatchesItemCount(state, itemCount) {
      state.projectBatchesItemCount = itemCount;
    },
    projectBatchesPage(state, page) {
      state.projectBatchesPageNumber = page;
    },

    projectCards(state, projectCards) {
      state.projectCards = projectCards;
    },
    listProjectCardsKeyword(state, keyword) {
      state.project_cards_list.keyword = keyword;
    },
    listProjectCardsStatus(state, status) {
      state.project_cards_list.status = status;
    },
    listProjectCardsPageCount(state, pageCount) {
      state.project_cards_list.pageCount = pageCount;
    },
    listProjectCardsItemCount(state, itemCount) {
      state.project_cards_list.itemCount = itemCount;
    },
    listProjectCardsPage(state, page) {
      state.project_cards_list.pageNumber = page;
    },

    projectNotes(state, projectNotes) {
      state.project_notes = projectNotes;
    },
    listProjectNotesPageCount(state, pageCount) {
      state.project_notes_list.pageCount = pageCount;
    },
    listProjectNotesItemCount(state, itemCount) {
      state.project_notes_list.itemCount = itemCount;
    },
    listProjectNotesPage(state, page) {
      state.project_notes_list.pageNumber = page;
    },
  },
  actions: {
    async autocompleteSearch({}, { page, limit, keyword }) {
      const instantCardProjectService = Vue.prototype.$services.instantCardProject;

      return instantCardProjectService.listProjects(page, limit, keyword);
    },
    async autocompleteOfferSearch({ state, dispatch }, { page, limit, keyword }) {
      const instantCardProjectService = Vue.prototype.$services.instantCardProject;
      const result = await instantCardProjectService.searchOffers(state.project.id, page, limit, keyword);
      return { items: result.items, item_count: result.item_count, page_count: result.page_count };
    },
    async createProject({ commit }, params) {
      // Forcing structure of blank if project is type blank.
      if (params.type === InstantCardProjectType.BLANK) {
        params.structure = InstantCardProjectStructure.BLANK;
      }

      const instantCardProjectService = Vue.prototype.$services.instantCardProject;
      return instantCardProjectService.createProject(params);
    },
    async getProject({ commit, dispatch }, id) {
      const instantCardProjectService = Vue.prototype.$services.instantCardProject;
      const project = await instantCardProjectService.getProject(id);

      commit('project', project);
      return project;
    },
    async listProjects({ state, commit }, { keyword, partner_id, page, limit } = {}) {
      const instantCardProjectService = Vue.prototype.$services.instantCardProject;
      const thePage = page || state.list.pageNumber || defaultPage;
      const theLimit = limit || paginationLimit;
      const theKeyword = keyword || '';
      const thePartnerId = partner_id || '';

      const listResults = await instantCardProjectService.listProjects(thePage, theLimit, theKeyword, thePartnerId);

      commit('listPartnerId', thePartnerId);
      commit('listKeyword', theKeyword);
      commit('listPage', thePage);
      commit('listPageCount', listResults.page_count);
      commit('listItemCount', listResults.item_count);
      commit('projects', listResults.items);

      return listResults;
    },
    async updateProject({ dispatch }, params) {
      const instantCardProjectService = Vue.prototype.$services.instantCardProject;
      const { id, name } = params;
      const result = await instantCardProjectService.updateProject(id, { name });
      await dispatch('getProject', id);
      return result;
    },
    async createProjectNote({ dispatch }, { project_id, note }) {
      const instantCardProjectService = Vue.prototype.$services.instantCardProject;

      await instantCardProjectService.createProjectNote(project_id, { note });

      await dispatch('listProjectNotes', { project_id });
    },
    async deleteProjectNote({ dispatch }, { project_id, note_id, reason }) {
      const instantCardProjectService = Vue.prototype.$services.instantCardProject;

      await instantCardProjectService.deleteProjectNote(project_id, note_id, { reason });

      await dispatch('listProjectNotes', { project_id });
    },
    async listProjectNotes({ state, commit }, { project_id, page, limit } = {}) {
      const instantCardProjectService = Vue.prototype.$services.instantCardProject;
      const thePage = page || state.project_notes_list.pageNumber;
      const theLimit = limit || projectNotesPaginationLimit;
      const theProjectId = project_id || '';

      const listResults = await instantCardProjectService.listProjectNotes(thePage, theLimit, theProjectId);

      commit('listProjectNotesPage', thePage);
      commit('listProjectNotesPageCount', listResults.page_count);
      commit('listProjectNotesItemCount', listResults.item_count);
      commit('projectNotes', listResults.items);
    },
    async updateInstantCardProjectOffer({ dispatch }, params) {
      const instantCardProjectService = Vue.prototype.$services.instantCardProject;
      const { id, payment_amount, bonus_amount, project_id } = params;
      const result = await instantCardProjectService.updateInstantCardProjectOffer(id, { payment_amount, bonus_amount });
      await dispatch('getProject', project_id);
      return result;
    },
    async listProjectGiftCertificates({ commit, state }, { id, page }) {
      const instantCardProjectService = Vue.prototype.$services.instantCardProject;
      const thePage = page || state.projectGiftCertificatesPageNumber || defaultPage;
      const result = await instantCardProjectService.listProjectGiftCertificates(id, 1, projectGiftCertificatesPaginationLimit);
      const projectGiftCertificates = result.items;

      commit('projectGiftCertificates', projectGiftCertificates);
      commit('projectGiftCertificatesPage', thePage);
      commit('projectGiftCertificatesPageCount', result.page_count);
      commit('projectGiftCertificatesItemCount', result.item_count);
    },
    async changeProjectGiftCertificatesPage({ commit, state }, { id, page }) {
      commit('projectGiftCertificatesPage', page);

      const instantCardProjectService = Vue.prototype.$services.instantCardProject;
      const limit = projectGiftCertificatesPaginationLimit;

      const result = await instantCardProjectService.listProjectGiftCertificates(id, page, limit);

      commit('projectGiftCertificatesPageCount', result.page_count);
      commit('projectGiftCertificates', result.items);
    },
    async activateProjectGiftCertificate({ dispatch }, { id, projectId }) {
      const instantCardProjectService = Vue.prototype.$services.instantCardProject;
      const result = await instantCardProjectService.activateProjectGiftCertificate(id);
      await dispatch('getProject', projectId);
      return result;
    },
    async deactivateProjectGiftCertificate({ dispatch }, { id, projectId }) {
      const instantCardProjectService = Vue.prototype.$services.instantCardProject;
      const result = await instantCardProjectService.deactivateProjectGiftCertificate(id);
      await dispatch('getProject', projectId);
      return result;
    },
    async removeProjectGiftCertificate({ dispatch }, { id, projectId }) {
      const instantCardProjectService = Vue.prototype.$services.instantCardProject;
      const result = await instantCardProjectService.removeProjectGiftCertificate(id);
      await dispatch('getProject', projectId);
      return result;
    },
    async addProjectGiftCertificate({ dispatch }, { project_id, offer_id, payment_amount, bonus_amount }) {
      const instantCardProjectService = Vue.prototype.$services.instantCardProject;
      const result = await instantCardProjectService.addProjectGiftCertificate(project_id, { offer_id, payment_amount, bonus_amount });
      await dispatch('getProject', project_id);
      return result;
    },
    async addProjectBatch({ dispatch }, params) {
      const instantCardBatchService = Vue.prototype.$services.instantCardBatch;
      const result = await instantCardBatchService.createBatch(params);
      await dispatch('getProject', params.instant_card_project_id);
      return result;
    },
    async listProjectBatches({ commit, state }, { id, page }) {
      const instantCardBatchService = Vue.prototype.$services.instantCardBatch;
      const thePage = page || state.projectBatchesPageNumber || defaultPage;
      const result = await instantCardBatchService.listBatches(1, projectBatchesPaginationLimit, null, null, id);
      const projectBatches = result.items;

      commit('projectBatches', projectBatches);
      commit('projectBatchesPage', thePage);
      commit('projectBatchesPageCount', result.page_count);
      commit('projectBatchesItemCount', result.item_count);
      return projectBatches;
    },
    async changeProjectBatchesPage({ commit, state }, { id, page }) {
      commit('projectBatchesPage', page);

      const instantCardBatchService = Vue.prototype.$services.instantCardBatch;
      const limit = projectBatchesPaginationLimit;

      const result = await instantCardBatchService.listBatches(page, limit, null, null, id);

      commit('projectBatchesPageCount', result.page_count);
      commit('projectBatches', result.items);
    },
    async listProjectCards({ commit, state }, { id, keyword, status, page, limit }) {
      const instantCardCardService = Vue.prototype.$services.instantCardCard;
      const thePage = page || state.listprojectCards.pageNumber || defaultPage;
      const theLimit = limit || projectCardsPaginationLimit;
      const theKeyword = keyword || '';
      const theStatus = status || '';

      const result = await instantCardCardService.listCards(thePage, theLimit, theKeyword, '', id, theStatus);
      const projectCards = result.items;

      commit('listProjectCardsStatus', theStatus);
      commit('listProjectCardsKeyword', theKeyword);
      commit('listProjectCardsPage', thePage);
      commit('listProjectCardsPageCount', result.page_count);
      commit('listProjectCardsItemCount', result.item_count);
      commit('projectCards', projectCards);
    },
    async unlinkAllCardsFromInstantCardProject({ dispatch }, id) {
      const instantCardProjectService = Vue.prototype.$services.instantCardProject;
      const result = await instantCardProjectService.unlinkAllCardsFromInstantCardProject(id);
      await dispatch('getProject', id);
      return result;
    },
    async pauseProjectCards({ dispatch }, id) {
      const instantCardProjectService = Vue.prototype.$services.instantCardProject;
      return instantCardProjectService.pauseProjectCards(id);
    },
    async resumeProjectCards({ dispatch }, id) {
      const instantCardProjectService = Vue.prototype.$services.instantCardProject;
      return instantCardProjectService.resumeProjectCards(id);
    },
    async clearListProjects({ commit }) {
      commit('projects', []);
    },
    async clearProjectCards({ commit }) {
      commit('projectCards', []);
    },
  },
};
