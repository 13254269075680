<template>
  <card-modal
    icon=""
    :title="$t('instant_card_projects.details.unlink_cards_section.title')"
    context="unlinkProjectCards"
    @submit="submit"
    @cancel="cancel"
    cancel_text="instant_card_projects.details.unlink_cards_section.button_cancel"
    submit_text="instant_card_projects.details.unlink_cards_section.button_save"
    :submit_working="working"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <p>{{ $t('instant_card_projects.details.unlink_cards_section.note') }}</p>
        <label-text :label="$t('instant_card_projects.details.unlink_cards_section.project')" :value="projectObj.id" />
        <label-text :label="$t('instant_card_projects.details.unlink_cards_section.total_cards')" :value="projectCardsItemCount" />
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapActions } from 'vuex';

export default {
  name: 'unlink-project-cards-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    projectObj: {
      type: Object,
      required: true,
    },
    projectCardsItemCount: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapActions('ui', ['addError', 'clearAllError', 'clearError']),
    clear() {
      this.$validator.reset();
    },
    success(response) {
      this.clear();
      this.$emit('unlinked');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      let validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        await this.executeAction(
          {
            action: 'instantCardProject/unlinkAllCardsFromInstantCardProject',
            name: 'unlinkProjectCards',
            success: this.success,
          },
          this.projectObj.id,
        );
      }
    },
  },
};
</script>
