<template>
  <div>
    <table class="responsive-table">
      <thead>
        <tr>
          <td>{{ $t('space.clerk_table.fields.id') }}</td>
          <td>{{ $t('space.clerk_table.fields.name') }}</td>
          <td>{{ $t('space.clerk_table.fields.username') }}</td>
          <td>{{ $t('space.clerk_table.fields.roles') }}</td>
          <td>{{ $t('space.clerk_table.fields.last_auth_date') }}</td>
          <td>{{ $t('space.clerk_table.fields.date_created') }}</td>
          <td>{{ $t('space.clerk_table.fields.status') }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>
            <h5>{{ $t('space.clerk_table.fields.id') }}</h5>
            <router-link :to="{ name: 'clerk_details', params: { clerkId: item.id } }">{{ item.id }}</router-link>
          </td>
          <td>
            <h5>{{ $t('space.clerk_table.fields.name') }}</h5>
            {{ item.name }}
          </td>
          <td>
            <h5>{{ $t('space.clerk_table.fields.username') }}</h5>
            {{ item.username }}
          </td>
          <td>
            <h5>{{ $t('space.clerk_table.fields.roles') }}</h5>
            {{ rolesFormatted(item.roles) }}
          </td>
          <td>
            <h5>{{ $t('space.clerk_table.fields.last_auth_date') }}</h5>
            <span v-if="item.last_auth_date">{{
              item.last_auth_date | formatShortDateTimeNoSecondsInverted({ withAgo: true, returnNullIfNoDate: true, timezone: 'Canada/Eastern' })
            }}</span>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('space.clerk_table.fields.date_created') }}</h5>
            {{ item.creation_date | formatShortDateInverted }}
          </td>
          <td>
            <h5>{{ $t('space.clerk_table.fields.status') }}</h5>
            <clerk-status-view slot="labelTextLink" :status="item.status" />
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-xs-center">
      <v-pagination v-if="listClerksPageCount > 1" v-model="pagination" :length="listClerksPageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';
import ClerkStatusView from '@/components/clerk/ClerkStatusView';

export default {
  name: 'space-clerk-table',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { ClerkStatusView },
  props: {
    partnerId: {
      type: String,
    },
    items: {
      type: Array,
    },
  },
  data() {
    return {
      pagination: 1,
    };
  },
  mounted() {
    this.pagination = this.listClerksPageNumber;
  },
  watch: {
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'partnerSecurity/listClerks',
        },
        {
          partner_id: this.partnerId,
          page: newValue,
        },
      );
    },
  },
  computed: {
    ...mapGetters('partnerSecurity', ['listClerksPageCount', 'listClerksPageNumber']),
  },
  methods: {
    rolesFormatted(roles) {
      const rolesFormat = _.map(roles, (role) => {
        return this.$t(`clerk.roles.${role}`);
      }).sort();
      return rolesFormat.join(', ');
    },
  },
};
</script>
