import Vue from 'vue';

const service = {
  async getCardUpdateLog(query) {
    let url = `${process.env.VUE_APP_BASE_API_URL}/cards/v1/cards/${query.public_token}/cardholder/logs`;

    const response = await Vue.prototype.$axios.get(url);
    return response.data.data.items;
  },
};

export default service;
