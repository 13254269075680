import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    virtualMoneys: [],
  },
  getters: {
    virtualMoneys: (state) => state.virtualMoneys,
  },
  mutations: {
    virtualMoneys(state, virtualMoneys) {
      if (!virtualMoneys) {
        virtualMoneys = [];
      }
      state.virtualMoneys = virtualMoneys;
    },
  },
  actions: {
    async listVirtualMoneys({ commit }) {
      const virtualMoneyService = Vue.prototype.$services.virtualMoney;
      const virtualMoneys = await virtualMoneyService.listVirtualMoneys();

      commit('virtualMoneys', virtualMoneys.items);

      return virtualMoneys.items;
    },
  },
};
