<template>
  <div class="card-wallet-transaction-section">
    <table class="responsive-table" id="card-wallet-transactions-table">
      <thead>
        <tr>
          <td>{{ $t('card.wallet_transactions.table.fields.transaction_id') }}</td>
          <td>{{ $t('card.wallet_transactions.table.fields.card_trx_type') }}</td>
          <td>{{ $t('card.wallet_transactions.table.fields.transaction_date') }}</td>
          <td>{{ $t('card.wallet_transactions.table.fields.operation_group_id') }}</td>
          <td>{{ $t('card.wallet_transactions.table.fields.load_operation_id') }}</td>
          <td>{{ $t('card.wallet_transactions.table.fields.activity_id') }}</td>
          <td>{{ $t('card.wallet_transactions.table.fields.wallet_trx_type') }}</td>
          <td>{{ $t('card.wallet_transactions.table.fields.wallet_definition_id') }}</td>
          <td>{{ $t('card.wallet_transactions.table.fields.merchant_name') }}</td>
          <td>{{ $t('card.wallet_transactions.table.fields.amount') }}</td>
          <td>{{ $t('card.wallet_transactions.table.fields.reason') }}</td>
          <td></td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>
            <h5>{{ $t('card.wallet_transactions.table.fields.transaction_id') }}:</h5>
            <span>{{ item.transaction_id }}</span>
          </td>
          <td>
            <h5>{{ $t('card.wallet_transactions.table.fields.card_trx_type') }}:</h5>
            <span>{{ item.card_trx_type }}</span>
          </td>
          <td>
            <h5>{{ $t('card.wallet_transactions.table.fields.transaction_date') }}:</h5>
            <span>{{ item.transaction_date | formatShortDateTimeInverted }}</span>
          </td>
          <td>
            <h5>{{ $t('card.wallet_transactions.table.fields.operation_group_id') }}:</h5>
            <span>{{ item.operation_group_id }}</span>
          </td>
          <td>
            <h5>{{ $t('card.wallet_transactions.table.fields.load_operation_id') }}:</h5>
            <span v-if="item.load_operation_id">{{ item.load_operation_id }}</span>
            <span v-if="!item.load_operation_id" class="info-not-available">{{ $t('system.na') }}</span>
          </td>
          <td>
            <h5>{{ $t('card.wallet_transactions.table.fields.activity_id') }}:</h5>
            <span>{{ item.activity_id }}</span>
          </td>
          <td>
            <h5>{{ $t('card.wallet_transactions.table.fields.wallet_trx_type') }}:</h5>
            <span>{{ item.wallet_trx_type }}</span>
          </td>
          <td>
            <h5>{{ $t('card.wallet_transactions.table.fields.wallet_definition_id') }}:</h5>
            <link-secondary-route
              :can-access="enableWalletDefinitionLink(item)"
              :route-name="'wallet_definition_details'"
              :route-params="{ walletDefinitionId: item.wallet_definition_id }"
              >{{ item.wallet_definition_id === 'open' ? 'open' : item.wallet_definition_id }}</link-secondary-route
            >
          </td>
          <td>
            <h5>{{ $t('card.wallet_transactions.table.fields.merchant_name') }}:</h5>
            <span class="merchant-name" v-if="item.merchant_name">{{ item.merchant_name }}</span>
            <span v-if="!item.merchant_name" class="info-not-available">{{ $t('system.na') }}</span>
          </td>
          <td class="amount">
            <h5>{{ $t('card.wallet_transactions.table.fields.amount') }}:</h5>
            <span :class="getAmountStyleClass(item.amount.$numberDecimal)">{{ item.amount.$numberDecimal | currency | dollarSignI18n }}</span>
          </td>
          <td>
            <h5>{{ $t('card.wallet_transactions.table.fields.reason') }}:</h5>
            <span v-if="item.reason">{{ item.reason }}</span>
            <span v-if="!item.reason" class="info-not-available">{{ $t('system.na') }}</span>
          </td>
          <td>
            <link-secondary-action slot="labelTextLink" @click="openOperationGroupAdjustmentModal(item)">
              {{ $t('card.wallet_transactions.table.fields.group_adjustment_action') }}
            </link-secondary-action>
          </td>
          <td>
            <link-secondary-action v-if="canCancel(item.card_trx_type)" slot="labelTextLink" @click="openCancelTransactionModal(item)">
              {{ $t('card.wallet_transactions.table.fields.cancel_transaction_action') }}
            </link-secondary-action>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-xs-center">
      <v-pagination v-if="cardWalletTransactionPageCount > 1" v-model="pagination" :length="cardWalletTransactionPageCount"></v-pagination>
    </div>

    <modal-action :show-modal="showOperationGroupAdjustmentModal">
      <operation-group-adjustment-action
        :card-public-token="card_public_token"
        :transaction="groupAdjustmentTransaction"
        @close="closeOperationGroupAdjustmentModal"
        @action="afterOperationGroupAdjustment"
      ></operation-group-adjustment-action>
    </modal-action>

    <modal-action :show-modal="showCancelTransactionModal">
      <cancel-transaction-action
        :card-public-token="card_public_token"
        :transaction="cancelTransaction"
        @close="closeCancelTransactionModal"
        @action="afterCancelTransaction"
      ></cancel-transaction-action>
    </modal-action>
  </div>
</template>

<script>
import { security, disableWithError, actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import OperationGroupAdjustmentAction from '../actions/OperationGroupAdjustmentAction.vue';
import CancelTransactionAction from '../actions/CancelTransactionAction.vue';

export default {
  name: 'card-wallet-transactions-table',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { OperationGroupAdjustmentAction, CancelTransactionAction },
  props: {
    card: {
      type: Object,
    },
    items: {
      type: Array,
    },
  },
  data() {
    return {
      pagination: 1,
      showOperationGroupAdjustmentModal: false,
      showCancelTransactionModal: false,
      groupAdjustmentTransaction: {},
      cancelTransaction: {},
    };
  },
  mounted() {
    this.pagination = this.cardWalletTransactionPageNumber;
  },
  computed: {
    ...mapGetters('card', [
      'cardWalletTransactionPageCount',
      'listWalletTransactionId',
      'listWalletTransactionOperationGroupId',
      'listWalletTransactionLoadOperationId',
      'cardWalletTransactionPageNumber',
    ]),
    cardholder_key() {
      return this.card.cardholder.key;
    },
    card_public_token() {
      return this.card.public_token;
    },
  },
  watch: {
    pagination(newValue) {
      this.listTransactions(newValue);
    },
  },
  methods: {
    canCancel(TrxType) {
      return [
        'authorization',
        'partial_authorization',
        'authorization_reversal',
        'credits_push_authorization',
        'payment',
        'unauthorized_payment',
        'credits_refund',
        'payment_reversal',
        'chargeback',
        'chargeback_reversal',
        'credits_push_settlement',
        'cashback',
      ].includes(TrxType);
    },
    openOperationGroupAdjustmentModal(transaction) {
      this.groupAdjustmentTransaction = transaction;
      this.showOperationGroupAdjustmentModal = true;
    },
    closeOperationGroupAdjustmentModal() {
      this.groupAdjustmentTransaction = {};
      this.showOperationGroupAdjustmentModal = false;
    },
    async afterOperationGroupAdjustment() {
      this.closeOperationGroupAdjustmentModal();
      await this.listTransactions(1);
    },
    openCancelTransactionModal(transaction) {
      this.cancelTransaction = transaction;
      this.showCancelTransactionModal = true;
    },
    closeCancelTransactionModal() {
      this.cancelTransaction = {};
      this.showCancelTransactionModal = false;
    },
    async afterCancelTransaction() {
      this.closeCancelTransactionModal();
      await this.listTransactions(1);
    },
    getAmountStyleClass(amount) {
      return amount < 0 ? 'negative' : '';
    },
    enableWalletDefinitionLink(item) {
      return this.canViewWalletDefinition() && item.wallet_definition_id !== 'open';
    },
    async listTransactions(page) {
      await this.executeAction(
        {
          action: 'card/listWalletTransactionsByCardholderKey',
        },
        {
          cardholder_key: this.cardholder_key,
          transaction_id: this.listWalletTransactionId,
          operation_group_id: this.listWalletTransactionOperationGroupId,
          load_operation_id: this.listWalletTransactionLoadOperationId,
          page,
        },
      );
    },
  },
};
</script>
<style lang="css">
.merchant-name {
  white-space: pre-wrap;
}
</style>
