<template>
  <div>
    <layout-notes :items="items" @delete="deleteNote" />
    <div class="text-xs-center">
      <v-pagination v-if="listBusinessNotesPageCount > 1" v-model="pagination" :length="listBusinessNotesPageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'business-notes-table',
  mixins: [actionErrorTrackable, security],
  props: {
    items: {
      type: Array,
    },
    businessId: {
      type: String,
    },
  },
  data() {
    return {
      pagination: 1,
    };
  },
  mounted() {
    this.pagination = this.listBusinessNotesPageNumber;
  },
  watch: {
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'business/listBusinessNotes',
        },
        {
          business_id: this.businessId,
          page: newValue,
        },
      );
    },
  },
  computed: {
    ...mapGetters('business', ['listBusinessNotesPageNumber', 'listBusinessNotesPageCount', 'listBusinessNotesItemCount']),
  },
  methods: {
    deleteNote(noteId) {
      this.$emit('business_note_delete_action', noteId);
    },
  },
};
</script>
