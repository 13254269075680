<template>
  <card :title="$t('business.details.access_section.title')" icon="">
    <template slot="headerZoneRight">
      <label-text
        class="text-sm-center"
        :label="$t('business.details.access_section.access_count')"
        :value="reportAccesses && reportAccesses.length ? reportAccesses.length : '0'"
      ></label-text>
      <div v-if="showAddReportAccessFeature()" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text v-if="showAddReportAccessFeature()" :label="`${$t('business.details.access_section.action')}`" value="3">
        <link-secondary-action slot="labelTextLink" @click="openAddReportAccessModal">
          {{ $t('business.details.access_section.add_access') }}
        </link-secondary-action>
      </label-text>
    </template>
    <template slot="content">
      <report-access-table
        v-if="reportAccesses.length > 0"
        @business_access_remove_action="openRemoveReportAccessModal"
        :items="reportAccesses"
      ></report-access-table>
      <label-none v-else>{{ $t('system.none') }}</label-none>
      <modal-action :show-modal="showAddReportAccessModal">
        <add-report-access-action :business-obj="business" @close="closeAddReportAccessModal" @created="addReportAccessSuccess" />
      </modal-action>
      <modal-confirm
        v-if="reportAccessIdToRemove"
        :show-modal="showRemoveReportAccessModal"
        :title="$t(`business.details.access_section.remove_action.confirm.title`)"
        context="removeReportEmailAccess"
        :text="
          $t(`business.details.access_section.remove_action.confirm.text`, {
            reportAccessEmail: `${reportAccessToRemove.email}`,
          })
        "
        :cancel_text="`business.details.access_section.remove_action.confirm.button_cancel`"
        :submit_text="`business.details.access_section.remove_action.confirm.button_submit`"
        :submit_working="working"
        @submit="removeReportAccess"
        @close="closeRemoveReportAccessModal"
      ></modal-confirm>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import ReportAccessTable from './ReportAccessTable';
import AddReportAccessAction from '../actions/AddReportAccessAction';
import { mapGetters } from 'vuex';

export default {
  name: 'report-access',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { ReportAccessTable, AddReportAccessAction },
  data() {
    return {
      showAddReportAccessModal: false,
      showRemoveReportAccessModal: false,
      reportAccessIdToRemove: null,
      reportAccessToRemove: {},
    };
  },
  computed: {
    ...mapGetters('business', ['business', 'reportAccesses']),
    ...mapGetters('ui', ['currentLocale']),
  },
  watch: {
    async business() {
      await this.fetchReportAccesses();
    },
  },
  methods: {
    async fetchReportAccesses() {
      await this.executeAction({ action: 'business/listReportAccesses' }, this.business.id);
    },
    openAddReportAccessModal() {
      this.showAddReportAccessModal = true;
    },
    closeAddReportAccessModal() {
      this.showAddReportAccessModal = false;
    },
    async addReportAccessSuccess() {
      await this.fetchReportAccesses();
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'business.details.access_section.create_action.success' });
      this.closeAddReportAccessModal();
    },
    showAddReportAccessFeature() {
      // TODO: Is there a special permission for business?
      // return this.canManageBusiness();
      return true;
    },
    openRemoveReportAccessModal(id) {
      this.reportAccessIdToRemove = id;

      (this.reportAccessToRemove = this.reportAccesses.find((l) => l.id === id)), (this.showRemoveReportAccessModal = true);
    },
    closeRemoveReportAccessModal() {
      this.reportAccessIdToRemove = null;
      this.reportAccessToRemove = {};
      this.showRemoveReportAccessModal = false;
    },
    async removeReportAccess() {
      await this.executeAction(
        {
          action: 'business/removeReportAccess',
          name: 'removeReportAccess',
          success: this.removeReportAccessSuccess,
        },
        { business_id: this.business.id, id: this.reportAccessIdToRemove },
      );
    },
    async removeReportAccessSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: `business.details.access_section.remove_action.success` });
      this.closeRemoveReportAccessModal();
    },
  },
};
</script>
