<template>
  <card :title="$t('publishers.details.branding_section.title')" icon="">
    <template slot="headerZoneRight">
      <label-text v-if="showAddLinkBrandingFeature()" :label="`${$t('publishers.details.branding_section.action')}`" value="">
        <link-secondary-action v-if="!publisher.branding_id" slot="labelTextLink" @click="openAddLinkBrandingModal">
          {{ $t('publishers.details.branding_section.link') }}
        </link-secondary-action>
        <link-secondary-action v-if="publisher.branding_id" slot="labelTextLink" @click="openUnlinkBrandingModal">
          {{ $t('publishers.details.branding_section.unlink') }}
        </link-secondary-action>
      </label-text>
    </template>
    <template slot="content">
      <modal-action :show-modal="showAddLinkBrandingModal" avoid-rendering
        ><link-branding-action :publisher-obj="publisher" @close="closeAddLinkBrandingModal" @linked="linkBrandingSuccess"></link-branding-action
      ></modal-action>
      <publishers-branding-table
        v-if="branding"
        :item="branding"
        @publishers_branding_update_action="openAddLinkBrandingModal"
      ></publishers-branding-table>
      <label-none v-if="!branding">{{ $t('system.none') }}</label-none>
      <modal-confirm
        v-if="branding"
        :show-modal="showUnlinkBrandingModal"
        :title="$t(`publishers.details.branding_section.unlink_action.title`)"
        context="UnlinkBranding"
        :text="
          $t(`publishers.details.branding_section.unlink_action.text`, {
            brandingName: branding.name,
            publisherName: publisher.name,
          })
        "
        :cancel_text="`publishers.details.branding_section.unlink_action.button_cancel`"
        :submit_text="`publishers.details.branding_section.unlink_action.button_submit`"
        :submit_working="working"
        @submit="unlinkBrandingSuccess"
        @close="closeUnlinkBrandingModal"
      ></modal-confirm>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import LinkBrandingAction from '../actions/LinkBrandingAction';
import PublishersBrandingTable from './PublishersBrandingTable';
import { mapGetters } from 'vuex';

export default {
  name: 'publishers-branding',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { PublishersBrandingTable, LinkBrandingAction },
  data() {
    return {
      branding: null,
      showAddLinkBrandingModal: false,
      showUnlinkBrandingModal: false,
    };
  },
  computed: {
    ...mapGetters('publishers', ['publisher']),
    ...mapGetters('ui', ['currentLocale']),
  },
  watch: {
    async publisher() {
      await this.fetchBranding();
    },
  },
  methods: {
    openAddLinkBrandingModal() {
      this.showAddLinkBrandingModal = true;
    },
    closeAddLinkBrandingModal() {
      this.showAddLinkBrandingModal = false;
    },
    openUnlinkBrandingModal() {
      this.showUnlinkBrandingModal = true;
    },
    closeUnlinkBrandingModal() {
      this.showUnlinkBrandingModal = false;
    },
    async unlinkBrandingSuccess() {
      this.showUnlinkBrandingModal = false;
      await this.executeAction(
        {
          action: 'publishers/unlinkBrandingFromPublisher',
          name: 'unlinkBrandingFromPublisher',
        },
        { id: this.publisher.id },
      );
      await this.fetchBranding();
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'publishers.details.branding_section.unlink_action.success' });
    },
    async linkBrandingSuccess() {
      await this.fetchBranding();
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'publishers.details.branding_section.link_action.success' });
      this.closeAddLinkBrandingModal();
    },
    showAddLinkBrandingFeature() {
      return this.canManageOffer(); // TODO: change permission
    },
    async fetchBranding() {
      if (this.publisher.branding_id) {
        this.branding = await this.executeAction({ action: 'branding/getBranding' }, this.publisher.branding_id);
      } else {
        this.branding = null;
      }
    },
  },
};
</script>
