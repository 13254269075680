<template>
  <card-modal
    icon=""
    :title="$t('application.send_change_application_request_action.title')"
    context="send-change-application-request"
    @submit="submit"
    @cancel="cancel"
    cancel_text="application.send_change_application_request_action.button_close"
    submit_text="application.send_change_application_request_action.button_save"
    :submit_working="working"
    :enable_submit="enableSubmit"
  >
    <form @submit.prevent="submit" slot="content">
      <v-alert :value="changeRequestExists" color="warning" icon="priority_high" outline data-test="pending-item-warning">
        {{ $t('application.create_change_application_request_action.review_already_exists') }}
      </v-alert>

      <v-alert :value="isDuplicateCard" type="warning" icon="priority_high">
        {{ $t('application.create_change_application_request_action.card_duplicated') }}
      </v-alert>

      <div v-if="!changeRequestExists" data-test="change-request-url">
        <h4>{{ $t('application.send_change_application_request_action.operations') }}</h4>
        <!--         TODO: would be nice to have just one error message display after the list of checkboxes -->
        <v-checkbox
          height="0"
          multiple
          v-for="(type, i) in operationTypes"
          :key="i"
          class="operation-checkbox"
          v-model="selectedTypes"
          :value="type.abbr"
          :label="type.name"
          data-test="send_change_application_request_action--operation-types"
          :checked="true"
          v-validate="{ required: true, minArrayLength: true }"
          data-vv-name="operation-types"
          :data-vv-as="$t(`application.send_change_application_request_action.operations`)"
          :error-messages="errors.collect('operation-types')"
        />
      </div>
    </form>
  </card-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable } from '@/mixins';
import _ from 'lodash';

export default {
  name: 'send-change-application-request',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    applicationId: {
      type: String,
      required: true,
    },
    applicationKyc: {
      type: Object,
      required: true,
    },
    kyc: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      operationTypes: [
        { name: this.$t('application.send_change_application_request_action.options.first_name'), abbr: 'first_name_verification_required' },
        { name: this.$t('application.send_change_application_request_action.options.last_name'), abbr: 'last_name_verification_required' },
        { name: this.$t('application.send_change_application_request_action.options.date_of_birth'), abbr: 'dob_verification_required' },
        { name: this.$t('application.send_change_application_request_action.options.address'), abbr: 'address_verification_required' },
        { name: this.$t('application.send_change_application_request_action.options.document_name_address'), abbr: 'document_for_address_required' },
        {
          name: this.$t('application.send_change_application_request_action.options.document_name_date_of_birth'),
          abbr: 'document_for_dob_required',
        },
      ],
      selectedTypes: [],
    };
  },
  computed: {
    ...mapGetters('selfserve', ['changeApplicationRequest']),
    enableSubmit() {
      return !this.changeApplicationRequest;
    },
    changeRequestExists() {
      return this.changeApplicationRequest;
    },
    isDuplicateCard() {
      const reasonCode = _.get(this.applicationKyc, 'reason.code');
      return reasonCode && reasonCode === 'card.active.already.in.card_program';
    },
  },
  methods: {
    clear() {
      this.selectedTypes = [];
      this.$validator.reset();
    },
    cancel() {
      this.$emit('close');
    },
    mapSelectedTypes() {
      const requestedChanges = {};
      this.selectedTypes.map((type) => {
        return (requestedChanges[type] = true);
      });
      return requestedChanges;
    },
    success() {
      this.clear();
      this.$emit('action');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        await this.executeAction(
          {
            action: 'selfserve/sendChangeApplicationRequestEmail',
            success: this.success,
          },
          {
            application_id: this.applicationId,
            requested_changes: this.mapSelectedTypes(),
          },
        );
      }
    },
  },
};
</script>
