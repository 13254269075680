<template>
  <div>
    <shared-offers-table :items="items" exclude="branding" />
    <div class="text-xs-center">
      <v-pagination v-if="brandingOffersPageCount > 1" v-model="pagination" :length="brandingOffersPageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import SharedOffersTable from '@/components/shared_tables/SharedOffersTable';
import { mapGetters } from 'vuex';

export default {
  name: 'branding-offers-table',
  mixins: [actionErrorTrackable, security],
  components: { SharedOffersTable },
  props: {
    id: {
      type: String,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      pagination: 1,
    };
  },
  mounted() {
    this.pagination = this.brandingOffersPageNumber;
  },
  watch: {
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'branding/listBrandingOffers',
        },
        {
          id: this.id,
          page: newValue,
        },
      );
    },
  },
  computed: {
    ...mapGetters('branding', ['brandingOffersPageNumber', 'brandingOffersPageCount']),
  },
};
</script>
