import { Joi, validate } from '@/utils/validators/index';

export async function validateUpdateCardholderParams(params) {
  const publicTokenSchema = Joi.string().required();
  const cardholderSchema = Joi.object()
    .keys({
      phone: Joi.string()
        .phone()
        .required(),
      mobile: Joi.string()
        .phone()
        .allow(null),
      email: Joi.string()
        .email()
        .required(),
      language: Joi.string().required(),
      address: Joi.object({
        street: Joi.string()
          .trim()
          .max(50)
          .required(),
        city: Joi.string()
          .trim()
          .max(50)
          .required(),
        state: Joi.string()
          .length(2)
          .valid(['AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'NT', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'])
          .required(),
        country: Joi.string()
          .length(2)
          .valid(['CA'])
          .required(),
        zip: Joi.string()
          .zip()
          .required(),
      }).required(),
      settings: Joi.object({
        alerts: Joi.boolean().required(),
      }),
    })
    .required();
  const schema = Joi.object()
    .keys({
      token: publicTokenSchema,
      cardholder: cardholderSchema,
    })
    .required();

  await validate(params, schema);
}

export async function validateGetUpdateLogParams(params) {
  const schema = Joi.string().required();
  await validate(params, schema);
}

export async function validateGetCommentParams(params) {
  const schema = Joi.string()
    .id()
    .required();
  await validate(params, schema);
}

export async function validateCreateCommentParams(params) {
  const schema = Joi.object({
    card_public_token: Joi.string().required(),
    comment: Joi.string()
      .required()
      .max(4096),
    account_id: Joi.string().max(20),
  }).required();

  await validate(params, schema);
}
