<template>
  <div>
    <v-text-field
      :autofocus="autofocus"
      clearable
      persistent-hint
      class="form-warning--search-field"
      :maxlength="maxLength"
      :label="label"
      :error-messages="errorMessages"
      :value="value"
      @input="updateData"
    >
    </v-text-field>
    <span v-html="totalResultsMessageShow" />
  </div>
</template>

<script>
export default {
  props: {
    autofocus: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
      default: 100,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    searched: {
      type: String,
      default: '',
    },
    value: {
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    hintMessage: {
      type: String,
      required: true,
    },
    showTotalResultsMessage: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    totalResultsMessageShow() {
      return this.shouldShowTotalResultsMessage()
        ? `<div class="search-warning-hint"><i class="fas fa-exclamation-triangle"></i> ${this.hintMessage}</div>`
        : '';
    },
  },
  methods: {
    shouldShowTotalResultsMessage() {
      return !!this.showTotalResultsMessage && this.searched === this.value;
    },
    updateData(event) {
      this.$emit('input', event);
    },
  },
  name: 'form-warning-search',
};
</script>

<style scoped></style>
