<template>
  <card-modal
    icon="fal fa-exchange"
    :title="$t('voucher.adjust_voucher_balance_action.title')"
    context="adjustVoucherBalance"
    @submit="openConfirmModal"
    @cancel="cancel"
    cancel_text="voucher.adjust_voucher_balance_action.button_cancel"
    submit_text="voucher.adjust_voucher_balance_action.button_submit"
    :submit_working="working"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-layout row wrap pad-form-row-elements>
          <v-flex sm6 xs12 d-flex>
            <v-text-field
              v-model="bonusAmount"
              type="number"
              step="0.01"
              :label="$t('voucher.adjust_voucher_balance_action.form.bonus_amount')"
              v-validate="{ decimal: 2 }"
              data-vv-name="Bonus Amount"
              :error-messages="errors.collect('Bonus Amount')"
              name="amount"
              required
              :suffix="currency"
            ></v-text-field>
          </v-flex>
          <v-flex sm6 xs12 d-flex>
            <v-text-field
              v-model="paymentAmount"
              type="number"
              step="0.01"
              :label="$t('voucher.adjust_voucher_balance_action.form.payment_amount')"
              v-validate="{ decimal: 2 }"
              data-vv-name="Payment Amount"
              :error-messages="errors.collect('Payment Amount')"
              name="amount"
              required
              :suffix="currency"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12 d-flex>
            <v-checkbox
              v-model="affectActual"
              :label="$t('voucher.adjust_voucher_balance_action.form.affect_actual')"
              required
              v-validate="{ required: true }"
              data-vv-name="AffectActual"
              name="affect_actual"
              :error-messages="errors.collect('Affect Actual')"
            ></v-checkbox>
          </v-flex>
          <v-flex xs12 d-flex>
            <v-checkbox
              v-model="affectAvailable"
              :label="$t('voucher.adjust_voucher_balance_action.form.affect_available')"
              data-vv-name="AffectAvailable"
              name="affect_available"
              :error-messages="errors.collect('Affect Available')"
            ></v-checkbox>
          </v-flex>
        </v-layout>
      </v-layout>
      <v-layout row wrap>
        <v-flex sm6 xs12 d-flex>
          <v-text-field
            v-model="operationId"
            :label="$t('voucher.adjust_voucher_balance_action.form.operation_id')"
            data-vv-name="OperationId"
            :error-messages="errors.collect('OperationId')"
            name="operationId"
            required
          ></v-text-field>
        </v-flex>
      </v-layout>

      <v-layout row wrap>
        <v-text-field
          v-model="reason"
          :label="$t('voucher.adjust_voucher_balance_action.form.reason') + ' *'"
          required
          v-validate="{ required: true }"
          data-vv-name="Reason"
          name="reason"
          :error-messages="errors.collect('Reason')"
        ></v-text-field>
      </v-layout>

      <modal-confirm
        :show-modal="showConfirmModal"
        :title="$t('voucher.adjust_voucher_balance_action.confirm.title')"
        context="AdjustWalletBalance"
        :text="
          $t('voucher.adjust_voucher_balance_action.confirm.text', {
            voucher_id: voucher.id,
          })
        "
        cancel_text="voucher.adjust_voucher_balance_action.confirm.button_cancel"
        submit_text="voucher.adjust_voucher_balance_action.confirm.button_confirm"
        :submit_working="working"
        @submit="submit"
        @close="closeConfirmModal"
      ></modal-confirm>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';

export default {
  name: 'adjust-wallet-balance-action',
  $_veeValidate: {
    validator: 'new',
  },
  mixins: [actionErrorTrackable, security],
  props: {
    voucher: {
      type: Object,
      required: true,
    },
  },
  data: function() {
    return {
      currency: 'CAD',
      showConfirmModal: false,

      bonusAmount: 0,
      paymentAmount: 0,
      affectAvailable: false,
      affectActual: false,
      reason: '',
      operationId: null,
    };
  },
  computed: {},
  methods: {
    clear() {
      this.resetForm();
      this.$validator.reset();
    },
    resetForm() {
      this.currency = 'CAD';
      this.bonusAmount = 0;
      this.paymentAmount = 0;
      this.affectAvailable = false;
      this.affectActual = false;
      this.reason = '';
      this.operationId = null;
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    success() {
      this.clear();
      this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'voucher.adjust_voucher_balance_action.success' });
      this.closeConfirmModal();
      this.$emit('action');
      this.$emit('close');
    },
    error() {
      this.closeConfirmModal();
    },
    async submit() {
      await this.executeAction(
        {
          action: 'voucher/adjustVoucherBalance',
          name: 'adjustVoucherBalance',
          success: this.success,
          afterError: this.error,
        },
        {
          id: this.voucher.id,
          bonus_amount: this.bonusAmount,
          payment_amount: this.paymentAmount,
          affect_available: this.affectAvailable,
          affect_actual: this.affectActual,
          reason: this.reason,
          related_to_voucher_operation_id: this.operationId === '' ? null : this.operationId,
        },
      );
    },
    async openConfirmModal() {
      const validationComplete = await this.$validator.validateAll();

      const calculateTargetBalance = () => {
        const currentBalance = 0;
        const amount = Number(this.amount);
        return this.operationType === 'credit' ? currentBalance + amount : currentBalance - amount;
      };

      if (validationComplete) {
        this.showConfirmModal = true;
        this.targetBalance = calculateTargetBalance();
      }
    },
    closeConfirmModal() {
      this.showConfirmModal = false;
    },
    applyCurrencyFilter(value) {
      return this.$options.filters.currency(value);
    },
  },
};
</script>
