<template>
  <card-modal
    icon=""
    :title="$t('inventory.adjust_action.title')"
    context="adjustInventory"
    @submit="submit"
    @cancel="cancel"
    cancel_text="inventory.adjust_action.button_cancel"
    submit_text="inventory.adjust_action.button_save"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <template>
          <v-layout row pad-form-row-elements>
            <v-flex sm3 xs12>
              <v-text-field
                autofocus
                ref="adjust_amount"
                name="adjust_amount"
                v-model="adjust_amount"
                :label="$t('inventory.adjust_action.adjust_amount') + '*'"
                append-icon="fas fa-dollar-sign"
                v-validate="{
                  required: true,
                  decimal: 2,
                  is_not: 0,
                  between: [-currentInventory.available_amount, 10000000 - currentInventory.current_amount],
                }"
                :error-messages="errors.collect('adjust_amount')"
                data-vv-name="adjust_amount"
                :data-vv-as="$t('inventory.adjust_action.adjust_amount')"
                data-test="adjust_inventory--adjust_amount"
              ></v-text-field>
            </v-flex>
            <v-flex sm3 xs12>
              <h5>{{ $t('inventory.adjust_action.new_current_amount') }}</h5>
              <p>{{ this.newTotalAmount | currency | dollarSignI18n }}</p>
            </v-flex>
            <v-flex sm3 xs12>
              <h5>{{ $t('inventory.adjust_action.new_available_amount') }}</h5>
              <p>{{ this.newAvailableAmount | currency | dollarSignI18n }}</p>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex sm12 xs12>
              <v-text-field
                ref="reason"
                name="reason"
                v-model="reason"
                :label="$t('inventory.adjust_action.reason') + '*'"
                v-validate="{
                  required: true,
                  max: 1024,
                }"
                :error-messages="errors.collect('reason')"
                data-vv-name="reason"
                :data-vv-as="$t('inventory.adjust_action.reason')"
                data-test="adjust_inventory--reason"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </template>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'adjust-inventory-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    inventory: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentInventory: {},
      adjust_amount: 0,
      reason: '',
    };
  },
  async mounted() {
    this.currentInventory = _.cloneDeep(this.inventory);
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    newTotalAmount() {
      return (this.currentInventory.current_amount + Number(this.adjust_amount) || 0).toFixed(2);
    },
    newAvailableAmount() {
      return (this.currentInventory.available_amount + Number(this.adjust_amount) || 0).toFixed(2);
    },
  },
  methods: {
    clear() {
      this.currentInventory = {};
      this.$validator.reset();
    },
    success() {
      this.clear();
      this.$emit('updated');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();
      if (validationComplete) {
        await this.executeAction(
          {
            action: 'inventory/adjustInventory',
            name: 'adjustInventory',
            success: this.success,
          },
          { id: this.inventory.id, adjust_amount: this.adjust_amount, reason: this.reason },
        );
      }
    },
  },
};
</script>
