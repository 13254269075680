<template>
  <card :title="$t('branding.publishers')" icon="">
    <template slot="headerZoneRight">
      <label-text
        class="text-xs-center"
        :label="`${$t('branding.details.publishers_section.count')}`"
        :value="brandingPublishersItemCount || '0'"
      ></label-text>
    </template>
    <template slot="content">
      <branding-publishers-table v-if="brandingPublishersItemCount > 0" :items="brandingPublishers" :id="branding.id"></branding-publishers-table>
      <label-none v-if="brandingPublishersItemCount === 0">{{ $t('system.none') }}</label-none>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import BrandingPublishersTable from './BrandingPublishersTable';
import { mapGetters } from 'vuex';

export default {
  name: 'branding-publishers',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { BrandingPublishersTable },
  data() {
    return {
      pagination: 1,
    };
  },
  async created() {
    await this.fetchBrandingPublishers();
  },
  watch: {
    async branding() {
      await this.fetchBrandingPublishers();
    },
    brandingPublishersPageNumber() {
      this.pagination = this.brandingPublishersPageNumber;
    },
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'branding/changeBrandingPublishersPage',
        },
        {
          id: this.branding.id,
          page: newValue,
        },
      );
    },
  },
  computed: {
    ...mapGetters('branding', [
      'branding',
      'brandingPublishers',
      'brandingPublishersPageCount',
      'brandingPublishersPageNumber',
      'brandingPublishersItemCount',
    ]),
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    async clear() {
      this.$validator.reset();
    },
    async fetchBrandingPublishers() {
      await this.executeAction(
        { action: 'branding/listBrandingPublishers' },
        {
          id: this.branding.id,
          page: this.brandingPublishersPageNumber,
        },
      );
    },
  },
};
</script>
