<template>
  <card-modal
    icon=""
    :title="$t('bank_account.update_action.title')"
    context="updateBankAccount"
    @submit="submit"
    @cancel="cancel"
    cancel_text="bank_account.update_action.button_cancel"
    submit_text="bank_account.update_action.button_save"
    data-test="bank-account--update-modal"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-text-field
          autofocus
          :label="$t('bank_account.update_action.business_name') + '*'"
          v-model="currentBankAccount.business_name"
          name="business_name"
          v-validate="'required|max:29'"
          :error-messages="errors.collect('business_name')"
          data-vv-name="business_name"
          maxlength="29"
          :data-vv-as="$t('bank_account.update_action.business_name')"
          data-test="update-bank-account--business-name"
          required
        ></v-text-field>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'update-bank-account-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  data() {
    return {
      currentBankAccount: {},
    };
  },
  props: {
    bankAccount: {
      type: Object,
      required: true,
    },
  },
  async created() {
    this.currentBankAccount = Object.assign(
      {},
      {
        business_name: this.bankAccount.business_name,
        key: this.bankAccount.key,
      },
    );
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    clear() {
      this.currentBankAccount = {};
      this.$validator.reset();
    },
    success() {
      this.clear();
      this.$emit('updated');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        const { key, ...payload } = this.currentBankAccount;

        await this.executeAction(
          {
            action: 'bankAccount/updateBankAccount',
            name: 'updateBankAccount',
            success: this.success,
          },
          { key, payload },
        );
      }
    },
  },
};
</script>
