<template>
  <card-modal
    icon=""
    :title="$t('user.edit_action.title')"
    context="updateAccount"
    @submit="submit"
    @cancel="cancel"
    cancel_text="user.edit_action.button_cancel"
    submit_text="user.edit_action.button_save"
    data-test="user--edit-modal"
  >
    <form @submit.prevent="submit" slot="content">
      <alert-warning v-if="account.kyc_lock" :text="$t('user.edit_action.kyc_lock')"></alert-warning>

      <h4>
        {{ $t('user.edit_action.personal_information') }}
      </h4>

      <v-layout v-if="!account.kyc_lock" row wrap pad-form-row-elements>
        <v-flex xs12 sm6>
          <v-text-field
            autofocus
            :label="$t('user.edit_action.fields.first_name.label')"
            name="name"
            v-validate="'max:50'"
            :error-messages="errors.collect('first_name')"
            v-model="currentAccount.first_name"
            data-vv-name="first_name"
            maxlength="50"
            :data-vv-as="$t('user.edit_action.fields.first_name.label')"
            data-test="add-user--name-fr"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm6>
          <v-text-field
            :label="$t('user.edit_action.fields.last_name.label')"
            name="name"
            v-validate="'max:50'"
            :error-messages="errors.collect('last_name')"
            v-model="currentAccount.last_name"
            data-vv-name="last_name"
            maxlength="50"
            :data-vv-as="$t('user.edit_action.fields.last_name.label')"
            data-test="add-user--name-fr"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row wrap pad-form-row-elements>
        <v-flex xs8 sm6>
          <v-select
            name="language"
            v-model="currentAccount.language"
            :error-messages="errors.collect('language')"
            data-vv-name="language"
            :data-vv-as="$t('user.edit_action.fields.language.label')"
            :items="languageOptions"
            item-text="name"
            item-value="abbr"
            :label="$t('user.edit_action.fields.language.label')"
          ></v-select>
        </v-flex>

        <v-flex xs8 sm6>
          <v-select
            name="gender"
            v-model="currentAccount.gender"
            :error-messages="errors.collect('gender')"
            data-vv-name="gender"
            :data-vv-as="$t('user.edit_action.fields.gender.label')"
            :items="genderOptions"
            item-text="name"
            item-value="abbr"
            :label="$t('user.edit_action.fields.gender.label')"
          ></v-select>
        </v-flex>
      </v-layout>
      <v-layout v-if="!account.kyc_lock" row wrap pad-form-row-elements>
        <v-flex xs12 sm6>
          <v-text-field
            name="year_of_birth"
            :label="$t('user.edit_action.fields.year_of_birth.label')"
            data-vv-name="year_of_birth"
            :data-vv-as="$t('user.edit_action.fields.year_of_birth.label')"
            v-model="currentAccount.year_of_birth"
            :error-messages="errors.collect('year_of_birth')"
            v-maska="{ mask: '####' }"
            pattern="[0-9][0-9][0-9][0-9]"
            v-validate="{ max: 4, min_value: new Date().getFullYear() - 150, max_value: new Date().getFullYear() }"
          />
        </v-flex>
        <v-flex xs8 sm6></v-flex>
      </v-layout>
      <h4>
        {{ $t('user.edit_action.contact_information') }}
      </h4>

      <v-layout row wrap pad-form-row-elements>
        <!-- NOTE (MAA) : Custom phone validation error message only work with one validation type. -->
        <v-flex xs12 sm6>
          <v-text-field
            name="phone"
            append-icon="fa-phone"
            v-model="currentAccount.phone"
            v-maska="'1-###-###-####'"
            v-validate="{ regex: /^1-[0-9]{3}-[0-9]{3}-[0-9]{4}$/ }"
            :label="$t('user.edit_action.fields.phone.label')"
            :error-messages="errors.has('phone') ? $t('user.edit_action.fields.phone.error') : null"
            data-vv-name="phone"
            :data-vv-as="$t('user.edit_action.fields.phone.label')"
            :hint="$t('user.edit_action.fields.phone.hint')"
          ></v-text-field>
        </v-flex>

        <v-flex xs12 sm6>
          <!-- NOTE (MAA) : Custom phone validation error message only work with one validation type. -->
          <v-text-field
            name="mobile"
            append-icon="fa-mobile"
            v-model="currentAccount.mobile"
            v-maska="'1-###-###-####'"
            v-validate="{ regex: /^1-[0-9]{3}-[0-9]{3}-[0-9]{4}$/ }"
            :label="$t('user.edit_action.fields.mobile.label')"
            :error-messages="errors.has('mobile') ? $t('user.edit_action.fields.mobile.error') : null"
            data-vv-name="mobile"
            :data-vv-as="$t('user.edit_action.fields.mobile.label')"
            :hint="$t('user.edit_action.fields.mobile.hint')"
          ></v-text-field>
        </v-flex>
      </v-layout>

      <h4>
        {{ $t('user.edit_action.address') }}
      </h4>

      <v-layout row wrap pad-form-row-elements>
        <v-flex xs12>
          <v-text-field
            name="street"
            v-model="currentAccount.address.street"
            v-validate="'max:50'"
            :label="$t(`user.edit_action.fields.street.label`)"
            :error-messages="errors.collect('street')"
            data-vv-name="street"
            :data-vv-as="$t(`user.edit_action.fields.street.label`)"
            counter
            maxlength="50"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row wrap pad-form-row-elements>
        <v-flex xs12 sm6>
          <v-text-field
            name="country"
            v-model="currentAccount.address.country"
            v-validate="'max:2'"
            :label="$t(`user.edit_action.fields.country.label`)"
            :error-messages="errors.collect('country')"
            data-vv-name="country"
            :data-vv-as="$t(`user.edit_action.fields.country.label`)"
            counter
            maxlength="2"
          ></v-text-field>
        </v-flex>

        <v-flex xs12 sm6>
          <v-text-field
            name="state"
            v-model="currentAccount.address.state"
            v-validate="'max:2'"
            :label="$t(`user.edit_action.fields.state.label`)"
            :error-messages="errors.collect('state')"
            data-vv-name="state"
            :data-vv-as="$t(`user.edit_action.fields.state.label`)"
            counter
            maxlength="2"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row wrap pad-form-row-elements>
        <v-flex xs12 sm6>
          <v-text-field
            name="city"
            v-model="currentAccount.address.city"
            v-validate="'max:50'"
            :label="$t(`user.edit_action.fields.city.label`)"
            :error-messages="errors.collect('city')"
            data-vv-name="city"
            :data-vv-as="$t(`user.edit_action.fields.city.label`)"
            counter
            maxlength="50"
          ></v-text-field>
        </v-flex>

        <v-flex xs12 sm6>
          <v-text-field
            name="zip"
            counter
            v-mask-zip
            v-model="currentAccount.address.zip"
            v-validate="'max:50'"
            :label="$t(`user.edit_action.fields.zip.label`)"
            :error-messages="errors.collect('zip')"
            data-vv-name="zip"
            :data-vv-as="$t(`user.edit_action.fields.zip.label`)"
            maxlength="50"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <h4>
        {{ $t('user.edit_action.settings_information') }}
      </h4>
      <v-layout row pad-form-row-elements>
        <v-flex xs12 sm6>
          <v-checkbox
            v-model="currentAccount.settings.alerts"
            :label="$t('user.edit_action.fields.settings_alerts.label')"
            data-vv-name="alerts"
            name="alerts"
            :error-messages="errors.collect('alerts')"
          ></v-checkbox>
        </v-flex>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import moment from 'moment';
import datetimeFactory from 'datetime-factory';

export default {
  name: 'edit-user-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    account: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.currentAccount.id = this.account.id;
    this.currentAccount.cardholder_id = this.account.cardholder_id;
    this.currentAccount.cardholder_key = this.account.cardholder_key;
    this.currentAccount.card_application_id = this.account.card_application_id;
    this.currentAccount.language = this.account.language;
    this.currentAccount.creation_date = this.account.creation_date;
    if (!this.account.kyc_lock) {
      this.currentAccount.first_name = this.account.first_name;
      this.currentAccount.year_of_birth = this.account.year_of_birth;
      this.currentAccount.last_name = this.account.last_name;
    }
    this.currentAccount.gender = this.account.gender;
    this.currentAccount.phone = this.account.phone;
    this.currentAccount.mobile = this.account.mobile;

    this.currentAccount.address.street = _.get(this.account, 'address.street');
    this.currentAccount.address.city = _.get(this.account, 'address.city');
    this.currentAccount.address.state = _.get(this.account, 'address.state');
    this.currentAccount.address.country = _.get(this.account, 'address.country');
    this.currentAccount.address.zip = _.get(this.account, 'address.zip');

    this.currentAccount.settings.alerts = _.get(this.account, 'settings.alerts');

    this.currentAccount.contact = {
      email: {
        value: _.get(this.account, 'contact.email.value'),
        status: _.get(this.account, 'contact.email.status'),
      },
    };
  },
  data() {
    return {
      showBirthDatePickerCalendar: false,
      currentAccount: { address: {}, settings: {} },
      countryOptions: [{ name: this.$t('country.canada'), abbr: 'CA' }],
      stateOptions: [
        { name: this.$t('state.alberta'), abbr: 'AB' },
        { name: this.$t('state.british_columbia'), abbr: 'BC' },
        { name: this.$t('state.manitoba'), abbr: 'MB' },
        { name: this.$t('state.new_brunswick'), abbr: 'NB' },
        { name: this.$t('state.newfoundland_labrador'), abbr: 'NL' },
        { name: this.$t('state.nova_scotia'), abbr: 'NS' },
        { name: this.$t('state.northwest_territories'), abbr: 'NT' },
        { name: this.$t('state.nunavut'), abbr: 'NU' },
        { name: this.$t('state.ontario'), abbr: 'ON' },
        { name: this.$t('state.prince_edward_island'), abbr: 'PE' },
        { name: this.$t('state.quebec'), abbr: 'QC' },
        { name: this.$t('state.saskatchewan'), abbr: 'SK' },
        { name: this.$t('state.yukon'), abbr: 'YT' },
      ],
      languageOptions: [
        { name: this.$t('language.french'), abbr: 'fr' },
        { name: this.$t('language.english'), abbr: 'en' },
      ],
      genderOptions: [
        { name: this.$t('gender.female'), abbr: 'female' },
        { name: this.$t('gender.male'), abbr: 'male' },
        { name: this.$t('gender.non_binary'), abbr: 'non_binary' },
        { name: this.$t('gender.undefined'), abbr: null },
      ],
    };
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    clear() {
      //this.currentAccount = { address: {} };
      this.$validator.reset();
    },
    success() {
      this.clear();
      this.$emit('action');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    maxDateLimit() {
      return new moment(datetimeFactory.now()).format('YYYY-MM-DD');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        const payload = this.currentAccount;

        await this.executeAction(
          {
            action: 'account/updateAccount',
            name: 'updateAccount',
            success: this.success,
          },
          payload,
        );
      }
    },
  },
};
</script>
