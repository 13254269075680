<template>
  <card :title="$t('store.details.business_section.title')" icon="">
    <template slot="headerZoneRight">
      <label-text v-if="showAddLinkBusinessFeature()" :label="`${$t('store.details.business_section.action')}`" value="">
        <link-secondary-action v-if="!store.business_id" slot="labelTextLink" @click="openAddLinkBusinessModal">
          {{ $t('store.details.business_section.link') }}
        </link-secondary-action>
        <link-secondary-action v-if="store.business_id" slot="labelTextLink" @click="openUnlinkBusinessModal">
          {{ $t('store.details.business_section.unlink') }}
        </link-secondary-action>
      </label-text>
    </template>
    <template slot="content">
      <modal-action :show-modal="showAddLinkBusinessModal" avoid-rendering
        ><link-business-action :store-obj="store" @close="closeAddLinkBusinessModal" @linked="linkBusinessSuccess"></link-business-action
      ></modal-action>
      <store-business-table v-if="business" :item="business" @store_business_update_action="openAddLinkBusinessModal"></store-business-table>
      <label-none v-if="!business">{{ $t('system.none') }}</label-none>
      <modal-confirm
        v-if="business"
        :show-modal="showUnlinkBusinessModal"
        :title="$t(`store.details.business_section.unlink_action.title`)"
        context="UnlinkBusiness"
        :text="
          $t(`store.details.business_section.unlink_action.text`, {
            businessName: business.name,
            storeName: store.name,
          })
        "
        :cancel_text="`store.details.business_section.unlink_action.button_cancel`"
        :submit_text="`store.details.business_section.unlink_action.button_submit`"
        :submit_working="working"
        @submit="unlinkBusinessSuccess"
        @close="closeUnlinkBusinessModal"
      ></modal-confirm>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import LinkBusinessAction from '../actions/LinkBusinessAction';
import StoreBusinessTable from './StoreBusinessTable';
import { mapGetters } from 'vuex';

export default {
  name: 'store-business',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { StoreBusinessTable, LinkBusinessAction },
  data() {
    return {
      business: null,
      showAddLinkBusinessModal: false,
      showUnlinkBusinessModal: false,
    };
  },
  computed: {
    ...mapGetters('store', ['store']),
    ...mapGetters('ui', ['currentLocale']),
  },
  watch: {
    async store() {
      await this.fetchBusiness();
    },
  },
  methods: {
    openAddLinkBusinessModal() {
      this.showAddLinkBusinessModal = true;
    },
    closeAddLinkBusinessModal() {
      this.showAddLinkBusinessModal = false;
    },
    openUnlinkBusinessModal() {
      this.showUnlinkBusinessModal = true;
    },
    closeUnlinkBusinessModal() {
      this.showUnlinkBusinessModal = false;
    },
    async unlinkBusinessSuccess() {
      this.showUnlinkBusinessModal = false;
      await this.executeAction(
        {
          action: 'store/unlinkBusinessFromStore',
          name: 'unlinkBusinessFromStore',
        },
        { id: this.store.id },
      );
      await this.fetchBusiness();
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'store.details.business_section.unlink_action.success' });
    },
    async linkBusinessSuccess() {
      await this.fetchBusiness();
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'store.details.business_section.link_action.success' });
      this.closeAddLinkBusinessModal();
    },
    showAddLinkBusinessFeature() {
      return this.canManageOffer(); // TODO: change permission
    },
    async fetchBusiness() {
      if (this.store.business_id) {
        this.business = await this.executeAction({ action: 'business/getBusiness' }, this.store.business_id);
      } else {
        this.business = null;
      }
    },
  },
};
</script>
