<template>
  <div>
    <table class="responsive-table">
      <thead>
        <tr>
          <td>{{ $t('workstation.workstation_registration_request_table.fields.id') }}</td>
          <td>{{ $t('workstation.workstation_registration_request_table.fields.token') }}</td>
          <td>{{ $t('workstation.workstation_registration_request_table.fields.date_created') }}</td>
          <td>{{ $t('workstation.workstation_registration_request_table.fields.token_expiration_date') }}</td>
          <td>{{ $t('workstation.workstation_registration_request_table.fields.status') }}</td>
          <td>{{ $t('workstation.workstation_registration_request_table.fields.rejected_reason') }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>
            <h5>{{ $t('workstation.workstation_registration_request_table.fields.id') }}</h5>
            <router-link
              :to="{
                name: 'workstation_registration_request_details',
                params: { workstationId: item.workstation_id, workstationRegistrationRequestId: item.id },
              }"
              >{{ item.id }}</router-link
            >
          </td>
          <td>
            <h5>{{ $t('workstation.workstation_registration_request_table.fields.token') }}</h5>
            <span>
              {{ item.token }}
              <button class="clipboard" v-clipboard:copy="item.token">
                <v-icon small>file_copy</v-icon>
              </button>
            </span>
          </td>
          <td>
            <h5>{{ $t('workstation.workstation_registration_request_table.fields.date_created') }}</h5>
            {{ item.creation_date | formatShortDateInverted }}
          </td>
          <td>
            <h5>{{ $t('workstation.workstation_registration_request_table.fields.token_expiration_date') }}</h5>
            {{ item.token_expiration_date | formatShortDateInverted({ withAgo: true }) }}
          </td>
          <td>
            <h5>{{ $t('workstation.workstation_registration_request_table.fields.status') }}</h5>
            <workstation-registration-status-view slot="labelTextLink" :status="item.status" />
          </td>
          <td>
            <h5>{{ $t('workstation.workstation_registration_request_table.fields.rejected_reason') }}</h5>
            <span v-if="item.rejected_reason">{{ item.rejected_reason }}</span>
            <span v-else class="info-not-available">{{ $t('system.na') }}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-xs-center">
      <v-pagination
        v-if="listWorkstationRegistrationRequestsPageCount > 1"
        v-model="pagination"
        :length="listWorkstationRegistrationRequestsPageCount"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';
import WorkstationRegistrationStatusView from '@/components/workstation-registration/WorkstationRegistrationStatusView';

export default {
  name: 'workstation-registration-request-table',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { WorkstationRegistrationStatusView },
  props: {
    partnerId: {
      type: String,
    },
    workstationId: {
      type: String,
    },
    items: {
      type: Array,
    },
  },
  data() {
    return {
      pagination: 1,
    };
  },
  mounted() {
    this.pagination = this.listWorkstationRegistrationRequestsPageNumber;
  },
  watch: {
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'partnerSecurity/listWorkstationRegistrationRequests',
        },
        {
          partner_id: this.workstation.partner_id,
          workstation_id: this.workstation.id,
          page: newValue,
        },
      );
    },
  },
  computed: {
    ...mapGetters('partnerSecurity', ['listWorkstationRegistrationRequestsPageCount', 'listWorkstationRegistrationRequestsPageNumber']),
  },
};
</script>
