<template>
  <view-main title="page_title.destination_details">
    <two-levels-breadcrumbs slot="content" :top-label="$t('navigation.destinations')" :to="{ name: 'destinations' }" :last-label="destination.name" />
    <destination-information slot="content" />
    <destination-location v-if="destination && destination.address" slot="content" />
    <destination-stores slot="content" />
  </view-main>
</template>

<script>
import { actionErrorTrackable, renderErrorTrackable } from '@/mixins';
import DestinationInformation from './detail/DestinationInformation.vue';
import DestinationLocation from './detail/DestinationLocation.vue';
import DestinationStores from './detail/DestinationStores.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'destination-details-view',
  mixins: [renderErrorTrackable, actionErrorTrackable],
  components: { DestinationInformation, DestinationStores, DestinationLocation },
  props: {
    destinationId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('destination', ['destination']),
  },
  async mounted() {
    await this.fetch();
  },
  watch: {
    async destinationId() {
      return this.fetch();
    },
  },
  methods: {
    async fetch() {
      const destination = await this.executeAction({ action: 'destination/getDestination' }, this.destinationId);
      if (!destination) {
        this.$router.replace({ name: 'notFound' });
      }
    },
  },
};
</script>
