<template>
  <card-modal
    icon=""
    :title="$t('space.partner_establishment_table.add_action.title')"
    context="AddPartnerEstablishmentAction"
    @submit="submit"
    @cancel="cancel"
    :submit_working="working"
    cancel_text="space.partner_establishment_table.add_action.cancel"
    submit_text="space.partner_establishment_table.add_action.submit"
  >
    <form @submit.prevent="submit" slot="content">
      <h-autocomplete
        v-model="business_id"
        :label="$t('space.partner_establishment_table.add_action.business_id') + ' *'"
        :error-messages="errors.collect('business_id')"
        name="business_id"
        data-vv-name="business_id"
        data-vv-as="business_id"
        search-action="business/autocompleteSearch"
        data-test="add-partner_establishment--business"
      >
      </h-autocomplete>
      <v-layout row wrap pad-form-row-elements>
        <v-select
          :items="canadianTimezone"
          name="timezone"
          v-validate="'required'"
          v-model="timezone"
          :label="$t('space.partner_establishment_table.add_action.timezone') + ' *'"
          :error-messages="errors.collect('timezone')"
          data-vv-name="timezone"
          :data-vv-as="$t('space.partner_establishment_table.add_action.timezone')"
          item-text="name"
          item-value="id"
          data-test="add-partner_establishment--timezone"
        ></v-select>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import moment from 'moment-timezone';
import { actionErrorTrackable } from '@/mixins';
import CANADIAN_TIMEZONES from '@/tools/canadian-timezones';

export default {
  name: 'add-partner-establishment-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    partnerId: {
      type: String,
    },
  },
  data() {
    return {
      business_id: null,
      timezone: null,
    };
  },
  computed: {
    canadianTimezone() {
      return CANADIAN_TIMEZONES.map((zone) => {
        return {
          name: `${zone} (${moment()
            .tz(zone)
            .format('Z z')})`,
          id: zone,
        };
      });
    },
  },
  methods: {
    clear() {
      this.business_id = null;
      this.timezone = null;
      this.$validator.reset();
    },
    success() {
      this.clear();
      this.$emit('success');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    buildPayload() {
      return {
        partner_id: this.partnerId,
        business_id: this.business_id,
        timezone: this.timezone,
      };
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        await this.executeAction(
          {
            action: 'space/createPartnerEstablishment',
            name: 'AddPartnerEstablishmentAction',
            success: this.success,
          },
          this.buildPayload(),
        );
      }
    },
  },
};
</script>
