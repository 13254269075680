<template>
  <card-modal
    icon="-alt"
    :title="$t('store.details.card_acceptors_section.create_action.title')"
    context="createStoreCardAcceptor"
    @submit="submit"
    @cancel="cancel"
    cancel_text="store.details.card_acceptors_section.create_action.button_cancel"
    submit_text="store.details.card_acceptors_section.create_action.button_save"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <h4>{{ $t('store.details.card_acceptors_section.static') }}</h4>
        <v-text-field
          autofocus
          :label="$t('store.details.card_acceptors_section.create_action.merchant_id')"
          name="merchant_id"
          v-validate="'max:50'"
          :error-messages="errors.collect('merchant_id')"
          v-model="storeCardAcceptor.merchant_id"
          data-vv-name="merchant_id"
          maxlength="50"
          :data-vv-as="$t('store.details.card_acceptors_section.create_action.merchant_id')"
        ></v-text-field>
        <v-text-field
          :label="$t('store.details.card_acceptors_section.create_action.merchant_name')"
          name="merchant_name"
          v-validate="'max:50'"
          :error-messages="errors.collect('merchant_name')"
          v-model="storeCardAcceptor.merchant_name"
          data-vv-name="merchant_name"
          maxlength="50"
          :data-vv-as="$t('store.details.card_acceptors_section.create_action.merchant_name')"
        ></v-text-field>
        <v-text-field
          :label="$t('store.details.card_acceptors_section.create_action.terminal_id')"
          name="terminal_id"
          v-validate="'max:50'"
          :error-messages="errors.collect('terminal_id')"
          v-model="storeCardAcceptor.terminal_id"
          data-vv-name="terminal_id"
          maxlength="50"
          :data-vv-as="$t('store.details.card_acceptors_section.create_action.terminal_id')"
        ></v-text-field>
        <v-text-field
          :label="$t('store.details.card_acceptors_section.create_action.city')"
          name="city"
          v-validate="'max:50'"
          :error-messages="errors.collect('city')"
          v-model="storeCardAcceptor.city"
          data-vv-name="city"
          maxlength="50"
          :data-vv-as="$t('store.details.card_acceptors_section.create_action.city')"
        ></v-text-field>
        <v-text-field
          :label="$t('store.details.card_acceptors_section.create_action.state')"
          name="state"
          v-validate="'max:50'"
          :error-messages="errors.collect('state')"
          v-model="storeCardAcceptor.state"
          data-vv-name="state"
          maxlength="50"
          :data-vv-as="$t('store.details.card_acceptors_section.create_action.state')"
        ></v-text-field>
        <v-text-field
          :label="$t('store.details.card_acceptors_section.create_action.country')"
          name="country"
          v-validate="'max:50'"
          :error-messages="errors.collect('country')"
          v-model="storeCardAcceptor.country"
          data-vv-name="country"
          maxlength="50"
          :data-vv-as="$t('store.details.card_acceptors_section.create_action.country')"
        ></v-text-field>
        <h4>{{ $t('store.details.card_acceptors_section.dynamic') }}</h4>
        <v-text-field
          :label="$t('store.details.card_acceptors_section.create_action.merchant_id_starts_with')"
          name="merchant_id_starts_with"
          v-validate="'max:50'"
          :error-messages="errors.collect('merchant_id_starts_with')"
          v-model="storeCardAcceptor.merchant_id_starts_with"
          data-vv-name="merchant_id_starts_with"
          maxlength="50"
          :data-vv-as="$t('store.details.card_acceptors_section.create_action.merchant_id_starts_with')"
        ></v-text-field>
        <v-text-field
          :label="$t('store.details.card_acceptors_section.create_action.merchant_name_starts_with')"
          name="merchant_name_starts_with"
          v-validate="'max:50'"
          :error-messages="errors.collect('merchant_name_starts_with')"
          v-model="storeCardAcceptor.merchant_name_starts_with"
          data-vv-name="merchant_name_starts_with"
          maxlength="50"
          :data-vv-as="$t('store.details.card_acceptors_section.create_action.merchant_name_starts_with')"
        ></v-text-field>
        <v-text-field
          :label="$t('store.details.card_acceptors_section.create_action.merchant_name_contains')"
          name="merchant_name_contains"
          v-validate="'max:50'"
          :error-messages="errors.collect('merchant_name_contains')"
          v-model="storeCardAcceptor.merchant_name_contains"
          data-vv-name="merchant_name_contains"
          maxlength="50"
          :data-vv-as="$t('store.details.card_acceptors_section.create_action.merchant_name_contains')"
        ></v-text-field>
        <v-checkbox
          v-model="storeCardAcceptor.check_transaction_existence"
          :label="$t('store.details.card_acceptors_section.update_action.check_transaction_existence')"
          data-vv-name="check_transaction_existence"
          name="check_transaction_existence"
          :error-messages="errors.collect('check_transaction_existence')"
        ></v-checkbox>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapActions } from 'vuex';
import errorTypes from '@/utils/error/error-types';

export default {
  name: 'create-store-card-acceptor-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    storeId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      storeCardAcceptor: {
        check_transaction_existence: true,
      },
    };
  },
  methods: {
    ...mapActions('ui', ['addError', 'clearAllError', 'clearError']),
    clear() {
      this.storeCardAcceptor = {
        check_transaction_existence: true,
      };
      this.$validator.reset();
    },
    success(response) {
      this.clear();
      this.$emit('created');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      let validationComplete = await this.$validator.validateAll();

      if (
        !this.storeCardAcceptor.merchant_id &&
        !this.storeCardAcceptor.merchant_name &&
        !this.storeCardAcceptor.terminal_id &&
        !this.storeCardAcceptor.country &&
        !this.storeCardAcceptor.state &&
        !this.storeCardAcceptor.city &&
        !this.storeCardAcceptor.merchant_id_starts_with &&
        !this.storeCardAcceptor.merchant_name_starts_with &&
        !this.storeCardAcceptor.merchant_name_contains
      ) {
        validationComplete = false;
        this.addError({
          contextName: 'createStoreCardAcceptor',
          error: new errorTypes.ValidationError('card_acceptor.must.specify_one_rule', 'Specify at least one rule'),
        });
      }

      if (validationComplete) {
        const payload = Object.assign({}, this.storeCardAcceptor, { store_id: this.storeId });

        await this.executeAction(
          {
            action: 'store/createStoreCardAcceptor',
            name: 'createStoreCardAcceptor',
            success: this.success,
          },
          payload,
        );
      }
    },
  },
};
</script>
