export default ({ Vue, app }) => {
  Vue.filter('formatPhone', (phone) => {
    if (phone) {
      const cleaned = ('' + phone).replace(/\D/g, '');
      const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
      if (match && match.length >= 4) {
        return match[1] + '-' + match[2] + '-' + match[3] + '-' + match[4];
      }
      return phone;
    } else {
      return app.i18n.t('system.na');
    }
  });
};
