import Vue from 'vue';
import { safeDispatcher } from '@/utils/context/context-helper';
import _ from 'lodash';

const defaultPage = 1;
const purseOperationsPaginationLimit = 10;

export default {
  namespaced: true,
  state: {
    purse: {},
    openPurse: {},
    purseOperations: [],
    listPurseOperations: {
      pageNumber: defaultPage,
      pageCount: 0,
      itemCount: 0,
    },
  },
  getters: {
    purse: (state) => state.purse,
    openPurse: (state) => state.openPurse,
    purseOperations: (state) => state.purseOperations,
    purseOperationsPageCount: (state) => state.listPurseOperations.pageCount,
    purseOperationsItemCount: (state) => state.listPurseOperations.itemCount,
    purseOperationsPageNumber: (state) => state.listPurseOperations.pageNumber,
  },
  mutations: {
    purse(state, purse) {
      if (!purse) {
        purse = {};
      }
      state.purse = purse;
    },
    openPurse(state, openPurse) {
      if (!openPurse) {
        openPurse = {};
      }
      state.openPurse = openPurse;
    },
    purseOperations(state, purseOperations) {
      if (!purseOperations) {
        purseOperations = [];
      }
      state.purseOperations = purseOperations;
    },
    purseOperationsPageCount(state, pageCount) {
      state.listPurseOperations.pageCount = pageCount;
    },
    purseOperationsItemCount(state, itemCount) {
      state.listPurseOperations.itemCount = itemCount;
    },
    purseOperationsPageNumber(state, pageNumber) {
      state.listPurseOperations.pageNumber = pageNumber;
    },
  },
  actions: {
    async listPurseOperations({ commit }, { id, page, limit, excluded_type }) {
      const purseService = Vue.prototype.$services.purse;

      const thePage = page || state.listPurseOperations.pageNumber || defaultPage;
      const theLimit = limit || purseOperationsPaginationLimit;

      const results = await purseService.listPurseOperations(id, thePage, theLimit, excluded_type);

      commit('purseOperations', results.items);
      commit('purseOperationsPageNumber', thePage);
      commit('purseOperationsPageCount', results.page_count);
      commit('purseOperationsItemCount', results.item_count);
    },
    async getPurse({ commit, dispatch }, id) {
      const purseService = Vue.prototype.$services.purse;
      const purse = await purseService.getPurse(id);

      commit('purse', purse);

      return purse;
    },
    async getOpenPurse({ commit, dispatch }, accountId) {
      const purseService = Vue.prototype.$services.purse;
      const openPurse = await purseService.getOpenPurse(accountId);

      commit('openPurse', openPurse);

      return openPurse;
    },
    async createPurse({ commit }, params) {
      const purseService = Vue.prototype.$services.purse;
      return purseService.createPurse(params);
    },
    async blockPurse({ commit }, id) {
      const purseService = Vue.prototype.$services.purse;
      return purseService.blockPurse(id);
    },
    async activatePurse({ commit }, id) {
      const purseService = Vue.prototype.$services.purse;
      return purseService.activatePurse(id);
    },
    async loadOpenInterac({ commit, dispatch }, params) {
      const defaultDispatch = safeDispatcher(dispatch);
      const purseService = Vue.prototype.$services.purse;

      const payload = {
        amount: {
          value: params.amount,
          currency: params.currency ? params.currency : 'CAD',
        },
        source_of_funds: {
          type: 'etransfer',
          network: 'internal',
        },
      };
      _.set(payload, 'source_of_funds.note', params.note);
      _.set(payload, 'source_of_funds.provider.reference_id', params.reference_id);
      _.set(payload, 'source_of_funds.provider.reference_date', params.reference_date);

      await purseService.loadOpenPurse(params.account_id, payload);
      const updatedOpenPurse = await purseService.getOpenPurse(params.account_id);

      await defaultDispatch('ui/showSuccessSnackBar', { text: 'purse.load_open_purse_from_interac_action.success' });
      commit('openPurse', updatedOpenPurse);

      return updatedOpenPurse;
    },
    async loadInterac({ commit, dispatch }, params) {
      const defaultDispatch = safeDispatcher(dispatch);
      const purseService = Vue.prototype.$services.purse;

      const payload = {
        amount: {
          value: params.amount,
          currency: params.currency ? params.currency : 'CAD',
        },
        source_of_funds: {
          type: 'etransfer',
          network: 'internal',
        },
      };

      _.set(payload, 'source_of_funds.note', params.note);
      _.set(payload, 'source_of_funds.provider.reference_id', params.reference_id);
      _.set(payload, 'source_of_funds.provider.reference_date', params.reference_date);

      const updatedPurse = await purseService.loadCredit(params.purse_id, payload);

      await defaultDispatch('ui/showSuccessSnackBar', { text: 'purse.load_purse_from_interac_action.success' });
      commit('purse', updatedPurse);

      return updatedPurse;
    },
    async adjustPurse(
      { dispatch },
      { operation_type, purse_id, amount, currency = 'CAD', reason, skip_webhook, skip_card_sync, operation_group_id },
    ) {
      validateOperationTypes(operation_type);
      const purseService = Vue.prototype.$services.purse;

      const payload = {
        amount: {
          value: amount,
          currency,
        },
        reason,
        skip_webhook,
        skip_card_sync,
      };

      if (haveTextContent(operation_group_id)) {
        payload.operation_group_id = operation_group_id.trim();
      }

      if (operation_type === 'debit') {
        await purseService.purseDebitAdjustment(purse_id, payload);
      } else {
        await purseService.purseCreditAdjustment(purse_id, payload);
      }

      const defaultDispatch = safeDispatcher(dispatch);
      await defaultDispatch('purse/getPurse', purse_id);

      function haveTextContent(str) {
        return !!str && !!str.trim().length;
      }

      function validateOperationTypes(type) {
        const validOperationTypes = ['credit', 'debit'];
        if (!type || !validOperationTypes.includes(type)) {
          throw new Error(`OperationType "${type}" is not valid`);
        }
      }
    },
    async loadPurseFromBusiness({ commit, dispatch }, params) {
      const defaultDispatch = safeDispatcher(dispatch);
      const purseService = Vue.prototype.$services.purse;

      const payload = {
        amount: {
          value: params.amount,
          currency: params.currency ? params.currency : 'CAD',
        },
        source_of_funds: {
          type: 'agent',
          network: 'internal',
          provider: {
            type: 'business',
            reference_id: params.business_id,
          },
        },
      };

      _.set(payload, 'source_of_funds.note', params.note);

      const updatedPurse = await purseService.loadCredit(params.purse_id, payload);

      await defaultDispatch('ui/showSuccessSnackBar', { text: 'purse.load_purse_from_business.success' });
      commit('purse', updatedPurse);

      return updatedPurse;
    },
    async loadOpenPurseFromBusiness({ commit, dispatch }, params) {
      const defaultDispatch = safeDispatcher(dispatch);
      const purseService = Vue.prototype.$services.purse;

      const payload = {
        amount: {
          value: params.amount,
          currency: params.currency ? params.currency : 'CAD',
        },
        source_of_funds: {
          type: 'agent',
          network: 'internal',
          provider: {
            type: 'business',
            reference_id: params.business_id,
          },
        },
      };

      _.set(payload, 'source_of_funds.note', params.note);

      await purseService.loadOpenPurse(params.account_id, payload);
      const updatedOpenPurse = await purseService.getOpenPurse(params.account_id);

      await defaultDispatch('ui/showSuccessSnackBar', { text: 'purse.load_open_purse_from_business.success' });
      commit('openPurse', updatedOpenPurse);

      return updatedOpenPurse;
    },
    async emptyOpenPurseByAccountId({ commit, dispatch }, { account_id, ...params }) {
      const defaultDispatch = safeDispatcher(dispatch);
      const purseService = Vue.prototype.$services.purse;

      const updatedOpenPurse = await purseService.emptyOpenPurseByAccountId(account_id, params);

      await defaultDispatch('ui/showSuccessSnackBar', { text: 'purse.empty_open_purse_action.success' });
      commit('openPurse', updatedOpenPurse);
    },
  },
};
