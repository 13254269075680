<template>
  <card-modal
    icon=""
    :title="$t('user.unblock_account_action.title')"
    context="unblockAccount"
    @submit="submit"
    @cancel="cancel"
    :submit_working="working"
    cancel_text="user.unblock_account_action.button_cancel"
    submit_text="user.unblock_account_action.button_save"
  >
    <div slot="content">
      <p
        class="modal-confirm--text text-xs-center"
        v-html="$t('user.unblock_account_action.text', { id: account.id, mail: account.contact.email.value })"
      ></p>
    </div>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
export default {
  name: 'unblock-account-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    account: {
      type: Object,
      required: true,
    },
  },
  methods: {
    clear() {
      this.$validator.reset();
    },
    success() {
      this.clear();
      this.$emit('action');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      await this.executeAction(
        {
          action: 'account/unblockAccount',
          name: 'unblockAccount',
          success: this.success,
        },
        {},
      );
    },
  },
};
</script>
