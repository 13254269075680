<template>
  <!-- DEPRECATED PLP:  REMOVED IN HC-1753 -->
  <card-modal
    icon="fal fa-clipboard-check"
    :title="$t('task.modal.resolve_selfserve_change_application_review.title')"
    context="resolveAmlNoteToReview"
    @submit="submit"
    @cancel="cancel"
    cancel_text="task.modal.resolve_selfserve_change_application_review.button_cancel"
    submit_text="task.modal.resolve_selfserve_change_application_review.button_resolve"
    id="resolve-modal-action"
    data-test="selfserve-change-application-review-modal"
  >
    <template slot="content">
      <div v-if="task">
        <user-resolve-modal-part v-if="task.account_id" :account-id="task.account_id"></user-resolve-modal-part>

        <card-application-resolve-modal-part :card-application-id="task.relations.card_application_id" />

        <selfserve-change-application-review-modal-part
          :change-request-id="task.relations.change_request_id"
          :card-application-id="task.relations.card_application_id"
        />

        <h3>{{ $t(`task.modal.resolve_selfserve_change_application_review.resolve_title`) }}</h3>

        <v-flex sm4 xs12>
          <v-select
            name="action"
            v-model="action"
            v-validate="'required'"
            :error-messages="errors.collect('action')"
            data-vv-name="action"
            :data-vv-as="$t('task.modal.resolve_selfserve_change_application_review.form.action')"
            :items="actions"
            :label="$t('task.modal.resolve_selfserve_change_application_review.form.action')"
            class="task-resolution-action"
          >
            <template slot="item" slot-scope="data">
              <span :data-test="data.item.value">{{ data.item.text }}</span>
            </template>
          </v-select>
        </v-flex>
      </div>
    </template>
  </card-modal>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import CardApplicationResolveModalPart from './parts/CardApplicationResolveModalPart';
import SelfserveChangeApplicationReviewModalPart from './parts/SelfserveChangeApplicationReviewModalPart';
import UserResolveModalPart from './parts/UserResolveModalPart';

export default {
  name: 'resolve-selfserve-change-application-review-action',

  components: {
    CardApplicationResolveModalPart,
    SelfserveChangeApplicationReviewModalPart,
    UserResolveModalPart,
  },

  $_veeValidate: {
    validator: 'new',
  },

  mixins: [actionErrorTrackable, security],

  props: {
    taskId: {
      type: String,
      required: true,
    },
  },

  data() {
    const $t = this.$t.bind(this);
    return {
      action: null,
      task: null,
      actions: [
        {
          text: $t('task.modal.resolve_selfserve_change_application_review.action.approve'),
          value: 'approved',
        },
        {
          text: $t('task.modal.resolve_selfserve_change_application_review.action.reject'),
          value: 'rejected',
        },
      ],
    };
  },

  async mounted() {
    this.task = await this.executeAction({ action: 'task/getTask' }, this.taskId);
  },

  methods: {
    clear() {
      this.$validator.reset();
    },

    cancel() {
      this.$emit('closed');
      this.clear();
    },

    success() {
      this.clear();
      this.$emit('task_resolved');
      this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'task.modal.resolve_selfserve_change_application_review.success' });
    },

    async submit() {
      if (await this.$validator.validateAll()) {
        await this.executeAction(
          {
            action: 'task/resolveTask',
            name: 'resolveSelfserveChangeApplicationReview',
            success: this.success,
          },
          { task_key: this.task.key, action: this.action },
        );
      }
    },
  },
};
</script>
