<template>
  <div>
    <shared-instant-card-projects-table :items="items" exclude="offers" />
    <div class="text-xs-center">
      <v-pagination v-if="offerInstantCardProjectsPageCount > 1" v-model="pagination" :length="offerInstantCardProjectsPageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';
import SharedInstantCardProjectsTable from '@/components/shared_tables/SharedInstantCardProjectsTable';

export default {
  name: 'offers-instant-card-projects-table',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { SharedInstantCardProjectsTable },
  props: {
    id: {
      type: String,
    },
    items: {
      type: Array,
    },
  },
  data() {
    return {
      pagination: 1,
    };
  },
  mounted() {
    this.pagination = this.offerInstantCardProjectsPageNumber;
  },
  watch: {
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'offers/listInstantCardProjectsByOffer',
        },
        {
          id: this.id,
          page: newValue,
        },
      );
    },
  },
  computed: {
    ...mapGetters('offers', ['offerInstantCardProjectsPageNumber', 'offerInstantCardProjectsPageCount']),
  },
};
</script>
