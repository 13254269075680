<template>
  <v-flex fill-height>
    <error context="global" /> <error context="render" />
    <v-container fill-height>
      <v-layout column> <slot name="content"> </slot> </v-layout>
    </v-container>
  </v-flex>
</template>

<script>
export default {
  name: 'view-main',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  created() {
    document.title = this.$t(this.title);
  },
};
</script>
