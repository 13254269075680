<template>
  <card-modal
    icon=""
    :title="$t('instant_card_batches.details.link_cards_section.title')"
    context="linkBatchCards"
    @submit="submit"
    @cancel="cancel"
    cancel_text="instant_card_batches.details.link_cards_section.button_cancel"
    submit_text="instant_card_batches.details.link_cards_section.button_save"
    :submit_working="working"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <p>{{ $t('instant_card_batches.details.link_cards_section.note') }}</p>
        <label-text :label="$t('instant_card_batches.details.link_cards_section.batch')" :value="batchObj.id" />
        <label-text :label="$t('instant_card_batches.details.link_cards_section.total_cards')" :value="batchCardsItemCount.toString()" />
        <h-autocomplete
          v-model="project_id"
          :label="$t('instant_card_batches.details.link_cards_section.project')"
          v-validate="'required'"
          :error-messages="errors.collect('project_id')"
          name="project_id"
          data-vv-name="project_id"
          data-vv-as="project_id"
          search-action="instantCardProject/autocompleteSearch"
          data-test="form-instant-card-cards--project"
        >
        </h-autocomplete>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapActions } from 'vuex';
import _ from 'lodash';
import Vue from 'vue';

export default {
  name: 'link-batch-cards-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    batchObj: {
      type: Object,
      required: true,
    },
    batchCardsItemCount: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      project_id: '',
    };
  },
  methods: {
    ...mapActions('ui', ['addError', 'clearAllError', 'clearError']),
    clear() {
      this.project_id = '';
      this.$validator.reset();
    },
    success(response) {
      this.clear();
      this.$emit('linked');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      let validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        await this.executeAction(
          {
            action: 'instantCardBatch/linkBatchToInstantCardProject',
            name: 'linkBatchCards',
            success: this.success,
          },
          {
            id: this.batchObj.id,
            instant_card_project_id: this.project_id,
          },
        );
      }
    },
  },
};
</script>
