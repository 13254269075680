<template>
  <div>
    <shared-activation-code-batches-table :items="items" exclude="business" @update_activation_code_batch="openUpdateActivationCodeBatchModal" />
    <div class="text-xs-center">
      <v-pagination
        v-if="businessActivationCodeBatchesPageCount > 1"
        v-model="pagination"
        :length="businessActivationCodeBatchesPageCount"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import { security, disableWithError, actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import SharedActivationCodeBatchesTable from '@/components/shared_tables/SharedActivationCodeBatchesTable';

export default {
  name: 'business-activation-code-batches-table',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { SharedActivationCodeBatchesTable },
  props: {
    businessId: {
      type: String,
    },
    items: {
      type: Array,
    },
  },
  data() {
    return {
      pagination: 1,
    };
  },
  mounted() {
    this.pagination = this.listPageNumber;
  },
  computed: {
    ...mapGetters('business', [
      'business',
      'businessActivationCodeBatches',
      'businessActivationCodeBatchesPageNumber',
      'businessActivationCodeBatchesPageCount',
    ]),
  },
  watch: {
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'business/listBusinessActivationCodeBatches',
        },
        {
          id: this.businessId,
          page: newValue,
        },
      );
    },
  },
  methods: {
    openUpdateActivationCodeBatchModal(id) {
      this.$emit('update_activation_code_batch', id);
    },
  },
};
</script>
