<template>
  <card :title="$t('instant_card_batches.details.notes_section.title')" icon="">
    <template slot="headerZoneRight">
      <label-text
        class="text-xs-center"
        :label="`${$t('instant_card_batches.details.notes_section.count')}`"
        :value="listBatchNotesItemCount || '0'"
      ></label-text>
      <div v-if="showAddBatchNoteFeature()" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text v-if="showAddBatchNoteFeature()" :label="`${$t('instant_card_batches.details.notes_section.action')}`" value="3">
        <link-secondary-action slot="labelTextLink" @click="openAddBatchNoteModal">
          {{ $t('instant_card_batches.details.notes_section.create_note') }}
        </link-secondary-action>
      </label-text>
    </template>
    <template slot="content">
      <batch-notes-table
        v-if="batchNotes.length"
        :items="batchNotes"
        :batch-id="batch.id"
        @batch_note_delete_action="openDeleteBatchNoteModal"
      ></batch-notes-table>
      <label-none v-if="batchNotes.length === 0">{{ $t('instant_card_batches.details.notes_section.no_found') }}</label-none>
      <modal-action :show-modal="showAddBatchNoteModal"
        ><create-batch-note-action
          :v-if="showAddBatchNoteModal"
          :batch-id="batch.id"
          @close="closeAddBatchNoteModal"
          @created="addBatchNoteSuccess"
        ></create-batch-note-action
      ></modal-action>
      <modal-action :show-modal="showDeleteBatchNoteModal"
        ><delete-batch-note-action
          :v-if="showDeleteBatchNoteModal"
          :batch-id="batch.id"
          :note-id="noteIdToDelete"
          @close="closeDeleteBatchNoteModal"
          @deleted="deleteBatchNoteSuccess"
        ></delete-batch-note-action
      ></modal-action>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import CreateBatchNoteAction from '../actions/CreateBatchNoteAction';
import DeleteBatchNoteAction from '../actions/DeleteBatchNoteAction';
import BatchNotesTable from './BatchNotesTable';
import { mapGetters } from 'vuex';

export default {
  name: 'batch-notes',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { BatchNotesTable, CreateBatchNoteAction, DeleteBatchNoteAction },
  data() {
    return {
      showAddBatchNoteModal: false,
      showDeleteBatchNoteModal: false,
      noteIdToDelete: null,
      noteToDelete: {},
    };
  },
  computed: {
    ...mapGetters('instantCardBatch', ['batchNotes', 'batch', 'listBatchNotesItemCount']),
    ...mapGetters('ui', ['currentLocale']),
  },
  watch: {
    async batch() {
      await this.fetchBatchNotes();
    },
  },
  methods: {
    openAddBatchNoteModal() {
      this.showAddBatchNoteModal = true;
    },
    closeAddBatchNoteModal() {
      this.showAddBatchNoteModal = false;
    },
    openDeleteBatchNoteModal(noteId) {
      this.noteIdToDelete = noteId;
      this.noteToDelete = this.batchNotes.find((l) => l.id === noteId);
      this.showDeleteBatchNoteModal = true;
    },
    closeDeleteBatchNoteModal() {
      this.noteIdToDelete = null;
      this.noteToDelete = {};
      this.showDeleteBatchNoteModal = false;
    },
    async deleteBatchNoteSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'instant_card_batches.delete_note_action.success' });
      this.closeDeleteBatchNoteModal();
    },
    async addBatchNoteSuccess() {
      await this.fetchBatchNotes();
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'instant_card_batches.create_note_action.success' });
      this.closeAddBatchNoteModal();
    },
    showAddBatchNoteFeature() {
      return this.canManageOffer(); // TODO change permission
    },
    async fetchBatchNotes() {
      await this.executeAction({ action: 'instantCardBatch/listBatchNotes' }, { batch_id: this.batch.id });
    },
  },
};
</script>
