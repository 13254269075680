'use strict';

export default (Joi) => {
  return {
    base: Joi.string(),
    name: 'string',
    rules: [
      {
        name: 'id',
        validate(params, value, state, options) {
          if (value.length > 30) {
            return this.createError('string.id', { v: value }, state, options);
          }

          return value;
        },
      },
    ],
  };
};
