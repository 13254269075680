import Vue from 'vue';

const service = {
  async searchBankAccount(keyword, status) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/eft_v2/v1/bank-accounts/search`, {
      params: { keyword, status },
    });
    return response.data.data;
  },
  async getBankAccount(key) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/eft_v2/v1/bank-accounts/${key}`);
    return response.data.data;
  },
  async createBankAccount(payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/eft_v2/v1/bank-accounts`, payload);
    return response.data.data;
  },
  async updateBankAccount(key, payload) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/eft_v2/v1/bank-accounts/${key}`, payload);
    return response.data.data;
  },
  async listBankAccounts(keyword, status, page, limit) {
    const params = {};
    if (keyword) {
      params.keyword = keyword;
    }
    if (status) {
      params.status = status;
    }
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath('/eft_v2/v1/bank-accounts', page, limit, params)}`,
    );
    return response.data.data;
  },
  async createBankAccountNote(bank_account_key, payload) {
    const response = await Vue.prototype.$axios.post(
      `${process.env.VUE_APP_BASE_API_URL}/eft_v2/v1/bank-accounts/${bank_account_key}/notes`,
      payload,
    );
    return response.data.data;
  },
  async deleteBankAccountNote(bank_account_key, note_id, payload) {
    const response = await Vue.prototype.$axios.put(
      `${process.env.VUE_APP_BASE_API_URL}/eft_v2/v1/bank-accounts/${bank_account_key}/notes/${note_id}/delete`,
      payload,
    );
    return response.data.data;
  },
  async listBankAccountNotes(page, limit, bank_account_key) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/eft_v2/v1/bank-accounts/${bank_account_key}/notes`, page, limit)}`,
    );
    return response.data.data;
  },
  async activateBankAccount(key) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/eft_v2/v1/bank-accounts/${key}/activate`);
    return response.data.data;
  },
  async blockBankAccount(key) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/eft_v2/v1/bank-accounts/${key}/block`);
    return response.data.data;
  },
};

function generatePath(startPath, page, limit, { keyword, status } = {}) {
  let path = startPath;
  if (page || limit || keyword | status) {
    path += '?';
  }

  const queryStrings = [];
  if (page) {
    queryStrings.push(['page', page]);
  }
  if (limit) {
    queryStrings.push(['limit', limit]);
  }
  if (keyword) {
    queryStrings.push(['keyword', keyword]);
  }
  if (status) {
    queryStrings.push(['status', status]);
  }

  const params = new URLSearchParams(queryStrings);
  return path + params.toString();
}

export default service;
