<template>
  <card :title="$t('branding.spaces')" icon="">
    <template slot="headerZoneRight">
      <label-text
        class="text-xs-center"
        :label="`${$t('branding.details.spaces_section.count')}`"
        :value="brandingSpacesItemCount || '0'"
      ></label-text>
    </template>
    <template slot="content">
      <branding-spaces-table v-if="brandingSpacesItemCount > 0" :items="brandingSpaces" :id="branding.id"></branding-spaces-table>
      <label-none v-if="brandingSpacesItemCount === 0">{{ $t('system.none') }}</label-none>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';
import BrandingSpacesTable from './BrandingSpacesTable.vue';

export default {
  name: 'branding-spaces',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { BrandingSpacesTable },
  data() {
    return {
      pagination: 1,
    };
  },
  async created() {
    await this.fetchBrandingSpaces();
  },
  watch: {
    async branding() {
      await this.fetchBrandingSpaces();
    },
    brandingSpacesPageNumber() {
      this.pagination = this.brandingSpacesPageNumber;
    },
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'branding/changeBrandingSpacesPage',
          success: this.success,
        },
        {
          id: this.branding.id,
          page: newValue,
        },
      );
    },
  },
  computed: {
    ...mapGetters('branding', ['branding', 'brandingSpaces', 'brandingSpacesPageCount', 'brandingSpacesPageNumber', 'brandingSpacesItemCount']),
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    async clear() {
      this.$validator.reset();
    },
    async fetchBrandingSpaces() {
      await this.executeAction(
        { action: 'branding/listBrandingSpaces' },
        {
          id: this.branding.id,
          page: this.brandingSpacesPageNumber,
        },
      );
    },
  },
};
</script>
