<template>
  <v-menu
    ref="menu"
    v-model="menu"
    lazy
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    :full-width="fullWidth"
    :min-width="minWidth"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        data-test="date-picker"
        v-model="content"
        :label="label"
        :hint="hint"
        :persistent-hint="persistentHint"
        readonly
        prepend-icon="event"
        v-on="on"
        v-bind="$attrs"
      ></v-text-field>
    </template>
    <v-date-picker ref="picker" v-model="content" :max="maxDate" :min="minDate" v-on="listeners" @change="save"></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'hello-date-picker',
  props: {
    label: {
      type: String,
      required: true,
    },
    minWidth: {
      type: String,
      required: true,
    },
    fullWidth: {
      type: Boolean,
      required: true,
    },
    hint: {
      type: String,
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
    minDate: {
      type: String,
      required: false,
    },
    maxDate: {
      type: String,
      required: false,
    },
    value: {
      type: String,
      default: null,
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
      if (!val) {
        this.$emit('input', this.content);
      }
    },
    content(val) {
      this.$emit('input', val);
    },
    value(val) {
      this.content = val;
    },
  },
  data() {
    return {
      menu: false,
      content: this.value,
    };
  },
  methods: {
    save(val) {
      this.$emit('input', val);
      this.$refs.menu.save(val);
    },
  },
  computed: {
    listeners() {
      const { input, ...listeners } = this.$listeners;
      return listeners;
    },
  },
};
</script>
