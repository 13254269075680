<template>
  <card-modal
    icon=""
    :title="$t('user.block_account_action.title')"
    context="blockAccount"
    @submit="submit"
    @cancel="cancel"
    :submit_working="working"
    cancel_text="user.block_account_action.button_cancel"
    submit_text="user.block_account_action.button_save"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-layout row pad-form-row-elements>
          <v-flex sm12 xs12>
            <v-text-field
              ref="reason"
              name="reason"
              v-model="reason"
              :label="$t('user.block_account_action.reason') + '*'"
              v-validate="{ required: true, max: 1204 }"
              :error-messages="errors.collect('reason')"
              data-vv-name="reason"
              :data-vv-as="$t('user.block_account_action.reason')"
              data-test="block-account--reason"
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-layout>
          <v-flex sm12 xs12>
            <v-checkbox
              ref="cascade"
              v-model="cascade"
              :label="$t('user.block_account_action.cascade')"
              data-vv-name="cascade"
              name="cascade"
              :error-messages="errors.collect('user.block_account_action.cascade')"
              v-validate="`required`"
            ></v-checkbox>
          </v-flex>
        </v-layout>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
export default {
  name: 'block-account-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    account: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      reason: '',
      cascade: false,
    };
  },
  methods: {
    clear() {
      this.$validator.reset();
    },
    success() {
      this.clear();
      this.$emit('action');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();
      if (validationComplete) {
        await this.executeAction(
          {
            action: 'account/blockAccount',
            name: 'blockAccount',
            success: this.success,
          },
          {
            reason: this.reason,
            cascade: this.cascade,
          },
        );
      }
    },
  },
};
</script>
