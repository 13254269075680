<template>
  <view-main title="page_title.space"><search-space-form slot="content"></search-space-form></view-main>
</template>

<script>
import { renderErrorTrackable } from '@/mixins';
import SearchSpaceForm from './list/SearchSpaceForm.vue';
export default {
  name: 'space-view',
  mixins: [renderErrorTrackable],
  components: { SearchSpaceForm },
};
</script>
