<template>
  <div class="eft-item-status">
    <span v-if="status" :class="`status-${status}`">{{ $t(`business.eft_items.status.${status}`) }}</span>
  </div>
</template>

<script>
export default {
  name: 'eft-item-status-view',
  props: {
    status: {
      type: String,
    },
  },
};
</script>
