import VueScrollTo from 'vue-scrollto';
import Vue from 'vue';

export default {
  mounted() {
    Vue.nextTick(() => {
      if (this.hashTag) {
        VueScrollTo.scrollTo(document.getElementById(`${this.hashTag}`), 600, { offset: -70 });
      }
    });
  },
  props: {
    hashTag: {
      type: String,
      required: false,
    },
  },
};
