<template>
  <view-main title="page_title.user"><search-user-form slot="content"></search-user-form></view-main>
</template>

<script>
import { renderErrorTrackable } from '@/mixins';
import SearchUserForm from './list/SearchUserForm.vue';

export default {
  name: 'user-view',
  mixins: [renderErrorTrackable],
  components: { SearchUserForm },
};
</script>
