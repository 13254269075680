<template>
  <card v-if="openPurse" :title="$t('user.mycash')" icon="">
    <template slot="headerZoneRight">
      <label-text :label="$t(`user.details.mycash_section.interac_etransfer_security`)">
        <p slot="labelTextLink" class="label-text--value">
          <v-layout row>
            {{ account.etransfer_id | orNotAvailable }}
            <button class="clipboard" v-clipboard:copy="account.etransfer_id">
              <v-icon small>file_copy</v-icon>
            </button>
          </v-layout>
        </p>
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="`${$t('user.details.mycash_section.sync_with_cardholder')}`">
        <p slot="labelTextLink" class="label-text--value">
          <v-layout row>
            <cardholder-current-card v-if="openPurse.cardholder_id" :cardholder-id="openPurse.cardholder_id" />
            <p v-else class="cannot-use-it">{{ $t('user.details.mycash_section.user_cannot_use_it') }}</p>
          </v-layout>
        </p>
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <link-secondary-action slot="labelTextLink">
        <button-secondary-actions class="application-info--actions" :text="$t(`system.actions`)" :actions="actions"> </button-secondary-actions>
      </link-secondary-action>
    </template>
    <template slot="content">
      <v-layout row wrap class="wallets-info--content-zone">
        <v-layout column>
          <v-layout row>
            <label-text
              :label="$t('card.limits_and_balances.available')"
              :value="openPurse.available_amount | currency"
              :class-array="['wallets-info--wallet-available-balance']"
            ></label-text>
            <label-text :label="$t('card.limits_and_balances.hold')" :value="walletHoldBalance | currency"></label-text>
          </v-layout>
          <v-layout column class="wallets-info--links column">
            <router-link v-if="openPurse.id" :to="{ name: 'user_purses_details', params: { userId: openPurse.account_id, purseId: openPurse.id } }">
              {{ $t('user.details.mycash_section.show_history') }}
            </router-link>
            <router-link
              v-if="openPurse.id"
              :class="{ 'disabled-link': !openPurse.cardholder_id }"
              target="_blank"
              :to="{
                name: 'debits_credits_dashboard',
                query: { cardholder_id: openPurse.cardholder_id, wallet_definition_id: 'open', timerange: '~' },
              }"
            >
              {{ $t('user.details.mycash_section.show_debits_credits') }}
            </router-link>
          </v-layout>
        </v-layout>
      </v-layout>
    </template>
    <template slot="content">
      <modal-action :show-modal="showPurseLoadFromInteracModal">
        <load-open-purse-from-interac-action
          :account_id="openPurse.account_id || ''"
          @close="closePurseLoadInteracModal"
          @action="fetchPurse"
        ></load-open-purse-from-interac-action>
      </modal-action>

      <modal-action :show-modal="showLoadPurseFromBankAccountModal">
        <load-open-purse-from-business-action
          :account_id="openPurse.account_id || ''"
          @close="closeLoadPurseFromBankAccountModal"
          @action="fetchPurse"
        ></load-open-purse-from-business-action>
      </modal-action>

      <modal-action :show-modal="showPurseAdjustmentModal">
        <adjust-purse-balance-action :purse="openPurse" @close="closePurseAdjustmentModal" @action="fetchPurse"></adjust-purse-balance-action>
      </modal-action>

      <modal-action :show-modal="showEmptyOpenPurse">
        <empty-open-purse-action :account="account" @close="closeEmptyOpenPurseModal" @action="fetchPurse"></empty-open-purse-action>
      </modal-action>
    </template>
  </card>
</template>

<style scoped lang="stylus">
.disabled-link {
  pointer-events: none;
  color: gray;
}
</style>

<script>
import { security, disableWithError, actionErrorTrackable } from '@/mixins';
import AdjustPurseBalanceAction from '../../purse/actions/AdjustPurseBalanceAction';
import LoadOpenPurseFromInteracAction from '../../purse/actions/LoadOpenPurseFromInteracAction';
import LoadOpenPurseFromBusinessAction from '../../purse/actions/LoadOpenPurseFromBusinessAction';
import EmptyOpenPurseAction from '../../purse/actions/EmptyOpenPurseAction';
import { mapGetters } from 'vuex';
import _round from 'lodash/round';
import Vue from 'vue';
import AccountStatus from '@/enums/account-status';

export default {
  name: 'user-my-cash',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { LoadOpenPurseFromInteracAction, AdjustPurseBalanceAction, LoadOpenPurseFromBusinessAction, EmptyOpenPurseAction },
  data() {
    return {
      showAddUserPurseModal: false,
      actions: [
        {
          slotName: 'purseLoadInterac',
          name: this.$t('purse.details.purse_information_section.load_purse_from_interac'),
          action: () => this.purseLoadInteracModal(),
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => this.accountIsDeactivated,
        },
        {
          slotName: 'purseLoadCredit',
          name: this.$t('purse.details.purse_information_section.load_purse_from_business'),
          action: () => this.openLoadPurseFromBankAccountModal(),
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => this.accountIsDeactivated,
        },
        {
          slotName: 'purseAdjustmentModal',
          name: this.$t('purse.details.purse_information_section.purse_adjustment'),
          action: () => this.purseAdjustmentModal(),
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => this.accountIsDeactivated,
        },
        {
          slotName: 'emptyOpenPurseModal',
          name: this.$t('purse.details.purse_information_section.empty_open_purse'),
          action: () => this.emptyOpenPurseModal(),
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => this.accountOpenPurseEmpty || this.accountIsDeactivated,
        },
      ],
      showPurseLoadFromInteracModal: false,
      showPurseAdjustmentModal: false,
      showLoadPurseFromBankAccountModal: false,
      showEmptyOpenPurse: false,
    };
  },
  computed: {
    ...mapGetters('account', ['account']),
    ...mapGetters('purse', ['openPurse']),
    ...mapGetters('ui', ['currentLocale']),
    walletHoldBalance() {
      return _round(this.openPurse.actual_amount - this.openPurse.available_amount, 2);
    },
    accountIsDeactivated() {
      return this.account.status === AccountStatus.DEACTIVATED;
    },
    accountOpenPurseEmpty() {
      return !this.openPurse || this.openPurse.available_amount <= 0;
    },
  },
  watch: {
    async account() {
      await this.fetchPurse();
    },
  },
  methods: {
    // Actions
    showActionBtnVoucher() {
      return this.canManageOffer();
    },

    // Purse Load From Interac
    purseLoadInteracModal() {
      this.showPurseLoadFromInteracModal = true;
    },
    closePurseLoadInteracModal() {
      this.showPurseLoadFromInteracModal = false;
    },

    // Purse Load credit
    openLoadPurseFromBankAccountModal() {
      this.showLoadPurseFromBankAccountModal = true;
    },
    closeLoadPurseFromBankAccountModal() {
      this.showLoadPurseFromBankAccountModal = false;
    },

    // Purse adjustment
    purseAdjustmentModal() {
      this.showPurseAdjustmentModal = true;
    },
    closePurseAdjustmentModal() {
      this.showPurseAdjustmentModal = false;
    },

    // Empty Open Purse
    emptyOpenPurseModal() {
      this.showEmptyOpenPurse = true;
    },
    closeEmptyOpenPurseModal() {
      this.showEmptyOpenPurse = false;
    },

    async fetchPurse() {
      await this.executeAction({ action: 'purse/getOpenPurse' }, this.account.id);
    },
  },
};
</script>

<style scoped lang="stylus">
@import './../../../styles/variables/custom-variables.styl';

.cannot-use-it {
  color: $error-default;
  margin-bottom: 0;
}
</style>
