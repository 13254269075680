<template>
  <card :title="$t('space.details.partner_establishment_section.title')" icon="">
    <template slot="headerZoneRight">
      <label-text class="text-sm-center" :label="$t('space.details.partner_establishment_section.include_deactivated')" :value="1">
        <v-checkbox
          style="margin: 0; padding: 0; display: inline-block"
          slot="labelTextLink"
          :hide-details="true"
          v-model="include_deactivated"
        ></v-checkbox>
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text
        class="text-sm-center"
        :label="$t('space.details.partner_establishment_section.count')"
        :value="listSpacePartnerEstablishmentItemCount || '0'"
      ></label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text class="text-sm-center" :label="$t('space.details.partner_establishment_section.action')">
        <link-secondary-action slot="labelTextLink" @click="showAddPartnerEstablishmentActionModal()">
          {{ $t('space.details.partner_establishment_section.add') }}
        </link-secondary-action>
      </label-text>
    </template>
    <template slot="content">
      <modal-action :show-modal="addPartnerEstablishmentModalShown" close>
        <add-partner-establishment-action
          :partner-id="space.partner_id"
          @success="partnerEstablishmentCreated"
          @close="closeAddPartnerEstablishmentActionModal"
        ></add-partner-establishment-action
      ></modal-action>
      <space-partner-establishment-table
        v-if="dataReady && spacePartnerEstablishments.length"
        :items="spacePartnerEstablishments"
        :partner-id="space.partner_id"
      >
      </space-partner-establishment-table>
      <label-none v-if="spacePartnerEstablishments.length === 0">{{ $t('system.none') }}</label-none>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import SpacePartnerEstablishmentTable from './SpacePartnerEstablishmentTable';
import { mapGetters } from 'vuex';
import AddPartnerEstablishmentAction from '../actions/AddPartnerEstablishmentAction.vue';

export default {
  name: 'space-partner-establishment',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { AddPartnerEstablishmentAction, SpacePartnerEstablishmentTable },
  data() {
    return {
      page: 1,
      dataReady: false,
      include_deactivated: true,
      addPartnerEstablishmentModalShown: false,
    };
  },
  async created() {
    await this.fetchPartnerEstablishments();
    this.page = this.listSpacePartnerEstablishmentPageNumber;
  },
  watch: {
    async space() {
      this.page = 1;
      await this.fetchPartnerEstablishments();
    },
    async include_deactivated() {
      this.page = 1;
      await this.fetchPartnerEstablishments();
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('space', [
      'space',
      'spacePartnerEstablishments',
      'listSpacePartnerEstablishmentItemCount',
      'listSpacePartnerEstablishmentPageNumber',
    ]),
  },
  methods: {
    showAddPartnerEstablishmentActionModal() {
      this.addPartnerEstablishmentModalShown = true;
    },
    async partnerEstablishmentCreated() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: `space.details.partner_establishment_section.add_action.success` });

      this.addPartnerEstablishmentModalShown = false;
    },
    closeAddPartnerEstablishmentActionModal() {
      this.addPartnerEstablishmentModalShown = false;
    },
    async fetchPartnerEstablishments() {
      this.dataReady = false;
      if (this.space.partner_id) {
        await this.executeAction(
          { action: 'space/listSpacePartnerEstablishments' },
          { partner_id: this.space.partner_id, include_deactivated: this.include_deactivated, page: this.page },
        );
      }
      this.dataReady = true;
    },
  },
};
</script>
