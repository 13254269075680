import Vue from 'vue';

const axios = require('axios');

const service = {
  async getCardPackage(id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/card-packages/ids/${id}`);
    return response.data;
  },
  async listCardPackages(page, limit, { card_program_type, status, bulk }) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath('/card-packages/v1', page, limit, card_program_type, status, bulk)}`,
    );
    return response.data.data.items;
  },
};

function generatePath(startPath, page, limit, card_program_type, status, bulk) {
  let path = startPath;
  if (page || limit || card_program_type) {
    path += '?';
  }

  const queryStrings = [];
  if (page) {
    queryStrings.push(['page', page]);
  }
  if (limit) {
    queryStrings.push(['limit', limit]);
  }
  if (card_program_type) {
    queryStrings.push(['card_program_type', card_program_type]);
  }
  if (status) {
    queryStrings.push(['status', status]);
  }
  if (bulk !== undefined) {
    queryStrings.push(['bulk', bulk]);
  }

  const params = new URLSearchParams(queryStrings);
  return path + params.toString();
}

export default service;
