import _ from 'lodash';

function generateSnackBar(options) {
  return _.merge(
    {
      y: 'top',
      x: 'right',
      mode: '',
      color: 'success',
      text: '',
      show: true,
      timeout: 6000,
    },
    options,
  );
}

export default {
  namespaced: true,
  state: {
    errors: {},
    currentLocale: 'en',
    locales: [
      {
        text: 'English',
        value: 'en',
      },
      {
        text: 'Français',
        value: 'fr',
      },
    ],
    snackbar: {
      show: false,
    },
    snackbarTimeout: null,
    snackbarText: [],
  },
  getters: {
    error: (state) => state.errors,
    currentLocale: (state) => state.currentLocale,
    locales: (state) => state.locales,
    snackbar: (state) => state.snackbar,
    snackbarText: (state) => state.snackbarText,
  },
  mutations: {
    currentLocale(state, locale) {
      state.currentLocale = locale;
    },
    error(state, { contextName, error }) {
      if (error) {
        if (state.errors[contextName]) {
          console.warn(`Only the first error will be displayed.  Caught: ${error}`); // eslint-disable-line no-console
        } else {
          state.errors = _.merge({}, state.errors, { [contextName]: error });
        }
      } else if (state.errors[contextName]) {
        state.errors = _.merge({}, _.omit(state.errors, contextName));
      }
    },
    clearError(state) {
      state.errors = {};
    },
    addSnackbar(state, { freshSnackbar, text }) {
      state.snackbar = freshSnackbar;
      state.snackbarText = _.concat(state.snackbarText, text);
    },
    augmentSnackbar(state, text) {
      state.snackbar.mode = 'multi-line';
      state.snackbarText = _.concat(state.snackbarText, text);
    },
    setSnackbarTimeout(state, timeout) {
      state.snackbarTimeout = timeout;
    },
    cleanSnackbar(state) {
      state.snackbar = {
        show: false,
      };
      state.snackbarTimeout = 0;
      state.snackbarText = [];
    },
  },
  actions: {
    showSuccessSnackBar({ commit, state }, { text }) {
      let timeout = 6000;
      let theTimeout;

      if (state.snackbar.show === false) {
        const freshSnackbar = generateSnackBar({ text, timeout });
        commit('addSnackbar', { freshSnackbar, text });

        theTimeout = setTimeout(() => {
          commit('cleanSnackbar');
        }, timeout);

        commit('setSnackbarTimeout', theTimeout);
      } else {
        clearTimeout(state.snackbarTimeout);

        const newTimeoutDuration = timeout;
        commit('augmentSnackbar', text);

        theTimeout = setTimeout(() => {
          commit('cleanSnackbar');
        }, newTimeoutDuration);

        commit('setSnackbarTimeout', theTimeout);
      }
    },
    changeLocale({ commit, state }, newLocale) {
      commit('currentLocale', newLocale);
      let timeout = 6000;
      let theTimeout;

      if (state.snackbar.show === false) {
        const freshSnackbar = generateSnackBar({ text: 'Locale changed', timeout });
        commit('addSnackbar', { freshSnackbar, text: 'Locale changed' });

        theTimeout = setTimeout(() => {
          commit('cleanSnackbar');
        }, timeout);

        commit('setSnackbarTimeout', theTimeout);
      } else {
        clearTimeout(state.snackbarTimeout);

        const newTimeoutDuration = timeout;
        commit('augmentSnackbar', { text: 'Locale changed', timeout });

        theTimeout = setTimeout(() => {
          commit('cleanSnackbar');
        }, newTimeoutDuration);

        commit('setSnackbarTimeout', theTimeout);
      }
    },
    addError({ commit }, { contextName, error }) {
      console.log(`CATCHED ERROR. Context: ${contextName} Error: `, error); // eslint-disable-line no-console
      console.trace(error); // eslint-disable-line no-console
      rg4js('send', {
        error,
      });
      commit('error', { contextName, error });
    },
    clearError({ commit }, contextName) {
      commit('error', { contextName, error: null });
    },
    clearAllError({ commit }) {
      commit('clearError');
    },
  },
};
