<template>
  <div class="workstation-registration-status">
    <span v-if="status" :class="`status-${status}`">{{ $t(`workstation_registration.status.${status}`) }}</span>
  </div>
</template>

<script>
export default {
  name: 'workstation-registration-status-view',
  props: {
    status: {
      type: String,
    },
  },
};
</script>

<style scoped lang="stylus">
@import './../../styles/variables/custom-variables.styl';

.status-created {
  font-weight: bold;
}
.status-approved {
  font-weight: bold;
  color: $success-default;
}
.status-pending {
  font-weight: bold;
  color: $pending;
}
.status-rejected {
  font-weight: bold;
  color: $error-default;
}
</style>
