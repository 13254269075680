<template>
  <div>
    <modal-confirm
      :show-modal="removeWebhookListenerModalVisible"
      :title="$t('offers.webhook_listeners.remove_modal.title')"
      context="OfferWebhookListenerRemove"
      :text="$t('offers.webhook_listeners.remove_modal.text', { webhookName: currentWebhookName })"
      cancel_text="offers.webhook_listeners.remove_modal.cancel"
      submit_text="offers.webhook_listeners.remove_modal.submit"
      :submit_working="working"
      @submit="removeWebhookListener"
      @close="closeRemoveWebhookListenerModal"
    ></modal-confirm>

    <table class="responsive-table" id="offer-activation-codes-table">
      <thead>
        <tr>
          <td>{{ $t('offers.webhook_listeners.fields.business') }}</td>
          <td>{{ $t('offers.webhook_listeners.fields.endpoint') }}</td>
          <td>{{ $t('offers.webhook_listeners.fields.endpoint_status') }}</td>
          <td>{{ $t('offers.webhook_listeners.fields.action') }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>
            <h5>{{ $t('offers.webhook_listeners.fields.business') }}:</h5>
            <router-link :to="{ name: 'business_details', params: { businessId: item.business_webhook.business_id } }">{{
              formattedBusinessInfo(item)
            }}</router-link>
          </td>
          <td>
            <h5>{{ $t('offers.webhook_listeners.fields.endpoint') }}</h5>
            <span> {{ item.business_webhook.name }} </span>
          </td>
          <td>
            <h5>{{ $t('offers.webhook_listeners.fields.endpoint_status') }}</h5>
            <span :class="`offer-webhook-listener-${item.business_webhook.status}`">
              {{ $t(`offers.webhook_listeners.status.${item.business_webhook.status}`) }}
            </span>
          </td>
          <td>
            <h5>{{ $t('offers.webhook_listeners.fields.action') }}</h5>
            <a class="button-subtle" @click="showRemoveWebhookListenerModal(item)">{{ $t('offers.webhook_listeners.actions.remove') }}</a>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-xs-center">
      <v-pagination v-if="offerWebhookListenersPageCount > 1" v-model="currentPage" :length="offerWebhookListenersPageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import { mapGetters } from 'vuex';
import _get from 'lodash/get';

export default {
  name: 'business-webhooks-table',
  mixins: [security, actionErrorTrackable],
  props: {
    offerId: {
      type: String,
    },
    items: {
      type: Array,
    },
  },
  data() {
    return {
      currentWebhookListener: {},
      currentWebhookName: '',
      currentPage: 1,
      removeWebhookListenerModalVisible: false,
    };
  },
  mounted() {
    this.pagination = this.businessWebhooksPageNumber;
  },
  computed: {
    ...mapGetters('offers', ['offerWebhookListenersPageNumber', 'offerWebhookListenersPageCount']),
  },
  watch: {
    async currentPage(newValue) {
      this.fetchWebhookListeners();
    },
  },

  methods: {
    async fetchWebhookListeners() {
      await this.executeAction(
        {
          action: 'offers/listOfferWebhookListeners',
        },
        {
          page: this.currentPage,
          id: this.offerId,
        },
      );
    },
    formattedBusinessInfo(item) {
      return `${item.business_webhook.business_name} (${item.business_webhook.business_id})`;
    },
    showRemoveWebhookListenerModal(webhookListener) {
      this.currentWebhookListener = webhookListener;
      this.currentWebhookName = _get(webhookListener, 'business_webhook.name');
      this.removeWebhookListenerModalVisible = true;
    },
    closeRemoveWebhookListenerModal() {
      this.removeWebhookListenerModalVisible = false;
    },
    async removeWebhookListener() {
      await this.executeAction(
        {
          name: 'OfferWebhookListenerRemove',
          action: 'offers/removeOfferWebhookListener',
          success: () => {
            this.closeRemoveWebhookListenerModal();
            this.fetchWebhookListeners();
          },
        },
        { offerId: this.offerId, webhookListenerId: this.currentWebhookListener.id },
      );
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: `offers.webhook_listeners.remove_modal.success` });
    },
  },
};
</script>
