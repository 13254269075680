<template>
  <card :title="$t('branding.details.branding_information_section.title')" icon="fal fa-registered" id="branding-informations">
    <template slot="headerZoneRight">
      <label-text :label="`${$t('branding.details.branding_information_section.id')}`" :value="branding.id">
        <p slot="labelTextLink" class="label-text--value">
          <v-layout row>
            {{ branding.id }}
            <button class="clipboard" v-clipboard:copy="branding.id ? branding.id : ''">
              <v-icon small>file_copy</v-icon>
            </button>
          </v-layout>
        </p>
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="`${$t('branding.details.branding_information_section.name')}`" :value="branding.name" />
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text
        :label="`${$t('branding.details.branding_information_section.created')}`"
        :value="branding.creation_date | formatShortDateInverted | orNotAvailable"
      />
      <div v-if="showUpdateBrandingFeature()" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text v-if="showUpdateBrandingFeature()" :label="`${$t('branding.details.branding_information_section.action')}`" value="3">
        <link-secondary-action slot="labelTextLink" @click="openUpdateBrandingModal">
          {{ $t('branding.details.branding_information_section.update_branding') }}
        </link-secondary-action>
      </label-text>
    </template>
    <template slot="content">
      <modal-action :show-modal="showUpdateBrandingModal"
        ><update-branding-action
          v-if="showUpdateBrandingModal"
          :branding="branding"
          @close="closeUpdateBrandingModal"
          @updated="updateBrandingSuccess"
        ></update-branding-action
      ></modal-action>
      <v-layout row>
        <v-flex xs4><label-text :label="$t(`branding.details.branding_information_section.name`)" :value="branding.name"></label-text></v-flex>
        <v-flex xs4
          ><label-text :label="$t(`branding.details.branding_information_section.type`)" :value="$t(`branding.types.${branding.type}`)"></label-text
        ></v-flex>
        <v-flex xs4
          ><label-text :label="$t(`branding.details.branding_information_section.category`)" :value="brandingCategory.name.en"></label-text
        ></v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs4
          ><label-text :label="$t(`branding.details.branding_information_section.label.fr`)" :value="branding.label.fr"></label-text
        ></v-flex>
        <v-flex xs4
          ><label-text :label="$t(`branding.details.branding_information_section.label.en`)" :value="branding.label.en"></label-text
        ></v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs4><label-text :label="$t(`branding.details.branding_information_section.color`)" :value="branding.branding_color"/></v-flex>
        <v-flex xs4 v-if="branding.branding_color">
          <label-text :label="$t(`branding.details.branding_information_section.preview_color`)" :value="branding.branding_color">
            <p slot="labelTextLink">
              <v-btn dark :color="branding.branding_color">{{ $t(`branding.details.branding_information_section.preview_color`) }}</v-btn>
            </p>
          </label-text>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs4
          ><label-text
            :label="$t(`branding.details.branding_information_section.description.fr`)"
            :value="branding.description.fr | truncateEllipsis(128)"
            :title="branding.description.fr"
          ></label-text
        ></v-flex>
        <v-flex xs4>
          <label-text
            :label="$t(`branding.details.branding_information_section.description.en`)"
            :value="branding.description.en | truncateEllipsis(128)"
            :title="branding.description.en"
          >
          </label-text
        ></v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs4><label-text :label="$t(`branding.details.branding_information_section.tagline.fr`)" :value="branding.tagline.fr"/></v-flex>
        <v-flex xs4><label-text :label="$t(`branding.details.branding_information_section.tagline.en`)" :value="branding.tagline.en"/></v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs4
          ><label-text :label="$t(`branding.details.branding_information_section.region_label.fr`)" :value="branding.region_label.fr"
        /></v-flex>
        <v-flex xs4
          ><label-text :label="$t(`branding.details.branding_information_section.region_label.en`)" :value="branding.region_label.en"
        /></v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs4>
          <label-text v-if="branding.url.fr" :label="$t('branding.details.branding_information_section.url.fr')" :value="branding.url.fr">
            <p class="label-text--value" slot="labelTextLink">
              <v-layout row>
                <a :href="branding.url.fr" target="_blank">{{ branding.url.fr }}</a>
                <button class="clipboard" v-clipboard:copy="branding.url.fr ? branding.url.fr : ''">
                  <v-icon small>file_copy</v-icon>
                </button>
              </v-layout>
            </p>
          </label-text>
        </v-flex>
        <v-flex xs4>
          <label-text v-if="branding.url.en" :label="$t('branding.details.branding_information_section.url.en')" :value="branding.url.en">
            <p class="label-text--value" slot="labelTextLink">
              <v-layout row>
                <a :href="branding.url.en" target="_blank">{{ branding.url.en }}</a>
                <button class="clipboard" v-clipboard:copy="branding.url.en ? branding.url.en : ''">
                  <v-icon small>file_copy</v-icon>
                </button>
              </v-layout>
            </p>
          </label-text>
        </v-flex>
        <v-flex xs4><label-text :label="$t(`branding.details.branding_information_section.phone`)" :value="branding.phone"/></v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12>
          <h4>{{ $t(`branding.details.branding_information_section.features`) }}</h4>
        </v-flex>
        <v-flex xs6>
          <label-text
            :label="$t(`branding.details.branding_information_section.panier_bleu`)"
            :value="$t('branding.details.branding_information_section.panier_bleu_value.' + branding.features.panier_bleu)"
          />
        </v-flex>
      </v-layout>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import UpdateBrandingAction from '../actions/UpdateBrandingAction';
import { mapGetters } from 'vuex';

export default {
  name: 'branding-information',
  components: { UpdateBrandingAction },
  mixins: [security, actionErrorTrackable, disableWithError],
  data() {
    return {
      showUpdateBrandingModal: false,
      brandingCategory: { name: {} },
    };
  },
  computed: {
    ...mapGetters('branding', ['types', 'branding']),
  },
  watch: {
    async branding() {
      await this.fetchCategory();
    },
  },
  methods: {
    openUpdateBrandingModal() {
      this.showUpdateBrandingModal = true;
    },
    closeUpdateBrandingModal() {
      this.showUpdateBrandingModal = false;
    },
    async updateBrandingSuccess() {
      await this.fetchCategory();
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'branding.details.branding_information_section.update_action.success' });
      this.closeUpdateBrandingModal();
    },
    showUpdateBrandingFeature() {
      return this.canManageOffer(); // TODO: change permission
    },
    async fetchCategory() {
      this.brandingCategory = (await this.executeAction({ action: 'branding/getCategoryById' }, this.branding.branding_category_id)) || { name: {} };
    },
  },
};
</script>
