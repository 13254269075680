import Vue from 'vue';

const service = {
  async createComment(cardApplicationId, payload) {
    const response = await Vue.prototype.$axios.post(
      `${process.env.VUE_APP_BASE_API_URL}/applications/v1/card-application/${cardApplicationId}/comment`,
      payload,
    );
    return response.data.data.items;
  },

  async getComments(cardApplicationId, page = null, limit = null) {
    let queryString = '';
    if (page) queryString += `page=${page}&`;
    if (limit) queryString += `limit=${limit}`;

    let url = `${process.env.VUE_APP_BASE_API_URL}/applications/v1/card-applications/${cardApplicationId}/comment?${queryString}`;

    const response = await Vue.prototype.$axios.get(url);
    return response.data.data.items;
  },
};

export default service;
