<template>
  <view-main title="page_title.business_suite_promoter_report">
    <v-flex slot="content" class="data-section">
      <h1 hidden>{{ $t('dashboards.business_suite_corporate_program_businesses_report.title') }}</h1>
      <dashboard id-name="corporate_program_businesses" :timerange="timerange" :params="{}" />
    </v-flex>
  </view-main>
</template>

<script>
import { renderErrorTrackable } from '@/mixins';
import Dashboard from './Dashboard.vue';

export default {
  name: 'business-suite-corporate-program-businesses-view',
  mixins: [renderErrorTrackable],
  components: { Dashboard },
  data() {
    return {
      timerange: null,
    };
  },
  created() {
    if (this.$route.query.timerange) {
      this.timerange = this.$route.query.timerange;
    }
  },
};
</script>
