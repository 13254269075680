<template>
  <div class="wallet-definition-logs-view">
    <div v-for="(log, index) in logs" :key="`${log.id}-${index}`" data-test="wallet-definition-log-block">
      <layout-message :key="log.id" :date="log.created">
        <app-name slot="author" :app_key="log.app_key"></app-name>
        <v-layout column slot="content" class="changes">
          <v-flex v-for="(field, index) in log.change_keys" :key="`${log.id}-${index}-${field}`">
            <h5>{{ $t(`wallet_definition.logs.fields.${field}`) }}</h5>
            <v-layout row wrap align-start>
              <table v-if="isRule(field)" class="responsive-table">
                <thead>
                  <tr>
                    <td>{{ $t('wallet_definition.logs.fields.merchant_id') }}</td>
                    <td>{{ $t('wallet_definition.logs.fields.merchant_name') }}</td>
                    <td>{{ $t('wallet_definition.logs.fields.terminal_id') }}</td>
                    <td>{{ $t('wallet_definition.logs.fields.city') }}</td>
                    <td>{{ $t('wallet_definition.logs.fields.state') }}</td>
                    <td>{{ $t('wallet_definition.logs.fields.country') }}</td>
                    <td v-if="containsMerchantNameStartWithRule(log)">{{ $t('wallet_definition.logs.fields.merchant_name_start_with') }}</td>
                    <td v-if="containsMerchantIdStartWithRule(log)">{{ $t('wallet_definition.logs.fields.merchant_id_start_with') }}</td>
                    <td v-if="containsMerchantNameContainsRule(log)">{{ $t('wallet_definition.logs.fields.merchant_name_contains') }}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(subField, subIndex) in getBeforeChange(log, field)"
                    :key="`${log.id}-${index}-${subIndex}`"
                    class="wallet-definition-logs--change-value"
                  >
                    <td>
                      <span :class="determineClass(subField.merchant_id)">{{ subField.merchant_id | orNone }}</span>
                    </td>
                    <td>
                      <span :class="determineClass(subField.merchant_name)">{{ subField.merchant_name | orNone }}</span>
                    </td>
                    <td>
                      <span :class="determineClass(subField.terminal_id)">{{ subField.terminal_id | orNone }}</span>
                    </td>
                    <td>
                      <span :class="determineClass(subField.merchant_city)">{{ subField.merchant_city | orNone }}</span>
                    </td>
                    <td>
                      <span :class="determineClass(subField.merchant_state)">{{ subField.merchant_state | orNone }}</span>
                    </td>
                    <td>
                      <span :class="determineClass(subField.merchant_country)">{{ subField.merchant_country | orNone }}</span>
                    </td>
                    <td v-if="containsMerchantNameStartWithRule(log)">
                      <span :class="determineClass(subField.merchant_name_start_with)">{{ subField.merchant_name_start_with | orNone }}</span>
                    </td>
                    <td v-if="containsMerchantIdStartWithRule(log)">
                      <span :class="determineClass(subField.merchant_id_start_with)">{{ subField.merchant_id_start_with | orNone }}</span>
                    </td>
                    <td v-if="containsMerchantNameContainsRule(log)">
                      <span :class="determineClass(subField.merchant_name_contains)">{{ subField.merchant_name_contains | orNone }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table v-else-if="isLabel(field)" class="responsive-table">
                <thead>
                  <tr>
                    <td>{{ $t('wallet_definition.logs.fields.label_fr') }}</td>
                    <td>{{ $t('wallet_definition.logs.fields.label_en') }}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr class="wallet-definition-logs--change-value">
                    <td>
                      <span :class="determineClass(getBeforeChange(log, field + '.fr'))">{{ getBeforeChange(log, field + '.fr') | orNone }}</span>
                    </td>
                    <td>
                      <span :class="determineClass(getBeforeChange(log, field + '.en'))">{{ getBeforeChange(log, field + '.en') | orNone }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <v-flex class="application-change-history--before" v-else> {{ getBeforeChange(log, field).toString() }} </v-flex>
              <v-flex class="application-change-history--arrow"> <v-icon small>fa-arrow-right</v-icon> </v-flex>
              <table v-if="isRule(field)" class="responsive-table">
                <thead>
                  <tr>
                    <td>{{ $t('wallet_definition.logs.fields.merchant_id') }}</td>
                    <td>{{ $t('wallet_definition.logs.fields.merchant_name') }}</td>
                    <td>{{ $t('wallet_definition.logs.fields.terminal_id') }}</td>
                    <td>{{ $t('wallet_definition.logs.fields.city') }}</td>
                    <td>{{ $t('wallet_definition.logs.fields.state') }}</td>
                    <td>{{ $t('wallet_definition.logs.fields.country') }}</td>
                    <td v-if="containsMerchantNameStartWithRule(log)">{{ $t('wallet_definition.logs.fields.merchant_name_start_with') }}</td>
                    <td v-if="containsMerchantIdStartWithRule(log)">{{ $t('wallet_definition.logs.fields.merchant_id_start_with') }}</td>
                    <td v-if="containsMerchantNameContainsRule(log)">{{ $t('wallet_definition.logs.fields.merchant_name_contains') }}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(subField, subIndex) in getChange(log, field)" :key="`${log.id}-${index}-${subIndex}`">
                    <td>
                      <span :class="{ 'info-not-available': !subField.merchant_id }">{{ subField.merchant_id | orNone }}</span>
                    </td>
                    <td>
                      <span :class="{ 'info-not-available': !subField.merchant_name }">{{ subField.merchant_name | orNone }}</span>
                    </td>
                    <td>
                      <span :class="{ 'info-not-available': !subField.terminal_id }">{{ subField.terminal_id | orNone }}</span>
                    </td>
                    <td>
                      <span :class="{ 'info-not-available': !subField.merchant_city }">{{ subField.merchant_city | orNone }}</span>
                    </td>
                    <td>
                      <span :class="{ 'info-not-available': !subField.merchant_state }">{{ subField.merchant_state | orNone }}</span>
                    </td>
                    <td>
                      <span :class="{ 'info-not-available': !subField.merchant_country }">{{ subField.merchant_country | orNone }}</span>
                    </td>
                    <td v-if="containsMerchantNameStartWithRule(log)">
                      <span :class="{ 'info-not-available': !subField.merchant_name_start_with }">{{
                        subField.merchant_name_start_with | orNone
                      }}</span>
                    </td>
                    <td v-if="containsMerchantIdStartWithRule(log)">
                      <span :class="{ 'info-not-available': !subField.merchant_id_start_with }">{{ subField.merchant_id_start_with | orNone }}</span>
                    </td>
                    <td v-if="containsMerchantNameContainsRule(log)">
                      <span :class="{ 'info-not-available': !subField.merchant_name_contains }">{{ subField.merchant_name_contains | orNone }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table v-else-if="isLabel(field)" class="responsive-table">
                <thead>
                  <tr>
                    <td>{{ $t('wallet_definition.logs.fields.label_fr') }}</td>
                    <td>{{ $t('wallet_definition.logs.fields.label_en') }}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span :class="{ 'info-not-available': !getChange(log, field + '.fr') }">{{
                        getChange(log, field + '.fr').toString() | orNone
                      }}</span>
                    </td>
                    <td>
                      <span :class="{ 'info-not-available': !getChange(log, field + '.en') }">{{
                        getChange(log, field + '.en').toString() | orNone
                      }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <v-flex class="application-change-history--after" v-else> {{ getChange(log, field).toString() }} </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-divider slot="divider" v-if="displayDivider(index)" :key="index"></v-divider>
      </layout-message>
    </div>
  </div>
</template>

<script>
import get from 'lodash/get';
import { renderErrorTrackable } from '@/mixins';
import { mapActions } from 'vuex';
import _reduce from 'lodash/reduce';
import _isNil from 'lodash/isNil';

export default {
  name: 'wallet-definition-logs-table',
  mixins: [renderErrorTrackable],
  props: {
    logs: {
      type: Array,
      required: true,
    },
  },
  methods: {
    ...mapActions('walletDefinition', ['getLogs']),
    isRule(key) {
      return key === 'rules';
    },
    isLabel(key) {
      return key === 'label';
    },
    getChange(item, key) {
      return get(item.changes, key) || '';
    },
    getBeforeChange(item, key) {
      return get(item.before_change, key) || '';
    },
    displayDivider(index) {
      return index + 1 < this.logs.length;
    },
    determineClass(item) {
      return item ? 'wallet-definition-logs--change-value' : 'info-not-available';
    },
    containsMerchantNameStartWithRule(log) {
      return _reduce(
        [...this.getChange(log, 'rules'), ...this.getBeforeChange(log, 'rules')],
        (contains, log) => contains || !_isNil(log.merchant_name_start_with),
        false,
      );
    },
    containsMerchantIdStartWithRule(log) {
      return _reduce(
        [...this.getChange(log, 'rules'), ...this.getBeforeChange(log, 'rules')],
        (contains, log) => contains || !_isNil(log.merchant_id_start_with),
        false,
      );
    },
    containsMerchantNameContainsRule(log) {
      return _reduce(
        [...this.getChange(log, 'rules'), ...this.getBeforeChange(log, 'rules')],
        (contains, log) => contains || !_isNil(log.merchant_name_contains),
        false,
      );
    },
  },
};
</script>
