<template>
  <card :title="$t('wallet_definition.details.rules_section.title')" icon="">
    <template slot="headerZoneRight">
      <label-text
        class="text-xs-center"
        :label="`${$t('wallet_definition.details.rules_section.count')}`"
        :value="walletDefinitionRulesCount || '0'"
      ></label-text>
    </template>
    <template slot="content">
      <wallet-definition-rules-table
        v-if="hasWalletDefinitionRules"
        :items="getWalletDefinitionRules()"
        :wallet-definition="walletDefinition"
      ></wallet-definition-rules-table>
      <label-none v-if="!hasWalletDefinitionRules">{{ $t('system.none') }}</label-none>
    </template>
  </card>
</template>

<script>
import { security, disableWithError, actionErrorTrackable } from '@/mixins';
import WalletDefinitionRulesTable from './WalletDefinitionRulesTable';

import { mapGetters } from 'vuex';

export default {
  name: 'wallet-definition-rules',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { WalletDefinitionRulesTable },
  props: {
    walletDefinitionId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('walletDefinition', ['walletDefinition']),
    ...mapGetters('ui', ['currentLocale']),
    hasWalletDefinitionRules() {
      return this.walletDefinition.rules.length > 0;
    },
    walletDefinitionRulesCount() {
      return this.walletDefinition.rules.length;
    },
  },
  methods: {
    async clear() {
      this.$validator.reset();
    },
    getWalletDefinitionRules() {
      if (this.walletDefinition && this.walletDefinition.rules.length > 0) {
        return this.walletDefinition.rules.map((w) => {
          w.card_program_key = this.walletDefinition.card_program_key;
          return w;
        });
      }
    },
  },
};
</script>
