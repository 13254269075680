<template>
  <view-main title="page_title.vouchers">
    <search-vouchers-form slot="content"></search-vouchers-form>
  </view-main>
</template>

<script>
import { renderErrorTrackable } from '@/mixins';
import SearchVouchersForm from './list/SearchVouchersForm.vue';

export default {
  name: 'vouchers-view',
  mixins: [renderErrorTrackable],
  components: { SearchVouchersForm },
};
</script>
