<template>
  <card-modal
    icon=""
    :title="$t('activation_code.details.activation_code_information_section.actions.update.title')"
    context="updateActivationCode"
    @submit="submit"
    @cancel="cancel"
    cancel_text="activation_code.details.activation_code_information_section.actions.update.button_cancel"
    submit_text="activation_code.details.activation_code_information_section.actions.update.button_save"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-layout row wrap pad-form-row-elements>
          <v-flex sm12 xs12>
            <v-textarea
              counter
              :label="$t('activation_code.details.activation_code_information_section.actions.update.personalized_message')"
              name="personalized_message"
              v-validate="{
                max: 150,
                maxLines: { nbLines: 6 },
              }"
              type="text"
              :error-messages="errors.collect('personalized_message')"
              v-model="updateParams.personalized_message"
              data-vv-name="personalized_message"
              maxlength="150"
              :data-vv-as="$t('activation_code.details.activation_code_information_section.actions.update.personalized_message')"
            ></v-textarea>
          </v-flex>
        </v-layout>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'update-activation-code-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    activationCode: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      updateParams: { id: null, personalized_message: null },
    };
  },
  mounted() {
    this.setUpdateParams();
  },
  watch: {
    activationCode() {
      this.setUpdateParams();
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    clear() {
      this.updateParams = { id: null, personalized_message: null };
      this.$validator.reset();
    },
    success(response) {
      this.clear();
      this.$emit('updated');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();
      if (validationComplete) {
        await this.executeAction(
          {
            action: 'activationCode/updateActivationCode',
            name: 'updateActivationCode',
            success: this.success,
          },
          this.updateParams,
        );
      }
    },
    setUpdateParams() {
      if (this.activationCode) {
        this.updateParams = {
          id: this.activationCode.id,
          personalized_message: this.activationCode.personalized_message,
        };
      }
    },
  },
};
</script>
