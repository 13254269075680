<template>
  <div>
    <shared-stores-table :items="items" :exclude="['destination', 'franchise']" @deleteItem="openDeletePublisherStoreModal" />
    <div class="text-xs-center">
      <v-pagination v-if="publisherStoresPageCount > 1" v-model="pagination" :length="publisherStoresPageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';
import SharedStoresTable from '@/components/shared_tables/SharedStoresTable';

export default {
  name: 'publisher-stores-table',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { SharedStoresTable },
  props: {
    id: {
      type: String,
    },
    items: {
      type: Array,
    },
  },
  data() {
    return {
      pagination: 1,
    };
  },
  mounted() {
    this.pagination = this.publisherStoresPageNumber;
  },
  computed: {
    ...mapGetters('publishers', [
      'publisherStoresKeyword',
      'publisherStoresStatus',
      'publisherStoresType',
      'publisherStoresCategory',
      'publisherStoresWithoutCardAcceptors',
      'publisherStoresPageNumber',
      'publisherStoresPageCount',
    ]),
  },
  watch: {
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'publishers/listPublisherStoresByPublisher',
        },
        {
          id: this.id,
          keyword: this.publisherStoresKeyword,
          status: this.publisherStoresStatus,
          type: this.publisherStoresType,
          category: this.publisherStoresCategory,
          without_card_acceptors: this.publisherStoresWithoutCardAcceptors,
          page: newValue,
        },
      );
    },
  },
  methods: {
    fullAddress(item) {
      if (item.location) {
        return `${item.location.address.street}, ${item.location.address.city}, ${item.location.address.state}, ${item.location.address.country}, ${item.location.address.zip}`;
      }
      return '';
    },
    openDeletePublisherStoreModal(id) {
      this.$emit('publishers_store_delete_action', id);
    },
  },
};
</script>
