<template>
  <table class="responsive-table">
    <thead>
      <tr>
        <td>{{ $t('store.business_table.fields.id') }}</td>
        <td>{{ $t('store.business_table.fields.name') }}</td>
        <td>{{ $t('store.business_table.fields.address') }}</td>
        <td>{{ $t('store.business_table.fields.contact') }}</td>
        <td>{{ $t('store.business_table.fields.bank_account') }}</td>
        <td>{{ $t('store.business_table.fields.created') }}</td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <router-link :to="{ name: 'business_details', params: { businessId: item.id } }">{{ item.id }}</router-link>
        </td>
        <td>{{ item.name }}</td>
        <td>{{ fullAddress(item) }}</td>
        <td>{{ item.contact.name }}</td>
        <td>
          <bank-account-view v-if="item.bank_account_key" :bank-account-key="item.bank_account_key"></bank-account-view>
          <label-none v-else>{{ $t('system.na') }}</label-none>
        </td>
        <td>{{ item.creation_date | formatShortDateInverted | orNotAvailable }}</td>
        <td>
          <link-secondary-action slot="labelTextLink" @click="openUpdateLinkBusinessModal()">{{
            $t('store.business_table.update_link')
          }}</link-secondary-action>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import BankAccountView from '@/components/bank_account/shared/BankAccountView.vue';

export default {
  name: 'store-business-table',
  props: {
    item: {
      type: Object,
    },
  },
  components: { BankAccountView },
  methods: {
    openUpdateLinkBusinessModal() {
      this.$emit('store_business_update_action');
    },
    fullAddress(item) {
      return `${item.address.street}, ${item.address.city}, ${item.address.state}, ${item.address.country}, ${item.address.zip}`;
    },
  },
};
</script>
