<template>
  <div class="establishment-status">
    <span v-if="status" :class="`status-${status}`">{{ $t(`establishment.status.${status}`) }}</span>
  </div>
</template>

<script>
export default {
  name: 'establishment-status-view',
  props: {
    status: {
      type: String,
    },
  },
};
</script>

<style scoped lang="stylus">
@import './../../styles/variables/custom-variables.styl';

.status-active {
  font-weight: bold;
  color: $success-default;
}
.status-deactivated {
  font-weight: bold;
  color: $error-default;
}
</style>
