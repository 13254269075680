<template>
  <card-modal
    icon=""
    :title="$t('address.search_address_action.modal.title')"
    context="searchStoreAddress"
    @submit="submit"
    @cancel="cancel"
    cancel_text="address.search_address_action.modal.button_cancel"
    submit_text="address.search_address_action.modal.button_submit"
    data-test="store--search--address-modal"
    :enable_submit="addressValid"
  >
    <template slot="content">
      <v-input class="v-text-field">
        <gmap-autocomplete
          ref="gmap"
          class="google-place-input"
          :options="{
            componentRestrictions: { country: ['ca'] },
          }"
          @place_changed="placeChanged"
        >
        </gmap-autocomplete>
      </v-input>

      <h5>{{ $t('address.search_address_action.modal.address_information') }}</h5>
      <v-layout class="address-info" column>
        <v-layout row>
          <v-flex grow>
            <span class="font-weight-bold">{{ $t('address.search_address_action.modal.street') }}: </span> {{ address.street || ' ' }}
          </v-flex>
          <button class="clipboard" v-clipboard:copy="address.street">
            <v-icon small>file_copy</v-icon>
          </button>
        </v-layout>

        <v-layout row>
          <v-flex grow>
            <span class="font-weight-bold">{{ $t('address.search_address_action.modal.city') }}: </span> {{ address.city || ' ' }}
          </v-flex>
          <button class="clipboard" v-clipboard:copy="address.city">
            <v-icon small>file_copy</v-icon>
          </button>
        </v-layout>

        <v-layout row>
          <v-flex grow>
            <span class="font-weight-bold">{{ $t('address.search_address_action.modal.state') }}: </span> {{ address.state || ' ' }}
          </v-flex>
          <button class="clipboard" v-clipboard:copy="address.state">
            <v-icon small>file_copy</v-icon>
          </button>
        </v-layout>

        <v-layout row>
          <v-flex grow>
            <span class="font-weight-bold">{{ $t('address.search_address_action.modal.country') }}: </span> {{ address.country || ' ' }}
          </v-flex>
          <button class="clipboard" v-clipboard:copy="address.country">
            <v-icon small>file_copy</v-icon>
          </button>
        </v-layout>

        <v-layout row>
          <v-flex grow>
            <span class="font-weight-bold">{{ $t('address.search_address_action.modal.zip') }}: </span> {{ address.zip || ' ' }}
          </v-flex>
          <button class="clipboard" v-clipboard:copy="address.zip">
            <v-icon small>file_copy</v-icon>
          </button>
        </v-layout>

        <v-layout row>
          <v-flex grow>
            <span class="font-weight-bold">{{ $t('address.search_address_action.modal.plus_code') }}: </span> {{ address.plus_code || ' ' }}
          </v-flex>
          <button class="clipboard" v-clipboard:copy="address.plus_code">
            <v-icon small>file_copy</v-icon>
          </button>
        </v-layout>
      </v-layout>
    </template>
  </card-modal>
</template>

<script>
import _get from 'lodash/get';

export default {
  name: 'search-address-modal',
  data() {
    return {
      address: {},
      coordinates: {},
      addressValid: false,
    };
  },
  methods: {
    clear() {
      this.address = {};
      this.coordinates = {};
      this.$refs.gmap.$refs.input.value = '';
    },
    placeChanged(event) {
      if (!event || !event.address_components) {
        this.addressValid = false;
        return;
      }

      let street_number, street, city, state, country, zip;
      let plus_code = _get(event, 'plus_code.global_code');

      let latitude = null;
      let longitude = null;
      if (event.geometry && event.geometry.location) {
        latitude = event.geometry.location.lat();
        longitude = event.geometry.location.lng();
      }

      event.address_components.forEach((address_item) => {
        if (address_item.types.indexOf('street_number') !== -1) {
          street_number = address_item.long_name;
          return;
        }

        if (address_item.types.indexOf('route') !== -1) {
          street = address_item.long_name;
          return;
        }

        if (address_item.types.indexOf('locality') !== -1) {
          city = address_item.long_name;
          return;
        }

        if (address_item.types.indexOf('administrative_area_level_1') !== -1) {
          state = address_item.short_name;
          return;
        }

        if (address_item.types.indexOf('country') !== -1) {
          country = address_item.short_name;
          return;
        }

        if (address_item.types.indexOf('postal_code') !== -1) {
          zip = address_item.short_name;
          return;
        }

        if (address_item.types.indexOf('plus_code') !== -1) {
          plus_code = address_item.short_name;
          return;
        }
      });

      if (street_number || street || city || state || country || zip || plus_code) {
        this.addressValid = true;

        this.address = {
          street: street_number || street ? [street_number, street].join(' ') : undefined,
          city,
          state,
          country,
          zip,
          plus_code,
        };

        if (longitude !== null && latitude !== null) {
          this.coordinates = {
            longitude,
            latitude,
          };
        }
      }
    },
    submit() {
      this.$emit('updated', { address: this.address, coordinates: this.coordinates });
      this.clear();
    },
    cancel() {
      this.$emit('close');
      this.clear();
    },
  },
};
</script>

<style scoped lang="stylus">
.address-info {
  padding: 16px 0px;

  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
}
</style>
