<template>
  <span>{{ publisher.name + ' (' + publisher.id + ')' }}</span>
</template>

<script>
import { actionErrorTrackable, renderErrorTrackable } from '@/mixins';

const EMPTY_PUBLISHER = { name: '...', id: '...' };

export default {
  name: 'publishers-name',
  props: {
    id: {
      type: String,
      required: false,
    },
  },
  data: () => {
    return {
      publisher: EMPTY_PUBLISHER,
    };
  },
  methods: {
    getPublisherSuccess(publisher) {
      this.publisher = publisher || EMPTY_PUBLISHER;
    },
  },
  watch: {
    id(newId) {
      if (newId) {
        this.publisher = EMPTY_PUBLISHER;
        this.executeAction({ action: 'publishers/getPublisher', success: this.getPublisherSuccess }, newId);
      } else {
        this.publisher = EMPTY_PUBLISHER;
      }
    },
  },
  mixins: [renderErrorTrackable, actionErrorTrackable],
  created: function() {
    if (this.id) {
      this.executeAction({ action: 'publishers/getPublisher', success: this.getPublisherSuccess }, this.id);
    }
  },
};
</script>
