<template>
  <view-main title="page_title.card_activities">
    <v-flex slot="content" class="data-section">
      <h1 hidden>{{ $t('dashboards.card_activities.title') }}</h1>
      <dashboard id-name="card_activities_report" :timerange="timerange" :params="params" />
    </v-flex>
  </view-main>
</template>

<script>
import { renderErrorTrackable } from '@/mixins';
import Dashboard from './Dashboard.vue';

export default {
  name: 'card-activities-report-view',
  mixins: [renderErrorTrackable],
  components: { Dashboard },
  data() {
    return {
      timerange: null,
      cardholder_id: null,
      user_id: null,
      activity_id: null,
    };
  },
  created() {
    if (this.$route.query.timerange) {
      this.timerange = this.$route.query.timerange;
    }

    if (this.$route.query.cardholder_id) {
      this.cardholder_id = this.$route.query.cardholder_id;
    }

    if (this.$route.query.user_id) {
      this.user_id = this.$route.query.user_id;
    }

    if (this.$route.query.activity_id) {
      this.activity_id = this.$route.query.activity_id;
    }
  },
  computed: {
    params() {
      const theParams = {};

      if (this.cardholder_id) {
        theParams.cardholder_id = this.cardholder_id;
      }

      if (this.user_id) {
        theParams.user_id = this.user_id;
      }

      if (this.activity_id) {
        theParams.activity_id = this.activity_id;
      }

      return theParams;
    },
  },
};
</script>
