<template>
  <table class="responsive-table" id="user-consumer-gift-card-orders-table">
    <thead>
      <tr>
        <td>{{ $t('user.consumer_gift_card_orders_table.fields.creation_date') }}</td>
        <td>{{ $t('user.consumer_gift_card_orders_table.fields.order_id') }}</td>
        <td>{{ $t('user.consumer_gift_card_orders_table.fields.partner_id') }}</td>
        <td>{{ $t('user.consumer_gift_card_orders_table.fields.offer_id') }}</td>
        <td>{{ $t('user.consumer_gift_card_orders_table.fields.amount') }}</td>

        <td>{{ $t('user.consumer_gift_card_orders_table.fields.order_status') }}</td>
        <td>{{ $t('user.consumer_gift_card_orders_table.fields.activation_code_id') }}</td>
        <td>{{ $t('user.consumer_gift_card_orders_table.fields.voucher_id') }}</td>

        <td>{{ $t('user.consumer_gift_card_orders_table.fields.voucher_status') }}</td>
        <td>{{ $t('user.consumer_gift_card_orders_table.fields.date_activated') }}</td>
        <td>{{ $t('user.consumer_gift_card_orders_table.fields.activated_by') }}</td>
        <td>{{ $t('user.consumer_gift_card_orders_table.fields.loaded_on_card') }}</td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in items" :key="index">
        <td>
          <h5>{{ $t('user.consumer_gift_card_orders_table.fields.creation_date') }}:</h5>
          <span>{{ item.creation_date | formatDateHourMin }}</span>
        </td>
        <td>
          <h5>{{ $t('user.consumer_gift_card_orders_table.fields.order_id') }}:</h5>
          <a v-if="item.status === 'completed'" target="_blank" :href="stripeSearchUrl(item.stripe_transaction_id)">
            {{ item.id }}
          </a>
          <span v-else>
            {{ item.id }}
          </span>
        </td>
        <td>
          <h5>{{ $t('user.consumer_gift_card_orders_table.fields.partner_id') }}:</h5>
          <span>{{ item.partner_id }}</span>
        </td>
        <td>
          <h5>{{ $t('user.consumer_gift_card_orders_table.fields.offer_id') }}:</h5>
          <link-secondary-route :can-access="canViewOffer()" :route-name="'offers_details'" :route-params="{ offerId: item.offer_id }">
            {{ item.offer_id }}
          </link-secondary-route>
        </td>
        <td>
          <h5>{{ $t('user.consumer_gift_card_orders_table.fields.amount') }}:</h5>
          <span>{{ item.amount | currency | dollarSignI18n }}</span>
        </td>

        <td>
          <h5>{{ $t('user.consumer_gift_card_orders_table.fields.order_status') }}:</h5>
          <span :class="`account-consumer-gift-card-orders-status-${item.status}`">{{
            $t(`code.consumer_gift_card_orders.status.${item.status}`)
          }}</span>
        </td>

        <td>
          <h5>{{ $t('user.consumer_gift_card_orders_table.fields.activation_code_id') }}:</h5>
          <link-secondary-route
            v-if="item.activation_code_id"
            :can-access="canViewOffer()"
            :route-name="'activation_code_details'"
            :route-params="{ activationCodeId: item.activation_code_id }"
          >
            {{ item.activation_code_id }}
          </link-secondary-route>
          <label-none v-else>{{ $t('system.na') }}</label-none>
        </td>
        <td>
          <h5>{{ $t('user.consumer_gift_card_orders_table.fields.voucher_id') }}:</h5>
          <link-secondary-route
            v-if="item.voucher_id"
            :can-access="canViewOffer()"
            :route-name="'voucher_details'"
            :route-params="{ voucherId: item.voucher_id }"
          >
            {{ item.voucher_id }}
          </link-secondary-route>
          <label-none v-else>{{ $t('system.na') }}</label-none>
        </td>
        <td>
          <h5>{{ $t('user.consumer_gift_card_orders_table.fields.voucher_status') }}:</h5>

          <voucher-status-view :status="item.voucher.status" />
        </td>
        <td>
          <h5>{{ $t('user.consumer_gift_card_orders_table.fields.date_activated') }}:</h5>

          {{ item.voucher.activation_date | formatDateHourMin | orNotAvailable }}
        </td>
        <td>
          <h5>{{ $t('user.consumer_gift_card_orders_table.fields.activated_by') }}:</h5>
          <account-email
            v-if="item.voucher.account_id"
            :id="item.voucher.account_id"
            :show-label="false"
            :generate-link-to-card="true"
          ></account-email>
          <label-none v-else>{{ $t('system.na') }}</label-none>
        </td>
        <td>
          <h5>{{ $t('user.consumer_gift_card_orders_table.fields.loaded_on_card') }}:</h5>
          <cardholder-current-card v-if="item.voucher.cardholder_id" :cardholder-id="item.voucher.cardholder_id" />
          <label-none v-else>{{ $t('system.na') }}</label-none>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import AccountEmail from '@/components/account/AccountEmail.vue';
import CardholderCurrentCard from '@/components/system/Cards/CardholderCurrentCard.vue';
import VoucherStatusView from '@/components/voucher/VoucherStatusView.vue';

export default {
  name: 'user-consumer-gift-card-orders-table',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { VoucherStatusView, AccountEmail, CardholderCurrentCard },
  props: {
    items: {
      type: Array,
    },
  },
  methods: {
    stripeSearchUrl(id) {
      if (!id) {
        return '';
      }

      if (id.startsWith('pi_')) {
        return `${process.env.VUE_APP_STRIPE_PAYMENTS_URL}/${id}`;
      }

      return `${process.env.VUE_APP_STRIPE_INVOICES_URL}/${id}`;
    },
  },
};
</script>
