import Vue from 'vue';

const service = {
  async generateCluvioJwt({ report_name }) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/cluvio/v2/jwt`, { report_name });
    return response.data.data;
  },
};

export default service;
