<template>
  <card-modal
    icon=""
    :title="$t('instant_card_batches.create_action.title')"
    context="createBatch"
    @submit="submit"
    @cancel="cancel"
    cancel_text="instant_card_batches.create_action.button_cancel"
    submit_text="instant_card_batches.create_action.button_save"
    data-test="project--add-modal"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <p>{{ $t('instant_card_batches.create_action.description') }}</p>
        <v-text-field
          autofocus
          v-model="batch.name"
          :label="$t('instant_card_batches.create_action.name') + ' *'"
          name="name"
          v-validate="'required|max:100'"
          :error-messages="errors.collect('name')"
          data-vv-name="name"
          maxlength="100"
          :data-vv-as="$t('instant_card_batches.create_action.name')"
          data-test="add-batch--name"
          required
        ></v-text-field>
        <h-autocomplete
          @itemsFetched="instantCardProjectUpdated"
          v-model="batch.instant_card_project_id"
          :label="$t('instant_card_batches.create_action.project')"
          v-validate="'max:50'"
          :error-messages="errors.collect('project')"
          name="project"
          data-vv-name="project"
          data-vv-as="project"
          search-action="instantCardProject/autocompleteSearch"
          data-test="add_batch--project"
          no-filter
          clearable
        >
        </h-autocomplete>
        <v-text-field
          v-model="batch.quantity"
          :label="$t('instant_card_batches.create_action.quantity') + ' *'"
          name="quantity"
          v-validate="'required|max_value:5000|min_value:2'"
          :error-messages="errors.collect('quantity')"
          data-vv-name="quantity"
          :data-vv-as="$t('instant_card_batches.create_action.quantity')"
          data-test="add-batch--quantity"
          required
        ></v-text-field>
        <v-select
          name="cardPackage"
          v-model="batch.card_package_id"
          :rules="selectCardPackageRules()"
          :items="cardPackageOptions"
          item-text="text"
          item-value="id"
          :label="$t(`instant_card_batches.create_action.card_package`) + ' *'"
          data-test="add_batch--card-package"
          required
        ></v-select>
        <v-checkbox
          name="pre_activation_required"
          v-model="batch.pre_activation_required"
          :label="$t(`instant_card_batches.create_action.pre_activation_required`)"
          :error-messages="errors.collect('pre_activation_required')"
          data-vv-name="pre_activation_required"
          :data-vv-as="$t(`instant_card_batches.create_action.pre_activation_required`)"
          type="checkbox"
          :disabled="preActivationRequiredDisabled"
        ></v-checkbox>
        <h-autocomplete
          v-model="batch.business_id"
          :label="$t('instant_card_batches.create_action.business') + ' *'"
          v-validate="'required'"
          :error-messages="errors.collect('business')"
          name="business"
          data-vv-name="business"
          data-vv-as="business"
          search-action="business/autocompleteSearch"
          data-test="add-batch--business"
          no-filter
        >
        </h-autocomplete>
        <a v-if="batch.business_id" @click="fillAddressFieldsFromBusiness">{{ $t('instant_card_batches.create_action.business_address_note') }}</a>
        <h-autocomplete
          v-model="establishment_id"
          :label="$t('instant_card_batches.create_action.establishment')"
          :error-messages="errors.collect('establishment_id')"
          :alternate-text="getEstablishmentLabel"
          alternate-id="business_id"
          name="establishment_id"
          data-vv-name="establishment_id"
          data-vv-as="establishment_id"
          search-action="space/autocompleteSearchSpacePartnerEstablishments"
          data-test="form-instant-card-batches--establishment"
          no-filter
          clearable
          @selectedValue="updateEstablishment"
        >
        </h-autocomplete>
        <a v-if="establishment && establishment.business_id" @click="fillAddressFieldsFromEstablishment">{{
          $t('instant_card_batches.create_action.establishment_address_note')
        }}</a>
        <h4>{{ $t('instant_card_batches.create_action.attn_title') }}</h4>
        <v-text-field
          name="attn"
          v-model="attn"
          v-validate="'max:50'"
          :label="$t(`instant_card_batches.create_action.attn.name`)"
          :error-messages="errors.collect('attn')"
          data-vv-name="attn"
          :data-vv-as="$t(`instant_card_batches.create_action.attn.name`)"
          maxlength="50"
          counter
          data-test="add_batch--attn"
        ></v-text-field>
        <v-text-field
          name="phone"
          v-model="phone"
          v-validate="'max:20'"
          :label="$t(`instant_card_batches.create_action.attn.phone`)"
          :error-messages="errors.collect('attn')"
          data-vv-name="phone"
          :data-vv-as="$t(`instant_card_batches.create_action.attn.phone`)"
          maxlength="20"
          counter
          data-test="add_batch--phone"
        ></v-text-field>
        <v-text-field
          name="business_name"
          v-model="business_name"
          v-validate="'max:100'"
          :label="$t(`instant_card_batches.create_action.attn.business_name`)"
          :error-messages="errors.collect('attn')"
          data-vv-name="business_name"
          :data-vv-as="$t(`instant_card_batches.create_action.attn.business_name`)"
          maxlength="100"
          counter
          data-test="add_batch--business_name"
        ></v-text-field>
        <h4>{{ $t('instant_card_batches.create_action.address_title') }}</h4>
        <v-text-field
          name="street"
          v-model="street"
          v-validate="'required|max:50'"
          :label="$t(`instant_card_batches.create_action.address.street`) + ' *'"
          :error-messages="errors.collect('street')"
          data-vv-name="street"
          :data-vv-as="$t(`instant_card_batches.create_action.address.street`)"
          maxlength="50"
          counter
          data-test="add_batch--street"
          required
        ></v-text-field>
        <v-text-field
          name="city"
          v-model="city"
          v-validate="'required|max:50'"
          :label="$t(`instant_card_batches.create_action.address.city`) + ' *'"
          :error-messages="errors.collect('city')"
          data-vv-name="city"
          :data-vv-as="$t(`instant_card_batches.create_action.address.city`)"
          counter
          maxlength="50"
          data-test="add_batch--city"
          required
        ></v-text-field>
        <v-select
          name="state"
          v-model="state"
          v-validate="'required'"
          :error-messages="errors.collect('state')"
          data-vv-name="state"
          :data-vv-as="$t(`instant_card_batches.create_action.address.state`)"
          :items="stateOptions"
          item-text="name"
          item-value="abbr"
          :label="$t(`instant_card_batches.create_action.address.state`) + ' *'"
          data-test="add_batch--state"
        ></v-select>
        <v-select
          name="country"
          v-model="country"
          v-validate="'required'"
          :error-messages="errors.collect('country')"
          data-vv-name="country"
          :data-vv-as="$t(`instant_card_batches.create_action.address.country`)"
          :items="countryOptions"
          item-text="name"
          item-value="abbr"
          :label="$t(`instant_card_batches.create_action.address.country`) + ' *'"
          data-test="add_batch--country"
        ></v-select>
        <v-text-field
          name="zip"
          v-mask-zip
          v-model="zip"
          v-validate="{ required: true, regex: /^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z] [0-9][ABCEGHJ-NPRSTV-Z][0-9]$/ }"
          :label="$t(`instant_card_batches.create_action.address.zip`) + ' *'"
          :error-messages="errors.collect('zip')"
          data-vv-name="zip"
          :data-vv-as="$t(`instant_card_batches.create_action.address.zip`)"
          data-test="add_batch--zip"
        ></v-text-field>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _map from 'lodash/map';

export default {
  name: 'create-batch-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  data() {
    return {
      instantCardProject: null,
      batch: {
        card_package_id: null,
        pre_activation_required: false,
      },
      street: '',
      city: '',
      state: 'QC',
      country: 'CA',
      zip: '',
      attn: '',
      phone: '',
      business_name: '',
      countryOptions: [
        {
          name: this.$t('country.canada'),
          abbr: 'CA',
        },
      ],
      stateOptions: [
        {
          name: this.$t('state.alberta'),
          abbr: 'AB',
        },
        {
          name: this.$t('state.british_columbia'),
          abbr: 'BC',
        },
        {
          name: this.$t('state.manitoba'),
          abbr: 'MB',
        },
        {
          name: this.$t('state.new_brunswick'),
          abbr: 'NB',
        },
        {
          name: this.$t('state.newfoundland_labrador'),
          abbr: 'NL',
        },
        {
          name: this.$t('state.nova_scotia'),
          abbr: 'NS',
        },
        {
          name: this.$t('state.northwest_territories'),
          abbr: 'NT',
        },
        {
          name: this.$t('state.nunavut'),
          abbr: 'NU',
        },
        {
          name: this.$t('state.ontario'),
          abbr: 'ON',
        },
        {
          name: this.$t('state.prince_edward_island'),
          abbr: 'PE',
        },
        {
          name: this.$t('state.quebec'),
          abbr: 'QC',
        },
        {
          name: this.$t('state.saskatchewan'),
          abbr: 'SK',
        },
        {
          name: this.$t('state.yukon'),
          abbr: 'YT',
        },
      ],
      establishment: {},
      establishment_id: '',
      cardPackageOptions: [],
    };
  },
  async created() {
    await this.executeAction(
      {
        action: 'context/listCardPackages',
      },
      {
        page: 1,
        limit: 100,
        params: {
          card_program_type: 'instant',
          status: 'active',
          bulk: true,
        },
      },
    );
  },
  watch: {
    cardPackages(val) {
      if (val) {
        this.cardPackageOptions = this.mapCardPackages(this.cardPackages);
      }
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('context', ['cardPackages']),
    preActivationRequiredDisabled() {
      const type = _get(this.instantCardProject, 'type', null);
      return type === 'blank' || type === 'reward';
    },
  },
  methods: {
    clear() {
      this.batch = {
        card_package_id: null,
        pre_activation_required: false,
      };
      this.establishment = {};
      this.$validator.reset();
    },
    instantCardProjectUpdated(instantCardProjects) {
      const instantCardProject = _find(instantCardProjects, (i) => i.id === this.batch.instant_card_project_id);

      this.instantCardProject = instantCardProject;
      this.batch.pre_activation_required = _get(instantCardProject, 'type') === 'blank';
    },

    selectCardPackageRules() {
      return [(v) => !!v || this.$t('validation.select.card_package')];
    },
    mapCardPackages() {
      return _map(this.cardPackages, (cardPackage) => {
        cardPackage.text = `${cardPackage.name} (${cardPackage.id})`;
        return cardPackage;
      });
    },
    async fillAddressFieldsFromEstablishment() {
      await this.fillAddressFields(this.establishment.business_id);
    },
    async fillAddressFieldsFromBusiness() {
      await this.fillAddressFields(this.batch.business_id);
    },
    async fillAddressFields(business_id) {
      const business = await this.executeAction(
        {
          action: 'business/getBusiness',
        },
        business_id,
      );

      this.street = business.address.street;
      this.city = business.address.city;
      this.state = business.address.state;
      this.country = business.address.country;
      this.zip = business.address.zip;

      this.attn = business.contact.name;
      this.phone = business.contact.phone;
      this.business_name = business.name;
    },
    success(response) {
      this.clear();
      this.$router.push({
        name: 'instant_card_batch_details',
        params: {
          batchId: response.id,
        },
      });
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    buildPayload() {
      const payload = this.batch;

      payload.delivery = {
        attn: this.attn,
        phone: this.phone,
        business_name: this.business_name,
      };

      payload.delivery_address = {
        street: this.street,
        city: this.city,
        state: this.state || 'QC',
        country: this.country || 'CA',
        zip: this.zip,
      };

      if (this.establishment && this.establishment.id) {
        payload.establishment_id = this.establishment.id;
      }

      return payload;
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        await this.executeAction(
          {
            action: 'instantCardBatch/createBatch',
            name: 'createBatch',
            success: this.success,
          },
          this.buildPayload(),
        );
      }
    },
    getEstablishmentLabel(establishment) {
      return `${establishment.partner_id} - ${establishment.business_name}`;
    },
    updateEstablishment(establishment) {
      this.establishment = establishment || {};
    },
  },
};
</script>
