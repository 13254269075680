<template>
  <card-modal
    class=""
    icon="fal fa-exchange"
    :title="$t('card.wallet_transactions.cancel_transaction_action.title')"
    context="cancelTransaction"
    @submit="submit"
    @cancel="cancel"
    cancel_text="card.wallet_transactions.cancel_transaction_action.button_cancel"
    submit_text="card.wallet_transactions.cancel_transaction_action.button_submit"
    :submit_working="working"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-layout row wrap pad-form-row-elements>
          <v-flex xs12 sm4>
            <label-text :label="$t('card.wallet_transactions.cancel_transaction_action.form.transaction_id')" :value="transactionId"></label-text>
          </v-flex>
          <v-flex xs12 sm4>
            <label-text
              :label="$t('card.wallet_transactions.cancel_transaction_action.form.transaction_date')"
              :value="trxDate | formatShortDateTimeInverted"
            ></label-text>
          </v-flex>
          <v-flex xs12 sm4>
            <label-text :label="$t('card.wallet_transactions.cancel_transaction_action.form.transaction_type')" :value="trxType"></label-text>
          </v-flex>
        </v-layout>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';

export default {
  name: 'cancel-transaction-action',
  mixins: [actionErrorTrackable, security],
  props: {
    cardPublicToken: {
      type: String,
      required: true,
    },
    transaction: {
      type: Object,
      required: true,
    },
  },
  computed: {
    transactionId() {
      return this.transaction.transaction_id;
    },
    trxType() {
      return this.transaction.card_trx_type;
    },
    trxDate() {
      return this.transaction.transaction_date;
    },
  },
  methods: {
    cancel() {
      this.clearAllError();
      this.$emit('close');
    },
    success() {
      this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'card.wallet_transactions.cancel_transaction_action.success' });
      this.$emit('action');
      this.$emit('close');
    },
    async submit() {
      this.clearAllError();
      await this.executeAction(
        {
          action: 'card/cancelTransaction',
          success: this.success,
          name: 'cancelTransaction',
        },
        {
          card_public_token: this.cardPublicToken,
          transaction_id: this.transactionId,
        },
      );
    },
  },
};
</script>
