<template>
  <div>
    <table class="responsive-table">
      <thead>
        <tr>
          <td>{{ $t('space.spaces_table.id') }}</td>
          <td>{{ $t('space.spaces_table.program') }}</td>
          <td>{{ $t('space.spaces_table.name') }}</td>
          <td>{{ $t('space.spaces_table.business') }}</td>
          <td>{{ $t('space.spaces_table.is_public') }}</td>
          <td>{{ $t('space.spaces_table.region') }}</td>
          <td>{{ $t('space.spaces_table.card_selector.title') }}</td>
          <td>{{ $t('space.spaces_table.url') }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>
            <h5>{{ $t('space.spaces_table.id') }}:</h5>
            <span>
              <router-link :to="{ name: 'space_details', params: { spaceId: item.id } }">
                {{ item.id }}
              </router-link>
            </span>
          </td>
          <td>
            <h5>{{ $t('space.spaces_table.program') }}:</h5>
            <span>{{ item.partner_id }}</span>
          </td>
          <td>
            <h5>{{ $t('space.spaces_table.name') }}:</h5>
            <span>{{ item.name }}</span>
          </td>
          <td>
            <h5>{{ $t('space.spaces_table.business') }}:</h5>
            <span>
              <router-link v-if="item.business_id" :to="{ name: 'business_details', params: { businessId: item.business_id } }">
                {{ item.business_id }}
              </router-link>
              <label-none v-else>{{ $t('system.na') }}</label-none>
            </span>
          </td>

          <td>
            <h5>{{ $t('space.spaces_table.is_public') }}:</h5>
            <span :class="`spaces-table-visibility-public-${!item.private_space}`">{{
              $t(`space.spaces_table.is_public_space.${!item.private_space}`)
            }}</span>
          </td>
          <td>
            <h5>{{ $t('space.spaces_table.region') }}:</h5>
            {{ item.partner_region }}
          </td>
          <td>
            <h5>{{ $t('space.spaces_table.card_selector.title') }}:</h5>
            <span v-if="extractCardProgramValue(item)">{{ extractCardProgramValue(item) }}</span>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('space.spaces_table.url') }}:</h5>
            <span>
              <a v-if="item.url" :href="item.url" target="blank">{{ item.url }}</a>
              <label-none v-else>{{ $t('system.na') }}</label-none>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-xs-center">
      <v-pagination v-if="listPageCount > 1" v-model="pagination" :length="listPageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import { mapGetters } from 'vuex';
import _get from 'lodash/get';

export default {
  name: 'spaces-table',
  mixins: [actionErrorTrackable, security],
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      pagination: 1,
    };
  },
  mounted() {
    this.pagination = this.listPageNumber;
  },
  computed: {
    ...mapGetters('space', ['listPageCount', 'listPageNumber', 'listKeyword', 'listRegion', 'listBusinessId']),
  },
  watch: {
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'space/listSpacesWithPartner',
        },
        {
          page: newValue,
          keyword: this.listKeyword,
          business_id: this.listBusinessId,
          region: this.listRegion,
        },
      );
    },
  },
  methods: {
    extractCardProgramValue(item) {
      const isHavingClassical = _get(item, 'partner_card_packages.en');
      const isHavingInstant = _get(item, 'partner_instant_card_packages.en');

      if (isHavingClassical && isHavingInstant) {
        return this.$t('space.spaces_table.card_selector.values.classical_or_instant');
      } else if (isHavingClassical) {
        return this.$t('space.spaces_table.card_selector.values.classical_only');
      } else if (isHavingInstant) {
        return this.$t('space.spaces_table.card_selector.values.instant_only');
      }

      return null;
    },
  },
};
</script>
