<template>
  <card icon="" :title="$t('user.aml_note_section.title')" class="card-aml-notes-section hc-aml-notes-section">
    <template slot="headerZoneRight">
      <label-text class="text-sm-center" :label="$t('user.aml_note_section.note_count')" :value="amlNotes.length || '0'" />

      <div v-if="showAddAmlNoteFeature()" class="card-header-divider--vertical hidden-xs-only">
        <v-divider vertical></v-divider>
      </div>

      <label-text v-if="showAddAmlNoteFeature()" :label="`${$t('user.aml_note_section.action')}`" value="3">
        <link-secondary-action slot="labelTextLink" @click="showModalNote">{{ $t('user.aml_note_section.add_note') }}</link-secondary-action>
      </label-text>
    </template>

    <template slot="content">
      <modal-action v-if="showAddAmlNoteFeature()" :show-modal="showNoteModal">
        <create-aml-note-action :account-id="account.id" @close="closeModalNote" @action="reloadPage" />
      </modal-action>

      <delete-with-reason-modal
        v-if="showAddAmlNoteFeature()"
        :show-modal="showDeleteNoteModal"
        object-type="Note"
        @confirm="onDeleteNote"
        @close="hideDeleteNoteModal"
      />

      <layout-aml-notes v-if="amlNotes && amlNotes.length > 0" :items="amlNotes" @delete="deleteNote" />
      <label-none v-else>{{ $t('user.aml_note_section.no_found') }}</label-none>
    </template>
  </card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { disableWithError, actionErrorTrackable, security } from '@/mixins';
import LayoutAmlNotes from './LayoutAmlNotes';
import CreateAmlNoteAction from '@/components/system/AML/CreateAmlNoteAction';

export default {
  name: 'user-aml-notes',
  mixins: [actionErrorTrackable, disableWithError, security],
  components: { LayoutAmlNotes, CreateAmlNoteAction },
  data() {
    return {
      showNoteModal: false,
    };
  },
  async created() {
    await Promise.all([this.listNoteTypes(), this.listNoteCategories(), this.fetchAmlNotes()]);
  },
  watch: {
    async account() {
      await this.fetchAmlNotes();
    },
  },
  computed: {
    ...mapGetters('aml', ['amlNotes', 'currentNote', 'showDeleteNoteModal']),
    ...mapGetters('account', ['account']),
  },
  methods: {
    ...mapActions('aml', ['getAmlNotes', 'hideDeleteNoteModal', 'listNoteTypes', 'listNoteCategories']),
    async fetchAmlNotes() {
      await this.getAmlNotes({ account_id: this.account.id });
    },
    async deleteNote(note) {
      await this.executeAction({ action: 'aml/showDeleteNoteModal' }, note);
    },
    async onDeleteNote({ reason }) {
      const params = {
        key: this.currentNote.key,
        reason,
        account_id: this.account.id,
      };

      await this.executeAction({ action: 'aml/deleteNote' }, params);
    },
    showModalNote() {
      this.showNoteModal = true;
    },
    closeModalNote() {
      this.showNoteModal = false;
    },
    reloadPage() {
      this.showNoteModal = false;
    },
    showAddAmlNoteFeature() {
      return this.canManageAmlProgram();
    },
  },
};
</script>
