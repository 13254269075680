<template>
  <card :title="$t('instant_card_batches.details.batch_information_section.title')" icon="fal fa-box" id="batch-information">
    <template slot="headerZoneRight">
      <label-text :label="`${$t('instant_card_batches.details.batch_information_section.id')}`" :value="batch.id">
        <p slot="labelTextLink" class="label-text--value">
          <v-layout row class="smoke-business-info--contact-email--single">
            {{ batch.id }}
            <button class="clipboard" v-clipboard:copy="batch.id">
              <v-icon small>file_copy</v-icon>
            </button>
          </v-layout>
        </p>
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>

      <label-text :label="`${$t('instant_card_batches.details.batch_information_section.program')}`" :value="batch.partner_id"></label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>

      <label-text :label="`${$t('instant_card_batches.details.batch_information_section.project')}`">
        <router-link
          slot="labelTextLink"
          v-if="batch.instant_card_project_id"
          :to="{ name: 'instant_card_project_details', params: { projectId: batch.instant_card_project_id } }"
        >
          <span>{{ batch.instant_card_project_name }} ({{ batch.instant_card_project_id }})</span>
        </router-link>
        <label-none v-else>{{ $t('system.na') }}</label-none>
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>

      <label-text
        :label="`${$t('instant_card_batches.details.batch_information_section.pre_activation_required')}`"
        :value="$t(`boolean.${batch.pre_activation_required}`)"
      ></label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>

      <label-text
        :label="`${$t('instant_card_batches.details.batch_information_section.created')}`"
        :value="batch.creation_date | formatShortDateInverted | orNotAvailable"
      ></label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>

      <label-text
        :label="`${$t('instant_card_batches.details.batch_information_section.status')}`"
        :value="$t(`instant_card_batches.status.${batch.status}`)"
        :class-array="[`batch-${batch.status}`]"
      />
      <div v-if="showActionBtn()" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>

      <link-secondary-action v-if="showActionBtn()" slot="labelTextLink">
        <button-secondary-actions :text="$t(`instant_card_batches.details.batch_information_section.actions`)" :actions="actions">
        </button-secondary-actions>
      </link-secondary-action>

      <modal-action :show-modal="showUpdateBatchModal || showCancelBatchModal || showApproveBatchModal || showMarkAsDeliveredModal"
        ><update-batch-action
          v-if="showUpdateBatchModal"
          :batch="batch"
          @close="closeUpdateBatchModal"
          @updated="updateBatchSuccess"
        ></update-batch-action>
        <cancel-batch-action
          v-if="showCancelBatchModal"
          :batch="batch"
          @close="closeCancelBatchModal"
          @cancelled="cancelBatchSuccess"
        ></cancel-batch-action>
        <approve-batch-action
          v-if="showApproveBatchModal"
          :batch="batch"
          @close="closeApproveBatchModal"
          @approved="approveBatchSuccess"
        ></approve-batch-action>
        <modal-confirm
          v-if="showMarkAsDeliveredModal"
          :show-modal="showMarkAsDeliveredModal"
          :title="$t('instant_card_batches.mark_as_delivered_action.title')"
          context="markBatchAsDelivered"
          :text="$t('instant_card_batches.mark_as_delivered_action.message', { batch_id: batch.id })"
          :cancel_text="'instant_card_batches.mark_as_delivered_action.button_cancel'"
          :submit_text="'instant_card_batches.mark_as_delivered_action.button_submit'"
          :submit_working="working"
          @submit="submitMarkAsDelivered"
          @close="closeMarkAsDeliveredModal"
        ></modal-confirm>
      </modal-action>
    </template>

    <template slot="content">
      <v-layout row>
        <v-flex xs3>
          <label-text :label="`${$t('instant_card_batches.details.batch_information_section.quantity')}`" :value="batch.quantity"></label-text>
        </v-flex>
        <v-flex xs3>
          <label-text
            :label="`${$t('instant_card_batches.details.batch_information_section.quantity_pre_activated')}`"
            :value="batch.pre_activated || '0'"
          ></label-text>
        </v-flex>
        <v-flex xs3>
          <label-text
            :label="`${$t('instant_card_batches.details.batch_information_section.quantity_destroyed')}`"
            :value="batch.destroyed || '0'"
          ></label-text>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs3>
          <label-text
            :label="`${$t('instant_card_batches.details.batch_information_section.card_package_id')}`"
            :value="batch.card_package_id"
          ></label-text>
        </v-flex>
      </v-layout>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import UpdateBatchAction from '@/components/instant_card_batch/actions/UpdateBatchAction';
import CancelBatchAction from '@/components/instant_card_batch/actions/CancelBatchAction';
import ApproveBatchAction from '@/components/instant_card_batch/actions/ApproveBatchAction';
import { mapGetters } from 'vuex';
import ModalConfirm from '@/components/system/Modals/ModalConfirm';

export default {
  name: 'batch-information',
  components: { ModalConfirm, UpdateBatchAction, CancelBatchAction, ApproveBatchAction },
  mixins: [security, actionErrorTrackable, disableWithError],
  data() {
    return {
      showUpdateBatchModal: false,
      showCancelBatchModal: false,
      showApproveBatchModal: false,
      showMarkAsDeliveredModal: false,
      actions: [
        {
          slotName: 'UpdateBatchModal',
          name: this.$t('instant_card_batches.details.batch_information_section.update_batch'),
          action: () => {
            this.openUpdateBatchModal();
          },
          isAvailable: () => this.canManageOffer(), // TODO change
          isDisabled: () => {
            return !this.showUpdateBatchFeature();
          },
        },
        {
          slotName: 'ApproveBatchModal',
          name: this.$t('instant_card_batches.details.batch_information_section.approve_batch'),
          action: () => {
            this.openApproveBatchModal();
          },
          isAvailable: () => this.canApproveInstantCardBatch(),
          isDisabled: () => {
            return !this.showApproveBatchFeature();
          },
        },
        {
          slotName: 'markAsDeliveredModal',
          name: this.$t('instant_card_batches.details.batch_information_section.mark_as_delivered'),
          action: () => {
            this.openMarkAsDeliveredModal();
          },
          isAvailable: () => this.canManageOffer(), // TODO change
          isDisabled: () => !this.showMarkAsDeliveredFeature(),
        },
        {
          slotName: 'CancelBatchModal',
          name: this.$t('instant_card_batches.details.batch_information_section.cancel_batch'),
          action: () => {
            this.openCancelBatchModal();
          },
          isAvailable: () => this.canManageOffer(), // TODO change
          isDisabled: () => {
            return !this.showCancelBatchFeature();
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('instantCardBatch', ['batch']),
  },
  methods: {
    showActionBtn() {
      return true; // TODO set
    },
    showUpdateBatchFeature() {
      return this.batch.status === 'pending';
    },
    openUpdateBatchModal() {
      this.showUpdateBatchModal = true;
    },
    closeUpdateBatchModal() {
      this.showUpdateBatchModal = false;
    },
    async updateBatchSuccess() {
      await this.executeAction(
        { action: 'ui/showSuccessSnackBar' },
        { text: 'instant_card_batches.details.batch_information_section.update_action.success' },
      );
      this.closeUpdateBatchModal();
    },
    showCancelBatchFeature() {
      return this.batch.status === 'pending';
    },
    openCancelBatchModal() {
      this.showCancelBatchModal = true;
    },
    closeCancelBatchModal() {
      this.showCancelBatchModal = false;
    },
    async cancelBatchSuccess() {
      await this.executeAction(
        { action: 'ui/showSuccessSnackBar' },
        { text: 'instant_card_batches.details.batch_information_section.cancel_action.success' },
      );
      this.closeCancelBatchModal();
    },
    showApproveBatchFeature() {
      return this.batch.status === 'pending';
    },
    openApproveBatchModal() {
      this.showApproveBatchModal = true;
    },
    closeApproveBatchModal() {
      this.showApproveBatchModal = false;
    },
    async approveBatchSuccess() {
      await this.executeAction(
        { action: 'ui/showSuccessSnackBar' },
        { text: 'instant_card_batches.details.batch_information_section.approve_action.success' },
      );
      this.closeApproveBatchModal();
    },
    showMarkAsDeliveredFeature() {
      return this.batch.status === 'generated';
    },
    openMarkAsDeliveredModal() {
      this.showMarkAsDeliveredModal = true;
    },
    closeMarkAsDeliveredModal() {
      this.showMarkAsDeliveredModal = false;
    },
    async markAsDeliveredSuccess() {
      await this.executeAction(
        { action: 'ui/showSuccessSnackBar' },
        { text: `instant_card_batches.details.batch_information_section.mark_as_delivered_action.success` },
      );
      this.closeMarkAsDeliveredModal();
    },
    async submitMarkAsDelivered() {
      const validationComplete = await this.$validator.validateAll();
      if (validationComplete) {
        await this.executeAction(
          {
            action: 'instantCardBatch/markBatchAsDelivered',
            name: 'markBatchAsDelivered',
            success: this.markAsDeliveredSuccess,
          },
          this.batch.id,
        );
      }
    },
  },
};
</script>
