import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    limits: {},
    currency: '',
  },

  getters: {
    limits: (state) => state.limits,
    currency: (state) => state.currency,
  },

  mutations: {
    limits(state, limits) {
      state.limits = limits;
    },
    currency(state, currency) {
      state.currency = currency;
    },
  },

  actions: {
    async getLimits({ commit }, key) {
      const programService = Vue.prototype.$services.program;
      const { limits, currency } = await programService.getProgram(key);
      commit('limits', limits);
      commit('currency', currency);
    },
    async clear({ commit }) {
      commit('limits', {});
      commit('currency', '');
    },
  },
};
