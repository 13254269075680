<template>
  <card :title="$t('space.details.space_information_section.title')" icon="fal fa-store" id="space-informations">
    <template slot="headerZoneRight">
      <label-text :label="`${$t('space.details.space_information_section.id')}`" :value="space.id">
        <p slot="labelTextLink" class="label-text--value">
          <v-layout row>
            {{ space.id }}
            <button class="clipboard" v-clipboard:copy="space.id ? space.id : ''">
              <v-icon small>file_copy</v-icon>
            </button>
          </v-layout>
        </p>
      </label-text>

      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="`${$t('space.details.space_information_section.program')}`" :value="space.partner_id" />

      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="`${$t('space.details.space_information_section.program_name')}`" :value="space.name" />

      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="`${$t('space.details.space_information_section.region')}`" :value="space.region" />

      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="`${$t('space.details.space_information_section.timezone')}`" :value="space.timezone" />

      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text
        :label="`${$t('space.details.space_information_section.visibility')}`"
        :value="$t(`space.details.space_information_section.is_public_space.${!space.private_space}`)"
      >
        <p slot="labelTextLink" class="label-text--value">
          <span :class="`spaces-table-visibility-public-${!space.private_space}`">{{
            $t(`space.spaces_table.is_public_space.${!space.private_space}`)
          }}</span>
        </p>
      </label-text>

      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="`${$t('space.details.space_information_section.card_selector')}`" :value="extractCardProgramValue(space)" />

      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text
        :label="`${$t('space.details.space_information_section.created')}`"
        :value="space.created | formatShortDateInverted | orNotAvailable"
      />

      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>

      <link-secondary-action slot="labelTextLink">
        <button-secondary-actions :text="$t(`space.details.space_information_section.actions`)" :actions="actions"> </button-secondary-actions>
      </link-secondary-action>

      <modal-action :show-modal="showOnboardModal"
        ><onboard-action
          v-if="showOnboardModal"
          :partner-id="space.partner_id"
          :space-id="space.id"
          @close="closeOnboardModal"
          @onboarded="onboardSuccess"
        ></onboard-action
      ></modal-action>

      <modal-confirm
        :show-modal="showSetSpacePublicModal"
        :title="$t('space.set_public_action.confirm.title')"
        context="setSpacePublic"
        :text="$t('space.set_public_action.confirm.text')"
        :cancel_text="'space.set_public_action.confirm.button_cancel'"
        :submit_text="'space.set_public_action.confirm.button_submit'"
        :submit_working="working"
        @submit="setSpacePublic"
        @close="closeSetSpacePublicModal"
      ></modal-confirm>
      <modal-confirm
        :show-modal="showSetSpacePrivateModal"
        :title="$t('space.set_private_action.confirm.title')"
        context="setSpacePrivate"
        :text="$t('space.set_private_action.confirm.text')"
        :cancel_text="'space.set_private_action.confirm.button_cancel'"
        :submit_text="'space.set_private_action.confirm.button_submit'"
        :submit_working="working"
        @submit="setSpacePrivate"
        @close="closeSetSpacePrivateModal"
      ></modal-confirm>
    </template>
    <template slot="content">
      <v-layout row>
        <v-flex xs4>
          <label-text :label="$t('space.details.space_information_section.url')" :value="space.url">
            <p class="label-text--value" slot="labelTextLink">
              <v-layout v-if="space.url" row>
                <a :href="space.url" target="_blank">{{ space.url }}</a>
                <button class="clipboard" v-clipboard:copy="space.url ? space.url : ''">
                  <v-icon small>file_copy</v-icon>
                </button>
              </v-layout>
              <label-none v-else>{{ $t('system.na') }}</label-none>
            </p>
          </label-text>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs4>
          <label-text :label="$t('space.details.space_information_section.guest_services_url')" :value="space.guest_services_url">
            <p class="label-text--value" slot="labelTextLink">
              <v-layout v-if="space.guest_services_url" row>
                <a :href="space.guest_services_url" target="_blank">{{ space.guest_services_url }}</a>
                <button class="clipboard" v-clipboard:copy="space.guest_services_url ? space.guest_services_url : ''">
                  <v-icon small>file_copy</v-icon>
                </button>
              </v-layout>
              <label-none v-else>{{ $t('system.na') }}</label-none>
            </p>
          </label-text>
        </v-flex>
      </v-layout>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';
import _get from 'lodash/get';
import OnboardAction from '../actions/OnboardAction.vue';

export default {
  name: 'space-information',
  components: { OnboardAction },
  mixins: [security, actionErrorTrackable, disableWithError],
  data() {
    return {
      showOnboardModal: false,
      showSetSpacePublicModal: false,
      showSetSpacePrivateModal: false,
      actions: [
        {
          slotName: 'OnboardModal',
          name: this.$t('space.details.space_information_section.onboard'),
          action: () => {
            this.openOnboardModal();
          },
          isAvailable: () => this.canManageOffer(), // TODO change
          isDisabled: () => {
            return !this.showOnboardFeature();
          },
        },
        {
          slotName: 'setPublicModal',
          name: this.$t('space.details.space_information_section.set_public'),
          action: () => {
            this.openSetSpacePublicModal();
          },
          isAvailable: () => this.showSetPublicFeature(),
        },
        {
          slotName: 'setPrivateModal',
          name: this.$t('space.details.space_information_section.set_private'),
          action: () => {
            this.openSetSpacePrivateModal();
          },
          isAvailable: () => this.showSetPrivateFeature(),
        },
      ],
    };
  },
  computed: {
    ...mapGetters('space', ['space']),
  },
  methods: {
    openOnboardModal() {
      this.showOnboardModal = true;
    },
    closeOnboardModal() {
      this.showOnboardModal = false;
    },
    async onboardSuccess() {
      this.showOnboardModal = false;
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'space.details.onboard_action.success' });
      this.closeOnboardModal();
    },
    openSetSpacePublicModal() {
      this.showSetSpacePublicModal = true;
    },
    closeSetSpacePublicModal() {
      this.spaceIdToSetPublic = null;
      this.showSetSpacePublicModal = false;
    },
    async setSpacePublic() {
      await this.executeAction(
        {
          action: 'space/setSpacePublic',
          name: 'setSpacePublic',
          success: this.setSpacePublicSuccess,
        },
        this.space.id,
      );
    },
    async setSpacePublicSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'space.set_public_action.success' });
      this.closeSetSpacePublicModal();
    },
    openSetSpacePrivateModal(id) {
      this.showSetSpacePrivateModal = true;
    },
    closeSetSpacePrivateModal() {
      this.showSetSpacePrivateModal = false;
    },
    async setSpacePrivate() {
      await this.executeAction(
        {
          action: 'space/setSpacePrivate',
          name: 'setSpacePrivate',
          success: this.setSpacePrivateSuccess,
        },
        this.space.id,
      );
    },
    async setSpacePrivateSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'space.set_private_action.success' });
      this.closeSetSpacePrivateModal();
    },
    extractCardProgramValue(item) {
      const isHavingClassical = _get(item, 'partner_card_packages.en');
      const isHavingInstant = _get(item, 'partner_instant_card_packages.en');

      if (isHavingClassical && isHavingInstant) {
        return this.$t('space.spaces_table.card_selector.values.classical_or_instant');
      } else if (isHavingClassical) {
        return this.$t('space.spaces_table.card_selector.values.classical_only');
      } else if (isHavingInstant) {
        return this.$t('space.spaces_table.card_selector.values.instant_only');
      }
      return null;
    },
    showOnboardFeature() {
      return true;
    },
    showSetPublicFeature() {
      return this.space.private_space;
    },
    showSetPrivateFeature() {
      return !this.space.private_space;
    },
  },
};
</script>
