import Vue from 'vue';

const service = {
  async getCardPin(publicToken) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/cards/v1/cards/${publicToken}/pin`);
    return response.data.data;
  },

  async getCard(publicToken) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/cards/v1/cards/${publicToken}`);
    return response.data.data;
  },

  async getCardTransactionContext(payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/ehi/v1/transaction/context`, payload);
    return response.data.data;
  },

  async getCardPackage(id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/card-packages/v1/ids/${id}`);
    return response.data.data;
  },

  async getCards(cardholderKey) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/cardholder/v1/cardholders/${cardholderKey}/cards`);
    return response.data.data.items;
  },

  async getLatestCardByCardholderId(cardholderId) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/cardholder/v1/cardholders/id/${cardholderId}/cards/latest`);
    return response.data.data;
  },

  async getActivationCode(publicToken) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/cards/v1/cards/${publicToken}/activation-code`);
    return response.data.data.activation_code;
  },

  async getCardholder(cardholder_id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/cardholder/v1/cardholders/${cardholder_id}`);
    return response.data.data;
  },

  async updateCardholder(publicToken, payload) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/cards/v1/cards/${publicToken}/cardholder`, payload);
    return response.data.data;
  },

  async reportLostStolen(publicToken, payload) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/cards/v1/cards/${publicToken}/report`, payload);
    return response.data.data;
  },

  async renewCard(publicToken, payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/cards/v1/cards/${publicToken}/renew`, payload);
    return response.data.data;
  },

  async regenerateCard(publicToken, payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/cards/v1/cards/${publicToken}/regenerate-card`, payload);
    return response.data.data;
  },

  async getComments(cardPublicToken, page, limit) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}/cards/v1/cards/${cardPublicToken}/comments?page=${page}&limit=${limit}`,
    );
    return response.data.data;
  },

  async createComment({ card_public_token, comment, account_id }) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/cards/v1/cards/${card_public_token}/comments`, {
      comment,
      account_id,
    });
    return response.data.data.comment;
  },

  async deleteComment({ id, reason }) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/cards/v1/cards/comments/${id}/delete`, { reason });
    return response.data.data;
  },

  async updateCardMaxBalance({ card_public_token, card_max_balance_amount }) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/cards/v1/cards/${card_public_token}/max-balance`, {
      amount: card_max_balance_amount,
    });
    return response.data.data;
  },

  async blockCard({ cardPublicToken }) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/cards/v1/cards/${cardPublicToken}/block`, {});
    return response.data.data;
  },

  async unblockCard({ cardPublicToken }) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/cards/v1/cards/${cardPublicToken}/unblock`, {});
    return response.data.data;
  },

  async unblockCardPin({ cardPublicToken }) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/cards/v1/cards/${cardPublicToken}/pin/unblock`, {});
    return response.data.data;
  },

  async lockCard({ cardPublicToken }) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/cards/v1/cards/${cardPublicToken}/lock`, {});
    return response.data.data;
  },

  async unlockCard({ cardPublicToken }) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/cards/v1/cards/${cardPublicToken}/unlock`, {});
    return response.data.data;
  },

  async deactivateCard({ cardPublicToken }) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/cards/v1/cards/${cardPublicToken}/deactivate`, {});
    return response.data.data;
  },

  async activateCard({ cardPublicToken }) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/cards/v1/cards/${cardPublicToken}/activation`, {});
    return response.data.data;
  },

  async listWalletTransactionsByCardholderKey(cardholder_key, transaction_id, operation_group_id, load_operation_id, page, limit) {
    const queryStrings = [];
    if (page) {
      queryStrings.push(['page', page]);
    }
    if (limit) {
      queryStrings.push(['limit', limit]);
    }
    if (transaction_id) {
      queryStrings.push(['transaction_id', transaction_id]);
    }
    if (operation_group_id) {
      queryStrings.push(['operation_group_id', operation_group_id]);
    }
    if (load_operation_id) {
      queryStrings.push(['load_operation_id', load_operation_id]);
    }

    const params = new URLSearchParams(queryStrings);
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}/cardholder/v1/cardholders/${cardholder_key}/wallet-transactions?${params}`,
    );
    return response.data.data;
  },

  async operationGroupAdjustment(card_public_token, operation_type, wallet_definition_id, operation_group_id, payload) {
    const response = await Vue.prototype.$axios.post(
      `${process.env.VUE_APP_BASE_API_URL}/cards/v1/cards/${card_public_token}/wallets/${wallet_definition_id}/operation-groups/${operation_group_id}/${operation_type}`,
      payload,
    );
    return response.data.data;
  },

  async cancelTransaction(card_public_token, transaction_id) {
    // what about activity_external_id?
    const response = await Vue.prototype.$axios.post(
      `${process.env.VUE_APP_BASE_API_URL}/cards/v1/cards/${card_public_token}/transactions/${transaction_id}/cancel`,
      {},
    );
    return response.data.data;
  },
};

export default service;
