<template>
  <card-modal
    icon=""
    :title="$t('store.set_coordinates_action.title')"
    context="setStoreCoordinates"
    @submit="submit"
    @cancel="cancel"
    cancel_text="store.set_coordinates_action.button_cancel"
    submit_text="store.set_coordinates_action.button_save"
    data-test="store--set-coordinates-modal"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-text-field
          autofocus
          :label="$t('store.set_coordinates_action.lat')"
          name="lat"
          v-validate="{ max_value: 180, min_value: -180, max: 12, decimal: 7 }"
          :error-messages="errors.collect('lat')"
          v-model="currentStore.coordinates.lat"
          data-vv-name="lat"
          maxlength="20"
          :data-vv-as="$t('store.set_coordinates_action.lat')"
          data-test="set-store-coordinates--lat"
        ></v-text-field>
        <v-text-field
          :label="$t('store.set_coordinates_action.lng')"
          name="lng"
          v-validate="{ max_value: 180, min_value: -180, max: 12, decimal: 7 }"
          :error-messages="errors.collect('lng')"
          v-model="currentStore.coordinates.lng"
          data-vv-name="lng"
          maxlength="20"
          :data-vv-as="$t('store.set_coordinates_action.lng')"
          data-test="set-store-coordinates--lng"
        ></v-text-field>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'set-coordinates-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    store: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentStore: {
        coordinates: {
          lat: '',
          lng: '',
        },
      },
    };
  },
  async mounted() {
    this.currentStore = _.merge(
      {
        coordinates: {
          lat: '',
          lng: '',
        },
      },
      _.cloneDeep(this.store),
    );
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    clear() {
      this.currentStore = {
        coordinates: {
          lat: '',
          lng: '',
        },
      };
      this.$validator.reset();
    },
    success() {
      this.clear();
      this.$emit('set');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    buildPayload() {
      return {
        id: this.store.id,
        lat: this.currentStore.coordinates.lat,
        lng: this.currentStore.coordinates.lng,
      };
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();
      if (validationComplete) {
        await this.executeAction(
          {
            action: 'store/updateStoreCoordinates',
            name: 'setStoreCoordinates',
            success: this.success,
          },
          this.buildPayload(),
        );
      }
    },
  },
};
</script>
