<template>
  <card-modal
    icon="fal fa-money-check-edit"
    :title="$t('offer_promotions.update_action.title')"
    context="updateOfferPromotionDetails"
    @submit="submit"
    @cancel="cancel"
    cancel_text="offer_promotions.update_action.cancel"
    submit_text="offer_promotions.update_action.save"
  >
    <form @submit.prevent="submit" slot="content" v-if="currentOfferPromotion.bonus !== {}">
      <v-layout column>
        <h-autocomplete
          v-model="currentOfferPromotion.branding_id"
          :label="$t('offer_promotions.fields.branding') + '*'"
          v-validate="'required'"
          :error-messages="errors.collect('branding')"
          name="branding"
          data-vv-name="branding"
          data-vv-as="branding"
          search-action="branding/autocompleteSearch"
          data-test="update-offers-offer-promotion--branding"
          clearable
        >
        </h-autocomplete>
        <h4>{{ $t('offer_promotions.fields.incentive_title') }}</h4>
        <v-layout row wrap pad-form-row-elements>
          <v-flex sm4 xs12>
            <v-text-field
              v-model="currentOfferPromotion.bonus.reward_percent"
              :label="$t('offer_promotions.fields.reward_percent') + '*'"
              name="reward_percent"
              append-icon="fas fa-percentage"
              v-validate="'required|integer|min_value:1|max_value:100'"
              :error-messages="errors.collect('reward_percent')"
              data-vv-name="reward_percent"
              :data-vv-as="$t('offer_promotions.fields.reward_percent')"
              data-test="update-offers-offer-promotion--reward_percent"
              required
            ></v-text-field>
          </v-flex>
          <v-flex sm4 xs12>
            <v-text-field
              v-model="currentOfferPromotion.bonus.user_inventory_limit"
              :label="$t('offer_promotions.fields.user_inventory_limit') + '*'"
              name="incentive.user_inventory_limit"
              append-icon="fas fa-dollar-sign"
              v-validate="{
                required: true,
                integer: true,
                min_value: 1,
                max_value: 20000,
              }"
              :error-messages="errors.collect('incentive.user_inventory_limit')"
              data-vv-name="incentive.user_inventory_limit"
              :data-vv-as="$t('offer_promotions.fields.user_inventory_limit')"
              data-test="update-offers-offer-promotion--incentive.user_inventory_limit"
              required
            ></v-text-field>
          </v-flex>
          <v-flex sm4 xs12>
            <label-text :label="$t('offer_promotions.fields.user_payment_limit')" :value="userPaymentLimit | currency | dollarSignI18n"></label-text>
          </v-flex>
        </v-layout>
        <v-layout row wrap pad-form-row-elements>
          <v-flex sm4 xs12>
            <v-text-field
              v-model="currentOfferPromotion.bonus.minimum_payment"
              :label="$t('offer_promotions.fields.minimum') + '*'"
              name="incentive_min"
              append-icon="fas fa-dollar-sign"
              v-validate="{
                required: true,
                integer: true,
                min_value: 1,
                minimumPaymentBetween: { field1: userPaymentLimit, field2: currentOfferPromotion.bonus.step.default_payment },
              }"
              :error-messages="errors.collect('incentive_min')"
              data-vv-name="incentive_min"
              :data-vv-as="$t('offer_promotions.fields.minimum')"
              data-test="update-offers-offer-promotion--incentive_min"
              required
            ></v-text-field>
          </v-flex>
          <v-flex sm4 xs12>
            <v-text-field
              v-model="currentOfferPromotion.bonus.step.step"
              :label="$t('offer_promotions.fields.step') + '*'"
              name="incentive_step"
              append-icon="fas fa-dollar-sign"
              v-validate="{ required: true, integer: true, min_value: 1, stepBetween: { field1: userPaymentLimit } }"
              :error-messages="errors.collect('incentive_step')"
              data-vv-name="incentive_step"
              :data-vv-as="$t('offer_promotions.fields.step')"
              data-test="update-offers-offer-promotion--incentive_step"
              required
            ></v-text-field>
          </v-flex>
          <v-flex sm4 xs12>
            <v-text-field
              v-model="currentOfferPromotion.bonus.step.default_payment"
              :label="$t('offer_promotions.fields.default_payment') + '*'"
              name="incentive.default_payment"
              append-icon="fas fa-dollar-sign"
              v-validate="{
                required: true,
                integer: true,
                defaultPaymentBetween: { field1: currentOfferPromotion.bonus.step.minimum_payment, field2: userPaymentLimit },
                withinMinStep: { min: currentOfferPromotion.bonus.step.minimum_payment, step: currentOfferPromotion.bonus.step.step },
              }"
              :error-messages="errors.collect('incentive.default_payment')"
              data-vv-name="incentive.default_payment"
              :data-vv-as="$t('offer_promotions.fields.default_payment')"
              data-test="update-offers-offer-promotion--incentive.default_payment"
              required
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row wrap pad-form-row-elements>
          <v-flex sm4 xs12>
            <v-select
              :items="durationModelOptions"
              name="duration_model"
              v-validate="'required'"
              v-model="currentOfferPromotion.bonus.duration.model"
              :label="$t('offer_promotions.fields.duration_model') + '*'"
              :error-messages="errors.collect('duration_model')"
              data-vv-name="duration_model"
              :data-vv-as="$t('offer_promotions.fields.duration_model')"
              item-text="name"
              item-value="id"
              data-test="update-offers-offer-promotion--duration_model"
            ></v-select>
          </v-flex>
          <v-flex sm8 xs12>
            <v-text-field
              v-if="currentOfferPromotion.bonus.duration.model === 'relative'"
              v-model="currentOfferPromotion.bonus.duration.days"
              :label="$t('offer_promotions.fields.duration') + '*'"
              :hint="$t('offer_promotions.fields.duration_hint')"
              name="incentive_duration"
              v-validate="{
                required: currentOfferPromotion.bonus.duration.model === 'relative',
                integer: true,
              }"
              :error-messages="errors.collect('incentive_duration')"
              data-vv-name="incentive_duration"
              :data-vv-as="$t('offer_promotions.fields.duration')"
              data-test="update-offers-offer-promotion--incentive_duration"
              required
            ></v-text-field>
            <v-menu
              v-if="currentOfferPromotion.bonus.duration.model === 'fixed'"
              v-model="showDisplayDurationEndDatePickerCalendar"
              :close-on-content-click="false"
              :nudge-right="40"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-layout row wrap pad-form-row-elements>
                  <v-flex sm6 xs12>
                    <v-text-field
                      v-model="currentOfferPromotion.bonus.duration.end_date"
                      v-validate="{ required: currentOfferPromotion.bonus.duration.model === 'fixed' }"
                      data-vv-name="duration_end_date"
                      :error-messages="errors.collect('duration_end_date')"
                      :data-vv-as="$t('offer_promotions.fields.duration_end_date')"
                      :label="$t('offer_promotions.fields.duration_end_date') + '*'"
                      data-test="update-offers-offer-promotion--duration_end_date"
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </template>
              <v-date-picker
                v-model="currentOfferPromotion.bonus.duration.end_date"
                @input="showDisplayDurationEndDatePickerCalendar = false"
                :show-current="new Date().toISOString().substr(0, 10)"
              ></v-date-picker>
            </v-menu>
          </v-flex>
        </v-layout>
        <h4>{{ $t('offer_promotions.fields.availability_title') }}</h4>
        <v-layout row wrap pad-form-row-elements>
          <v-flex sm9 xs12>
            <h-autocomplete
              v-model="currentOfferPromotion.bonus.availability.inventory_id"
              :label="$t('offer_promotions.fields.inventory') + '*'"
              v-validate="'required'"
              :error-messages="errors.collect('inventory')"
              name="inventory"
              :alternate-text="inventoryLookupItemText"
              data-vv-name="inventory"
              data-vv-as="inventory"
              search-action="inventory/autocompleteSearch"
              data-test="update-offers-offer-promotion--inventory"
              no-filter
            >
            </h-autocomplete>
          </v-flex>
          <v-flex sm3 xs12>
            <v-checkbox
              name="refillable"
              v-model="currentOfferPromotion.bonus.availability.is_refillable"
              :label="$t(`offer_promotions.fields.is_refillable`)"
              :error-messages="errors.collect('refillable')"
              data-vv-name="refillable"
              :data-vv-as="$t(`offer_promotions.fields.is_refillable`)"
              type="checkbox"
            ></v-checkbox>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12 sm12>
            <v-menu
              v-model="showDisplayStartDatePickerCalendar"
              :close-on-content-click="false"
              :nudge-right="40"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-layout row wrap pad-form-row-elements>
                  <v-flex sm6 xs12>
                    <v-text-field
                      v-model="currentOfferPromotion.display_start_date"
                      v-validate="'required'"
                      data-vv-name="display_start_date"
                      :error-messages="errors.collect('display_start_date')"
                      :data-vv-as="$t('offer_promotions.fields.display_start_date')"
                      :label="$t('offer_promotions.fields.display_start_date') + '*'"
                      data-test="update-offers-offer-promotion--display_start_date"
                      prepend-icon="event"
                      clearable
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </v-flex>
                  <v-flex sm6 xs12>
                    <v-select
                      :items="generateRangeOfThirtyMinutes"
                      name="display_start_time"
                      v-validate="'required'"
                      v-model="currentOfferPromotion.display_start_time"
                      :label="$t('offer_promotions.fields.display_start_time')"
                      :error-messages="errors.collect('display_start_time')"
                      data-vv-name="display_start_time"
                      :data-vv-as="$t('offer_promotions.fields.display_start_time')"
                      prepend-icon="far fa-clock"
                      data-test="update-offers-offer-promotion--display_start_time"
                    ></v-select>
                  </v-flex>
                </v-layout>
              </template>
              <v-date-picker
                v-model="currentOfferPromotion.display_start_date"
                @input="showDisplayStartDatePickerCalendar = false"
                :show-current="new Date().toISOString().substring(0, 10)"
                :max="currentOfferPromotion.start_date"
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs12 sm12>
            <v-menu
              v-model="showStartDatePickerCalendar"
              :close-on-content-click="false"
              :nudge-right="40"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-layout row wrap pad-form-row-elements>
                  <v-flex sm6 xs12>
                    <v-text-field
                      v-model="currentOfferPromotion.start_date"
                      v-validate="{ required: true, islaterOrSame: { date: currentOfferPromotion.display_start_date } }"
                      data-vv-name="start_date"
                      :error-messages="errors.collect('start_date')"
                      :data-vv-as="$t('offer_promotions.fields.start_date')"
                      :label="$t('offer_promotions.fields.start_date') + '*'"
                      data-test="update-offers-offer-promotion--start_date"
                      prepend-icon="event"
                      clearable
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </v-flex>
                  <v-flex sm6 xs12>
                    <v-select
                      :items="generateRangeOfThirtyMinutes"
                      name="start_time"
                      v-validate="'required'"
                      v-model="currentOfferPromotion.start_time"
                      :label="$t('offer_promotions.fields.start_time')"
                      :error-messages="errors.collect('start_time')"
                      data-vv-name="start_time"
                      :data-vv-as="$t('offer_promotions.fields.start_time')"
                      prepend-icon="far fa-clock"
                      data-test="update-offers-offer-promotion--start_time"
                    ></v-select>
                  </v-flex>
                </v-layout>
              </template>
              <v-date-picker
                v-model="currentOfferPromotion.start_date"
                @input="showStartDatePickerCalendar = false"
                :show-current="new Date().toISOString().substring(0, 10)"
                :min="currentOfferPromotion.display_start_date"
                :max="currentOfferPromotion.end_date"
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs12 sm12>
            <v-menu
              v-model="showEndDatePickerCalendar"
              :close-on-content-click="false"
              :nudge-right="40"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-layout row wrap pad-form-row-elements>
                  <v-flex sm6 xs12>
                    <v-text-field
                      v-model="currentOfferPromotion.end_date"
                      data-vv-name="end_date"
                      :error-messages="errors.collect('end_date')"
                      v-validate="{ required: true, islater: { date: currentOfferPromotion.start_date } }"
                      :data-vv-as="$t('offer_promotions.fields.end_date')"
                      :label="$t('offer_promotions.fields.end_date') + '*'"
                      data-test="update-offers-offer-promotion--end_date"
                      prepend-icon="event"
                      clearable
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </v-flex>
                  <v-flex sm6 xs12>
                    <v-select
                      :items="generateRangeOfThirtyMinutes"
                      name="end_time"
                      v-validate="'required'"
                      v-model="currentOfferPromotion.end_time"
                      :label="$t('offer_promotions.fields.end_time')"
                      :error-messages="errors.collect('end_time')"
                      data-vv-name="end_time"
                      :data-vv-as="$t('offer_promotions.fields.end_time')"
                      prepend-icon="far fa-clock"
                      data-test="update-offers-offer-promotion--end_time"
                    ></v-select>
                  </v-flex>
                </v-layout>
              </template>
              <v-date-picker
                v-model="currentOfferPromotion.end_date"
                @input="showEndDatePickerCalendar = false"
                :show-current="new Date().toISOString().substr(0, 10)"
                :min="currentOfferPromotion.start_date"
                :max="currentOfferPromotion.display_end_date"
              ></v-date-picker>
            </v-menu>
          </v-flex>

          <v-flex xs12 sm12>
            <v-menu
              v-model="showDisplayEndDatePickerCalendar"
              :close-on-content-click="false"
              :nudge-right="40"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-layout row wrap pad-form-row-elements>
                  <v-flex sm6 xs12>
                    <v-text-field
                      v-model="currentOfferPromotion.display_end_date"
                      data-vv-name="display_end_date"
                      :error-messages="errors.collect('display_end_date')"
                      v-validate="{ required: true, islaterOrSame: { date: currentOfferPromotion.end_date } }"
                      :data-vv-as="$t('offer_promotions.fields.display_end_date')"
                      :label="$t('offer_promotions.fields.display_end_date') + '*'"
                      data-test="update-offers-offer-promotion--display_end_date"
                      prepend-icon="event"
                      clearable
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </v-flex>
                  <v-flex sm6 xs12>
                    <v-select
                      :items="generateRangeOfThirtyMinutes"
                      name="display_end_time"
                      v-validate="'required'"
                      v-model="currentOfferPromotion.display_end_time"
                      :label="$t('offer_promotions.fields.display_end_time')"
                      :error-messages="errors.collect('display_end_time')"
                      data-vv-name="display_end_time"
                      :data-vv-as="$t('offer_promotions.fields.display_end_time')"
                      prepend-icon="far fa-clock"
                      data-test="update-offers-offer-promotion--display_end_time"
                    ></v-select>
                  </v-flex>
                </v-layout>
              </template>
              <v-date-picker
                v-model="currentOfferPromotion.display_end_date"
                @input="showDisplayEndDatePickerCalendar = false"
                :show-current="new Date().toISOString().substr(0, 10)"
                :min="currentOfferPromotion.end_date"
              ></v-date-picker>
            </v-menu>
          </v-flex>
        </v-layout>

        <h4>{{ $t('offer_promotions.fields.accounting_title') }}</h4>
        <v-layout row wrap pad-form-row-elements>
          <v-text-field
            :label="$t('offer_promotions.fields.accounting_reference_id') + '*'"
            name="accounting_reference_id"
            v-validate="'required|max:100'"
            :error-messages="errors.collect('accounting_reference_id')"
            v-model="currentOfferPromotion.accounting_reference_id"
            data-vv-label="accounting_reference_id"
            maxlength="100"
            :data-vv-as="$t('offer_promotions.fields.accounting_reference_id')"
            data-test="update-offers-offer-promotion--accounting_reference_id"
          >
          </v-text-field>
        </v-layout>
        <v-layout row wrap pad-form-row-elements>
          <h-autocomplete
            v-model="currentOfferPromotion.bonus.funding.bonus_breakage_owner_business_id"
            :label="$t('offer_promotions.fields.breakage_bonus_owner')"
            :error-messages="errors.collect('bonus_breakage_owner_business_id')"
            name="bonus_breakage_owner_business_id"
            data-vv-name="bonus_breakage_owner_business_id"
            :data-vv-as="$t('offer_promotions.fields.breakage_bonus_owner')"
            search-action="business/autocompleteSearch"
            data-test="update-offers-offer-promotion--bonus_breakage_owner_business_id"
            clearable
          >
          </h-autocomplete>
        </v-layout>

        <h4>{{ $t('offer_promotions.fields.funding_title') }}</h4>

        <v-layout row wrap pad-form-row-elements>
          <v-flex xs12 sm4>
            <v-select
              :items="fundingTriggerOptions"
              name="bonus_trigger"
              v-validate="'required'"
              v-model="currentOfferPromotion.bonus.funding.trigger"
              :label="$t('offer_promotions.fields.funding_trigger') + '*'"
              :error-messages="errors.collect('bonus_trigger')"
              data-vv-name="bonus_trigger"
              :data-vv-as="$t('offer_promotions.fields.funding_trigger')"
              item-text="name"
              item-value="id"
              data-test="update-offers-offer-promotion--bonus_trigger"
              @change="onChangeTrigger"
            ></v-select>
          </v-flex>
        </v-layout>

        <v-layout row wrap pad-form-row-elements>
          <h-autocomplete
            :readonly="currentOfferPromotion.bonus.funding.trigger === 'upfront'"
            v-model="currentOfferPromotion.bonus.funding.contributor_business_id"
            :label="$t('offer_promotions.fields.contributor') + '*'"
            :error-messages="errors.collect('contributor_business_id')"
            name="contributor_business_id"
            v-validate="'required'"
            data-vv-name="contributor_business_id"
            :data-vv-as="$t('offer_promotions.fields.contributor')"
            search-action="business/autocompleteSearch"
            data-test="update-offers-offer-promotion--contributor_business_id"
          >
          </h-autocomplete>
        </v-layout>
      </v-layout>
    </form>
  </card-modal>
</template>
<script>
import { actionErrorTrackable } from '@/mixins';
import { adjustValueForMinStep } from '@/utils/offer-utils';
import { mapGetters } from 'vuex';
import moment from 'moment-timezone';
import _ from 'lodash';

export default {
  name: 'update-offer-promotion-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    offerPromotion: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentOfferPromotion: {
        start_date: null,
        start_time: '00:00',
        end_date: null,
        end_time: '00:00',
        display_start_date: null,
        display_start_time: '00:00',
        display_end_date: null,
        display_end_time: '00:00',
      },
      showDisplayStartDatePickerCalendar: false,
      showStartDatePickerCalendar: false,
      showEndDatePickerCalendar: false,
      showDisplayEndDatePickerCalendar: false,
      showDisplayDurationEndDatePickerCalendar: false,
    };
  },
  async created() {
    this.currentOfferPromotion = Object.assign(
      {
        start_date: null,
        start_time: '00:00',
        end_date: null,
        end_time: '00:00',
        display_start_date: null,
        display_start_time: '00:00',
        display_end_date: null,
        display_end_time: '00:00',
        bonus: {
          duration: {},
          step: {},
          availability: {},
          funding: {},
        },
      },
      _.cloneDeep(this.offerPromotion),
    );
    this.currentOfferPromotion.bonus.reward_percent = this.$options.filters.percentageFloatToInt(this.currentOfferPromotion.bonus.reward_percent);
  },
  computed: {
    ...mapGetters('offers', ['fundingTriggers', 'incentiveDurationModels']),
    durationModelOptions() {
      return _.map(this.incentiveDurationModels, (durationModel) => ({
        name: this.$t(`offers.incentive_duration_models.${durationModel}`),
        id: durationModel,
      }));
    },
    fundingTriggerOptions() {
      return _.map(this.fundingTriggers, (item) => ({
        name: this.$t(`offers.funding_triggers.${item}`),
        id: item,
      }));
    },
    userPaymentLimit() {
      return this.currentOfferPromotion.bonus.reward_percent && this.currentOfferPromotion.bonus.user_inventory_limit
        ? adjustValueForMinStep(
            this.currentOfferPromotion.bonus.minimum_payment,
            this.currentOfferPromotion.bonus.step.step,
            this.currentOfferPromotion.bonus.user_inventory_limit / (this.currentOfferPromotion.bonus.reward_percent / 100),
          )
        : 0;
    },
    generateRangeOfThirtyMinutes() {
      return Array.from({ length: 24 }, (_, i) => i).reduce((r, hour) => {
        r.push(moment({ hour, minute: 0 }).format('HH:mm'));
        r.push(moment({ hour, minute: 30 }).format('HH:mm'));
        return r;
      }, []);
    },
  },
  methods: {
    onChangeTrigger() {
      if (_.get(this.currentOfferPromotion, 'bonus.funding.trigger') === 'upfront') {
        this.currentOfferPromotion.bonus.funding.contributor_business_id = process.env.VUE_APP_DEFAULT_UPFRONT_CONTRIBUTOR;
      } else {
        this.currentOfferPromotion.bonus.funding.contributor_business_id = null;
      }
    },
    inventoryLookupItemText(it) {
      return `${it.name} - ${this.$options.filters.dollarSignI18n(this.$options.filters.currency(it.available_amount))}`;
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();
      if (validationComplete) {
        await this.executeAction(
          {
            action: 'offers/updateOfferPromotion',
            name: 'updateOfferPromotionDetails',
            success: this.success,
          },
          this.buildPayload(),
        );
      }
    },
    buildPayload() {
      const payload = {
        id: this.offerPromotion.id,
        offer_id: this.offerPromotion.offer_id,
        accounting_reference_id: this.currentOfferPromotion.accounting_reference_id,
        branding_id: this.currentOfferPromotion.branding_id,
        start_date: this.currentOfferPromotion.start_date,
        start_time: this.currentOfferPromotion.start_time,
        display_start_date: this.currentOfferPromotion.display_start_date,
        display_start_time: this.currentOfferPromotion.display_start_time,
        end_date: this.currentOfferPromotion.end_date,
        end_time: this.currentOfferPromotion.end_time,
        display_end_date: this.currentOfferPromotion.display_end_date,
        display_end_time: this.currentOfferPromotion.display_end_time,
        bonus: {
          reward_percent: this.currentOfferPromotion.bonus.reward_percent,
          user_inventory_limit: this.currentOfferPromotion.bonus.user_inventory_limit,
          minimum_payment: this.currentOfferPromotion.bonus.minimum_payment,
          step: {
            step: this.currentOfferPromotion.bonus.step.step,
            default_payment: this.currentOfferPromotion.bonus.step.default_payment,
          },
          availability: {
            inventory_id: this.currentOfferPromotion.bonus.availability.inventory_id,
            is_refillable: this.currentOfferPromotion.bonus.availability.is_refillable,
          },
          funding: {
            contributor_business_id: this.currentOfferPromotion.bonus.funding.contributor_business_id,
            bonus_breakage_owner_business_id: _.get(this.currentOfferPromotion, 'bonus.funding.bonus_breakage_owner_business_id', null),
            trigger: this.currentOfferPromotion.bonus.funding.trigger,
          },
          duration: {
            model: this.currentOfferPromotion.bonus.duration.model,
          },
        },
      };
      if (this.currentOfferPromotion.branding_id && this.currentOfferPromotion.branding_id !== '') {
        payload.branding_id = this.currentOfferPromotion.branding_id;
      }

      if (this.currentOfferPromotion.bonus.duration.model && this.currentOfferPromotion.bonus.duration.model !== 'fixed') {
        payload.bonus.duration.days = this.currentOfferPromotion.bonus.duration.days;
        payload.bonus.duration.end_date = null;
        payload.bonus.duration.end_time = null;
      }
      if (this.currentOfferPromotion.bonus.duration.model && this.currentOfferPromotion.bonus.duration.model !== 'relative') {
        payload.bonus.duration.end_date = this.currentOfferPromotion.bonus.duration.end_date;
        payload.bonus.duration.end_time = '00:00';
        payload.bonus.duration.days = null;
      }

      return payload;
    },
    clear() {
      this.currentOfferPromotion = {
        start_date: null,
        start_time: '00:00',
        end_date: null,
        end_time: '00:00',
        display_start_date: null,
        display_start_time: '00:00',
        display_end_date: null,
        display_end_time: '00:00',
        bonus: {
          duration: {},
          step: {},
          availability: {},
          funding: {},
        },
      };
      this.showDisplayStartDatePickerCalendar = false;
      this.showStartDatePickerCalendar = false;
      this.showEndDatePickerCalendar = false;
      this.showDisplayEndDatePickerCalendar = false;
      this.showDisplayDurationEndDatePickerCalendar = false;
      this.$validator.reset();
    },
    success(response) {
      this.clear();
      this.$emit('updated');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
  },
};
</script>
