<template>
  <card-modal
    icon=""
    :title="$t('instant_card_projects.details.add_gift_certificate_section.title')"
    context="addProjectGiftCertificate"
    @submit="submit"
    @cancel="cancel"
    cancel_text="instant_card_projects.details.add_gift_certificate_section.button_cancel"
    submit_text="instant_card_projects.details.add_gift_certificate_section.button_save"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-flex sm4 xs12>
          <label-text :label="$t('instant_card_projects.details.add_gift_certificate_section.project')" :value="projectId" />
          <h-autocomplete
            v-model="offer_id"
            :label="$t('instant_card_projects.details.add_gift_certificate_section.offer')"
            v-validate="'required'"
            :error-messages="errors.collect('offer')"
            name="offer"
            data-vv-name="offer"
            data-vv-as="offer"
            search-action="instantCardProject/autocompleteOfferSearch"
            data-test="add-project-gift-certificate--offer"
            no-filter
            @selectedValue="updateAmountFields"
          >
          </h-autocomplete>
        </v-flex>
        <v-flex sm4 xs12 v-show="foundOffer">
          <v-text-field
            v-model="payment_amount"
            :label="$t('instant_card_projects.details.add_gift_certificate_section.payment_amount')"
            name="payment_amount"
            append-icon="fas fa-dollar-sign"
            v-validate="'integer|min_value:0|max_value:1000'"
            :error-messages="errors.collect('payment_amount')"
            data-vv-name="payment_amount"
            :data-vv-as="$t('instant_card_projects.details.add_gift_certificate_section.payment_amount')"
            data-test="update-project-offer--payment_amount"
          ></v-text-field>
        </v-flex>

        <v-flex sm4 xs12 v-show="foundOffer">
          <v-text-field
            v-model="bonus_amount"
            :label="$t('instant_card_projects.details.add_gift_certificate_section.bonus_amount') + '*'"
            name="bonus_amount"
            append-icon="fas fa-dollar-sign"
            v-validate="{
              required: true,
              integer: true,
              min_value: 0,
              max_value: 1000,
              sumLessThanOrEqual: {
                field: payment_amount,
                total: 1000,
                totalFieldLabel: $t('instant_card_projects.details.add_gift_certificate_section.total_amount'),
              },
            }"
            :error-messages="errors.collect('bonus_amount')"
            data-vv-name="bonus_amount"
            :data-vv-as="$t('instant_card_projects.details.add_gift_certificate_section.bonus_amount')"
            data-test="update-project-offer--bonus_amount"
            required
          ></v-text-field>
        </v-flex>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'add-project-gift-certificate-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    projectId: {
      type: String,
    },
    partnerId: {
      type: String,
    },
    projectStructure: {
      type: String,
    },
  },
  data() {
    return {
      offer_id: '',
      payment_amount: 0,
      bonus_amount: 0,
      foundOffer: false,
    };
  },
  computed: {
    ...mapGetters('instantCardProject', ['project']),
  },
  methods: {
    ...mapActions('ui', ['addError', 'clearAllError', 'clearError']),
    clear() {
      this.offer_id = null;
      this.$validator.reset();
    },
    updateAmountFields(newRawValue) {
      this.payment_amount = _.get(newRawValue, 'incentive.payment_amount', 0);
      this.bonus_amount = _.get(newRawValue, 'incentive.bonus_amount', 0);
      this.foundOffer = true;
    },
    success() {
      this.clear();
      this.$emit('created');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      let validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        await this.executeAction(
          {
            action: 'instantCardProject/addProjectGiftCertificate',
            name: 'addProjectGiftCertificate',
            success: this.success,
          },
          {
            project_id: this.projectId,
            offer_id: this.offer_id,
            payment_amount: this.payment_amount,
            bonus_amount: this.bonus_amount,
          },
        );
      }
    },
  },
};
</script>
