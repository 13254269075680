<template>
  <div data-test="component--aml-note">
    <v-checkbox v-model="hasNote" :label="$t(`aml.create_aml_note_action.add_a_note`)" data-test="create-aml-note--with-note" />

    <div v-if="hasNote">
      <form @submit.prevent="submit" slot="content">
        <v-layout row wrap pad-form-row-elements>
          <v-flex xs12 sm6>
            <v-select
              name="category_id"
              v-model="category_id"
              v-validate="'required'"
              :error-messages="errors.collect('category')"
              data-vv-name="category"
              :data-vv-as="$t(`aml.create_aml_note_action.category`)"
              :items="noteCategories"
              item-text="name.en"
              item-value="id"
              :label="$t(`aml.create_aml_note_action.category`)"
              data-test="create-aml-note--category"
            ></v-select>
          </v-flex>

          <v-flex xs12 sm6>
            <v-select
              name="type_id"
              v-model="type_id"
              v-validate="'required'"
              :error-messages="errors.collect('type_id')"
              data-vv-name="type_id"
              :data-vv-as="$t(`aml.create_aml_note_action.type`)"
              :items="getCategoryTypes(category_id)"
              item-text="name.en"
              item-value="id"
              :label="$t(`aml.create_aml_note_action.type`)"
              data-test="create-aml-note--type"
            ></v-select>
          </v-flex>

          <v-flex xs12>
            <v-textarea
              auto-grow
              name="aml_note"
              row-height="1"
              rows="1"
              :label="$t('aml.create_aml_note_action.aml_note')"
              v-model="aml_note"
              v-validate="'required|max:4096'"
              :error-messages="errors.collect('aml_note')"
              data-vv-name="aml_note"
              :data-vv-as="$t('aml.create_aml_note_action.aml_note')"
              counter
              required
              maxlength="4096"
              data-test="create-aml-note--note"
            ></v-textarea>
          </v-flex>
        </v-layout>
      </form>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'create-aml-note-partial',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    defaultCategoryId: {
      type: String,
      required: false,
    },
    defaultTypeId: {
      type: String,
      required: false,
    },
    cardApplicationId: {
      type: String,
      required: false,
    },
    accountId: {
      type: String,
      required: false,
    },
    publicToken: {
      type: String,
      required: false,
    },
    cardholderKey: {
      type: String,
      required: false,
    },
    // cardProgramKey must remain false, because used by both card and application!
    cardProgramKey: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      hasNote: false,
      aml_note: '',
      category_id: this.defaultCategoryId || '',
      type_id: this.defaultTypeId || '',
    };
  },
  async created() {
    await this.executeAction({ action: 'aml/listNoteTypes' });
    await this.executeAction({ action: 'aml/listNoteCategories' });
  },
  computed: {
    ...mapGetters('aml', ['noteCategories', 'getCategoryTypes']),
  },
  methods: {
    clear() {
      this.aml_note = '';
      this.category_id = '';
      this.type_id = '';
      this.$validator.reset();
    },
    success() {
      this.clear();
      this.$emit('action');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      if (this.hasNote) {
        const validationComplete = await this.$validator.validateAll();

        if (validationComplete) {
          const payload = {
            type_id: this.type_id,
            category_id: this.category_id,
            note: this.aml_note,
            account_id: this.accountId,
            card_program_key: this.cardProgramKey,
            cardholder_key: this.cardholderKey,
            card_application_id: this.cardApplicationId,
          };
          let actionName;

          if (this.publicToken) {
            actionName = 'aml/saveCardholderAmlNote';
            payload.public_token = this.publicToken;
          } else if (this.cardApplicationId) {
            actionName = 'aml/saveCardApplicationAmlNote';
          } else {
            actionName = 'aml/saveAccountAmlNote';
            payload.account_id = this.accountId;
          }

          await this.executeAction(
            {
              action: actionName,
              name: 'createAml',
              success: this.success,
            },
            payload,
          );
        }
      }
    },
  },
};
</script>
