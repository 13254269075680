import Vue from 'vue';

const service = {
  async getAccountAmlStatusLogsWithAssessmentChecks(account_id) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}/aml/v1/accounts/${account_id}/card-aml-status-logs-and-assessment-checks`,
    );
    return response.data.data.items;
  },

  async getAmlStatusLogsWithAssessmentChecks(publicToken) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}/aml/v1/card-aml-status-logs-and-assessment-checks/${publicToken}`,
    );
    return response.data.data.items;
  },
};

export default service;
