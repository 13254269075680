<template>
  <div>
    <div v-for="(item, index) in items" :key="index">
      <div :class="{ card: true, 'deleted-note': isDeleted(item) }">
        <div class="main-content">
          <div v-if="item.isCard">
            {{ $t('user.aml_note_section.note_type_card') }} ({{ item.public_token }} - {{ noteCategory(item) }} - {{ noteType(item) }})
          </div>

          <div v-if="item.isApplication">
            {{ $t('user.aml_note_section.note_type_application') }} ({{ item.card_application_id }} - {{ noteCategory(item) }} - {{ noteType(item) }})
          </div>

          <div v-if="item.isAccount">{{ noteCategory(item) }} - {{ noteType(item) }}</div>

          <div>
            <app-name class="app-name--wrap" :app_key="item.app_key" /><span> - </span>
            <span class="date" v-if="item.created">{{ item.created | formatDateAbbreviateMonth }}</span>
          </div>

          <div :class="{ 'deleted-note': isDeleted(item) }">
            <div v-html="generateHtml(item.note)"></div>
            <label class="label-text--value red--text" v-if="isDeleted(item)">
              {{ $t('note.deleted_by') }}
              <inline-app-name :app_key="item.deleted.app_key" />
              ({{ item.deleted.deleted_at | formatDateHourMin }}):
              <span data-test="note--delete-reason">{{ item.deleted.reason }}</span>
            </label>
          </div>
        </div>
        <link-secondary-action class="actions" v-if="!isDeleted(item)" slot="labelTextLink" @click="openDeleteNoteModal(item)">
          {{ $t('note.delete') }}
        </link-secondary-action>
      </div>

      <v-divider style="margin-top: 1em" v-if="!isLastNote(index)" />
    </div>
  </div>
</template>

<script>
import _get from 'lodash/get';
import _ from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'layout-aml-notes',
  props: {
    items: {
      type: Array,
      required: false,
    },
    deletedBy: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('aml', ['getNoteCategoryById', 'getNoteTypeById']),
  },
  methods: {
    noteCategory(note) {
      const category = this.getNoteCategoryById(note && note.category_id);
      return category && category.name[this.currentLocale];
    },
    noteType(note) {
      const type = this.getNoteTypeById(note.type_id);
      return type && type.name[this.currentLocale];
    },
    generateHtml(note) {
      let sentences = note.split('\n');
      return _.reduce(sentences, (acc, sentence) => `${acc}${sentence}<br>`, '');
    },
    isDeleted(note) {
      return _get(note, 'deleted.deleted_at', false);
    },
    isLastNote(index) {
      return index + 1 === this.items.length;
    },
    openDeleteNoteModal(note) {
      this.$emit('delete', note);
    },
  },
};
</script>

<style scoped lang="stylus">
@import './../../../styles/variables/custom-variables.styl';
.card {
  display: flex;
  flex-wrap: wrap;
}
.main-content {
  width: 70%;
}
.actions {
  width: 30%;
  text-align: right;
}
.app-name--wrap {
  display: inline;
}
.date {
  color: $font-color-lighter;
}
.deleted-note {
  background-color: $deleted-background;
}
</style>
