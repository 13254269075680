<template>
  <card-modal
    icon="fal fa-money-check-edit"
    :title="$t('offers.create_action.title')"
    context="createBonusOffer"
    @submit="submit"
    @cancel="cancel"
    cancel_text="offers.create_action.button_cancel"
    submit_text="offers.create_action.button_save"
    data-test="offers--add-modal"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <h-autocomplete
          autofocus
          v-model="offer.partner_id"
          :label="$t('offers.create_action.program') + '*'"
          v-validate="'required'"
          :error-messages="errors.collect('program')"
          name="program"
          data-vv-name="program"
          data-vv-as="program"
          search-action="partner/autocompleteSearch"
          data-test="add-offer--partner"
        >
        </h-autocomplete>
        <h-autocomplete
          v-model="offer.owner_business_id"
          :label="$t('offers.create_action.owner_business_id') + '*'"
          v-validate="'required'"
          :error-messages="errors.collect('owner_business_id')"
          name="owner_business_id"
          data-vv-name="owner_business_id"
          data-vv-as="owner_business_id"
          search-action="business/autocompleteSearch"
          data-test="add-offer--owner-business"
          no-filter
        >
        </h-autocomplete>
        <h-autocomplete
          v-model="offer.branding_id"
          :label="$t('offers.create_action.branding') + '*'"
          v-validate="'required'"
          :error-messages="errors.collect('branding')"
          name="branding"
          data-vv-name="branding"
          data-vv-as="branding"
          search-action="branding/autocompleteSearch"
          data-test="add-offer--branding"
          no-filter
        >
        </h-autocomplete>
        <v-layout row wrap pad-form-row-elements>
          <v-select
            :items="structureOptions"
            name="is_single_publisher"
            v-validate="'required'"
            v-model="offer.is_single_publisher"
            :label="$t('offers.create_action.is_single_publisher') + '*'"
            :error-messages="errors.collect('is_single_publisher')"
            data-vv-name="is_single_publisher"
            :data-vv-as="$t('offers.create_action.is_single_publisher')"
            item-text="name"
            item-value="id"
            data-test="add-offer--structure"
          ></v-select>
        </v-layout>
        <v-layout row wrap pad-form-row-elements>
          <v-select
            :items="canadianTimezone"
            name="timezone"
            v-validate="'required'"
            v-model="offer.timezone"
            :label="$t('offers.create_action.timezone') + '*'"
            :error-messages="errors.collect('timezone')"
            data-vv-name="timezone"
            :data-vv-as="$t('offers.update_action.timezone')"
            item-text="name"
            item-value="id"
            data-test="add-offer--timezone"
          ></v-select>
        </v-layout>
        <h4>{{ $t('offers.create_action.incentive.title') }}</h4>
        <v-layout row wrap pad-form-row-elements>
          <v-flex sm4 xs12>
            <v-text-field
              v-model="offer.incentive.bonus_percent"
              :label="$t('offers.create_action.incentive.bonus') + '*'"
              name="incentive_bonus"
              append-icon="fas fa-percentage"
              v-validate="'required|integer|min_value:1|max_value:100'"
              :error-messages="errors.collect('incentive_bonus')"
              data-vv-name="incentive_bonus"
              :data-vv-as="$t('offers.create_action.incentive.bonus')"
              data-test="add-offer--incentive_bonus"
              required
            ></v-text-field>
          </v-flex>
          <v-flex sm4 xs12>
            <v-text-field
              v-model="offer.incentive.user_inventory_limit"
              :label="$t('offers.create_action.incentive.user_inventory_limit') + '*'"
              name="incentive.user_inventory_limit"
              append-icon="fas fa-dollar-sign"
              v-validate="{
                required: true,
                integer: true,
                min_value: 1,
                max_value: 20000,
              }"
              :error-messages="errors.collect('incentive.user_inventory_limit')"
              data-vv-name="incentive.user_inventory_limit"
              :data-vv-as="$t('offers.create_action.incentive.user_inventory_limit')"
              data-test="add-offer--incentive.user_inventory_limit"
              required
            ></v-text-field>
          </v-flex>
          <v-flex sm4 xs12>
            <label-text
              :label="$t('offers.create_action.incentive.user_payment_limit')"
              :value="userPaymentLimit | currency | dollarSignI18n"
            ></label-text>
          </v-flex>
        </v-layout>
        <v-layout row wrap pad-form-row-elements>
          <v-flex sm4 xs12>
            <v-text-field
              v-model="offer.incentive.minimum_payment"
              :label="$t('offers.create_action.incentive.min') + '*'"
              name="incentive_min"
              append-icon="fas fa-dollar-sign"
              v-validate="{
                required: true,
                integer: true,
                min_value: 1,
                minimumPaymentBetween: { field1: userPaymentLimit, field2: offer.incentive.default_payment },
              }"
              :error-messages="errors.collect('incentive_min')"
              data-vv-name="incentive_min"
              :data-vv-as="$t('offers.create_action.incentive.min')"
              data-test="add-offer--incentive_min"
              required
            ></v-text-field>
          </v-flex>
          <v-flex sm4 xs12>
            <v-text-field
              v-model="offer.incentive.step"
              :label="$t('offers.create_action.incentive.step') + '*'"
              name="incentive_step"
              append-icon="fas fa-dollar-sign"
              v-validate="{ required: true, integer: true, min_value: 1, stepBetween: { field1: userPaymentLimit } }"
              :error-messages="errors.collect('incentive_step')"
              data-vv-name="incentive_step"
              :data-vv-as="$t('offers.create_action.incentive.step')"
              data-test="add-offer--incentive_step"
              required
            ></v-text-field>
          </v-flex>
          <v-flex sm4 xs12>
            <v-text-field
              v-model="offer.incentive.default_payment"
              :label="$t('offers.create_action.incentive.default_payment') + '*'"
              name="incentive.default_payment"
              append-icon="fas fa-dollar-sign"
              v-validate="{
                required: true,
                integer: true,
                defaultPaymentBetween: { field1: offer.incentive.minimum_payment, field2: userPaymentLimit },
                withinMinStep: { min: offer.incentive.minimum_payment, step: offer.incentive.step },
              }"
              :error-messages="errors.collect('incentive.default_payment')"
              data-vv-name="incentive.default_payment"
              :data-vv-as="$t('offers.create_action.incentive.default_payment')"
              data-test="add-offer--incentive.default_payment"
              required
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row wrap pad-form-row-elements>
          <v-flex sm4 xs12>
            <v-select
              :items="durationModelOptions"
              name="duration_model"
              v-validate="'required'"
              v-model="offer.incentive.duration_model"
              :label="$t('offers.create_action.incentive.duration_model') + '*'"
              :error-messages="errors.collect('duration_model')"
              data-vv-name="duration_model"
              :data-vv-as="$t('offers.create_action.incentive.duration_model')"
              item-text="name"
              item-value="id"
              data-test="add-offer--duration_model"
            ></v-select>
          </v-flex>
          <v-flex sm8 xs12>
            <v-text-field
              v-if="offer.incentive.duration_model === 'relative'"
              v-model="offer.incentive.bonus_duration_days"
              :label="$t('offers.create_action.incentive.duration') + '*'"
              :hint="$t('offers.create_action.incentive.duration_hint')"
              name="incentive_duration"
              v-validate="{
                required: offer.incentive.duration_model === 'relative',
                integer: true,
                bonusDurationBetween: { field1: offer.incentive.closure_days },
              }"
              :error-messages="errors.collect('incentive_duration')"
              data-vv-name="incentive_duration"
              :data-vv-as="$t('offers.create_action.incentive.duration')"
              data-test="add-offer--incentive_duration"
              required
            ></v-text-field>
            <v-menu
              v-if="offer.incentive.duration_model === 'fixed'"
              v-model="showBonusEndDatePickerCalendar"
              :close-on-content-click="false"
              :nudge-right="40"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-layout row wrap pad-form-row-elements>
                  <v-flex sm6 xs12>
                    <v-text-field
                      v-model="offer.incentive.bonus_end_date"
                      v-validate="{ required: offer.incentive.duration_model === 'fixed' }"
                      data-vv-name="bonus_end_date"
                      :error-messages="errors.collect('bonus_end_date')"
                      :data-vv-as="$t('offers.create_action.incentive.bonus_end_date')"
                      :label="$t('offers.create_action.incentive.bonus_end_date') + '*'"
                      data-test="offers-create-action--bonus_end_date"
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </template>
              <v-date-picker
                v-model="offer.incentive.bonus_end_date"
                @input="showBonusEndDatePickerCalendar = false"
                :show-current="new Date().toISOString().substr(0, 10)"
              ></v-date-picker>
            </v-menu>
          </v-flex>
        </v-layout>
        <v-layout row wrap pad-form-row-elements>
          <v-flex sm4 xs12>
            <v-select
              :items="refundMethodOptions"
              name="refund_method"
              v-validate="'required'"
              v-model="offer.incentive.refund_method"
              :label="$t('offers.create_action.incentive.refund_method') + '*'"
              :error-messages="errors.collect('refund_method')"
              data-vv-name="refund_method"
              :data-vv-as="$t('offers.create_action.incentive.refund_method')"
              item-text="name"
              item-value="id"
              data-test="add-offer--refund_method"
            ></v-select>
          </v-flex>
          <v-flex sm8 xs12 v-if="offer.incentive.refund_method === OfferRefundType.OPEN_WALLET">
            <v-text-field
              v-if="offer.incentive.duration_model === 'relative'"
              v-model="offer.incentive.closure_days"
              :label="$t('offers.create_action.incentive.closure') + '*'"
              :hint="$t('offers.create_action.incentive.duration_hint')"
              name="incentive_closure"
              v-validate="{
                required: offer.incentive.duration_model === 'relative',
                integer: true,
                closureBetween: { field1: offer.incentive.bonus_duration_days },
              }"
              :error-messages="errors.collect('incentive_closure')"
              data-vv-name="incentive_closure"
              :data-vv-as="$t('offers.create_action.incentive.closure')"
              data-test="add-offer--incentive_closure"
              required
            ></v-text-field>
            <v-menu
              v-if="offer.incentive.duration_model === 'fixed'"
              v-model="showClosureEndDatePickerCalendar"
              :close-on-content-click="false"
              :nudge-right="40"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-layout row wrap pad-form-row-elements>
                  <v-flex sm6 xs12>
                    <v-text-field
                      v-model="offer.incentive.closure_end_date"
                      v-validate="{ required: offer.incentive.duration_model === 'fixed', islater: { date: offer.incentive.bonus_end_date } }"
                      data-vv-name="closure_end_date"
                      :error-messages="errors.collect('closure_end_date')"
                      :data-vv-as="$t('offers.create_action.incentive.closure_end_date')"
                      :label="$t('offers.create_action.incentive.closure_end_date') + '*'"
                      data-test="offers-create-action--closure_end_date"
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </template>
              <v-date-picker
                v-model="offer.incentive.closure_end_date"
                @input="showClosureEndDatePickerCalendar = false"
                :show-current="new Date().toISOString().substr(0, 10)"
              ></v-date-picker>
            </v-menu>
          </v-flex>
        </v-layout>
        <h4>{{ $t('offers.create_action.availability.title') }}</h4>
        <v-layout row wrap pad-form-row-elements>
          <v-flex sm9 xs12>
            <h-autocomplete
              v-model="offer.availability.inventory_id"
              :label="$t('offers.create_action.availability.inventory') + '*'"
              v-validate="'required'"
              :error-messages="errors.collect('inventory')"
              name="inventory"
              :alternate-text="inventoryLookupItemText"
              data-vv-name="inventory"
              data-vv-as="inventory"
              search-action="inventory/autocompleteSearch"
              data-test="add-offer--inventory"
              no-filter
            >
            </h-autocomplete>
          </v-flex>
          <v-flex sm3 xs12>
            <v-checkbox
              name="refillable"
              v-model="offer.availability.is_refillable"
              :label="$t(`offers.create_action.availability.refillable`)"
              :error-messages="errors.collect('refillable')"
              data-vv-name="refillable"
              :data-vv-as="$t(`offers.create_action.availability.refillable`)"
              type="checkbox"
            ></v-checkbox>
          </v-flex>
        </v-layout>
        <v-layout row wrap pad-form-row-elements v-if="!offer.is_single_publisher">
          <v-flex sm6 xs12>
            <v-text-field
              v-model="offer.availability.publisher_default_inventory_limit"
              :label="$t('offers.create_action.availability.publisher_default_inventory_limit') + '*'"
              name="availability.publisher_default_inventory_limit"
              append-icon="fas fa-dollar-sign"
              v-validate="{
                required: true,
                integer: true,
                min_value: 1,
              }"
              :error-messages="errors.collect('availability.publisher_default_inventory_limit')"
              data-vv-name="availability.publisher_default_inventory_limit"
              :data-vv-as="$t('offers.create_action.availability.publisher_default_inventory_limit')"
              data-test="add-offer--availability.publisher_default_inventory_limit"
              required
            ></v-text-field>
          </v-flex>
          <v-flex sm6 xs12>
            <label-text
              :label="$t('offers.create_action.availability.publisher_payment_limit')"
              :value="publisherPaymentLimit | currency | dollarSignI18n"
            ></label-text>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12 sm12>
            <v-menu
              v-model="showStartDatePickerCalendar"
              :close-on-content-click="false"
              :nudge-right="40"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-layout row wrap pad-form-row-elements>
                  <v-flex sm6 xs12>
                    <v-text-field
                      v-model="offer.availability.start_date"
                      v-validate="'required'"
                      data-vv-name="start_date"
                      :error-messages="errors.collect('start_date')"
                      :data-vv-as="$t('offers.create_action.availability.start_date')"
                      :label="$t('offers.create_action.availability.start_date') + '*'"
                      data-test="offers-create-action--start_date"
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </v-flex>
                  <v-flex sm6 xs12>
                    <v-select
                      :items="generateRangeOfThirtyMinutes"
                      name="start_time"
                      v-validate="'required'"
                      v-model="offer.availability.start_time"
                      :label="$t('offers.create_action.availability.start_time') + '*'"
                      :error-messages="errors.collect('start_time')"
                      data-vv-name="start_time"
                      :data-vv-as="$t('offers.create_action.availability.start_time')"
                      prepend-icon="far fa-clock"
                      data-test="offers-create-action--start_time"
                    ></v-select>
                  </v-flex>
                </v-layout>
              </template>
              <v-date-picker
                v-model="offer.availability.start_date"
                @input="showStartDatePickerCalendar = false"
                :show-current="new Date().toISOString().substring(0, 10)"
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs12 sm12>
            <v-menu
              v-model="showEndDatePickerCalendar"
              :close-on-content-click="false"
              :nudge-right="40"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-layout row wrap pad-form-row-elements>
                  <v-flex sm6 xs12>
                    <v-text-field
                      v-model="offer.availability.end_date"
                      data-vv-name="end_date"
                      :error-messages="errors.collect('end_date')"
                      v-validate="{ required: true, islater: { date: offer.availability.start_date } }"
                      :data-vv-as="$t('offers.create_action.availability.end_date')"
                      :label="$t('offers.create_action.availability.end_date') + '*'"
                      data-test="offers-create-action--end_date"
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </template>
              <v-date-picker
                v-model="offer.availability.end_date"
                @input="showEndDatePickerCalendar = false"
                :show-current="new Date().toISOString().substr(0, 10)"
                :min="offer.availability.start_date"
              ></v-date-picker>
            </v-menu>
          </v-flex>
        </v-layout>

        <h4>{{ $t('offers.create_action.accounting_section.title') }}</h4>
        <v-flex sm4 xs12>
          <v-select
            :items="customerTypeOptions"
            v-validate="'required'"
            name="customer_type"
            v-model="offer.customer_type"
            :label="$t('offers.create_action.accounting_section.customer_type') + '*'"
            :error-messages="errors.collect('customer_type')"
            data-vv-name="customer_type"
            :data-vv-as="$t('offers.create_action.accounting_section.customer_type')"
            item-text="name"
            item-value="id"
            data-test="update-offer--customer_type"
          ></v-select>
        </v-flex>
        <v-layout row wrap pad-form-row-elements>
          <v-text-field
            :label="$t('offers.create_action.accounting_section.reference_id')"
            name="reference_id"
            v-validate="'max:100'"
            :error-messages="errors.collect('reference_id')"
            v-model="offer.reference_id"
            data-vv-label="reference_id"
            maxlength="100"
            :data-vv-as="$t('offers.create_action.accounting_section.reference_id')"
            data-test="reference_id"
          >
          </v-text-field>
        </v-layout>

        <v-layout row wrap pad-form-row-elements>
          <h-autocomplete
            v-model="offer.bonus_breakage_owner_business_id"
            :label="$t('offers.create_action.breakage_bonus_owner')"
            :error-messages="errors.collect('bonus_breakage_owner_business_id')"
            name="bonus_breakage_owner_business_id"
            data-vv-name="bonus_breakage_owner_business_id"
            :data-vv-as="$t('offers.create_action.breakage_bonus_owner')"
            search-action="business/autocompleteSearch"
            data-test="update-offer--bonus_breakage_owner_business_id"
            clearable
          >
          </h-autocomplete>
        </v-layout>

        <v-layout row wrap pad-form-row-elements>
          <h-autocomplete
            v-model="offer.payment_breakage_owner_business_id"
            :label="$t('offers.create_action.breakage_payment_owner')"
            :error-messages="errors.collect('payment_breakage_owner_business_id')"
            name="payment_breakage_owner_business_id"
            data-vv-name="payment_breakage_owner_business_id"
            :data-vv-as="$t('offers.create_action.breakage_payment_owner')"
            search-action="business/autocompleteSearch"
            data-test="update-offer--payment_breakage_owner_business_id"
            clearable
          >
          </h-autocomplete>
        </v-layout>

        <h4>{{ $t('offers.create_action.features.title') }}</h4>
        <v-layout column class="features-checkboxes">
          <v-flex xs12>
            <v-checkbox
              name="send_reminder_emails"
              v-model="offer.features.send_reminder_emails"
              :label="$t(`offers.create_action.features.send_reminder_emails`)"
              :error-messages="errors.collect('send_reminder_emails')"
              data-vv-name="send_reminder_emails"
              :data-vv-as="$t(`offers.create_action.features.send_reminder_emails`)"
              type="checkbox"
            ></v-checkbox>
          </v-flex>

          <v-flex xs12>
            <v-checkbox
              name="cash_flow_optimization"
              v-model="offer.features.cash_flow_optimization"
              :label="$t(`offers.create_action.features.cash_flow_optimization`)"
              :error-messages="errors.collect('cash_flow_optimization')"
              data-vv-name="cash_flow_optimization"
              :data-vv-as="$t(`offers.create_action.features.cash_flow_optimization`)"
              type="checkbox"
            ></v-checkbox>
          </v-flex>

          <v-flex xs12>
            <v-checkbox
              name="crowdfunding"
              v-model="offer.features.crowdfunding"
              :label="$t(`offers.create_action.features.crowdfunding`)"
              :error-messages="errors.collect('crowdfunding')"
              data-vv-name="crowdfunding"
              :data-vv-as="$t(`offers.create_action.features.crowdfunding`)"
              type="checkbox"
            ></v-checkbox>
          </v-flex>

          <v-flex xs12>
            <v-checkbox
              name="hide_store_before_start_date"
              v-model="offer.features.hide_store_before_start_date"
              :label="$t(`offers.create_action.features.hide_store_before_start_date`)"
              :error-messages="errors.collect('hide_store_before_start_date')"
              data-vv-name="hide_store_before_start_date"
              :data-vv-as="$t(`offers.create_action.features.hide_store_before_start_date`)"
              type="checkbox"
            ></v-checkbox>
          </v-flex>

          <v-flex xs12>
            <v-checkbox
              name="private"
              v-model="offer.features.private"
              :label="$t(`offers.create_action.features.private`)"
              :error-messages="errors.collect('private')"
              data-vv-name="private"
              :data-vv-as="$t(`offers.create_action.features.private`)"
              type="checkbox"
            ></v-checkbox>
          </v-flex>

          <v-flex xs12>
            <v-checkbox
              name="restricted_to_members"
              v-model="offer.features.restricted_to_members"
              :label="$t(`offers.create_action.features.restricted_to_members`)"
              :error-messages="errors.collect('restricted_to_members')"
              data-vv-name="private"
              :data-vv-as="$t(`offers.create_action.features.restricted_to_members`)"
              type="checkbox"
            ></v-checkbox>
          </v-flex>
        </v-layout>
        <v-layout row wrap pad-form-row-elements>
          <v-flex sm8 xs12>
            <v-text-field
              :label="$t(`offers.create_action.features.slug`)"
              name="slug"
              v-validate="'slug|max:50'"
              :error-messages="errors.collect('slug')"
              v-model="offer.features.slug"
              data-vv-label="features.slug"
              maxlength="50"
              :hint="$t('offers.create_action.features.slug_hint')"
              :data-vv-as="$t('offers.create_action.features.slug')"
              data-test="features-slug"
            >
              <div slot="prepend-inner" :style="{ 'margin-top': '5px' }">/</div>
            </v-text-field>
          </v-flex>
        </v-layout>
        <h4>{{ $t('offers.create_action.funding.title') }}</h4>
        <v-layout row wrap pad-form-row-elements>
          <h-autocomplete
            :readonly="offer.funding.bonus.trigger === 'upfront'"
            v-model="offer.contributor_business_id"
            :label="$t('offers.create_action.contributor') + '*'"
            v-validate="'required'"
            :error-messages="errors.collect('contributor')"
            name="contributor"
            data-vv-name="contributor"
            :data-vv-as="$t('offers.create_action.contributor')"
            search-action="business/autocompleteSearch"
            data-test="add-offer--contributor"
          >
          </h-autocomplete>
        </v-layout>

        <h5 class="sub-title">{{ $t('offers.create_action.funding.bonus') }}</h5>
        <v-layout row wrap pad-form-row-elements>
          <v-flex xs12 sm4>
            <v-select
              :items="fundingTriggerOptions"
              name="bonus_trigger"
              v-validate="'required'"
              v-model="offer.funding.bonus.trigger"
              :label="$t('offers.create_action.funding.trigger') + '*'"
              :error-messages="errors.collect('bonus_trigger')"
              data-vv-name="bonus_trigger"
              :data-vv-as="$t('offers.create_action.funding.trigger')"
              item-text="name"
              item-value="id"
              data-test="add-offer--bonus_trigger"
            ></v-select>
          </v-flex>
        </v-layout>
        <v-layout row wrap pad-form-row-elements>
          <v-flex sm4 xs12>
            <v-text-field
              v-model="offer.funding.bonus.contributor"
              :label="$t('offers.create_action.funding.contributor') + '*'"
              name="funding_contributor"
              append-icon="fas fa-percentage"
              v-validate="{
                required: true,
                decimal: 2,
                max: 6,
                max_value: 100,
                fundingBonusSumsTo: { field1: offer.funding.bonus.publisher, field2: offer.funding.bonus.store, total: 100 },
              }"
              :error-messages="errors.collect('funding_contributor')"
              data-vv-name="funding_contributor"
              :data-vv-as="$t('offers.create_action.funding.contributor')"
              data-test="add-offer--funding_contributor"
              required
            ></v-text-field>
          </v-flex>
          <v-flex sm4 xs12>
            <v-text-field
              v-model="offer.funding.bonus.publisher"
              :label="$t('offers.create_action.funding.publisher') + '*'"
              name="funding_publisher"
              append-icon="fas fa-percentage"
              v-validate="{
                required: true,
                decimal: 2,
                max: 6,
                max_value: 100,
                fundingBonusSumsTo: { field1: offer.funding.bonus.contributor, field2: offer.funding.bonus.store, total: 100 },
              }"
              :error-messages="errors.collect('funding_publisher')"
              data-vv-name="funding_publisher"
              :data-vv-as="$t('offers.create_action.funding.publisher')"
              data-test="add-offer--funding_publisher"
              required
            ></v-text-field>
          </v-flex>
          <v-flex sm4 xs12>
            <v-text-field
              :disabled="!isFundingStoreAllowed"
              v-model="offer.funding.bonus.store"
              :label="$t('offers.create_action.funding.store') + '*'"
              name="funding_store"
              append-icon="fas fa-percentage"
              v-validate="{
                required: true,
                decimal: 2,
                max: 6,
                max_value: 100,
                fundingBonusSumsTo: { field1: offer.funding.bonus.contributor, field2: offer.funding.bonus.publisher, total: 100 },
              }"
              :error-messages="errors.collect('funding_store')"
              data-vv-name="funding_store"
              :data-vv-as="$t('offers.create_action.funding.store')"
              data-test="add-offer--funding_store"
              required
            ></v-text-field>
          </v-flex>
        </v-layout>

        <h5 class="sub-title">{{ $t('offers.create_action.funding.payment') }}</h5>
        <v-layout row wrap pad-form-row-elements>
          <v-flex xs12 sm6>
            <v-select
              :items="fundingPayerOptions"
              name="payer"
              v-validate="'required'"
              v-model="offer.funding.payer"
              :label="$t('offers.create_action.funding.trigger') + '*'"
              :error-messages="errors.collect('payer')"
              data-vv-name="payer"
              :data-vv-as="$t('offers.create_action.funding.payer')"
              item-text="name"
              item-value="id"
              data-test="add-offer--payer"
            ></v-select>
          </v-flex>
        </v-layout>

        <h4>{{ $t('offers.update_action.fees.title') }}</h4>

        <v-layout row wrap pad-form-row-elements>
          <v-flex sm6 xs12>
            <v-select
              :items="feePaymentMethodOptions"
              name="fee_payment_method"
              v-validate="'required'"
              v-model="offer.fee_payment_method"
              :label="$t('offers.update_action.fees.payment_method.title') + '*'"
              :error-messages="errors.collect('purchase_fixed_fee_payer')"
              data-vv-name="fee_payment_method"
              :data-vv-as="$t('offers.update_action.fees.payment_method.title')"
              item-text="name"
              item-value="id"
              data-test="update-offer--fee_payment_method"
            ></v-select>
          </v-flex>
        </v-layout>

        <template v-if="hasAutomatedFeesPaymentMethod">
          <h5>{{ $t('offers.create_action.purchase_fee.title') }}</h5>
          <v-layout row wrap pad-form-row-elements>
            <v-flex sm6 xs12>
              <v-select
                :items="purchasePercentFeePayerOptions"
                name="purchase_percent_fee_payer"
                v-validate="'required'"
                v-model="offer.purchase_fee.percent_fee.payer"
                :label="$t('offers.create_action.purchase_fee.percent_fee.payer') + '*'"
                :error-messages="errors.collect('purchase_percent_fee_payer')"
                data-vv-name="purchase_percent_fee_payer"
                :data-vv-as="$t('offers.create_action.purchase_fee.percent_fee.payer')"
                item-text="name"
                item-value="id"
                data-test="add-offer--purchase_percent_fee_payer"
              ></v-select>
              <v-text-field
                v-if="offer.purchase_fee.percent_fee.payer !== 'none'"
                v-model="offer.purchase_fee.percent_fee.ratio"
                :label="$t('offers.create_action.purchase_fee.percent_fee.ratio') + '*'"
                name="purchase_percent_fee_ratio"
                append-icon="fas fa-percentage"
                v-validate="{ required: offer.purchase_fee.percent_fee.payer !== 'none', decimal: 2, max: 6, min_value: 0.01, max_value: 100 }"
                :error-messages="errors.collect('purchase_percent_fee_ratio')"
                data-vv-name="purchase_percent_fee_ratio"
                :data-vv-as="$t('offers.create_action.purchase_fee.percent_fee.ratio')"
                data-test="add-offer--purchase_percent_fee_ratio"
                required
              ></v-text-field>
              <v-select
                v-if="offer.purchase_fee.percent_fee.payer !== 'none'"
                :items="purchasePercentFeeScopeOptions"
                name="purchase_percent_fee_scope"
                v-validate="{ required: offer.purchase_fee.percent_fee.payer !== 'none' }"
                v-model="offer.purchase_fee.percent_fee.scope"
                :label="$t('offers.create_action.purchase_fee.percent_fee.scope') + '*'"
                :error-messages="errors.collect('purchase_percent_fee_scope')"
                data-vv-name="purchase_percent_fee_scope"
                :data-vv-as="$t('offers.create_action.purchase_fee.percent_fee.scope')"
                item-text="name"
                item-value="id"
                data-test="add-offer--purchase_percent_fee_scope"
              ></v-select>
            </v-flex>
            <v-flex sm6 xs12>
              <v-select
                :items="purchasePercentFeePayerOptions"
                name="purchase_fixed_fee_payer"
                v-validate="'required'"
                v-model="offer.purchase_fee.fixed_fee.payer"
                :label="$t('offers.create_action.purchase_fee.fixed_fee.payer') + '*'"
                :error-messages="errors.collect('purchase_fixed_fee_payer')"
                data-vv-name="purchase_fixed_fee_payer"
                :data-vv-as="$t('offers.create_action.purchase_fee.fixed_fee.payer')"
                item-text="name"
                item-value="id"
                data-test="add-offer--purchase_fixed_fee_payer"
              ></v-select>
              <v-text-field
                v-if="offer.purchase_fee.fixed_fee.payer !== 'none'"
                v-model="offer.purchase_fee.fixed_fee.amount"
                :label="$t('offers.create_action.purchase_fee.fixed_fee.amount') + '*'"
                name="purchase_fixed_fee_amount"
                append-icon="fas fa-dollar-sign"
                v-validate="{ required: offer.purchase_fee.fixed_fee.payer !== 'none', decimal: 2, max: 6, min_value: 0.01, max_value: 100.0 }"
                :error-messages="errors.collect('purchase_fixed_fee_amount')"
                data-vv-name="purchase_fixed_fee_amount"
                :data-vv-as="$t('offers.create_action.purchase_fee.fixed_fee.amount')"
                data-test="add-offer--purchase_fixed_fee_amount"
                required
              ></v-text-field>
            </v-flex>
          </v-layout>

          <h5>{{ $t('offers.create_action.transaction_fee.title') }}</h5>
          <v-layout row wrap pad-form-row-elements>
            <v-flex sm6 xs12>
              <v-select
                :items="transactionPercentFeePayerOptions"
                name="transaction_percent_fee_payer"
                v-validate="'required'"
                v-model="offer.transaction_fee.percent_fee.payer"
                :label="$t('offers.create_action.transaction_fee.percent_fee.payer') + '*'"
                :error-messages="errors.collect('transaction_percent_fee_payer')"
                data-vv-name="transaction_percent_fee_payer"
                :data-vv-as="$t('offers.create_action.transaction_fee.percent_fee.payer')"
                item-text="name"
                item-value="id"
                data-test="add-offer--transaction_percent_fee_payer"
              ></v-select>
              <v-text-field
                v-if="offer.transaction_fee.percent_fee.payer !== 'none'"
                v-model="offer.transaction_fee.percent_fee.ratio"
                :label="$t('offers.create_action.transaction_fee.percent_fee.ratio') + '*'"
                name="transaction_percent_fee_ratio"
                append-icon="fas fa-percentage"
                v-validate="{ required: offer.transaction_fee.percent_fee.payer !== 'none', decimal: 2, max: 6, max_value: 100 }"
                :error-messages="errors.collect('transaction_percent_fee_ratio')"
                data-vv-name="transaction_percent_fee_ratio"
                :data-vv-as="$t('offers.create_action.transaction_fee.percent_fee.ratio')"
                data-test="add-offer--transaction_percent_fee_ratio"
                required
              ></v-text-field>
              <v-select
                v-if="offer.transaction_fee.percent_fee.payer !== 'none'"
                :items="transactionPercentFeeScopeOptions"
                name="transaction_percent_fee_scope"
                v-validate="{ required: offer.transaction_fee.percent_fee.payer !== 'none' }"
                v-model="offer.transaction_fee.percent_fee.scope"
                :label="$t('offers.create_action.transaction_fee.percent_fee.scope') + '*'"
                :error-messages="errors.collect('transaction_percent_fee_scope')"
                data-vv-name="transaction_percent_fee_scope"
                :data-vv-as="$t('offers.create_action.transaction_fee.percent_fee.scope')"
                item-text="name"
                item-value="id"
                data-test="add-offer--transaction_percent_fee_scope"
              ></v-select>
            </v-flex>
            <v-flex sm6 xs12>
              <v-select
                :items="transactionPercentFeePayerOptions"
                name="transaction_fixed_fee_payer"
                v-validate="'required'"
                v-model="offer.transaction_fee.fixed_fee.payer"
                :label="$t('offers.create_action.transaction_fee.fixed_fee.payer') + '*'"
                :error-messages="errors.collect('transaction_fixed_fee_payer')"
                data-vv-name="transaction_fixed_fee_payer"
                :data-vv-as="$t('offers.create_action.transaction_fee.fixed_fee.payer')"
                item-text="name"
                item-value="id"
                data-test="add-offer--transaction_fixed_fee_payer"
              ></v-select>
              <v-text-field
                v-if="offer.transaction_fee.fixed_fee.payer !== 'none'"
                v-model="offer.transaction_fee.fixed_fee.amount"
                :label="$t('offers.create_action.transaction_fee.fixed_fee.amount') + '*'"
                name="transaction_fixed_fee_amount"
                append-icon="fas fa-dollar-sign"
                v-validate="{ required: offer.transaction_fee.fixed_fee.payer !== 'none', decimal: 2, max: 6, min_value: 0.01, max_value: 100.0 }"
                :error-messages="errors.collect('transaction_fixed_fee_amount')"
                data-vv-name="transaction_fixed_fee_amount"
                :data-vv-as="$t('offers.create_action.transaction_fee.fixed_fee.amount')"
                data-test="add-offer--transaction_fixed_fee_amount"
                required
              ></v-text-field>
            </v-flex>
          </v-layout>
        </template>

        <h4>{{ $t('offers.create_gift_action.user_fee.title') }}</h4>
        <v-layout row wrap pad-form-row-elements>
          <v-flex sm4 xs12>
            <v-select
              :items="userFeeStrategyOptions"
              name="user_fee_strategy"
              v-validate="'required'"
              v-model="offer.user_fee.strategy"
              :label="$t('offers.create_gift_action.user_fee.fees_policy') + '*'"
              :error-messages="errors.collect('user_fee_strategy')"
              data-vv-name="duration_model"
              :data-vv-as="$t('offers.create_gift_action.user_fee.fees_policy')"
              item-text="name"
              item-value="id"
              data-test="add-offer--user_fee_strategy"
              required
            ></v-select>
          </v-flex>
        </v-layout>
        <div v-if="offer.user_fee.strategy === DormancyType.DORMANCY">
          <v-layout row wrap pad-form-row-elements>
            <v-flex sm4 xs12>
              <v-text-field
                v-model="offer.user_fee.dormancy.fixed_fee"
                :label="$t('offers.create_gift_action.user_fee.dormancy_fee') + '*'"
                name="user_fee_dormancy_fee"
                append-icon="fas fa-dollar-sign"
                v-validate="'required|decimal:2|min_value:0.01|max_value:10.00'"
                :error-messages="errors.collect('user_fee_dormancy_fee')"
                data-vv-name="user_fee_dormancy_fee"
                :data-vv-as="$t('offers.create_gift_action.user_fee.dormancy_fee')"
                data-test="add-offer--user_fee_dormancy_fee"
                required
              ></v-text-field>
            </v-flex>
            <v-flex sm4 xs12>
              <v-text-field
                v-model="offer.user_fee.dormancy.last_activity_trigger_in_months"
                :label="$t('offers.create_gift_action.user_fee.dormancy_trigger') + '*'"
                name="user_fee_dormancy_trigger"
                v-validate="'required|integer|min_value:1|max_value:24'"
                :error-messages="errors.collect('user_fee_dormancy_trigger')"
                data-vv-name="user_fee_dormancy_trigger"
                :data-vv-as="$t('offers.create_gift_action.user_fee.dormancy_trigger')"
                data-test="add-offer--user_fee_dormancy_trigger"
                required
              ></v-text-field>
            </v-flex>
            <v-flex sm4 xs12>
              <v-text-field
                v-model="offer.user_fee.dormancy.extension_in_months"
                :label="$t('offers.create_gift_action.user_fee.dormancy_extension_in_months') + '*'"
                name="user_fee_dormancy_extension_in_months"
                v-validate="'required|integer|min_value:0|max_value:12'"
                :error-messages="errors.collect('user_fee_dormancy_extension_in_months')"
                data-vv-name="user_fee_dormancy_extension_in_months"
                :data-vv-as="$t('offers.create_gift_action.user_fee.dormancy_extension_in_months')"
                data-test="add-offer--user_fee_dormancy_extension_in_months"
                required
              ></v-text-field>
            </v-flex>
          </v-layout>
        </div>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import OfferRefundType from '@/enums/offer-refund-type';
import CANADIAN_TIMEZONES from '@/tools/canadian-timezones';
import _ from 'lodash';
import LabelText from '@/components/system/Labels/LabelText';
import moment from 'moment-timezone';
import DormancyType from '@/enums/dormancy-type';
import { adjustValueForMinStep } from '@/utils/offer-utils';

export default {
  name: 'create-offers-action',
  components: { LabelText },
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  data() {
    return {
      OfferRefundType,
      DormancyType,
      offer: {
        contributor_business_id: null,
        user_fee: { strategy: 'none', dormancy: { fixed_fee: null, last_activity_trigger_in_months: null, extension_in_months: null } },
        incentive: { refund_method: null, duration_model: 'relative' },
        availability: { is_refillable: 1, start_time: '00:00' },
        is_single_publisher: null,
        features: {
          send_reminder_emails: true,
          cash_flow_optimization: false,
          crowdfunding: false,
          private: false,
          restricted_to_members: false,
          hide_store_before_start_date: false,
          slug: null,
        },
        funding: { bonus: { contributor: 100, publisher: 0, store: 0 }, payer: 'cardholder', payment: { trigger: 'transaction' } },
        fee_payment_method: 'none',
        purchase_fee: { percent_fee: { payer: 'none' }, fixed_fee: { payer: 'none' } },
        transaction_fee: { percent_fee: { payer: 'none' }, fixed_fee: { payer: 'none' } },
      },
      showStartDatePickerCalendar: false,
      showEndDatePickerCalendar: false,
      showBonusEndDatePickerCalendar: false,
      showClosureEndDatePickerCalendar: false,
    };
  },
  watch: {
    async isFundingStoreAllowed(newVal) {
      if (!newVal) {
        this.offer.funding.bonus.store = 0;
      }
    },
    async 'offer.funding.bonus.trigger'() {
      if (_.get(this.offer, 'funding.bonus.trigger') === 'upfront') {
        this.offer.contributor_business_id = process.env.VUE_APP_DEFAULT_UPFRONT_CONTRIBUTOR;
      } else {
        this.offer.contributor_business_id = null;
      }
    },
    async 'offer.user_fee.strategy'(value) {
      if (value === 'dormancy') {
        this.offer.user_fee.dormancy = {
          fixed_fee: 2.5,
          last_activity_trigger_in_months: 14,
          extension_in_months: 4,
        };
      } else if (value === 'none') {
        this.offer.user_fee.dormancy = {
          fixed_fee: null,
          last_activity_trigger_in_months: null,
          extension_in_months: null,
        };
      }
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('offers', [
      'incentiveRefundMethods',
      'customerTypes',
      'incentiveDurationModels',
      'structureModels',
      'bonusFundingPayers',
      'fundingPayerPaymentValues',
      'fundingTriggers',
      'feePaymentMethods',
      'purchasePercentFeeScope',
      'purchasePercentFeePayer',
      'transactionPercentFeeScope',
      'transactionPercentFeePayer',
      'userFeeStrategies',
    ]),
    hasAutomatedFeesPaymentMethod() {
      return _.get(this, 'offer.fee_payment_method') === 'monthly_automated_pad';
    },
    isFundingStoreAllowed() {
      if (this.offer.funding.bonus.trigger === 'purchase') {
        return false;
      }
      return true;
    },
    generateRangeOfThirtyMinutes() {
      return Array.from({ length: 24 }, (_, i) => i).reduce((r, hour) => {
        r.push(moment({ hour, minute: 0 }).format('HH:mm'));
        r.push(moment({ hour, minute: 30 }).format('HH:mm'));
        return r;
      }, []);
    },
    canadianTimezone() {
      return CANADIAN_TIMEZONES.map((zone) => {
        return {
          name: `${zone} (${moment()
            .tz(zone)
            .format('Z z')})`,
          id: zone,
        };
      });
    },
    userPaymentLimit() {
      return this.offer.incentive.bonus_percent && this.offer.incentive.user_inventory_limit
        ? adjustValueForMinStep(
            this.offer.incentive.minimum_payment,
            this.offer.incentive.step,
            this.offer.incentive.user_inventory_limit / (this.offer.incentive.bonus_percent / 100),
          )
        : 0;
    },
    publisherPaymentLimit() {
      return this.offer.incentive.bonus_percent && this.offer.availability.publisher_default_inventory_limit
        ? adjustValueForMinStep(
            this.offer.incentive.minimum_payment,
            this.offer.incentive.step,
            this.offer.availability.publisher_default_inventory_limit / (this.offer.incentive.bonus_percent / 100),
          )
        : 0;
    },
    refundMethodOptions() {
      return _.map(this.incentiveRefundMethods, (refund) => ({
        name: this.$t(`offers.incentive_refund_methods.${refund}`),
        id: refund,
      }));
    },
    customerTypeOptions() {
      return _.map(this.customerTypes, (type) => ({
        name: this.$t(`offers.customer_type.${type}`),
        id: type,
      }));
    },
    durationModelOptions() {
      return _.map(this.incentiveDurationModels, (durationModel) => ({
        name: this.$t(`offers.incentive_duration_models.${durationModel}`),
        id: durationModel,
      }));
    },
    structureOptions() {
      return _.map(this.structureModels, (structure) => ({
        name: this.$t(`offers.single_publisher.${structure}`),
        id: structure,
      }));
    },
    feePaymentMethodOptions() {
      return _.map(this.feePaymentMethods, (feePayment) => {
        return {
          name: this.$t(`offers.fee_payment_methods.${feePayment}`),
          id: feePayment,
        };
      });
    },
    fundingTriggerOptions() {
      return _.map(this.fundingTriggers, (item) => ({
        name: this.$t(`offers.funding_triggers.${item}`),
        id: item,
      }));
    },
    fundingPayerOptions() {
      return _.map(this.bonusFundingPayers, (item) => ({
        name: this.$t(`offers.funding_payer_triggers.${item}`),
        id: item,
      }));
    },
    purchasePercentFeeScopeOptions() {
      return _.map(this.purchasePercentFeeScope, (item) => ({
        name: this.$t(`offers.fee_purchase_scope.${item}`),
        id: item,
      }));
    },
    purchasePercentFeePayerOptions() {
      return _.map(this.purchasePercentFeePayer, (item) => ({
        name: this.$t(`offers.fee_purchase_payer.${item}`),
        id: item,
      }));
    },
    transactionPercentFeeScopeOptions() {
      return _.map(this.transactionPercentFeeScope, (item) => ({
        name: this.$t(`offers.fee_transaction_scope.${item}`),
        id: item,
      }));
    },
    transactionPercentFeePayerOptions() {
      return _.map(this.transactionPercentFeePayer, (item) => ({
        name: this.$t(`offers.fee_transaction_payer.${item}`),
        id: item,
      }));
    },
    userFeeStrategyOptions() {
      return _.map(this.userFeeStrategies, (item) => ({
        name: this.$t(`offers.user_fee_strategy.${item}`),
        id: item,
      }));
    },
  },
  methods: {
    inventoryLookupItemText(it) {
      return `${it.name} - ${this.$options.filters.dollarSignI18n(this.$options.filters.currency(it.available_amount))}`;
    },
    clear() {
      this.offer = {
        contributor_business_id: null,
        user_fee: { strategy: 'none', dormancy: { fixed_fee: null, last_activity_trigger_in_months: null, extension_in_months: null } },
        incentive: { refund_method: null, duration_model: 'relative' },
        availability: { is_refillable: 1, start_time: '00:00' },
        is_single_publisher: null,
        features: {
          send_reminder_emails: true,
          cash_flow_optimization: false,
          crowdfunding: false,
          private: false,
          restricted_to_members: false,
          slug: null,
        },
        funding: { bonus: { contributor: 100, publisher: 0, store: 0 }, payer: 'cardholder', payment: { trigger: 'transaction' } },
        purchase_fee: { percent_fee: { payer: 'none' }, fixed_fee: { payer: 'none' } },
        transaction_fee: { percent_fee: { payer: 'none' }, fixed_fee: { payer: 'none' } },
      };
      this.clearDatePickerModal();
      this.$validator.reset();
    },
    clearDatePickerModal() {
      this.showConfirmModal = false;
      this.showStartDatePickerCalendar = false;
      this.showEndDatePickerCalendar = false;
      this.showBonusEndDatePickerCalendar = false;
      this.showClosureEndDatePickerCalendar = false;
    },
    success(response) {
      this.clear();
      this.$emit('created');
      this.$router.push({ name: 'offers_details', params: { offerId: response.id } });
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        await this.executeAction(
          {
            action: 'offers/createBonusOffer',
            name: 'createBonusOffer',
            success: this.success,
          },
          this.buildPayload(),
        );
      }
    },
    buildPayload() {
      const payload = _.cloneDeep(this.offer);

      if (payload.features.slug) {
        payload.features.slug = payload.features.slug.toLowerCase();
      }

      // TODO (JG) We force the amount to 0 in the case of a single publisher, Maybe provide a better solution.
      if (this.offer.is_single_publisher) {
        payload.availability.publisher_default_inventory_limit = 0;
      }

      payload.availability.is_refillable = !!this.offer.availability.is_refillable;

      if (this.offer.user_fee.strategy === 'none') {
        payload.user_fee.dormancy.fixed_fee = null;
        payload.user_fee.dormancy.last_activity_trigger_in_months = null;
        payload.user_fee.dormancy.extension_in_months = null;
      }

      if (this.offer.incentive.refund_method === 'no_refund') {
        payload.incentive.closure_days = null;
        payload.incentive.closure_end_date = null;
      }

      if (this.offer.incentive.duration_model !== 'relative') {
        payload.incentive.bonus_duration_days = null;
        payload.incentive.closure_days = null;
      }

      if (this.offer.incentive.duration_model !== 'fixed') {
        payload.incentive.bonus_end_date = null;
        payload.incentive.closure_end_date = null;
      }

      if (!this.offer.bonus_breakage_owner_business_id) {
        payload.bonus_breakage_owner_business_id = null;
      }
      if (!this.offer.payment_breakage_owner_business_id) {
        payload.payment_breakage_owner_business_id = null;
      }

      payload.customer_type = this.offer.customer_type;

      const payerPaymentValue = this.fundingPayerPaymentValues[this.offer.funding.payer];
      payload.funding.payer = payerPaymentValue.payer;
      payload.funding.payment.trigger = payerPaymentValue.payment_trigger;

      return payload;
    },
  },
};
</script>

<style scoped lang="stylus">
@import './../../../styles/variables/custom-variables.styl';

h5 {
  color: $hc-blue;

  &.sub-title {
    color: $hc-blue;
  }
}

.features-checkboxes .flex:first-child .v-input--selection-controls {
  margin-top: 16px
}

.v-input--selection-controls {
  margin-top: 0;
}

.features-checkboxes .v-input--selection-controls:not(.v-input--hide-details) .v-input__slot {
  margin-bottom: 0;
}
</style>
