<template>
  <v-card class="card">
    <v-card-title class="card--section-padding">
      <v-layout column>
        <v-layout row wrap>
          <v-layout align-center :class="{ hand: routeName }" class="card--header-zone__left" @click="navigateBack()">
            <v-icon v-show="icon" size="24px" color="primary">{{ icon }}</v-icon>
            <h3 class="preserve-white-space card--card-title">{{ title }}</h3>
          </v-layout>
          <v-spacer></v-spacer>
          <v-flex d-inline-flex class="no-flex-grow card--header-zone__right"> <slot name="headerZoneRight"></slot> </v-flex>
        </v-layout>
        <v-divider v-if="headerDivider" class="card--divider"></v-divider>
      </v-layout>
    </v-card-title>
    <v-card-text v-if="headerDivider" class="card--section-padding"> <slot name="content"></slot> </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'card',
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
    },
    routeName: {
      type: String,
    },
    headerDivider: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    navigateBack() {
      if (this.routeName) {
        this.$router.push({ name: this.routeName });
      }
    },
  },
};
</script>

<style>
.hand {
  cursor: pointer;
}
</style>
