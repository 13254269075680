<template>
  <card-modal
    icon=""
    :title="$t('purse.load_open_purse_from_interac_action.title')"
    context="LoadOpenPurseFromInterac"
    @submit="validate"
    @cancel="cancel"
    cancel_text="purse.load_open_purse_from_interac_action.button_cancel"
    submit_text="purse.load_open_purse_from_interac_action.button_save"
    :submit_working="working"
    data-test="load-purse-from-interac-modal"
  >
    <form @submit.prevent="validate" slot="content">
      <modal-confirm
        :show-modal="showConfirmModal"
        context="LoadOpenPurseFromInterac"
        :title="$t('purse.load_open_purse_from_interac_action.confirm.title')"
        :text="$t('purse.load_open_purse_from_interac_action.confirm.text', { amount: this.amount })"
        cancel_text="purse.load_open_purse_from_interac_action.confirm.button_cancel"
        submit_text="purse.load_open_purse_from_interac_action.confirm.button_submit"
        :submit_working="working"
        @submit="submit"
        @close="closeConfirmModal"
      ></modal-confirm>

      <v-layout column>
        <v-layout wrap row class="pad-form-row-elements">
          <v-flex xs12 sm4 md4>
            <v-text-field
              data-test="load-purse-from-interac-amount-input"
              v-model="amount"
              name="amount"
              type="number"
              :label="$t('purse.load_open_purse_from_interac_action.fields.amount') + ' *'"
              v-validate="{ required: true, decimal: 2, min_value: 0.01, max_value: 2500 }"
              :error-messages="errors.collect('amount')"
              data-vv-name="amount"
              :data-vv-as="$t('purse.load_open_purse_from_interac_action.fields.amount')"
              :suffix="currency"
              required
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-layout wrap row class="pad-form-row-elements">
          <v-flex xs12 sm6 md5 class="load-purse-from-interac-e-transfer-id-wrap">
            <v-text-field
              data-test="load-purse-from-interac-reference-id-input"
              v-validate="'required|max:50'"
              data-vv-name="reference_id"
              :error-messages="errors.collect('reference_id')"
              :data-vv-as="$t('purse.load_open_purse_from_interac_action.fields.etransfer.reference_id')"
              :label="$t('purse.load_open_purse_from_interac_action.fields.etransfer.reference_id') + ' *'"
              maxlength="50"
              v-model="reference_id"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md4>
            <v-menu
              v-model="showDatePickerCalendar"
              :close-on-content-click="false"
              :nudge-right="40"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  data-test="load-purse-from-interac-reference-date-input"
                  v-validate="'required'"
                  data-vv-name="reference_date"
                  :error-messages="errors.collect('reference_date')"
                  :data-vv-as="$t('purse.load_open_purse_from_interac_action.fields.etransfer.reference_date')"
                  v-model="reference_date"
                  :label="$t('purse.load_open_purse_from_interac_action.fields.etransfer.reference_date') + ' *'"
                  prepend-icon="event"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="reference_date"
                @input="showDatePickerCalendar = false"
                :show-current="new Date().toISOString().substr(0, 10)"
              ></v-date-picker>
            </v-menu>
          </v-flex>
        </v-layout>
        <v-layout wrap row class="pad-form-row-elements">
          <v-textarea
            data-test="load-purse-from-interac-optional-note-input"
            v-validate="'max:140'"
            data-vv-name="note"
            :error-messages="errors.collect('note')"
            :data-vv-as="$t('purse.load_open_purse_from_interac_action.fields.credit.note')"
            v-model="note"
            rows="1"
            auto-grow
            :label="$t('purse.load_open_purse_from_interac_action.fields.etransfer.note')"
            counter="140"
            maxlength="140"
          ></v-textarea>
        </v-layout>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable } from '@/mixins';

export default {
  name: 'load-open-purse-from-interac-action',
  mixins: [actionErrorTrackable],

  $_veeValidate: {
    validator: 'new',
  },

  props: {
    account_id: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      showConfirmModal: false,
      reference_id: undefined,
      note: undefined,
      amount: 0,
      showDatePickerCalendar: false,
      reference_date: undefined,
    };
  },

  computed: {
    ...mapGetters('program', ['currency']),
  },

  methods: {
    async openConfirmModal() {
      this.showConfirmModal = true;
    },
    closeConfirmModal() {
      this.showConfirmModal = false;
    },
    inModalClear() {
      this.showConfirmModal = false;
      this.amount = 0;
      this.reference_id = undefined;
      this.note = undefined;
      this.showDatePickerCalendar = false;
      this.reference_date = undefined;
      this.$validator.reset();
      this.clearError('LoadOpenPurseFromInterac');
    },
    clear() {
      this.inModalClear();
    },

    success() {
      this.clear();
      this.closeConfirmModal();
      this.$emit('action');
      this.$emit('close');
    },

    cancel() {
      this.clear();
      this.$emit('close');
    },

    async validate() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        if (this.amount >= 500) {
          await this.openConfirmModal();
        } else {
          await this.submit();
        }
      }
    },

    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        const params = {
          account_id: this.account_id,
          amount: this.amount,
          currency: this.currency,
          reference_id: this.reference_id,
          reference_date: this.reference_date,
          note: this.note,
        };

        await this.executeAction(
          {
            action: 'purse/loadOpenInterac',
            name: 'LoadOpenPurseFromInterac',
            success: this.success,
          },
          params,
        );
      }
    },
  },
};
</script>
