<template>
  <view-main title="page_title.wallet_definition">
    <two-levels-breadcrumbs
      slot="content"
      :top-label="$t('navigation.wallet_definition')"
      :to="{ name: 'wallet_definition' }"
      :last-label="walletDefinition.name || walletDefinition.id"
    />
    <wallet-definition-information slot="content" :wallet-definition-id="walletDefinitionId"></wallet-definition-information>
    <wallet-definition-rules slot="content" :wallet-definition-id="walletDefinitionId"></wallet-definition-rules>
    <wallet-definition-logs slot="content" :wallet-definition-id="walletDefinitionId"></wallet-definition-logs>
  </view-main>
</template>

<script>
import { actionErrorTrackable, renderErrorTrackable } from '@/mixins';
import WalletDefinitionInformation from './detail/WalletDefinitionInformation.vue';
import WalletDefinitionRules from './detail/WalletDefinitionRules.vue';
import WalletDefinitionLogs from './detail/WalletDefinitionLogs.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'wallet-definition-detail-view',
  mixins: [renderErrorTrackable, actionErrorTrackable],
  components: { WalletDefinitionInformation, WalletDefinitionRules, WalletDefinitionLogs },
  props: {
    walletDefinitionId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('walletDefinition', ['walletDefinition']),
  },
  async mounted() {
    await this.fetch();
  },
  watch: {
    async walletDefinitionId() {
      return this.fetch();
    },
  },
  methods: {
    async fetch() {
      const wallet_definition = await this.executeAction({ action: 'walletDefinition/getWalletDefinition' }, this.walletDefinitionId);
      if (!wallet_definition) {
        this.$router.replace({ name: 'notFound' });
      }
    },
  },
};
</script>
