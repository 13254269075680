<template>
  <card icon="" :title="`${$t('card.comments_section.title')}`" class="hc-comments-section">
    <template slot="headerZoneRight">
      <label-text :label="`${$t('card.comments_section.count')}`" :value="`${comments.length}`"></label-text>

      <div v-if="showAddCommentFeature()" class="card-header-divider--vertical hidden-xs-only">
        <v-divider vertical></v-divider>
      </div>

      <label-text v-if="showAddCommentFeature()" :label="`${$t('card.comments_section.action')}`" value="3" id="add-comment">
        <link-secondary-action slot="labelTextLink" @click="showModalComment">{{ $t('card.comments_section.add') }}</link-secondary-action>
      </label-text>
    </template>

    <template slot="content">
      <modal-action v-if="showAddCommentFeature()" :show-modal="showCommentModal">
        <create-card-comment-action :public_token="public_token" :account_id="account_id" @close="closeModalComment" @action="reloadPage" />
      </modal-action>

      <delete-with-reason-modal
        v-if="showAddCommentFeature()"
        :show-modal="showDeleteCommentModal"
        object-type="Comment"
        @confirm="onDeleteComment"
        @close="hideDeleteCommentModal"
      />

      <label-none v-if="comments.length === 0">{{ $t('card.comments_section.no_found') }}</label-none>

      <template v-for="(item, index) in comments">
        <card-comment :comment="item" :has-divider="isLastComment(index)" :key="index" />
      </template>
    </template>
  </card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import CardComment from './CardComment';
import CreateCardCommentAction from '../actions/CreateCardCommentAction';
import DeleteWithReasonModal from '@/components/system/Modals/DeleteWithReasonModal';

export default {
  name: 'card-comments-list',

  mixins: [actionErrorTrackable, disableWithError, security],

  components: { CreateCardCommentAction, CardComment, DeleteWithReasonModal },

  props: {
    public_token: {
      type: String,
      required: false,
    },
    account_id: {
      type: String,
      required: false,
    },
  },

  data() {
    this.fetch();

    return {
      showCommentModal: false,
    };
  },

  computed: {
    ...mapGetters('card', ['comments', 'currentComment', 'showDeleteCommentModal']),
  },

  watch: {
    public_token() {
      this.fetch();
    },
  },

  methods: {
    ...mapActions('card', ['getComments', 'deleteComment', 'hideDeleteCommentModal']),

    async fetch() {
      if (this.public_token) {
        await this.getComments(this.public_token);
      }
    },

    isLastComment(index) {
      return index + 1 < this.comments.length;
    },

    async onDeleteComment({ reason }) {
      const params = {
        public_token: this.public_token,
        id: this.currentComment.id,
        reason,
      };

      await this.deleteComment(params);
    },

    showModalComment() {
      this.showCommentModal = true;
    },

    closeModalComment() {
      this.showCommentModal = false;
    },

    reloadPage() {
      this.showCommentModal = false;
    },

    showAddCommentFeature() {
      return this.canCommentCard();
    },
  },
};
</script>
