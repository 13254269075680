<template>
  <card :title="$t('voucher.operations.title')" icon="">
    <template slot="content">
      <voucher-operations-table v-if="voucherOperations.length" :items="voucherOperations"></voucher-operations-table>
      <label-none v-if="voucherOperations.length === 0">{{ $t('system.na') }}</label-none>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';
import VoucherOperationsTable from './VoucherOperationsTable.vue';

export default {
  name: 'voucher-operations',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { VoucherOperationsTable },
  computed: {
    ...mapGetters('voucher', ['voucherOperations']),
    ...mapGetters('ui', ['currentLocale']),
  },
};
</script>
