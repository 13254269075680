<template>
  <card-modal
    icon=""
    :title="$t('publishers.details.stores_section.add_publisher_store_action.title')"
    context="addStoreToPublisher"
    @submit="submit"
    @cancel="cancel"
    cancel_text="publishers.details.stores_section.add_publisher_store_action.button_cancel"
    submit_text="publishers.details.stores_section.add_publisher_store_action.button_submit"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <modal-info-block
          :heading="$t('publishers.details.stores_section.add_publisher_store_action.publisher')"
          :text="publisherInfo"
        ></modal-info-block>
        <h-autocomplete
          v-model="storeToAdd"
          :label="$t('publishers.details.stores_section.add_publisher_store_action.store') + '*'"
          v-validate="'required'"
          :error-messages="errors.collect('store')"
          name="store"
          data-vv-name="store"
          data-vv-as="store"
          search-action="store/autocompleteSearch"
          data-test="add-publisher-store"
          no-filter
        >
        </h-autocomplete>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import Vue from 'vue';

export default {
  name: 'add-publishers-store-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    publisherObj: {
      type: Object,
    },
  },
  data() {
    return {
      storeToAdd: null,
    };
  },
  computed: {
    ...mapGetters('publishers', ['publisherStores']),
    addedStoreIds() {
      return _.map(this.publisherStores, (store) => {
        return store.id;
      });
    },
    publisherInfo() {
      return `${this.publisherObj.name} (${this.publisherObj.id})`;
    },
  },
  methods: {
    clear() {
      this.storeToAdd = null;
      this.$validator.reset();
    },
    async success() {
      this.clear();
      this.$emit('created');
      await this.executeAction(
        {
          action: 'publishers/getPublisher',
        },
        this.publisherObj.id,
      );
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        if (this.storeToAdd) {
          const payload = { publisher_id: this.publisherObj.id, store_id: this.storeToAdd };

          await this.executeAction(
            {
              action: 'publishers/addStoreToPublisher',
              name: 'addStoreToPublisher',
              success: this.success,
            },
            payload,
          );
        }
      }
    },
  },
};
</script>
