import Vue from 'vue';

const service = {
  async getDestination(id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/destinations/${id}`);
    return response.data.data;
  },
  async updateDestination(id, payload) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/destinations/${id}`, payload);
    return response.data.data;
  },
  async createDestination(payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/destinations`, payload);
    return response.data.data;
  },
  async updateDestinationCoordinates(id, payload) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/destinations/${id}/coordinates`, payload);
    return response.data.data;
  },
  async regenerateDestinationCoordinates(id) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/destinations/${id}/regenerate-coordinates`);
    return response.data.data;
  },
  async listDestinations(keyword, page, limit) {
    const params = {};
    if (keyword) {
      params.keyword = keyword;
    }
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath('/publisher/v2/destinations', page, limit, params)}`,
    );
    return response.data.data;
  },
  async searchDestinations(page, limit, keyword) {
    const params = {};
    if (keyword) {
      params.keyword = keyword;
    }
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath('/publisher/v2/destinations/search', page, limit, params)}`,
    );
    return response.data.data;
  },
  async listDestinationStores(id, page, limit) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v2/destinations/${id}/stores`, page, limit)}`,
    );
    return response.data.data;
  },
  async getDestinationStatistics(id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/destinations/${id}/statistics`);
    return response.data.data;
  },
};

function generatePath(startPath, page, limit, { keyword, partner_id, business_id, types } = {}) {
  let path = startPath;
  if (page || limit || keyword || partner_id || business_id || types) {
    path += '?';
  }

  const queryStrings = [];
  if (page) {
    queryStrings.push(['page', page]);
  }
  if (limit) {
    queryStrings.push(['limit', limit]);
  }
  if (keyword) {
    queryStrings.push(['keyword', keyword]);
  }
  if (partner_id) {
    queryStrings.push(['partner_id', partner_id]);
  }
  if (business_id) {
    queryStrings.push(['business_id', business_id]);
  }
  if (types) {
    queryStrings.push(['types', types]);
  }

  const params = new URLSearchParams(queryStrings);
  return path + params.toString();
}

export default service;
