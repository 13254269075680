<template>
  <card-modal
    icon="fal fa-clipboard-check"
    :title="$t(`task.modal.resolve_aml_assessment_check_hit.${hitType}_title`)"
    context="resolveAmlStatusPossiblePep"
    @submit="submit"
    @cancel="cancel"
    cancel_text="task.modal.resolve_aml_assessment_check_hit.button_cancel"
    submit_text="task.modal.resolve_aml_assessment_check_hit.button_resolve"
    id="resolve-modal-action"
    data-test="aml-assessment-check-resolution-modal"
  >
    <template slot="content">
      <user-resolve-modal-part v-if="currentTask.account_id" :account-id="currentTask.account_id"></user-resolve-modal-part>

      <card-resolve-modal-part
        v-if="currentTask.relations && currentTask.relations.public_token && !currentTask.relations.card_application_id"
        :public-token="currentTask.relations.public_token"
      ></card-resolve-modal-part>

      <aml-profile-resolve-modal-part
        v-if="currentTask.relations && currentTask.relations.public_token"
        :public-token="currentTask.relations.public_token"
        data-test="aml-profile"
      ></aml-profile-resolve-modal-part>

      <aml-assessment-check-hits-resolve-modal-part
        v-if="currentTask.relations && currentTask.relations.aml_assessment_check_key"
        :aml-assessment-check-key="currentTask.relations.aml_assessment_check_key"
        :hit-type="hitType"
        :public-token="_get(currentTask, 'relations.public_token')"
        class="aml-assessment-check-hits-resolve-modal-part"
        data-test="pep"
      ></aml-assessment-check-hits-resolve-modal-part>

      <h3>{{ $t(`task.modal.resolve_aml_assessment_check_hit.resolve_title`) }}</h3>

      <v-layout wrap align-center class="pad-form-row-elements" data-test="aml-assessment-resolution-actions">
        <v-flex xs12 sm6>
          <v-select
            name="action"
            v-model="action"
            v-validate="'required'"
            :error-messages="errors.collect('action')"
            data-vv-name="action"
            :data-vv-as="$t('task.modal.resolve_aml_assessment_check_hit.form.action')"
            :items="resolutionActionOptions"
            :label="$t('task.modal.resolve_aml_assessment_check_hit.form.action')"
            class="task-resolution-action"
          >
            <template slot="item" slot-scope="data">
              <span :data-test="data.item.value">{{ data.item.text }}</span>
            </template>
          </v-select>
        </v-flex>

        <v-flex xs12 sm6 v-if="resolutionReasonVisible">
          <v-select
            name="reason"
            v-model="reason"
            data-vv-name="reason"
            :data-vv-as="$t('task.modal.resolve_aml_assessment_check_hit.form.reason')"
            :items="resolutionReasonOptions"
            :label="$t('task.modal.resolve_aml_assessment_check_hit.form.reason')"
            class="task-resolution-reason"
          >
            <template slot="item" slot-scope="data">
              <span :data-test="data.item.value">{{ data.item.text }}</span>
            </template>
          </v-select>
        </v-flex>
      </v-layout>

      <resolve-with-aml-note-partial-modal-part
        :task-id="taskId"
        ref="noteResolutionPart"
        default-category-id="aml-note"
        default-type-id="sanctions-and-peps"
      />
    </template>
  </card-modal>
</template>

<script>
import _get from 'lodash/get';
import { actionErrorTrackable, security } from '@/mixins';
import AmlProfileResolveModalPart from './parts/AmlProfileResolveModalPart';
import AmlAssessmentCheckHitsResolveModalPart from './parts/AmlAssessmentCheckHitsResolveModalPart';
import CardResolveModalPart from './parts/CardResolveModalPart';
import ResolveWithAmlNotePartialModalPart from './parts/ResolveWithAmlNotePartialModalPart';
import UserResolveModalPart from './parts/UserResolveModalPart';

export default {
  name: 'resolve-aml-assessment-check-hit-action',
  components: {
    AmlProfileResolveModalPart,
    AmlAssessmentCheckHitsResolveModalPart,
    UserResolveModalPart,
    CardResolveModalPart,
    ResolveWithAmlNotePartialModalPart,
  },
  $_veeValidate: {
    validator: 'new',
  },
  mixins: [actionErrorTrackable, security],
  props: {
    taskId: {
      type: String,
      required: true,
    },
  },
  computed: {
    resolutionReasonVisible() {
      return this.action === 'negative';
    },
    hitType() {
      return this.currentTask && this.currentTask.type === 'aml_status_possible_pep' ? 'pep' : 'sanction';
    },
  },
  watch: {
    action(val) {
      if (val !== 'negative') {
        this.reason = null;
      }
    },
  },
  data() {
    const $t = this.$t.bind(this);
    return {
      action: null,
      reason: null,
      currentTask: {},
      resolutionActionOptions: [
        {
          text: $t('task.modal.resolve_aml_assessment_check_hit.action.none'),
          value: 'none',
        },
        {
          text: $t('task.modal.resolve_aml_assessment_check_hit.action.positive'),
          value: 'positive',
        },
        {
          text: $t('task.modal.resolve_aml_assessment_check_hit.action.negative'),
          value: 'negative',
        },
      ],
      resolutionReasonOptions: [
        {
          text: $t('task.modal.resolve_aml_assessment_check_hit.reason.false_positive'),
          value: 'false_positive',
        },
        {
          text: $t('task.modal.resolve_aml_assessment_check_hit.reason.other'),
          value: 'other',
        },
      ],
    };
  },
  async mounted() {
    this.currentTask = await this.executeAction({ action: 'task/getTask' }, this.taskId);
  },
  methods: {
    _get,
    clear() {
      this.$validator.reset();
    },
    cancel() {
      this.$emit('closed');
      this.clear();
    },
    success() {
      this.clear();
      this.$emit('task_resolved');
      this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'task.modal.resolve_aml_note_to_review.success' });
    },
    amlNoteSaved() {
      this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'task.modal.resolve_aml_note_to_review.aml_note_saved' });
    },
    async submit() {
      const resolveWithNotePart = this.$refs.noteResolutionPart;

      const validationComplete = await this.$validator.validateAll();
      const resolveWithNoteValidation = await resolveWithNotePart.$validator.validateAll();

      if (validationComplete && resolveWithNoteValidation) {
        const actionParams = {
          task_key: this.currentTask.key,
          action: this.action,
        };

        if (this.reason) {
          actionParams.params = {
            reason: this.reason,
          };
        }

        await this.executeAction(
          {
            action: 'task/resolveTask',
            name: 'resolveAmlAssessmentCheckHit',
            success: this.success,
          },
          actionParams,
        );

        await resolveWithNotePart.submit();
      }
    },
  },
};
</script>
