import Vue from 'vue';

const service = {
  async getBusiness(id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/businesses/${id}`);
    return response.data.data;
  },
  async createBusiness(payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/businesses`, payload);
    return response.data.data;
  },
  async searchBusinesses(page, limit, keyword) {
    const params = {};
    if (keyword) {
      params.keyword = keyword;
    }
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath('/publisher/v2/businesses/search', { page, limit, keyword })}`,
    );
    return response.data.data;
  },
  async searchBusinessWithWebhooks(page, limit, keyword) {
    const params = {};
    if (keyword) {
      params.keyword = keyword;
    }
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath('/publisher/v2/businesses/search-with-webhooks', { page, limit, keyword })}`,
    );
    return response.data.data;
  },
  async listBusinesses(page, limit, keyword) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath('/publisher/v2/businesses', { page, limit, keyword })}`,
    );
    return response.data.data;
  },
  async createBusinessNote(business_id, payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/businesses/${business_id}/notes`, payload);
    return response.data.data;
  },
  async deleteBusinessNote(business_id, note_id, payload) {
    const response = await Vue.prototype.$axios.put(
      `${process.env.VUE_APP_BASE_API_URL}/publisher/v2/businesses/${business_id}/notes/${note_id}/delete`,
      payload,
    );
    return response.data.data;
  },
  async listBusinessNotes(page, limit, business_id) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v2/businesses/${business_id}/notes`, page, limit)}`,
    );
    return response.data.data;
  },
  async listBusinessStores(id, sort_order, page, limit) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v2/businesses/${id}/stores`, { page, limit, sort_order })}`,
    );
    return response.data.data;
  },
  async listBusinessPublishers(id, page, limit) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v2/businesses/${id}/publishers`, { page, limit })}`,
    );
    return response.data.data;
  },
  async listBusinessOwnerOffers(id, sort_order, page, limit) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v2/businesses/${id}/owner-offers`, { page, limit, sort_order })}`,
    );
    return response.data.data;
  },
  // NOTE (SC): This remains for the business contributor offers (vs listBusinessOwnerOffers)
  async listBusinessOffers(id, sort_order, page, limit) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v2/businesses/${id}/contributing-offers`, { page, limit, sort_order })}`,
    );
    return response.data.data;
  },
  async listBusinessActivationCodeBatches(id, page, limit) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v2/businesses/${id}/activation-code-batches`, { page, limit })}`,
    );
    return response.data.data;
  },
  async listBusinessWebhooks(id, sort_order, page, limit) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v2/businesses/${id}/webhooks`, { page, limit, sort_order })}`,
    );
    return response.data.data;
  },
  async activateBusinessWebhook(webhookId, businessId) {
    const response = await Vue.prototype.$axios.put(
      `${process.env.VUE_APP_BASE_API_URL}/publisher/v2/businesses/${businessId}/webhooks/${webhookId}/active`,
    );

    return response;
  },
  async disableBusinessWebhook(webhookId, businessId) {
    const response = await Vue.prototype.$axios.put(
      `${process.env.VUE_APP_BASE_API_URL}/publisher/v2/businesses/${businessId}/webhooks/${webhookId}/disabled`,
    );

    return response;
  },
  async removeBusinessWebhook(webhookId, businessId) {
    const response = await Vue.prototype.$axios.delete(
      `${process.env.VUE_APP_BASE_API_URL}/publisher/v2/businesses/${businessId}/webhooks/${webhookId}`,
    );

    return response;
  },
  async addBusinessWebhook(businessId, payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/businesses/${businessId}/webhooks`, payload);

    return response;
  },
  async updateBusinessWebhook(webhookId, businessId, payload) {
    const response = await Vue.prototype.$axios.put(
      `${process.env.VUE_APP_BASE_API_URL}/publisher/v2/businesses/${businessId}/webhooks/${webhookId}`,
      payload,
    );

    return response;
  },
  async updateBusiness(id, params) {
    const payload = params;
    delete payload.system_status;

    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/businesses/${id}`, payload);
    return response.data.data;
  },
  async linkBankAccountToBusiness(id, payload) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/businesses/${id}/bank-account`, payload);
    return response.data.data;
  },
  async unlinkBankAccountFromBusiness(id) {
    const response = await Vue.prototype.$axios.delete(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/businesses/${id}/bank-account`);
    return response.data.data;
  },
  async addBusinessEmailAccess({ business_id, email }) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/businesses/${business_id}/email-access`, {
      email,
    });
    return response.data;
  },
  async removeBusinessEmailAccess(id) {
    const response = await Vue.prototype.$axios.delete(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/businesses/email-access/${id}`);
    return response.data.data;
  },
  async listBusinessEmailAccess(business_id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/businesses/${business_id}/email-access`);
    return response.data.data.items;
  },
  async listBusinessAccessesByEmail(email) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}/publisher/v2/businesses/accesses?email=${encodeURIComponent(email)}`,
    );
    return response.data.data.items;
  },
  async setBusinessAsVerified(business_id) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/businesses/${business_id}/verify-status`);
    return response.data.data.items;
  },
  async setBusinessAsUnverified(business_id) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/businesses/${business_id}/unverify-status`);
    return response.data.data.items;
  },
};

function generatePath(startPath, { page, limit, keyword, sort_order }) {
  let path = startPath;
  if (page || limit || keyword || sort_order) {
    path += '?';
  }

  const queryStrings = [];
  if (page) {
    queryStrings.push(['page', page]);
  }
  if (limit) {
    queryStrings.push(['limit', limit]);
  }
  if (keyword) {
    queryStrings.push(['keyword', keyword]);
  }
  if (sort_order) {
    queryStrings.push(['sort_order', sort_order]);
  }

  const params = new URLSearchParams(queryStrings);
  return path + params.toString();
}

export default service;
