<template>
  <view-main title="page_title.franchise_details">
    <two-levels-breadcrumbs slot="content" :top-label="$t('navigation.franchises')" :to="{ name: 'franchises' }" :last-label="franchise.name" />
    <franchise-information slot="content" />
    <franchise-stores slot="content" />
  </view-main>
</template>

<script>
import { actionErrorTrackable, renderErrorTrackable } from '@/mixins';
import FranchiseInformation from './detail/FranchiseInformation.vue';
import FranchiseStores from './detail/FranchiseStores.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'franchise-details-view',
  mixins: [renderErrorTrackable, actionErrorTrackable],
  components: { FranchiseInformation, FranchiseStores },
  props: {
    franchiseId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('franchise', ['franchise']),
  },
  async mounted() {
    await this.fetch();
  },
  watch: {
    async franchiseId() {
      return this.fetch();
    },
  },
  methods: {
    async fetch() {
      const franchise = await this.executeAction({ action: 'franchise/getFranchise' }, this.franchiseId);
      if (!franchise) {
        this.$router.replace({ name: 'notFound' });
      }
    },
  },
};
</script>
