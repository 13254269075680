<template>
  <div v-if="isReady">
    <h3>{{ $t(`task.list.change_application_request.title`) }}</h3>

    <changes-viewer
      i18n-prefix="task.list.change_application_request.changes"
      :changed-fields="changeApplicationRequestFields"
      :original-values="cardApplication(cardApplicationId).card_applicant"
      :changes="changeApplicationRequest.changes"
    />
  </div>
</template>

<script>
import _get from 'lodash/get';
import { mapGetters } from 'vuex';
import { actionErrorTrackable, security } from '@/mixins';

export default {
  name: 'selfserve-change-application-review-modal-part',

  mixins: [actionErrorTrackable, security],

  props: {
    changeRequestId: {
      type: String,
      required: true,
    },
    cardApplicationId: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapGetters('task', ['cardApplication']),
    ...mapGetters('selfserve', ['changeApplicationRequest', 'changeApplicationRequestFields']),

    isReady() {
      return _get(this.changeApplicationRequest, 'id') && this.cardApplication(this.cardApplicationId);
    },
  },

  async mounted() {
    await this.executeAction({ action: 'selfserve/getChangeApplicationRequest' }, this.changeRequestId);
    await this.executeAction({ action: 'task/getCardApplication' }, this.cardApplicationId);
  },
};
</script>
