<template>
  <view-main title="page_title.business_suite_members">
    <v-flex slot="content" class="data-section">
      <h1 hidden>{{ $t('dashboards.business_suite_members.title') }}</h1>
      <v-flex fill-height class="data-section">
        <dashboard id-name="business_suite_members" :params="params" :timerange="timerange" />
      </v-flex>
    </v-flex>
  </view-main>
</template>

<script>
import { renderErrorTrackable } from '@/mixins';
import Dashboard from './Dashboard.vue';

export default {
  name: 'business-suite-members-view',
  mixins: [renderErrorTrackable],
  components: { Dashboard },
  data() {
    return {
      timerange: null,
      partner_id: null,
      offer: null,
    };
  },
  created() {
    if (this.$route.query.timerange) {
      this.timerange = this.$route.query.timerange;
    }
    if (this.$route.query.partner_id) {
      this.partner_id = this.$route.query.partner_id;
    }
    if (this.$route.query.offers) {
      this.offer = this.$route.query.offers;
    }
  },
  computed: {
    params() {
      const theParams = {};

      if (this.partner_id) {
        theParams.partner_id = this.partner_id;
      }
      if (this.offer) {
        theParams.offer = this.offer;
      }

      return theParams;
    },
  },
};
</script>
