<template>
  <v-input v-bind="$attrs" v-on="listeners">
    <div style="margin-left: 10px">
      <link-secondary-action>
        <label :for="`space-${imageRole}-file-input`" id="space-images-file-input-label">
          {{ $t(`space.form.upload.${imageRole}`) }}
        </label>
      </link-secondary-action>
      <p v-if="imageNameToUpload">{{ imageNameToUpload }}</p>
      <input type="file" accept="image/*" :id="`space-${imageRole}-file-input`" @change="addImages" @click="resetInputValue" />
    </div>
  </v-input>
</template>

<script>
import { security, disableWithError, actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'space-image',
  mixins: [security, actionErrorTrackable, disableWithError],
  props: {
    imageRole: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      imageToUpload: null,
      imageNameToUpload: '',
    };
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    listeners() {
      const { input, ...listeners } = this.$listeners;
      return listeners;
    },
  },
  methods: {
    async addImages(e) {
      const files = [...e.target.files];

      if (files.length) {
        this.imageToUpload = files[0];
        this.imageNameToUpload = files[0].name;
        this.$emit('input', this.imageToUpload);
      }
    },
    resetInputValue(e) {
      e.target.value = null;
    },
  },
};
</script>

<style scoped lang="stylus">
.branding-image-container img {
  width: 200px;
}

#space-logo_horizontal-file-input,
#space-logo_square-file-input {
  display: none;

  & + label {
    display: block;
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
  }
}

#branding-images-file-input-label {
  cursor: pointer;
}
</style>
