<template>
  <card-modal
    icon=""
    :title="$t('card.get_pin_action.title')"
    context="cardPin"
    @cancel="cancel"
    cancel_text="card.get_activation_code.button_cancel"
  >
    <div slot="content">
      <label-text :label="$t('card.get_pin_action.label')" :value="cardPin" />
    </div>
  </card-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable } from '@/mixins';

export default {
  name: 'card-pin',
  mixins: [actionErrorTrackable],
  props: {
    publicToken: {
      type: String,
      required: true,
    },
    helloShow: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters('card', ['cardPin']),
  },
  watch: {
    async helloShow(show) {
      if (show) {
        await this.getPin();
      }
    },
  },
  methods: {
    cancel() {
      this.$emit('close');
    },
    async getPin() {
      await this.executeAction(
        {
          action: 'card/getCardPin',
        },
        this.publicToken,
      );
    },
  },
};
</script>
