<template>
  <card :title="$t('instant_card_batches.details.generated_cards.title')" icon="">
    <template slot="headerZoneRight">
      <label-text :label="`${$t('instant_card_batches.details.delivery_section.gps_job_number')}`" :value="batch.gps_job_number"></label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>

      <label-text :label="`${$t('instant_card_batches.details.delivery_section.gps_filename')}`" :value="batch.gps_filename"></label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>

      <label-text
        class="text-sm-center"
        :label="$t('instant_card_batches.details.generated_cards.count')"
        :value="batch.generated_card_count || '0'"
      ></label-text>

      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text
        v-if="batch.generated_date"
        :label="`${$t('instant_card_batches.details.delivery_section.generated_date')}`"
        :value="batch.generated_date | formatShortDateTimeInverted"
      ></label-text>
      <label-text v-else :label="`${$t('instant_card_batches.details.delivery_section.generated_date')}`">{{ $t('system.na') }}</label-text>

      <div v-if="showActionBtn()" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>

      <link-secondary-action v-if="showActionBtn()" slot="labelTextLink">
        <button-secondary-actions :text="$t(`instant_card_batches.details.generated_cards.actions`)" :actions="actions"> </button-secondary-actions>
      </link-secondary-action>
    </template>

    <template slot="content">
      <form v-if="batch.generated_card_count > 0" @submit.prevent="submit" class="form-basic form-search">
        <v-layout align-center class="form-search--main-wrap-sub-module">
          <v-flex class="form-search--button-wrap-sub-module">
            <button-primary
              class="instant-card-batches-generated-cards--btn-search"
              :text="$t('instant_card_batches.details.generated_cards.form.submit')"
              :loading="working"
              type="submit"
            ></button-primary>
          </v-flex>
          <v-flex class="form-search--inputs-zone-sub-module">
            <form-warning-search
              :autofocus="true"
              :searched="searched"
              :hint-message="$t('instant_card_batches.details.generated_cards.form.total_results_message')"
              class="generated-cards--search-field"
              :label="$t('instant_card_batches.details.generated_cards.placeholder')"
              v-model="keyword"
              data-vv-name="search"
              v-validate="'max:50'"
              :error-messages="errors.collect('search')"
              data-test="generated-cards--keyword"
            ></form-warning-search>
            <h-autocomplete
              v-model="program_id"
              no-filter
              clearable
              :label="$t('instant_card_batches.details.generated_cards.program')"
              :error-messages="errors.collect('program_id')"
              name="program_id"
              data-vv-name="program_id"
              data-vv-as="program_id"
              search-action="partner/autocompleteSearch"
              data-test="generated-cards--program"
            >
            </h-autocomplete>
            <v-select
              name="status"
              v-model="status"
              :error-messages="errors.collect('status')"
              data-vv-name="status"
              :data-vv-as="$t('instant_card_batches.details.generated_cards.status')"
              :items="statusOptions"
              item-text="name"
              item-value="value"
              clearable
              :label="$t('instant_card_batches.details.generated_cards.status')"
              data-test="generated-cards-status"
            ></v-select>
          </v-flex>
        </v-layout>
      </form>

      <label-none v-else>{{ $t('system.none') }}</label-none>

      <modal-action :show-modal="showLinkBatchCardsModal">
        <link-batch-cards-action
          v-if="showLinkBatchCardsModal"
          :batch-obj="batch"
          :batch-cards-item-count="batch.generated_card_count"
          @close="closeLinkBatchCardsModal"
          @linked="linkBatchCardsSuccess"
        />
      </modal-action>
      <modal-action :show-modal="showUnlinkBatchCardsModal">
        <unlink-batch-cards-action
          v-if="showUnlinkBatchCardsModal"
          :batch-obj="batch"
          :batch-cards-item-count="batch.generated_card_count"
          @close="closeUnlinkBatchCardsModal"
          @unlinked="unlinkBatchCardsSuccess"
        />
      </modal-action>
      <modal-action :show-modal="showPauseBatchCardsModal">
        <pause-batch-cards-action
          v-if="showPauseBatchCardsModal"
          :batch-obj="batch"
          :batch-cards-item-count="batch.generated_card_count"
          @close="closePauseBatchCardsModal"
          @paused="pauseBatchCardsSuccess"
        />
      </modal-action>
      <modal-action :show-modal="showResumeBatchCardsModal">
        <resume-batch-cards-action
          v-if="showResumeBatchCardsModal"
          :batch-obj="batch"
          :batch-cards-item-count="batch.generated_card_count"
          @close="closeResumeBatchCardsModal"
          @resumed="resumeBatchCardsSuccess"
        />
      </modal-action>
      <batch-generated-cards-table v-if="dataReady && batchCards.length" :items="batchCards" :batch-id="batch.id" />
      <label-none v-if="batchCards.length === 0 && dirty">{{ $t('system.none') }}</label-none>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import Vue from 'vue';
import BatchGeneratedCardsTable from './BatchGeneratedCardsTable';
import LinkBatchCardsAction from '../actions/LinkBatchCardsAction';
import UnlinkBatchCardsAction from '../actions/UnlinkBatchCardsAction';
import PauseBatchCardsAction from '@/components/instant_card_batch/actions/PauseBatchCardsAction';
import ResumeBatchCardsAction from '@/components/instant_card_batch/actions/ResumeBatchCardsAction';

export default {
  name: 'generated-cards',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { BatchGeneratedCardsTable, LinkBatchCardsAction, UnlinkBatchCardsAction, PauseBatchCardsAction, ResumeBatchCardsAction },
  data() {
    return {
      dataReady: false,
      dirty: false,
      searched: '',
      keyword: '',
      program_id: '',
      status: '',
      page: 1,
      statusOptions: [
        { name: this.$t('instant_card_cards.status.pending_activation'), value: 'pending_activation' },
        { name: this.$t('instant_card_cards.status.active'), value: 'active' },
        { name: this.$t('instant_card_cards.status.paused'), value: 'paused' },
        { name: this.$t('instant_card_cards.status.destroyed'), value: 'destroyed' },
      ],
      showLinkBatchCardsModal: false,
      showUnlinkBatchCardsModal: false,
      showPauseBatchCardsModal: false,
      showResumeBatchCardsModal: false,
      actions: [
        {
          slotName: 'LinkBatchCardsModal',
          name: this.$t('instant_card_batches.details.generated_cards.link_cards_action.title'),
          action: () => {
            this.openLinkBatchCardsModal();
          },
          isAvailable: () => this.canManageOffer(), // TODO change
          isDisabled: () => {
            return !this.showLinkBatchCardsFeature();
          },
        },
        {
          slotName: 'UnlinkBatchCardsModal',
          name: this.$t('instant_card_batches.details.generated_cards.unlink_cards_action.title'),
          action: () => {
            this.openUnlinkBatchCardsModal();
          },
          isAvailable: () => this.canManageOffer(), // TODO change
          isDisabled: () => {
            return !this.showUnlinkBatchCardsFeature();
          },
        },
        {
          slotName: 'PauseBatchCardsModal',
          name: this.$t('instant_card_batches.details.generated_cards.pause_cards_action.title'),
          action: () => {
            this.openPauseBatchCardsModal();
          },
          isAvailable: () => this.canManageOffer(), // TODO change
          isDisabled: () => {
            return !this.showPauseBatchCardsFeature();
          },
        },
        {
          slotName: 'ResumeBatchCardsModal',
          name: this.$t('instant_card_batches.details.generated_cards.resume_cards_action.title'),
          action: () => {
            this.openResumeBatchCardsModal();
          },
          isAvailable: () => this.canManageOffer(), // TODO change
          isDisabled: () => {
            return !this.showResumeBatchCardsFeature();
          },
        },
      ],
    };
  },
  async created() {
    this.clearBatchCards();
    this.page = this.listBatchCardsPageNumber;
    this.keyword = this.listBatchCardsKeyword;
    this.program_id = this.listBatchCardsPartnerId;
    this.status = this.listBatchCardsStatus;
  },
  watch: {
    async batch() {
      // TODO: this is commented because if it fires, the computed cardPublicTokens refreshes, and gets filled with the public tokens of all the cards in the batch, unfiltered. Is this desirable?
      // await this.fetchBatchCards();
      this.searched = '';
      this.page = 1;
      this.keyword = '';
      this.status = '';
      this.program_id = '';
      this.dirty = false;
    },
  },
  computed: {
    ...mapGetters('instantCardBatch', [
      'batch',
      'batchCards',
      'listBatchCardsKeyword',
      'listBatchCardsPartnerId',
      'listBatchCardsStatus',
      'listBatchCardsPageCount',
      'listBatchCardsPageNumber',
      'listBatchCardsItemCount',
    ]),
    ...mapGetters('ui', ['currentLocale']),
    cardPublicTokens() {
      return _.map(this.batchCards, (card) => {
        return card.public_token;
      });
    },
  },
  methods: {
    async clear() {
      this.searched = '';
      this.page = 1;
      this.keyword = '';
      this.status = '';
      this.program_id = '';
      this.dirty = false;
      this.$validator.reset();
    },
    async clearBatchCards() {
      return this.executeAction({ action: 'instantCardBatch/clearBatchCards' });
    },
    showActionBtn() {
      return true; // TODO set
    },
    showLinkBatchCardsFeature() {
      return this.batch.status === 'generated' || this.batch.status === 'delivered';
    },
    openLinkBatchCardsModal() {
      this.showLinkBatchCardsModal = true;
    },
    closeLinkBatchCardsModal() {
      this.showLinkBatchCardsModal = false;
    },
    async linkBatchCardsSuccess() {
      await this.fetchBatchCards();
      await this.executeAction(
        { action: 'ui/showSuccessSnackBar' },
        { text: 'instant_card_batches.details.generated_cards.link_cards_action.success' },
      );
      this.closeLinkBatchCardsModal();
    },

    showUnlinkBatchCardsFeature() {
      return this.batch.status === 'generated' || this.batch.status === 'delivered';
    },
    openUnlinkBatchCardsModal() {
      this.showUnlinkBatchCardsModal = true;
    },
    closeUnlinkBatchCardsModal() {
      this.showUnlinkBatchCardsModal = false;
    },
    async unlinkBatchCardsSuccess() {
      await this.fetchBatchCards();
      await this.executeAction(
        { action: 'ui/showSuccessSnackBar' },
        { text: 'instant_card_batches.details.generated_cards.unlink_cards_action.success' },
      );
      this.closeUnlinkBatchCardsModal();
    },

    showPauseBatchCardsFeature() {
      return this.batch.status === 'generated' || this.batch.status === 'delivered';
    },
    openPauseBatchCardsModal() {
      this.showPauseBatchCardsModal = true;
    },
    closePauseBatchCardsModal() {
      this.showPauseBatchCardsModal = false;
    },
    async pauseBatchCardsSuccess() {
      await this.fetchBatchCards();
      await this.executeAction(
        { action: 'ui/showSuccessSnackBar' },
        { text: 'instant_card_batches.details.generated_cards.pause_cards_action.success' },
      );
      this.closePauseBatchCardsModal();
    },

    showResumeBatchCardsFeature() {
      return this.batch.status === 'generated' || this.batch.status === 'delivered';
    },
    openResumeBatchCardsModal() {
      this.showResumeBatchCardsModal = true;
    },
    closeResumeBatchCardsModal() {
      this.showResumeBatchCardsModal = false;
    },
    async resumeBatchCardsSuccess() {
      await this.fetchBatchCards();
      await this.executeAction(
        { action: 'ui/showSuccessSnackBar' },
        { text: 'instant_card_batches.details.generated_cards.resume_cards_action.success' },
      );
      this.closeResumeBatchCardsModal();
    },
    async fetchBatchCards() {
      await this.executeAction(
        { action: 'instantCardBatch/listBatchCards' },
        {
          batch_id: this.batch.id,
          keyword: this.keyword,
          partner_id: this.program_id,
          status: this.status,
          page: this.listBatchCardsPageNumber,
        },
      );
    },
    async submit(event, forceSearch = false, page = 1) {
      let validationComplete;
      if (!forceSearch) {
        validationComplete = await this.$validator.validateAll();
      }

      if (validationComplete || forceSearch) {
        await this.clearAllError();
        this.dataReady = false;
        await this.executeAction(
          { action: 'instantCardBatch/listBatchCards' },
          {
            batch_id: this.batch.id,
            keyword: this.keyword,
            partner_id: this.program_id,
            status: this.status,
            page: 1,
          },
        );
        this.dataReady = true;
        this.dirty = true;
      }
    },
  },
};
</script>
