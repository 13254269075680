const isPepHit = (hit) => {
  if (hit.type) return hit.type.toLowerCase() === 'pep';
  return hit['Record ID'] && hit['Record ID'].slice(0, 3).toLowerCase() === 'pep';
};

export function mapAssessmentCheck(check, commonToAllLogs) {
  const val = {
    key: check.key,
    created: check.created,
    hits: check.hits.map(mapHit),
    has_pep: check.pep_matched === 'possible',
    has_sanctions: check.sanctions_matched === 'possible',
  };

  if (val.has_pep) val.pep_details = check.pep_details;
  if (val.has_sanctions) val.sanctions_details = check.sanctions_details;

  return val;
}

function mapHit(hit) {
  return {
    type: isPepHit(hit) ? 'pep' : 'sanction',
    record_id: hit['Record ID'],
    list_name: hit['List Name'],
    as_names: hit.Hits.map((nameHit) => nameHit.Hit),
    score: hit['Score'],
    hit_location: hit['Hit Location'],
  };
}
