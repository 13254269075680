<template>
  <div v-if="hasAmlProfile">
    <h3>{{ $t(`task.list.aml_profile.title`) }}</h3>
    <v-layout row wrap align-center justify-start class="data-card--content-zone">
      <v-flex xs12 class="d-inline-flex data-card--content-zone__upper">
        <label-text :label="$t(`task.list.aml_profile.aml_flag`)" :value="$t(`code.card.aml_flag.${currentCard.card_aml_flag}`)"></label-text>
        <label-text
          :label="$t(`task.list.aml_profile.peps_status`)"
          :value="currentAmlStatus.pep_matched ? $t(`code.aml_profile.pep_matched.${currentAmlStatus.pep_matched}`) : $t('system.na')"
          :class-array="[`pep-matched-${currentAmlStatus.pep_matched}`, 'aml-profile--pep-matched-field']"
        ></label-text>
        <label-text
          :label="$t(`task.list.aml_profile.sanctions_status`)"
          :value="
            currentAmlStatus.sanctions_matched ? $t(`code.aml_profile.sanctions_matched.${currentAmlStatus.sanctions_matched}`) : $t('system.na')
          "
          :class-array="[`sanctions-matched-${currentAmlStatus.sanctions_matched}`, 'aml-profile--sanctions-matched-field']"
        ></label-text>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { actionErrorTrackable, security } from '@/mixins';

export default {
  name: 'aml-profile-part',
  mixins: [actionErrorTrackable, security],
  props: {
    publicToken: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('task', ['amlStatus', 'card']),
    currentAmlStatus() {
      return this.amlStatus(this.publicToken);
    },
    currentCard() {
      return this.card(this.publicToken);
    },
    hasAmlProfile() {
      return !_.isEmpty(this.currentAmlStatus);
    },
  },
  async mounted() {
    await this.executeAction({ action: 'task/getCard' }, this.publicToken);
    await this.executeAction({ action: 'task/getAmlStatus' }, this.publicToken);
  },
  methods: {
    showModalDialog() {
      this.modalData.content = JSON.stringify(this.currentAssessmentCheck.pep_details, null, 2);
      this.showModal = true;
    },
  },
};
</script>
