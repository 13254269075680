<template>
  <view-main title="page_title.business_details">
    <two-levels-breadcrumbs slot="content" :top-label="$t('navigation.businesses')" :to="{ name: 'businesses' }" :last-label="business.name" />
    <business-information slot="content" :business-id="businessId"></business-information>
    <report-access slot="content" :business-id="businessId"></report-access>
    <business-bank-account slot="content" :business-id="businessId"></business-bank-account>
    <business-eft-items slot="content" :business-id="businessId"></business-eft-items>
    <business-owner-offers slot="content" :business-id="businessId"></business-owner-offers>
    <business-contributor-offers slot="content" :business-id="businessId"></business-contributor-offers>
    <business-publishers slot="content" :business-id="businessId"></business-publishers>
    <business-stores slot="content" :business-id="businessId"></business-stores>
    <business-activation-code-batches :business-id="businessId" slot="content"></business-activation-code-batches>
    <business-webhooks slot="content" :business-id="businessId"></business-webhooks>
    <business-notes slot="content"></business-notes>
  </view-main>
</template>

<script>
import { actionErrorTrackable, renderErrorTrackable } from '@/mixins';
import BusinessInformation from './detail/BusinessInformation.vue';
import ReportAccess from './detail/ReportAccess';
import BusinessBankAccount from './detail/BusinessBankAccount.vue';
import BusinessEftItems from './detail/BusinessEftItems.vue';
import BusinessOwnerOffers from '@/components/business/detail/BusinessOwnerOffers';
import BusinessContributorOffers from '@/components/business/detail/BusinessContributorOffers';
import BusinessPublishers from '@/components/business/detail/BusinessPublishers';
import BusinessStores from '@/components/business/detail/BusinessStores';
import BusinessActivationCodeBatches from '@/components/business/detail/BusinessActivationCodeBatches';
import BusinessWebhooks from '@/components/business/detail/BusinessWebhooks';
import BusinessNotes from '@/components/business/detail/BusinessNotes';

import { mapGetters } from 'vuex';

export default {
  name: 'business-details-view',
  mixins: [renderErrorTrackable, actionErrorTrackable],
  components: {
    BusinessInformation,
    ReportAccess,
    BusinessBankAccount,
    BusinessEftItems,
    BusinessContributorOffers,
    BusinessOwnerOffers,
    BusinessPublishers,
    BusinessStores,
    BusinessActivationCodeBatches,
    BusinessWebhooks,
    BusinessNotes,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('business', ['business']),
  },
  async mounted() {
    await this.fetch();
  },
  watch: {
    async businessId() {
      return this.fetch();
    },
  },
  methods: {
    async fetch() {
      const business = await this.executeAction({ action: 'business/getBusiness' }, this.businessId);
      if (!business) {
        this.$router.replace({ name: 'notFound' });
      }
    },
  },
};
</script>
