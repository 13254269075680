<template>
  <div>
    <layout-notes :items="items" @delete="deleteNote" />
    <div class="text-xs-center">
      <v-pagination v-if="listOfferNotesPageCount > 1" v-model="pagination" :length="listOfferNotesPageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'offer-notes-table',
  mixins: [actionErrorTrackable, security],
  props: {
    items: {
      type: Array,
    },
    offerId: {
      type: String,
    },
  },
  data() {
    return {
      pagination: 1,
    };
  },
  mounted() {
    this.pagination = this.listOfferNotesPageNumber;
  },
  watch: {
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'offers/listOfferNotes',
        },
        {
          offer_id: this.offerId,
          page: newValue,
        },
      );
    },
  },
  computed: {
    ...mapGetters('offers', ['listOfferNotesPageNumber', 'listOfferNotesPageCount', 'listOfferNotesItemCount']),
  },
  methods: {
    deleteNote(noteId) {
      this.$emit('offer_note_delete_action', noteId);
    },
  },
};
</script>
