<template>
  <div>
    <shared-stores-table :items="items" :exclude="['business', 'action']" />
    <div class="text-xs-center">
      <v-pagination v-if="businessStoresPageCount > 1" v-model="pagination" :length="businessStoresPageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import SharedStoresTable from '@/components/shared_tables/SharedStoresTable';
import { mapGetters } from 'vuex';

export default {
  name: 'business-stores-table',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { SharedStoresTable },
  props: {
    id: {
      type: String,
    },
    items: {
      type: Array,
    },
  },
  data() {
    return {
      pagination: 1,
    };
  },
  mounted() {
    this.pagination = this.businessStoresPageNumber;
  },
  watch: {
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'business/listBusinessStores',
        },
        {
          id: this.id,
          page: newValue,
        },
      );
    },
  },
  computed: {
    ...mapGetters('business', ['businessStoresPageNumber', 'businessStoresPageCount']),
  },
};
</script>
