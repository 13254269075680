<template>
  <table class="responsive-table">
    <thead>
      <tr>
        <td>{{ $t('store.branding_table.fields.id') }}</td>
        <td>{{ $t('store.branding_table.fields.name') }}</td>
        <td>{{ $t('store.branding_table.fields.type') }}</td>
        <td>{{ $t('store.branding_table.fields.images.showcase') }}</td>
        <td>{{ $t('store.branding_table.fields.images.horizontal_logo') }}</td>
        <td>{{ $t('store.branding_table.fields.images.big_hero') }}</td>
        <td>{{ $t('store.branding_table.fields.images.small_hero') }}</td>
        <td>{{ $t('store.branding_table.fields.images.background') }}</td>
        <td>{{ $t('store.branding_table.fields.created') }}</td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <router-link :to="{ name: 'branding_details', params: { brandingId: item.id } }">{{ item.id }}</router-link>
        </td>
        <td>{{ item.name }}</td>
        <td>{{ $t(`branding.types.${item.type}`) }}</td>
        <td align="center">
          <span v-if="item.images">{{ item.images.filter((image) => image.role === 'showcase_fr' || image.role === 'showcase_en').length }}</span>
        </td>
        <td align="center">
          <span v-if="item.images">{{ item.images.filter((image) => image.role.startsWith('logo_horizontal')).length }}</span>
        </td>
        <td align="center">
          <span v-if="item.images">{{ item.images.filter((image) => image.role.startsWith('big_hero')).length }}</span>
        </td>
        <td align="center">
          <span v-if="item.images">{{ item.images.filter((image) => image.role.startsWith('small_hero')).length }}</span>
        </td>
        <td align="center">
          <span v-if="item.images">{{ item.images.filter((image) => image.role.startsWith('background')).length }}</span>
        </td>
        <td>{{ item.creation_date | formatShortDateInverted | orNotAvailable }}</td>
        <td>
          <link-secondary-action slot="labelTextLink" @click="openUpdateLinkBrandingModal()">{{
            $t('store.branding_table.update_link')
          }}</link-secondary-action>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'store-branding-table',
  props: {
    item: {
      type: Object,
    },
  },
  methods: {
    openUpdateLinkBrandingModal() {
      this.$emit('store_branding_update_action');
    },
  },
};
</script>
