<template>
  <card :title="$t('instant_card_projects.details.batch_section.title')" icon="">
    <template slot="headerZoneRight">
      <label-text
        class="text-xs-center"
        :label="`${$t('instant_card_projects.details.batch_section.count')}`"
        :value="projectBatchesItemCount || '0'"
      ></label-text>
      <div v-if="showAddBatchFeature" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text v-if="showAddBatchFeature" :label="`${$t('instant_card_projects.details.batch_section.action')}`" value="">
        <link-secondary-action slot="labelTextLink" @click="openAddBatchModal">
          {{ $t('instant_card_projects.details.batch_section.create') }}
        </link-secondary-action>
      </label-text>
    </template>
    <template slot="content">
      <modal-action :show-modal="showAddBatchModal">
        <add-project-batch-action
          v-if="showAddBatchModal"
          :project-id="project.id"
          :project-type="project.type"
          @close="closeAddBatchModal"
          @created="addBatchSuccess"
        ></add-project-batch-action>
      </modal-action>
      <project-batches-table
        v-if="dataReady && projectBatches.length"
        :items="projectBatches"
        :project-id="project.id"
        @deactivate_gift_certificate_action="fetchBatches"
      ></project-batches-table>
      <label-none v-if="projectBatches.length === 0">{{ $t('card.comments_section.no_found') }}</label-none>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';
import ProjectBatchesTable from '@/components/instant_card_project/detail/ProjectBatchesTable';
import AddProjectBatchAction from '@/components/instant_card_project/actions/AddProjectBatchAction';

export default {
  name: 'project-batches',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { ProjectBatchesTable, AddProjectBatchAction },
  data() {
    return {
      dataReady: false,
      pagination: 1,
      batches: null,
      showAddBatchModal: false,
    };
  },
  async created() {
    await this.fetchBatches();
  },
  watch: {
    async project() {
      return this.fetchBatches();
    },
    projectBatchesPageNumber() {
      this.pagination = this.projectBatchesPageNumber;
    },
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'instantCardProject/changeProjectBatchesPage',
          success: this.success,
        },
        {
          id: this.project.id,
          page: newValue,
        },
      );
    },
  },
  computed: {
    ...mapGetters('instantCardProject', [
      'project',
      'projectBatches',
      'projectBatchesPageCount',
      'projectBatchesPageNumber',
      'projectBatchesItemCount',
    ]),
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    showAddBatchFeature() {
      return this.canManageOffer(); // TODO change permission
    },
    openAddBatchModal() {
      this.showAddBatchModal = true;
    },
    closeAddBatchModal() {
      this.showAddBatchModal = false;
    },
    async addBatchSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'instant_card_projects.details.batch_section.add_action.success' });
      this.closeAddBatchModal();
    },
    async fetchBatches() {
      await this.executeAction({ action: 'instantCardProject/listProjectBatches' }, { id: this.project.id, page: this.projectBatchesPageNumber });
      this.dataReady = true;
    },
  },
};
</script>
