import Vue from 'vue';
import keyExtractor from '../utils/keys-extractor';

const defaultPage = 1;
const paginationLimit = 20;

export default {
  namespaced: true,
  state: {
    walletDefinitions: [],
    walletDefinition: { rules: {}, statistics: {}, labels: {} },
    list: {
      keyword: '',
      pageNumber: 1,
      pageCount: 0,
      itemCount: 0,
    },
  },
  getters: {
    walletDefinitions: (state) => state.walletDefinitions,
    walletDefinition: (state) => state.walletDefinition,
    listKeyword: (state) => state.list.keyword,
    listPageCount: (state) => state.list.pageCount,
    listItemCount: (state) => state.list.itemCount,
    listPageNumber: (state) => state.list.pageNumber,
  },
  mutations: {
    walletDefinitions(state, walletDefinitions) {
      state.walletDefinitions = walletDefinitions;
    },
    walletDefinition(state, walletDefinition) {
      if (!walletDefinition) {
        walletDefinition = { rules: {}, statistics: {}, labels: {} };
      }
      state.walletDefinition = walletDefinition;
    },
    listKeyword(state, keyword) {
      state.list.keyword = keyword;
    },
    listPageCount(state, pageCount) {
      state.list.pageCount = pageCount;
    },
    listItemCount(state, itemCount) {
      state.list.itemCount = itemCount;
    },
    listPage(state, page) {
      state.list.pageNumber = page;
    },
  },
  actions: {
    async listWalletDefinitions({ commit, state }, { keyword, page, limit } = {}) {
      const walletDefinitionService = Vue.prototype.$services.walletDefinition;
      const thePage = page || state.list.pageNumber || defaultPage;
      const theLimit = limit || paginationLimit;
      const theKeyword = keyword || '';

      const listResults = await walletDefinitionService.listWalletDefinitions(thePage, theLimit, theKeyword);

      commit('listKeyword', theKeyword);
      commit('listPage', thePage);
      commit('listPageCount', listResults.page_count);
      commit('listItemCount', listResults.item_count);
      commit('walletDefinitions', listResults.items);
    },
    async getWalletDefinition({ commit }, id) {
      const walletDefinitionService = Vue.prototype.$services.walletDefinition;
      const walletDefinition = await walletDefinitionService.getWalletDefinition(id);

      walletDefinition.statistics = await walletDefinitionService.getStatistics(walletDefinition.key);

      commit('walletDefinition', walletDefinition);
      return walletDefinition;
    },
    async clearListWalletDefinitions({ commit }) {
      commit('walletDefinitions', []);
    },
    async getLogs(context, walletDefinitionId) {
      const walletDefinitionService = Vue.prototype.$services.walletDefinition;

      let page = 1;
      const limit = 3;
      let { items: walletDefinitionLogs } = await walletDefinitionService.getLogs(walletDefinitionId, page, limit);

      return walletDefinitionLogs.map((l) => {
        l.change_keys = keyExtractor.sortObjectsExistingKeys(['external_id', 'rules', 'tags', 'name', 'label'], l.before_change, l.changes);
        l.created = new Date(l.created);
        return l;
      });
    },
    async renameWalletDefinition({ dispatch }, { id, name }) {
      const walletDefinitionService = Vue.prototype.$services.walletDefinition;
      const result = await walletDefinitionService.renameWalletDefinition(id, name);
      await dispatch('getWalletDefinition', id);
      return result;
    },
  },
};
