<template>
  <card-modal
    icon=""
    :title="$t('voucher.extend_expiration_action.title')"
    context="extendVoucherExpiration"
    @submit="submit"
    @cancel="cancel"
    :submit_working="submitting"
    cancel_text="voucher.extend_expiration_action.button_cancel"
    submit_text="voucher.extend_expiration_action.button_save"
    :submit_hidden="isExpirationAtLeastOneDayBeforeClosure"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <template>
          <v-layout row pad-form-row-elements v-if="isRelativeVoucher">
            <v-flex sm12 xs12>
              <alert-warning
                v-if="isExpirationAtLeastOneDayBeforeClosure"
                :text="$t('voucher.extend_expiration_action.relative_voucher_cannot_be_extended_to_close_of_closure_warning')"
              ></alert-warning>
              <v-text-field
                autofocus
                v-show="!isExpirationAtLeastOneDayBeforeClosure"
                ref="bonus_extension_days"
                name="bonus_extension_days"
                v-model="bonus_extension_days"
                :label="$t('voucher.extend_expiration_action.bonus_extension_days') + '*'"
                v-validate="{
                  required: true,
                  integer: true,
                  min_value: 1,
                  max_value: maxExtensionDays,
                }"
                :error-messages="errors.collect('bonus_extension_days')"
                data-vv-name="bonus_extension_days"
                :data-vv-as="$t('voucher.extend_expiration_action.bonus_extension_days')"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row pad-form-row-elements v-if="isFixedVoucher">
            <v-flex sm12 xs12>
              <v-menu
                v-model="showDatePickerCalendar"
                :close-on-content-click="false"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    data-vv-name="bonus_end_date"
                    :error-messages="errors.collect('bonus_end_date')"
                    v-validate="{
                      required: true,
                      islater: { date: min_bonus_end_date },
                      isbefore: { date: offer.incentive.closure_end_date },
                    }"
                    :data-vv-as="$t('voucher.extend_expiration_action.bonus_end_date')"
                    v-model="bonus_end_date"
                    :label="$t('voucher.extend_expiration_action.bonus_end_date')"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="bonus_end_date"
                  @input="showDatePickerCalendar = false"
                  :min="offer.incentive.bonus_end_date"
                  :max="offer.incentive.closure_end_date"
                  :show-current="new Date().toISOString().substr(0, 10)"
                ></v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
        </template>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import moment from 'moment';

export default {
  name: 'extend-voucher-expiration-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    voucher: {
      type: Object,
      required: true,
    },
    numberOfDaysBetweenExpirationAndClosure: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      submitting: false,
      bonus_end_date: moment(this.voucher.bonus_expiration_date).format('YYYY-MM-DD'),
      min_bonus_end_date: moment(this.voucher.bonus_expiration_date)
        .add(1, 'day')
        .format('YYYY-MM-DD'),
      bonus_extension_days: null,
      currentVoucher: {},
      showDatePickerCalendar: false,
    };
  },
  async mounted() {
    this.currentVoucher = _.cloneDeep(this.voucher);
    await this.fetchOffer();
  },
  computed: {
    ...mapGetters('offers', ['offer']),
    ...mapGetters('ui', ['currentLocale']),
    isFixedVoucher() {
      return this.offer.incentive.duration_model === 'fixed';
    },
    isRelativeVoucher() {
      return this.offer.incentive.duration_model === 'relative';
    },
    isExpirationAtLeastOneDayBeforeClosure() {
      return this.numberOfDaysBetweenExpirationAndClosure !== 0 && this.numberOfDaysBetweenExpirationAndClosure <= 1;
    },
    maxExtensionDays() {
      if (this.numberOfDaysBetweenExpirationAndClosure === 0) {
        return 365;
      }

      let nbDays = this.numberOfDaysBetweenExpirationAndClosure - 1;
      if (nbDays < 1) {
        return 1;
      }

      if (nbDays > 365) {
        return 365;
      }

      return nbDays;
    },
  },
  methods: {
    async fetchOffer() {
      return this.executeAction({ action: 'offers/getOffer' }, this.currentVoucher.offer_id);
    },
    clear() {
      this.currentVoucher = {};
      this.submitting = false;
      this.bonus_end_date = null;
      this.bonus_extension_days = null;
      this.$validator.reset();
      this.showDatePickerCalendar = false;
    },
    success() {
      this.clear();
      this.$emit('updated');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();
      if (validationComplete) {
        const params = {
          id: this.voucher.id,
        };
        if (this.isFixedVoucher) {
          params.bonus_end_date = this.bonus_end_date;
        } else {
          params.bonus_extension_days = this.bonus_extension_days;
        }

        this.submitting = true;
        try {
          await this.executeAction(
            {
              action: 'voucher/extendVoucherExpiration',
              name: 'extendVoucherExpiration',
              success: this.success,
            },
            params,
          );
        } finally {
          this.submitting = false;
        }
      }
    },
  },
};
</script>
