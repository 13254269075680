<template>
  <card-modal
    icon=""
    :title="$t('card.load_open_wallet_action.title')"
    context="LoadOpenWallet"
    @submit="validate"
    @cancel="cancel"
    cancel_text="card.load_open_wallet_action.button_cancel"
    submit_text="card.load_open_wallet_action.button_save"
    :submit_working="working"
    data-test="load-open-wallet-modal"
  >
    <form @submit.prevent="validate" slot="content">
      <modal-confirm
        :show-modal="showConfirmModal"
        context="LoadOpenWallet"
        :title="$t('card.load_open_wallet_action.confirm.title')"
        :text="$t('card.load_open_wallet_action.confirm.text', { walletDefinitionId: 'Open', amount: this.amount })"
        cancel_text="card.load_open_wallet_action.confirm.button_cancel"
        submit_text="card.load_open_wallet_action.confirm.button_submit"
        :submit_working="working"
        @submit="submit"
        @close="closeConfirmModal"
      ></modal-confirm>

      <v-layout column>
        <v-layout wrap row class="pad-form-row-elements">
          <v-flex xs12 sm6 md5>
            <v-select
              data-test="load-open-wallet-activity-selector"
              v-model="selected_activity"
              :items="activities"
              item-text="name"
              item-value="value"
              :label="$t('card.load_open_wallet_action.fields.activity')"
              data-vv-name="activity"
              :error-messages="errors.collect('activity')"
              :data-vv-as="$t('card.load_open_wallet_action.fields.activity')"
              v-validate="'required'"
            ></v-select>
          </v-flex>
          <v-flex xs12 sm4 md4 v-show="selected_activity">
            <v-text-field
              data-test="load-open-wallet-amount-input"
              v-model="amount"
              name="amount"
              type="number"
              :label="$t('card.load_open_wallet_action.fields.amount')"
              v-validate="{ required: true, decimal: 2, min_value: 0.01, max_value: 2500 }"
              :error-messages="errors.collect('amount')"
              data-vv-name="amount"
              :data-vv-as="$t('card.load_open_wallet_action.fields.amount')"
              :suffix="currency"
              required
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-layout v-if="isCreditActivity" wrap row class="pad-form-row-elements">
          <v-layout column>
            <v-flex xs12 sm8 md8>
              <h-autocomplete
                v-model="reference_id"
                :label="$t('purse.load_open_purse_from_business.fields.business_id') + '*'"
                v-validate="'required'"
                :error-messages="errors.collect('business_id')"
                name="business_id"
                data-vv-name="business_id"
                data-vv-as="business_id"
                search-action="business/autocompleteSearch"
                data-test="load-open-purse--business"
                no-filter
              >
              </h-autocomplete>
            </v-flex>
          </v-layout>
        </v-layout>
        <v-layout v-if="isCreditActivity" wrap row class="pad-form-row-elements">
          <v-textarea
            data-test="load-open-wallet-note-input"
            v-validate="'required|max:140'"
            data-vv-name="note"
            :error-messages="errors.collect('note')"
            :data-vv-as="$t('card.load_open_wallet_action.fields.credit.note')"
            v-model="note"
            rows="1"
            auto-grow
            :label="$t('card.load_open_wallet_action.fields.credit.note')"
            counter="140"
            maxlength="140"
          ></v-textarea>
        </v-layout>
        <v-layout v-if="isEtransferActivity" wrap row class="pad-form-row-elements">
          <v-flex xs12 sm6 md5 class="load-open-wallet-e-transfer-id-wrap">
            <v-text-field
              data-test="load-open-wallet-reference-id-input"
              v-validate="'required|max:50'"
              data-vv-name="reference_id"
              :error-messages="errors.collect('reference_id')"
              :data-vv-as="$t('card.load_open_wallet_action.fields.etransfer.reference_id')"
              :label="$t('card.load_open_wallet_action.fields.etransfer.reference_id')"
              maxlength="50"
              v-model="reference_id"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md4>
            <v-menu
              v-model="showDatePickerCalendar"
              :close-on-content-click="false"
              :nudge-right="40"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  data-test="load-open-wallet-reference-date-input"
                  v-validate="'required'"
                  data-vv-name="reference_date"
                  :error-messages="errors.collect('reference_date')"
                  :data-vv-as="$t('card.load_open_wallet_action.fields.etransfer.reference_date')"
                  v-model="reference_date"
                  :label="$t('card.load_open_wallet_action.fields.etransfer.reference_date')"
                  prepend-icon="event"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="reference_date"
                @input="showDatePickerCalendar = false"
                :show-current="new Date().toISOString().substr(0, 10)"
              ></v-date-picker>
            </v-menu>
          </v-flex>
        </v-layout>
        <v-layout v-if="isEtransferActivity" wrap row class="pad-form-row-elements">
          <v-textarea
            data-test="load-open-wallet-optional-note-input"
            v-validate="'max:140'"
            data-vv-name="note"
            :error-messages="errors.collect('note')"
            :data-vv-as="$t('card.load_open_wallet_action.fields.credit.note')"
            v-model="note"
            rows="1"
            auto-grow
            :label="$t('card.load_open_wallet_action.fields.etransfer.note')"
            counter="140"
            maxlength="140"
          ></v-textarea>
        </v-layout>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import _find from 'lodash/find';
import { mapGetters } from 'vuex';
import { actionErrorTrackable } from '@/mixins';
import _ from 'lodash';
import Vue from 'vue';

export default {
  name: 'load-open-wallet-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    public_token: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      showSelectBusinessModal: false,
      selected_activity: undefined,
      activities: [
        {
          name: this.$t('card.load_open_wallet_action.fields.activities.credit'),
          type: 'agent',
          value: 'CREDIT',
        },
        {
          name: this.$t('card.load_open_wallet_action.fields.activities.etransfer'),
          type: 'etransfer',
          value: 'ETRANSFER',
        },
      ],
      showConfirmModal: false,
      showDatePickerCalendar: false,
      reference_date: undefined,
      reference_id: undefined,
      selected_activity_value: undefined,
      note: undefined,
      amount: 0,
    };
  },
  watch: {
    selected_activity(val) {
      if (val !== this.selected_activity_value) {
        this.inModalClear();
      }

      this.selected_activity_value = val;
    },
  },
  computed: {
    ...mapGetters('program', ['currency']),
    isCreditActivity() {
      return this.selected_activity_value === 'CREDIT';
    },
    isEtransferActivity() {
      return this.selected_activity_value === 'ETRANSFER';
    },
  },
  methods: {
    async openConfirmModal() {
      this.showConfirmModal = true;
    },
    closeConfirmModal() {
      this.showConfirmModal = false;
    },
    inModalClear() {
      this.showSelectBusinessModal = false;
      this.showConfirmModal = false;
      this.showDatePickerCalendar = false;
      this.amount = 0;
      this.reference_id = undefined;
      this.reference_date = undefined;
      this.note = undefined;
      this.clearError('LoadOpenWallet');
      this.$validator.reset();
    },
    clear() {
      this.inModalClear();
      this.selected_activity = undefined;
      this.selected_activity_value = undefined;
    },
    success() {
      this.clear();
      this.$emit('action');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async validate() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        if (this.amount >= 500) {
          await this.openConfirmModal();
        } else {
          await this.submit();
        }
      }
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        await this.executeAction(
          {
            action: 'card/loadOpenWallet',
            name: 'LoadOpenWallet',
            success: this.success,
          },
          {
            card_public_token: this.public_token,
            amount: this.amount,
            currency: this.currency,
            type: _find(this.activities, (activity) => activity.value === this.selected_activity).type,
            reference_id: this.reference_id,
            reference_date: this.reference_date,
            note: this.note,
          },
        );
      }
      this.closeConfirmModal();
    },
  },
};
</script>
