import Vue from 'vue';

const service = {
  async addStoresFromPublisher(to_publisher_id, from_publisher_id) {
    const payload = { from_publisher_id };
    const response = await Vue.prototype.$axios.post(
      `${process.env.VUE_APP_BASE_API_URL}/publisher/v2/publishers/${to_publisher_id}/publisher-stores`,
      payload,
    );
    return response.data;
  },
  async addStoresFromDestination(to_publisher_id, from_destination_id) {
    const payload = { from_destination_id };
    const response = await Vue.prototype.$axios.post(
      `${process.env.VUE_APP_BASE_API_URL}/publisher/v2/publishers/${to_publisher_id}/destination-stores`,
      payload,
    );
    return response.data;
  },
  async getPublisherStatistics(id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/publishers/${id}/statistics`);
    return response.data.data;
  },
  async getPublisher(id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/publishers/${id}`);
    return response.data.data;
  },
  async createPublisher(payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/publishers`, payload);
    return response.data.data;
  },
  async updatePublisher(id, params) {
    const payload = params;
    delete payload.business_id;
    delete payload.branding_id;
    delete payload.system_status;

    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/publishers/${id}`, payload);
    return response.data.data;
  },
  async searchPublishers(page, limit, keyword, exclude_offer_id, exclude_publisher_id) {
    const params = {};
    if (keyword) {
      params.keyword = keyword;
    }
    if (exclude_offer_id) {
      params.exclude_offer_id = exclude_offer_id;
    }
    if (exclude_publisher_id) {
      params.exclude_publisher_id = exclude_publisher_id;
    }

    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v2/publishers/search`, page, limit, params)}`,
    );
    return response.data.data;
  },
  async listPublishers(keyword, partner_id, business_id, page, limit) {
    const params = {};
    if (keyword) {
      params.keyword = keyword;
    }
    if (partner_id) {
      params.partner_id = partner_id;
    }
    if (business_id) {
      params.business_id = business_id;
    }
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath('/publisher/v2/publishers', page, limit, params)}`,
    );
    return response.data.data;
  },
  async createPublisherNote(publisher_id, payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/publishers/${publisher_id}/notes`, payload);
    return response.data.data;
  },
  async deletePublisherNote(publisher_id, note_id, payload) {
    const response = await Vue.prototype.$axios.put(
      `${process.env.VUE_APP_BASE_API_URL}/publisher/v2/publishers/${publisher_id}/notes/${note_id}/delete`,
      payload,
    );
    return response.data.data;
  },
  async listPublisherNotes(page, limit, publisher_id) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v2/publishers/${publisher_id}/notes`, page, limit)}`,
    );
    return response.data.data;
  },
  async linkBrandingToPublisher(id, payload) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/publishers/${id}/branding`, payload);
    return response.data.data;
  },
  async unlinkBrandingFromPublisher(id) {
    const response = await Vue.prototype.$axios.delete(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/publishers/${id}/branding`);
    return response.data.data;
  },
  async linkBusinessToPublisher(id, payload) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/publishers/${id}/business`, payload);
    return response.data.data;
  },
  async unlinkBusinessFromPublisher(id) {
    const response = await Vue.prototype.$axios.delete(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/publishers/${id}/business`);
    return response.data.data;
  },
  async addStoreToPublisher(publisherId, store_id) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/publishers/${publisherId}/stores`, {
      store_id,
    });
    return response.data.data;
  },
  async removeAllStoresFromPublisher(publisherId) {
    const response = await Vue.prototype.$axios.delete(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/publishers/${publisherId}/stores`);
    return response.data.data;
  },
  async removeStoreFromPublisher(publisherId, store_id) {
    const response = await Vue.prototype.$axios.delete(
      `${process.env.VUE_APP_BASE_API_URL}/publisher/v2/publishers/${publisherId}/stores/${store_id}`,
    );
    return response.data.data;
  },
  async listPublisherStoresByPublisher(id, keyword, status, type, category, without_card_acceptors, page, limit) {
    const params = {};
    if (keyword) {
      params.keyword = keyword;
    }
    if (status) {
      params.status = status;
    }
    if (type) {
      params.type = type;
    }
    if (category) {
      params.category = category;
    }
    if (without_card_acceptors) {
      params.without_card_acceptors = without_card_acceptors;
    }
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v2/publishers/${id}/stores`, page, limit, params)}`,
    );
    return response.data.data;
  },
  async listPublisherOffers(id, page, limit) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v2/publishers/${id}/offers`, page, limit)}`,
    );
    return response.data.data;
  },
  async searchPublisherStoresByPublisher(id, keyword, page, limit) {
    const params = {};
    if (keyword) {
      params.keyword = keyword;
    }
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v2/publishers/${id}/stores`, page, limit, params)}`,
    );
    return response.data.data;
  },
  async autocompleteSearchOfferToAddToPublisher(publisher_id, page, limit, keyword) {
    const params = {};
    if (keyword) {
      params.keyword = keyword;
    }
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v2/publishers/${publisher_id}/search-offers-to-link`, page, limit, params)}`,
    );
    return response.data.data;
  },
};

function generatePath(
  startPath,
  page,
  limit,
  { keyword, partner_id, business_id, status, type, category, without_card_acceptors, exclude_offer_id, exclude_publisher_id } = {},
) {
  let path = startPath;
  if (
    page ||
    limit ||
    keyword ||
    partner_id ||
    business_id ||
    status ||
    type ||
    category ||
    without_card_acceptors ||
    exclude_offer_id ||
    exclude_publisher_id
  ) {
    path += '?';
  }

  const queryStrings = [];
  if (page) {
    queryStrings.push(['page', page]);
  }
  if (limit) {
    queryStrings.push(['limit', limit]);
  }
  if (keyword) {
    queryStrings.push(['keyword', keyword]);
  }
  if (status) {
    queryStrings.push(['status', status]);
  }
  if (type) {
    queryStrings.push(['type', type]);
  }
  if (category) {
    queryStrings.push(['category', category]);
  }
  if (without_card_acceptors) {
    queryStrings.push(['without_card_acceptors', without_card_acceptors]);
  }
  if (partner_id) {
    queryStrings.push(['partner_id', partner_id]);
  }
  if (business_id) {
    queryStrings.push(['business_id', business_id]);
  }
  if (exclude_offer_id) {
    queryStrings.push(['exclude_offer_id', exclude_offer_id]);
  }
  if (exclude_publisher_id) {
    queryStrings.push(['exclude_publisher_id', exclude_publisher_id]);
  }

  const params = new URLSearchParams(queryStrings);
  return path + params.toString();
}

export default service;
