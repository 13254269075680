<template>
  <div>
    <table class="responsive-table" id="publisher-spaces-table">
      <thead>
        <tr>
          <td>{{ $t('branding.spaces_table.id') }}</td>
          <td>{{ $t('branding.spaces_table.name') }}</td>
          <td>{{ $t('branding.spaces_table.program') }}</td>
          <td>{{ $t('branding.spaces_table.slug') }}</td>
          <td>{{ $t('branding.spaces_table.visibility') }}</td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>
            <h5>{{ $t('branding.spaces_table.id') }}:</h5>
            <span>{{ item.id }}</span>
          </td>
          <td>
            <h5>{{ $t('branding.spaces_table.name') }}:</h5>
            <span>{{ item.name }}</span>
          </td>
          <td>
            <h5>{{ $t('branding.spaces_table.program') }}:</h5>
            <span>{{ item.partner_id }}</span>
          </td>
          <td>
            <h5>{{ $t('branding.spaces_table.slug') }}:</h5>
            <span>{{ item.slug }}</span>
          </td>
          <td>
            <h5>{{ $t('branding.spaces_table.visibility') }}:</h5>
            <span>{{
              item.private_space ? $t('branding.spaces_table.visibility_type.private') : $t('branding.spaces_table.visibility_type.public')
            }}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-xs-center">
      <v-pagination v-if="brandingSpacesPageCount > 1" v-model="pagination" :length="brandingSpacesPageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'branding-spaces-table',
  mixins: [security, actionErrorTrackable, disableWithError],
  props: {
    id: {
      type: String,
    },
    items: {
      type: Array,
    },
  },
  data() {
    return {
      pagination: 1,
    };
  },
  mounted() {
    this.pagination = this.brandingSpacesPageNumber;
  },
  watch: {
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'branding/listBrandingSpaces',
        },
        {
          id: this.id,
          page: newValue,
        },
      );
    },
  },
  computed: {
    ...mapGetters('branding', ['brandingSpacesPageNumber', 'brandingSpacesPageCount']),
  },
};
</script>
