<template>
  <v-flex class="data-section">
    <div v-if="shouldRender">
      <applicant-info :application="application" :cards="cards" :kyc="kyc" />

      <kyc-info v-if="shouldRenderWithKyc" :application="application" :kyc="kyc" />

      <v-alert v-else type="info" :value="true" class="alert--no-kyc">{{ $t('application.no_kyc') }}</v-alert>

      <manual-approval-info v-if="shouldRenderManualApproval" :application="application" :kyc="kyc" />

      <v-alert v-if="shouldRenderNoManualApprovalMessage" type="info" :value="true" class="alert--no-kyc-detail">{{
        $t('application.no_kyc_detail')
      }}</v-alert>

      <aml-document-list
        v-if="canUploadDocumentApplication() || canManageApplication() || canManageAmlProgram()"
        id="#documents"
        from-view="application"
        :card-application-id="application.card_application_id"
        :account-id="application.account_id"
        :cardholder-key="application.cardholder_key"
        :card-program-key="application.card_program_key"
      />

      <change-request-history
        :type="application"
        :change-request-log="changeRequestLog"
        :application="application"
        :kyc="kyc"
        :field-headings="'change_request_history_section.fields'"
      />

      <change-history :type="application" :update-logs="updateLogs" :field-headings="'application.update_applicant_info_action.fields'" />

      <application-comments-list
        v-if="canCommentApplication() || canManageApplication()"
        id="#comments"
        :application_id="application.card_application_id"
        :account_id="application.account_id"
      />

      <aml-profile v-if="canManageAmlProgram()" :card-applicant="application.card_applicant" />

      <aml-notes-list
        id="#notes"
        v-if="canManageAmlProgram()"
        :card-application-id="application.card_application_id"
        :cardholder-key="application.cardholder_key"
        :account-id="application.account_id"
        :card-program-key="application.card_program_key"
      />
    </div>
  </v-flex>
</template>

<script>
import _ from 'lodash';
import KycInfo from './detail/KycInfo';
import ManualApprovalInfo from './detail/ManualApprovalInfo';
import AmlNotesList from './detail/AmlNotesList';
import AmlProfile from './detail/AmlProfile';
import ApplicantInfo from './detail/ApplicantInfo';
import ApplicationCommentsList from './detail/ApplicationCommentsList';
import AmlDocumentList from '@/components/system/AML/AmlDocumentList';
import ChangeHistory from '@/components/system/ChangeHistory';
import ChangeRequestHistory from './detail/ChangeRequestHistory';
import { security, disableWithError, actionErrorTrackable, hashtag } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'view-application',
  mixins: [security, actionErrorTrackable, disableWithError, hashtag],
  components: {
    ChangeHistory,
    ChangeRequestHistory,
    ApplicantInfo,
    ManualApprovalInfo,
    KycInfo,
    AmlNotesList,
    AmlProfile,
    AmlDocumentList,
    ApplicationCommentsList,
  },
  props: {
    application: {
      type: Object,
      required: false,
    },
    cards: {
      type: Array,
      required: false,
    },
    kyc: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapGetters('application', ['updateLogs']),
    ...mapGetters('selfserve', ['changeRequestLog']),
    shouldRender() {
      return !_.isEmpty(this.application) && !_.isUndefined(this.application);
    },
    shouldRenderWithKyc() {
      return !_.isEmpty(this.application) && !_.isUndefined(this.application);
    },
    shouldRenderManualApproval() {
      return (
        this.shouldRenderWithKyc && this.application.status === 'approved' && _.get(this.application, 'kyc_detail.reviewer_type', 'none') === 'agent'
      );
    },
    shouldRenderNoManualApprovalMessage() {
      return this.shouldRenderWithKyc && this.application.status === 'approved' && !_.get(this.application, 'kyc_detail', false);
    },
  },
  watch: {
    application() {
      this.fetchUpdateLogs();
      this.fetchChangeRequestLogs();
    },
  },
  async created() {
    await this.fetchUpdateLogs();
    await this.fetchChangeRequestLogs();
  },
  methods: {
    async fetchUpdateLogs() {
      if (this.application.card_application_id) {
        await this.executeAction({ action: 'application/getUpdateLog' }, this.application.card_application_id);
      }
    },
    async fetchChangeRequestLogs() {
      if (this.application.card_application_id) {
        await this.executeAction({ action: 'selfserve/getChangeRequestLog' }, this.application.card_application_id);
      }
    },
  },
};
</script>
