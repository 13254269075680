<template>
  <card :title="$t('space.details.clerk_section.title')" icon="">
    <template slot="headerZoneRight">
      <label-text class="text-sm-center" :label="$t('space.details.clerk_section.count')" :value="listClerksItemCount || '0'"></label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text class="text-sm-center" :label="$t('space.details.clerk_section.action')">
        <link-secondary-action slot="labelTextLink" @click="showAddClerkActionModal()">
          {{ $t('space.details.clerk_section.add') }}
        </link-secondary-action>
      </label-text>
    </template>
    <template slot="content">
      <modal-action :show-modal="addClerkModalShown" close>
        <add-clerk-action
          :partner-id="space.partner_id"
          :space-id="space.id"
          @success="clerkCreated"
          @close="closeAddClerkActionModal"
        ></add-clerk-action
      ></modal-action>
      <space-clerk-table v-if="dataReady && clerks.length" :items="clerks" :partner-id="space.partner_id"></space-clerk-table>
      <label-none v-if="clerks.length === 0">{{ $t('system.none') }}</label-none>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import SpaceClerkTable from './SpaceClerkTable';
import { mapGetters } from 'vuex';
import AddClerkAction from '../actions/AddClerkAction.vue';

export default {
  name: 'space-clerk',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { AddClerkAction, SpaceClerkTable },
  data() {
    return {
      page: 1,
      dataReady: false,
      addClerkModalShown: false,
    };
  },
  async created() {
    await this.fetch();
    this.page = this.listClerksPageNumber;
  },
  watch: {
    async space() {
      await this.fetch();
      this.page = 1;
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('space', ['space']),
    ...mapGetters('partnerSecurity', ['clerks', 'listClerksItemCount', 'listClerksPageNumber']),
  },
  methods: {
    showAddClerkActionModal() {
      this.addClerkModalShown = true;
    },
    async clerkCreated() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: `space.details.clerk_section.add_action.success` });
      this.addClerkModalShown = false;
    },
    closeAddClerkActionModal() {
      this.addClerkModalShown = false;
    },
    async fetch() {
      this.dataReady = false;
      if (this.space.partner_id) {
        await this.executeAction({ action: 'partnerSecurity/listClerks' }, { partner_id: this.space.partner_id });

        await this.executeAction({ action: 'space/listAllActiveSpacePartnerEstablishments' }, { partner_id: this.space.partner_id });
      }
      this.dataReady = true;
    },
  },
};
</script>
