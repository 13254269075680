<template>
  <card-modal
    icon="fal fa-upload"
    :title="$t(`aml.upload_document_action.title`)"
    context="uploadDocument"
    @cancel="cancel"
    cancel_text="aml.upload_document_action.button_cancel"
  >
    <form slot="content">
      <v-layout column>
        <v-select
          name="document_type"
          v-model="documentType"
          v-validate="'required'"
          :error-messages="errors.collect('document_type')"
          data-vv-name="state"
          :data-vv-as="$t(`aml.upload_document_action.document_type`)"
          :items="documentTypeOptions"
          item-text="name"
          item-value="value"
          :label="$t(`aml.upload_document_action.document_type`)"
          class="smoke-document-upload-type"
        ></v-select>
      </v-layout>

      <v-layout column v-if="documentType">
        <vue-dropzone
          v-if="!isLoading"
          v-on:vdropzone-file-added="fileAdded"
          v-on:vdropzone-sending="sendingEvent"
          @vdropzone-success="uploadSuccess"
          ref="myVueDropzone"
          id="dropzone"
          :options="dropzoneOptions"
        ></vue-dropzone>
        <span>{{ $t('aml.upload_document_action.max_size') }}: {{ (dropzoneOptions.maxFilesize * 1000000) | bytes }}</span>
      </v-layout>
    </form>

    <div slot="test-zone" v-if="documentType">
      <button-warning v-if="cypress" class="smoke-test-document-upload-dropzone" @click="sendTestFile()" :text="'Test Only'"></button-warning>
    </div>
  </card-modal>
</template>

<script>
import Jimp from 'jimp';

import { actionErrorTrackable, cypress } from '@/mixins';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import axios from 'axios';

export default {
  name: 'aml-document-upload-action',
  mixins: [actionErrorTrackable, cypress],
  components: { vueDropzone: vue2Dropzone },
  props: {
    cardApplicationId: {
      type: String,
      required: true,
    },
    cardholderKey: {
      type: String,
      required: false,
    },
    accountId: {
      type: String,
      required: false,
    },
    publicToken: {
      type: String,
      required: false,
    },
    cardProgramKey: {
      type: String,
      required: true,
    },
  },
  data() {
    const data = {
      isLoading: true,
      fileCountToUpload: 0,
      fileCountUploaded: 0,
      dropzoneOptions: {
        transformFile: (file, done) => {
          this.compressImage(file).then((newFile) => {
            done(newFile);
          });
        },
        dictDefaultMessage: `<i class="fal fa-file-import"></i> ${this.$t('aml.upload_document_action.dropzone_msg')}`,
        url: `${process.env.VUE_APP_BASE_API_URL}/aml/v1/documents`,
        maxFilesize: 5, // MB
        autoProcessQueue: true,
        addRemoveLinks: true,
        headers: { Authorization: `Bearer ${this.$auth.getAccessToken()}` },
      },
      documentType: null,
      documentTypeOptions: [
        { name: this.$t('aml.document_types.id_verification'), value: 'id_verification' },
        { name: this.$t('aml.document_types.card_limit'), value: 'card_limit' },
        { name: this.$t('aml.document_types.other'), value: 'other' },
      ],
    };

    this.$auth.getAccessToken().then((accessToken) => {
      data.dropzoneOptions.headers.Authorization = `Bearer ${accessToken}`;
      data.isLoading = false;
    });

    return data;
  },
  methods: {
    async compressImage(file) {
      const supportedTypes = ['image/gif', 'image/jpeg', 'image/png'];

      return new Promise((resolve) => {
        if (!supportedTypes.includes(file.type)) {
          return resolve(file);
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.addEventListener(
          'load',
          () => {
            Jimp.read(reader.result).then((sourceImage) => {
              let image = sourceImage;

              if (image.bitmap.width > 1920) {
                image = image.resize(1920, Jimp.AUTO);
              } else if (image.bitmap.height > 1080) {
                image = image.resize(Jimp.AUTO, 1080);
              }

              image = image.quality(50);

              image.getBufferAsync(file.type).then((buffer) => {
                image.getBase64Async(file.type).then((base64) => {
                  const newFile = new File([buffer], file.name, { type: file.type });

                  newFile.dataURL = base64;
                  newFile.height = image.bitmap.height;
                  newFile.width = image.bitmap.width;

                  resolve(newFile);
                });
              });
            });
          },
          false,
        );
      });
    },

    fileAdded() {
      this.totalFilesToUpload = this.$refs.myVueDropzone.getQueuedFiles().length + 1;
    },
    sendingEvent(file, xhr, formData) {
      formData.append('filename', file.name);
      formData.append('content_type', file.type || 'text/plain');
      formData.append('card_application_id', this.cardApplicationId);
      formData.append('card_program_key', this.cardProgramKey);
      formData.append('document_type', this.documentType);

      if (this.cardholderKey) formData.append('cardholder_key', this.cardholderKey);
      if (this.accountId) formData.append('account_id', this.accountId);
      if (this.publicToken) formData.append('public_token', this.publicToken);
    },
    async sendTestFile() {
      const payload = {
        file: 'String representing a test file',
        filename: 'TestFile',
        content_type: 'text/plain',
        card_application_id: this.cardApplicationId,
        card_program_key: this.cardProgramKey,
        document_type: this.documentType,
      };

      if (this.cardholderKey) payload.cardholder_key = this.cardholderKey;
      if (this.accountId) payload.account_id = this.accountId;
      if (this.publicToken) payload.public_token = this.publicToken;

      await axios.post(`${process.env.VUE_APP_BASE_API_URL}/aml/v1/documents`, payload);
      this.success();
    },
    clear() {
      this.fileCountUploaded = 0;
      this.totalFilesToUpload = 0;
      this.documentType = null;
      if (this.$refs.myVueDropzone) {
        this.$refs.myVueDropzone.removeAllFiles();
      }
    },
    async uploadSuccess() {
      this.fileCountUploaded = this.fileCountUploaded + 1;
      if (this.totalFilesToUpload === this.fileCountUploaded) {
        this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'aml.document_upload.success' });
        this.success();
      }
    },
    success() {
      this.executeAction(
        { action: 'aml/getDocuments' },
        {
          card_application_id: this.cardApplicationId,
          public_token: this.publicToken,
          cardholder_key: this.cardholderKey,
          account_id: this.accountId,
        },
      );
      this.clear();
      this.$emit('action');
      this.$emit('close');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
  },
};
</script>
