<template>
  <table class="responsive-table" id="inventory-mouvements-table">
    <thead>
      <tr>
        <td>{{ $t('inventory.mouvements_table.id') }}</td>
        <td>{{ $t('inventory.mouvements_table.created') }}</td>
        <td>{{ $t('inventory.mouvements_table.type') }}</td>
        <td align="right">{{ $t('inventory.mouvements_table.amount') }}</td>
        <td>{{ $t('inventory.mouvements_table.voucher') }}</td>
        <td>{{ $t('inventory.mouvements_table.activation_code') }}</td>
        <td>{{ $t('inventory.mouvements_table.reason') }}</td>
        <td>{{ $t('inventory.mouvements_table.agent') }}</td>
        <td></td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in items" :key="index">
        <td>
          <h5>{{ $t('inventory.mouvements_table.id') }}:</h5>
          <span>{{ item.id }}</span>
        </td>
        <td>
          <h5>{{ $t('inventory.mouvements_table.type') }}:</h5>
          <span>{{ item.creation_date | formatShortDateTimeInverted }}</span>
        </td>
        <td>
          <h5>{{ $t('inventory.mouvements_table.type') }}:</h5>
          <span>{{ $t(`inventory.mouvements.types.${item.type}`) }}</span>
        </td>
        <td align="right">
          <h5>{{ $t('inventory.mouvements_table.amount') }}:</h5>
          <span v-if="item.amount >= 0">{{ item.amount | currency | dollarSignI18n }}</span>
          <span v-else style="color: darkred">{{ item.amount | currency | dollarSignI18n }}</span>
        </td>
        <td>
          <h5>{{ $t('inventory.mouvements_table.voucher') }}:</h5>
          <span>
            <router-link v-if="item.voucher" :to="{ name: 'voucher_details', params: { voucherId: item.voucher.voucher_id } }">
              {{ item.voucher.voucher_id }}
            </router-link>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </span>
        </td>
        <td>
          <h5>{{ $t('inventory.mouvements_table.activation_code') }}:</h5>
          <router-link
            v-if="item.activation_code"
            :to="{ name: 'activation_code_details', params: { activationCodeId: item.activation_code.activation_code_id } }"
          >
            {{ item.activation_code.activation_code_id }}
          </router-link>
          <label-none v-else>{{ $t('system.na') }}</label-none>
        </td>
        <td>
          <h5>{{ $t('inventory.mouvements_table.reason') }}:</h5>
          <span v-if="item.reason">{{ item.reason }}</span>
          <label-none v-else>{{ $t('system.na') }}</label-none>
        </td>
        <td>
          <h5>{{ $t('inventory.mouvements_table.agent') }}:</h5>
          <span><app-name :app_key="item.creation_by_app_key"/></span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import AppName from '@/components/system/AppName';

export default {
  name: 'inventory-mouvements-table',
  components: { AppName },
  mixins: [security, actionErrorTrackable, disableWithError],
  props: {
    items: {
      type: Array,
    },
  },
};
</script>
