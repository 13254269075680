<template>
  <table class="responsive-table">
    <thead>
      <tr>
        <td>{{ $t('space.business_table.fields.id') }}</td>
        <td>{{ $t('space.business_table.fields.name') }}</td>
        <td>{{ $t('space.business_table.fields.address') }}</td>
        <td>{{ $t('space.business_table.fields.contact') }}</td>
        <td>{{ $t('space.business_table.fields.bank_account') }}</td>
        <td>{{ $t('space.business_table.fields.created') }}</td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <h5>{{ $t('space.business_table.fields.id') }}</h5>
          <router-link :to="{ name: 'business_details', params: { businessId: item.id } }">{{ item.id }}</router-link>
        </td>
        <td>
          <h5>{{ $t('space.business_table.fields.name') }}</h5>
          {{ item.name }}
        </td>
        <td>
          <h5>{{ $t('space.business_table.fields.address') }}</h5>
          {{ fullAddress(item) }}
        </td>
        <td>
          <h5>{{ $t('space.business_table.fields.contact') }}</h5>
          {{ item.contact.name }}
        </td>
        <td>
          <h5>{{ $t('space.business_table.fields.bank_account') }}</h5>
          <bank-account-view v-if="item.bank_account_key" :bank-account-key="item.bank_account_key"></bank-account-view>
          <label-none v-else>{{ $t('system.na') }}</label-none>
        </td>
        <td>
          <h5>{{ $t('space.business_table.fields.created') }}</h5>
          {{ item.creation_date | formatShortDateInverted | orNotAvailable }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import BankAccountView from '@/components/bank_account/shared/BankAccountView.vue';

export default {
  name: 'space-business-table',
  props: {
    item: {
      type: Object,
    },
  },
  components: { BankAccountView },
  methods: {
    fullAddress(item) {
      return `${item.address.street}, ${item.address.city}, ${item.address.state}, ${item.address.country}, ${item.address.zip}`;
    },
  },
};
</script>
