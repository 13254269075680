<template>
  <card :title="$t('user.accesses')" icon="">
    <template slot="headerZoneRight">
      <label-text
        class="text-sm-center"
        :label="$t('user.details.account_accesses_section.count')"
        :value="accountAccesses ? accountAccesses.length.toString() : '0'"
      ></label-text>
    </template>
    <template slot="content">
      <user-accesses-table />
    </template>
  </card>
</template>

<script>
import { security, disableWithError, actionErrorTrackable } from '@/mixins';
import UserAccessesTable from './UserAccessesTable';
import { mapGetters } from 'vuex';

export default {
  name: 'user-accesses',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { UserAccessesTable },
  computed: {
    ...mapGetters('account', ['account', 'accountAccesses', 'businessAccesses', 'offerAccesses']),
    ...mapGetters('ui', ['currentLocale']),
  },
  watch: {
    async account() {
      await this.fetchAccountAccesses();
    },
  },
  methods: {
    async fetchAccountAccesses() {
      await this.executeAction({ action: 'account/listAccountAccesses' }, this.account.contact.email.value);
    },
  },
};
</script>
