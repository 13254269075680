<template>
  <v-card>
    <v-card-title class="modal-card--card-title text-xs-center">
      <v-layout justify-center>
        <v-icon color="primary" data-fa-transform="down-1"></v-icon>
        <h3 class="primary--text">{{ $t('application.create_card_action.title') }}</h3>
      </v-layout>
    </v-card-title>

    <v-card-text>
      <error context="retryKycChcck" />
      <p class="modal-confirm--text text-xs-center">{{ $t('application.create_card_action.text') }}</p>
    </v-card-text>

    <v-card-actions>
      <v-layout justify-center>
        <button-modal-cancel :text="$t('application.create_card_action.button_cancel')" @click="cancel"></button-modal-cancel>
        <button-error @click="submit()" :text="$t('application.create_card_action.button_save')" :loading="working"></button-error>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';

export default {
  name: 'create-card',
  $_veeValidate: {
    validator: 'new',
  },
  mixins: [actionErrorTrackable, security],
  props: {
    applicationId: {
      type: String,
      required: true,
    },
  },
  methods: {
    clear() {
      this.$validator.reset();
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    success() {
      this.clear();
      this.$emit('action');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        await this.executeAction(
          {
            action: 'application/createCard',
            success: this.success,
          },
          { card_application_id: this.applicationId },
        );
        this.$emit('close');
      }
    },
  },
};
</script>
