<template>
  <v-flex class="view-destination-form">
    <h1 hidden>{{ $t('destination.form.title') }}</h1>
    <v-card>
      <form @submit.prevent="submit" class="form-basic form-search">
        <v-layout align-center wrap class="form-search--main-wrap-v2">
          <v-flex class="form-search--inputs-zone-v2">
            <form-warning-search
              :autofocus="true"
              :searched="searched"
              :hint-message="$t('destination.form.total_results_message')"
              class="destination--search-field"
              :label="$t('destination.form.placeholder')"
              v-model="keyword"
              data-vv-name="search"
              v-validate="'max:50'"
              :max-length="50"
              :error-messages="errors.collect('search')"
            ></form-warning-search>
            <button-primary class="destination--btn-search" :text="$t('destination.form.submit')" :loading="working" @click="submit" type="submit" />
          </v-flex>

          <v-flex class="form-search--actions-zone-v2">
            <button-primary
              data-test="destination--btn-add"
              id="destination--btn-add"
              class="destination-form--btn-add"
              :loading="working"
              :text="$t('destination.form.add')"
              @click="openCreateDestinationModal"
            ></button-primary>
          </v-flex>
        </v-layout>
      </form>
    </v-card>

    <v-flex class="data-section">
      <alert-warning v-if="dataReady && destinations.length === 0" :text="$t('destination.no_destination_found')"></alert-warning>
      <card v-if="dataReady && destinations.length > 0" icon="fal fa-map-marked-alt" :title="`${$t('destination.form.data_section.title')}`">
        <template slot="headerZoneRight">
          <label-text
            class="text-sm-center"
            :label="$t('destination.form.data_section.destinations_count')"
            :value="listItemCount || '0'"
          ></label-text>
        </template>
        <template slot="content">
          <destinations-table :items="destinations"></destinations-table>
        </template>
      </card>
    </v-flex>
    <modal-action :show-modal="showCreateDestinationModal"
      ><create-destination-action
        v-if="showCreateDestinationModal"
        @close="closeCreateDestinationModal"
        @created="createDestinationSuccess"
      ></create-destination-action
    ></modal-action>
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable, security } from '@/mixins';
import DestinationsTable from './DestinationsTable';
import CreateDestinationAction from '../actions/CreateDestinationAction';
import _ from 'lodash';
import Vue from 'vue';
import { ignoreCaseAndAccentsRegex } from '@/utils/string-utils';

export default {
  name: 'search-destination-form',
  mixins: [actionErrorTrackable, security],
  components: { DestinationsTable, CreateDestinationAction },
  $_veeValidate: {
    validator: 'new',
  },
  beforeDestroy() {
    this.executeAction({ action: 'destination/clearSearchDestinations' });
  },
  data() {
    return {
      page: 1,
      dataReady: false,
      keyword: '',
      searched: '',
      showCreateDestinationModal: false,
    };
  },
  async created() {
    this.page = this.listPageNumber;
    this.keyword = this.listKeyword;

    if (this.routeHasQueryParams()) {
      this.page = 1;
    }
    if (this.$route.query.keyword) {
      this.keyword = this.$route.query.keyword;
    }

    this.routeHasQueryParams() ? this.submit(null, false, this.page) : this.submit(null, true, this.page);
  },
  computed: {
    ...mapGetters('destination', ['destinations', 'listKeyword', 'listPageNumber', 'listItemCount']),
  },
  methods: {
    async clear() {
      this.searched = '';
      this.keyword = '';
      this.$validator.reset();
    },
    routeHasQueryParams() {
      return this.$route.query.keyword || this.$route.query.business_id;
    },
    openCreateDestinationModal() {
      this.showCreateDestinationModal = true;
    },
    closeCreateDestinationModal() {
      this.showCreateDestinationModal = false;
    },
    buildPayloadQueryString() {
      const payload = {};
      if (this.keyword) {
        payload.keyword = this.keyword;
      }

      return payload;
    },
    async submit(event, forceSearch = false, page = 1) {
      let validationComplete;
      if (forceSearch !== true) {
        validationComplete = await this.$validator.validateAll();
      }

      if (validationComplete || forceSearch) {
        this.$router.replace({ query: this.buildPayloadQueryString() });
        await this.clearAllError();

        try {
          this.dataReady = false;
          const search = this.buildPayloadQueryString();
          search.page = page;
          await this.executeAction({ action: 'destination/listDestinations' }, search);
        } finally {
          this.dataReady = true;
        }
      }
    },
    async createDestinationSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'destination.create_action.success' });
      this.closeCreateDestinationModal();
    },
  },
};
</script>
