<template>
  <card-modal
    icon=""
    :title="$t('application.create_change_application_request_action.title')"
    context="create_change_application_request"
    @cancel="cancel"
    cancel_text="application.create_change_application_request_action.button_close"
  >
    <div slot="content">
      <v-alert :value="isPending" color="warning" icon="priority_high" outline data-test="pending-item-warning">
        {{ $t('application.create_change_application_request_action.review_already_exists') }}
      </v-alert>

      <div v-if="isActive" data-test="change-request-url">
        <label-text :label="$t('application.create_change_application_request_action.url')">
          <p slot="labelTextLink">
            <v-layout row>
              <span class="change-request-link">
                <a href="javascript:void(0)" v-clipboard:copy="changeApplicationRequestUrl">{{ changeApplicationRequestUrl }}</a>
              </span>

              <button class="clipboard" v-clipboard:copy="changeApplicationRequestUrl">
                <v-icon small>file_copy</v-icon>
              </button>
            </v-layout>
          </p>
        </label-text>
      </div>
    </div>
  </card-modal>
</template>

<script>
import _get from 'lodash/get';
import { mapGetters } from 'vuex';
import { actionErrorTrackable } from '@/mixins';

export default {
  name: 'create-change-request-url',

  mixins: [actionErrorTrackable],

  props: {
    applicationId: {
      type: String,
      required: true,
    },
  },

  created() {
    this.executeAction({ action: 'selfserve/getCurrentChangeApplicationRequest' }, this.applicationId);
  },

  computed: {
    ...mapGetters('selfserve', ['changeApplicationRequest', 'changeApplicationRequestUrl']),

    isPending() {
      return _get(this.changeApplicationRequest, 'status') === 'pending';
    },

    isActive() {
      return _get(this.changeApplicationRequest, 'status') === 'active';
    },
  },

  methods: {
    cancel() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.btn-create {
  margin: 10px 0;
}

.change-request-link {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
