<template>
  <card :title="$t('purse.operations.title')" icon="">
    <template slot="content">
      <purse-operations-table v-if="dataReady && purseOperations.length" :items="purseOperations"></purse-operations-table>
      <label-none v-if="purseOperations.length === 0">{{ $t('system.na') }}</label-none>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';
import PurseOperationsTable from './PurseOperationsTable.vue';
import PurseOperationType from '@/enums/purse-operation-type';

export default {
  name: 'purse-operations',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { PurseOperationsTable },
  data() {
    return {
      dataReady: false,
    };
  },
  computed: {
    ...mapGetters('purse', ['purseOperations', 'purse']),
    ...mapGetters('ui', ['currentLocale']),
  },
  async created() {
    if (this.purse.id) {
      await this.refreshPurseOperationsList(1);
    }
  },
  watch: {
    async purse() {
      await this.refreshPurseOperationsList(1);
    },
  },
  methods: {
    async refreshPurseOperationsList(page) {
      await this.executeAction(
        {
          action: 'purse/listPurseOperations',
          success: () => {
            this.dataReady = true;
          },
        },
        {
          id: this.purse.id,
          page,
          excluded_type: PurseOperationType.excludedByDefault,
        },
      );
    },
  },
};
</script>
