<template>
  <card :title="$t('instant_card_projects.details.gift_certificates_section.title')" icon="">
    <template slot="headerZoneRight">
      <label-text
        class="text-xs-center"
        :label="`${$t('instant_card_projects.details.gift_certificates_section.count')}`"
        :value="projectGiftCertificatesItemCount || '0'"
      ></label-text>
      <div v-if="showAddGiftCertificateFeature" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text
        class="text-xs-center"
        :label="`${$t('instant_card_projects.details.gift_certificates_section.total_amount')}`"
        :value="(project.total_amount || 0) | dollarSignI18n"
      ></label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text v-if="showAddGiftCertificateFeature" :label="`${$t('instant_card_projects.details.gift_certificates_section.action')}`" value="">
        <link-secondary-action slot="labelTextLink" @click="openAddGiftCertificateModal" :disabled="!this.canAddGiftCertificateOffer">
          {{ $t('instant_card_projects.details.gift_certificates_section.create') }}
        </link-secondary-action>
      </label-text>
    </template>
    <template slot="content">
      <modal-action :show-modal="showAddGiftCertificateModal">
        <add-project-gift-certificate-action
          v-if="showAddGiftCertificateModal"
          :project-id="project.id"
          :partner-id="project.partner_id"
          :project-structure="project.structure"
          @close="closeAddGiftCertificateModal"
          @created="addGiftCertificateSuccess"
        ></add-project-gift-certificate-action>
      </modal-action>
      <project-gift-certificates-table
        v-if="projectGiftCertificatesItemCount"
        :items="projectGiftCertificates"
        :project="project"
        @deactivate_gift_certificate_action="fetchGiftCertificates"
      ></project-gift-certificates-table>
      <label-none v-if="projectGiftCertificatesItemCount === 0">{{ $t('card.comments_section.no_found') }}</label-none>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';
import ProjectGiftCertificatesTable from '@/components/instant_card_project/detail/ProjectGiftCertificatesTable';
import AddProjectGiftCertificateAction from '@/components/instant_card_project/actions/AddProjectGiftCertificateAction';

export default {
  name: 'gift-certificates',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { ProjectGiftCertificatesTable, AddProjectGiftCertificateAction },
  data() {
    return {
      pagination: 1,
      giftCertificates: null,
      showAddGiftCertificateModal: false,
    };
  },
  async created() {
    await this.fetchGiftCertificates();
  },
  watch: {
    async project() {
      return this.fetchGiftCertificates();
    },
    projectGiftCertificatesPageNumber() {
      this.pagination = this.projectGiftCertificatesPageNumber;
    },
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'instantCardProject/changeProjectGiftCertificatesPage',
        },
        {
          id: this.project.id,
          page: newValue,
        },
      );
    },
  },
  computed: {
    ...mapGetters('instantCardProject', [
      'project',
      'projectGiftCertificates',
      'projectGiftCertificatesAvailable',
      'projectGiftCertificatesPageCount',
      'projectGiftCertificatesPageNumber',
      'projectGiftCertificatesItemCount',
    ]),

    ...mapGetters('ui', ['currentLocale']),

    showAddGiftCertificateFeature() {
      return this.canManageOffer() && this.projectGiftCertificatesAvailable === 0;
    },
    canAddGiftCertificateOffer() {
      const maxNumber = this.project.structure === 'single' ? 1 : 100;
      return this.project.total_active_offers < maxNumber;
    },
  },
  methods: {
    openAddGiftCertificateModal() {
      this.showAddGiftCertificateModal = true;
    },
    closeAddGiftCertificateModal() {
      this.showAddGiftCertificateModal = false;
    },
    openDeactivateGiftCertificateModal() {
      this.showDeactivateGiftCertificateModal = true;
    },
    closeDeactivateGiftCertificateModal() {
      this.showDeactivateGiftCertificateModal = false;
    },
    async addGiftCertificateSuccess() {
      await this.executeAction(
        { action: 'ui/showSuccessSnackBar' },
        { text: 'instant_card_projects.details.gift_certificates_section.add_action.success' },
      );
      this.closeAddGiftCertificateModal();
    },
    async deactivateGiftCertificateSuccess() {
      this.showDeactivateGiftCertificateModal = false;
      await this.executeAction(
        {
          action: 'store/unlinkBusinessFromStore',
          name: 'deactivateGiftCertificate',
        },
        { id: this.store.id },
      );
      await this.fetchBusiness();
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'store.details.business_section.unlink_action.success' });
    },
    async fetchGiftCertificates() {
      await this.executeAction(
        { action: 'instantCardProject/listProjectGiftCertificates' },
        { id: this.project.id, page: this.projectGiftCertificatesPageNumber },
      );
    },
  },
};
</script>
