'use strict';

export default (Joi) => {
  return {
    base: Joi.string(),
    name: 'string',
    language: {
      phone: 'not a valid phone for "CA".  The valid format is "1-999-999-9999"',
    },
    rules: [
      {
        name: 'phone',
        validate(params, value, state, options) {
          if (!/^1-\d{3}-\d{3}-\d{4}$/.test(value)) {
            return this.createError('string.phone', { v: value }, state, options);
          }

          return value;
        },
      },
    ],
  };
};
