<template>
  <card :title="$t('user.card_applications')" icon="">
    <template slot="headerZoneRight">
      <label-text
        class="text-sm-center"
        :label="$t('user.details.card_applications_section.count')"
        :value="accountCardApplicationsItemCount.toString()"
      ></label-text>
    </template>
    <template slot="content">
      <user-card-applications-table v-if="accountCardApplications.length" :items="accountCardApplications"></user-card-applications-table>
      <label-none v-if="accountCardApplications.length === 0">{{ $t('user.details.card_applications_section.no_found') }}</label-none>
      <div class="text-xs-center">
        <v-pagination
          v-if="accountCardApplicationsPageCount > 1"
          class="apps-table--pagination"
          v-model="pagination"
          :length="accountCardApplicationsPageCount"
        ></v-pagination>
      </div>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import UserCardApplicationsTable from './UserCardApplicationsTable';
import { mapGetters } from 'vuex';

export default {
  name: 'user-card-applications',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { UserCardApplicationsTable },
  data() {
    return {
      pagination: 1,
    };
  },
  computed: {
    ...mapGetters('account', [
      'account',
      'accountCardApplications',
      'accountCardApplicationsPageCount',
      'accountCardApplicationsPageNumber',
      'accountCardApplicationsItemCount',
    ]),
    ...mapGetters('ui', ['currentLocale']),
  },
  watch: {
    async account() {
      await this.fetchCardApplications();
    },
    accountCardApplicationsPageNumber() {
      this.pagination = this.accountCardApplicationsPageNumber;
    },
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'account/changeAccountCardApplicationPage',
          success: this.success,
        },
        {
          id: this.account.id,
          page: newValue,
        },
      );
    },
  },
  methods: {
    async fetchCardApplications() {
      await this.executeAction(
        { action: 'account/listCardApplicationsByAccountId' },
        { id: this.account.id, page: this.accountCardApplicationsPageNumber },
      );
    },
  },
};
</script>
