<template>
  <card :title="$t('offers.details.access_section.title')" icon="">
    <template slot="headerZoneRight">
      <label-text
        class="text-sm-center"
        :label="$t('offers.details.access_section.access_count')"
        :value="offerAccesses && offerAccesses.length ? offerAccesses.length : '0'"
      ></label-text>
      <div v-if="showAddOfferAccessFeature()" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text v-if="showAddOfferAccessFeature()" :label="`${$t('offers.details.access_section.action')}`" value="3">
        <link-secondary-action slot="labelTextLink" @click="openAddOfferAccessModal">
          {{ $t('offers.details.access_section.add_access') }}
        </link-secondary-action>
      </label-text>
    </template>
    <template slot="content">
      <offers-access-table
        v-if="offerAccesses.length > 0"
        @offers_access_remove_action="openRemoveOfferAccessModal"
        :items="offerAccesses"
      ></offers-access-table>
      <label-none v-else>{{ $t('system.none') }}</label-none>
      <modal-action :show-modal="showAddOfferAccessModal">
        <add-offers-access-action :offer-obj="offer" @close="closeAddOfferAccessModal" @created="addOfferAccessSuccess" />
      </modal-action>
      <modal-confirm
        v-if="offersAccessIdToRemove"
        :show-modal="showRemoveOfferAccessModal"
        :title="$t(`offers.details.access_section.remove_action.confirm.title`)"
        context="removeOfferEmailAccess"
        :text="
          $t(`offers.details.access_section.remove_action.confirm.text`, {
            offersAccessEmail: `${offersAccessToRemove.email}`,
          })
        "
        :cancel_text="`offers.details.access_section.remove_action.confirm.button_cancel`"
        :submit_text="`offers.details.access_section.remove_action.confirm.button_submit`"
        :submit_working="working"
        @submit="removeOfferAccess"
        @close="closeRemoveOfferAccessModal"
      ></modal-confirm>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import OffersAccessTable from './OffersAccessTable';
import AddOffersAccessAction from '../actions/AddOffersAccessAction';
import { mapGetters } from 'vuex';

export default {
  name: 'offers-access',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { OffersAccessTable, AddOffersAccessAction },
  data() {
    return {
      showAddOfferAccessModal: false,
      showRemoveOfferAccessModal: false,
      offersAccessIdToRemove: null,
      offersAccessToRemove: {},
    };
  },
  computed: {
    ...mapGetters('offers', ['offer', 'offerAccesses']),
    ...mapGetters('ui', ['currentLocale']),
  },
  watch: {
    async offer() {
      await this.fetchOfferAccesses();
    },
  },
  methods: {
    async fetchOfferAccesses() {
      await this.executeAction({ action: 'offers/listOfferAccesses' }, this.offer.id);
    },
    openAddOfferAccessModal() {
      this.showAddOfferAccessModal = true;
    },
    closeAddOfferAccessModal() {
      this.showAddOfferAccessModal = false;
    },
    async addOfferAccessSuccess() {
      await this.fetchOfferAccesses();
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'offers.details.access_section.create_action.success' });
      this.closeAddOfferAccessModal();
    },
    showAddOfferAccessFeature() {
      return this.canManageOffer();
    },
    openRemoveOfferAccessModal(id) {
      this.offersAccessIdToRemove = id;

      (this.offersAccessToRemove = this.offerAccesses.find((l) => l.id === id)), (this.showRemoveOfferAccessModal = true);
    },
    closeRemoveOfferAccessModal() {
      this.offersAccessIdToRemove = null;
      this.offersAccessToRemove = {};
      this.showRemoveOfferAccessModal = false;
    },
    async removeOfferAccess() {
      await this.executeAction(
        {
          action: 'offers/removeOfferEmailAccess',
          name: 'removeOfferEmailAccess',
          success: this.removeOfferAccessSuccess,
        },
        { offer_id: this.offer.id, id: this.offersAccessIdToRemove },
      );
    },
    async removeOfferAccessSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: `offers.details.access_section.remove_action.success` });
      this.closeRemoveOfferAccessModal();
    },
  },
};
</script>
