<template>
  <card-modal
    icon=""
    :title="$t('offers.offers_tracking_links.add_modal.title')"
    context="AddOfferTrackingLink"
    @submit="submit"
    @cancel="cancel"
    :submit_working="working"
    cancel_text="offers.offers_tracking_links.add_modal.cancel"
    submit_text="offers.offers_tracking_links.add_modal.submit"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-text-field
          autofocus
          :label="$t('offers.offers_tracking_links.add_modal.fields.name') + ' *'"
          name="name"
          v-model="name"
          v-validate="{ required: true }"
          :error-messages="errors.collect('name')"
          data-vv-name="name"
          maxlength="50"
          :data-vv-as="$t('offers.offers_tracking_links.add_modal.fields.name')"
        />

        <h-autocomplete
          v-model="business_id"
          :label="$t('offers.offers_tracking_links.add_modal.fields.business_id')"
          :error-messages="errors.collect('business_id')"
          name="business_id"
          data-vv-name="business_id"
          data-vv-as="business_id"
          search-action="business/autocompleteSearch"
          no-filter
        />

        <v-select
          :items="offerTrackingLinkTypeOptions"
          name="type"
          v-validate="'required'"
          v-model="type"
          :label="$t('offers.offers_tracking_links.add_modal.fields.type') + '*'"
          :error-messages="errors.collect('type')"
          data-vv-name="type"
          :data-vv-as="$t('offers.offers_tracking_links.add_modal.fields.type')"
          item-text="name"
          item-value="id"
        />
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'add-offer-tracking-link',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    offerId: {
      type: String,
    },
    corporateEnabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      name: null,
      business_id: null,
      type: null,
    };
  },
  computed: {
    ...mapGetters('offers', ['offerTrackingLinkTypes']),
    offerTrackingLinkTypeOptions() {
      const types = _.map(this.offerTrackingLinkTypes, (item) => {
        if (!this.corporateEnabled && item === 'corporate') {
          return null;
        }

        return {
          name: this.$t(`offers.offers_tracking_link_types.${item}`),
          id: item,
        };
      });

      return _.compact(types);
    },
  },
  methods: {
    clear() {
      this.name = null;
      this.business_id = null;
      this.type = null;
      this.$validator.reset();
    },
    success() {
      this.clear();
      this.$emit('success');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        const payload = { offerId: this.offerId, name: this.name, redirect_type: this.type };
        if (this.business_id && this.business_id !== '') {
          payload.business_id = this.business_id;
        }

        await this.executeAction(
          {
            action: 'offers/addOfferTrackingLink',
            name: 'AddOfferTrackingLink',
            success: this.success,
          },
          payload,
        );
      }
    },
  },
};
</script>
