<template>
  <table class="responsive-table" id="offer-activation-codes-table">
    <thead>
      <tr>
        <td>{{ $t('offers.activation_code_batches_table.fields.id') }}</td>
        <td>{{ $t('offers.activation_code_batches_table.fields.reference_id') }}</td>
        <td>{{ $t('offers.activation_code_batches_table.fields.name') }}</td>
        <td v-if="exclude !== 'business'">{{ $t('offers.activation_code_batches_table.fields.business') }}</td>
        <td v-if="exclude !== 'offer'">{{ $t('offers.activation_code_batches_table.fields.offer') }}</td>
        <td>{{ $t('offers.activation_code_batches_table.fields.quantity') }}</td>
        <td>{{ $t('offers.activation_code_batches_table.fields.payment_amount') }}</td>
        <td>{{ $t('offers.activation_code_batches_table.fields.bonus_amount') }}</td>
        <td>{{ $t('offers.activation_code_batches_table.fields.creation_date') }}</td>
        <td>{{ $t('offers.activation_code_batches_table.fields.action') }}</td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in items" :key="index">
        <td>
          <h5>{{ $t('offers.activation_code_batches_table.fields.id') }}:</h5>
          <router-link :to="{ name: 'activation_code_batch_details', params: { activationCodeBatchId: item.id } }">
            {{ item.id }}
          </router-link>
        </td>
        <td>
          <h5>{{ $t('offers.activation_code_batches_table.fields.reference_id') }}:</h5>
          <span v-if="item.reference_id">{{ item.reference_id }}</span>
          <label-none v-else>{{ $t('system.na') }}</label-none>
        </td>
        <td>
          <h5>{{ $t('offers.activation_code_batches_table.fields.name') }}:</h5>
          {{ item.name }}
        </td>
        <td v-if="exclude !== 'business'">
          <h5>{{ $t('offers.activation_code_batches_table.fields.business') }}:</h5>
          <router-link v-if="item.business_id" :to="{ name: 'business_details', params: { businessId: item.business_id } }">
            {{ `${item.business_name} (${item.business_id})` }}
          </router-link>
          <label-none v-else>{{ $t('system.na') }}</label-none>
        </td>
        <td v-if="exclude !== 'offer'">
          <h5>{{ $t('offers.activation_code_batches_table.fields.offer') }}:</h5>
          <router-link v-if="item.offer.label[currentLocale]" :to="{ name: 'offers_details', params: { offerId: item.offer_id } }">
            {{ `${item.offer.label[currentLocale]} (${item.offer_id})` }}
          </router-link>
          <label-none v-else>{{ $t('system.na') }}</label-none>
        </td>
        <td align="right">
          <h5>{{ $t('offers.activation_code_batches_table.fields.quantity') }}:</h5>
          <span>{{ item.quantity }}</span>
        </td>
        <td align="right">
          <h5>{{ $t('offers.activation_code_batches_table.fields.payment_amount') }}:</h5>
          <span>{{ item.payment_amount | currency | dollarSignI18n }}</span>
        </td>
        <td align="right">
          <h5>{{ $t('offers.activation_code_batches_table.fields.bonus_amount') }}:</h5>
          <span>{{ item.bonus_amount | currency | dollarSignI18n }}</span>
        </td>
        <td>
          <h5>{{ $t('offers.activation_code_batches_table.fields.creation_date') }}:</h5>
          <span :class="!item.creation_date ? 'info-not-available' : ''">{{ item.creation_date | formatShortDateInverted | orNotAvailable }}</span>
        </td>
        <td>
          <h5>{{ $t('offers.activation_code_batches_table.fields.edit') }}</h5>
          <link-secondary-action slot="labelTextLink" @click="openUpdateActivationCodeBatchModal(item.id)">{{
            $t('offers.activation_code_batches_table.fields.edit')
          }}</link-secondary-action>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'shared-activation-code-batches-table',
  mixins: [actionErrorTrackable, security],
  props: {
    items: {
      type: Array,
      required: true,
    },
    exclude: {
      type: String, // Acceptable values: business, branding
      required: false,
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    fullAddress(item) {
      if (item.location) {
        return `${item.location.address.street}, ${item.location.address.city}, ${item.location.address.state}, ${item.location.address.country}, ${item.location.address.zip}`;
      }
      return '';
    },
    openUpdateActivationCodeBatchModal(id) {
      this.$emit('update_activation_code_batch', id);
    },
  },
};
</script>
