<template>
  <div class="workstation-name--wrap">
    <span class="workstation-name--text" v-if="workstation_id">{{ name }}</span>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'workstation-name',
  props: {
    workstation_id: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      name: '?',
    };
  },
  methods: {
    ...mapActions('partnerSecurity', ['getWorkstation']),
  },
  watch: {
    workstation_id: function(newVal) {
      // NOTE (SG) : Don't use async here, we want to load the name asynchronously and
      // don't want to stop the creation of components until the value as been get.
      this.getWorkstation(newVal)
        .then((workstation) => (this.name = workstation ? workstation.name : '?'))
        .catch((e) => console.log(e.stack || e)); // eslint-disable-line no-console
    },
  },
  created() {
    // NOTE (SG) : Don't use async here, we want to load the name asynchronously and
    // don't want to stop the creation of components until the value as been get.
    this.getWorkstation(this.workstation_id)
      .then((workstation) => (this.name = workstation ? workstation.name : '?'))
      .catch((e) => console.log(e.stack || e)); // eslint-disable-line no-console
  },
};
</script>
