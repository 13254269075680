<template>
  <table class="responsive-table" id="user-card-applications-table">
    <thead>
      <tr>
        <td>{{ $t('user.cards_table.fields.public_token') }}</td>
        <td>{{ $t('user.cards_table.fields.card_sponsor') }}</td>
        <td>{{ $t('user.cards_table.fields.cardholder_id') }}</td>
        <td>{{ $t('user.cards_table.fields.date_issued') }}</td>
        <td>{{ $t('user.cards_table.fields.status') }}</td>
        <td>{{ $t('user.cards_table.fields.type') }}</td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in items" :key="index">
        <td>
          <h5>{{ $t('user.cards_table.fields.public_token') }}:</h5>
          <link-secondary-route :can-access="canViewCard()" :route-name="'card'" :route-params="{ cardPublicToken: item.public_token }">
            {{ item.public_token }}
          </link-secondary-route>
        </td>
        <td>
          <h5>{{ $t('user.cards_table.fields.card_sponsor') }}:</h5>
          <span>{{ item.partner_id }}</span>
        </td>
        <td>
          <h5>{{ $t('user.cards_table.fields.cardholder_id') }}:</h5>
          <span>{{ item.cardholder_id }}</span>
        </td>
        <td>
          <h5>{{ $t('user.cards_table.fields.date_issued') }}:</h5>
          <span>{{ item.date_issued | formatShortDateInverted | orNotAvailable }}</span>
        </td>
        <td>
          <h5>{{ $t('user.cards_table.fields.status') }}:</h5>
          <span :class="`status-${item.status}`">{{ $t(`code.card.status.${item.status}`) }}</span>
        </td>
        <td>
          <h5>{{ $t('user.cards_table.fields.type') }}:</h5>
          <span>{{ item.bulk ? $t('user.cards_table.types.bulk') : $t('user.cards_table.types.single') }}</span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';

export default {
  name: 'user-cards-table',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: {},
  props: {
    items: {
      type: Array,
    },
  },
};
</script>
