<template>
  <card :title="$t('publishers.details.publishers_information_section.title')" icon="fal fa-chart-network" id="publishers-information">
    <template slot="headerZoneRight">
      <label-text :label="`${$t('publishers.details.publishers_information_section.id')}`" :value="publisher.id">
        <p slot="labelTextLink" class="label-text--value">
          <v-layout row>
            {{ publisher.id }}
            <button class="clipboard" v-clipboard:copy="publisher.id ? publisher.id : ''">
              <v-icon small>file_copy</v-icon>
            </button>
          </v-layout>
        </p>
      </label-text>
      <div v-if="showUpdatePublishersFeature()" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="`${$t('publishers.details.publishers_information_section.name')}`" :value="publisher.name"></label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="`${$t('publishers.details.publishers_information_section.system_status.pad')}`" :value="publisher.system_status.pad">
        <ready-status-view slot="labelTextLink" :status="publisher.system_status.pad" />
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text
        :label="`${$t('publishers.details.publishers_information_section.system_status.display')}`"
        :value="publisher.system_status.display"
      >
        <ready-status-view slot="labelTextLink" :status="publisher.system_status.display" />
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text
        :label="`${$t('publishers.details.publishers_information_section.system_status.transactional')}`"
        :value="publisher.system_status.transactional"
      >
        <ready-status-view slot="labelTextLink" :status="publisher.system_status.transactional" />
      </label-text>
      <div v-if="showUpdatePublishersFeature()" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text v-if="showUpdatePublishersFeature()" :label="`${$t('publishers.details.publishers_information_section.action')}`" value="3">
        <link-secondary-action slot="labelTextLink" @click="openUpdatePublishersModal">
          {{ $t('publishers.details.publishers_information_section.update_publishers') }}</link-secondary-action
        >
      </label-text>
    </template>
    <template slot="content">
      <modal-action :show-modal="showUpdatePublishersModal"
        ><update-publishers-action
          v-if="showUpdatePublishersModal"
          :publisher="publisher"
          @close="closeUpdatePublishersModal"
          @updated="updatePublishersSuccess"
        ></update-publishers-action
      ></modal-action>
      <v-layout row> </v-layout>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import UpdatePublishersAction from '../actions/UpdatePublishersAction';
import { mapGetters } from 'vuex';

export default {
  name: 'publishers-information',
  components: { UpdatePublishersAction },
  mixins: [security, actionErrorTrackable, disableWithError],
  data() {
    return {
      showUpdatePublishersModal: false,
    };
  },
  computed: {
    ...mapGetters('publishers', ['publisher']),
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    openUpdatePublishersModal() {
      this.showUpdatePublishersModal = true;
    },
    closeUpdatePublishersModal() {
      this.showUpdatePublishersModal = false;
    },
    async updatePublishersSuccess() {
      await this.executeAction(
        { action: 'ui/showSuccessSnackBar' },
        { text: 'publishers.details.publishers_information_section.update_action.success' },
      );
      this.closeUpdatePublishersModal();
    },
    showUpdatePublishersFeature() {
      return this.canManageOffer();
    },
  },
};
</script>
