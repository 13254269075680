<template>
  <card-modal
    icon=""
    :title="$t('space.details.onboard_action.title')"
    context="onboard"
    @submit="submit"
    @cancel="cancel"
    cancel_text="space.details.onboard_action.button_cancel"
    submit_text="space.details.onboard_action.button_save"
  >
    <form @submit.prevent="submit" slot="content">
      <h4>{{ $t('space.details.onboard_action.clerk_title') }}</h4>
      <v-text-field
        v-model="clerk_name"
        :label="$t('space.details.onboard_action.clerk_name') + '*'"
        name="clerk_name"
        v-validate="'required'"
        :error-messages="errors.collect('clerk_name')"
        data-vv-name="clerk_name"
        maxlength="50"
        :data-vv-as="$t('space.details.onboard_action.clerk_name')"
        data-test="onboard--clerk_name"
      ></v-text-field>
      <v-text-field
        :label="$t('space.details.onboard_action.clerk_email') + ' *'"
        name="clerk_email"
        v-model="clerk_email"
        v-validate="{ required: true, email: true }"
        :error-messages="errors.collect('clerk_email')"
        data-vv-name="clerk_email"
        maxlength="255"
        :data-vv-as="$t('space.details.onboard_action.clerk_email')"
      ></v-text-field>
      <v-select
        name="clerk_language"
        v-model="clerk_language"
        :error-messages="errors.collect('clerk_language')"
        data-vv-name="clerk_language"
        :data-vv-as="$t('space.details.onboard_action.clerk_language')"
        v-validate="{ required: true }"
        :items="languageOptions"
        item-text="name"
        item-value="abbr"
        :label="$t('space.details.onboard_action.clerk_language') + ' *'"
      ></v-select>
      <v-text-field
        v-model="clerk_username"
        :label="$t('space.details.onboard_action.clerk_username') + '*'"
        name="clerk_username"
        v-validate="'required'"
        :error-messages="errors.collect('clerk_username')"
        data-vv-name="clerk_username"
        maxlength="50"
        :data-vv-as="$t('space.details.onboard_action.clerk_username')"
        data-test="onboard--clerk_username"
      ></v-text-field>
      <v-text-field
        v-model="clerk_passcode"
        :label="$t('space.details.onboard_action.clerk_passcode') + '*'"
        name="clerk_passcode"
        v-validate="'required'"
        hint="0000"
        :error-messages="errors.collect('clerk_passcode')"
        data-vv-name="clerk_passcode"
        maxlength="4"
        v-maska="'####'"
        :data-vv-as="$t('space.details.onboard_action.clerk_passcode')"
        data-test="onboard--clerk_passcode"
      ></v-text-field>
      <v-select
        v-if="activeSpacePartnerEstablishmentOptions.length > 0"
        name="clerk_establishments"
        v-model="clerk_establishment_ids"
        :error-messages="errors.collect('clerk_establishments')"
        data-vv-name="clerk_establishments"
        :data-vv-as="$t(`space.details.onboard_action.clerk_establishments`)"
        :items="activeSpacePartnerEstablishmentOptions"
        item-text="label"
        item-value="value"
        :label="$t(`space.details.onboard_action.clerk_establishments`)"
        multiple
      ></v-select>

      <h4>{{ $t('space.details.onboard_action.workstation_title') }}</h4>
      <v-text-field
        v-model="workstation_name"
        :label="$t('space.details.onboard_action.workstation_name') + '*'"
        name="workstation_name"
        v-validate="'required'"
        :error-messages="errors.collect('workstation_name')"
        data-vv-name="workstation_name"
        maxlength="50"
        :data-vv-as="$t('space.details.onboard_action.workstation_name')"
        data-test="onboard--workstation_name"
      ></v-text-field>

      <v-checkbox v-model="ip_restricted" :label="$t('space.details.onboard_action.ip_restricted')"></v-checkbox>

      <v-select
        v-if="activeSpacePartnerEstablishmentOptions.length > 0"
        name="workstation_establishments"
        v-model="workstation_establishment_ids"
        :error-messages="errors.collect('workstation_establishments')"
        data-vv-name="workstation_establishments"
        :data-vv-as="$t(`space.details.onboard_action.workstation_establishments`)"
        :items="activeSpacePartnerEstablishmentOptions"
        item-text="label"
        item-value="value"
        :label="$t(`space.details.onboard_action.workstation_establishments`)"
        multiple
      ></v-select>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'onboard-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    partnerId: {
      type: String,
    },
    spaceId: {
      type: String,
    },
  },
  data() {
    return {
      clerk_email: '',
      clerk_language: '',
      clerk_name: '',
      clerk_username: '',
      clerk_passcode: '',
      workstation_name: '',
      workstation_establishment_ids: [],
      clerk_establishment_ids: [],
      ip_restricted: false,
      approbation_required: false,
      languageOptions: [
        { name: this.$t('language.french'), abbr: 'fr' },
        { name: this.$t('language.english'), abbr: 'en' },
      ],
    };
  },
  computed: {
    ...mapGetters('space', ['activeSpacePartnerEstablishmentOptions']),
  },
  methods: {
    ...mapActions('ui', ['addError', 'clearAllError', 'clearError']),
    async clear() {
      this.clerk_name = '';
      this.workstation_establishment_ids = [];
      this.clerk_establishment_ids = [];
    },
    success(response) {
      this.clear();
      this.$emit('onboarded');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    buildPayload() {
      const payload = {
        space_id: this.spaceId,
        partner_id: this.partnerId,
        clerk: {
          name: this.clerk_name,
          username: this.clerk_username,
          email: this.clerk_email,
          language: this.clerk_language,
          passcode: this.clerk_passcode,
          roles: ['admin', 'manager', 'clerk'],
          establishment_ids: this.clerk_establishment_ids.map((e) => e.id),
        },
        workstation: {
          name: this.workstation_name,
          ip_restricted: this.ip_restricted,
          establishment_ids: this.workstation_establishment_ids.map((e) => e.id),
        },
        workstation_registration: {
          approbation_required: this.approbation_required,
        },
      };

      return payload;
    },
    async submit() {
      let validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        await this.executeAction(
          {
            action: 'partnerSecurity/onboardPartner',
            name: 'onboard',
            success: this.success,
          },
          this.buildPayload(),
        );
      }
    },
  },
};
</script>
