<template>
  <v-menu
    v-if="items.length"
    :close-on-content-click="false"
    open-on-hover
    bottom
    :offset-y="!mobileView"
    :offset-x="mobileView"
    content-class="dropdown-menu--detached-list"
  >
    <template v-slot:activator="{ on }">
      <v-btn :class="{ 'hidden-sm-and-down': !mobileView }" v-ripple="false" flat v-on="on">{{ name }}</v-btn>
    </template>

    <v-list>
      <div v-for="(item, index) in items" :key="index">
        <div v-if="item.items" no-action>
          <!-- For expand functionality, uncomment -->
          <!--          <template v-slot:activator>-->
          <v-list-tile>
            <v-list-tile-content>
              <v-list-tile-title style="color: grey">{{ item.title }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <!--          </template>-->
          <v-list class="dropdown-menu--sub-list">
            <v-list-tile v-for="(subItem, idx) in item.items" :key="idx" :to="subItem.theRoute">
              <v-list-tile-content>
                <v-list-tile-title>{{ subItem.title }}</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </div>

        <template v-if="!item.items">
          <v-list-tile v-if="item.click" :key="index" @click="item.click">
            <v-list-tile-content>
              <v-list-tile-title>{{ item.title }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>

          <v-list-tile v-if="item.theRoute" :key="index" :to="item.theRoute">
            <v-list-tile-content>
              <v-list-tile-title>{{ item.title }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </template>
      </div>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'dropdown-menu',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
    },
    mobileView: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.dropdown-menu--sub-list .v-list__tile__title {
  padding-left: 20px;
}
</style>
