<template>
  <card-modal
    icon=""
    :title="$t(`application.update_applicant_info_action.title`)"
    context="updateCardApplicant"
    @submit="submit"
    @cancel="cancel"
    cancel_text="application.update_applicant_info_action.button_cancel"
    submit_text="application.update_applicant_info_action.button_save"
    :enable_submit="isFormChanged"
    :submit_working="working"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-layout row wrap pad-form-row-elements>
          <v-flex sm6 xs12>
            <v-select
              name="gender"
              v-model="currentCardApplicant.gender"
              :error-messages="errors.collect('gender')"
              data-vv-name="gender"
              :data-vv-as="$t(`application.update_applicant_info_action.fields.gender`)"
              :items="genderOptions"
              item-text="name"
              item-value="abbr"
              :label="$t(`application.update_applicant_info_action.fields.gender`)"
            ></v-select>
          </v-flex>
        </v-layout>
        <v-layout row wrap pad-form-row-elements>
          <v-flex sm4 xs12>
            <v-text-field
              name="first_name"
              v-model="currentCardApplicant.first_name"
              v-validate="'required|max:20'"
              :error-messages="errors.collect('first_name')"
              :label="$t(`application.update_applicant_info_action.fields.first_name`)"
              data-vv-name="first_name"
              :data-vv-as="$t(`application.update_applicant_info_action.fields.first_name`)"
              required
              counter
              maxlength="20"
            ></v-text-field>
          </v-flex>
          <v-flex sm4 xs12>
            <v-text-field
              name="last_name"
              v-model="currentCardApplicant.last_name"
              v-validate="'required|max:20'"
              :error-messages="errors.collect('last_name')"
              :label="$t(`application.update_applicant_info_action.fields.last_name`)"
              data-vv-name="last_name"
              :data-vv-as="$t(`application.update_applicant_info_action.fields.last_name`)"
              required
              counter
              maxlength="20"
            ></v-text-field>
          </v-flex>
          <v-flex sm4 xs12>
            <v-text-field
              name="date_of_birth"
              v-model="currentCardApplicant.date_of_birth"
              v-validate="'required|date_format:YYYY-MM-DD|before:' + maxDateLimit()"
              :error-messages="errors.collect('date_of_birth')"
              :label="$t('application.update_applicant_info_action.fields.date_of_birth')"
              data-vv-name="date_of_birth"
              :data-vv-as="$t(`application.update_applicant_info_action.fields.date_of_birth`)"
              :hint="formattedDateHint"
              persistent-hint
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-text-field
          name="street"
          v-model="currentCardApplicant.address.street"
          v-validate="'required|max:50'"
          :label="$t(`application.update_applicant_info_action.fields.address.street`)"
          :error-messages="errors.collect('street')"
          data-vv-name="street"
          :data-vv-as="$t(`application.update_applicant_info_action.fields.address.street`)"
          required
          counter
          maxlength="50"
        ></v-text-field>

        <v-layout row wrap pad-form-row-elements>
          <v-flex sm4 xs12>
            <v-text-field
              name="city"
              v-model="currentCardApplicant.address.city"
              v-validate="'required|max:50'"
              :label="$t(`application.update_applicant_info_action.fields.address.city`)"
              :error-messages="errors.collect('city')"
              data-vv-name="city"
              :data-vv-as="$t(`application.update_applicant_info_action.fields.address.city`)"
              required
              counter
              maxlength="50"
            ></v-text-field>
          </v-flex>
          <v-flex sm4 xs12>
            <v-select
              name="state"
              v-model="currentCardApplicant.address.state"
              v-validate="'required'"
              :error-messages="errors.collect('state')"
              data-vv-name="state"
              :data-vv-as="$t(`application.update_applicant_info_action.fields.address.state`)"
              :items="stateOptions"
              item-text="name"
              item-value="abbr"
              :label="$t(`application.update_applicant_info_action.fields.address.state`)"
            ></v-select>
          </v-flex>
          <v-flex sm4 xs12>
            <v-select
              name="country"
              v-model="currentCardApplicant.address.country"
              v-validate="'required'"
              :error-messages="errors.collect('country')"
              data-vv-name="country"
              :data-vv-as="$t(`application.update_applicant_info_action.fields.address.country`)"
              :items="countryOptions"
              item-text="name"
              item-value="abbr"
              :label="$t(`application.update_applicant_info_action.fields.address.country`)"
            ></v-select>
          </v-flex>
        </v-layout>

        <v-layout row wrap pad-form-row-elements>
          <v-flex sm4 xs12>
            <v-text-field
              name="zip"
              v-mask-zip
              v-model="currentCardApplicant.address.zip"
              v-validate="{ required: true, regex: /^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z] [0-9][ABCEGHJ-NPRSTV-Z][0-9]$/ }"
              :label="$t(`application.update_applicant_info_action.fields.address.zip`)"
              :error-messages="errors.collect('zip')"
              data-vv-name="zip"
              :data-vv-as="$t(`application.update_applicant_info_action.fields.address.zip`)"
            ></v-text-field>
          </v-flex>
          <v-flex sm8 xs12>
            <v-text-field
              name="email"
              v-model="currentCardApplicant.email"
              v-validate="'required|email'"
              :label="$t(`application.update_applicant_info_action.fields.email`)"
              :error-messages="errors.collect('email')"
              data-vv-name="email"
              :data-vv-as="$t(`application.update_applicant_info_action.fields.email`)"
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-layout row wrap pad-form-row-elements>
          <v-flex sm4 xs12>
            <v-text-field
              name="phone"
              v-model="currentCardApplicant.phone"
              v-maska="'1-###-###-####'"
              v-validate="{ required: true, regex: /^1-[0-9]{3}-[0-9]{3}-[0-9]{4}$/ }"
              :label="$t(`application.update_applicant_info_action.fields.phone`)"
              :error-messages="errors.collect('phone')"
              data-vv-name="phone"
              :data-vv-as="$t(`application.update_applicant_info_action.fields.phone`)"
            ></v-text-field>
          </v-flex>
          <v-flex sm4 xs12>
            <v-text-field
              name="mobile"
              v-model="currentCardApplicant.mobile"
              v-maska="'1-###-###-####'"
              v-validate="{ regex: /^1-[0-9]{3}-[0-9]{3}-[0-9]{4}$/ }"
              :label="$t(`application.update_applicant_info_action.fields.mobile`)"
              :error-messages="errors.collect('mobile')"
              data-vv-name="mobile"
              :data-vv-as="$t(`application.update_applicant_info_action.fields.mobile`)"
            ></v-text-field>
          </v-flex>
          <v-flex sm4 xs12>
            <v-select
              name="language"
              v-model="currentCardApplicant.language"
              v-validate="'required'"
              :error-messages="errors.collect('language')"
              data-vv-name="language"
              :data-vv-as="$t(`application.update_applicant_info_action.fields.language`)"
              :items="languageOptions"
              item-text="name"
              item-value="abbr"
              :label="$t(`application.update_applicant_info_action.fields.language`)"
            ></v-select>
          </v-flex>
        </v-layout>

        <v-layout v-if="complianceOccupation" row wrap pad-form-row-elements>
          <v-flex sm4 xs12>
            <v-select
              name="status"
              v-model="currentCardApplicant.compliance.occupation.status"
              v-validate="'required'"
              :error-messages="errors.collect('status')"
              data-vv-name="status"
              :data-vv-as="$t(`application.update_applicant_info_action.fields.status`)"
              :items="occupationStatusOptions"
              item-text="name"
              item-value="abbr"
              @change="changeStatus"
              :label="$t(`application.update_applicant_info_action.fields.status`)"
              class="status"
            ></v-select>
          </v-flex>
          <v-flex sm4 xs12 v-if="showSectors">
            <v-select
              name="sector"
              v-model="currentCardApplicant.compliance.occupation.sector"
              v-validate="'required'"
              :error-messages="errors.collect('sector')"
              data-vv-name="sector"
              :data-vv-as="$t(`application.update_applicant_info_action.fields.sector`)"
              :items="occupationSectorOptions"
              item-text="name"
              item-value="abbr"
              @change="changeSector"
              :label="$t(`application.update_applicant_info_action.fields.sector`)"
            ></v-select>
          </v-flex>
          <v-flex sm4 xs12 v-if="showProfessions">
            <v-select
              name="profession"
              v-model="currentCardApplicant.compliance.occupation.profession"
              v-validate="'required'"
              :error-messages="errors.collect('profession')"
              data-vv-name="profession"
              :data-vv-as="$t(`application.update_applicant_info_action.fields.profession`)"
              :items="occupationProfessionOptions"
              item-text="name"
              item-value="abbr"
              :label="$t(`application.update_applicant_info_action.fields.profession`)"
            ></v-select>
          </v-flex>
        </v-layout>

        <v-layout row wrap pad-form-row-elements>
          <v-flex v-if="!complianceOccupation" sm4 xs12>
            <v-text-field
              name="occupation"
              v-model="currentCardApplicant.occupation"
              v-validate="'required'"
              :label="$t(`application.update_applicant_info_action.fields.profession`)"
              :error-messages="errors.collect('occupation')"
              data-vv-name="occupation"
              :data-vv-as="$t(`application.update_applicant_info_action.fields.occupation`)"
            ></v-text-field>
          </v-flex>
          <v-flex sm4 xs12>
            <v-select
              name="alerts"
              v-model="currentCardApplicant.settings.alerts"
              v-validate="{ required: true, max: 5 }"
              :error-messages="errors.collect('alerts')"
              data-vv-name="alerts"
              :data-vv-as="$t(`application.update_applicant_info_action.fields.settings.alerts`)"
              :items="alertsOptions"
              item-text="name"
              item-value="abbr"
              :label="$t(`application.update_applicant_info_action.fields.settings.alerts`)"
            ></v-select>
          </v-flex>
        </v-layout>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import datetimeFactory from 'datetime-factory';
import moment from 'moment';
import _ from 'lodash';

export default {
  name: 'update-applicant-info-action',
  $_veeValidate: {
    validator: 'new',
  },
  mixins: [actionErrorTrackable],
  props: {
    cardApplicationId: {
      type: String,
      required: true,
    },
    cardApplicant: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentCardApplicant: mapCardApplicant(this.cardApplicant),
      countryOptions: [{ name: this.$t('country.canada'), abbr: 'CA' }],
      stateOptions: [
        { name: this.$t('state.alberta'), abbr: 'AB' },
        { name: this.$t('state.british_columbia'), abbr: 'BC' },
        { name: this.$t('state.manitoba'), abbr: 'MB' },
        { name: this.$t('state.new_brunswick'), abbr: 'NB' },
        { name: this.$t('state.newfoundland_labrador'), abbr: 'NL' },
        { name: this.$t('state.nova_scotia'), abbr: 'NS' },
        { name: this.$t('state.northwest_territories'), abbr: 'NT' },
        { name: this.$t('state.nunavut'), abbr: 'NU' },
        { name: this.$t('state.ontario'), abbr: 'ON' },
        { name: this.$t('state.prince_edward_island'), abbr: 'PE' },
        { name: this.$t('state.quebec'), abbr: 'QC' },
        { name: this.$t('state.saskatchewan'), abbr: 'SK' },
        { name: this.$t('state.yukon'), abbr: 'YT' },
      ],
      languageOptions: [
        { name: this.$t('language.french'), abbr: 'fr' },
        { name: this.$t('language.english'), abbr: 'en' },
      ],
      alertsOptions: [
        { name: this.$t('code.application.settings.alerts.true'), abbr: true },
        { name: this.$t('code.application.settings.alerts.false'), abbr: false },
      ],
      genderOptions: [
        { name: this.$t('gender.female'), abbr: 'female' },
        { name: this.$t('gender.male'), abbr: 'male' },
        { name: this.$t('gender.non_binary'), abbr: 'non_binary' },
        { name: this.$t('gender.undefined'), abbr: null },
      ],
      activeWorker: '1',
    };
  },
  async created() {
    await this.executeAction({ action: 'application/getOccupations' });
  },
  computed: {
    ...mapGetters('application', ['application', 'occupations', 'sectors']),
    complianceOccupation() {
      return this.currentCardApplicant.compliance.occupation.status;
    },
    showSectors() {
      return this.currentCardApplicant.compliance.occupation.status === this.activeWorker;
    },
    showProfessions() {
      return this.currentCardApplicant.compliance.occupation.status === this.activeWorker && this.currentCardApplicant.compliance.occupation.sector;
    },
    occupationStatusOptions() {
      return _.map(this.occupations, (status) => {
        return { name: status.name[this.currentLocale || 'en'], abbr: status.id };
      });
    },
    occupationSectorOptions() {
      const status = _.find(this.occupations, { id: this.currentCardApplicant.compliance.occupation.status });
      const sectors = _.get(status, 'sectors');

      const uniqueValues = _.uniqBy(
        _.map(sectors, (sector) => {
          return { name: sector.name[this.currentLocale || 'en'], abbr: sector.id };
        }),
        'name',
      );
      return _.sortBy(uniqueValues, ['name']);
    },
    occupationProfessionOptions() {
      const sector = _.find(this.sectors, { id: this.currentCardApplicant.compliance.occupation.sector });
      const professions = _.get(sector, 'professions');

      const uniqueValues = _.uniqBy(
        _.map(professions, (profession) => {
          return {
            name: profession.name[this.currentLocale || 'en'],
            abbr: profession.id,
          };
        }),
        'name',
      );
      return _.sortBy(uniqueValues, ['name']);
    },
    formattedDateHint() {
      const date = moment(this.currentCardApplicant.date_of_birth, 'YYYY-MM-DD');
      if (date.isValid()) {
        return date.format('MMMM D, YYYY');
      }
      return '';
    },
    isFormChanged() {
      return Object.keys(this.fields).some((key) => this.fields[key].changed);
    },
  },
  watch: {
    cardApplicant() {
      this.currentCardApplicant = mapCardApplicant(this.cardApplicant);
    },
  },
  methods: {
    changeStatus() {
      this.currentCardApplicant.compliance.occupation.sector = null;
      this.currentCardApplicant.compliance.occupation.profession = null;
    },
    changeSector() {
      this.currentCardApplicant.compliance.occupation.profession = null;
    },
    maxDateLimit() {
      return new moment(datetimeFactory.now())
        .subtract(18, 'year')
        .subtract(1, 'day')
        .format('YYYY-MM-DD');
    },
    clear() {
      this.resetForm();
      this.$validator.reset();
    },
    resetForm() {
      this.currentCardApplicant = mapCardApplicant(this.application.card_applicant);
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    success() {
      this.clear();
      this.$emit('action');
    },
    buildPayload() {
      const payload = _.cloneDeep(this.currentCardApplicant);
      if (_.isUndefined(payload.gender) || payload.gender == 'undefined') {
        payload.gender = null;
      }
      return payload;
    },
    async validationComplete() {
      let isValid = false;

      if (await this.$validator.validateAll()) {
        if (!this.currentCardApplicant.mobile) {
          this.currentCardApplicant.mobile = null;
        }
        isValid = true;
      }
      return isValid;
    },
    async submit() {
      if (await this.validationComplete()) {
        await this.executeAction(
          {
            action: 'application/updateCardApplicant',
            name: 'updateCardApplicant',
            success: this.success,
          },
          {
            cardApplicationId: this.cardApplicationId,
            cardApplicant: this.buildPayload(),
          },
        );
      }
    },
  },
};

function mapCardApplicant(source) {
  return {
    gender: source.gender || null,
    first_name: source.first_name,
    last_name: source.last_name,
    date_of_birth: source.date_of_birth,
    address: {
      zip: source.address.zip,
      street: source.address.street,
      city: source.address.city,
      country: source.address.country,
      state: source.address.state,
    },
    phone: source.phone,
    mobile: source.mobile,
    email: source.email,
    occupation: source.occupation,
    language: source.language,
    settings: {
      alerts: source.settings.alerts,
    },
    compliance: {
      occupation: {
        status: _.get(source, 'compliance.occupation.status.id', null),
        sector: _.get(source, 'compliance.occupation.sector.id', null),
        profession: _.get(source, 'compliance.occupation.profession.id', null),
      },
    },
  };
}
</script>
