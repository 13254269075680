<template>
  <card :title="$t('activation_code_batch.details.activation_code_batch_information_section.title')" icon="" id="activation_code_batch-information">
    <template slot="headerZoneRight">
      <label-text :label="`${$t('activation_code_batch.details.activation_code_batch_information_section.id')}`" :value="activationCodeBatch.id">
        <p slot="labelTextLink" class="label-text--value">
          <v-layout row>
            {{ activationCodeBatch.id }}
            <button class="clipboard" v-clipboard:copy="activationCodeBatch.id ? activationCodeBatch.id : ''">
              <v-icon small>file_copy</v-icon>
            </button>
          </v-layout>
        </p>
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only">
        <v-divider vertical></v-divider>
      </div>
      <label-text :label="$t(`activation_code_batch.details.activation_code_batch_information_section.reference_id`)">
        <p v-if="activationCodeBatch.reference_id" class="label-text--value" slot="labelTextLink">
          {{ activationCodeBatch.reference_id }}
        </p>
        <label-none v-else>{{ $t('system.na') }}</label-none>
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only">
        <v-divider vertical></v-divider>
      </div>
      <label-text
        :label="`${$t('activation_code_batch.details.activation_code_batch_information_section.name')}`"
        :value="activationCodeBatch.name"
      />
      <div class="card-header-divider--vertical hidden-xs-only">
        <v-divider vertical></v-divider>
      </div>
      <label-text
        :label="`${$t('activation_code_batch.details.activation_code_batch_information_section.offer_id')}`"
        :value="activationCodeBatch.offer_id"
      >
        <p slot="labelTextLink">
          <router-link v-if="activationCodeBatch.offer_id" :to="{ name: 'offers_details', params: { offerId: activationCodeBatch.offer_id } }"
            >{{ activationCodeBatch.offer_id }}
          </router-link>
          <label-none v-else>{{ $t('system.na') }}</label-none>
        </p>
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only">
        <v-divider vertical></v-divider>
      </div>
      <label-text
        :label="`${$t('activation_code_batch.details.activation_code_batch_information_section.business_id')}`"
        :value="activationCodeBatch.business_id"
      >
        <p slot="labelTextLink">
          <router-link
            v-if="activationCodeBatch.business_id"
            :to="{ name: 'business_details', params: { businessId: activationCodeBatch.business_id } }"
            >{{ activationCodeBatch.business_name }}
          </router-link>
          <label-none v-else>{{ $t('system.na') }}</label-none>
        </p>
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only">
        <v-divider vertical></v-divider>
      </div>
      <label-text
        :label="`${$t('activation_code_batch.details.activation_code_batch_information_section.duration_trigger')}`"
        :value="$t('activation_code_batch.duration_trigger.' + activationCodeBatch.duration_trigger)"
      />
      <div class="card-header-divider--vertical hidden-xs-only">
        <v-divider vertical></v-divider>
      </div>
      <label-text
        :label="`${$t('activation_code_batch.details.activation_code_batch_information_section.creation_date')}`"
        :value="activationCodeBatch.creation_date | formatShortDateInverted | orNotAvailable"
      />
      <div class="card-header-divider--vertical hidden-xs-only">
        <v-divider vertical></v-divider>
      </div>
      <link-secondary-action slot="labelTextLink">
        <button-secondary-actions :actions="actions" :text="$t(`system.actions`)" class="application-info--actions"></button-secondary-actions>
      </link-secondary-action>
      <modal-action :show-modal="activeModal !== null">
        <download-activation-codes-batch-excel-action
          v-if="activeModal === 'chose_csv_lang'"
          :activation-code-batch-id="activationCodeBatch.id"
          @close="closeModal"
          @action="closeModal"
        />
        <download-corporate-order-invoice
          v-if="activeModal === 'chose_invoice_lang'"
          :activation-code-corporate-order-id="activationCodeBatch.corporate_order_id"
          @close="closeModal"
          @action="closeModal"
        />
        <download-activation-codes-batch-pdf-action
          v-if="activeModal === 'chose_pdf_lang'"
          :activation-code-batch-id="activationCodeBatch.id"
          @close="closeModal"
          @action="closeModal"
        />
        <download-activation-codes-batch-infos-pdf-action
          v-if="activeModal === 'chose_infos_pdf_lang'"
          :activation-code-batch-id="activationCodeBatch.id"
          @close="closeModal"
          @action="closeModal"
        />
        <download-activation-codes-batch-zip-action
          v-if="activeModal === 'chose_zip_lang'"
          :activation-code-batch-id="activationCodeBatch.id"
          @close="closeModal"
          @action="closeModal"
        />
        <update-activation-code-batch-action
          v-if="activeModal === 'update'"
          :activation-code-batch="activationCodeBatch"
          @close="closeModal"
          @updated="updateActivationCodeBatch"
        />
      </modal-action>
    </template>
    <template slot="content">
      <v-layout row>
        <v-flex xs3>
          <label-text
            :label="$t(`activation_code_batch.details.activation_code_batch_information_section.payment_amount`)"
            :value="activationCodeBatch.payment_amount | currency | dollarSignI18n"
          />
        </v-flex>
        <v-flex xs3>
          <label-text
            :label="$t(`activation_code_batch.details.activation_code_batch_information_section.bonus_amount`)"
            :value="activationCodeBatch.bonus_amount | currency | dollarSignI18n"
          />
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs3>
          <label-text
            :label="$t(`activation_code_batch.details.activation_code_batch_information_section.purchase_channel`)"
            :value="activationCodeBatch.purchase_channel ? $t('purchase_channel.' + activationCodeBatch.purchase_channel) : null"
          ></label-text>
        </v-flex>
        <v-flex v-if="activationCodeBatch.buyer_account_id" xs3>
          <label-text
            :label="`${$t('activation_code_batch.details.activation_code_batch_information_section.buyer_account_id')}`"
            :value="activationCodeBatch.buyer_account_id"
          >
            <account-email
              :id="activationCodeBatch.buyer_account_id"
              slot="labelTextLink"
              :generate-link-to-card="true"
              :show-label="false"
            ></account-email>
          </label-text>
        </v-flex>
        <v-flex v-if="activationCodeBatch.corporate_order_id && corporate_order" xs3>
          <label-text
            :label="$t(`activation_code_batch.details.activation_code_batch_information_section.corporate_order_id`)"
            :value="activationCodeBatch.corporate_order_id"
          >
            <p slot="labelTextLink">
              <a :href="stripeSearchUrl" target="_blank">
                {{ activationCodeBatch.corporate_order_id }}
              </a>
            </p>
          </label-text>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs3>
          <label-text
            :label="$t(`activation_code_batch.details.activation_code_batch_information_section.payment_amount_funded`)"
            :value="$t(`boolean.${activationCodeBatch.payment_amount_funded}`)"
          />
        </v-flex>
        <v-flex xs3>
          <label-text
            :label="$t(`activation_code_batch.details.activation_code_batch_information_section.bonus_amount_funded`)"
            :value="$t(`boolean.${activationCodeBatch.bonus_amount_funded}`)"
          />
        </v-flex>
        <v-flex xs3>
          <label-text
            :label="$t(`activation_code_batch.details.activation_code_batch_information_section.pre_activation_required`)"
            :value="$t(`boolean.${activationCodeBatch.pre_activation_required}`)"
          />
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs6>
          <label-text
            :label="$t(`activation_code_batch.details.activation_code_batch_information_section.personalized_message`)"
            :html-value="activationCodeBatch.personalized_message ? activationCodeBatch.personalized_message.replaceAll('\n', '<br/>') : null"
          />
        </v-flex>
      </v-layout>
      <v-layout row wrap class="card-info-footer-zone--inner">
        <v-flex xs12 md6 class="card-info-footer-zone--left">
          <v-btn align-bottom small round outline color="primary" class="dashboard-link-btn">
            <router-link
              target="_blank"
              :to="{ name: 'business_suite_employer_dashboard', query: { activation_code_batch_id: activationCodeBatch.id, timerange: '~' } }"
            >
              {{ $t('activation_code_batch.details.bi.employer_report') }}
            </router-link>
            <v-icon small>navigate_next</v-icon>
          </v-btn>
        </v-flex>
        <v-flex 12 class="card-info-footer-zone--right" md6></v-flex>
      </v-layout>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';
import DownloadActivationCodesBatchExcelAction from '@/components/activation_code_batch/actions/DownloadActivationCodesBatchExcelAction';
import DownloadActivationCodesBatchPdfAction from '@/components/activation_code_batch/actions/DownloadActivationCodesBatchPdfAction';
import DownloadActivationCodesBatchInfosPdfAction from '@/components/activation_code_batch/actions/DownloadActivationCodesBatchInfosPdfAction';
import DownloadActivationCodesBatchZipAction from '@/components/activation_code_batch/actions/DownloadActivationCodesBatchZipAction';
import DownloadCorporateOrderInvoice from '../actions/DownloadCorporateOrderInvoice.vue';
import UpdateActivationCodeBatchAction from '../actions/UpdateActivationCodeBatchAction.vue';
import Vue from 'vue';
import AccountEmail from '@/components/account/AccountEmail.vue';

export default {
  name: 'activation-code-batch-information',
  components: {
    AccountEmail,
    DownloadActivationCodesBatchExcelAction,
    DownloadActivationCodesBatchPdfAction,
    DownloadActivationCodesBatchInfosPdfAction,
    DownloadActivationCodesBatchZipAction,
    UpdateActivationCodeBatchAction,
    DownloadCorporateOrderInvoice,
  },
  mixins: [security, actionErrorTrackable, disableWithError],
  data() {
    const $t = this.$t.bind(this);
    return {
      activeModal: null,
      keyword: '',
      searched: '',
      page: 1,
      dataReady: false,
      dirty: false,
      corporate_order: null,
      actions: [
        {
          slotName: 'updateActivationCodeBatch',
          name: $t(`activation_code_batch.details.actions.update.action`),
          action: () => {
            this.activeModal = 'update';
          },
          isAvailable: () => this.isAgentInternal(),
        },
        {
          slotName: 'downloadActivationCodesCorporateOrderInvoice',
          name: $t(`activation_code_batch.details.actions.downloadInvoice.title`),
          action: () => {
            this.activeModal = 'chose_invoice_lang';
          },
          isAvailable: () => this.isAgentInternal() && this.activationCodeBatch.corporate_order_id,
        },
        {
          slotName: 'downloadBatchActivationCodesInfosPdf',
          name: $t(`activation_code_batch.details.actions.downloadInfosPdf.title`),
          action: () => {
            this.activeModal = 'chose_infos_pdf_lang';
          },
          isAvailable: () => this.isAgentInternal(),
        },
        {
          slotName: 'downloadBatchActivationCodes',
          name: $t(`activation_code_batch.details.actions.downloadExcel.title`),
          action: () => {
            this.activeModal = 'chose_csv_lang';
          },
          isAvailable: () => this.isAgentInternal(),
        },
        {
          slotName: 'downloadBatchActivationCodesPdf',
          name: $t(`activation_code_batch.details.actions.downloadPdf.title`),
          action: () => {
            this.activeModal = 'chose_pdf_lang';
          },
          isAvailable: () => this.isAgentInternal(),
        },
        {
          slotName: 'downloadBatchActivationCodesZip',
          name: $t(`activation_code_batch.details.actions.downloadZip.title`),
          action: () => {
            this.activeModal = 'chose_zip_lang';
          },
          isAvailable: () => this.isAgentInternal(),
        },
      ],
    };
  },
  watch: {
    async activationCodeBatch() {
      this.corporate_order = null;

      if (this.activationCodeBatch.corporate_order_id) {
        this.corporate_order = await this.getCorporateOrder(this.activationCodeBatch.corporate_order_id);
      }
    },
  },
  async created() {
    this.page = this.listPageNumber;
    this.keyword = this.listKeyword;
  },
  computed: {
    ...mapGetters('activationCodeBatch', ['activationCodeBatch']),
    ...mapGetters('ui', ['currentLocale']),
    stripeSearchUrl() {
      const stripe_transaction_id = this.corporate_order.stripe_transaction_id;

      if (!stripe_transaction_id) {
        return '';
      }

      if (stripe_transaction_id.startsWith('pi_')) {
        return `${process.env.VUE_APP_STRIPE_PAYMENTS_URL}/${stripe_transaction_id}`;
      }

      return `${process.env.VUE_APP_STRIPE_INVOICES_URL}/${stripe_transaction_id}`;
    },
  },
  methods: {
    closeModal() {
      this.activeModal = null;
    },
    async getCorporateOrder(id) {
      const accountService = Vue.prototype.$services.account;
      return await accountService.getCorporateOrder(id);
    },
    async updateActivationCodeBatch() {
      await this.executeAction({ action: 'activationCodeBatch/getActivationCodeBatch' }, this.activationCodeBatch.id);
      await this.executeAction(
        { action: 'ui/showSuccessSnackBar' },
        { text: 'offers.details.activation_code_batches_section.update_action.success' },
      );
      this.closeModal();
    },
  },
};
</script>
