<template>
  <card :title="$t('business.details.business_information_section.title')" icon="fal fa-building" id="business-informations">
    <template slot="headerZoneRight">
      <label-text :label="$t('business.details.business_information_section.id')" :value="business.id">
        <p slot="labelTextLink" class="label-text--value">
          <v-layout row>
            {{ business.id }}
            <button class="clipboard" v-clipboard:copy="business.id ? business.id : ''">
              <v-icon small>file_copy</v-icon>
            </button>
          </v-layout>
        </p>
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="$t('business.details.business_information_section.name')" :value="business.name"></label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="$t('business.details.business_information_section.system_status.pad')" :value="business.system_status.pad">
        <ready-status-view slot="labelTextLink" :status="business.system_status.pad" />
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text
        :label="$t('business.details.business_information_section.created')"
        :value="business.creation_date | formatShortDateInverted | orNotAvailable"
      ></label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <v-flex xs4>
        <label-text
          :label="$t(`business.details.business_information_section.creation_by_provider_title`)"
          :value="$t(`business.details.business_information_section.creation_by_provider.${business.creation_by_provider}`)"
        />
      </v-flex>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="$t('business.details.business_information_section.status_title')" :value="business.status">
        <business-status-view slot="labelTextLink" :status="business.status" />
      </label-text>
      <div v-if="showActionBtn()" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <link-secondary-action v-if="showActionBtn()" slot="labelTextLink">
        <button-secondary-actions class="application-info--actions" :text="$t(`system.actions`)" :actions="actions"> </button-secondary-actions>
      </link-secondary-action>
    </template>
    <template slot="content">
      <modal-action :show-modal="showUpdateBusinessModal"
        ><update-business-action
          v-if="showUpdateBusinessModal"
          :business="business"
          @close="closeUpdateBusinessModal"
          @updated="updateBusinessSuccess"
        ></update-business-action
      ></modal-action>
      <modal-action :show-modal="showVerifyModal">
        <modal-confirm
          :show-modal="showVerifyModal"
          :title="$t('business.details.business_information_section.action_verify.title')"
          :text="$t('business.details.business_information_section.action_verify.text')"
          cancel_text="business.details.business_information_section.action_verify.cancel_text"
          submit_text="business.details.business_information_section.action_verify.submit_text"
          @submit="setBusinessAsVerified"
          @close="closeVerifyModal"
          context="set-status-to-verified"
        ></modal-confirm>
      </modal-action>
      <modal-action :show-modal="showUnverifyModal">
        <modal-confirm
          :show-modal="showUnverifyModal"
          :title="$t('business.details.business_information_section.action_unverify.title')"
          :text="$t('business.details.business_information_section.action_unverify.text')"
          cancel_text="business.details.business_information_section.action_unverify.cancel_text"
          submit_text="business.details.business_information_section.action_unverify.submit_text"
          @submit="setBusinessAsUnverified"
          @close="closeUnverifyModal"
          context="set-status-to-unverified"
        ></modal-confirm>
      </modal-action>
      <v-layout row>
        <v-flex xs4
          ><label-text :label="$t(`business.details.business_information_section.contact_name`)" :value="business.contact.name"></label-text
        ></v-flex>
        <v-flex xs4
          ><label-text :label="$t(`business.details.business_information_section.email`)" :value="business.contact.email">
            <p slot="labelTextLink" class="label-text--value">
              <v-layout row class="smoke-business-info--contact-email--single">
                {{ business.contact.email }}
                <button class="clipboard" v-clipboard:copy="business.contact.email">
                  <v-icon small>file_copy</v-icon>
                </button>
              </v-layout>
            </p>
          </label-text></v-flex
        >
        <v-flex xs4><label-text :label="$t(`business.details.business_information_section.phone`)" :value="formattedPhone"></label-text></v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs6><label-text :label="$t(`business.details.business_information_section.address`)" :value="fullAddress"></label-text></v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs6
          ><label-text
            :label="$t(`business.details.business_information_section.number_of_employees`)"
            :value="$t('business.create_action.number_of_employees.options.' + business.number_of_employees)"
          ></label-text
        ></v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs4 v-if="showCreationAccount">
          <label-text :label="$t(`business.details.business_information_section.creation_by_account_title`)" :value="business.creation_by_account_id">
            <p slot="labelTextLink">
              <router-link :to="{ name: 'user_details', params: { userId: business.creation_by_account_id } }">
                {{ business.creation_by_account_id }}
              </router-link>
            </p>
          </label-text>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12 md6>
          <v-btn align-bottom small round outline color="primary" class="dashboard-link-btn">
            <router-link target="_blank" :to="{ name: 'business_suite_pads_dashboard', query: { business_id: businessId, timerange: '~' } }">
              {{ $t('business.details.business_information_section.pads') }}
            </router-link>
            <v-icon small>navigate_next</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import UpdateBusinessAction from '../actions/UpdateBusinessAction';
import { mapGetters } from 'vuex';
import ReadyStatusView from '@/components/system/ReadyStatusView';
import BusinessStatusView from '../BusinessStatusView';

export default {
  name: 'business-information',
  components: { ReadyStatusView, UpdateBusinessAction, BusinessStatusView },
  mixins: [security, actionErrorTrackable, disableWithError],
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  data() {
    const $t = this.$t.bind(this);
    return {
      showUpdateBusinessModal: false,
      showVerifyModal: false,
      showUnverifyModal: false,
      actions: [
        {
          slotName: 'UpdateBusinessModal',
          name: $t('business.details.business_information_section.update_business'),
          action: () => this.openUpdateBusinessModal(),
          isAvailable: () => this.showUpdateBusinessFeature(),
          isDisabled: () => !this.showUpdateBusinessFeature(),
        },
        {
          slotName: 'SetBusinessVerified',
          name: $t('business.details.business_information_section.button_set_verify'),
          action: () => this.openVerifyModal(),
          isAvailable: () => this.showUpdateBusinessFeature(),
          isDisabled: () => !this.canSetVerified(),
        },
        {
          slotName: 'SetBusinessUnverified',
          name: $t('business.details.business_information_section.button_set_unverify'),
          action: () => this.openUnverifyModal(),
          isAvailable: () => this.showUpdateBusinessFeature(),
          isDisabled: () => !this.canSetUnverified(),
        },
      ],
    };
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('business', ['business']),
    fullAddress() {
      return `${this.business.address.street}, ${this.business.address.city}, ${this.business.address.state}, ${this.business.address.country}, ${this.business.address.zip}`;
    },
    showCreationAccount() {
      return this.business && this.business.creation_by_provider === 'account';
    },
    formattedPhone() {
      if (this.business.contact.phone_extension) {
        return `${this.business.contact.phone} ${this.$t('business.details.business_information_section.phone_extension')} ${
          this.business.contact.phone_extension
        }`;
      }
      return this.business.contact.phone;
    },
  },
  methods: {
    openUpdateBusinessModal() {
      this.showUpdateBusinessModal = true;
    },
    closeUpdateBusinessModal() {
      this.showUpdateBusinessModal = false;
    },
    openVerifyModal() {
      this.showVerifyModal = true;
    },
    closeVerifyModal() {
      this.showVerifyModal = false;
    },
    openUnverifyModal() {
      this.showUnverifyModal = true;
    },
    closeUnverifyModal() {
      this.showUnverifyModal = false;
    },
    async updateBusinessSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'business.details.business_information_section.update_action.success' });
      this.closeUpdateBusinessModal();
    },
    async setBusinessAsVerified() {
      await this.executeAction({ action: 'business/setBusinessAsVerified' }, this.business.id);
      this.closeVerifyModal();
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'business.details.business_information_section.action_verify.success' });
    },
    async setBusinessAsUnverified() {
      await this.executeAction({ action: 'business/setBusinessAsUnverified' }, this.business.id);
      this.closeUnverifyModal();
      await this.executeAction(
        { action: 'ui/showSuccessSnackBar' },
        { text: 'business.details.business_information_section.action_unverify.success' },
      );
    },
    showActionBtn() {
      return this.showUpdateBusinessFeature();
    },
    showUpdateBusinessFeature() {
      return this.canManageOffer(); // TODO: change permission
    },
    canSetVerified() {
      return this.showUpdateBusinessFeature() && this.business && this.business.status !== 'verified';
    },
    canSetUnverified() {
      return this.showUpdateBusinessFeature() && this.business && this.business.status !== 'unverified';
    },
  },
};
</script>
