<template>
  <div>
    <v-card id="task-form">
      <form @submit.prevent="submit">
        <v-layout wrap>
          <v-flex xs12>
            <v-icon size="24px" color="primary">fal fa-tasks</v-icon>
            <h3 class="task-form-title">{{ $t('task.form.title') }}</h3>
          </v-flex>
          <v-flex xs12 sm6 md2>
            <label>{{ $t('task.form.type') }}</label>

            <v-checkbox
              v-for="(type, i) in taskTypes"
              :key="i"
              class="task-checkbox"
              v-model="selectedTypes"
              :value="type"
              :label="$t(`task.form.types.${type}`)"
              data-test="task-type"
              :checked="true"
            />
          </v-flex>
          <v-flex xs12 sm6 md2>
            <label>{{ $t('task.form.status') }}</label>
            <v-checkbox
              v-model="statuses"
              value="pending"
              :label="$t('task.form.statuses.pending')"
              class="task-checkbox"
              data-test="task_status"
            ></v-checkbox>
            <!-- <v-checkbox class="task-checkbox" v-model="statuses" value="in_progress" :label="$t('task.form.statuses.in_progress')" data-test="task_status"></v-checkbox> -->
            <v-checkbox
              class="task-checkbox"
              v-model="statuses"
              value="resolved"
              :label="$t('task.form.statuses.resolved')"
              data-test="task_status"
            ></v-checkbox>
            <v-select v-if="assign_to_items.length > 0" :items="assign_to_items" :label="$t('task.form.assignee')"></v-select>
            <v-select
              v-if="resolvedSelected"
              v-model="agent_key"
              name="agent"
              :label="$t('task.form.resolved_by')"
              :items="resolvingAgentsList"
              :error-messages="errors.collect('agent')"
              data-vv-name="agent"
              :data-vv-as="$t('task.form.resolved_by')"
              v-validate=""
              item-text="full_name"
              item-value="key"
              data-test="task-agent-selector"
              class="mt-3"
            ></v-select>
          </v-flex>
          <v-flex xs12 sm6 md3>
            <label>{{ $t('task.form.date') }}</label>
            <v-menu
              v-model="date_from_menu"
              :close-on-content-click="false"
              :nudge-right="40"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  class="task-checkbox"
                  v-model="date_from"
                  :label="$t('task.form.date_from')"
                  prepend-icon="event"
                  readonly
                  v-on="on"
                  clearable
                  data-test="task_date_from"
                ></v-text-field>
              </template>
              <v-date-picker v-model="date_from" @input="date_from_menu = false"></v-date-picker>
            </v-menu>
            <v-menu
              v-model="date_to_menu"
              :close-on-content-click="false"
              :nudge-right="40"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  class="task-checkbox"
                  v-model="date_to"
                  :label="$t('task.form.date_to')"
                  prepend-icon="event"
                  readonly
                  v-on="on"
                  clearable
                  data-test="task_date_to"
                ></v-text-field>
              </template>
              <v-date-picker v-model="date_to" @input="date_to_menu = false"></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex sm12 md5>
            <label>{{ $t('task.form.keyword') }}</label>
            <v-text-field
              :label="$t('task.form.keyword_placeholder')"
              v-model="keyword"
              data-vv-name="keyword"
              v-validate="'min:3|max:100'"
              :error-messages="errors.collect('keyword')"
              clearable
              data-test="keyword"
              @keyup.enter="submit(null, 1)"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout justify-end>
          <v-flex shrink>
            <button-primary @click="submit(null, 1)" :text="$t('task.form.submit')" data-test="submit" :loading="working"></button-primary>
          </v-flex>
        </v-layout>
      </form>
    </v-card>
    <v-flex class="data-section">
      <alert-warning v-if="hasEmptyData" :text="$t('task.form.no_task_found')" data-test="no-task-found-warning"></alert-warning>

      <span v-if="!hasEmptyData">
        <task-list :data-loaded="dataLoaded" />

        <task-pagination id="task-pagination" data-test="task-pagination" @page_requested="submit(null, $event)" />
      </span>
    </v-flex>
  </div>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import TaskList from './TaskList';
import TaskPagination from './TaskPagination';
import { mapGetters } from 'vuex';

export default {
  name: 'view-task-form',
  mixins: [actionErrorTrackable, security],
  components: { TaskList, TaskPagination },
  $_veeValidate: {
    validator: 'new',
  },
  beforeDestroy() {
    this.executeAction({ action: 'task/resetTasksData' });
  },
  data() {
    return {
      date_from_menu: false,
      date_to_menu: false,
      date_from: null,
      date_to: null,
      assign_to_items: [],
      statuses: ['pending', 'in_progress'],
      selectedTypes: [],
      keyword: '',
      showTotalResultsMessage: false,
      dataLoaded: false,
      agent_key: '',
    };
  },
  watch: {
    statuses() {
      if (!this.statuses.includes('resolved')) {
        this.agent_key = '';
      }
    },
  },
  computed: {
    ...mapGetters('task', ['taskTypes', 'tasks', 'resolvingAgents']),
    hasEmptyData() {
      return this.dataLoaded && !this.tasks.length;
    },
    resolvedSelected() {
      return this.statuses.includes('resolved');
    },
    resolvingAgentsList() {
      const withAllAgents = _.clone(this.resolvingAgents);
      const fullName = this.$t('task.form.all_agents');
      withAllAgents.unshift({ full_name: fullName, key: 'all_agents' });

      return withAllAgents;
    },
  },
  async mounted() {
    this.submit(null, 1);
    await this.executeAction({ action: 'task/getTaskTypes' });
    this.selectedTypes = this.taskTypes;
    await this.executeAction({ action: 'task/getUniqueResolvingAgents' });
  },
  methods: {
    async submit(event, page = 1) {
      if (await this.$validator.validateAll()) {
        this.dataLoaded = false;

        const filters = {
          date_from: this.date_from,
          date_to: this.date_to,
          keyword: this.keyword,
          types: this.selectedTypes,
          statuses: this.statuses,
          resolved_by_agent_key: this.agent_key === 'all_agents' ? '' : this.agent_key,
        };

        await this.executeAction({ action: 'task/getTasks' }, { filters, page });

        this.dataLoaded = true;
      }
    },
    async clear() {
      this.$validator.reset();
    },
  },
};
</script>

<style scoped lang="stylus">
.task-form-title {
  display: inline;
  margin-left: 10.5px;
}
</style>
