import Vue from 'vue';

const service = {
  async getActivationCodeBatch(id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/publisher/v1/activation-code-batches/${id}`);
    return response.data.data;
  },
  async listActivationCodeBatches(offerId, page, limit) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v2/offers/${offerId}/activation-code-batches`, { page, limit })}`,
    );
    return response.data.data;
  },
  async downloadActivationCodesBatchExcel(id, language = 'en') {
    const response = await Vue.prototype.$axios.get(
      generatePath(`${process.env.VUE_APP_BASE_API_URL}/publisher/v1/activation-code-batches/${id}/export/excel`, { lang: language }),
      {
        responseType: 'blob',
      },
    );

    return { fileContent: response.data, filename: extractFileNameFromHeaders(response.headers) };
  },
  async downloadActivationCodesBatchPdf(id, language = 'en') {
    const response = await Vue.prototype.$axios.get(
      generatePath(`${process.env.VUE_APP_BASE_API_URL}/publisher/v1/activation-code-batches/${id}/export/pdf`, { lang: language }),
      {
        responseType: 'blob',
      },
    );

    return { fileContent: response.data, filename: extractFileNameFromHeaders(response.headers) };
  },
  async downloadActivationCodesBatchInfosPdf(id, language = 'en') {
    const response = await Vue.prototype.$axios.get(
      generatePath(`${process.env.VUE_APP_BASE_API_URL}/publisher/v1/activation-code-batches/${id}/export/pdf-infos`, { lang: language }),
      {
        responseType: 'blob',
      },
    );

    return { fileContent: response.data, filename: extractFileNameFromHeaders(response.headers) };
  },
  async downloadActivationCodePreviewPdf(id, language = 'en') {
    const response = await Vue.prototype.$axios.get(
      generatePath(`${process.env.VUE_APP_BASE_API_URL}/publisher/v1/offers/${id}/export/activation-code-preview-pdf`, { lang: language }),
      {
        responseType: 'blob',
      },
    );

    return { fileContent: response.data, filename: extractFileNameFromHeaders(response.headers) };
  },

  async downloadActivationCodesBatchZip(id, language = 'en') {
    const response = await Vue.prototype.$axios.get(
      generatePath(`${process.env.VUE_APP_BASE_API_URL}/publisher/v1/activation-code-batches/${id}/export/zip`, { lang: language }),
      {
        responseType: 'blob',
      },
    );

    return { fileContent: response.data, filename: extractFileNameFromHeaders(response.headers) };
  },

  async validateRecipientCsvFile(file) {
    const formData = new FormData();
    formData.append('file', file);

    const response = await Vue.prototype.$axios.post(
      `${process.env.VUE_APP_BASE_API_URL}/publisher/v1/activation-code-batches/validate-recipients-csv-file`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return response.data.data;
  },

  async updateActivationCodeBatch(id, payload) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/publisher/v1/activation-code-batches/${id}`, payload);
    return response.data.data;
  },
};

function extractFileNameFromHeaders(headers) {
  return headers['content-disposition']
    .split(';')
    .find((n) => n.includes('filename='))
    .replace('filename=', '')
    .trim()
    .slice(1, -1);
}

function generatePath(startPath, { page, limit, lang }) {
  let path = startPath;
  if (page || limit || lang) {
    path += '?';
  }

  const queryStrings = [];
  if (lang) {
    queryStrings.push(['lang', lang]);
  }
  if (page) {
    queryStrings.push(['page', page]);
  }
  if (limit) {
    queryStrings.push(['limit', limit]);
  }

  const params = new URLSearchParams(queryStrings);
  return path + params.toString();
}

export default service;
