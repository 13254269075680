<template>
  <div>
    <layout-notes :items="items" @delete="deleteNote" />
    <div class="text-xs-center">
      <v-pagination v-if="listPublisherNotesPageCount > 1" v-model="pagination" :length="listPublisherNotesPageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'publisher-notes-table',
  mixins: [actionErrorTrackable, security],
  props: {
    items: {
      type: Array,
    },
    publisherId: {
      type: String,
    },
  },
  data() {
    return {
      pagination: 1,
    };
  },
  mounted() {
    this.pagination = this.listPublisherNotesPageNumber;
  },
  watch: {
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'publishers/listPublisherNotes',
        },
        {
          publisher_id: this.publisherId,
          page: newValue,
        },
      );
    },
  },
  computed: {
    ...mapGetters('publishers', ['listPublisherNotesPageNumber', 'listPublisherNotesPageCount', 'listPublisherNotesItemCount']),
  },
  methods: {
    deleteNote(noteId) {
      this.$emit('publisher_note_delete_action', noteId);
    },
  },
};
</script>
