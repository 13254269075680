<template>
  <v-sheet :color="backgroundColor" data-test="card-aml-note--item">
    <div v-if="note.isCard">
      {{ $t('application.aml_note_section.note_type_card') }} ({{ note.public_token }} - {{ noteCategory(note) }} - {{ noteType(note) }})
    </div>

    <div v-if="note.isApplication">
      {{ $t('application.aml_note_section.note_type_application') }} ({{ note.card_application_id }} - {{ noteCategory(note) }} -
      {{ noteType(note) }})
    </div>

    <div v-if="note.isAccount">{{ noteCategory(note) }} - {{ noteType(note) }}</div>

    <layout-message :author="note.created_by_email" :date="note.created">
      <v-divider slot="divider" v-if="hasDivider" />

      <span slot="actions" v-if="!isDeleted && canManageAmlProgram()">
        <link-secondary-action data-test="aml-note-action--delete" slot="labelTextLink" @click="deleteNote">{{
          $t('application.aml_note_section.delete')
        }}</link-secondary-action>
      </span>

      <div slot="content">
        <div>{{ note.note }}</div>

        <label class="label-text--value red--text" v-if="isDeleted">
          {{ $t('application.aml_note_section.deleted_by') }}
          <inline-app-name :app_key="note.deleted.app_key" />
          ({{ note.deleted.deleted_at | formatDateHourMin }}):
          <span data-test="card-aml-note--delete-reason">{{ note.deleted.reason }}</span>
        </label>
      </div>
    </layout-message>
  </v-sheet>
</template>

<script>
import _get from 'lodash/get';
import { mapGetters } from 'vuex';
import { actionErrorTrackable, security } from '@/mixins';

export default {
  name: 'aml-note',

  mixins: [actionErrorTrackable, security],

  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('aml', ['getNoteCategoryById', 'getNoteTypeById']),

    isDeleted() {
      return _get(this.note, 'deleted.deleted_at', false);
    },

    backgroundColor() {
      return this.isDeleted ? 'red lighten-4' : '';
    },
  },

  props: {
    note: {
      type: Object,
      required: true,
    },

    hasDivider: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    async deleteNote() {
      await this.executeAction({ action: 'aml/showDeleteNoteModal' }, this.note);
    },

    noteCategory() {
      const category = this.getNoteCategoryById(this.note && this.note.category_id);
      return category && category.name[this.currentLocale];
    },

    noteType(note) {
      const type = this.getNoteTypeById(note.type_id);
      return type && type.name[this.currentLocale];
    },
  },
};
</script>
