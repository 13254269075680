<template>
  <view-main title="page_title.task"><view-task-form slot="content"></view-task-form></view-main>
</template>

<script>
import { renderErrorTrackable } from '@/mixins';
import ViewTaskForm from './ViewTaskForm';

export default {
  name: 'task-view',
  mixins: [renderErrorTrackable],
  components: { ViewTaskForm },
};
</script>
