<template>
  <card :title="$t('user.details.notes_section.title')" icon="">
    <template slot="headerZoneRight">
      <label-text class="text-xs-center" :label="`${$t('user.details.notes_section.count')}`" :value="listAccountNotesItemCount || '0'"></label-text>
      <div v-if="showAddUserNoteFeature()" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text v-if="showAddUserNoteFeature()" :label="`${$t('user.details.notes_section.action')}`" value="3">
        <link-secondary-action slot="labelTextLink" @click="openAddUserNoteModal">
          {{ $t('user.details.notes_section.create_note') }}
        </link-secondary-action>
      </label-text>
    </template>
    <template slot="content">
      <user-notes-table
        v-if="accountNotes.length"
        :items="accountNotes"
        :account-id="account.id"
        @user_note_delete_action="openDeleteUserNoteModal"
      ></user-notes-table>
      <label-none v-if="accountNotes.length === 0">{{ $t('user.details.notes_section.no_found') }}</label-none>
      <modal-action :show-modal="showAddUserNoteModal"
        ><create-user-note-action
          :v-if="showAddUserNoteModal"
          :account-id="account.id"
          @close="closeAddUserNoteModal"
          @created="addUserNoteSuccess"
        ></create-user-note-action
      ></modal-action>
      <modal-action :show-modal="showDeleteUserNoteModal"
        ><delete-user-note-action
          :v-if="showDeleteUserNoteModal"
          :account-id="account.id"
          :note-id="noteIdToDelete"
          @close="closeDeleteUserNoteModal"
          @deleted="deleteUserNoteSuccess"
        ></delete-user-note-action
      ></modal-action>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import CreateUserNoteAction from '../actions/CreateUserNoteAction';
import DeleteUserNoteAction from '../actions/DeleteUserNoteAction';
import UserNotesTable from './UserNotesTable';
import { mapGetters } from 'vuex';

export default {
  name: 'user-notes',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { UserNotesTable, CreateUserNoteAction, DeleteUserNoteAction },
  data() {
    return {
      showAddUserNoteModal: false,
      showDeleteUserNoteModal: false,
      noteIdToDelete: null,
      noteToDelete: {},
    };
  },
  computed: {
    ...mapGetters('account', ['accountNotes', 'account', 'listAccountNotesItemCount']),
    ...mapGetters('ui', ['currentLocale']),
  },
  watch: {
    async account() {
      await this.fetchUserNotes();
    },
  },
  methods: {
    openAddUserNoteModal() {
      this.showAddUserNoteModal = true;
    },
    closeAddUserNoteModal() {
      this.showAddUserNoteModal = false;
    },
    openDeleteUserNoteModal(noteId) {
      this.noteIdToDelete = noteId;
      this.noteToDelete = this.accountNotes.find((l) => l.id === noteId);
      this.showDeleteUserNoteModal = true;
    },
    closeDeleteUserNoteModal() {
      this.noteIdToDelete = null;
      this.noteToDelete = {};
      this.showDeleteUserNoteModal = false;
    },
    async deleteUserNoteSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'user.delete_note_action.success' });
      this.closeDeleteUserNoteModal();
    },
    async addUserNoteSuccess() {
      await this.fetchUserNotes();
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'user.create_note_action.success' });
      this.closeAddUserNoteModal();
    },
    showAddUserNoteFeature() {
      return this.canManageOffer(); // TODO change permission
    },
    async fetchUserNotes() {
      await this.executeAction({ action: 'account/listAccountNotes' }, { account_id: this.account.id });
    },
  },
};
</script>
