import _get from 'lodash/get';
import _isBoolean from 'lodash/isBoolean';

export default ({ Vue, app }) => {
  Vue.filter('orNotAvailable', (text) => {
    if ((!text && text !== false) || text === '<NO VALUE>') {
      return app.i18n.t('system.na');
    }
    return text;
  });

  Vue.filter('orNone', (text) => {
    if (!text || text === '<NO VALUE>') {
      return app.i18n.t('system.none');
    }
    return text;
  });

  Vue.filter('boolean', (text) => {
    if (_isBoolean(text)) {
      return app.i18n.t(`system.bool.${text}`);
    }
    return text;
  });

  Vue.filter('formatToOfferIncentive', (offer) => {
    return `$${Number(_.get(offer, 'cashback.amount', 0)).toFixed(0)} (min. $${Number(_.get(offer, 'restrictions.min_amount', 0)).toFixed(0)})`;
  });

  Vue.filter('truncateEllipsis', (text, maxLength = 128) => {
    if (!text) return '';
    return text.substring(0, maxLength) + (text.length > maxLength ? '...' : '');
  });

  Vue.filter('formatToOfferIncentiveFull', (offer, publisher) => {
    const currentLocale = app.i18n.locale;
    return `$${Number(_.get(offer, 'cashback.amount', 0)).toFixed(0)} (min. $${Number(_.get(offer, 'restrictions.min_amount', 0)).toFixed(0)}) at ${
      publisher.name[currentLocale]
    } (${offer.id})`;
  });

  Vue.filter('formatSsn', (text) => {
    if (!text) return '';
    return `${text.substring(0, 3)} ${text.substring(3, 6)} ${text.substring(6, 9)}`;
  });
};
