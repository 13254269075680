<template>
  <div>
    <modal-action :show-modal="printTrackingLinkModalVisible">
      <view-qr-code-action v-if="printTrackingLinkModalVisible" :tracking-link="currentTrackingLink" @close="closePrintTrackingLinkVisible" />
    </modal-action>

    <modal-confirm
      :show-modal="enableOfferTrackingLinkModalVisible"
      :title="$t('offers.offers_tracking_links.activate_modal.title')"
      context="OfferTrackingLinkActivate"
      :text="$t('offers.offers_tracking_links.activate_modal.text', { trackingLinkName: currentTrackingLink.name })"
      cancel_text="offers.offers_tracking_links.activate_modal.cancel"
      submit_text="offers.offers_tracking_links.activate_modal.submit"
      :submit_working="working"
      @submit="enableOfferTrackingLink"
      @close="closeActivateOfferTrackingLinkModal"
    ></modal-confirm>

    <modal-confirm
      :show-modal="disableOfferTrackingLinkModalVisible"
      :title="$t('offers.offers_tracking_links.disable_modal.title')"
      context="OfferTrackingLinkDisable"
      :text="$t('offers.offers_tracking_links.disable_modal.text', { trackingLinkName: currentTrackingLink.name })"
      cancel_text="offers.offers_tracking_links.disable_modal.cancel"
      submit_text="offers.offers_tracking_links.disable_modal.submit"
      :submit_working="working"
      @submit="disableOfferTrackingLink"
      @close="closeDisableOfferTrackingLinkModal"
    ></modal-confirm>

    <table class="responsive-table" id="offer-activation-codes-table">
      <thead>
        <tr>
          <td>{{ $t('offers.offers_tracking_links_table.id') }}</td>
          <td>{{ $t('offers.offers_tracking_links_table.name') }}</td>
          <td>{{ $t('offers.offers_tracking_links_table.business') }}</td>
          <td>{{ $t('offers.offers_tracking_links_table.redirect_type') }}</td>
          <td>{{ $t('offers.offers_tracking_links_table.qr_code') }}</td>
          <td>{{ $t('offers.offers_tracking_links_table.creation_date') }}</td>
          <td>{{ $t('offers.offers_tracking_links_table.status') }}</td>
          <td>{{ $t('offers.offers_tracking_links_table.action') }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>
            <h5>{{ $t('offers.offers_tracking_links_table.id') }}:</h5>
            <span> {{ item.id }}</span>
          </td>
          <td>
            <h5>{{ $t('offers.offers_tracking_links_table.name') }}</h5>
            <span> {{ item.name }} </span>
          </td>
          <td>
            <h5>{{ $t('offers.offers_tracking_links_table.business') }}:</h5>
            <router-link v-if="item.business_id" :to="{ name: 'business_details', params: { businessId: item.business_id } }">
              {{ `${item.business_name} (${item.business_id})` }}
            </router-link>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('offers.offers_tracking_links_table.redirect_type') }}</h5>
            <span>
              {{ $t(`offers.offers_tracking_links.redirect_type.${item.redirect_type}`) }}
            </span>
          </td>
          <td>
            <h5>{{ $t('offers.offers_tracking_links_table.qr_code') }}</h5>
            <span>
              <a aria-role="button" class="button-subtle" @click="showPrintTrackingLinkVisible(item)">{{
                $t('offers.offers_tracking_links_table.qr_code_view')
              }}</a>
            </span>
          </td>
          <td>
            <h5>{{ $t('offers.offers_tracking_links_table.creation_date') }}</h5>
            <span>{{ item.creation_date | formatShortDateInverted | orNotAvailable }}</span>
          </td>
          <td>
            <h5>{{ $t('offers.offers_tracking_links_table.status') }}:</h5>
            <span :class="!item.status ? 'info-not-available' : `offers-tracking-link-${item.status}`">{{
              $t(`offers.offers_tracking_links.status.${item.status}`) | orNotAvailable
            }}</span>
          </td>
          <td>
            <h5>{{ $t('offers.offers_tracking_links_table.action') }}</h5>
            <a v-show="isDisabled(item)" @click="showActivateOfferTrackingLinkModal(item)" class="button-subtle"
              >{{ $t('offers.offers_tracking_links.actions.enable') }}
            </a>
            <a v-show="isActive(item)" @click="showDisableOfferTrackingLinkModal(item)" class="button-subtle">{{
              $t('offers.offers_tracking_links.actions.disable')
            }}</a>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-xs-center">
      <v-pagination v-if="offerTrackingLinksPageCount > 1" v-model="currentPage" :length="offerTrackingLinksPageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import { mapGetters } from 'vuex';
import ViewQrCodeAction from '../actions/ViewOfferQrCodeAction.vue';

export default {
  name: 'offer-tracking-links-table',
  mixins: [security, actionErrorTrackable],
  components: { ViewQrCodeAction },
  props: {
    offerId: {
      type: String,
    },
    items: {
      type: Array,
    },
  },
  data() {
    return {
      currentTrackingLink: {},
      currentPage: 1,
      disableOfferTrackingLinkModalVisible: false,
      enableOfferTrackingLinkModalVisible: false,
      printTrackingLinkModalVisible: false,
      editOfferTrackingLinkModalVisible: false,
    };
  },
  mounted() {
    this.pagination = this.offerTrackingLinksPageNumber;
  },
  computed: {
    ...mapGetters('offers', ['offerTrackingLinksPageNumber', 'offerTrackingLinksPageCount']),
  },
  watch: {
    async currentPage(newValue) {
      this.fetchOfferTrackingLinks();
    },
  },
  methods: {
    async fetchOfferTrackingLinks() {
      await this.executeAction(
        {
          action: 'offers/listOfferTrackingLinks',
        },
        {
          page: this.currentPage,
          id: this.offerId,
        },
      );
    },
    isActive(item) {
      return item.status === 'active';
    },
    isDisabled(item) {
      return item.status === 'disabled';
    },
    showDisableOfferTrackingLinkModal(offerTrackingLink) {
      this.currentTrackingLink = offerTrackingLink;
      this.disableOfferTrackingLinkModalVisible = true;
    },
    showActivateOfferTrackingLinkModal(offerTrackingLink) {
      this.currentTrackingLink = offerTrackingLink;
      this.enableOfferTrackingLinkModalVisible = true;
    },
    showPrintTrackingLinkVisible(offerTrackingLink) {
      this.currentTrackingLink = offerTrackingLink;
      this.printTrackingLinkModalVisible = true;
    },
    closeDisableOfferTrackingLinkModal() {
      this.disableOfferTrackingLinkModalVisible = false;
    },
    closeActivateOfferTrackingLinkModal() {
      this.enableOfferTrackingLinkModalVisible = false;
    },
    closePrintTrackingLinkVisible() {
      this.printTrackingLinkModalVisible = false;
    },
    async disableOfferTrackingLink() {
      await this.executeAction(
        {
          name: 'OfferTrackingLinkDisable',
          action: 'offers/disableOfferTrackingLink',
          success: () => {
            this.closeDisableOfferTrackingLinkModal();
            this.fetchOfferTrackingLinks();
          },
        },
        { offerId: this.offerId, offerTrackingLinkId: this.currentTrackingLink.id },
      );
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: `offers.offers_tracking_links.disable_modal.success` });
    },
    async enableOfferTrackingLink() {
      await this.executeAction(
        {
          name: 'OfferTrackingLinkActivate',
          action: 'offers/enableOfferTrackingLink',
          success: () => {
            this.closeActivateOfferTrackingLinkModal();
            this.fetchOfferTrackingLinks();
          },
        },
        { offerId: this.offerId, offerTrackingLinkId: this.currentTrackingLink.id },
      );
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: `offers.offers_tracking_links.activate_modal.success` });
    },
  },
};
</script>
