<template>
  <card :title="$t('store.details.card_acceptors_section.check_verified.title')">
    <template slot="content">
      <v-layout align-center justify-center row fill-height>
        <v-flex class="pa-2" cols="12">
          <v-progress-circular align-center v-show="loading" :size="72" indeterminate color="primary"></v-progress-circular>
          <v-icon v-if="!loading" align-center size="72px" :color="iconColor">{{ icon }}</v-icon>
        </v-flex>
        <v-flex class="pa-2" cols="auto">
          <span v-if="!loading && !verified">{{ $t('store.details.card_acceptors_section.check_verified.not_authorized') }}</span>
          <span v-if="!loading && verified">{{ $t('store.details.card_acceptors_section.check_verified.success') }}</span>

          <span v-if="loading">{{ $t('store.details.card_acceptors_section.check_verified.loading') }}</span>
        </v-flex>
      </v-layout>

      <v-layout justify-end>
        <v-btn color="primary" @click="close">{{ $t('store.details.card_acceptors_section.check_verified.close') }}</v-btn>
      </v-layout>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import errorTypes from '@/utils/error/error-types';
import { mapActions } from 'vuex';

export default {
  name: 'check-store-card-acceptor-modal',
  mixins: [actionErrorTrackable],
  props: {
    storeCardAcceptorId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      verified: false,
      loading: true,
    };
  },
  async created() {
    const cardAcceptor = await this.executeAction(
      {
        action: 'store/checkStoreCardAcceptorVerification',
        name: 'checkStoreCardAcceptor',
        success: this.success,
      },
      this.storeCardAcceptorId,
    );

    this.verified = cardAcceptor.verified;
    this.loading = false;
  },
  methods: {
    ...mapActions('ui', ['addError', 'clearAllError', 'clearError']),
    clear() {
      this.currentStoreCardAcceptor = {};
      this.$validator.reset();
    },
    success(response) {
      this.clear();
      this.$emit('updated');
    },
    close() {
      this.clear();
      this.$emit('close');
    },
  },
  computed: {
    icon() {
      return this.verified ? 'fal fa-check-circle' : 'far fa-exclamation-triangle';
    },
    iconColor() {
      return this.verified ? 'success' : 'warning';
    },
  },
};
</script>
