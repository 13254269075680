<template>
  <card :title="$t('store.details.custom_section.title')" icon="" id="store-type-web">
    <template slot="headerZoneRight">
      <label-text :label="`${$t('store.details.custom_section.type')}`" :value="$t(`store.types.${store.type}`)" />
    </template>
    <template slot="content">
      <v-layout row>
        <v-flex xs3>
          <label-text :label="$t('store.details.custom_section.label_en')" :value="store.branding.label_en" />
        </v-flex>
        <v-flex xs3>
          <label-text :label="$t('store.details.custom_section.label_fr')" :value="store.branding.label_fr" />
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs3>
          <label-text :label="$t('store.details.custom_section.description_en')" :value="store.branding.description_en" />
        </v-flex>
        <v-flex xs3>
          <label-text :label="$t('store.details.custom_section.description_fr')" :value="store.branding.description_fr" />
        </v-flex>
      </v-layout>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'type-web',
  mixins: [security, actionErrorTrackable, disableWithError],
  data() {
    return {};
  },
  computed: {
    ...mapGetters('store', ['store']),
    ...mapGetters('ui', ['currentLocale']),
  },
};
</script>
