<template>
  <card :title="$t('store.details.branding_section.title')" icon="">
    <template slot="headerZoneRight">
      <label-text v-if="showAddLinkBrandingFeature()" :label="`${$t('store.details.branding_section.action')}`" value="">
        <link-secondary-action v-if="!store.branding_id" slot="labelTextLink" @click="openAddLinkBrandingModal">
          {{ $t('store.details.branding_section.link') }}
        </link-secondary-action>
        <link-secondary-action v-if="store.branding_id" slot="labelTextLink" @click="openUnlinkBrandingModal">
          {{ $t('store.details.branding_section.unlink') }}
        </link-secondary-action>
      </label-text>
    </template>
    <template slot="content">
      <modal-action :show-modal="showAddLinkBrandingModal" avoid-rendering
        ><link-branding-action :store-obj="store" @close="closeAddLinkBrandingModal" @linked="linkBrandingSuccess"></link-branding-action
      ></modal-action>
      <store-branding-table v-if="branding" :item="branding" @store_branding_update_action="openAddLinkBrandingModal"></store-branding-table>
      <label-none v-if="!branding">{{ $t('system.none') }}</label-none>
      <modal-confirm
        v-if="branding"
        :show-modal="showUnlinkBrandingModal"
        :title="$t(`store.details.branding_section.unlink_action.title`)"
        context="UnlinkBranding"
        :text="
          $t(`store.details.branding_section.unlink_action.text`, {
            brandingName: branding.name,
            storeName: store.name,
          })
        "
        :cancel_text="`store.details.branding_section.unlink_action.button_cancel`"
        :submit_text="`store.details.branding_section.unlink_action.button_submit`"
        :submit_working="working"
        @submit="unlinkBrandingSuccess"
        @close="closeUnlinkBrandingModal"
      ></modal-confirm>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import LinkBrandingAction from '../actions/LinkBrandingAction';
import StoreBrandingTable from './StoreBrandingTable';
import { mapGetters } from 'vuex';

export default {
  name: 'store-branding',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { StoreBrandingTable, LinkBrandingAction },
  data() {
    return {
      branding: null,
      showAddLinkBrandingModal: false,
      showUnlinkBrandingModal: false,
    };
  },
  watch: {
    async store() {
      await this.fetchBranding();
    },
  },
  computed: {
    ...mapGetters('store', ['store']),
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    openAddLinkBrandingModal() {
      this.showAddLinkBrandingModal = true;
    },
    closeAddLinkBrandingModal() {
      this.showAddLinkBrandingModal = false;
    },
    openUnlinkBrandingModal() {
      this.showUnlinkBrandingModal = true;
    },
    closeUnlinkBrandingModal() {
      this.showUnlinkBrandingModal = false;
    },
    async unlinkBrandingSuccess() {
      this.showUnlinkBrandingModal = false;
      await this.executeAction(
        {
          action: 'store/unlinkBrandingFromStore',
          name: 'unlinkBrandingFromStore',
        },
        { id: this.store.id },
      );
      await this.fetchBranding();
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'store.details.branding_section.unlink_action.success' });
    },
    async linkBrandingSuccess() {
      await this.fetchBranding();
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'store.details.branding_section.link_action.success' });
      this.closeAddLinkBrandingModal();
    },
    showAddLinkBrandingFeature() {
      return this.canManageOffer(); // TODO: change permission
    },
    async fetchBranding() {
      if (this.store.branding_id) {
        this.branding = await this.executeAction({ action: 'branding/getBranding' }, this.store.branding_id);
      } else {
        this.branding = null;
      }
    },
  },
};
</script>
