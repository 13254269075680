<template>
  <card-modal
    icon=""
    :title="$t('branding.update_action.title')"
    context="updateBranding"
    @submit="submit"
    @cancel="cancel"
    cancel_text="branding.update_action.button_cancel"
    submit_text="branding.update_action.button_save"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-text-field
          autofocus
          :label="$t('branding.update_action.name') + '*'"
          name="name"
          v-validate="'required|max:100'"
          :error-messages="errors.collect('name')"
          v-model="currentBranding.name"
          data-vv-name="name"
          maxlength="100"
          :data-vv-as="$t('branding.update_action.name')"
          required
          data-test="update-branding--name"
        ></v-text-field>
        <v-select
          :items="typesTranslated"
          name="type"
          v-validate="'required'"
          v-model="currentBranding.type"
          :label="$t('branding.update_action.type') + '*'"
          :error-messages="errors.collect('type')"
          data-vv-name="type"
          :data-vv-as="$t('branding.update_action.type')"
          item-text="text"
          item-value="key"
          data-test="update-branding--type"
          v-on:change="onTypeChange"
        ></v-select>
        <v-select
          :items="brandingCategoriesFilteredIfTypeRequires(currentBranding.type)"
          name="category"
          v-validate="'required'"
          v-model="currentBranding.branding_category_id"
          :label="$t('branding.update_action.category') + '*'"
          :error-messages="errors.collect('category')"
          data-vv-name="category"
          :data-vv-as="$t('branding.update_action.category')"
          :item-text="`name[${currentLocale}]`"
          item-value="id"
          data-test="update-branding--categories"
        ></v-select>
        <v-text-field
          :label="$t('branding.update_action.label.fr') + '*'"
          name="label.fr"
          v-validate="'required|max:100'"
          :error-messages="errors.collect('label.fr')"
          v-model="currentBranding.label.fr"
          data-vv-name="label.fr"
          maxlength="100"
          :data-vv-as="$t('branding.update_action.label.fr')"
          required
          data-test="update-branding--label-fr"
        ></v-text-field>
        <v-text-field
          :label="$t('branding.update_action.label.en') + '*'"
          name="label.en"
          v-validate="'required|max:100'"
          :error-messages="errors.collect('label.en')"
          v-model="currentBranding.label.en"
          data-vv-name="label.en"
          maxlength="100"
          :data-vv-as="$t('branding.update_action.label.en')"
          required
          data-test="update-branding--label-en"
        ></v-text-field>
        <v-textarea
          name="description.fr"
          v-model="currentBranding.description.fr"
          v-validate="'max:4096'"
          :error-messages="errors.collect('description.fr')"
          :label="$t('branding.create_action.description.fr')"
          data-vv-name="description.fr"
          :data-vv-as="$t('branding.create_action.description.fr')"
          auto-grow
          rows="1"
          maxlength="4096"
          data-test="update-branding--description-fr"
        ></v-textarea>
        <v-textarea
          name="description.en"
          v-model="currentBranding.description.en"
          v-validate="'max:4096'"
          :error-messages="errors.collect('description.en')"
          :label="$t('branding.update_action.description.en')"
          data-vv-name="description.en"
          :data-vv-as="$t('branding.update_action.description.en')"
          auto-grow
          rows="1"
          maxlength="4096"
          data-test="update-branding--description-en"
        ></v-textarea>
        <v-text-field
          :label="$t('branding.update_action.tagline.fr')"
          name="tagline.fr"
          v-validate="'max:100'"
          :error-messages="errors.collect('tagline.fr')"
          v-model="currentBranding.tagline.fr"
          data-vv-name="tagline.fr"
          maxlength="100"
          :data-vv-as="$t('branding.update_action.tagline.fr')"
          data-test="update-branding--tagline-fr"
        ></v-text-field>
        <v-text-field
          :label="$t('branding.update_action.tagline.en')"
          name="tagline.en"
          v-validate="'max:100'"
          :error-messages="errors.collect('tagline.en')"
          v-model="currentBranding.tagline.en"
          data-vv-name="tagline.en"
          maxlength="100"
          :data-vv-as="$t('branding.update_action.tagline.en')"
          data-test="update-branding--tagline-en"
        ></v-text-field>
        <v-text-field
          :label="$t('branding.update_action.region_label.fr')"
          name="region_label.fr"
          v-validate="'max:50'"
          :error-messages="errors.collect('region_label.fr')"
          v-model="currentBranding.region_label.fr"
          data-vv-name="region_label.fr"
          maxlength="50"
          :data-vv-as="$t('branding.update_action.region_label.fr')"
          data-test="update-branding--region_label-fr"
        ></v-text-field>
        <v-text-field
          :label="$t('branding.update_action.region_label.en')"
          name="region_label.en"
          v-validate="'max:50'"
          :error-messages="errors.collect('region_label.en')"
          v-model="currentBranding.region_label.en"
          data-vv-name="region_label.en"
          maxlength="50"
          :data-vv-as="$t('branding.update_action.region_label.en')"
          data-test="update-branding--region_label-en"
        ></v-text-field>
        <v-text-field
          :label="$t('branding.update_action.url.fr')"
          name="url.fr"
          v-validate="'url'"
          :error-messages="errors.collect('url.fr')"
          v-model="currentBranding.url.fr"
          data-vv-name="url.fr"
          :data-vv-as="$t('branding.update_action.url.fr')"
          data-test="update-branding--url-fr"
        ></v-text-field>
        <v-text-field
          :label="$t('branding.update_action.url.en')"
          name="url.en"
          v-validate="'url'"
          :error-messages="errors.collect('url.en')"
          v-model="currentBranding.url.en"
          data-vv-name="url.en"
          :data-vv-as="$t('branding.update_action.url.en')"
          data-test="update-branding--url-en"
        ></v-text-field>
        <v-text-field
          name="color"
          v-model="currentBranding.branding_color"
          v-validate="{ max: 7, regex: /^\#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/ }"
          :error-messages="errors.collect('color')"
          :label="$t('branding.update_action.color')"
          data-vv-name="color"
          :data-vv-as="$t('branding.update_action.color')"
          counter
          maxlength="7"
          data-test="update-branding--color"
        >
          <template v-slot:append
            ><div
              :style="
                `display: block; height: 28px; width: 28px; backgroundColor: ${currentBranding.branding_color || 'transparent'}; borderRadius: 5px;`
              "
            ></div
          ></template>
        </v-text-field>
        <v-text-field
          :label="$t('branding.update_action.phone')"
          name="phone"
          :error-messages="errors.collect('phone')"
          v-model="currentBranding.phone"
          data-vv-name="phone"
          :data-vv-as="$t('branding.update_action.phone')"
          data-test="update-branding--phone"
        ></v-text-field>
        <v-container class="px-0" fluid>
          <h4>
            {{ $t('branding.update_action.features_title') }}
          </h4>
          <v-checkbox v-model="currentBranding.features.panier_bleu" :label="$t('branding.update_action.panier_bleu')"></v-checkbox>
        </v-container>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'update-branding-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    branding: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentBranding: { label: {}, description: {}, tagline: {}, url: {}, region_label: {}, features: {} },
    };
  },
  async mounted() {
    await this.executeAction({
      action: 'branding/listCategories',
    });
    this.currentBranding = Object.assign(
      { label: {}, description: {}, tagline: {}, url: {}, region_label: {}, features: {} },
      _.cloneDeep(this.branding),
    );
    this.tryClearCategoryIfTypeInvalid(this.currentBranding.type);
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('branding', ['types', 'categories', 'brandingCategoriesFilteredIfTypeRequires']),
    typesTranslated() {
      return _.map(this.types, (type) => ({
        text: this.$t(`branding.types.${type}`),
        key: type,
      })).sort((a, b) => a.text > b.text);
    },
  },
  methods: {
    clear() {
      this.currentBranding = { label: {}, description: {}, tagline: {}, url: {}, region_label: {}, features: {} };
      this.$validator.reset();
    },
    success(response) {
      this.clear();
      this.$emit('updated');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();
      if (validationComplete) {
        await this.executeAction(
          {
            action: 'branding/updateBranding',
            name: 'updateBranding',
            success: this.success,
          },
          this.buildPayload(),
        );
      }
    },
    getImagesFromForm(urls) {
      if (Array.isArray(urls)) {
        return JSON.parse(JSON.stringify(urls));
      }
      const imageUrls = urls.split(',');
      return imageUrls.map((url) => url.trim()).filter((url) => url);
    },
    buildPayload() {
      const payload = Object.assign({}, _.omit(this.currentBranding, ['creation_date', 'split_images']));

      if (_.isNil(payload.description.en) && _.isNil(payload.description.fr)) delete payload.description;
      if (_.isNil(payload.tagline.en) && _.isNil(payload.tagline.fr)) delete payload.tagline;
      if (_.isNil(payload.region_label.en) && _.isNil(payload.region_label.fr)) delete payload.region_label;
      if (_.isNil(payload.url.en) && _.isNil(payload.url.fr)) delete payload.url;

      if (payload.phone) {
        payload.phone = this.$options.filters.formatPhone(payload.phone);
      }

      return payload;
    },
    async tryClearCategoryIfTypeInvalid(type) {
      const currentCategory = this.brandingCategoriesFilteredIfTypeRequires(type).find(
        (brand) => brand.id == this.currentBranding.branding_category_id,
      );
      this.currentBranding.branding_category_id = currentCategory ? currentCategory.id : undefined;
    },
    onTypeChange(newType) {
      this.tryClearCategoryIfTypeInvalid(newType);
    },
  },
};
</script>
