<template>
  <v-flex class="view-space-form">
    <h1 hidden>{{ $t('space.form.title') }}</h1>
    <v-card>
      <form @submit.prevent="submit" class="form-basic form-search">
        <v-layout align-center wrap class="form-search--main-wrap-v2">
          <v-flex class="form-search--inputs-zone-v2">
            <form-warning-search
              :autofocus="true"
              :searched="searched"
              :hint-message="$t('space.form.total_results_message')"
              :label="$t('space.form.search_placeholder')"
              v-model="keyword"
              data-vv-name="search"
              v-validate="'max:50'"
              :max-length="50"
              :error-messages="errors.collect('search')"
              data-test="form-spaces-keyword"
            />

            <h-autocomplete
              v-model="business_id"
              no-filter
              clearable
              :label="$t('space.form.business')"
              :error-messages="errors.collect('business_id')"
              name="business"
              data-vv-name="business"
              data-vv-as="business"
              search-action="business/autocompleteSearch"
              data-test="form-space-owner"
            >
            </h-autocomplete>

            <v-select
              name="region"
              v-model="region"
              :error-messages="errors.collect('region')"
              data-vv-name="region"
              :data-vv-as="$t('space.form.region')"
              :items="regionOptions"
              item-text="name"
              item-value="value"
              clearable
              :label="$t('space.form.region')"
              data-test="space-offers-region"
            ></v-select>

            <button-primary class="space--btn-search" :text="$t('space.form.submit')" :loading="working" @click="submit" type="submit" />
          </v-flex>

          <v-flex class="form-search--actions-zone-v2">
            <button-primary
              data-test="space--btn-add"
              id="space--btn-add"
              class="space-form--btn-add"
              :loading="working"
              :text="$t('space.form.add')"
              @click="openCreateSpaceModal"
            ></button-primary>
          </v-flex>
        </v-layout>
      </form>
    </v-card>

    <v-flex class="data-section">
      <alert-warning v-if="dataReady && spaces.length === 0" :text="$t('space.no_space_found')"></alert-warning>
      <card v-if="dataReady && spaces.length > 0" icon="fal fa-store" :title="`${$t('space.form.data_section.title')}`">
        <template slot="headerZoneRight">
          <label-text class="text-sm-center" :label="$t('space.form.data_section.spaces_count')" :value="listItemCount || '0'"></label-text>
        </template>
        <template slot="content">
          <spaces-table :items="spaces"></spaces-table>
        </template>
      </card>
    </v-flex>
    <modal-action :show-modal="showCreateSpaceModal"
      ><create-space-action v-if="showCreateSpaceModal" @close="closeCreateSpaceModal" @created="createSpaceSuccess"></create-space-action
    ></modal-action>
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable, security } from '@/mixins';
import SpacesTable from './SpacesTable';
import CreateSpaceAction from '../actions/CreateSpaceAction';
import AlertWarning from '@/components/system/Alerts/AlertWarning';

export default {
  name: 'view-space-form',
  mixins: [actionErrorTrackable, security],
  components: { AlertWarning, SpacesTable, CreateSpaceAction },
  $_veeValidate: {
    validator: 'new',
  },
  beforeDestroy() {
    this.executeAction({ action: 'space/clearListSpaces' });
  },
  data() {
    return {
      page: 1,
      dataReady: false,
      keyword: '',
      region: null,
      business_id: null,
      searched: '',
      showCreateSpaceModal: false,
      regionOptions: [
        { name: this.$t('regions.ab'), value: 'AB' },
        { name: this.$t('regions.nb'), value: 'NB' },
        { name: this.$t('regions.on'), value: 'ON' },
        { name: this.$t('regions.qc'), value: 'QC' },
      ],
    };
  },
  async created() {
    this.page = this.listPageNumber;
    this.keyword = this.listKeyword;
    this.region = this.listRegion;
    this.business_id = this.listBusinessId;

    if (this.routeHasQueryParams()) {
      this.page = 1;
    }
    if (this.$route.query.keyword) {
      this.keyword = this.$route.query.keyword;
    }
    if (this.$route.query.business_id) {
      this.business_id = this.$route.query.business_id;
    }
    if (this.$route.query.region) {
      this.region = this.$route.query.region;
    }

    this.routeHasQueryParams() ? this.submit(null, false, this.page) : this.submit(null, true, this.page);
  },
  watch: {},
  computed: {
    ...mapGetters('space', ['spaces', 'listKeyword', 'listPageNumber', 'listItemCount', 'listBusinessId', 'listRegion']),
  },
  methods: {
    async clear() {
      this.searched = '';
      this.keyword = '';
      this.region = null;
      this.business_id = null;
      this.$validator.reset();
    },
    routeHasQueryParams() {
      return this.$route.query.keyword || this.$route.query.business_id || this.$route.query.region;
    },
    openCreateSpaceModal() {
      this.showCreateSpaceModal = true;
    },
    closeCreateSpaceModal() {
      this.showCreateSpaceModal = false;
    },
    buildPayloadQueryString() {
      const payload = {};

      if (this.keyword) {
        payload.keyword = this.keyword;
      }
      if (this.business_id) {
        payload.business_id = this.business_id;
      }
      if (this.region) {
        payload.region = this.region;
      }

      return payload;
    },
    async submit(event, forceSearch = false, page = 1) {
      let validationComplete;
      if (!forceSearch) {
        validationComplete = await this.$validator.validateAll();
      }

      if (validationComplete || forceSearch) {
        this.$router.replace({ query: this.buildPayloadQueryString() });
        await this.clearAllError();

        try {
          this.dataReady = false;
          const search = this.buildPayloadQueryString();
          search.page = page;

          await this.executeAction({ action: 'space/listSpacesWithPartner' }, search);
        } finally {
          this.dataReady = true;
        }
      }
    },
    async listSpacesWithPartner() {
      await this.executeAction(
        {
          action: 'space/listSpacesWithPartner',
        },
        {
          page: this.listPageNumber,
          keyword: this.listKeyword,
          business_id: this.listBusinessId,
          region: this.listRegion,
        },
      );
    },
    async createSpaceSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'space.create_action.success' });
      this.closeCreateSpaceModal();
      await this.listSpacesWithPartner();
    },
  },
};
</script>
