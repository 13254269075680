<template>
  <card :title="$t('offers.details.offers_tracking_links_section.title')" icon="">
    <template slot="headerZoneRight">
      <label-text
        class="text-sm-center"
        :label="$t('offers.details.offers_tracking_links_section.count')"
        :value="offerTrackingLinksItemCount || '0'"
      ></label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text class="text-sm-center" :label="$t('offers.details.offers_tracking_links_section.action')">
        <link-secondary-action slot="labelTextLink" @click="showAddOfferTrackingLinkModal()">
          {{ $t('offers.details.offers_tracking_links_section.add') }}
        </link-secondary-action>
      </label-text>
    </template>
    <template slot="content">
      <modal-action :show-modal="showAddOfferTrackingLink" close>
        <add-offer-tracking-link
          :offer-id="offer.id"
          :corporate-enabled="offerCorporateEnabled"
          @success="offerTrackingLinkCreated"
          @close="closeAddOfferTrackingLinkModal"
        ></add-offer-tracking-link>
      </modal-action>
      <offers-tracking-links-table v-if="offerTrackingLinks.length" :items="offerTrackingLinks" :offer-id="offer.id" />
      <label-none v-if="offerTrackingLinks.length === 0">{{ $t('system.none') }}</label-none>
    </template>
  </card>
</template>

<script>
import { security, disableWithError, actionErrorTrackable } from '@/mixins';
import OffersTrackingLinksTable from './OffersTrackingLinksTable';
import AddOfferTrackingLink from '../actions/AddOfferTrackingLink';
import { mapGetters } from 'vuex';

export default {
  name: 'offers-tracking-links',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: {
    OffersTrackingLinksTable,
    AddOfferTrackingLink,
  },
  data() {
    return {
      showAddOfferTrackingLink: false,
    };
  },
  watch: {
    async offer() {
      await this.fetchOfferTrackingLinks();
    },
  },
  computed: {
    ...mapGetters('offers', ['offer', 'offerTrackingLinks', 'offerTrackingLinksPageNumber', 'offerTrackingLinksItemCount']),
    offerCorporateEnabled() {
      return _.get(this.offer, 'corporate.enabled', false);
    },
  },
  methods: {
    async fetchOfferTrackingLinks() {
      await this.executeAction({ action: 'offers/listOfferTrackingLinks' }, { id: this.offer.id, page: 1 });
    },
    showAddOfferTrackingLinkModal() {
      this.showAddOfferTrackingLink = true;
    },
    async offerTrackingLinkCreated() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: `offers.offers_tracking_links.add_modal.success` });
      this.showAddOfferTrackingLink = false;
      await this.fetchOfferTrackingLinks();
    },
    closeAddOfferTrackingLinkModal() {
      this.showAddOfferTrackingLink = false;
    },
  },
};
</script>
