<template>
  <div>
    <v-layout row>
      <v-flex xs12>
        <h4 class="business-access">{{ $t('user.user_access_table.type.business') }}</h4>
        <business-access-table v-if="businessAccesses.length" :items="businessAccesses"></business-access-table>
        <p v-else class="info-not-available">{{ $t('system.none') }}</p>
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex xs12>
        <h4>{{ $t('user.user_access_table.type.offer') }}</h4>
        <offer-access-table v-if="offerAccesses.length" :items="offerAccesses"></offer-access-table>
        <p v-else class="info-not-available">{{ $t('system.none') }}</p>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { security, disableWithError, actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import BusinessAccessTable from './components/BusinessAccessTable';
import OfferAccessTable from './components/OfferAccessTable';

export default {
  name: 'user-accesses-table',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { BusinessAccessTable, OfferAccessTable },
  props: {
    items: {
      type: Array,
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('account', ['account', 'businessAccesses', 'offerAccesses']),
  },
  methods: {
    generateLink(item) {
      return `${item.name[this.currentLocale]} (${item.id})`;
    },
    async fetchBusinessAccesses() {
      await this.executeAction({ action: 'account/listAccountAccesses' }, this.account.contact.email.value);
    },
  },
};
</script>

<style scoped lang="stylus">
.business-access {
  padding-top: 0;
}
</style>
