<template>
  <card :title="$t('workstation.details.workstation_information_section.title')" icon="fal fa-store">
    <template slot="headerZoneRight">
      <label-text :label="`${$t('workstation.details.workstation_information_section.id')}`" :value="workstation.id">
        <p slot="labelTextLink" class="label-text--value">
          <v-layout row>
            {{ workstation.id }}
            <button class="clipboard" v-clipboard:copy="workstation.id ? workstation.id : ''">
              <v-icon small>file_copy</v-icon>
            </button>
          </v-layout>
        </p>
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="$t('workstation.details.workstation_information_section.name')" :value="workstation.name" />

      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text
        :label="$t('workstation.details.workstation_information_section.ip_restricted')"
        :value="$t(`workstation.ip_restricted.${workstation.ip_restricted}`)"
      />

      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text
        :label="$t('workstation.details.workstation_information_section.date_created')"
        :value="workstation.creation_date | formatShortDateInverted | orNotAvailable"
      />

      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="$t('workstation.details.workstation_information_section.status')">
        <workstation-status-view slot="labelTextLink" :status="workstation.status" />
      </label-text>

      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <link-secondary-action slot="labelTextLink">
        <button-secondary-actions :text="$t(`workstation.details.workstation_information_section.actions`)" :actions="actions" />
      </link-secondary-action>

      <modal-action :show-modal="showEditModal"
        ><update-workstation-action
          v-if="showEditModal"
          avoid-rendering
          :workstation="workstation"
          @close="closeEditModal"
          @updated="editSuccess"
        ></update-workstation-action
      ></modal-action>

      <modal-confirm
        :show-modal="showActivateModal"
        :title="$t('workstation.activate_action.confirm.title')"
        context="activate"
        :text="$t('workstation.activate_action.confirm.text')"
        :cancel_text="'workstation.activate_action.confirm.button_cancel'"
        :submit_text="'workstation.activate_action.confirm.button_submit'"
        :submit_working="working"
        @submit="activate"
        @close="closeActivateModal"
      ></modal-confirm>
      <modal-confirm
        :show-modal="showDeactivateModal"
        :title="$t('workstation.deactivate_action.confirm.title')"
        context="deactivate"
        :text="$t('workstation.deactivate_action.confirm.text')"
        :cancel_text="'workstation.deactivate_action.confirm.button_cancel'"
        :submit_text="'workstation.deactivate_action.confirm.button_submit'"
        :submit_working="working"
        @submit="deactivate"
        @close="closeDeactivateModal"
      ></modal-confirm>
      <modal-confirm
        :show-modal="showSuspendModal"
        :title="$t('workstation.suspend_action.confirm.title')"
        context="suspend"
        :text="$t('workstation.suspend_action.confirm.text')"
        :cancel_text="'workstation.suspend_action.confirm.button_cancel'"
        :submit_text="'workstation.suspend_action.confirm.button_submit'"
        :submit_working="working"
        @submit="suspend"
        @close="closeSuspendModal"
      ></modal-confirm>
    </template>
    <template slot="content">
      <label-text :label="`${$t('workstation.details.workstation_information_section.allowed_cidrs')}`" :value="cidrsAsString" />

      <label-text :label="`${$t('workstation.details.workstation_information_section.establishments')}`">
        <p v-for="(establishment, index) in workstation.establishments" :key="index" slot="labelTextLink" class="label-text--value">
          <router-link :to="{ name: 'establishment_details', params: { establishmentId: establishment.id } }">
            {{ establishment.id }} ({{ establishment.business_name }})
          </router-link>
        </p>
      </label-text>

      <label-text
        :label="`${$t('workstation.details.workstation_information_section.registration_clerk')}`"
        :value="workstation.registration_clerk_id"
      >
        <p slot="labelTextLink" class="label-text--value">
          <router-link
            v-if="workstation.registration_clerk_id"
            :to="{ name: 'clerk_details', params: { clerkId: workstation.registration_clerk_id } }"
          >
            {{ workstation.registration_clerk_username }} ({{ workstation.registration_clerk_id }})
          </router-link>
          <label-none v-else>{{ $t('system.na') }}</label-none>
        </p>
      </label-text>

      <label-text :label="`${$t('workstation.details.workstation_information_section.last_access_date')}`" :value="workstation.last_access_date">
        <p slot="labelTextLink" class="label-text--value">
          <span v-if="workstation.last_access_date">{{
            workstation.last_access_date
              | formatShortDateTimeNoSecondsInverted({ withAgo: true, returnNullIfNoDate: true, timezone: 'Canada/Eastern' })
          }}</span>
          <label-none v-else>{{ $t('system.na') }}</label-none>
        </p>
      </label-text>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';
import _get from 'lodash/get';
import UpdateWorkstationAction from '../actions/UpdateWorkstationAction.vue';
import WorkstationStatusView from '@/components/workstation/WorkstationStatusView';

export default {
  name: 'workstation-information',
  components: { UpdateWorkstationAction, WorkstationStatusView },
  mixins: [security, actionErrorTrackable, disableWithError],
  data() {
    return {
      showEditModal: false,
      showActivateModal: false,
      showDeactivateModal: false,
      showSuspendModal: false,
      actions: [
        {
          slotName: 'EditModal',
          name: this.$t('workstation.details.workstation_information_section.edit'),
          action: () => {
            this.openEditModal();
          },
          isAvailable: () => this.canManageOffer(), // TODO change
          isDisabled: () => {
            return !this.showEditFeature();
          },
        },
        {
          slotName: 'suspendModal',
          name: this.$t('workstation.details.workstation_information_section.suspend'),
          action: () => {
            this.openSuspendModal();
          },
          isAvailable: () => this.canManageOffer(), // TODO change
          isDisabled: () => !this.showSuspendFeature(),
        },
        {
          slotName: 'activateModal',
          name: this.$t('workstation.details.workstation_information_section.activate'),
          action: () => {
            this.openActivateModal();
          },
          isAvailable: () => this.canManageOffer(), // TODO change
          isDisabled: () => !this.showActivateFeature(),
        },
        {
          slotName: 'deactivateModal',
          name: this.$t('workstation.details.workstation_information_section.deactivate'),
          action: () => {
            this.openDeactivateModal();
          },
          isAvailable: () => this.canManageOffer(), // TODO change
          isDisabled: () => !this.showDeactivateFeature(),
        },
      ],
    };
  },
  computed: {
    ...mapGetters('partnerSecurity', ['workstation']),
    cidrsAsString() {
      if (this.workstation && this.workstation.allowed_cidrs && this.workstation.allowed_cidrs.length) {
        return this.workstation.allowed_cidrs.join(', ');
      }
      return '';
    },
  },
  methods: {
    openEditModal() {
      this.showEditModal = true;
    },
    closeEditModal() {
      this.showEditModal = false;
    },
    async editSuccess() {
      this.showEditModal = false;
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'workstation.update_action.success' });
      this.closeEditModal();
    },
    openActivateModal() {
      this.showActivateModal = true;
    },
    closeActivateModal() {
      this.showActivateModal = false;
    },
    async activate() {
      await this.executeAction(
        {
          action: 'partnerSecurity/activateWorkstation',
          name: 'activate',
          success: this.activateSuccess,
        },
        this.workstation.id,
      );
    },
    async activateSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'workstation.activate_action.success' });
      this.closeActivateModal();
    },
    openDeactivateModal(id) {
      this.showDeactivateModal = true;
    },
    closeDeactivateModal() {
      this.showDeactivateModal = false;
    },
    async deactivate() {
      await this.executeAction(
        {
          action: 'partnerSecurity/deactivateWorkstation',
          name: 'deactivate',
          success: this.deactivateSuccess,
        },
        this.workstation.id,
      );
    },
    async deactivateSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'workstation.deactivate_action.success' });
      this.closeDeactivateModal();
    },
    openSuspendModal(id) {
      this.showSuspendModal = true;
    },
    closeSuspendModal() {
      this.showSuspendModal = false;
    },
    async suspend() {
      await this.executeAction(
        {
          action: 'partnerSecurity/suspendWorkstation',
          name: 'suspend',
          success: this.suspendSuccess,
        },
        this.workstation.id,
      );
    },
    async suspendSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'workstation.suspend_action.success' });
      this.closeSuspendModal();
    },
    extractCardProgramValue(item) {
      const isHavingClassical = _get(item, 'partner_card_packages.en');
      const isHavingInstant = _get(item, 'partner_instant_card_packages.en');

      if (isHavingClassical && isHavingInstant) {
        return this.$t('workstation.workstations_table.card_selector.values.classical_or_instant');
      } else if (isHavingClassical) {
        return this.$t('workstation.workstations_table.card_selector.values.classical_only');
      } else if (isHavingInstant) {
        return this.$t('workstation.workstations_table.card_selector.values.instant_only');
      }
      return null;
    },
    showEditFeature() {
      return true;
    },
    showActivateFeature() {
      return this.workstation.status === 'suspended';
    },
    showDeactivateFeature() {
      return this.workstation.status !== 'deactivated';
    },
    showSuspendFeature() {
      return this.workstation.status === 'active';
    },
  },
};
</script>
