<template>
  <card :title="$t('wallet_definition.details.logs_section.title')" icon="">
    <template slot="content">
      <wallet-definition-logs-table :logs="logs" />
      <label-none v-if="!hasLogs()">{{ $t('system.none') }}</label-none>
    </template>
  </card>
</template>

<script>
import { security, disableWithError, actionErrorTrackable } from '@/mixins';
import WalletDefinitionLogsTable from './WalletDefinitionLogsTable';

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'wallet-definition-logs',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { WalletDefinitionLogsTable },
  data: () => {
    return {
      logs: [],
    };
  },
  props: {
    walletDefinitionId: {
      type: String,
      required: true,
    },
  },
  watch: {
    walletDefinition() {
      this.getLogsSafe();
    },
  },
  created() {
    this.getLogsSafe();
  },
  computed: {
    ...mapGetters('walletDefinition', ['walletDefinition']),
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    async clear() {
      this.$validator.reset();
    },
    hasLogs() {
      return this.logs.length > 0;
    },
    async getLogsSafe() {
      try {
        const logs = await this.executeAction({ action: 'walletDefinition/getLogs' }, this.walletDefinitionId);
        this.logs = logs;
      } catch (e) {
        console.log(e.stack); // eslint-disable-line no-console
      }
    },
  },
};
</script>
