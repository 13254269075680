<template>
  <view-main title="page_title.clerk_details">
    <four-levels-breadcrumbs
      slot="content"
      :top-label="$t('navigation.spaces')"
      :top-to="{ name: 'spaces' }"
      :second-label="spaceName"
      :second-to="{ name: 'space_details', params: { spaceId } }"
      :third-label="$t('navigation.clerks')"
      :last-label="clerk.name"
    />
    <clerk-information slot="content"></clerk-information>
  </view-main>
</template>

<script>
import { actionErrorTrackable, renderErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import ClerkInformation from './detail/ClerkInformation.vue';

export default {
  name: 'clerk-detail-view',
  mixins: [renderErrorTrackable, actionErrorTrackable],
  components: { ClerkInformation },
  props: {
    clerkId: {
      type: String,
      required: true,
    },
    spaceId: {
      type: String,
      required: true,
    },
  },
  data() {
    return { spaceName: '' };
  },
  computed: {
    ...mapGetters('partnerSecurity', ['clerk']),
  },
  async mounted() {
    await this.fetch();
  },
  watch: {
    async clerkId() {
      return this.fetch();
    },
  },
  methods: {
    async fetch() {
      const clerk = await this.executeAction({ action: 'partnerSecurity/getClerk' }, this.clerkId);
      if (!clerk) {
        this.$router.replace({ name: 'notFound' });
      }

      const space = await this.executeAction({ action: 'space/getSpace' }, this.spaceId);
      if (space) {
        this.spaceName = space.name;

        await this.executeAction({ action: 'space/listAllActiveSpacePartnerEstablishments' }, { partner_id: space.partner_id });
      }
    },
  },
};
</script>
