<template>
  <view-main title="page_title.vouchers">
    <v-flex slot="content" class="data-section">
      <h1 hidden>{{ $t('dashboards.vouchers.title') }}</h1>
      <dashboard id-name="vouchers_report" :timerange="timerange" />
    </v-flex>
  </view-main>
</template>

<script>
import { renderErrorTrackable } from '@/mixins';
import Dashboard from './Dashboard.vue';

export default {
  name: 'vouchers-report-view',
  mixins: [renderErrorTrackable],
  components: { Dashboard },
  data() {
    return {
      timerange: null,
    };
  },
  created() {
    if (this.$route.query.timerange) {
      this.timerange = this.$route.query.timerange;
    }
  },
};
</script>
