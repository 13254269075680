<template>
  <card icon="" :title="`${$t('change_request_history_section.title')}`" class="hc-change-history-section">
    <template slot="headerZoneRight">
      <label-text :label="`${$t('change_request_history_section.change_count')}`" :value="changeRequestLog.length"></label-text>
      <div v-if="showCreateChangeRequestUrlFeature()" class="card-header-divider--vertical hidden-xs-only">
        <v-divider vertical></v-divider>
      </div>

      <label-text v-if="showCreateChangeRequestUrlFeature()" :label="`${$t('change_request_history_section.action')}`" value="3">
        <link-secondary-action slot="labelTextLink" @click="showModalsSequenceConfirmChangesReqest">{{
          $t('change_request_history_section.create_url')
        }}</link-secondary-action>
      </label-text>

      <div v-if="showSendChangeRequestFeature()" class="card-header-divider--vertical hidden-xs-only">
        <v-divider vertical></v-divider>
      </div>

      <label-text v-if="showSendChangeRequestFeature()" :label="`${$t('change_request_history_section.action')}`" value="3">
        <link-secondary-action slot="labelTextLink" @click="showModalSendChangeRequest">{{
          $t('change_request_history_section.send_change_request')
        }}</link-secondary-action>
      </label-text>
    </template>
    <template slot="content">
      <label-none v-if="shouldDisplayNone">{{ $t('change_request_history_section.none_found') }}</label-none>

      <template v-for="(item, index) in changeRequestLog">
        <v-layout row wrap :key="item.id">
          <v-flex xs12 md7>
            <layout-message :key="item.id" :date="item.creation_date">
              <app-name slot="author" :app_key="item.created_by_app_key"></app-name>
              <v-layout column slot="content">
                <v-flex xs12 class="change-request-history--changes-list">
                  <span>{{ orderedRequestedChanges(item.requested_changes) }}</span>
                </v-flex>
                <v-flex xs12 class="change-request-history--link">
                  <span>
                    <a :href="selfserveChangeRequestUrl(item.token)" target="_blank">{{ selfserveChangeRequestUrl(item.token) }}</a>
                  </span>
                  <button class="clipboard" v-clipboard:copy="selfserveChangeRequestUrl(item.token)">
                    <v-icon small>file_copy</v-icon>
                  </button>
                </v-flex>
              </v-layout>
            </layout-message>
          </v-flex>

          <v-flex xs12 md5>
            <v-layout row class="change-request-history--content-row-upper-right" wrap justify-end>
              <label-text :label="`${$t('change_request_history_section.id')}`" :value="item.id"></label-text>
              <label-text
                :label="`${$t('change_request_history_section.status_title')}`"
                :class-array="[`change-request-history--status-${item.status}`]"
                :value="$t(`change_request_history_section.status.${item.status}`)"
              ></label-text>
              <label-text
                :label="`${$t('change_request_history_section.outcome_title')}`"
                :class-array="[`change-request-history--outcome-${item.outcome}`]"
                :value="
                  item.outcome ? $t(`change_request_history_section.outcome.${item.outcome}`) : $t(`change_request_history_section.outcome.undefined`)
                "
              ></label-text>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-divider slot="divider" v-if="displayDivider(index)" :key="index"></v-divider>
      </template>

      <modal-action v-if="showCreateChangeRequestUrlFeature()" :show-modal="showCreateChangeRequestUrlModal">
        <create-change-request-url
          :application-id="application.card_application_id"
          @close="closeModalCreateChangeRequestUrl"
          @action="changeRequestUrlReloadPage"
        />
      </modal-action>

      <template slot="duplicateCard">
        <modal-action :show-modal="showDuplicateComfirmChangeRequestModal">
          <modal-confirm
            :show-modal="showDuplicateComfirmChangeRequestModal"
            :title="$t('application.create_change_application_request_action.duplicate_card_modal.title')"
            :text="$t('application.create_change_application_request_action.duplicate_card_modal.text')"
            :cancel_text="'application.create_change_application_request_action.duplicate_card_modal.cancel_text'"
            :submit_text="'application.create_change_application_request_action.duplicate_card_modal.submit_text'"
            @submit="submitModalDuplicateComfirmChangeRequestModal"
            @close="closeModalDuplicateComfirmChangeRequestModal"
            context="duplicateCard"
          ></modal-confirm>
        </modal-action>
      </template>

      <modal-action v-if="showSendChangeRequestFeature()" :show-modal="showSendChangeRequestModal">
        <send-change-application-request-action
          :application-id="application.card_application_id"
          :kyc="kyc"
          :application-kyc="application.kyc"
          @close="closeModalSendChangeRequest"
          @action="changeRequestReloadPage"
        />
      </modal-action>
    </template>
  </card>
</template>

<script>
import selfserveChangeLogBuilder from '@/utils/ui/selfserve-change-log-builder';
import { security } from '@/mixins';
import CreateChangeRequestUrl from '@/components/application/detail/CreateChangeRequestUrl';
import SendChangeApplicationRequestAction from '@/components/application/actions/SendChangeApplicationRequestAction';

export default {
  name: 'change-request-history',
  components: { CreateChangeRequestUrl, SendChangeApplicationRequestAction },
  mixins: [security],
  props: {
    changeRequestLog: {
      type: Array,
      required: false,
    },
    kyc: {
      type: Object,
      required: false,
    },
    fieldHeadings: {
      type: String,
      required: false,
    },
    application: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      field_order: ['first_name', 'last_name', 'date_of_birth', 'address', 'document_name_address', 'document_name_date_of_birth'],
      requested_changes_order: [
        'first_name_verification_required',
        'last_name_verification_required',
        'dob_verification_required',
        'address_verification_required',
        'document_for_address_required',
        'document_for_dob_required',
        'document_required',
      ],
      showCreateChangeRequestUrlModal: false,
      showDuplicateComfirmChangeRequestModal: false,
      showSendChangeRequestModal: false,
    };
  },
  computed: {
    shouldDisplayNone() {
      return this.changeRequestLog.length === 0;
    },
  },
  methods: {
    displayDivider(index) {
      return index + 1 < this.changeRequestLog.length;
    },
    orderedRequestedChanges(changes) {
      return selfserveChangeLogBuilder.orderChanges(changes, this.requested_changes_order, this);
    },
    selfserveChangeRequestUrl(token) {
      return selfserveChangeLogBuilder.buildChangeRequestUrl(token);
    },
    showCreateChangeRequestUrlFeature() {
      return (
        this.isAgentInternal() &&
        this.application.status === 'pending' &&
        (_.isEmpty(this.changeRequestLog) || this.changeRequestLog[0].status === 'resolved')
      );
    },
    showModalCreateChangeRequestUrl() {
      this.showCreateChangeRequestUrlModal = true;
    },
    closeModalCreateChangeRequestUrl() {
      this.showCreateChangeRequestUrlModal = false;
    },

    showModalsSequenceConfirmChangesReqest() {
      const reasonCode = _.get(this.application, 'kyc.reason.code');
      if (reasonCode && reasonCode === 'card.active.already.in.card_program') {
        this.showModalDuplicateComfirmChangeRequestModal();
      } else {
        this.showModalCreateChangeRequestUrl();
      }
    },

    submitModalDuplicateComfirmChangeRequestModal() {
      this.closeModalDuplicateComfirmChangeRequestModal();
      this.showModalCreateChangeRequestUrl();
    },

    showModalDuplicateComfirmChangeRequestModal() {
      this.showDuplicateComfirmChangeRequestModal = true;
    },

    closeModalDuplicateComfirmChangeRequestModal() {
      this.showDuplicateComfirmChangeRequestModal = false;
    },

    showSendChangeRequestFeature() {
      return (
        this.canManageApplication() &&
        this.application.status === 'pending' &&
        (_.isEmpty(this.changeRequestLog) || this.changeRequestLog[0].status === 'resolved')
      );
    },
    showModalSendChangeRequest() {
      this.showSendChangeRequestModal = true;
    },
    closeModalSendChangeRequest() {
      this.showSendChangeRequestModal = false;
    },

    changeRequestUrlReloadPage() {
      this.showCreateChangeRequestUrlModal = false;
    },
    changeRequestReloadPage() {
      this.showSendChangeRequestModal = false;
    },
  },
};
</script>

<style scoped lang="stylus">
@import './../../../styles/variables/custom-variables.styl';

.change-request-history--content-row-upper-right {
  flex-wrap: wrap;
  margin-bottom: 6px;
  margin-top: $p-margin-bottom;
  > div {
    display: flex;
    flex-direction: row;
    margin-left: $label-text-margin--header;
  }
}

.change-request-history--changes-list {
  margin-top: 6px;
}

.change-request-history--link {
  margin-bottom: 10px;
  span {
    display: inline-block;
    width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    }
}

.clipboard .v-icon {
  bottom: 0.4em;
}

@media screen and (max-width: 960px) {
  .change-request-history--content-row-upper-right {
    justify-content: start;
    margin-top: 0;
  }
  .change-request-history--content-row-upper-right > div:not(.v-dialog__container) {
    margin-left: 0;
    margin-right: $label-text-margin--header;
  }
}
</style>
