<template>
  <v-card v-if="isReady" data-test="selfserve-change-application-request">
    <v-card-text>
      <user-list-item-part v-if="task.account_id" :account-id="task.account_id" data-test="user" />

      <card-application-list-item-part
        v-if="task.relations.card_application_id"
        :card-application-id="task.relations.card_application_id"
        data-test="card-application"
      />

      <change-application-request-list-item-part
        v-if="task.relations.card_application_id"
        :card-application-id="task.relations.card_application_id"
        :change-request-id="task.relations.change_request_id"
        data-test="change-application-request"
      />

      <selfserve-change-application-review-resolution-part
        v-if="isResolved"
        :card-application-id="task.relations.card_application_id"
        :change-request-id="task.relations.change_request_id"
        :task-id="taskId"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable, security } from '@/mixins';
import UserListItemPart from './parts/UserListItemPart';
import CardApplicationListItemPart from './parts/CardApplicationListItemPart';
import ChangeApplicationRequestListItemPart from './parts/ChangeApplicationRequestListItemPart';
import SelfserveChangeApplicationReviewResolutionPart from './resolution-parts/SelfserveChangeApplicationReviewResolutionPart';

export default {
  name: 'selfserve-change-application-review',
  mixins: [actionErrorTrackable, security],
  components: {
    UserListItemPart,
    CardApplicationListItemPart,
    ChangeApplicationRequestListItemPart,
    SelfserveChangeApplicationReviewResolutionPart,
  },
  props: {
    taskId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    task: null,
  }),
  async mounted() {
    this.task = await this.executeAction({ action: 'task/getTask' }, this.taskId);
  },
  computed: {
    ...mapGetters('selfserve', ['documents']),
    isResolved() {
      return this.task && this.task.status === 'resolved';
    },
    isReady() {
      return !!(this.task && this.task.id);
    },
  },
  methods: {},
};
</script>
