<template>
  <div>
    <alert-system-error
      :error="currentError"
      :text="getErrorTranslation()"
      v-if="currentError && currentError.errorType === 'system' && show"
    ></alert-system-error>

    <alert-normal-error
      :text="getErrorTranslation()"
      v-else-if="currentError && currentError.errorType === 'http' && getErrorTranslation() !== currentError.code"
    ></alert-normal-error>

    <alert-normal-error :text="getErrorTranslation()" v-else-if="currentError && currentError.errorType === 'validation'"></alert-normal-error>

    <alert-system-error
      :error="currentError"
      :text="getErrorTranslation()"
      v-if="currentError && currentError.errorType === 'http' && getErrorTranslation() === currentError.code"
    ></alert-system-error>

    <alert-app-error
      :stack="currentError.stack"
      :text="getErrorTranslation()"
      v-else-if="currentError && currentError.errorType === 'app' && show"
    ></alert-app-error>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'error',
  props: {
    context: {
      type: String,
    },
  },
  computed: {
    ...mapGetters('ui', ['error']),
    displayErrorStackUserFriendly() {
      return `<v-layout column>${_.reduce(
        this.currentError.stack.split('\n'),
        (acc, e) => {
          acc += `<div>${e}</div>`;
          return acc;
        },
        '',
      )}</v-layout>`;
    },
  },
  watch: {
    error: function() {
      this.currentError = this.error[this.context];
      this.show = !_.isEmpty(this.currentError) && !_.isUndefined(this.currentError);
      if (!this.currentError) {
        this.showErrorStack = false;
      }
    },
  },
  data() {
    return {
      show: false,
      showErrorStack: false,
      currentError: {},
    };
  },
  methods: {
    showStack() {
      this.showErrorStack = !this.showErrorStack;
    },
    getErrorTranslation() {
      return this.$t(this.currentError.code, { message: this.currentError.msg });
    },
  },
};
</script>
