<template>
  <card :title="`${$t('change_history_section.title')}`" class="hc-change-history-section">
    <template slot="headerZoneRight">
      <label-text :label="`${$t('change_history_section.change_count')}`" :value="updateLogs.length"></label-text>
    </template>

    <template slot="content">
      <label-none v-if="shouldDisplayNone">{{ $t('change_history_section.none_found') }}</label-none>

      <template v-for="(item, index) in updateLogs">
        <layout-message :key="item.id" :date="item.created">
          <app-name slot="author" :app_key="item.app_key"></app-name>

          <v-layout column slot="content">
            <v-flex v-for="(field, index) in item.change_keys" :key="`${item.id}-${index}`">
              <v-layout column>
                <h5>{{ $t(`${fieldHeadings}.${field}`) }}</h5>

                <v-flex>
                  <v-layout row wrap align-center>
                    <v-flex class="application-change-history--before">
                      <span v-if="getBeforeChange(item, field) !== undefined && getBeforeChange(item, field) !== null">{{
                        getBeforeChange(item, field)
                      }}</span
                      ><span class="info-not-available" v-else>{{ $t('change_history_section.no_value') }}</span></v-flex
                    >

                    <v-flex class="application-change-history--arrow"> <v-icon small>fa-arrow-right</v-icon> </v-flex>

                    <v-flex class="application-change-history--after">
                      <span v-if="getChange(item, field) !== undefined && getChange(item, field) !== null">{{ getChange(item, field) }}</span
                      ><span class="info-not-available" v-else>{{ $t('change_history_section.no_value') }}</span></v-flex
                    >
                  </v-layout>
                  <br />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-divider slot="divider" v-if="displayDivider(index)" :key="index"></v-divider>
        </layout-message>
      </template>
    </template>
  </card>
</template>

<script>
import get from 'lodash/get';

export default {
  name: 'change-history',
  props: {
    type: {
      type: Object,
      required: false,
    },
    updateLogs: {
      type: Array,
      required: false,
    },
    fieldHeadings: {
      type: String,
      required: false,
    },
  },
  computed: {
    shouldDisplayNone() {
      return this.updateLogs.length === 0;
    },
  },
  methods: {
    getChange(item, key) {
      return get(item.changes, key);
    },
    getBeforeChange(item, key) {
      return get(item.before_change, key);
    },
    displayDivider(index) {
      return index + 1 < this.updateLogs.length;
    },
  },
};
</script>
