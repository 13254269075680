<template>
  <card :title="$t('inventory.details.notes_section.title')" icon="">
    <template slot="headerZoneRight">
      <label-text
        class="text-xs-center"
        :label="`${$t('inventory.details.notes_section.count')}`"
        :value="listInventoryNotesItemCount || '0'"
      ></label-text>
      <div v-if="showAddInventoryNoteFeature()" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text v-if="showAddInventoryNoteFeature()" :label="`${$t('inventory.details.notes_section.action')}`" value="3">
        <link-secondary-action slot="labelTextLink" @click="openAddInventoryNoteModal">
          {{ $t('inventory.details.notes_section.create_note') }}
        </link-secondary-action>
      </label-text>
    </template>
    <template slot="content">
      <inventory-notes-table
        v-if="inventoryNotes.length"
        :items="inventoryNotes"
        :inventory-id="inventory.id"
        @inventory_note_delete_action="openDeleteInventoryNoteModal"
      ></inventory-notes-table>
      <label-none v-if="inventoryNotes.length === 0">{{ $t('inventory.details.notes_section.no_found') }}</label-none>
      <modal-action :show-modal="showAddInventoryNoteModal"
        ><create-inventory-note-action
          :v-if="showAddInventoryNoteModal"
          :inventory-id="inventory.id"
          @close="closeAddInventoryNoteModal"
          @created="addInventoryNoteSuccess"
        ></create-inventory-note-action
      ></modal-action>
      <modal-action :show-modal="showDeleteInventoryNoteModal"
        ><delete-inventory-note-action
          :v-if="showDeleteInventoryNoteModal"
          :inventory-id="inventory.id"
          :note-id="noteIdToDelete"
          @close="closeDeleteInventoryNoteModal"
          @deleted="deleteInventoryNoteSuccess"
        ></delete-inventory-note-action
      ></modal-action>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import CreateInventoryNoteAction from '../actions/CreateInventoryNoteAction';
import DeleteInventoryNoteAction from '../actions/DeleteInventoryNoteAction';
import InventoryNotesTable from './InventoryNotesTable';
import { mapGetters } from 'vuex';

export default {
  name: 'inventory-notes',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { InventoryNotesTable, CreateInventoryNoteAction, DeleteInventoryNoteAction },
  data() {
    return {
      showAddInventoryNoteModal: false,
      showDeleteInventoryNoteModal: false,
      noteIdToDelete: null,
      noteToDelete: {},
    };
  },
  computed: {
    ...mapGetters('inventory', ['inventoryNotes', 'inventory', 'listInventoryNotesItemCount']),
    ...mapGetters('ui', ['currentLocale']),
  },
  watch: {
    async inventory() {
      await this.fetchInventoryNotes();
    },
  },
  methods: {
    openAddInventoryNoteModal() {
      this.showAddInventoryNoteModal = true;
    },
    closeAddInventoryNoteModal() {
      this.showAddInventoryNoteModal = false;
    },
    openDeleteInventoryNoteModal(noteId) {
      this.noteIdToDelete = noteId;
      this.noteToDelete = this.inventoryNotes.find((l) => l.id === noteId);
      this.showDeleteInventoryNoteModal = true;
    },
    closeDeleteInventoryNoteModal() {
      this.noteIdToDelete = null;
      this.noteToDelete = {};
      this.showDeleteInventoryNoteModal = false;
    },
    async deleteInventoryNoteSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'inventory.delete_note_action.success' });
      this.closeDeleteInventoryNoteModal();
    },
    async addInventoryNoteSuccess() {
      await this.fetchInventoryNotes();
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'inventory.create_note_action.success' });
      this.closeAddInventoryNoteModal();
    },
    showAddInventoryNoteFeature() {
      return this.canManageOffer(); // TODO change permission
    },
    async fetchInventoryNotes() {
      await this.executeAction({ action: 'inventory/listInventoryNotes' }, { inventory_id: this.inventory.id });
    },
  },
};
</script>
