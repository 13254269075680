import Vue from 'vue';

const service = {
  async getAccountAmlStatus(account_id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/aml/v1/accounts/${account_id}/card-aml-status`);
    return response.data.data;
  },

  async setAccountAmlStatus(account_id, amlStatusObject) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/aml/v1/accounts/${account_id}/card-aml-status`, {
      pep_matched: amlStatusObject.pep_matched,
      sanctions_matched: amlStatusObject.sanctions_matched,
    });

    return response.data.data.status;
  },

  async getAmlStatus(publicToken) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/aml/v1/card-aml-status/${publicToken}`);
    return response.data.data;
  },

  async setAmlStatus(publicToken, amlStatusObject) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/aml/v1/card-aml-status/${publicToken}`, {
      pep_matched: amlStatusObject.pep_matched,
      sanctions_matched: amlStatusObject.sanctions_matched,
      card_program_key: amlStatusObject.card_program_key,
    });

    return response.data.data.status;
  },
};

export default service;
