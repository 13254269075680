import Vue from 'vue';

export default {
  namespaced: true,
  state: {},
  actions: {
    async generateQrCodeFromUrl({}, { url }) {
      return Vue.prototype.$services.tools.generateQRCodeFromUrl(url);
    },
  },
};
