<template>
  <v-flex class="data-section">
    <card-information
      @card-change="cardChange"
      :account-id="card.account_id"
      :card="card"
      :cards="cards"
      :aml-status="amlStatus"
      :partner-card-renew-package="partnerCardRenewPackage"
    />

    <card-limits-and-balances :card="card" />

    <card-wallet-transactions :card="card" v-if="isDev()" />

    <aml-document-list
      v-if="classicProgram && (canUploadDocumentCard() || canManageCard() || canManageAmlProgram())"
      id="#documents"
      from-view="card"
      :public-token="card.public_token"
      :cardholder-key="card.cardholder.key"
      :account-id="card.account_id"
      :card-application-id="card.cardholder.card_application_id || null"
      :card-program-key="card.card_program_key"
    ></aml-document-list>

    <change-history
      v-if="classicProgram"
      :type="card"
      :update-logs="updateLogs"
      :field-headings="'card.update_cardholder_info_action.fields'"
    ></change-history>

    <card-comments-list
      v-if="canCommentCard() || canManageCard()"
      id="#comments"
      :public_token="card.public_token"
      :account_id="card.account_id"
    ></card-comments-list>

    <card-aml-profile
      v-if="classicProgram && canManageAmlProgram()"
      :card="card"
      :aml-status="amlStatus"
      :aml-status-logs-and-assessment-checks="amlStatusLogsAndAssessmentChecks"
      :card-applicant="application.card_applicant"
    />

    <card-aml-notes-list
      id="#notes"
      v-if="canManageAmlProgram()"
      :card-application-id="card.cardholder.card_application_id || null"
      :public-token="card.public_token"
      :cardholder-key="card.cardholder.key"
      :card-program-key="card.card_program_key"
      :account-id="card.account_id"
    />
  </v-flex>
</template>

<script>
import CardInformation from './detail/CardInformation';
import CardAmlProfile from './detail/CardAmlProfile';
import CardLimitsAndBalances from './detail/CardLimitsAndBalances';
import CardAmlNotesList from './detail/CardAmlNotesList';
import CardCommentsList from './detail/CardCommentsList';
import AmlDocumentList from '@/components/system/AML/AmlDocumentList';
import ChangeHistory from '@/components/system/ChangeHistory';
import CardWalletTransactions from './detail/CardWalletTransactions';
import { security, disableWithError, actionErrorTrackable, hashtag } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'view-card',
  mixins: [security, actionErrorTrackable, disableWithError, hashtag],
  components: {
    CardInformation,
    CardLimitsAndBalances,
    CardAmlProfile,
    CardAmlNotesList,
    ChangeHistory,
    CardCommentsList,
    AmlDocumentList,
    CardWalletTransactions,
  },
  props: {
    account: {
      type: Object,
      required: false,
    },
    card: {
      type: Object,
      required: false,
    },
    cards: {
      type: Array,
      required: false,
    },
    application: {
      type: Object,
      required: false,
    },
    amlStatus: {
      type: Object,
      required: false,
    },
    amlStatusLogsAndAssessmentChecks: {
      type: Object,
      required: false,
    },
    partnerCardRenewPackage: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapGetters('card', ['updateLogs']),
    classicProgram() {
      return this.card && this.card.card_program_type === 'gpr';
    },
  },
  watch: {
    card() {
      this.fetchUpdateLogs();
    },
  },
  async created() {
    await this.fetchUpdateLogs();
  },
  methods: {
    cardChange(publicToken) {
      this.$emit('card-change', publicToken);
    },
    async fetchUpdateLogs() {
      if (this.card.public_token) {
        await this.executeAction({ action: 'card/getUpdateLog' }, this.card.public_token);
      }
    },
  },
};
</script>
