import datetimeFactory from 'datetime-factory';
import moment from 'moment';
import { Joi, validate } from '@/utils/validators/index';

export async function validateUpdateCardApplicantParams(params) {
  const now = datetimeFactory.now().toDate();
  const cardApplicationIdSchema = Joi.string()
    .id()
    .required();
  const cardApplicantSchema = Joi.object()
    .keys({
      first_name: Joi.string()
        .trim()
        .max(20)
        .required(),
      last_name: Joi.string()
        .trim()
        .max(20)
        .required(),
      gender: Joi.string()
        .trim()
        .valid(['female', 'male', 'non_binary', null])
        .optional(),
      date_of_birth: Joi.date()
        .format('YYYY-MM-DD')
        .max(
          moment(now)
            .subtract(18, 'years')
            .toISOString(),
        )
        .required(),
      phone: Joi.string()
        .phone()
        .required(),
      mobile: Joi.string()
        .phone()
        .allow(null),
      email: Joi.string()
        .email()
        .required(),
      occupation: Joi.string()
        .trim()
        .max(50)
        .optional(),
      compliance: Joi.object({
        occupation: Joi.object({
          status: Joi.string()
            .max(4)
            .optional()
            .allow(null),
          sector: Joi.string()
            .max(4)
            .optional()
            .allow(null),
          profession: Joi.string()
            .max(4)
            .optional()
            .allow(null),
        }).optional(),
      }).optional(),
      address: Joi.object({
        street: Joi.string()
          .trim()
          .max(50)
          .required(),
        city: Joi.string()
          .trim()
          .max(50)
          .required(),
        state: Joi.string()
          .length(2)
          .valid(['AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'NT', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'])
          .required(),
        country: Joi.string()
          .length(2)
          .valid(['CA'])
          .required(),
        zip: Joi.string()
          .zip()
          .required(),
      }).required(),
      language: Joi.string().required(),
      settings: Joi.object({
        alerts: Joi.boolean().required(),
      }),
    })
    .required();
  const schema = Joi.object()
    .keys({
      cardApplicationId: cardApplicationIdSchema,
      cardApplicant: cardApplicantSchema,
    })
    .required();

  await validate(params, schema);
}

export async function validateGetUpdateLogParams(params) {
  const schema = Joi.string()
    .id()
    .required();
  await validate(params, schema);
}

export async function validateGetCommentParams(params) {
  const schema = Joi.string()
    .id()
    .required();
  await validate(params, schema);
}

export async function validateCreateCommentParams(params) {
  const schema = Joi.object({
    id: Joi.string().required(),
    comment: Joi.string()
      .required()
      .max(4096),
    account_id: Joi.string().max(20),
  }).required();

  await validate(params, schema);
}

export async function validateListDocumentParams(params) {
  const schema = Joi.string()
    .id()
    .required();
  await validate(params, schema);
}

export async function validateDownloadDocument(params) {
  const schema = Joi.object({
    filename: Joi.string().required(),
    card_application_id: Joi.string()
      .id()
      .required(),
    id: Joi.string()
      .id()
      .required(),
  });

  await validate(params, schema);
}
