<template>
  <card :title="$t('franchise.stores.title')" icon="">
    <template slot="headerZoneRight">
      <label-text class="text-xs-center" :label="`${$t('franchise.stores.count')}`" :value="franchiseStoresItemCount || '0'"></label-text>
    </template>
    <template slot="content">
      <franchise-stores-table v-if="franchiseStores.length" :items="franchiseStores" :franchise-id="franchise.id"></franchise-stores-table>
      <label-none v-if="franchiseStores.length === 0">{{ $t('system.na') }}</label-none>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';
import FranchiseStoresTable from './FranchiseStoresTable.vue';

export default {
  name: 'franchise-stores',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { FranchiseStoresTable },
  data() {
    return {
      pagination: 1,
    };
  },
  watch: {
    async franchise() {
      return this.executeAction({ action: 'franchise/listFranchiseStores' }, { id: this.franchise.id, page: this.franchiseStoresPageNumber });
    },
    franchiseStoresPageNumber() {
      this.pagination = this.franchiseStoresPageNumber;
    },
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'franchise/changeFranchiseStoresPage',
          success: this.success,
        },
        {
          id: this.franchise.id,
          page: newValue,
        },
      );
    },
  },
  computed: {
    ...mapGetters('franchise', ['franchise', 'franchiseStores', 'franchiseStoresPageNumber', 'franchiseStoresItemCount']),
    ...mapGetters('ui', ['currentLocale']),
  },
};
</script>
