<template>
  <div class="ready-status">
    <span v-if="status" :class="`status-${status}`">{{ $t(`ready.status.${status}`) }}</span>
  </div>
</template>

<script>
export default {
  name: 'ready-status-view',
  props: {
    status: {
      type: String,
    },
  },
};
</script>
