import Vue from 'vue';
import _ from 'lodash';

const defaultPage = 1;
const clerksPaginationLimit = 20;
const workstationsPaginationLimit = 20;
const workstationRegistrationRequestsPaginationLimit = 20;

export default {
  namespaced: true,
  state: {
    clerk: {},
    clerkRoleOptions: {
      // NOTE (MAA) : Ensure to sort in alphabetic order the roles array
      clerk: ['clerk'],
      admin: ['admin', 'clerk'],
      superadmin: ['admin', 'clerk', 'superadmin'],
    },
    clerks: [],
    workstation: {},
    workstations: [],
    workstationRegistrationRequest: {},
    workstationRegistrationRequests: [],

    listClerks: {
      pageNumber: defaultPage,
      pageCount: 0,
      itemCount: 0,
    },

    listWorkstationRegistrationRequests: {
      pageNumber: defaultPage,
      pageCount: 0,
      itemCount: 0,
    },

    listWorkstations: {
      pageNumber: defaultPage,
      pageCount: 0,
      itemCount: 0,
    },
  },
  getters: {
    clerk: (state) => state.clerk,
    clerkRoleOptions: (state) => state.clerkRoleOptions,
    clerks: (state) => state.clerks,
    listClerksPageNumber: (state) => state.listClerks.pageNumber,
    listClerksPageCount: (state) => state.listClerks.pageCount,
    listClerksItemCount: (state) => state.listClerks.itemCount,
    workstation: (state) => state.workstation,
    workstations: (state) => state.workstations,
    listWorkstationsPageNumber: (state) => state.listWorkstations.pageNumber,
    listWorkstationsPageCount: (state) => state.listWorkstations.pageCount,
    listWorkstationsItemCount: (state) => state.listWorkstations.itemCount,
    workstationRegistrationRequest: (state) => state.workstationRegistrationRequest,
    workstationRegistrationRequests: (state) => state.workstationRegistrationRequests,
    listWorkstationRegistrationRequestsPageNumber: (state) => state.listWorkstationRegistrationRequests.pageNumber,
    listWorkstationRegistrationRequestsPageCount: (state) => state.listWorkstationRegistrationRequests.pageCount,
    listWorkstationRegistrationRequestsItemCount: (state) => state.listWorkstationRegistrationRequests.itemCount,
  },
  mutations: {
    clerk(state, clerk) {
      state.clerk = clerk;
    },
    clerks(state, clerks) {
      state.clerks = clerks;
    },
    listClerksPageNumber(state, listClerksPageNumber) {
      state.listClerks.pageNumber = listClerksPageNumber;
    },
    listClerksPageCount(state, listClerksPageCount) {
      state.listClerks.pageCount = listClerksPageCount;
    },
    listClerksItemCount(state, listClerksItemCount) {
      state.listClerks.itemCount = listClerksItemCount;
    },
    workstation(state, workstation) {
      state.workstation = workstation;
    },
    workstations(state, workstations) {
      state.workstations = workstations;
    },
    listWorkstationsPageNumber(state, listWorkstationsPageNumber) {
      state.listWorkstations.pageNumber = listWorkstationsPageNumber;
    },
    listWorkstationsPageCount(state, listWorkstationsPageCount) {
      state.listWorkstations.pageCount = listWorkstationsPageCount;
    },
    listWorkstationsItemCount(state, listWorkstationsItemCount) {
      state.listWorkstations.itemCount = listWorkstationsItemCount;
    },
    workstationRegistrationRequest(state, workstationRegistrationRequest) {
      state.workstationRegistrationRequest = workstationRegistrationRequest;
    },
    workstationRegistrationRequests(state, workstationRegistrationRequests) {
      state.workstationRegistrationRequests = workstationRegistrationRequests;
    },
    listWorkstationRegistrationRequestsPageNumber(state, listWorkstationRegistrationRequestsPageNumber) {
      state.listWorkstationRegistrationRequests.pageNumber = listWorkstationRegistrationRequestsPageNumber;
    },
    listWorkstationRegistrationRequestsPageCount(state, listWorkstationRegistrationRequestsPageCount) {
      state.listWorkstationRegistrationRequests.pageCount = listWorkstationRegistrationRequestsPageCount;
    },
    listWorkstationRegistrationRequestsItemCount(state, listWorkstationRegistrationRequestsItemCount) {
      state.listWorkstationRegistrationRequests.itemCount = listWorkstationRegistrationRequestsItemCount;
    },
  },
  actions: {
    async onboardPartner({ dispatch }, params) {
      const partnerSecurityService = Vue.prototype.$services.partnerSecurity;
      const payload = _.omit(params, ['partner_id', 'space_id']);

      let result = await partnerSecurityService.onboardPartner(params.partner_id, payload);
      await dispatch('space/getSpace', params.space_id, { root: true });

      return result;
    },
    async addClerk({ dispatch }, params) {
      const partnerSecurityService = Vue.prototype.$services.partnerSecurity;
      const payload = _.omit(params, ['partner_id', 'space_id']);

      let result = await partnerSecurityService.createClerk(params.partner_id, payload);
      await dispatch('space/getSpace', params.space_id, { root: true });

      return result;
    },
    async getClerk({ commit }, id) {
      const partnerSecurityService = Vue.prototype.$services.partnerSecurity;
      const clerk = await partnerSecurityService.getClerk(id);

      // NOTE (MAA) : Sorting permission to ensure permission matching.
      clerk.roles = clerk.roles.sort();
      commit('clerk', clerk);
      return clerk;
    },
    async listClerks({ state, commit }, { partner_id, page, limit } = {}) {
      const partnerSecurityService = Vue.prototype.$services.partnerSecurity;

      const thePage = page || state.listClerks.pageNumber || 1;
      const theLimit = limit || clerksPaginationLimit;

      const listResults = await partnerSecurityService.listClerks(partner_id, thePage, theLimit);

      commit('clerks', listResults.items);
      commit('listClerksPageNumber', thePage);
      commit('listClerksPageCount', listResults.page_count);
      commit('listClerksItemCount', listResults.item_count);
    },
    async updateClerk({ dispatch }, params) {
      const partnerSecurityService = Vue.prototype.$services.partnerSecurity;
      const { id, ...payload } = params;
      delete payload.locked;
      const result = await partnerSecurityService.updateClerk(id, payload);
      await dispatch('getClerk', id);
      return result;
    },
    async changePasscode({ dispatch }, params) {
      const partnerSecurityService = Vue.prototype.$services.partnerSecurity;
      const payload = { passcode: params.passcode };
      const result = await partnerSecurityService.changePasscode(params.id, payload);
      await dispatch('getClerk', params.id);
      return result;
    },
    async activateClerk({ dispatch }, id) {
      const partnerSecurityService = Vue.prototype.$services.partnerSecurity;
      const result = await partnerSecurityService.activateClerk(id);
      await dispatch('getClerk', id);
      return result;
    },
    async suspendClerk({ dispatch }, id) {
      const partnerSecurityService = Vue.prototype.$services.partnerSecurity;
      const result = await partnerSecurityService.suspendClerk(id);
      await dispatch('getClerk', id);
      return result;
    },
    async deactivateClerk({ dispatch }, id) {
      const partnerSecurityService = Vue.prototype.$services.partnerSecurity;
      const result = await partnerSecurityService.deactivateClerk(id);
      await dispatch('getClerk', id);
      return result;
    },
    async unlockClerk({ dispatch }, id) {
      const partnerSecurityService = Vue.prototype.$services.partnerSecurity;
      const result = await partnerSecurityService.unlockClerk(id);
      await dispatch('getClerk', id);
      return result;
    },
    async workstationAutocompleteSearch({}, { page, limit, keyword, partner_id }) {
      const partnerSecurityService = Vue.prototype.$services.partnerSecurity;
      return partnerSecurityService.searchWorkstations(page, limit, keyword, partner_id);
    },
    async getWorkstation({ commit }, id) {
      const partnerSecurityService = Vue.prototype.$services.partnerSecurity;
      const workstation = await partnerSecurityService.getWorkstation(id);
      commit('workstation', workstation);
      return workstation;
    },
    async addWorkstation({ dispatch }, params) {
      const partnerSecurityService = Vue.prototype.$services.partnerSecurity;
      const payload = _.omit(params, ['partner_id', 'space_id']);

      let result = await partnerSecurityService.createWorkstation(params.partner_id, payload);
      await dispatch('space/getSpace', params.space_id, { root: true });

      return result;
    },
    async listWorkstations({ state, commit }, { partner_id, page, limit } = {}) {
      const partnerSecurityService = Vue.prototype.$services.partnerSecurity;

      const thePage = page || state.listWorkstations.pageNumber || 1;
      const theLimit = limit || workstationsPaginationLimit;

      const listResults = await partnerSecurityService.listWorkstations(partner_id, thePage, theLimit);

      commit('workstations', listResults.items);
      commit('listWorkstationsPageNumber', thePage);
      commit('listWorkstationsPageCount', listResults.page_count);
      commit('listWorkstationsItemCount', listResults.item_count);
    },
    async updateWorkstation({ dispatch }, params) {
      const partnerSecurityService = Vue.prototype.$services.partnerSecurity;
      const { id, ...payload } = params;
      const result = await partnerSecurityService.updateWorkstation(id, payload);
      await dispatch('getWorkstation', id);
      return result;
    },
    async activateWorkstation({ dispatch }, id) {
      const partnerSecurityService = Vue.prototype.$services.partnerSecurity;
      const result = await partnerSecurityService.activateWorkstation(id);
      await dispatch('getWorkstation', id);
      return result;
    },
    async suspendWorkstation({ dispatch }, id) {
      const partnerSecurityService = Vue.prototype.$services.partnerSecurity;
      const result = await partnerSecurityService.suspendWorkstation(id);
      await dispatch('getWorkstation', id);
      return result;
    },
    async deactivateWorkstation({ dispatch }, id) {
      const partnerSecurityService = Vue.prototype.$services.partnerSecurity;
      const result = await partnerSecurityService.deactivateWorkstation(id);
      await dispatch('getWorkstation', id);
      return result;
    },
    async generateRequest({ dispatch }, params) {
      const partnerSecurityService = Vue.prototype.$services.partnerSecurity;
      const { partner_id, workstation_id, ...payload } = params;
      const result = await partnerSecurityService.createWorkstationRegistrationRequest(partner_id, workstation_id, payload);
      await dispatch('getWorkstation', workstation_id);
      return result;
    },

    async getWorkstationRegistrationRequest({ commit }, id) {
      const partnerSecurityService = Vue.prototype.$services.partnerSecurity;
      const workstationRegistrationRequest = await partnerSecurityService.getWorkstationRegistrationRequest(id);
      commit('workstationRegistrationRequest', workstationRegistrationRequest);
      return workstationRegistrationRequest;
    },
    async addWorkstationRegistrationRequest({ dispatch }, params) {
      const partnerSecurityService = Vue.prototype.$services.partnerSecurity;
      const payload = _.omit(params, ['partner_id', 'workstation_id', 'space_id']);

      let result = await partnerSecurityService.createWorkstationRegistrationRequest(params.partner_id, params.workstation_id, payload);
      await dispatch('space/getSpace', params.space_id, { root: true });

      return result;
    },
    async listWorkstationRegistrationRequests({ state, commit }, { partner_id, workstation_id, page, limit } = {}) {
      const partnerSecurityService = Vue.prototype.$services.partnerSecurity;

      const thePage = page || state.listWorkstationRegistrationRequests.pageNumber || 1;
      const theLimit = limit || workstationRegistrationRequestsPaginationLimit;

      const listResults = await partnerSecurityService.listWorkstationRegistrationRequests(partner_id, workstation_id, thePage, theLimit);

      commit('workstationRegistrationRequests', listResults.items);
      commit('listWorkstationRegistrationRequestsPageNumber', thePage);
      commit('listWorkstationRegistrationRequestsPageCount', listResults.page_count);
      commit('listWorkstationRegistrationRequestsItemCount', listResults.item_count);
    },
    async approveWorkstationRegistrationRequest({ dispatch }, id) {
      const partnerSecurityService = Vue.prototype.$services.partnerSecurity;
      const result = await partnerSecurityService.approveWorkstationRegistrationRequest(id);
      await dispatch('getWorkstationRegistrationRequest', id);
      return result;
    },
    async rejectWorkstationRegistrationRequest({ dispatch }, params) {
      const partnerSecurityService = Vue.prototype.$services.partnerSecurity;
      const { id, ...payload } = params;
      const result = await partnerSecurityService.rejectWorkstationRegistrationRequest(id, payload);
      await dispatch('getWorkstationRegistrationRequest', id);
      return result;
    },
  },
};
