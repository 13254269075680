import BaseJoi from 'joi';
import Extension from 'joi-date-extensions';
import address from './address';
import phone from './phone';
import zip from './zip';
import id from './id';

export const Joi = BaseJoi.extend(Extension).extend([address, phone, zip, id]);

export function validate(object, schema) {
  return Joi.validate(object, schema);
}
