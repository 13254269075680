<template>
  <card-modal
    icon=""
    :title="$t('user.details.memberships_section.add_membership_action.title')"
    context="addMembership"
    @submit="submit"
    @cancel="cancel"
    cancel_text="user.details.memberships_section.add_membership_action.button_cancel"
    submit_text="user.details.memberships_section.add_membership_action.button_save"
    data-test="membership--add-modal"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout row wrap>
        <v-flex sm6 xs12>
          <h-autocomplete
            v-model="partner"
            :label="$t('user.details.memberships_section.add_membership_action.partner') + ' *'"
            v-validate="'required'"
            :error-messages="errors.collect('partner')"
            name="partner"
            data-vv-name="partner"
            data-vv-as="partner"
            search-action="partner/autocompleteSearchPartnersForMembershipSubscription"
            :extra-search-params="{ account_id: accountId }"
            data-test="add-membership--partner"
            no-filter
          >
          </h-autocomplete>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex sm6 xs12>
          <v-checkbox
            name="allow_communication"
            v-model="allow_communication"
            :label="$t(`user.details.memberships_section.add_membership_action.allow_communication`)"
            :error-messages="errors.collect('allow_communication')"
            data-vv-name="allow_communication"
            :data-vv-as="$t(`user.details.memberships_section.add_membership_action.allow_communication`)"
            type="checkbox"
          ></v-checkbox>
        </v-flex>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import _ from 'lodash';
import Vue from 'vue';

export default {
  name: 'add-membership-action',
  mixins: [actionErrorTrackable, security],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    accountId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      partner: '',
      allow_communication: true,
    };
  },
  methods: {
    clear() {
      this.partner = '';
      this.allow_communication = true;
      this.$validator.reset();
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      await this.executeAction(
        {
          action: 'partner/subscribeMembership',
          name: 'addMembership',
          success: this.success,
        },
        { account_id: this.accountId, partner_id: this.partner, allow_communication: this.allow_communication },
      );
    },
    success() {
      this.clear();
      this.$emit('action');
    },
  },
};
</script>
