<template>
  <card-modal
    class=""
    icon="fal fa-exchange"
    :title="$t('card.adjust_wallet_balance_action.title')"
    context="adjustWalletBalance"
    @submit="openConfirmModal"
    @cancel="cancel"
    cancel_text="card.adjust_wallet_balance_action.button_cancel"
    submit_text="card.adjust_wallet_balance_action.button_submit"
    :submit_working="working"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-layout row wrap>
          <v-flex xs12 sm4 d-flex>
            <v-select
              :items="availableOperationTypes"
              :label="$t('card.adjust_wallet_balance_action.form.operation_type.label')"
              v-model="operationType"
              required
              v-validate="{ required: true }"
              data-vv-name="Type"
              :error-messages="errors.collect('Type')"
              name="operation_type"
              class="operation_type"
            ></v-select>
          </v-flex>
        </v-layout>
        <v-layout row wrap pad-form-row-elements>
          <v-flex sm6 xs12 d-flex>
            <v-text-field
              v-model="amount"
              type="number"
              step="0.01"
              :label="$t('card.adjust_wallet_balance_action.form.amount.label')"
              v-validate="{ required: true, decimal: 2, min_value: 0.01 }"
              data-vv-name="Amount"
              :error-messages="errors.collect('Amount')"
              name="amount"
              required
              :suffix="currency"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 d-flex>
            <v-text-field
              type="string"
              v-validate="{ required: false }"
              data-vv-name="OperationGroupId"
              :error-messages="errors.collect('OperationGroupId')"
              name="operation_group_id"
              class="operation_group_id m-r-10"
              v-model="operationGroupId"
              :label="$t('card.adjust_wallet_balance_action.form.operation_group_id.label')"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-text-field
            v-model="reason"
            :label="$t('card.adjust_wallet_balance_action.form.reason.label')"
            required
            v-validate="{ required: true }"
            data-vv-name="Reason"
            name="reason"
            :error-messages="errors.collect('Reason')"
          ></v-text-field>
        </v-layout>
        <v-layout row wrap>
          <v-checkbox
            v-model="skip_webhook"
            :label="$t('card.adjust_wallet_balance_action.form.skip_webhook.label')"
            data-vv-name="SkipWebhook"
            name="skip_webhook"
            :error-messages="errors.collect('SkipWebhook')"
          ></v-checkbox>
        </v-layout>
      </v-layout>

      <modal-confirm
        :show-modal="showConfirmModal"
        :title="$t('card.adjust_wallet_balance_action.confirm.title')"
        context="AdjustWalletBalance"
        :text="
          $t('card.adjust_wallet_balance_action.confirm.text', {
            currentBalance: applyCurrencyFilter(wallet.available_balance),
            targetBalance: applyCurrencyFilter(targetBalance),
            walletDefinitionId: walletDefinitionId,
          })
        "
        cancel_text="card.adjust_wallet_balance_action.confirm.button_cancel"
        submit_text="card.adjust_wallet_balance_action.confirm.button_submit"
        :submit_working="working"
        @submit="submit"
        @close="closeConfirmModal"
      ></modal-confirm>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';

export default {
  name: 'adjust-wallet-balance-action',
  $_veeValidate: {
    validator: 'new',
  },
  mixins: [actionErrorTrackable, security],
  props: {
    cardPublicToken: {
      type: String,
      required: true,
    },
    wallet: {
      type: Object,
      required: true,
    },
  },
  data: function() {
    return {
      amount: '',
      reason: '',
      skip_webhook: false,
      currency: 'CAD',
      availableOperationTypes: [
        {
          value: 'debit',
          text: 'Debit',
        },
        {
          value: 'credit',
          text: 'Credit',
        },
      ],
      operationType: '',
      operationGroupId: '',
      showConfirmModal: false,
      targetBalance: '',
    };
  },
  computed: {
    walletDefinitionId() {
      return this.wallet.wallet_definition_id;
    },
  },
  methods: {
    clear() {
      this.resetForm();
      this.$validator.reset();
    },
    resetForm() {
      this.amount = '';
      this.operationType = '';
      this.reason = '';
      this.skip_webhook = false;
      this.targetBalance = '';
      this.operationGroupId = '';
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    success() {
      this.clear();
      this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'card.adjust_wallet_balance_action.success' });
      this.closeConfirmModal();
      this.$emit('action');
      this.$emit('close');
    },
    error() {
      this.closeConfirmModal();
    },
    async submit() {
      await this.executeAction(
        {
          action: 'wallet/ajustWallet',
          name: 'adjustWalletBalance',
          success: this.success,
          afterError: this.error,
        },
        {
          operation_type: this.operationType,
          operation_group_id: this.operationGroupId,
          card_public_token: this.cardPublicToken,
          wallet_definition_id: this.walletDefinitionId,
          amount: this.amount,
          reason: this.reason,
          skip_webhook: this.skip_webhook,
        },
      );
    },
    async openConfirmModal() {
      const validationComplete = await this.$validator.validateAll();

      const calculateTargetBalance = () => {
        const currentBalance = this.wallet.available_balance;
        const amount = Number(this.amount);
        return this.operationType === 'credit' ? currentBalance + amount : currentBalance - amount;
      };

      if (validationComplete) {
        this.showConfirmModal = true;
        this.targetBalance = calculateTargetBalance();
      }
    },
    closeConfirmModal() {
      this.showConfirmModal = false;
    },
    applyCurrencyFilter(value) {
      return this.$options.filters.currency(value);
    },
  },
};
</script>
