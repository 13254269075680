<template>
  <div v-if="hasAssessmentCheck && currentCardExists">
    <h3>{{ $t(`task.list.aml_assessment_check.title`) }}</h3>
    <v-layout>
      <v-flex>
        <table class="responsive-table" style="width: 100%">
          <thead>
            <tr>
              <th>
                <h5>{{ $t(`task.list.aml_assessment_check.date`) }}</h5>
              </th>
              <th>
                <h5>{{ $t(`task.list.aml_assessment_check.lists`) }}</h5>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="aml-assessment-check--col-date">
                {{ currentAssessmentCheck.created | formatDateNoTime | orNotAvailable }}
                <span v-if="detailsPayload">
                  <br />
                  <a class="small-link-text" @click="showDetailsDialog()">{{ $t('task.list.aml_assessment_check.view_details') }}</a>
                </span>
              </td>
              <td class="aml-assessment-check--col-lists">{{ hitListNames.join(', ') }}</td>
            </tr>
          </tbody>
        </table>
      </v-flex>
    </v-layout>

    <modal-scrollable-zone :modal-data="modalData" :show-modal="showModal" @close="showModal = false">
      <template slot="modalContent">
        <pre v-highlightjs="modalData.content"><code class="json"></code></pre>
      </template>
    </modal-scrollable-zone>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { actionErrorTrackable, security } from '@/mixins';
import _get from 'lodash/get';

export default {
  name: 'aml-assessment-check-hits-part',
  mixins: [actionErrorTrackable, security],
  props: {
    amlAssessmentCheckKey: {
      type: String,
      required: true,
    },
    hitType: {
      type: String,
      required: true,
      validator: (value) => ['pep', 'sanction'].includes(value),
    },
    publicToken: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      showModal: false,
      currentCard: {},
    };
  },
  async mounted() {
    await this.executeAction({ action: 'task/getAmlAssessmentCheck' }, this.amlAssessmentCheckKey);
    if (this.publicToken) {
      this.currentCard = await this.executeAction({ action: 'task/getCard' }, this.publicToken);
    }
  },
  computed: {
    ...mapGetters('task', ['amlAssessmentCheck']),
    currentAssessmentCheck() {
      return this.amlAssessmentCheck(this.amlAssessmentCheckKey);
    },
    hits() {
      return _.get(this.currentAssessmentCheck, 'hits', []).filter((hit) => hit.type === this.hitType);
    },
    hitListNames() {
      return this.hits.map((hit) => {
        return hit.list_name;
      });
    },
    modalData() {
      return {
        title: `${this.modalTitleHitType} - ${_get(this.currentCard, 'cardholder.first_name')} ${_get(
          this.currentCard,
          'cardholder.last_name',
        )} (DOB: ${_get(this.currentCard, 'cardholder.date_of_birth')})`,
        content: '',
      };
    },
    modalTitleHitType() {
      return this.$t(`task.list.aml_assessment_check.${this.hitType}_modal_title`);
    },
    hasAssessmentCheck() {
      return !_.isEmpty(this.currentAssessmentCheck);
    },
    detailsPayload() {
      return this.hitType === 'pep' ? this.currentAssessmentCheck.pep_details : this.currentAssessmentCheck.sanctions_details;
    },
  },
  methods: {
    _get,
    currentCardExists() {
      return !!this.currentCard.public_token;
    },
    showDetailsDialog() {
      this.modalData.content = JSON.stringify(this.detailsPayload, null, 2);
      this.showModal = true;
    },
  },
};
</script>
