import Vue from 'vue';

const service = {
  async createBatch(payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v1/instant-card-batches`, payload);
    return response.data.data;
  },
  async getBatch(id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/publisher/v1/instant-card-batches/${id}`);
    return response.data.data;
  },
  async listBatches(page, limit, keyword, partner_id, instant_card_project_id, business_id, status, establishment_id) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath('/publisher/v1/instant-card-batches', {
        page,
        limit,
        keyword,
        partner_id,
        instant_card_project_id,
        business_id,
        status,
        establishment_id,
      })}`,
    );
    return response.data.data;
  },
  async createBatchNote(batch_id, payload) {
    const response = await Vue.prototype.$axios.post(
      `${process.env.VUE_APP_BASE_API_URL}/publisher/v1/instant-card-batches/${batch_id}/notes`,
      payload,
    );
    return response.data.data;
  },
  async deleteBatchNote(batch_id, note_id, payload) {
    const response = await Vue.prototype.$axios.put(
      `${process.env.VUE_APP_BASE_API_URL}/publisher/v1/instant-card-batches/${batch_id}/notes/${note_id}/delete`,
      payload,
    );
    return response.data.data;
  },
  async listBatchNotes(page, limit, batch_id) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v1/instant-card-batches/${batch_id}/notes`, {
        page,
        limit,
      })}`,
    );
    return response.data.data;
  },
  async listBatchCards(page, limit, batch_id, keyword, partner_id, status) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v1/instant-card-batches/${batch_id}/cards`, {
        page,
        limit,
        keyword,
        partner_id,
        status,
      })}`,
    );
    return response.data.data;
  },
  async searchPrograms(page, limit, keyword) {
    const params = {};
    if (keyword) {
      params.keyword = keyword;
    }
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath('/publisher/v1/instant-card-programs/search', page, limit, keyword)}`,
    );
    return response.data.data;
  },
  async updateBatch(id, payload) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/publisher/v1/instant-card-batches/${id}`, payload);
    return response.data.data;
  },
  async updateBatchTracking(id, payload) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/publisher/v1/instant-card-batches/${id}/tracking`, payload);
    return response.data.data;
  },
  async cancelBatch(id) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/publisher/v1/instant-card-batches/${id}/cancel`, {});
    return response.data.data;
  },
  async approveBatch(id) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/publisher/v1/instant-card-batches/${id}/approve`, {});
    return response.data.data;
  },
  async linkBatchToInstantCardProject({ id, instant_card_project_id }) {
    const payload = { instant_card_project_id };
    const response = await Vue.prototype.$axios.put(
      `${process.env.VUE_APP_BASE_API_URL}/publisher/v1/instant-card-batches/${id}/cards/instant-card-project`,
      payload,
    );
    return response.data.data;
  },
  async unlinkBatchFromInstantCardProject(id) {
    const response = await Vue.prototype.$axios.delete(
      `${process.env.VUE_APP_BASE_API_URL}/publisher/v1/instant-card-batches/${id}/cards/instant-card-project`,
    );
    return response.data.data;
  },
  async pauseBatch(id) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/publisher/v1/instant-card-batches/${id}/cards/pause`, {});
    return response.data.data;
  },
  async resumeBatch(id) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/publisher/v1/instant-card-batches/${id}/cards/resume`, {});
    return response.data.data;
  },
  async markBatchAsDelivered(id) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/publisher/v1/instant-card-batches/${id}/delivered`, {});
    return response.data.data;
  },
};

function generatePath(startPath, { page, limit, keyword, partner_id, instant_card_project_id, business_id, status, establishment_id }) {
  let path = startPath;
  if (page || limit || keyword || partner_id || instant_card_project_id || business_id || status || establishment_id) {
    path += '?';
  }

  const queryStrings = [];
  if (page) {
    queryStrings.push(['page', page]);
  }
  if (limit) {
    queryStrings.push(['limit', limit]);
  }
  if (keyword) {
    queryStrings.push(['keyword', keyword]);
  }
  if (partner_id) {
    queryStrings.push(['partner_id', partner_id]);
  }
  if (instant_card_project_id) {
    queryStrings.push(['instant_card_project_id', instant_card_project_id]);
  }
  if (business_id) {
    queryStrings.push(['business_id', business_id]);
  }
  if (status) {
    queryStrings.push(['status', status]);
  }
  if (establishment_id) {
    queryStrings.push(['establishment_id', establishment_id]);
  }

  const params = new URLSearchParams(queryStrings);
  return path + params.toString();
}

export default service;
