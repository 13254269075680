<template>
  <div class="offer-promotion-status">
    <span v-if="status" :class="`status-${status}`">{{ $t(`offer_promotions.status.${status}`) }}</span>
  </div>
</template>

<script>
export default {
  name: 'offer-promotion-status',
  props: {
    status: {
      type: String,
    },
  },
};
</script>
