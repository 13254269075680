<template>
  <view-main title="page_title.instant_card_cards"><search-instant-cards-form slot="content"></search-instant-cards-form></view-main>
</template>

<script>
import { renderErrorTrackable } from '@/mixins';
import SearchInstantCardsForm from './list/SearchInstantCardsForm.vue';

export default {
  name: 'instant-card-cards-view',
  mixins: [renderErrorTrackable],
  components: { SearchInstantCardsForm },
};
</script>

<style scoped lang="stylus"></style>
