<template>
  <card-modal
    icon=""
    :title="`${$t('business.link_bank_account_action.title')}`"
    @submit="close"
    submit_text="business.link_bank_account_action.button_close"
    :submit_working="working"
    context="SearchBankAccount"
    class="link-bank-account-action"
  >
    <div slot="content">
      <search-bank-account
        ref="bank_account_search"
        select-label="business.link_bank_account_action.button_link"
        :can-select-account="() => true"
        :select-account="linkBankAccount"
        :all-status="true"
      ></search-bank-account>
    </div>
  </card-modal>
</template>

<script>
import SearchBankAccount from '@/components/business/actions/SearchBankAccount';
import { mapGetters } from 'vuex';
import { actionErrorTrackable } from '@/mixins';

export default {
  name: 'business-link-bank-account-action',
  components: { SearchBankAccount },
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    business: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      searched: '',
      keyword: '',
      dataReady: false,
      formSubmitted: false,
      showTotalResultsMessage: false,
      totalResultsMessage: '',
    };
  },
  computed: {
    ...mapGetters('eftV2', ['bankAccountsFromSearch', 'bankAccountsMoreResults']),
  },
  methods: {
    clear() {
      this.searched = '';
      this.keyword = '';
      this.dataReady = false;
      this.formSubmitted = false;
      this.showTotalResultsMessage = false;
      this.totalResultsMessage = '';
      this.$validator.reset();
    },
    close() {
      this.clear();
      this.$emit('close');
    },
    showSearchResults() {
      return this.bankAccountsFromSearch.length !== 0;
    },
    setTotalResultsMessage() {
      this.searched = this.keyword;
      if (this.bankAccountsMoreResults) {
        this.showTotalResultsMessage = true;
        this.totalResultsMessage = this.$t('business.link_bank_account_action.form.total_results_message');
      } else if (this.bankAccountsFromSearch.length === 0) {
        this.showTotalResultsMessage = true;
        this.totalResultsMessage = this.$t('business.link_bank_account_action.form.no_results');
      } else {
        this.showTotalResultsMessage = false;
      }
    },
    async executeLinkAction(payload) {
      await this.executeAction(
        {
          action: 'business/linkBankAccountToBusiness',
          name: 'linkBankAccountToBusiness',
        },
        payload,
      );
    },
    async linkBankAccount(bank_account) {
      await this.executeLinkAction({ id: this.business.id, bank_account_key: bank_account.key });
      this.clear();
      this.$emit('linked');
    },
  },
};
</script>
