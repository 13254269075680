import Vue from 'vue';
import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';
import _isEmpty from 'lodash/isEmpty';
import { safeDispatcher } from '@/utils/context/context-helper';
import amlStatusLogConverter from '../utils/aml-status-log-converter';
import { validateDownloadDocument, validateListDocumentParams } from './validators/aml-validators';

export default {
  namespaced: true,
  state: {
    amlNotes: [],
    currentNote: {},
    showDeleteNoteModal: false,
    noteTypes: [],
    noteCategories: [],
    amlStatus: {},
    documents: [],
    currentDocument: {},
    showDeleteDocumentModal: false,
    amlStatusLogsAndAssessmentChecks: {},
  },

  getters: {
    amlNotes: (state) =>
      state.amlNotes.map((note) => ({
        ...note,
        isCard: !!note.public_token,
        isApplication: !!note.card_application_id,
        isAccount: !note.public_token && !note.card_application_id && note.account_id,
      })),

    currentNote: (state) => state.currentNote,

    showDeleteNoteModal: (state) => state.showDeleteNoteModal,

    noteCategories: (state) => state.noteCategories.filter((category) => category.active),

    getCategoryTypes: (state) => (category_id) => state.noteTypes.filter((type) => type.category_id === category_id && type.active),

    getNoteTypeById: (state) => (type_id) => _find(state.noteTypes, (type) => type.id === type_id),

    getNoteCategoryById: (state) => (category_id) => _find(state.noteCategories, (category) => category.id === category_id),

    amlStatus: (state) => state.amlStatus,

    amlStatusLogsAndAssessmentChecks: (state) => state.amlStatusLogsAndAssessmentChecks,

    statusOptions: (state) => state.statusOptions,

    documents: (state) => state.documents,

    currentDocument: (state) => state.currentDocument,

    showDeleteDocumentModal: (state) => state.showDeleteDocumentModal,
  },

  mutations: {
    documents(state, documents) {
      state.documents = documents;
    },

    currentDocument(state, document) {
      state.currentDocument = document;
    },

    showDeleteDocumentModal(state, visible) {
      state.showDeleteDocumentModal = visible;
    },

    currentNote(state, note) {
      state.currentNote = note;
    },

    showDeleteNoteModal(state, visible) {
      state.showDeleteNoteModal = visible;
    },

    amlNotes(state, amlNotes) {
      state.amlNotes = amlNotes;
    },

    noteCategories(state, categories) {
      state.noteCategories = _sortBy(categories, ['name.en']);
    },

    noteTypes(state, types) {
      state.noteTypes = _sortBy(types, ['name.en']);
    },

    amlStatus(state, amlStatus) {
      state.amlStatus = amlStatus;
    },

    amlStatusLogsAndAssessmentChecks(state, amlStatusLogsAndAssessmentChecks) {
      state.amlStatusLogsAndAssessmentChecks = amlStatusLogsAndAssessmentChecks;
    },
  },

  actions: {
    async getDocuments({ commit }, { card_application_id, cardholder_key, account_id, public_token }) {
      const amlDocumentService = Vue.prototype.$services.amlDocument;
      await validateListDocumentParams({ card_application_id, cardholder_key, account_id, public_token });

      const docs = await amlDocumentService.listDocument({ card_application_id, cardholder_key, account_id, public_token });
      commit('documents', docs);
    },

    async downloadDocument(context, { id, filename }) {
      await validateDownloadDocument({ id, filename });
      const amlDocumentService = Vue.prototype.$services.amlDocument;
      const fileSaver = Vue.prototype.$services.fileSaver;

      const result = await amlDocumentService.getDocument(id);
      await fileSaver.saveFile(result, filename);
    },

    showDeleteDocumentModal({ commit }, document) {
      commit('currentDocument', document);
      commit('showDeleteDocumentModal', true);
    },

    hideDeleteDocumentModal({ commit }) {
      commit('currentDocument', {});
      commit('showDeleteDocumentModal', false);
    },

    async deleteDocument({ dispatch }, { id, reason, card_application_id, cardholder_key, public_token, account_id }) {
      const defaultDispatch = safeDispatcher(dispatch);
      const amlDocumentService = Vue.prototype.$services.amlDocument;

      await amlDocumentService.deleteDocument(id, { reason });

      await defaultDispatch('ui/showSuccessSnackBar', { text: 'application.create_application_comment_action.success' });
      await defaultDispatch('aml/hideDeleteDocumentModal');
      await defaultDispatch('aml/getDocuments', { card_application_id, cardholder_key, public_token, account_id });
    },

    async saveAccountAmlNote({ dispatch }, { type_id, category_id, account_id, note }) {
      const amlNoteService = Vue.prototype.$services.amlNote;
      const defaultDispatch = safeDispatcher(dispatch);

      let payload = {
        type_id,
        category_id,
        account_id,
        created_by_email: this.state.security.currentUser.email,
        note,
      };

      await amlNoteService.createAmlNote(payload);

      await defaultDispatch('ui/showSuccessSnackBar', { text: 'aml.create_aml_note_action.success' });
      await defaultDispatch('aml/getAmlNotes', { account_id });
    },

    async saveCardholderAmlNote(
      { dispatch },
      { type_id, category_id, public_token, card_application_id, cardholder_key, card_program_key, note, account_id },
    ) {
      if (!cardholder_key) {
        throw new Error('cardholder_key is required');
      }

      const amlNoteService = Vue.prototype.$services.amlNote;
      const defaultDispatch = safeDispatcher(dispatch);

      let payload = {
        type_id,
        category_id,
        public_token,
        cardholder_key,
        card_program_key,
        account_id,
        created_by_email: this.state.security.currentUser.email,
        note,
      };

      await amlNoteService.createAmlNote(payload);

      await defaultDispatch('ui/showSuccessSnackBar', { text: 'aml.create_aml_note_action.success' });
      await defaultDispatch('aml/getAmlNotes', { card_application_id, cardholder_key, account_id });
    },

    async saveCardApplicationAmlNote(
      { dispatch },
      { type_id, category_id, card_application_id, cardholder_key, account_id, card_program_key, note },
    ) {
      const amlNoteService = Vue.prototype.$services.amlNote;
      const defaultDispatch = safeDispatcher(dispatch);

      let payload = {
        type_id,
        category_id,
        card_application_id,
        cardholder_key,
        account_id,
        card_program_key,
        created_by_email: this.state.security.currentUser.email,
        note,
      };

      await amlNoteService.createAmlNote(payload);

      await defaultDispatch('ui/showSuccessSnackBar', { text: 'aml.create_aml_note_action.success' });
      await defaultDispatch('aml/getAmlNotes', { card_application_id, cardholder_key, account_id });
    },

    showDeleteNoteModal({ commit }, note) {
      commit('currentNote', note);
      commit('showDeleteNoteModal', true);
    },

    hideDeleteNoteModal({ commit }) {
      commit('currentNote', {});
      commit('showDeleteNoteModal', false);
    },

    async deleteNote({ dispatch }, { key, reason, card_application_id, cardholder_key, account_id }) {
      const defaultDispatch = safeDispatcher(dispatch);
      const amlNoteService = Vue.prototype.$services.amlNote;

      await amlNoteService.deleteAmlNote(key, { reason });

      await defaultDispatch('ui/showSuccessSnackBar', { text: 'application.create_application_comment_action.success' });
      await defaultDispatch('aml/hideDeleteNoteModal');
      await defaultDispatch('aml/getAmlNotes', { card_application_id, cardholder_key, account_id });
    },

    async getAmlNote({ commit }, { aml_note_key }) {
      const amlNoteService = Vue.prototype.$services.amlNote;

      const note = await amlNoteService.getAmlNote(aml_note_key);
      commit('currentNote', note);
    },

    async getAmlNotes({ commit }, { public_token, card_application_id, cardholder_key, account_id }) {
      commit('amlNotes', []);
      const amlNoteService = Vue.prototype.$services.amlNote;

      let payload = {
        card_application_id,
        cardholder_key,
        public_token,
        account_id,
      };

      const amlNotes = await amlNoteService.getAmlNotes(payload);
      commit('amlNotes', amlNotes);
    },

    async listNoteTypes({ commit, state }) {
      if (_isEmpty(state.noteTypes)) {
        const service = Vue.prototype.$services.amlNoteType;

        const types = await service.listNoteTypes();
        commit('noteTypes', types);
      }
    },

    async listNoteCategories({ commit, state }) {
      if (_isEmpty(state.noteCategories)) {
        const service = Vue.prototype.$services.amlNoteCategory;

        const categories = await service.listNoteCategories();
        commit('noteCategories', categories);
      }
    },

    async getAmlStatus({ commit }, token) {
      const amlStatusService = Vue.prototype.$services.amlStatus;

      const amlStatus = await amlStatusService.getAmlStatus(token);
      commit('amlStatus', amlStatus);
    },

    async setCardAmlStatus({ dispatch }, { public_token, aml_status_object }) {
      const amlStatusService = Vue.prototype.$services.amlStatus;
      const defaultDispatch = safeDispatcher(dispatch);

      await amlStatusService.setAmlStatus(public_token, aml_status_object);

      await defaultDispatch('ui/showSuccessSnackBar', { text: aml_status_object.status_bar_text });

      await defaultDispatch('aml/getAmlStatus', public_token);
      await defaultDispatch('aml/getAmlStatusLogsAndAssessmentChecks', public_token);
    },

    async getAmlStatusLogsAndAssessmentChecks({ commit }, token) {
      const amlStatusLogsService = Vue.prototype.$services.amlStatusLog;

      const amlStatusLogsAndAssessmentChecksOriginal = await amlStatusLogsService.getAmlStatusLogsWithAssessmentChecks(token);

      if (amlStatusLogsAndAssessmentChecksOriginal.length > 0) {
        const amlStatusLogsAndAssessmentChecks = amlStatusLogConverter(amlStatusLogsAndAssessmentChecksOriginal);
        commit('amlStatusLogsAndAssessmentChecks', amlStatusLogsAndAssessmentChecks);
      } else {
        commit('amlStatusLogsAndAssessmentChecks', {});
      }
    },
  },
};
