<template>
  <card :title="$t('activation_code_batch.activation_codes')" icon="">
    <template slot="headerZoneRight">
      <label-text
        class="text-sm-center"
        :label="$t('activation_code_batch.details.activation_codes_section.count')"
        :value="activationCodeBatch.activation_code_count"
      ></label-text>
    </template>
    <template slot="content">
      <form @submit.prevent="submit" class="form-basic form-search">
        <v-layout align-center wrap>
          <v-flex xs12 sm6 md5>
            <form-warning-search
              :searched="searched"
              :hint-message="$t('activation_code_batch.details.activation_codes_section.form.total_results_message')"
              :label="$t('activation_code_batch.details.activation_codes_section.placeholder')"
              v-model="keyword"
              data-vv-name="search"
              v-validate="'max:100'"
              :error-messages="errors.collect('search')"
              data-test="activation-codes-keyword"
            ></form-warning-search>
          </v-flex>
          <v-flex xs12 sm6 md7 class="form-search--button-wrap">
            <button-primary
              :text="$t('activation_code_batch.details.activation_codes_section.form.submit')"
              :loading="working"
              type="submit"
            ></button-primary>
          </v-flex>
        </v-layout>
      </form>
      <activation-codes-table
        v-if="dataReady && listItemCount"
        :items="activationCodes"
        :offer-id="activationCodeBatch.offer_id"
        :activation-code-batch-id="activationCodeBatch.id"
      />
      <label-none v-if="listItemCount === 0 && dirty">{{ $t('system.none') }}</label-none>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';
import ActivationCodesTable from '@/components/activation_code_batch/detail/ActivationCodesTable.vue';

export default {
  name: 'activation-codes',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: {
    ActivationCodesTable,
  },
  $_veeValidate: {
    validator: 'new',
  },
  data() {
    const $t = this.$t.bind(this);
    return {
      keyword: '',
      searched: '',
      page: 1,
      dataReady: false,
      dirty: false,
    };
  },
  async created() {
    this.page = this.listPageNumber;
    this.keyword = this.listKeyword;
    await this.fetchActivationCodesByOffer();
  },
  computed: {
    ...mapGetters('activationCodeBatch', ['activationCodeBatch']),
    ...mapGetters('activationCode', ['activationCodes', 'listKeyword', 'listPageNumber', 'listItemCount']),
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    async clear() {
      this.searched = '';
      this.keyword = '';
      this.page = 1;
      this.dirty = false;
      this.$validator.reset();
    },
    async fetchActivationCodesByOffer() {
      await this.executeAction(
        { action: 'activationCode/listActivationCodesByOffer' },
        {
          offerId: this.activationCodeBatch.offer_id,
          keyword: this.keyword,
          batchId: this.activationCodeBatch.id,
          page: this.page,
        },
      );
    },
    async submit(event, forceSearch = false, page = 1) {
      let validationComplete;
      if (!forceSearch) {
        validationComplete = await this.$validator.validateAll();
      }

      if (validationComplete || forceSearch) {
        await this.clearAllError();
        this.dataReady = false;
        await this.executeAction(
          { action: 'activationCode/listActivationCodesByOffer' },
          {
            offerId: this.activationCodeBatch.offer_id,
            keyword: this.keyword,
            batchId: this.activationCodeBatch.id,
            page,
          },
        );
        this.dataReady = true;
        this.dirty = true;
      }
    },
  },
};
</script>
