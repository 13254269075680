const PurseOperationType = Object.freeze({
  // purse
  PURSE_LOAD: 'purse_load',
  PURSE_UNLOAD: 'purse_unload',
  PURSE_START_PURCHASE: 'purse_start_purchase',
  PURSE_COMPLETE_PURCHASE: 'purse_complete_purchase',
  PURSE_CREDIT_ADJUSTMENT: 'purse_credit_adjustment',
  PURSE_DEBIT_ADJUSTMENT: 'purse_debit_adjustment',
  // card
  CARD_LOAD: 'card_load',
  CARD_UNLOAD: 'card_unload',
  CARD_CREDIT_ADJUSTMENT: 'card_credit_adjustment',
  CARD_DEBIT_ADJUSTMENT: 'card_debit_adjustment',
  // trx
  TRANSACTION_FINANCIAL_DEBIT_ADJUSTMENT: 'transaction_financial_debit_adjustment',
  TRANSACTION_FINANCIAL_CREDIT_ADJUSTMENT: 'transaction_financial_credit_adjustment',
  TRANSACTION_PAYMENT: 'transaction_payment',
  TRANSACTION_PAYMENT_REVERSAL: 'transaction_payment_reversal',
  TRANSACTION_PAYMENT_DEBIT_ADJUSTMENT: 'transaction_payment_debit_adjustment',
  TRANSACTION_UNAUTHORIZED_PAYMENT: 'transaction_unauthorized_payment',
  TRANSACTION_AUTHORIZATION: 'transaction_authorization',
  TRANSACTION_AUTHORIZATION_REVERSAL: 'transaction_authorization_reversal',
  TRANSACTION_AUTHORIZATION_DEBIT_ADJUSTMENT: 'transaction_authorization_debit_adjustment',
  TRANSACTION_AUTHORIZATION_CREDIT_ADJUSTMENT: 'transaction_authorization_credit_adjustment',
  TRANSACTION_CREDITS_REFUND: 'transaction_credits_refund',
  TRANSACTION_CHARGEBACK: 'transaction_chargeback',
  TRANSACTION_CHARGEBACK_REVERSAL: 'transaction_chargeback_reversal',
  TRANSACTION_CANCEL: 'transaction_cancel',
});

export default {
  PurseOperationType,
  enum: Object.values(PurseOperationType),
  excludedByDefault: Object.values([
    PurseOperationType.TRANSACTION_FINANCIAL_CREDIT_ADJUSTMENT,
    PurseOperationType.TRANSACTION_FINANCIAL_DEBIT_ADJUSTMENT,
    PurseOperationType.TRANSACTION_AUTHORIZATION_DEBIT_ADJUSTMENT,
    PurseOperationType.TRANSACTION_AUTHORIZATION_CREDIT_ADJUSTMENT,
  ]),
};
