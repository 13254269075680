<template>
  <card :title="$t('store.publishers')" icon="">
    <template slot="headerZoneRight">
      <label-text
        class="text-xs-center"
        :label="`${$t('store.details.publishers_section.count')}`"
        :value="storePublishersItemCount || '0'"
      ></label-text>
      <div v-if="showAddStorePublisherFeature()" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text v-if="showAddStorePublisherFeature()" :label="`${$t('store.details.publishers_section.action')}`" value="3">
        <link-secondary-action slot="labelTextLink" @click="openAddStorePublisherModal">
          {{ $t('store.details.publishers_section.add_publisher') }}
        </link-secondary-action>
      </label-text>
    </template>
    <template slot="content">
      <modal-action :show-modal="showAddStorePublisherModal">
        <add-store-publisher-action
          :store-obj="store"
          @close="closeAddStorePublisherModal"
          @created="addStorePublisherSuccess"
        ></add-store-publisher-action
      ></modal-action>
      <modal-confirm
        v-if="storePublisherIdToDelete"
        :show-modal="showDeleteStorePublisherModal"
        :title="$t(`store.details.publishers_section.delete_action.confirm.title`)"
        context="deleteStorePublisher"
        :text="
          $t(`store.details.publishers_section.delete_action.confirm.text`, {
            storePublisherName: `${storePublisherToDelete.name} - (${storePublisherToDelete.id})`,
          })
        "
        :cancel_text="`store.details.publishers_section.delete_action.confirm.button_cancel`"
        :submit_text="`store.details.publishers_section.delete_action.confirm.button_submit`"
        :submit_working="working"
        @submit="deleteStorePublisher"
        @close="closeDeleteStorePublisherModal"
      ></modal-confirm>
      <store-publishers-table v-if="storePublishers.length" @store_publisher_delete_action="openDeleteStorePublisherModal" :items="storePublishers">
      </store-publishers-table>
      <label-none v-if="storePublishers.length === 0">{{ $t('system.none') }}</label-none>
      <div class="text-xs-center">
        <v-pagination
          v-if="storePublishersPageCount > 1"
          class="apps-table--pagination"
          v-model="pagination"
          :length="storePublishersPageCount"
        ></v-pagination>
      </div>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import StorePublishersTable from './StorePublishersTable';
import { mapGetters } from 'vuex';
import AddStorePublisherAction from '../actions/AddStorePublisherAction';

export default {
  name: 'store-publishers',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { StorePublishersTable, AddStorePublisherAction },
  data() {
    return {
      showAddStorePublisherModal: false,
      showDeleteStorePublisherModal: false,
      storePublisherIdToDelete: null,
      storePublisherToDelete: {},
      pagination: 1,
    };
  },
  watch: {
    async store() {
      await this.fetchStorePublishers();
    },
    storePublishersPageNumber() {
      this.pagination = this.storePublishersPageNumber;
    },
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'store/changeStorePublishersPage',
          success: this.success,
        },
        {
          id: this.store.id,
          page: newValue,
        },
      );
    },
  },
  computed: {
    ...mapGetters('store', ['store', 'storePublishers', 'storePublishersPageCount', 'storePublishersPageNumber', 'storePublishersItemCount']),
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    showAddStorePublisherFeature() {
      return this.canManageOffer();
    },
    openAddStorePublisherModal() {
      this.showAddStorePublisherModal = true;
    },
    closeAddStorePublisherModal() {
      this.showAddStorePublisherModal = false;
    },
    async addStorePublisherSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'store.details.publishers_section.create_action.success' });
      this.closeAddStorePublisherModal();
    },
    openDeleteStorePublisherModal(id) {
      this.storePublisherIdToDelete = id;
      this.storePublisherToDelete = this.storePublishers.find((l) => l.id === id);
      this.showDeleteStorePublisherModal = true;
    },
    closeDeleteStorePublisherModal() {
      this.storePublisherIdToDelete = null;
      this.storePublisherToDelete = {};
      this.showDeleteStorePublisherModal = false;
    },
    async deleteStorePublisherSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: `store.details.publishers_section.delete_action.success` });
      this.closeDeleteStorePublisherModal();
    },
    async deleteStorePublisher() {
      await this.executeAction(
        {
          action: 'store/removePublisherFromStore',
          name: 'removePublisherFromStore',
          success: this.deleteStorePublisherSuccess,
        },
        { publisherId: this.storePublisherToDelete.id, store_id: this.store.id },
      );
    },
    async fetchStorePublishers() {
      await this.executeAction({ action: 'store/listStorePublishers' }, { id: this.store.id, page: this.storePublishersPageNumber });
    },
  },
};
</script>
