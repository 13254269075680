<template>
  <table class="responsive-table" id="offer-vouchers-table">
    <thead>
      <tr>
        <td>{{ $t('user.subscriptions_table.fields.subscription_plan_id') }}</td>
        <td>{{ $t('user.subscriptions_table.fields.partner_id') }}</td>
        <td>{{ $t('user.subscriptions_table.fields.status') }}</td>
        <td>{{ $t('user.subscriptions_table.fields.creation_date') }}</td>
        <td align="right">{{ $t('user.subscriptions_table.fields.stripe_subscription_id') }}</td>
      </tr>
    </thead>

    <tbody>
      <tr v-for="(item, index) in items" :key="index">
        <td>
          <h5>{{ $t('user.subscription_table.fields.subscription_plan_id') }}:</h5>
          {{ item.subscription_plan_id }}
        </td>

        <td>
          <h5>{{ $t('user.subscription_table.fields.partner_id') }}:</h5>
          {{ item.partner_id }}
        </td>

        <td class="subscription-status">
          <h5>{{ $t('user.subscription_table.fields.status') }}:</h5>
          <span :class="`status-${item.status}`">{{ $t(`user.subscriptions_table.status.${item.status}`) }}</span>
        </td>

        <td>
          <h5>{{ $t('user.vouchers_table.fields.created') }}:</h5>
          <span>{{ item.created_at | formatShortDateInverted | orNotAvailable }}</span>
        </td>

        <td>
          <h5>{{ $t('user.subscriptions_table.fields.stripe_subscription_id') }}:</h5>
          <a :href="createStripeSubscriptionUrl(item.stripe_subscription_id)" target="_blank">{{
            $t('user.subscriptions_table.fields.view_subscription')
          }}</a>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';

export default {
  name: 'user-subscriptions-table',

  mixins: [security, actionErrorTrackable, disableWithError],

  props: {
    items: {
      type: Array,
    },
  },

  methods: {
    createStripeSubscriptionUrl(id) {
      return `${process.env.VUE_APP_STRIPE_SUBSCRIPTION_URL}/${id}`;
    },
  },
};
</script>
