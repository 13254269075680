import stringToDom from '../utils/string-to-dom';
import equifaxConverter from '../utils/equifax-converter';
import kycMatchesConverter from '../utils/kyc-matches-converter';
import kycVerdict from '../utils/kyc-verdict';

export default (application) => {
  if (application.kyc.result && application.kyc.result.raw) {
    const kycResultAsDomDocument = stringToDom(application.kyc.result.raw);
    const kycResult = equifaxConverter(kycResultAsDomDocument);

    const kycMatchesForDisplay = kycMatchesConverter(kycResult);
    const kycVerdictIs = kycVerdict(application);

    const kyc = kycResult;
    kyc.single_source.matches_cleaned = kycMatchesForDisplay;
    kyc.verdict = kycVerdictIs;

    return kyc;
  }
  return {};
};
