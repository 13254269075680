import Vue from 'vue';

const service = {
  async generateQRCodeFromUrl(url) {
    const payload = { url };

    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v1/qr-code/generate-from-url`, payload);
    return response.data.data;
  },
};

export default service;
