<template>
  <card-modal
    class=""
    icon="fal fa-exchange"
    :title="$t('card.wallet_transactions.operation_group_adjustment_action.title')"
    context="operationGroupAdjustment"
    @submit="submit"
    @cancel="cancel"
    cancel_text="card.wallet_transactions.operation_group_adjustment_action.button_cancel"
    submit_text="card.wallet_transactions.operation_group_adjustment_action.button_submit"
    :submit_working="working"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-layout row wrap pad-form-row-elements>
          <v-flex xs12 sm6>
            <label-text
              :label="$t('card.wallet_transactions.operation_group_adjustment_action.form.wallet_definition_id')"
              :value="walletDefinitionId"
            ></label-text>
          </v-flex>
          <v-flex xs12 sm6>
            <label-text
              :label="$t('card.wallet_transactions.operation_group_adjustment_action.form.operation_group_id')"
              :value="operationGroupId"
            ></label-text>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12 sm4>
            <v-select
              :items="availableOperationTypes"
              :label="$t('card.wallet_transactions.operation_group_adjustment_action.form.operation_type') + ' *'"
              v-model="operationType"
              required
              v-validate="{ required: true }"
              data-vv-name="Type"
              :error-messages="errors.collect('Type')"
              name="operation_type"
              class="operation_type"
            ></v-select>
          </v-flex>
        </v-layout>
        <v-layout row wrap pad-form-row-elements>
          <v-flex xs12 sm4>
            <v-checkbox
              ref="adjust_available_amount"
              v-model="adjust_available_amount"
              :label="$t('card.wallet_transactions.operation_group_adjustment_action.form.adjust_available_amount')"
              data-vv-name="AdjustAvailableAmount"
              name="adjust_available_amount"
              :error-messages="errors.collect('AdjustAvailableAmount')"
              v-validate="`required|validateAtLeastOne:${adjust_actual_amount}`"
            ></v-checkbox>
          </v-flex>
          <v-flex sm6 xs12 v-if="adjust_available_amount">
            <v-text-field
              v-model="available_amount"
              type="number"
              step="0.01"
              :label="$t('card.wallet_transactions.operation_group_adjustment_action.form.available_amount') + ' *'"
              v-validate="{ required: adjust_available_amount, decimal: 2, min_value: 0.01 }"
              data-vv-name="AvailableAmount"
              :error-messages="errors.collect('AvailableAmount')"
              name="available_amount"
              required
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row wrap pad-form-row-elements>
          <v-flex xs12 sm4>
            <v-checkbox
              ref="adjust_actual_amount"
              v-model="adjust_actual_amount"
              :label="$t('card.wallet_transactions.operation_group_adjustment_action.form.adjust_actual_amount')"
              data-vv-name="AdjustActualAmount"
              name="adjust_actual_amount"
              :error-messages="errors.collect('AdjustActualAmount')"
              v-validate="`required|validateAtLeastOne:${adjust_available_amount}`"
            ></v-checkbox>
          </v-flex>
          <v-flex sm6 xs12 v-if="adjust_actual_amount">
            <v-text-field
              v-model="actual_amount"
              type="number"
              step="0.01"
              :label="$t('card.wallet_transactions.operation_group_adjustment_action.form.actual_amount') + ' *'"
              v-validate="{ required: adjust_actual_amount, decimal: 2, min_value: 0.01 }"
              data-vv-name="ActualAmount"
              :error-messages="errors.collect('ActualAmount')"
              name="actual_amount"
              required
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-text-field
            v-model="reason"
            :label="$t('card.wallet_transactions.operation_group_adjustment_action.form.reason') + ' *'"
            required
            v-validate="{ required: true }"
            data-vv-name="Reason"
            name="reason"
            :error-messages="errors.collect('Reason')"
          ></v-text-field>
        </v-layout>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';

export default {
  name: 'operation-group-adjustment-action',
  $_veeValidate: {
    validator: 'new',
  },
  mixins: [actionErrorTrackable, security],
  props: {
    cardPublicToken: {
      type: String,
      required: true,
    },
    transaction: {
      type: Object,
      required: true,
    },
  },
  async created() {
    this.$validator.extend('validateAtLeastOne', {
      name: 'validateAtLeastOne',
      getMessage(field, args) {
        return 'Must select at least one adjustment option.';
      },
      validate: (value, [otherValue]) => {
        return value || JSON.parse(otherValue);
      },
    });
  },
  data() {
    return {
      adjust_available_amount: false,
      available_amount: '',
      adjust_actual_amount: false,
      actual_amount: '',
      reason: '',
      currency: 'CAD',
      availableOperationTypes: [
        {
          value: 'debit',
          text: 'Debit',
        },
        {
          value: 'credit',
          text: 'Credit',
        },
      ],
      operationType: '',
    };
  },
  computed: {
    operationGroupId() {
      return this.transaction.operation_group_id;
    },
    walletDefinitionId() {
      return this.transaction.wallet_definition_id;
    },
  },
  methods: {
    clear() {
      this.resetForm();
      this.$validator.reset();
    },
    resetForm() {
      this.adjust_available_amount = false;
      this.available_amount = '';
      this.adjust_actual_amount = false;
      this.actual_amount = '';
      this.operationType = '';
      this.reason = '';
    },
    cancel() {
      this.clear();
      this.clearAllError();
      this.$emit('close');
    },
    success() {
      this.clear();
      this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'card.wallet_transactions.operation_group_adjustment_action.success' });
      this.clearAllError();
      this.$emit('action');
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        await this.executeAction(
          {
            action: 'card/operationGroupAdjustment',
            success: this.success,
          },
          {
            card_public_token: this.cardPublicToken,
            operation_type: this.operationType,
            operation_group_id: this.operationGroupId,
            wallet_definition_id: this.walletDefinitionId,
            adjust_available_amount: this.adjust_available_amount,
            available_amount: this.available_amount,
            adjust_actual_amount: this.adjust_actual_amount,
            actual_amount: this.actual_amount,
            currency: this.currency,
            reason: this.reason,
          },
        );
      }
    },
  },
};
</script>
