<template>
  <table class="responsive-table" id="offer-vouchers-table">
    <thead>
      <tr>
        <td>{{ $t('instant_card_cards.vouchers_table.fields.id') }}</td>
        <td>{{ $t('instant_card_cards.vouchers_table.fields.partner_id') }}</td>
        <td>{{ $t('instant_card_cards.vouchers_table.fields.offer_id') }}</td>
        <td align="right">{{ $t('instant_card_cards.vouchers_table.fields.received') }}</td>
        <td align="right">{{ $t('instant_card_cards.vouchers_table.fields.remaining') }}</td>
        <td>{{ $t('instant_card_cards.vouchers_table.fields.created') }}</td>
        <td>{{ $t('instant_card_cards.vouchers_table.fields.status') }}</td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in items" :key="index">
        <td>
          <h5>{{ $t('instant_card_cards.vouchers_table.fields.id') }}:</h5>
          <router-link v-if="item.id" :to="{ name: 'voucher_details', params: { voucherId: item.id } }">
            {{ item.id }}
          </router-link>
        </td>
        <td>
          <h5>{{ $t('instant_card_cards.vouchers_table.fields.partner_id') }}:</h5>
          <span>{{ item.partner_id }}</span>
        </td>
        <td>
          <h5>{{ $t('instant_card_cards.vouchers_table.fields.offer_id') }}:</h5>
          <router-link v-if="item.offer_id" :to="{ name: 'offers_details', params: { offerId: item.offer_id } }">
            {{ item.offer_branding.name }} ({{ item.offer_id }})
          </router-link>
          <label-none v-else>{{ $t('system.na') }}</label-none>
        </td>

        <td align="right">
          <h5>{{ $t('instant_card_cards.vouchers_table.fields.received') }}:</h5>
          {{ (item.payment_amount + item.bonus_amount) | currency | dollarSignI18n }}
        </td>
        <td align="right">
          <h5>{{ $t('instant_card_cards.vouchers_table.fields.remaining') }}:</h5>
          {{ item.remaining_amount | currency | dollarSignI18n }}
        </td>
        <td>
          <h5>{{ $t('instant_card_cards.vouchers_table.fields.created') }}:</h5>
          <span>{{ item.creation_date | formatShortDateInverted | orNotAvailable }}</span>
        </td>
        <td>
          <h5>{{ $t('instant_card_cards.vouchers_table.fields.status') }}:</h5>
          <voucher-status-view slot="labelTextLink" :status="item.status" />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';

import VoucherStatusView from '@/components/voucher/VoucherStatusView';
import Vue from 'vue';

export default {
  name: 'instant-card-vouchers-table',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { VoucherStatusView },
  props: {
    items: {
      type: Array,
    },
  },
  methods: {
    async goToCardFromCardholderId(cardholderId) {
      const card = await Vue.prototype.$services.card.getLatestCardByCardholderId(cardholderId);
      if (card) {
        this.$router.push({ name: 'card', params: { cardPublicToken: card.public_token } });
      }
    },
  },
};
</script>
