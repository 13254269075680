import Vue from 'vue';

const defaultPage = 1;
const paginationLimit = 20;
const franchiseStoresPaginationLimit = 20;

export default {
  namespaced: true,
  state: {
    franchises: [],
    franchise: {},
    list: {
      pageNumber: 1,
      pageCount: 0,
      itemCount: 0,
      keyword: '',
    },
    franchiseStores: [],
    franchiseStoresPageNumber: defaultPage,
    franchiseStoresPageCount: 0,
    franchiseStoresItemCount: 0,
  },
  getters: {
    franchises: (state) => state.franchises,
    franchise: (state) => state.franchise,
    listPageCount: (state) => state.list.pageCount,
    listItemCount: (state) => state.list.itemCount,
    listPageNumber: (state) => state.list.pageNumber,
    listKeyword: (state) => state.list.keyword,
    franchiseStores: (state) => state.franchiseStores,
    franchiseStoresPageCount: (state) => state.franchiseStoresPageCount,
    franchiseStoresItemCount: (state) => state.franchiseStoresItemCount,
    franchiseStoresPageNumber: (state) => state.franchiseStoresPageNumber,
  },
  mutations: {
    franchises(state, franchises) {
      state.franchises = franchises;
    },
    franchise(state, franchise) {
      if (!franchise) {
        franchise = {};
      }
      state.franchise = franchise;
    },
    listPageCount(state, pageCount) {
      state.list.pageCount = pageCount;
    },
    listItemCount(state, itemCount) {
      state.list.itemCount = itemCount;
    },
    listPageNumber(state, pageNumber) {
      state.list.pageNumber = pageNumber;
    },
    listKeyword(state, keyword) {
      state.list.keyword = keyword;
    },

    franchiseStores(state, franchiseStores) {
      state.franchiseStores = franchiseStores;
    },
    franchiseStoresPageCount(state, pageCount) {
      state.franchiseStoresPageCount = pageCount;
    },
    franchiseStoresItemCount(state, itemCount) {
      state.franchiseStoresItemCount = itemCount;
    },
    franchiseStoresPageNumber(state, page) {
      state.franchiseStoresPageNumber = page;
    },
  },
  actions: {
    async autocompleteSearch({}, { page, limit, keyword }) {
      const franchiseService = Vue.prototype.$services.franchise;

      return franchiseService.searchFranchises(page, limit, keyword);
    },
    async clearSearchFranchises({ commit }) {
      commit('franchises', []);
    },
    async createFranchise({}, params) {
      const franchiseService = Vue.prototype.$services.franchise;
      return franchiseService.createFranchise(params);
    },
    async listFranchises({ state, commit }, { keyword, page, limit } = {}) {
      const franchiseService = Vue.prototype.$services.franchise;

      const thePage = page || state.list.pageNumber || 1;
      const theLimit = limit || paginationLimit;
      const theKeyword = keyword || '';

      const listResults = await franchiseService.listFranchises(theKeyword, thePage, theLimit);

      commit('franchises', listResults.items);
      commit('listPageNumber', thePage);
      commit('listPageCount', listResults.page_count);
      commit('listItemCount', listResults.item_count);
      commit('listKeyword', theKeyword);
    },
    async getFranchise({ commit }, id) {
      const franchiseService = Vue.prototype.$services.franchise;
      const franchise = await franchiseService.getFranchise(id);

      commit('franchise', franchise);

      return franchise;
    },
    async updateFranchise({ dispatch }, params) {
      const franchiseService = Vue.prototype.$services.franchise;
      const { id, ...payload } = params;
      const result = await franchiseService.updateFranchise(id, payload);
      await dispatch('getFranchise', id);
      return result;
    },
    async listFranchiseStores({ commit }, { id, page }) {
      const franchiseService = Vue.prototype.$services.franchise;
      const thePage = page || defaultPage;
      const result = await franchiseService.listFranchiseStores(id, 1, franchiseStoresPaginationLimit);
      const franchiseStores = result.items;

      commit('franchiseStores', franchiseStores);
      commit('franchiseStoresPageNumber', thePage);
      commit('franchiseStoresPageCount', result.page_count);
      commit('franchiseStoresItemCount', result.item_count);
    },
    async changeFranchiseStoresPage({ commit }, { id, page }) {
      commit('franchiseStoresPageNumber', page);

      const franchiseService = Vue.prototype.$services.franchise;
      const limit = franchiseStoresPaginationLimit;

      const franchiseStoresResult = await franchiseService.listFranchiseStores(id, page, limit);

      commit('franchiseStoresPageCount', franchiseStoresResult.page_count);
      commit('franchiseStores', franchiseStoresResult.items);
    },
  },
};
