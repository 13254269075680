<template>
  <card :title="$t('instant_card_projects.details.linked_cards.title')" icon="">
    <template slot="headerZoneRight">
      <label-text
        class="text-sm-center"
        :label="$t('instant_card_projects.details.linked_cards.count')"
        :value="project.linked_card_count || '0'"
      ></label-text>
      <div v-if="showActionBtn()" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>

      <link-secondary-action v-if="showActionBtn()" slot="labelTextLink">
        <button-secondary-actions :text="$t(`instant_card_projects.details.linked_cards.actions`)" :actions="actions"> </button-secondary-actions>
      </link-secondary-action>
    </template>

    <template slot="content">
      <form v-if="project.linked_card_count > 0" @submit.prevent="submit" class="form-basic form-search">
        <v-layout align-center class="form-search--main-wrap-sub-module">
          <v-flex class="form-search--button-wrap-sub-module">
            <button-primary
              class="instant-card-projects-linked-cards--btn-search"
              :text="$t('instant_card_projects.details.linked_cards.form.submit')"
              :loading="working"
              type="submit"
              @click="submit"
            ></button-primary>
          </v-flex>
          <v-flex class="form-search--inputs-zone-sub-module">
            <form-warning-search
              :autofocus="true"
              :searched="searched"
              :hint-message="$t('instant_card_projects.details.linked_cards.form.total_results_message')"
              class="linked-cards--search-field"
              :label="$t('instant_card_projects.details.linked_cards.placeholder')"
              v-model="keyword"
              data-vv-name="search"
              v-validate="'max:50'"
              :error-messages="errors.collect('search')"
              data-test="linked-cards--keyword"
            ></form-warning-search>
            <v-select
              name="status"
              v-model="status"
              :error-messages="errors.collect('status')"
              data-vv-name="status"
              :data-vv-as="$t('instant_card_projects.details.linked_cards.status')"
              :items="statusOptions"
              item-text="name"
              item-value="value"
              clearable
              :label="$t('instant_card_projects.details.linked_cards.status')"
              data-test="linked-cards-status"
            ></v-select>
          </v-flex>
        </v-layout>
      </form>

      <label-none v-else>{{ $t('system.none') }}</label-none>

      <modal-action :show-modal="showUnlinkProjectCardsModal">
        <unlink-project-cards-action
          v-if="showUnlinkProjectCardsModal"
          :project-obj="project"
          :project-cards-item-count="project.linked_card_count"
          @close="closeUnlinkProjectCardsModal"
          @unlinked="unlinkProjectCardsSuccess"
        />
      </modal-action>
      <modal-action :show-modal="showPauseProjectCardsModal">
        <pause-project-cards-action
          v-if="showPauseProjectCardsModal"
          :project-obj="project"
          :project-cards-item-count="project.linked_card_count"
          @close="closePauseProjectCardsModal"
          @paused="pauseProjectCardsSuccess"
        />
      </modal-action>
      <modal-action :show-modal="showResumeProjectCardsModal">
        <resume-project-cards-action
          v-if="showResumeProjectCardsModal"
          :project-obj="project"
          :project-cards-item-count="project.linked_card_count"
          @close="closeResumeProjectCardsModal"
          @resumed="resumeProjectCardsSuccess"
        />
      </modal-action>
      <project-linked-cards-table v-if="dataReady && projectCards.length" :items="projectCards" :project-id="project.id" />
      <label-none v-if="projectCards.length === 0 && dirty">{{ $t('system.none') }}</label-none>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import ProjectLinkedCardsTable from '@/components/instant_card_project/detail/ProjectLinkededCardsTable';
import UnlinkProjectCardsAction from '@/components/instant_card_project/actions/UnlinkProjectCardsAction';
import PauseProjectCardsAction from '@/components/instant_card_project/actions/PauseProjectCardsAction';
import ResumeProjectCardsAction from '@/components/instant_card_project/actions/ResumeProjectCardsAction';

export default {
  name: 'linked-cards',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { ProjectLinkedCardsTable, UnlinkProjectCardsAction, PauseProjectCardsAction, ResumeProjectCardsAction },
  data() {
    return {
      dataReady: false,
      dirty: false,
      searched: '',
      keyword: '',
      status: '',
      page: 1,
      statusOptions: [
        { name: this.$t('instant_card_cards.status.pending_activation'), value: 'pending_activation' },
        { name: this.$t('instant_card_cards.status.active'), value: 'active' },
        { name: this.$t('instant_card_cards.status.paused'), value: 'paused' },
        { name: this.$t('instant_card_cards.status.destroyed'), value: 'destroyed' },
      ],
      showUnlinkProjectCardsModal: false,
      showPauseProjectCardsModal: false,
      showResumeProjectCardsModal: false,
      actions: [
        {
          slotName: 'UnlinkProjectCardsModal',
          name: this.$t('instant_card_projects.details.linked_cards.unlink_cards_action.title'),
          action: () => {
            this.openUnlinkProjectCardsModal();
          },
          isAvailable: () => this.canManageOffer(), // TODO change
          isDisabled: () => {
            return !this.showUnlinkProjectCardsFeature();
          },
        },
        {
          slotName: 'PauseProjectCardsModal',
          name: this.$t('instant_card_projects.details.linked_cards.pause_cards_action.title'),
          action: () => {
            this.openPauseProjectCardsModal();
          },
          isAvailable: () => this.canManageOffer(), // TODO change
          isDisabled: () => {
            return !this.showPauseProjectCardsFeature();
          },
        },
        {
          slotName: 'ResumeProjectCardsModal',
          name: this.$t('instant_card_projects.details.linked_cards.resume_cards_action.title'),
          action: () => {
            this.openResumeProjectCardsModal();
          },
          isAvailable: () => this.canManageOffer(), // TODO change
          isDisabled: () => {
            return !this.showResumeProjectCardsFeature();
          },
        },
      ],
    };
  },
  async created() {
    this.clearProjectCards();
    this.page = this.listProjectCardsPageNumber;
    this.keyword = this.listProjectCardsKeyword;
    this.status = this.listProjectCardsStatus;
  },
  watch: {
    async project() {
      // TODO: this is commented because if it fires, the computed cardPublicTokens refreshes, and gets filled with the public tokens of all the cards in the batch, unfiltered. Is this desirable?
      // await this.fetchProjectCards();
      this.searched = '';
      this.page = 1;
      this.keyword = '';
      this.status = '';
      this.dirty = false;
    },
  },
  computed: {
    ...mapGetters('instantCardProject', [
      'project',
      'projectCards',
      'listProjectCardsKeyword',
      'listProjectCardsStatus',
      'listProjectCardsPageCount',
      'listProjectCardsPageNumber',
      'listProjectCardsItemCount',
    ]),
    ...mapGetters('ui', ['currentLocale']),
    cardPublicTokens() {
      return _.map(this.projectCards, (card) => {
        return card.public_token;
      });
    },
  },
  methods: {
    async clear() {
      this.searched = '';
      this.page = 1;
      this.keyword = '';
      this.status = '';
      this.dirty = false;
      this.$validator.reset();
    },
    async clearProjectCards() {
      return this.executeAction({ action: 'instantCardProject/clearProjectCards' });
    },
    showActionBtn() {
      return true; // TODO set
    },
    showUnlinkProjectCardsFeature() {
      return this.project.linked_card_count > 0;
    },
    openUnlinkProjectCardsModal() {
      this.showUnlinkProjectCardsModal = true;
    },
    closeUnlinkProjectCardsModal() {
      this.showUnlinkProjectCardsModal = false;
    },
    async unlinkProjectCardsSuccess() {
      await this.fetchProjectCards();
      await this.executeAction(
        { action: 'ui/showSuccessSnackBar' },
        { text: 'instant_card_projects.details.linked_cards.unlink_cards_action.success' },
      );
      this.closeUnlinkProjectCardsModal();
    },
    showPauseProjectCardsFeature() {
      return this.project.linked_card_count > 0;
    },
    openPauseProjectCardsModal() {
      this.showPauseProjectCardsModal = true;
    },
    closePauseProjectCardsModal() {
      this.showPauseProjectCardsModal = false;
    },
    async pauseProjectCardsSuccess() {
      await this.fetchProjectCards();
      await this.executeAction(
        { action: 'ui/showSuccessSnackBar' },
        { text: 'instant_card_projects.details.linked_cards.pause_cards_action.success' },
      );
      this.closePauseProjectCardsModal();
    },
    showResumeProjectCardsFeature() {
      return this.project.linked_card_count > 0;
    },
    openResumeProjectCardsModal() {
      this.showResumeProjectCardsModal = true;
    },
    closeResumeProjectCardsModal() {
      this.showResumeProjectCardsModal = false;
    },
    async resumeProjectCardsSuccess() {
      await this.fetchProjectCards();
      await this.executeAction(
        { action: 'ui/showSuccessSnackBar' },
        { text: 'instant_card_projects.details.linked_cards.resume_cards_action.success' },
      );
      this.closeResumeProjectCardsModal();
    },
    async fetchProjectCards() {
      await this.executeAction(
        { action: 'instantCardProject/listProjectCards' },
        {
          id: this.project.id,
          keyword: this.keyword,
          status: this.status,
          page: this.listProjectCardsPageNumber,
        },
      );
    },
    async submit(event, forceSearch = false, page = 1) {
      let validationComplete;
      if (!forceSearch) {
        validationComplete = await this.$validator.validateAll();
      }

      if (validationComplete || forceSearch) {
        await this.clearAllError();
        this.dataReady = false;
        await this.executeAction(
          { action: 'instantCardProject/listProjectCards' },
          {
            id: this.project.id,
            keyword: this.keyword,
            status: this.status,
            page: 1,
          },
        );
        this.dataReady = true;
        this.dirty = true;
      }
    },
  },
};
</script>
