<template>
  <card :title="$t('instant_card_cards.details.project_section.title')" icon="">
    <template slot="headerZoneRight">
      <link-secondary-action v-if="showActionBtn()" slot="labelTextLink">
        <button-secondary-actions :text="$t(`instant_card_cards.details.project_section.actions`)" :actions="actions"> </button-secondary-actions>
      </link-secondary-action>
    </template>

    <template slot="content">
      <v-layout row>
        <v-flex xs3>
          <label-text :label="$t(`instant_card_cards.details.project_section.id`)" :value="card.instant_card_project_id">
            <p v-if="card.instant_card_project_id" class="label-text--value" slot="labelTextLink">
              <router-link :to="{ name: 'instant_card_project_details', params: { projectId: card.instant_card_project_id } }">{{
                card.instant_card_project_id
              }}</router-link>
            </p>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </label-text>
        </v-flex>
        <v-flex xs3>
          <label-text
            v-if="card.instant_card_project && card.instant_card_project.name"
            :label="$t('instant_card_cards.details.project_section.name')"
            :value="card.instant_card_project.name"
          />
        </v-flex>

        <v-flex xs3>
          <label-text v-if="card.partner_id" :label="$t('instant_card_cards.details.project_section.program')" :value="card.partner_id" />
        </v-flex>

        <v-flex xs3>
          <label-text
            v-if="card.instant_card_project && card.instant_card_project.type"
            :label="$t('instant_card_cards.details.project_section.type')"
            :value="$t(`instant_card_projects.details.project_information_section.type.options.${card.instant_card_project.type}`)"
          />
        </v-flex>
      </v-layout>
      <modal-action :show-modal="showLinkCardModal">
        <link-instant-card-action
          v-if="showLinkCardModal"
          :public-token="card.public_token"
          :hello-show="showLinkCardModal"
          @close="closeLinkCardModal"
          @linked="linkCardSuccess"
        />
      </modal-action>
      <modal-action :show-modal="showUnlinkCardModal">
        <unlink-instant-card-action
          v-if="showUnlinkCardModal"
          :public-token="card.public_token"
          :instant-card-project-id="card.instant_card_project_id"
          :hello-show="showUnlinkCardModal"
          @close="closeUnlinkCardModal"
          @unlinked="unlinkCardSuccess"
        />
      </modal-action>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';
import LinkInstantCardAction from '../actions/LinkInstantCardAction';
import UnlinkInstantCardAction from '../actions/UnlinkInstantCardAction';

export default {
  name: 'project',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { LinkInstantCardAction, UnlinkInstantCardAction },
  data() {
    return {
      dataReady: false,
      showLinkCardModal: false,
      showUnlinkCardModal: false,
      actions: [
        {
          slotName: 'LinkCardModal',
          name: this.$t('instant_card_cards.details.project_section.link_card_action.title'),
          action: () => {
            this.openLinkCardModal();
          },
          isAvailable: () => this.canManageOffer(), // TODO change
          isDisabled: () => {
            return !this.showLinkCardFeature();
          },
        },
        {
          slotName: 'UnlinkCardModal',
          name: this.$t('instant_card_cards.details.project_section.unlink_card_action.title'),
          action: () => {
            this.openUnlinkCardModal();
          },
          isAvailable: () => this.canManageOffer(), // TODO change
          isDisabled: () => {
            return !this.showUnlinkCardFeature();
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('instantCardCard', ['card']),
  },
  methods: {
    showActionBtn() {
      return true; // TODO set
    },

    showLinkCardFeature() {
      return !this.card.instant_card_project_id && this.card.status !== 'active';
    },
    openLinkCardModal() {
      this.showLinkCardModal = true;
    },
    closeLinkCardModal() {
      this.showLinkCardModal = false;
    },
    async linkCardSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'instant_card_cards.details.project_section.link_card_action.success' });
      this.closeLinkCardModal();
    },

    showUnlinkCardFeature() {
      return this.card.instant_card_project_id && this.card.status !== 'active';
    },
    openUnlinkCardModal() {
      this.showUnlinkCardModal = true;
    },
    closeUnlinkCardModal() {
      this.showUnlinkCardModal = false;
    },
    async unlinkCardSuccess() {
      await this.executeAction(
        { action: 'ui/showSuccessSnackBar' },
        { text: 'instant_card_cards.details.project_section.unlink_card_action.success' },
      );
      this.closeUnlinkCardModal();
    },
  },
};
</script>
