<template>
  <div>
    <table class="responsive-table" id="offer-activation-codes-table">
      <thead>
        <tr>
          <td>{{ $t('activation_code_batch.activation_codes_table.fields.id') }}</td>
          <td>{{ $t('activation_code_batch.activation_codes_table.fields.account_id') }}</td>
          <td>{{ $t('activation_code_batch.activation_codes_table.fields.voucher_id') }}</td>
          <td align="right">{{ $t('activation_code_batch.activation_codes_table.fields.remaining') }}</td>
          <td>{{ $t('activation_code_batch.activation_codes_table.fields.status') }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>
            <h5>{{ $t('activation_code_batch.activation_codes_table.fields.id') }}:</h5>
            <router-link :to="{ name: 'activation_code_details', params: { activationCodeId: item.id } }">
              {{ item.id }}
            </router-link>
          </td>
          <td>
            <h5>{{ $t('activation_code_batch.activation_codes_table.fields.account_id') }}</h5>
            <router-link v-if="item.account_id" :to="{ name: 'user_details', params: { userId: item.account_id } }">
              {{ item.account_id }}
            </router-link>
            <span v-else class="info-not-available">{{ $t('system.na') }}</span>
          </td>
          <td>
            <h5>{{ $t('activation_code_batch.activation_codes_table.fields.voucher_id') }}</h5>
            <router-link v-if="item.voucher_id" :to="{ name: 'voucher_details', params: { voucherId: item.voucher_id } }">
              {{ item.voucher_id }}
            </router-link>
            <span v-else class="info-not-available">{{ $t('system.na') }}</span>
          </td>
          <td align="right">
            <h5>{{ $t('activation_code_batch.activation_codes_table.fields.remaining') }}:</h5>
            <span v-if="item.voucher.remaining_amount !== null">{{ item.voucher.remaining_amount | currency | dollarSignI18n }}</span>
            <span v-else class="info-not-available">{{ $t('system.na') }}</span>
          </td>
          <td>
            <h5>{{ $t('activation_code_batch.activation_codes_table.fields.status') }}:</h5>
            <activation-code-status-view :status="item.system_status.status" />
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-xs-center">
      <v-pagination v-if="listPageCount > 1" v-model="pagination" :length="listPageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';
import ActivationCodeStatusView from '@/components/activation_code/ActivationCodeStatusView.vue';

export default {
  name: 'activation-codes-table',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { ActivationCodeStatusView },
  props: {
    items: {
      type: Array,
    },
    offerId: {
      type: String,
    },
    activationCodeBatchId: {
      type: String,
    },
  },
  data() {
    return {
      pagination: 1,
    };
  },
  mounted() {
    this.pagination = this.listPageNumber;
  },
  computed: {
    ...mapGetters('activationCode', ['listKeyword', 'listPageNumber', 'listPageCount']),
  },
  watch: {
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'activationCode/listActivationCodesByOffer',
        },
        {
          offerId: this.offerId,
          keyword: this.listKeyword,
          batchId: this.activationCodeBatchId,
          page: newValue,
        },
      );
    },
  },
};
</script>
