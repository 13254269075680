<template>
  <card-modal
    icon=""
    :title="$t('branding.create_action.title')"
    context="createBranding"
    @submit="submit"
    @cancel="cancel"
    cancel_text="branding.create_action.button_cancel"
    submit_text="branding.create_action.button_save"
    data-test="branding--add-modal"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-text-field
          autofocus
          :label="$t('branding.create_action.name') + '*'"
          name="name"
          v-validate="'required|max:100'"
          :error-messages="errors.collect('name')"
          v-model="branding.name"
          data-vv-name="name"
          maxlength="100"
          :data-vv-as="$t('branding.create_action.name')"
          data-test="add-branding--name"
          required
        ></v-text-field>
        <v-select
          :items="typesTranslated"
          name="type"
          v-validate="'required'"
          v-model="branding.type"
          :label="$t('branding.create_action.type') + '*'"
          :error-messages="errors.collect('type')"
          data-vv-name="type"
          :data-vv-as="$t('branding.create_action.type')"
          item-text="text"
          item-value="key"
          data-test="add-branding--type"
          v-on:change="onTypeChange"
        ></v-select>
        <v-select
          :items="brandingCategoriesFilteredIfTypeRequires(branding.type)"
          name="category"
          v-validate="'required'"
          v-model="branding.branding_category_id"
          :label="$t('branding.create_action.category') + '*'"
          :error-messages="errors.collect('category')"
          data-vv-name="category"
          :data-vv-as="$t('branding.create_action.category')"
          :item-text="`name[${currentLocale}]`"
          item-value="id"
          data-test="add-branding--category"
        ></v-select>
        <v-text-field
          :label="$t('branding.create_action.label.fr') + '*'"
          name="label.fr"
          v-validate="'required|max:100'"
          :error-messages="errors.collect('label.fr')"
          v-model="branding.label.fr"
          data-vv-label="label.fr"
          maxlength="100"
          :data-vv-as="$t('branding.create_action.label.fr')"
          data-test="add-branding--label-fr"
          required
        ></v-text-field>
        <v-text-field
          :label="$t('branding.create_action.label.en') + '*'"
          name="label.en"
          v-validate="'required|max:100'"
          :error-messages="errors.collect('label.en')"
          v-model="branding.label.en"
          data-vv-label="label.en"
          maxlength="100"
          :data-vv-as="$t('branding.create_action.label.en')"
          data-test="add-branding--label-en"
          required
        ></v-text-field>
        <v-textarea
          name="description.fr"
          v-model="branding.description.fr"
          v-validate="'max:4096'"
          :error-messages="errors.collect('description.fr')"
          :label="$t('branding.create_action.description.fr')"
          data-vv-name="description.fr"
          :data-vv-as="$t('branding.create_action.description.fr')"
          auto-grow
          rows="1"
          maxlength="4096"
          data-test="add-branding--description-fr"
        ></v-textarea>
        <v-textarea
          name="description.en"
          v-model="branding.description.en"
          v-validate="'max:4096'"
          :error-messages="errors.collect('description.en')"
          :label="$t('branding.create_action.description.en')"
          data-vv-name="description.en"
          :data-vv-as="$t('branding.create_action.description.en')"
          auto-grow
          rows="1"
          maxlength="4096"
          data-test="add-branding--description-en"
        ></v-textarea>
        <v-text-field
          :label="$t('branding.create_action.tagline.fr')"
          name="tagline.fr"
          v-validate="'max:100'"
          :error-messages="errors.collect('tagline.fr')"
          v-model="branding.tagline.fr"
          data-vv-name="tagline.fr"
          maxlength="100"
          :data-vv-as="$t('branding.create_action.tagline.fr')"
          data-test="add-branding--tag-line-fr"
        ></v-text-field>
        <v-text-field
          :label="$t('branding.create_action.tagline.en')"
          name="tagline.en"
          v-validate="'max:100'"
          :error-messages="errors.collect('tagline.en')"
          v-model="branding.tagline.en"
          data-vv-name="tagline.en"
          maxlength="100"
          :data-vv-as="$t('branding.create_action.tagline.en')"
          data-test="add-branding--tag-line-en"
        ></v-text-field>
        <v-text-field
          :label="$t('branding.create_action.region_label.fr')"
          name="region_label.fr"
          v-validate="'max:50'"
          :error-messages="errors.collect('region_label.fr')"
          v-model="branding.region_label.fr"
          data-vv-name="region_label.fr"
          maxlength="50"
          :data-vv-as="$t('branding.create_action.region_label.fr')"
          data-test="add-branding--region-label-fr"
        ></v-text-field>
        <v-text-field
          :label="$t('branding.create_action.region_label.en')"
          name="region_label.en"
          v-validate="'max:50'"
          :error-messages="errors.collect('region_label.en')"
          v-model="branding.region_label.en"
          data-vv-name="region_label.en"
          maxlength="50"
          :data-vv-as="$t('branding.create_action.region_label.en')"
          data-test="add-branding--region-label-en"
        ></v-text-field>
        <v-text-field
          :label="$t('branding.create_action.url.fr')"
          name="url.fr"
          v-validate="'url'"
          :error-messages="errors.collect('url.fr')"
          v-model="branding.url.fr"
          data-vv-name="url.fr"
          :data-vv-as="$t('branding.create_action.url.fr')"
          data-test="add-branding--url-fr"
        ></v-text-field>
        <v-text-field
          :label="$t('branding.create_action.url.en')"
          name="url.en"
          v-validate="'url'"
          :error-messages="errors.collect('url.en')"
          v-model="branding.url.en"
          data-vv-name="url.en"
          :data-vv-as="$t('branding.create_action.url.en')"
          data-test="add-branding--url-en"
        ></v-text-field>
        <v-text-field
          name="color"
          v-model="branding.branding_color"
          v-validate="{ max: 7, regex: /^\#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/ }"
          :error-messages="errors.collect('color')"
          :label="$t('branding.create_action.color')"
          data-vv-name="color"
          :data-vv-as="$t('branding.create_action.color')"
          counter
          maxlength="7"
          data-test="add-branding--color"
        >
          <template v-slot:append
            ><div
              :style="`display: block; height: 28px; width: 28px; backgroundColor: ${branding.branding_color || 'transparent'}; borderRadius: 5px;`"
            ></div
          ></template>
        </v-text-field>
        <v-text-field
          type="tel"
          name="phone"
          :error-messages="errors.collect('phone')"
          v-model="branding.phone"
          :label="$t('branding.create_action.phone')"
          data-test="add-branding--phone-number"
        ></v-text-field>
        <v-container class="px-0" fluid>
          <h4>
            {{ $t('branding.update_action.features_title') }}
          </h4>
          <v-checkbox v-model="branding.features.panier_bleu" :label="$t('branding.update_action.panier_bleu')"></v-checkbox>
        </v-container>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'create-branding-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  data() {
    return {
      branding: { label: {}, description: {}, tagline: {}, url: {}, region_label: {}, features: { panier_bleu: false } },
    };
  },
  async created() {
    await this.executeAction({
      action: 'branding/listCategories',
    });
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('branding', ['types', 'brandingCategoriesFilteredIfTypeRequires']),
    typesTranslated() {
      return _.map(this.types, (type) => ({
        text: this.$t(`branding.types.${type}`),
        key: type,
      })).sort((a, b) => a.text > b.text);
    },
  },
  methods: {
    clear() {
      this.branding = { label: {}, description: {}, tagline: {}, url: {}, region_label: {} };
      this.$validator.reset();
    },
    success(response) {
      this.clear();
      this.$emit('created');
      this.$router.push({ name: 'branding_details', params: { brandingId: response.id } });
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        const payload = this.branding;
        if (payload.phone) {
          payload.phone = this.$options.filters.formatPhone(payload.phone);
        }

        await this.executeAction(
          {
            action: 'branding/createBranding',
            name: 'createBranding',
            success: this.success,
          },
          payload,
        );
      }
    },
    async tryClearCategoryIfTypeInvalid(type) {
      const currentCategory = this.brandingCategoriesFilteredIfTypeRequires(type).find((brand) => brand.id == this.branding.branding_category_id);
      this.branding.branding_category_id = currentCategory ? currentCategory.id : undefined;
    },
    onTypeChange(newType) {
      this.tryClearCategoryIfTypeInvalid(newType);
    },
  },
};
</script>
