<template>
  <card icon="" :title="$t('card.aml_note_section.title')" class="card-aml-notes-section hc-aml-notes-section">
    <template slot="headerZoneRight">
      <label-text :label="$t('card.aml_note_section.note_count')" :value="amlNotes.length" />

      <div v-if="showAddAmlNoteFeature()" class="card-header-divider--vertical hidden-xs-only">
        <v-divider vertical></v-divider>
      </div>

      <label-text v-if="showAddAmlNoteFeature()" :label="`${$t('card.aml_note_section.action')}`" value="3">
        <link-secondary-action slot="labelTextLink" @click="showModalNote">{{ $t('card.aml_note_section.add_note') }}</link-secondary-action>
      </label-text>
    </template>

    <template slot="content">
      <modal-action v-if="showAddAmlNoteFeature()" :show-modal="showNoteModal">
        <create-aml-note-action
          :card-application-id="cardApplicationId"
          :public-token="publicToken"
          :card-program-key="cardProgramKey"
          :cardholder-key="cardholderKey"
          :account-id="accountId"
          @close="closeModalNote"
          @action="reloadPage"
        />
      </modal-action>

      <delete-with-reason-modal
        v-if="showAddAmlNoteFeature()"
        :show-modal="showDeleteNoteModal"
        object-type="Note"
        @confirm="onDeleteNote"
        @close="hideDeleteNoteModal"
      />

      <label-none v-if="amlNotes.length === 0">{{ $t('card.aml_note_section.no_found') }}</label-none>

      <div v-for="(item, index) in amlNotes" :key="index">
        <card-aml-note :note="item" :has-divider="isLastNote(index)" :key="index" />
      </div>
    </template>
  </card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { disableWithError, actionErrorTrackable, security } from '@/mixins';
import CardAmlNote from './CardAmlNote';
import CreateAmlNoteAction from '@/components/system/AML/CreateAmlNoteAction';

export default {
  name: 'aml-notes-list',

  mixins: [actionErrorTrackable, disableWithError, security],

  components: { CardAmlNote, CreateAmlNoteAction },

  props: {
    publicToken: {
      type: String,
      required: true,
    },
    accountId: {
      type: String,
      required: false,
    },
    cardApplicationId: {
      type: String,
      required: false,
    },
    cardholderKey: {
      type: String,
      required: true,
    },
    cardProgramKey: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      showNoteModal: false,
    };
  },

  async created() {
    await Promise.all([this.listNoteTypes(), this.listNoteCategories(), this.fetch()]);
  },

  computed: {
    ...mapGetters('aml', ['amlNotes', 'currentNote', 'showDeleteNoteModal']),
  },

  methods: {
    ...mapActions('aml', ['getAmlNotes', 'deleteNote', 'hideDeleteNoteModal', 'listNoteTypes', 'listNoteCategories']),

    async fetch() {
      await this.getAmlNotes({ card_application_id: this.cardApplicationId, cardholder_key: this.cardholderKey, account_id: this.accountId });
    },

    isLastNote(index) {
      return index + 1 < this.amlNotes.length;
    },

    async onDeleteNote({ reason }) {
      const params = {
        key: this.currentNote.key,
        reason,
        card_application_id: this.cardApplicationId,
        cardholder_key: this.cardholderKey,
        account_id: this.accountId,
      };

      await this.deleteNote(params);
    },

    showModalNote() {
      this.showNoteModal = true;
    },

    closeModalNote() {
      this.showNoteModal = false;
    },

    reloadPage() {
      this.showNoteModal = false;
      this.$router.replace({ name: 'card', params: { cardPublicToken: this.publicToken } });
    },

    showAddAmlNoteFeature() {
      return this.canManageAmlProgram();
    },
  },
};
</script>
