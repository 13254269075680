<template>
  <card-modal
    icon=""
    :title="`${$t('wallet_definition.rename_wallet_definition_action.title')}`"
    @submit="submit"
    @cancel="cancel"
    cancel_text="wallet_definition.rename_wallet_definition_action.button_cancel"
    submit_text="wallet_definition.rename_wallet_definition_action.button_submit"
    :submit_working="working"
    :enable_submit="isFormChanged"
    context="RenameWalletDefinition"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout align-center>
        <v-flex md6>
          <v-text-field
            v-model="currentWalletDefinition.name"
            maxlength="100"
            :label="$t('wallet_definition.rename_wallet_definition_action.name')"
            :error-messages="errors.collect('name')"
            v-validate="'required|max:100'"
            data-vv-name="name"
            name="name"
            required
          ></v-text-field>
        </v-flex>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import _ from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'rename-wallet-definition-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    walletDefinition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentWalletDefinition: { rules: {}, statistics: {}, labels: {} },
    };
  },
  async mounted() {
    this.currentWalletDefinition = Object.assign({ rules: {}, statistics: {}, labels: {} }, _.cloneDeep(this.walletDefinition));
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    isFormChanged() {
      return this.walletDefinition.name != this.currentWalletDefinition.name;
    },
  },
  methods: {
    clear() {
      this.currentWalletDefinition = { rules: {}, statistics: {}, labels: {} };
      this.$validator.reset();
    },
    cancel() {
      this.currentWalletDefinition = Object.assign({}, this.walletDefinition);
      this.$validator.reset();
      this.$emit('close_rename_action');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        await this.executeAction(
          {
            action: 'walletDefinition/renameWalletDefinition',
            name: 'RenameWalletDefinition',
            success: this.success,
          },
          {
            id: this.walletDefinition.id,
            name: this.currentWalletDefinition.name,
          },
        );
      }
    },
    success() {
      this.clear();
      this.$emit('wallet_definition_renamed');
    },
  },
};
</script>
