<template>
  <card :title="$t('instant_card_batches.details.delivery_section.title')" icon="">
    <template slot="headerZoneRight">
      <label-text :label="`${$t('instant_card_batches.details.delivery_section.work_order_id')}`" :value="workOrderId"></label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>

      <label-text v-if="showUpdateBatchFeature()" :label="`${$t('instant_card_batches.details.delivery_section.action')}`" value="3">
        <link-secondary-action slot="labelTextLink" @click="openUpdateBatchTrackingModal">
          {{ $t('instant_card_projects.details.project_information_section.update_project') }}</link-secondary-action
        >
      </label-text>
    </template>
    <template slot="content">
      <v-layout row>
        <v-flex xs3>
          <label-text :label="$t(`instant_card_batches.details.delivery_section.business`)" :value="batch.business_name">
            <p v-if="batch.business_id" class="label-text--value" slot="labelTextLink">
              <router-link :to="{ name: 'business_details', params: { businessId: batch.business_id } }"
                >{{ batch.business_name }} ({{ batch.business_id }})</router-link
              >
            </p>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </label-text>
        </v-flex>
        <v-flex xs3>
          <label-text :label="`${$t('instant_card_batches.details.batch_information_section.establishment')}`">
            <router-link
              slot="labelTextLink"
              v-if="batch.establishment_business_id"
              :to="{ name: 'business_details', params: { businessId: batch.establishment_business_id } }"
            >
              <span>{{ batch.establishment_partner_id }} - {{ batch.establishment_business_name }} ({{ batch.establishment_id }})</span>
            </router-link>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </label-text>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs3>
          <label-text :label="$t(`instant_card_batches.details.delivery_section.tracking_number`)">
            <p v-if="batch.delivery && batch.delivery.tracking_number" class="label-text--value" slot="labelTextLink">
              {{ batch.delivery.tracking_number }}
            </p>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </label-text>
        </v-flex>
        <v-flex xs6>
          <label-text
            v-if="batch.delivery_address"
            :label="$t(`instant_card_batches.details.delivery_section.shipping_address`)"
            :value="fullAddress(batch.delivery_address)"
          />
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs3>
          <label-text :label="$t(`instant_card_batches.details.delivery_section.attn`)">
            <p v-if="batch.delivery && batch.delivery.attn" class="label-text--value" slot="labelTextLink">
              {{ batch.delivery.attn }}
            </p>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </label-text>
        </v-flex>
        <v-flex xs3>
          <label-text :label="$t(`instant_card_batches.details.delivery_section.phone`)">
            <p v-if="batch.delivery && batch.delivery.phone" class="label-text--value" slot="labelTextLink">
              {{ batch.delivery.phone }}
            </p>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </label-text>
        </v-flex>
        <v-flex xs3>
          <label-text :label="`${$t('instant_card_batches.details.delivery_section.business_name')}`">
            <p v-if="batch.delivery && batch.delivery.business_name" class="label-text--value" slot="labelTextLink">
              {{ batch.delivery.business_name }}
            </p>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </label-text>
        </v-flex>
      </v-layout>
      <modal-action :show-modal="showUpdateBatchTrackingModal"
        ><update-batch-tracking-action
          v-if="showUpdateBatchTrackingModal"
          :batch="batch"
          @close="closeUpdateBatchTrackingModal"
          @updated="updateBatchTrackingSuccess"
        ></update-batch-tracking-action
      ></modal-action>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import UpdateBatchTrackingAction from '../actions/UpdateBatchTrackingAction';
import { mapGetters } from 'vuex';

export default {
  name: 'delivery',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { UpdateBatchTrackingAction },
  data() {
    return {
      dataReady: false,
      showUpdateBatchTrackingModal: false,
    };
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('instantCardBatch', ['batch']),
    workOrderId() {
      return this.batch.delivery ? this.batch.delivery.card_printer_work_order_id : null;
    },
  },
  methods: {
    openUpdateBatchTrackingModal() {
      this.showUpdateBatchTrackingModal = true;
    },
    closeUpdateBatchTrackingModal() {
      this.showUpdateBatchTrackingModal = false;
    },
    async updateBatchTrackingSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'instant_card_batches.update_tracking_action.success' });
      this.closeUpdateBatchTrackingModal();
    },
    showUpdateBatchFeature() {
      return this.canManageOffer(); // TODO: change permission
    },
    fullAddress(address) {
      return `${address.street}, ${address.city}, ${address.state}, ${address.country}, ${address.zip}`;
    },
  },
};
</script>

<style scoped lang="stylus">
@import './../../../styles/variables/custom-variables.styl';

h5 {
  color: $hc-blue;

  &.sub-title {
    color: $font-color;
  }
}
</style>
