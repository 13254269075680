<template>
  <card-modal
    icon=""
    :title="$t('activation_code_batch.details.actions.update.title')"
    context="updateActivationCodeBatch"
    @submit="submit"
    @cancel="cancel"
    cancel_text="activation_code_batch.details.actions.update.button_cancel"
    submit_text="activation_code_batch.details.actions.update.button_save"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-layout row wrap>
          <v-flex sm12 xs12>
            <h-autocomplete
              v-model="updateParams.business_id"
              :label="$t('activation_code_batch.details.actions.update.business_id')"
              :error-messages="errors.collect('business_id')"
              name="business_id"
              data-vv-name="business_id"
              data-vv-as="business_id"
              search-action="business/autocompleteSearch"
              data-test="create-activation-code-batch--contributor"
            >
            </h-autocomplete>
          </v-flex>
        </v-layout>
        <v-layout row wrap pad-form-row-elements>
          <v-flex sm12 xs12>
            <v-textarea
              counter
              :label="$t('activation_code_batch.details.actions.update.personalized_message')"
              name="personalized_message"
              v-validate="{
                max: 150,
                maxLines: { nbLines: 6 },
              }"
              type="text"
              :error-messages="errors.collect('personalized_message')"
              v-model="updateParams.personalized_message"
              data-vv-name="personalized_message"
              maxlength="150"
              :data-vv-as="$t('activation_code_batch.details.actions.update.personalized_message')"
            ></v-textarea>
          </v-flex>
          <v-flex>
            <v-text-field
              :label="$t('activation_code_batch.details.actions.update.reference_id')"
              name="reference_id"
              v-validate="'max:100'"
              :error-messages="errors.collect('reference_id')"
              v-model="updateParams.reference_id"
              data-vv-name="reference_id"
              maxlength="100"
              :data-vv-as="$t('activation_code_batch.details.actions.update.reference_id')"
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'update-activation-code-batch-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    activationCodeBatch: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      updateParams: { id: null, business_id: null, reference_id: null, personalized_message: null },
    };
  },
  mounted() {
    this.setUpdateParams();
  },
  watch: {
    activationCodeBatch() {
      this.setUpdateParams();
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    clear() {
      this.updateParams = { id: null, business_id: null, reference_id: null, personalized_message: null };
      this.$validator.reset();
    },
    success(response) {
      this.clear();
      this.$emit('updated');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();
      if (validationComplete) {
        await this.executeAction(
          {
            action: 'activationCodeBatch/updateActivationCodeBatch',
            name: 'updateActivationCodeBatch',
            success: this.success,
          },
          this.updateParams,
        );
      }
    },
    setUpdateParams() {
      if (this.activationCodeBatch) {
        this.updateParams = {
          id: this.activationCodeBatch.id,
          reference_id: this.activationCodeBatch.reference_id,
          business_id: this.activationCodeBatch.business_id,
          personalized_message: this.activationCodeBatch.personalized_message,
        };
      }
    },
  },
};
</script>
