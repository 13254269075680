<template>
  <card-modal
    icon="fal fa-exchange"
    :title="$t('purse.empty_open_purse_action.title')"
    context="emptyOpenPurseByAccountId"
    @submit="openConfirmModal"
    @cancel="cancel"
    cancel_text="purse.empty_open_purse_action.button_cancel"
    submit_text="purse.empty_open_purse_action.button_submit"
    :submit_working="working"
  >
    <form @submit.prevent="openConfirmModal" slot="content">
      <v-layout column>
        <v-layout row wrap>
          <v-text-field
            v-model="reason"
            :label="$t('purse.empty_open_purse_action.form.reason.label') + ' *'"
            required
            v-validate="{ required: true }"
            data-vv-name="Reason"
            name="reason"
            :error-messages="errors.collect('Reason')"
          ></v-text-field>
        </v-layout>
      </v-layout>

      <modal-confirm
        :show-modal="showConfirmModal"
        :title="$t('purse.empty_open_purse_action.confirm.title')"
        context="emptyOpenPurseByAccountId"
        :text="
          $t('purse.empty_open_purse_action.confirm.text', {
            id: account.id,
            email: account.contact.email.value,
          })
        "
        cancel_text="purse.empty_open_purse_action.confirm.button_cancel"
        submit_text="purse.empty_open_purse_action.confirm.button_submit"
        :submit_working="working"
        @submit="submit"
        @close="closeConfirmModal"
      ></modal-confirm>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';

export default {
  name: 'adjust-purse-balance-action',
  $_veeValidate: {
    validator: 'new',
  },
  mixins: [actionErrorTrackable, security],
  props: {
    account: {
      type: Object,
      required: true,
    },
  },
  data: function() {
    return {
      reason: '',
      showConfirmModal: false,
    };
  },
  computed: {},
  methods: {
    clear() {
      this.resetForm();
      this.$validator.reset();
    },
    resetForm() {
      this.reason = '';
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    success() {
      this.clear();

      this.closeConfirmModal();
      this.$emit('action');
      this.$emit('close');
    },
    error() {
      this.closeConfirmModal();
    },
    async submit() {
      await this.executeAction(
        {
          action: 'purse/emptyOpenPurseByAccountId',
          name: 'emptyOpenPurseByAccountId',
          success: this.success,
          afterError: this.error,
        },
        { account_id: this.account.id, reason: this.reason },
      );
    },
    async openConfirmModal() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        this.showConfirmModal = true;
      }
    },
    closeConfirmModal() {
      this.showConfirmModal = false;
    },
  },
};
</script>
