<template>
  <card-modal
    icon="fal fa-store-alt"
    :title="$t('offers.details.activation_codes_section.batch_action.title')"
    context="createActivationCodeBatch"
    @submit="submit"
    @cancel="cancel"
    :submit_working="submitting"
    :submit_hidden="submitted"
    :cancel_text="cancelText"
    submit_text="offers.details.activation_codes_section.batch_action.submit_text"
  >
    <div slot="content">
      <form @submit.prevent="submit">
        <template v-if="!submitted">
          <v-layout column>
            <modal-info-block :heading="$t('offers.details.activation_codes_section.batch_action.offer')" :text="offerInfo"></modal-info-block>
          </v-layout>
          <v-layout>
            <v-flex>
              <v-text-field
                :label="$t('offers.details.activation_codes_section.batch_action.reference_id')"
                name="reference_id"
                v-validate="'max:100'"
                :error-messages="errors.collect('reference_id')"
                v-model="activationCodeData.reference_id"
                data-vv-name="reference_id"
                maxlength="100"
                :data-vv-as="$t('offers.details.activation_codes_section.batch_action.reference_id')"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row wrap pad-form-row-elements>
            <v-flex sm4 xs12>
              <v-text-field
                autofocus
                :label="$t('offers.details.activation_codes_section.batch_action.payment_amount') + '*'"
                name="payment_amount"
                v-validate="{
                  required: true,
                  integer: true,
                  min_value: 0,
                  max_value: 1000,
                }"
                type="number"
                :error-messages="errors.collect('payment_amount')"
                v-model="activationCodeData.payment_amount"
                data-vv-name="payment_amount"
                maxlength="255"
                :data-vv-as="$t('offers.details.activation_codes_section.batch_action.payment_amount')"
              ></v-text-field>
            </v-flex>
            <v-flex sm4 xs12>
              <v-text-field
                :label="$t('offers.details.activation_codes_section.batch_action.bonus_amount') + '*'"
                name="bonus_amount"
                v-validate="{
                  required: true,
                  integer: true,
                  min_value: 0,
                  max_value: 1000,
                  sumLessThanOrEqual: {
                    field: activationCodeData.payment_amount,
                    total: 1000,
                    totalFieldLabel: $t('offers.details.activation_codes_section.batch_action.amount'),
                  },
                }"
                type="number"
                :error-messages="errors.collect('bonus_amount')"
                v-model="activationCodeData.bonus_amount"
                data-vv-name="bonus_amount"
                maxlength="255"
                :data-vv-as="$t('offers.details.activation_codes_section.batch_action.bonus_amount')"
              ></v-text-field>
            </v-flex>
            <v-flex sm4 xs12>
              <label-text
                :label="$t('offers.details.activation_codes_section.batch_action.amount')"
                :value="totalAmount | currency | dollarSignI18n"
              ></label-text>
            </v-flex>
          </v-layout>
          <v-layout row pad-form-row-elements>
            <v-flex sm3 xs3>
              <v-select
                name="trigger_expiration_at_purchase"
                v-model="activationCodeData.trigger_expiration_at_purchase"
                v-validate="'required'"
                :error-messages="errors.collect('trigger_expiration_at_purchase')"
                data-vv-name="trigger_expiration_at_purchase"
                :data-vv-as="$t(`offers.details.activation_codes_section.batch_action.trigger_expiration_at_purchase.label`)"
                :items="trigger_expiration_at_purchase_options"
                item-text="name"
                :label="$t(`offers.details.activation_codes_section.batch_action.trigger_expiration_at_purchase.label`)"
                data-test="create-activation-code-batch--trigger-expiration-at-purchase"
              ></v-select>
            </v-flex>
          </v-layout>
          <v-layout row wrap pad-form-row-elements>
            <v-flex sm6 xs12>
              <v-checkbox
                name="pre_activation_required"
                v-model="activationCodeData.pre_activation_required"
                :label="$t(`offers.details.activation_codes_section.batch_action.pre_activation_required`)"
                :error-messages="errors.collect('pre_activation_required')"
                data-vv-name="pre_activation_required"
                :data-vv-as="$t(`offers.details.activation_codes_section.batch_action.pre_activation_required`)"
                type="checkbox"
              ></v-checkbox>
            </v-flex>
          </v-layout>
          <v-layout row wrap pad-form-row-elements>
            <v-flex sm6 xs12>
              <v-checkbox
                name="reward_funded"
                v-model="activationCodeData.bonus_amount_funded"
                :label="$t(`offers.details.activation_codes_section.batch_action.bonus_amount_funded`)"
                :error-messages="errors.collect('reward_funded')"
                data-vv-name="reward_funded"
                :data-vv-as="$t(`offers.details.activation_codes_section.batch_action.bonus_amount_funded`)"
                type="checkbox"
              ></v-checkbox>
            </v-flex>
          </v-layout>
          <v-layout row wrap pad-form-row-elements>
            <v-flex sm6 xs12>
              <v-checkbox
                name="payment_funded"
                v-model="activationCodeData.payment_amount_funded"
                :label="$t(`offers.details.activation_codes_section.batch_action.payment_amount_funded`)"
                :error-messages="errors.collect('payment_funded')"
                data-vv-name="payment_funded"
                :data-vv-as="$t(`offers.details.activation_codes_section.batch_action.payment_amount_funded`)"
                type="checkbox"
              ></v-checkbox>
            </v-flex>
          </v-layout>
          <v-layout row wrap pad-form-row-elements>
            <v-flex sm3 xs3>
              <v-text-field
                :label="$t('offers.details.activation_codes_section.batch_action.batch_quantity') + ' *'"
                name="batch_quantity"
                v-validate="{
                  required: true,
                  min_value: 0,
                  max_value: 1000,
                  integer: true,
                  max: 4,
                }"
                maxlength="4"
                :error-messages="errors.collect('batch_quantity')"
                v-model="activationCodeData.batch_quantity"
                data-vv-name="batch_quantity"
                type="number"
                :data-vv-as="$t('offers.details.activation_codes_section.batch_action.batch_quantity')"
              ></v-text-field>
            </v-flex>
            <v-flex sm3 xs3>
              <label-text
                :label="$t('offers.details.activation_codes_section.batch_action.batch_amount')"
                :value="totalBatchAmount | currency | dollarSignI18n"
              ></label-text>
            </v-flex>
          </v-layout>
          <v-layout row wrap pad-form-row-elements>
            <v-flex sm8 xs12>
              <v-text-field
                :label="$t('offers.details.activation_codes_section.batch_action.batch_name') + ' *'"
                name="batch_name"
                v-validate="{
                  required: true,
                  max: 50,
                }"
                maxlength="50"
                :error-messages="errors.collect('batch_name')"
                v-model="activationCodeData.batch_name"
                data-vv-name="batch_name"
                type="text"
                :data-vv-as="$t('offers.details.activation_codes_section.batch_action.batch_name')"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex sm12 xs12>
              <h-autocomplete
                v-model="business_id"
                :label="$t('offers.details.activation_codes_section.batch_action.business_id') + '*'"
                v-validate="'required'"
                :error-messages="errors.collect('contributor')"
                name="contributor"
                data-vv-name="contributor"
                data-vv-as="contributor"
                search-action="business/autocompleteSearch"
                data-test="create-activation-code-batch--contributor"
              >
              </h-autocomplete>
            </v-flex>
          </v-layout>
          <v-layout row wrap pad-form-row-elements>
            <v-flex sm12 xs12>
              <v-textarea
                counter
                :label="$t('offers.details.activation_codes_section.batch_action.personalized_message')"
                name="personalized_message"
                v-validate="{
                  max: 150,
                  maxLines: { nbLines: 6 },
                }"
                type="text"
                :error-messages="errors.collect('personalized_message')"
                v-model="activationCodeData.personalized_message"
                data-vv-name="personalized_message"
                maxlength="150"
                :data-vv-as="$t('offers.details.activation_codes_section.batch_action.personalized_message')"
              ></v-textarea>
            </v-flex>
          </v-layout>
        </template>
      </form>

      <template v-if="submitted">
        <v-layout row wrap>
          <v-flex xs12>
            <v-alert :value="true" color="success" icon="check_circle" outline>
              {{ $t('offers.details.activation_codes_section.batch_action.success_message') }}
            </v-alert>
          </v-flex>
          <v-flex sm10 xs12>
            <h4>{{ $t('offers.details.activation_codes_section.batch_action.offer') }}</h4>
            <p>{{ offerInfo }}</p>
            <v-layout>
              <v-flex xs6>
                <label-text :label="`${$t('offers.details.activation_codes_section.batch_action.batch_id')}`" :value="response.batch_id">
                  <p slot="labelTextLink">
                    <span class="font-weight-bold">{{ response.batch_id }}</span>
                    <button class="clipboard" v-clipboard:copy="response.batch_id">
                      <v-icon small>file_copy</v-icon>
                    </button>
                  </p>
                </label-text>
              </v-flex>
              <v-flex xs6>
                <label-text :label="`${$t('offers.details.activation_codes_section.batch_action.batch_name')}`" :value="response.batch_name">
                  <p slot="labelTextLink">
                    <span class="font-weight-bold">{{ response.batch_name }}</span>
                    <button class="clipboard" v-clipboard:copy="response.batch_name">
                      <v-icon small>file_copy</v-icon>
                    </button>
                  </p>
                </label-text>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </template>
    </div>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import _ from 'lodash';
import Vue from 'vue';

export default {
  name: 'create-activation-code-batch-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    offerObj: {
      type: Object,
    },
  },
  data() {
    return {
      submitting: false,
      submitted: false,
      response: {},
      business_id: '',
      activationCodeData: {
        reference_id: null,
        batch_quantity: 1,
        bonus_amount: this.offerObj.incentive.bonus_amount,
        payment_amount: this.offerObj.incentive.payment_amount,
        bonus_amount_funded: false,
        payment_amount_funded: false,
        personalized_message: null,
        trigger_expiration_at_purchase: null,
        pre_activation_required: false,
      },
      cancelText: 'offers.details.activation_codes_section.batch_action.cancel_text',
    };
  },
  watch: {
    offerObj() {
      this.activationCodeData = {
        reference_id: null,
        batch_quantity: 1,
        bonus_amount: this.offerObj.incentive.bonus_amount,
        payment_amount: this.offerObj.incentive.payment_amount,
        personalized_message: null,
        bonus_amount_funded: false,
        payment_amount_funded: false,
        trigger_expiration_at_purchase: null,
        pre_activation_required: false,
      };
    },
  },
  computed: {
    trigger_expiration_at_purchase_options() {
      const options = [
        { name: this.$t('offers.details.activation_codes_section.batch_action.trigger_expiration_at_purchase.options.activation'), value: false },
      ];

      if (this.offerObj.incentive.duration_model === 'relative') {
        options.unshift({
          name: this.$t('offers.details.activation_codes_section.batch_action.trigger_expiration_at_purchase.options.purchase'),
          value: true,
        });
      }

      return options;
    },
    totalAmount() {
      return Number(this.activationCodeData.payment_amount || 0) + Number(this.activationCodeData.bonus_amount || 0);
    },
    totalBatchAmount() {
      return this.totalAmount * Number(this.activationCodeData.batch_quantity || 0);
    },
    offerInfo() {
      return `${this.offerObj.branding.name} (${this.offerObj.id})`;
    },
  },
  methods: {
    clear() {
      this.submitting = false;
      this.submitted = false;
      this.response = {};
      this.business_id = '';
      this.activationCodeData = {
        reference_id: null,
        batch_quantity: 1,
        bonus_amount: this.offerObj.incentive.bonus_amount,
        payment_amount: this.offerObj.incentive.payment_amount,
        trigger_expiration_at_purchase: null,
        pre_activation_required: false,
      };
      this.cancelText = 'offers.details.activation_codes_section.batch_action.cancel_text';
      this.$validator.reset();
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    buildPayload() {
      const payload = {
        offer_id: this.offerObj.id,
        batch_quantity: this.activationCodeData.batch_quantity,
        batch_name: this.activationCodeData.batch_name,
        bonus_amount: this.activationCodeData.bonus_amount,
        payment_amount: this.activationCodeData.payment_amount,
        bonus_amount_funded: this.activationCodeData.bonus_amount_funded,
        payment_amount_funded: this.activationCodeData.payment_amount_funded,
        pre_activation_required: this.activationCodeData.pre_activation_required,
        trigger_expiration_at_purchase: this.activationCodeData.trigger_expiration_at_purchase,
      };

      if (this.activationCodeData.reference_id) {
        payload.reference_id = this.activationCodeData.reference_id;
      }

      if (this.activationCodeData.personalized_message) {
        payload.personalized_message = this.activationCodeData.personalized_message;
      }

      if (this.business_id) {
        payload.business_id = this.business_id;
      }

      return payload;
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        this.submitting = true;
        const payload = this.buildPayload();

        try {
          await this.executeAction(
            {
              action: 'activationCode/createActivationCodeBatch',
              name: 'createActivationCodeBatch',
              success: (payload) => {
                this.cancelText = 'offers.details.activation_codes_section.batch_action.close_text';
                this.response = payload;
                this.submitted = true;
              },
            },
            payload,
          );
        } finally {
          this.submitting = false;
        }
      }
    },
  },
};
</script>
