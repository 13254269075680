function tryToAddAddressPart(part, currentAddress) {
  if (part) {
    if (currentAddress) {
      return `${currentAddress}, ${part}`;
    }
    return `${part}`;
  }
  return currentAddress;
}
export default ({ Vue }) => {
  Vue.filter('addressOneLine', (address) => {
    let default_address = null;

    default_address = tryToAddAddressPart(address.street, default_address);
    default_address = tryToAddAddressPart(address.city, default_address);
    default_address = tryToAddAddressPart(address.state, default_address);
    default_address = tryToAddAddressPart(address.country, default_address);
    default_address = tryToAddAddressPart(address.zip, default_address);

    return default_address;
  });
};
