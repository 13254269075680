<script>
import disabler from '@/mixins/disable-with-error';

export default {
  name: 'button-secondary-actions',
  mixins: [disabler],
  props: {
    text: {
      type: String,
    },
    actions: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
    },
    context: {
      type: Object,
    },
  },
  data: () => {
    return {
      showActionMenu: false,
      btnConfig: {},
    };
  },
  mounted: function() {
    this.btnConfig = this.getBtnConfig(this.type);
  },
  methods: {
    closeMenu() {
      this.showActionMenu = false;
    },
    getBtnConfig() {
      let btnConfig;

      switch (this.type) {
        case 'more_vertical':
          btnConfig = {
            props: { fab: true, small: true, flat: true, disabled: this.isGlobalErrorActive },
            getContent: (h) => {
              return [h('v-icon', {}, 'more_vert')];
            },
          };
          break;
        default:
          btnConfig = {
            props: { outline: true, color: 'info', disabled: this.isGlobalErrorActive },
            getContent: (h) => {
              return [this.text, h('span', { style: 'padding-left: 5px;' }, ' '), h('v-icon', { props: { small: true } }, 'fa-angle-down')];
            },
          };
          break;
      }

      return btnConfig;
    },
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        button: (event) => {
          this.$emit('button', event.target.value);
        },
      };
    },
  },

  render(h) {
    if (this.actions.reduce((acc, val) => val.isAvailable(this.context) || acc, false)) {
      return h(
        'v-menu',
        {
          props: {
            disabled: this.isGlobalErrorActive,
            'offset-y': true,
            'v-model': this.showActionMenu,
            'v-show': this.actions.reduce((acc, val) => val.isAvailable(this.context) || acc, false),
            left: true,
          },
        },
        [
          h(
            'v-btn',
            {
              props: this.btnConfig.props,
              slot: 'activator',
              on: this.listeners,
              'v-show': this.actions.reduce((acc, val) => val.isAvailable(this.context) || acc, false),
            },
            this.btnConfig.getContent ? this.btnConfig.getContent(h) : null,
          ),
          h(
            'v-list',
            this.actions.map((e) => {
              if (e.isAvailable(this.context)) {
                const obj = {
                  props: { disabled: e.isDisabled && e.isDisabled(this.context) },
                  on: {
                    click: (event) => {
                      event.stopPropagation();
                      this.closeMenu;
                      e.action(this.context);
                    },
                  },
                  class: {},
                };
                obj.class[`${e.slotName}-action-btn`] = true;
                return h('v-list-tile', obj, [e.name, this.$slots[e.slotName]]);
              }
            }),
          ),
        ],
      );
    }
    return h();
  },
};
</script>
