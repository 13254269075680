<template>
  <div v-if="currentTask && currentTask.resolution" data-test="resolution-result">
    <h3>{{ $t(`task.list.resolution.title`) }}</h3>
    <v-layout row wrap align-center justify-start class="data-card--content-zone">
      <v-flex xs12 class="d-inline-flex data-card--content-zone__upper">
        <label-text :label="$t(`task.list.resolution.date`)" :value="currentTask.resolution.resolved_on | formatDateHourMin" />

        <label-text :label="$t(`task.list.resolution.agent`)" :value="appName" data-test="resolution-agent" />

        <label-text
          :label="$t(`task.list.resolution.action`)"
          :value="$t(`task.list.aml_assessment_check_resolution.actions.${currentTask.resolution.action}`)"
          :data-test="currentTask.resolution.action"
          class="resolution-action"
        />

        <label-text
          v-if="currentTask.resolution && currentTask.resolution.params"
          :label="$t(`task.list.aml_assessment_check_resolution.reason`)"
          :value="$t(`task.list.aml_assessment_check_resolution.reasons.${currentTask.resolution.params.reason}`)"
          :data-test="currentTask.resolution.params.reason"
          class="resolution-reason"
        />

        <label-text :label="$t(`task.list.aml_note_to_review_resolution.category`)" :value="amlCategoryName" data-test="resolution-aml-category" />

        <label-text :label="$t(`task.list.aml_note_to_review_resolution.type`)" :value="amlTypeName" data-test="resolution-aml-type" />

        <label-text
          :label="$t(`task.list.aml_assessment_check_resolution.aml_note`)"
          :value="currentAmlNote && currentAmlNote.note"
          data-test="resolution-aml-note"
        />
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import _get from 'lodash/get';
import { mapActions, mapGetters } from 'vuex';
import { actionErrorTrackable, security } from '@/mixins';

export default {
  name: 'aml-assessment-check-hit-resolution-part',
  mixins: [actionErrorTrackable, security],
  props: {
    taskId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentTask: {},
      currentAmlNote: null,
      appName: '',
    };
  },
  methods: {
    ...mapActions('security', ['getAppName']),
    ...mapActions('task', ['getTask', 'getAmlTaskNote']),
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    amlTypeName() {
      return _get(this.currentAmlNote, `type.name.${this.currentLocale}`);
    },
    amlCategoryName() {
      return _get(this.currentAmlNote, `category.name.${this.currentLocale}`);
    },
  },
  async mounted() {
    this.currentTask = await this.getTask(this.taskId);
    this.currentAmlNote = await this.getAmlTaskNote(this.currentTask.id);
    this.appName = await this.getAppName(this.currentTask.resolution.resolved_by.app_key)
      .then((name) => name || '?')
      .catch((e) => console.log(e.stack || e)); // eslint-disable-line no-console
  },
};
</script>
