<template>
  <div class="modal-info-block">
    <h4>{{ heading }}</h4>
    <p>{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: 'button-error',
  props: {
    heading: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: true,
    },
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        button: (event) => this.$emit('button', event.target.value),
      };
    },
  },
};
</script>

<style scoped>
.modal-info-block p {
  font-weight: bold;
}
</style>
