<template>
  <card-modal
    icon=""
    :title="$t('voucher.transfer_action.title_to_user')"
    context="transferVoucherToUser"
    @submit="submit"
    @cancel="cancel"
    cancel_text="voucher.transfer_action.button_cancel"
    submit_text="voucher.transfer_action.button_save"
    :submit_working="working"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-layout row pad-form-row-elements>
          <modal-info-block :heading="$t('voucher.transfer_action.voucher_id')" :text="voucher.id"></modal-info-block>
          <v-spacer></v-spacer>
          <modal-info-block :heading="$t('voucher.transfer_action.account_id')" :text="accountEmail"></modal-info-block>
          <v-spacer></v-spacer>
        </v-layout>
        <v-layout column>
          <v-flex sm12 xs12>
            <h-autocomplete
              autofocus
              v-model="toAccountId"
              :label="$t('voucher.transfer_action.user') + '*'"
              v-validate="'required'"
              :error-messages="errors.collect('user')"
              name="user"
              data-vv-name="user"
              data-vv-as="user"
              search-action="account/autocompleteSearch"
              :add-asterisk-to-keyword="true"
              item-value="account_id"
              alternate-text="email"
              alternate-id="account_id"
              data-test="add-voucher--user"
              no-filter
            >
            </h-autocomplete>
          </v-flex>
          <v-flex sm12 xs12 v-if="toAccountId && loadableCardOptions.length">
            <v-select
              name="card"
              v-model="cardholder_id"
              :error-messages="errors.collect('card')"
              data-vv-name="card"
              :data-vv-as="$t(`voucher.transfer_action.card`)"
              :items="loadableCardOptions"
              item-text="text"
              item-value="value"
              :label="$t(`voucher.transfer_action.card`)"
            ></v-select>
          </v-flex>
          <v-flex sm12 xs12>
            <v-text-field
              ref="reason"
              name="reason"
              v-model="reason"
              :label="$t('voucher.transfer_action.reason')"
              v-validate="{ required: false, max: 1204 }"
              :error-messages="errors.collect('reason')"
              data-vv-name="reason"
              :data-vv-as="$t('voucher.transfer_action.reason')"
              data-test="update-voucher--reason"
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-layout>
    </form>
  </card-modal>
</template>
<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import Vue from 'vue';
import _reduce from 'lodash/reduce';
import _get from 'lodash/get';
import _ from 'lodash';

export default {
  name: 'transfer-voucher-action-to-user',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    voucher: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      toAccountId: null,
      reason: '',
      accountEmail: '?',
      cardholder_id: null,
      loadableCardOptions: [],
    };
  },
  async created() {
    let account = null;
    if (this.voucher) {
      account = await this.executeAction({ action: 'account/getAccount' }, this.voucher.account_id);
    }
    this.accountEmail = _get(account, 'contact.email.value', '?');
  },
  watch: {
    async toAccountId(val) {
      await this.executeAction(
        {
          action: 'account/listLoadableCardsByAccountId',
          name: 'listLoadableCardsByAccountId',
        },
        val,
      );
    },
    loadableCards() {
      this.cardholder_id = null;
      this.loadableCardOptions = _.map(this.loadableCards, (card) => {
        card.text = `${card.public_token} (${card.card_package_id}) ${card.masked_pan}`;
        card.value = card.cardholder_id;
        return card;
      });

      this.loadableCardOptions = _.concat([{ text: this.$t('voucher.transfer_action.unloaded_card'), value: null }], this.loadableCardOptions);
    },
  },
  computed: {
    ...mapGetters('account', ['accounts', 'loadableCards']),
  },
  methods: {
    clear() {
      this.toAccountId = null;
      this.reason = undefined;
      this.cardholder_id = null;
      this.loadableCardOptions = null;
      this.$validator.reset();
    },
    success() {
      this.clear();
      this.$emit('updated');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    buildPayload() {
      const payload = {
        id: this.voucher.id,
        to_account_id: this.toAccountId,
      };
      if (this.reason && this.reason !== '') {
        payload.reason = this.reason;
      }
      if (this.cardholder_id) {
        payload.to_cardholder_id = this.cardholder_id;
      }
      return payload;
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        await this.executeAction(
          {
            action: 'voucher/transferVoucher',
            name: 'transferVoucherToUser',
            success: this.success,
          },
          this.buildPayload(),
        );
      }
    },
  },
};
</script>
