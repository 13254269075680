<template>
  <card :title="$t('user.subscriptions')" icon="">
    <template slot="headerZoneRight">
      <label-text
        class="text-sm-center"
        :label="$t('user.details.subscriptions_section.count')"
        :value="accountSubscriptions.length.toString()"
      ></label-text>
    </template>

    <template slot="content">
      <user-subscriptions-table v-if="accountSubscriptions.length" :items="accountSubscriptions" />
      <label-none v-else>{{ $t('user.details.subscriptions_section.no_found') }}</label-none>
    </template>
  </card>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable, disableWithError, security } from '@/mixins';

import UserSubscriptionsTable from './UserSubscriptionsTable.vue';
import LabelNone from '@/components/system/Labels/LabelNone';

export default {
  name: 'user-subscriptions',

  mixins: [security, actionErrorTrackable, disableWithError],

  components: { LabelNone, UserSubscriptionsTable },

  data() {
    return {
      pagination: 1,
    };
  },

  computed: {
    ...mapGetters('account', ['accountSubscriptions', 'account']),
    ...mapGetters('ui', ['currentLocale']),
  },

  watch: {
    async account() {
      await this.executeAction({ action: 'account/listAccountSubscriptions' }, { id: this.account.id });
    },
  },
};
</script>
