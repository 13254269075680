<template>
  <card-modal
    icon=""
    :title="$t('workstation_registration.reject_action.title')"
    context="rejectWorkstationRegistration"
    @submit="submit"
    @cancel="cancel"
    cancel_text="workstation_registration.reject_action.button_cancel"
    submit_text="workstation_registration.reject_action.button_submit"
  >
    <form @submit.prevent="submit" slot="content">
      <v-text-field
        :label="$t('workstation_registration.reject_action.reason') + ' *'"
        name="reason"
        v-model="reason"
        v-validate="{ required: true }"
        :error-messages="errors.collect('reason')"
        data-vv-name="reason"
        maxlength="50"
        :data-vv-as="$t('workstation_registration.reject_action.reason')"
      ></v-text-field>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'reject-workstation-registration-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    workstationRegistrationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      reason: '',
    };
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    clear() {
      this.reason = '';
      this.$validator.reset();
    },
    success(response) {
      this.clear();
      this.$emit('rejected');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();
      if (validationComplete) {
        await this.executeAction(
          {
            action: 'partnerSecurity/rejectWorkstationRegistrationRequest',
            name: 'rejectWorkstationRegistration',
            success: this.success,
          },
          {
            id: this.workstationRegistrationId,
            reason: this.reason,
          },
        );
      }
    },
  },
};
</script>

<style scoped lang="stylus"></style>
