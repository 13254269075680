import Vue from 'vue';

const service = {
  async getFranchise(id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/franchises/${id}`);
    return response.data.data;
  },
  async updateFranchise(id, payload) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/franchises/${id}`, payload);
    return response.data.data;
  },
  async createFranchise(payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/franchises`, payload);
    return response.data.data;
  },
  async listFranchises(keyword, page, limit) {
    const params = {};
    if (keyword) {
      params.keyword = keyword;
    }
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath('/publisher/v2/franchises', page, limit, params)}`,
    );
    return response.data.data;
  },
  async searchFranchises(page, limit, keyword) {
    const params = {};
    if (keyword) {
      params.keyword = keyword;
    }
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath('/publisher/v2/franchises/search', page, limit, params)}`,
    );
    return response.data.data;
  },
  async listFranchiseStores(id, page, limit) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v2/franchises/${id}/stores`, page, limit)}`,
    );
    return response.data.data;
  },
};

function generatePath(startPath, page, limit, { keyword, partner_id, business_id, types } = {}) {
  let path = startPath;
  if (page || limit || keyword || partner_id || business_id || types) {
    path += '?';
  }

  const queryStrings = [];
  if (page) {
    queryStrings.push(['page', page]);
  }
  if (limit) {
    queryStrings.push(['limit', limit]);
  }
  if (keyword) {
    queryStrings.push(['keyword', keyword]);
  }
  if (partner_id) {
    queryStrings.push(['partner_id', partner_id]);
  }
  if (business_id) {
    queryStrings.push(['business_id', business_id]);
  }
  if (types) {
    queryStrings.push(['types', types]);
  }

  const params = new URLSearchParams(queryStrings);
  return path + params.toString();
}

export default service;
