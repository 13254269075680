<template>
  <view-main title="page_title.instant_card_project_details">
    <two-levels-breadcrumbs
      slot="content"
      :top-label="$t('navigation.instant_card_projects')"
      :to="{ name: 'instant_card_projects' }"
      :last-label="project.name"
    />
    <project-information slot="content"></project-information>
    <project-gift-certificates v-if="projectTypeIsReward" slot="content"></project-gift-certificates>
    <project-batches slot="content"></project-batches>
    <project-linked-cards slot="content"></project-linked-cards>
    <project-notes slot="content"></project-notes>
  </view-main>
</template>

<script>
import InstantCardProjectType from '@/enums/instant-card-project-type';
import { actionErrorTrackable, renderErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import ProjectInformation from './detail/ProjectInformation.vue';
import ProjectGiftCertificates from '@/components/instant_card_project/detail/ProjectGiftCertificates';
import ProjectBatches from '@/components/instant_card_project/detail/ProjectBatches';
import ProjectLinkedCards from '@/components/instant_card_project/detail/ProjectLinkedCards';
import ProjectNotes from './detail/ProjectNotes';

export default {
  name: 'instant-card-project-detail-view',
  mixins: [renderErrorTrackable, actionErrorTrackable],
  components: { ProjectInformation, ProjectGiftCertificates, ProjectBatches, ProjectLinkedCards, ProjectNotes },
  props: {
    projectId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('instantCardProject', ['project', 'projectType']),
    projectTypeIsReward() {
      return this.projectType === InstantCardProjectType.REWARD;
    },
  },
  async mounted() {
    await this.fetch();
  },
  watch: {
    async projectId() {
      return this.fetch();
    },
  },
  methods: {
    async fetch() {
      const project = await this.executeAction({ action: 'instantCardProject/getProject' }, this.projectId);
      if (!project) {
        this.$router.replace({ name: 'notFound' });
      }
    },
  },
};
</script>
