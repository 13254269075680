<template>
  <card :title="$t('store.card_acceptors')" icon="">
    <template slot="headerZoneRight">
      <label-text
        class="text-xs-center"
        :label="`${$t('store.details.card_acceptors_section.acceptors_count')}`"
        :value="storeCardAcceptors.length.toString()"
      ></label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="`${$t('store.details.card_acceptors_section.fuzzy_trx_enabled')}`" :value="store.fuzzy_trx_enabled">
        <p slot="labelTextLink" style="font-weight: bold">{{ $t('store.details.card_acceptors_section.fuzzy.' + store.fuzzy_trx_enabled) }}</p>
      </label-text>
      <div v-if="showAddStoreCardAcceptorFeature()" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text v-if="showAddStoreCardAcceptorFeature()" :label="`${$t('store.details.card_acceptors_section.action')}`" value="3">
        <link-secondary-action slot="labelTextLink" @click="openAddStoreCardAcceptorModal">
          {{ $t('store.details.card_acceptors_section.create_card_acceptor') }}
        </link-secondary-action>
      </label-text>
    </template>
    <template slot="content">
      <modal-action :show-modal="showAddStoreCardAcceptorModal"
        ><create-store-card-acceptor-action
          :v-if="showAddStoreCardAcceptorModal"
          :store-id="store.id || ''"
          @close="closeAddStoreCardAcceptorModal"
          @created="addStoreCardAcceptorSuccess"
        ></create-store-card-acceptor-action
      ></modal-action>
      <modal-action :show-modal="showUpdateStoreCardAcceptorModal"
        ><update-store-card-acceptor-action
          v-if="storeCardAcceptorIdToUpdate"
          :store-card-acceptor="storeCardAcceptorToUpdate"
          @close="closeUpdateStoreCardAcceptorModal"
          @updated="updateStoreCardAcceptorSuccess"
        ></update-store-card-acceptor-action
      ></modal-action>
      <modal-action-small :show-modal="showCheckStoreCardAcceptorModal">
        <check-store-card-acceptor-modal
          v-if="storeCardAcceptorIdToCheck"
          :store-card-acceptor-id="storeCardAcceptorIdToCheck"
          @close="closeCheckStoreCardAcceptorModal"
        >
        </check-store-card-acceptor-modal>
      </modal-action-small>
      <store-card-acceptors-table
        v-if="storeCardAcceptors.length"
        :items="storeCardAcceptors"
        @store_card_acceptor_update_action="openUpdateStoreCardAcceptorModal"
        @store_card_acceptor_remove_action="fetchCardAcceptors"
        @store_card_acceptor_check_action="openCheckStoreCardAcceptorModal"
      ></store-card-acceptors-table>
      <label-none v-if="storeCardAcceptors.length === 0">{{ $t('card.comments_section.no_found') }}</label-none>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import CreateStoreCardAcceptorAction from '../actions/CreateStoreCardAcceptorAction';
import UpdateStoreCardAcceptorAction from '../actions/UpdateStoreCardAcceptorAction';
import CheckStoreCardAcceptorModal from '../actions/CheckStoreCardAcceptorModal';
import StoreCardAcceptorsTable from './StoreCardAcceptorsTable';
import { mapGetters } from 'vuex';

export default {
  name: 'store-card-acceptors',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { StoreCardAcceptorsTable, CreateStoreCardAcceptorAction, CheckStoreCardAcceptorModal, UpdateStoreCardAcceptorAction },
  data() {
    return {
      showAddStoreCardAcceptorModal: false,
      showUpdateStoreCardAcceptorModal: false,
      showCheckStoreCardAcceptorModal: false,
      storeCardAcceptorIdToUpdate: null,
      storeCardAcceptorIdToCheck: null,
      storeCardAcceptorToUpdate: {},
    };
  },
  computed: {
    ...mapGetters('store', ['storeCardAcceptors', 'store']),
    ...mapGetters('ui', ['currentLocale']),
  },
  watch: {
    async store() {
      await this.fetchCardAcceptors();
    },
  },
  methods: {
    openAddStoreCardAcceptorModal() {
      this.showAddStoreCardAcceptorModal = true;
    },
    closeAddStoreCardAcceptorModal() {
      this.showAddStoreCardAcceptorModal = false;
    },
    async addStoreCardAcceptorSuccess() {
      await this.fetchCardAcceptors();
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'store.details.card_acceptors_section.create_action.success' });
      this.closeAddStoreCardAcceptorModal();
    },
    openUpdateStoreCardAcceptorModal(id) {
      this.storeCardAcceptorIdToUpdate = id;
      this.storeCardAcceptorToUpdate = this.storeCardAcceptors.find((l) => l.id === id);
      this.showUpdateStoreCardAcceptorModal = true;
    },
    closeUpdateStoreCardAcceptorModal() {
      this.storeCardAcceptorIdToUpdate = null;
      this.storeCardAcceptorToUpdate = {};
      this.showUpdateStoreCardAcceptorModal = false;
    },
    openCheckStoreCardAcceptorModal(id) {
      this.storeCardAcceptorIdToCheck = id;
      this.showCheckStoreCardAcceptorModal = true;
    },
    closeCheckStoreCardAcceptorModal() {
      this.storeCardAcceptorIdToCheck = null;
      this.showCheckStoreCardAcceptorModal = false;
      this.fetchCardAcceptors();
    },
    async updateStoreCardAcceptorSuccess() {
      await this.fetchCardAcceptors();
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'store.details.card_acceptors_section.update_action.success' });
      this.closeUpdateStoreCardAcceptorModal();
    },
    showAddStoreCardAcceptorFeature() {
      return this.canManageOffer();
    },
    async fetchCardAcceptors() {
      await this.executeAction({ action: 'store/getStoreCardAcceptorsByStore' }, this.store.id);
    },
  },
};
</script>
