import Vue from 'vue';

const service = {
  async getDocument(id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/selfserve/v1/change-requests/application/document/${id}`, {
      responseType: 'blob',
    });
    return response.data;
  },

  async listDocuments(id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/selfserve/v1/change-requests/application/documents/${id}`);
    return response.data.data.items;
  },
};

export default service;
