import Vue from 'vue';
import _, { update } from 'lodash';

const defaultPage = 1;
const paginationEstablishmentsLimit = 10;

const paginationLimit = 20;
export default {
  namespaced: true,
  state: {
    spaces: [],
    space: {},
    list: {
      pageNumber: 1,
      pageCount: 0,
      itemCount: 0,
      keyword: '',
      region: null,
      business_id: null,
    },

    spacePartnerEstablishments: [],
    listSpacePartnerEstablishment: {
      pageNumber: defaultPage,
      pageCount: 0,
      itemCount: 0,
      includeDeactivated: true,
    },
    activeSpacePartnerEstablishments: [],

    establishment: {},
  },
  getters: {
    space: (state) => state.space,
    spaces: (state) => state.spaces,
    listPageCount: (state) => state.list.pageCount,
    listPageNumber: (state) => state.list.pageNumber,
    listItemCount: (state) => state.list.itemCount,
    listKeyword: (state) => state.list.keyword,
    listBusinessId: (state) => state.list.businessId,
    listRegion: (state) => state.list.region,
    spacePartnerEstablishments: (state) => state.spacePartnerEstablishments,
    listSpacePartnerEstablishmentPageCount: (state) => state.listSpacePartnerEstablishment.pageCount,
    listSpacePartnerEstablishmentPageNumber: (state) => state.listSpacePartnerEstablishment.pageNumber,
    listSpacePartnerEstablishmentItemCount: (state) => state.listSpacePartnerEstablishment.itemCount,
    activeSpacePartnerEstablishments: (state) => state.activeSpacePartnerEstablishments,
    activeSpacePartnerEstablishmentOptions: (state) =>
      state.activeSpacePartnerEstablishments.map((establishment) => {
        return {
          label: `${establishment.business_name} (${establishment.business_id})`,
          value: { id: establishment.id, business_id: establishment.business_id },
        };
      }),
    establishment: (state) => state.establishment,
  },
  mutations: {
    spaces(state, spaces) {
      state.spaces = spaces;
    },
    space(state, space) {
      state.space = space;
    },
    listPageCount(state, pageCount) {
      state.list.pageCount = pageCount;
    },
    listItemCount(state, itemCount) {
      state.list.itemCount = itemCount;
    },
    listPageNumber(state, pageNumber) {
      state.list.pageNumber = pageNumber;
    },
    listKeyword(state, keyword) {
      state.list.keyword = keyword;
    },
    listRegion(state, region) {
      state.list.region = region;
    },
    listLimit(state, limit) {
      state.list.limit = limit;
    },
    listBusinessId(state, businessId) {
      state.list.business_id = businessId;
    },
    spacePartnerEstablishments(state, partnerEstablishments) {
      state.spacePartnerEstablishments = partnerEstablishments;
    },
    listSpacePartnerEstablishmentPageCount(state, pageCount) {
      state.listSpacePartnerEstablishment.pageCount = pageCount;
    },
    listSpacePartnerEstablishmentItemCount(state, itemCount) {
      state.listSpacePartnerEstablishment.itemCount = itemCount;
    },
    listSpacePartnerEstablishmentPageNumber(state, pageNumber) {
      state.listSpacePartnerEstablishment.pageNumber = pageNumber;
    },
    listSpacePartnerEstablishmentIncludeDeactivated(state, include_deactivated) {
      state.listSpacePartnerEstablishment.includeDeactivated = include_deactivated;
    },
    activeSpacePartnerEstablishments(state, partnerEstablishments) {
      state.activeSpacePartnerEstablishments = partnerEstablishments.sort((a, b) => {
        return (a.business_name || '').toLowerCase().localeCompare((b.business_name || '').toLowerCase());
      });
    },
    establishment(state, establishment) {
      state.establishment = establishment;
    },
  },
  actions: {
    async createSpace({ commit }, params) {
      const spaceService = Vue.prototype.$services.space;
      const spaceBrandingPartner = await spaceService.createSpace(params);
      return spaceBrandingPartner;
    },
    async getSpace({ commit }, id) {
      const spaceService = Vue.prototype.$services.space;
      let space = await spaceService.getSpace(id);
      commit('space', space);
      return space;
    },
    async setSpacePublic({ dispatch }, id) {
      const spaceService = Vue.prototype.$services.space;
      let result = await spaceService.setSpacePublic(id);
      await dispatch('getSpace', id);
      return result;
    },
    async setSpacePrivate({ dispatch }, id) {
      const spaceService = Vue.prototype.$services.space;
      let result = await spaceService.setSpacePrivate(id);
      await dispatch('getSpace', id);
      return result;
    },
    async listSpacesWithPartner({ state, commit }, { keyword, region, business_id, page, limit = paginationLimit } = {}) {
      const spaceService = Vue.prototype.$services.space;

      if (page) {
        commit('listPageNumber', page);
      }

      commit('listRegion', region);

      commit('listBusinessId', business_id);

      commit('listKeyword', keyword);

      const listResults = await spaceService.listSpacesWithPartner(
        {
          keyword: state.list.keyword,
          region: state.list.region,
          business_id: state.list.business_id,
        },
        state.list.pageNumber,
        limit,
      );

      commit('spaces', listResults.items);
      commit('listPageCount', listResults.page_count);
      commit('listItemCount', listResults.item_count);
    },
    async listSpacePartnerEstablishments({ state, commit }, { partner_id, include_deactivated, business_id, page, limit } = {}) {
      const partnerService = Vue.prototype.$services.partner;

      const thePage = page || state.listSpacePartnerEstablishment.pageNumber || 1;
      const theLimit = limit || paginationEstablishmentsLimit;
      const theBusinessId = business_id || null;

      let theIncludeDeactivated = state.listSpacePartnerEstablishment.includeDeactivated || false;
      if (include_deactivated !== undefined) {
        theIncludeDeactivated = include_deactivated;
      }

      const listResults = await partnerService.listPartnerEstablishments(partner_id, thePage, theLimit, theIncludeDeactivated, theBusinessId);

      commit('spacePartnerEstablishments', listResults.items);
      commit('listSpacePartnerEstablishmentPageNumber', thePage);
      commit('listSpacePartnerEstablishmentIncludeDeactivated', theIncludeDeactivated);
      commit('listSpacePartnerEstablishmentPageCount', listResults.page_count);
      commit('listSpacePartnerEstablishmentItemCount', listResults.item_count);
    },
    async autocompleteSearchSpacePartnerEstablishments({ state }, { keyword, page, limit } = {}) {
      const partnerService = Vue.prototype.$services.partner;

      return partnerService.searchPartnerEstablishments(page, limit, keyword);
    },
    async listAllActiveSpacePartnerEstablishments({ state, commit }, { partner_id } = {}) {
      const partnerService = Vue.prototype.$services.partner;

      const listResults = await partnerService.listPartnerEstablishments(partner_id, 1, 100, false);

      commit('activeSpacePartnerEstablishments', listResults.items);
    },
    async createPartnerEstablishment({ dispatch }, { partner_id, ...payload }) {
      const partnerService = Vue.prototype.$services.partner;
      const partnerEstablishment = await partnerService.createPartnerEstablishment(partner_id, payload);

      await dispatch('listSpacePartnerEstablishments', { partner_id });
      await dispatch('listAllActiveSpacePartnerEstablishments', { partner_id });
      return partnerEstablishment;
    },
    async deactivatePartnerEstablishment({ dispatch }, { partner_id, id }) {
      const partnerService = Vue.prototype.$services.partner;
      const partnerEstablishment = await partnerService.deactivatePartnerEstablishment(partner_id, id);

      await dispatch('getPartnerEstablishment', id);
      return partnerEstablishment;
    },
    async activatePartnerEstablishment({ dispatch }, { partner_id, id }) {
      const partnerService = Vue.prototype.$services.partner;
      const partnerEstablishment = await partnerService.activatePartnerEstablishment(partner_id, id);

      await dispatch('getPartnerEstablishment', id);
      return partnerEstablishment;
    },
    async getPartnerEstablishment({ commit }, id) {
      const partnerService = Vue.prototype.$services.partner;
      const partnerEstablishment = await partnerService.getPartnerEstablishment(id);

      commit('establishment', partnerEstablishment);
      return partnerEstablishment;
    },
    async updatePartnerEstablishment({ dispatch }, { id, ...updateParams }) {
      const partnerService = Vue.prototype.$services.partner;
      const partnerEstablishment = await partnerService.updatePartnerEstablishment(id, updateParams);

      dispatch('getPartnerEstablishment', id);
      return partnerEstablishment;
    },
    async clearListSpaces({ commit }) {
      commit('spaces', []);
    },
  },
};
