<template>
  <card :title="`${$t('application.aml_profile.title')}`" class="hc-aml-profile-section">
    <template slot="headerZoneRight">
      <label-text
        :label="$t('application.aml_profile.fatca.title')"
        :value="
          _get(cardApplicant, 'compliance.fatca_crs.foreign_tax_obligations')
            ? $t(`code.aml_profile.fatca.${cardApplicant.compliance.fatca_crs.foreign_tax_obligations}`)
            : $t('code.aml_profile.fatca.false')
        "
        :class-array="[`fatca-${cardApplicant.compliance.fatca_crs.foreign_tax_obligations}`, 'aml-profile--fatca']"
      ></label-text>
      <label-text
        :label="$t('application.aml_profile.third_party.title')"
        :value="
          isThirdPartyDetermination
            ? $t(`code.aml_profile.third_party_determination.${cardApplicant.compliance.third_party_determination.is_third_party}`)
            : $t('code.aml_profile.third_party_determination.false')
        "
        :class-array="[`third-party-${isThirdPartyDetermination}`, 'aml-profile--third-party']"
      ></label-text>
    </template>

    <template slot="content">
      <h4>{{ $t('application.aml_profile.fatca.title') }}</h4>
      <fatca-table v-if="isFatca" :fatca="cardApplicant.compliance.fatca_crs" />
      <v-layout v-if="!isFatca">
        <p>{{ $t('system.na') }}</p>
      </v-layout>

      <h4>{{ $t('application.aml_profile.third_party.title') }}</h4>
      <div v-if="isThirdPartyDetermination" class="data-card--content-zone">
        <v-flex xs12 class="d-inline-flex data-card--content-zone__middle">
          <label-text
            :label="$t('application.aml_profile.third_party.type')"
            :value="cardApplicant.compliance.third_party_determination.third_party.type"
          ></label-text>
          <label-text
            v-if="cardApplicant.compliance.third_party_determination.third_party.type === 'corporate'"
            :label="$t('application.aml_profile.third_party.incorporation_number')"
            :value="cardApplicant.compliance.third_party_determination.third_party.incorporation_number"
          ></label-text>
          <label-text
            v-if="cardApplicant.compliance.third_party_determination.third_party.type === 'corporate'"
            :label="$t('application.aml_profile.third_party.jurisdiction')"
            :value="cardApplicant.compliance.third_party_determination.third_party.jurisdiction"
          ></label-text>
          <label-text
            :label="$t('application.aml_profile.third_party.first_name')"
            :value="_get(cardApplicant, 'compliance.third_party_determination.third_party.first_name')"
          ></label-text>
          <label-text
            :label="$t('application.aml_profile.third_party.last_name')"
            :value="_get(cardApplicant, 'compliance.third_party_determination.third_party.last_name')"
          ></label-text>
          <label-text
            :label="$t('application.aml_profile.third_party.relationship')"
            :value="_get(cardApplicant, 'compliance.third_party_determination.third_party.relationship')"
          ></label-text>
          <label-text
            :label="$t('application.aml_profile.third_party.occupation')"
            :value="_get(cardApplicant, 'compliance.third_party_determination.third_party.occupation')"
          ></label-text>
          <label-text
            :label="$t('application.aml_profile.third_party.date_of_birth')"
            :value="_get(cardApplicant, 'compliance.third_party_determination.third_party.date_of_birth')"
          ></label-text>
          <label-text :label="$t('application.aml_profile.third_party.address')" :value="fullAddress"></label-text>
        </v-flex>
      </div>
      <v-layout v-else>
        <p>{{ $t('system.na') }}</p>
      </v-layout>
    </template>
  </card>
</template>

<script>
import _get from 'lodash/get';
import { actionErrorTrackable, security } from '@/mixins';

export default {
  name: 'aml-profile',
  mixins: [actionErrorTrackable, security],
  props: {
    cardApplicant: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    fullAddress() {
      return `${this.cardApplicant.compliance.third_party_determination.third_party.address.state}, ${this.cardApplicant.compliance.third_party_determination.third_party.address.city}, ${this.cardApplicant.compliance.third_party_determination.third_party.address.state}, ${this.cardApplicant.compliance.third_party_determination.third_party.address.country}, ${this.cardApplicant.compliance.third_party_determination.third_party.address.zip}`;
    },
    isThirdPartyDetermination() {
      return _get(this.cardApplicant, 'compliance.third_party_determination.is_third_party', false);
    },
    isFatca() {
      return _get(this.cardApplicant, 'compliance.fatca_crs.foreign_tax_obligations', false);
    },
  },
  methods: {
    _get,
  },
};
</script>
