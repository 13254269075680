<template>
  <card :title="$t('branding.stores')" icon="">
    <template slot="headerZoneRight">
      <label-text
        class="text-xs-center"
        :label="`${$t('branding.details.stores_section.count')}`"
        :value="brandingStoresItemCount || '0'"
      ></label-text>
    </template>
    <template slot="content">
      <branding-stores-table v-if="brandingStoresItemCount > 0" :items="brandingStores" :id="branding.id"></branding-stores-table>
      <label-none v-if="brandingStoresItemCount === 0">{{ $t('system.none') }}</label-none>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import BrandingStoresTable from './BrandingStoresTable';
import { mapGetters } from 'vuex';

export default {
  name: 'branding-stores',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { BrandingStoresTable },
  data() {
    return {
      pagination: 1,
    };
  },
  async created() {
    await this.fetchBrandingStores();
  },
  watch: {
    async branding() {
      await this.fetchBrandingStores();
    },
    brandingStoresPageNumber() {
      this.pagination = this.brandingStoresPageNumber;
    },
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'branding/changeBrandingStoresPage',
        },
        {
          id: this.branding.id,
          page: newValue,
        },
      );
    },
  },
  computed: {
    ...mapGetters('branding', ['branding', 'brandingStores', 'brandingStoresPageCount', 'brandingStoresPageNumber', 'brandingStoresItemCount']),
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    async clear() {
      this.$validator.reset();
    },
    async fetchBrandingStores() {
      await this.executeAction(
        { action: 'branding/listBrandingStores' },
        {
          id: this.branding.id,
          page: this.brandingStoresPageNumber,
        },
      );
    },
  },
};
</script>
