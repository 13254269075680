<template>
  <div>
    <v-checkbox
      v-model="hasNote"
      :label="$t(`task.modal.resolve_aml_note_to_review.form.with_note`)"
      class="create-aml-note--with-note"
      data-test="create-aml-note--with-note"
    />

    <div v-if="hasNote">
      <v-layout row pad-form-row-elements>
        <v-flex xs6 sm6>
          <v-select
            name="category_id"
            v-model="note.category_id"
            v-validate="'required'"
            :error-messages="errors.collect('category')"
            data-vv-name="category"
            :data-vv-as="$t(`task.modal.resolve_aml_note_to_review.form.category`)"
            :items="noteCategories"
            item-text="name.en"
            item-value="id"
            :label="$t(`task.modal.resolve_aml_note_to_review.form.category`)"
            data-test="create-aml-note--category"
          ></v-select>
        </v-flex>

        <v-flex xs6 sm6>
          <v-select
            name="type_id"
            v-model="note.type_id"
            v-validate="'required'"
            :error-messages="errors.collect('type_id')"
            data-vv-name="type_id"
            :data-vv-as="$t(`task.modal.resolve_aml_note_to_review.form.type`)"
            :items="getCategoryTypes(note.category_id)"
            item-text="name.en"
            item-value="id"
            :label="$t(`task.modal.resolve_aml_note_to_review.form.type`)"
            data-test="create-aml-note--type"
          ></v-select>
        </v-flex>
      </v-layout>

      <v-layout row class="task-resolution-aml-note-wrap">
        <v-textarea
          xs12
          auto-grow
          name="aml_note"
          row-height="1"
          :label="$t('task.modal.resolve_aml_note_to_review.form.aml_note')"
          v-model="note.note"
          v-validate="'required|min:1|max:4096'"
          :error-messages="errors.collect('aml_note')"
          data-vv-name="aml_note"
          :data-vv-as="$t('task.modal.resolve_aml_note_to_review.form.aml_note')"
          counter
          required
          minlength="1"
          maxlength="4096"
          data-test="task-resolution-aml-note"
        ></v-textarea>
      </v-layout>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable, security } from '@/mixins';

export default {
  name: 'resolve-with-aml-note-partial-modal-part',
  $_veeValidate: {
    validator: 'new',
  },
  mixins: [actionErrorTrackable, security],
  props: {
    taskId: {
      type: String,
      required: true,
    },
    defaultCategoryId: {
      type: String,
    },
    defaultTypeId: {
      type: String,
    },
  },
  data() {
    return {
      hasNote: false,
      note: {
        category_id: this.defaultCategoryId || null,
        type_id: this.defaultTypeId || null,
        note: '',
      },
    };
  },
  async created() {
    await this.executeAction({ action: 'aml/listNoteTypes' });
    await this.executeAction({ action: 'aml/listNoteCategories' });
  },
  computed: {
    ...mapGetters('aml', ['noteCategories', 'getCategoryTypes']),
  },
  methods: {
    amlNoteSaved() {
      this.$validator.reset();
      this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'task.modal.resolve_aml_note_to_review.aml_note_saved' });
    },
    async submit() {
      if (this.hasNote) {
        const validationComplete = await this.$validator.validateAll();

        if (validationComplete) {
          await this.executeAction(
            {
              action: 'task/saveAmlTaskNote',
              success: this.amlNoteSaved,
            },
            { task_id: this.taskId, note: this.note },
          );
        }
      }
    },
  },
};
</script>
