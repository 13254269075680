<template>
  <view-main title="page_title.franchise"><search-franchise-form slot="content"></search-franchise-form></view-main>
</template>

<script>
import { renderErrorTrackable } from '@/mixins';
import SearchFranchiseForm from './list/SearchFranchiseForm.vue';

export default {
  name: 'list-franchise-view',
  mixins: [renderErrorTrackable],
  components: { SearchFranchiseForm },
};
</script>
