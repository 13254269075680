<template>
  <card :title="$t('offers.publishers')" icon="">
    <template slot="headerZoneRight">
      <label-text
        class="text-sm-center"
        :label="$t('offers.details.publishers_section.publisher_count')"
        :value="offer.publishers_count || '0'"
      ></label-text>
      <div v-if="showAddOfferPublisherFeature()" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>

      <link-secondary-action v-if="!offer.is_single_publisher" slot="labelTextLink">
        <button-secondary-actions :text="$t(`system.actions`)" :actions="actions"> </button-secondary-actions>
      </link-secondary-action>

      <label-text
        v-if="showAddOfferPublisherFeature() && offer.is_single_publisher"
        :label="`${$t('offers.details.publishers_section.action')}`"
        value="3"
      >
        <link-secondary-action slot="labelTextLink" @click="openAddOfferPublisherModal">
          {{ $t('offers.details.publishers_section.add_publisher') }}
        </link-secondary-action>
      </label-text>
    </template>
    <template slot="content">
      <form v-if="!offer.is_single_publisher" @submit.prevent="submitListPublishersByOffer" class="form-basic form-search">
        <v-layout align-center class="form-search--main-wrap-sub-module">
          <v-flex class="form-search--button-wrap-sub-module">
            <button-primary
              class="publishers-details.stores_section--btn-search"
              :text="$t('offers.details.publishers_section.form.submit')"
              :loading="working"
              type="submit"
            ></button-primary>
          </v-flex>
          <v-flex class="form-search--inputs-zone-sub-module">
            <form-warning-search
              :searched="searched"
              :hint-message="$t('offers.details.publishers_section.form.total_results_message')"
              :label="$t('offers.details.publishers_section.placeholder')"
              v-model="keyword"
              data-vv-name="search"
              v-validate="'max:50'"
              :max-length="50"
              :error-messages="errors.collect('search')"
              data-test="offers-publishers-keyword"
            ></form-warning-search>
            <v-select
              name="category"
              v-model="category"
              :error-messages="errors.collect('category')"
              data-vv-name="category"
              :data-vv-as="$t('offers.details.publishers_section.category')"
              :items="categoryItems"
              item-text="text"
              item-value="id"
              clearable
              :label="$t('offers.details.publishers_section.category')"
              data-test="offers-publishers-category"
            ></v-select>
            <v-select
              name="status"
              v-model="status"
              :error-messages="errors.collect('status')"
              data-vv-name="status"
              :data-vv-as="$t('offers.details.publishers_section.status')"
              :items="statusOptions"
              item-text="name"
              item-value="value"
              clearable
              :label="$t('offers.details.publishers_section.status')"
              data-test="offers-publishers-status"
            ></v-select>
            <v-select
              name="readiness"
              v-model="readiness"
              :error-messages="errors.collect('readiness')"
              data-vv-name="readiness"
              :data-vv-as="$t('offers.details.publishers_section.readiness')"
              :items="readinessOptions"
              item-text="name"
              item-value="value"
              clearable
              :label="$t('offers.details.publishers_section.readiness')"
              data-test="offers-publishers-readiness"
            ></v-select>
          </v-flex>
        </v-layout>
      </form>

      <modal-action :show-modal="showAddOfferPublisherModal">
        <add-offers-publisher-action
          :offer-obj="offer"
          :hello-show="showAddOfferPublisherModal"
          @close="closeAddOfferPublisherModal"
          @created="addOfferPublisherSuccess"
        />
      </modal-action>
      <modal-action :show-modal="showUpdateInventoryLimitForAllModal">
        <update-inventory-limit-for-all-action
          :offer="offer"
          :hello-show="showUpdateInventoryLimitForAllModal"
          @close="closeUpdateInventoryLimitForAllModal"
          @updated="updateInventoryLimitForAllSuccess"
        />
      </modal-action>
      <modal-action :show-modal="showUpdateOfferPublisherModal">
        <update-offers-publisher-action
          v-if="offerPublisherIdToUpdate"
          :offer-publisher="offerPublisherToUpdate"
          :hello-show="showUpdateOfferPublisherModal"
          @close="closeUpdateOfferPublisherModal"
          @updated="updateOfferPublisherSuccess"
        />
      </modal-action>
      <modal-confirm
        v-if="offersPublisherIdToDelete"
        :show-modal="showDeleteOfferPublisherModal"
        :title="$t(`offers.details.publishers_section.delete_action.confirm.title`)"
        context="removePublisherFromOffer"
        :text="
          $t(`offers.details.publishers_section.delete_action.confirm.text`, {
            offersPublisherName: `${offersPublisherToDelete.publisher.name} - (${offersPublisherToDelete.publisher.id})`,
          })
        "
        :cancel_text="`offers.details.publishers_section.delete_action.confirm.button_cancel`"
        :submit_text="`offers.details.publishers_section.delete_action.confirm.button_submit`"
        :submit_working="working"
        @submit="deleteOfferPublisher"
        @close="closeDeleteOfferPublisherModal"
      ></modal-confirm>
      <modal-confirm
        v-if="offersPublisherIdToDeactivate"
        :show-modal="showDeactivateOfferPublisherModal"
        :title="$t(`offers.details.publishers_section.deactivate_action.confirm.title`)"
        context="deactivateOfferPublisher"
        :text="
          $t(`offers.details.publishers_section.deactivate_action.confirm.text`, {
            offersPublisherName: `${offersPublisherToDeactivate.publisher.name} - (${offersPublisherToDeactivate.publisher.id})`,
          })
        "
        :cancel_text="`offers.details.publishers_section.deactivate_action.confirm.button_cancel`"
        :submit_text="`offers.details.publishers_section.deactivate_action.confirm.button_submit`"
        :submit_working="working"
        @submit="deactivateOfferPublisher"
        @close="closeDeactivateOfferPublisherModal"
      ></modal-confirm>
      <modal-confirm
        v-if="offersPublisherIdToActivate"
        :show-modal="showActivateOfferPublisherModal"
        :title="$t(`offers.details.publishers_section.activate_action.confirm.title`)"
        context="activateOfferPublisher"
        :text="
          $t(`offers.details.publishers_section.activate_action.confirm.text`, {
            offersPublisherName: `${offersPublisherToActivate.publisher.name} - (${offersPublisherToActivate.publisher.id})`,
          })
        "
        :cancel_text="`offers.details.publishers_section.activate_action.confirm.button_cancel`"
        :submit_text="`offers.details.publishers_section.activate_action.confirm.button_submit`"
        :submit_working="working"
        @submit="activateOfferPublisher"
        @close="closeActivateOfferPublisherModal"
      ></modal-confirm>
      <offers-publishers-table
        v-if="dataReady && offerPublishers.length"
        @offers_publisher_delete_action="openDeleteOfferPublisherModal"
        @offers_publisher_deactivate_action="openDeactivateOfferPublisherModal"
        @offers_publisher_activate_action="openActivateOfferPublisherModal"
        @offers_publisher_edit_action="openUpdateOfferPublisherModal"
        :items="offerPublishers"
        :id="offer.id"
      />
      <label-none v-if="offerPublishers.length === 0 && (dirty || offer.is_single_publisher)">{{ $t('system.none') }}</label-none>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import OffersPublishersTable from './OffersPublishersTable';
import AddOffersPublisherAction from '../actions/AddOffersPublisherAction';
import UpdateOffersPublisherAction from '../actions/UpdateOffersPublisherAction';
import UpdateInventoryLimitForAllAction from '../actions/UpdateInventoryLimitForAllAction';
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'offer-publishers',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { OffersPublishersTable, AddOffersPublisherAction, UpdateInventoryLimitForAllAction, UpdateOffersPublisherAction },
  data() {
    return {
      keyword: '',
      searched: '',
      status: '',
      category: '',
      readiness: '',
      page: 1,
      dataReady: false,
      dirty: false,
      showAddOfferPublisherModal: false,
      showUpdateInventoryLimitForAllModal: false,
      showUpdateOfferPublisherModal: false,
      offerPublisherIdToUpdate: null,
      offerPublisherToUpdate: {},
      showDeleteOfferPublisherModal: false,
      offersPublisherIdToDelete: null,
      offersPublisherToDelete: {},
      showDeactivateOfferPublisherModal: false,
      offersPublisherIdToDeactivate: null,
      offersPublisherToDeactivate: {},
      showActivateOfferPublisherModal: false,
      offersPublisherIdToActivate: null,
      offersPublisherToActivate: {},
      categoryOptions: [],
      statusOptions: [
        { name: this.$t('offers_publisher.status.available'), value: 'available' },
        { name: this.$t('offers_publisher.status.deactivated'), value: 'deactivated' },
        { name: this.$t('offers_publisher.status.sold_out'), value: 'sold_out' },
      ],
      readinessOptions: [
        { name: this.$t('offers_publisher.readiness.display'), value: 'display_unready' },
        { name: this.$t('offers_publisher.readiness.pad'), value: 'pad_unready' },
        { name: this.$t('offers_publisher.readiness.transactional'), value: 'transactional_unready' },
      ],
      actions: [
        {
          slotName: 'addOfferPublisher',
          name: this.$t('offers.details.publishers_section.add_publisher'),
          action: () => this.openAddOfferPublisherModal(),
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => !this.showAddOfferPublisherFeature(),
        },
        {
          slotName: 'updateInventoryLimitForAll',
          name: this.$t('offers.details.publishers_section.update_inventory_limit_for_all'),
          action: () => this.openUpdateInventoryLimitForAllModal(),
          isAvailable: () => this.showUpdateInventoryLimitForAllFeature(),
          isDisabled: () => !this.showUpdateInventoryLimitForAllFeature(),
        },
      ],
    };
  },
  async created() {
    await this.fetchCategories();
    this.page = this.publisherStoresPageNumber;
    this.keyword = this.publisherStoresKeyword;
    this.status = this.publisherStoresStatus;
    this.category = this.offerPublishersCategory;
    this.readiness = this.offerPublishersReadiness;
    if (this.offer.is_single_publisher) {
      await this.executeAction(
        {
          action: 'offers/listOfferPublishersByOffer',
          success: () => {
            this.dataReady = true;
          },
        },
        {
          offer_id: this.offer.id,
          page: 1,
        },
      );
    }
  },
  computed: {
    ...mapGetters('offers', [
      'offer',
      'offerPublishers',
      'offerPublishersKeyword',
      'offerPublishersStatus',
      'offerPublishersCategory',
      'offerPublishersReadiness',
      'offerPublishersPageCount',
      'offerPublishersPageNumber',
      'offerPublishersItemCount',
    ]),
    ...mapGetters('branding', ['categories']),
    ...mapGetters('ui', ['currentLocale']),
    categoryItems() {
      return _.map(this.categoryOptions, (it) => {
        it.text = `${it.name[this.currentLocale]}`;
        return it;
      });
    },
  },
  watch: {
    async offer() {
      await this.fetchOfferPublishers();
      this.keyword = '';
      this.searched = '';
      this.page = 1;
      this.status = '';
      this.category = '';
      this.readiness = '';
      this.dirty = false;
      if (this.offer.is_single_publisher) {
        await this.executeAction(
          {
            action: 'offers/listOfferPublishersByOffer',
            success: () => {
              this.dataReady = true;
            },
          },
          {
            offer_id: this.offer.id,
            keyword: this.keyword,
            status: this.status,
            category: this.category,
            readiness: this.readiness,
            page: 1,
          },
        );
      }
    },
  },
  methods: {
    async clear() {
      this.searched = '';
      this.keyword = '';
      this.page = 1;
      this.status = '';
      this.category = '';
      this.readiness = '';
      this.dirty = false;
      this.$validator.reset();
    },
    async fetchCategories() {
      await this.executeAction({
        action: 'branding/listCategories',
      });
      this.categoryOptions = this.categories;
    },
    async submitListPublishersByOffer(event, forceSearch = false, page = 1) {
      let validationComplete;
      if (!forceSearch) {
        validationComplete = await this.$validator.validateAll();
      }

      if (validationComplete || forceSearch) {
        await this.clearAllError();
        this.dataReady = false;
        await this.executeAction(
          { action: 'offers/listOfferPublishersByOffer' },
          {
            offer_id: this.offer.id,
            keyword: this.keyword,
            status: this.status,
            category: this.category,
            readiness: this.readiness,
            page: 1,
          },
        );
        this.dataReady = true;
        this.dirty = true;
      }
    },
    openUpdateInventoryLimitForAllModal() {
      this.showUpdateInventoryLimitForAllModal = true;
    },
    closeUpdateInventoryLimitForAllModal() {
      this.showUpdateInventoryLimitForAllModal = false;
    },
    async updateInventoryLimitForAllSuccess() {
      await this.fetchOfferPublishers();
      await this.executeAction(
        { action: 'ui/showSuccessSnackBar' },
        { text: 'offers.details.publishers_section.update_inventory_limit_for_all_action.success' },
      );
      this.closeUpdateInventoryLimitForAllModal();
    },
    openAddOfferPublisherModal() {
      this.showAddOfferPublisherModal = true;
    },
    closeAddOfferPublisherModal() {
      this.showAddOfferPublisherModal = false;
    },
    async addOfferPublisherSuccess() {
      await this.fetchOfferPublishers();
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'offers.details.publishers_section.create_action.success' });
      this.closeAddOfferPublisherModal();
    },
    openUpdateOfferPublisherModal(id) {
      this.offerPublisherIdToUpdate = id;
      this.offerPublisherToUpdate = this.offerPublishers.find((l) => l.publisher.id === id);
      this.showUpdateOfferPublisherModal = true;
    },
    closeUpdateOfferPublisherModal() {
      this.offerPublisherIdToUpdate = null;
      this.offerPublisherToUpdate = {};
      this.showUpdateOfferPublisherModal = false;
    },
    async updateOfferPublisherSuccess() {
      await this.fetchOfferPublishers();
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'offers.details.publishers_section.update_action.success' });
      this.closeUpdateOfferPublisherModal();
    },
    openDeleteOfferPublisherModal(id) {
      this.offersPublisherIdToDelete = id;
      this.offersPublisherToDelete = this.offerPublishers.find((l) => l.publisher.id === id);
      this.showDeleteOfferPublisherModal = true;
    },
    closeDeleteOfferPublisherModal() {
      this.offersPublisherIdToDelete = null;
      this.offersPublisherToDelete = {};
      this.showDeleteOfferPublisherModal = false;
    },
    async deleteOfferPublisherSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: `offers.details.publishers_section.delete_action.success` });
      this.closeDeleteOfferPublisherModal();
    },
    openDeactivateOfferPublisherModal(id) {
      this.offersPublisherIdToDeactivate = id;
      this.offersPublisherToDeactivate = this.offerPublishers.find((l) => l.publisher.id === id);
      this.showDeactivateOfferPublisherModal = true;
    },
    closeDeactivateOfferPublisherModal() {
      this.offersPublisherIdToDeactivate = null;
      this.offersPublisherToDeactivate = {};
      this.showDeactivateOfferPublisherModal = false;
    },
    async deactivateOfferPublisherSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: `offers.details.publishers_section.deactivate_action.success` });
      this.closeDeactivateOfferPublisherModal();
    },
    openActivateOfferPublisherModal(id) {
      this.offersPublisherIdToActivate = id;
      this.offersPublisherToActivate = this.offerPublishers.find((l) => l.publisher.id === id);
      this.showActivateOfferPublisherModal = true;
    },
    closeActivateOfferPublisherModal() {
      this.offersPublisherIdToActivate = null;
      this.offersPublisherToActivate = {};
      this.showActivateOfferPublisherModal = false;
    },
    async activateOfferPublisherSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: `offers.details.publishers_section.activate_action.success` });
      this.closeActivateOfferPublisherModal();
    },
    showAddOfferPublisherFeature() {
      if (this.offer.is_single_publisher) {
        return this.offerPublishers.length < 1 && this.canManageOffer() && this.offer.system_status.status !== 'draft';
      }
      return this.canManageOffer() && this.offer.system_status.status !== 'draft';
    },
    showUpdateInventoryLimitForAllFeature() {
      return this.offer.type === 'bonus';
    },
    async fetchOfferPublishers() {
      await this.executeAction(
        { action: 'offers/listOfferPublishersByOffer' },
        {
          offer_id: this.offer.id,
          page: this.offerPublishersPageNumber,
        },
      );
    },
    async deleteOfferPublisher() {
      await this.executeAction(
        {
          action: 'offers/removePublisherFromOffer',
          name: 'removePublisherFromOffer',
          success: this.deleteOfferPublisherSuccess,
        },
        { offer_id: this.offer.id, publisher_id: this.offersPublisherToDelete.publisher.id },
      );
    },
    async deactivateOfferPublisher() {
      await this.executeAction(
        {
          action: 'offers/deactivateOfferPublisher',
          name: 'deactivateOfferPublisher',
          success: this.deactivateOfferPublisherSuccess,
        },
        { offer_id: this.offer.id, publisher_id: this.offersPublisherToDeactivate.publisher.id },
      );
    },
    async activateOfferPublisher() {
      await this.executeAction(
        {
          action: 'offers/activateOfferPublisher',
          name: 'activateOfferPublisher',
          success: this.activateOfferPublisherSuccess,
        },
        { offer_id: this.offer.id, publisher_id: this.offersPublisherToActivate.publisher.id },
      );
    },
  },
};
</script>
