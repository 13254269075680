<template>
  <card :title="$t('purse.details.purse_information_section.title')" icon="fal fa-money-check-alt" id="user-purse-informations">
    <template slot="headerZoneRight">
      <label-text :label="`${$t('purse.details.purse_information_section.id')}`" :value="purse.id">
        <p slot="labelTextLink" class="label-text--value">
          <v-layout row>
            {{ purse.id }}
            <button class="clipboard" v-clipboard:copy="purse.id ? purse.id : ''">
              <v-icon small>file_copy</v-icon>
            </button>
          </v-layout>
        </p>
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="`${$t('purse.details.purse_information_section.user')}`" :value="purse.account_id">
        <account-email slot="labelTextLink" :id="purse.account_id" :show-label="false" :generate-link-to-card="true"></account-email>
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>

      <label-text :label="$t(`purse.details.purse_information_section.interac_etransfer_security`)">
        <p slot="labelTextLink" class="label-text--value">
          <v-layout row>
            {{ account.etransfer_id | orNotAvailable }}
            <button class="clipboard" v-clipboard:copy="account.etransfer_id">
              <v-icon small>file_copy</v-icon>
            </button>
          </v-layout>
        </p>
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="`${$t('purse.details.purse_information_section.cardholder')}`" :value="purse.cardholder_id">
        <p slot="labelTextLink">
          <cardholder-current-card v-if="purse.cardholder_id" :cardholder-id="purse.cardholder_id" />
          <span v-else>
            <label-none v-if="purse.virtual_money_id !== 'open'">{{ $t('system.na') }}</label-none>
            <p v-else class="cannot-use-it">{{ $t('purse.details.purse_information_section.user_cannot_use_it') }}</p>
          </span>
        </p>
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text
        :label="`${$t('purse.details.purse_information_section.created')}`"
        :value="purse.creation_date | formatShortDateInverted | orNotAvailable"
      />

      <div v-if="showActionBtnVoucher()" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <link-secondary-action v-if="showActionBtnVoucher()" slot="labelTextLink">
        <button-secondary-actions class="application-info--actions" :text="$t(`system.actions`)" :actions="actions"> </button-secondary-actions>
      </link-secondary-action>
    </template>
    <template slot="content">
      <v-layout row>
        <v-flex xs3>
          <label-text
            :label="$t(`purse.details.purse_information_section.available_amount`)"
            :value="purse.available_amount | currency | dollarSignI18n"
          ></label-text>
        </v-flex>
        <v-flex xs3>
          <label-text
            :label="$t(`purse.details.purse_information_section.actual_amount`)"
            :value="walletHoldBalance | currency | dollarSignI18n"
          ></label-text>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-btn align-bottom small round outline :disabled="!purse.cardholder_id" color="primary" class="dashboard-link-btn">
          <router-link
            :class="{ 'disabled-link': !purse.cardholder_id }"
            target="_blank"
            :to="{ name: 'debits_credits_dashboard', query: { cardholder_id: purse.cardholder_id, wallet_definition_id: 'open', timerange: '~' } }"
          >
            {{ $t('purse.details.purse_information_section.debits_credits') }}
          </router-link>
          <v-icon small>navigate_next</v-icon>
        </v-btn>
      </v-layout>

      <modal-action :show-modal="showPurseLoadFromInteracModal">
        <load-open-purse-from-interac-action
          :account_id="purse.account_id || ''"
          @close="closePurseLoadInteracModal"
          @action="fetchPurse"
        ></load-open-purse-from-interac-action>
      </modal-action>

      <modal-action :show-modal="showLoadPurseFromBusinessModal">
        <load-open-purse-from-business-action
          :account_id="purse.account_id || ''"
          @close="closeLoadPurseFromBusinessModal"
          @action="fetchPurse"
        ></load-open-purse-from-business-action>
      </modal-action>

      <modal-action :show-modal="showPurseAdjustmentModal">
        <adjust-purse-balance-action
          :purse="purse || {}"
          @close="closePurseAdjustmentModal"
          @action="closePurseAdjustmentModal"
        ></adjust-purse-balance-action>
      </modal-action>

      <modal-action :show-modal="showEmptyOpenPurse">
        <empty-open-purse-action :account="account" @close="closeEmptyOpenPurseModal" @action="fetchPurse"></empty-open-purse-action>
      </modal-action>
    </template>
  </card>
</template>

<script>
import { security, disableWithError, actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import AccountEmail from './../../account/AccountEmail';
import AdjustPurseBalanceAction from '../actions/AdjustPurseBalanceAction';
import LoadOpenPurseFromInteracAction from '../actions/LoadOpenPurseFromInteracAction';
import LoadOpenPurseFromBusinessAction from '../actions/LoadOpenPurseFromBusinessAction';
import _ from 'lodash';
import AccountStatus from '@/enums/account-status';
import EmptyOpenPurseAction from '@/components/purse/actions/EmptyOpenPurseAction.vue';

export default {
  name: 'user-purse-information',
  components: {
    EmptyOpenPurseAction,
    AccountEmail,
    LoadOpenPurseFromBusinessAction,
    AdjustPurseBalanceAction,
    LoadOpenPurseFromInteracAction,
  },
  mixins: [security, actionErrorTrackable, disableWithError],
  data() {
    return {
      actions: [
        {
          slotName: 'purseLoadInterac',
          name: this.$t('purse.details.purse_information_section.load_purse_from_interac'),
          action: () => this.openPurseLoadInteracModal(),
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => this.accountIsDeactivated,
        },
        {
          slotName: 'purseLoadCredit',
          name: this.$t('purse.details.purse_information_section.load_purse_from_business'),
          action: () => this.openLoadPurseFromBusinessModal(),
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => this.accountIsDeactivated,
        },
        {
          slotName: 'purseAdjustmentModal',
          name: this.$t('purse.details.purse_information_section.purse_adjustment'),
          action: () => this.openPurseAdjustmentModal(),
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => this.accountIsDeactivated,
        },
        {
          slotName: 'emptyOpenPurseModal',
          name: this.$t('purse.details.purse_information_section.empty_open_purse'),
          action: () => this.emptyOpenPurseModal(),
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => this.accountOpenPurseEmpty || this.accountIsDeactivated,
        },
      ],
      showPurseLoadFromInteracModal: false,
      showPurseAdjustmentModal: false,
      showLoadPurseFromBusinessModal: false,
      showEmptyOpenPurse: false,
    };
  },
  computed: {
    ...mapGetters('purse', ['purse']),
    ...mapGetters('account', ['account']),
    ...mapGetters('ui', ['currentLocale']),
    walletHoldBalance() {
      return _.round(this.purse.actual_amount - this.purse.available_amount, 2);
    },
    purseId() {
      return this.purse.id;
    },
    accountIsDeactivated() {
      return this.account.status === AccountStatus.DEACTIVATED;
    },
    accountOpenPurseEmpty() {
      return this.purse.available_amount <= 0;
    },
  },
  watch: {
    async purse() {
      if (this.purse && this.purse.account_id) {
        await this.executeAction({ action: 'account/getAccount' }, this.purse.account_id);
      }
    },
  },
  methods: {
    // Actions
    showActionBtnVoucher() {
      return this.canManageOffer();
    },

    // Purse Load From Interac
    openPurseLoadInteracModal() {
      this.showPurseLoadFromInteracModal = true;
    },
    closePurseLoadInteracModal() {
      this.showPurseLoadFromInteracModal = false;
    },

    // Purse Load credit
    openLoadPurseFromBusinessModal() {
      this.showLoadPurseFromBusinessModal = true;
    },
    closeLoadPurseFromBusinessModal() {
      this.showLoadPurseFromBusinessModal = false;
    },

    // Empty Open Purse
    emptyOpenPurseModal() {
      this.showEmptyOpenPurse = true;
    },
    closeEmptyOpenPurseModal() {
      this.showEmptyOpenPurse = false;
    },

    // Purse adjustment
    openPurseAdjustmentModal() {
      this.showPurseAdjustmentModal = true;
    },
    closePurseAdjustmentModal() {
      this.showPurseAdjustmentModal = false;
    },

    // Details
    async fetchPurse() {
      const purse = await this.executeAction(
        {
          action: 'purse/getPurse',
          name: 'getPurse',
        },
        this.purse.id,
      );
    },
  },
};
</script>

<style scoped lang="stylus">
@import './../../../styles/variables/custom-variables.styl';

.disabled-link {
  pointer-events: none;
  color: gray;
}

.cannot-use-it {
  color: $error-default;
  margin-bottom: 0;
  font-weight: bold;
}
</style>
