<template>
  <v-card class="card-text-component">
    <v-card-text class="card--section-padding">{{ text }}</v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'card-text',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
