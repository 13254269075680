import Vue from 'vue';

const service = {
  async loadOpenWallet(publicToken, payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/cards/v1/cards/${publicToken}/wallets/open/load`, payload);
    return response.data.data;
  },
};

export default service;
