<template>
  <card-modal
    icon="fal fa-store-alt"
    :title="$t('offers.details.vouchers_section.extend_expiration_action.title')"
    context="extendOfferVoucherExpiration"
    @submit="submit"
    @cancel="cancel"
    :submit_working="submitting"
    :submit_hidden="submitted"
    :cancel_text="cancelText"
    submit_text="offers.details.vouchers_section.extend_expiration_action.submit_text"
  >
    <div slot="content">
      <form @submit.prevent="submit">
        <template v-if="!submitted">
          <v-layout column>
            <template>
              <v-layout row pad-form-row-elements v-if="isRelativeVoucher">
                <v-flex sm12 xs12>
                  <v-text-field
                    autofocus
                    ref="bonus_extension_days"
                    name="bonus_extension_days"
                    v-model="bonus_extension_days"
                    :label="$t('voucher.extend_expiration_action.bonus_extension_days') + '*'"
                    v-validate="{
                      required: true,
                      integer: true,
                      min_value: 1,
                      max_value: 365,
                    }"
                    :error-messages="errors.collect('bonus_extension_days')"
                    data-vv-name="bonus_extension_days"
                    :data-vv-as="$t('voucher.extend_expiration_action.bonus_extension_days')"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row pad-form-row-elements v-if="isFixedVoucher">
                <v-flex sm12 xs12>
                  <v-menu
                    v-model="showDatePickerCalendar"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-validate="{
                          required: true,
                          islater: { date: offerObj.incentive.bonus_end_date },
                          isbefore: { date: offerObj.incentive.closure_end_date },
                        }"
                        data-vv-name="bonus_end_date"
                        :error-messages="errors.collect('bonus_end_date')"
                        :data-vv-as="$t('voucher.extend_expiration_action.bonus_end_date')"
                        v-model="bonus_end_date"
                        :label="$t('voucher.extend_expiration_action.bonus_end_date')"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="bonus_end_date"
                      @input="showDatePickerCalendar = false"
                      :min="offerObj.incentive.bonus_end_date"
                      :max="offerObj.incentive.closure_end_date"
                      :show-current="new Date().toISOString().substr(0, 10)"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
              </v-layout>
            </template>
          </v-layout>
        </template>
      </form>

      <template v-if="submitted">
        <v-layout row wrap>
          <v-flex xs12>
            <v-alert :value="true" color="success" icon="check_circle" outline>
              {{ $t('offers.details.vouchers_section.extend_expiration_action.success_message', { newExpiration: expirationParams }) }}
            </v-alert>
          </v-flex>
          <v-flex sm10 xs12>
            <h4>{{ $t('offers.details.vouchers_section.extend_expiration_action.offer') }}</h4>
            <p>{{ offerInfo }}</p>
            <v-layout>
              <v-data-table :headers="statHeaders" :items="commandStats" class="elevation-1" hide-actions>
                <template v-slot:items="props">
                  <td>{{ props.item.actionName }}</td>
                  <td class="text-xs-right">{{ props.item.nbOfCall }}</td>
                </template>
              </v-data-table>
            </v-layout>
          </v-flex>
        </v-layout>
      </template>
    </div>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import _ from 'lodash';

export default {
  name: 'extend-offer-voucher-expiration-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    offerObj: {
      type: Object,
    },
    helloShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDatePickerCalendar: false,
      bonus_end_date: _.get(this.offerObj, 'incentive.bonus_end_date'),
      submitting: false,
      submitted: false,
      response: {},
      cancelText: 'offers.details.vouchers_section.extend_expiration_action.cancel_text',
      statHeaders: [
        {
          text: this.$t('offers.details.vouchers_section.extend_expiration_action.report.header.action_column_name'),
          align: 'left',
          sortable: false,
        },
        {
          text: this.$t('offers.details.vouchers_section.extend_expiration_action.report.header.number_of_voucher_column_name'),
          sortable: false,
        },
      ],
    };
  },
  computed: {
    commandStats() {
      return [
        {
          actionName: this.$t('offers.details.vouchers_section.extend_expiration_action.report.voucher_processed.title'),
          nbOfCall: this.response.evaluated || 0,
        },
        {
          actionName: `- ${this.$t('offers.details.vouchers_section.extend_expiration_action.report.voucher_extended.title')}`,
          nbOfCall: this.response.extended || 0,
        },
        {
          actionName: `- ${this.$t('offers.details.vouchers_section.extend_expiration_action.report.voucher_skipped.title')}`,
          nbOfCall: this.response.skipped || 0,
        },
      ];
    },
    expirationParams() {
      if (this.isFixedVoucher) {
        return this.bonus_end_date;
      } else {
        return this.$t('offers.details.vouchers_section.extend_expiration_action.report.params.relativeParamsInfo', {
          numberOfDays: this.bonus_extension_days,
        });
      }
    },
    isFixedVoucher() {
      return this.offerObj.incentive.duration_model === 'fixed';
    },
    isRelativeVoucher() {
      return this.offerObj.incentive.duration_model === 'relative';
    },
    offerInfo() {
      return `${this.offerObj.branding.name} (${this.offerObj.id})`;
    },
  },
  methods: {
    clear() {
      this.bonus_end_date = null;
      this.bonus_extension_days = null;
      this.submitting = false;
      this.submitted = false;
      this.response = {};
      this.cancelText = 'offers.details.vouchers_section.extend_expiration_action.cancel_text';
      this.$validator.reset();
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        const params = {
          id: this.offerObj.id,
        };
        if (this.isFixedVoucher) {
          params.bonus_end_date = this.bonus_end_date;
        } else {
          params.bonus_extension_days = this.bonus_extension_days;
        }
        this.submitting = true;
        try {
          await this.executeAction(
            {
              action: 'offers/extendOfferVoucherExpiration',
              name: 'extendOfferVoucherExpiration',
              success: (payload) => {
                this.cancelText = 'offers.details.vouchers_section.extend_expiration_action.close_text';

                this.response = payload || {};

                this.submitted = true;
              },
            },
            params,
          );
        } finally {
          this.submitting = false;
        }
      }
    },
  },
};
</script>
