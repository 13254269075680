<template>
  <div>
    <table class="responsive-table">
      <thead>
        <tr>
          <td>{{ $t('instant_card_projects.batches_table.id') }}</td>
          <td>{{ $t('instant_card_projects.batches_table.name') }}</td>
          <td>{{ $t('instant_card_projects.batches_table.business') }}</td>
          <td>{{ $t('instant_card_projects.batches_table.shipping_address') }}</td>
          <td>{{ $t('instant_card_projects.batches_table.quantity') }}</td>
          <td>{{ $t('instant_card_projects.batches_table.card_package_id') }}</td>
          <td>{{ $t('instant_card_projects.batches_table.date_created') }}</td>
          <td>{{ $t('instant_card_projects.batches_table.status') }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>
            <h5>{{ $t('instant_card_projects.batches_table.id') }}:</h5>
            <router-link v-if="item.id" :to="{ name: 'instant_card_batch_details', params: { batchId: item.id } }">
              {{ item.id }}
            </router-link>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('instant_card_projects.batches_table.name') }}:</h5>
            <span>{{ item.name }}</span>
          </td>
          <td>
            <h5>{{ $t('instant_card_projects.batches_table.business') }}:</h5>
            <router-link v-if="item.business_id" :to="{ name: 'business_details', params: { businessId: item.business_id } }">
              {{ item.business_name }}
            </router-link>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('instant_card_projects.batches_table.shipping_address') }}:</h5>
            <span v-if="item.delivery_address">{{ fullAddress(item.delivery_address) }}</span>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td align="right">
            <h5>{{ $t('instant_card_projects.batches_table.quantity') }}:</h5>
            <span>{{ item.quantity }}</span>
          </td>
          <td>
            <h5>{{ $t('instant_card_projects.batches_table.card_package_id') }}:</h5>
            <span :class="!item.card_package_id ? 'info-not-available' : ''">{{ item.card_package_id | orNotAvailable }}</span>
          </td>
          <td>
            <h5>{{ $t('instant_card_projects.batches_table.date_created') }}:</h5>
            <span :class="!item.creation_date ? 'info-not-available' : ''">{{ item.creation_date | formatShortDateInverted | orNotAvailable }}</span>
          </td>
          <td>
            <h5>{{ $t('instant_card_projects.batches_table.status') }}:</h5>
            <span :class="`batch-${item.status}`">{{ $t(`instant_card_batches.status.${item.status}`) }}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-xs-center">
      <v-pagination v-if="projectBatchesPageCount > 1" v-model="pagination" :length="projectBatchesPageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, renderErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'batches-table',
  mixins: [renderErrorTrackable, actionErrorTrackable],
  props: {
    items: {
      type: Array,
    },
    projectId: {
      type: String,
    },
  },
  data() {
    return {
      pagination: 1,
      batchId: null,
    };
  },
  mounted() {
    this.pagination = this.projectBatchesPageNumber;
  },
  watch: {
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'instantCardProject/listProjectBatches',
        },
        {
          id: this.projectId,
          page: newValue,
        },
      );
    },
  },
  computed: {
    ...mapGetters('instantCardProject', ['projectBatchesPageCount', 'projectBatchesPageNumber']),
  },
  methods: {
    fullAddress(address) {
      return `${address.street}, ${address.city}, ${address.state}, ${address.country}, ${address.zip}`;
    },
  },
};
</script>
