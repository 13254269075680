<template>
  <card :title="$t('offer_promotions.vouchers_section.title')" icon="">
    <template slot="headerZoneRight">
      <!--        TODO: Change voucher count value-->
      <label-text class="text-sm-center" :label="$t('offer_promotions.vouchers_section.voucher_count')" :value="listItemCount || '0'"></label-text>
    </template>
    <template slot="content">
      <offer-vouchers-table v-if="dataReady && vouchers.length" :items="vouchers" :offer-id="offerPromotion.offer_id"></offer-vouchers-table>
      <label-none v-if="vouchers.length === 0">{{ $t('offer_promotions.vouchers_section.no_found') }}</label-none>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import OfferVouchersTable from '../../detail/OfferVouchersTable';
import { mapGetters } from 'vuex';

export default {
  name: 'offer-promotion-vouchers',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { OfferVouchersTable },
  data() {
    return {
      keyword: '',
      page: 1,
      dataReady: false,
    };
  },
  computed: {
    ...mapGetters('offers', ['offerPromotion']),
    ...mapGetters('voucher', ['vouchers', 'listKeyword', 'listPageNumber', 'listItemCount']),
  },
  async created() {
    await this.fetchVouchers();
    this.page = this.listPageNumber;
    this.keyword = this.listKeyword;
  },
  watch: {
    async offerPromotion() {
      await this.fetchVouchers();
      this.keyword = '';
      this.page = 1;
    },
  },
  methods: {
    async clear() {
      this.keyword = '';
      this.page = 1;
      this.dataReady = false;
    },
    async fetchVouchers() {
      this.dataReady = false;
      await this.executeAction(
        { action: 'voucher/listVouchersByOffer' },
        { offerPromotionId: this.offerPromotion.id, offerId: this.offerPromotion.offer_id, keyword: this.keyword, page: 1 },
      );
      this.dataReady = true;
    },
  },
};
</script>
