<template>
  <div>
    <table class="responsive-table">
      <thead>
        <tr>
          <td>{{ $t('space.partner_establishment_table.fields.id') }}</td>
          <td>{{ $t('space.partner_establishment_table.fields.business') }}</td>
          <td>{{ $t('space.partner_establishment_table.fields.timezone') }}</td>
          <td>{{ $t('space.partner_establishment_table.fields.status') }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>
            <h5>{{ $t('space.partner_establishment_table.fields.id') }}</h5>
            <router-link :to="{ name: 'establishment_details', params: { establishmentId: item.id } }">
              {{ item.id }}
            </router-link>
          </td>
          <td>
            <h5>{{ $t('space.partner_establishment_table.fields.business') }}</h5>
            <router-link :to="{ name: 'business_details', params: { businessId: item.business_id } }">
              {{ item.business_name }} ({{ item.business_id }})
            </router-link>
          </td>
          <td>
            <h5>{{ $t('space.partner_establishment_table.fields.timezone') }}</h5>
            {{ item.timezone }}
          </td>
          <td>
            <h5>{{ $t('space.partner_establishment_table.fields.status') }}</h5>
            <establishment-status-view slot="labelTextLink" :status="item.status" />
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-xs-center">
      <v-pagination
        v-if="listSpacePartnerEstablishmentPageCount > 1"
        v-model="pagination"
        :length="listSpacePartnerEstablishmentPageCount"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';
import EstablishmentStatusView from '@/components/establishment/EstablishmentStatusView';

export default {
  name: 'space-partner-establishment-table',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { EstablishmentStatusView },
  props: {
    partnerId: {
      type: String,
    },
    items: {
      type: Array,
    },
  },
  data() {
    return {
      pagination: 1,
    };
  },
  mounted() {
    this.pagination = this.listSpacePartnerEstablishmentPageNumber;
  },
  watch: {
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'space/listSpacePartnerEstablishments',
        },
        {
          partner_id: this.partnerId,
          page: newValue,
        },
      );
    },
  },
  computed: {
    ...mapGetters('space', ['listSpacePartnerEstablishmentPageCount', 'listSpacePartnerEstablishmentPageNumber']),
  },
};
</script>
