<template>
  <card :title="$t('publishers.stores')" icon="">
    <template slot="headerZoneRight">
      <label-text
        class="text-xs-center"
        :label="`${$t('publishers.details.stores_section.store_count')}`"
        :value="publisher.stores_count || '0'"
      ></label-text>
      <div v-if="showActionBtn()" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <link-secondary-action v-if="showActionBtn()" slot="labelTextLink">
        <button-secondary-actions :text="$t(`publishers.details.stores_section.actions`)" :actions="actions"> </button-secondary-actions>
      </link-secondary-action>
    </template>

    <template slot="content">
      <form @submit.prevent="submitListStoresByPublisher" class="form-basic form-search">
        <v-layout align-center class="form-search--main-wrap-sub-module">
          <v-flex class="form-search--button-wrap-sub-module">
            <button-primary
              class="publishers-details.stores_section--btn-search"
              :text="$t('publishers.details.stores_section.form.submit')"
              :loading="working"
              type="submit"
            ></button-primary>
          </v-flex>
          <v-flex class="form-search--inputs-zone-sub-module">
            <form-warning-search
              :searched="searched"
              :hint-message="$t('publishers.details.stores_section.form.total_results_message')"
              :label="$t('publishers.details.stores_section.placeholder')"
              v-model="keyword"
              data-vv-name="search"
              v-validate="'max:50'"
              :max-length="50"
              :error-messages="errors.collect('search')"
              data-test="publishers-stores-keyword"
            ></form-warning-search>
            <v-select
              name="status"
              v-model="status"
              :error-messages="errors.collect('status')"
              data-vv-name="status"
              :data-vv-as="$t('publishers.details.stores_section.status')"
              :items="statusOptions"
              item-text="name"
              item-value="value"
              clearable
              :label="$t('publishers.details.stores_section.status')"
              data-test="publishers-stores-status"
            ></v-select>
            <v-select
              name="type"
              v-model="type"
              :error-messages="errors.collect('type')"
              data-vv-name="type"
              :data-vv-as="$t('publishers.details.stores_section.type')"
              :items="typeOptions"
              item-text="name"
              item-value="value"
              clearable
              :label="$t('publishers.details.stores_section.type')"
              data-test="publishers-stores-type"
            ></v-select>
            <v-select
              name="category"
              v-model="category"
              :error-messages="errors.collect('category')"
              data-vv-name="category"
              :data-vv-as="$t('publishers.details.stores_section.category')"
              :items="categoryItems"
              item-text="text"
              item-value="id"
              clearable
              :label="$t('publishers.details.stores_section.category')"
              data-test="publishers-stores-category"
            ></v-select>
            <v-checkbox
              name="without_card_acceptors"
              v-model="without_card_acceptors"
              :label="$t(`publishers.details.stores_section.without_card_acceptors`)"
              :error-messages="errors.collect('without_card_acceptors')"
              data-vv-name="without_card_acceptors"
              :data-vv-as="$t(`publishers.details.stores_section.without_card_acceptors`)"
              value="0"
              type="checkbox"
            ></v-checkbox>
          </v-flex>
        </v-layout>
      </form>

      <modal-action :show-modal="showAddPublisherStoreModal">
        <add-publishers-store-action
          v-if="showAddPublisherStoreModal"
          :publisher-obj="publisher"
          @close="closeAddPublisherStoreModal"
          @created="addPublisherStoreSuccess"
        ></add-publishers-store-action
      ></modal-action>
      <modal-action :show-modal="showAddStoresFromOfferModal">
        <add-stores-from-offer-action
          v-if="showAddStoresFromOfferModal"
          :publisher-obj="publisher"
          @close="closeAddStoresFromOfferModal"
          @storesAdded="addStoresFromOfferSuccess"
        />
      </modal-action>
      <modal-action :show-modal="showAddStoresFromPublisherModal">
        <add-stores-from-publisher-action
          v-if="showAddStoresFromPublisherModal"
          :publisher-obj="publisher"
          @close="closeAddStoresFromPublisherModal"
          @storesAdded="addStoresFromPublisherSuccess"
        />
      </modal-action>

      <modal-action :show-modal="showAddStoresFromDestinationModal">
        <add-stores-from-destination-action
          v-if="showAddStoresFromDestinationModal"
          :publisher-obj="publisher"
          @close="closeAddStoresFromDestinationModal"
          @storesAdded="addStoresFromDestinationSuccess"
        />
      </modal-action>
      <publishers-stores-table
        v-if="dataReady && publisherStores.length"
        @publishers_store_delete_action="openDeletePublisherStoreModal"
        :items="publisherStores"
        :id="publisher.id"
      ></publishers-stores-table>
      <modal-confirm
        v-if="publishersStoreIdToDelete"
        :show-modal="showDeletePublisherStoreModal"
        :title="$t(`publishers.details.stores_section.delete_action.confirm.title`)"
        context="DeletePublisherStore"
        :text="
          $t(`publishers.details.stores_section.delete_action.confirm.text`, {
            publishersStoreName: `${publishersStoreToDelete.name} - (${publishersStoreToDelete.id})`,
          })
        "
        :cancel_text="`publishers.details.stores_section.delete_action.confirm.button_cancel`"
        :submit_text="`publishers.details.stores_section.delete_action.confirm.button_submit`"
        :submit_working="working"
        @submit="deletePublisherStore"
        @close="closeDeletePublisherStoreModal"
      ></modal-confirm>
      <modal-confirm
        v-if="publisher.stores_count > 0"
        :show-modal="showDeleteAllStoresFromPublisherModal"
        :title="$t(`publishers.details.stores_section.delete_action.confirm.title`)"
        context="DeleteAllStoresFromPublisher"
        :text="
          $t(`publishers.details.stores_section.delete_all_Stores_action.confirm.text`, {
            publisherId: publisher.id,
            storeCount: publisher.stores_count || '0',
          })
        "
        :cancel_text="`publishers.details.stores_section.delete_all_Stores_action.confirm.button_cancel`"
        :submit_text="`publishers.details.stores_section.delete_all_Stores_action.confirm.button_submit`"
        :submit_working="working"
        @submit="deleteAllStoresFromPublisher"
        @close="closeDeleteAllStoresFromPublisherModal"
      ></modal-confirm>
      <label-none v-if="publisherStores.length === 0 && dirty">{{ $t('system.none') }}</label-none>
    </template>
  </card>
</template>

<script>
import AddPublishersStoreAction from '@/components/publishers/actions/AddPublishersStoreAction.vue';
import PublishersStoresTable from '@/components/publishers/detail/PublishersStoresTable.vue';
import AddStoresFromOfferAction from '@/components/publishers/actions/AddStoresFromOfferAction.vue';
import AddStoresFromPublisherAction from '@/components/publishers/actions/AddStoresFromPublisherAction.vue';
import AddStoresFromDestinationAction from '@/components/publishers/actions/AddStoresFromDestinationAction.vue';
import { security, disableWithError, actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'publishers-stores',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: {
    PublishersStoresTable,
    AddPublishersStoreAction,
    AddStoresFromOfferAction,
    AddStoresFromPublisherAction,
    AddStoresFromDestinationAction,
  },
  data() {
    return {
      keyword: '',
      searched: '',
      status: '',
      type: '',
      category: '',
      without_card_acceptors: false,
      page: 1,
      dataReady: false,
      dirty: false,
      showDeletePublisherStoreModal: false,
      publishersStoreIdToDelete: null,
      publishersStoreToDelete: {},
      statusOptions: [
        { name: this.$t('store.status.open'), value: 'open' },
        { name: this.$t('store.status.pending'), value: 'pending' },
        { name: this.$t('store.status.closed'), value: 'closed' },
      ],
      typeOptions: [
        { name: this.$t('store.types.place'), value: 'place' },
        { name: this.$t('store.types.web'), value: 'web' },
        { name: this.$t('store.types.custom'), value: 'custom' },
      ],
      categoryOptions: [],
      showAddStoresFromOfferModal: false,
      showAddStoresFromPublisherModal: false,
      showAddStoresFromDestinationModal: false,
      showDeleteAllStoresFromPublisherModal: false,
      showAddPublisherStoreModal: false,
      actions: [
        {
          slotName: 'AddPublisherStoreModal',
          name: this.$t('publishers.details.stores_section.add_publisher_store_action.title'),
          action: () => {
            this.openAddPublisherStoreModal();
          },
          isAvailable: () => this.canManageOffer(), // TODO change
          isDisabled: () => {
            return !this.showAddPublisherStoreFeature();
          },
        },
        {
          slotName: 'AddStoresFromOfferModal',
          name: this.$t('publishers.details.stores_section.add_stores_from_offer_action.title'),
          action: () => {
            this.openAddStoresFromOfferModal();
          },
          isAvailable: () => this.canManageOffer(), // TODO change
          isDisabled: () => {
            return !this.showAddStoresFromOfferFeature();
          },
        },
        {
          slotName: 'AddStoresFromPublisherModal',
          name: this.$t('publishers.details.stores_section.add_stores_from_publisher_action.title'),
          action: () => {
            this.openAddStoresFromPublisherModal();
          },
          isAvailable: () => this.canManageOffer(), // TODO change
          isDisabled: () => {
            return !this.showAddStoresFromPublisherFeature();
          },
        },
        {
          slotName: 'AddStoresFromDestinationModal',
          name: this.$t('publishers.details.stores_section.add_stores_from_destination_action.title'),
          action: () => {
            this.openAddStoresFromDestinationModal();
          },
          isAvailable: () => this.canManageOffer(), // TODO change
          isDisabled: () => {
            return !this.showAddStoresFromDestinationFeature();
          },
        },
        {
          slotName: 'DeleteAllStoresFromPublisherModal',
          name: this.$t('publishers.details.stores_section.delete_all_stores_from_publisher_action.title'),
          action: () => {
            this.openDeleteAllStoresFromPublisherModal();
          },
          isAvailable: () => this.canManageOffer(), // TODO change
          isDisabled: () => {
            return !this.showDeleteAllStoresFromPublisherFeature();
          },
        },
      ],
    };
  },
  async created() {
    await this.fetchCategories();
    this.page = this.publisherStoresPageNumber;
    this.keyword = this.publisherStoresKeyword;
    this.status = this.publisherStoresStatus;
    this.type = this.publisherStoresType;
    this.category = this.publisherStoresCategory;
    this.without_card_acceptors = this.publisherStoresWithoutCardAcceptors;
  },
  computed: {
    ...mapGetters('publishers', [
      'publisher',
      'publisherStores',
      'publisherStoresKeyword',
      'publisherStoresStatus',
      'publisherStoresType',
      'publisherStoresCategory',
      'publisherStoresWithoutCardAcceptors',
      'publisherStoresPageCount',
      'publisherStoresPageNumber',
      'publisherStoresItemCount',
    ]),
    ...mapGetters('branding', ['categories']),
    ...mapGetters('ui', ['currentLocale']),
    categoryItems() {
      return _.map(this.categoryOptions, (it) => {
        it.text = `${it.name[this.currentLocale]}`;
        return it;
      });
    },
  },
  watch: {
    async publisher() {
      this.keyword = '';
      this.searched = '';
      this.page = 1;
      this.status = '';
      this.type = '';
      this.category = '';
      this.without_card_acceptors = false;
      this.dirty = false;
    },
  },
  methods: {
    async clear() {
      this.searched = '';
      this.keyword = '';
      this.page = 1;
      this.status = '';
      this.type = '';
      this.category = '';
      this.without_card_acceptors = false;
      this.dirty = false;
      this.$validator.reset();
    },
    showAddPublisherStoreFeature() {
      return true; // TODO: write
    },
    openAddPublisherStoreModal() {
      this.showAddPublisherStoreModal = true;
    },
    closeAddPublisherStoreModal() {
      this.showAddPublisherStoreModal = false;
    },
    async addPublisherStoreSuccess() {
      await this.executeAction(
        { action: 'ui/showSuccessSnackBar' },
        { text: 'publishers.details.stores_section.add_publisher_store_action.success' },
      );
      this.closeAddPublisherStoreModal();
    },
    showAddStoresFromOfferFeature() {
      return true; // TODO: write
    },
    openAddStoresFromOfferModal() {
      this.showAddStoresFromOfferModal = true;
    },
    closeAddStoresFromOfferModal() {
      this.showAddStoresFromOfferModal = false;
    },
    async addStoresFromOfferSuccess() {
      await this.executeAction(
        { action: 'ui/showSuccessSnackBar' },
        { text: 'publishers.details.stores_section.add_stores_from_offer_action.success' },
      );
      this.closeAddStoresFromOfferModal();
    },
    showAddStoresFromPublisherFeature() {
      return true; // TODO: write
    },
    showAddStoresFromDestinationFeature() {
      return true; // TODO: write
    },
    showDeleteAllStoresFromPublisherFeature() {
      return this.publisher.stores_count > 0;
    },
    openAddStoresFromPublisherModal() {
      this.showAddStoresFromPublisherModal = true;
    },
    closeAddStoresFromPublisherModal() {
      this.showAddStoresFromPublisherModal = false;
    },
    openAddStoresFromDestinationModal() {
      this.showAddStoresFromDestinationModal = true;
    },
    closeAddStoresFromDestinationModal() {
      this.showAddStoresFromDestinationModal = false;
    },
    openDeleteAllStoresFromPublisherModal() {
      this.showDeleteAllStoresFromPublisherModal = true;
    },
    closeDeleteAllStoresFromPublisherModal() {
      this.showDeleteAllStoresFromPublisherModal = false;
    },
    async addStoresFromPublisherSuccess() {
      await this.executeAction(
        { action: 'ui/showSuccessSnackBar' },
        { text: 'publishers.details.stores_section.add_stores_from_publisher_action.success' },
      );
      this.closeAddStoresFromPublisherModal();
    },
    async addStoresFromDestinationSuccess() {
      await this.executeAction(
        { action: 'ui/showSuccessSnackBar' },
        { text: 'publishers.details.stores_section.add_stores_from_destination_action.success' },
      );
      this.closeAddStoresFromDestinationModal();
    },
    async deleteAllStoresFromPublisherSuccess() {
      await this.executeAction(
        { action: 'ui/showSuccessSnackBar' },
        { text: 'publishers.details.stores_section.delete_all_stores_from_publisher_action.success' },
      );
      this.closeDeleteAllStoresFromPublisherModal();
    },
    async fetchCategories() {
      await this.executeAction({
        action: 'branding/listCategories',
      });
      this.categoryOptions = this.categories;
    },
    openDeletePublisherStoreModal(id) {
      this.publishersStoreIdToDelete = id;
      this.publishersStoreToDelete = this.publisherStores.find((l) => l.id === id);
      this.showDeletePublisherStoreModal = true;
    },
    async closeDeletePublisherStoreModal() {
      this.publishersStoreIdToDelete = null;
      this.publishersStoreToDelete = {};
      this.showDeletePublisherStoreModal = false;
      await this.submitListStoresByPublisher(undefined, false, this.publisherStoresPageNumber);
    },
    async deletePublisherStoreSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: `publishers.details.stores_section.delete_action.success` });
      this.closeDeletePublisherStoreModal();
    },
    showActionBtn() {
      return this.canManageOffer(); // TODO change
    },
    async deletePublisherStore() {
      await this.executeAction(
        {
          action: 'publishers/removeStoreFromPublisher',
          name: 'removeStoreFromPublisher',
          success: this.deletePublisherStoreSuccess,
        },
        { publisherId: this.publisher.id, store_id: this.publishersStoreToDelete.id },
      );
    },
    async deleteAllStoresFromPublisher() {
      await this.executeAction(
        {
          action: 'publishers/removeAllStoresFromPublisher',
          name: 'removeAllStoresFromPublisher',
          success: this.deleteAllStoresFromPublisherSuccess,
        },
        { publisherId: this.publisher.id },
      );
    },
    async submitListStoresByPublisher(event, forceSearch = false, page = 1) {
      let validationComplete;
      if (!forceSearch) {
        validationComplete = await this.$validator.validateAll();
      }

      if (validationComplete || forceSearch) {
        await this.clearAllError();
        this.dataReady = false;
        await this.executeAction(
          { action: 'publishers/listPublisherStoresByPublisher' },
          {
            id: this.publisher.id,
            keyword: this.keyword,
            status: this.status,
            type: this.type,
            category: this.category,
            without_card_acceptors: this.without_card_acceptors,
            page,
          },
        );
        this.dataReady = true;
        this.dirty = true;
      }
    },
  },
};
</script>
