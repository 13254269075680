<template>
  <iframe v-if="sharingSecret" :id="idName" frameborder="0" allowfullscreen height="100%" width="100%" :src="url"></iframe>
</template>

<script>
import { renderErrorTrackable } from '@/mixins';
import Vue from 'vue';
import _ from 'lodash';

export default {
  name: 'dashboard',
  mixins: [renderErrorTrackable],
  props: {
    idName: {
      type: String,
      required: true,
    },
    params: {
      type: Object,
      required: false,
    },
    timerange: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      sharingSecret: null,
      sharingToken: null,
      urlReportSegment: null,
      reportId: null,
    };
  },
  methods: {
    isSecretExpiredEvent(event) {
      if (event.origin !== 'https://dashboards.cluvio.com') {
        return false;
      }

      let data = JSON.parse(event.data);
      return data.event === 'secretExpired' || data.event === 'secret_expired';
    },
    async getRefreshedSecretFromToken() {
      const cfg = await Vue.prototype.$services.cluvio.generateCluvioJwt({ report_name: this.idName });

      this.sharingSecret = cfg.cluvio_jwt_token;
      this.reportId = cfg.report_id;
      this.urlReportSegment = cfg.url_report_segment;
      this.sharingToken = cfg.sharing_token;
    },
    postMessageToCluvioDashboard(message) {
      document.getElementById(this.idName).contentWindow.postMessage(JSON.stringify(message), '*');
    },
    async windowMessageListener(event) {
      if (!this.isSecretExpiredEvent(event)) {
        return;
      }

      await this.getRefreshedSecretFromToken();
      this.postMessageToCluvioDashboard({
        action: 'updateSharingSecret',
        params: {
          sharingSecret: this.sharingSecret,
        },
      });
    },
  },
  async created() {
    window.addEventListener('message', this.windowMessageListener);
    await this.getRefreshedSecretFromToken();
  },
  async beforeDestroy() {
    window.removeEventListener('message', this.windowMessageListener);
  },
  computed: {
    url() {
      const filters = {};

      if (!_.isEmpty(this.params)) {
        _.forEach(this.params, (param, index) => {
          filters[index] = [param];
        });
      }

      let result = `https://dashboards.cluvio.com/dashboards/${this.urlReportSegment}/shared?sharingToken=${this.sharingToken}&sharingSecret=${
        this.sharingSecret
      }&filters=${encodeURIComponent(JSON.stringify(filters))}&backgroundColor=FFFFFF`;

      if (this.reportId) {
        result += `&reportId=${this.reportId}`;
      }

      if (this.timerange) {
        result += `&timerange=${encodeURIComponent(this.timerange)}`;
      }

      return result;
    },
  },
};
</script>
