import Vue from 'vue';

const service = {
  async getWalletDefinition(id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/wallet-definitions/v1/wallet-definitions/${id}`);
    return response.data.data;
  },
  async listWalletDefinitions(page, limit, keyword) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath('/wallet-definitions/v1/wallet-definitions', {
        page,
        limit,
        keyword,
      })}`,
    );
    return response.data.data;
  },
  async getStatistics(key) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/wallet-definitions/v1/wallet-definitions/${key}/statistics`);
    return response.data.data;
  },
  async getLogs(id, page, limit) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}/wallet-definitions/v1/wallet-definitions/${id}/logs?page=${page}&limit=${limit}`,
    );
    return response.data.data;
  },
  async renameWalletDefinition(id, name) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/wallet-definitions/v1/wallet-definitions/${id}/rename`, {
      name,
    });
    return response.data;
  },
};

function generatePath(startPath, { page, limit, keyword }) {
  let path = startPath;
  if (page || limit || keyword) {
    path += '?';
  }

  const queryStrings = [];
  if (page) {
    queryStrings.push(['page', page]);
  }
  if (limit) {
    queryStrings.push(['limit', limit]);
  }
  if (keyword) {
    queryStrings.push(['keyword', keyword]);
  }

  const params = new URLSearchParams(queryStrings);
  return path + params.toString();
}

export default service;
