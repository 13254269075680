<template>
  <card class="hc-manual-approval-section" :title="$t('application.manual_approval.title')" icon="">
    <template slot="headerZoneRight">
      <label-text :label="$t(`application.manual_approval.reviewed_date`)" :value="application.reviewed_date | formatDateHourMin"></label-text>
      <label-text :label="$t(`application.manual_approval.agent_title`)">
        <app-name :app_key="application.reviewed_by_app_key" slot="labelTextLink" class="label-text--value"></app-name>
      </label-text>
    </template>

    <template slot="content">
      <v-layout column>
        <div class="row-items-block">
          <label-text
            :label="$t(`application.manual_approval.verification_method`)"
            :value="$t(`code.application.manual_approval.verification_method.${application.kyc_detail.verification_method}`)"
          ></label-text>
          <label-text
            v-if="application.kyc_detail.verification_method === 'credit_file'"
            :label="$t(`application.manual_approval.credit_file.reference`)"
            :value="application.kyc_detail.credit_file.reference_number"
          ></label-text>
        </div>

        <div class="row-items-block last-block-no-bottom-padding" v-if="application.kyc_detail.verification_method === 'dual_source'">
          <v-layout colunm v-for="(source, index) in application.kyc_detail.dual_source.sources" :key="index">
            <list-container>
              <template slot="heading">
                <h5 class="dual-source-sub-headings">{{ $t(`application.manual_approval.dual_source.${index}`) }}</h5>
              </template>
              <template slot="stack-vertically">
                <label-text
                  :label="$t(`application.manual_approval.dual_source.information`)"
                  :value="$t(`code.application.manual_approval.information.${source.information}`)"
                ></label-text>
                <label-text
                  :label="$t(`application.manual_approval.dual_source.provision`)"
                  :value="$t(`code.application.manual_approval.provision.${source.provision}`)"
                ></label-text>
                <label-text :label="$t(`application.manual_approval.dual_source.institution`)" :value="source.institution"></label-text>
                <label-text :label="$t(`application.manual_approval.dual_source.reference`)" :value="source.reference_number"></label-text>
              </template>
            </list-container>
          </v-layout>
        </div>

        <div class="row-items-block last-block-no-bottom-padding" v-if="application.kyc_detail.verification_method === 'face_to_face'">
          <label-text
            :label="$t(`application.manual_approval.face.document`)"
            :value="$t(`application.approve_application_action.document_options.${application.kyc_detail.face_to_face.document_type}`)"
          ></label-text>
          <label-text
            :label="$t(`application.manual_approval.face.jurisdiction`)"
            :value="application.kyc_detail.face_to_face.issuing_jurisdiction"
          ></label-text>
          <label-text
            :label="$t(`application.manual_approval.face.expiration`)"
            :value="application.kyc_detail.face_to_face.expiration_date | formatDateNoTime"
          ></label-text>
          <label-text
            :label="$t(`application.manual_approval.face.reference`)"
            :value="application.kyc_detail.face_to_face.reference_number"
          ></label-text>
        </div>
      </v-layout>
    </template>
  </card>
</template>

<script>
export default {
  name: 'manual-approval-info',
  props: {
    application: {
      type: Object,
      required: false,
    },
  },
};
</script>
