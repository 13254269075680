<template>
  <card-modal
    icon=""
    :title="$t('voucher.apply_dormancy_extension_action.title')"
    context="applyVoucherDormancyExtension"
    @submit="submit"
    @cancel="cancel"
    :submit_working="submitting"
    cancel_text="voucher.apply_dormancy_extension_action.button_cancel"
    submit_text="voucher.apply_dormancy_extension_action.button_save"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <template>
          <v-layout row pad-form-row-elements>
            <v-flex sm12 xs12>
              <v-text-field
                v-model="reason"
                :label="$t('voucher.apply_dormancy_extension_action.reason')"
                required
                v-validate="{ required: true }"
                data-vv-name="Reason"
                name="reason"
                :error-messages="errors.collect('Reason')"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </template>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'apply-voucher-dormancy-extension-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    voucher: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      submitting: false,
      reason: null,
      currentVoucher: {},
    };
  },
  async mounted() {
    this.currentVoucher = _.cloneDeep(this.voucher);
  },
  computed: {
    ...mapGetters('offers', ['offer']),
    ...mapGetters('ui', ['currentLocale']),
    // TODO (PLM) check if we want that.
    // isExpirationAtLeastOneDayBeforeClosure() {
    //   return this.numberOfDaysBetweenExpirationAndClosure !== 0 && this.numberOfDaysBetweenExpirationAndClosure <= 1;
    // },
  },
  methods: {
    clear() {
      this.currentVoucher = {};
      this.submitting = false;
      this.reason = null;
      this.$validator.reset();
    },
    success() {
      this.clear();
      this.$emit('updated');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();
      if (validationComplete) {
        const params = {
          id: this.voucher.id,
          reason: this.reason,
        };

        this.submitting = true;
        try {
          await this.executeAction(
            {
              action: 'voucher/applyVoucherDormancyExtension',
              name: 'applyVoucherDormancyExtension',
              success: this.success,
            },
            params,
          );
        } finally {
          this.submitting = false;
        }
      }
    },
  },
};
</script>
