import moment from 'moment-timezone';
import datetimeFactory from 'datetime-factory';

export default ({ Vue, app }) => {
  Vue.filter('formatDate', (date) => {
    if (date) {
      moment.locale(app.i18n.locale);
      return moment(date).format('MMMM D YYYY, h:mm:ss a');
    } else {
      return app.i18n.t('system.na');
    }
  });

  Vue.filter('formatDateAbbreviateMonth', (date) => {
    if (date) {
      moment.locale(app.i18n.locale);
      return moment(date).format('MMM D YYYY, h:mm:ss a');
    } else {
      return app.i18n.t('system.na');
    }
  });

  Vue.filter('formatDateHourMin', (date) => {
    if (date) {
      moment.locale(app.i18n.locale);
      return moment(date).format('MMMM D YYYY, h:mm a');
    } else {
      return app.i18n.t('system.na');
    }
  });

  Vue.filter('formatDateNoTime', (date) => {
    if (date) {
      moment.locale(app.i18n.locale);
      return moment(date).format('MMMM D, YYYY');
    } else {
      return app.i18n.t('system.na');
    }
  });

  Vue.filter('formatShortDate', (date) => {
    if (!date) {
      return app.i18n.t('system.na');
    }

    moment.locale(app.i18n.locale);
    return moment(date).format('DD-MM-YYYY');
  });

  Vue.filter('formatShortDateInverted', (date, args) => {
    if (!date) {
      if (args && args.returnNullIfNoDate) {
        return null;
      }
      return app.i18n.t('system.na');
    }

    moment.locale(app.i18n.locale);
    const current = moment(date);

    let formattedDate = current.format('YYYY-MM-DD');

    if (args && args.timezone) {
      formattedDate = current
        .tz(args.timezone)
        .local()
        .format('YYYY-MM-DD');
    }
    if (args && args.withAgo) {
      formattedDate += ' (' + current.fromNow() + ')';
    }

    return formattedDate;
  });

  Vue.filter('formatShortDateTimeInverted', (date, args) => {
    if (!date) {
      return app.i18n.t('system.na');
    }

    moment.locale(app.i18n.locale);
    const current = moment(date);

    let formattedDate = current.format('YYYY-MM-DD HH:mm:ss');

    if (args && args.timezone) {
      formattedDate = current
        .tz(args.timezone)
        .local()
        .format('YYYY-MM-DD HH:mm:ss');
    }
    if (args && args.withAgo) {
      formattedDate += ' (' + current.fromNow() + ')';
    }

    return formattedDate;
  });

  Vue.filter('formatShortDateTimeNoSecondsInverted', (date, args) => {
    if (!date) {
      if (args.returnNull) return null;
      return app.i18n.t('system.na');
    }

    moment.locale(app.i18n.locale);
    const current = moment(date);

    let formattedDate = current.format('YYYY-MM-DD HH:mm');

    if (args && args.timezone) {
      formattedDate = current
        .tz(args.timezone)
        .local()
        .format('YYYY-MM-DD HH:mm');
    }
    if (args && args.withAgo) {
      formattedDate += ' (' + current.fromNow() + ')';
    }

    return formattedDate;
  });

  Vue.filter('formatYearHyphenMonth', (date) => {
    if (date) {
      moment.locale(app.i18n.locale);
      return moment(date).format('YYYY-MM');
    } else {
      return app.i18n.t('system.na');
    }
  });

  Vue.filter('addHyphensToDate', (date) => {
    if (date && !includesHyphen(date)) {
      return hyphenateDate(date);
    }
  });

  Vue.filter('calculateTimeElapsed', (date, args1 = datetimeFactory.now().toISOString()) => {
    moment.locale(app.i18n.locale);
    if (date) {
      const diff = moment(args1).diff(moment(date));
      const currentDuration = moment.duration(diff);

      if (currentDuration.years() >= 3) {
        return `${currentDuration.years()} ${app.i18n.tc('system.dates.year', currentDuration.years())}`;
      } else if (currentDuration.asMonths() < 1) {
        return `${currentDuration.days()} ${app.i18n.tc('system.dates.day', currentDuration.days())}`;
      }
      const monthsTruncated = Math.trunc(currentDuration.asMonths());
      return `${monthsTruncated} ${app.i18n.tc('system.dates.month', monthsTruncated)}`;
    }
  });

  function includesHyphen(string) {
    return string.includes('-');
  }

  function hyphenateDate(date) {
    return [date.slice(0, 4), date.slice(4, 6), date.slice(6)].join('-');
  }
};
