<template>
  <view-main title="page_title.branding_details">
    <two-levels-breadcrumbs slot="content" :top-label="$t('navigation.brandings')" :to="{ name: 'brandings' }" :last-label="branding.name" />
    <branding-information slot="content" />
    <branding-showcase-images
      slot="content"
      :title="$t('branding.details.branding_images_section.title_fr')"
      :images="showcase_images_fr"
      role="showcase_fr"
    />
    <branding-showcase-images
      slot="content"
      :title="$t('branding.details.branding_images_section.title_en')"
      :images="showcase_images_en"
      role="showcase_en"
    />
    <branding-logo-horizontal-images slot="content" :image-en="logo_horizontal_en" :image-fr="logo_horizontal_fr" />
    <branding-small-hero-images slot="content" :image-en="small_hero_en" :image-fr="small_hero_fr" />
    <branding-background-images slot="content" :image-en="background_image_en" :image-fr="background_image_fr" />
    <branding-stores slot="content" />
    <branding-publishers slot="content" />
    <branding-offers slot="content" />
    <branding-spaces slot="content" />
  </view-main>
</template>

<script>
import { actionErrorTrackable, renderErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import BrandingInformation from '@/components/branding/detail/BrandingInformation.vue';
import BrandingShowcaseImages from '@/components/branding/detail/BrandingShowcaseImages.vue';
import BrandingBackgroundImages from '@/components/branding/detail/BrandingBackgroundImages.vue';
import BrandingSmallHeroImages from '@/components/branding/detail/BrandingSmallHeroImages.vue';
import BrandingLogoHorizontalImages from '@/components/branding/detail/BrandingLogoHorizontalImages.vue';
import BrandingStores from '@/components/branding/detail/BrandingStores.vue';
import BrandingPublishers from '@/components/branding/detail/BrandingPublishers.vue';
import BrandingOffers from '@/components/branding/detail/BrandingOffers.vue';
import BrandingSpaces from '@/components/branding/detail/BrandingSpaces.vue';

export default {
  name: 'branding-details-view',
  mixins: [renderErrorTrackable, actionErrorTrackable],
  components: {
    BrandingInformation,
    BrandingShowcaseImages,
    BrandingBackgroundImages,
    BrandingSmallHeroImages,
    BrandingLogoHorizontalImages,
    BrandingStores,
    BrandingPublishers,
    BrandingOffers,
    BrandingSpaces,
  },
  props: {
    brandingId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('branding', ['branding']),
    showcase_images_fr() {
      return _.get(this.branding, 'split_images.showcases.fr', []);
    },
    showcase_images_en() {
      return _.get(this.branding, 'split_images.showcases.en', []);
    },
    background_image_en() {
      return _.get(this.branding, 'split_images.background_image.en', {});
    },
    background_image_fr() {
      return _.get(this.branding, 'split_images.background_image.fr', {});
    },
    big_hero_en() {
      return _.get(this.branding, 'split_images.big_hero.en', {});
    },
    big_hero_fr() {
      return _.get(this.branding, 'split_images.big_hero.fr', {});
    },
    small_hero_en() {
      return _.get(this.branding, 'split_images.small_hero.en', {});
    },
    small_hero_fr() {
      return _.get(this.branding, 'split_images.small_hero.fr', {});
    },
    logo_horizontal_en() {
      return _.get(this.branding, 'split_images.logo_horizontal.en', {});
    },
    logo_horizontal_fr() {
      return _.get(this.branding, 'split_images.logo_horizontal.fr', {});
    },
  },
  async mounted() {
    await this.fetch();
  },
  watch: {
    async brandingId() {
      return this.fetch();
    },
  },
  methods: {
    async fetch() {
      const branding = await this.executeAction({ action: 'branding/getBranding' }, this.brandingId);
      if (!branding) {
        this.$router.replace({ name: 'notFound' });
      }
    },
  },
};
</script>
