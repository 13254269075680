import Vue from 'vue';
import moment from 'moment';

const service = {
  async getTasks({ agent_key, card_program_key }, { date_from, date_to, keyword, types, statuses, resolved_by_agent_key }, { page, limit }) {
    const filtersQueryString = buildgetTasksQueryString(
      { agent_key, card_program_key, date_from, date_to, keyword, types, statuses, resolved_by_agent_key },
      { page, limit },
    );
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/tasks/v1/tasks?${filtersQueryString}`);
    return response.data.data;
  },
  async getUniqueResolvingAgents({ card_program_key }) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}/tasks/v1/tasks/unique-resolving-agents?card_program_key=${card_program_key}`,
    );
    return response.data.data.items;
  },
  // Never been used nor tested yet, might need fixes or adjustments
  async assignTask(taskKey, { assigner_agent_key, assignee_agent_key, assignee_app_key }) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/tasks/v1/tasks/${taskKey}/assign`, {
      agent_key: assigner_agent_key,
      assign_to: { agent_key: assignee_agent_key, app_key: assignee_app_key },
    });
    return response.data.task;
  },
  async resolveTask(taskKey, { agent_key, action, params }) {
    const payload = {
      agent_key,
      action,
    };

    if (params) {
      payload.params = params;
    }

    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/tasks/v1/tasks/${taskKey}/resolve`, payload);
    return response.data.task;
  },
  async getTaskTypes({ agent_key, card_program_key }) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}/tasks/v1/tasks/types?agent_key=${agent_key}&card_program_key=${card_program_key}`,
    );
    return response.data.data.types;
  },
};

function buildgetTasksQueryString(params, { page, limit }) {
  let queryString = `agent_key=${params.agent_key}&card_program_key=${params.card_program_key}&page=${page}&limit=${limit}`;

  queryString += params.date_from
    ? `&date_from=${moment(params.date_from)
        .startOf('day')
        .toISOString()}`
    : '';
  queryString += params.date_to
    ? `&date_to=${moment(params.date_to)
        .endOf('day')
        .toISOString()}`
    : '';

  if (params.types && params.types.length > 0) {
    params.types.forEach((str) => (queryString += `&type=${str}`));
  }

  if (params.statuses && params.statuses.length > 0) {
    params.statuses.forEach((str) => (queryString += `&status=${str}`));
  }

  if (params.keyword) {
    queryString += params.keyword ? `&keyword=${params.keyword}` : '';
  }

  if (params.resolved_by_agent_key) {
    queryString += params.resolved_by_agent_key ? `&resolved_by_agent_key=${params.resolved_by_agent_key}` : '';
  }

  return queryString;
}

export default service;
