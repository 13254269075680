<template>
  <card-modal
    icon=""
    :title="$t('destination.update_action.title')"
    context="updateDestination"
    @submit="submit"
    @cancel="cancel"
    cancel_text="destination.update_action.button_cancel"
    submit_text="destination.update_action.button_save"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-text-field
          autofocus
          :label="$t('destination.update_action.name') + '*'"
          name="name"
          v-validate="'required|max:100'"
          :error-messages="errors.collect('name')"
          v-model="currentDestination.name"
          data-vv-name="name"
          maxlength="100"
          :data-vv-as="$t('destination.update_action.name')"
          required
        ></v-text-field>
        <h-autocomplete
          v-model="currentDestination.branding_id"
          :label="$t('destination.update_action.branding')"
          v-validate="'max:50'"
          :error-messages="errors.collect('branding')"
          name="branding"
          data-vv-name="branding"
          data-vv-as="branding"
          search-action="branding/autocompleteSearch"
          data-test="add_destination--branding"
          no-filter
        >
        </h-autocomplete>

        <v-checkbox
          name="show_address"
          v-model="showAddress"
          :label="$t(`destination.update_action.address.title`)"
          :error-messages="errors.collect('show_address')"
          data-vv-name="show_address"
          :data-vv-as="$t(`destination.update_action.address.show`)"
          type="checkbox"
        ></v-checkbox>
        <div v-if="showAddress">
          <h4>{{ $t('destination.update_action.address.title') }}</h4>

          <search-address @updated="updateDestinationAddressModal"></search-address>

          <v-text-field
            name="street"
            v-model="currentDestination.address.street"
            v-validate="{ required: showAddress, max: 50 }"
            @paste="this.pasteParsedAddress"
            :label="$t(`destination.update_action.address.street`) + '*'"
            :error-messages="errors.collect('street')"
            data-vv-name="street"
            :data-vv-as="$t(`destination.update_action.address.street`)"
            maxlength="50"
            counter
            data-test="update-destination--street"
          ></v-text-field>
          <v-text-field
            name="city"
            v-model="currentDestination.address.city"
            v-validate="{ required: showAddress, max: 50 }"
            :label="$t(`destination.update_action.address.city`) + '*'"
            :error-messages="errors.collect('city')"
            data-vv-name="city"
            :data-vv-as="$t(`destination.update_action.address.city`)"
            counter
            maxlength="50"
            data-test="update-destination--city"
            required
          ></v-text-field>
          <v-select
            name="state"
            v-model="currentDestination.address.state"
            v-validate="{ required: showAddress }"
            :error-messages="errors.collect('state')"
            data-vv-name="state"
            :data-vv-as="$t(`destination.update_action.address.state`)"
            :items="stateOptions"
            item-text="name"
            item-value="abbr"
            :label="$t(`destination.update_action.address.state`) + '*'"
            data-test="update-destination--state"
          ></v-select>
          <v-select
            name="country"
            v-model="currentDestination.address.country"
            v-validate="{ required: showAddress }"
            :error-messages="errors.collect('country')"
            data-vv-name="country"
            :data-vv-as="$t(`destination.update_action.address.country`)"
            :items="countryOptions"
            item-text="name"
            item-value="abbr"
            :label="$t(`destination.update_action.address.country`) + '*'"
            data-test="update-destination--country"
          ></v-select>
          <v-text-field
            name="zip"
            v-mask-zip
            v-model="currentDestination.address.zip"
            v-validate="{ required: showAddress, regex: /^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z] [0-9][ABCEGHJ-NPRSTV-Z][0-9]$/ }"
            :label="$t(`destination.update_action.address.zip`) + '*'"
            hint="X9X 9X9"
            :error-messages="errors.collect('zip')"
            data-vv-name="zip"
            :data-vv-as="$t(`destination.update_action.address.zip`)"
            data-test="update-destination--zip"
          ></v-text-field>
          <v-text-field
            name="plus_code"
            v-model="currentDestination.address.plus_code"
            v-validate="{ max: 20 }"
            :label="$t(`destination.update_action.address.plus_code`)"
            hint="87Q8CCWF+6C"
            :error-messages="errors.collect('plus_code')"
            data-vv-name="plus_code"
            :data-vv-as="$t(`destination.update_action.address.zip`)"
            data-test="update-destination--plus_code"
            maxlength="20"
          ></v-text-field>
        </div>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import _isEmpty from 'lodash/isEmpty';
import _pickBy from 'lodash/pickBy';
import _cloneDeep from 'lodash/cloneDeep';
import _omit from 'lodash/omit';
import { parseAddress } from '@/utils/address-utils';
import SearchAddress from '@/components/store/actions/SearchAddress.vue';

import Vue from 'vue';

const DEFAULT_EMPTY_ADDRESS = Object.freeze({
  street: '',
  city: '',
  zip: '',
  state: '',
  country: '',
  plus_code: null,
});

export default {
  name: 'update-destination-action',
  mixins: [actionErrorTrackable],
  components: { SearchAddress },
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    destination: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentDestination: { address: {}, coordinates: {} },
      showAddress: false,
      stateOptions: [
        { name: this.$t('state.alberta'), abbr: 'AB' },
        { name: this.$t('state.british_columbia'), abbr: 'BC' },
        { name: this.$t('state.manitoba'), abbr: 'MB' },
        { name: this.$t('state.new_brunswick'), abbr: 'NB' },
        { name: this.$t('state.newfoundland_labrador'), abbr: 'NL' },
        { name: this.$t('state.nova_scotia'), abbr: 'NS' },
        { name: this.$t('state.northwest_territories'), abbr: 'NT' },
        { name: this.$t('state.nunavut'), abbr: 'NU' },
        { name: this.$t('state.ontario'), abbr: 'ON' },
        { name: this.$t('state.prince_edward_island'), abbr: 'PE' },
        { name: this.$t('state.quebec'), abbr: 'QC' },
        { name: this.$t('state.saskatchewan'), abbr: 'SK' },
        { name: this.$t('state.yukon'), abbr: 'YT' },
      ],
      countryOptions: [{ name: this.$t('country.canada'), abbr: 'CA' }],
    };
  },
  async created() {
    this.currentDestination = _cloneDeep(this.destination);
    if (this.currentDestination.address) {
      this.showAddress = true;
    } else {
      this.currentDestination.address = {};
      this.showAddress = false;
    }
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    clear() {
      this.currentDestination = { address: {} };
      this.$validator.reset();
    },
    success() {
      this.clear();
      this.$emit('updated');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    pasteParsedAddress(event) {
      let pasteString = (event.clipboardData || window.clipboardData).getData('text');

      const address = parseAddress(pasteString);
      if (address) {
        event.preventDefault();
        this.updateDestinationAddress(address);
      }
    },
    updateDestinationAddressModal({ address, coordinates }) {
      this.updateDestinationAddress(address, true);
      this.currentDestination.coordinates = {
        generated: true,
        lat: coordinates.latitude,
        lng: coordinates.longitude,
      };
    },
    updateDestinationAddress(address, overrideNotMutedFieldsWithDefaultValues = false) {
      const assignAddressValues = (values) => Object.entries(values).forEach(([key, value]) => Vue.set(this.currentDestination.address, key, value));
      const mutedValues = _pickBy(address, (item) => !_isEmpty(item));

      if (overrideNotMutedFieldsWithDefaultValues) {
        assignAddressValues({
          ...DEFAULT_EMPTY_ADDRESS,
          ...mutedValues,
        });
      } else {
        assignAddressValues(mutedValues);
      }
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();
      if (validationComplete) {
        await this.executeAction(
          {
            action: 'destination/updateDestination',
            name: 'updateDestination',
            success: this.success,
          },
          this.buildPayload(),
        );
      }
    },
    buildPayload() {
      const payload = Object.assign({}, _omit(this.currentDestination, ['creation_date', 'branding']));

      if (!this.showAddress) {
        payload.address = null;
      }

      return payload;
    },
  },
};
</script>
