<template>
  <card-modal
    icon=""
    :title="$t('voucher.refund_action.title')"
    context="refundVoucher"
    @submit="submit"
    @cancel="cancel"
    :submit_working="working"
    cancel_text="voucher.refund_action.button_cancel"
    submit_text="voucher.refund_action.button_save"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <template>
          <v-layout row pad-form-row-elements>
            <v-flex sm3 xs12>
              <v-text-field
                autofocus
                ref="refund_amount"
                name="refund_amount"
                v-model="refund_amount"
                :label="$t('voucher.refund_action.payment') + '*'"
                append-icon="fas fa-dollar-sign"
                v-validate="{ required: true, decimal: 2, between: [0.01, currentVoucher.remaining_amount] }"
                :error-messages="errors.collect('payment')"
                data-vv-name="payment"
                :data-vv-as="$t('voucher.refund_action.payment')"
                data-test="update-voucher--payment"
              ></v-text-field>
            </v-flex>
            <v-flex sm3 xs12>
              <h5>{{ $t('voucher.refund_action.remaining') }}</h5>
              <p>{{ this.remaining | currency | dollarSignI18n }}</p>
            </v-flex>
          </v-layout>
          <v-layout row pad-form-row-elements>
            <v-flex sm6 xs12>
              <h-autocomplete
                autofocus
                v-model="store_id"
                :label="$t('voucher.refund_action.store_id')"
                v-validate="'required'"
                :error-messages="errors.collect('store')"
                name="store"
                data-vv-name="store"
                data-vv-as="store"
                search-action="publishers/autocompleteSearchPublisherStoresByPublisher"
                :extra-search-params="{ id: voucher.publisher_id }"
                data-test="refund-voucher-store"
                no-filter
              >
              </h-autocomplete>
            </v-flex>
          </v-layout>
          <v-layout row pad-form-row-elements>
            <v-flex sm12 xs12>
              <v-text-field
                ref="reason"
                name="reason"
                v-model="reason"
                :label="$t('voucher.refund_action.reason') + '*'"
                v-validate="{ required: true, max: 1204 }"
                :error-messages="errors.collect('reason')"
                data-vv-name="reason"
                :data-vv-as="$t('voucher.refund_action.reason')"
                data-test="update-voucher--reason"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </template>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import Vue from 'vue';

export default {
  name: 'refund-voucher-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    voucher: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentVoucher: {},
      refund_amount: 0,
      store_id: null,
      reason: '',
      status: '',
    };
  },
  async mounted() {
    this.currentVoucher = _.cloneDeep(this.voucher);
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    remaining() {
      return (this.currentVoucher.remaining_amount - Number(this.refund_amount) || 0).toFixed(2);
    },
  },
  methods: {
    clear() {
      this.currentVoucher = {};
      this.$validator.reset();
    },
    success() {
      this.clear();
      this.$emit('updated');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();
      if (validationComplete) {
        await this.executeAction(
          {
            action: 'voucher/refundVoucher',
            name: 'refundVoucher',
            success: this.success,
          },
          {
            id: this.voucher.id,
            amount: this.refund_amount,
            reason: this.reason,
            store_id: this.store_id,
          },
        );
      }
    },
  },
};
</script>
