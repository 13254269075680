<template>
  <table class="responsive-table" id="user-card-applications-table">
    <thead>
      <tr>
        <td>{{ $t('user.card_applications_table.fields.id') }}</td>
        <td>{{ $t('user.card_applications_table.fields.sponsor') }}</td>
        <td>{{ $t('user.card_applications_table.fields.name') }}</td>
        <td>{{ $t('user.card_applications_table.fields.date_submitted') }}</td>
        <td>{{ $t('user.card_applications_table.fields.status') }}</td>
        <td>{{ $t('user.card_applications_table.fields.card_public_token') }}</td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in items" :key="index">
        <td>
          <h5>{{ $t('user.card_applications_table.fields.id') }}:</h5>
          <link-secondary-route :can-access="canViewApplication()" :route-name="'application'" :route-params="{ applicationId: item.id }">
            {{ item.id }}
          </link-secondary-route>
        </td>
        <td>
          <h5>{{ $t('user.card_applications_table.fields.sponsor') }}:</h5>
          <span>{{ item.partner_id }}</span>
        </td>
        <td>
          <h5>{{ $t('user.card_applications_table.fields.name') }}:</h5>
          <span>{{ item.first_name }} {{ item.last_name }}</span>
        </td>
        <td>
          <h5>{{ $t('user.card_applications_table.fields.date_submitted') }}:</h5>
          <span>{{ item.date_submitted | formatShortDateInverted | orNotAvailable }}</span>
        </td>
        <td class="application--status-field">
          <h5>{{ $t('user.card_applications_table.fields.status') }}:</h5>
          <span :class="`status-${item.status}`">{{ $t(`code.application.status.${item.status}`) }}</span>
        </td>
        <td>
          <h5>{{ $t('user.card_applications_table.fields.card_public_token') }}:</h5>
          <router-link v-if="item.card_public_token" :to="{ name: 'card', params: { cardPublicToken: item.card_public_token } }">
            <span>{{ item.card_public_token }}</span>
          </router-link>
          <label-none v-else>{{ $t('system.na') }}</label-none>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';

export default {
  name: 'user-card-applications-table',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: {},
  props: {
    items: {
      type: Array,
    },
  },
};
</script>
