import _ from 'lodash';

export default (amlStatusLogs) => {
  const modifiedLogs = {};
  const pepLogs = [];
  const sanctionLogs = [];

  _.forEach(amlStatusLogs, (log) => {
    const commonToAllLogs = {
      created: log.created,
      user: log.app_key,
    };

    if (log.assessment_check) {
      getLogWithAssessmentCheck(log, commonToAllLogs);
    } else {
      buildBasicLog(log, commonToAllLogs);
    }
  });

  function buildBasicLog(log, commonToAllLogs) {
    if (log.pep_to_matched) {
      const pepLog = Object.assign({}, commonToAllLogs);
      const changeObject = {};

      changeObject.change_from = log.pep_from_matched;
      changeObject.change_to = log.pep_to_matched;
      pepLog.change = changeObject;

      pepLogs.push(pepLog);
    }

    if (log.sanctions_to_matched) {
      const sanctionLog = Object.assign({}, commonToAllLogs);
      const changeObject = {};

      changeObject.change_from = log.sanctions_from_matched;
      changeObject.change_to = log.sanctions_to_matched;
      sanctionLog.change = changeObject;

      sanctionLogs.push(sanctionLog);
    }
  }

  function getLogWithAssessmentCheck(log, commonToAllLogs) {
    if (_.includes(log.changed, 'pep')) {
      const pepLog = buildBasicPepLog();
      const pepListAndNameInfo = getListAndNameInfoFromHits(log);
      const augmentedPepLog = addListInfoToPepLog(pepLog, pepListAndNameInfo);

      addToMainPepLogsArray(augmentedPepLog);
    }

    if (_.includes(log.changed, 'sanctions')) {
      const sanctionLog = buildBasicSanctionLog();
      const sanctionListAndNameInfo = getListAndNameInfoFromHits(log);
      const augmentedSanctionLog = addListInfoToSanctionLog(sanctionLog, sanctionListAndNameInfo);

      addToMainSanctionLogsArray(augmentedSanctionLog);
    }

    function buildBasicPepLog() {
      const pepLogWithChecks = Object.assign({}, commonToAllLogs);
      const changeObject = {};
      changeObject.change_from = log.pep_from_matched;
      changeObject.change_to = log.pep_to_matched;
      changeObject.view_summary = log.assessment_check.hits;

      pepLogWithChecks.change = changeObject;
      pepLogWithChecks.view_details = log.assessment_check.pep_details || [];

      return pepLogWithChecks;
    }

    function buildBasicSanctionLog() {
      const sanctionLogWithChecks = Object.assign({}, commonToAllLogs);
      const changeObject = {};
      changeObject.change_from = log.sanctions_from_matched;
      changeObject.change_to = log.sanctions_to_matched;
      changeObject.view_summary = log.assessment_check.hits;

      sanctionLogWithChecks.change = changeObject;
      sanctionLogWithChecks.view_details = log.assessment_check.sanctions_details || [];

      return sanctionLogWithChecks;
    }

    function getListAndNameInfoFromHits(log) {
      const listAndNameInfo = {};
      const pepListNames = [];
      const sanctionListNames = [];

      _.forEach(log.assessment_check.hits, (hit) => {
        let isPep = false;

        determineIfPep();

        function determineIfPep() {
          const firstThreeCharsOfRecordId = hit.type || hit['Record ID'].slice(0, 3);
          if (firstThreeCharsOfRecordId.toLowerCase() === 'pep') {
            isPep = true;
          }
        }

        if (isPep) {
          const pepList = {};
          const pepAsNames = [];

          _.forEach(hit['Hits'], (nestedHit) => {
            pepAsNames.push(nestedHit['Hit']);
          });

          pepList.list_name = hit['List Name'];
          pepList.as_names = pepAsNames;
          pepListNames.push(pepList);
        } else {
          const sanctionList = {};
          const sanctionAsNames = [];

          _.forEach(hit['Hits'], (nestedHit) => {
            sanctionAsNames.push(nestedHit['Hit']);
          });

          sanctionList.list_name = hit['List Name'];
          sanctionList.as_names = sanctionAsNames;
          sanctionListNames.push(sanctionList);
        }
      });

      listAndNameInfo.pepListNames = pepListNames;
      listAndNameInfo.sanctionListNames = sanctionListNames;

      return listAndNameInfo;
    }

    function addListInfoToPepLog(log, listInfo) {
      log.list = listInfo.pepListNames;
      return log;
    }

    function addListInfoToSanctionLog(log, listInfo) {
      log.list = listInfo.sanctionListNames;
      return log;
    }

    function addToMainPepLogsArray(pepLogWithChecks) {
      pepLogs.push(pepLogWithChecks);
    }

    function addToMainSanctionLogsArray(sanctionLogWithChecks) {
      sanctionLogs.push(sanctionLogWithChecks);
    }
  }

  modifiedLogs.pepLogs = pepLogs;
  modifiedLogs.sanctionLogs = sanctionLogs;

  return modifiedLogs;
};
