import Vue from 'vue';
import _ from 'lodash';
import { safeDispatcher } from '../../utils/context/context-helper';
import { validateCreateCommentParams } from './validators/card-validators';

const defaultPage = 1;
const paginationLimit = 20;
const batchCardsPaginationLimit = 20;
const batchNotesPaginationLimit = 20;

export default {
  namespaced: true,
  state: {
    batch: {},
    batches: [],
    batch_cards: [],
    batch_notes: [],
    list: {
      keyword: '',
      partner_id: '',
      project_id: '',
      business_id: '',
      establishment_id: '',
      status: '',
      pageNumber: 1,
      pageCount: 0,
      itemCount: 0,
    },
    batch_cards_list: {
      keyword: '',
      partner_id: '',
      status: '',
      pageNumber: 1,
      pageCount: 0,
      itemCount: 0,
    },
    batch_notes_list: {
      pageNumber: 1,
      pageCount: 0,
      itemCount: 0,
    },
  },
  getters: {
    batch: (state) => state.batch,
    batches: (state) => state.batches,
    listKeyword: (state) => state.list.keyword,
    listPartnerId: (state) => state.list.partner_id,
    listProjectId: (state) => state.list.project_id,
    listBusinessId: (state) => state.list.business_id,
    listEstablishmentId: (state) => state.list.establishment_id,
    listStatus: (state) => state.list.status,
    listPageCount: (state) => state.list.pageCount,
    listItemCount: (state) => state.list.itemCount,
    listPageNumber: (state) => state.list.pageNumber,

    batchCards: (state) => state.batch_cards,
    listBatchCardsKeyword: (state) => state.batch_cards_list.keyword,
    listBatchCardsPartnerId: (state) => state.batch_cards_list.partner_id,
    listBatchCardsStatus: (state) => state.batch_cards_list.status,
    listBatchCardsPageCount: (state) => state.batch_cards_list.pageCount,
    listBatchCardsItemCount: (state) => state.batch_cards_list.itemCount,
    listBatchCardsPageNumber: (state) => state.batch_cards_list.pageNumber,

    batchNotes: (state) => state.batch_notes,
    listBatchNotesPageCount: (state) => state.batch_notes_list.pageCount,
    listBatchNotesItemCount: (state) => state.batch_notes_list.itemCount,
    listBatchNotesPageNumber: (state) => state.batch_notes_list.pageNumber,
  },
  mutations: {
    batch(state, batch) {
      state.batch = batch;
    },
    batches(state, batches) {
      state.batches = batches;
    },
    listKeyword(state, keyword) {
      state.list.keyword = keyword;
    },
    listPartnerId(state, partner_id) {
      state.list.partner_id = partner_id;
    },
    listProjectId(state, project_id) {
      state.list.project_id = project_id;
    },
    listBusinessId(state, business_id) {
      state.list.business_id = business_id;
    },
    listEstablishmentId(state, establishment_id) {
      state.list.establishment_id = establishment_id;
    },
    listStatus(state, status) {
      state.list.status = status;
    },
    listPageCount(state, pageCount) {
      state.list.pageCount = pageCount;
    },
    listItemCount(state, itemCount) {
      state.list.itemCount = itemCount;
    },
    listPage(state, page) {
      state.list.pageNumber = page;
    },

    batchCards(state, batchCards) {
      state.batch_cards = batchCards;
    },
    listBatchCardsKeyword(state, keyword) {
      state.batch_cards_list.keyword = keyword;
    },
    listBatchCardsPartnerId(state, partner_id) {
      state.batch_cards_list.partner_id = partner_id;
    },
    listBatchCardsStatus(state, status) {
      state.batch_cards_list.status = status;
    },
    listBatchCardsPageCount(state, pageCount) {
      state.batch_cards_list.pageCount = pageCount;
    },
    listBatchCardsItemCount(state, itemCount) {
      state.batch_cards_list.itemCount = itemCount;
    },
    listBatchCardsPage(state, page) {
      state.batch_cards_list.pageNumber = page;
    },

    batchNotes(state, batchNotes) {
      state.batch_notes = batchNotes;
    },
    listBatchNotesPageCount(state, pageCount) {
      state.batch_notes_list.pageCount = pageCount;
    },
    listBatchNotesItemCount(state, itemCount) {
      state.batch_notes_list.itemCount = itemCount;
    },
    listBatchNotesPage(state, page) {
      state.batch_notes_list.pageNumber = page;
    },
  },
  actions: {
    async createBatchNote({ dispatch }, { batch_id, note }) {
      const instantCardBatchService = Vue.prototype.$services.instantCardBatch;

      await instantCardBatchService.createBatchNote(batch_id, { note });

      await dispatch('listBatchNotes', { batch_id });
    },
    async deleteBatchNote({ dispatch }, { batch_id, note_id, reason }) {
      const instantCardBatchService = Vue.prototype.$services.instantCardBatch;

      await instantCardBatchService.deleteBatchNote(batch_id, note_id, { reason });

      await dispatch('listBatchNotes', { batch_id });
    },
    async listBatchNotes({ state, commit }, { batch_id, page, limit } = {}) {
      const instantCardBatchService = Vue.prototype.$services.instantCardBatch;
      const thePage = page || state.batch_notes_list.pageNumber || page;
      const theLimit = limit || batchNotesPaginationLimit;
      const theBatchId = batch_id || '';

      const listResults = await instantCardBatchService.listBatchNotes(thePage, theLimit, theBatchId);

      commit('listBatchNotesPage', thePage);
      commit('listBatchNotesPageCount', listResults.page_count);
      commit('listBatchNotesItemCount', listResults.item_count);
      commit('batchNotes', listResults.items);
    },
    async createBatch({ commit }, params) {
      const instantCardBatchService = Vue.prototype.$services.instantCardBatch;
      return instantCardBatchService.createBatch(params);
    },
    async getBatch({ commit, dispatch }, id) {
      const instantCardBatchService = Vue.prototype.$services.instantCardBatch;
      const batch = await instantCardBatchService.getBatch(id);

      commit('batch', batch);
      return batch;
    },
    async listBatches({ state, commit }, { keyword, partner_id, project_id, business_id, status, establishment_id, page, limit } = {}) {
      const instantCardBatchService = Vue.prototype.$services.instantCardBatch;
      const thePage = page || state.list.pageNumber || defaultPage;
      const theLimit = limit || paginationLimit;
      const theKeyword = keyword || '';
      const thePartnerId = partner_id || '';
      const theProjectId = project_id || '';
      const theBusinessId = business_id || '';
      const theStatus = status || '';
      const theEstablishmentId = establishment_id || '';

      const listResults = await instantCardBatchService.listBatches(
        thePage,
        theLimit,
        theKeyword,
        thePartnerId,
        theProjectId,
        theBusinessId,
        theStatus,
        theEstablishmentId,
      );

      commit('listPartnerId', thePartnerId);
      commit('listProjectId', theProjectId);
      commit('listBusinessId', theBusinessId);
      commit('listStatus', theStatus);
      commit('listEstablishmentId', theEstablishmentId);
      commit('listKeyword', theKeyword);
      commit('listPage', thePage);
      commit('listPageCount', listResults.page_count);
      commit('listItemCount', listResults.item_count);
      commit('batches', listResults.items);
    },
    async listBatchCards({ state, commit }, { batch_id, keyword, partner_id, status, page, limit } = {}) {
      const instantCardBatchService = Vue.prototype.$services.instantCardBatch;
      const thePage = page || state.batch_cards_list.pageNumber || defaultPage;
      const theLimit = limit || batchCardsPaginationLimit;
      const theKeyword = keyword || '';
      const thePartnerId = partner_id || '';
      const theBatchId = batch_id || '';
      const theStatus = status || '';

      const listResults = await instantCardBatchService.listBatchCards(thePage, theLimit, theBatchId, theKeyword, thePartnerId, theStatus);

      commit('listBatchCardsPartnerId', thePartnerId);
      commit('listBatchCardsStatus', theStatus);
      commit('listBatchCardsKeyword', theKeyword);
      commit('listBatchCardsPage', thePage);
      commit('listBatchCardsPageCount', listResults.page_count);
      commit('listBatchCardsItemCount', listResults.item_count);
      commit('batchCards', listResults.items);
    },
    async updateBatch({ dispatch }, params) {
      const instantCardBatchService = Vue.prototype.$services.instantCardBatch;
      const { id, ...payload } = _.cloneDeep(params);

      delete payload.business_name;
      delete payload.partner_id;
      delete payload.instant_card_project_name;
      delete payload.status;
      delete payload.creation_date;
      delete payload.last_update_date;
      delete payload.generated_card_count;
      delete payload.delivery;
      delete payload.pre_activated;
      delete payload.destroyed;

      const result = await instantCardBatchService.updateBatch(id, payload);
      await dispatch('getBatch', id);
      return result;
    },
    async updateBatchTracking({ dispatch }, params) {
      const instantCardBatchService = Vue.prototype.$services.instantCardBatch;
      const { id, ...payload } = _.cloneDeep(params);

      const result = await instantCardBatchService.updateBatchTracking(id, payload);
      await dispatch('getBatch', id);
      return result;
    },
    async cancelBatch({ dispatch }, id) {
      const instantCardBatchService = Vue.prototype.$services.instantCardBatch;
      const result = await instantCardBatchService.cancelBatch(id);
      await dispatch('getBatch', id);
      return result;
    },
    async approveBatch({ dispatch }, id) {
      const instantCardBatchService = Vue.prototype.$services.instantCardBatch;
      const result = await instantCardBatchService.approveBatch(id);
      await dispatch('getBatch', id);
      return result;
    },
    async linkBatchToInstantCardProject({ dispatch }, { id, instant_card_project_id }) {
      const instantCardBatchService = Vue.prototype.$services.instantCardBatch;
      return instantCardBatchService.linkBatchToInstantCardProject({ id, instant_card_project_id });
    },
    async unlinkBatchFromInstantCardProject({ dispatch }, id) {
      const instantCardBatchService = Vue.prototype.$services.instantCardBatch;
      return instantCardBatchService.unlinkBatchFromInstantCardProject(id);
    },
    async pauseBatch({}, id) {
      const instantCardBatchService = Vue.prototype.$services.instantCardBatch;
      return instantCardBatchService.pauseBatch(id);
    },
    async resumeBatch({}, id) {
      const instantCardBatchService = Vue.prototype.$services.instantCardBatch;
      return instantCardBatchService.resumeBatch(id);
    },
    async clearListBatches({ commit }) {
      commit('batches', []);
    },
    async clearBatchCards({ commit }) {
      commit('batchCards', []);
    },
    async markBatchAsDelivered({ dispatch }, id) {
      const instantCardBatchService = Vue.prototype.$services.instantCardBatch;
      const result = await instantCardBatchService.markBatchAsDelivered(id);
      await dispatch('getBatch', id);
      return result;
    },
  },
};
