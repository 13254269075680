<template>
  <v-flex class="view-instant_card-form">
    <h1 hidden>{{ $t('instant_card_projects.form.title') }}</h1>
    <v-card>
      <form @submit.prevent="submit" class="form-basic form-search">
        <v-layout align-center wrap class="form-search--main-wrap-v2">
          <v-flex class="form-search--inputs-zone-v2">
            <form-warning-search
              :autofocus="true"
              :searched="searched"
              :hint-message="$t('instant_card_projects.form.total_results_message')"
              class="instant-card--search-field"
              :label="$t('instant_card_projects.form.placeholder')"
              v-model="keyword"
              data-vv-name="search"
              v-validate="'max:50'"
              :error-messages="errors.collect('search')"
              data-test="form-instant-card-projects--keyword"
            ></form-warning-search>
            <h-autocomplete
              v-model="program_id"
              :label="$t('instant_card_projects.form.program')"
              :error-messages="errors.collect('program_id')"
              name="program_id"
              data-vv-name="program_id"
              data-vv-as="program_id"
              search-action="partner/autocompleteSearch"
              data-test="form-instant-card-projects--program"
              no-filter
              clearable
            >
            </h-autocomplete>
            <div class="instant-card-projects--btn-search">
              <button-primary :text="$t('instant_card_projects.form.submit')" :loading="working" type="submit" />
            </div>
          </v-flex>

          <v-flex class="form-search--actions-zone-v2">
            <button-primary
              data-test="instant-card-projects--btn-add-project"
              class="instant-card-projects-form--btn-add-project"
              :loading="working"
              :text="$t('instant_card_projects.form.add_project')"
              @click="openCreateProjectModal"
            />
          </v-flex>
        </v-layout>
      </form>
    </v-card>

    <v-flex class="data-section">
      <alert-warning v-if="dataReady && projects && projects.length === 0" :text="$t('instant_card.no_instant_card_project_found')"></alert-warning>
      <card v-if="dataReady && projects && projects.length > 0" icon="fal fa-tasks-alt" :title="`${$t('instant_card_projects.form.title')}`">
        <template slot="headerZoneRight">
          <label-text class="text-sm-center" :label="$t('instant_card_projects.form.projects_count')" :value="listItemCount || '0'"></label-text>
        </template>
        <template slot="content">
          <instant-card-projects-table :items="projects" />
        </template>
      </card>
    </v-flex>

    <modal-action :show-modal="showCreateProjectModal"
      ><create-project-action v-if="showCreateProjectModal" @close="closeCreateProjectModal" @created="createProjectSuccess"></create-project-action
    ></modal-action>
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable, security } from '@/mixins';
import _ from 'lodash';
import Vue from 'vue';
import InstantCardProjectsTable from '@/components/instant_card_project/list/InstantCardProjectsTable';
import CreateProjectAction from '@/components/instant_card_project/actions/CreateProjectAction';

export default {
  name: 'search-instant-card-projects-form',
  mixins: [actionErrorTrackable, security],
  components: { InstantCardProjectsTable, CreateProjectAction },
  $_veeValidate: {
    validator: 'new',
  },
  beforeDestroy() {
    this.executeAction({ action: 'instantCardProject/clearListProjects' });
  },
  data() {
    return {
      page: 1,
      dataReady: false,
      keyword: '',
      searched: '',
      showCreateProjectModal: false,
      program_id: '',
    };
  },
  async created() {
    this.page = this.listPageNumber;
    this.keyword = this.listKeyword;
    this.program_id = this.listPartnerId;

    if (this.routeHasQueryParams()) {
      this.page = 1;
    }
    if (this.$route.query.keyword) {
      this.keyword = this.$route.query.keyword;
    }
    if (this.$route.query.program_id) {
      this.program_id = this.$route.query.program_id;
    }

    this.routeHasQueryParams() ? this.submit(null, false, this.page) : this.submit(null, true, this.page);
  },
  computed: {
    ...mapGetters('instantCardProject', ['projects', 'listKeyword', 'listPageNumber', 'listPartnerId', 'listItemCount']),
  },
  methods: {
    async clear() {
      this.searched = '';
      this.keyword = '';
      this.program_id = '';
      this.$validator.reset();
    },
    async fetchPrograms(keyword) {
      const searchProgramResults = await Vue.prototype.$services.partner.searchPartners(1, 10, keyword);
      this.programSelections = searchProgramResults.items;
      return searchProgramResults.items;
    },
    showCreateProjectFeature() {
      return this.canManageOffer(); // TODO: change permission
    },
    routeHasQueryParams() {
      return this.$route.query.keyword || this.$route.query.program_id;
    },
    openCreateProjectModal() {
      this.showCreateProjectModal = true;
    },
    closeCreateProjectModal() {
      this.showCreateProjectModal = false;
    },
    buildPayloadQueryString() {
      const payload = {};
      if (this.keyword) {
        payload.keyword = this.keyword;
      }
      if (this.program_id) {
        payload.partner_id = this.program_id;
      }

      return payload;
    },
    async submit(event, forceSearch = false, page = 1) {
      let validationComplete;
      if (!forceSearch) {
        validationComplete = await this.$validator.validateAll();
      }

      if (validationComplete || forceSearch) {
        this.$router.replace({ query: this.buildPayloadQueryString() });
        await this.clearAllError();

        try {
          this.dataReady = false;
          const search = this.buildPayloadQueryString();
          search.page = page;
          await this.executeAction({ action: 'instantCardProject/listProjects' }, search);
        } finally {
          this.dataReady = true;
        }
      }
    },
    async createProjectSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'instant_card_projects.create_action.success' });
      this.closeCreateProjectModal();
    },
  },
};
</script>
