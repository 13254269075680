<template>
  <card :title="$t('space.details.business_section.title')" icon="">
    <template slot="headerZoneRight">
      <!--      NOTE: component like StoreBusiness, for when actions added in -->
    </template>
    <template slot="content">
      <space-business-table v-if="business" :item="business"></space-business-table>
      <label-none v-if="!business">{{ $t('system.none') }}</label-none>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import SpaceBusinessTable from './SpaceBusinessTable';
import { mapGetters } from 'vuex';

export default {
  name: 'space-business',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { SpaceBusinessTable },
  data() {
    return {
      business: null,
    };
  },
  computed: {
    ...mapGetters('space', ['space']),
    ...mapGetters('ui', ['currentLocale']),
  },
  watch: {
    async space() {
      await this.fetchBusiness();
    },
  },
  methods: {
    async fetchBusiness() {
      if (this.space.business_id) {
        this.business = await this.executeAction({ action: 'business/getBusiness' }, this.space.business_id);
      } else {
        this.business = null;
      }
    },
  },
};
</script>
