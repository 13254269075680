<template>
  <card-modal
    icon=""
    :title="$t('space.create_action.title')"
    context="createSpace"
    @submit="submit"
    @cancel="cancel"
    cancel_text="space.create_action.button_cancel"
    submit_text="space.create_action.button_save"
    :submit_working="working"
    data-test="space--add-modal"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-text-field
          autofocus
          :label="$t('space.create_action.name') + ' *'"
          name="name"
          v-validate="'required|max:100'"
          :error-messages="errors.collect('name')"
          v-model="space.name"
          data-vv-name="name"
          maxlength="100"
          :data-vv-as="$t('space.create_action.name')"
          data-test="add-space--name"
          required
        ></v-text-field>
        <h-autocomplete
          v-model="space.business_id"
          :label="$t('space.create_action.business') + ' *'"
          v-validate="'required'"
          :error-messages="errors.collect('business')"
          name="business"
          data-vv-name="business"
          data-vv-as="business"
          search-action="business/autocompleteSearch"
          data-test="add-space--business"
          no-filter
        >
        </h-autocomplete>
        <v-text-field
          :label="$t('space.create_action.id') + ' *'"
          name="id"
          v-validate="'required|max:100'"
          :error-messages="errors.collect('id')"
          v-model="space.id"
          data-vv-name="id"
          maxlength="100"
          :data-vv-as="$t('space.create_action.id')"
          data-test="add-space--id"
          required
        ></v-text-field>
        <v-text-field
          :label="$t('space.create_action.slug') + ' *'"
          name="slug"
          v-validate="'required|max:100'"
          :error-messages="errors.collect('slug')"
          v-model="space.slug"
          data-vv-name="slug"
          maxlength="100"
          :data-vv-as="$t('space.create_action.slug')"
          data-test="add-space--slug"
          required
        ></v-text-field>
        <v-select
          name="space.region"
          v-model="space.region"
          :label="$t('space.create_action.region')"
          :error-messages="errors.collect('space.region')"
          data-vv-name="space.region"
          :data-vv-as="$t('space.create_action.region')"
          :items="regionOptions"
          v-validate="'required|max:20'"
          class="add-space--region"
        ></v-select>
        <v-select
          name="space.timezone"
          v-model="space.timezone"
          :label="$t('space.create_action.timezone')"
          :error-messages="errors.collect('space.timezone')"
          data-vv-name="space.timezone"
          :data-vv-as="$t('space.create_action.timezone')"
          :items="timezoneOptions"
          item-text="name"
          item-value="id"
          v-validate="'required|max:20'"
          class="add-space--timezone"
        ></v-select>
        <v-text-field
          :label="$t('space.create_action.label.fr') + ' *'"
          name="label.fr"
          v-validate="'required|max:50'"
          :error-messages="errors.collect('label.fr')"
          v-model="space.label.fr"
          data-vv-name="label.fr"
          maxlength="50"
          :data-vv-as="$t('space.create_action.label.fr')"
          data-test="add-space--label-fr"
        ></v-text-field>
        <v-text-field
          :label="$t('space.create_action.label.en') + ' *'"
          name="label.en"
          v-validate="'required|max:50'"
          :error-messages="errors.collect('label.en')"
          v-model="space.label.en"
          data-vv-name="label.en"
          maxlength="50"
          :data-vv-as="$t('space.create_action.label.en')"
          data-test="add-space--label-en"
        ></v-text-field>
        <v-textarea
          name="description.fr"
          v-model="space.description.fr"
          v-validate="'required|max:4096'"
          :error-messages="errors.collect('description.fr')"
          :label="$t('space.create_action.description.fr') + ' *'"
          data-vv-name="description.fr"
          :data-vv-as="$t('space.create_action.description.fr')"
          auto-grow
          rows="1"
          maxlength="4096"
          data-test="add-space--description-fr"
        ></v-textarea>
        <v-textarea
          name="description.en"
          v-model="space.description.en"
          v-validate="'required|max:4096'"
          :error-messages="errors.collect('description.en')"
          :label="$t('space.create_action.description.en') + ' *'"
          data-vv-name="description.en"
          :data-vv-as="$t('space.create_action.description.en')"
          auto-grow
          rows="1"
          maxlength="4096"
          data-test="add-space--description-en"
        ></v-textarea>
        <v-flex class="create-space-logo-layout">
          <space-image
            name="logo-logo_horizontal"
            v-validate="'required'"
            v-model="logoHorizontalImage"
            :error-messages="errors.collect(`logo-logo_horizontal`)"
            :label="$t(`space.create_action.horizontal_logo`) + ' *'"
            :data-vv-name="`logo-logo_horizontal`"
            :data-vv-as="$t(`space.form.upload.logo_horizontal`)"
            ref="logo_horizontal"
            image-role="logo_horizontal"
          />
        </v-flex>
        <v-text-field
          name="color"
          v-model="space.color"
          v-validate="{ required: true, max: 7, regex: /^\#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/ }"
          :error-messages="errors.collect('color')"
          :label="$t('space.create_action.color') + ' *'"
          data-vv-name="color"
          :data-vv-as="$t('space.create_action.color')"
          counter
          maxlength="7"
          data-test="add-space--color"
        >
          <template v-slot:append>
            <div :style="`display: block; height: 28px; width: 28px; backgroundColor: ${space.color || 'transparent'}; borderRadius: 5px;`" />
          </template>
        </v-text-field>
        <v-select
          name="language"
          v-model="space.default_language"
          v-validate="'required'"
          :error-messages="errors.collect('default_language')"
          data-vv-name="language"
          :data-vv-as="$t('space.create_action.default_language')"
          :items="languageOptions"
          item-text="name"
          item-value="abbr"
          :label="$t('space.create_action.default_language') + ' *'"
        ></v-select>
        <v-checkbox
          name="request_consent_communication"
          v-model="space.request_consent_communication"
          :label="$t(`space.create_action.request_consent_communication`)"
          :error-messages="errors.collect('refillable')"
          data-vv-name="refillable"
          :data-vv-as="$t(`space.create_action.request_consent_communication`)"
          type="checkbox"
        ></v-checkbox>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import Vue from 'vue';
import SpaceImage from '@/components/space/actions/components/SpaceImage';
import moment from 'moment-timezone';
import CANADIAN_TIMEZONES from '@/tools/canadian-timezones';

export default {
  name: 'create-space-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  components: { SpaceImage },
  data() {
    return {
      space: { description: {}, label: {}, url: {}, request_consent_communication: true },
      logoHorizontalImage: null,
      loadingImages: [],
      languageOptions: [
        { name: this.$t('language.french'), abbr: 'fr' },
        { name: this.$t('language.english'), abbr: 'en' },
      ],
      regionOptions: ['AB', 'NB', 'ON', 'QC'],
    };
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('business', ['businesses']),
    ...mapGetters('space', ['spaceBrandingPartner']),
    timezoneOptions() {
      return CANADIAN_TIMEZONES.map((zone) => {
        return {
          name: `${zone} (${moment()
            .tz(zone)
            .format('Z z')})`,
          id: zone,
        };
      });
    },
  },
  methods: {
    clear() {
      this.space = { description: {}, label: {}, url: {} };
      this.$validator.reset();
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    resetLoadingImages() {
      this.loadingImages = [];
    },
    async uploadBrandingImage(image, imageRole, branding_id) {
      if (image) {
        const signedUploadUrls = await this.executeAction(
          { action: 'branding/createBrandingImagesUploadSignedUrls' },
          { branding_id, images: [image.name] },
        );

        this.loadingImages.push({});

        const signed_url = signedUploadUrls[0].upload_url;
        await this.executeAction({ action: 'branding/uploadBrandingImage' }, { signed_url, image });
        await this.executeAction(
          { action: 'branding/addBrandingImage' },
          { branding_id, payload: { role: imageRole, url: signedUploadUrls[0].image_url } },
        );
        this.resetLoadingImages();
      }
    },
    async uploadPartnerImage(image, partner_id) {
      if (image) {
        const signedUploadUrls = await this.executeAction(
          { action: 'partner/createPartnerImagesUploadSignedUrls' },
          { partner_id, images: [image.name] },
        );

        this.loadingImages.push({});

        const signed_url = signedUploadUrls[0].upload_url;
        await this.executeAction({ action: 'partner/uploadPartnerImage' }, { signed_url, image });
        return signedUploadUrls[0].image_url;
      }
    },
    async updatePartnerImages(partner_id, partnerHorizontalSignedUrl) {
      await this.executeAction(
        { action: 'partner/updatePartner' },
        {
          id: partner_id,
          content: {
            logo: {
              light_background: {
                horizontal: {
                  en: partnerHorizontalSignedUrl,
                  fr: partnerHorizontalSignedUrl,
                },
                square: {
                  en: partnerHorizontalSignedUrl,
                  fr: partnerHorizontalSignedUrl,
                },
              },
              dark_background: {
                horizontal: {
                  en: partnerHorizontalSignedUrl,
                  fr: partnerHorizontalSignedUrl,
                },
                square: {
                  en: partnerHorizontalSignedUrl,
                  fr: partnerHorizontalSignedUrl,
                },
              },
            },
          },
        },
      );
    },
    async success(response) {
      await this.uploadBrandingImage(this.$refs.logo_horizontal.imageToUpload, 'logo_horizontal_en', response.branding_id);
      await this.uploadBrandingImage(this.$refs.logo_horizontal.imageToUpload, 'logo_horizontal_fr', response.branding_id);

      const partnerHorizontalSignedUrl = await this.uploadPartnerImage(this.$refs.logo_horizontal.imageToUpload, this.space.id);
      await this.updatePartnerImages(this.space.id, partnerHorizontalSignedUrl);

      this.clear();
      this.$emit('created');
    },
    buildPayload() {
      return {
        ...this.space,
        horizontal_logo: 'https://images.hellocard.ca/to_change.jpg',
        square_logo: 'https://images.hellocard.ca/to_change.jpg',
      };
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();
      if (validationComplete) {
        await this.executeAction(
          {
            action: 'space/createSpace',
            name: 'createSpace',
            success: this.success,
          },
          this.buildPayload(),
        );
      }
    },
  },
};
</script>

<style scoped lang="stylus">
@import './../../../styles/variables/custom-variables.styl';

.create-space-logo-layout {
  margin-bottom: $p-margin-bottom;
}
</style>
