<template>
  <v-card class="card">
    <v-breadcrumbs
      :items="[
        {
          text: this.topLabel,
          disabled: !!!this.topTo,
          to: this.topTo,
          exact: true,
        },
        {
          text: this.secondLabel,
          disabled: !!!this.secondTo,
          to: this.secondTo,
          exact: true,
        },
        {
          text: this.thirdLabel,
          disabled: !!!this.thirdTo,
          to: this.thirdTo,
          exact: true,
        },
        {
          text: this.lastLabel,
          disabled: true,
        },
      ]"
    ></v-breadcrumbs>
  </v-card>
</template>

<script>
export default {
  name: 'four-levels-breadcrumbs',
  props: {
    topLabel: {
      type: String,
      required: false,
    },
    topTo: {
      type: Object,
      required: false,
    },
    secondLabel: {
      type: String,
      required: false,
    },
    secondTo: {
      type: Object,
      required: false,
    },
    thirdLabel: {
      type: String,
      required: false,
    },
    thirdTo: {
      type: Object,
      required: false,
    },
    lastLabel: {
      type: String,
      required: false,
    },
  },
};
</script>
