<template>
  <card :title="$t('branding.details.branding_background_images_section.title')" icon="">
    <template slot="content">
      <v-layout row>
        <branding-image language="en" image-role="background_image_en" :image-obj="imageEn" />
        <branding-image language="fr" image-role="background_image_fr" :image-obj="imageFr" />
      </v-layout>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import BrandingImage from '@/components/branding/detail/components/BrandingImage';

export default {
  name: 'branding-background-images',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { BrandingImage },
  props: {
    imageEn: {
      type: Object,
    },
    imageFr: {
      type: Object,
    },
  },
};
</script>
