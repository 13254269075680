<template>
  <table class="responsive-table">
    <thead>
      <tr>
        <td>{{ $t('instant_card_projects.projects_table.id') }}</td>
        <td>{{ $t('instant_card_projects.projects_table.name') }}</td>
        <td>{{ $t('instant_card_projects.projects_table.program') }}</td>
        <td v-if="exclude !== 'offers'">{{ $t('instant_card_projects.projects_table.offers_available') }}</td>
        <td>{{ $t('instant_card_projects.projects_table.batches_pending') }}</td>
        <td>{{ $t('instant_card_projects.projects_table.batches_generated') }}</td>
        <td>{{ $t('instant_card_projects.projects_table.batches_delivered') }}</td>
        <td>{{ $t('instant_card_projects.projects_table.cards_paused') }}</td>
        <td>{{ $t('instant_card_projects.projects_table.cards_pending_activation') }}</td>
        <td>{{ $t('instant_card_projects.projects_table.cards_active') }}</td>
        <td>{{ $t('instant_card_projects.projects_table.created') }}</td>
        <td></td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in items" :key="index">
        <td>
          <h5>{{ $t('instant_card_projects.projects_table.id') }}:</h5>
          <router-link :to="{ name: 'instant_card_project_details', params: { projectId: item.id } }">
            <span>{{ item.id }}</span>
          </router-link>
        </td>
        <td>
          <h5>{{ $t('instant_card_projects.projects_table.name') }}:</h5>
          <span>{{ item.name }}</span>
        </td>
        <td>
          <h5>{{ $t('instant_card_projects.projects_table.program') }}:</h5>
          <span :class="!item.partner_id ? 'info-not-available' : ''">{{ item.partner_id }}</span>
        </td>
        <td v-if="exclude !== 'offers'" align="right">
          <h5>{{ $t('instant_card_projects.projects_table.offers_available') }}:</h5>
          <span>{{ item.offers_available }}</span>
        </td>
        <td align="right">
          <h5>{{ $t('instant_card_projects.projects_table.batches_pending') }}:</h5>
          <span>{{ item.batches_pending }}</span>
        </td>
        <td align="right">
          <h5>{{ $t('instant_card_projects.projects_table.batches_generated') }}:</h5>
          <span>{{ item.batches_generated }}</span>
        </td>
        <td align="right">
          <h5>{{ $t('instant_card_projects.projects_table.batches_delivered') }}:</h5>
          <span>{{ item.batches_delivered }}</span>
        </td>
        <td align="right">
          <h5>{{ $t('instant_card_projects.projects_table.cards_paused') }}:</h5>
          <span>{{ item.cards_paused }}</span>
        </td>
        <td align="right">
          <h5>{{ $t('instant_card_projects.projects_table.cards_pending_activation') }}:</h5>
          <span>{{ item.cards_pending_activation }}</span>
        </td>
        <td align="right">
          <h5>{{ $t('instant_card_projects.projects_table.cards_active') }}:</h5>
          <span>{{ item.cards_active }}</span>
        </td>
        <td>
          <h5>{{ $t('instant_card_projects.projects_table.created') }}:</h5>
          <span :class="!item.creation_date ? 'info-not-available' : ''">{{ item.creation_date | formatShortDateInverted | orNotAvailable }}</span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';

export default {
  name: 'shared-instant-card-projects-table',
  mixins: [actionErrorTrackable, security],
  props: {
    items: {
      type: Array,
      required: true,
    },
    exclude: {
      type: String, // Acceptable values: offers
      required: false,
    },
  },
};
</script>
