<template>
  <card-modal
    icon=""
    :title="$t('inventory.create_note_action.title')"
    context="createInventoryNote"
    @submit="submit"
    @cancel="cancel"
    cancel_text="inventory.create_note_action.button_cancel"
    submit_text="inventory.create_note_action.button_save"
    :submit_working="working"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-textarea
          autofocus
          auto-grow
          name="note"
          rows="1"
          row-height="1"
          :label="$t('inventory.create_note_action.note')"
          v-model="note"
          v-validate="'required|max:4096'"
          :error-messages="errors.collect('note')"
          data-vv-name="note"
          :data-vv-as="$t('inventory.create_note_action.note')"
          counter
          required
          maxlength="4096"
        ></v-textarea>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';

export default {
  name: 'create-inventory-note-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    inventoryId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      note: '',
    };
  },
  methods: {
    clear() {
      this.note = '';
      this.$validator.reset();
    },
    success() {
      this.clear();
      this.$emit('created');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        await this.executeAction(
          {
            action: 'inventory/createInventoryNote',
            success: this.success,
            name: 'createInventoryNote',
          },
          {
            inventory_id: this.inventoryId,
            note: this.note,
          },
        );
      }
    },
  },
};
</script>
