const _ = require('lodash');

function removeMultipleSpace(keywords) {
  return _.replace(keywords, /[ ][ ]+/g, ' ');
}

function escapeSpaceCharacter(keywords) {
  return keywords
    .replace(/\n/g, '\\n')
    .replace(/\r/g, '\\r')
    .replace(/\t/g, '\\t')
    .replace(/\f/g, '\\f')
    .replace(/\v/g, '\\v');
}

function escapeRegexSymbole(keywords) {
  return _.replace(keywords, /[-[\]{}()*+?.,\\^$|#]/g, '\\$&');
}

export function ignoreCaseAndAccentsRegex(keywords) {
  const ACCENT_STRINGS = 'ŠŒŽšœžŸ¥µÀÁÂÃÄÅÆÇÈÉÊËẼÌÍÎÏĨÐÑÒÓÔÕÖØÙÚÛÜÝßàáâãäåæçèéêëẽìíîïĩðñòóôõöøùúûüýÿ'.split('');
  const NO_ACCENT_STRINGS = 'SOZsozYYuAAAAAAACEEEEEIIIIIDNOOOOOOUUUUYsaaaaaaaceeeeeiiiiionoooooouuuuyy'.split('');

  const regex = [];

  const prepareKeywords = _.flow([_.deburr, removeMultipleSpace, escapeSpaceCharacter, escapeRegexSymbole]);

  prepareKeywords(keywords)
    .split('')
    .forEach((letter) => {
      const reg = [letter];

      NO_ACCENT_STRINGS.forEach((checkLetter, index) => {
        if (checkLetter === letter) {
          reg.push(ACCENT_STRINGS[index]);
        }
      });

      if (reg.length === 1) {
        regex.push(`${reg.join('')}`);
      } else {
        regex.push(`[${reg.join('')}]`);
      }
    });

  const formattedRegex = regex.join('');

  const parts = [formattedRegex];
  const reg = `(${parts.join('|')})`;

  return new RegExp(reg, 'i');
}

// module.exports = { ignoreCaseAndAccentsRegex };
