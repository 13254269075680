<template>
  <table class="responsive-table">
    <thead>
      <tr>
        <td>{{ $t('offers.offers_table.id') }}</td>
        <td v-if="exclude !== 'branding'">{{ $t('offers.offers_table.name') }}</td>
        <td>{{ $t('offers.offers_table.program') }}</td>
        <td v-if="exclude !== 'business'">{{ $t('offers.offers_table.business') }}</td>
        <td v-if="exclude !== 'publishers'" align="center">{{ $t('offers.offers_table.publishers') }}</td>
        <td>{{ $t('offers.offers_table.incentive') }}</td>
        <td>{{ $t('offers.offers_table.period') }}</td>
        <td v-if="exclude !== 'inventory'">{{ $t('offers.offers_table.inventory.title') }}</td>
        <td>{{ $t('offers.offers_table.visibility') }}</td>
        <td>{{ $t('offers.offers_table.status') }}</td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in items" :key="index">
        <td>
          <h5>{{ $t('offers.offers_table.id') }}:</h5>
          <span>
            <router-link v-if="canViewOffer()" :to="{ name: 'offers_details', params: { offerId: determineOfferId(item) } }">
              {{ determineOfferId(item) }}
            </router-link>
            <span v-else>{{ item.id }}</span>
          </span>
        </td>
        <td v-if="exclude !== 'branding'">
          <h5>{{ $t('offers.offers_table.name') }}:</h5>
          <span>{{ item.branding.name }}</span>
        </td>
        <td>
          <h5>{{ $t('offers.offers_table.program') }}:</h5>
          <span>{{ item.partner_id }}</span>
        </td>
        <td v-if="exclude !== 'business'">
          <h5>{{ $t('offers.offers_table.business') }}:</h5>
          <router-link v-if="item.owner_business_id" :to="{ name: 'business_details', params: { businessId: item.owner_business_id } }">
            {{ `${item.owner_business_name} (${item.owner_business_id})` }}
          </router-link>
          <label-none v-else>{{ $t('system.na') }}</label-none>
        </td>
        <td v-if="exclude !== 'publishers'" align="center">
          <h5>{{ $t('offers.offers_table.publishers') }}:</h5>
          <span>{{ item.publishers_count }}</span>
        </td>
        <td align="right">
          <h5>{{ $t('offers.offers_table.incentive') }}:</h5>
          <span v-if="item.incentive && item.type === 'bonus'">+ {{ item.incentive.bonus_percent * 100 }}%</span>
          <span v-if="item.incentive && item.type === 'gift_certificate'"
            >{{ item.incentive.payment_amount | dollarSignI18n }} - {{ item.incentive.bonus_amount | dollarSignI18n }}</span
          >
        </td>
        <td align="right">
          <h5>{{ $t('offers.offers_table.period') }}:</h5>
          <span>{{ item.availability.start_date | formatShortDateInverted }} - {{ item.availability.end_date | formatShortDateInverted }}</span>
        </td>
        <td v-if="exclude !== 'inventory'" align="right">
          <h5>{{ $t('offers.offers_table.inventory.title') }}:</h5>
          <span v-if="item.availability && item.availability.inventory && item.availability.inventory_id">
            {{ item.availability.inventory.available_amount | currency | dollarSignI18n }}
          </span>
          <span v-else-if="item.availability && item.availability.inventory">
            {{ $t('offers.offers_table.inventory.unlimited') }}
          </span>
        </td>
        <td>
          <h5>{{ $t('offers.offers_table.visibility') }}:</h5>
          <span :class="`offers-table-visibility-private-${item.features.private}`">{{ displayVisibility(item.features.private) }}</span>
        </td>
        <td>
          <h5>{{ $t('offers.offers_table.status') }}:</h5>
          <span><offer-status-view slot="labelTextLink" :status="item.system_status.status"/></span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import OfferStatusView from '../offers/OfferStatusView';

export default {
  name: 'shared-offers-table',
  mixins: [actionErrorTrackable, security],
  components: { OfferStatusView },
  props: {
    items: {
      type: Array,
      required: true,
    },
    exclude: {
      type: String, // Acceptable values: inventory, publishers, business, branding
      required: false,
    },
  },
  methods: {
    typesTranslated(type) {
      return this.$t(`branding.types.${type}`);
    },
    displayVisibility(visibility) {
      return visibility === true ? this.$t('offers.offers_table.visibility_type.private') : this.$t('offers.offers_table.visibility_type.public');
    },
    determineOfferId(item) {
      return item.offer_id ? item.offer_id : item.id;
    },
  },
};
</script>
