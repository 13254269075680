import formatCurrency from 'vue-currency-filter';
import vkbeautify from 'vkbeautify';

export default ({ Vue, app }) => {
  Vue.use(formatCurrency, {
    symbol: '',
    thousandsSeparator: ',',
    fractionCount: 2,
    fractionSeparator: '.',
    symbolPosition: 'front',
    symbolSpacing: false,
  });

  Vue.filter('formatXmlDoc', (xmlDocument) => {
    return vkbeautify.xml(xmlDocument);
  });

  Vue.filter('formatJsonDoc', (jsonDocument) => {
    return vkbeautify.json(jsonDocument);
  });

  Vue.filter('dollarSignI18n', (amount) => {
    const currentLocale = app.i18n.locale;
    if (currentLocale == 'fr') {
      return `${amount}$`;
    }
    if (currentLocale == 'en') {
      return `$${amount}`;
    }
  });

  Vue.filter('percentageToFloat', (percent, numDecimals) => {
    if (percent) {
      const divided = percent / 100;

      return parseFloat(divided.toFixed(numDecimals));
    }
  });

  Vue.filter('percentageFloatToInt', (percent, numDecimals) => {
    if (percent) {
      const divided = percent * 100;

      return parseFloat(divided.toFixed(numDecimals));
    }
  });

  Vue.filter('floatToPercentage', (percent, numDecimals) => {
    if (percent) {
      const divided = parseFloat(percent) * 100;
      return `${divided.toFixed(numDecimals)} %`;
    }
  });
};
