<template>
  <v-flex class="view-franchise-form">
    <h1 hidden>{{ $t('franchise.form.title') }}</h1>
    <v-card>
      <form @submit.prevent="submit" class="form-basic form-search">
        <v-layout align-center wrap class="form-search--main-wrap-v2">
          <v-flex class="form-search--inputs-zone-v2">
            <form-warning-search
              :autofocus="true"
              :searched="searched"
              :hint-message="$t('franchise.form.total_results_message')"
              class="franchise--search-field"
              :label="$t('franchise.form.placeholder')"
              v-model="keyword"
              data-vv-name="search"
              v-validate="'max:50'"
              :max-length="50"
              :error-messages="errors.collect('search')"
            ></form-warning-search>
            <button-primary class="franchise--btn-search" :text="$t('franchise.form.submit')" :loading="working" @click="submit" type="submit" />
          </v-flex>

          <v-flex class="form-search--actions-zone-v2">
            <button-primary
              data-test="franchise--btn-add"
              id="franchise--btn-add"
              class="franchise-form--btn-add"
              :loading="working"
              :text="$t('franchise.form.add')"
              @click="openCreateFranchiseModal"
            ></button-primary>
          </v-flex>
        </v-layout>
      </form>
    </v-card>

    <v-flex class="data-section">
      <alert-warning v-if="dataReady && franchises.length === 0" :text="$t('franchise.no_franchise_found')"></alert-warning>
      <card v-if="dataReady && franchises.length > 0" icon="fal fa-sitemap" :title="`${$t('franchise.form.data_section.title')}`">
        <template slot="headerZoneRight">
          <label-text class="text-sm-center" :label="$t('franchise.form.data_section.franchises_count')" :value="listItemCount || '0'"></label-text>
        </template>
        <template slot="content">
          <franchises-table :items="franchises"></franchises-table>
        </template>
      </card>
    </v-flex>
    <modal-action :show-modal="showCreateFranchiseModal"
      ><create-franchise-action
        v-if="showCreateFranchiseModal"
        @close="closeCreateFranchiseModal"
        @created="createFranchiseSuccess"
      ></create-franchise-action
    ></modal-action>
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable, security } from '@/mixins';
import FranchisesTable from './FranchisesTable';
import CreateFranchiseAction from '../actions/CreateFranchiseAction';
import _ from 'lodash';
import Vue from 'vue';
import { ignoreCaseAndAccentsRegex } from '@/utils/string-utils';

export default {
  name: 'search-franchise-form',
  mixins: [actionErrorTrackable, security],
  components: { FranchisesTable, CreateFranchiseAction },
  $_veeValidate: {
    validator: 'new',
  },
  beforeDestroy() {
    this.executeAction({ action: 'franchise/clearSearchFranchises' });
  },
  data() {
    return {
      page: 1,
      dataReady: false,
      keyword: '',
      searched: '',
      showCreateFranchiseModal: false,
    };
  },
  async created() {
    this.page = this.listPageNumber;
    this.keyword = this.listKeyword;

    if (this.routeHasQueryParams()) {
      this.page = 1;
    }
    if (this.$route.query.keyword) {
      this.keyword = this.$route.query.keyword;
    }

    this.routeHasQueryParams() ? this.submit(null, false, this.page) : this.submit(null, true, this.page);
  },
  computed: {
    ...mapGetters('franchise', ['franchises', 'listKeyword', 'listPageNumber', 'listItemCount']),
  },
  methods: {
    async clear() {
      this.searched = '';
      this.keyword = '';
      this.$validator.reset();
    },
    routeHasQueryParams() {
      return this.$route.query.keyword || this.$route.query.business_id;
    },
    openCreateFranchiseModal() {
      this.showCreateFranchiseModal = true;
    },
    closeCreateFranchiseModal() {
      this.showCreateFranchiseModal = false;
    },
    buildPayloadQueryString() {
      const payload = {};
      if (this.keyword) {
        payload.keyword = this.keyword;
      }

      return payload;
    },
    async submit(event, forceSearch = false, page = 1) {
      let validationComplete;
      if (forceSearch !== true) {
        validationComplete = await this.$validator.validateAll();
      }

      if (validationComplete || forceSearch) {
        this.$router.replace({ query: this.buildPayloadQueryString() });
        await this.clearAllError();

        try {
          this.dataReady = false;
          const search = this.buildPayloadQueryString();
          search.page = page;
          await this.executeAction({ action: 'franchise/listFranchises' }, search);
        } finally {
          this.dataReady = true;
        }
      }
    },
    async createFranchiseSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'franchise.create_action.success' });
      this.closeCreateFranchiseModal();
    },
  },
};
</script>
