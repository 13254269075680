<template>
  <div>
    <v-layout row>
      <v-flex sm10 xs12>
        <v-layout class="message-card--header" row align-content-center fill-height>
          <v-avatar size="40" tile>
            <img class="message-card--avatar" :src="avatar" />
          </v-avatar>

          <v-layout class="message-card--message-info no-flex-grow" column>
            <slot name="author">
              <span class="message-card--author" v-if="author">{{ author }}</span>
            </slot>

            <span class="message-card--date" v-if="date">{{ date | formatDate }}</span>
          </v-layout>
        </v-layout>
      </v-flex>

      <v-flex sm2 xs12 style="text-align: right">
        <slot name="actions" />
      </v-flex>
    </v-layout>

    <slot name="content">
      <v-layout v-if="content" column v-html="generateHtml(content, 'p')"></v-layout>
    </slot>

    <slot name="divider"></slot>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'layout-message',
  props: {
    avatar: {
      type: String,
      default: () => require('@/assets/avatar.jpg'),
    },
    author: {
      type: String,
      required: false,
    },
    date: {
      type: [Date, String],
      required: false,
    },
    content: {
      type: String,
      required: false,
    },
  },

  methods: {
    generateHtml(note, tag) {
      let sentences = note.split('\n');
      return _.reduce(sentences, (acc, sentence) => `${acc}<${tag} class="break-word-wrap">${sentence}</${tag}>`, '');
    },
  },
};
</script>
