<template>
  <card :title="$t('store.details.notes_section.title')" icon="">
    <template slot="headerZoneRight">
      <label-text class="text-xs-center" :label="`${$t('store.details.notes_section.count')}`" :value="listStoreNotesItemCount || '0'"></label-text>
      <div v-if="showAddStoreNoteFeature()" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text v-if="showAddStoreNoteFeature()" :label="`${$t('store.details.notes_section.action')}`" value="3">
        <link-secondary-action slot="labelTextLink" @click="openAddStoreNoteModal">
          {{ $t('store.details.notes_section.create_note') }}
        </link-secondary-action>
      </label-text>
    </template>
    <template slot="content">
      <store-notes-table
        v-if="storeNotes.length"
        :items="storeNotes"
        :store-id="store.id"
        @store_note_delete_action="openDeleteStoreNoteModal"
      ></store-notes-table>
      <label-none v-if="storeNotes.length === 0">{{ $t('store.details.notes_section.no_found') }}</label-none>
      <modal-action :show-modal="showAddStoreNoteModal"
        ><create-store-note-action
          :v-if="showAddStoreNoteModal"
          :store-id="store.id"
          @close="closeAddStoreNoteModal"
          @created="addStoreNoteSuccess"
        ></create-store-note-action
      ></modal-action>
      <modal-action :show-modal="showDeleteStoreNoteModal"
        ><delete-store-note-action
          :v-if="showDeleteStoreNoteModal"
          :store-id="store.id"
          :note-id="noteIdToDelete"
          @close="closeDeleteStoreNoteModal"
          @deleted="deleteStoreNoteSuccess"
        ></delete-store-note-action
      ></modal-action>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import CreateStoreNoteAction from '../actions/CreateStoreNoteAction';
import DeleteStoreNoteAction from '../actions/DeleteStoreNoteAction';
import StoreNotesTable from './StoreNotesTable';
import { mapGetters } from 'vuex';

export default {
  name: 'store-notes',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { StoreNotesTable, CreateStoreNoteAction, DeleteStoreNoteAction },
  data() {
    return {
      showAddStoreNoteModal: false,
      showDeleteStoreNoteModal: false,
      noteIdToDelete: null,
      noteToDelete: {},
    };
  },
  computed: {
    ...mapGetters('store', ['storeNotes', 'store', 'listStoreNotesItemCount']),
    ...mapGetters('ui', ['currentLocale']),
  },
  watch: {
    async store() {
      await this.fetchStoreNotes();
    },
  },
  methods: {
    openAddStoreNoteModal() {
      this.showAddStoreNoteModal = true;
    },
    closeAddStoreNoteModal() {
      this.showAddStoreNoteModal = false;
    },
    openDeleteStoreNoteModal(noteId) {
      this.noteIdToDelete = noteId;
      this.noteToDelete = this.storeNotes.find((l) => l.id === noteId);
      this.showDeleteStoreNoteModal = true;
    },
    closeDeleteStoreNoteModal() {
      this.noteIdToDelete = null;
      this.noteToDelete = {};
      this.showDeleteStoreNoteModal = false;
    },
    async deleteStoreNoteSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'store.delete_note_action.success' });
      this.closeDeleteStoreNoteModal();
    },
    async addStoreNoteSuccess() {
      await this.fetchStoreNotes();
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'store.create_note_action.success' });
      this.closeAddStoreNoteModal();
    },
    showAddStoreNoteFeature() {
      return this.canManageOffer(); // TODO change permission
    },
    async fetchStoreNotes() {
      await this.executeAction({ action: 'store/listStoreNotes' }, { store_id: this.store.id });
    },
  },
};
</script>
