<template>
  <card-modal
    icon="e"
    :title="$t('card.regenerate_card_action.title')"
    context="regenerateCard"
    @submit="submit"
    @cancel="cancel"
    cancel_text="card.regenerate_card_action.button_cancel"
    submit_text="card.regenerate_card_action.button_save"
    data-test="regenerate-card-modal"
  >
    <template slot="content">
      <form @submit.prevent="submit">
        <v-layout column>
          <v-layout row>
            <v-flex sm3 xs12>
              <label-text :label="$t(`card.regenerate_card_action.card_product_id`)" :value="partnerCardRenewPackage.card_product_id"></label-text>
            </v-flex>
            <v-flex sm3 xs12>
              <label-text :label="$t(`card.regenerate_card_action.card_package_id`)" :value="partnerCardRenewPackage.id"></label-text>
            </v-flex>
            <v-flex sm3 xs12>
              <v-text-field
                name="card_pin"
                v-model="card_pin"
                v-validate="'required|numeric|length:4'"
                mask="####"
                :label="$t(`card.regenerate_card_action.pin`) + ' *'"
                :error-messages="errors.collect('card_pin')"
                data-vv-name="card_pin"
                :data-vv-as="$t(`card.regenerate_card_action.pin_error_label`)"
                required
                counter
              ></v-text-field>
            </v-flex>
            <v-flex sm3 xs12>
              <button-primary
                name="random_pin"
                :text="$t(`card.regenerate_card_action.random_pin`)"
                @click="generateRandomPin"
                data-test="regenerate-card-random-pin-button"
              ></button-primary>
            </v-flex>
          </v-layout>
        </v-layout>

        <v-layout column>
          <v-text-field
            name="street"
            v-model="address.street"
            v-validate="'required|max:50'"
            :label="$t(`card.regenerate_card_action.address.street`) + ' *'"
            :error-messages="errors.collect('street')"
            data-vv-name="street"
            :data-vv-as="$t(`card.regenerate_card_action.address.street`)"
            required
            counter
            maxlength="50"
          ></v-text-field>

          <v-text-field
            name="city"
            v-model="address.city"
            v-validate="'required|max:50'"
            :label="$t(`card.regenerate_card_action.address.city`) + ' *'"
            :error-messages="errors.collect('city')"
            data-vv-name="city"
            :data-vv-as="$t(`card.regenerate_card_action.address.city`)"
            required
            counter
            maxlength="50"
          ></v-text-field>

          <v-layout row wrap pad-form-row-elements>
            <v-flex sm4 xs12>
              <v-select
                name="state"
                v-model="address.state"
                v-validate="'required'"
                :error-messages="errors.collect('state')"
                data-vv-name="state"
                :data-vv-as="$t(`card.regenerate_card_action.address.state`)"
                :items="stateOptions"
                item-text="name"
                item-value="abbr"
                :label="$t(`card.regenerate_card_action.address.state`) + ' *'"
              ></v-select>
            </v-flex>
            <v-flex sm4 xs12>
              <v-select
                name="country"
                v-validate="'required'"
                v-model="address.country"
                :error-messages="errors.collect('country')"
                data-vv-name="country"
                :data-vv-as="$t(`card.regenerate_card_action.address.country`)"
                :items="countryOptions"
                item-text="name"
                item-value="abbr"
                :label="$t(`card.regenerate_card_action.address.country`) + ' *'"
              ></v-select>
            </v-flex>
          </v-layout>

          <v-layout row wrap pad-form-row-elements>
            <v-flex sm4 xs12>
              <v-text-field
                name="zip"
                v-mask-zip
                v-validate="{ required: true, regex: /^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z] [0-9][ABCEGHJ-NPRSTV-Z][0-9]$/ }"
                v-model="address.zip"
                :label="$t(`card.regenerate_card_action.address.zip`) + ' *'"
                :error-messages="errors.collect('zip')"
                data-vv-name="zip"
                :data-vv-as="$t(`card.regenerate_card_action.address.zip`)"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-layout>
      </form>

      <modal-confirm
        :show-modal="showConfirmModal"
        :title="$t('card.regenerate_card_confirmation.title')"
        context="RegenerateCardConfirmationModal"
        :text="$t('card.regenerate_card_confirmation.text')"
        cancel_text="card.regenerate_card_confirmation.cancel_text"
        submit_text="card.regenerate_card_confirmation.submit_text"
        :submit_working="working"
        @submit="regenerateCard()"
        @close="showConfirmModal = false"
        class="smoke-regenerate--confirm-modal"
      ></modal-confirm>
    </template>
  </card-modal>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import ModalConfirm from '../../system/Modals/ModalConfirm';

export default {
  name: 'regenerate-card-action',
  components: { ModalConfirm },
  mixins: [actionErrorTrackable, security],
  props: {
    cardholderLanguage: {
      type: String,
      required: true,
    },
    partnerId: {
      type: String,
      required: true,
    },
    helloShow: {
      type: Boolean,
      required: true,
    },
    card_program_type: {
      type: String,
      required: true,
    },
    publicToken: {
      type: String,
      required: true,
    },
    cardholderAddress: {
      type: Object,
      required: true,
    },
  },
  watch: {
    async helloShow(show) {
      if (!show) {
        this.clear();
      } else {
        await this.refreshPartnerCardRenewPackage();
      }
    },
  },
  data() {
    return {
      card_pin: '',
      showConfirmModal: false,
      partnerCardRenewPackage: {
        id: null,
        card_product_id: null,
      },
      address: {
        street: this.cardholderAddress.street,
        city: this.cardholderAddress.city,
        state: this.cardholderAddress.state,
        country: this.cardholderAddress.country,
        zip: this.cardholderAddress.zip,
      },
      countryOptions: [{ name: this.$t('country.canada'), abbr: 'CA' }],
      stateOptions: [
        { name: this.$t('state.alberta'), abbr: 'AB' },
        { name: this.$t('state.british_columbia'), abbr: 'BC' },
        { name: this.$t('state.manitoba'), abbr: 'MB' },
        { name: this.$t('state.new_brunswick'), abbr: 'NB' },
        { name: this.$t('state.newfoundland_labrador'), abbr: 'NL' },
        { name: this.$t('state.nova_scotia'), abbr: 'NS' },
        { name: this.$t('state.northwest_territories'), abbr: 'NT' },
        { name: this.$t('state.nunavut'), abbr: 'NU' },
        { name: this.$t('state.ontario'), abbr: 'ON' },
        { name: this.$t('state.prince_edward_island'), abbr: 'PE' },
        { name: this.$t('state.quebec'), abbr: 'QC' },
        { name: this.$t('state.saskatchewan'), abbr: 'SK' },
        { name: this.$t('state.yukon'), abbr: 'YT' },
      ],
    };
  },
  methods: {
    clear() {
      this.card_pin = '';
      this.partnerCardRenewPackage = {
        id: null,
        card_product_id: null,
      };
      this.address = {
        street: this.cardholderAddress.street,
        city: this.cardholderAddress.city,
        state: this.cardholderAddress.state,
        country: this.cardholderAddress.country,
        zip: this.cardholderAddress.zip,
      };
      this.$validator.reset();
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        this.showConfirmModal = true;
      }
    },
    async regenerateCard() {
      await this.executeAction(
        { action: 'card/regenerateCard', name: 'regenerateCard' },
        { token: this.publicToken, card_pin: this.card_pin, address: this.address },
      );
      this.clear();
      this.$emit('close');
    },
    generateRandomPin() {
      const newPin = Math.floor(Math.random() * (9999 - 1000)) + 1000;
      this.card_pin = String(newPin);
    },
    async refreshPartnerCardRenewPackage() {
      const partnerCardRenewPackage = await this.executeAction(
        { action: 'partner/getPartnerCardRenewPackage' },
        {
          card_program_type: this.card_program_type,
          partnerId: this.partnerId,
          cardholderLanguage: this.cardholderLanguage,
        },
      );

      if (!partnerCardRenewPackage) {
        this.partnerCardRenewPackage = { id: 'error', card_product_id: 'error' };
        return;
      }

      this.partnerCardRenewPackage = partnerCardRenewPackage;
    },
  },
};
</script>
