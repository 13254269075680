<template>
  <card-modal
    icon=""
    :title="$t('business.details.access_section.create_action.title')"
    context="addAccessToBusiness"
    @submit="submit"
    @cancel="cancel"
    cancel_text="business.details.access_section.create_action.cancel_text"
    submit_text="business.details.access_section.create_action.submit_text"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <modal-info-block :heading="$t('business.details.access_section.create_action.business')" :text="businessInfo"></modal-info-block>
      </v-layout>
      <v-layout column>
        <v-text-field
          :label="$t('business.details.access_section.create_action.email')"
          name="email"
          v-validate="{ required: true, email: true }"
          :error-messages="errors.collect('email')"
          v-model="email"
          data-vv-name="email"
          maxlength="50"
          :data-vv-as="$t('business.details.access_section.create_action.email')"
        ></v-text-field>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'add-report-access-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    businessObj: {
      type: Object,
    },
  },
  data() {
    return {
      email: null,
    };
  },
  computed: {
    ...mapGetters('publishers', ['publishers']),
    ...mapGetters('business', ['businessAccess']),
    businessInfo() {
      return `${this.businessObj.name} (${this.businessObj.id})`;
    },
  },
  methods: {
    clear() {
      this.email = null;
      this.$validator.reset();
    },
    success() {
      this.clear();
      this.$emit('created');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async fetchBusinessAccesses() {
      await this.executeAction({ action: 'business/listReportAccesses' }, { business_id: this.businessObj.id });
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        const payload = { business_id: this.businessObj.id, email: this.email };

        await this.executeAction(
          {
            action: 'business/addReportAccess',
            name: 'addAccessToBusiness',
            success: this.success,
          },
          payload,
        );
      }
    },
  },
};
</script>
