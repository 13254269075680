<template>
  <card :title="$t('business.details.contributor_offers_section.title')" icon="">
    <template slot="headerZoneRight">
      <label-text
        class="text-xs-center"
        :label="`${$t('business.details.contributor_offers_section.count')}`"
        :value="businessOffersItemCount || '0'"
      ></label-text>
    </template>
    <template slot="content">
      <shared-offers-table v-if="businessOffers.length" :items="businessOffers" exclude="business" />
      <div v-if="businessOffers.length" class="text-xs-center">
        <v-pagination v-if="businessOffersPageCount > 1" v-model="pagination" :length="businessOffersPageCount"></v-pagination>
      </div>
      <label-none v-if="!businessOffers.length">{{ $t('system.none') }}</label-none>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import SharedOffersTable from '@/components/shared_tables/SharedOffersTable';
import { mapGetters } from 'vuex';

export default {
  name: 'business-contributor-offers',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { SharedOffersTable },
  data() {
    return {
      pagination: 1,
    };
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  async created() {
    return this.fetchBusinessOffers();
  },
  watch: {
    async business() {
      return this.fetchBusinessOffers();
    },
    businessOffersPageNumber() {
      this.pagination = this.businessOffersPageNumber;
    },
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'business/listBusinessOffers',
          success: this.success,
        },
        {
          id: this.businessId,
          page: newValue,
        },
      );
    },
  },
  computed: {
    ...mapGetters('business', ['business', 'businessOffers', 'businessOffersPageCount', 'businessOffersPageNumber', 'businessOffersItemCount']),
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    async clear() {
      this.page = 1;
      this.$validator.reset();
    },
    async fetchBusinessOffers() {
      return this.executeAction({ action: 'business/listBusinessOffers' }, { id: this.businessId, page: 1 });
    },
  },
};
</script>
