import xpath from 'xpath';

const selectWithNamespace = xpath.useNamespaces({
  EfxToCust: 'http://www.equifax.ca/XMLSchemas/EfxToCust',
});

export default (xml) => {
  let obj = {};
  let inputObj = {};
  let consumerReportObj = {};
  let singleSourceObj = {};
  let dualSourceObj = {};

  const hasMoreThanJustAHeader = selectWithNamespace("//EfxToCust:CSItem[@value='Single']", xml)[0];

  createInputObject();

  if (hasMoreThanJustAHeader !== undefined) {
    createConsumerReportObject();
  }
  createSingleSourceObject();
  createDualSourceObject();

  function createInputObject() {
    const NameInput = valueOfNode(selectWithNamespace("//EfxToCust:CSItem[@name='InputName']", xml)[0]);
    const DateOfBirthInput = valueOfNode(selectWithNamespace("//EfxToCust:CSItem[@name='InputDateOfBirth']", xml)[0]);
    const AddressInput = valueOfNode(selectWithNamespace("//EfxToCust:CSItem[@name='InputAddress']", xml)[0]);
    const AddressInputCleaned = removeLineBreaks(AddressInput);

    inputObj.name = NameInput;
    inputObj.date_of_birth = DateOfBirthInput;
    inputObj.address = AddressInputCleaned;
  }

  function createConsumerReportObject() {
    const FirstName = getNodeValue('FirstName');
    const MiddleName = getNodeValue('MiddleName');
    const LastName = getNodeValue('LastName');
    const DateOfBirth = getNodeValue('DateOfBirth');
    const UniqueNumber = getNodeValue('UniqueNumber');
    const FileSinceDate = getNodeValue('FileSinceDate');
    const AddressArray = xml.getElementsByTagName('CNAddress');
    const OtherNamesArray = xml.getElementsByTagName('CNOtherName');
    let Addresses = [];
    let OtherNames = [];

    for (let i = 0; i < AddressArray.length; i++) {
      const AddressObject = {};
      AddressObject.title = AddressArray[i].getAttribute('description');
      AddressObject.date_reported = valueOfNode(AddressArray[i].getElementsByTagName('DateReported')[0]);
      AddressObject.civic_number = valueOfNode(AddressArray[i].getElementsByTagName('CivicNumber')[0]);
      AddressObject.street_name = valueOfNode(AddressArray[i].getElementsByTagName('StreetName')[0]);
      AddressObject.city = valueOfNode(AddressArray[i].getElementsByTagName('City')[0]);
      AddressObject.province = AddressArray[i].getElementsByTagName('Province')[0].getAttribute('code');
      AddressObject.postal_code = valueOfNode(AddressArray[i].getElementsByTagName('PostalCode')[0]);
      Addresses.push(AddressObject);
    }

    for (let i = 0; i < OtherNamesArray.length; i++) {
      const OtherNamesObject = {};
      OtherNamesObject.first_name = valueOfNode(OtherNamesArray[i].getElementsByTagName('FirstName')[0]);
      OtherNamesObject.last_name = valueOfNode(OtherNamesArray[i].getElementsByTagName('LastName')[0]);
      OtherNames.push(OtherNamesObject);
    }

    consumerReportObj.unique_number = UniqueNumber;
    consumerReportObj.file_since_date = FileSinceDate;
    consumerReportObj.first_name = FirstName;
    consumerReportObj.middle_name = MiddleName;
    consumerReportObj.last_name = LastName;
    consumerReportObj.date_of_birth = DateOfBirth;
    consumerReportObj.addresses = Addresses;
    consumerReportObj.also_known_as = OtherNames;
  }

  function createSingleSourceObject() {
    const SingleSourceHit = valueOfNode(selectWithNamespace("//EfxToCust:CSItem[@name='SingleSourceHit']", xml)[0]);
    const SingleSourceDecision = valueOfNode(selectWithNamespace("//EfxToCust:CSItem[@name='SingleSourceDecision']", xml)[0]);
    const SingleSourceMatchTagBlock = selectWithNamespace("//EfxToCust:CSItem[@value='Single']", xml)[0];

    if (SingleSourceMatchTagBlock) {
      const SingleSourceMatchTags = SingleSourceMatchTagBlock.getElementsByTagName('CSItem');
      let MissingMatches = [];
      let PartialMatches = [];
      let FullMatches = [];

      for (let i = 0; i < SingleSourceMatchTags.length; i++) {
        if (SingleSourceMatchTags[i].firstChild.nodeValue === 'N') {
          MissingMatches.push(SingleSourceMatchTags[i].getAttribute('name'));
        } else if (SingleSourceMatchTags[i].firstChild.nodeValue === 'P') {
          PartialMatches.push(SingleSourceMatchTags[i].getAttribute('name'));
        } else if (SingleSourceMatchTags[i].firstChild.nodeValue === 'Y') {
          FullMatches.push(SingleSourceMatchTags[i].getAttribute('name'));
        }
      }

      singleSourceObj.missing_matches = MissingMatches;
      singleSourceObj.partial_matches = PartialMatches;
      singleSourceObj.full_matches = FullMatches;
    }

    singleSourceObj.decision = SingleSourceDecision;
    singleSourceObj.hit = SingleSourceHit;
  }

  function createDualSourceObject() {
    const DualSourceHit = valueOfNode(selectWithNamespace("//EfxToCust:CSItem[@name='DualSourceHit']", xml)[0]);
    const DualSourceDecisionTag = selectWithNamespace("//EfxToCust:CSItem[@name='DualSourceDecision']", xml)[0];
    let DualSourceMetaFields = {};
    let DualSources = [];

    dualSourceObj.decision = valueOfNode(DualSourceDecisionTag);

    if (DualSourceDecisionTag !== undefined) {
      createSourceObjects();
    }

    createMetaFields();

    function createMetaFields() {
      if (singleSourceObj.decision === 'Y' || singleSourceObj.decision === '<NO VALUE>') {
        DualSourceMetaFields.decision_label = 'na';
      } else if (dualSourceObj.decision === 'Y') {
        DualSourceMetaFields.decision_label = 'yes';
      } else if (dualSourceObj.decision === 'N') {
        DualSourceMetaFields.decision_label = 'no';
      }
    }

    function createSourceObjects() {
      const DualSourceMatchTagBlock = selectWithNamespace("//EfxToCust:CSItem[@name='Trade']", xml);
      let InstitutionName = null;
      let FamilyName = null;

      for (let i = 0; i < DualSourceMatchTagBlock.length; i++) {
        const DualSourceMatchTags = DualSourceMatchTagBlock[i].getElementsByTagName('CSItem');
        let DualSource = {};
        DualSource.meta = {};
        let MetaMatches = [];

        for (let i = 0; i < DualSourceMatchTags.length; i++) {
          switch (DualSourceMatchTags[i].getAttribute('name')) {
            case 'NameAndDOBMatch':
              DualSource.name_and_dob_match = valueOfNode(DualSourceMatchTags[i]);
              if (DualSource.name_and_dob_match === 'Y') {
                MetaMatches.push('name_and_dob_match');
              }
              break;
            case 'NameAndAddressMatch':
              DualSource.name_and_address_match = valueOfNode(DualSourceMatchTags[i]);
              if (DualSource.name_and_address_match === 'Y') {
                MetaMatches.push('name_and_address_match');
              }
              break;
            case 'InstitutionName':
              InstitutionName = valueOfNode(DualSourceMatchTags[i]);
              break;
            case 'FamilyName':
              FamilyName = valueOfNode(DualSourceMatchTags[i]);
              FamilyName = removeTrailingCommas(FamilyName);
              break;
            case 'AccountNumber':
              DualSource.account_number = valueOfNode(DualSourceMatchTags[i]);
              break;
            case 'NameAsReported':
              {
                let nameAsReported = valueOfNode(DualSourceMatchTags[i]);
                nameAsReported = removeTrailingCommas(nameAsReported);
                DualSource.name_as_reported = nameAsReported;
              }
              break;
            case 'DateOfBirthAsReported':
              DualSource.date_of_birth_as_reported = valueOfNode(DualSourceMatchTags[i]);
              break;
            case 'AddressAsReported':
              {
                let addressAsReported = valueOfNode(DualSourceMatchTags[i]);
                addressAsReported = removeTrailingCommas(addressAsReported);
                DualSource.address_as_reported = addressAsReported;
              }
              break;
          }

          if (InstitutionName !== null && FamilyName !== null) {
            DualSource.institution_name = `${InstitutionName} (${FamilyName})`;
          } else if (InstitutionName !== null) {
            DualSource.institution_name = InstitutionName;
          }
        }
        DualSource.meta.matches = MetaMatches;
        DualSources.push(DualSource);
      }
    }

    dualSourceObj.sources = DualSources;
    dualSourceObj.hit = DualSourceHit;
    dualSourceObj.meta = DualSourceMetaFields;
  }

  obj.input = inputObj;
  obj.consumer_report = consumerReportObj;
  obj.single_source = singleSourceObj;
  obj.dual_source = dualSourceObj;

  function getNodeValue(tagName) {
    return valueOfNode(xml.getElementsByTagName(tagName)[0]);
  }

  function valueOfNode(tag) {
    if (!tag || !tag.firstChild) {
      return '<NO VALUE>';
    }
    return removeLineBreaks(tag.firstChild.nodeValue);
  }

  return obj;
};

function removeTrailingCommas(value) {
  return value.replace(/,+$/, '');
}

function removeLineBreaks(value) {
  return value.replace(/\n */g, ',');
}
