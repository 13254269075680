<template>
  <div>
    <table class="responsive-table">
      <thead>
        <tr>
          <td>{{ $t('offers.offers_table.id') }}</td>
          <td>{{ $t('offers.offers_table.reference_id') }}</td>
          <td>{{ $t('offers.offers_table.name') }}</td>
          <td>{{ $t('offers.offers_table.program') }}</td>
          <td align="center">{{ $t('offers.offers_table.publishers') }}</td>
          <td>{{ $t('offers.offers_table.incentive') }}</td>
          <td>{{ $t('offers.offers_table.period') }}</td>
          <td>{{ $t('offers.offers_table.inventory.title') }}</td>
          <td>{{ $t('offers.offers_table.visibility') }}</td>
          <td>{{ $t('offers.offers_table.status') }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>
            <h5>{{ $t('offers.offers_table.id') }}:</h5>
            <span>
              <router-link v-if="canViewOffer()" :to="{ name: 'offers_details', params: { offerId: item.id } }">
                {{ item.id }}
              </router-link>
              <span v-else>{{ item.id }}</span>
            </span>
          </td>
          <td>
            <h5>{{ $t('offers.offers_table.reference_id') }}:</h5>
            <span v-if="item.reference_id">{{ item.reference_id }}</span>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('offers.offers_table.name') }}:</h5>
            <span>{{ item.branding.name }}</span>
          </td>
          <td>
            <h5>{{ $t('offers.offers_table.program') }}:</h5>
            <span>{{ item.partner_id }}</span>
          </td>
          <td align="center">
            <h5>{{ $t('offers.offers_table.publishers') }}:</h5>
            <span>{{ item.publishers_count }}</span>
          </td>
          <td align="right">
            <h5>{{ $t('offers.offers_table.incentive') }}:</h5>
            <span v-if="item.incentive && item.type === 'bonus'">+ {{ item.incentive.bonus_percent }}%</span>
            <span v-if="item.incentive && item.type === 'gift_certificate'"
              >{{ item.incentive.payment_amount | dollarSignI18n }} - {{ item.incentive.bonus_amount | dollarSignI18n }}</span
            >
          </td>
          <td align="right">
            <h5>{{ $t('offers.offers_table.period') }}:</h5>
            <span>{{ item.availability.start_date | formatShortDateInverted }} - {{ item.availability.end_date | formatShortDateInverted }}</span>
          </td>
          <td align="right">
            <h5>{{ $t('offers.offers_table.inventory.title') }}:</h5>
            <span v-if="item.availability && item.availability.inventory && item.availability.inventory_id">
              {{ item.availability.inventory.available_amount | currency | dollarSignI18n }}
            </span>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('offers.offers_table.visibility') }}:</h5>
            <span :class="`offers-table-visibility-private-${item.features.private}`">{{ displayVisibility(item.features.private) }}</span>
          </td>
          <td>
            <h5>{{ $t('offers.offers_table.status') }}:</h5>
            <span><offer-status-view slot="labelTextLink" :status="item.system_status.status"/></span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-xs-center">
      <v-pagination v-if="listPageCount > 1" v-model="pagination" :length="listPageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import { mapGetters } from 'vuex';
import OfferStatusView from '../OfferStatusView';

export default {
  name: 'the-offers-table',
  mixins: [actionErrorTrackable, security],
  components: { OfferStatusView },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      pagination: 1,
    };
  },
  mounted() {
    this.pagination = this.listPageNumber;
  },
  computed: {
    ...mapGetters('offers', ['listPageCount', 'listPageNumber', 'listPartnerId', 'listOwnerBusinessId', 'listStatus', 'listType', 'listVisibility']),
  },
  methods: {
    typesTranslated(type) {
      return this.$t(`branding.types.${type}`);
    },
    displayVisibility(visibility) {
      return visibility === true ? this.$t('offers.offers_table.visibility_type.private') : this.$t('offers.offers_table.visibility_type.public');
    },
  },
  watch: {
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'offers/listOffers',
        },
        {
          page: newValue,
          keyword: this.listKeyword,
          partner_id: this.listPartnerId,
          owner_business_id: this.listOwnerBusinessId,
          status: this.listStatus,
          type: this.listType,
          corporate_enabled: this.$route.query.corporate_enabled,
          consumer_enabled: this.$route.query.consumer_enabled,
          visibility: this.listVisibility,
        },
      );
    },
  },
};
</script>
