<template>
  <view-main title="page_title.vouchers_operations">
    <v-flex slot="content" class="data-section">
      <h1 hidden>{{ $t('dashboards.vouchers_operations') }}</h1>
      <dashboard
        id-name="vouchers_operations_report"
        sharing-token="8fcdd2ec-13a8-445d-9afe-e6137776c8ec"
        report-id="94zr-3dvk-qz8j"
        url-report-segment="5j3y-dk2v-kpo9"
        :params="params"
        :timerange="timerange"
      />
    </v-flex>
  </view-main>
</template>

<script>
import { renderErrorTrackable } from '@/mixins';
import Dashboard from './Dashboard.vue';

export default {
  name: 'vouchers-operations-report-view',
  mixins: [renderErrorTrackable],
  components: { Dashboard },
  data() {
    return {
      timerange: null,
      user_id: null,
    };
  },
  created() {
    if (this.$route.query.timerange) {
      this.timerange = this.$route.query.timerange;
    }

    if (this.$route.query.user_id) {
      this.user_id = this.$route.query.user_id;
    }
  },
  computed: {
    params() {
      const theParams = {};

      if (this.user_id) {
        theParams.user_id = this.user_id;
      }

      return theParams;
    },
  },
};
</script>
