<template>
  <card :title="$t('wallet_definition.info.title')" icon="fal fa-envelope-open-dollar" class="wallet-definition">
    <template slot="headerZoneRight">
      <label-text :label="$t('wallet_definition.info.id')" :value="walletDefinition.id">
        <p slot="labelTextLink" class="label-text--value">
          <v-layout row>
            {{ walletDefinition.id }}
            <button class="clipboard" v-clipboard:copy="walletDefinition.id ? walletDefinition.id : ''">
              <v-icon small>file_copy</v-icon>
            </button>
          </v-layout>
        </p>
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text v-if="walletDefinition.partner_id" :label="$t('wallet_definition.info.partner')" :value="walletDefinition.partner_id"></label-text>
      <div v-if="walletDefinition.partner_id" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="$t('wallet_definition.info.name')" :value="walletDefinition.name" data-test="wallet-definition-name-container"></label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text
        v-if="walletDefinition.type"
        :label="$t('wallet_definition.info.type')"
        :value="walletDefinition.type"
        data-test="wallet-definition-type-container"
      ></label-text>
      <div v-if="walletDefinition.type" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text
        data-test="wallet-definitions-table--external-id"
        :label="$t('wallet_definition.info.external_id')"
        :value="walletDefinition.wallet_definition_external_id"
      ></label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text
        class="text-sm-center"
        :label="$t('wallet_definition.info.wallets_count')"
        :value="walletDefinition.statistics.wallets_count || '0'"
      ></label-text>
      <label-text
        class="text-sm-right"
        :label="$t('wallet_definition.info.wallets_amount')"
        :value="walletDefinition.statistics.wallets_amount | currency | dollarSignI18n"
      />
      <div v-if="showActionBtn()" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <link-secondary-action v-if="showActionBtn()" slot="labelTextLink">
        <button-secondary-actions :text="$t(`system.actions`)" :actions="actions" data-test="wallet-definition-block-actions-button">
        </button-secondary-actions>
      </link-secondary-action>
    </template>
    <template slot="content">
      <div :class="{ 'wallet-definition-rules': true }">
        <modal-action :show-modal="showRenameWalletDefinitionModal">
          <rename-wallet-definition-action
            v-if="showRenameWalletDefinitionModal"
            :wallet-definition="walletDefinition"
            @close_rename_action="closeRenameWalletDefinitionModal"
            @wallet_definition_renamed="renameWalletDefinitionSuccess"
          ></rename-wallet-definition-action>
        </modal-action>
        <h4>{{ $t('wallet_definition.labels.title') }}</h4>
        <v-layout row>
          <v-flex xs3
            ><label-text :label="$t(`wallet_definition.labels.fr`)" :value="walletDefinition.label ? walletDefinition.label.fr : ''"></label-text
          ></v-flex>
          <v-flex xs3
            ><label-text :label="$t(`wallet_definition.labels.en`)" :value="walletDefinition.label ? walletDefinition.label.en : ''"></label-text
          ></v-flex>
        </v-layout>
      </div>
    </template>
  </card>
</template>

<script>
import RenameWalletDefinitionAction from '../actions/RenameWalletDefinitionAction';
import { security, disableWithError, actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'wallet-definition-information',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { RenameWalletDefinitionAction },
  props: {
    walletDefinitionId: {
      type: String,
      required: true,
    },
  },
  data() {
    const $t = this.$t.bind(this);
    return {
      showRenameWalletDefinitionModal: false,
      actions: [
        {
          slotName: 'renameWalletDefinition',
          name: $t('wallet_definition.info.button_rename_wallet_definition'),
          action: this.openRenameWalletDefinitionModal,
          isAvailable: () => this.canManageWalletDefinition() && !this.walletDefinition.system_managed,
        },
      ],
    };
  },
  computed: {
    ...mapGetters('walletDefinition', ['walletDefinition']),
  },
  methods: {
    openRenameWalletDefinitionModal() {
      this.showRenameWalletDefinitionModal = true;
    },
    closeRenameWalletDefinitionModal() {
      this.showRenameWalletDefinitionModal = false;
    },
    showActionBtn() {
      return this.canManageWalletDefinition() && !this.walletDefinition.system_managed;
    },
    async renameWalletDefinitionSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'wallet_definition.rename_wallet_definition_action.success' });
      this.closeRenameWalletDefinitionModal();
    },
  },
};
</script>
