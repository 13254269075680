<template>
  <div v-if="currentCardExists()">
    <h3>{{ $t(`task.list.card.title`) }}</h3>
    <v-layout row wrap align-center justify-start class="data-card--content-zone">
      <v-flex xs12 class="d-inline-flex data-card--content-zone__upper">
        <label-text :label="$t(`task.list.card.public_token`)">
          <p slot="labelTextLink" class="label-text--value">
            <v-layout row>
              <link-secondary-route
                :can-access="canViewCard()"
                :route-name="'card'"
                :route-params="{ cardPublicToken: publicToken }"
                target="blank"
                data-test="card-public-token"
                >{{ publicToken }}</link-secondary-route
              >
            </v-layout>
          </p>
        </label-text>
        <label-text :label="$t(`task.list.card.date_issued`)" :value="currentCard.date_issued | formatDateHourMin" />
        <label-card-status :status="currentCard.status" />
        <label-text :label="$t(`task.list.card.cardholder.first_name`)" :value="currentCard.cardholder.first_name" data-test="card-first-name" />
        <label-text :label="$t(`task.list.card.cardholder.last_name`)" :value="currentCard.cardholder.last_name" data-test="card-last-name" />
        <label-text :label="$t(`task.list.card.cardholder.birth_date`)" :value="currentCard.cardholder.date_of_birth" data-test="card-birthdate" />
        <label-text :label="$t(`task.list.card.cardholder.card_application_id`)">
          <p slot="labelTextLink" class="label-text--value">
            <v-layout row>
              <link-secondary-route
                :can-access="canViewApplication()"
                :route-name="'application'"
                :route-params="{ applicationId: currentCard.cardholder.card_application_id }"
                target="blank"
              >
                {{ currentCard.cardholder.card_application_id }}
              </link-secondary-route>
            </v-layout>
          </p>
        </label-text>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';

export default {
  name: 'card-list-item-part',
  mixins: [actionErrorTrackable, security],
  props: {
    publicToken: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentCard: {},
    };
  },
  async mounted() {
    this.currentCard = await this.executeAction({ action: 'task/getCard' }, this.publicToken);
  },
  methods: {
    currentCardExists() {
      return !!this.currentCard.public_token;
    },
  },
};
</script>
