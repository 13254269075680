<template>
  <label-text
    :label="label || $t('code.card.status_title')"
    :class-array="augmentedClassArray"
    :value="$t(`code.card.status.${status}`)"
  ></label-text>
</template>

<script>
export default {
  name: 'label-card-status',
  props: {
    status: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    classArray: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    augmentedClassArray: function() {
      return [`status-${this.status}`].concat(this.classArray);
    },
  },
};
</script>
