<template>
  <card-modal
    icon=""
    :title="$t('activation_code_batch.details.actions.downloadInvoice.language_modal.title')"
    context="downloadActivationCodesCorporateOrderInvoice"
    @submit="submit"
    @cancel="cancel"
    cancel_text="activation_code_batch.details.actions.downloadInvoice.button_cancel"
    submit_text="activation_code_batch.details.actions.downloadInvoice.button_save"
    :submit_working="working"
    data-test="lang--chose-pdf-lang"
    :enable_submit="!!chosenLang"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-select
          name="chosenLang"
          v-model="chosenLang"
          v-validate="{ required: true }"
          :error-messages="errors.collect('chosenLang')"
          data-vv-name="chosenLang"
          :data-vv-as="$t(`activation_code_batch.details.actions.downloadInvoice.language_modal.languageSelectedField`)"
          :items="availableLanguages"
          item-text="label"
          item-value="lang"
          :label="$t(`activation_code_batch.details.actions.downloadInvoice.language_modal.languageSelectedField`)"
          class="smoke--chosenLang"
        ></v-select>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'download-corporate-order-invoice',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    activationCodeCorporateOrderId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
  },
  data() {
    return {
      chosenLang: 'en',
      availableLanguages: [
        { lang: 'en', label: this.$t('activation_code_batch.details.actions.downloadInvoice.language_modal.options.en') },
        { lang: 'fr', label: this.$t('activation_code_batch.details.actions.downloadInvoice.language_modal.options.fr') },
      ],
    };
  },
  methods: {
    clear() {
      this.chosenLang = 'en';
      this.$validator.reset();
    },
    success(lang) {
      this.clear();
      this.$emit('action', lang);
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        const res = await this.executeAction(
          { action: 'activationCodeBatch/downloadActivationCodesCorporateOrderInvoice', name: 'downloadActivationCodesCorporateOrderInvoice' },
          {
            language: this.chosenLang,
            id: this.activationCodeCorporateOrderId,
          },
        );

        if (!res.errorType) {
          this.success();
        }
      }
    },
  },
};
</script>
