<template>
  <div>
    <shared-stores-table :items="items" :exclude="['destination', 'action']" />
    <div class="text-xs-center">
      <v-pagination v-if="destinationStoresPageCount > 1" v-model="pagination" :length="destinationStoresPageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import { mapGetters } from 'vuex';
import SharedStoresTable from '@/components/shared_tables/SharedStoresTable';

export default {
  name: 'destination-stores-table',
  mixins: [actionErrorTrackable, security],
  components: { SharedStoresTable },
  props: {
    items: {
      type: Array,
      required: true,
    },
    destinationId: {
      type: String,
    },
  },
  data() {
    return {
      pagination: 1,
    };
  },
  mounted() {
    this.pagination = this.destinationStoresPageNumber;
  },
  watch: {
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'destination/listDestinationStores',
        },
        {
          id: this.destinationId,
          page: newValue,
        },
      );
    },
  },
  computed: {
    ...mapGetters('destination', ['destinationStoresPageCount', 'destinationStoresPageNumber']),
  },
};
</script>
