import _ from 'lodash';

export function parseAddress(string) {
  if (addressParsable(string)) {
    const addressArray = string.split(/[ ]*,[ ]*/);
    googleCopyAddressFormat(addressArray);

    return { street: addressArray[0], city: addressArray[1], state: formatState(addressArray[2]), zip: formatZip(addressArray[3]), country: 'CA' };
  }

  return null;
}

function googleCopyAddressFormat(addressArray) {
  const checkStartWithProvinceCode = new RegExp(/^[A-Z]{2} [a-zA-Z]/).test(addressArray[2]);
  if (addressArray[2] && checkStartWithProvinceCode) {
    const googleFormatSupport = addressArray[2].split(/[ ]+/);
    if (googleFormatSupport.length === 3) {
      addressArray[2] = googleFormatSupport[0];
      addressArray[3] = `${googleFormatSupport[1]}${googleFormatSupport[2]}`;
    }
  }
}

function addressParsable(string) {
  return string.indexOf(',') > -1;
}

const stateDictionary = {
  ON: ['ON', 'Ontario'],
  AB: ['AB', 'Alberta'],
  BC: ['BC', 'Colombie-Britannique', 'British Columbia'],
  PE: ['PE', 'Île-du-Prince-Édouard', 'Prince Edward Island'],
  MB: ['MB', 'Manitoba'],
  NB: ['NB', 'Nouveau-Brunswick', 'New Brunswick'],
  NS: ['NS', 'Nouvelle-Écosse', 'Nova Scotia'],
  QC: ['QC', 'Québec'],
  SK: ['SK', 'Saskatchewan'],
  NL: ['NL', 'Terre-Neuve-et-Labrador', 'Newfoundland and Labrador', 'Newfoundland', 'Labrador'],
  NU: ['NU', 'Nunavut'],
  NT: ['NT', 'Territoires du Nord-Ouest', 'Northwest Territories'],
  YT: ['YT', 'Yukon'],
};

const formattedStateDictionary = {};

Object.keys(stateDictionary).forEach((key) => {
  const strippedStateValues = [];
  stateDictionary[key].forEach((stateKey) => {
    const strippedState = stripSpacesHyphensDeburrAndCapitalize(stateKey);

    strippedStateValues.push(strippedState);
  });

  formattedStateDictionary[key] = strippedStateValues;
});

function stripSpacesHyphensDeburrAndCapitalize(string) {
  return _.deburr(string.toUpperCase().replace(/[\--\s]/g, ''));
}

function formatState(string) {
  if (!string) {
    return null;
  }
  const strippedString = stripSpacesHyphensDeburrAndCapitalize(string);

  const stateKey = Object.keys(formattedStateDictionary).find((key) => {
    return formattedStateDictionary[key].indexOf(strippedString) !== -1;
  });

  return stateKey;
}

function formatZip(string) {
  if (!string) {
    return null;
  }
  const strippedString = stripSpacesHyphensDeburrAndCapitalize(string);
  return strippedString.replace(/(.{3})/, '$1 ');
}
