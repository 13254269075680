<template>
  <div>
    <label-text class="fatca-ssn" :label="$t('application.aml_profile.fatca.ssn')" :value="fatca.ssn ? formatSsn : $t('system.na')"></label-text>
    <table data-test="fatca-table" class="responsive-table">
      <thead>
        <tr>
          <td>{{ $t('application.aml_profile.fatca.jurisdiction') }}</td>
          <td>{{ $t('application.aml_profile.fatca.tin') }}</td>
          <td>{{ $t('application.aml_profile.fatca.no_tin_reason') }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(tinItem, index) in fatca.tins" :key="index">
          <td>
            <h5>{{ $t('application.aml_profile.fatca.jurisdiction') }}:</h5>
            <span>{{ tinItem.jurisdiction }}</span>
          </td>
          <td>
            <h5>{{ $t('application.aml_profile.fatca.tin') }}:</h5>
            <span
              >{{ tinItem.tin }}<span class="info-not-available" v-if="!tinItem.tin">{{ $t('system.na') }}</span></span
            >
          </td>
          <td>
            <h5>{{ $t('application.aml_profile.fatca.no_tin_reason') }}:</h5>
            <span
              >{{ tinItem.no_tin_reason }}<span class="info-not-available" v-if="!tinItem.no_tin_reason">{{ $t('system.na') }}</span></span
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import Vue from 'vue';

export default {
  name: 'fatca-table',
  mixins: [actionErrorTrackable, security],
  props: {
    fatca: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    formatSsn() {
      return Vue.filter('formatSsn')(this.fatca.ssn);
    },
  },
};
</script>
