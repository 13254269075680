import _ from 'lodash';
import Vue from 'vue';

const defaultPage = 1;
const paginationLimit = 20;
const brandingStoresPaginationLimit = 10;
const brandingPublishersPaginationLimit = 10;
const brandingOffersPaginationLimit = 10;
const brandingSpacesPaginationLimit = 10;
const merchantType = 'merchant';
const mixedPlacesCategoryId = 'mixed';

function isValidCategoryForType(categoryId, type) {
  return !(type == merchantType && categoryId == mixedPlacesCategoryId);
}

function convertBrandingImages(branding) {
  if (branding.images) {
    const splitImages = {
      background_image: {},
      big_hero: {},
      small_hero: {},
      logo_horizontal: {},
      showcases: { fr: [], en: [] },
    };

    branding.images.map((image) => {
      if (image.role === 'showcase_fr') {
        splitImages.showcases.fr.push({ id: image.id, url: image.url });
      } else if (image.role === 'showcase_en') {
        splitImages.showcases.en.push({ id: image.id, url: image.url });
      } else {
        const prefix = image.role.slice(0, -3);
        const language = image.role.slice(-2);

        _.forEach(splitImages, (value, index) => {
          if (prefix === index) {
            splitImages[index][language] = { id: image.id, url: image.url };
          }
        });
      }
    });

    branding.split_images = splitImages;
  }
  return branding;
}

export default {
  namespaced: true,
  state: {
    brandings: [],
    branding: { label: {}, description: {}, tagline: {}, url: {}, region_label: {}, features: {} },
    types: ['merchant', 'campaign', 'space'],
    categories: [],
    brandingStores: [],
    brandingPublishers: [],
    brandingOffers: [],
    brandingSpaces: [],
    list: {
      pageNumber: 1,
      pageCount: 0,
      itemCount: 0,
      keyword: '',
      type: '',
      category: '',
    },
    listBrandingStores: {
      pageNumber: 1,
      pageCount: 0,
      itemCount: 0,
    },
    listBrandingPublishers: {
      pageNumber: 1,
      pageCount: 0,
      itemCount: 0,
    },
    listBrandingOffers: {
      pageNumber: 1,
      pageCount: 0,
      itemCount: 0,
    },
    listBrandingSpaces: {
      pageNumber: 1,
      pageCount: 0,
      itemCount: 0,
    },
  },
  getters: {
    branding: (state) => state.branding,
    brandings: (state) => state.brandings,
    showcase_images: (state) => _.get(state.branding, 'split_images.showcase', []),
    types: (state) => state.types,
    categories: (state) => state.categories,
    listPageCount: (state) => state.list.pageCount,
    listPageNumber: (state) => state.list.pageNumber,
    listItemCount: (state) => state.list.itemCount,
    listKeyword: (state) => state.list.keyword,
    listType: (state) => state.list.type,
    listCategory: (state) => state.list.category,

    brandingStores: (state) => state.brandingStores,
    brandingStoresPageNumber: (state) => state.listBrandingStores.pageNumber,
    brandingStoresPageCount: (state) => state.listBrandingStores.pageCount,
    brandingStoresItemCount: (state) => state.listBrandingStores.itemCount,

    brandingPublishers: (state) => state.brandingPublishers,
    brandingPublishersPageNumber: (state) => state.listBrandingPublishers.pageNumber,
    brandingPublishersPageCount: (state) => state.listBrandingPublishers.pageCount,
    brandingPublishersItemCount: (state) => state.listBrandingPublishers.itemCount,

    brandingOffers: (state) => state.brandingOffers,
    brandingOffersPageNumber: (state) => state.listBrandingOffers.pageNumber,
    brandingOffersPageCount: (state) => state.listBrandingOffers.pageCount,
    brandingOffersItemCount: (state) => state.listBrandingOffers.itemCount,

    brandingSpaces: (state) => state.brandingSpaces,
    brandingSpacesPageNumber: (state) => state.listBrandingSpaces.pageNumber,
    brandingSpacesPageCount: (state) => state.listBrandingSpaces.pageCount,
    brandingSpacesItemCount: (state) => state.listBrandingSpaces.itemCount,

    brandingCategoriesFilteredIfTypeRequires: (state) => (type) => {
      return state.categories.filter((cat) => isValidCategoryForType(cat.id, type));
    },
  },
  mutations: {
    brandings(state, brandings) {
      state.brandings = brandings;
    },
    branding(state, branding) {
      if (!branding) {
        branding = { label: {}, description: {}, tagline: {}, url: {}, region_label: {}, features: {} };
      }
      state.branding = branding;
    },
    types(state, types) {
      state.types = types;
    },
    categories(state, categories) {
      state.categories = _.sortBy(categories, 'name.en');
    },
    listPageCount(state, pageCount) {
      state.list.pageCount = pageCount;
    },
    listItemCount(state, itemCount) {
      state.list.itemCount = itemCount;
    },
    listPageNumber(state, pageNumber) {
      state.list.pageNumber = pageNumber;
    },
    listKeyword(state, keyword) {
      state.list.keyword = keyword;
    },
    listType(state, type) {
      state.list.type = type;
    },
    listCategory(state, category) {
      state.list.category = category;
    },

    brandingStores(state, stores) {
      state.brandingStores = stores;
    },
    brandingStoresPageCount(state, pageCount) {
      state.listBrandingStores.pageCount = pageCount;
    },
    brandingStoresItemCount(state, itemCount) {
      state.listBrandingStores.itemCount = itemCount;
    },
    brandingStoresPage(state, page) {
      state.listBrandingStores.pageNumber = page;
    },

    brandingPublishers(state, publishers) {
      state.brandingPublishers = publishers;
    },
    brandingPublishersPageCount(state, pageCount) {
      state.listBrandingPublishers.pageCount = pageCount;
    },
    brandingPublishersItemCount(state, itemCount) {
      state.listBrandingPublishers.itemCount = itemCount;
    },
    brandingPublishersPage(state, page) {
      state.listBrandingPublishers.pageNumber = page;
    },

    brandingOffers(state, publishers) {
      state.brandingOffers = publishers;
    },
    brandingOffersPageCount(state, pageCount) {
      state.listBrandingOffers.pageCount = pageCount;
    },
    brandingOffersItemCount(state, itemCount) {
      state.listBrandingOffers.itemCount = itemCount;
    },
    brandingOffersPage(state, page) {
      state.listBrandingOffers.pageNumber = page;
    },

    brandingSpaces(state, publishers) {
      state.brandingSpaces = publishers;
    },
    brandingSpacesPageCount(state, pageCount) {
      state.listBrandingSpaces.pageCount = pageCount;
    },
    brandingSpacesItemCount(state, itemCount) {
      state.listBrandingSpaces.itemCount = itemCount;
    },
    brandingSpacesPage(state, page) {
      state.listBrandingSpaces.pageNumber = page;
    },
  },
  actions: {
    async autocompleteSearch({}, { page, limit, keyword }) {
      const brandingService = Vue.prototype.$services.branding;

      return brandingService.searchBrandings(page, limit, keyword);
    },
    async listBrandings({ state, commit }, { keyword, type, category, page, limit } = {}) {
      const brandingService = Vue.prototype.$services.branding;

      const thePage = page || state.list.pageNumber || defaultPage;
      const theLimit = limit || paginationLimit;
      const theKeyword = keyword || '';
      const theType = type || '';
      const theCategory = category || '';

      const listResults = await brandingService.listBrandings(theKeyword, theType, theCategory, thePage, theLimit);

      commit('brandings', listResults.items);
      commit('listPageNumber', thePage);
      commit('listPageCount', listResults.page_count);
      commit('listItemCount', listResults.item_count);
      commit('listKeyword', theKeyword);
      commit('listType', theType);
      commit('listCategory', theCategory);
    },
    async listBrandingStores({ state, commit }, { id, page, limit } = {}) {
      const brandingService = Vue.prototype.$services.branding;
      const thePage = page || state.listBrandingStores.pageNumber || defaultPage;
      const theLimit = limit || brandingStoresPaginationLimit;

      const listResults = await brandingService.listBrandingStores(id, thePage, theLimit);

      commit('brandingStores', listResults.items);
      commit('brandingStoresPage', thePage);
      commit('brandingStoresPageCount', listResults.page_count);
      commit('brandingStoresItemCount', listResults.item_count);
    },
    async changeBrandingStoresPage({ commit, state }, { id, page }) {
      commit('brandingStoresPage', page);

      const brandingService = Vue.prototype.$services.branding;
      const limit = brandingStoresPaginationLimit;

      const result = await brandingService.listBrandingStores(id, page, limit);

      commit('brandingStoresPageCount', result.page_count);
      commit('brandingStores', result.items);
    },
    async listBrandingPublishers({ state, commit }, { id, page, limit } = {}) {
      const brandingService = Vue.prototype.$services.branding;
      const thePage = page || state.listBrandingPublishers.pageNumber || defaultPage;
      const theLimit = limit || brandingPublishersPaginationLimit;

      const listResults = await brandingService.listBrandingPublishers(id, thePage, theLimit);

      commit('brandingPublishers', listResults.items);
      commit('brandingPublishersPage', thePage);
      commit('brandingPublishersPageCount', listResults.page_count);
      commit('brandingPublishersItemCount', listResults.item_count);
    },
    async changeBrandingPublishersPage({ commit, state }, { id, page }) {
      commit('brandingPublishersPage', page);

      const brandingService = Vue.prototype.$services.branding;
      const limit = brandingPublishersPaginationLimit;

      const result = await brandingService.listBrandingPublishers(id, page, limit);

      commit('brandingPublishersPageCount', result.page_count);
      commit('brandingPublishers', result.items);
    },
    async listBrandingOffers({ state, commit }, { id, page, limit } = {}) {
      const brandingService = Vue.prototype.$services.branding;
      const thePage = page || state.listBrandingOffers.pageNumber || defaultPage;
      const theLimit = limit || brandingOffersPaginationLimit;

      const listResults = await brandingService.listBrandingOffers(id, thePage, theLimit);

      commit('brandingOffers', listResults.items);
      commit('brandingOffersPage', thePage);
      commit('brandingOffersPageCount', listResults.page_count);
      commit('brandingOffersItemCount', listResults.item_count);
    },
    async changeBrandingOffersPage({ commit, state }, { id, page }) {
      commit('brandingOffersPage', page);

      const brandingService = Vue.prototype.$services.branding;
      const limit = brandingOffersPaginationLimit;

      const result = await brandingService.listBrandingOffers(id, page, limit);

      commit('brandingOffersPageCount', result.page_count);
      commit('brandingOffers', result.items);
    },
    async listBrandingSpaces({ state, commit }, { id, page, limit } = {}) {
      const partnerService = Vue.prototype.$services.partner;
      const thePage = page || state.listBrandingSpaces.pageNumber || defaultPage;
      const theLimit = limit || brandingSpacesPaginationLimit;

      const listResults = await partnerService.listSpacesByBrandingId(id, thePage, theLimit);

      commit('brandingSpaces', listResults.items);
      commit('brandingSpacesPage', thePage);
      commit('brandingSpacesPageCount', listResults.page_count);
      commit('brandingSpacesItemCount', listResults.item_count);
    },
    async changeBrandingSpacesPage({ commit, state }, { id, page }) {
      commit('brandingSpacesPage', page);

      const partnerService = Vue.prototype.$services.partner;
      const limit = brandingSpacesPaginationLimit;

      const result = await partnerService.listSpacesByBrandingId(id, page, limit);

      commit('brandingSpacesPageCount', result.page_count);
      commit('brandingSpaces', result.items);
    },
    async clearListBrandings({ commit }) {
      commit('brandings', []);
    },
    async createBranding({ commit }, params) {
      const brandingService = Vue.prototype.$services.branding;
      return brandingService.createBranding(params);
    },
    async getBranding({ commit }, id) {
      const brandingService = Vue.prototype.$services.branding;
      const result = await brandingService.getBranding(id);
      const branding = convertBrandingImages(result);

      commit('branding', branding);
      return branding;
    },
    async listCategories({ commit }) {
      const brandingService = Vue.prototype.$services.branding;
      const categories = await brandingService.listCategories();

      commit('categories', categories);
      return categories;
    },
    async getCategoryById({ state, dispatch }, id) {
      let categories = state.categories;
      if (!categories || categories.length === 0) {
        categories = await dispatch('listCategories');
      }

      return categories.find((category) => category.id === id);
    },
    async updateBranding({ dispatch }, params) {
      const brandingService = Vue.prototype.$services.branding;
      const { id, ...payload } = params;
      const result = await brandingService.updateBranding(id, payload);
      await dispatch('getBranding', id);
      return result;
    },
    async createBrandingImagesUploadSignedUrls({ commit }, { branding_id, images }) {
      const brandingService = Vue.prototype.$services.branding;
      const response = await brandingService.createBrandingImagesUploadSignedUrls(branding_id, { images });
      return response.items;
    },
    async addBrandingImage({ dispatch, commit }, { branding_id, payload }) {
      const brandingService = Vue.prototype.$services.branding;
      const result = await brandingService.addBrandingImage(branding_id, payload);

      await dispatch('getBranding', branding_id);
      return result;
    },
    async removeBrandingImage({ dispatch, commit }, payload) {
      const brandingService = Vue.prototype.$services.branding;
      const result = await brandingService.removeBrandingImage(payload);
      await dispatch('getBranding', payload.branding_id);
      return result;
    },
    async uploadBrandingImage({ commit }, { signed_url, image }) {
      const brandingService = Vue.prototype.$services.branding;
      return brandingService.uploadImage(signed_url, image);
    },
  },
};
