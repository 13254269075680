<template>
  <div>
    <table class="responsive-table">
      <thead>
        <tr>
          <td>{{ $t('bank_account.bank_accounts_table.business_name') }}</td>
          <td>{{ $t('bank_account.bank_accounts_table.bank_account_number') }}</td>
          <td>{{ $t('bank_account.bank_accounts_table.status') }}</td>
          <td>{{ $t('bank_account.bank_accounts_table.created') }}</td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>
            <h5>{{ $t('bank_account.bank_accounts_table.business_name') }}:</h5>
            <router-link :to="{ name: 'bank_account_details', params: { bankAccountKey: item.key } }">
              {{ item.business_name }}
            </router-link>
          </td>
          <td>
            <h5>{{ $t('bank_account.bank_accounts_table.bank_account_number') }}:</h5>
            <span>{{ item.bank_account_number }}</span>
          </td>
          <td>
            <h5>{{ $t('bank_account.bank_accounts_table.status') }}:</h5>
            <span :class="!item.status ? 'info-not-available' : `bank-account-${item.status}`">{{
              $t(`bank_account.status.${item.status}`) | orNotAvailable
            }}</span>
          </td>
          <td>
            <h5>{{ $t('bank_account.bank_accounts_table.created') }}:</h5>
            <span>{{ item.created | formatShortDateInverted | orNotAvailable }}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-xs-center">
      <v-pagination v-if="listPageCount > 1" v-model="pagination" :length="listPageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'bank-accounts-table',
  mixins: [actionErrorTrackable, security],
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      pagination: 1,
    };
  },
  mounted() {
    this.pagination = this.listPageNumber;
  },
  computed: {
    ...mapGetters('bankAccount', ['listPageCount', 'listPageNumber', 'listKeyword', 'listStatus']),
  },
  watch: {
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'bankAccount/listBankAccounts',
        },
        {
          page: newValue,
          keyword: this.listKeyword,
          status: this.listStatus,
        },
      );
    },
  },
};
</script>
