<template>
  <div class="label-text">
    <div v-if="url">
      <h5>{{ label }}</h5>
      <p class="label-text--action-wrap">
        <a :href="url" target="_blank">{{ url }}</a>
      </p>
    </div>

    <div v-else>
      <h5 v-bind:class="[classArray]">{{ label }}</h5>
      <slot name="labelTextLink">
        <p class="label-text--value" v-bind:class="[classArray, isNotAvailable]" :title="title">{{ value | orNotAvailable }}</p>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'link-label-text',
  props: {
    label: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    value: {
      required: false,
    },
    url: {
      type: String,
      required: false,
    },
    classArray: {
      type: Array,
      required: false,
    },
  },
  computed: {
    classes() {
      return {
        ...this.$classes,
      };
    },
    isNotAvailable() {
      if (this.$options.filters.orNotAvailable(this.value) === this.value) {
        return;
      }
      return 'label-text-na';
    },
  },
  methods: {
    showForm() {
      this.actionData.showLabelTextForm = !this.actionData.showLabelTextForm;
    },
  },
};
</script>
