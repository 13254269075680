<template>
  <view-main title="page_title.destination"><search-destination-form slot="content"></search-destination-form></view-main>
</template>

<script>
import { renderErrorTrackable } from '@/mixins';
import SearchDestinationForm from './list/SearchDestinationForm.vue';

export default {
  name: 'list-destination-view',
  mixins: [renderErrorTrackable],
  components: { SearchDestinationForm },
};
</script>
