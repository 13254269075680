import Vue from 'vue';
import _ from 'lodash';
import keyExtractor from '../utils/keys-extractor';
import { safeDispatcher } from '@/utils/context/context-helper';
import { validateDownloadDocument } from '@/store/modules/validators/aml-validators';

export default {
  namespaced: true,

  state: {
    changeApplicationRequest: null,
    changeRequestLog: [],
    documents: [],
  },

  getters: {
    documents: (state) => state.documents,
    changeApplicationRequest: (state) => state.changeApplicationRequest && state.changeApplicationRequest.change_request,
    changeRequestLog: (state) => state.changeRequestLog,
    changeApplicationRequestUrl(state) {
      if (state.changeApplicationRequest) {
        return `${process.env.VUE_APP_SELFSERVE_CONSOLE_URL}/application/${state.changeApplicationRequest.token}`;
      }
    },
    changeApplicationRequestFieldsFunction(state) {
      return (car) => {
        if (!car || !car.change_request) return [];
        const paths = [
          'first_name',
          'last_name',
          'date_of_birth',
          'address.street',
          'address.city',
          'address.state',
          'address.country',
          'address.zip',
          'phone',
          'mobile',
          'email',
          'language',
          'settings.alerts',
          'compliance.occupation.status.text',
          'compliance.occupation.sector.text',
          'compliance.occupation.profession.text',
          'documents_added',
        ];

        return keyExtractor.sortExistingKeys(car.change_request.changes, paths);
      };
    },
    changeApplicationRequestFields(state) {
      if (!state.changeApplicationRequest) return [];
      const paths = [
        'first_name',
        'last_name',
        'date_of_birth',
        'address.street',
        'address.city',
        'address.state',
        'address.country',
        'address.zip',
        'phone',
        'mobile',
        'email',
        'language',
        'settings.alerts',
        'compliance.occupation.status.text',
        'compliance.occupation.sector.text',
        'compliance.occupation.profession.text',
        'documents_added',
      ];

      return keyExtractor.sortExistingKeys(state.changeApplicationRequest.change_request.changes, paths);
    },
  },

  mutations: {
    changeApplicationRequest(state, changeRequest) {
      state.changeApplicationRequest = changeRequest;
    },
    changeRequestLog(state, log) {
      state.changeRequestLog = log;
    },
    documents(state, documents) {
      state.documents = documents;
    },
  },

  actions: {
    async getChangeRequestLog({ commit }, application_id) {
      const selfserveService = Vue.prototype.$services.selfserve;

      const log = await selfserveService.listChangeApplicationRequests(application_id);
      commit('changeRequestLog', log);

      return log;
    },

    async getChangeApplicationRequest({ getters, commit }, id) {
      const selfserveService = Vue.prototype.$services.selfserve;

      const changeRequest = await selfserveService.getChangeApplicationRequestById(id);
      commit('changeApplicationRequest', changeRequest);

      return changeRequest;
    },

    async getCurrentChangeApplicationRequest({ getters, commit }, application_id) {
      const selfserveService = Vue.prototype.$services.selfserve;

      const cachedValue = getters.changeApplicationRequest;

      if (_.get(cachedValue, 'props.card_application_id') === application_id) {
        return cachedValue;
      }

      const changeRequest = await selfserveService.getCurrentChangeApplicationRequest(application_id).catch((err) => console.error('.catch', err)); // eslint-disable-line no-console
      commit('changeApplicationRequest', changeRequest);

      return changeRequest;
    },

    async createChangeApplicationRequest({ commit }, application_id) {
      const selfserveService = Vue.prototype.$services.selfserve;

      const changeRequest = await selfserveService.createChangeApplicationRequest({ application_id });
      commit('changeApplicationRequest', changeRequest);

      return changeRequest;
    },

    async getOrCreateChangeApplicationRequest({ dispatch }, application_id) {
      const defaultDispatch = safeDispatcher(dispatch);

      const changeRequest = await defaultDispatch('selfserve/getCurrentChangeApplicationRequest', application_id);

      if (!changeRequest) {
        await defaultDispatch('selfserve/createChangeApplicationRequest', application_id);
        await defaultDispatch('selfserve/getCurrentChangeApplicationRequest', application_id);
      }
    },

    async sendChangeApplicationRequestEmail({ dispatch }, { application_id, requested_changes }) {
      const defaultDispatch = safeDispatcher(dispatch);
      const selfserveService = Vue.prototype.$services.selfserve;

      await selfserveService.sendChangeApplicationRequestEmail({ application_id, requested_changes });
      await defaultDispatch('selfserve/getChangeRequestLog', application_id);
    },

    async downloadDocument(context, { id, filename }) {
      await validateDownloadDocument({ id, filename });
      const selfserveDocumentService = Vue.prototype.$services.selfserveDocument;
      const fileSaver = Vue.prototype.$services.fileSaver;

      const result = await selfserveDocumentService.getDocument(id);
      await fileSaver.saveFile(result, filename);
    },
  },
};
