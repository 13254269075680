import Vue from 'vue';
import { safeDispatcher } from '@/utils/context/context-helper';
import { mapAssessmentCheck } from '../utils/aml-assessment-check-mapper';

export default {
  namespaced: true,
  state: {
    filters: {},
    pagination: {
      page: 1,
      limit: 15,
      item_count: 0,
    },
    tasks: {},
    taskTypes: [],
    cards: {},
    aml_notes: {},
    aml_task_notes: {},
    aml_assessment_checks: {},
    aml_statuses: {},
    card_applications: {},
    users: {},
    resolvingAgents: [],
  },
  mutations: {
    task: (state, task) => Vue.set(state.tasks, task.id, task),
    tasks: (state, tasks) => {
      state.tasks = tasks.reduce(function(acc, current) {
        acc[current.id] = current;
        return acc;
      }, {});
    },
    taskTypes: (state, types) => (state.taskTypes = types),
    card: (state, card) => Vue.set(state.cards, card.public_token, card),
    cards: (state, cards) => (state.cards = cards),
    aml_note: (state, note) => Vue.set(state.aml_notes, note.key, note),
    aml_notes: (state, aml_notes) => (state.aml_notes = aml_notes),
    aml_task_note: (state, note) => Vue.set(state.aml_task_notes, note.task_id, note),
    aml_task_notes: (state, aml_task_notes) => (state.aml_task_notes = aml_task_notes),
    aml_assessment_check: (state, check) => Vue.set(state.aml_assessment_checks, check.key, check),
    aml_assessment_checks: (state, checks) => (state.aml_assessment_checks = checks),
    aml_status: (state, status) => Vue.set(state.aml_statuses, status.public_token, status),
    aml_statuses: (state, statuses) => (state.aml_statuses = statuses),
    card_application: (state, cardApplication) => Vue.set(state.card_applications, cardApplication.card_application_id, cardApplication),
    card_applications: (state, cardApplications) => (state.card_applications = cardApplications),
    user: (state, user) => Vue.set(state.users, user.id, user),
    users: (state, users) => (state.users = users),
    pagination: (state, pagination) => (state.pagination = pagination),
    filters: (state, filters) => (state.filters = filters),
    resolvingAgents: (state, resolvingAgents) => (state.resolvingAgents = resolvingAgents),
  },
  getters: {
    task: (state) => (id) => state.tasks[id],
    tasks: (state) => Object.values(state.tasks),
    taskTypes: (state) => state.taskTypes,
    card: (state) => (public_token) => state.cards[public_token],
    amlNote: (state) => (aml_note_key) => state.aml_notes[aml_note_key],
    amlTaskNote: (state) => (task_id) => state.aml_task_notes[task_id],
    amlAssessmentCheck: (state) => (aml_assessment_check_key) => state.aml_assessment_checks[aml_assessment_check_key],
    amlStatus: (state) => (public_token) => state.aml_statuses[public_token],
    cardApplication: (state) => (card_application_id) => state.card_applications[card_application_id],
    user: (state) => (account_id) => state.users[account_id],
    pagination: (state) => state.pagination,
    filters: (state) => state.filters,
    resolvingAgents: (state) => state.resolvingAgents,
  },
  actions: {
    async getUniqueResolvingAgents({ rootState, commit }) {
      const card_program_key = rootState.security.currentCardProgramKey;
      const taskService = Vue.prototype.$services.task;
      const resolvingAgents = await taskService.getUniqueResolvingAgents({ card_program_key });
      commit('resolvingAgents', resolvingAgents);
    },

    async getTaskTypes({ rootState, commit }) {
      const agent_key = rootState.security.currentAgent.key;
      const card_program_key = rootState.security.currentCardProgramKey;
      const taskService = Vue.prototype.$services.task;

      const taskTypes = await taskService.getTaskTypes({ agent_key, card_program_key });
      commit('taskTypes', taskTypes);
    },

    async getTasks({ rootState, commit, dispatch, getters }, { filters, page = null }) {
      const pagination = getters.pagination;
      const limit = pagination.limit;
      const agent_key = rootState.security.currentAgent.key;
      const card_program_key = rootState.security.currentCardProgramKey;

      const defaultDispatch = safeDispatcher(dispatch);
      const taskService = Vue.prototype.$services.task;

      async function getTasksByPage(pageNumber) {
        const { items, page_count, item_count } = await taskService.getTasks({ agent_key, card_program_key }, filters, { page: pageNumber, limit });

        if (!pageExists()) {
          return getTasksByPage(page_count);
        }

        function pageExists() {
          return pageNumber <= page_count;
        }

        return { items, page_count, item_count, current_page: pageNumber };
      }

      const { items, page_count, current_page, item_count } = await getTasksByPage(page || pagination.page);

      await defaultDispatch('task/resetTasksData');
      commit('tasks', items);
      commit('filters', filters);
      commit('pagination', { page: current_page, page_count, limit, item_count });
    },

    async refreshTasks({ getters, dispatch }) {
      const filters = getters.filters;
      const defaultDispatch = safeDispatcher(dispatch);
      await defaultDispatch('task/getTasks', { filters });
    },

    async getCard({ getters, commit }, public_token) {
      const cardService = Vue.prototype.$services.card;

      const cachedValue = getters.card(public_token);

      if (cachedValue) {
        return cachedValue;
      }

      const value = await cardService.getCard(public_token);
      commit('card', value);

      return value;
    },

    async getAmlTaskNote({ getters, commit }, task_id) {
      const amlNoteService = Vue.prototype.$services.amlNote;
      const amlNoteTypeService = Vue.prototype.$services.amlNoteType;
      const amlNoteCategoryService = Vue.prototype.$services.amlNoteCategory;

      const storedNote = getters.amlTaskNote(task_id);

      if (storedNote) {
        return storedNote;
      }

      const note = await amlNoteService.getTaskNote(task_id);

      if (note) {
        const [type, category] = await Promise.all([
          //
          amlNoteTypeService.getNoteType(note.type_id),
          amlNoteCategoryService.getNoteCategory(note.category_id),
        ]);

        const savedNote = {
          ...note,
          type,
          category,
        };

        commit('aml_task_note', savedNote);

        return savedNote;
      }
    },

    async getAmlNote({ getters, commit }, aml_note_key) {
      const amlNoteService = Vue.prototype.$services.amlNote;
      const amlNoteTypeService = Vue.prototype.$services.amlNoteType;
      const amlNoteCategoryService = Vue.prototype.$services.amlNoteCategory;

      const storedNote = getters.amlNote(aml_note_key);

      if (storedNote) {
        return storedNote;
      }

      const note = await amlNoteService.getAmlNote(aml_note_key);
      const [type, category] = await Promise.all([
        //
        amlNoteTypeService.getNoteType(note.type_id),
        amlNoteCategoryService.getNoteCategory(note.category_id),
      ]);

      const savedNote = {
        ...note,
        type,
        category,
      };

      commit('aml_note', savedNote);

      return savedNote;
    },

    async getAmlAssessmentCheck({ getters, commit }, aml_assessment_check_key) {
      const service = Vue.prototype.$services.amlAssessmentCheck;

      const cachedValue = getters.amlAssessmentCheck(aml_assessment_check_key);

      if (cachedValue) {
        return cachedValue;
      }

      const value = await service.getAmlAssessmentCheck(aml_assessment_check_key);
      commit('aml_assessment_check', mapAssessmentCheck(value));

      return value;
    },

    async getAmlStatus({ getters, commit }, token) {
      const service = Vue.prototype.$services.amlStatus;
      const cachedValue = getters.amlStatus(token);

      if (cachedValue) {
        return cachedValue;
      }

      const value = await service.getAmlStatus(token);
      commit('aml_status', value);
    },

    async getCardApplication({ getters, commit }, card_application_id) {
      const applicationService = Vue.prototype.$services.application;

      const cachedValue = getters.cardApplication(card_application_id);

      if (cachedValue) {
        return cachedValue;
      }

      const value = await applicationService.getApplication(card_application_id);
      commit('card_application', value);

      return value;
    },

    async getUser({ getters, commit }, account_id) {
      const accountService = Vue.prototype.$services.account;

      const cachedValue = getters.user(account_id);

      if (cachedValue) {
        return cachedValue;
      }

      const value = await accountService.getAccount(account_id);
      commit('user', value);

      return value;
    },

    async getTask({ getters }, taskId) {
      return getters.task(taskId);
    },

    // Never been used so far, please REMOVE comment when method is used and properly working (might need fixes, never tested)
    // async assignTask({ commit }, taskKey, assigneeKey) {
    //   const taskService = Vue.prototype.$services.task;
    //   const currentUser = this.state.security.currentUser;
    //   const assignedTask = await taskService.assignTask(taskKey, { assigner_agent_key: currentUser.key, assignee_agent_key: assigneeKey, assignee_app_key });
    //   commit('task', assignedTask);
    // },

    async resolveTask({ rootState }, { task_key, action, params }) {
      const agent_key = rootState.security.currentAgent.key;

      const taskService = Vue.prototype.$services.task;
      await taskService.resolveTask(task_key, { action, agent_key, params });
    },

    async createAmlNote(context, amlNote) {
      const amlNoteService = Vue.prototype.$services.aml;
      await amlNoteService.createAmlNote(amlNote);
    },

    async saveAmlTaskNote({ getters, dispatch }, { task_id, note }) {
      const amlNoteService = Vue.prototype.$services.amlNote;

      const task = getters.task(task_id);
      const card_program_key = task.card_program_key;

      const { public_token, card_application_id } = task.relations;

      const card = public_token ? await dispatch('getCard', public_token) : {};
      const application = card_application_id ? await dispatch('getCardApplication', card_application_id) : {};
      const cardholder_key = public_token ? card.cardholder.key : application.cardholder_key;

      let payload = {
        task_id,
        public_token,
        card_application_id,
        cardholder_key,
        card_program_key,
        account_id: task.account_id,
        created_by_email: this.state.security.currentUser.email,
        category_id: note.category_id,
        type_id: note.type_id,
        note: note.note,
      };
      await amlNoteService.createAmlNote(payload);
      const defaultDispatch = safeDispatcher(dispatch);
      await defaultDispatch('aml/getAmlNotes', { public_token, card_application_id, cardholder_key });
    },

    async resetTasksData({ commit }) {
      commit('tasks', []);
      commit('aml_notes', {});
      commit('aml_task_notes', {});
      commit('aml_assessment_checks', {});
      commit('aml_statuses', {});
      commit('cards', {});
      commit('card_applications', {});
    },
  },
};
