import Vue from 'vue';

const service = {
  async setCardAmlFlag(publicToken, amlFlag) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/cards/v1/cards/${publicToken}/aml-flag`, {
      aml_flag: amlFlag,
    });
    return response.data.data.aml_flag;
  },
};

export default service;
