<template>
  <card-modal
    icon=""
    :title="$t('voucher.block_voucher_action.title')"
    context="blockVoucher"
    @submit="submit"
    @cancel="cancel"
    :submit_working="working"
    cancel_text="voucher.block_voucher_action.button_cancel"
    submit_text="voucher.block_voucher_action.button_save"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <template>
          <v-layout row pad-form-row-elements>
            <v-flex sm12 xs12>
              <v-text-field
                ref="reason"
                name="reason"
                v-model="reason"
                :label="$t('voucher.block_voucher_action.reason') + '*'"
                v-validate="{ required: true, max: 1204 }"
                :error-messages="errors.collect('reason')"
                data-vv-name="reason"
                :data-vv-as="$t('voucher.block_voucher_action.reason')"
                data-test="block-voucher--reason"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </template>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';

export default {
  name: 'block-voucher-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    voucher: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      reason: '',
    };
  },
  methods: {
    clear() {
      this.currentVoucher = {};
      this.$validator.reset();
    },
    success() {
      this.clear();
      this.$emit('updated');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();
      if (validationComplete) {
        await this.executeAction(
          {
            action: 'voucher/blockVoucher',
            name: 'blockVoucher',
            success: this.success,
          },
          {
            id: this.voucher.id,
            reason: this.reason,
          },
        );
      }
    },
  },
};
</script>
