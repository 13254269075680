<template>
  <card :title="$t('aml.documents_section.title')" class="hc-documents-section">
    <template slot="headerZoneRight">
      <label-text :label="$t('aml.documents_section.document_count')" :value="documents.length" />

      <div v-if="showUploadDocumentFeature()" class="card-header-divider--vertical hidden-xs-only">
        <v-divider vertical />
      </div>

      <label-text v-if="showUploadDocumentFeature()" :label="$t('aml.documents_section.action')" value="3">
        <link-secondary-action slot="labelTextLink" @click="openUploadDocumentModal">{{
          $t('aml.documents_section.upload_document')
        }}</link-secondary-action>
      </label-text>
    </template>

    <template slot="content">
      <label-none v-if="documents.length === 0">{{ $t('aml.documents_section.no_found') }}</label-none>

      <v-layout column>
        <template v-for="(item, index) in documents">
          <aml-document :document="item" :has-divider="isLastDocument(index)" :key="index" />
        </template>
      </v-layout>

      <delete-with-reason-modal
        v-if="showUploadDocumentFeature()"
        :show-modal="showDeleteDocumentModal"
        object-type="Note"
        @confirm="onDeleteDocument"
        @close="hideDeleteDocumentModal"
      />

      <modal-action :show-modal="showUploadDocumentModal" v-if="showUploadDocumentFeature()">
        <aml-document-upload-action
          :card-application-id="cardApplicationId"
          :cardholder-key="cardholderKey"
          :account-id="accountId"
          :card-program-key="cardProgramKey"
          @close="closeUploadDocumentModal"
          @action="closeUploadDocumentModal"
        />
      </modal-action>
    </template>
  </card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import AmlDocumentUploadAction from './AmlDocumentUploadAction';

export default {
  name: 'aml-document-list',

  mixins: [actionErrorTrackable, disableWithError, security],

  components: { AmlDocumentUploadAction },

  props: {
    cardApplicationId: {
      type: String,
      required: true,
    },
    cardholderKey: {
      type: String,
      required: false,
    },
    accountId: {
      type: String,
      required: false,
    },
    publicToken: {
      type: String,
      required: false,
    },
    cardProgramKey: {
      type: String,
      required: true,
    },
    fromView: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapGetters('aml', ['documents', 'currentDocument', 'showDeleteDocumentModal']),
  },

  data() {
    return {
      showUploadDocumentModal: false,
    };
  },

  created() {
    this.getDocuments({
      card_application_id: this.cardApplicationId,
      public_token: this.publicToken,
      cardholder_key: this.cardholderKey,
      account_id: this.accountId,
    });
  },

  methods: {
    ...mapActions('aml', ['getDocuments', 'deleteDocument', 'hideDeleteDocumentModal']),

    isLastDocument(index) {
      return index + 1 < this.documents.length;
    },

    async onDeleteDocument({ reason }) {
      const params = {
        id: this.currentDocument.id,
        reason,
        card_application_id: this.cardApplicationId,
        cardholder_key: this.cardholderKey,
        public_token: this.publicToken,
        account_id: this.accountId,
      };

      await this.deleteDocument(params);
    },

    openUploadDocumentModal() {
      this.showUploadDocumentModal = true;
    },

    closeUploadDocumentModal() {
      this.showUploadDocumentModal = false;
    },

    showUploadDocumentFeature() {
      if (this.fromView === 'card') {
        return this.canUploadDocumentCard();
      }

      return this.canUploadDocumentApplication();
    },
  },
};
</script>
