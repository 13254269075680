<template>
  <card :title="$t('business.details.notes_section.title')" icon="">
    <template slot="headerZoneRight">
      <label-text
        class="text-xs-center"
        :label="`${$t('business.details.notes_section.count')}`"
        :value="listBusinessNotesItemCount || '0'"
      ></label-text>
      <div v-if="showAddBusinessNoteFeature()" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text v-if="showAddBusinessNoteFeature()" :label="`${$t('business.details.notes_section.action')}`" value="3">
        <link-secondary-action slot="labelTextLink" @click="openAddBusinessNoteModal">
          {{ $t('business.details.notes_section.create_note') }}
        </link-secondary-action>
      </label-text>
    </template>
    <template slot="content">
      <business-notes-table
        v-if="businessNotes.length"
        :items="businessNotes"
        :business-id="business.id"
        @business_note_delete_action="openDeleteBusinessNoteModal"
      ></business-notes-table>
      <label-none v-if="businessNotes.length === 0">{{ $t('business.details.notes_section.no_found') }}</label-none>
      <modal-action :show-modal="showAddBusinessNoteModal"
        ><create-business-note-action
          :v-if="showAddBusinessNoteModal"
          :business-id="business.id"
          @close="closeAddBusinessNoteModal"
          @created="addBusinessNoteSuccess"
        ></create-business-note-action
      ></modal-action>
      <modal-action :show-modal="showDeleteBusinessNoteModal"
        ><delete-business-note-action
          :v-if="showDeleteBusinessNoteModal"
          :business-id="business.id"
          :note-id="noteIdToDelete"
          @close="closeDeleteBusinessNoteModal"
          @deleted="deleteBusinessNoteSuccess"
        ></delete-business-note-action
      ></modal-action>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import CreateBusinessNoteAction from '../actions/CreateBusinessNoteAction';
import DeleteBusinessNoteAction from '../actions/DeleteBusinessNoteAction';
import BusinessNotesTable from './BusinessNotesTable';
import { mapGetters } from 'vuex';

export default {
  name: 'business-notes',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { BusinessNotesTable, CreateBusinessNoteAction, DeleteBusinessNoteAction },
  data() {
    return {
      showAddBusinessNoteModal: false,
      showDeleteBusinessNoteModal: false,
      noteIdToDelete: null,
      noteToDelete: {},
    };
  },
  computed: {
    ...mapGetters('business', ['businessNotes', 'business', 'listBusinessNotesItemCount']),
    ...mapGetters('ui', ['currentLocale']),
  },
  watch: {
    async business() {
      await this.fetchBusinessNotes();
    },
  },
  methods: {
    openAddBusinessNoteModal() {
      this.showAddBusinessNoteModal = true;
    },
    closeAddBusinessNoteModal() {
      this.showAddBusinessNoteModal = false;
    },
    openDeleteBusinessNoteModal(noteId) {
      this.noteIdToDelete = noteId;
      this.noteToDelete = this.businessNotes.find((l) => l.id === noteId);
      this.showDeleteBusinessNoteModal = true;
    },
    closeDeleteBusinessNoteModal() {
      this.noteIdToDelete = null;
      this.noteToDelete = {};
      this.showDeleteBusinessNoteModal = false;
    },
    async deleteBusinessNoteSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'business.delete_note_action.success' });
      this.closeDeleteBusinessNoteModal();
    },
    async addBusinessNoteSuccess() {
      await this.fetchBusinessNotes();
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'business.create_note_action.success' });
      this.closeAddBusinessNoteModal();
    },
    showAddBusinessNoteFeature() {
      return this.canManageOffer(); // TODO change permission
    },
    async fetchBusinessNotes() {
      await this.executeAction({ action: 'business/listBusinessNotes' }, { business_id: this.business.id });
    },
  },
};
</script>
