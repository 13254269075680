<template>
  <div>
    <table class="responsive-table">
      <thead>
        <tr>
          <td>{{ $t('instant_card_batches.batches_table.id') }}</td>
          <td>{{ $t('instant_card_batches.batches_table.name') }}</td>
          <td>{{ $t('instant_card_batches.batches_table.program') }}</td>
          <td>{{ $t('instant_card_batches.batches_table.project') }}</td>
          <td>{{ $t('instant_card_batches.batches_table.owner') }}</td>
          <td>{{ $t('instant_card_batches.batches_table.shipping_address') }}</td>
          <td>{{ $t('instant_card_batches.batches_table.quantity') }}</td>
          <td>{{ $t('instant_card_batches.batches_table.card_package_id') }}</td>
          <td>{{ $t('instant_card_batches.batches_table.created') }}</td>
          <td>{{ $t('instant_card_batches.batches_table.status') }}</td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>
            <h5>{{ $t('instant_card_batches.batches_table.id') }}:</h5>
            <router-link :to="{ name: 'instant_card_batch_details', params: { batchId: item.id } }">
              <span>{{ item.id }}</span>
            </router-link>
          </td>
          <td>
            <h5>{{ $t('instant_card_batches.batches_table.name') }}:</h5>
            <span>{{ item.name }}</span>
          </td>
          <td>
            <h5>{{ $t('instant_card_batches.batches_table.program') }}:</h5>
            <span :class="!item.partner_id ? 'info-not-available' : ''">{{ item.partner_id | orNotAvailable }}</span>
          </td>
          <td>
            <h5>{{ $t('instant_card_batches.batches_table.project') }}:</h5>
            <router-link
              v-if="item.instant_card_project_id"
              :to="{ name: 'instant_card_project_details', params: { projectId: item.instant_card_project_id } }"
            >
              <span>{{ item.instant_card_project_name }} ({{ item.instant_card_project_id }})</span>
            </router-link>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('instant_card_batches.batches_table.owner') }}:</h5>
            <router-link v-if="item.business_id" :to="{ name: 'business_details', params: { businessId: item.business_id } }">
              <span>{{ item.business_name }} ({{ item.business_id }})</span>
            </router-link>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('instant_card_batches.batches_table.shipping_address') }}:</h5>
            <span>{{ fullAddress(item.delivery_address) }}</span>
          </td>
          <td align="right">
            <h5>{{ $t('instant_card_batches.batches_table.quantity') }}:</h5>
            <span :class="!item.quantity ? 'info-not-available' : ''">{{ item.quantity | orNotAvailable }}</span>
          </td>
          <td>
            <h5>{{ $t('instant_card_batches.batches_table.card_package_id') }}:</h5>
            <span :class="!item.card_package_id ? 'info-not-available' : ''">{{ item.card_package_id | orNotAvailable }}</span>
          </td>
          <td>
            <h5>{{ $t('instant_card_batches.batches_table.created') }}:</h5>
            <span :class="!item.creation_date ? 'info-not-available' : ''">{{ item.creation_date | formatShortDateInverted | orNotAvailable }}</span>
          </td>
          <td>
            <h5>{{ $t('instant_card_batches.batches_table.status') }}:</h5>
            <span :class="!item.status ? 'info-not-available' : `batch-${item.status}`">{{
              $t(`instant_card_batches.status.${item.status}`) | orNotAvailable
            }}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-xs-center">
      <v-pagination v-if="listPageCount > 1" v-model="pagination" :length="listPageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'instant-cards-batches-table',
  mixins: [actionErrorTrackable, security],
  components: {},
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      pagination: 1,
    };
  },
  mounted() {
    this.pagination = this.listPageNumber;
  },
  computed: {
    ...mapGetters('instantCardBatch', [
      'listPageCount',
      'listPageNumber',
      'listKeyword',
      'listProjectId',
      'listBusinessId',
      'listPartnerId',
      'listStatus',
    ]),
  },
  methods: {
    fullAddress(address) {
      return `${address.street}, ${address.city}, ${address.state}, ${address.country}, ${address.zip}`;
    },
  },
  watch: {
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'instantCardBatch/listBatches',
        },
        {
          page: newValue,
          keyword: this.listKeyword,
          partner_id: this.listPartnerId,
          project_id: this.listProjectId,
          business_id: this.listBusinessId,
          status: this.listStatus,
        },
      );
    },
  },
};
</script>
