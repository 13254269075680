<template>
  <div v-if="userExists()">
    <h3>{{ $t(`task.list.user.title`) }}</h3>
    <v-layout row wrap align-center justify-start class="data-card--content-zone">
      <v-flex xs12 class="d-inline-flex data-card--content-zone__upper">
        <label-text :label="$t(`task.list.user.id`)">
          <p slot="labelTextLink" class="label-text--value">
            <v-layout row>
              <link-secondary-route :can-access="canViewApplication()" :route-name="'user_details'" :route-params="{ userId: accountId }">{{
                accountId
              }}</link-secondary-route>
            </v-layout>
          </p>
        </label-text>
        <label-text :label="$t(`task.list.user.first_name`)" :value="user.first_name" data-test="user-first-name"></label-text>
        <label-text :label="$t(`task.list.user.last_name`)" :value="user.last_name" data-test="user-last-name"></label-text>
        <label-text
          :label="$t(`task.list.user.creation_date`)"
          :value="user.creation_date | formatDateHourMin"
          data-test="user-creation-date"
        ></label-text>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';

export default {
  name: 'user-list-item-part',
  mixins: [actionErrorTrackable, security],
  props: {
    accountId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      user: {},
    };
  },
  async mounted() {
    this.user = await this.executeAction({ action: 'task/getUser' }, this.accountId);
  },
  methods: {
    userExists() {
      return !!this.user.id;
    },
  },
};
</script>
