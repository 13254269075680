<template>
  <table class="responsive-table">
    <thead>
      <tr>
        <td>{{ $t('publishers.publishers_table.id') }}</td>
        <td>{{ $t('publishers.publishers_table.name') }}</td>
        <td v-if="exclude !== 'business'">{{ $t('publishers.publishers_table.owner') }}</td>
        <td v-if="exclude !== 'branding'">{{ $t('publishers.publishers_table.branding') }}</td>
        <td>{{ $t('publishers.publishers_table.stores') }}</td>
        <td>{{ $t('publishers.publishers_table.offers') }}</td>
        <td>{{ $t('publishers.publishers_table.created') }}</td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in items" :key="index">
        <td>
          <h5>{{ $t('publishers.publishers_table.id') }}:</h5>
          <span>
            <router-link :to="{ name: 'closed_loop_details', params: { publishersId: item.id } }">
              {{ item.id }}
            </router-link>
          </span>
        </td>
        <td>
          <h5>{{ $t('publishers.publishers_table.name') }}:</h5>
          <span>{{ item.name }}</span>
        </td>
        <td v-if="exclude !== 'business'">
          <h5>{{ $t('publishers.publishers_table.owner') }}:</h5>
          <span>
            <router-link v-if="item.business_id" :to="{ name: 'business_details', params: { businessId: item.business_id } }">
              {{ `${item.business.name} (${item.business_id})` }}
            </router-link>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </span>
        </td>
        <td v-if="exclude !== 'branding'">
          <h5>{{ $t('publishers.publishers_table.branding') }}:</h5>
          <router-link v-if="item.branding_id" :to="{ name: 'branding_details', params: { brandingId: item.branding_id } }">
            {{ `${item.branding.name} (${item.branding_id})` }}
          </router-link>
          <label-none v-else>{{ $t('system.na') }}</label-none>
        </td>
        <td align="center">
          <h5>{{ $t('publishers.publishers_table.stores') }}:</h5>
          <span>{{ item.stores_count }}</span>
        </td>
        <td align="center">
          <h5>{{ $t('publishers.publishers_table.offers') }}:</h5>
          {{ item.offers_count }}
        </td>
        <td>
          <h5>{{ $t('publishers.publishers_table.created') }}:</h5>
          <span>{{ item.creation_date | formatShortDateInverted | orNotAvailable }}</span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';

export default {
  name: 'shared-publishers-table',
  mixins: [actionErrorTrackable, security],
  props: {
    items: {
      type: Array,
      required: true,
    },
    exclude: {
      type: String, // Acceptable values: ['business', 'branding']
      required: false,
    },
  },
};
</script>
