<template>
  <v-autocomplete
    v-bind="$attrs"
    v-on="listeners"
    v-model="newValue"
    :items="theItems"
    :loading="isLoading"
    item-text="text"
    :item-value="itemValue"
    :search-input.sync="searchNew"
    no-filter
  />
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';

export default {
  inheritAttrs: false,
  mixins: [actionErrorTrackable],
  name: 'h-autocomplete',
  props: {
    value: {
      required: true,
    },
    searchAction: {
      type: String,
      required: true,
    },
    itemValue: {
      type: String,
      default: 'id',
    },
    alternateText: {
      required: false,
    },
    alternateId: {
      type: String,
      required: false,
    },
    extraSearchParams: {
      type: Object,
      required: false,
    },
    addAsteriskToKeyword: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newValue: this.value,
      isLoading: false,
      searchNew: '',
      itemSelections: [],
    };
  },
  async mounted() {
    if (!this.extraSearchParams) {
      await this.fetchItems(this.value);
    }
  },
  watch: {
    async value(val) {
      if (!this.extraSearchParams) {
        await this.fetchItems(val);
      }
      this.newValue = val;
    },
    newValue() {
      const newRawValue = _find(this.itemSelections, (i) => i.id === this.newValue);

      this.$emit('selectedValue', newRawValue);
      this.$emit('input', this.newValue);
    },
    async searchNew(searchValue) {
      if (searchValue === null || !searchValue) return;
      if (this.isLoading) return;

      // Note (LH) - Should not re-trigger search for pre-filled fields. This happens when alternate-id and alternate-text are both used together as theItems watcher updates text which re-triggers search-input.sync event
      const newRawValue = _find(this.itemSelections, (i) => i.text === searchValue);
      if (newRawValue && newRawValue.id === this.newValue) return;

      try {
        this.isLoading = true;

        const extractedSearchKeyword = this.getLastPart(searchValue.matchAll(/\(([^)]+)\)/g));
        if (extractedSearchKeyword && extractedSearchKeyword === this.newValue) {
          return;
        }

        if (searchValue && !_isEmpty(searchValue.trim())) {
          if (this.addAsteriskToKeyword) {
            await this.fetchItems(searchValue.trim() + '*');
          } else {
            await this.fetchItems(searchValue.trim());
          }
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
  computed: {
    listeners() {
      const { input, ...listeners } = this.$listeners;
      return listeners;
    },
    theItems() {
      return _map(this.itemSelections, (it) => {
        it.text = '';

        let text = it.name;
        if (this.alternateText) {
          if (typeof this.alternateText === 'function') {
            text = this.alternateText(it);
          } else {
            text = it[this.alternateText];
          }
        }
        let id = it.id;
        if (this.alternateId) {
          id = it[this.alternateId];
        }

        it.text = `${text} (${id})`;

        return it;
      });
    },
  },
  methods: {
    clear() {
      this.isLoading = false;
      this.searchNew = '';
      this.itemSelections = [];
    },
    async fetchItems(keyword) {
      const params = { page: 1, limit: 10, keyword };

      if (this.extraSearchParams) {
        _forEach(this.extraSearchParams, (searchParam, index) => {
          params[index] = searchParam;
        });
      }

      const searchResults = await this.executeAction({ action: this.searchAction }, params);

      this.itemSelections = searchResults.items;

      this.$emit('itemsFetched', this.itemSelections);

      return searchResults.items;
    },
    getLastPart(result) {
      let part = null;
      let nextResult = null;

      do {
        nextResult = result.next();
        if (nextResult.value) {
          part = nextResult.value[1];
        }
      } while (!nextResult.done);

      return part;
    },
  },
};
</script>
