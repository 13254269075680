import _ from 'lodash';

export default {
  sortExistingKeys: (value, orderPaths) => {
    if (orderPaths && orderPaths.length) {
      return _.compact(orderPaths.map((path) => (_.get(value, path) ? path : undefined)));
    }

    return [];
  },

  sortObjectsExistingKeys: (orderPaths, ...objects) => {
    const uniqueKeys = objects.reduce(addKeyToSetReducer, new Set());

    function addKeyToSetReducer(acc, value) {
      Object.keys(value).forEach((key) => acc.add(key));
      return acc;
    }

    return orderPaths.filter((key) => uniqueKeys.has(key));
  },
};
