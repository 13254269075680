<template>
  <card-modal
    icon="fal fa-money-check-edit"
    :title="$t('instant_card_projects.update_gift_certificate_action.title')"
    context="updateInstantCardProjectOffer"
    @submit="submit"
    @cancel="cancel"
    cancel_text="instant_card_projects.update_gift_certificate_action.button_cancel"
    submit_text="instant_card_projects.update_gift_certificate_action.button_save"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-flex sm4 xs12>
          <v-text-field
            v-model="currentProjectOffer.payment_amount"
            :label="$t('instant_card_projects.update_gift_certificate_action.payment_amount')"
            name="payment_amount"
            append-icon="fas fa-dollar-sign"
            v-validate="'integer|min_value:0|max_value:1000'"
            :error-messages="errors.collect('payment_amount')"
            data-vv-name="payment_amount"
            :data-vv-as="$t('instant_card_projects.update_gift_certificate_action.payment_amount')"
            data-test="update-project-offer--payment_amount"
          ></v-text-field>
        </v-flex>
        <v-flex sm4 xs12>
          <v-text-field
            v-model="currentProjectOffer.bonus_amount"
            :label="$t('instant_card_projects.update_gift_certificate_action.bonus_amount') + '*'"
            name="bonus_amount"
            append-icon="fas fa-dollar-sign"
            v-validate="{
              required: true,
              integer: true,
              min_value: 0,
              max_value: 1000,
              sumLessThanOrEqual: {
                field: currentProjectOffer.payment_amount,
                total: 1000,
                totalFieldLabel: $t('instant_card_projects.update_gift_certificate_action.total_amount'),
              },
            }"
            :error-messages="errors.collect('bonus_amount')"
            data-vv-name="bonus_amount"
            :data-vv-as="$t('instant_card_projects.update_gift_certificate_action.bonus_amount')"
            data-test="update-project-offer--bonus_amount"
            required
          ></v-text-field>
        </v-flex>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'update-project-gift-certificate-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    projectOffer: {
      type: Object,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentProjectOffer: {},
    };
  },
  async created() {
    this.currentProjectOffer = Object.assign({}, _.cloneDeep(this.projectOffer));
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    clear() {
      this.currentProjectOffer = { incentive: {} };
      this.$validator.reset();
    },
    success() {
      this.clear();
      this.$emit('updated');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    buildPayload() {
      return {
        id: this.currentProjectOffer.id,
        payment_amount: this.currentProjectOffer.payment_amount,
        bonus_amount: this.currentProjectOffer.bonus_amount,
        project_id: this.projectId,
      };
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();
      if (validationComplete) {
        await this.executeAction(
          {
            action: 'instantCardProject/updateInstantCardProjectOffer',
            name: 'updateInstantCardProjectOffer',
            success: this.success,
          },
          this.buildPayload(),
        );
      }
    },
  },
};
</script>
