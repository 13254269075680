<template>
  <v-card class="card">
    <v-breadcrumbs
      :items="[
        {
          text: this.topLabel,
          disabled: !!!this.to,
          to: this.to,
          exact: true,
        },
        {
          text: this.lastLabel,
          disabled: true,
        },
      ]"
    ></v-breadcrumbs>
  </v-card>
</template>

<script>
export default {
  name: 'two-levels-breadcrumbs',
  props: {
    topLabel: {
      type: String,
      required: false,
    },
    to: {
      type: Object,
      required: false,
      default: null,
    },
    lastLabel: {
      type: String,
      required: false,
    },
  },
};
</script>
