<template>
  <div>
    <layout-notes :items="items" @delete="deleteNote" />
    <div class="text-xs-center">
      <v-pagination v-if="listBatchNotesPageCount > 1" v-model="pagination" :length="listBatchNotesPageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'batch-notes-table',
  mixins: [actionErrorTrackable, security],
  props: {
    items: {
      type: Array,
    },
    batchId: {
      type: String,
    },
  },
  data() {
    return {
      pagination: 1,
    };
  },
  mounted() {
    this.pagination = this.listBatchNotesPageNumber;
  },
  watch: {
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'instantCardBatch/listBatchNotes',
        },
        {
          batch_id: this.batchId,
          page: newValue,
        },
      );
    },
  },
  computed: {
    ...mapGetters('instantCardBatch', ['listBatchNotesPageNumber', 'listBatchNotesPageCount', 'listBatchNotesItemCount']),
  },
  methods: {
    deleteNote(noteId) {
      this.$emit('batch_note_delete_action', noteId);
    },
  },
};
</script>
