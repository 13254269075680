<template>
  <table class="responsive-table">
    <thead>
      <tr>
        <td>{{ $t('business.bank_account_table.fields.business_name') }}</td>
        <td>{{ $t('business.bank_account_table.fields.bank_account_number') }}</td>
        <td>{{ $t('business.bank_account_table.fields.created') }}</td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ item.business_name }}</td>
        <td>{{ item.bank_account_number }}</td>
        <td>{{ item.created | formatShortDateInverted | orNotAvailable }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'business-bank-account-table',
  props: {
    item: {
      type: Object,
    },
  },
};
</script>
