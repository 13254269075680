<template>
  <div>
    <modal-confirm
      :show-modal="activateWebhookModalVisible"
      :title="$t('business.webhooks.activate_modal.title')"
      context="BusinessWebhookActivate"
      :text="$t('business.webhooks.activate_modal.text', { webhookName: currentWebhook.name })"
      cancel_text="business.webhooks.activate_modal.cancel"
      submit_text="business.webhooks.activate_modal.submit"
      :submit_working="working"
      @submit="activateWebhook"
      @close="closeActivateWebhookModal"
    ></modal-confirm>

    <modal-confirm
      :show-modal="disableWebhookModalVisible"
      :title="$t('business.webhooks.disable_modal.title')"
      context="BusinessWebhookDisable"
      :text="$t('business.webhooks.disable_modal.text', { webhookName: currentWebhook.name })"
      cancel_text="business.webhooks.disable_modal.cancel"
      submit_text="business.webhooks.disable_modal.submit"
      :submit_working="working"
      @submit="disableWebhook"
      @close="closeDisableWebhookModal"
    ></modal-confirm>

    <modal-confirm
      :show-modal="removeWebhookModalVisible"
      :title="$t('business.webhooks.remove_modal.title')"
      context="BusinessWebhookRemove"
      :text="$t('business.webhooks.remove_modal.text', { webhookName: currentWebhook.name })"
      cancel_text="business.webhooks.remove_modal.cancel"
      submit_text="business.webhooks.remove_modal.submit"
      :submit_working="working"
      @submit="removeWebhook"
      @close="closeRemoveWebhookModal"
    ></modal-confirm>

    <modal-action :show-modal="editWebhookModalVisible" close>
      <edit-business-webhook
        :webhook="currentWebhook"
        :business-id="businessId"
        @success="businessWebhookUpdated"
        @close="closeEditBusinessWebhookModal"
      ></edit-business-webhook
    ></modal-action>

    <table class="responsive-table" id="offer-activation-codes-table">
      <thead>
        <tr>
          <td>{{ $t('business.webhooks.fields.id') }}</td>
          <td>{{ $t('business.webhooks.fields.name') }}</td>
          <td>{{ $t('business.webhooks.fields.uri') }}</td>
          <td>{{ $t('business.webhooks.fields.status') }}</td>
          <td>{{ $t('business.webhooks.fields.offer_listeners_count') }}</td>
          <td>{{ $t('business.webhooks.fields.action') }}</td>
        </tr>
      </thead>
      <tbody>
        <tr class="business-webhook" v-for="(item, index) in items" :key="index">
          <td>
            <h5>{{ $t('business.webhooks.fields.id') }}:</h5>
            <span> {{ item.id }}</span>
          </td>
          <td>
            <h5>{{ $t('business.webhooks.fields.name') }}</h5>
            <span> {{ item.name }} </span>
          </td>
          <td>
            <h5>{{ $t('business.webhooks.fields.uri') }}</h5>
            <span>
              {{ item.uri | truncateEllipsis(30) }}
            </span>
          </td>
          <td>
            <h5>{{ $t('business.webhooks.fields.status') }}</h5>
            <span :class="{ 'status-active': isActive(item), 'status-disabled': isDisabled(item) }">
              {{ $t(`business.webhooks.status.${item.status}`) }}
            </span>
          </td>
          <td align="center">
            <h5>{{ $t('business.webhooks.fields.offer_listeners_count') }}</h5>
            <span>
              {{ item.offer_listeners_count }}
            </span>
          </td>
          <td>
            <h5>{{ $t('business.webhooks.fields.action') }}</h5>

            <a v-show="isDisabled(item)" @click="showActivateWebhookModal(item)" class="button-subtle"
              >{{ $t('business.webhooks.actions.activate') }}
            </a>
            <a v-show="isActive(item)" @click="showDisableWebhookModal(item)" class="button-subtle">{{ $t('business.webhooks.actions.disable') }}</a>
            &nbsp;
            <a class="button-subtle" @click="showEditWebhookModal(item)">{{ $t('business.webhooks.actions.edit') }}</a>
            &nbsp;
            <a v-if="item.offer_listeners_count === 0" class="button-subtle" @click="showRemoveWebhookModal(item)">{{
              $t('business.webhooks.actions.remove')
            }}</a>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-xs-center">
      <v-pagination v-if="businessWebhooksPageCount > 1" v-model="currentPage" :length="businessWebhooksPageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import { mapGetters } from 'vuex';
import EditBusinessWebhook from '../actions/EditBusinessWebhook';
export default {
  name: 'business-webhooks-table',
  mixins: [security, actionErrorTrackable],
  components: { EditBusinessWebhook },
  props: {
    businessId: {
      type: String,
    },
    items: {
      type: Array,
    },
  },
  data() {
    return {
      currentWebhook: {},
      currentPage: 1,
      disableWebhookModalVisible: false,
      activateWebhookModalVisible: false,
      removeWebhookModalVisible: false,
      editWebhookModalVisible: false,
    };
  },
  mounted() {
    this.pagination = this.businessWebhooksPageNumber;
  },
  computed: {
    ...mapGetters('business', ['businessWebhooksPageNumber', 'businessWebhooksPageCount']),
  },
  watch: {
    async currentPage(newValue) {
      this.fetchWebhooks();
    },
  },
  methods: {
    async fetchWebhooks() {
      await this.executeAction(
        {
          action: 'business/listBusinessWebhooks',
        },
        {
          page: this.currentPage,
          id: this.businessId,
        },
      );
    },
    isActive(item) {
      return item.status === 'active';
    },
    isDisabled(item) {
      return item.status === 'disabled';
    },
    showDisableWebhookModal(webhook) {
      this.currentWebhook = webhook;
      this.disableWebhookModalVisible = true;
    },
    showActivateWebhookModal(webhook) {
      this.currentWebhook = webhook;
      this.activateWebhookModalVisible = true;
    },
    showRemoveWebhookModal(webhook) {
      this.currentWebhook = webhook;
      this.removeWebhookModalVisible = true;
    },
    showEditWebhookModal(webhook) {
      this.currentWebhook = {
        id: webhook.id,
        name: webhook.name,
        uri: webhook.uri,
        authorization: webhook.authorization,
      };
      this.editWebhookModalVisible = true;
    },
    closeDisableWebhookModal() {
      this.disableWebhookModalVisible = false;
    },
    closeActivateWebhookModal() {
      this.activateWebhookModalVisible = false;
    },
    closeRemoveWebhookModal() {
      this.removeWebhookModalVisible = false;
    },

    async disableWebhook() {
      await this.executeAction(
        {
          name: 'BusinessWebhookDisable',
          action: 'business/disableBusinessWebhook',
          success: () => {
            this.closeDisableWebhookModal();
            this.fetchWebhooks();
          },
        },
        { businessId: this.businessId, webhookId: this.currentWebhook.id },
      );
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: `business.webhooks.disable_modal.success` });
    },
    async activateWebhook() {
      await this.executeAction(
        {
          name: 'BusinessWebhookActivate',
          action: 'business/activateBusinessWebhook',
          success: () => {
            this.closeActivateWebhookModal();
            this.fetchWebhooks();
          },
        },
        { businessId: this.businessId, webhookId: this.currentWebhook.id },
      );
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: `business.webhooks.activate_modal.success` });
    },

    async removeWebhook() {
      await this.executeAction(
        {
          name: 'BusinessWebhookRemove',
          action: 'business/removeBusinessWebhook',
          success: () => {
            this.closeRemoveWebhookModal();
            this.fetchWebhooks();
          },
        },
        { businessId: this.businessId, webhookId: this.currentWebhook.id },
      );
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: `business.webhooks.remove_modal.success` });
    },
    async businessWebhookUpdated() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: `business.webhooks.edit_modal.success` });
      this.editWebhookModalVisible = false;
      await this.fetchWebhooks();
    },
    closeEditBusinessWebhookModal() {
      this.editWebhookModalVisible = false;
    },
  },
};
</script>
