<template>
  <view-main title="page_title.workstation_details">
    <four-levels-breadcrumbs
      slot="content"
      :top-label="$t('navigation.spaces')"
      :top-to="{ name: 'spaces' }"
      :second-label="spaceName"
      :second-to="{ name: 'space_details', params: { spaceId } }"
      :third-label="$t('navigation.workstations')"
      :last-label="workstation.name"
    />
    <workstation-information slot="content"></workstation-information>
    <workstation-registration-request slot="content"></workstation-registration-request>
  </view-main>
</template>

<script>
import { actionErrorTrackable, renderErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import WorkstationInformation from '@/components/workstation/detail/WorkstationInformation.vue';
import WorkstationRegistrationRequest from '@/components/workstation/detail/WorkstationRegistrationRequest.vue';

export default {
  name: 'workstation-detail-view',
  mixins: [renderErrorTrackable, actionErrorTrackable],
  components: { WorkstationRegistrationRequest, WorkstationInformation },
  props: {
    workstationId: {
      type: String,
      required: true,
    },
    spaceId: {
      type: String,
      required: true,
    },
  },
  data() {
    return { spaceName: '' };
  },
  computed: {
    ...mapGetters('partnerSecurity', ['workstation']),
  },
  async mounted() {
    await this.fetch();
  },
  watch: {
    async workstationId() {
      return this.fetch();
    },
  },
  methods: {
    async fetch() {
      const workstation = await this.executeAction({ action: 'partnerSecurity/getWorkstation' }, this.workstationId);
      if (!workstation) {
        this.$router.replace({ name: 'notFound' });
      }

      const space = await this.executeAction({ action: 'space/getSpace' }, this.spaceId);
      if (space) {
        this.spaceName = space.name;

        await this.executeAction({ action: 'space/listAllActiveSpacePartnerEstablishments' }, { partner_id: space.partner_id });
      }
    },
  },
};
</script>
