<template>
  <card-data class="card-info hc-card-section">
    <template slot="headerZoneLeft">
      <v-icon>fal fa-credit-card-front</v-icon>
      <h3>{{ card.card_program_type === 'gpr' ? $t('card.card_info.title') : $t('card.card_info.title_instant') }}</h3>
    </template>

    <template slot="headerZoneRight">
      <label-text v-if="accountId" :label="$t(`card.card_info.account_id`)">
        <p slot="labelTextLink" class="label-text--value">
          <v-layout row>
            <link-secondary-route
              class="hc-public-token"
              :can-access="canViewApplication()"
              :route-name="'user_details'"
              :route-params="{ userId: accountId }"
              >{{ accountId }}</link-secondary-route
            >
            <button class="clipboard" v-clipboard:copy="accountId">
              <v-icon small>file_copy</v-icon>
            </button>
          </v-layout>
        </p>
      </label-text>
      <div v-if="accountId" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="$t(`card.card_info.cardholder_id`)">
        <p slot="labelTextLink" class="label-text--value">
          <v-layout row class="smoke-card-info--cardholder-id--single">
            {{ card.cardholder.id }}
            <button class="clipboard" v-clipboard:copy="card.cardholder.id">
              <v-icon small>file_copy</v-icon>
            </button>
          </v-layout>
        </p>
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="$t(`card.card_info.public_token`)">
        <p v-if="cards.length === 1" slot="labelTextLink" class="label-text--value">
          <v-layout row class="smoke-card-info--public-token--single">
            {{ card.public_token }}
            <button class="clipboard" v-clipboard:copy="card.public_token">
              <v-icon small>file_copy</v-icon>
            </button>
          </v-layout>
        </p>
        <p v-else slot="labelTextLink" class="label-text--value">
          <v-layout data-test="public-token-multiple" row align-center class="label-text-value-as-dropdown">
            <v-select
              class="pt-0"
              :items="cards"
              item-value="public_token"
              item-text="public_token"
              v-model="selectedCardPublicToken"
              @change="cardSelected"
            >
              <template slot="item" slot-scope="{ item }">
                <span data-test="card-info-public-token-item" class="pr-3">{{ item.public_token }}</span>
                <span :class="`status-${item.status}`">{{ $t(`code.card.status.${item.status}`) }}</span>
              </template>
            </v-select>
            <button class="clipboard" v-clipboard:copy="selectedCardPublicToken">
              <v-icon small>file_copy</v-icon>
            </button>
          </v-layout>
        </p>
      </label-text>
      <label-text :label="$t(`card.card_info.pan`)" :value="card.card_masked_pan">
        <p slot="labelTextLink" class="label-text--value">
          <v-layout row>
            {{ card.card_masked_pan }}
            <button class="clipboard" v-clipboard:copy="cardLastFourDigits">
              <v-icon small>file_copy</v-icon>
            </button>
          </v-layout>
        </p>
      </label-text>
      <label-text :label="$t(`card.card_info.expiration`)" :value="card.card_expiration | formatYearHyphenMonth"></label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="$t(`card.card_info.date_issued`)" :value="card.date_issued | formatDateNoTime"></label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text
        :label="$t(`card.card_info.status`)"
        :value="$t(`code.card.status.${card.status}`)"
        :class-array="[`card-status-${card.status}`]"
      ></label-text>
      <div v-if="actions.reduce((acc, val) => val.isAvailable() || acc, false)" class="card-header-divider--vertical hidden-xs-only">
        <v-divider vertical></v-divider>
      </div>
      <button-secondary-actions data-test="card-actions" class="card-info--actions-aml" :text="$t('card.button_actions')" :actions="actions">
        <template slot="regeneratecard">
          <modal-action :show-modal="showRegenerateCardModal">
            <regenerate-card-action
              :hello-show="showRegenerateCardModal"
              :partner-id="card.partner_id"
              :card_program_type="card.card_program_type"
              :cardholder-language="cardholder.language"
              :public-token="card.public_token"
              :cardholder-address="cardholder.address"
              @close="closeModalRegenerateCard"
              @action="resetPageState"
            ></regenerate-card-action>
          </modal-action>
        </template>

        <template slot="updatecardholder">
          <modal-action :show-modal="showUpdateCardholderModal">
            <update-cardholder-info-action
              :cardholder="cardholder"
              :public-token="card.public_token"
              @close="closeModalUpdateCardholder"
              @action="resetPageState"
            ></update-cardholder-info-action>
          </modal-action>
        </template>

        <template slot="getCardContextTransaction">
          <modal-action :show-modal="showGetCardContextTransactionModal" :max-width="1024">
            <get-card-context-transaction-action
              @close="closeModalGetCardContextTransaction"
              @action="resetPageState"
            ></get-card-context-transaction-action>
          </modal-action>
        </template>

        <template slot="activationcode">
          <modal-action :show-modal="showActivationCodeModal">
            <get-activation-code-action
              :public-token="card.public_token"
              :birth-date="card.cardholder.date_of_birth"
              @close="closeModalActivationCode"
              @action="resetPageState"
            ></get-activation-code-action>
          </modal-action>
        </template>

        <template slot="amlnote">
          <modal-action :show-modal="showNoteModal">
            <create-aml-note-action
              :public-token="card.public_token"
              :cardholder-key="card.cardholder.key"
              :card-program-key="card.card_program_key"
              :card-application-id="card.cardholder.card_application_id"
              :account-id="card.account_id"
              @close="closeModalNote"
              @action="resetPageState"
            ></create-aml-note-action>
          </modal-action>
        </template>

        <template slot="blockcard">
          <modal-action :show-modal="showBlockCardModal">
            <modal-confirm
              :show-modal="showBlockCardModal"
              :title="$t('card.block_card_action.title')"
              :text="$t('card.block_card_action.text')"
              cancel_text="card.block_card_action.cancel_text"
              submit_text="card.block_card_action.submit_text"
              @submit="blockCard"
              @close="resetPageState"
              context="blockCard"
            ></modal-confirm>
          </modal-action>
        </template>

        <template slot="activatecard">
          <modal-action :show-modal="showActivateCardModal">
            <modal-confirm
              :show-modal="showActivateCardModal"
              :title="$t('card.activate_card_action.title')"
              :text="$t('card.activate_card_action.text')"
              cancel_text="card.activate_card_action.cancel_text"
              submit_text="card.activate_card_action.submit_text"
              @submit="activateCard"
              @close="resetPageState"
              context="activateCard"
            ></modal-confirm>
          </modal-action>
        </template>

        <template slot="unblockcard">
          <modal-action :show-modal="showUnblockCardModal">
            <modal-confirm
              :show-modal="showUnblockCardModal"
              :title="$t('card.unblock_card_action.title')"
              :text="$t('card.unblock_card_action.text')"
              cancel_text="card.unblock_card_action.cancel_text"
              submit_text="card.unblock_card_action.submit_text"
              @submit="unblockCard"
              @close="resetPageState"
              context="unblockCard"
            ></modal-confirm>
          </modal-action>
        </template>

        <template slot="unblockcardpin">
          <modal-action :show-modal="showUnblockCardPinModal">
            <modal-confirm
              :show-modal="showUnblockCardPinModal"
              :title="$t('card.unblock_card_pin_action.title')"
              :text="$t('card.unblock_card_pin_action.text')"
              cancel_text="card.unblock_card_pin_action.cancel_text"
              submit_text="card.unblock_card_pin_action.submit_text"
              @submit="unblockCardPin"
              @close="resetPageState"
              context="unblockCardPin"
            ></modal-confirm>
          </modal-action>
        </template>

        <template slot="reportloststolen">
          <modal-action :show-modal="showReportLostStolenModal">
            <report-lost-stolen-card-action
              :hello-show="showReportLostStolenModal"
              :partner-id="card.partner_id"
              :cardholder-language="cardholder.language"
              :card_program_type="card.card_program_type"
              :public-token="card.public_token"
              :cardholder-address="cardholder.address"
              @close="closeModalReportLostStolen"
              @action="resetPageState"
            ></report-lost-stolen-card-action>
          </modal-action>
        </template>

        <template slot="renewcard">
          <modal-action :show-modal="showRenewCardModal">
            <renew-card-action
              :hello-show="showRenewCardModal"
              :public-token="card.public_token"
              :card_program_type="card.card_program_type"
              :card-status="card.status"
              :cardholder-language="cardholder.language"
              :partner-id="card.partner_id"
              :cardholder-address="cardholder.address"
              @close="closeModalRenewCard"
              @action="resetPageState"
            ></renew-card-action>
          </modal-action>
        </template>

        <template slot="lockcard">
          <modal-action :show-modal="showLockCardModal">
            <modal-confirm
              :show-modal="showLockCardModal"
              :title="$t('card.lock_card_action.title')"
              :text="$t('card.lock_card_action.text')"
              cancel_text="card.lock_card_action.cancel_text"
              submit_text="card.lock_card_action.submit_text"
              @submit="lockCard"
              @close="resetPageState"
              context="lockCard"
            ></modal-confirm>
          </modal-action>
        </template>

        <template slot="unlockcard">
          <modal-action :show-modal="showUnlockCardModal">
            <modal-confirm
              :show-modal="showUnlockCardModal"
              :title="$t('card.unlock_card_action.title')"
              :text="$t('card.unlock_card_action.text')"
              cancel_text="card.unlock_card_action.cancel_text"
              submit_text="card.unlock_card_action.submit_text"
              @submit="unlockCard"
              @close="resetPageState"
              context="unlockCard"
            ></modal-confirm>
          </modal-action>
        </template>

        <template slot="deactivatecard">
          <modal-action :show-modal="showDeactivateCardModal">
            <modal-confirm
              :show-modal="showDeactivateCardModal"
              :title="$t('card.deactivate_card_action.title')"
              :text="$t('card.deactivate_card_action.text')"
              cancel_text="card.deactivate_card_action.cancel_text"
              submit_text="card.deactivate_card_action.submit_text"
              @submit="deactivateCard"
              @close="resetPageState"
              context="deactivateCard"
            ></modal-confirm>
          </modal-action>
        </template>

        <template slot="getpin">
          <modal-action :show-modal="showGetPinModal">
            <get-card-pin-action
              :public-token="card.public_token"
              :hello-show="showGetPinModal"
              @close="closeModalGetPin"
              @action="resetPageState"
            ></get-card-pin-action>
          </modal-action>
        </template>
      </button-secondary-actions>
    </template>

    <template slot="contentZoneUpper">
      <div v-if="cardHasBeenRenewed" class="has-been-renewed-alert--wrap">
        <v-alert class="has-been-renewed-alert" type="info" :value="true">
          {{ $t('card.card_info.has_been_renewed') }}
        </v-alert>
      </div>

      <label-text :label="$t(`card.cardholder_info.first_name`)" :value="card.cardholder.first_name" :class-array="['larger-text']"></label-text>
      <label-text :label="$t(`card.cardholder_info.last_name`)" :value="card.cardholder.last_name" :class-array="['larger-text']"></label-text>
      <label-text
        :label="$t(`card.cardholder_info.date_of_birth`)"
        :value="card.cardholder.date_of_birth"
        :class-array="['larger-text']"
      ></label-text>
      <label-text
        :label="$t(`card.cardholder_info.address`)"
        :value="fullAddress"
        :class-array="['larger-text card-info--address-field']"
      ></label-text>
    </template>

    <template slot="contentZoneMiddle">
      <label-text :label="$t(`card.cardholder_info.phone`)" :value="card.cardholder.phone"></label-text>
      <label-text :label="$t(`card.cardholder_info.mobile`)" :value="card.cardholder.mobile"></label-text>
      <label-text :label="$t(`card.cardholder_info.email`)" :value="card.cardholder.email"></label-text>
      <label-text :label="$t(`card.cardholder_info.language`)" :value="card.cardholder.language"></label-text>
      <label-text :label="$t(`card.card_info.product`)" :value="card.card_product_id"></label-text>
      <label-text :label="$t(`card.card_info.package`)" :value="card.card_package_id"></label-text>
      <label-text :label="$t(`card.card_info.sponsor`)" :value="card.partner_id"></label-text>
      <label-text :label="$t(`card.card_info.external_id`)" :value="card.card_external_id ? card.card_external_id : $t(`system.na`)"></label-text>
      <label-text
        :label="$t(`card.cardholder_info.settings.alerts`)"
        :value="
          card.cardholder.settings.alerts
            ? $t(`code.card.cardholder.settings.alerts.${card.cardholder.settings.alerts}`)
            : $t(`code.card.cardholder.settings.alerts.false`)
        "
      ></label-text>
      <label-text
        v-if="card.cardholder.card_application_id"
        :label="$t(`card.card_info.application_id`)"
        :value="card.cardholder.card_application_id"
      >
        <p slot="labelTextLink" class="label-text--value">
          <v-layout row>
            <link-secondary-route
              :can-access="canViewApplication()"
              :route-name="'application'"
              :route-params="{ applicationId: card.cardholder.card_application_id }"
              >{{ card.cardholder.card_application_id }}</link-secondary-route
            >
            <button class="clipboard" v-clipboard:copy="card.cardholder.card_application_id">
              <v-icon small>file_copy</v-icon>
            </button>
          </v-layout>
        </p>
      </label-text>
    </template>

    <template slot="footerZone">
      <v-layout row wrap class="card-info-footer-zone--inner">
        <v-flex xs12 md6 class="card-info-footer-zone--left">
          <v-btn v-if="isDev()" align-bottom small round outline color="primary" class="dashboard-link-btn">
            <router-link target="_blank" :to="{ name: 'card_activities_dashboard', query: { cardholder_id: cardholder.id, timerange: '~' } }">
              {{ $t('card.card_info.bi.activity') }}
            </router-link>
            <v-icon small>navigate_next</v-icon>
          </v-btn>
          <v-btn v-if="isDev()" align-bottom small round outline color="primary" class="dashboard-link-btn">
            <router-link target="_blank" :to="{ name: 'debits_credits_dashboard', query: { cardholder_id: cardholder.id, timerange: '~' } }">
              {{ $t('card.card_info.bi.debits_credits') }}
            </router-link>
            <v-icon small>navigate_next</v-icon>
          </v-btn>
          <v-btn v-if="isDev()" align-bottom small round outline color="primary" class="dashboard-link-btn">
            <router-link target="_blank" :to="{ name: 'transactions_dashboard', query: { cardholder_id: cardholder.id, timerange: '~' } }">
              {{ $t('card.card_info.bi.transactions') }}
            </router-link>
            <v-icon small>navigate_next</v-icon>
          </v-btn>
        </v-flex>
        <v-flex 12 md6 class="card-info-footer-zone--right"> </v-flex>
      </v-layout>
    </template>
  </card-data>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';

import UpdateCardholderInfoAction from '../actions/UpdateCardholderInfoAction';
import GetCardContextTransactionAction from '../actions/GetCardContextTransactionAction';
import ReportLostStolenCardAction from '../actions/ReportLostStolenCardAction';
import RegenerateCardAction from '../actions/RegenerateCardAction';
import RenewCardAction from '../actions/RenewCardAction';
import CreateAmlNoteAction from '@/components/system/AML/CreateAmlNoteAction';
import GetCardPinAction from '../actions/GetCardPinAction';

import GetActivationCodeAction from '../actions/GetActivationCodeAction';
import _ from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'card-info',
  mixins: [actionErrorTrackable, security],
  components: {
    UpdateCardholderInfoAction,
    GetCardContextTransactionAction,
    GetActivationCodeAction,
    CreateAmlNoteAction,
    ReportLostStolenCardAction,
    RegenerateCardAction,
    RenewCardAction,
    GetCardPinAction,
  },
  props: {
    accountId: {
      type: String,
      required: false,
    },
    card: {
      type: Object,
      required: false,
    },
    cards: {
      type: Array,
      required: false,
    },
    amlStatus: {
      type: Object,
      required: false,
    },
    partnerCardRenewPackage: {
      type: Object,
      required: false,
    },
  },
  data() {
    const $t = this.$t.bind(this);
    return {
      selectedCardPublicToken: this.card.public_token,
      cardholder: this.card.cardholder,
      showActivationCodeModal: false,
      showNoteModal: false,
      showGetPinModal: false,
      showUpdateCardholderModal: false,
      showGetCardContextTransactionModal: false,
      showReportLostStolenModal: false,
      showRenewCardModal: false,
      showActivateCardModal: false,
      showBlockCardModal: false,
      showUnblockCardModal: false,
      showLockCardModal: false,
      showUnlockCardModal: false,
      showDeactivateCardModal: false,
      showRegenerateCardModal: false,
      showUnblockCardPinModal: false,
      actions: [
        {
          slotName: 'updatecardholder',
          name: $t('card.button_update_cardholder'),
          action: this.showModalUpdateCardholder,
          isAvailable: () => this.canManageCard() && !['lost_stolen', 'deactivated', 'expired'].includes(this.card.status),
        },
        {
          slotName: 'activationcode',
          name: $t('card.button_get_activation_code'),
          action: this.showModalActivationCode,
          isAvailable: () => this.canManageCard() && this.card.status === 'pending_activation',
        },
        {
          slotName: 'activatecard',
          name: $t('card.button_activate_card'),
          action: this.showModalActivateCard,
          isAvailable: () => this.canManageCard() && this.card.status === 'pending_activation',
        },
        {
          slotName: 'getpin',
          name: $t('card.button_get_pin'),
          action: this.showModalGetPin,
          isAvailable: () => !['lost_stolen', 'deactivated', 'expired'].includes(this.card.status),
        },
        {
          slotName: 'unblockcardpin',
          name: $t('card.button_unblock_card_pin'),
          action: this.showModalUnblockCardPin,
          isAvailable: () => this.canManageCard() && ['active', 'locked'].includes(this.card.status),
        },
        {
          slotName: 'lockcard',
          name: $t('card.button_lock_card'),
          action: this.showModalLockCard,
          isAvailable: () => this.isAgentInternal() && this.card.status === 'active',
        },
        {
          slotName: 'unlockcard',
          name: $t('card.button_unlock_card'),
          action: this.showModalUnlockCard,
          isAvailable: () => this.isAgentInternal() && this.card.status === 'locked',
        },
        {
          slotName: 'blockcard',
          name: $t('card.button_block_card'),
          action: this.showModalBlockCard,
          isAvailable: () => this.canManageAmlProgram() && ['active', 'pending_activation', 'locked'].includes(this.card.status),
        },
        {
          slotName: 'unblockcard',
          name: $t('card.button_unblock_card'),
          action: this.showModalUnblockCard,
          isAvailable: () => this.canManageAmlProgram() && this.card.status === 'blocked',
        },
        {
          slotName: 'renewcard',
          name: $t('card.button_renew_card'),
          action: this.showModalRenewCard,
          isAvailable: () => this.isRenewPossible,
        },
        {
          slotName: 'regeneratecard',
          name: $t('card.button_regenerate_card'),
          action: this.showModalRegenerateCard,
          isAvailable: () => this.isRegeneratePossible,
        },
        {
          slotName: 'reportloststolen',
          name: $t('card.button_report_lost_stolen'),
          action: this.showModalReportLostStolen,
          isAvailable: () => this.isAgentInternal() && ['active', 'pending_activation'].includes(this.card.status),
        },
        {
          slotName: 'deactivatecard',
          name: $t('card.button_deactivate_card'),
          action: this.showModalDeactivateCard,
          isDisabled: () => this.cardHasBalance(),
          isAvailable: () => this.isAgentInternal() && ['active', 'pending_activation', 'locked'].includes(this.card.status),
        },
        {
          slotName: 'getCardContextTransaction',
          name: $t('card.simulate_transaction'),
          action: this.showModalGetCardContextTransaction,
          isAvailable: () => this.canManageCard(),
          isDisabled: () => ['pending_activation', 'lost_stolen', 'deactivated', 'expired'].includes(this.card.status),
        },
      ],
    };
  },
  computed: {
    ...mapGetters('card', ['cardPin']),
    cardHasBeenRenewed() {
      let renewedCardPublicToken = null;

      _.forEach(this.cards, (card) => {
        if (card.public_token !== this.card.public_token && card.previous_public_token && card.status === 'pending_activation') {
          renewedCardPublicToken = card.public_token;
        }
      });

      return renewedCardPublicToken !== null;
    },
    classicProgram() {
      return this.card.card_program_type === 'gpr';
    },
    selectedCard() {
      return _.find(this.cards, (c) => {
        return c.public_token === this.selectedCardPublicToken;
      });
    },
    cardLastFourDigits() {
      return this.card.card_masked_pan.substring(12, 16);
    },
    fullAddress() {
      return _.join(
        _.compact([
          this.card.cardholder.address.street,
          this.card.cardholder.address.city,
          this.card.cardholder.address.state,
          this.card.cardholder.address.country,
          this.card.cardholder.address.zip,
        ]),
        ', ',
      );
    },
    isRegeneratePossible() {
      return (
        this.partnerCardRenewPackage &&
        this.card.status === 'lost_stolen' &&
        otherWorkingCard(this.card, this.cards) &&
        cardIsMostRecent(this.card, this.cards)
      );
    },
    isRenewPossible() {
      const allowedCardStatus = ['active', 'expired', 'deactivated'];
      return (
        this.partnerCardRenewPackage &&
        this.isAgentInternal() &&
        allowedCardStatus.includes(this.card.status) &&
        otherWorkingCard(this.card, this.cards)
      );
    },
  },
  watch: {
    card() {
      this.cardholder = this.card.cardholder;
    },
  },
  methods: {
    cardHasBalance() {
      return _.get(this.card, 'card_balance', 0) > 0;
    },
    cardSelected() {
      this.$emit('card-change', this.selectedCardPublicToken);
    },
    showModalGetPin() {
      this.showGetPinModal = true;
    },
    closeModalGetPin() {
      this.showGetPinModal = false;
    },
    showModalUpdateCardholder() {
      this.showUpdateCardholderModal = true;
    },
    closeModalUpdateCardholder() {
      this.showUpdateCardholderModal = false;
    },
    async showModalGetCardContextTransaction() {
      await this.executeAction({ action: 'card/resetCardTransactionContext' });
      this.showGetCardContextTransactionModal = true;
    },
    closeModalGetCardContextTransaction() {
      this.showGetCardContextTransactionModal = false;
    },
    showModalActivationCode() {
      this.showActivationCodeModal = true;
    },
    closeModalActivationCode() {
      this.showActivationCodeModal = false;
    },
    showModalReportLostStolen() {
      this.showReportLostStolenModal = true;
    },
    showModalRenewCard() {
      this.showRenewCardModal = true;
    },
    showModalActivateCard() {
      this.showActivateCardModal = true;
    },
    closeModalActivateCard() {
      this.showActivateCardModal = false;
    },
    closeModalReportLostStolen() {
      this.showReportLostStolenModal = false;
    },
    closeModalRenewCard() {
      this.showRenewCardModal = false;
    },
    showModalNote() {
      this.showNoteModal = true;
    },
    closeModalNote() {
      this.showNoteModal = false;
    },
    showModalBlockCard() {
      this.showBlockCardModal = true;
    },
    closeModalBlockCard() {
      this.showBlockCardModal = false;
    },
    showModalUnblockCard() {
      this.showUnblockCardModal = true;
    },
    closeModalUnblockCard() {
      this.showUnblockCardModal = false;
    },
    showModalLockCard() {
      this.showLockCardModal = true;
    },
    closeModalLockCard() {
      this.showLockCardModal = false;
    },
    showModalUnlockCard() {
      this.showUnlockCardModal = true;
    },
    closeModalUnlockCard() {
      this.showUnlockCardModal = false;
    },
    showModalDeactivateCard() {
      this.showDeactivateCardModal = true;
    },
    closeModalDeactivateCard() {
      this.showDeactivateCardModal = false;
    },
    showModalRegenerateCard() {
      this.showRegenerateCardModal = true;
    },
    closeModalRegenerateCard() {
      this.showRegenerateCardModal = false;
    },
    showModalUnblockCardPin() {
      this.showUnblockCardPinModal = true;
    },
    closeModalUnblockCardPin() {
      this.showUnblockCardPinModal = false;
    },
    resetPageState() {
      this.showGetPinModal = false;
      this.showUpdateCardholderModal = false;
      this.showGetCardContextTransactionModal = false;
      this.showActivationCodeModal = false;
      this.showReportLostStolenModal = false;
      this.showRenewCardModal = false;
      this.showActivateCardModal = false;
      this.showNoteModal = false;
      this.showBlockCardModal = false;
      this.showUnblockCardModal = false;
      this.showLockCardModal = false;
      this.showUnlockCardModal = false;
      this.showDeactivateCardModal = false;
      this.showUnblockCardPinModal = false;
      this.showRegenerateCardModal = false;
    },
    async blockCard() {
      await this.executeAction(
        {
          action: 'card/blockCard',
          success: this.success,
        },
        this.card.public_token,
      );
      this.closeModalBlockCard();
    },
    async activateCard() {
      await this.executeAction(
        {
          action: 'card/activateCard',
          success: this.success,
        },
        this.card.public_token,
      );
      this.closeModalBlockCard();
    },
    async unblockCard() {
      await this.executeAction(
        {
          action: 'card/unblockCard',
          success: this.success,
        },
        this.card.public_token,
      );
      this.closeModalUnblockCard();
    },
    async unblockCardPin() {
      await this.executeAction(
        {
          action: 'card/unblockCardPin',
          success: this.success,
        },
        this.card.public_token,
      );
      this.closeModalUnblockCardPin();
    },
    async lockCard() {
      await this.executeAction(
        {
          action: 'card/lockCard',
          success: this.success,
        },
        this.card.public_token,
      );
      this.closeModalLockCard();
    },
    async unlockCard() {
      await this.executeAction(
        {
          action: 'card/unlockCard',
          success: this.success,
        },
        this.card.public_token,
      );
      this.closeModalUnlockCard();
    },
    async deactivateCard() {
      await this.executeAction(
        {
          action: 'card/deactivateCard',
          success: this.success,
        },
        this.card.public_token,
      );
      this.closeModalDeactivateCard();
    },
  },
};

function otherWorkingCard(currentCard, allCards) {
  const otherCardForbiddenStatuses = ['active', 'blocked', 'locked', 'pending_activation'];
  const filterOutCurrentCard = (otherCard) => currentCard.public_token !== otherCard.public_token;
  const cardIsNotInForbiddenStatus = (otherCard) => !otherCardForbiddenStatuses.includes(otherCard.status);

  return allCards.filter(filterOutCurrentCard).every(cardIsNotInForbiddenStatus);
}

function cardIsMostRecent(currentCard, allCards) {
  if (allCards.length === 1) {
    return true;
  }

  const sortedCards = _.sortBy(allCards, ['date_issued']);
  const lastCard = _.last(sortedCards);

  return lastCard.public_token === currentCard.public_token;
}
</script>
