<template>
  <card :title="$t('user.vouchers')" icon="">
    <template slot="headerZoneRight">
      <label-text
        class="text-sm-center"
        :label="$t('user.details.vouchers_section.voucher_count')"
        :value="accountVouchersItemCount.toString()"
      ></label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="`${$t('user.details.purses_section.action')}`">
        <router-link
          target="_blank"
          slot="labelTextLink"
          :to="{ name: 'vouchers_operations_dashboard', query: { user_id: account.id, timerange: '~' } }"
        >
          {{ $t('user.details.vouchers_section.view_vouchers_operations') }}
        </router-link>
      </label-text>
    </template>
    <template slot="content">
      <user-vouchers-table v-if="accountVouchers.length" :items="accountVouchers"></user-vouchers-table>
      <label-none v-else>{{ $t('card.comments_section.no_found') }}</label-none>
      <div class="text-xs-center">
        <v-pagination
          v-if="accountVouchersPageCount > 1"
          class="apps-table--pagination"
          v-model="pagination"
          :length="accountVouchersPageCount"
        ></v-pagination>
      </div>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import UserVouchersTable from './UserVouchersTable';
import { mapGetters } from 'vuex';

export default {
  name: 'user-vouchers',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { UserVouchersTable },
  data() {
    return {
      pagination: 1,
    };
  },
  computed: {
    ...mapGetters('account', ['account', 'accountVouchers', 'accountVouchersPageCount', 'accountVouchersPageNumber', 'accountVouchersItemCount']),
    ...mapGetters('ui', ['currentLocale']),
  },
  watch: {
    async account() {
      await this.fetchVouchers();
    },
    accountVouchersPageNumber() {
      this.pagination = this.accountVouchersPageNumber;
    },
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'account/changeAccountVoucherPage',
          success: this.success,
        },
        {
          id: this.account.id,
          page: newValue,
        },
      );
    },
  },
  methods: {
    async fetchVouchers() {
      await this.executeAction({ action: 'account/listVouchersByAccountId' }, { id: this.account.id, page: this.accountVouchersPageNumber });
    },
  },
};
</script>
