<template>
  <card-modal
    icon=""
    :title="$t('franchise.update_action.title')"
    context="updateFranchise"
    @submit="submit"
    @cancel="cancel"
    cancel_text="franchise.update_action.button_cancel"
    submit_text="franchise.update_action.button_save"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-text-field
          autofocus
          :label="$t('franchise.update_action.name') + '*'"
          name="name"
          v-validate="'required|max:100'"
          :error-messages="errors.collect('name')"
          v-model="currentFranchise.name"
          data-vv-name="name"
          maxlength="100"
          :data-vv-as="$t('franchise.update_action.name')"
          required
        ></v-text-field>
        <h-autocomplete
          v-model="currentFranchise.branding_id"
          :label="$t('franchise.update_action.branding') + '*'"
          v-validate="'required|max:50'"
          :error-messages="errors.collect('branding')"
          name="branding"
          data-vv-name="branding"
          data-vv-as="branding"
          search-action="branding/autocompleteSearch"
          data-test="add_franchise--branding"
          no-filter
        >
        </h-autocomplete>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'update-franchise-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    franchise: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentFranchise: {},
    };
  },
  async created() {
    this.currentFranchise = _.cloneDeep(this.franchise);
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    clear() {
      this.currentFranchise = {};
      this.$validator.reset();
    },
    success(response) {
      this.clear();
      this.$emit('updated');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();
      if (validationComplete) {
        await this.executeAction(
          {
            action: 'franchise/updateFranchise',
            name: 'updateFranchise',
            success: this.success,
          },
          this.buildPayload(),
        );
      }
    },
    buildPayload() {
      const payload = Object.assign({}, _.omit(this.currentFranchise, ['creation_date', 'branding']));
      return payload;
    },
  },
};
</script>
