import * as VueGoogleMaps from 'vue2-google-maps';

export default ({ app, Vue }) => {
  Vue.use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      libraries: 'places',
    },
  });
};
