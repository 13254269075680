<template>
  <card-modal
    icon=""
    :title="$t('offers.details.activation_codes_section.cancel_action.title')"
    context="cancelActivationCode"
    @submit="submit"
    @cancel="cancel"
    cancel_text="offers.details.activation_codes_section.cancel_action.button_cancel"
    submit_text="offers.details.activation_codes_section.cancel_action.button_save"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <template>
          <v-layout row pad-form-row-elements>
            <v-flex sm12 xs12>
              <v-text-field
                ref="reason"
                name="reason"
                v-model="reason"
                :label="$t('offers.details.activation_codes_section.cancel_action.reason') + '*'"
                v-validate="{ required: true, max: 1204 }"
                :error-messages="errors.collect('reason')"
                data-vv-name="reason"
                :data-vv-as="$t('offers.details.activation_codes_section.cancel_action.reason')"
                data-test="update-activationCode--reason"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </template>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'cancel-activation-code-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    activationCodeId: {
      type: String,
    },
  },
  data() {
    return {
      currentActivationCode: {},
      reason: '',
    };
  },
  async mounted() {
    this.currentActivationCode = {};
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    clear() {
      this.currentActivationCode = {};
      this.$validator.reset();
    },
    success() {
      this.clear();
      this.$emit('canceled');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();
      if (validationComplete) {
        await this.executeAction(
          {
            action: 'activationCode/cancelActivationCode',
            name: 'cancelActivationCode',
            success: this.success,
          },
          {
            id: this.activationCodeId,
            reason: this.reason,
          },
        );
      }
    },
  },
};
</script>
