<template>
  <card :title="$t('space.details.branding_section.title')" icon="">
    <template slot="headerZoneRight">
      <!--      NOTE: component like StoreBranding, for when actions added in -->
    </template>
    <template slot="content">
      <space-branding-table v-if="branding" :item="branding"></space-branding-table>
      <label-none v-if="!branding">{{ $t('system.none') }}</label-none>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import SpaceBrandingTable from './SpaceBrandingTable';
import { mapGetters } from 'vuex';

export default {
  name: 'space-branding',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { SpaceBrandingTable },
  data() {
    return {
      branding: null,
    };
  },
  watch: {
    async space() {
      await this.fetchBranding();
    },
  },
  computed: {
    ...mapGetters('space', ['space']),
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    async fetchBranding() {
      if (this.space.branding_id) {
        this.branding = await this.executeAction({ action: 'branding/getBranding' }, this.space.branding_id);
      } else {
        this.branding = null;
      }
    },
  },
};
</script>
