<template>
  <view-main title="page_title.establishment_details">
    <four-levels-breadcrumbs
      slot="content"
      :top-label="$t('navigation.spaces')"
      :top-to="{ name: 'spaces' }"
      :second-label="spaceName"
      :second-to="{ name: 'space_details', params: { spaceId } }"
      :third-label="$t('navigation.establishments')"
      :last-label="establishment.id"
    />
    <establishment-information slot="content"></establishment-information>
  </view-main>
</template>

<script>
import { actionErrorTrackable, renderErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import EstablishmentInformation from '@/components/establishment/detail/EstablishmentInformation.vue';

export default {
  name: 'establishment-detail-view',
  mixins: [renderErrorTrackable, actionErrorTrackable],
  components: { EstablishmentInformation },
  props: {
    establishmentId: {
      type: String,
      required: true,
    },
    spaceId: {
      type: String,
      required: true,
    },
  },
  data() {
    return { spaceName: '' };
  },
  computed: {
    ...mapGetters('space', ['establishment']),
  },
  async mounted() {
    await this.fetch();
  },
  watch: {
    async establishmentId() {
      return this.fetch();
    },
  },
  methods: {
    async fetch() {
      const establishment = await this.executeAction({ action: 'space/getPartnerEstablishment' }, this.establishmentId);
      if (!establishment) {
        this.$router.replace({ name: 'notFound' });
      }

      // TODO (LH): either feed space id when coming to establishment details from outside of space, or get establishment's space somehow
      const space = await this.executeAction({ action: 'space/getSpace' }, this.spaceId);
      if (space) {
        this.spaceName = space.name;
      }
    },
  },
};
</script>
