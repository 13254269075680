<template>
  <div class="app-name--wrap">
    <span class="app-name--text" v-if="app_key">{{ name }}</span>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'app-name',
  props: {
    app_key: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      name: '?',
    };
  },
  methods: {
    ...mapActions('security', ['getAppName']),
  },
  watch: {
    app_key: function(newVal) {
      // NOTE (SG) : Don't use async here, we want to load the name asynchronously and
      // don't want to stop the creation of components until the value as been get.
      this.getAppName(newVal)
        .then((name) => (this.name = name || '?'))
        .catch((e) => console.log(e.stack || e)); // eslint-disable-line no-console
    },
  },
  created() {
    // NOTE (SG) : Don't use async here, we want to load the name asynchronously and
    // don't want to stop the creation of components until the value as been get.
    this.getAppName(this.app_key)
      .then((name) => (this.name = name || '?'))
      .catch((e) => console.log(e.stack || e)); // eslint-disable-line no-console
  },
};
</script>
