<template>
  <card
    :title="$t('activation_code.details.activation_code_information_section.title')"
    icon="fal fa-money-check-alt"
    id="activation_code-informations"
  >
    <template slot="headerZoneRight">
      <label-text :label="`${$t('activation_code.details.activation_code_information_section.id')}`" :value="activationCode.id">
        <p slot="labelTextLink" class="label-text--value">
          <v-layout row>
            {{ activationCode.id }}
            <button class="clipboard" v-clipboard:copy="activationCode.id ? activationCode.id : ''">
              <v-icon small>file_copy</v-icon>
            </button>
          </v-layout>
        </p>
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>

      <label-text v-if="activationCode.batch_id" :label="$t(`activation_code.details.activation_code_information_section.batch_id`)">
        <p slot="labelTextLink" class="label-text--value">
          <v-layout row>
            <link-secondary-route :route-name="'activation_code_batch_details'" :route-params="{ activationCodeBatchId: activationCode.batch_id }">{{
              activationCode.batch_id
            }}</link-secondary-route>
          </v-layout>
        </p>
      </label-text>
      <label-text v-else :label="`${$t('activation_code.details.activation_code_information_section.batch_id')}`" :value="activationCode.batch_id" />

      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>

      <label-text
        :label="`${$t('activation_code.details.activation_code_information_section.system_status.status')}`"
        :value="activationCode.system_status.status"
      >
        <activation-code-status-view slot="labelTextLink" :status="activationCode.system_status.status" />
      </label-text>
      <label-text
        :label="`${$t('activation_code.details.activation_code_information_section.activation_enabled')}`"
        :value="$t(`boolean.${activationCode.activation_enabled}`)"
      />

      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text
        :label="`${$t('activation_code.details.activation_code_information_section.created')}`"
        :value="activationCode.creation_date | formatShortDateInverted | orNotAvailable"
      />
      <div v-if="canManageOffer()" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <link-secondary-action slot="labelTextLink">
        <button-secondary-actions class="application-info--actions" :text="$t(`system.actions`)" :actions="actions" />
      </link-secondary-action>
    </template>
    <template slot="content">
      <modal-action :show-modal="showRedeemActivationCodeModal">
        <redeem-activation-code-action
          v-if="showRedeemActivationCodeModal"
          :offer-id="activationCode.offer_id"
          :activation-code-id="activationCode.id"
          :activation-start-date="activationCode.voucher ? activationCode.voucher.activation.start_date : null"
          :hello-show="showRedeemActivationCodeModal"
          @close="closeRedeemActivationCodeModal"
          @created="redeemActivationCodeSuccess"
        />
      </modal-action>
      <modal-action :show-modal="showCancelActivationCodeModal">
        <cancel-activation-code-action
          v-if="showCancelActivationCodeModal"
          :activation-code-id="activationCode.id"
          :hello-show="showCancelActivationCodeModal"
          @close="closeCancelActivationCodeModal"
          @canceled="cancelActivationCodeSuccess"
        />
      </modal-action>
      <modal-action :show-modal="showDownloadActivationCodePdfModal">
        <download-activation-code-pdf-action
          v-if="showDownloadActivationCodePdfModal"
          :activation-code-id="activationCode.id"
          @close="closeDownloadActivationCodePdfModal"
          @action="closeDownloadActivationCodePdfModal"
        />
      </modal-action>
      <modal-action :show-modal="showUpdateActivationCodeModal">
        <update-activation-code-action
          v-if="showUpdateActivationCodeModal"
          :activation-code="activationCode"
          @close="closeUpdateActivationCodeModal"
          @updated="updateActivationCodeSuccess"
        />
      </modal-action>
      <modal-confirm
        v-if="showResendActivationCodeModal"
        :show-modal="showResendActivationCodeModal"
        :title="$t(`offers.details.activation_codes_section.resend_action.confirm.title`)"
        context="deactivateActivationCode"
        :text="
          $t(`offers.details.activation_codes_section.resend_action.confirm.text`, {
            activationCodeName: `${activationCode.recipient.email} - (${activationCode.id})`,
          })
        "
        :cancel_text="`offers.details.activation_codes_section.resend_action.confirm.button_cancel`"
        :submit_text="`offers.details.activation_codes_section.resend_action.confirm.button_submit`"
        :submit_working="working"
        @submit="resendActivationCode"
        @close="closeResendActivationCodeModal"
      ></modal-confirm>
      <modal-confirm
        v-if="showPreActivateModal"
        :show-modal="showPreActivateModal"
        :title="$t(`offers.details.activation_codes_section.pre_activate_action.confirm.title`)"
        context="preActivate"
        :text="$t(`offers.details.activation_codes_section.pre_activate_action.confirm.text`, { activationCodeName: `${activationCode.id}` })"
        :cancel_text="`offers.details.activation_codes_section.pre_activate_action.confirm.button_cancel`"
        :submit_text="`offers.details.activation_codes_section.pre_activate_action.confirm.button_submit`"
        :submit_working="working"
        @submit="preActivate"
        @close="closePreActivateModal"
      ></modal-confirm>
      <modal-confirm
        v-if="showMarkAsNonPreActivatedModal"
        :show-modal="showMarkAsNonPreActivatedModal"
        :title="$t(`offers.details.activation_codes_section.mark_as_non_pre_activated_action.confirm.title`)"
        context="markAsNonPreActivated"
        :text="
          $t(`offers.details.activation_codes_section.mark_as_non_pre_activated_action.confirm.text`, { activationCodeName: `${activationCode.id}` })
        "
        :cancel_text="`offers.details.activation_codes_section.mark_as_non_pre_activated_action.confirm.button_cancel`"
        :submit_text="`offers.details.activation_codes_section.mark_as_non_pre_activated_action.confirm.button_submit`"
        :submit_working="working"
        @submit="markAsNonPreActivated"
        @close="closeMarkAsNonPreActivatedModal"
      ></modal-confirm>
      <v-layout row>
        <v-flex xs3>
          <label-text
            :label="$t(`activation_code.details.activation_code_information_section.amount`)"
            :value="activationCode.amount | dollarSignI18n"
          />
        </v-flex>
        <v-flex xs3>
          <label-text
            :label="$t(`activation_code.details.activation_code_information_section.payment_amount`)"
            :value="activationCode.payment_amount | dollarSignI18n"
          />
        </v-flex>
        <v-flex xs3>
          <label-text
            :label="$t(`activation_code.details.activation_code_information_section.bonus_amount`)"
            :value="activationCode.bonus_amount | dollarSignI18n"
          />
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs3>
          <label-text
            :label="$t(`activation_code.details.activation_code_information_section.purchase_channel`)"
            :value="activationCode.purchase_channel ? $t('purchase_channel.' + activationCode.purchase_channel) : null"
          ></label-text>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs3>
          <label-text
            :label="$t(`activation_code.details.activation_code_information_section.recipient_email`)"
            :value="activationCode.recipient.email"
          >
            <p slot="labelTextLink" class="label-text--value">
              <span v-if="activationCode.recipient.email">{{ activationCode.recipient.email }}</span>
              <label-none v-else>{{ $t('system.na') }}</label-none>
              <button v-if="activationCode.recipient.email" class="clipboard" v-clipboard:copy="activationCode.recipient.email">
                <v-icon small>file_copy</v-icon>
              </button>
            </p>
          </label-text>
        </v-flex>
        <v-flex xs3>
          <label-text
            :label="$t(`activation_code.details.activation_code_information_section.recipient_mobile_number`)"
            :value="activationCode.recipient.mobile_number"
          >
            <p slot="labelTextLink" class="label-text--value">
              <span v-if="activationCode.recipient.mobile_number">{{ activationCode.recipient.mobile_number }}</span>
              <label-none v-else>{{ $t('system.na') }}</label-none>
              <button v-if="activationCode.recipient.mobile_number" class="clipboard" v-clipboard:copy="activationCode.recipient.mobile_number">
                <v-icon small>file_copy</v-icon>
              </button>
            </p>
          </label-text>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs3>
          <label-text
            :label="$t(`activation_code.details.activation_code_information_section.recipient_first_name`)"
            :value="activationCode.recipient.first_name"
          />
        </v-flex>
        <v-flex xs3>
          <label-text
            :label="$t(`activation_code.details.activation_code_information_section.recipient_last_name`)"
            :value="activationCode.recipient.last_name"
          />
        </v-flex>
        <v-flex xs3>
          <label-text
            :label="$t(`activation_code.details.activation_code_information_section.recipient_language`)"
            :value="$t('activation_code.details.languages.' + activationCode.recipient.language)"
          />
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs6>
          <label-text
            :label="$t(`activation_code.details.activation_code_information_section.personalized_message`)"
            :html-value="activationCode.personalized_message ? activationCode.personalized_message.replaceAll('\n', '<br/>') : null"
          />
        </v-flex>
      </v-layout>
      <v-layout row v-if="activationCode.voucher_id">
        <h4>{{ $t('activation_code.details.activation_code_information_section.voucher_title') }}</h4>
      </v-layout>
      <v-layout row v-if="activationCode.voucher_id">
        <v-flex xs3>
          <account-email v-if="activationCode.account_id" :id="activationCode.account_id" :generate-link-to-card="true"></account-email>
          <label-text v-else :label="$t(`activation_code.details.activation_code_information_section.user`)" />
        </v-flex>
        <v-flex xs3>
          <label-text
            :label="$t(`activation_code.details.activation_code_information_section.cardholder_id`)"
            :value="activationCode.voucher.cardholder_id"
          >
            <p slot="labelTextLink">
              <cardholder-current-card v-if="activationCode.voucher.cardholder_id" :cardholder-id="activationCode.voucher.cardholder_id" />
              <label-none v-else>{{ $t('system.na') }}</label-none>
            </p>
          </label-text>
        </v-flex>
      </v-layout>
      <v-layout row v-if="activationCode.voucher_id">
        <v-flex xs3>
          <label-text :label="$t(`activation_code.details.activation_code_information_section.voucher`)" :value="activationCode.voucher_id">
            <p slot="labelTextLink">
              <router-link v-if="activationCode.voucher_id" :to="{ name: 'voucher_details', params: { voucherId: activationCode.voucher_id } }">
                {{ activationCode.voucher_id }}
              </router-link>
              <label-none v-else>{{ $t('system.na') }}</label-none>
            </p>
          </label-text>
        </v-flex>
        <v-flex xs3>
          <label-text :label="$t(`activation_code.details.activation_code_information_section.publisher`)" :value="activationCode.publisher_id">
            <p slot="labelTextLink">
              <router-link
                v-if="activationCode.publisher_id"
                :to="{ name: 'closed_loop_details', params: { publishersId: activationCode.publisher_id } }"
              >
                {{ activationCode.publisher_id }}
              </router-link>
              <label-none v-else>{{ $t('activation_code.details.activation_code_information_section.publisher_na') }}</label-none>
            </p>
          </label-text>
        </v-flex>
        <v-flex xs3>
          <label-text
            v-if="activationCode.redeemed_date"
            :label="$t(`activation_code.details.activation_code_information_section.redeemed_date`)"
            :value="activationCode.redeemed_date | formatShortDateInverted({ withAgo: true })"
          />
          <label-text v-else :label="$t(`activation_code.details.activation_code_information_section.redeemed_date`)" :value="null" />
        </v-flex>
      </v-layout>
      <v-layout row v-if="activationCode.voucher_id">
        <v-flex xs3>
          <label-text
            :label="$t(`activation_code.details.activation_code_information_section.received`)"
            :value="activationCode.voucher.amount | currency | dollarSignI18n"
          />
        </v-flex>
        <v-flex xs3>
          <label-text
            :label="$t(`activation_code.details.activation_code_information_section.remaining`)"
            :value="activationCode.voucher.remaining_amount | currency | dollarSignI18n"
          />
        </v-flex>
      </v-layout>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';

import Vue from 'vue';
import AccountEmail from '@/components/account/AccountEmail.vue';
import ActivationCodeStatusView from '@/components/activation_code/ActivationCodeStatusView.vue';
import RedeemActivationCodeAction from '@/components/activation_code/actions/RedeemActivationCodeAction.vue';
import CancelActivationCodeAction from '@/components/activation_code/actions/CancelActivationCodeAction.vue';
import DownloadActivationCodePdfAction from '@/components/activation_code/actions/DownloadActivationCodePdfAction.vue';
import UpdateActivationCodeAction from '../actions/UpdateActivationCodeAction.vue';
import ActivationCodeStatus from '@/enums/activation-code-status';

export default {
  name: 'activation-code-information',
  components: {
    AccountEmail,
    ActivationCodeStatusView,
    RedeemActivationCodeAction,
    CancelActivationCodeAction,
    DownloadActivationCodePdfAction,
    UpdateActivationCodeAction,
  },
  mixins: [security, actionErrorTrackable, disableWithError],
  data() {
    const $t = this.$t.bind(this);
    return {
      showCancelActivationCodeModal: false,
      showResendActivationCodeModal: false,
      showDownloadActivationCodePdfModal: false,
      showRedeemActivationCodeModal: false,
      showUpdateActivationCodeModal: false,
      showPreActivateModal: false,
      showMarkAsNonPreActivatedModal: false,
      actions: [
        {
          slotName: 'updateActivationCode',
          name: $t('activation_code.details.activation_code_information_section.actions.update.action'),
          action: () => this.openUpdateActivationCodeModal(),
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => !this.showUpdateActivationCodeFeature(),
        },
        {
          slotName: 'PreActivateModal',
          name: this.$t('activation_code.details.activation_code_information_section.actions.pre_activate'),
          action: () => {
            this.openPreActivateCardModal();
          },
          isAvailable: () => this.canManageOffer(), // TODO change
          isDisabled: () => {
            return !this.showPreActivateCardFeature() || this.activationCode.activation_enabled;
          },
        },
        {
          slotName: 'MarkAsNonPreActivatedModal',
          name: this.$t('activation_code.details.activation_code_information_section.actions.mark_as_non_pre_activated'),
          action: () => {
            this.openmarkAsNonPreActivatedModal();
          },
          isAvailable: () => this.canManageOffer(), // TODO change
          isDisabled: () => {
            return !this.showmarkAsNonPreActivatedFeature() || !this.activationCode.activation_enabled;
          },
        },
        {
          slotName: 'cancelActivationCode',
          name: $t('activation_code.details.activation_code_information_section.cancel_activation_code'),
          action: () => this.openCancelActivationCodeModal(),
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => !this.showCancelActivationCodeFeature(),
        },
        {
          slotName: 'redeemActivationCode',
          name: $t('activation_code.details.activation_code_information_section.redeem_activation_code'),
          action: () => this.openRedeemActivationCodeModal(),
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => !this.showRedeemActivationCodeFeature(),
        },
        {
          slotName: 'resendActivationCode',
          name: $t('activation_code.details.activation_code_information_section.resend_activation_code'),
          action: () => this.openResendActivationCodeModal(),
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => !this.showResendActivationCodeFeature(),
        },
        {
          slotName: 'downloadPdf',
          name: $t('activation_code.details.activation_code_information_section.actions.downloadPDF.title'),
          action: () => this.openDownloadActivationCodePdfModal(),
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => !this.showDownloadActivationCodePdfFeature(),
        },
      ],
    };
  },
  computed: {
    ...mapGetters('activationCode', ['activationCode']),
    ...mapGetters('ui', ['currentLocale']),
    activationCodeIsPendingActivation() {
      return this.activationCode.system_status.status === ActivationCodeStatus.PENDING_ACTIVATION;
    },
  },
  methods: {
    openRedeemActivationCodeModal() {
      this.showRedeemActivationCodeModal = true;
    },
    closeRedeemActivationCodeModal() {
      this.showRedeemActivationCodeModal = false;
    },
    async redeemActivationCodeSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'offers.redeem_action.success' });
      this.closeRedeemActivationCodeModal();
    },
    openCancelActivationCodeModal() {
      this.showCancelActivationCodeModal = true;
    },
    closeCancelActivationCodeModal() {
      this.showCancelActivationCodeModal = false;
    },
    async cancelActivationCodeSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: `offers.details.activation_codes_section.cancel_action.success` });
      this.closeCancelActivationCodeModal();
    },
    openResendActivationCodeModal() {
      this.showResendActivationCodeModal = true;
    },
    openDownloadActivationCodePdfModal() {
      this.showDownloadActivationCodePdfModal = true;
    },
    closeDownloadActivationCodePdfModal() {
      this.showDownloadActivationCodePdfModal = false;
    },
    closeResendActivationCodeModal() {
      this.showResendActivationCodeModal = false;
    },
    async resendActivationCodeSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: `offers.details.activation_codes_section.resend_action.success` });
      this.closeResendActivationCodeModal();
    },
    showCancelActivationCodeFeature() {
      return this.canManageOffer() && this.activationCodeIsPendingActivation && !this.activationCode.voucher_id;
    },
    showRedeemActivationCodeFeature() {
      return this.canManageOffer() && this.activationCodeIsPendingActivation;
    },
    showResendActivationCodeFeature() {
      return this.canManageOffer() && this.activationCodeIsPendingActivation;
    },
    showDownloadActivationCodePdfFeature() {
      return this.canManageOffer() && this.activationCode.batch_id;
    },
    showUpdateActivationCodeFeature() {
      return this.canManageOffer() && !this.activationCode.batch_id;
    },
    showPreActivateCardFeature() {
      return this.canManageOffer() && this.activationCodeIsPendingActivation;
    },
    showmarkAsNonPreActivatedFeature() {
      return this.canManageOffer() && this.activationCodeIsPendingActivation;
    },
    openPreActivateCardModal() {
      this.showPreActivateModal = true;
    },
    closePreActivateModal() {
      this.showPreActivateModal = false;
    },
    openmarkAsNonPreActivatedModal() {
      this.showMarkAsNonPreActivatedModal = true;
    },
    closeMarkAsNonPreActivatedModal() {
      this.showMarkAsNonPreActivatedModal = false;
    },
    async goToCardFromCardholderId(cardholderId) {
      const card = await Vue.prototype.$services.card.getLatestCardByCardholderId(cardholderId);
      if (card) {
        this.$router.push({ name: 'card', params: { cardPublicToken: card.public_token } });
      }
    },
    async markAsNonPreActivated() {
      await this.executeAction(
        {
          action: 'activationCode/markAsNonPreActivated',
          name: 'markAsNonPreActivated',
          success: this.markAsNonPreActivatedSuccess,
        },
        { id: this.activationCode.id },
      );
    },
    async markAsNonPreActivatedSuccess() {
      await this.executeAction({ action: 'activationCode/getActivationCode' }, this.activationCode.id);
      await this.executeAction(
        { action: 'ui/showSuccessSnackBar' },
        { text: `activation_code.details.activation_code_information_section.actions.mark_as_non_pre_activated_success` },
      );
      this.closeMarkAsNonPreActivatedModal();
    },
    async preActivate() {
      await this.executeAction(
        {
          action: 'activationCode/preActivate',
          name: 'preActivate',
          success: this.preActivateSuccess,
        },
        { id: this.activationCode.id },
      );
    },
    async preActivateSuccess() {
      await this.executeAction({ action: 'activationCode/getActivationCode' }, this.activationCode.id);
      await this.executeAction(
        { action: 'ui/showSuccessSnackBar' },
        { text: `activation_code.details.activation_code_information_section.actions.pre_activate_success` },
      );
      this.closePreActivateModal();
    },
    async resendActivationCode() {
      await this.executeAction(
        {
          action: 'activationCode/resendActivationCode',
          name: 'resendActivationCode',
          success: this.resendActivationCodeSuccess,
        },
        { id: this.activationCode.id },
      );
    },
    openUpdateActivationCodeModal() {
      this.showUpdateActivationCodeModal = true;
    },
    closeUpdateActivationCodeModal() {
      this.showUpdateActivationCodeModal = false;
    },
    async updateActivationCodeSuccess() {
      await this.executeAction({ action: 'activationCode/getActivationCode' }, this.activationCode.id);
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'offers.details.activation_codes_section.update_action.success' });
      this.closeUpdateActivationCodeModal();
    },
  },
};
</script>
