<template>
  <div>
    <modal-action :show-modal="shownSearchAddressModal" :max-width="500">
      <search-address-modal @close="closeSearchStoreAddressModal" @updated="updateStoreAddressModal" />
    </modal-action>

    <p>
      <a @click="showSearchStoreAddressModal">{{ $t('address.search_address_action.call_to_action_text') }}</a>
    </p>
  </div>
</template>

<script>
import SearchAddressModal from './SearchAddressModal.vue';

export default {
  components: { SearchAddressModal },
  name: 'search-address',
  data() {
    return {
      shownSearchAddressModal: false,
    };
  },
  methods: {
    updateStoreAddressModal({ address, coordinates }) {
      this.$emit('updated', { address, coordinates });
      this.closeSearchStoreAddressModal();
    },
    closeSearchStoreAddressModal() {
      this.shownSearchAddressModal = false;
    },
    showSearchStoreAddressModal() {
      this.shownSearchAddressModal = true;
    },
  },
};
</script>
