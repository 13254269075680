<template>
  <card-modal
    icon=""
    :title="$t('tools.generate_qr_code_modal.title')"
    context="generateQRCodeFromUrl"
    @cancel="cancel"
    cancel_text="tools.generate_qr_code_modal.close"
  >
    <form slot="content" @submit.prevent="generate">
      <v-layout row wrap>
        <v-flex sm9 xs12>
          <v-text-field
            autofocus
            :label="$t('tools.generate_qr_code_modal.url') + ' *'"
            name="url"
            v-model="url"
            autocomplete="off"
            v-validate="{ required: true, url: { require_protocol: true }, max: 255 }"
            :error-messages="errors.collect('url')"
            data-vv-name="url"
            maxlength="255"
            :data-vv-as="$t('tools.generate_qr_code_modal.url')"
          />
        </v-flex>

        <v-flex sm3 xs12>
          <button-primary @click="generate" :text="$t('tools.generate_qr_code_modal.generate')" />
        </v-flex>
      </v-layout>

      <v-layout row wrap>
        <v-flex sm6>
          <div class="qr-code">
            <div v-if="qrCodeImage" class="qrcode-image" v-html="qrCodeImage"></div>
            <div v-else><v-icon class="qrcode-icon">fal fa-qrcode</v-icon></div>
            <a aria-role="button" v-if="hasQrCodeImage" class="download-link button-subtle" @click="downloadQrCode()">{{
              $t('offers.offers_tracking_links.qr_code_modal.download_svg')
            }}</a>
          </div>
        </v-flex>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import Vue from 'vue';

import moment from 'moment';

export default {
  name: 'generate-q-r-code',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {},
  data() {
    return {
      url: null,
      qrCodeImage: null,
      qrCodeId: null,
      qrCodeFileName: null,
    };
  },
  computed: {
    ...mapGetters('offers', ['offerTrackingLinkTypes']),
    hasQrCodeImage() {
      return !!this.qrCodeImage;
    },
  },
  methods: {
    async generate(e) {
      const validated = await this.$validator.validateAll();

      if (validated) {
        await this.executeAction(
          {
            action: 'tools/generateQrCodeFromUrl',
            name: 'generateQRCodeFromUrl',
            success: (response) => {
              const currentDate = moment().format('YYYY-MM-DD-HH-mm-ss');

              this.qrCodeFileName = `qrcode-${currentDate}.svg`;
              this.qrCodeImage = response.qrcode;
            },
          },
          { url: this.url },
        );
      }
    },
    async downloadQrCode() {
      const fileSaver = Vue.prototype.$services.fileSaver;
      const blob = new Blob([this.qrCodeImage], { type: 'svg/plain;charset=utf-8' });

      return fileSaver.saveFile(blob, this.qrCodeFileName);
    },
    clear() {
      this.$validator.reset();

      this.url = null;
      this.qrCodeImage = null;
      this.qrCodeId = null;
      this.qrCodeFileName = null;
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
  },
};
</script>

<style lang="stylus" scoped>
.short-url-and-qr-code {
  display: flex;
}

.short-url {
  width: 50%;
}

.qr-code {
  width: 48%;
}

.qr-code-title, .download-link {
  margin-left: 24px;
}

.qrcode-icon {
  width: 140px;
  height: 140px;
}

.qrcode-image {
  width: 140px;
  height: 140px;
}

a.disabled {
  cursor: default;
  color: grey;
}
</style>
