import _ from 'lodash';

export function calculatePaymentAmount(offer, rewardAmount) {
  if (offer.type === 'bonus') {
    return _.round(rewardAmount / offer.incentive.bonus_percent, 2);
  }

  return rewardAmount;
}

export function adjustValueForMinStep(aMin, aStep, aValue) {
  if (!aMin || !aStep || !aValue) return 0;

  let min = Number(aMin);
  let step = Number(aStep);
  let value = Number(aValue);

  if (!_.isFinite(min) || !_.isFinite(step) || !_.isFinite(value)) return 0;

  if (min === value) return value;
  if (step === 0) return 0;

  const x = Math.max(value - min, 0);
  if (x === 0) return _.round(0, 2);
  const base = Math.floor(x / step);

  return _.round(base * step + min, 2); // eslint-disable-line no-mixed-operators
}
