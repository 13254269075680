<template>
  <card-modal
    icon=""
    :title="$t('instant_card_batches.details.resume_cards_section.title')"
    context="resumeBatchCards"
    @submit="submit"
    @cancel="cancel"
    cancel_text="instant_card_batches.details.resume_cards_section.button_cancel"
    submit_text="instant_card_batches.details.resume_cards_section.button_save"
    :submit_working="working"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <p>{{ $t('instant_card_batches.details.resume_cards_section.note') }}</p>
        <label-text :label="$t('instant_card_batches.details.resume_cards_section.batch')" :value="batchObj.id" />
        <label-text :label="$t('instant_card_batches.details.resume_cards_section.total_cards')" :value="batchCardsItemCount" />
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapActions } from 'vuex';

export default {
  name: 'resume-batch-cards-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    batchObj: {
      type: Object,
      required: true,
    },
    batchCardsItemCount: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapActions('ui', ['addError', 'clearAllError', 'clearError']),
    clear() {
      this.$validator.reset();
    },
    success(response) {
      this.clear();
      this.$emit('resumed');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      let validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        await this.executeAction(
          {
            action: 'instantCardBatch/resumeBatch',
            name: 'resumeBatchCards',
            success: this.success,
          },
          this.batchObj.id,
        );
      }
    },
  },
};
</script>
