<template>
  <card-modal
    icon="fal fa-store-alt"
    :title="$t('offers.details.offers_information_section.corporate_gift_program.update.title')"
    context="updateOfferCorporateDetails"
    @submit="submit"
    @cancel="cancel"
    cancel_text="offers.details.offers_information_section.corporate_gift_program.update.cancel"
    submit_text="offers.details.offers_information_section.corporate_gift_program.update.save"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-layout row wrap pad-form-row-elements>
          <v-flex xs12>
            <v-checkbox
              name="enabled"
              v-model="currentCorporateDetails.enabled"
              :label="$t(`offers.details.offers_information_section.corporate_gift_program.enabled`)"
              :error-messages="errors.collect('enabled')"
              data-vv-name="enabled"
              :data-vv-as="$t(`offers.details.offers_information_section.corporate_gift_program.enabled`)"
              type="checkbox"
            ></v-checkbox>
            <v-select
              :items="voucherStructureOptions"
              name="voucher_structure"
              v-validate="'required'"
              v-model="currentCorporateDetails.voucher_structure"
              :label="$t('offers.details.offers_information_section.corporate_gift_program.update.voucher_structure') + '*'"
              :error-messages="errors.collect('voucher_structure')"
              data-vv-name="voucher_structure"
              :data-vv-as="$t('offers.details.offers_information_section.corporate_gift_program.update.voucher_structure')"
              item-text="name"
              item-value="id"
              data-test="update-corporate-details--voucher_structure"
            ></v-select>
            <v-text-field
              v-model="currentCorporateDetails.minimum_purchase_amount"
              :label="$t('offers.details.offers_information_section.corporate_gift_program.update.minimum_purchase_amount') + '*'"
              name="minimum_purchase_amount"
              append-icon="fas fa-dollar-sign"
              v-validate="{ required: !!currentCorporateDetails.enabled, decimal: 2, max: 6, min_value: 1.0, max_value: 500.0 }"
              :error-messages="errors.collect('minimum_purchase_amount')"
              data-vv-name="minimum_purchase_amount"
              :data-vv-as="$t('offers.details.offers_information_section.corporate_gift_program.update.minimum_purchase_amount')"
              data-test="update-corporate-details--minimum_purchase_amount"
              :disabled="!currentCorporateDetails.enabled"
              required
            ></v-text-field>
            <v-text-field
              v-model="currentCorporateDetails.fixed_fee"
              :label="$t('offers.details.offers_information_section.corporate_gift_program.update.fixed_fee') + '*'"
              name="fixed_fee"
              append-icon="fas fa-dollar-sign"
              v-validate="{ required: !!currentCorporateDetails.enabled, decimal: 2, max: 6, min_value: 0.0, max_value: 100.0 }"
              :error-messages="errors.collect('fixed_fee')"
              data-vv-name="fixed_fee"
              :data-vv-as="$t('offers.details.offers_information_section.corporate_gift_program.update.fixed_fee')"
              data-test="update-corporate-details--fixed_fee"
              :disabled="!currentCorporateDetails.enabled"
              required
            ></v-text-field>
            <v-text-field
              v-model="currentCorporateDetails.percent_fee"
              :label="$t('offers.details.offers_information_section.corporate_gift_program.update.percent_fee') + '*'"
              name="percent_fee"
              append-icon="fas fa-percentage"
              v-validate="{ required: !!currentCorporateDetails.enabled, decimal: 2, max: 6, max_value: 100 }"
              :error-messages="errors.collect('percent_fee')"
              data-vv-name="percent_fee"
              :data-vv-as="$t('offers.details.offers_information_section.corporate_gift_program.update.percent_fee')"
              data-test="update-corporate-details--percent_fee"
              :disabled="!currentCorporateDetails.enabled"
              required
            ></v-text-field>
            <v-text-field
              v-model="currentCorporateDetails.minimum_fee"
              :label="$t('offers.details.offers_information_section.corporate_gift_program.update.minimum_fee') + '*'"
              name="minimum_fee"
              append-icon="fas fa-dollar-sign"
              v-validate="{ required: !!currentCorporateDetails.enabled, decimal: 2, max: 6, min_value: 0.0, max_value: 100.0 }"
              :error-messages="errors.collect('minimum_fee')"
              data-vv-name="minimum_fee"
              :data-vv-as="$t('offers.details.offers_information_section.corporate_gift_program.update.minimum_fee')"
              data-test="update-corporate-details--minimum_fee"
              :disabled="!currentCorporateDetails.enabled"
              required
            ></v-text-field>
            <h-autocomplete
              v-model="currentCorporateDetails.fee_inventory_id"
              :label="$t('offers.details.offers_information_section.corporate_gift_program.update.fee_inventory')"
              clearable
              :error-messages="errors.collect('corporate-inventory')"
              :disabled="!currentCorporateDetails.enabled"
              name="corporate-inventory"
              :alternate-text="inventoryLookupItemText"
              data-vv-name="corporate-inventory"
              data-vv-as="corporate-inventory"
              search-action="inventory/autocompleteSearch"
              data-test="update-offer--corporate-inventory"
            >
            </h-autocomplete>
          </v-flex>
        </v-layout>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import _cloneDeep from 'lodash/cloneDeep';
import _isNull from 'lodash/isNull';
import Vue from 'vue';

export default {
  name: 'update-offer-corporate-details-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    offer: {
      type: Object,
      required: true,
    },
    helloShow: {
      type: Boolean,
    },
  },
  data() {
    return {
      currentCorporateDetails: {
        enabled: false,
        fixed_fee: null,
        minimum_fee: null,
        percent_fee: null,
        fee_inventory_id: null,
        minimum_purchase_amount: null,
        voucher_structure: null,
      },
    };
  },
  async created() {
    this.currentCorporateDetails = Object.assign(
      {
        enabled: false,
        fixed_fee: null,
        minimum_fee: null,
        percent_fee: null,
        fee_inventory_id: null,
        minimum_purchase_amount: null,
        voucher_structure: null,
      },
      _cloneDeep({
        enabled: this.offer.corporate.enabled,
        fixed_fee: this.offer.corporate.fixed_fee,
        minimum_fee: this.offer.corporate.minimum_fee,
        percent_fee: this.offer.corporate.percent_fee,
        fee_inventory_id: this.offer.corporate.fee_inventory_id,
        minimum_purchase_amount: this.offer.corporate.minimum_purchase_amount,
        voucher_structure: this.offer.corporate.voucher_structure,
      }),
    );

    if (_isNull(this.offer.corporate.fixed_fee)) {
      this.currentCorporateDetails.fixed_fee = 0;
      this.currentCorporateDetails.minimum_fee = 5;
      this.currentCorporateDetails.percent_fee = 7.5;
      this.currentCorporateDetails.minimum_purchase_amount = 50;
    }
  },
  watch: {
    async helloShow(flag) {
      if (!flag) {
        this.clear();
      }
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('offers', ['voucherStructures']),
    voucherStructureOptions() {
      return _.map(this.voucherStructures, (item) => ({
        name: this.$t(`offers.voucher_structures.${item}`),
        id: item,
      }));
    },
  },
  methods: {
    inventoryLookupItemText(it) {
      return `${it.name} - ${this.$options.filters.dollarSignI18n(this.$options.filters.currency(it.available_amount))}`;
    },
    clear() {
      this.currentCorporateDetails = {
        enabled: false,
        fixed_fee: null,
        minimum_fee: null,
        percent_fee: null,
        fee_inventory_id: null,
        voucher_structure: null,
      };
      this.searchInventory = null;
      this.$validator.reset();
    },
    success() {
      this.clear();
      this.$emit('updated');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();
      if (validationComplete) {
        await this.executeAction(
          {
            action: 'offers/updateOfferCorporateDetails',
            name: 'updateOfferCorporateDetails',
            success: this.success,
          },
          this.buildPayload(),
        );
      }
    },
    buildPayload() {
      const payload = {
        offer_id: this.offer.id,
        corporate: {
          enabled: this.currentCorporateDetails.enabled,
          fixed_fee: this.currentCorporateDetails.fixed_fee,
          minimum_fee: this.currentCorporateDetails.minimum_fee,
          percent_fee: this.currentCorporateDetails.percent_fee,
          minimum_purchase_amount: this.currentCorporateDetails.minimum_purchase_amount,
          fee_inventory_id: this.currentCorporateDetails.fee_inventory_id ? this.currentCorporateDetails.fee_inventory_id : null,
          voucher_structure: this.currentCorporateDetails.voucher_structure,
        },
      };

      if (!payload.corporate.enabled) {
        payload.corporate.fixed_fee = null;
        payload.corporate.minimum_fee = null;
        payload.corporate.percent_fee = null;
        payload.corporate.minimum_purchase_amount = null;
        payload.corporate.fee_inventory_id = null;
        payload.corporate.voucher_structure = null;
      }

      return payload;
    },
  },
};
</script>
