<template>
  <v-flex class="view-store-form">
    <h1 hidden>{{ $t('store.form.title') }}</h1>
    <v-card>
      <form @submit.prevent="submit" class="form-basic form-search">
        <v-layout align-center wrap class="form-search--main-wrap-v2">
          <v-flex class="form-search--inputs-zone-v2">
            <form-warning-search
              :autofocus="true"
              :searched="searched"
              :hint-message="$t('store.form.total_results_message')"
              class="store--search-field"
              :label="$t('store.form.placeholder')"
              v-model="keyword"
              data-vv-name="search"
              v-validate="'max:50'"
              :max-length="50"
              :error-messages="errors.collect('search')"
            />
            <h-autocomplete
              v-model="business_id"
              :label="$t('store.form.business')"
              :error-messages="errors.collect('business_id')"
              name="business_id"
              data-vv-name="business_id"
              data-vv-as="business_id"
              search-action="business/autocompleteSearch"
              data-test="form-store-business"
              no-filter
              clearable
            >
            </h-autocomplete>
            <v-text-field
              v-model="card_acceptor"
              :label="$t('store.form.card_acceptor')"
              clearable
              data-test="form-store-card-acceptor"
            ></v-text-field>
            <v-select
              name="status"
              v-model="status"
              :error-messages="errors.collect('status')"
              data-vv-name="status"
              :data-vv-as="$t('store.form.status')"
              :items="statusOptions"
              item-text="name"
              item-value="value"
              clearable
              :label="$t('store.form.status')"
              data-test="form-store--status"
            ></v-select>
            <v-select
              name="type"
              v-model="type"
              :error-messages="errors.collect('type')"
              data-vv-name="type"
              :data-vv-as="$t('store.form.type')"
              :items="typeOptions"
              item-text="name"
              item-value="value"
              clearable
              :label="$t('store.form.type')"
              data-test="form-store--type"
            ></v-select>
            <h-autocomplete
              v-model="franchise_id"
              :label="$t('store.form.franchise')"
              v-validate="'max:50'"
              :error-messages="errors.collect('franchise')"
              name="franchise"
              data-vv-name="franchise"
              data-vv-as="franchise"
              search-action="franchise/autocompleteSearch"
              data-test="add_store--franchise"
              no-filter
              clearable
            >
            </h-autocomplete>
            <h-autocomplete
              v-model="destination_id"
              :label="$t('store.form.destination')"
              v-validate="'max:50'"
              :error-messages="errors.collect('destination')"
              name="destination"
              data-vv-name="destination"
              data-vv-as="destination"
              search-action="destination/autocompleteSearch"
              data-test="add_store--destination"
              no-filter
              clearable
            >
            </h-autocomplete>
            <button-primary class="store--btn-search" :text="$t('store.form.submit')" :loading="working" @click="submit" type="submit" />
          </v-flex>

          <v-flex class="form-search--actions-zone-v2">
            <button-primary
              data-test="store--btn-add"
              id="store--btn-add"
              class="store-form--btn-add"
              :loading="working"
              :text="$t('store.form.add')"
              @click="openCreateStoreModal"
            ></button-primary>
          </v-flex>
        </v-layout>
      </form>
    </v-card>

    <v-flex class="data-section">
      <alert-warning v-if="dataReady && stores.length === 0" :text="$t('store.no_store_found')"></alert-warning>
      <card v-if="dataReady && stores.length > 0" icon="fal fa-store" :title="`${$t('store.form.data_section.title')}`">
        <template slot="headerZoneRight">
          <label-text class="text-sm-center" :label="$t('store.form.data_section.stores_count')" :value="listItemCount || '0'"></label-text>
        </template>
        <template slot="content">
          <stores-table :items="stores" />
        </template>
      </card>
    </v-flex>
    <modal-action :show-modal="showCreateStoreModal"
      ><create-store-action v-if="showCreateStoreModal" @close="closeCreateStoreModal" @created="createStoreSuccess"></create-store-action
    ></modal-action>
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable, security } from '@/mixins';
import StoresTable from './StoresTable.vue';
import CreateStoreAction from '../actions/CreateStoreAction';
import AlertWarning from '@/components/system/Alerts/AlertWarning';
import _ from 'lodash';

export default {
  name: 'view-store-form',
  mixins: [actionErrorTrackable, security],
  components: { AlertWarning, StoresTable, CreateStoreAction },
  $_veeValidate: {
    validator: 'new',
  },
  beforeDestroy() {
    this.executeAction({ action: 'store/clearListStores' });
  },
  data() {
    return {
      page: 1,
      dataReady: false,
      keyword: '',
      searched: '',
      business_id: '',
      franchise_id: '',
      destination_id: '',
      card_acceptor: '',
      status: '',
      type: '',
      showCreateStoreModal: false,
      statusOptions: [
        { name: this.$t('store.status.open'), value: 'open' },
        { name: this.$t('store.status.pending'), value: 'pending' },
        { name: this.$t('store.status.closed'), value: 'closed' },
      ],
      typeOptions: [
        { name: this.$t('store.types.place'), value: 'place' },
        { name: this.$t('store.types.web'), value: 'web' },
        { name: this.$t('store.types.custom'), value: 'custom' },
      ],
    };
  },
  async created() {
    this.page = this.listPageNumber;
    this.keyword = this.listKeyword;
    this.business_id = this.listBusinessId;
    this.card_acceptor = this.listCardAcceptor;
    this.destination_id = this.listDestinationId;
    this.franchise_id = this.listFranchiseId;
    this.status = this.listStatus;
    this.type = this.listType;

    if (this.routeHasQueryParams()) {
      this.page = 1;
    }
    if (this.$route.query.keyword) {
      this.keyword = this.$route.query.keyword;
    }
    if (this.$route.query.business_id) {
      this.business_id = this.$route.query.business_id;
    }
    if (this.$route.query.franchise_id) {
      this.franchise_id = this.$route.query.franchise_id;
    }
    if (this.$route.query.destination_id) {
      this.destination_id = this.$route.query.destination_id;
    }
    if (this.$route.query.card_acceptor) {
      this.card_acceptor = this.$route.query.card_acceptor;
    }
    if (this.$route.query.status) {
      this.status = this.$route.query.status;
    }
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
    }

    this.routeHasQueryParams() ? this.submit(null, false, this.page) : this.submit(null, true, this.page);
  },
  computed: {
    ...mapGetters('store', [
      'stores',
      'listKeyword',
      'listPageNumber',
      'listBusinessId',
      'listCardAcceptor',
      'listStatus',
      'listDestinationId',
      'listFranchiseId',
      'listType',
      'listItemCount',
    ]),
  },
  methods: {
    async clear() {
      this.searched = '';
      this.keyword = '';
      this.business_id = '';
      this.franchise_id = '';
      this.type = '';
      this.destination_id = '';
      this.card_acceptor = '';
      this.status = '';
      this.$validator.reset();
    },
    routeHasQueryParams() {
      return (
        this.$route.query.keyword ||
        this.$route.query.business_id ||
        this.$route.query.franchise_id ||
        this.$route.query.type ||
        this.$route.query.destination_id ||
        this.$route.query.card_acceptor ||
        this.$route.query.status
      );
    },
    openCreateStoreModal() {
      this.showCreateStoreModal = true;
    },
    closeCreateStoreModal() {
      this.showCreateStoreModal = false;
    },
    buildPayloadQueryString() {
      const payload = {};
      if (this.keyword) {
        payload.keyword = this.keyword;
      }
      if (this.business_id) {
        payload.business_id = this.business_id;
      }
      if (this.franchise_id) {
        payload.franchise_id = this.franchise_id;
      }
      if (this.destination_id) {
        payload.destination_id = this.destination_id;
      }
      if (this.card_acceptor) {
        payload.card_acceptor = this.card_acceptor;
      }
      if (this.status) {
        payload.status = this.status;
      }
      if (this.type) {
        payload.type = this.type;
      }

      return payload;
    },
    async submit(event, forceSearch = false, page = 1) {
      let validationComplete;
      if (!forceSearch) {
        validationComplete = await this.$validator.validateAll();
      }

      if (validationComplete || forceSearch) {
        this.$router.replace({ query: this.buildPayloadQueryString() });
        await this.clearAllError();

        try {
          this.dataReady = false;
          const search = this.buildPayloadQueryString();
          search.page = page;
          await this.executeAction({ action: 'store/listStores' }, search);
        } finally {
          this.dataReady = true;
        }
      }
    },
    async createStoreSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'store.create_action.success' });
      this.closeCreateStoreModal();
    },
  },
};
</script>
