<template>
  <card :title="$t('offers.activation_codes')" icon="">
    <template slot="headerZoneRight">
      <label-text
        class="text-sm-center"
        :label="$t('offers.details.activation_codes_section.activation_codes_count')"
        :value="offerActivationCodesCount || '0'"
      ></label-text>

      <template v-if="showAddActivationCodeFeature()">
        <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>

        <button-secondary-actions class="application-info--actions" :text="$t('offers.details.activation_codes_section.actions')" :actions="actions">
        </button-secondary-actions>

        <template slot="addActivationCode">
          <modal-action :show-modal="showAddActivationCodeModal">
            <add-activation-code-action
              :offer-obj="offer"
              :hello-show="showAddActivationCodeModal"
              @close="closeAddActivationCodeModal"
              @created="addActivationCodeSuccess"
            /> </modal-action
        ></template>
      </template>
    </template>
    <template slot="content">
      <form @submit.prevent="submitListActivationCodes" class="form-basic form-search">
        <v-layout align-center wrap>
          <v-flex xs12 sm6 md5>
            <form-warning-search
              :searched="searched"
              :hint-message="$t('offers.details.activation_codes_section.form.total_results_message')"
              :label="$t('offers.details.activation_codes_section.placeholder')"
              v-model="keyword"
              data-vv-name="search"
              v-validate="'max:100'"
              :error-messages="errors.collect('search')"
              data-test="offers-activation-codes-keyword"
            ></form-warning-search>
          </v-flex>
          <v-flex xs12 sm6 md7 class="form-search--button-wrap">
            <button-primary
              class="offers-details.activation_codes_section--btn-search"
              :text="$t('offers.details.activation_codes_section.form.submit')"
              :loading="working"
              @click="submitListActivationCodes"
            ></button-primary>
          </v-flex>
        </v-layout>
      </form>

      <modal-action :show-modal="showRedeemActivationCodeModal">
        <redeem-activation-code-action
          v-if="showRedeemActivationCodeModal"
          :offer-id="offer.id"
          :activation-code-id="activationCodeIdToRedeem"
          :hello-show="showRedeemActivationCodeModal"
          @close="closeRedeemActivationCodeModal"
          @created="redeemActivationCodeSuccess"
        />
      </modal-action>
      <modal-action :show-modal="showCancelActivationCodeModal">
        <cancel-activation-code-action
          v-if="showCancelActivationCodeModal"
          :activation-code-id="activationCodeIdToCancel"
          :hello-show="showCancelActivationCodeModal"
          @close="closeCancelActivationCodeModal"
          @canceled="cancelActivationCodeSuccess"
        />
      </modal-action>
      <modal-action :show-modal="showUpdateActivationCodeModal">
        <update-activation-code-action
          v-if="showUpdateActivationCodeModal"
          :activation-code="activationCodeToUpdate"
          @close="closeUpdateActivationCodeModal"
          @updated="updateActivationCodeSuccess"
        />
      </modal-action>
      <modal-confirm
        v-if="activationCodeIdToResend"
        :show-modal="showResendActivationCodeModal"
        :title="$t(`offers.details.activation_codes_section.resend_action.confirm.title`)"
        context="deactivateActivationCode"
        :text="
          $t(`offers.details.activation_codes_section.resend_action.confirm.text`, {
            activationCodeName: `${activationCodeToResend.recipient.email} - (${activationCodeToResend.id})`,
          })
        "
        :cancel_text="`offers.details.activation_codes_section.resend_action.confirm.button_cancel`"
        :submit_text="`offers.details.activation_codes_section.resend_action.confirm.button_submit`"
        :submit_working="working"
        @submit="resendActivationCode"
        @close="closeResendActivationCodeModal"
      ></modal-confirm>
      <offers-activation-codes-table
        v-if="dataReady && activationCodes.length"
        @activation_code_cancel_action="openCancelActivationCodeModal"
        @activation_code_resend_action="openResendActivationCodeModal"
        @activation_code_redeem_action="openRedeemActivationCodeModal"
        @activation_code_update_action="openUpdateActivationCodeModal"
        :items="activationCodes"
        :offer-id="offer.id"
      />
      <label-none v-if="activationCodes.length === 0 && dirty">{{ $t('system.none') }}</label-none>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';
import OffersActivationCodesTable from '@/components/offers/detail/OffersActivationCodesTable.vue';
import AddActivationCodeAction from '@/components/offers/actions/AddActivationCodeAction.vue';
import CancelActivationCodeAction from '@/components/activation_code/actions/CancelActivationCodeAction.vue';
import RedeemActivationCodeAction from '@/components/activation_code/actions/RedeemActivationCodeAction.vue';
import UpdateActivationCodeAction from '../../activation_code/actions/UpdateActivationCodeAction.vue';

export default {
  name: 'offer-activation-codes',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: {
    OffersActivationCodesTable,
    AddActivationCodeAction,
    CancelActivationCodeAction,
    RedeemActivationCodeAction,
    UpdateActivationCodeAction,
  },
  $_veeValidate: {
    validator: 'new',
  },
  data() {
    const $t = this.$t.bind(this);
    return {
      activeModal: null,
      actions: [
        {
          slotName: 'addActivationCode',
          name: $t(`offers.details.activation_codes_section.add_activation_code`),
          action: () => {
            this.openAddActivationCodeModal();
          },
          isAvailable: () => this.isAgentInternal() && !this.emailVerified,
        },
      ],
      keyword: '',
      searched: '',
      page: 1,
      dataReady: false,
      dirty: false,
      showAddActivationCodeModal: false,
      showCancelActivationCodeModal: false,
      activationCodeIdToCancel: null,
      activationCodeToCancel: {},
      showResendActivationCodeModal: false,
      activationCodeIdToResend: null,
      activationCodeToResend: {},
      showRedeemActivationCodeModal: false,
      activationCodeIdToRedeem: null,
      activationCodeToRedeem: {},
      showUpdateActivationCodeModal: false,
      activationCodeIdToUpdate: null,
      activationCodeToUpdate: {},
    };
  },
  async created() {
    this.page = this.listPageNumber;
    this.keyword = this.listKeyword;
    await this.fetchActivationCodes();
  },
  computed: {
    ...mapGetters('offers', ['offer']),
    ...mapGetters('activationCode', ['activationCodes', 'listKeyword', 'listPageNumber', 'listItemCount', 'offerActivationCodesCount']),
    ...mapGetters('ui', ['currentLocale']),
  },
  watch: {
    async offer() {
      this.keyword = '';
      this.searched = '';
      this.page = 1;
      this.dirty = false;
    },
  },
  methods: {
    async clear() {
      this.searched = '';
      this.keyword = '';
      this.page = 1;
      this.dirty = false;
      this.$validator.reset();
    },
    async fetchActivationCodes() {
      await this.executeAction({ action: 'activationCode/listActivationCodesByOffer' }, { offerId: this.offer.id, keyword: this.keyword, page: 1 });
    },
    async submitListActivationCodes(event, forceSearch = false, page = 1) {
      let validationComplete;
      if (!forceSearch) {
        validationComplete = await this.$validator.validateAll();
      }

      if (validationComplete || forceSearch) {
        await this.clearAllError();
        this.dataReady = false;
        await this.executeAction({ action: 'activationCode/listActivationCodesByOffer' }, { offerId: this.offer.id, keyword: this.keyword, page });
        this.dataReady = true;
        this.dirty = true;
      }
    },
    openAddActivationCodeModal() {
      this.showAddActivationCodeModal = true;
    },
    closeAddActivationCodeModal() {
      this.showAddActivationCodeModal = false;
    },
    async addActivationCodeSuccess() {
      await this.fetchActivationCodes();
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'offers.details.activation_codes_section.create_action.success' });
      this.closeAddActivationCodeModal();
    },
    openRedeemActivationCodeModal(id) {
      this.activationCodeIdToRedeem = id;
      this.activationCodeToRedeem = this.activationCodes.find((l) => l.id === id);
      this.showRedeemActivationCodeModal = true;
    },
    closeRedeemActivationCodeModal() {
      this.showRedeemActivationCodeModal = false;
      this.activationCodeIdToRedeem = null;
      this.activationCodeToRedeem = {};
    },
    async redeemActivationCodeSuccess() {
      await this.fetchActivationCodes();
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'offers.redeem_action.success' });
      this.closeRedeemActivationCodeModal();
    },
    openCancelActivationCodeModal(id) {
      this.activationCodeIdToCancel = id;
      this.activationCodeToCancel = this.activationCodes.find((l) => l.id === id);
      this.showCancelActivationCodeModal = true;
    },
    closeCancelActivationCodeModal() {
      this.activationCodeIdToCancel = null;
      this.activationCodeToCancel = {};
      this.showCancelActivationCodeModal = false;
    },
    async cancelActivationCodeSuccess() {
      await this.fetchActivationCodes();
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: `offers.details.activation_codes_section.cancel_action.success` });
      this.closeCancelActivationCodeModal();
    },
    openResendActivationCodeModal(id) {
      this.activationCodeIdToResend = id;
      this.activationCodeToResend = this.activationCodes.find((l) => l.id === id);
      this.showResendActivationCodeModal = true;
    },
    closeResendActivationCodeModal() {
      this.activationCodeIdToResend = null;
      this.activationCodeToResend = {};
      this.showResendActivationCodeModal = false;
    },
    async resendActivationCodeSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: `offers.details.activation_codes_section.resend_action.success` });
      this.closeResendActivationCodeModal();
    },
    showAddActivationCodeFeature() {
      return false; // NOTE (SG) We decide to remove these action for now because of the way we manage activation code, maybe later we will put it back.  this.canManageOffer() && this.offer.system_status.status !== 'draft';
    },
    async resendActivationCode() {
      await this.executeAction(
        {
          action: 'activationCode/resendActivationCode',
          name: 'resendActivationCode',
          success: this.resendActivationCodeSuccess,
        },
        { id: this.activationCodeToResend.id },
      );
    },
    openUpdateActivationCodeModal(id) {
      this.activationCodeIdToUpdate = id;
      this.activationCodeToUpdate = this.activationCodes.find((l) => l.id === id);
      this.showUpdateActivationCodeModal = true;
    },
    closeUpdateActivationCodeModal() {
      this.activationCodeIdToUpdate = null;
      this.activationCodeToUpdate = {};
      this.showUpdateActivationCodeModal = false;
    },
    async updateActivationCodeSuccess() {
      await this.fetchActivationCodes();
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'offers.details.activation_codes_section.update_action.success' });
      this.closeUpdateActivationCodeModal();
    },
  },
};
</script>
