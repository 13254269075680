<template>
  <view-main title="page_title.offers"><search-offers-form slot="content"></search-offers-form></view-main>
</template>

<script>
import { renderErrorTrackable } from '@/mixins';
import SearchOffersForm from './list/SearchOffersForm.vue';

export default {
  name: 'offers-view',
  mixins: [renderErrorTrackable],
  components: { SearchOffersForm },
};
</script>
