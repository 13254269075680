import Vue from 'vue';

const service = {
  async listVirtualMoneys() {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/virtual-money/v1/virtual-moneys`);
    return response.data.data;
  },
};

export default service;
