<template>
  <card-modal
    icon=""
    :title="$t('publishers.update_action.title')"
    context="updatePublisher"
    @submit="submit"
    @cancel="cancel"
    cancel_text="publishers.update_action.button_cancel"
    submit_text="publishers.update_action.button_save"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-text-field
          autofocus
          :label="$t('publishers.update_action.name') + '*'"
          name="name"
          v-validate="'required|max:100'"
          :error-messages="errors.collect('name')"
          v-model="currentPublisher.name"
          data-vv-name="name"
          maxlength="100"
          :data-vv-as="$t('publishers.update_action.name')"
          data-test="update-publishers--name"
          required
        ></v-text-field>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'update-publishers-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    publisher: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentPublisher: {},
    };
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
  },
  mounted() {
    this.currentPublisher = Object.assign({}, _.cloneDeep(this.publisher));
  },
  methods: {
    clear() {
      this.currentPublisher = {};
      this.$validator.reset();
    },
    success(response) {
      this.clear();
      this.$emit('updated');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();
      if (validationComplete) {
        await this.executeAction(
          {
            action: 'publishers/updatePublisher',
            name: 'updatePublisher',
            success: this.success,
          },
          this.buildPayload(),
        );
      }
    },
    buildPayload() {
      return Object.assign({}, _.omit(this.currentPublisher, ['creation_date']));
    },
  },
};
</script>
