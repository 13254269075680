<template>
  <view-main title="page_title.bank_accounts"><search-bank-accounts-form slot="content"></search-bank-accounts-form></view-main>
</template>

<script>
import { renderErrorTrackable } from '@/mixins';
import SearchBankAccountsForm from './list/SearchBankAccountsForm.vue';

export default {
  name: 'bank-accounts-list-view',
  mixins: [renderErrorTrackable],
  components: { SearchBankAccountsForm },
};
</script>

<style scoped lang="stylus"></style>
