<template>
  <card :title="$t('inventory.offer.title')" icon="">
    <template slot="content">
      <inventory-offers-table v-if="inventoryOffers.length" :items="inventoryOffers" :inventory-id="inventory.id"></inventory-offers-table>
      <label-none v-if="inventoryOffers.length === 0">{{ $t('system.na') }}</label-none>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';
import InventoryOffersTable from './InventoryOffersTable.vue';

export default {
  name: 'inventory-offers',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { InventoryOffersTable },
  data() {
    return {
      pagination: 1,
    };
  },
  watch: {
    async inventory() {
      return this.executeAction({ action: 'inventory/listInventoryOffers' }, { id: this.inventory.id, page: this.inventoryOffersPageNumber });
    },
    inventoryOffersPageNumber() {
      this.pagination = this.inventoryOffersPageNumber;
    },
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'inventory/changeInventoryOffersPage',
          success: this.success,
        },
        {
          id: this.inventory.id,
          page: newValue,
        },
      );
    },
  },
  computed: {
    ...mapGetters('inventory', ['inventory', 'inventoryOffers', 'inventoryOffersPageCount', 'inventoryOffersPageNumber']),
    ...mapGetters('ui', ['currentLocale']),
  },
};
</script>
