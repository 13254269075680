<template>
  <div v-if="currentCardExists()">
    <h3>{{ $t(`task.modal.card.title`) }}</h3>
    <v-layout row wrap align-center justify-start class="data-card--content-zone">
      <v-flex xs12 class="d-inline-flex data-card--content-zone__upper">
        <label-text :label="$t(`task.modal.card.public_token`)">
          <p slot="labelTextLink" class="label-text--value">
            <v-layout row>
              <link-secondary-route
                :can-access="canViewCard()"
                :route-name="'card'"
                :route-params="{ cardPublicToken: publicToken }"
                target="blank"
                >{{ publicToken }}</link-secondary-route
              >
            </v-layout>
          </p>
        </label-text>
        <label-text :label="$t(`task.modal.card.date_issued`)" :value="currentCard.date_issued | formatDateHourMin"></label-text>
        <label-card-status :status="currentCard.status"></label-card-status>
        <label-text :label="$t(`task.modal.card.cardholder.first_name`)" :value="currentCard.cardholder.first_name"></label-text>
        <label-text :label="$t(`task.modal.card.cardholder.last_name`)" :value="currentCard.cardholder.last_name"></label-text>
        <label-text
          :label="$t(`task.modal.card.cardholder.date_of_birth`)"
          :value="currentCard.cardholder.date_of_birth | formatDateNoTime"
        ></label-text>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';

export default {
  name: 'card-resolve-modal-part',
  mixins: [actionErrorTrackable, security],
  props: {
    publicToken: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentCard: {},
    };
  },
  async mounted() {
    this.currentCard = await this.executeAction({ action: 'task/getCard' }, this.publicToken);
  },
  methods: {
    currentCardExists() {
      return !!this.currentCard.public_token;
    },
  },
};
</script>
