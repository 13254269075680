<template>
  <card-modal
    icon=""
    :title="$t('establishment.update_action.title')"
    context="updateEstablishment"
    @submit="submit"
    @cancel="cancel"
    cancel_text="establishment.update_action.button_cancel"
    submit_text="establishment.update_action.button_save"
  >
    <form @submit.prevent="submit" slot="content">
      <v-select
        :items="canadianTimezone"
        name="timezone"
        v-validate="'required'"
        v-model="currentEstablishment.timezone"
        :label="$t('establishment.update_action.timezone') + '*'"
        :error-messages="errors.collect('timezone')"
        data-vv-name="timezone"
        :data-vv-as="$t('establishment.update_action.timezone')"
        item-text="name"
        item-value="id"
        data-test="update-establishment--timezone"
      ></v-select>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import moment from 'moment-timezone';
import CANADIAN_TIMEZONES from '@/tools/canadian-timezones';

export default {
  name: 'update-establishment-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    establishment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentEstablishment: {},
    };
  },
  async mounted() {
    this.currentEstablishment = Object.assign({}, _.cloneDeep(this.establishment));
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    canadianTimezone() {
      return CANADIAN_TIMEZONES.map((zone) => {
        return {
          name: `${zone} (${moment()
            .tz(zone)
            .format('Z z')})`,
          id: zone,
        };
      });
    },
  },
  methods: {
    clear() {
      this.currentEstablishment = {};
    },
    success(response) {
      this.clear();
      this.$emit('updated');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    buildPayload() {
      const payload = {
        id: this.currentEstablishment.id,
        timezone: this.currentEstablishment.timezone,
      };

      return payload;
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();
      if (validationComplete) {
        await this.executeAction(
          {
            action: 'space/updatePartnerEstablishment',
            name: 'updatePartnerEstablishment',
            success: this.success,
          },
          this.buildPayload(),
        );
      }
    },
  },
};
</script>

<style scoped lang="stylus">
.add-cidr {
  position: relative;
  top: 0.8em;
}

.remove-cidr {
  top: -3px;
  position: relative;
  margin-left: 1em;
  margin-bottom: 0.4em;
}

.cidrs-section label {
  margin: 2em 0 1em;
  display: block;
}

.cidrs-list {
  list-style: none;
  padding-left: 0;
}
</style>
