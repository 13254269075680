<template>
  <card-modal
    icon="fal fa-store-alt"
    :title="$t('offers.details.publishers_section.update_action.title')"
    context="updateOfferPublisher"
    @submit="submit"
    @cancel="cancel"
    cancel_text="offers.details.publishers_section.update_action.button_cancel"
    submit_text="offers.details.publishers_section.update_action.button_save"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-text-field
          autofocus
          :label="$t('offers.details.publishers_section.update_action.inventory_limit')"
          name="inventory_limit"
          append-icon="fas fa-dollar-sign"
          v-validate="{
            required: true,
            integer: true,
            min_value: 0,
          }"
          :error-messages="errors.collect('inventory_limit')"
          v-model="currentOfferPublisher.inventory_limit"
          data-vv-name="inventory_limit"
          maxlength="50"
          :data-vv-as="$t('offers.details.publishers_section.update_action.inventory_limit')"
        ></v-text-field>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapActions } from 'vuex';

export default {
  name: 'update-offer-publisher-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    offerPublisher: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentOfferPublisher: {},
    };
  },
  async created() {
    this.currentOfferPublisher = Object.assign(
      {},
      {
        publisher_id: this.offerPublisher.publisher.id,
        offer_id: this.offerPublisher.offer_id,
        inventory_limit: this.offerPublisher.inventory_limit,
      },
    );
  },
  methods: {
    ...mapActions('ui', ['addError', 'clearAllError', 'clearError']),
    clear() {
      this.currentOfferPublisher = {};
      this.$validator.reset();
    },
    success(response) {
      this.clear();
      this.$emit('updated');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      let validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        await this.executeAction(
          {
            action: 'offers/updateOfferPublisher',
            name: 'updateOfferPublisher',
            success: this.success,
          },
          this.currentOfferPublisher,
        );
      }
    },
  },
};
</script>
