<template>
  <card :title="$t('user.cards')" icon="">
    <template slot="headerZoneRight">
      <label-text class="text-sm-center" :label="$t('user.details.cards_section.count')" :value="cards ? cards.length : '0'"></label-text>
    </template>
    <template slot="content">
      <user-cards-table v-if="cards && cards.length" :items="cards"></user-cards-table>
      <label-none v-else>{{ $t('user.details.cards_section.no_found') }}</label-none>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import UserCardsTable from './UserCardsTable';
import { mapGetters } from 'vuex';

export default {
  name: 'user-cards',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { UserCardsTable },
  computed: {
    ...mapGetters('account', ['account']),
    ...mapGetters('card', ['cards']),
    ...mapGetters('ui', ['currentLocale']),
  },
  watch: {
    async account(val) {
      await this.clearCards();

      if (val.cardholder_key) {
        await this.fetchCards();
      }
    },
  },
  methods: {
    async clearCards() {
      await this.executeAction({ action: 'card/clearCards' });
    },
    async fetchCards() {
      await this.executeAction({ action: 'card/getCards' }, this.account.cardholder_key);
    },
  },
};
</script>
