<template>
  <div v-cloak id="app">
    <v-app id="inspire">
      <v-snackbar
        :timeout="0"
        :top="snackbar.y === 'top'"
        :bottom="snackbar.y === 'bottom'"
        :right="snackbar.x === 'right'"
        :left="snackbar.x === 'left'"
        :color="snackbar.color"
        :multi-line="snackbar.mode === 'multi-line'"
        :vertical="snackbar.mode === 'vertical'"
        v-model="snackbar.show"
      >
        <v-list class="snackbar--list">
          <li v-for="(text, index) in snackbarText" :key="index">{{ $t(text) }}</li>
        </v-list>
      </v-snackbar>
      <v-navigation-drawer class="navigation--drawer" v-model="drawer" temporary app>
        <v-list>
          <dropdown-menu v-show="authenticated" :mobile-view="true" :items="dashboards" :name="$t('navigation.dashboards')"></dropdown-menu>
          <v-list-tile v-for="(item, index) in mainMenu" :key="index" :to="item.theRoute" class="navigation--mobile-menu-item">
            <v-list-tile-content>
              <v-list-tile-title>{{ item.title }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <dropdown-menu v-show="authenticated" :mobile-view="true" :items="instantCardItems" :name="$t('navigation.instant_card')"></dropdown-menu>
          <dropdown-menu v-show="authenticated" :mobile-view="true" :items="setup" :name="$t('navigation.setup')"></dropdown-menu>
          <v-list-tile v-show="authenticated" class="navigation--mobile-menu-item" to="/task" name="task">
            <v-list-tile-content>
              <v-list-tile-title>{{ $t(`navigation.task`) }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <dropdown-menu v-show="authenticated" :mobile-view="true" :items="tools" :name="$t('navigation.tools')"></dropdown-menu>
          <v-list-tile v-show="!authenticated" @click="login()">
            <v-list-tile-action> <v-icon>check</v-icon> </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>{{ $t('navigation.login') }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile class="button-logout" v-show="authenticated" @click="logoutSession()">
            <v-list-tile-action> <v-icon>cancel</v-icon> </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>{{ $t('navigation.logout') }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-navigation-drawer>

      <v-toolbar app fixed class="navigation--toolbar-desktop">
        <v-toolbar-side-icon class="hidden-md-and-up" @click.stop="drawer = !drawer" />
        <v-toolbar-title> <img width="110px" src="@/assets/logo-text.png" /> </v-toolbar-title>
        <v-spacer />
        <dropdown-menu v-show="authenticated" :items="dashboards" :name="$t('navigation.dashboards')"></dropdown-menu>
        <v-btn class="hidden-sm-and-down" v-for="(item, index) in mainMenu" v-ripple="false" :key="index" :to="item.theRoute" flat>{{
          item.title
        }}</v-btn>
        <dropdown-menu v-show="authenticated" :items="instantCardItems" :name="$t('navigation.instant_card')"></dropdown-menu>
        <dropdown-menu v-show="authenticated" :items="setup" :name="$t('navigation.setup')"></dropdown-menu>
        <v-btn v-show="authenticated" class="hidden-sm-and-down" v-ripple="false" to="/task" flat>{{ $t(`navigation.task`) }}</v-btn>

        <dropdown-menu v-show="authenticated" :items="tools" :name="$t('navigation.tools')"></dropdown-menu>

        <modal-action :show-modal="showGenerateQRCode" close>
          <generate-q-r-code @close="closeGenerateQRModal"></generate-q-r-code>
        </modal-action>

        <v-spacer></v-spacer>
        <v-btn v-ripple="false" v-show="!authenticated" class="hidden-sm-and-down" flat @click="login()">{{ $t('navigation.login') }}</v-btn>
        <v-btn v-ripple="false" v-show="authenticated" class="button-logout hidden-sm-and-down" flat @click="logoutSession()">{{
          $t('navigation.logout')
        }}</v-btn>
      </v-toolbar>

      <v-content> <router-view /> </v-content>

      <v-footer class="justify-center" app>
        <span class="white--text">&copy; Hello Network Inc. {{ new Date().getFullYear() }} v{{ version }}</span>
        <span class="hidden-sm-and-down white--text">&nbsp;#{{ buildNumber }}</span>
      </v-footer>
    </v-app>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { security } from '@/mixins';

import GenerateQRCode from './components/tools/actions/GenerateQRCode.vue';

export default {
  mixins: [security],
  components: { GenerateQRCode },
  data: function data() {
    return {
      drawer: null,
      buildNumber: process.env.VUE_APP_BUILD_NUMBER,
      version: process.env.VUE_APP_VERSION,

      showGenerateQRCode: false,
    };
  },
  watch: {
    $route: 'checkSecurityStatus',
  },
  computed: {
    ...mapGetters('security', ['authenticated']),
    ...mapGetters('ui', ['snackbar', 'snackbarText']),
    mainMenu() {
      const mainMenu = [];
      if (this.authenticated) {
        mainMenu.push({
          title: this.$t(`navigation.users`),
          theRoute: '/users',
        });
        if (this.canViewOffer()) {
          mainMenu.push({
            title: this.$t(`navigation.vouchers`),
            theRoute: '/vouchers',
          });
          mainMenu.push({
            title: this.$t(`navigation.offers`),
            theRoute: '/offers',
          });
        }
      }
      return mainMenu;
    },
    instantCardItems() {
      const instantCardItems = [];
      if (this.authenticated && this.canManageInstantCard()) {
        instantCardItems.push({
          title: this.$t(`navigation.instant_card_projects`),
          theRoute: '/instant-card-projects',
        });
        instantCardItems.push({
          title: this.$t(`navigation.instant_card_batches`),
          theRoute: '/instant-card-batches',
        });
        instantCardItems.push({
          title: this.$t(`navigation.instant_cards`),
          theRoute: '/instant-cards',
        });
      }
      return instantCardItems;
    },
    dashboards() {
      const dashboards = [];
      if (this.authenticated && this.canViewCard()) {
        dashboards.push({
          title: this.$t(`navigation.activation_codes`),
          theRoute: '/dashboards/activation-codes',
        });
        dashboards.push({
          title: this.$t(`navigation.card_activities`),
          theRoute: '/dashboards/card-activities',
        });
        dashboards.push({
          title: this.$t(`navigation.card_applications`),
          theRoute: '/dashboards/card-applications',
        });
        dashboards.push({
          title: this.$t(`navigation.debits_credits`),
          theRoute: '/dashboards/debits-credits',
        });
        dashboards.push({
          title: this.$t(`navigation.fraud_analysis`),
          theRoute: '/dashboards/fraud-analysis',
        });
        dashboards.push({
          title: this.$t(`navigation.transactions`),
          theRoute: '/dashboards/transactions',
        });
        dashboards.push({
          title: this.$t(`navigation.vouchers`),
          theRoute: '/dashboards/vouchers',
        });
        dashboards.push({
          title: this.$t(`navigation.vouchers_operations`),
          theRoute: '/dashboards/vouchers-operations',
        });
        dashboards.push({
          title: this.$t(`navigation.business_suite`),
          theRoute: '/dashboards/business-suite',
          items: this.businessSuiteDashboards,
        });
      }
      return dashboards;
    },
    businessSuiteDashboards() {
      const businessSuiteDashboards = [];
      if (this.authenticated && this.canViewCard()) {
        businessSuiteDashboards.push({
          title: this.$t(`navigation.business_suite_balances`),
          theRoute: '/dashboards/business-suite/balances',
        });
        businessSuiteDashboards.push({
          title: this.$t(`navigation.business_suite_corporate_program_businesses`),
          theRoute: '/dashboards/business-suite/corporate-program-businesses',
        });
        businessSuiteDashboards.push({
          title: this.$t(`navigation.business_suite_corporate_program_orders`),
          theRoute: '/dashboards/business-suite/corporate-program-orders',
        });
        businessSuiteDashboards.push({
          title: this.$t(`navigation.business_suite_employer_report`),
          theRoute: '/dashboards/business-suite/employer',
        });
        businessSuiteDashboards.push({
          title: this.$t(`navigation.business_suite_members`),
          theRoute: '/dashboards/business-suite/members',
        });
        businessSuiteDashboards.push({
          title: this.$t(`navigation.business_suite_pads`),
          theRoute: '/dashboards/business-suite/pads',
        });
        businessSuiteDashboards.push({
          title: this.$t(`navigation.business_suite_promoter_report`),
          theRoute: '/dashboards/business-suite/promoter',
        });
        businessSuiteDashboards.push({
          title: this.$t(`navigation.business_suite_transactions`),
          theRoute: '/dashboards/business-suite/transactions',
        });
      }
      return businessSuiteDashboards;
    },
    setup() {
      const setup = [];
      if (this.authenticated) {
        if (this.canViewOffer()) {
          setup.push({
            id: 'inventory',
            title: this.$t(`navigation.inventory`),
            theRoute: '/inventories',
          });
        }
        if (this.canViewOffer()) {
          setup.push({
            title: this.$t(`navigation.bank_accounts`),
            theRoute: '/bank-accounts',
          });
        }
        if (this.canViewOffer()) {
          setup.push({
            title: this.$t(`navigation.publishers`),
            theRoute: '/closed-loops',
          });
        }
        if (this.canViewOffer()) {
          setup.push({
            title: this.$t(`navigation.stores`),
            theRoute: '/stores',
          });
        }
        if (this.canViewOffer()) {
          setup.push({
            title: this.$t(`navigation.franchises`),
            theRoute: '/franchises',
          });
        }
        if (this.canViewOffer()) {
          setup.push({
            title: this.$t(`navigation.destinations`),
            theRoute: '/destinations',
          });
        }
        if (this.canViewOffer()) {
          setup.push({
            title: this.$t(`navigation.businesses`),
            theRoute: '/businesses',
          });
        }
        if (this.canViewOffer()) {
          setup.push({
            title: this.$t(`navigation.brandings`),
            theRoute: '/brandings',
          });
        }
        if (this.canViewOffer()) {
          setup.push({
            title: this.$t(`navigation.spaces`),
            theRoute: '/spaces',
          });
        }
        if (this.canViewWalletDefinition()) {
          setup.push({
            title: this.$t(`navigation.wallet_definition`),
            theRoute: '/wallet-definitions',
          });
        }
      }
      return setup;
    },
    tools() {
      var _this = this;
      const tools = [
        {
          title: this.$t(`navigation.generate_qr_code`),
          click(e) {
            _this.showGenerateQRCode = true;
          },
        },
      ];
      return tools;
    },
  },
  methods: {
    ...mapActions('security', ['logout', 'checkSecurityStatus']),
    ...mapActions('ui', ['changeLocale']),

    closeGenerateQRModal() {
      this.showGenerateQRCode = false;
    },
    async logoutSession() {
      await this.logout();
      this.$router.push({ name: 'root' });
    },
    async login() {
      this.$router.push({ name: 'login' });
    },
  },
};
</script>
