import _ from 'lodash';
import Vue from 'vue';

const defaultPage = 1;
const paginationLimit = 20;
const bankAccountNotesPaginationLimit = 20;

export default {
  namespaced: true,
  state: {
    bankAccounts: [],
    bankAccount: {},
    bankAccountsFromSearch: {},
    bankAccountsMoreResults: false,
    bank_account_notes: [],
    list: {
      pageNumber: 1,
      pageCount: 0,
      itemCount: 0,
      keyword: '',
      status: '',
    },
    bank_account_notes_list: {
      pageNumber: 1,
      pageCount: 0,
      itemCount: 0,
    },
  },
  getters: {
    bankAccount: (state) => state.bankAccount,
    bankAccounts: (state) => state.bankAccounts,
    bankAccountsFromSearch: (state) => state.bankAccountsFromSearch,
    bankAccountsMoreResults: (state) => state.bankAccountsMoreResults,
    listPageCount: (state) => state.list.pageCount,
    listItemCount: (state) => state.list.itemCount,
    listPageNumber: (state) => state.list.pageNumber,
    listKeyword: (state) => state.list.keyword,
    listStatus: (state) => state.list.status,
    bankAccountNotes: (state) => state.bank_account_notes,
    listBankAccountNotesPageCount: (state) => state.bank_account_notes_list.pageCount,
    listBankAccountNotesItemCount: (state) => state.bank_account_notes_list.itemCount,
    listBankAccountNotesPageNumber: (state) => state.bank_account_notes_list.pageNumber,
  },
  mutations: {
    bankAccounts(state, bankAccounts) {
      state.bankAccounts = bankAccounts;
    },
    bankAccount(state, bankAccount) {
      state.bankAccount = bankAccount;
    },
    bankAccountsFromSearch(state, accounts) {
      state.bankAccountsFromSearch = accounts;
    },
    bankAccountsMoreResults(state, bankAccountsMoreResults) {
      state.bankAccountsMoreResults = bankAccountsMoreResults;
    },
    listPageCount(state, pageCount) {
      state.list.pageCount = pageCount;
    },
    listItemCount(state, itemCount) {
      state.list.itemCount = itemCount;
    },
    listPageNumber(state, pageNumber) {
      state.list.pageNumber = pageNumber;
    },
    listKeyword(state, keyword) {
      state.list.keyword = keyword;
    },
    listStatus(state, status) {
      state.list.status = status;
    },
    bankAccountNotes(state, notes) {
      state.bank_account_notes = notes;
    },
    listBankAccountNotesPageCount(state, pageCount) {
      state.bank_account_notes_list.pageCount = pageCount;
    },
    listBankAccountNotesItemCount(state, itemCount) {
      state.bank_account_notes_list.itemCount = itemCount;
    },
    listBankAccountNotesPage(state, page) {
      state.bank_account_notes_list.pageNumber = page;
    },
  },
  actions: {
    async listBankAccounts({ state, commit }, { keyword, status, page, limit } = {}) {
      const bankAccountService = Vue.prototype.$services.bankAccount;

      const thePage = page || state.list.pageNumber || defaultPage;
      const theLimit = limit || paginationLimit;
      const theKeyword = keyword || '';
      const theStatus = status || '';

      const listResults = await bankAccountService.listBankAccounts(theKeyword, theStatus, thePage, theLimit);

      commit('bankAccounts', listResults.items);
      commit('listPageNumber', thePage);
      commit('listPageCount', listResults.page_count);
      commit('listItemCount', listResults.item_count);
      commit('listKeyword', theKeyword);
      commit('listStatus', theStatus);
    },
    async clearListBankAccounts({ commit }) {
      commit('bankAccounts', []);
    },
    // TODO: Can search and list bank accounts become one? ie detete search and keep list
    async searchBankAccount({ rootState, commit }, { keyword, all_status }) {
      const bankAccountService = Vue.prototype.$services.bankAccount;
      let status = 'active';
      if (all_status) {
        status = null;
      }

      const result = await bankAccountService.searchBankAccount(keyword, status);
      const bankAccountsFromSearch = result.items;

      commit('bankAccountsFromSearch', bankAccountsFromSearch);
      commit('bankAccountsMoreResults', result.more_results);
    },
    async createBankAccount({ commit }, params) {
      const bankAccountService = Vue.prototype.$services.bankAccount;
      return bankAccountService.createBankAccount(params);
    },
    async updateBankAccount({ commit }, { key, payload }) {
      const bankAccountService = Vue.prototype.$services.bankAccount;
      const updatedBankAccount = await bankAccountService.updateBankAccount(key, payload);

      commit('bankAccount', updatedBankAccount);
    },
    async getBankAccount({ commit }, key) {
      try {
        const bankAccountService = Vue.prototype.$services.bankAccount;
        const bankAccount = await bankAccountService.getBankAccount(key);
        commit('bankAccount', bankAccount);

        if (bankAccount) {
          return bankAccount;
        }
      } catch (e) {
        console.log(e); // eslint-disable-line no-console
        return {};
      }
    },
    async createBankAccountNote({ dispatch }, { bank_account_key, note }) {
      const bankAccountService = Vue.prototype.$services.bankAccount;
      await bankAccountService.createBankAccountNote(bank_account_key, { note });
      await dispatch('listBankAccountNotes', { bank_account_key });
    },
    async deleteBankAccountNote({ dispatch }, { bank_account_key, note_id, reason }) {
      const bankAccountService = Vue.prototype.$services.bankAccount;
      await bankAccountService.deleteBankAccountNote(bank_account_key, note_id, { reason });
      await dispatch('listBankAccountNotes', { bank_account_key });
    },
    async listBankAccountNotes({ state, commit }, { bank_account_key, page, limit } = {}) {
      const bankAccountService = Vue.prototype.$services.bankAccount;
      const thePage = page || state.bank_account_notes_list.pageNumber;
      const theLimit = limit || bankAccountNotesPaginationLimit;
      const theBankAccountKey = bank_account_key || '';

      const listResults = await bankAccountService.listBankAccountNotes(thePage, theLimit, theBankAccountKey);

      commit('listBankAccountNotesPage', thePage);
      commit('listBankAccountNotesPageCount', listResults.page_count);
      commit('listBankAccountNotesItemCount', listResults.item_count);
      commit('bankAccountNotes', listResults.items);
    },
    async activateBankAccount({ dispatch }, { key }) {
      const bankAccountService = Vue.prototype.$services.bankAccount;
      const result = await bankAccountService.activateBankAccount(key);
      await dispatch('getBankAccount', key);
      return result;
    },
    async blockBankAccount({ dispatch }, { key }) {
      const bankAccountService = Vue.prototype.$services.bankAccount;
      const result = await bankAccountService.blockBankAccount(key);
      await dispatch('getBankAccount', key);
      return result;
    },
  },
};
