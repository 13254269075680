<template>
  <card :title="title" icon="" id="branding-images-section">
    <template slot="headerZoneRight">
      <label-text
        class="text-xs-center"
        v-if="images"
        :label="`${$t('branding.details.branding_images_section.images_count')}`"
        :value="Object.keys(images).length.toString()"
      ></label-text>
      <div v-if="showUpdateBrandingImagesFeature" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text v-if="showUpdateBrandingImagesFeature()" :label="`${$t('branding.details.branding_images_section.action')}`">
        <link-secondary-action slot="labelTextLink">
          <label :for="'branding-images-file-input-' + this.role" :id="'branding-images-file-input-label-' + this.role">
            {{ $t(`branding.details.branding_images_section.upload`) }}
          </label>
        </link-secondary-action>
      </label-text>
    </template>
    <template slot="content">
      <input type="file" :id="'branding-images-file-input-' + this.role" multiple @change="addImages" @click="resetInputValue" />
      <label-none v-if="!brandingHasShowcaseImages && !loadingHasImages">{{ $t('branding.details.branding_images_section.none') }}</label-none>
      <v-layout row>
        <v-flex xs2 v-for="(image, index) in images" :key="index" class="branding-image-container">
          <img :src="`${image.url}`" />
          <a @click="deleteImage(image.id)"><v-icon small color="#fff">fad fa-trash-alt</v-icon></a>
        </v-flex>
        <v-flex xs2 v-for="(image, index) in loadingImages" :key="`loading-${index}`" class="branding-image-container">
          <div class="text-xs-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
        </v-flex>
      </v-layout>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'branding-showcase-images',
  mixins: [security, actionErrorTrackable, disableWithError],
  props: {
    images: {
      type: Array,
    },
    title: {
      type: String,
    },
    role: {
      type: String,
    },
  },
  data() {
    return {
      loadingImages: [],
    };
  },
  watch: {
    async images() {
      await this.resetLoadingImages();
    },
  },
  computed: {
    ...mapGetters('branding', ['types', 'branding']),
    brandingHasShowcaseImages() {
      return this.images && this.images.length > 0;
    },
    loadingHasImages() {
      return this.loadingImages && this.loadingImages.length > 0;
    },
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    showUpdateBrandingImagesFeature() {
      return this.canManageOffer(); // TODO change permission
    },
    async addImages(e) {
      const files = [...e.target.files];

      if (files.length) {
        const images = files.map((f) => f.name);
        const signedUploadUrls = await this.executeAction(
          { action: 'branding/createBrandingImagesUploadSignedUrls' },
          { branding_id: this.branding.id, images },
        );

        for (let key in signedUploadUrls) {
          this.loadingImages.push({});
        }

        for (let key in signedUploadUrls) {
          const signed_url = signedUploadUrls[key].upload_url;
          const image = files[key];
          await this.executeAction({ action: 'branding/uploadBrandingImage' }, { signed_url, image });
          await this.executeAction(
            { action: 'branding/addBrandingImage' },
            { branding_id: this.branding.id, payload: { role: this.role, url: signedUploadUrls[key].image_url } },
          );
        }

        this.resetLoadingImages();
      }
    },
    async deleteImage(image_id) {
      await this.executeAction({ action: 'branding/removeBrandingImage' }, { branding_id: this.branding.id, image_id });
    },
    resetInputValue(e) {
      e.target.value = null;
    },
    resetLoadingImages() {
      this.loadingImages = [];
    },
  },
};
</script>
