<template>
  <div>
    <table class="responsive-table">
      <thead>
        <tr>
          <td>{{ $t('instant_card_batches.generated_cards_table.id') }}</td>
          <td>{{ $t('instant_card_batches.generated_cards_table.public_token') }}</td>
          <td>{{ $t('instant_card_batches.generated_cards_table.barcode') }}</td>
          <td>{{ $t('instant_card_batches.generated_cards_table.unique_number') }}</td>
          <td>{{ $t('instant_card_batches.generated_cards_table.program') }}</td>
          <td>{{ $t('instant_card_batches.generated_cards_table.project') }}</td>
          <td>{{ $t('instant_card_batches.generated_cards_table.status') }}</td>
          <td>{{ $t('instant_card_batches.generated_cards_table.user') }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>
            <h5>{{ $t('instant_card_batches.generated_cards_table.id') }}:</h5>
            <router-link :to="{ name: 'instant_card_details', params: { cardId: item.public_token } }">
              {{ item.id }}
            </router-link>
          </td>
          <td>
            <h5>{{ $t('instant_card_batches.generated_cards_table.public_token') }}:</h5>
            <router-link v-if="item.status === 'active'" :to="{ name: 'card', params: { cardPublicToken: item.public_token } }">
              {{ item.public_token }}
            </router-link>
            <span v-else>{{ item.public_token }}</span>
          </td>
          <td>
            <h5>{{ $t('instant_card_batches.generated_cards_table.barcode') }}:</h5>
            <span v-if="item.barcode">{{ item.barcode }}</span>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('instant_card_batches.generated_cards_table.unique_number') }}:</h5>
            <span v-if="item.unique_number">{{ item.unique_number }}</span>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('instant_card_batches.generated_cards_table.program') }}:</h5>
            <span v-if="item.partner_id">{{ item.partner_id }}</span>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <router-link
              v-if="item.instant_card_project_id"
              :to="{ name: 'instant_card_project_details', params: { projectId: item.instant_card_project_id } }"
            >
              <span>{{ `${item.instant_card_project.name} (${item.instant_card_project_id})` }}</span>
            </router-link>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('instant_card_batches.generated_cards_table.status') }}:</h5>
            <span :class="!item.status ? 'info-not-available' : `instant-card-${item.status}`">{{
              $t(`instant_card_cards.status.${item.status}`) | orNotAvailable
            }}</span>
          </td>
          <td>
            <h5>{{ $t('instant_card_batches.linked_cards_table.user') }}:</h5>
            <router-link v-if="item.account_id" :to="{ name: 'user_details', params: { userId: item.account_id } }">
              <span>{{ item.account_id }}</span>
            </router-link>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-xs-center">
      <v-pagination v-if="listBatchCardsPageCount > 1" v-model="pagination" :length="listBatchCardsPageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'generated-cards-table',
  mixins: [security, actionErrorTrackable, disableWithError],
  props: {
    batchId: {
      type: String,
    },
    items: {
      type: Array,
    },
  },
  data() {
    return {
      pagination: 1,
    };
  },
  mounted() {
    this.pagination = this.listBatchCardsPageNumber;
  },
  computed: {
    ...mapGetters('instantCardBatch', [
      'batchCards',
      'listBatchCardsKeyword',
      'listBatchCardsPartnerId',
      'listBatchCardsStatus',
      'listBatchCardsPageCount',
      'listBatchCardsPageNumber',
      'listBatchCardsItemCount',
    ]),
  },
  watch: {
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'instantCardBatch/listBatchCards',
        },
        {
          batch_id: this.batchId,
          keyword: this.listBatchCardsKeyword,
          status: this.listBatchCardsStatus,
          partner_id: this.listBatchCardsPartnerId,
          page: newValue,
        },
      );
    },
  },
};
</script>
