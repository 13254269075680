<template>
  <table class="responsive-table" id="store-publishers-table">
    <thead>
      <tr>
        <td>{{ $t('store.publishers_table.fields.id') }}</td>
        <td>{{ $t('store.publishers_table.fields.name') }}</td>
        <td>{{ $t('store.publishers_table.fields.owner') }}</td>
        <td>{{ $t('store.publishers_table.fields.branding_id') }}</td>
        <td>{{ $t('store.publishers_table.fields.created') }}</td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in items" :key="index">
        <td>
          <h5>{{ $t('store.publishers_table.fields.id') }}:</h5>
          <router-link v-if="item.id" :to="{ name: 'closed_loop_details', params: { publishersId: item.id } }">
            {{ item.id }}
          </router-link>
        </td>
        <td>
          <h5>{{ $t('store.publishers_table.fields.name') }}:</h5>
          <span>{{ item.name }}</span>
        </td>
        <td>
          <h5>{{ $t('store.publishers_table.fields.owner') }}:</h5>
          <span>
            <router-link v-if="item.business && item.business.name" :to="{ name: 'business_details', params: { businessId: item.business_id } }">
              {{ item.business.name }}
            </router-link>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </span>
        </td>
        <td>
          <h5>{{ $t('store.publishers_table.fields.branding_id') }}:</h5>
          <span>
            <router-link v-if="item.branding_id" :to="{ name: 'branding_details', params: { brandingId: item.branding_id } }">
              {{ item.branding_id }}
            </router-link>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </span>
        </td>
        <td>
          <h5>{{ $t('store.publishers_table.fields.created') }}:</h5>
          <span>{{ item.creation_date | formatShortDateInverted | orNotAvailable }}</span>
        </td>
        <td>
          <link-secondary-action slot="labelTextLink" @click="openDeleteStorePublisherModal(item.id)">{{
            $t('store.publishers_table.delete_link')
          }}</link-secondary-action>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'store-publishers-table',
  props: {
    items: {
      type: Array,
    },
  },
  methods: {
    openDeleteStorePublisherModal(id) {
      this.$emit('store_publisher_delete_action', id);
    },
  },
};
</script>
