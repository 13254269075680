<template>
  <view-main title="page_title.card_applications">
    <v-flex slot="content" class="data-section">
      <h1 hidden>{{ $t('dashboards.card_applications.title') }}</h1>
      <dashboard
        id-name="card_applications_report"
        sharing-token="1c4f6ef8-34b1-427b-836a-43cb0e9909f4"
        report-id="94zr-32x5-jz8j"
        url-report-segment="v5dy-lwkl-r6z3"
        :timerange="timerange"
      ></dashboard>
    </v-flex>
  </view-main>
</template>

<script>
import { renderErrorTrackable } from '@/mixins';
import Dashboard from './Dashboard.vue';

export default {
  name: 'card-applications-report-view',
  mixins: [renderErrorTrackable],
  components: { Dashboard },
  data() {
    return {
      timerange: null,
    };
  },
  created() {
    if (this.$route.query.timerange) {
      this.timerange = this.$route.query.timerange;
    }
  },
};
</script>
