<template>
  <card :title="$t('store.details.place_section.title')" icon="fa-map-marker" id="store-type-place">
    <template slot="headerZoneRight">
      <label-text :label="`${$t('store.details.place_section.type')}`" :value="$t(`store.types.${store.type}`)" />
      <link-secondary-action slot="labelTextLink">
        <button-secondary-actions :text="$t(`system.actions`)" :actions="actions"> </button-secondary-actions>
      </link-secondary-action>
    </template>
    <template slot="content">
      <modal-confirm
        v-if="showGenerateCoordinatesModal"
        :show-modal="showGenerateCoordinatesModal"
        :title="$t(`store.generate_coordinates_action.title`)"
        context="generateStoreCoordinates"
        :text="$t(`store.generate_coordinates_action.text`, { storeName: store.name })"
        :cancel_text="`store.generate_coordinates_action.button_cancel`"
        :submit_text="`store.generate_coordinates_action.button_submit`"
        :submit_working="working"
        @submit="generateCoordinates"
        @close="closeGenerateCoordinatesModal"
      ></modal-confirm>
      <modal-action :show-modal="showSetCoordinatesModal"
        ><set-store-coordinates-action
          v-if="showSetCoordinatesModal"
          :store="store"
          @close="closeSetCoordinatesModal"
          @set="setCoordinatesSuccess"
        ></set-store-coordinates-action
      ></modal-action>
      <v-layout row>
        <v-flex xs3>
          <label-text :label="$t('store.details.place_section.address')" :value="fullAddress" />
        </v-flex>
        <v-flex v-if="store.coordinates.lat" xs3>
          <label-text :label="$t('store.details.place_section.coordinates')" :value="fullCoordinates" />
        </v-flex>

        <v-flex xs3>
          <label-text :label="$t('store.details.place_section.plus_code')" :value="plusCode" />
        </v-flex>
      </v-layout>
      <gmap-map
        v-if="store.coordinates.lat"
        :center="{ lat: store.coordinates.lat, lng: store.coordinates.lng }"
        :zoom="15"
        style="width: 100%; height: 500px"
      >
        <gmap-marker ref="theMarker" :position="latLng" :title="store.name" />
      </gmap-map>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';
import SetStoreCoordinatesAction from '../actions/SetStoreCoordinatesAction';
import _ from 'lodash';

import _get from 'lodash/get';

export default {
  name: 'type-place',
  components: { SetStoreCoordinatesAction },
  mixins: [security, actionErrorTrackable, disableWithError],
  data() {
    const $t = this.$t.bind(this);
    return {
      showSetCoordinatesModal: false,
      showGenerateCoordinatesModal: false,
      actions: [
        {
          slotName: 'generateCoordinates',
          name: $t('store.details.place_section.generate_coordinates'),
          action: () => this.openGenerateCoordinatesModal(),
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => !this.showGenerateCoordinatesFeature(),
        },
        {
          slotName: 'setCoordinates',
          name: $t('store.details.place_section.set_coordinates'),
          action: () => this.openSetCoordinatesModal(),
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => !this.showSetCoordinatesFeature(),
        },
      ],
    };
  },
  computed: {
    ...mapGetters('store', ['store']),
    ...mapGetters('ui', ['currentLocale']),
    fullCoordinates() {
      return `${this.store.coordinates.lat}, ${this.store.coordinates.lng}`;
    },
    plusCode() {
      return _get(this.store, 'location.address.plus_code', null);
    },
    latLng() {
      return {
        lat: this.store.coordinates.lat,
        lng: this.store.coordinates.lng,
      };
    },
    fullAddress() {
      if (this.store.location && !_.isEmpty(this.store.location.address)) {
        return `${this.store.location.address.street}, ${this.store.location.address.city}, ${this.store.location.address.state}, ${this.store.location.address.country}, ${this.store.location.address.zip}`;
      }
      return '';
    },
  },
  methods: {
    openSetCoordinatesModal() {
      this.showSetCoordinatesModal = true;
    },
    closeSetCoordinatesModal() {
      this.showSetCoordinatesModal = false;
    },
    showGenerateCoordinatesFeature() {
      return this.canManageOffer(); // TODO: change permission
    },
    showSetCoordinatesFeature() {
      return this.canManageOffer(); // TODO: change permission
    },
    async setCoordinatesSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'store.set_coordinates_action.success' });
      this.closeSetCoordinatesModal();
    },
    openGenerateCoordinatesModal() {
      this.showGenerateCoordinatesModal = true;
    },
    closeGenerateCoordinatesModal() {
      this.showGenerateCoordinatesModal = false;
    },
    async generateCoordinatesSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'store.generate_coordinates_action.success' });
      this.closeGenerateCoordinatesModal();
    },
    async generateCoordinates() {
      await this.executeAction(
        {
          action: 'store/regenerateStoreCoordinates',
          name: 'generateStoreCoordinates',
          success: this.generateCoordinatesSuccess,
        },
        this.store.id,
      );
    },
  },
};
</script>
