<template>
  <card-modal
    class="update-card-max-balance-action"
    icon=""
    :title="$t('card.update_card_max_balance_action.title')"
    context="setCardMaxBalance"
    @submit="submit"
    @cancel="cancel"
    cancel_text="card.update_card_max_balance_action.button_cancel"
    submit_text="card.update_card_max_balance_action.button_save"
    :submit_working="working"
    :enable_submit="isFormChanged"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-layout row wrap>
          <v-flex sm4 xs12>
            <v-text-field
              type="number"
              :label="$t('card.update_card_max_balance_action.form.balance.label')"
              v-model="cardMaxBalanceToUpdate"
              v-validate="{ required: true, numeric: true, max_value: $store.state.program.limits.card_upper_max_balance, min_value: 1 }"
              data-vv-name="balance"
              :error-messages="errors.collect('balance')"
              required
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';

export default {
  name: 'update-card-max-balance-action',
  $_veeValidate: {
    validator: 'new',
  },
  mixins: [actionErrorTrackable, security],
  props: {
    publicToken: {
      type: String,
      required: true,
    },
    cardMaxBalance: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isFormChanged() {
      return this.cardMaxBalanceToUpdate != '' && this.cardMaxBalanceToUpdate != this.cardMaxBalance;
    },
  },
  data: function() {
    return {
      cardMaxBalanceToUpdate: this.cardMaxBalance,
    };
  },
  methods: {
    clear() {
      this.resetForm();
      this.$validator.reset();
    },
    resetForm() {
      this.cardMaxBalanceToUpdate = '';
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    success() {
      this.clear();
      this.$emit('action');
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        await this.executeAction(
          {
            action: 'card/updateCardMaxBalance',
            name: 'setCardMaxBalance',
          },
          {
            card_public_token: this.publicToken,
            card_max_balance_amount: this.cardMaxBalanceToUpdate,
          },
        );

        this.success();
      }
    },
  },
};
</script>
