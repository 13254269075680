<template>
  <card :title="$t('branding.offers')" icon="">
    <template slot="headerZoneRight">
      <label-text
        class="text-xs-center"
        :label="`${$t('branding.details.offers_section.count')}`"
        :value="brandingOffersItemCount || '0'"
      ></label-text>
    </template>
    <template slot="content">
      <branding-offers-table v-if="brandingOffersItemCount > 0" :items="brandingOffers" :id="branding.id"></branding-offers-table>
      <label-none v-if="brandingOffersItemCount === 0">{{ $t('system.none') }}</label-none>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';
import BrandingOffersTable from './BrandingOffersTable.vue';

export default {
  name: 'branding-offers',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { BrandingOffersTable },
  data() {
    return {
      pagination: 1,
    };
  },
  async created() {
    await this.fetchBrandingOffers();
  },
  watch: {
    async branding() {
      await this.fetchBrandingOffers();
    },
    brandingOffersPageNumber() {
      this.pagination = this.brandingOffersPageNumber;
    },
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'branding/changeBrandingOffersPage',
          success: this.success,
        },
        {
          id: this.branding.id,
          page: newValue,
        },
      );
    },
  },
  computed: {
    ...mapGetters('branding', ['branding', 'brandingOffers', 'brandingOffersPageCount', 'brandingOffersPageNumber', 'brandingOffersItemCount']),
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    async fetchBrandingOffers() {
      await this.executeAction(
        { action: 'branding/listBrandingOffers' },
        {
          id: this.branding.id,
          page: this.brandingOffersPageNumber,
        },
      );
    },
  },
};
</script>
