<template>
  <div>
    <table class="responsive-table">
      <thead>
        <tr>
          <td>{{ $t('instant_card_projects.gift_certificates_table.id') }}</td>
          <td>{{ $t('instant_card_projects.gift_certificates_table.name') }}</td>
          <td>{{ $t('instant_card_projects.gift_certificates_table.program') }}</td>
          <td>{{ $t('instant_card_projects.gift_certificates_table.business') }}</td>
          <td align="center">{{ $t('instant_card_projects.gift_certificates_table.publishers') }}</td>
          <td>{{ $t('instant_card_projects.gift_certificates_table.incentive') }}</td>
          <td>{{ $t('instant_card_projects.gift_certificates_table.activation_period') }}</td>
          <td>{{ $t('instant_card_projects.gift_certificates_table.visibility') }}</td>
          <td>{{ $t('instant_card_projects.gift_certificates_table.offer_status') }}</td>
          <td>{{ $t('instant_card_projects.gift_certificates_table.project_status') }}</td>
          <td>{{ $t('instant_card_projects.gift_certificates_table.edit') }}</td>
          <td>{{ $t('instant_card_projects.gift_certificates_table.actions') }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>
            <h5>{{ $t('instant_card_projects.gift_certificates_table.id') }}:</h5>
            <router-link :to="{ name: 'offers_details', params: { offerId: item.offer_id } }">
              {{ `${item.branding && item.branding.name ? item.branding.name : ''} (${item.offer_id})` }}
            </router-link>
          </td>
          <td>
            <h5>{{ $t('instant_card_projects.gift_certificates_table.name') }}:</h5>
            <span>{{ item.branding.name }}</span>
          </td>
          <td>
            <h5>{{ $t('instant_card_projects.gift_certificates_table.program') }}:</h5>
            <span>{{ item.partner_id }}</span>
          </td>
          <td>
            <h5>{{ $t('instant_card_projects.gift_certificates_table.business') }}:</h5>
            <router-link v-if="item.owner_business_id" :to="{ name: 'business_details', params: { businessId: item.owner_business_id } }">
              {{ `${item.owner_business_name} (${item.owner_business_id})` }}
            </router-link>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td align="center">
            <h5>{{ $t('offers.offers_table.publishers') }}:</h5>
            <span>{{ item.publishers_count }}</span>
          </td>
          <td>
            <h5>{{ $t('instant_card_projects.gift_certificates_table.incentive') }}:</h5>
            <span>{{ item.payment_amount | dollarSignI18n }} - {{ item.bonus_amount | dollarSignI18n }}</span>
          </td>
          <td align="right">
            <h5>{{ $t('instant_card_projects.gift_certificates_table.activation_period') }}:</h5>
            <span v-if="item.availability">
              {{ item.availability.start_date | formatShortDateInverted }} - {{ item.availability.end_date | formatShortDateInverted }}
            </span>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('offers.offers_table.visibility') }}:</h5>
            <span :class="`offers-table-visibility-private-${item.features.private}`">{{ displayVisibility(item.features.private) }}</span>
          </td>
          <td>
            <h5>{{ $t('instant_card_projects.gift_certificates_table.offer_status') }}:</h5>
            <offer-status-view :status="item.offer_status" />
          </td>
          <td>
            <h5>{{ $t('instant_card_projects.gift_certificates_table.project_status') }}:</h5>
            <span :class="`status-${item.project_status}`">{{ $t(`code.instant_card_project_gift_certificate.status.${item.project_status}`) }}</span>
          </td>
          <td>
            <h5>{{ $t('instant_card_projects.gift_certificates_table.edit') }}:</h5>
            <link-secondary-action slot="labelTextLink" @click="openUpdateGiftCertificateModal(item.offer_id)">{{
              $t('instant_card_projects.gift_certificates_table.edit_link')
            }}</link-secondary-action>
          </td>
          <td>
            <h5>{{ $t('instant_card_projects.gift_certificates_table.actions') }}:</h5>
            <link-secondary-action v-if="activatedAndHasCard(item)" slot="labelTextLink" @click="openDeactivateGiftCertificateModal(item.id)">{{
              $t('instant_card_projects.gift_certificates_table.deactivate')
            }}</link-secondary-action>
            <link-secondary-action v-else-if="activatedAndNoCards(item)" slot="labelTextLink" @click="openRemoveGiftCertificateModal(item.id)">{{
              $t('instant_card_projects.gift_certificates_table.remove')
            }}</link-secondary-action>
            <link-secondary-action v-else-if="deactivated(item)" slot="labelTextLink" @click="openActivateGiftCertificateModal(item.id)">{{
              $t('instant_card_projects.gift_certificates_table.activate')
            }}</link-secondary-action>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-xs-center">
      <v-pagination v-if="projectGiftCertificatesPageCount > 1" v-model="pagination" :length="projectGiftCertificatesPageCount"></v-pagination>
    </div>

    <modal-action :show-modal="showUpdateGiftCertificateModal"
      ><update-project-gift-certificate-action
        v-if="showUpdateGiftCertificateModal"
        :project-offer="projectGiftCertificateToUpdate"
        :project-id="project.id"
        @close="closeUpdateGiftCertificateModal"
        @updated="updateGiftCertificateSuccess"
      ></update-project-gift-certificate-action
    ></modal-action>
    <modal-confirm
      :show-modal="showActivateGiftCertificateModal"
      :title="$t(`instant_card_projects.gift_certificates_table.activate_action.title`)"
      context="ActivateGiftCertificateModal"
      :text="$t(`instant_card_projects.gift_certificates_table.activate_action.text`)"
      :cancel_text="`instant_card_projects.gift_certificates_table.activate_action.button_cancel`"
      :submit_text="`instant_card_projects.gift_certificates_table.activate_action.button_submit`"
      @submit="activateGiftCertificateSuccess()"
      @close="closeActivateGiftCertificateModal"
    ></modal-confirm>
    <modal-confirm
      :show-modal="showDeactivateGiftCertificateModal"
      :title="$t(`instant_card_projects.gift_certificates_table.deactivate_action.title`)"
      context="DeactivateGiftCertificateModal"
      :text="$t(`instant_card_projects.gift_certificates_table.deactivate_action.text`)"
      :cancel_text="`instant_card_projects.gift_certificates_table.deactivate_action.button_cancel`"
      :submit_text="`instant_card_projects.gift_certificates_table.deactivate_action.button_submit`"
      @submit="deactivateGiftCertificateSuccess()"
      @close="closeDeactivateGiftCertificateModal"
    ></modal-confirm>
    <modal-confirm
      :show-modal="showRemoveGiftCertificateModal"
      :title="$t(`instant_card_projects.gift_certificates_table.remove_action.title`)"
      context="RemoveGiftCertificateModal"
      :text="$t(`instant_card_projects.gift_certificates_table.remove_action.text`)"
      :cancel_text="`instant_card_projects.gift_certificates_table.remove_action.button_cancel`"
      :submit_text="`instant_card_projects.gift_certificates_table.remove_action.button_submit`"
      @submit="removeGiftCertificateSuccess()"
      @close="closeRemoveGiftCertificateModal"
    ></modal-confirm>
  </div>
</template>

<script>
import { actionErrorTrackable, renderErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import UpdateProjectGiftCertificateAction from '@/components/instant_card_project/actions/UpdateProjectGiftCertificateAction';
import InstantCardProjectOfferStatus from '@/enums/instant-card-project-offer-status';
import OfferStatusView from '../../offers/OfferStatusView';

export default {
  name: 'gift-certificates-table',
  mixins: [renderErrorTrackable, actionErrorTrackable],
  components: { UpdateProjectGiftCertificateAction, OfferStatusView },
  props: {
    items: {
      type: Array,
    },
    project: {
      type: Object,
    },
  },
  data() {
    return {
      pagination: 1,
      giftCertificateId: null,
      projectGiftCertificateToUpdate: {},
      showUpdateGiftCertificateModal: false,
      showActivateGiftCertificateModal: false,
      showDeactivateGiftCertificateModal: false,
      showRemoveGiftCertificateModal: false,
    };
  },
  mounted() {
    this.pagination = this.projectGiftCertificatesPageNumber;
  },
  watch: {
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'instantCardProject/listProjectGiftCertificates',
        },
        {
          id: this.project.id,
          page: newValue,
        },
      );
    },
  },
  computed: {
    ...mapGetters('instantCardProject', [
      'projectGiftCertificatesAvailable',
      'projectGiftCertificatesPageCount',
      'projectGiftCertificatesPageNumber',
      'listProjectCardsItemCount',
    ]),
  },
  methods: {
    displayVisibility(visibility) {
      return visibility === true ? this.$t('offers.offers_table.visibility_type.private') : this.$t('offers.offers_table.visibility_type.public');
    },
    activatedAndHasCard(item) {
      return item.project_status === InstantCardProjectOfferStatus.AVAILABLE && this.project.linked_card_count > 0;
    },
    deactivated(item) {
      return item.project_status === InstantCardProjectOfferStatus.DEACTIVATED && this.projectGiftCertificatesAvailable === 0;
    },
    activatedAndNoCards(item) {
      return item.project_status === InstantCardProjectOfferStatus.AVAILABLE && this.project.linked_card_count === 0;
    },
    openUpdateGiftCertificateModal(offerId) {
      this.projectGiftCertificateToUpdate = this.items.find((l) => l.offer_id === offerId);
      this.showUpdateGiftCertificateModal = true;
    },
    closeUpdateGiftCertificateModal() {
      this.projectGiftCertificateToUpdate = {};
      this.showUpdateGiftCertificateModal = false;
    },
    openActivateGiftCertificateModal(giftCertificateId) {
      this.showActivateGiftCertificateModal = true;
      this.giftCertificateId = giftCertificateId;
    },
    closeActivateGiftCertificateModal() {
      this.showActivateGiftCertificateModal = false;
      this.giftCertificateId = null;
    },
    openDeactivateGiftCertificateModal(giftCertificateId) {
      this.showDeactivateGiftCertificateModal = true;
      this.giftCertificateId = giftCertificateId;
    },
    closeDeactivateGiftCertificateModal() {
      this.showDeactivateGiftCertificateModal = false;
      this.giftCertificateId = null;
    },
    openRemoveGiftCertificateModal(giftCertificateId) {
      this.showRemoveGiftCertificateModal = true;
      this.giftCertificateId = giftCertificateId;
    },
    closeRemoveGiftCertificateModal() {
      this.showRemoveGiftCertificateModal = false;
      this.giftCertificateId = null;
    },
    async updateGiftCertificateSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'instant_card_projects.gift_certificates_table.update_action.success' });
      this.closeUpdateGiftCertificateModal();
    },
    async activateGiftCertificateSuccess() {
      const theGiftCertificateId = this.giftCertificateId;
      this.giftCertificateId = null;

      await this.executeAction(
        {
          action: 'instantCardProject/activateProjectGiftCertificate',
          name: 'ActivateGiftCertificateModal',
          success: async () => {
            this.showActivateGiftCertificateModal = false;
            this.$emit('activate_gift_certificate_action');
            await this.executeAction(
              { action: 'ui/showSuccessSnackBar' },
              { text: 'instant_card_projects.gift_certificates_table.activate_action.success' },
            );
          },
        },
        { id: theGiftCertificateId, projectId: this.project.id },
      );
    },
    async deactivateGiftCertificateSuccess() {
      this.showDeactivateGiftCertificateModal = false;
      const theGiftCertificateId = this.giftCertificateId;
      this.giftCertificateId = null;

      await this.executeAction(
        {
          action: 'instantCardProject/deactivateProjectGiftCertificate',
        },
        { id: theGiftCertificateId, projectId: this.project.id },
      );
      this.$emit('deactivate_gift_certificate_action');
      await this.executeAction(
        { action: 'ui/showSuccessSnackBar' },
        { text: 'instant_card_projects.gift_certificates_table.deactivate_action.success' },
      );
    },
    async removeGiftCertificateSuccess() {
      this.showRemoveGiftCertificateModal = false;
      const theGiftCertificateId = this.giftCertificateId;
      this.giftCertificateId = null;

      await this.executeAction(
        {
          action: 'instantCardProject/removeProjectGiftCertificate',
        },
        { id: theGiftCertificateId, projectId: this.project.id },
      );
      this.$emit('remove_gift_certificate_action');
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'instant_card_projects.gift_certificates_table.remove_action.success' });
    },
  },
};
</script>
