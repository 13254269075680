<template>
  <card-modal
    icon=""
    :title="$t(`application.reject_application_action.title`)"
    context="rejectApplication"
    @submit="submit"
    @cancel="cancel"
    cancel_text="application.reject_application_action.button_cancel"
    submit_text="application.reject_application_action.button_save"
    :submit_working="working"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <create-aml-note-partial
          v-if="canManageAmlProgram()"
          default-category-id="aml-note"
          default-type-id="kyc-identity-verification"
          :card-program-key="cardProgramKey"
          :cardholder-key="cardholderKey"
          :account-id="accountId"
          ref="createAmlNote"
          :card-application-id="cardApplicationId"
        />
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';

export default {
  name: 'reject-application-action',
  mixins: [actionErrorTrackable, security],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    accountId: {
      type: String,
    },
    cardApplicationId: {
      type: String,
      required: true,
    },
    cardholderKey: {
      type: String,
      required: false,
    },
    cardProgramKey: {
      type: String,
      required: false,
    },
  },
  methods: {
    clear() {
      this.$validator.reset();
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    success() {
      this.clear();
      this.$emit('action');
    },
    async submit() {
      const createAmlNoteRef = this.$refs.createAmlNote;

      const validationComplete = await this.$validator.validateAll();
      const amlNoteValidation = await createAmlNoteRef.$validator.validateAll();

      if (validationComplete && amlNoteValidation) {
        await this.executeAction(
          {
            action: 'application/rejectApplication',
            name: 'rejectApplication',
            success: this.success,
          },
          {
            card_application_id: this.cardApplicationId,
          },
        );

        await createAmlNoteRef.submit();
      }
    },
  },
};
</script>
