<template>
  <view-main title="page_title.voucher_details">
    <four-levels-breadcrumbs
      v-if="voucher.account_id"
      slot="content"
      :top-label="$t('navigation.users')"
      :top-to="{ name: 'users' }"
      :second-label="voucher.account_id"
      :second-to="{ name: 'user_details', params: { userId: voucher.account_id } }"
      :third-label="$t('navigation.vouchers')"
      :last-label="voucher.id"
    />
    <voucher-alert slot="content"></voucher-alert>
    <voucher-information slot="content" />
    <voucher-operations slot="content" />
  </view-main>
</template>

<script>
import { actionErrorTrackable, renderErrorTrackable } from '@/mixins';
import VoucherInformation from './detail/VoucherInformation.vue';
import VoucherAlert from './detail/components/VoucherAlert.vue';
import VoucherOperations from './detail/VoucherOperations.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'voucher-details-view',
  mixins: [renderErrorTrackable, actionErrorTrackable],
  components: { VoucherInformation, VoucherOperations, VoucherAlert },
  props: {
    voucherId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('voucher', ['voucher']),
  },
  async mounted() {
    await this.fetch();
  },
  watch: {
    async voucherId() {
      return this.fetch();
    },
  },
  methods: {
    async fetch() {
      const voucher = await this.executeAction({ action: 'voucher/getVoucher' }, this.voucherId);
      if (!voucher) {
        this.$router.replace({ name: 'notFound' });
      }
    },
  },
};
</script>
