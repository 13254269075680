<template>
  <card :title="$t('clerk.details.clerk_information_section.title')" icon="fal fa-store">
    <template slot="headerZoneRight">
      <label-text :label="`${$t('clerk.details.clerk_information_section.id')}`" :value="clerk.id">
        <p slot="labelTextLink" class="label-text--value">
          <v-layout row>
            {{ clerk.id }}
            <button class="clipboard" v-clipboard:copy="clerk.id">
              <v-icon small>file_copy</v-icon>
            </button>
          </v-layout>
        </p>
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="$t('clerk.details.clerk_information_section.name')" :value="clerk.name" />

      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="$t('clerk.details.clerk_information_section.username')" :value="clerk.username">
        <p slot="labelTextLink" class="label-text--value">
          <v-layout row>
            {{ clerk.username }}
            <button class="clipboard" v-clipboard:copy="clerk.username">
              <v-icon small>file_copy</v-icon>
            </button>
          </v-layout>
        </p>
      </label-text>

      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="$t('clerk.details.clerk_information_section.email')" :value="clerk.email" />
      <button v-if="clerk.email" class="clipboard" v-clipboard:copy="clerk.email ? clerk.email : ''">
        <v-icon small>file_copy</v-icon>
      </button>

      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text
        :label="$t('clerk.details.clerk_information_section.date_created')"
        :value="clerk.creation_date | formatShortDateInverted | orNotAvailable"
      />

      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="$t('clerk.details.clerk_information_section.status')">
        <clerk-status-view slot="labelTextLink" :status="clerk.status" />
      </label-text>

      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>

      <link-secondary-action slot="labelTextLink">
        <button-secondary-actions :text="$t(`clerk.details.clerk_information_section.actions`)" :actions="actions"> </button-secondary-actions>
      </link-secondary-action>

      <modal-action :show-modal="showEditModal" avoid-rendering
        ><update-clerk-action v-if="showEditModal" :clerk="clerk" @close="closeEditModal" @updated="editSuccess"></update-clerk-action
      ></modal-action>

      <modal-action :show-modal="showChangePasscodeModal"
        ><change-passcode-action
          v-if="showChangePasscodeModal"
          :clerk="clerk"
          @close="closeChangePasscodeModal"
          @changed="changePasscodeSuccess"
        ></change-passcode-action
      ></modal-action>

      <modal-confirm
        :show-modal="showActivateModal"
        :title="$t('clerk.activate_action.confirm.title')"
        context="activate"
        :text="$t('clerk.activate_action.confirm.text')"
        :cancel_text="'clerk.activate_action.confirm.button_cancel'"
        :submit_text="'clerk.activate_action.confirm.button_submit'"
        :submit_working="working"
        @submit="activate"
        @close="closeActivateModal"
      ></modal-confirm>
      <modal-confirm
        :show-modal="showDeactivateModal"
        :title="$t('clerk.deactivate_action.confirm.title')"
        context="deactivate"
        :text="$t('clerk.deactivate_action.confirm.text')"
        :cancel_text="'clerk.deactivate_action.confirm.button_cancel'"
        :submit_text="'clerk.deactivate_action.confirm.button_submit'"
        :submit_working="working"
        @submit="deactivate"
        @close="closeDeactivateModal"
      ></modal-confirm>
      <modal-confirm
        :show-modal="showUnlockModal"
        :title="$t('clerk.unlock_action.confirm.title')"
        context="unlock"
        :text="$t('clerk.unlock_action.confirm.text')"
        :cancel_text="'clerk.unlock_action.confirm.button_cancel'"
        :submit_text="'clerk.unlock_action.confirm.button_submit'"
        :submit_working="working"
        @submit="unlock"
        @close="closeUnlockModal"
      ></modal-confirm>
      <modal-confirm
        :show-modal="showSuspendModal"
        :title="$t('clerk.suspend_action.confirm.title')"
        context="suspend"
        :text="$t('clerk.suspend_action.confirm.text')"
        :cancel_text="'clerk.suspend_action.confirm.button_cancel'"
        :submit_text="'clerk.suspend_action.confirm.button_submit'"
        :submit_working="working"
        @submit="suspend"
        @close="closeSuspendModal"
      ></modal-confirm>
    </template>
    <template slot="content">
      <label-text :label="`${$t('clerk.details.clerk_information_section.language')}`" :value="clerk.language" />

      <label-text :label="`${$t('clerk.details.clerk_information_section.roles')}`" :value="rolesFormatted" />

      <label-text :label="`${$t('clerk.details.clerk_information_section.establishments')}`">
        <p v-for="(establishment, index) in clerk.establishments" :key="index" slot="labelTextLink" class="label-text--value">
          <router-link :to="{ name: 'establishment_details', params: { establishmentId: establishment.id } }">
            {{ establishment.id }} ({{ establishment.business_name }})
          </router-link>
        </p>
      </label-text>

      <label-text :label="`${$t('clerk.details.clerk_information_section.last_auth_date')}`" :value="clerk.last_auth_date">
        <p slot="labelTextLink" class="label-text--value">
          <span v-if="clerk.last_auth_date">{{
            clerk.last_auth_date | formatShortDateTimeNoSecondsInverted({ withAgo: true, returnNullIfNoDate: true, timezone: 'Canada/Eastern' })
          }}</span>
          <label-none v-else>{{ $t('system.na') }}</label-none>
        </p>
      </label-text>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';
import _get from 'lodash/get';
import UpdateClerkAction from '../actions/UpdateClerkAction.vue';
import ClerkStatusView from '@/components/clerk/ClerkStatusView';
import ChangePasscodeAction from '../actions/ChangePasscodeAction.vue';

export default {
  name: 'clerk-information',
  components: { ChangePasscodeAction, UpdateClerkAction, ClerkStatusView },
  mixins: [security, actionErrorTrackable, disableWithError],
  data() {
    return {
      showEditModal: false,
      showChangePasscodeModal: false,
      showActivateModal: false,
      showDeactivateModal: false,
      showUnlockModal: false,
      showSuspendModal: false,
      actions: [
        {
          slotName: 'EditModal',
          name: this.$t('clerk.details.clerk_information_section.edit'),
          action: () => {
            this.openEditModal();
          },
          isAvailable: () => this.canManageOffer(), // TODO change
          isDisabled: () => {
            return !this.showEditFeature();
          },
        },
        {
          slotName: 'ChangePasscodeModal',
          name: this.$t('clerk.details.clerk_information_section.change_passcode'),
          action: () => {
            this.openChangePasscodeModal();
          },
          isAvailable: () => this.canManageOffer(), // TODO change
          isDisabled: () => {
            return !this.showChangePasscodeFeature();
          },
        },
        {
          slotName: 'activateModal',
          name: this.$t('clerk.details.clerk_information_section.activate'),
          action: () => {
            this.openActivateModal();
          },
          isAvailable: () => this.canManageOffer(), // TODO change
          isDisabled: () => !this.showActivateFeature(),
        },
        {
          slotName: 'unlockModal',
          name: this.$t('clerk.details.clerk_information_section.unlock'),
          action: () => {
            this.openUnlockModal();
          },
          isAvailable: () => this.canManageOffer(), // TODO change
          isDisabled: () => !this.showUnlockFeature(),
        },
        {
          slotName: 'deactivateModal',
          name: this.$t('clerk.details.clerk_information_section.deactivate'),
          action: () => {
            this.openDeactivateModal();
          },
          isAvailable: () => this.canManageOffer(), // TODO change
          isDisabled: () => !this.showDeactivateFeature(),
        },
        // NOTE (SG) Temporarily removed until we found it usefull to manage the suspend status
        // {
        //   slotName: 'suspendModal',
        //   name: this.$t('clerk.details.clerk_information_section.suspend'),
        //   action: () => {
        //     this.openSuspendModal();
        //   },
        //   isAvailable: () => this.canManageOffer(), // TODO change
        //   isDisabled: () => !this.showSuspendFeature(),
        // },
      ],
    };
  },
  computed: {
    ...mapGetters('partnerSecurity', ['clerk']),
    rolesFormatted() {
      if (this.clerk && this.clerk.roles) {
        const rolesFormat = _.map(this.clerk.roles, (role) => {
          return this.$t(`clerk.roles.${role}`);
        }).sort();
        return rolesFormat.join(', ');
      }
      return '';
    },
  },
  methods: {
    openEditModal() {
      this.showEditModal = true;
    },
    closeEditModal() {
      this.showEditModal = false;
    },
    async editSuccess() {
      this.showEditModal = false;
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'clerk.update_action.success' });
      this.closeEditModal();
    },
    openChangePasscodeModal() {
      this.showChangePasscodeModal = true;
    },
    closeChangePasscodeModal() {
      this.showChangePasscodeModal = false;
    },
    async changePasscodeSuccess() {
      this.showChangePasscodeModal = false;
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'clerk.change_passcode_action.success' });
      this.closeChangePasscodeModal();
    },
    openActivateModal() {
      this.showActivateModal = true;
    },
    closeActivateModal() {
      this.clerkIdToActivate = null;
      this.showActivateModal = false;
    },
    async activate() {
      await this.executeAction(
        {
          action: 'partnerSecurity/activateClerk',
          name: 'activate',
          success: this.activateSuccess,
        },
        this.clerk.id,
      );
    },
    async activateSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'clerk.activate_action.success' });
      this.closeActivateModal();
    },
    openDeactivateModal() {
      this.showDeactivateModal = true;
    },
    closeDeactivateModal() {
      this.showDeactivateModal = false;
    },
    async deactivate() {
      await this.executeAction(
        {
          action: 'partnerSecurity/deactivateClerk',
          name: 'deactivate',
          success: this.deactivateSuccess,
        },
        this.clerk.id,
      );
    },
    async deactivateSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'clerk.deactivate_action.success' });
      this.closeDeactivateModal();
    },
    openUnlockModal() {
      this.showUnlockModal = true;
    },
    closeUnlockModal() {
      this.showUnlockModal = false;
    },
    async unlock() {
      await this.executeAction(
        {
          action: 'partnerSecurity/unlockClerk',
          name: 'unlock',
          success: this.unlockSuccess,
        },
        this.clerk.id,
      );
    },
    async unlockSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'clerk.unlock_action.success' });
      this.closeUnlockModal();
    },
    openSuspendModal(id) {
      this.showSuspendModal = true;
    },
    closeSuspendModal() {
      this.showSuspendModal = false;
    },
    async suspend() {
      await this.executeAction(
        {
          action: 'partnerSecurity/suspendClerk',
          name: 'suspend',
          success: this.suspendSuccess,
        },
        this.clerk.id,
      );
    },
    async suspendSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'clerk.suspend_action.success' });
      this.closeSuspendModal();
    },
    extractCardProgramValue(item) {
      const isHavingClassical = _get(item, 'partner_card_packages.en');
      const isHavingInstant = _get(item, 'partner_instant_card_packages.en');

      if (isHavingClassical && isHavingInstant) {
        return this.$t('clerk.clerks_table.card_selector.values.classical_or_instant');
      } else if (isHavingClassical) {
        return this.$t('clerk.clerks_table.card_selector.values.classical_only');
      } else if (isHavingInstant) {
        return this.$t('clerk.clerks_table.card_selector.values.instant_only');
      }
      return null;
    },
    showEditFeature() {
      return true;
    },
    showChangePasscodeFeature() {
      return true;
    },
    showActivateFeature() {
      return this.clerk.status !== 'active';
    },
    showDeactivateFeature() {
      return this.clerk.status !== 'deactivated';
    },
    showUnlockFeature() {
      return this.clerk.locked;
    },
    showSuspendFeature() {
      return this.clerk.status !== 'suspended';
    },
  },
};
</script>
