var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"card"},[_c('v-breadcrumbs',{attrs:{"items":[
      {
        text: this.topLabel,
        disabled: !!!this.to,
        to: this.to,
        exact: true,
      },
      {
        text: this.lastLabel,
        disabled: true,
      } ]}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }