<template>
  <div class="offer-promotion-active">
    <span v-if="active" :class="`active-${active}`">{{ $t(`offer_promotions.active.${active}`) }}</span>
  </div>
</template>

<script>
export default {
  name: 'offer-promotion-active',
  props: {
    active: {
      type: String,
    },
  },
};
</script>
