<template>
  <card :title="$t('business.webhooks.title')" icon="">
    <template slot="headerZoneRight">
      <label-text class="text-sm-center" :label="$t('business.webhooks.count')" :value="businessWebhooksItemCount || '0'"></label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text class="text-sm-center" :label="$t('business.webhooks.action')">
        <link-secondary-action slot="labelTextLink" @click="showAddBusinessWebhookModal()">
          {{ $t('business.webhooks.add') }}
        </link-secondary-action>
      </label-text>
    </template>
    <template slot="content">
      <modal-action :show-modal="addBusinessWebhookModalShown" close>
        <add-business-webhook
          :business-id="business.id"
          @success="businessWebhookCreated"
          @close="closeAddBusinessWebhookModal"
        ></add-business-webhook
      ></modal-action>
      <business-webhooks-table v-if="businessWebhooks.length" :items="businessWebhooks" :business-id="business.id" />
      <label-none v-if="businessWebhooks.length === 0">{{ $t('system.none') }}</label-none>
    </template>
  </card>
</template>

<script>
import { security, disableWithError, actionErrorTrackable } from '@/mixins';
import BusinessWebhooksTable from './BusinessWebhooksTable';
import AddBusinessWebhook from '../actions/AddBusinessWebhook';
import { mapGetters } from 'vuex';

export default {
  name: 'business-webhooks',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: {
    BusinessWebhooksTable,
    AddBusinessWebhook,
  },
  data() {
    return {
      addBusinessWebhookModalShown: false,
    };
  },
  watch: {
    async business() {
      await this.fetchWebhooks();
    },
  },
  computed: {
    ...mapGetters('business', ['business', 'businessWebhooks', 'businessWebhooksPageNumber', 'businessWebhooksItemCount']),
  },
  methods: {
    async fetchWebhooks() {
      await this.executeAction({ action: 'business/listBusinessWebhooks' }, { id: this.business.id, page: 1 });
    },
    showAddBusinessWebhookModal() {
      this.addBusinessWebhookModalShown = true;
    },
    async businessWebhookCreated() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: `business.webhooks.add_modal.success` });
      this.addBusinessWebhookModalShown = false;
      await this.fetchWebhooks();
    },
    closeAddBusinessWebhookModal() {
      this.addBusinessWebhookModalShown = false;
    },
  },
};
</script>
