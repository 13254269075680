<template>
  <card :title="$t('offers.details.offers_urls_section.title')" icon="" id="offers-url-information">
    <template slot="content">
      <v-layout row class="section-wrap">
        <v-flex xs12 sm5>
          <h4 v-if="this.isBonusOffer">{{ $t('offers.details.offers_urls_section.activation_urls.bonus') }}</h4>
          <h4 v-if="this.isGiftCertificateOffer">{{ $t('offers.details.offers_urls_section.activation_urls.gift_certificate') }}</h4>
          <label-text :label="$t(`offers.details.offers_urls_section.activation_urls.raw`)" :value="offer.activation_url_raw">
            <p v-if="offer.activation_url_raw" class="label-text--value" slot="labelTextLink">
              <v-layout row>
                <a :href="offer.activation_url_raw" target="_blank">{{ offer.activation_url_raw }}</a>
                <button class="clipboard" v-clipboard:copy="offer.activation_url_raw ? offer.activation_url_raw : ''">
                  <v-icon small>file_copy</v-icon>
                </button>
              </v-layout>
            </p>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </label-text>
          <label-text :label="$t(`offers.details.offers_urls_section.activation_urls.preview`)" :value="offer.activation_url_raw">
            <p v-if="offer.activation_url_raw" class="label-text--value" slot="labelTextLink">
              <v-layout row>
                <a :href="offer.activation_url_raw + '?preview=true'" target="_blank">{{ offer.activation_url_raw + '?preview=true' }}</a>
                <button class="clipboard" v-clipboard:copy="offer.activation_url_raw ? offer.activation_url_raw + '?preview=true' : ''">
                  <v-icon small>file_copy</v-icon>
                </button>
              </v-layout>
            </p>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </label-text>
          <label-text :label="$t(`offers.details.offers_urls_section.activation_urls.friendly`)" :value="offer.activation_url_friendly">
            <p v-if="offer.activation_url_friendly" class="label-text--value" slot="labelTextLink">
              <v-layout row>
                <a :href="offer.activation_url_friendly" target="_blank">{{ offer.activation_url_friendly }}</a>
                <button class="clipboard" v-clipboard:copy="offer.activation_url_friendly ? offer.activation_url_friendly : ''">
                  <v-icon small>file_copy</v-icon>
                </button>
              </v-layout>
            </p>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </label-text>
          <label-text :label="$t(`offers.details.offers_urls_section.activation_urls.short`)" :value="offer.activation_url_short">
            <p v-if="offer.activation_url_short" class="label-text--value" slot="labelTextLink">
              <v-layout row>
                <a :href="offer.activation_url_short" target="_blank">{{ offer.activation_url_short }}</a>
                <button class="clipboard" v-clipboard:copy="offer.activation_url_short ? offer.activation_url_short : ''">
                  <v-icon small>file_copy</v-icon>
                </button>
              </v-layout>
            </p>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </label-text>
        </v-flex>
        <v-flex xs12 sm5 v-if="this.isGiftCertificateOffer && this.isCorporateGiftProgram">
          <h4>{{ $t('offers.details.offers_urls_section.corpo_urls.title') }}</h4>
          <label-text :label="$t(`offers.details.offers_urls_section.corpo_urls.raw`)" :value="offer.corpo_url_raw">
            <p v-if="offer.corpo_url_raw" class="label-text--value" slot="labelTextLink">
              <v-layout row>
                <a :href="offer.corpo_url_raw" target="_blank">{{ offer.corpo_url_raw }}</a>
                <button class="clipboard" v-clipboard:copy="offer.corpo_url_raw ? offer.corpo_url_raw : ''">
                  <v-icon small>file_copy</v-icon>
                </button>
              </v-layout>
            </p>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </label-text>
          <label-text :label="$t(`offers.details.offers_urls_section.corpo_urls.friendly`)" :value="offer.corpo_url_friendly">
            <p v-if="offer.corpo_url_friendly" class="label-text--value" slot="labelTextLink">
              <v-layout row>
                <a :href="offer.corpo_url_friendly" target="_blank">{{ offer.corpo_url_friendly }}</a>
                <button class="clipboard" v-clipboard:copy="offer.corpo_url_friendly ? offer.corpo_url_friendly : ''">
                  <v-icon small>file_copy</v-icon>
                </button>
              </v-layout>
            </p>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </label-text>
          <label-text :label="$t(`offers.details.offers_urls_section.corpo_urls.short`)" :value="offer.corpo_url_short">
            <p v-if="offer.corpo_url_short" class="label-text--value" slot="labelTextLink">
              <v-layout row>
                <a :href="offer.corpo_url_short" target="_blank">{{ offer.corpo_url_short }}</a>
                <button class="clipboard" v-clipboard:copy="offer.corpo_url_short ? offer.corpo_url_short : ''">
                  <v-icon small>file_copy</v-icon>
                </button>
              </v-layout>
            </p>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </label-text>
        </v-flex>
      </v-layout>
    </template>
  </card>
</template>

<script>
import { security, disableWithError, actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import LabelNone from '@/components/system/Labels/LabelNone';
import _ from 'lodash';

export default {
  name: 'offers-urls',
  components: {
    LabelNone,
  },
  mixins: [security, actionErrorTrackable, disableWithError],
  data() {
    this.$t.bind(this);
    return {};
  },
  computed: {
    ...mapGetters('offers', ['offer']),
    ...mapGetters('ui', ['currentLocale']),
    isBonusOffer() {
      return this.offer.type === 'bonus';
    },
    isGiftCertificateOffer() {
      return this.offer.type === 'gift_certificate';
    },
    isCorporateGiftProgram() {
      return _.get(this.offer, 'corporate.enabled');
    },
  },
  methods: {},
};
</script>

<style scoped lang="stylus">
@import './../../../styles/variables/custom-variables.styl';
.section-wrap {
  flex-wrap: wrap;
}
h5 {
  color: $hc-blue;

  &.sub-title {
    color: $font-color;
  }
}

#offers-url-information h4 {
  padding-top 0;
}
</style>
