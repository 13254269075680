<template>
  <card-modal
    icon="fal fa-store-alt"
    :title="$t('offers.details.offers_information_section.consumer_gift_program.update.title')"
    context="updateOfferConsumerDetails"
    @submit="submit"
    @cancel="cancel"
    cancel_text="offers.details.offers_information_section.consumer_gift_program.update.cancel"
    submit_text="offers.details.offers_information_section.consumer_gift_program.update.save"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-layout row wrap pad-form-row-elements>
          <v-flex xs12>
            <v-checkbox
              name="enabled"
              v-model="currentConsumerDetails.enabled"
              :label="$t(`offers.details.offers_information_section.consumer_gift_program.enabled`)"
              :error-messages="errors.collect('enabled')"
              data-vv-name="enabled"
              :data-vv-as="$t(`offers.details.offers_information_section.consumer_gift_program.enabled`)"
              type="checkbox"
            ></v-checkbox>
            <v-text-field
              v-model="currentConsumerDetails.purchase_limit.min"
              :label="$t('offers.details.offers_information_section.consumer_gift_program.update.purchase_limit_min') + '*'"
              name="purchase_limit_min"
              append-icon="fas fa-dollar-sign"
              v-validate="{
                required: !!currentConsumerDetails.enabled,
                integer: true,
                min_value: 1,
                max_value: 1000,
                between: [1, currentConsumerDetails.purchase_limit.max],
              }"
              :error-messages="errors.collect('purchase_limit_min')"
              data-vv-name="purchase_limit_min"
              :data-vv-as="$t('offers.details.offers_information_section.consumer_gift_program.update.purchase_limit_min')"
              data-test="update-consumer-details--purchase_limit_min"
              :disabled="!currentConsumerDetails.enabled"
              requiredi
            ></v-text-field>
            <v-text-field
              v-model="currentConsumerDetails.purchase_limit.max"
              :label="$t('offers.details.offers_information_section.consumer_gift_program.update.purchase_limit_max') + '*'"
              name="purchase_limit_max"
              append-icon="fas fa-dollar-sign"
              v-validate="{
                required: !!currentConsumerDetails.enabled,
                integer: true,
                min_value: 1,
                max_value: 1000,
                between: [currentConsumerDetails.purchase_limit.min, 1000],
              }"
              :error-messages="errors.collect('purchase_limit_max')"
              data-vv-name="purchase_limit_max"
              :data-vv-as="$t('offers.details.offers_information_section.consumer_gift_program.update.purchase_limit_max')"
              data-test="update-consumer-details--purchase_limit_max"
              :disabled="!currentConsumerDetails.enabled"
              required
            ></v-text-field>
            <v-text-field
              v-model="currentConsumerDetails.purchase_default_amount"
              :label="$t('offers.details.offers_information_section.consumer_gift_program.update.purchase_default_amount') + '*'"
              name="purchase_default_amount"
              append-icon="fas fa-dollar-sign"
              v-validate="{
                required: !!currentConsumerDetails.enabled,
                integer: true,
                min_value: currentConsumerDetails.purchase_limit.min,
                max_value: currentConsumerDetails.purchase_limit.max,
                between: [currentConsumerDetails.purchase_limit.min, currentConsumerDetails.purchase_limit.max],
              }"
              :error-messages="errors.collect('purchase_default_amount')"
              data-vv-name="purchase_default_amount"
              :data-vv-as="$t('offers.details.offers_information_section.consumer_gift_program.update.purchase_default_amount')"
              data-test="update-consumer-details--purchase_default_amount"
              :disabled="!currentConsumerDetails.enabled"
              required
            ></v-text-field>
            <h-autocomplete
              v-model="currentConsumerDetails.purchase_inventory_id"
              :label="$t('offers.details.offers_information_section.consumer_gift_program.update.purchase_inventory')"
              clearable
              :error-messages="errors.collect('purchase_inventory_id')"
              name="purchase_inventory_id"
              :alternate-text="inventoryLookupItemText"
              :disabled="!currentConsumerDetails.enabled"
              data-vv-name="purchase_inventory_id"
              data-vv-as="purchase_inventory_id"
              search-action="inventory/autocompleteSearch"
              data-test="update-consumer-details--purchase_inventory_id"
            >
            </h-autocomplete>
          </v-flex>
        </v-layout>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import _cloneDeep from 'lodash/cloneDeep';
import _isNull from 'lodash/isNull';
import Vue from 'vue';

export default {
  name: 'update-offer-consumer-details-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    offer: {
      type: Object,
      required: true,
    },
    helloShow: {
      type: Boolean,
    },
  },
  data() {
    return {
      currentConsumerDetails: {
        enabled: false,
        purchase_default_amount: null,
        purchase_limit: {
          min: null,
          max: null,
        },
        purchase_inventory_id: null,
      },
    };
  },
  async created() {
    this.currentConsumerDetails = Object.assign(
      {
        enabled: false,
        purchase_limit: {
          min: null,
          max: null,
        },
        purchase_default_amount: null,
        purchase_inventory_id: null,
      },
      _cloneDeep({
        enabled: this.offer.consumer.enabled,
        purchase_limit: {
          min: this.offer.consumer.purchase_limit.min,
          max: this.offer.consumer.purchase_limit.max,
        },
        purchase_default_amount: this.offer.consumer.purchase_default_amount,
        purchase_inventory_id: this.offer.consumer.purchase_inventory_id,
      }),
    );

    if (_isNull(this.offer.consumer.purchase_limit.min)) {
      this.currentConsumerDetails.purchase_limit.min = 50;
      this.currentConsumerDetails.purchase_limit.max = 500;
      this.currentConsumerDetails.purchase_default_amount = 100;
    }
  },
  watch: {
    async helloShow(flag) {
      if (!flag) {
        this.clear();
      }
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    inventoryLookupItemText(it) {
      return `${it.name} - ${this.$options.filters.dollarSignI18n(this.$options.filters.currency(it.available_amount))}`;
    },
    clear() {
      this.currentConsumerDetails = {
        enabled: false,
        purchase_default_amount: null,
        purchase_limit: {
          min: null,
          max: null,
        },
        purchase_inventory_id: null,
      };
      this.$validator.reset();
    },
    success() {
      this.clear();
      this.$emit('updated');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();
      if (validationComplete) {
        await this.executeAction(
          {
            action: 'offers/updateOfferConsumerDetails',
            name: 'updateOfferConsumerDetails',
            success: this.success,
          },
          this.buildPayload(),
        );
      }
    },
    buildPayload() {
      const payload = {
        offer_id: this.offer.id,
        consumer: {
          enabled: this.currentConsumerDetails.enabled,
          purchase_limit: {
            min: this.currentConsumerDetails.purchase_limit.min,
            max: this.currentConsumerDetails.purchase_limit.max,
          },
          purchase_default_amount: this.currentConsumerDetails.purchase_default_amount,
          purchase_inventory_id: this.currentConsumerDetails.purchase_inventory_id ? this.currentConsumerDetails.purchase_inventory_id : null,
        },
      };

      if (!payload.consumer.enabled) {
        payload.consumer.purchase_limit.min = null;
        payload.consumer.purchase_limit.max = null;
        payload.consumer.purchase_default_amount = null;
        payload.consumer.purchase_inventory_id = null;
      }

      return payload;
    },
  },
};
</script>
