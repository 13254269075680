<template>
  <view-main title="page_title.activation_code_batch_detail">
    <three-levels-breadcrumbs
      slot="content"
      :top-label="$t('navigation.offers')"
      :top-to="{ name: 'offers' }"
      :second-label="activationCodeBatch.offer_id"
      :second-to="{ name: 'offers_details', params: { offerId: activationCodeBatch.offer_id } }"
      :last-label="activationCodeBatch.id"
    />
    <activation-code-batch-information slot="content" />
    <activation-codes v-if="activationCodeBatch && activationCodeBatch.offer_id" slot="content" />
  </view-main>
</template>

<script>
import { actionErrorTrackable, renderErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import ActivationCodeBatchInformation from '@/components/activation_code_batch/detail/ActivationCodeBatchInformation.vue';
import ActivationCodes from '@/components/activation_code_batch/detail/ActivationCodes.vue';

export default {
  name: 'activation-code-batch-detail-view',
  mixins: [renderErrorTrackable, actionErrorTrackable],
  components: { ActivationCodeBatchInformation, ActivationCodes },
  props: {
    activationCodeBatchId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('activationCodeBatch', ['activationCodeBatch']),
  },
  async mounted() {
    await this.fetch();
  },
  watch: {
    async activationCodeBatchId() {
      return this.fetch();
    },
  },
  methods: {
    async fetch() {
      const activationCodeBatch = await this.executeAction({ action: 'activationCodeBatch/getActivationCodeBatch' }, this.activationCodeBatchId);
      if (!activationCodeBatch) {
        this.$router.replace({ name: 'notFound' });
      }
    },
  },
};
</script>
