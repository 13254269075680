<template>
  <div>
    <table class="responsive-table">
      <thead>
        <tr>
          <td rowspan="2" style="vertical-align: bottom">{{ $t('store.card_acceptors_table.fields.merchant_id') }}</td>
          <td rowspan="2" style="vertical-align: bottom">{{ $t('store.card_acceptors_table.fields.merchant_name') }}</td>
          <td rowspan="2" style="vertical-align: bottom">{{ $t('store.card_acceptors_table.fields.terminal_id') }}</td>
          <td rowspan="2" style="vertical-align: bottom">{{ $t('store.card_acceptors_table.fields.state') }}</td>
          <td rowspan="2" style="vertical-align: bottom">{{ $t('store.card_acceptors_table.fields.city') }}</td>
          <td rowspan="2" style="vertical-align: bottom">{{ $t('store.card_acceptors_table.fields.country') }}</td>
          <td colspan="2" style="text-align: center">{{ $t('store.card_acceptors_table.fields.starts_with') }}</td>
          <td colspan="1" style="text-align: left">{{ $t('store.card_acceptors_table.fields.contains') }}</td>
          <td rowspan="2" style="vertical-align: bottom">{{ $t('store.card_acceptors_table.fields.creation_date') }}</td>
          <td rowspan="2" style="vertical-align: bottom">{{ $t('store.card_acceptors_table.fields.last_update_date') }}</td>
          <td rowspan="2" style="vertical-align: bottom">{{ $t('store.card_acceptors_table.fields.verified.label') }}</td>
          <td rowspan="2" style="vertical-align: bottom">{{ $t('store.card_acceptors_table.verify_link') }}</td>
          <td rowspan="2" style="vertical-align: bottom">{{ $t('store.card_acceptors_table.update_link') }}</td>
          <td rowspan="2" style="vertical-align: bottom">{{ $t('store.card_acceptors_table.remove_link') }}</td>
        </tr>
        <tr>
          <td>{{ $t('store.card_acceptors_table.fields.merchant_id_starts_with') }}</td>
          <td>{{ $t('store.card_acceptors_table.fields.merchant_name_starts_with') }}</td>
          <td>{{ $t('store.card_acceptors_table.fields.merchant_name_contains') }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>
            <h5>{{ $t('store.card_acceptors_table.fields.merchant_id') }}:</h5>
            <span v-if="item.merchant_id">{{ item.merchant_id }}</span>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('store.card_acceptors_table.fields.merchant_name') }}:</h5>
            <span class="merchant-name" v-if="item.merchant_name">{{ item.merchant_name }}</span>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('store.card_acceptors_table.fields.terminal_id') }}:</h5>
            <span v-if="item.terminal_id">{{ item.terminal_id }}</span>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('store.card_acceptors_table.fields.state') }}:</h5>
            <span v-if="item.state">{{ item.state }}</span>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('store.card_acceptors_table.fields.city') }}:</h5>
            <span v-if="item.city">{{ item.city }}</span>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('store.card_acceptors_table.fields.country') }}:</h5>
            <span v-if="item.country">{{ item.country }}</span>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('store.card_acceptors_table.fields.merchant_id_starts_with_mobile') }}:</h5>
            <span v-if="item.merchant_id_starts_with">{{ item.merchant_id_starts_with }}</span>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('store.card_acceptors_table.fields.merchant_name_starts_with_mobile') }}:</h5>
            <span v-if="item.merchant_name_starts_with">{{ item.merchant_name_starts_with }}</span>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('store.card_acceptors_table.fields.merchant_name_contains_mobile') }}:</h5>
            <span v-if="item.merchant_name_contains">{{ item.merchant_name_contains }}</span>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('store.card_acceptors_table.fields.creation_date') }}:</h5>
            <span v-if="item.creation_date">{{ item.creation_date | formatShortDateTimeInverted }}</span>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('store.card_acceptors_table.fields.last_update_date') }}:</h5>
            <span v-if="item.last_update_date">{{ item.last_update_date | formatShortDateTimeInverted }}</span>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('store.card_acceptors_table.fields.verified.label') }}:</h5>
            <span :class="`verified-${item.verified}`">{{ $t(`store.card_acceptors_table.fields.verified.${item.verified}`) }}</span>
          </td>
          <td>
            <link-secondary-action v-if="!item.verified" @click="checkVerifyCardAcceptorModal(item.id)">
              {{ $t('store.card_acceptors_table.verify_link') }}
            </link-secondary-action>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <link-secondary-action @click="openUpdatePublisherCardAcceptorModal(item.id)">
              {{ $t('store.card_acceptors_table.update_link') }}
            </link-secondary-action>
          </td>
          <td>
            <link-secondary-action @click="openRemoveCardAcceptorModal(item.id, item.store_id)">
              {{ $t('store.card_acceptors_table.remove_link') }}
            </link-secondary-action>
          </td>
        </tr>
      </tbody>
    </table>
    <modal-confirm
      :show-modal="showRemoveCardAcceptorModal"
      :title="$t(`store.card_acceptors_table.remove_action.title`)"
      context="RemoveCardAcceptorModal"
      :text="$t(`store.card_acceptors_table.remove_action.text`)"
      :cancel_text="`store.card_acceptors_table.remove_action.button_cancel`"
      :submit_text="`store.card_acceptors_table.remove_action.button_submit`"
      :submit_working="working"
      @submit="removeCardAcceptorSuccess()"
      @close="closeRemoveCardAcceptorModal"
    ></modal-confirm>
  </div>
</template>

<script>
import { actionErrorTrackable, renderErrorTrackable } from '@/mixins';

export default {
  name: 'store-card-acceptors-table',
  mixins: [renderErrorTrackable, actionErrorTrackable],
  props: {
    items: {
      type: Array,
    },
  },
  data() {
    return {
      showRemoveCardAcceptorModal: false,
      cardAcceptorId: null,
      cardAcceptoreStoreId: null,
    };
  },
  methods: {
    async removeCardAcceptorSuccess() {
      this.showRemoveCardAcceptorModal = false;
      const cardAcceptorId = this.cardAcceptorId;
      const cardAcceptoreStoreId = this.cardAcceptoreStoreId;
      this.cardAcceptorId = null;
      this.cardAcceptoreStoreId = null;
      await this.executeAction(
        {
          action: 'store/removeStoreCardAcceptor',
        },
        { id: cardAcceptorId, store_id: cardAcceptoreStoreId },
      );
      this.$emit('store_card_acceptor_remove_action', cardAcceptorId);
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'store.card_acceptors_table.remove_action.success' });
    },
    openRemoveCardAcceptorModal(cardAcceptorId, storeId) {
      this.showRemoveCardAcceptorModal = true;
      this.cardAcceptorId = cardAcceptorId;
      this.cardAcceptoreStoreId = storeId;
    },
    closeRemoveCardAcceptorModal() {
      this.showRemoveCardAcceptorModal = false;
      this.cardAcceptorId = null;
      this.cardAcceptoreStoreId = null;
    },
    openUpdatePublisherCardAcceptorModal(id) {
      this.$emit('store_card_acceptor_update_action', id);
    },
    checkVerifyCardAcceptorModal(id) {
      this.$emit('store_card_acceptor_check_action', id);
    },
  },
};
</script>
<style lang="css">
.merchant-name {
  white-space: pre-wrap;
}
</style>
