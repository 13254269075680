var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card-modal',{attrs:{"icon":"","title":_vm.$t('voucher.extend_expiration_action.title'),"context":"extendVoucherExpiration","submit_working":_vm.submitting,"cancel_text":"voucher.extend_expiration_action.button_cancel","submit_text":"voucher.extend_expiration_action.button_save","submit_hidden":_vm.isExpirationAtLeastOneDayBeforeClosure},on:{"submit":_vm.submit,"cancel":_vm.cancel}},[_c('form',{attrs:{"slot":"content"},on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}},slot:"content"},[_c('v-layout',{attrs:{"column":""}},[[(_vm.isRelativeVoucher)?_c('v-layout',{attrs:{"row":"","pad-form-row-elements":""}},[_c('v-flex',{attrs:{"sm12":"","xs12":""}},[(_vm.isExpirationAtLeastOneDayBeforeClosure)?_c('alert-warning',{attrs:{"text":_vm.$t('voucher.extend_expiration_action.relative_voucher_cannot_be_extended_to_close_of_closure_warning')}}):_vm._e(),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isExpirationAtLeastOneDayBeforeClosure),expression:"!isExpirationAtLeastOneDayBeforeClosure"},{name:"validate",rawName:"v-validate",value:({
                required: true,
                integer: true,
                min_value: 1,
                max_value: _vm.maxExtensionDays,
              }),expression:"{\n                required: true,\n                integer: true,\n                min_value: 1,\n                max_value: maxExtensionDays,\n              }"}],ref:"bonus_extension_days",attrs:{"autofocus":"","name":"bonus_extension_days","label":_vm.$t('voucher.extend_expiration_action.bonus_extension_days') + '*',"error-messages":_vm.errors.collect('bonus_extension_days'),"data-vv-name":"bonus_extension_days","data-vv-as":_vm.$t('voucher.extend_expiration_action.bonus_extension_days')},model:{value:(_vm.bonus_extension_days),callback:function ($$v) {_vm.bonus_extension_days=$$v},expression:"bonus_extension_days"}})],1)],1):_vm._e(),(_vm.isFixedVoucher)?_c('v-layout',{attrs:{"row":"","pad-form-row-elements":""}},[_c('v-flex',{attrs:{"sm12":"","xs12":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"lazy":"","transition":"scale-transition","offset-y":"","full-width":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-text-field',_vm._g({directives:[{name:"validate",rawName:"v-validate",value:({
                    required: true,
                    islater: { date: _vm.min_bonus_end_date },
                    isbefore: { date: _vm.offer.incentive.closure_end_date },
                  }),expression:"{\n                    required: true,\n                    islater: { date: min_bonus_end_date },\n                    isbefore: { date: offer.incentive.closure_end_date },\n                  }"}],attrs:{"data-vv-name":"bonus_end_date","error-messages":_vm.errors.collect('bonus_end_date'),"data-vv-as":_vm.$t('voucher.extend_expiration_action.bonus_end_date'),"label":_vm.$t('voucher.extend_expiration_action.bonus_end_date'),"prepend-icon":"event","readonly":""},model:{value:(_vm.bonus_end_date),callback:function ($$v) {_vm.bonus_end_date=$$v},expression:"bonus_end_date"}},on))]}}],null,false,3908740766),model:{value:(_vm.showDatePickerCalendar),callback:function ($$v) {_vm.showDatePickerCalendar=$$v},expression:"showDatePickerCalendar"}},[_c('v-date-picker',{attrs:{"min":_vm.offer.incentive.bonus_end_date,"max":_vm.offer.incentive.closure_end_date,"show-current":new Date().toISOString().substr(0, 10)},on:{"input":function($event){_vm.showDatePickerCalendar = false}},model:{value:(_vm.bonus_end_date),callback:function ($$v) {_vm.bonus_end_date=$$v},expression:"bonus_end_date"}})],1)],1)],1):_vm._e()]],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }