<template>
  <div>
    <div v-if="task" data-test="resolution-result">
      <h3>{{ $t(`task.list.resolution.title`) }}</h3>

      <v-layout row wrap align-center justify-start class="data-card--content-zone">
        <v-flex xs12 class="d-inline-flex data-card--content-zone__upper">
          <label-text :label="$t(`task.list.resolution.date`)" :value="task.resolution.resolved_on | formatDateHourMin" />

          <label-text :label="$t(`task.list.resolution.agent`)" :value="appName" data-test="resolution-agent" />

          <label-text
            :label="$t(`task.list.resolution.action`)"
            :value="$t(`task.list.change_application_request.actions.${task.resolution.action}`)"
            :data-test="task.resolution.action"
            class="resolution-action"
          />
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { actionErrorTrackable, security } from '@/mixins';

export default {
  name: 'selfserve-change-application-review-resolution-part',

  mixins: [actionErrorTrackable, security],

  props: {
    taskId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      task: null,
      appName: '',
    };
  },

  async mounted() {
    this.task = await this.getTask(this.taskId);
    this.appName = await this.getAppName(this.task.resolution.resolved_by.app_key).then((name) => name || '?');
  },

  methods: {
    ...mapActions('task', ['getTask']),
    ...mapActions('security', ['getAppName']),
  },
};
</script>
