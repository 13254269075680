<template>
  <card-modal
    class=""
    icon="fal fa-exchange"
    :title="$t('purse.adjust_purse_balance_action.title')"
    context="adjustPurseBalance"
    @submit="openConfirmModal"
    @cancel="cancel"
    cancel_text="purse.adjust_purse_balance_action.button_cancel"
    submit_text="purse.adjust_purse_balance_action.button_submit"
    :submit_working="working"
  >
    <form @submit.prevent="openConfirmModal" slot="content">
      <v-layout column>
        <v-layout row wrap>
          <v-flex xs12 sm4 d-flex>
            <v-select
              :items="availableOperationTypes"
              :label="$t('purse.adjust_purse_balance_action.form.operation_type.label') + ' *'"
              v-model="operationType"
              required
              v-validate="{ required: true }"
              data-vv-name="Type"
              :error-messages="errors.collect('Type')"
              name="operation_type"
              class="operation_type"
            ></v-select>
          </v-flex>
        </v-layout>
        <v-layout row wrap pad-form-row-elements>
          <v-flex sm6 xs12 d-flex>
            <v-text-field
              v-model="amount.value"
              type="number"
              step="0.01"
              :label="$t('purse.adjust_purse_balance_action.form.amount.label') + ' *'"
              v-validate="validateAmount"
              data-vv-name="Amount"
              :error-messages="errors.collect('Amount')"
              name="amount"
              required
              :suffix="amount.currency"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 d-flex>
            <v-text-field
              type="string"
              v-validate="{ required: false }"
              data-vv-name="OperationGroupId"
              :error-messages="errors.collect('OperationGroupId')"
              name="operation_group_id"
              class="operation_group_id m-r-10"
              v-model="operationGroupId"
              :label="$t('purse.adjust_purse_balance_action.form.operation_group_id.label')"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-text-field
            v-model="reason"
            :label="$t('purse.adjust_purse_balance_action.form.reason.label') + ' *'"
            required
            v-validate="{ required: true }"
            data-vv-name="Reason"
            name="reason"
            :error-messages="errors.collect('Reason')"
          ></v-text-field>
        </v-layout>
        <v-layout row wrap v-if="isDev()">
          <v-checkbox
            v-model="skip_webhook"
            :label="$t('purse.adjust_purse_balance_action.form.skip_webhook.label')"
            data-vv-name="SkipWebhook"
            name="skip_webhook"
            :error-messages="errors.collect('SkipWebhook')"
          ></v-checkbox>
        </v-layout>
        <v-layout row wrap v-if="isDev()">
          <v-checkbox
            v-model="skip_card_sync"
            :label="$t('purse.adjust_purse_balance_action.form.skip_card_sync.label')"
            data-vv-name="SkipCardSync"
            name="skip_card_sync"
            :error-messages="errors.collect('SkipCardSync')"
          ></v-checkbox>
        </v-layout>
      </v-layout>

      <modal-confirm
        :show-modal="showConfirmModal"
        :title="$t('purse.adjust_purse_balance_action.confirm.title')"
        context="AdjustPurseBalance"
        :text="
          $t('purse.adjust_purse_balance_action.confirm.text', {
            currentBalance: applyCurrencyFilter(purse.available_amount),
            targetBalance: applyCurrencyFilter(targetBalance),
            purseId,
          })
        "
        cancel_text="purse.adjust_purse_balance_action.confirm.button_cancel"
        submit_text="purse.adjust_purse_balance_action.confirm.button_submit"
        :submit_working="working"
        @submit="submit"
        @close="closeConfirmModal"
      ></modal-confirm>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';

export default {
  name: 'adjust-purse-balance-action',
  $_veeValidate: {
    validator: 'new',
  },
  mixins: [actionErrorTrackable, security],
  props: {
    purse: {
      type: Object,
      required: true,
    },
  },
  data: function() {
    return {
      amount: {
        value: '',
        currency: 'CAD',
      },
      operationGroupId: '',
      reason: '',
      skip_webhook: false,
      skip_card_sync: false,
      availableOperationTypes: [
        {
          value: 'debit',
          text: 'Debit',
        },
        {
          value: 'credit',
          text: 'Credit',
        },
      ],
      operationType: '',
      targetBalance: '',
      showConfirmModal: false,
    };
  },
  computed: {
    purseId() {
      return this.purse.id;
    },
    validateAmount() {
      let config = { required: true, decimal: 2, min_value: 0.01 };
      if (this.operationType === 'credit') {
        return config;
      }

      config.max_value = this.purse.available_amount;

      return config;
    },
  },
  methods: {
    clear() {
      this.resetForm();
      this.$validator.reset();
    },
    resetForm() {
      this.amount.value = '';
      this.operationType = '';
      this.reason = '';
      this.skip_webhook = false;
      this.skip_card_sync = false;
      this.operationGroupId = '';
      this.targetBalance = '';
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    success() {
      this.clear();
      this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'purse.adjust_purse_balance_action.success' });
      this.closeConfirmModal();
      this.$emit('action');
      this.$emit('close');
    },
    error() {
      this.closeConfirmModal();
    },
    async submit() {
      const params = {
        amount: this.amount.value,
        reason: this.reason,
        operation_type: this.operationType,
        purse_id: this.purse.id,
        skip_webhook: this.skip_webhook,
        skip_card_sync: this.skip_card_sync,
        operation_group_id: this.operationGroupId,
      };

      await this.executeAction(
        {
          action: 'purse/adjustPurse',
          name: 'adjustPurseBalance',
          success: this.success,
          afterError: this.error,
        },
        params,
      );
    },
    async openConfirmModal() {
      const validationComplete = await this.$validator.validateAll();

      const calculateTargetBalance = () => {
        const currentBalance = this.purse.available_amount;
        const amount = Number(this.amount.value);
        return this.operationType === 'credit' ? currentBalance + amount : currentBalance - amount;
      };

      if (validationComplete) {
        this.showConfirmModal = true;
        this.targetBalance = calculateTargetBalance();
      }
    },
    closeConfirmModal() {
      this.showConfirmModal = false;
    },
    applyCurrencyFilter(value) {
      return this.$options.filters.currency(value);
    },
  },
};
</script>
