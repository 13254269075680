<template>
  <card-modal
    icon=""
    :title="$t('card.create_card_comment_action.title')"
    context="createCardComment"
    @submit="submit"
    @cancel="cancel"
    cancel_text="card.create_card_comment_action.button_cancel"
    submit_text="card.create_card_comment_action.button_save"
    :submit_working="working"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-textarea
          auto-grow
          name="card_comment"
          rows="1"
          row-height="1"
          :label="$t('card.create_card_comment_action.comment')"
          v-model="card_comment"
          v-validate="'required|max:4096'"
          :error-messages="errors.collect('card_comment')"
          data-vv-name="card_comment"
          :data-vv-as="$t('card.create_card_comment_action.comment')"
          counter
          required
          maxlength="4096"
        ></v-textarea>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';

export default {
  name: 'create-card-comment-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    public_token: {
      type: String,
      required: false,
    },
    account_id: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      card_comment: '',
    };
  },
  methods: {
    clear() {
      this.card_comment = '';
      this.$validator.reset();
    },
    success() {
      this.clear();
      this.$emit('action');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        await this.executeAction(
          {
            action: 'card/createComment',
            success: this.success,
          },
          {
            card_public_token: this.public_token,
            comment: this.card_comment,
            account_id: this.account_id,
          },
        );
      }
    },
  },
};
</script>
