<template>
  <v-flex class="search-voucher-form">
    <h1 hidden>{{ $t('voucher.form.title') }}</h1>
    <v-card>
      <form @submit.prevent="submit" class="form-basic form-search">
        <v-layout align-center wrap class="form-search--main-wrap-v2">
          <v-flex class="form-search--inputs-zone-v2">
            <form-warning-search
              :autofocus="true"
              :searched="searched"
              :hint-message="$t('voucher.form.total_results_message')"
              :label="$t('voucher.form.placeholder')"
              v-model="keyword"
              data-vv-name="search"
              v-validate="'max:50'"
              :max-length="50"
              :error-messages="errors.collect('search')"
              data-test="form-voucher-keyword"
            />
            <v-text-field
              v-model="activation_code"
              :label="$t('voucher.form.activation_code')"
              :error-messages="errors.collect('activation_code')"
              name="activation_code"
              data-vv-name="activation_code"
              data-vv-as="activation_code"
              data-test="form-voucher-activation_code"
            >
            </v-text-field>
            <h-autocomplete
              v-model="partner_id"
              no-filter
              clearable
              :label="$t('voucher.form.partner')"
              :error-messages="errors.collect('partner_id')"
              name="partner_id"
              data-vv-name="partner_id"
              data-vv-as="partner_id"
              search-action="partner/autocompleteSearch"
              data-test="form-voucher-partner"
            >
            </h-autocomplete>
            <h-autocomplete
              v-model="offer_id"
              no-filter
              clearable
              :label="$t('voucher.form.offer')"
              :error-messages="errors.collect('offer_id')"
              name="offer_id"
              data-vv-name="offer_id"
              data-vv-as="offer_id"
              search-action="offers/autocompleteSearch"
              data-test="form-voucher-offer"
            >
            </h-autocomplete>
            <button-primary class="voucher--btn-search" :text="$t('voucher.form.submit')" :loading="working" @click="submit" type="submit" />
          </v-flex>
        </v-layout>
      </form>
    </v-card>

    <v-flex class="data-section">
      <alert-warning v-if="dataReady && vouchers.length === 0" :text="$t('voucher.no_voucher_found')" />
      <card v-if="dataReady && vouchers.length > 0" icon="fal fa-money-check-edit" :title="`${$t('voucher.form.data_section.title')}`">
        <template slot="headerZoneRight">
          <label-text class="text-sm-center" :label="$t('voucher.form.voucher_count')" :value="listItemCount || '0'"></label-text>
        </template>
        <template slot="content">
          <vouchers-table :items="vouchers" />
        </template>
      </card>
    </v-flex>
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable, security } from '@/mixins';
import VouchersTable from './VouchersTable';
import _ from 'lodash';

export default {
  name: 'search-voucher-form',
  mixins: [actionErrorTrackable, security],
  components: { VouchersTable },
  $_veeValidate: {
    validator: 'new',
  },
  beforeDestroy() {
    this.executeAction({ action: 'voucher/clearListVouchers' });
  },
  data() {
    return {
      page: 1,
      dataReady: false,
      keyword: '',
      searched: '',
      partner_id: '',
      offer_id: '',
      activation_code: null,
    };
  },
  async created() {
    this.page = this.listPageNumber;
    this.keyword = this.listKeyword;
    this.partner_id = this.listPartnerId;
    this.offer_id = this.listOfferId;

    if (this.routeHasQueryParams()) {
      this.page = 1;
    }
    if (this.$route.query.keyword) {
      this.keyword = this.$route.query.keyword;
    }
    if (this.$route.query.partner_id) {
      this.partner_id = this.$route.query.partner_id;
    }
    if (this.$route.query.offer_id) {
      this.offer_id = this.$route.query.offer_id;
    }
    if (this.$route.query.activation_code) {
      this.activation_code = this.$route.query.activation_code;
    }

    this.routeHasQueryParams() ? this.submit(null, false, this.page) : this.submit(null, true, this.page);
  },
  computed: {
    ...mapGetters('voucher', ['vouchers', 'listKeyword', 'listItemCount', 'listPageNumber', 'listPartnerId', 'listOfferId']),
  },
  methods: {
    async clear() {
      this.searched = '';
      this.keyword = '';
      this.partner_id = '';
      this.offer_id = '';
      this.activation_code = null;
      this.$validator.reset();
    },
    routeHasQueryParams() {
      return this.$route.query.keyword || this.$route.query.partner_id || this.$route.query.offer_id;
    },
    buildPayloadQueryString() {
      const payload = {};
      if (this.keyword) {
        payload.keyword = this.keyword;
      }
      if (this.partner_id) {
        payload.partner_id = this.partner_id;
      }
      if (this.offer_id) {
        payload.offer_id = this.offer_id;
      }
      if (this.activation_code) {
        payload.activation_code = this.activation_code;
      }

      return payload;
    },
    async submit(event, forceSearch = false, page = 1) {
      let validationComplete;
      if (!forceSearch) {
        validationComplete = await this.$validator.validateAll();
      }

      if (validationComplete || forceSearch) {
        this.$router.replace({ query: this.buildPayloadQueryString() });
        await this.clearAllError();

        try {
          this.dataReady = false;
          const search = this.buildPayloadQueryString();
          search.page = page;

          await this.executeAction({ action: 'voucher/listVouchers' }, search);
        } finally {
          this.dataReady = true;
        }
      }
    },
  },
};
</script>
