import { Joi, validate } from '@/utils/validators/index';

export async function validateListDocumentParams(params) {
  const schema = Joi.object({
    card_application_id: Joi.string()
      .id()
      .allow(null),
    public_token: Joi.string(),
    cardholder_key: Joi.string()
      .allow(null)
      .id(),
    account_id: Joi.string()
      .allow(null)
      .id(),
  });

  await validate(params, schema);
}

export async function validateDownloadDocument(params) {
  const schema = Joi.object({
    filename: Joi.string().required(),
    id: Joi.string()
      .id()
      .required(),
  });

  await validate(params, schema);
}
