<template>
  <card :title="$t('offers.details.notes_section.title')" icon="">
    <template slot="headerZoneRight">
      <label-text class="text-xs-center" :label="`${$t('offers.details.notes_section.count')}`" :value="listOfferNotesItemCount || '0'"></label-text>
      <div v-if="showAddOfferNoteFeature()" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text v-if="showAddOfferNoteFeature()" :label="`${$t('offers.details.notes_section.action')}`" value="3">
        <link-secondary-action slot="labelTextLink" @click="openAddOfferNoteModal">
          {{ $t('offers.details.notes_section.create_note') }}
        </link-secondary-action>
      </label-text>
    </template>
    <template slot="content">
      <offer-notes-table
        v-if="offerNotes.length"
        :items="offerNotes"
        :offer-id="offer.id"
        @offer_note_delete_action="openDeleteOfferNoteModal"
      ></offer-notes-table>
      <label-none v-if="offerNotes.length === 0">{{ $t('offers.details.notes_section.no_found') }}</label-none>
      <modal-action :show-modal="showAddOfferNoteModal"
        ><create-offer-note-action
          :v-if="showAddOfferNoteModal"
          :offer-id="offer.id"
          @close="closeAddOfferNoteModal"
          @created="addOfferNoteSuccess"
        ></create-offer-note-action
      ></modal-action>
      <modal-action :show-modal="showDeleteOfferNoteModal"
        ><delete-offer-note-action
          :v-if="showDeleteOfferNoteModal"
          :offer-id="offer.id"
          :note-id="noteIdToDelete"
          @close="closeDeleteOfferNoteModal"
          @deleted="deleteOfferNoteSuccess"
        ></delete-offer-note-action
      ></modal-action>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import CreateOfferNoteAction from '../actions/CreateOfferNoteAction';
import DeleteOfferNoteAction from '../actions/DeleteOfferNoteAction';
import OfferNotesTable from './OfferNotesTable';
import { mapGetters } from 'vuex';

export default {
  name: 'offer-notes',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { OfferNotesTable, CreateOfferNoteAction, DeleteOfferNoteAction },
  data() {
    return {
      showAddOfferNoteModal: false,
      showDeleteOfferNoteModal: false,
      noteIdToDelete: null,
      noteToDelete: {},
    };
  },
  computed: {
    ...mapGetters('offers', ['offerNotes', 'offer', 'listOfferNotesItemCount']),
    ...mapGetters('ui', ['currentLocale']),
  },
  watch: {
    async offer() {
      await this.fetchOfferNotes();
    },
  },
  methods: {
    openAddOfferNoteModal() {
      this.showAddOfferNoteModal = true;
    },
    closeAddOfferNoteModal() {
      this.showAddOfferNoteModal = false;
    },
    openDeleteOfferNoteModal(noteId) {
      this.noteIdToDelete = noteId;
      this.noteToDelete = this.offerNotes.find((l) => l.id === noteId);
      this.showDeleteOfferNoteModal = true;
    },
    closeDeleteOfferNoteModal() {
      this.noteIdToDelete = null;
      this.noteToDelete = {};
      this.showDeleteOfferNoteModal = false;
    },
    async deleteOfferNoteSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'offers.delete_note_action.success' });
      this.closeDeleteOfferNoteModal();
    },
    async addOfferNoteSuccess() {
      await this.fetchOfferNotes();
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'offers.create_note_action.success' });
      this.closeAddOfferNoteModal();
    },
    showAddOfferNoteFeature() {
      return this.canManageOffer(); // TODO change permission
    },
    async fetchOfferNotes() {
      await this.executeAction({ action: 'offers/listOfferNotes' }, { offer_id: this.offer.id });
    },
  },
};
</script>
