<template>
  <card-modal
    icon=""
    :title="$t('clerk.change_passcode_action.title')"
    context="changePasscode"
    @submit="submit"
    @cancel="cancel"
    cancel_text="clerk.change_passcode_action.button_cancel"
    submit_text="clerk.change_passcode_action.button_save"
  >
    <form @submit.prevent="submit" slot="content">
      <v-text-field
        v-model="passcode"
        v-validate="{ required: true }"
        hint="0000"
        maxlength="4"
        v-maska="'####'"
        :label="$t('clerk.change_passcode_action.passcode') + '*'"
        name="passcode"
        :error-messages="errors.collect('passcode')"
        data-vv-name="passcode"
        :data-vv-as="$t('clerk.change_passcode_action.passcode')"
      ></v-text-field>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'change-passcode-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    clerk: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      passcode: null,
    };
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    clear() {
      this.passcode = null;
      this.$validator.reset();
    },
    success(response) {
      this.clear();
      this.$emit('changed');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        await this.executeAction(
          {
            action: 'partnerSecurity/changePasscode',
            name: 'changePasscode',
            success: this.success,
          },
          {
            id: this.clerk.id,
            passcode: this.passcode,
          },
        );
      }
    },
  },
};
</script>
