<template>
  <card-modal
    icon=""
    :title="$t('business.create_action.title')"
    context="createBusiness"
    @submit="submit"
    @cancel="cancel"
    cancel_text="business.create_action.button_cancel"
    submit_text="business.create_action.button_save"
    data-test="business--add-modal"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-text-field
          autofocus
          :label="$t('business.create_action.name') + '*'"
          name="name"
          v-validate="'required|max:100'"
          :error-messages="errors.collect('name')"
          v-model="business.name"
          data-vv-name="name"
          maxlength="100"
          :data-vv-as="$t('business.create_action.name')"
          data-test="add-business--name-fr"
          required
        ></v-text-field>
        <v-select
          name="number-of-employees"
          v-model="business.number_of_employees"
          :error-messages="errors.collect('number-of-employees')"
          data-vv-name="state"
          :data-vv-as="$t(`business.create_action.number_of_employees.label`)"
          :items="employeesRanges"
          item-text="name"
          item-value="abbr"
          :label="$t(`business.create_action.number_of_employees.label`)"
          data-test="add-business--number-of-employees"
        ></v-select>
        <h4>{{ $t('business.create_action.contact_info') }}</h4>
        <v-text-field
          name="contact_name"
          v-model="business.contact.name"
          :label="$t('business.create_action.contact_name') + '*'"
          v-validate="'required|max:50'"
          :error-messages="errors.collect('contact_name')"
          data-vv-label="contact_name"
          maxlength="50"
          :data-vv-as="$t('business.create_action.contact_name')"
          data-test="add-business--contact-name"
          required
        ></v-text-field>
        <v-textarea
          name="email"
          v-model="business.contact.email"
          v-validate="'required|email'"
          :label="$t(`business.create_action.email`) + '*'"
          :error-messages="errors.collect('email')"
          data-vv-name="email"
          :data-vv-as="$t(`business.create_action.email`)"
          rows="1"
          data-test="add-business--email"
          required
        ></v-textarea>
        <v-text-field
          name="phone"
          v-model="business.contact.phone"
          v-maska="'1-###-###-####'"
          v-validate="{ required: true, regex: /^1-[0-9]{3}-[0-9]{3}-[0-9]{4}$/ }"
          :label="$t(`business.create_action.phone`) + '*'"
          :error-messages="errors.collect('phone')"
          hint="1-999-999-9999"
          data-vv-name="phone"
          :data-vv-as="$t(`business.create_action.phone`)"
          data-test="add-business--phone"
          required
        ></v-text-field>
        <v-text-field
          name="phone_extension"
          v-model="business.contact.phone_extension"
          v-validate="{ regex: /^\d{0,10}$/ }"
          :label="$t(`business.create_action.phone_extension`)"
          :error-messages="errors.collect('phone_extension')"
          data-vv-name="phone_extension"
          :data-vv-as="$t(`business.create_action.phone_extension`)"
          data-test="add-business--phone-extension"
        ></v-text-field>
        <h4>{{ $t('business.create_action.address.title') }}</h4>

        <search-address @updated="updateAddressModal"></search-address>

        <v-text-field
          name="street"
          @paste="pasteParsedAddress"
          v-model="business.address.street"
          v-validate="'required|max:50'"
          :label="$t(`business.create_action.address.street`) + '*'"
          :error-messages="errors.collect('street')"
          data-vv-name="street"
          :data-vv-as="$t(`business.create_action.address.street`)"
          maxlength="50"
          counter
          data-test="add-business--street"
          :hint="$t(`business.update_action.address.street_hint`)"
          required
        ></v-text-field>
        <v-text-field
          name="city"
          v-model="business.address.city"
          v-validate="'required|max:50'"
          :label="$t(`business.create_action.address.city`) + '*'"
          :error-messages="errors.collect('city')"
          data-vv-name="city"
          :data-vv-as="$t(`business.create_action.address.city`)"
          counter
          maxlength="50"
          data-test="add-business--city"
          required
        ></v-text-field>
        <v-select
          name="state"
          v-model="business.address.state"
          v-validate="'required'"
          :error-messages="errors.collect('state')"
          data-vv-name="state"
          :data-vv-as="$t(`business.create_action.address.state`)"
          :items="stateOptions"
          item-text="name"
          item-value="abbr"
          :label="$t(`business.create_action.address.state`) + '*'"
          data-test="add-business--state"
        ></v-select>
        <v-select
          name="country"
          v-model="business.address.country"
          v-validate="'required'"
          :error-messages="errors.collect('country')"
          data-vv-name="country"
          :data-vv-as="$t(`business.create_action.address.country`)"
          :items="countryOptions"
          item-text="name"
          item-value="abbr"
          :label="$t(`business.create_action.address.country`) + '*'"
          data-test="add-business--country"
        ></v-select>
        <v-text-field
          name="zip"
          v-mask-zip
          v-model="business.address.zip"
          v-validate="{ required: true, regex: /^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z] [0-9][ABCEGHJ-NPRSTV-Z][0-9]$/ }"
          :label="$t(`business.create_action.address.zip`) + '*'"
          :error-messages="errors.collect('zip')"
          data-vv-name="zip"
          :data-vv-as="$t(`business.create_action.address.zip`)"
          data-test="add-business--zip"
        ></v-text-field>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import Vue from 'vue';
import _pickBy from 'lodash/pickBy';
import { parseAddress } from '@/utils/address-utils';
import SearchAddress from '@/components/store/actions/SearchAddress.vue';

const DEFAULT_EMPTY_ADDRESS = Object.freeze({
  street: '',
  city: '',
  zip: '',
  state: '',
  country: '',
  plus_code: null,
});

export default {
  name: 'create-business-action',
  components: { SearchAddress },
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  data() {
    return {
      business: { contact: {}, address: { country: 'CA', state: 'QC' } },
      countryOptions: [{ name: this.$t('country.canada'), abbr: 'CA' }],
      stateOptions: [
        { name: this.$t('state.alberta'), abbr: 'AB' },
        { name: this.$t('state.british_columbia'), abbr: 'BC' },
        { name: this.$t('state.manitoba'), abbr: 'MB' },
        { name: this.$t('state.new_brunswick'), abbr: 'NB' },
        { name: this.$t('state.newfoundland_labrador'), abbr: 'NL' },
        { name: this.$t('state.nova_scotia'), abbr: 'NS' },
        { name: this.$t('state.northwest_territories'), abbr: 'NT' },
        { name: this.$t('state.nunavut'), abbr: 'NU' },
        { name: this.$t('state.ontario'), abbr: 'ON' },
        { name: this.$t('state.prince_edward_island'), abbr: 'PE' },
        { name: this.$t('state.quebec'), abbr: 'QC' },
        { name: this.$t('state.saskatchewan'), abbr: 'SK' },
        { name: this.$t('state.yukon'), abbr: 'YT' },
      ],
      employeesRanges: [
        { name: this.$t('business.create_action.number_of_employees.options.null'), abbr: null },
        { name: this.$t('business.create_action.number_of_employees.options.1_50'), abbr: '1_50' },
        { name: this.$t('business.create_action.number_of_employees.options.51_200'), abbr: '51_200' },
        { name: this.$t('business.create_action.number_of_employees.options.201_500'), abbr: '201_500' },
        { name: this.$t('business.create_action.number_of_employees.options.500_MORE'), abbr: '500_MORE' },
      ],
    };
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    updateStoreAddress(address, overrideNotMutedFieldsWithDefaultValues = false) {
      const assignAddressValues = (values) => Object.entries(values).forEach(([key, value]) => Vue.set(this.business.address, key, value));
      const mutedValues = _pickBy(address, (item) => !_.isEmpty(item));

      if (overrideNotMutedFieldsWithDefaultValues) {
        assignAddressValues({
          ...DEFAULT_EMPTY_ADDRESS,
          ...mutedValues,
        });
      } else {
        assignAddressValues(mutedValues);
      }
    },
    pasteParsedAddress(event) {
      let pasteString = (event.clipboardData || window.clipboardData).getData('text');

      //Is parsable if there are commas in string ortherwise returns null.
      const address = parseAddress(pasteString);

      //If parsed address not null set values.
      if (address) {
        //Block default paste process.
        event.preventDefault();
        this.updateStoreAddress(address);
      }
    },
    updateAddressModal({ address }) {
      this.updateStoreAddress(address, true);
    },
    clear() {
      this.business = { contact: {}, address: { country: 'CA', state: 'QC' } };
      this.$validator.reset();
    },
    success(response) {
      this.clear();
      this.$router.push({ name: 'business_details', params: { businessId: response.id } });
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        const payload = this.business;

        await this.executeAction(
          {
            action: 'business/createBusiness',
            name: 'createBusiness',
            success: this.success,
          },
          payload,
        );
      }
    },
  },
};
</script>
