<template>
  <view-main title="page_title.wallet_definition"
    ><search-wallet-definition-form slot="content" :wallet-definition-id="walletDefinitionId"></search-wallet-definition-form
  ></view-main>
</template>

<script>
import { renderErrorTrackable } from '@/mixins';
import SearchWalletDefinitionForm from './list/SearchWalletDefinitionForm.vue';

export default {
  name: 'wallet-definition-list-view',
  mixins: [renderErrorTrackable],
  components: { SearchWalletDefinitionForm },
  props: {
    walletDefinitionId: {
      type: String,
      required: false,
    },
  },
};
</script>
