<template>
  <view-main title="page_title.space_details">
    <two-levels-breadcrumbs slot="content" :top-label="$t('navigation.spaces')" :to="{ name: 'spaces' }" :last-label="space.name" />
    <space-information slot="content"></space-information>
    <space-business slot="content"></space-business>
    <space-partner-establishment slot="content"></space-partner-establishment>
    <space-branding slot="content"></space-branding>
    <space-clerk slot="content"></space-clerk>
    <space-workstation slot="content"></space-workstation>
  </view-main>
</template>

<script>
import { actionErrorTrackable, renderErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import SpaceInformation from './detail/SpaceInformation.vue';
import SpaceBusiness from './detail/SpaceBusiness.vue';
import SpacePartnerEstablishment from './detail/SpacePartnerEstablishment.vue';
import SpaceBranding from './detail/SpaceBranding.vue';
import SpaceClerk from './detail/SpaceClerk.vue';
import SpaceWorkstation from './detail/SpaceWorkstation.vue';

export default {
  name: 'space-detail-view',
  mixins: [renderErrorTrackable, actionErrorTrackable],
  components: { SpaceInformation, SpaceBusiness, SpaceBranding, SpaceClerk, SpaceWorkstation, SpacePartnerEstablishment },
  props: {
    spaceId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('space', ['space']),
  },
  async mounted() {
    await this.fetch();
  },
  watch: {
    async spaceId() {
      return this.fetch();
    },
  },
  methods: {
    async fetch() {
      const space = await this.executeAction({ action: 'space/getSpace' }, this.spaceId);
      if (!space) {
        this.$router.replace({ name: 'notFound' });
      }
      await this.executeAction({ action: 'space/listAllActiveSpacePartnerEstablishments' }, { partner_id: this.space.partner_id });
    },
  },
};
</script>
