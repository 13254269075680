<template>
  <v-flex class="view-branding-form">
    <h1 hidden>{{ $t('branding.form.title') }}</h1>
    <v-card>
      <form @submit.prevent="submit" class="form-basic form-search">
        <v-layout align-center wrap class="form-search--main-wrap-v2">
          <v-flex class="form-search--inputs-zone-v2">
            <form-warning-search
              :autofocus="true"
              :searched="searched"
              :hint-message="$t('branding.form.total_results_message')"
              class="branding--search-field"
              :label="$t('branding.form.placeholder')"
              v-model="keyword"
              data-vv-name="search"
              v-validate="'max:50'"
              :max-length="50"
              :error-messages="errors.collect('search')"
            ></form-warning-search>
            <v-select
              name="type"
              v-model="type"
              :error-messages="errors.collect('type')"
              data-vv-name="state"
              :data-vv-as="$t('branding.form.type')"
              :items="typeOptions"
              item-text="name"
              item-value="value"
              clearable
              :label="$t('branding.form.type')"
              data-test="form-branding-type"
            ></v-select>
            <v-select
              :items="categories"
              name="category"
              v-model="category"
              :error-messages="errors.collect('category')"
              data-vv-name="state"
              :data-vv-as="$t('branding.form.category')"
              :item-text="`name[${currentLocale}]`"
              item-value="id"
              clearable
              :label="$t('branding.form.category')"
              data-test="form-branding-category"
            ></v-select>
            <button-primary class="branding--btn-search" :text="$t('branding.form.submit')" :loading="working" type="submit" />
          </v-flex>

          <v-flex class="form-search--actions-zone-v2">
            <button-primary
              data-test="branding--btn-add"
              id="branding--btn-add"
              class="branding-form--btn-add"
              :loading="working"
              :text="$t('branding.form.add')"
              @click="openCreateBrandingModal"
            ></button-primary>
          </v-flex>
        </v-layout>
      </form>
    </v-card>

    <v-flex class="data-section">
      <alert-warning v-if="dataReady && brandings.length === 0" :text="$t('branding.no_branding_found')"></alert-warning>
      <card v-if="dataReady && brandings.length > 0" icon="fal fa-registered" :title="`${$t('branding.form.data_section.title')}`">
        <template slot="headerZoneRight">
          <label-text class="text-sm-center" :label="$t('branding.form.data_section.brandings_count')" :value="listItemCount || '0'"></label-text>
        </template>
        <template slot="content">
          <brandings-table :items="brandings"></brandings-table>
        </template>
      </card>
    </v-flex>
    <modal-action :show-modal="showCreateBrandingModal"
      ><create-branding-action
        v-if="showCreateBrandingModal"
        @close="closeCreateBrandingModal"
        @created="createBrandingSuccess"
      ></create-branding-action
    ></modal-action>
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable, security } from '@/mixins';
import BrandingsTable from './BrandingsTable';
import CreateBrandingAction from '../actions/CreateBrandingAction';
import AlertWarning from '@/components/system/Alerts/AlertWarning';

export default {
  name: 'view-branding-form',
  mixins: [actionErrorTrackable, security],
  components: { AlertWarning, BrandingsTable, CreateBrandingAction },
  $_veeValidate: {
    validator: 'new',
  },
  beforeDestroy() {
    this.executeAction({ action: 'branding/clearListBrandings' });
  },
  data() {
    return {
      page: 1,
      dataReady: false,
      keyword: '',
      searched: '',
      type: '',
      category: '',
      showCreateBrandingModal: false,
      typeOptions: [
        { name: this.$t('branding.types.campaign'), value: 'campaign' },
        { name: this.$t('branding.types.merchant'), value: 'merchant' },
        { name: this.$t('branding.types.space'), value: 'space' },
      ],
    };
  },
  async created() {
    this.fetchCategories();
    this.page = this.listPageNumber;
    this.keyword = this.listKeyword;
    this.type = this.listType;
    this.category = this.listCategory;

    if (this.routeHasQueryParams()) {
      this.page = 1;
    }
    if (this.$route.query.keyword) {
      this.keyword = this.$route.query.keyword;
    }
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
    }
    if (this.$route.query.category) {
      this.category = this.$route.query.category;
    }

    this.routeHasQueryParams() ? this.submit(null, false, this.page) : this.submit(null, true, this.page);
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('branding', ['brandings', 'categories', 'listKeyword', 'listPageNumber', 'listType', 'listCategory', 'listItemCount']),
  },
  methods: {
    async clear() {
      this.searched = '';
      this.keyword = '';
      this.type = '';
      this.category = '';
      this.$validator.reset();
    },
    async fetchCategories() {
      await this.executeAction({ action: 'branding/listCategories' });
    },
    routeHasQueryParams() {
      return this.$route.query.keyword || this.$route.query.type || this.$route.query.category;
    },
    openCreateBrandingModal() {
      this.showCreateBrandingModal = true;
    },
    closeCreateBrandingModal() {
      this.showCreateBrandingModal = false;
    },
    buildPayloadQueryString() {
      const payload = {};
      if (this.keyword) {
        payload.keyword = this.keyword;
      }
      if (this.type) {
        payload.type = this.type;
      }
      if (this.category) {
        payload.category = this.category;
      }

      return payload;
    },
    async submit(event, forceSearch = false, page = 1) {
      let validationComplete;
      if (!forceSearch) {
        validationComplete = await this.$validator.validateAll();
      }

      if (validationComplete || forceSearch) {
        this.$router.replace({ query: this.buildPayloadQueryString() });
        await this.clearAllError();

        try {
          this.dataReady = false;
          const search = this.buildPayloadQueryString();
          search.page = page;
          await this.executeAction({ action: 'branding/listBrandings' }, search);
        } finally {
          this.dataReady = true;
        }
      }
    },
    async createBrandingSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'branding.create_action.success' });
      this.closeCreateBrandingModal();
    },
  },
};
</script>
