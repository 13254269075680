import Vue from 'vue';

const defaultPage = 1;
const paginationLimit = 10;

export default {
  namespaced: true,
  state: {
    activationCodeBatch: {},
    activationCodeBatches: [],
    list: {
      pageNumber: 1,
      pageCount: 0,
      itemCount: 0,
    },
  },
  getters: {
    activationCodeBatches: (state) => state.activationCodeBatches,
    activationCodeBatch: (state) => state.activationCodeBatch,
    listPageCount: (state) => state.list.pageCount,
    listItemCount: (state) => state.list.itemCount,
    listPageNumber: (state) => state.list.pageNumber,
  },
  mutations: {
    activationCodeBatches(state, activationCodeBatches) {
      state.activationCodeBatches = activationCodeBatches;
    },
    activationCodeBatch(state, activationCodeBatch) {
      state.activationCodeBatch = activationCodeBatch;
    },
    listPageCount(state, pageCount) {
      state.list.pageCount = pageCount;
    },
    listItemCount(state, itemCount) {
      state.list.itemCount = itemCount;
    },
    listPage(state, pageNumber) {
      state.list.pageNumber = pageNumber;
    },
  },
  actions: {
    async downloadActivationCodesBatchExcel(context, { language, id }) {
      const activationCodeBatchService = Vue.prototype.$services.activationCodeBatch;
      const fileSaver = Vue.prototype.$services.fileSaver;

      const { fileContent, filename } = await activationCodeBatchService.downloadActivationCodesBatchExcel(id, language);
      await fileSaver.saveFile(fileContent, filename);

      return { success: true };
    },
    async downloadActivationCodesCorporateOrderInvoice(context, { language, id }) {
      const accountService = Vue.prototype.$services.account;
      const fileSaver = Vue.prototype.$services.fileSaver;

      const { fileContent, filename } = await accountService.getCorporateOrderInvoice(id, language);
      await fileSaver.saveFile(fileContent, filename);

      return { success: true };
    },
    async downloadActivationCodesBatchPdf(context, { language, id }) {
      const activationCodeBatchService = Vue.prototype.$services.activationCodeBatch;
      const fileSaver = Vue.prototype.$services.fileSaver;

      const { fileContent, filename } = await activationCodeBatchService.downloadActivationCodesBatchPdf(id, language);
      await fileSaver.saveFile(fileContent, filename);

      return { success: true };
    },
    async downloadActivationCodesBatchInfosPdf(context, { language, id }) {
      const activationCodeBatchService = Vue.prototype.$services.activationCodeBatch;
      const fileSaver = Vue.prototype.$services.fileSaver;

      const { fileContent, filename } = await activationCodeBatchService.downloadActivationCodesBatchInfosPdf(id, language);
      await fileSaver.saveFile(fileContent, filename);

      return { success: true };
    },
    async downloadActivationCodePreviewPdf(context, { language, id }) {
      const activationCodeBatchService = Vue.prototype.$services.activationCodeBatch;
      const fileSaver = Vue.prototype.$services.fileSaver;

      const { fileContent, filename } = await activationCodeBatchService.downloadActivationCodePreviewPdf(id, language);

      await fileSaver.saveFile(fileContent, filename);

      return { success: true };
    },
    async downloadActivationCodesBatchZip(context, { language, id }) {
      const activationCodeBatchService = Vue.prototype.$services.activationCodeBatch;
      const fileSaver = Vue.prototype.$services.fileSaver;

      const { fileContent, filename } = await activationCodeBatchService.downloadActivationCodesBatchZip(id, language);
      await fileSaver.saveFile(fileContent, filename);

      return { success: true };
    },
    async getActivationCodeBatch({ commit }, id) {
      const activationCodeBatchService = Vue.prototype.$services.activationCodeBatch;
      const activationCodeBatch = await activationCodeBatchService.getActivationCodeBatch(id);

      commit('activationCodeBatch', activationCodeBatch);

      return activationCodeBatch;
    },
    async getActivationCode({ commit, dispatch }, id) {
      const activationCodesService = Vue.prototype.$services.activationCodes;
      const activationCode = await activationCodesService.getActivationCode(id);
      commit('activationCode', activationCode);

      return activationCode;
    },
    async listActivationCodeBatchesByOffer({ state, commit }, { offerId, page, limit } = {}) {
      const activationCodeBatchService = Vue.prototype.$services.activationCodeBatch;
      const thePage = page || state.list.pageNumber || defaultPage;
      const theLimit = limit || paginationLimit;

      const listResults = await activationCodeBatchService.listActivationCodeBatches(offerId, thePage, theLimit);

      commit('listPage', thePage);
      commit('listPageCount', listResults.page_count);
      commit('listItemCount', listResults.item_count);
      commit('activationCodeBatches', listResults.items);
    },
    async updateActivationCodeBatch({}, params) {
      const activationCodeBatchService = Vue.prototype.$services.activationCodeBatch;
      const { id, ...payload } = params;
      const activationCodeBatch = await activationCodeBatchService.updateActivationCodeBatch(id, payload);

      return activationCodeBatch;
    },
  },
};
