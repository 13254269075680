<template>
  <card :title="$t('bank_account.details.bank_account_information_section.title')" icon="fal fa-university" id="bank-account-information">
    <template slot="headerZoneRight">
      <label-text :label="$t('bank_account.details.bank_account_information_section.bank_account_number')" :value="bankAccount.bank_account_number">
        <p slot="labelTextLink" class="label-text--value">
          <v-layout row>
            {{ bankAccount.bank_account_number }}
            <button class="clipboard" v-clipboard:copy="bankAccount.bank_account_number ? bankAccount.bank_account_number : ''">
              <v-icon small>file_copy</v-icon>
            </button>
          </v-layout>
        </p>
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="$t('bank_account.details.bank_account_information_section.business_name')" :value="bankAccount.business_name"> </label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>

      <label-text
        :label="$t('bank_account.details.bank_account_information_section.created')"
        :value="bankAccount.created | formatShortDateInverted | orNotAvailable"
      ></label-text>

      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>

      <label-text
        :label="`${$t('bank_account.details.bank_account_information_section.status')}`"
        :value="$t(`bank_account.status.${bankAccount.status}`)"
        :class-array="[`bank-account-${bankAccount.status}`]"
      />

      <div v-if="showActionBtn()" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>

      <link-secondary-action v-if="showActionBtn()" slot="labelTextLink">
        <button-secondary-actions :text="$t(`bank_account.details.bank_account_information_section.actions`)" :actions="actions">
        </button-secondary-actions>
      </link-secondary-action>
    </template>
    <template slot="content">
      <modal-action :show-modal="showActivateBankAccountModal"
        ><activate-bank-account-action
          v-if="showActivateBankAccountModal"
          :bank-account-key="bankAccount.key"
          :bank-account-number="bankAccount.bank_account_number"
          @close="closeActivateBankAccountModal"
          @activated="activateBankAccountSuccess"
        ></activate-bank-account-action
      ></modal-action>
      <modal-action :show-modal="showBlockBankAccountModal"
        ><block-bank-account-action
          v-if="showBlockBankAccountModal"
          :bank-account-key="bankAccount.key"
          :bank-account-number="bankAccount.bank_account_number"
          @close="closeBlockBankAccountModal"
          @blocked="blockBankAccountSuccess"
        ></block-bank-account-action
      ></modal-action>
      <modal-action :show-modal="showUpdateBankAccountModal"
        ><update-bank-account-action
          v-if="showUpdateBankAccountModal"
          :bank-account="bankAccount"
          @close="closeUpdateBankAccountModal"
          @updated="updateBankAccountSuccess"
        ></update-bank-account-action
      ></modal-action>
      <v-layout row>
        <v-flex xs4
          ><label-text
            :label="$t(`bank_account.details.bank_account_information_section.bank_institution_code`)"
            :value="bankAccount.bank_institution_code"
          ></label-text
        ></v-flex>
        <v-flex xs4
          ><label-text
            :label="$t(`bank_account.details.bank_account_information_section.branch_transit_number`)"
            :value="bankAccount.branch_transit_number"
          ></label-text
        ></v-flex>
        <v-flex xs4
          ><label-text
            :label="$t(`bank_account.details.bank_account_information_section.account_number`)"
            :value="bankAccount.account_number"
          ></label-text
        ></v-flex>
      </v-layout>
    </template>
  </card>
</template>

<script>
import { security, disableWithError, actionErrorTrackable } from '@/mixins';
import ActivateBankAccountAction from '../actions/ActivateBankAccountAction';
import BlockBankAccountAction from '../actions/BlockBankAccountAction';
import UpdateBankAccountAction from '../actions/UpdateBankAccountAction';
import { mapGetters } from 'vuex';

export default {
  name: 'bank-account-information',
  components: { ActivateBankAccountAction, BlockBankAccountAction, UpdateBankAccountAction },
  mixins: [security, actionErrorTrackable, disableWithError],
  data() {
    return {
      showActivateBankAccountModal: false,
      showUpdateBankAccountModal: false,
      showBlockBankAccountModal: false,
      actions: [
        {
          slotName: 'UpdateBankAccountModal',
          name: this.$t('bank_account.details.bank_account_information_section.update'),
          action: () => {
            this.openUpdateBankAccountModal();
          },
          isAvailable: () => this.canManageOffer(), // TODO change
          isDisabled: () => false,
        },
        {
          slotName: 'ActivateBankAccountModal',
          name: this.$t('bank_account.details.bank_account_information_section.activate'),
          action: () => {
            this.openActivateBankAccountModal();
          },
          isAvailable: () => this.canManageOffer(), // TODO change
          isDisabled: () => {
            return !this.showActivateBankAccountFeature();
          },
        },
        {
          slotName: 'BlockBankAccountModal',
          name: this.$t('bank_account.details.bank_account_information_section.block'),
          action: () => {
            this.openBlockBankAccountModal();
          },
          isAvailable: () => this.canManageOffer(), // TODO change
          isDisabled: () => {
            return !this.showBlockBankAccountFeature();
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('bankAccount', ['bankAccount']),
  },
  methods: {
    showActionBtn() {
      return true; // TODO set
    },
    openActivateBankAccountModal() {
      this.showActivateBankAccountModal = true;
    },
    openUpdateBankAccountModal() {
      this.showUpdateBankAccountModal = true;
    },
    closeUpdateBankAccountModal() {
      this.showUpdateBankAccountModal = false;
    },
    closeActivateBankAccountModal() {
      this.showActivateBankAccountModal = false;
    },
    openBlockBankAccountModal() {
      this.showBlockBankAccountModal = true;
    },
    closeBlockBankAccountModal() {
      this.showBlockBankAccountModal = false;
    },
    async activateBankAccountSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'bank_account.details.activate_action.success' });
      this.closeActivateBankAccountModal();
    },
    async blockBankAccountSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'bank_account.details.block_action.success' });
      this.closeBlockBankAccountModal();
    },
    async updateBankAccountSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'bank_account.update_action.success' });
      this.closeUpdateBankAccountModal();
    },
    showActivateBankAccountFeature() {
      return this.bankAccount.status !== 'active';
    },
    showBlockBankAccountFeature() {
      return this.bankAccount.status !== 'blocked';
    },
  },
};
</script>

<style scoped="true" lang="stylus">
.card-info-public-token-clipboard.clipboard .v-icon  {
  margin-bottom: 0.4em;
}
</style>
