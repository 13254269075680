<template>
  <card-modal
    icon=""
    :title="$t('user.change_email_action.title')"
    context="changeEmailUser"
    @submit="submit"
    @cancel="cancel"
    cancel_text="user.change_email_action.button_cancel"
    submit_text="user.change_email_action.button_save"
    data-test="user--verify-email-modal"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <label-text :label="`${$t('user.change_email_action.user')}`" :value="user.contact.email.value">
          <p slot="labelTextLink" class="label-text--value">
            <v-layout row>
              {{ user.contact.email.value }}
            </v-layout>
          </p>
        </label-text>
        <v-text-field
          autofocus
          :label="$t('user.change_email_action.new_email') + '*'"
          name="new_email"
          autocomplete="off"
          v-validate="{ required: true, email: true }"
          :error-messages="errors.collect('new_email')"
          v-model="new_email"
          data-vv-name="new_email"
          maxlength="50"
          :data-vv-as="$t('user.change_email_action.new_email')"
          data-test="change-email-user--new-email"
          required
        ></v-text-field>
        <v-text-field
          :label="$t('user.change_email_action.confirm_new_email') + '*'"
          name="confirm_new_email"
          autocomplete="off"
          v-validate="{ required: true, email: true, isSameUserEmail: { email: new_email } }"
          :error-messages="errors.collect('confirm_new_email')"
          v-model="confirm_new_email"
          data-vv-name="confirm_new_email"
          maxlength="50"
          :data-vv-as="$t('user.change_email_action.confirm_new_email')"
          data-test="change-email-user--confirm-new-email"
          required
        ></v-text-field>
        <v-select
          name="verification_type"
          v-model="verification_type"
          :label="$t('user.change_email_action.verification_type') + '*'"
          :error-messages="errors.collect('verification_type')"
          data-vv-name="verification_type"
          :data-vv-as="$t('user.change_email_action.verification_type')"
          :items="verificationOptions"
          item-text="label"
          item-value="value"
          v-validate="{ required: true }"
        ></v-select>
        <div v-if="doAutoVerify" class="auto-verify-container">
          <div class="label-text--value red--text auto-verify-text">{{ $t('user.change_email_action.auto_verify_warning') }}</div>
        </div>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';

export default {
  name: 'change-email-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  async created() {
    this.$validator.extend('validateEmailsMatch', {
      name: 'validateEmailsMatch',
      getMessage(field, args) {
        return 'Emails must match.';
      },
      validate: (value, { email } = {}) => {
        return value === email;
      },
      paramNames: ['email'],
    });
  },
  data() {
    return {
      new_email: '',
      confirm_new_email: '',
      verification_type: '',
      verificationOptions: [
        { value: 'auto', label: this.$t('user.change_email_action.verification_types.auto') },
        { value: 'manual', label: this.$t('user.change_email_action.verification_types.manual') },
      ],
    };
  },
  computed: {
    doAutoVerify() {
      return this.verification_type === 'auto';
    },
  },
  methods: {
    clear() {
      this.new_email = '';
      this.confirm_new_email = '';
      this.verification_type = '';
      this.$validator.reset();
    },
    success() {
      this.clear();
      this.$emit('action');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        await this.executeAction(
          {
            action: 'account/changeEmail',
            name: 'changeEmailUser',
            success: this.success,
          },
          { id: this.user.id, email: this.new_email, doAutoVerify: this.doAutoVerify, language: this.user.language },
        );
      }
    },
  },
};
</script>
<style lang="css">
.auto-verify-container {
  color: red;
  border-style: solid;
  border-radius: 5px;
}
.auto-verify-text {
  margin: 10px;
}
</style>
