import Vue from 'vue';
import _ from 'lodash';

const defaultPage = 1;
const paginationLimit = 20;
const publisherStoresPaginationLimit = 20;
const publisherOffersPaginationLimit = 20;
const publisherNotesPaginationLimit = 20;

export default {
  namespaced: true,
  state: {
    publishers: [],
    business: [],
    businessMoreResults: false,
    publisher: { system_status: {} },
    publisherStores: [],
    publisherOffers: [],
    publisher_notes: [],
    publisherStatistics: {},
    search: {
      keyword: '',
      pageNumber: 1,
      pageCount: 0,
    },
    list: {
      pageNumber: 1,
      pageCount: 0,
      itemCount: 0,
      keyword: '',
      partner_id: '',
      business_id: '',
    },
    listPublisherStores: {
      keyword: '',
      status: '',
      category: '',
      without_card_acceptors: false,
      pageNumber: 1,
      pageCount: 0,
      itemCount: 0,
    },
    listPublisherOffers: {
      pageNumber: 1,
      pageCount: 0,
      itemCount: 0,
    },
    publisher_notes_list: {
      pageNumber: 1,
      pageCount: 0,
      itemCount: 0,
    },
  },
  getters: {
    publisher: (state) => state.publisher,
    publishers: (state) => state.publishers,
    publisherStatsStoreCount: (state) => _.get(state, 'publisherStatistics.store_count', 0),
    searchPageCount: (state) => state.search.pageCount,
    searchPageNumber: (state) => state.search.pageNumber,
    searchKeyword: (state) => state.search.keyword,
    listPageCount: (state) => state.list.pageCount,
    listItemCount: (state) => state.list.itemCount,
    listPageNumber: (state) => state.list.pageNumber,
    listKeyword: (state) => state.list.keyword,
    listPartnerId: (state) => state.list.partner_id,
    listBusinessId: (state) => state.list.business_id,

    publisherStores: (state) => state.publisherStores,
    publisherStoresKeyword: (state) => state.listPublisherStores.keyword,
    publisherStoresStatus: (state) => state.listPublisherStores.status,
    publisherStoresType: (state) => state.listPublisherStores.type,
    publisherStoresCategory: (state) => state.listPublisherStores.category,
    publisherStoresWithoutCardAcceptors: (state) => state.listPublisherStores.without_card_acceptors,
    publisherStoresPageCount: (state) => state.listPublisherStores.pageCount,
    publisherStoresPageNumber: (state) => state.listPublisherStores.pageNumber,
    publisherStoresItemCount: (state) => state.listPublisherStores.itemCount,

    publisherOffers: (state) => state.publisherOffers,
    publisherOffersPageCount: (state) => state.listPublisherOffers.pageCount,
    publisherOffersPageNumber: (state) => state.listPublisherOffers.pageNumber,
    publisherOffersItemCount: (state) => state.listPublisherOffers.itemCount,
    businessFromSearch: (state) => state.business,
    businessMoreResults: (state) => state.businessMoreResults,

    publisherNotes: (state) => state.publisher_notes,
    listPublisherNotesPageCount: (state) => state.publisher_notes_list.pageCount,
    listPublisherNotesItemCount: (state) => state.publisher_notes_list.itemCount,
    listPublisherNotesPageNumber: (state) => state.publisher_notes_list.pageNumber,
  },
  mutations: {
    publisher(state, publisher) {
      if (!publisher) {
        publisher = { system_status: {} };
      }
      state.publisher = publisher;
    },
    publishers(state, publishers) {
      state.publishers = publishers;
    },
    publisherStatistics(state, publisherStatistics) {
      state.publisherStatistics = publisherStatistics;
    },
    searchPageCount(state, pageCount) {
      state.search.pageCount = pageCount;
    },
    searchPageNumber(state, pageNumber) {
      state.search.pageNumber = pageNumber;
    },
    searchKeyword(state, keyword) {
      state.search.keyword = keyword;
    },
    listPageCount(state, pageCount) {
      state.list.pageCount = pageCount;
    },
    listItemCount(state, itemCount) {
      state.list.itemCount = itemCount;
    },
    listPageNumber(state, pageNumber) {
      state.list.pageNumber = pageNumber;
    },
    listKeyword(state, keyword) {
      state.list.keyword = keyword;
    },
    listPartnerId(state, partner_id) {
      state.list.partner_id = partner_id;
    },
    listBusinessId(state, business_id) {
      state.list.business_id = business_id;
    },

    publisherStores(state, stores) {
      state.publisherStores = stores;
    },
    publisherStoresKeyword(state, keyword) {
      state.listPublisherStores.keyword = keyword;
    },
    publisherStoresStatus(state, status) {
      state.listPublisherStores.status = status;
    },
    publisherStoresType(state, type) {
      state.listPublisherStores.type = type;
    },
    publisherStoresCategory(state, category) {
      state.listPublisherStores.category = category;
    },
    publisherStoresWithoutCardAcceptors(state, without_card_acceptors) {
      state.listPublisherStores.without_card_acceptors = without_card_acceptors;
    },
    publisherStoresPageCount(state, pageCount) {
      state.listPublisherStores.pageCount = pageCount;
    },
    publisherStoresItemCount(state, itemCount) {
      state.listPublisherStores.itemCount = itemCount;
    },
    publisherStoresPage(state, page) {
      state.listPublisherStores.pageNumber = page;
    },

    publisherOffers(state, stores) {
      state.publisherOffers = stores;
    },
    publisherOffersPageCount(state, pageCount) {
      state.listPublisherOffers.pageCount = pageCount;
    },
    publisherOffersItemCount(state, itemCount) {
      state.listPublisherOffers.itemCount = itemCount;
    },
    publisherOffersPage(state, page) {
      state.listPublisherOffers.pageNumber = page;
    },
    business(state, business) {
      state.business = business;
    },
    businessMoreResults(state, bankAccountsMoreResults) {
      state.businessMoreResults = bankAccountsMoreResults;
    },
    publisherNotes(state, notes) {
      state.publisher_notes = notes;
    },
    listPublisherNotesPageCount(state, pageCount) {
      state.publisher_notes_list.pageCount = pageCount;
    },
    listPublisherNotesItemCount(state, itemCount) {
      state.publisher_notes_list.itemCount = itemCount;
    },
    listPublisherNotesPage(state, page) {
      state.publisher_notes_list.pageNumber = page;
    },
  },
  actions: {
    async autocompleteSearch({}, { page, limit, keyword, exclude_offer_id, exclude_publisher_id }) {
      const publishersService = Vue.prototype.$services.publishers;

      return publishersService.searchPublishers(page, limit, keyword, exclude_offer_id, exclude_publisher_id);
    },
    async autocompleteSearchOfferToAddToPublisher({}, { publisher_id, page, limit, keyword }) {
      const publishersService = Vue.prototype.$services.publishers;

      return publishersService.autocompleteSearchOfferToAddToPublisher(publisher_id, page, limit, keyword);
    },
    async autocompleteSearchPublisherStoresByPublisher({}, { id, keyword, page, limit }) {
      const publishersService = Vue.prototype.$services.publishers;

      return publishersService.searchPublisherStoresByPublisher(id, keyword, page, limit);
    },
    createPublisher({ commit }, params) {
      const publishersService = Vue.prototype.$services.publishers;
      return publishersService.createPublisher(params);
    },
    async listPublishers({ commit }, { keyword, partner_id, business_id, page, limit } = {}) {
      const publishersService = Vue.prototype.$services.publishers;

      const thePage = page || state.list.pageNumber || defaultPage;
      const theLimit = limit || paginationLimit;
      const theKeyword = keyword || '';
      const thePartnerId = partner_id || '';
      const theBusinessId = business_id || '';

      const listResults = await publishersService.listPublishers(theKeyword, thePartnerId, theBusinessId, thePage, theLimit);

      commit('publishers', listResults.items);
      commit('listPageNumber', thePage);
      commit('listPageCount', listResults.page_count);
      commit('listItemCount', listResults.item_count);
      commit('listKeyword', theKeyword);
      commit('listPartnerId', thePartnerId);
      commit('listBusinessId', theBusinessId);
    },
    async updatePublisher({ dispatch }, params) {
      const publishersService = Vue.prototype.$services.publishers;
      const { id, ...payload } = params;

      delete payload.branding;
      delete payload.stores_count;

      const result = await publishersService.updatePublisher(id, payload);
      await dispatch('getPublisher', id);
      return result;
    },
    async getPublisher({ commit }, id) {
      const publishersService = Vue.prototype.$services.publishers;
      const publisher = await publishersService.getPublisher(id);

      commit('publisher', publisher);

      return publisher;
    },
    async searchPublishers({ state, commit }, { keyword, page, limit, exclude_offer_id }) {
      const publishersService = Vue.prototype.$services.publishers;

      const thePage = page || state.search.pageNumber || 1;
      const theLimit = limit || paginationLimit;
      const theKeyword = keyword || '';

      const searchResults = await publishersService.searchPublishers(thePage, theLimit, theKeyword, exclude_offer_id);
      const publishers = searchResults.items;

      commit('searchPageNumber', thePage);
      commit('searchPageCount', searchResults.page_count);
      commit('searchKeyword', theKeyword);
      commit('publishers', publishers);

      return searchResults;
    },
    async clearSearchPublishers({ commit }) {
      commit('publishers', []);
    },
    async searchBusiness({ rootState, commit }, { keyword }) {
      const businessService = Vue.prototype.$services.business;

      const result = await businessService.searchBusinesses(0, 0, keyword);

      const business = result.items;

      commit('business', business);
      commit('businessMoreResults', result.more_results);
    },
    async createPublisherNote({ dispatch }, { publisher_id, note }) {
      const publishersService = Vue.prototype.$services.publishers;
      await publishersService.createPublisherNote(publisher_id, { note });
      await dispatch('listPublisherNotes', { publisher_id });
    },
    async deletePublisherNote({ dispatch }, { publisher_id, note_id, reason }) {
      const publishersService = Vue.prototype.$services.publishers;
      await publishersService.deletePublisherNote(publisher_id, note_id, { reason });
      await dispatch('listPublisherNotes', { publisher_id });
    },
    async listPublisherNotes({ state, commit }, { publisher_id, page, limit } = {}) {
      const publishersService = Vue.prototype.$services.publishers;
      const thePage = page || state.publisher_notes_list.pageNumber;
      const theLimit = limit || publisherNotesPaginationLimit;
      const thePublisherId = publisher_id || '';

      const listResults = await publishersService.listPublisherNotes(thePage, theLimit, thePublisherId);

      commit('listPublisherNotesPage', thePage);
      commit('listPublisherNotesPageCount', listResults.page_count);
      commit('listPublisherNotesItemCount', listResults.item_count);
      commit('publisherNotes', listResults.items);
    },
    async linkBrandingToPublisher({ dispatch }, { id, ...payload }) {
      const publishersService = Vue.prototype.$services.publishers;
      const result = await publishersService.linkBrandingToPublisher(id, payload);
      await dispatch('getPublisher', id);
      return result;
    },
    async linkBusinessToPublisher({ dispatch }, { id, ...payload }) {
      const publishersService = Vue.prototype.$services.publishers;
      const result = await publishersService.linkBusinessToPublisher(id, payload);
      await dispatch('getPublisher', id);
      return result;
    },
    async unlinkBrandingFromPublisher({ dispatch }, { id }) {
      const publishersService = Vue.prototype.$services.publishers;
      const result = await publishersService.unlinkBrandingFromPublisher(id);
      await dispatch('getPublisher', id);
      return result;
    },
    async unlinkBusinessFromPublisher({ dispatch }, { id }) {
      const publishersService = Vue.prototype.$services.publishers;
      const result = await publishersService.unlinkBusinessFromPublisher(id);
      await dispatch('getPublisher', id);
      return result;
    },
    async addOfferToPublisher({ rootState, dispatch }, params) {
      await dispatch('offers/addPublisherToOffer', params, { root: true });
      await dispatch('getPublisher', params.publisher_id);
    },
    async addStoresFromPublisher({ dispatch, commit }, { to_publisher_id, from_publisher_id }) {
      const publishersService = Vue.prototype.$services.publishers;
      await publishersService.addStoresFromPublisher(to_publisher_id, from_publisher_id);

      await dispatch('getPublisher', to_publisher_id);
    },
    async addStoresFromDestination({ dispatch, commit }, { to_publisher_id, from_destination_id }) {
      const publishersService = Vue.prototype.$services.publishers;
      await publishersService.addStoresFromDestination(to_publisher_id, from_destination_id);

      await dispatch('getPublisher', to_publisher_id);
    },
    async getPublisherStatistics({ commit }, id) {
      const publishersService = Vue.prototype.$services.publishers;
      const publisherStatistics = await publishersService.getPublisherStatistics(id);

      commit('publisherStatistics', publisherStatistics);
    },
    async addStoreToPublisher({ dispatch }, { publisher_id, store_id }) {
      const publishersService = Vue.prototype.$services.publishers;
      await publishersService.addStoreToPublisher(publisher_id, store_id);

      await dispatch('getPublisher', publisher_id);
    },
    async removeAllStoresFromPublisher({ dispatch }, { publisherId }) {
      const publishersService = Vue.prototype.$services.publishers;
      const result = await publishersService.removeAllStoresFromPublisher(publisherId);
      await dispatch('getPublisher', publisherId);
      return result;
    },
    async removeStoreFromPublisher({ dispatch }, { publisherId, store_id }) {
      const publishersService = Vue.prototype.$services.publishers;
      const result = await publishersService.removeStoreFromPublisher(publisherId, store_id);
      await dispatch('getPublisher', publisherId);
      return result;
    },
    async listPublisherStoresByPublisher({ commit, dispatch, state }, { id, keyword, status, type, category, without_card_acceptors, page, limit }) {
      const publishersService = Vue.prototype.$services.publishers;
      const thePage = page || state.listPublisherStores.pageNumber || defaultPage;
      const theLimit = limit || publisherStoresPaginationLimit;
      const theKeyword = keyword || '';
      const theStatus = status || '';
      const theType = type || '';
      const theCategory = category || '';
      const theWithoutCardAcceptors = !!without_card_acceptors;

      const result = await publishersService.listPublisherStoresByPublisher(
        id,
        theKeyword,
        theStatus,
        theType,
        theCategory,
        theWithoutCardAcceptors,
        thePage,
        theLimit,
      );
      const publisherStores = result.items;

      commit('publisherStores', publisherStores);
      commit('publisherStoresKeyword', theKeyword);
      commit('publisherStoresStatus', theStatus);
      commit('publisherStoresType', theType);
      commit('publisherStoresCategory', theCategory);
      commit('publisherStoresWithoutCardAcceptors', theWithoutCardAcceptors);
      commit('publisherStoresPage', thePage);
      commit('publisherStoresPageCount', result.page_count);
      commit('publisherStoresItemCount', result.item_count);
    },
    async listPublisherOffers({ state, commit }, { id, page, limit }) {
      const publishersService = Vue.prototype.$services.publishers;
      const thePage = page || state.listPublisherOffers.pageNumber || defaultPage;
      const theLimit = limit || publisherOffersPaginationLimit;

      const result = await publishersService.listPublisherOffers(id, thePage, theLimit);
      const publisherOffers = result.items;

      commit('publisherOffers', publisherOffers);
      commit('publisherOffersPage', thePage);
      commit('publisherOffersPageCount', result.page_count);
      commit('publisherOffersItemCount', result.item_count);
    },
    async replaceBusinessInPublisher({ dispatch }, { id, ...payload }) {
      const publishersService = Vue.prototype.$services.publishers;
      await publishersService.unlinkBusinessFromPublisher(id);
      const result = await publishersService.linkBusinessToPublisher(id, payload);
      await dispatch('getPublisher', id);
      return result;
    },
    async replaceBrandingInPublisher({ dispatch }, { id, ...payload }) {
      const publishersService = Vue.prototype.$services.publishers;
      await publishersService.unlinkBrandingFromPublisher(id);
      const result = await publishersService.linkBrandingToPublisher(id, payload);
      await dispatch('getPublisher', id);
      return result;
    },
  },
};
