<template>
  <card-modal
    icon=""
    :title="$t(`user.details.memberships_section.update_membership_action.title`)"
    context="updateMembership"
    @submit="submit"
    @cancel="cancel"
    cancel_text="user.details.memberships_section.update_membership_action.button_cancel"
    submit_text="user.details.memberships_section.update_membership_action.button_save"
    data-test="membership--update-modal"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-flex>
          <v-text-field
            name="external_id"
            v-model="currentMembership.external_id"
            v-validate="'max:50'"
            :label="$t(`user.details.memberships_section.update_membership_action.fields.external_id`)"
            :error-messages="errors.collect('external_id')"
            data-vv-name="external_id"
            :data-vv-as="$t(`user.details.memberships_section.update_membership_action.fields.external_id`)"
            counter
            maxlength="50"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex sm6 xs12>
          <v-checkbox
            name="allow_communication"
            v-model="currentMembership.allow_communication"
            :label="$t(`user.details.memberships_section.update_membership_action.fields.allow_communication`)"
            :error-messages="errors.collect('allow_communication')"
            data-vv-name="allow_communication"
            :data-vv-as="$t(`user.details.memberships_section.update_membership_action.fields.allow_communication`)"
            type="checkbox"
          ></v-checkbox>
        </v-flex>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'update-membership-action',
  $_veeValidate: {
    validator: 'new',
  },
  mixins: [actionErrorTrackable],
  props: {
    membership: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentMembership: mapMembership(this.membership),
    };
  },
  computed: {
    ...mapGetters('partner', ['getLocalMembershipById']),
  },
  watch: {
    membership() {
      this.currentMembership = mapMembership(this.membership);
    },
  },
  methods: {
    clear() {
      this.resetForm();
      this.$validator.reset();
    },
    resetForm() {
      this.currentMembership = mapMembership(this.getLocalMembershipById(this.membership.id));
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    success() {
      this.clear();
      this.$emit('action');
    },
    async submit() {
      if (await this.$validator.validateAll()) {
        let payload = {
          id: this.membership.id,
          account_id: this.membership.account_id,
          external_id: this.currentMembership.external_id ? this.currentMembership.external_id : null,
          allow_communication: this.currentMembership.allow_communication,
        };

        await this.executeAction(
          {
            action: 'partner/updateMembership',
            name: 'updateMembership',
            success: this.success,
          },
          payload,
        );
      }
    },
  },
};

function mapMembership(source) {
  return {
    external_id: source.external_id,
    allow_communication: source.allow_communication,
  };
}
</script>
