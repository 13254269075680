<template>
  <div>
    <table class="responsive-table">
      <thead>
        <tr>
          <td>{{ $t('user.user_table.id') }}</td>
          <td>{{ $t('user.user_table.email') }}</td>
          <td>{{ $t('user.user_table.first_name') }}</td>
          <td>{{ $t('user.user_table.last_name') }}</td>
          <td>{{ $t('user.user_table.card_application_id') }}</td>
          <td>{{ $t('user.user_table.card') }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>
            <h5>{{ $t('user.user_table.id') }}:</h5>
            <span>
              <router-link :to="{ name: 'user_details', params: { userId: item.account_id } }">
                {{ item.account_id }}
              </router-link>
            </span>
          </td>
          <td class="email-status">
            <h5>{{ $t('user.user_table.email') }}:</h5>
            <span :class="`status-${item.email_verified ? 'verified' : 'not_verified'}`">{{ item.email }}</span>
          </td>
          <td>
            <h5>{{ $t('user.user_table.first_name') }}:</h5>
            <span v-if="item.first_name">{{ item.first_name }}</span>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('user.user_table.last_name') }}:</h5>
            <span v-if="item.last_name">{{ item.last_name }}</span>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('user.user_table.card_application_id') }}:</h5>
            <router-link v-if="item.last_card_application_id" :to="{ name: 'application', params: { applicationId: item.last_card_application_id } }">
              {{ item.last_card_application_id }}
            </router-link>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('user.user_table.card') }}:</h5>
            <router-link v-if="item.active_card_public_token" :to="{ name: 'card', params: { cardPublicToken: item.active_card_public_token } }">
              {{ item.active_card_public_token }}
            </router-link>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-xs-center">
      <v-pagination v-if="searchPageCount > 1" v-model="pagination" :length="truncatedListPageCount"></v-pagination>

      <p v-if="maximumPageReached" class="pt-1 hint-default">
        {{ $t('voucher.vouchers_table.maximum_page_reached', { page_count: searchPageCount }) }}
      </p>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'user-table',
  mixins: [actionErrorTrackable, security],
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      pagination: 1,
    };
  },
  mounted() {
    this.pagination = this.searchPageNumber;
  },
  computed: {
    ...mapGetters('account', ['searchPageCount', 'searchPageNumber', 'searchKeyword']),
    truncatedListPageCount() {
      return Math.min(this.searchPageCount, 20);
    },
    maximumPageReached() {
      return this.searchPageCount > 20;
    },
  },
  watch: {
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'account/searchAccounts',
        },
        {
          page: newValue,
          keyword: this.searchKeyword,
        },
      );
    },
  },
};
</script>
