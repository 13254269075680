<template>
  <div>
    <form @submit.prevent="submit">
      <v-layout align-center>
        <v-flex>
          <form-warning-search
            data-test="search-bank-account-input"
            :searched="searched"
            :hint-message="totalResultsMessage"
            :show-total-results-message="showTotalResultsMessage"
            v-model="keyword"
            :label="$t('business.search_bank_account.form.label_search')"
            :error-messages="errors.collect('search')"
            v-validate="'required|min:3|max:100'"
            data-vv-name="search"
            data-vv-as="Business Name"
          ></form-warning-search>
        </v-flex>
        <button-primary
          data-test="search-bank-account-button"
          @click="submit"
          :text="$t('business.search_bank_account.form.button_search')"
          :loading="working"
        ></button-primary>
      </v-layout>
    </form>
    <table v-if="formSubmitted && showSearchResults() && dataReady" class="responsive-table search-results">
      <thead>
        <tr>
          <td>{{ $t('business.search_bank_account.search_results.business_name') }}</td>
          <td>{{ $t('business.search_bank_account.search_results.bank_account') }}</td>
          <td v-if="allStatus">{{ $t('business.search_bank_account.search_results.status') }}</td>
          <td></td>
        </tr>
      </thead>
      <tbody v-if="showSearchResults()">
        <tr v-for="(bankAccount, index) in bankAccountsFromSearch" :key="`${index}`">
          <td class="preserve-white-space">{{ bankAccount.business_name }}</td>
          <td>{{ bankAccount.bank_account_number }}</td>
          <td v-if="allStatus"><bank-account-status-view :bank-account-object="bankAccount" /></td>
          <td>
            <a v-if="canSelectAccount(bankAccount)" @click="selectAccount(bankAccount)" class="bank-account-action">{{
              $t(selectLabel || 'business.search_bank_account.search_results.button_select')
            }}</a>
          </td>
        </tr>
      </tbody>
      <div v-else>
        <p class="label-text-na">{{ $t('business.search_bank_account.search_results.na') }}</p>
      </div>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable } from '@/mixins';
import BankAccountStatusView from '@/components/bank_account/shared/BankAccountStatusView';

export default {
  name: 'search-bank-account',
  components: { BankAccountStatusView },
  mixins: [actionErrorTrackable],
  props: {
    selectLabel: {
      type: String,
      required: false,
    },
    canSelectAccount: {
      type: Function,
      required: true,
    },
    selectAccount: {
      type: Function,
      required: true,
    },
    allStatus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searched: '',
      keyword: '',
      dataReady: false,
      formSubmitted: false,
      showTotalResultsMessage: false,
      totalResultsMessage: '',
    };
  },
  computed: {
    ...mapGetters('bankAccount', ['bankAccountsFromSearch', 'bankAccountsMoreResults']),
  },
  methods: {
    clear() {
      this.searched = '';
      this.keyword = '';
      this.dataReady = false;
      this.formSubmitted = false;
      this.showTotalResultsMessage = false;
      this.totalResultsMessage = '';
      this.$validator.reset();
    },
    showSearchResults() {
      return this.bankAccountsFromSearch.length !== 0;
    },
    setTotalResultsMessage() {
      this.searched = this.keyword;
      if (this.bankAccountsMoreResults) {
        this.showTotalResultsMessage = true;
        this.totalResultsMessage = this.$t('business.search_bank_account.form.total_results_message');
      } else if (this.bankAccountsFromSearch.length === 0) {
        this.showTotalResultsMessage = true;
        this.totalResultsMessage = this.$t('business.search_bank_account.form.no_results');
      } else {
        this.showTotalResultsMessage = false;
      }
    },

    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        this.formSubmitted = true;
        this.dataReady = false;
        await this.executeAction(
          {
            action: 'bankAccount/searchBankAccount',
            name: 'SearchBankAccount',
          },
          {
            keyword: this.keyword,
            all_status: this.allStatus,
          },
        );
        this.setTotalResultsMessage();
        this.dataReady = true;
      }
    },
  },
};
</script>
