<template>
  <div>
    <modal-confirm
      :show-modal="showRemoveMembershipModal"
      :title="$t('memberships_table.remove_membership_action.title')"
      context="RemoveMembershipConfirmationModal"
      @submit="removeMembership"
      @close="closeModalRemoveMembership"
      :text="$t('memberships_table.remove_membership_action.text')"
      cancel_text="memberships_table.remove_membership_action.button_cancel"
      submit_text="memberships_table.remove_membership_action.button_save"
      :submit_working="working"
    ></modal-confirm>
    <modal-action :show-modal="showUpdateMembershipModal">
      <update-membership-action :membership="membership" @close="closeModalUpdateMembership" @action="reloadPage"></update-membership-action>
    </modal-action>

    <table class="responsive-table" id="memberships-table">
      <thead>
        <tr>
          <td>{{ $t('user.memberships_table.fields.program') }}</td>
          <td>{{ $t('user.memberships_table.fields.membership_id') }}</td>
          <td>{{ $t('user.memberships_table.fields.external_id') }}</td>
          <td>{{ $t('user.memberships_table.fields.features') }}</td>
          <td>{{ $t('user.memberships_table.fields.allow_communication') }}</td>
          <td>{{ $t('user.memberships_table.fields.date_joined') }}</td>
          <td>{{ $t('user.memberships_table.fields.status') }}</td>
          <td>{{ $t('memberships_table.fields.edit') }}</td>
          <td>{{ $t('memberships_table.fields.change_status') }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(membership, index) in sortedMemberships" :key="index">
          <td>
            <h5>{{ $t('user.memberships_table.fields.program') }}:</h5>
            <span>{{ membership.partner_id }}</span>
          </td>
          <td>
            <h5>{{ $t('user.memberships_table.fields.membership_id') }}:</h5>
            <span>{{ membership.id }}</span>
          </td>
          <td>
            <h5>{{ $t('user.memberships_table.fields.external_id') }}:</h5>
            {{ membership.external_id }}
            <span class="info-not-available" v-if="!membership.external_id">{{ $t('system.na') }}</span>
          </td>
          <td>
            <h5>{{ $t('user.memberships_table.fields.features') }}:</h5>
            {{ membership.features.join(',') }}
            <span class="info-not-available" v-if="!membership.features.length">{{ $t('system.na') }}</span>
          </td>
          <td>
            <h5>{{ $t('user.memberships_table.fields.allow_communication') }}:</h5>
            {{ membership.allow_communication ? $t('boolean.true') : $t('boolean.false') }}
          </td>
          <td>
            <h5>{{ $t('user.memberships_table.fields.date_joined') }}:</h5>
            <span>{{ membership.date_subscribed | formatDateHourMin }}</span>
          </td>
          <td>
            <h5>{{ $t('user.memberships_table.fields.status') }}:</h5>
            <span :class="`status-${membership.status}`"> {{ $t(`memberships_table.status.${membership.status}`) }} </span>
          </td>
          <td data-test="user-memberships-table--update">
            <link-secondary-action
              v-if="!accountIsDeactivated"
              data-test="user-memberships-table--update-link"
              aria-role="button"
              @click="
                () => {
                  passMembershipToModal(membership);
                  showModalUpdateMembership();
                }
              "
              >{{ $t('user.memberships_table.fields.update') }}</link-secondary-action
            >
          </td>
          <td data-test="user-memberships-table--remove">
            <link-secondary-action
              v-if="canUnsubscribe(membership)"
              data-test="user-memberships-table--remove-link"
              aria-role="button"
              @click="
                () => {
                  passMembershipPartnerIdToModal(membership.partner_id);
                  showModalRemoveMembership();
                }
              "
              >{{ $t('user.memberships_table.fields.unsubscribe') }}</link-secondary-action
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { security, disableWithError, actionErrorTrackable } from '@/mixins';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import UpdateMembershipAction from '@/components/user/actions/UpdateMembershipAction.vue';

export default {
  name: 'user-memberships-table',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { UpdateMembershipAction },
  props: {
    memberships: {
      type: Array,
    },
    partners: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      partnerId: '',
      membership: {},
      showRemoveMembershipModal: false,
      showUpdateMembershipModal: false,
    };
  },
  computed: {
    ...mapGetters('account', ['account', 'accountIsDeactivated']),
    sortedMemberships() {
      let deactivated = [];

      const active = _.filter(this.memberships, (membership) => {
        if (_.get(membership, 'status') === 'unsubscribed') {
          deactivated.push(membership);
        } else {
          return membership;
        }
      });

      return _.concat(active, deactivated);
    },
  },
  methods: {
    reloadPage() {
      this.partnerId = '';
      this.membership = {};
      this.showRemoveMembershipModal = false;
      this.showUpdateMembershipModal = false;
    },
    passMembershipPartnerIdToModal(partner_id) {
      this.partnerId = partner_id;
    },
    passMembershipToModal(membership) {
      this.membership = membership;
    },
    showModalRemoveMembership() {
      this.showRemoveMembershipModal = true;
    },
    showModalUpdateMembership() {
      this.showUpdateMembershipModal = true;
    },
    closeModalRemoveMembership() {
      this.showRemoveMembershipModal = false;
    },
    closeModalUpdateMembership() {
      this.showUpdateMembershipModal = false;
    },
    async removeMembership() {
      await this.executeAction(
        {
          action: 'partner/unsubscribeMembership',
          success: this.removeMembershipSuccess,
        },
        {
          account_id: this.account.id,
          partner_id: this.partnerId,
        },
      );
    },
    removeMembershipSuccess() {
      this.reloadPage();
    },
    canUnsubscribe(membership) {
      return membership.status === 'subscribed' && this.isManageable(membership) && !this.accountIsDeactivated;
    },
    isManageable(membership) {
      let isManageable = false;

      _.forEach(this.partners, (partner) => {
        if (partner.id === membership.partner_id) {
          if (_.get(partner.options, 'manageable')) {
            isManageable = true;
            return;
          }
          return;
        }
      });

      return isManageable;
    },
  },
};
</script>
