<template>
  <div>
    <shared-offers-table :items="items" exclude="publishers" />
    <div class="text-xs-center">
      <v-pagination v-if="publisherOffersPageCount > 1" v-model="pagination" :length="publisherOffersPageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, renderErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import SharedOffersTable from '@/components/shared_tables/SharedOffersTable';

export default {
  name: 'publisher-offers-table',
  mixins: [renderErrorTrackable, actionErrorTrackable],
  components: { SharedOffersTable },
  props: {
    items: {
      type: Array,
    },
    publisherId: {
      type: String,
    },
  },
  data() {
    return {
      pagination: 1,
    };
  },
  mounted() {
    this.pagination = this.publisherOffersPageNumber;
  },
  watch: {
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'publishers/listPublisherOffers',
        },
        {
          id: this.publisherId,
          page: newValue,
        },
      );
    },
  },
  computed: {
    ...mapGetters('publishers', ['publisherOffersPageCount', 'publisherOffersPageNumber']),
  },
};
</script>
