<template>
  <div>
    <card id="memberships" :title="$t('user.memberships')" icon="" data-test="memberships">
      <template slot="headerZoneRight">
        <label-text
          class="text-sm-center"
          :label="$t('user.details.memberships_section.count')"
          :value="memberships ? memberships.length.toString() : '0'"
        ></label-text>
        <div v-if="showAddUserMembershipFeature()" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
        <label-text v-if="showAddUserMembershipFeature()" :label="`${$t('user.details.memberships_section.action')}`" value="3">
          <link-secondary-action slot="labelTextLink" @click="showModalAddMembership">
            {{ $t('user.details.memberships_section.add_membership') }}
          </link-secondary-action>
        </label-text>
      </template>
      <template slot="content">
        <user-memberships-table v-if="memberships.length !== 0" :memberships="memberships" :partners="partners"></user-memberships-table>
        <p v-else class="info-not-available">{{ $t('system.none') }}</p>
      </template>
    </card>
    <modal-action :show-modal="showAddMembershipModal">
      <add-membership-action :account-id="account.id || ''" @close="closeModalAddMembership" @action="reloadPage"></add-membership-action>
    </modal-action>
  </div>
</template>

<script>
import { security, disableWithError, actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import UserMembershipsTable from '@/components/user/detail/UserMembershipsTable.vue';
import AddMembershipAction from '@/components/user/actions/AddMembershipAction.vue';

export default {
  name: 'user-memberships',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { UserMembershipsTable, AddMembershipAction },
  data() {
    return {
      showAddMembershipModal: false,
    };
  },
  watch: {
    async account(val) {
      await this.clearMemberships();

      if (val) {
        await this.fetchMemberships();
        await this.fetchPartners();
      }
    },
  },
  computed: {
    ...mapGetters('account', ['account', 'accountIsDeactivated']),
    ...mapGetters('partner', ['memberships', 'partners']),
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    resetForm() {
      this.partner = this.dropdownLabel;
    },
    reloadPage() {
      this.showAddMembershipModal = false;
    },
    showModalAddMembership() {
      this.showAddMembershipModal = true;
    },
    closeModalAddMembership() {
      this.showAddMembershipModal = false;
    },
    showAddUserMembershipFeature() {
      return this.canManageOffer() && !this.accountIsDeactivated;
    },
    async clearMemberships() {
      await this.executeAction({ action: 'partner/clearAccountMemberships' });
    },
    async fetchMemberships() {
      if (this.account && this.account.id && !this.memberships.length) {
        await this.executeAction(
          {
            action: 'partner/listAccountMemberships',
          },
          this.account.id,
        );
      }
    },
    async fetchPartners() {
      if (this.account && this.account.id && !this.partners.length) {
        await this.executeAction(
          {
            action: 'partner/listPartners',
          },
          this.account.id,
        );
      }
    },
  },
};
</script>
