import Vue from 'vue';
import _ from 'lodash';

const defaultPage = 1;
const paginationLimit = 20;
const storePublishersPaginationLimit = 20;
const storeNotesPaginationLimit = 20;

export default {
  namespaced: true,
  state: {
    stores: [],
    store: { system_status: {}, location: { url: {}, address: {} }, complementary_info: {}, coordinates: {}, branding: {} },
    types: ['place', 'web', 'custom'],
    status: ['open', 'pending', 'closed'],
    storeCardAcceptors: [],
    storePublishers: [],
    store_notes: [],
    storePublishersPageNumber: defaultPage,
    storePublishersPageCount: 0,
    storePublishersItemCount: 0,
    list: {
      pageNumber: 1,
      pageCount: 0,
      itemCount: 0,
      keyword: '',
      franchise_id: '',
      business_id: '',
      card_acceptor: '',
      status: '',
      type: '',
      destination_id: '',
    },
    store_notes_list: {
      pageNumber: 1,
      pageCount: 0,
      itemCount: 0,
    },
  },
  getters: {
    store: (state) => state.store,
    stores: (state) => state.stores,
    types: (state) => state.types,
    status: (state) => state.status,
    storeCardAcceptors: (state) => state.storeCardAcceptors,
    storePublishers: (state) => state.storePublishers,
    storePublishersPageCount: (state) => state.storePublishersPageCount,
    storePublishersItemCount: (state) => state.storePublishersItemCount,
    storePublishersPageNumber: (state) => state.storePublishersPageNumber,
    listPageCount: (state) => state.list.pageCount,
    listItemCount: (state) => state.list.itemCount,
    listPageNumber: (state) => state.list.pageNumber,
    listKeyword: (state) => state.list.keyword,
    listBusinessId: (state) => state.list.business_id,
    listCardAcceptor: (state) => state.list.card_acceptor,
    listDestinationId: (state) => state.list.destination_id,
    listFranchiseId: (state) => state.list.franchise_id,
    listStatus: (state) => state.list.status,
    listType: (state) => state.list.type,
    storeNotes: (state) => state.store_notes,
    listStoreNotesPageCount: (state) => state.store_notes_list.pageCount,
    listStoreNotesItemCount: (state) => state.store_notes_list.itemCount,
    listStoreNotesPageNumber: (state) => state.store_notes_list.pageNumber,
  },
  mutations: {
    stores(state, stores) {
      state.stores = stores;
    },
    store(state, store) {
      if (!store) {
        store = { system_status: {}, location: { url: {}, address: {} }, complementary_info: {}, coordinates: {}, branding: {} };
      }
      store = _.merge(
        { system_status: {}, location: { url: {}, address: {} }, complementary_info: {}, coordinates: {}, branding: {} },
        _.cloneDeep(store),
      );
      state.store = store;
    },
    types(state, types) {
      state.types = types;
    },
    status(state, status) {
      state.status = status;
    },
    storeCardAcceptors(state, storeCardAcceptors) {
      state.storeCardAcceptors = storeCardAcceptors;
    },
    storePublishers(state, storePublishers) {
      state.storePublishers = storePublishers;
    },
    storePublishersPageCount(state, pageCount) {
      state.storePublishersPageCount = pageCount;
    },
    storePublishersItemCount(state, itemCount) {
      state.storePublishersItemCount = itemCount;
    },
    storePublishersPage(state, page) {
      state.storePublishersPageNumber = page;
    },
    listPageCount(state, pageCount) {
      state.list.pageCount = pageCount;
    },
    listItemCount(state, itemCount) {
      state.list.itemCount = itemCount;
    },
    listPageNumber(state, pageNumber) {
      state.list.pageNumber = pageNumber;
    },
    listKeyword(state, keyword) {
      state.list.keyword = keyword;
    },
    listBusinessId(state, business_id) {
      state.list.business_id = business_id;
    },
    listCardAcceptor(state, card_acceptor) {
      state.list.card_acceptor = card_acceptor;
    },
    listFranchiseId(state, franchise_id) {
      state.list.franchise_id = franchise_id;
    },
    listDestinationId(state, destinationId) {
      state.list.destination_id = destinationId;
    },
    listStatus(state, status) {
      state.list.status = status;
    },
    listType(state, type) {
      state.list.type = type;
    },
    storeNotes(state, notes) {
      state.store_notes = notes;
    },
    listStoreNotesPageCount(state, pageCount) {
      state.store_notes_list.pageCount = pageCount;
    },
    listStoreNotesItemCount(state, itemCount) {
      state.store_notes_list.itemCount = itemCount;
    },
    listStoreNotesPage(state, page) {
      state.store_notes_list.pageNumber = page;
    },
  },
  actions: {
    async autocompleteSearch({}, { page, limit, keyword }) {
      const storeService = Vue.prototype.$services.store;

      return storeService.searchStores(page, limit, keyword);
    },
    async listStores({ state, commit }, { keyword, business_id, franchise_id, destination_id, card_acceptor, status, type, page, limit } = {}) {
      const storeService = Vue.prototype.$services.store;

      const thePage = page || state.list.pageNumber || 1;
      const theLimit = limit || paginationLimit;
      const theKeyword = keyword || '';
      const theBusinessId = business_id || '';
      const theFranchiseId = franchise_id || '';
      const theDestinationId = destination_id || '';
      const theCardAcceptor = card_acceptor || '';
      const theStatus = status || '';
      const theType = type || '';

      const listResults = await storeService.listStores(
        theKeyword,
        theBusinessId,
        theFranchiseId,
        theDestinationId,
        theCardAcceptor,
        theStatus,
        theType,
        thePage,
        theLimit,
      );

      commit('stores', listResults.items);
      commit('listPageNumber', thePage);
      commit('listPageCount', listResults.page_count);
      commit('listItemCount', listResults.item_count);
      commit('listKeyword', theKeyword);
      commit('listBusinessId', theBusinessId);
      commit('listCardAcceptor', theCardAcceptor);
      commit('listFranchiseId', theFranchiseId);
      commit('listDestinationId', theDestinationId);
      commit('listType', theType);
      commit('listStatus', theStatus);
    },
    async clearListStores({ commit }) {
      commit('stores', []);
    },
    async createStore({ commit }, params) {
      const storeService = Vue.prototype.$services.store;
      return storeService.createStore(params);
    },
    async getStore({ commit }, id) {
      const storeService = Vue.prototype.$services.store;
      let store = await storeService.getStore(id);

      commit('store', store);

      return store;
    },
    async updateStore({ dispatch }, params) {
      const storeService = Vue.prototype.$services.store;
      const { id, ...payload } = params;
      const result = await storeService.updateStore(id, payload);
      await dispatch('getStore', id);
      return result;
    },
    async createStoreNote({ dispatch }, { store_id, note }) {
      const storeService = Vue.prototype.$services.store;
      await storeService.createStoreNote(store_id, { note });
      await dispatch('listStoreNotes', { store_id });
    },
    async deleteStoreNote({ dispatch }, { store_id, note_id, reason }) {
      const storeService = Vue.prototype.$services.store;
      await storeService.deleteStoreNote(store_id, note_id, { reason });
      await dispatch('listStoreNotes', { store_id });
    },
    async listStoreNotes({ state, commit }, { store_id, page, limit } = {}) {
      const storeService = Vue.prototype.$services.store;
      const thePage = page || state.store_notes_list.pageNumber;
      const theLimit = limit || storeNotesPaginationLimit;
      const theStoreId = store_id || '';

      const listResults = await storeService.listStoreNotes(thePage, theLimit, theStoreId);

      commit('listStoreNotesPage', thePage);
      commit('listStoreNotesPageCount', listResults.page_count);
      commit('listStoreNotesItemCount', listResults.item_count);
      commit('storeNotes', listResults.items);
    },
    async updateStoreCoordinates({ dispatch }, params) {
      const storeService = Vue.prototype.$services.store;
      const { id, ...payload } = params;
      const result = await storeService.updateStoreCoordinates(id, payload);
      await dispatch('getStore', id);
      return result;
    },
    async regenerateStoreCoordinates({ dispatch }, id) {
      const storeService = Vue.prototype.$services.store;
      const result = await storeService.regenerateStoreCoordinates(id);
      await dispatch('getStore', id);
      return result;
    },
    async createStoreCardAcceptor({ dispatch }, { store_id, ...payload }) {
      const storeService = Vue.prototype.$services.store;
      const result = await storeService.createStoreCardAcceptor(store_id, payload);
      await dispatch('getStore', store_id);
      return result;
    },
    async updateStoreCardAcceptor({ dispatch }, { id, ...payload }) {
      const storeService = Vue.prototype.$services.store;
      const result = await storeService.updateStoreCardAcceptor(id, payload);
      await dispatch('getStore', result.store_id);
      return result;
    },
    async checkStoreCardAcceptorVerification({ dispatch }, id) {
      const storeService = Vue.prototype.$services.store;
      const result = await storeService.checkStoreCardAcceptorVerification(id);
      //await dispatch('getStore', result.store_id);
      return result;
    },
    async removeStoreCardAcceptor({ dispatch }, { id, store_id }) {
      const storeService = Vue.prototype.$services.store;
      const result = await storeService.removeStoreCardAcceptor(id);
      await dispatch('getStore', store_id);
      return result;
    },
    async getStoreCardAcceptorsByStore({ commit }, id) {
      const storeService = Vue.prototype.$services.store;
      const storeCardAcceptors = await storeService.getStoreCardAcceptorsByStore(id);

      commit('storeCardAcceptors', storeCardAcceptors);
    },
    async listStorePublishers({ commit }, { id, page }) {
      const storeService = Vue.prototype.$services.store;
      const thePage = page || defaultPage;
      const result = await storeService.listStorePublishers(id, 1, storePublishersPaginationLimit);
      const storePublishers = result.items;

      commit('storePublishers', storePublishers);
      commit('storePublishersPage', thePage);
      commit('storePublishersPageCount', result.page_count);
      commit('storePublishersItemCount', result.item_count);
    },
    async changeStorePublishersPage({ commit, state }, { id, page }) {
      commit('storePublishersPage', page);

      const storeService = Vue.prototype.$services.store;
      const limit = storePublishersPaginationLimit;

      const storePublishersResult = await storeService.listStorePublishers(id, page, limit);

      commit('storePublishersPageCount', storePublishersResult.page_count);
      commit('storePublishers', storePublishersResult.items);
    },
    async addPublisherToStore({ dispatch }, { publisher_id, store_id }) {
      const publishersService = Vue.prototype.$services.publishers;
      const result = publishersService.addStoreToPublisher(publisher_id, store_id);
      await dispatch('getStore', store_id);
      return result;
    },
    async removePublisherFromStore({ dispatch }, { publisherId, store_id }) {
      const publishersService = Vue.prototype.$services.publishers;
      const result = await publishersService.removeStoreFromPublisher(publisherId, store_id);
      await dispatch('getStore', store_id);
      return result;
    },
    async linkBrandingToStore({ dispatch }, { id, ...payload }) {
      const storeService = Vue.prototype.$services.store;
      const result = await storeService.linkBrandingToStore(id, payload);
      await dispatch('getStore', id);
      return result;
    },
    async linkBusinessToStore({ dispatch }, { id, ...payload }) {
      const storeService = Vue.prototype.$services.store;
      const result = await storeService.linkBusinessToStore(id, payload);
      await dispatch('getStore', id);
      return result;
    },
    async unlinkBrandingFromStore({ dispatch }, { id }) {
      const storeService = Vue.prototype.$services.store;
      const result = await storeService.unlinkBrandingFromStore(id);
      await dispatch('getStore', id);
      return result;
    },
    async unlinkBusinessFromStore({ dispatch }, { id }) {
      const storeService = Vue.prototype.$services.store;
      const result = await storeService.unlinkBusinessFromStore(id);
      await dispatch('getStore', id);
      return result;
    },
    async replaceBusinessInStore({ dispatch }, { id, ...payload }) {
      const storeService = Vue.prototype.$services.store;
      await storeService.unlinkBusinessFromStore(id);
      const result = await storeService.linkBusinessToStore(id, payload);
      await dispatch('getStore', id);
      return result;
    },
    async replaceBrandingInStore({ dispatch }, { id, ...payload }) {
      const storeService = Vue.prototype.$services.store;
      await storeService.unlinkBrandingFromStore(id);
      const result = await storeService.linkBrandingToStore(id, payload);
      await dispatch('getStore', id);
      return result;
    },
  },
};
