<template>
  <v-alert v-model="show" class="warning-banner" type="warning" transition="scale-transition" :value="true"> {{ text }} </v-alert>
</template>

<script>
export default {
  name: 'alert-warning',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show: true,
    };
  },
};
</script>
