<template>
  <card :title="$t('inventory.details.business_section.title')" icon="" id="inventory-business">
    <template slot="headerZoneRight">
      <label-text v-if="showAddLinkBusinessFeature()" :label="`${$t('inventory.details.business_section.action')}`" value="">
        <link-secondary-action v-if="!inventory.business_id" slot="labelTextLink" @click="openAddLinkBusinessModal">
          {{ $t('inventory.details.business_section.link') }}
        </link-secondary-action>
        <link-secondary-action v-if="inventory.business_id" slot="labelTextLink" @click="openUnlinkBusinessModal">
          {{ $t('inventory.details.business_section.unlink') }}
        </link-secondary-action>
      </label-text>
    </template>
    <template slot="content">
      <modal-action :show-modal="showAddLinkBusinessModal" avoid-rendering
        ><link-business-action :inventory-obj="inventory" @close="closeAddLinkBusinessModal" @linked="linkBusinessSuccess"></link-business-action
      ></modal-action>
      <inventory-business-table
        v-if="business"
        :item="business"
        @inventory_business_update_action="openAddLinkBusinessModal"
      ></inventory-business-table>
      <label-none v-if="!business">{{ $t('system.none') }}</label-none>
      <modal-confirm
        v-if="business"
        :show-modal="showUnlinkBusinessModal"
        :title="$t(`inventory.details.business_section.unlink_action.title`)"
        context="UnlinkBusiness"
        :text="
          $t(`inventory.details.business_section.unlink_action.text`, {
            businessName: business.name,
            inventoryName: inventory.name,
          })
        "
        :cancel_text="`inventory.details.business_section.unlink_action.button_cancel`"
        :submit_text="`inventory.details.business_section.unlink_action.button_submit`"
        :submit_working="working"
        @submit="unlinkBusinessSuccess"
        @close="closeUnlinkBusinessModal"
      ></modal-confirm>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import LinkBusinessAction from '../actions/LinkBusinessAction';
import InventoryBusinessTable from './InventoryBusinessTable';
import { mapGetters } from 'vuex';

export default {
  name: 'inventory-business',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { InventoryBusinessTable, LinkBusinessAction },
  data() {
    return {
      business: null,
      showAddLinkBusinessModal: false,
      showUnlinkBusinessModal: false,
    };
  },
  computed: {
    ...mapGetters('inventory', ['inventory']),
    ...mapGetters('ui', ['currentLocale']),
  },
  watch: {
    async inventory() {
      await this.fetchBusiness();
    },
  },
  methods: {
    openAddLinkBusinessModal() {
      this.showAddLinkBusinessModal = true;
    },
    closeAddLinkBusinessModal() {
      this.showAddLinkBusinessModal = false;
    },
    openUnlinkBusinessModal() {
      this.showUnlinkBusinessModal = true;
    },
    closeUnlinkBusinessModal() {
      this.showUnlinkBusinessModal = false;
    },
    async unlinkBusinessSuccess() {
      this.showUnlinkBusinessModal = false;
      await this.executeAction(
        {
          action: 'inventory/unlinkBusinessFromInventory',
          name: 'unlinkBusinessFromInventory',
        },
        { id: this.inventory.id },
      );
      await this.fetchBusiness();
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'inventory.details.business_section.unlink_action.success' });
    },
    async linkBusinessSuccess() {
      await this.fetchBusiness();
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'inventory.details.business_section.link_action.success' });
      this.closeAddLinkBusinessModal();
    },
    showAddLinkBusinessFeature() {
      return this.canManageOffer(); // TODO: change permission
    },
    async fetchBusiness() {
      if (this.inventory.business_id) {
        this.business = await this.executeAction({ action: 'business/getBusiness' }, this.inventory.business_id);
      } else {
        this.business = null;
      }
    },
  },
};
</script>
