<template>
  <card-modal
    icon="-alt"
    :title="$t('store.details.branding_section.link_action.title')"
    context="linkBranding"
    @submit="submit"
    @cancel="cancel"
    cancel_text="store.details.branding_section.link_action.button_cancel"
    :submit_text="dynamicBtnText"
    :enable_submit="newBrandingSelected"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <modal-info-block :heading="$t('store.details.branding_section.link_action.store')" :text="storeInfo"></modal-info-block>
        <h-autocomplete
          autofocus
          v-model="currentStore.branding_id"
          :label="$t('store.details.branding_section.link_action.branding')"
          v-validate="'required'"
          :error-messages="errors.collect('branding_id')"
          name="branding_id"
          data-vv-name="branding_id"
          data-vv-as="branding_id"
          search-action="branding/autocompleteSearch"
          data-test="link-branding-store"
          no-filter
        >
        </h-autocomplete>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import Vue from 'vue';

export default {
  name: 'link-branding-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    storeObj: {
      type: Object,
    },
  },
  data() {
    return {
      currentStore: _.cloneDeep(this.storeObj),
    };
  },
  watch: {
    async storeObj() {
      this.currentStore = _.cloneDeep(this.storeObj);
    },
  },
  computed: {
    ...mapGetters('branding', ['brandings']),
    storeInfo() {
      return `${this.storeObj.name} (${this.storeObj.id})`;
    },
    dynamicBtnText() {
      return 'store.details.branding_section.link_action.button_save';
    },
    changingLink() {
      return this.storeObj.branding_id && this.currentStore.branding_id !== 'none';
    },
    linking() {
      return !this.storeObj.branding_id && this.currentStore.branding_id !== 'none';
    },
    newBrandingSelected() {
      return this.currentStore.branding_id !== 'none' && this.currentStore.branding_id !== this.storeObj.branding_id;
    },
  },
  methods: {
    clear() {
      this.currentStore = _.cloneDeep(this.storeObj);
      this.$validator.reset();
    },
    successLinked() {
      this.clear();
      this.$emit('linked');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async executeLinkAction(payload) {
      await this.executeAction(
        {
          action: 'store/linkBrandingToStore',
          name: 'linkBranding',
          success: this.successLinked,
        },
        payload,
      );
    },
    async executeReplaceAction(payload) {
      await this.executeAction(
        {
          action: 'store/replaceBrandingInStore',
          name: 'linkBranding',
          success: this.successLinked,
        },
        payload,
      );
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();
      const payload = { branding_id: this.currentStore.branding_id, id: this.currentStore.id };

      if (validationComplete) {
        if (this.linking) {
          await this.executeLinkAction(payload);
        } else if (this.changingLink) {
          await this.executeReplaceAction(payload);
        }
      }
    },
  },
};
</script>
