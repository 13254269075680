<template>
  <card-modal
    icon="fal fa-file-chart-line"
    :title="$t('offers.details.access_section.create_action.title')"
    context="addAccessToOffer"
    @submit="submit"
    @cancel="cancel"
    cancel_text="offers.details.access_section.create_action.cancel_text"
    submit_text="offers.details.access_section.create_action.submit_text"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <modal-info-block :heading="$t('offers.details.access_section.create_action.offer')" :text="offerInfo"></modal-info-block>
      </v-layout>
      <v-layout column>
        <v-text-field
          :label="$t('offers.details.access_section.create_action.email')"
          name="email"
          v-validate="{ required: true, email: true }"
          :error-messages="errors.collect('email')"
          v-model="email"
          data-vv-name="email"
          maxlength="50"
          :data-vv-as="$t('offers.details.access_section.create_action.email')"
        ></v-text-field>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'add-offers-access-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    offerObj: {
      type: Object,
    },
  },
  data() {
    return {
      email: null,
    };
  },
  computed: {
    offerInfo() {
      return `${this.offerObj.branding.name} (${this.offerObj.id})`;
    },
  },
  methods: {
    clear() {
      this.email = null;
      this.$validator.reset();
    },
    success() {
      this.clear();
      this.$emit('created');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        const payload = { offer_id: this.offerObj.id, email: this.email };

        await this.executeAction(
          {
            action: 'offers/addOfferEmailAccess',
            name: 'addAccessToOffer',
            success: this.success,
          },
          payload,
        );
      }
    },
  },
};
</script>
