<template>
  <div class="card" v-if="voucher.blocked">
    <v-alert type="error" :value="true">
      <strong v-if="latestBlockVoucherOperation">{{
        $t('voucher.blocked_message', {
          reason: latestBlockVoucherOperation.reason,
          date: this.$options.filters.formatShortDateInverted(latestBlockVoucherOperation.operation_date),
          agent: appName,
        })
      }}</strong>
    </v-alert>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'voucher-alert',
  data() {
    return {
      appName: '?',
    };
  },
  watch: {
    async latestBlockVoucherOperation() {
      if (this.latestBlockVoucherOperation && this.voucher.blocked && this.latestBlockVoucherOperation.creation_by_app_key) {
        const app = await this.getAppName(this.latestBlockVoucherOperation.creation_by_app_key);
        this.appName = app ? app : '?';
      } else {
        this.appName = '?';
      }
    },
  },
  computed: {
    ...mapGetters('voucher', ['voucher', 'latestBlockVoucherOperation']),
  },
  methods: {
    ...mapActions('security', ['getAppName']),
  },
};
</script>
