<template>
  <card :title="$t('user.instant_cards')" icon="">
    <template slot="headerZoneRight">
      <label-text class="text-sm-center" :label="$t('user.details.cards_section.count')" :value="instantCardsItemCount.toString()"></label-text>
    </template>
    <template slot="content">
      <user-instant-cards-table v-if="sortedCards && sortedCards.length" :items="sortedCards"></user-instant-cards-table>
      <label-none v-else>{{ $t('user.details.instant_cards_section.no_found') }}</label-none>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import UserInstantCardsTable from './UserInstantCardsTable';
import { mapGetters } from 'vuex';
import _groupBy from 'lodash/groupBy';
import _reduce from 'lodash/reduce';

export default {
  name: 'user-insant-cards',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { UserInstantCardsTable },
  computed: {
    ...mapGetters('account', ['account', 'instantCards', 'instantCardsItemCount']),
    ...mapGetters('ui', ['currentLocale']),
    sortedCards() {
      const grouped = _groupBy(this.instantCards, 'cardholder_id');
      return _reduce(
        grouped,
        (result, value) => {
          (result || (result = [])).push(...value);
          return result;
        },
        [],
      );
    },
  },
  watch: {
    async account() {
      await this.clearInstantCards();
      await this.fetchInstantCards();
    },
  },
  methods: {
    async clearInstantCards() {
      await this.executeAction({ action: 'account/clearInstantCards' });
    },
    async fetchInstantCards() {
      await this.executeAction({ action: 'account/listInstantCardsByAccountId' }, this.account.id);
    },
  },
};
</script>
