<template>
  <card :title="$t('offers.instant_card_projects')" icon="">
    <template slot="headerZoneRight">
      <label-text
        class="text-sm-center"
        :label="$t('offers.details.instant_card_projects_section.projects_count')"
        :value="offerInstantCardProjectsItemCount || '0'"
      ></label-text>
    </template>
    <template slot="content">
      <offers-instant-card-projects-table
        v-if="offerInstantCardProjectsItemCount > 0"
        :items="offerInstantCardProjects"
        :id="offer.id"
      ></offers-instant-card-projects-table>
      <label-none v-if="offerInstantCardProjects.length === 0">{{ $t('system.none') }}</label-none>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import OffersInstantCardProjectsTable from './OffersInstantCardProjectsTable';
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'offers-instant-card-projects',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { OffersInstantCardProjectsTable },
  data() {
    return {
      pagination: 1,
    };
  },
  async created() {
    await this.fetchOfferInstantCardProjects();
  },
  watch: {
    async offer() {
      return this.fetchOfferInstantCardProjects();
    },
    offerInstantCardProjectsPageNumber() {
      this.pagination = this.offerInstantCardProjectsPageNumber;
    },
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'offers/changeOfferInstantCardProjectsPage',
          success: this.success,
        },
        {
          id: this.offer.id,
          page: newValue,
        },
      );
    },
  },
  computed: {
    ...mapGetters('offers', [
      'offer',
      'offerInstantCardProjects',
      'offerInstantCardProjectsPageNumber',
      'offerInstantCardProjectsPageCount',
      'offerInstantCardProjectsItemCount',
    ]),
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    async fetchOfferInstantCardProjects() {
      await this.executeAction(
        { action: 'offers/listInstantCardProjectsByOffer' },
        { id: this.offer.id, page: this.offerInstantCardProjectsPageNumber },
      );
    },
  },
};
</script>
