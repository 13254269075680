<template>
  <card-modal
    icon=""
    :title="$t('business.webhooks.edit_modal.title')"
    context="EditBusinessWebhook"
    @submit="submit"
    @cancel="cancel"
    :submit_working="working"
    cancel_text="business.webhooks.edit_modal.cancel"
    submit_text="business.webhooks.edit_modal.submit"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-text-field
          :label="$t('business.webhooks.edit_modal.fields.name') + ' *'"
          name="name"
          v-model="name"
          v-validate="{ required: true }"
          :error-messages="errors.collect('name')"
          data-vv-name="name"
          maxlength="50"
          :data-vv-as="$t('business.webhooks.edit_modal.fields.name')"
        ></v-text-field>

        <v-text-field
          :label="$t('business.webhooks.edit_modal.fields.uri') + ' *'"
          name="uri"
          v-model="uri"
          v-validate="{ required: true, url: true }"
          :error-messages="errors.collect('uri')"
          data-vv-name="uri"
          maxlength="255"
          :data-vv-as="$t('business.webhooks.edit_modal.fields.uri')"
        ></v-text-field>

        <v-text-field
          :label="$t('business.webhooks.edit_modal.fields.authorization')"
          name="authorization"
          v-model="authorization"
          v-validate="{ required: false }"
          :error-messages="errors.collect('authorization')"
          data-vv-name="authorization"
          maxlength="255"
          :data-vv-as="$t('business.webhooks.edit_modal.fields.authorization')"
        ></v-text-field>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'edit-business-webhook',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    webhook: {
      type: Object,
      required: true,
    },
    businessId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('publishers', ['publishers']),
    ...mapGetters('business', ['businessAccess']),
    businessInfo() {
      return `${this.businessObj.name} (${this.businessObj.id})`;
    },
  },
  watch: {
    webhook() {
      this.webhookId = this.webhook.id;
      this.name = this.webhook.name;
      this.uri = this.webhook.uri;
      this.authorization = this.webhook.authorization;
    },
  },
  data() {
    return {
      webhookId: null,
      name: null,
      uri: null,
      authorization: null,
    };
  },
  methods: {
    clear() {
      this.name = null;
      this.uri = null;
      this.authorization = null;
      this.$validator.reset();
    },
    success() {
      this.clear();
      this.$emit('success');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        const payload = {
          webhookId: this.webhook.id,
          businessId: this.businessId,
          name: this.name,
          uri: this.uri,
        };

        if (this.authorization) {
          payload.authorization = this.authorization;
        }

        if (this.authorization === '') {
          payload.authorization = null;
        }

        await this.executeAction(
          {
            action: 'business/updateBusinessWebhook',
            name: 'EditBusinessWebhook',
            success: this.success,
          },
          payload,
        );
      }
    },
  },
};
</script>
