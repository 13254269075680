<template>
  <div v-if="currentCardApplicationExists()">
    <h3>{{ $t(`task.modal.card_application.title`) }}</h3>
    <v-layout row wrap align-center justify-start class="data-card--content-zone">
      <v-flex xs12 class="d-inline-flex data-card--content-zone__upper">
        <label-text :label="$t(`task.modal.card_application.id`)">
          <p slot="labelTextLink" class="label-text--value">
            <v-layout row>
              <link-secondary-route
                :can-access="canViewApplication()"
                :route-name="'application'"
                :route-params="{ applicationId: cardApplicationId }"
                target="blank"
                >{{ cardApplicationId }}</link-secondary-route
              >
            </v-layout>
          </p>
        </label-text>
        <label-text
          :label="$t(`task.modal.card_application.card_applicant.first_name`)"
          :value="currentCardApplication.card_applicant.first_name"
        ></label-text>
        <label-text
          :label="$t(`task.modal.card_application.card_applicant.last_name`)"
          :value="currentCardApplication.card_applicant.last_name"
        ></label-text>
        <label-text
          :label="$t(`task.modal.card_application.card_applicant.date_of_birth`)"
          :value="currentCardApplication.card_applicant.date_of_birth | formatDateNoTime"
        ></label-text>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';

export default {
  name: 'card-application-resolve-modal-part',
  mixins: [actionErrorTrackable, security],
  props: {
    cardApplicationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentCardApplication: {},
    };
  },
  async mounted() {
    this.currentCardApplication = await this.executeAction({ action: 'task/getCardApplication' }, this.cardApplicationId);
  },
  methods: {
    currentCardApplicationExists() {
      return !!this.currentCardApplication.key;
    },
  },
};
</script>
