import Vue from 'vue';

import _ from 'lodash';
import { safeDispatcher } from '@/utils/context/context-helper';
import kycFlow from '../flows/kyc-flow';
import getKycVerdict from '../utils/kyc-verdict';
import {
  validateCreateCommentParams,
  validateDownloadDocument,
  validateGetCommentParams,
  validateGetUpdateLogParams,
  validateListDocumentParams,
  validateUpdateCardApplicantParams,
} from './validators/application-validators';
import keyExtractor from '../utils/keys-extractor';

export default {
  namespaced: true,

  state: {
    application: {},
    kyc: {},
    kycVerdict: '',
    updateLogs: [],
    documents: [],
    comments: [],
    currentComment: {},
    showDeleteCommentModal: false,
    occupations: [],
    sectors: [],
  },

  getters: {
    application: (state) => state.application,
    kyc: (state) => state.kyc,
    documents: (state) => state.documents,
    kycVerdict: (state) => state.kycVerdict,
    updateLogs: (state) => state.updateLogs,
    comments: (state) => state.comments,
    currentComment: (state) => state.currentComment,
    showDeleteCommentModal: (state) => state.showDeleteCommentModal,
    occupations: (state) => state.occupations,
    sectors: (state) => state.sectors,
  },

  mutations: {
    documents(state, documents) {
      state.documents = documents;
    },
    application(state, application) {
      state.application = application;
    },
    kyc(state, kyc) {
      state.kyc = kyc;
    },
    kycVerdict(state, kycVerdict) {
      state.kycVerdict = kycVerdict;
    },
    updateLogs(state, logs) {
      state.updateLogs = logs;
    },
    comments(state, comments) {
      state.comments = comments;
    },
    currentComment(state, comment) {
      state.currentComment = comment;
    },
    showDeleteCommentModal(state, visible) {
      state.showDeleteCommentModal = visible;
    },
    occupations(state, occupations) {
      state.occupations = occupations;
    },
    sectors(state, sectors) {
      state.sectors = sectors;
    },
  },

  actions: {
    async listDocument({ commit }, { card_application_id }) {
      await validateListDocumentParams(card_application_id);
      const applicationService = Vue.prototype.$services.application;

      let docs = await applicationService.listDocument(card_application_id);
      commit('documents', docs);
    },

    async download(context, { id, card_application_id, filename }) {
      await validateDownloadDocument({ id, card_application_id, filename });
      const applicationService = Vue.prototype.$services.application;
      const fileSaver = Vue.prototype.$services.fileSaver;

      const result = await applicationService.getDocument(card_application_id, id);
      await fileSaver.saveFile(result, filename);
    },

    async getUpdateLog({ commit }, card_application_id) {
      await validateGetUpdateLogParams(card_application_id);

      const applicationChangeLog = Vue.prototype.$services.applicationChangeLog;

      let logs = await applicationChangeLog.getApplicationUpdateLog({ card_application_id });

      commit(
        'updateLogs',
        _.map(logs, (log) => {
          const paths = [
            'gender',
            'first_name',
            'last_name',
            'date_of_birth',
            'address.street',
            'address.city',
            'address.state',
            'address.country',
            'address.zip',
            'phone',
            'mobile',
            'email',
            'language',
            'settings.alerts',
            'compliance.occupation.status.text',
            'compliance.occupation.sector.text',
            'compliance.occupation.profession.text',
          ];

          log.change_keys = keyExtractor.sortExistingKeys(log.before_change, paths);
          log.change_keys = _.merge(log.change_keys, keyExtractor.sortExistingKeys(log.changes, paths));

          return log;
        }),
      );
    },

    async updateCardApplicant({ dispatch }, { cardApplicationId, cardApplicant }) {
      await validateUpdateCardApplicantParams({ cardApplicationId, cardApplicant });

      const applicationService = Vue.prototype.$services.application;
      const defaultDispatch = safeDispatcher(dispatch);

      await applicationService.updateCardApplicant(cardApplicationId, cardApplicant);

      await defaultDispatch('ui/showSuccessSnackBar', { text: 'application.update_applicant_info_action.success' });

      await defaultDispatch('application/getApplication', cardApplicationId);
    },

    async getOccupations({ commit }) {
      const applicationService = Vue.prototype.$services.application;
      const { statuses } = await applicationService.getOccupations();
      const activeWorkerSectors = statuses[0].sectors;

      commit('occupations', statuses);
      commit('sectors', activeWorkerSectors);
    },

    async approveApplication({ dispatch }, { card_application_id, payload }) {
      const applicationService = Vue.prototype.$services.application;
      const defaultDispatch = safeDispatcher(dispatch);

      await applicationService.approveApplication(card_application_id, payload);

      await defaultDispatch('ui/showSuccessSnackBar', { text: 'application.approve_application_action.success' });

      await defaultDispatch('application/getApplication', card_application_id);
    },

    async rejectApplication({ dispatch }, { card_application_id }) {
      const applicationService = Vue.prototype.$services.application;
      const defaultDispatch = safeDispatcher(dispatch);

      await applicationService.rejectApplication(card_application_id);

      await defaultDispatch('ui/showSuccessSnackBar', { text: 'application.reject_application_action.success' });

      await defaultDispatch('application/getApplication', card_application_id);
    },

    async retryKycCheck({ dispatch }, { card_application_id }) {
      const applicationService = Vue.prototype.$services.application;
      const defaultDispatch = safeDispatcher(dispatch);

      await applicationService.retryKycCheck(card_application_id);

      await defaultDispatch('ui/showSuccessSnackBar', { text: 'application.retry_kyc_check_action.success' });

      await defaultDispatch('application/getApplication', card_application_id);
    },

    async getApplication({ commit }, id) {
      const applicationService = Vue.prototype.$services.application;

      if (!id) {
        commit('application', null);
        commit('kyc', null);
        return;
      }

      const application = await applicationService.getApplication(id);
      let kyc = null;
      if (application) {
        kyc = kycFlow(application);
      }

      commit('application', application);
      commit('kyc', kyc);
    },

    async getComments({ commit }, card_application_id) {
      await validateGetCommentParams(card_application_id);

      const applicationService = Vue.prototype.$services.application;

      let page = 1;
      const limit = 50;
      let { items: applicationComments, item_count: applicationCommentsCount } = await applicationService.getComments(
        card_application_id,
        page,
        limit,
      );

      while (applicationCommentsCount > applicationComments.length) {
        page++;
        const { items: comments } = await applicationService.getComments(card_application_id, page, limit);
        applicationComments = applicationComments.concat(comments);
      }

      commit('comments', applicationComments);
    },

    async createComment({ dispatch }, params) {
      const defaultDispatch = safeDispatcher(dispatch);
      const applicationService = Vue.prototype.$services.application;

      await validateCreateCommentParams(params);
      await applicationService.createComment(params);

      await defaultDispatch('ui/showSuccessSnackBar', { text: 'application.create_application_comment_action.success' });
      await defaultDispatch('application/getComments', params.id);
    },

    showDeleteCommentModal({ commit }, comment) {
      commit('currentComment', comment);
      commit('showDeleteCommentModal', true);
    },

    hideDeleteCommentModal({ commit }) {
      commit('currentComment', {});
      commit('showDeleteCommentModal', false);
    },

    async deleteComment({ dispatch }, params) {
      const defaultDispatch = safeDispatcher(dispatch);
      const applicationService = Vue.prototype.$services.application;

      await applicationService.deleteComment({ id: params.id, reason: params.reason });

      await defaultDispatch('ui/showSuccessSnackBar', { text: 'application.create_application_comment_action.success' });
      await defaultDispatch('application/hideDeleteCommentModal');
      await defaultDispatch('application/getComments', params.application_id);
    },

    async createCard({ dispatch }, { card_application_id }) {
      const applicationService = Vue.prototype.$services.application;
      const defaultDispatch = safeDispatcher(dispatch);

      await applicationService.createCard(card_application_id);

      await defaultDispatch('ui/showSuccessSnackBar', { text: 'application.create_card_action.success' });

      await defaultDispatch('application/getApplication', card_application_id);
    },
  },
};
