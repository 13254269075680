import _ from 'lodash';
import Vue from 'vue';

const service = {
  async createAmlNote(payload) {
    if (payload.account_id === '' || payload.account_id === null) {
      delete payload.account_id;
    }

    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/aml/v1/notes`, payload);
    return response.data.data.items;
  },

  async getAmlNote(key) {
    let url = `${process.env.VUE_APP_BASE_API_URL}/aml/v1/notes/${key}`;

    const response = await Vue.prototype.$axios.get(url);
    return response.data.data;
  },

  async deleteAmlNote(key, payload) {
    let url = `${process.env.VUE_APP_BASE_API_URL}/aml/v1/notes/${key}/delete`;

    const response = await Vue.prototype.$axios.post(url, payload);
    return response.data.data;
  },

  async getTaskNote(task_id) {
    let url = `${process.env.VUE_APP_BASE_API_URL}/aml/v1/notes/task/${task_id}`;

    const response = await Vue.prototype.$axios.get(url);

    if (_.isEmpty(response.data.data)) {
      return null;
    }

    return response.data.data;
  },

  async getAmlNotes(query) {
    let url = `${process.env.VUE_APP_BASE_API_URL}/aml/v1/notes`;

    if (query) {
      const params = {};

      if (query.card_application_id) {
        params.card_application_id = query.card_application_id;
      }

      if (query.public_token) {
        params.public_token = query.public_token;
      }

      if (query.account_id) {
        params.account_id = query.account_id;
      }

      if (query.cardholder_key) {
        params.cardholder_key = query.cardholder_key;
      }

      const queryStrings = Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join('&');

      url += '?' + queryStrings;
    }

    const response = await Vue.prototype.$axios.get(url);
    return response.data.data.items;
  },
};

export default service;
