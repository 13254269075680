<template>
  <card-modal
    icon=""
    :title="$t('clerk.update_action.title')"
    context="updateClerk"
    @submit="submit"
    @cancel="cancel"
    cancel_text="clerk.update_action.button_cancel"
    submit_text="clerk.update_action.button_save"
  >
    <form @submit.prevent="submit" slot="content">
      <v-text-field
        v-model="currentClerk.name"
        :label="$t('clerk.update_action.name') + '*'"
        name="name"
        v-validate="'required'"
        :error-messages="errors.collect('name')"
        data-vv-name="name"
        maxlength="50"
        :data-vv-as="$t('clerk.update_action.name')"
        data-test="onboard--name"
      ></v-text-field>
      <v-text-field
        v-model="currentClerk.username"
        :label="$t('clerk.update_action.username') + '*'"
        name="username"
        v-validate="'required'"
        :error-messages="errors.collect('username')"
        data-vv-name="username"
        maxlength="50"
        :data-vv-as="$t('clerk.update_action.username')"
        data-test="onboard--username"
      ></v-text-field>
      <v-text-field
        :label="$t('space.clerk_table.add_action.email') + (isAdmin ? ' *' : '')"
        name="email"
        v-model="currentClerk.email"
        v-validate="{ required: isAdmin, email: true }"
        :error-messages="errors.collect('email')"
        data-vv-name="email"
        maxlength="255"
        :data-vv-as="$t('space.clerk_table.add_action.email')"
      ></v-text-field>
      <v-select
        name="language"
        v-model="currentClerk.language"
        :error-messages="errors.collect('language')"
        data-vv-name="language"
        v-validate="{ required: true }"
        :data-vv-as="$t('space.clerk_table.add_action.language')"
        :items="languageOptions"
        item-text="name"
        item-value="abbr"
        :label="$t('space.clerk_table.add_action.language') + ' *'"
      ></v-select>
      <v-select
        name="establishments"
        v-model="currentClerk.establishments"
        :error-messages="errors.collect('establishments')"
        data-vv-name="establishments"
        :data-vv-as="$t(`space.clerk_table.add_action.establishments`)"
        :items="activeSpacePartnerEstablishmentOptions"
        item-text="label"
        item-value="value"
        :label="$t(`space.clerk_table.add_action.establishments`)"
        multiple
      ></v-select>

      <h4>{{ $t('space.clerk_table.add_action.roles_title') }}</h4>
      <v-radio-group
        row
        v-model="currentClerk.roles"
        name="roles"
        v-validate="'required'"
        :error-messages="errors.collect('roles')"
        data-vv-name="roles"
        :data-vv-as="$t('space.clerk_table.add_action.roles_title')"
      >
        <v-radio
          :label="$t('space.clerk_table.add_action.roles.clerk')"
          :value="clerkRoleOptions.clerk"
          data-test="add-action--roles-clerk"
        ></v-radio>
        <v-radio
          :label="$t('space.clerk_table.add_action.roles.admin')"
          :value="clerkRoleOptions.admin"
          data-test="add-action--roles-admin"
        ></v-radio>
        <v-radio
          :label="$t('space.clerk_table.add_action.roles.superadmin')"
          :value="clerkRoleOptions.superadmin"
          data-test="add-action--roles-superadmin"
        ></v-radio>
      </v-radio-group>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'update-clerk-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    clerk: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentClerk: {},
      currentRolesAsObj: {},
      languageOptions: [
        { name: this.$t('language.french'), abbr: 'fr' },
        { name: this.$t('language.english'), abbr: 'en' },
      ],
    };
  },
  async mounted() {
    this.currentClerk = Object.assign({}, _.cloneDeep(this.clerk));

    this.currentClerk.establishments = this.currentClerk.establishments.map((est) => {
      return {
        id: est.id,
        business_id: est.business_id,
      };
    });
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('partnerSecurity', ['clerkRoleOptions']),
    ...mapGetters('space', ['activeSpacePartnerEstablishmentOptions']),
    isAdmin() {
      return _.get(this.currentClerk, 'roles', []).includes('admin');
    },
  },
  methods: {
    clear() {
      this.$validator.reset();
    },
    success(response) {
      this.clear();
      this.$emit('updated');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    buildPayload() {
      const payload = _.omit(this.currentClerk, ['status', 'partner_id', 'creation_date', 'last_auth_date', 'establishments']);
      payload.email = _.isEmpty(payload.email) ? null : payload.email;
      payload.establishment_ids = this.currentClerk.establishments.map((establishment) => {
        return establishment.id;
      });
      return payload;
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();
      if (validationComplete) {
        await this.executeAction(
          {
            action: 'partnerSecurity/updateClerk',
            name: 'updateClerk',
            success: this.success,
          },
          this.buildPayload(),
        );
      }
    },
  },
};
</script>
