import _ from 'lodash';
import Vue from 'vue';

const defaultPage = 1;
const paginationLimit = 20;

export default {
  namespaced: true,
  state: {
    activationCode: { system_status: {}, recipient: {}, voucher: {} },
    activationCodes: [],
    offerActivationCodesCount: 0,
    recipientLanguages: ['fr', 'en'],
    list: {
      keyword: '',
      pageNumber: 1,
      pageCount: 0,
      itemCount: 0,
    },
  },
  getters: {
    offerActivationCodesCount: (state) => state.offerActivationCodesCount,
    activationCodes: (state) => state.activationCodes,
    recipientLanguages: (state) => state.recipientLanguages,
    activationCode: (state) => state.activationCode,
    listKeyword: (state) => state.list.keyword,
    listPageCount: (state) => state.list.pageCount,
    listItemCount: (state) => state.list.itemCount,
    listPageNumber: (state) => state.list.pageNumber,
  },
  mutations: {
    activationCodes(state, activationCodes) {
      state.activationCodes = activationCodes;
    },
    offerActivationCodesCount(state, offerActivationCodesCount) {
      state.offerActivationCodesCount = offerActivationCodesCount;
    },
    activationCode(state, activationCode) {
      if (!activationCode) {
        activationCode = { system_status: {}, recipient: {}, voucher: {} };
      }

      if (!activationCode.recipient) {
        activationCode.recipient = {};
      }
      state.activationCode = activationCode;
    },
    listKeyword(state, keyword) {
      state.list.keyword = keyword;
    },
    listPageCount(state, pageCount) {
      state.list.pageCount = pageCount;
    },
    listItemCount(state, itemCount) {
      state.list.itemCount = itemCount;
    },
    listPage(state, pageNumber) {
      state.list.pageNumber = pageNumber;
    },
  },
  actions: {
    async preActivate({ commit }, { id }) {
      const activationCodesService = Vue.prototype.$services.activationCodes;
      return activationCodesService.preActivate(id, { purchase_channel: 'other' });
    },
    async markAsNonPreActivated({ commit }, { id }) {
      const activationCodesService = Vue.prototype.$services.activationCodes;
      return activationCodesService.markAsNonPreActivated(id);
    },
    async downloadActivationCodePDF(context, { language, id }) {
      const activationCodesService = Vue.prototype.$services.activationCodes;
      const fileSaver = Vue.prototype.$services.fileSaver;

      const { fileContent, filename } = await activationCodesService.downloadActivationCodePDF(id, language);
      await fileSaver.saveFile(fileContent, filename);

      return { success: true };
    },
    async createActivationCode({ commit, dispatch }, params) {
      const activationCodesService = Vue.prototype.$services.activationCodes;

      const payload = _.cloneDeep(params);
      delete payload.offer_id;

      const result = await activationCodesService.createActivationCode(params.offer_id, payload);

      await dispatch('countActivationCodesByOffer', { offerId: params.offer_id });

      return result;
    },
    async createActivationCodeBatch(
      { commit, dispatch },
      {
        offer_id,
        reference_id,
        batch_quantity,
        batch_name,
        bonus_amount,
        payment_amount,
        business_id,
        personalized_message,
        bonus_amount_funded,
        payment_amount_funded,
        trigger_expiration_at_purchase,
        pre_activation_required,
      },
    ) {
      const activationCodesService = Vue.prototype.$services.activationCodes;
      const payload = {
        batch_quantity,
        reference_id,
        batch_name,
        bonus_amount,
        payment_amount,
        business_id,
        personalized_message,
        bonus_amount_funded,
        payment_amount_funded,
        trigger_expiration_at_purchase,
        pre_activation_required,
      };

      const activationCodeBatch = await activationCodesService.createActivationCodeBatch(offer_id, payload);

      await dispatch('countActivationCodesByOffer', { offerId: offer_id });
      await dispatch('activationCodeBatch/listActivationCodeBatchesByOffer', { offerId: offer_id }, { root: true });

      return activationCodeBatch;
    },
    async createActivationCodeBatchWithRecipientCsvFile(
      { commit, dispatch },
      {
        offer_id,
        reference_id,
        batch_quantity,
        batch_name,
        bonus_amount,
        payment_amount,
        business_id,
        personalized_message,
        bonus_amount_funded,
        payment_amount_funded,
        recipient_file,
        trigger_expiration_at_purchase,
        pre_activation_required,
      },
    ) {
      const payload = {
        batch_quantity,
        batch_name,
        bonus_amount,
        payment_amount,
        business_id,
        bonus_amount_funded,
        payment_amount_funded,
        trigger_expiration_at_purchase,
        recipient_file,
        pre_activation_required,
      };

      if (personalized_message) {
        payload.personalized_message = personalized_message;
      }

      if (reference_id) {
        payload.reference_id = reference_id;
      }

      const activationCodesService = Vue.prototype.$services.activationCodes;
      const activationCodeBatch = await activationCodesService.createActivationCodeBatchWithRecipientCsvFile(offer_id, payload);

      await dispatch('countActivationCodesByOffer', { offerId: offer_id });
      await dispatch('activationCodeBatch/listActivationCodeBatchesByOffer', { offerId: offer_id }, { root: true });

      return activationCodeBatch;
    },
    async getActivationCode({ commit, dispatch }, id) {
      const activationCodesService = Vue.prototype.$services.activationCodes;
      const activationCode = await activationCodesService.getActivationCode(id);
      commit('activationCode', activationCode);

      return activationCode;
    },
    async cancelActivationCode({ commit, dispatch }, params) {
      const activationCodesService = Vue.prototype.$services.activationCodes;
      const result = await activationCodesService.cancelActivationCode(params.id, { reason: params.reason });
      await dispatch('getActivationCode', params.id);
      return result;
    },
    async resendActivationCode({ commit, dispatch }, params) {
      const activationCodesService = Vue.prototype.$services.activationCodes;
      const result = await activationCodesService.resendActivationCode(params.id);
      await dispatch('getActivationCode', params.id);
      return result;
    },
    async redeemActivationCode({ commit, dispatch }, params) {
      const activationCodesService = Vue.prototype.$services.activationCodes;

      const payload = _.cloneDeep(params);
      delete payload.id;

      const result = await activationCodesService.redeemActivationCode(params.id, payload);
      await dispatch('getActivationCode', params.id);
      return result;
    },
    async resetListActivationCodesByOffer({ commit }) {
      commit('listKeyword', null);
      commit('listPage', 1);
      commit('listPageCount', 1);
      commit('listItemCount', 0);
      commit('activationCodes', []);
    },
    async countActivationCodesByOffer({ state, commit }, { offerId }) {
      const activationCodesService = Vue.prototype.$services.activationCodes;
      const result = await activationCodesService.countActivationCodesByOffer(offerId);

      commit('offerActivationCodesCount', result);
    },
    async listActivationCodesByOffer({ state, commit }, { offerId, keyword, batchId, page, limit }) {
      const activationCodesService = Vue.prototype.$services.activationCodes;
      const thePage = page || state.list.pageNumber || defaultPage;
      const theLimit = limit || paginationLimit;
      const theKeyword = keyword || '';
      const batch_id = batchId || null;

      const listResults = await activationCodesService.listActivationCodesByOffer(offerId, thePage, theLimit, theKeyword, batch_id);
      commit('listKeyword', theKeyword);
      commit('listPage', thePage);
      commit('listPageCount', listResults.page_count);
      commit('listItemCount', listResults.item_count);
      commit('activationCodes', listResults.items);
    },
    async updateActivationCode({}, params) {
      const activationCodesService = Vue.prototype.$services.activationCodes;
      const { id, ...payload } = params;
      const activationCode = await activationCodesService.updateActivationCode(id, payload);

      return activationCode;
    },
  },
};
