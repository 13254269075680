<template>
  <table class="responsive-table" id="business-access-table">
    <thead>
      <tr>
        <td>{{ $t('user.business_access_table.fields.business') }}</td>
        <td>{{ $t('user.business_access_table.fields.creation_date') }}</td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in items" :key="index">
        <td>
          <router-link :to="{ name: 'business_details', params: { businessId: item.business_id } }">
            <h5>{{ $t('user.business_access_table.fields.business') }}:</h5>
            <span>{{ generateLink(item) }}</span>
          </router-link>
        </td>
        <td>
          <h5>{{ $t('user.business_access_table.fields.creation_date') }}:</h5>
          <span v-if="item.creation_date">{{ item.creation_date | formatShortDateInverted }}</span>
          <label-none v-else>{{ $t('system.na') }}</label-none>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { security, disableWithError, actionErrorTrackable } from '@/mixins';

export default {
  name: 'business-access-table',
  mixins: [security, actionErrorTrackable, disableWithError],
  props: {
    items: {
      type: Array,
    },
  },
  methods: {
    generateLink(item) {
      return `${item.business_name} (${item.business_id})`;
    },
  },
};
</script>
