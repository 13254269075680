<template>
  <card-modal
    icon=""
    :title="$t(`application.approve_application_action.title`)"
    context="approveApplication"
    @submit="submit"
    @cancel="cancel"
    cancel_text="application.approve_application_action.button_cancel"
    submit_text="application.approve_application_action.button_save"
    :submit_working="working"
  >
    <form @submit.prevent="submit" slot="content">
      <v-alert v-if="isDuplicateCard" :value="true" type="warning">
        {{ $t(`application.approve_application_action.duplicate_warning`) }}
      </v-alert>
      <v-layout row wrap class="pad-form-row-elements">
        <v-flex sm6 xs12>
          <v-select
            name="verification_method"
            v-model="verification_method"
            v-validate="{ required: true }"
            :error-messages="errors.collect('verification_method')"
            data-vv-name="verification_method"
            :data-vv-as="$t(`application.approve_application_action.verification_method`)"
            :items="verification_method_options"
            item-text="name"
            item-value="abbr"
            :label="$t(`application.approve_application_action.verification_method`)"
            class="smoke-app-approve--verification_method"
          ></v-select>
        </v-flex>
        <v-flex sm6 xs12 v-if="verification_method === 'credit_file'">
          <v-text-field
            name="credit_reference_number"
            v-model="credit_file.reference_number"
            v-validate="{ required: true }"
            :label="$t(`application.approve_application_action.reference_number`)"
            :error-messages="errors.collect('credit_reference_number')"
            data-vv-name="credit_reference_number"
            :data-vv-as="$t(`application.approve_application_action.reference_number`)"
            class="smoke-app-approve--single-ref"
          ></v-text-field>
        </v-flex>
      </v-layout>

      <v-layout row class="pad-form-row-elements" v-if="verification_method === 'dual_source'">
        <v-flex sm6 xs12>
          <h4>{{ $t(`application.approve_application_action.source1`) }}</h4>
          <v-select
            name="source1_information"
            v-model="dual_source.source1.information"
            v-validate="{ required: true }"
            :error-messages="errors.collect('source1_information')"
            data-vv-name="source1_information"
            :data-vv-as="$t(`application.approve_application_action.information`)"
            :items="getSource1InformationOptions"
            item-text="name"
            item-value="abbr"
            :label="$t(`application.approve_application_action.information`)"
            class="smoke-app-approve--source1-info"
          ></v-select>
          <v-select
            v-if="dual_source.source1.information"
            name="source1_provision"
            v-model="dual_source.source1.provision"
            v-validate="{ required: true }"
            :error-messages="errors.collect('source1_provision')"
            data-vv-name="source1_provision"
            :data-vv-as="$t(`application.approve_application_action.provision`)"
            :items="getSource1ProvisionOptions"
            item-text="name"
            item-value="abbr"
            :label="$t(`application.approve_application_action.provision`)"
            class="smoke-app-approve--source1-provision"
          ></v-select>
          <v-text-field
            v-if="dual_source.source1.provision"
            name="source1_institution"
            v-model="dual_source.source1.institution"
            v-validate="{ required: true }"
            :label="$t(`application.approve_application_action.institution`)"
            :error-messages="errors.collect('source1_institution')"
            data-vv-name="source1_institution"
            :data-vv-as="$t(`application.approve_application_action.institution`)"
            maxlength="100"
            class="smoke-app-approve--source1-institution"
          ></v-text-field>
          <v-text-field
            v-if="dual_source.source1.provision"
            name="source1_reference_number"
            v-model="dual_source.source1.reference_number"
            v-validate="{ required: true }"
            :label="$t(`application.approve_application_action.reference_number`)"
            :error-messages="errors.collect('source1_reference_number')"
            data-vv-name="source1_reference_number"
            :data-vv-as="$t(`application.approve_application_action.reference_number`)"
            maxlength="50"
            class="smoke-app-approve--source1-ref"
          ></v-text-field>
        </v-flex>

        <v-flex sm6 xs12>
          <h4>{{ $t(`application.approve_application_action.source2`) }}</h4>
          <v-select
            name="source2_information"
            v-model="dual_source.source2.information"
            v-validate="{ required: true }"
            :error-messages="errors.collect('source2_information')"
            data-vv-name="source2_information"
            :data-vv-as="$t(`application.approve_application_action.information`)"
            :items="getSource2InformationOptions"
            item-text="name"
            item-value="abbr"
            :label="$t(`application.approve_application_action.information`)"
            class="smoke-app-approve--source2-info"
          ></v-select>
          <v-select
            v-if="dual_source.source2.information"
            name="source2_provision"
            v-model="dual_source.source2.provision"
            v-validate="{ required: true }"
            :error-messages="errors.collect('source2_provision')"
            data-vv-name="source2_provision"
            :data-vv-as="$t(`application.approve_application_action.provision`)"
            :items="getSource2ProvisionOptions"
            item-text="name"
            item-value="abbr"
            :label="$t(`application.approve_application_action.provision`)"
            class="smoke-app-approve--source2-provision"
          ></v-select>
          <v-text-field
            v-if="dual_source.source2.provision"
            name="source2_institution"
            v-model="dual_source.source2.institution"
            v-validate="{ required: true }"
            :label="$t(`application.approve_application_action.institution`)"
            :error-messages="errors.collect('source2_institution')"
            data-vv-name="source2_institution"
            :data-vv-as="$t(`application.approve_application_action.institution`)"
            maxlength="100"
            class="smoke-app-approve--source2-institution"
          ></v-text-field>
          <v-text-field
            v-if="dual_source.source2.provision"
            name="source2_reference_number"
            v-model="dual_source.source2.reference_number"
            v-validate="{ required: true }"
            :label="$t(`application.approve_application_action.reference_number`)"
            :error-messages="errors.collect('source2_reference_number')"
            data-vv-name="source2_reference_number"
            :data-vv-as="$t(`application.approve_application_action.reference_number`)"
            maxlength="50"
            class="smoke-app-approve--source2-ref"
          ></v-text-field>
        </v-flex>
      </v-layout>

      <v-layout row wrap class="pad-form-row-elements" v-if="verification_method === 'face_to_face'">
        <v-flex sm6 xs12>
          <v-select
            name="face2face_document"
            v-model="face_to_face.document_type"
            v-validate="{ required: true }"
            :error-messages="errors.collect('face2face_document')"
            data-vv-name="face2face_document"
            :data-vv-as="$t(`application.approve_application_action.document_type`)"
            :items="document_options"
            item-text="name"
            item-value="abbr"
            :label="$t(`application.approve_application_action.document_type`)"
            class="smoke-app-approve--face-doc-type"
          ></v-select>
          <v-text-field
            v-if="face_to_face.document_type === 'other'"
            name="face2face_doc_type_other"
            v-model="face_to_face_document_type_other"
            v-validate="{ required: true }"
            :label="$t(`application.approve_application_action.doc_type_other`)"
            :error-messages="errors.collect('face2face_doc_type_other')"
            data-vv-name="face2face_doc_type_other"
            :data-vv-as="$t(`application.approve_application_action.doc_type_other`)"
            maxlength="50"
            class="smoke-app-approve--face-doc-type-other"
          ></v-text-field>
        </v-flex>
        <v-flex sm6 xs12>
          <v-text-field
            name="face2face_reference_number"
            v-model="face_to_face.reference_number"
            v-validate="{ required: true }"
            :label="$t(`application.approve_application_action.reference_number`)"
            :error-messages="errors.collect('face2face_reference_number')"
            data-vv-name="face2face_reference_number"
            :data-vv-as="$t(`application.approve_application_action.reference_number`)"
            maxlength="50"
            class="smoke-app-approve--face-ref"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row wrap class="pad-form-row-elements" v-if="verification_method === 'face_to_face'">
        <v-flex sm6 xs12>
          <v-text-field
            name="face2face_issuing_jurisdiction"
            v-model="face_to_face.issuing_jurisdiction"
            v-validate="{ required: true }"
            :label="$t(`application.approve_application_action.issuing_jurisdiction`)"
            :error-messages="errors.collect('face2face_issuing_jurisdiction')"
            data-vv-name="face2face_issuing_jurisdiction"
            :data-vv-as="$t(`application.approve_application_action.issuing_jurisdiction`)"
            maxlength="50"
            class="smoke-app-approve--face-jurisdiction"
          ></v-text-field>
        </v-flex>
        <v-flex sm6 xs12>
          <v-menu
            ref="date_picker_menu"
            v-model="date_picker_menu"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="face_to_face.expiration_date"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                name="face2face_date"
                v-model="expiration_date"
                v-validate="{ required: true, date_format: 'YYYY-MM-DD' }"
                :label="$t(`application.approve_application_action.expiration_date`)"
                :error-messages="errors.collect('face2face_date')"
                prepend-icon="event"
                data-vv-name="face2face_date"
                :data-vv-as="$t(`application.approve_application_action.expiration_date`)"
                readonly
                v-on="on"
                :hint="formattedDateHint"
                persistent-hint
                maxlength="50"
                class="smoke-app-approve--face-date"
              ></v-text-field>
            </template>
            <v-date-picker v-model="expiration_date" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="date_picker_menu = false">{{
                $t(`application.approve_application_action.expiration_date_cancel`)
              }}</v-btn>
              <v-btn flat color="primary" @click="date_picker_menu = false">{{
                $t(`application.approve_application_action.expiration_date_ok`)
              }}</v-btn>
            </v-date-picker>
          </v-menu>
        </v-flex>
      </v-layout>

      <!--AML note section -->
      <v-layout v-if="showAmlNoteField()">
        <create-aml-note-partial
          v-if="canManageAmlProgram()"
          default-category-id="aml-note"
          default-type-id="kyc-identity-verification"
          :card-program-key="cardProgramKey"
          :cardholder-key="cardholderKey"
          :account-id="accountId"
          ref="createAmlNote"
          :card-application-id="cardApplicationId"
        />
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { actionErrorTrackable, security } from '@/mixins';

export default {
  name: 'approve-application-action',
  mixins: [actionErrorTrackable, security],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    cardApplicationId: {
      type: String,
      required: true,
    },
    accountId: {
      type: String,
    },
    cardholderKey: {
      type: String,
      required: false,
    },
    cardProgramKey: {
      type: String,
      required: false,
    },
    kyc: {
      type: Object,
      required: false,
    },
    applicationKyc: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      verification_method_options: [
        { name: this.$t('application.approve_application_action.verification_options.credit_file'), abbr: 'credit_file' },
        { name: this.$t('application.approve_application_action.verification_options.dual_source'), abbr: 'dual_source' },
        { name: this.$t('application.approve_application_action.verification_options.face_to_face'), abbr: 'face_to_face' },
      ],
      source_options: {
        name_address: this.$t('application.approve_application_action.single_options.name_address'),
        name_birthdate: this.$t('application.approve_application_action.single_options.name_birthdate'),
        financial_account: this.$t('application.approve_application_action.single_options.financial_account'),
      },
      provision_options: {},
      document_options: [
        { name: this.$t('application.approve_application_action.document_options.passport'), abbr: 'passport' },
        { name: this.$t('application.approve_application_action.document_options.drivers_license'), abbr: 'drivers_license' },
        { name: this.$t('application.approve_application_action.document_options.permanent_resident_card'), abbr: 'permanent_resident_card' },
        { name: this.$t('application.approve_application_action.document_options.other'), abbr: 'other' },
      ],
      verification_method: '',
      credit_file: {
        reference_number: '',
        institution: '',
      },
      dual_source: {
        source1: {
          reference_number: '',
          information: '',
          provision: '',
          institution: '',
        },
        source2: {
          reference_number: '',
          information: '',
          provision: '',
          institution: '',
        },
      },
      face_to_face: {
        reference_number: '',
        document_type: '',
        issuing_jurisdiction: '',
        expiration_date: '',
      },
      expiration_date: '',
      date_picker_menu: false,
      face_to_face_document_type_other: '',
      equifaxUniqueNumber: _.get(this.kyc, 'consumer_report.unique_number'),
    };
  },
  created() {
    this.provision_options = this.loadProvisionOptions;
  },
  computed: {
    isDuplicateCard() {
      const reasonCode = _.get(this.applicationKyc, 'reason.code');
      return reasonCode && reasonCode === 'card.active.already.in.card_program';
    },
    loadProvisionOptions() {
      const tradeExists = !_.isEmpty(_.get(this.kyc, 'dual_source.sources'));

      if (tradeExists) {
        return {
          credit_file: this.$t('application.approve_application_action.dual_options.credit_file'),
          trade: this.$t('application.approve_application_action.dual_options.trade'),
          document: this.$t('application.approve_application_action.dual_options.document'),
        };
      } else {
        return {
          credit_file: this.$t('application.approve_application_action.dual_options.credit_file'),
          document: this.$t('application.approve_application_action.dual_options.document'),
        };
      }
    },
    getSource1InformationOptions() {
      const filteredValues = _.difference(_.keys(this.source_options), [this.dual_source.source2.information]);
      return this.constructArrayContainingTranslatedNames(filteredValues, this.source_options);
    },
    getSource2InformationOptions() {
      const filteredValues = _.difference(_.keys(this.source_options), [this.dual_source.source1.information]);
      return this.constructArrayContainingTranslatedNames(filteredValues, this.source_options);
    },
    getSource1ProvisionOptions() {
      let filteredValues = this.removeCreditFileFromFinancialAccount(this.dual_source.source1.information);

      if (this.dual_source.source2.provision !== 'document') {
        filteredValues = _.difference(filteredValues, [this.dual_source.source2.provision]);
      }
      return this.constructArrayContainingTranslatedNames(filteredValues, this.provision_options, 'provision');
    },
    getSource2ProvisionOptions() {
      let filteredValues = this.removeCreditFileFromFinancialAccount(this.dual_source.source2.information);

      if (this.dual_source.source1.provision !== 'document') {
        filteredValues = _.difference(filteredValues, [this.dual_source.source1.provision]);
      }
      return this.constructArrayContainingTranslatedNames(filteredValues, this.provision_options, 'provision');
    },
    formattedDateHint() {
      const date = moment(this.expiration_date, 'YYYY-MM-DD');
      if (date.isValid()) {
        return date.format('MMMM D, YYYY');
      }
      return '';
    },
  },
  watch: {
    verification_method(newVal) {
      if (newVal === 'credit_file' && this.equifaxUniqueNumber) {
        this.credit_file.reference_number = this.equifaxUniqueNumber;
        this.credit_file.institution = 'Equifax';
      }
    },
    'dual_source.source1.information'() {
      this.clearSourceFields('source1');
    },
    'dual_source.source2.information'() {
      this.clearSourceFields('source2');
    },
    'dual_source.source1.provision'(newVal) {
      this.prefillProvisionFields(newVal, 'source1');
    },
    'dual_source.source2.provision'(newVal) {
      this.prefillProvisionFields(newVal, 'source2');
    },
  },
  'face_to_face.document_type'(newVal) {
    if (newVal !== 'other') {
      this.face_to_face_document_type_other = '';
    }
  },
  methods: {
    constructArrayContainingTranslatedNames(filteredValues, options, type) {
      const arrayContainingTranslatedNames = [];

      _.forEach(filteredValues, (abbr) => {
        if (options[abbr]) {
          arrayContainingTranslatedNames.push({ name: options[abbr], abbr });
        }
      });
      return arrayContainingTranslatedNames;
    },
    removeCreditFileFromFinancialAccount(sourceInformation) {
      if (sourceInformation === 'financial_account') {
        const financialAccount = _.difference(_.keys(this.provision_options), ['credit_file']);
        return financialAccount;
      }
      return _.keys(this.provision_options);
    },
    showAmlNoteField() {
      if (this.verification_method === 'credit_file' || this.verification_method === 'dual_source') {
        return true;
      }
    },
    clearSourceFields(sourceNumber) {
      this.dual_source[sourceNumber].reference_number = '';
      this.dual_source[sourceNumber].provision = '';
      this.dual_source[sourceNumber].institution = '';
    },
    prefillProvisionFields(newVal, sourceNumber) {
      if (newVal === 'trade') {
        this.dual_source[sourceNumber].reference_number = _.get(this.kyc, 'dual_source.sources[0].account_number');
        this.dual_source[sourceNumber].institution = _.get(this.kyc, 'dual_source.sources[0].institution_name');
      }
      if (newVal === 'credit_file') {
        this.dual_source[sourceNumber].reference_number = this.equifaxUniqueNumber;
        this.dual_source[sourceNumber].institution = 'Equifax';
      }
      if (newVal === 'document') {
        this.dual_source[sourceNumber].reference_number = '';
        this.dual_source[sourceNumber].institution = '';
      }
    },
    buildPayload() {
      const payload = {
        verification_method: this.verification_method,
      };
      switch (this.verification_method) {
        case 'credit_file':
          payload.credit_file = this.credit_file;
          break;
        case 'dual_source':
          payload.dual_source = this.dual_source;
          break;
        case 'face_to_face':
          this.face_to_face_document_type_other
            ? (this.face_to_face.document_type = this.face_to_face_document_type_other)
            : (this.face_to_face.document_type = this.face_to_face.document_type);
          this.face_to_face.expiration_date = this.expiration_date;
          payload.face_to_face = this.face_to_face;
          break;
        default:
          break;
      }
      return payload;
    },
    clear() {
      this.verification_method = '';
      this.credit_file = {
        reference_number: '',
        institution: '',
      };
      this.dual_source = {
        source1: {
          reference_number: '',
          information: '',
          provision: '',
          institution: '',
        },
        source2: {
          reference_number: '',
          information: '',
          provision: '',
          institution: '',
        },
      };
      this.face_to_face = {
        reference_number: '',
        document_type: '',
        issuing_jurisdiction: '',
        expiration_date: '',
      };
      this.expiration_date = '';
      this.$validator.reset();
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    success() {
      this.clear();
      this.$emit('action');
    },
    async submit() {
      const createAmlNoteRef = this.$refs.createAmlNote;

      const validationComplete = await this.$validator.validateAll();
      const amlNoteValidation = !!createAmlNoteRef ? await createAmlNoteRef.$validator.validateAll() : true;

      if (validationComplete && amlNoteValidation) {
        const payload = this.buildPayload();

        await this.executeAction(
          {
            action: 'application/approveApplication',
            name: 'approveApplication',
            success: this.success,
          },
          {
            card_application_id: this.cardApplicationId,
            payload,
          },
        );

        if (!!createAmlNoteRef) {
          await createAmlNoteRef.submit();
        }
      }
    },
  },
};
</script>
