<template>
  <router-link v-if="cardholderId" role="button" class="button-subtle" :to="{ name: 'card', params: { cardPublicToken: publicToken } }">
    {{ publicToken }} ({{ $t(`card.type.${type}`) }})
  </router-link>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'cardholder-current-card',
  props: {
    cardholderId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      publicToken: '?',
      type: 'unknown',
    };
  },
  methods: {
    async fetch(cardholderId) {
      this.publicToken = '?';
      this.type = 'unknown';

      const card = await Vue.prototype.$services.card.getLatestCardByCardholderId(cardholderId);
      if (!card) {
        return;
      }

      this.publicToken = card.public_token;
      this.type = card.card_program_type;
    },
  },
  watch: {
    async cardholderId() {
      await this.fetch(this.cardholderId);
    },
  },
  async created() {
    await this.fetch(this.cardholderId);
  },
};
</script>
