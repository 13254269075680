<template>
  <view-main title="page_title.activation_code_detail">
    <three-levels-breadcrumbs
      slot="content"
      :top-label="$t('navigation.offers')"
      :top-to="{ name: 'offers' }"
      :second-label="activationCode.offer_id"
      :second-to="{ name: 'offers_details', params: { offerId: activationCode.offer_id } }"
      :last-label="activationCode.id"
    />
    <activation-code-information slot="content" />
  </view-main>
</template>

<script>
import { actionErrorTrackable, renderErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import ActivationCodeInformation from '@/components/activation_code/detail/ActivationCodeInformation.vue';

export default {
  name: 'activation-code-detail-view',
  mixins: [renderErrorTrackable, actionErrorTrackable],
  components: { ActivationCodeInformation },
  props: {
    activationCodeId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('activationCode', ['activationCode']),
  },
  async mounted() {
    await this.fetch();
  },
  watch: {
    async activationCodeId() {
      return this.fetch();
    },
  },
  methods: {
    async fetch() {
      const activationCode = await this.executeAction({ action: 'activationCode/getActivationCode' }, this.activationCodeId);
      if (!activationCode) {
        this.$router.replace({ name: 'notFound' });
      }
    },
  },
};
</script>
