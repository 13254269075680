<template>
  <v-flex xs2 class="background_images-section">
    <h4 v-if="brandingHasImages" class="branding-images-title">{{ $t(`branding.details.branding_background_images_section.${language}.title`) }}</h4>
    <label-text v-if="showUpdateBrandingImagesFeature() && !brandingHasImages" label="">
      <link-secondary-action slot="labelTextLink">
        <label :for="`branding-${imageRole}-file-input`" id="branding-images-file-input-label">
          {{ $t(`branding.details.branding_background_images_section.${language}.upload`) }}
        </label>
      </link-secondary-action>
    </label-text>

    <input type="file" :id="`branding-${imageRole}-file-input`" @change="addImages" @click="resetInputValue" />
    <v-layout row class="branding-image-container">
      <v-flex v-if="brandingHasImages">
        <img :src="`${imageObj.url}`" />
        <a @click="deleteImage(imageObj.id)"><v-icon small color="#fff">fad fa-trash-alt</v-icon></a>
      </v-flex>
      <v-flex v-for="(image, index) in loadingImages" :key="`loading-${index}`" class="branding-image-container">
        <div class="text-xs-center">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'branding-image',
  mixins: [security, actionErrorTrackable, disableWithError],
  props: {
    imageRole: {
      type: String,
      required: true,
    },
    imageObj: {
      type: Object,
      required: true,
    },
    language: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loadingImages: [],
    };
  },
  watch: {
    async branding() {
      await this.resetLoadingImages();
    },
  },
  computed: {
    ...mapGetters('branding', ['types', 'branding']),
    brandingHasImages() {
      return this.imageObj && Object.keys(this.imageObj).length > 0;
    },
    loadingHasImages() {
      return this.loadingImages && this.loadingImages.length > 0;
    },
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    showUpdateBrandingImagesFeature() {
      return this.canManageOffer(); // TODO change permission
    },
    async addImages(e) {
      const files = [...e.target.files];

      if (files.length) {
        const images = files.map((f) => f.name);
        const signedUploadUrls = await this.executeAction(
          { action: 'branding/createBrandingImagesUploadSignedUrls' },
          { branding_id: this.branding.id, images },
        );

        for (let key in signedUploadUrls) {
          this.loadingImages.push({});
        }

        for (let key in signedUploadUrls) {
          const signed_url = signedUploadUrls[key].upload_url;
          const image = files[key];
          await this.executeAction({ action: 'branding/uploadBrandingImage' }, { signed_url, image });
          await this.executeAction(
            { action: 'branding/addBrandingImage' },
            { branding_id: this.branding.id, payload: { role: this.imageRole, url: signedUploadUrls[key].image_url } },
          );
        }

        this.resetLoadingImages();
      }
    },
    async deleteImage(image_id) {
      await this.executeAction({ action: 'branding/removeBrandingImage' }, { branding_id: this.branding.id, image_id });
    },
    resetInputValue(e) {
      e.target.value = null;
    },
    resetLoadingImages() {
      this.loadingImages = [];
    },
  },
};
</script>
