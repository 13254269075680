import Vue from 'vue';

const service = {
  async approveApplication(id, payload) {
    const response = await Vue.prototype.$axios.put(
      `${process.env.VUE_APP_BASE_API_URL}/applications/v1/card-applications/${id}/manual-approval`,
      payload,
    );
    return response.data.data;
  },

  async rejectApplication(id) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/applications/v1/card-applications/${id}/rejection`, {});
    return response.data.data;
  },

  async updateCardApplicant(id, payload) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/applications/v1/card-applications/${id}/applicant`, payload);
    return response.data.data;
  },

  async getOccupations() {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/applications/v1/card-applications/occupations`);
    return response.data.data;
  },

  async getApplication(id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/applications/v1/card-applications/${id}`);
    return response.data.data;
  },

  async getDocument(id, doc_id) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}/applications/v1/card-applications/${id}/documents/${doc_id}`,
      { responseType: 'blob' },
    );
    return response.data;
  },

  async listDocument(id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/applications/v1/card-applications/${id}/documents`);
    return response.data.data.items;
  },

  async retryKycCheck(id) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/applications/v1/card-applications/${id}/kyc-check`, {});
    return response.data;
  },

  async getComments(id, page, limit) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}/applications/v1/card-applications/${id}/comments?page=${page}&limit=${limit}`,
    );
    return response.data.data;
  },

  async createComment({ id, comment, account_id }) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/applications/v1/card-applications/${id}/comments`, {
      comment,
      account_id,
    });
    return response.data.data.comment;
  },

  async deleteComment({ id, reason }) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/applications/v1/card-applications/comments/${id}/delete`, {
      reason,
    });
    return response.data.data;
  },

  async createCard(id) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/applications/v1/card-applications/${id}/create-card`, {});
    return response.data.data;
  },

  async listCardApplicationsByAccountId(id, page, limit) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/applications/v1/accounts/${id}/card-applications`, page, limit)}`,
    );
    return response.data.data;
  },
};

function generatePath(startPath, page, limit, keyword) {
  let path = startPath;
  if (page || limit) {
    path += '?';
  }

  const queryStrings = [];
  if (page) {
    queryStrings.push(['page', page]);
  }
  if (limit) {
    queryStrings.push(['limit', limit]);
  }
  if (keyword) {
    queryStrings.push(['keyword', keyword]);
  }

  const params = new URLSearchParams(queryStrings);
  return path + params.toString();
}

export default service;
