import Vue from 'vue';
import {
  validateCreateCommentParams,
  validateGetCommentParams,
  validateGetUpdateLogParams,
  validateUpdateCardholderParams,
} from './validators/card-validators';
import { safeDispatcher } from '../../utils/context/context-helper';
import _ from 'lodash';
import keyExtractor from '../utils/keys-extractor';

const defaultPage = 1;
const paginationLimit = 10;

export default {
  namespaced: true,

  state: {
    card: {},
    cardholder: {},
    cards: [],
    cardTransactionContext: {},
    cardPin: '',
    updateLogs: [],
    activationCode: '',
    comments: [],
    currentComment: {},
    showDeleteCommentModal: false,
    cardWalletTransactions: [],
    listWalletTransactions: {
      itemCount: 0,
      pageCount: 0,
      pageNumber: 1,
      transactionId: '',
      operationGroupId: '',
      loadOperationId: '',
    },
  },

  getters: {
    card: (state) => state.card,
    cardholder: (state) => state.cardholder,
    cards: (state) => state.cards,
    cardPin: (state) => state.cardPin.pin,
    amlFlagOptions: (state) => state.amlFlagOptions,
    activationCode: (state) => state.activationCode,
    cardTransactionContext: (state) => state.cardTransactionContext,
    updateLogs: (state) => state.updateLogs,
    comments: (state) => state.comments,
    currentComment: (state) => state.currentComment,
    showDeleteCommentModal: (state) => state.showDeleteCommentModal,
    cardWalletTransactions: (state) => state.cardWalletTransactions,
    cardWalletTransactionsCount: (state) => state.listWalletTransactions.itemCount,
    cardWalletTransactionPageCount: (state) => state.listWalletTransactions.pageCount,
    cardWalletTransactionPageNumber: (state) => state.listWalletTransactions.pageNumber,
    listWalletTransactionId: (state) => state.listWalletTransactions.transactionId,
    listWalletTransactionOperationGroupId: (state) => state.listWalletTransactions.operationGroupId,
    listWalletTransactionLoadOperationId: (state) => state.listWalletTransactions.loadOperationId,
  },

  mutations: {
    card(state, card) {
      state.card = card;
    },
    cardholder(state, cardholder) {
      state.cardholder = cardholder;
    },
    cardTransactionContext(state, cardTransactionContext) {
      state.cardTransactionContext = cardTransactionContext;
    },
    cardWalletTransactions(state, transactions) {
      state.cardWalletTransactions = transactions;
    },
    cardWalletTransactionsCount(state, count) {
      state.listWalletTransactions.itemCount = count;
    },
    cardWalletTransactionPageCount(state, count) {
      state.listWalletTransactions.pageCount = count;
    },
    cardWalletTransactionPageNumber(state, pageNumber) {
      state.listWalletTransactions.pageNumber = pageNumber;
    },
    listWalletTransactionId(state, id) {
      state.listWalletTransactions.transactionId = id;
    },
    listWalletTransactionOperationGroupId(state, id) {
      state.listWalletTransactions.operationGroupId = id;
    },
    listWalletTransactionLoadOperationId(state, id) {
      state.listWalletTransactions.loadOperationId = id;
    },
    cards(state, cards) {
      state.cards = cards;
    },
    cardPin(state, cardPin) {
      state.cardPin = cardPin;
    },
    activationCode(state, activationCode) {
      state.activationCode = activationCode;
    },
    clearCard(state) {
      state.card = {};
    },
    clearCards(state) {
      state.cards = [];
    },
    updateLogs(state, logs) {
      state.updateLogs = logs;
    },
    comments(state, comments) {
      state.comments = comments;
    },
    currentComment(state, comment) {
      state.currentComment = comment;
    },
    showDeleteCommentModal(state, visible) {
      state.showDeleteCommentModal = visible;
    },
  },

  actions: {
    async getCard({ commit }, token) {
      const cardService = Vue.prototype.$services.card;

      const card = await cardService.getCard(token);
      let augmentedCard = null;
      if (card) {
        augmentedCard = augmentCard(card);
      }

      commit('card', augmentedCard);
    },

    async resetCardTransactionContext({ commit, state }) {
      commit('cardTransactionContext', {});
    },

    async resetCardTransactionContextResults({ commit, state }) {
      const newState = { ...state.cardTransactionContext, wallets: [] };

      commit('cardTransactionContext', newState);
    },

    async getCardTransactionContext({ commit }, payload) {
      const cardService = Vue.prototype.$services.card;

      const result = await cardService.getCardTransactionContext(payload);
      commit('cardTransactionContext', result);
    },

    async getCardPin({ commit }, token) {
      const cardService = Vue.prototype.$services.card;

      const cardPin = await cardService.getCardPin(token);
      commit('cardPin', cardPin);
    },

    async getCards({ commit }, key) {
      const cardService = Vue.prototype.$services.card;

      const cards = await cardService.getCards(key);
      commit('cards', cards);
    },

    async getLatestCardByCardholderId({ commit }, cardholderId) {
      const cardService = Vue.prototype.$services.card;
      return cardService.getLatestCardByCardholderId(cardholderId);
    },

    async setCardAmlFlag({ dispatch }, { account_id, public_token, card_aml_flag, card_program_key, note, cardholder_key }) {
      const cardAmlFlagService = Vue.prototype.$services.cardAmlFlag;
      const amlNoteService = Vue.prototype.$services.amlNote;
      const defaultDispatch = safeDispatcher(dispatch);

      if (!_.isEmpty(note)) {
        let payload = {
          note,
          cardholder_key,
          public_token,
          account_id,
          card_program_key,
          created_by_email: this.state.security.currentUser.email,
        };
        await amlNoteService.createAmlNote(payload);
        await defaultDispatch('aml/getAmlNotes', { public_token, cardholder_key });
      }

      await cardAmlFlagService.setCardAmlFlag(public_token, card_aml_flag);

      await defaultDispatch('ui/showSuccessSnackBar', { text: 'card.set_aml_flag_action.success' });

      await defaultDispatch('card/getCard', public_token);
    },

    async getActivationCode({ commit }, token) {
      const cardService = Vue.prototype.$services.card;

      const activationCode = await cardService.getActivationCode(token);
      commit('activationCode', activationCode);
    },

    async reportLostStolen({ dispatch, state }, { token, regenerate, card_pin, address }) {
      const cardService = Vue.prototype.$services.card;
      const defaultDispatch = safeDispatcher(dispatch);

      const payload = { regenerate };

      if (regenerate) {
        payload.address = address;
      }

      if (card_pin) {
        payload.card_pin = card_pin;
      }
      await cardService.reportLostStolen(token, payload);

      await defaultDispatch('ui/showSuccessSnackBar', { text: 'card.report_lost_stolen_action.success' });

      await defaultDispatch('card/getCard', token);
      await defaultDispatch('card/getCards', state.card.cardholder.key);
    },

    async renewCard({ dispatch, state }, { token, card_pin, card_package_id, address }) {
      const cardService = Vue.prototype.$services.card;
      const defaultDispatch = safeDispatcher(dispatch);

      const payload = { card_package_id, address };
      if (card_pin) {
        payload.card_pin = card_pin;
      }
      await cardService.renewCard(token, payload);

      await defaultDispatch('ui/showSuccessSnackBar', { text: 'card.renew_card_action.success' });

      await defaultDispatch('card/getCard', token);
      await defaultDispatch('card/getCards', state.card.cardholder.key);
    },

    async regenerateCard({ dispatch, state }, { token, card_pin, address }) {
      const cardService = Vue.prototype.$services.card;
      const defaultDispatch = safeDispatcher(dispatch);

      const payload = { address };
      if (card_pin) {
        payload.card_pin = card_pin;
      }
      await cardService.regenerateCard(token, payload);

      await defaultDispatch('ui/showSuccessSnackBar', { text: 'card.regenerate_card_action.success' });
      await defaultDispatch('card/getCard', token);
      await defaultDispatch('card/getCards', state.card.cardholder.key);
    },

    async getUpdateLog({ commit }, public_token) {
      await validateGetUpdateLogParams(public_token);

      const cardChangeLog = Vue.prototype.$services.cardChangeLog;

      let logs = await cardChangeLog.getCardUpdateLog({ public_token });

      commit(
        'updateLogs',
        _.map(logs, (log) => {
          const paths = [
            //
            'first_name',
            'last_name',
            'date_of_birth',
            'address.street',
            'address.city',
            'address.state',
            'address.country',
            'address.zip',
            'phone',
            'mobile',
            'email',
            'language',
            'settings.alerts',
            'settings.communications',
          ];

          log.change_keys = keyExtractor.sortExistingKeys(log.before_change, paths);
          log.change_keys = _.merge(log.change_keys, keyExtractor.sortExistingKeys(log.changes, paths));

          return log;
        }),
      );
    },

    async getCardholder({ commit }, cardholder_id) {
      const cardService = Vue.prototype.$services.card;

      const cardholder = await cardService.getCardholder(cardholder_id);

      commit('cardholder', cardholder);
    },

    async updateCardholder({ dispatch }, { token, cardholder }) {
      await validateUpdateCardholderParams({ token, cardholder });

      const cardService = Vue.prototype.$services.card;
      const defaultDispatch = safeDispatcher(dispatch);

      await cardService.updateCardholder(token, cardholder);

      await defaultDispatch('ui/showSuccessSnackBar', { text: 'card.update_cardholder_info_action.success' });

      await defaultDispatch('card/getCard', token);
    },

    async getComments({ commit }, card_public_token) {
      await validateGetCommentParams(card_public_token);

      const cardService = Vue.prototype.$services.card;

      let page = 1;
      const limit = 50;
      let { items: cardComments, item_count: cardCommentsCount } = await cardService.getComments(card_public_token, page, limit);

      while (cardCommentsCount > cardComments.length) {
        page++;
        const { items: comments } = await cardService.getComments(card_public_token, page, limit);
        cardComments = cardComments.concat(comments);
      }

      commit('comments', cardComments);
    },

    async createComment({ dispatch }, params) {
      const defaultDispatch = safeDispatcher(dispatch);
      const cardService = Vue.prototype.$services.card;

      await validateCreateCommentParams(params);
      await cardService.createComment(params);

      await defaultDispatch('ui/showSuccessSnackBar', { text: 'application.create_application_comment_action.success' });
      await defaultDispatch('card/getComments', params.card_public_token);
    },

    showDeleteCommentModal({ commit }, comment) {
      commit('currentComment', comment);
      commit('showDeleteCommentModal', true);
    },

    hideDeleteCommentModal({ commit }) {
      commit('currentComment', {});
      commit('showDeleteCommentModal', false);
    },

    async deleteComment({ dispatch }, { id, reason, public_token }) {
      const defaultDispatch = safeDispatcher(dispatch);
      const cardService = Vue.prototype.$services.card;

      await cardService.deleteComment({ id, reason });

      await defaultDispatch('ui/showSuccessSnackBar', { text: 'application.create_application_comment_action.success' });
      await defaultDispatch('card/hideDeleteCommentModal');
      await defaultDispatch('card/getComments', public_token);
    },

    async updateCardMaxBalance({ dispatch }, { card_public_token, card_max_balance_amount }) {
      const cardService = Vue.prototype.$services.card;

      await cardService.updateCardMaxBalance({ card_public_token, card_max_balance_amount });

      const defaultDispatch = safeDispatcher(dispatch);
      await defaultDispatch('ui/showSuccessSnackBar', { text: 'card.update_card_max_balance_action.success' });

      await defaultDispatch('card/getCard', card_public_token);
    },

    async loadOpenWallet({ dispatch }, params) {
      const defaultDispatch = safeDispatcher(dispatch);
      const cardService = Vue.prototype.$services.cardWallet;

      const payload = {
        amount: {
          value: params.amount,
          currency: params.currency ? params.currency : 'CAD',
        },
        source_of_funds: {
          type: params.type,
          network: 'internal',
        },
      };

      if (params.type === 'agent') {
        _.set(payload, 'source_of_funds.note', params.note);
        _.set(payload, 'source_of_funds.provider.reference_id', params.reference_id);
        _.set(payload, 'source_of_funds.provider.type', 'business');
      }

      if (params.type === 'etransfer') {
        _.set(payload, 'source_of_funds.provider.reference_id', params.reference_id);
        _.set(payload, 'source_of_funds.provider.reference_date', params.reference_date);
        if (!_.isUndefined(params.note) && !_.isEmpty(params.note)) {
          _.set(payload, 'source_of_funds.note', params.note);
        }
      }

      await cardService.loadOpenWallet(params.card_public_token, payload);
      await defaultDispatch('ui/showSuccessSnackBar', { text: 'card.load_open_wallet_action.success' });

      await defaultDispatch('card/getCard', params.card_public_token);
    },

    async blockCard({ dispatch }, public_token) {
      const cardService = Vue.prototype.$services.card;
      const defaultDispatch = safeDispatcher(dispatch);

      await cardService.blockCard({ cardPublicToken: public_token });

      await defaultDispatch('ui/showSuccessSnackBar', { text: 'card.block_card_action.success' });

      await defaultDispatch('card/getCard', public_token);
    },

    async unblockCard({ dispatch }, public_token) {
      const cardService = Vue.prototype.$services.card;
      const defaultDispatch = safeDispatcher(dispatch);

      await cardService.unblockCard({ cardPublicToken: public_token });

      await defaultDispatch('ui/showSuccessSnackBar', { text: 'card.unblock_card_action.success' });

      await defaultDispatch('card/getCard', public_token);
    },

    async unblockCardPin({ dispatch }, public_token) {
      const cardService = Vue.prototype.$services.card;
      const defaultDispatch = safeDispatcher(dispatch);

      await cardService.unblockCardPin({ cardPublicToken: public_token });

      await defaultDispatch('ui/showSuccessSnackBar', { text: 'card.unblock_card_pin_action.success' });

      await defaultDispatch('card/getCard', public_token);
    },

    async lockCard({ dispatch }, public_token) {
      const cardService = Vue.prototype.$services.card;
      const defaultDispatch = safeDispatcher(dispatch);

      await cardService.lockCard({ cardPublicToken: public_token });

      await defaultDispatch('ui/showSuccessSnackBar', { text: 'card.lock_card_action.success' });

      await defaultDispatch('card/getCard', public_token);
    },

    async unlockCard({ dispatch }, public_token) {
      const cardService = Vue.prototype.$services.card;
      const defaultDispatch = safeDispatcher(dispatch);

      await cardService.unlockCard({ cardPublicToken: public_token });

      await defaultDispatch('ui/showSuccessSnackBar', { text: 'card.unlock_card_action.success' });

      await defaultDispatch('card/getCard', public_token);
    },

    async deactivateCard({ dispatch }, public_token) {
      const cardService = Vue.prototype.$services.card;
      const defaultDispatch = safeDispatcher(dispatch);

      await cardService.deactivateCard({ cardPublicToken: public_token });

      await defaultDispatch('ui/showSuccessSnackBar', { text: 'card.deactivate_card_action.success' });

      await defaultDispatch('card/getCard', public_token);
    },

    async activateCard({ dispatch }, public_token) {
      const cardService = Vue.prototype.$services.card;
      const defaultDispatch = safeDispatcher(dispatch);

      await cardService.activateCard({ cardPublicToken: public_token });

      await defaultDispatch('ui/showSuccessSnackBar', { text: 'card.activate_card_action.success' });

      await defaultDispatch('card/getCard', public_token);
    },

    clearCard({ commit }) {
      commit('card');
    },

    clearCards({ commit }) {
      commit('cards');
    },

    async listWalletTransactionsByCardholderKey(
      { commit, state },
      { cardholder_key, transaction_id, operation_group_id, load_operation_id, page, limit },
    ) {
      const cardService = Vue.prototype.$services.card;
      const thePage = page || state.listWalletTransactions.pageNumber || defaultPage;
      const theLimit = limit || paginationLimit;
      const theTransactionId = transaction_id || '';
      const theOperationGroupId = operation_group_id || '';
      const theLoadOperationId = load_operation_id || '';

      const response = await cardService.listWalletTransactionsByCardholderKey(
        cardholder_key,
        theTransactionId,
        theOperationGroupId,
        theLoadOperationId,
        thePage,
        theLimit,
      );
      const transactions = response.items;
      const item_count = response.item_count;
      const page_count = response.page_count;

      commit('cardWalletTransactions', transactions);
      commit('cardWalletTransactionsCount', item_count);
      commit('cardWalletTransactionPageCount', page_count);
      commit('cardWalletTransactionPageNumber', thePage);
      commit('listWalletTransactionId', theTransactionId);
      commit('listWalletTransactionOperationGroupId', theOperationGroupId);
      commit('listWalletTransactionLoadOperationId', theLoadOperationId);
    },

    async operationGroupAdjustment(
      { dispatch },
      {
        card_public_token,
        operation_type,
        operation_group_id,
        wallet_definition_id,
        adjust_available_amount,
        available_amount,
        adjust_actual_amount,
        actual_amount,
        reason,
        currency,
      },
    ) {
      const cardService = Vue.prototype.$services.card;

      currency = currency ? currency : 'CAD';
      const payload = {
        reason,
        available_amount: {
          value: adjust_available_amount ? available_amount : 0,
          currency,
        },
        actual_amount: {
          value: adjust_actual_amount ? actual_amount : 0,
          currency,
        },
      };

      await cardService.operationGroupAdjustment(card_public_token, operation_type, wallet_definition_id, operation_group_id, payload);
      const defaultDispatch = safeDispatcher(dispatch);
      await defaultDispatch('card/getCard', card_public_token);
    },

    async cancelTransaction({ dispatch }, { card_public_token, transaction_id }) {
      const cardService = Vue.prototype.$services.card;
      await cardService.cancelTransaction(card_public_token, transaction_id);
      const defaultDispatch = safeDispatcher(dispatch);
      await defaultDispatch('card/getCard', card_public_token);
    },
  },
};

function augmentCard(card) {
  let other_wallets_balance = 0;

  _.map(card.wallets, (wallet) => {
    if (wallet.wallet_definition_id === 'open') {
      card.open_wallet_balance = wallet.available_balance;
    } else {
      other_wallets_balance += wallet.available_balance;
    }
  });

  card.other_wallets_balance = other_wallets_balance;
  card.card_balance = card.open_wallet_balance + card.other_wallets_balance;
  return card;
}
