<template>
  <div class="clerk-name--wrap">
    <span class="clerk-name--text" v-if="clerk_id">{{ name }}</span>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'clerk-name',
  props: {
    clerk_id: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      name: '?',
    };
  },
  methods: {
    ...mapActions('partnerSecurity', ['getClerk']),
  },
  watch: {
    clerk_id: function(newVal) {
      // NOTE (SG) : Don't use async here, we want to load the name asynchronously and
      // don't want to stop the creation of components until the value as been get.
      this.getClerk(newVal)
        .then((clerk) => (this.name = clerk ? clerk.name : '?'))
        .catch((e) => console.log(e.stack || e)); // eslint-disable-line no-console
    },
  },
  created() {
    // NOTE (SG) : Don't use async here, we want to load the name asynchronously and
    // don't want to stop the creation of components until the value as been get.
    this.getClerk(this.clerk_id)
      .then((clerk) => (this.name = clerk ? clerk.name : '?'))
      .catch((e) => console.log(e.stack || e)); // eslint-disable-line no-console
  },
};
</script>
