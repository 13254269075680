<template>
  <card :title="$t('business.details.bank_account_section.title')" icon="">
    <template slot="headerZoneRight">
      <label-text v-if="bankAccount" :label="`${$t('business.details.bank_account_section.status')}`" value="">
        <bank-account-status-view slot="labelTextLink" class="font-weight-bold" :bank-account-object="bankAccount"></bank-account-status-view>
      </label-text>
      <div v-if="showAddLinkBankAccountFeature()" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text v-if="showAddLinkBankAccountFeature()" :label="`${$t('business.details.bank_account_section.action')}`" value="">
        <link-secondary-action v-if="!business.bank_account_key" slot="labelTextLink" @click="openAddLinkBankAccountModal">
          {{ $t('business.details.bank_account_section.link') }}
        </link-secondary-action>
        <link-secondary-action v-if="business.bank_account_key" slot="labelTextLink" @click="showUnlinkBankAccountModal = true">
          {{ $t('business.details.bank_account_section.unlink') }}
        </link-secondary-action>
      </label-text>
    </template>
    <template slot="content">
      <modal-action :show-modal="showAddLinkBankAccountModal"
        ><business-link-bank-account-action
          :v-if="showAddLinkBankAccountModal"
          :business="business"
          @close="closeAddLinkBankAccountModal"
          @linked="linkBankAccountSuccess"
        ></business-link-bank-account-action
      ></modal-action>
      <business-bank-account-table v-if="bankAccount" :item="bankAccount"></business-bank-account-table>
      <label-none v-if="!bankAccount">{{ $t('card.comments_section.no_found') }}</label-none>
      <modal-confirm
        v-if="bankAccount"
        :show-modal="showUnlinkBankAccountModal"
        :title="$t(`business.details.bank_account_section.unlink_action.title`)"
        context="UnlinkBankAccount"
        :text="
          $t(`business.details.bank_account_section.unlink_action.text`, {
            bankAccountName: bankAccount.business_name,
            businessName: business.name,
          })
        "
        :cancel_text="`business.details.bank_account_section.unlink_action.button_cancel`"
        :submit_text="`business.details.bank_account_section.unlink_action.button_submit`"
        :submit_working="working"
        @submit="unlinkBankAccountSuccess"
        @close="closeUnlinkBankAccountModal"
      ></modal-confirm>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';
import BusinessLinkBankAccountAction from '../actions/BusinessLinkBankAccountAction.vue';
import BusinessBankAccountTable from './BusinessBankAccountTable.vue';
import BankAccountStatusView from '@/components/bank_account/shared/BankAccountStatusView.vue';

export default {
  name: 'business-bank-account',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { BusinessLinkBankAccountAction, BusinessBankAccountTable, BankAccountStatusView },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      bankAccount: null,
      showUnlinkBankAccountModal: false,
      showAddLinkBankAccountModal: false,
    };
  },
  watch: {
    async business() {
      await this.fetchBankAccount();
    },
  },
  computed: {
    ...mapGetters('business', ['business']),
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    openAddLinkBankAccountModal() {
      this.showAddLinkBankAccountModal = true;
    },
    closeAddLinkBankAccountModal() {
      this.showAddLinkBankAccountModal = false;
    },
    closeUnlinkBankAccountModal() {
      this.showUnlinkBankAccountModal = false;
    },
    async linkBankAccountSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'business.details.bank_account_section.link_success' });
      this.closeAddLinkBankAccountModal();
    },
    async unlinkBankAccountSuccess() {
      this.showUnlinkBankAccountModal = false;
      await this.executeAction(
        {
          action: 'business/unlinkBankAccountFromBusiness',
        },
        { id: this.businessId },
      );
      await this.fetchBankAccount();
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'business.details.bank_account_section.unlink_success' });
    },
    showAddLinkBankAccountFeature() {
      return this.canManageOffer(); // TODO: change permission
    },
    async fetchBankAccount() {
      if (this.business.bank_account_key) {
        this.bankAccount = await this.executeAction({ action: 'bankAccount/getBankAccount' }, this.business.bank_account_key);
      } else {
        this.bankAccount = null;
      }
    },
  },
};
</script>
