<template>
  <card-modal
    icon=""
    :title="$t('purse.load_open_purse_from_business.title')"
    context="LoadOpenPurseFromBusiness"
    @submit="validate"
    @cancel="cancel"
    cancel_text="purse.load_open_purse_from_business.button_cancel"
    submit_text="purse.load_open_purse_from_business.button_save"
    :submit_working="working"
    data-test="load-purse-from-business-modal"
  >
    <form @submit.prevent="validate" slot="content">
      <modal-confirm
        :show-modal="showConfirmModal"
        context="LoadOpenPurseFromBusiness"
        :title="$t('purse.load_open_purse_from_business.confirm.title')"
        :text="$t('purse.load_open_purse_from_business.confirm.text', { amount: this.amount })"
        cancel_text="purse.load_open_purse_from_business.confirm.button_cancel"
        submit_text="purse.load_open_purse_from_business.confirm.button_submit"
        :submit_working="working"
        @submit="submit"
        @close="closeConfirmModal"
      ></modal-confirm>

      <v-layout column>
        <v-layout wrap row class="pad-form-row-elements">
          <v-flex xs12 sm4 md4>
            <v-text-field
              data-test="load-purse-from-business-amount-input"
              v-model="amount"
              name="amount"
              type="number"
              :label="$t('purse.load_open_purse_from_business.fields.amount') + ' *'"
              v-validate="{ required: true, decimal: 2, min_value: 0.01, max_value: 2500 }"
              :error-messages="errors.collect('amount')"
              data-vv-name="amount"
              :data-vv-as="$t('purse.load_open_purse_from_business.fields.amount')"
              :suffix="currency"
              required
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-layout wrap row class="pad-form-row-elements">
          <v-flex xs12 sm8 md8>
            <h-autocomplete
              v-model="business_id"
              :label="$t('purse.load_open_purse_from_business.fields.business_id') + '*'"
              v-validate="'required'"
              :error-messages="errors.collect('business_id')"
              name="business_id"
              data-vv-name="business_id"
              data-vv-as="business_id"
              search-action="business/autocompleteSearch"
              data-test="load-open-purse--business"
              no-filter
            >
            </h-autocomplete>
          </v-flex>
        </v-layout>

        <v-layout wrap row class="pad-form-row-elements">
          <v-textarea
            data-test="load-purse-from-business-note-input"
            v-validate="'required|max:140'"
            data-vv-name="note"
            :error-messages="errors.collect('note')"
            :data-vv-as="$t('purse.load_open_purse_from_business.fields.note')"
            v-model="note"
            rows="1"
            auto-grow
            :label="$t('purse.load_open_purse_from_business.fields.note') + ' *'"
            counter="140"
            maxlength="140"
          ></v-textarea>
        </v-layout>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable } from '@/mixins';

import _ from 'lodash';
import Vue from 'vue';

export default {
  name: 'load-open-purse-from-business-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    account_id: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      business_id: '',
      showConfirmModal: false,
      note: undefined,
      amount: 0,
    };
  },
  computed: {
    ...mapGetters('program', ['currency']),
  },
  methods: {
    async openConfirmModal() {
      this.showConfirmModal = true;
    },
    closeConfirmModal() {
      this.showConfirmModal = false;
    },
    inModalClear() {
      this.showConfirmModal = false;
      this.business_id = '';
      this.amount = 0;
      this.note = undefined;
      this.clearError('LoadOpenPurseFromBusiness');
      this.$validator.reset();
    },
    clear() {
      this.inModalClear();
    },
    success() {
      this.clear();
      this.closeConfirmModal();
      this.$emit('action');
      this.$emit('close');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async validate() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        if (this.amount >= 500) {
          await this.openConfirmModal();
        } else {
          await this.submit();
        }
      }
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        const params = {
          account_id: this.account_id,
          amount: this.amount,
          currency: this.currency,
          business_id: this.business_id,
          note: this.note,
        };

        await this.executeAction(
          {
            action: 'purse/loadOpenPurseFromBusiness',
            name: 'LoadOpenPurseFromBusiness',
            success: this.success,
          },
          params,
        );
      }
    },
  },
};
</script>
