<template>
  <card :title="$t('offers.webhook_listeners.title')" icon="">
    <template slot="headerZoneRight">
      <label-text class="text-sm-center" :label="$t('offers.webhook_listeners.count')" :value="offerWebhookListenersItemCount || '0'"></label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text class="text-sm-center" :label="$t('offers.webhook_listeners.action')">
        <link-secondary-action slot="labelTextLink" @click="showAddOfferWebhookListenerModal()">
          {{ $t('offers.webhook_listeners.add') }}
        </link-secondary-action>
      </label-text>
    </template>
    <template slot="content">
      <modal-action :show-modal="addOfferWebhookListenerModalShown" close>
        <add-offer-webhook-listener-action
          :offer-obj="offer"
          @success="offerWebhookListenerCreated"
          @close="closeAddOfferWebhookListenerModal"
        ></add-offer-webhook-listener-action>
      </modal-action>
      <offer-webhook-listeners-table v-if="offerWebhookListeners.length" :items="offerWebhookListeners" :offer-id="offer.id" />
      <label-none v-if="offerWebhookListeners.length === 0">{{ $t('system.none') }}</label-none>
    </template>
  </card>
</template>

<script>
import { security, disableWithError, actionErrorTrackable } from '@/mixins';
import OfferWebhookListenersTable from './OfferWebhookListenersTable';
import AddOfferWebhookListenerAction from '../actions/AddOfferWebhookListenerAction';
import { mapGetters } from 'vuex';

export default {
  name: 'business-webhooks',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: {
    OfferWebhookListenersTable,
    AddOfferWebhookListenerAction,
  },
  data() {
    return {
      addOfferWebhookListenerModalShown: false,
    };
  },
  watch: {
    async offer() {
      await this.fetchWebhookListeners();
    },
  },
  computed: {
    ...mapGetters('offers', ['offer', 'offerWebhookListeners', 'offerWebhookListenersPageNumber', 'offerWebhookListenersItemCount']),
  },
  methods: {
    async fetchWebhookListeners() {
      await this.executeAction({ action: 'offers/listOfferWebhookListeners' }, { id: this.offer.id, page: 1 });
    },
    showAddOfferWebhookListenerModal() {
      this.addOfferWebhookListenerModalShown = true;
    },
    closeAddOfferWebhookListenerModal() {
      this.addOfferWebhookListenerModalShown = false;
    },
    async offerWebhookListenerCreated() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'offers.webhook_listeners.add_modal.success' });
      this.addOfferWebhookListenerModalShown = false;
      await this.fetchWebhookListeners();
    },
  },
};
</script>
