<template>
  <card :title="$t('card.wallet_transactions.title')" icon="">
    <template slot="content">
      <form @submit.prevent="submitListTransactionsByCardholder" class="form-basic form-search">
        <v-layout align-center class="form-search--main-wrap-sub-module">
          <v-flex class="form-search--button-wrap-sub-module">
            <button-primary
              class="card.wallet_transactions--btn-search"
              :text="$t('card.wallet_transactions.form.submit')"
              :loading="working"
              type="submit"
            ></button-primary>
          </v-flex>
          <v-flex class="form-search--inputs-zone-sub-module">
            <form-warning-search
              :searched="searched"
              :hint-message="$t('card.wallet_transactions.form.transaction_id')"
              :label="$t('card.wallet_transactions.form.transaction_id')"
              v-model="transaction_id"
              data-vv-name="transaction_id"
              v-validate="'max:50'"
              :max-length="50"
              :error-messages="errors.collect('transaction_id')"
            ></form-warning-search>
            <form-warning-search
              :searched="searched"
              :hint-message="$t('card.wallet_transactions.form.operation_group_id')"
              :label="$t('card.wallet_transactions.form.operation_group_id')"
              v-model="operation_group_id"
              data-vv-name="operation_group_id"
              v-validate="'max:50'"
              :max-length="50"
              :error-messages="errors.collect('operation_group_id')"
            ></form-warning-search>
            <form-warning-search
              :searched="searched"
              :hint-message="$t('card.wallet_transactions.form.load_operation_id_hint')"
              :label="$t('card.wallet_transactions.form.load_operation_id')"
              v-model="load_operation_id"
              data-vv-name="load_operation_id"
              v-validate="'max:50'"
              :max-length="50"
              :error-messages="errors.collect('load_operation_id')"
            ></form-warning-search>
          </v-flex>
        </v-layout>
      </form>

      <card-wallet-transactions-table
        v-if="dataReady && cardWalletTransactions.length"
        :items="cardWalletTransactions"
        :card="card"
      ></card-wallet-transactions-table>
      <label-none v-if="cardWalletTransactions.length === 0 && dirty">{{ $t('system.none') }}</label-none>
    </template>
  </card>
</template>

<script>
import { security, disableWithError, actionErrorTrackable } from '@/mixins';
import CardWalletTransactionsTable from './CardWalletTransactionsTable';
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'card-wallet-transactions',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { CardWalletTransactionsTable },
  data() {
    return {
      transaction_id: '',
      operation_group_id: '',
      load_operation_id: '',
      searched: '',

      page: 1,
      dataReady: false,
      dirty: false,
    };
  },
  props: {
    card: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapGetters('card', ['cardWalletTransactions', 'cardWalletTransactionsCount']),
    key() {
      return this.dataReady ? this.card.cardholder.key : null;
    },
  },
  methods: {
    async clear() {
      this.transaction_id = '';
      this.operation_group_id = '';
      this.load_operation_id = '';
      this.searched = '';
      this.page = 1;
      this.dirty = false;
      this.$validator.reset();
    },

    async submitListTransactionsByCardholder(event, forceSearch = false, page = 1) {
      let validationComplete;
      if (!forceSearch) {
        validationComplete = await this.$validator.validateAll();
      }

      if (validationComplete || forceSearch) {
        await this.clearAllError();
        this.dataReady = false;
        await this.executeAction(
          { action: 'card/listWalletTransactionsByCardholderKey' },
          {
            cardholder_key: this.card.cardholder.key,
            transaction_id: this.transaction_id,
            operation_group_id: this.operation_group_id,
            load_operation_id: this.load_operation_id,
            page,
          },
        );
        this.dataReady = true;
        this.dirty = true;
      }
    },
  },
};
</script>
