<template>
  <card-modal
    icon="fal fa-store-alt"
    :title="$t('offers.details.publishers_section.create_action.title')"
    context="addPublisherToOffer"
    @submit="submit"
    @cancel="cancel"
    cancel_text="offers.details.publishers_section.create_action.cancel_text"
    submit_text="offers.details.publishers_section.create_action.submit_text"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <modal-info-block :heading="$t('offers.details.publishers_section.create_action.offer')" :text="offerInfo"></modal-info-block>
        <h-autocomplete
          v-model="publisherToAdd"
          :label="$t('offers.details.publishers_section.create_action.publisher') + '*'"
          v-validate="'required'"
          :error-messages="errors.collect('publisher')"
          name="publisher"
          data-vv-name="publisher"
          data-vv-as="publisher"
          search-action="publishers/searchPublishers"
          :extra-search-params="{ exclude_offer_id: offerObj.id }"
          data-test="update-offer--publisher"
        >
        </h-autocomplete>
      </v-layout>
      <v-layout column v-if="!offerObj.is_single_publisher">
        <v-text-field
          :label="$t('offers.details.publishers_section.create_action.inventory_limit')"
          name="inventory_limit"
          append-icon="fas fa-dollar-sign"
          v-validate="{
            required: true,
            integer: true,
            min_value: 0,
          }"
          :error-messages="errors.collect('inventory_limit')"
          v-model="inventory_limit"
          data-vv-name="inventory_limit"
          maxlength="50"
          :data-vv-as="$t('offers.details.publishers_section.create_action.inventory_limit')"
        ></v-text-field>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import Vue from 'vue';

export default {
  name: 'add-offers-publisher-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    offerObj: {
      type: Object,
    },
    helloShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      publisherToAdd: null,
      inventory_limit: 0,
      isPublisherLoading: false,
      searchPublisher: null,
      publisherSelections: [],
    };
  },
  watch: {
    offerPublishers() {
      this.publisherToAdd = null;
    },
    async helloShow(flag) {
      if (flag) {
        this.inventory_limit = this.offerObj.availability.publisher_default_inventory_limit;
      }
    },
  },
  computed: {
    ...mapGetters('offers', ['offerPublishers']),
    offerInfo() {
      return `${this.offerObj.branding.name} (${this.offerObj.id})`;
    },
  },
  methods: {
    clear() {
      this.publisherToAdd = null;
      this.$validator.reset();
    },
    success() {
      this.clear();
      this.$emit('created');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        if (this.publisherToAdd) {
          const payload = { offer_id: this.offerObj.id, publisher_id: this.publisherToAdd, inventory_limit: this.inventory_limit };

          await this.executeAction(
            {
              action: 'offers/addPublisherToOffer',
              name: 'addPublisherToOffer',
              success: this.success,
            },
            payload,
          );
        }
      }
    },
  },
};
</script>
