<template>
  <span v-if="bankAccount.key">{{ `${bankAccount.business_name} (${bankAccount.bank_account_number})` }}</span>
</template>

<script>
import { actionErrorTrackable, renderErrorTrackable } from '@/mixins';

export default {
  name: 'bank-account-view',
  props: {
    bankAccountKey: {
      type: String,
      required: true,
    },
  },
  watch: {
    bankAccountKey() {
      this.fetch();
    },
  },
  data: () => {
    return {
      bankAccount: {},
    };
  },
  methods: {
    getBankAccountSuccess(account) {
      this.bankAccount = account ? account : {};
    },
    fetch() {
      if (this.bankAccountKey) {
        this.executeAction({ action: 'bankAccount/getBankAccount', success: this.getBankAccountSuccess }, this.bankAccountKey);
      }
    },
  },
  mixins: [renderErrorTrackable, actionErrorTrackable],
  created: function() {
    this.fetch();
  },
};
</script>
