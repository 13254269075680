<template>
  <card-modal
    icon=""
    :title="$t('card.get_activation_code.title')"
    context="activationCode"
    @cancel="cancel"
    cancel_text="card.get_activation_code.button_cancel"
  >
    <div slot="content">
      <label-text :label="$t('card.get_activation_code.label_application_code')">
        <p slot="labelTextLink" class="label-text--value card--activation-code-field">
          <v-layout row>
            {{ activationCode }} <button class="clipboard" v-clipboard:copy="activationCode"><v-icon small>file_copy</v-icon></button>
          </v-layout>
        </p>
      </label-text>
      <label-text :label="$t('card.get_activation_code.label_birth_date')" :class-array="['larger-text']">
        <p slot="labelTextLink" class="label-text--value">
          <v-layout row>
            {{ birthDate }} <button class="clipboard" v-clipboard:copy="birthDate"><v-icon small>file_copy</v-icon></button>
          </v-layout>
          <span class="hint-default">{{ birthDate | formatDateNoTime }}</span>
        </p>
      </label-text>
    </div>
  </card-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable } from '@/mixins';

export default {
  name: 'activation-code',
  mixins: [actionErrorTrackable],
  props: {
    publicToken: {
      type: String,
      required: true,
    },
    birthDate: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('card', ['activationCode']),
  },
  created() {
    this.getActivationCode();
  },
  methods: {
    cancel() {
      this.$emit('close');
    },
    async getActivationCode() {
      await this.executeAction({ action: 'card/getActivationCode' }, this.publicToken);
    },
  },
};
</script>
