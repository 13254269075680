<template>
  <card :title="$t('instant_card_projects.details.notes_section.title')" icon="">
    <template slot="headerZoneRight">
      <label-text
        class="text-xs-center"
        :label="`${$t('instant_card_projects.details.notes_section.count')}`"
        :value="listProjectNotesItemCount || '0'"
      ></label-text>
      <div v-if="showAddProjectNoteFeature()" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text v-if="showAddProjectNoteFeature()" :label="`${$t('instant_card_projects.details.notes_section.action')}`" value="3">
        <link-secondary-action slot="labelTextLink" @click="openAddProjectNoteModal">
          {{ $t('instant_card_projects.details.notes_section.create_note') }}
        </link-secondary-action>
      </label-text>
    </template>
    <template slot="content">
      <project-notes-table
        v-if="projectNotes.length"
        :items="projectNotes"
        :project-id="project.id"
        @project_note_delete_action="openDeleteProjectNoteModal"
      ></project-notes-table>
      <label-none v-if="projectNotes.length === 0">{{ $t('instant_card_projects.details.notes_section.no_found') }}</label-none>
      <modal-action :show-modal="showAddProjectNoteModal"
        ><create-project-note-action
          :v-if="showAddProjectNoteModal"
          :project-id="project.id"
          @close="closeAddProjectNoteModal"
          @created="addProjectNoteSuccess"
        ></create-project-note-action
      ></modal-action>
      <modal-action :show-modal="showDeleteProjectNoteModal"
        ><delete-project-note-action
          :v-if="showDeleteProjectNoteModal"
          :project-id="project.id"
          :note-id="noteIdToDelete"
          @close="closeDeleteProjectNoteModal"
          @deleted="deleteProjectNoteSuccess"
        ></delete-project-note-action
      ></modal-action>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import CreateProjectNoteAction from '../actions/CreateProjectNoteAction';
import DeleteProjectNoteAction from '../actions/DeleteProjectNoteAction';
import ProjectNotesTable from './ProjectNotesTable';
import { mapGetters } from 'vuex';

export default {
  name: 'project-notes',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { ProjectNotesTable, CreateProjectNoteAction, DeleteProjectNoteAction },
  data() {
    return {
      showAddProjectNoteModal: false,
      showDeleteProjectNoteModal: false,
      noteIdToDelete: null,
      noteToDelete: {},
    };
  },
  computed: {
    ...mapGetters('instantCardProject', ['projectNotes', 'project', 'listProjectNotesItemCount']),
    ...mapGetters('ui', ['currentLocale']),
  },
  watch: {
    async project() {
      await this.fetchProjectNotes();
    },
  },
  methods: {
    openAddProjectNoteModal() {
      this.showAddProjectNoteModal = true;
    },
    closeAddProjectNoteModal() {
      this.showAddProjectNoteModal = false;
    },
    openDeleteProjectNoteModal(noteId) {
      this.noteIdToDelete = noteId;
      this.noteToDelete = this.projectNotes.find((l) => l.id === noteId);
      this.showDeleteProjectNoteModal = true;
    },
    closeDeleteProjectNoteModal() {
      this.noteIdToDelete = null;
      this.noteToDelete = {};
      this.showDeleteProjectNoteModal = false;
    },
    async deleteProjectNoteSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'instant_card_projects.delete_note_action.success' });
      this.closeDeleteProjectNoteModal();
    },
    async addProjectNoteSuccess() {
      await this.fetchProjectNotes();
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'instant_card_projects.create_note_action.success' });
      this.closeAddProjectNoteModal();
    },
    showAddProjectNoteFeature() {
      return this.canManageOffer(); // TODO change permission
    },
    async fetchProjectNotes() {
      await this.executeAction({ action: 'instantCardProject/listProjectNotes' }, { project_id: this.project.id });
    },
  },
};
</script>
