<template>
  <div>
    <table class="responsive-table">
      <thead>
        <tr>
          <td>{{ $t('business.business_table.id') }}</td>
          <td>{{ $t('business.business_table.name') }}</td>
          <td>{{ $t('business.business_table.address') }}</td>
          <td>{{ $t('business.business_table.contact') }}</td>
          <td>{{ $t('business.business_table.bank_account') }}</td>
          <td>{{ $t('business.business_table.created') }}</td>
          <td>{{ $t('business.business_table.status') }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>
            <h5>{{ $t('business.business_table.id') }}:</h5>
            <span>
              <router-link :to="{ name: 'business_details', params: { businessId: item.id } }">
                {{ item.id }}
              </router-link>
            </span>
          </td>
          <td>
            <h5>{{ $t('business.business_table.name') }}:</h5>
            <span>{{ item.name }}</span>
          </td>
          <td>
            <h5>{{ $t('business.business_table.address') }}:</h5>
            <span>{{ fullAddress(item) }}</span>
          </td>
          <td>
            <h5>{{ $t('business.business_table.contact') }}:</h5>
            <span>{{ item.contact.name }}</span>
          </td>
          <td>
            <h5>{{ $t('business.business_table.bank_account') }}:</h5>
            <span>
              <bank-account-view v-if="item.bank_account_key" :bank-account-key="item.bank_account_key"></bank-account-view>
              <label-none v-else>{{ $t('system.na') }}</label-none>
            </span>
          </td>
          <td>
            <h5>{{ $t('business.business_table.created') }}:</h5>
            <span>{{ item.creation_date | formatShortDateInverted | orNotAvailable }}</span>
          </td>
          <td>
            <h5>{{ $t('business.business_table.status') }}:</h5>
            <business-status-view :status="item.status" />
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-xs-center">
      <v-pagination v-if="listPageCount > 1" v-model="pagination" :length="listPageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import { mapGetters } from 'vuex';
import BankAccountView from '@/components/bank_account/shared/BankAccountView.vue';
import LabelNone from '@/components/system/Labels/LabelNone';
import BusinessStatusView from '../BusinessStatusView';

export default {
  name: 'business-table',
  mixins: [actionErrorTrackable, security],
  components: { LabelNone, BankAccountView, BusinessStatusView },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      pagination: 1,
    };
  },
  mounted() {
    this.pagination = this.listPageNumber;
  },
  computed: {
    ...mapGetters('business', ['listPageCount', 'listPageNumber', 'listKeyword']),
  },
  methods: {
    fullAddress(item) {
      return `${item.address.street}, ${item.address.city}, ${item.address.state}, ${item.address.country}, ${item.address.zip}`;
    },
  },
  watch: {
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'business/listBusinesses',
        },
        {
          page: newValue,
          keyword: this.listKeyword,
        },
      );
    },
  },
};
</script>
