<template>
  <card-data class="hc-application-section">
    <template slot="headerZoneLeft">
      <v-layout align-center>
        <v-icon size="24px" color="primary">fal fa-user-check</v-icon>
        <h3>{{ $t('application.applicant_info.title') }}</h3>
      </v-layout>
    </template>

    <template slot="headerZoneRight">
      <label-text :label="$t(`application.application_info.id`)">
        <p slot="labelTextLink" class="label-text--value">
          <v-layout row>
            {{ application.card_application_id }}
            <button class="clipboard" v-clipboard:copy="application.card_application_id">
              <v-icon small>file_copy</v-icon>
            </button>
          </v-layout>
        </p>
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="$t(`application.application_info.date`)" :value="application.date_submitted | formatDateHourMin"></label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text
        :label="$t(`application.application_info.status`)"
        :class-array="[`status-${application.status}`, 'application--status-field']"
        :value="$t(`code.application.status.${application.status}`)"
      ></label-text>
      <div v-if="application.account_id" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text v-if="application.account_id" :label="$t(`application.application_info.account_id`)">
        <p slot="labelTextLink" class="label-text--value">
          <v-layout row>
            <link-secondary-route
              class="hc-public-token"
              :can-access="canViewApplication()"
              :route-name="'user_details'"
              :route-params="{ userId: application.account_id }"
              >{{ application.account_id }}</link-secondary-route
            >
            <button class="clipboard" v-clipboard:copy="application.account_id">
              <v-icon small>file_copy</v-icon>
            </button>
          </v-layout>
        </p>
      </label-text>
      <div v-if="cardIssued" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text v-if="cardIssued" :label="$t(`application.issued_card.title`)" :value="application.card_public_token">
        <p slot="labelTextLink" class="label-text--value application--card-public-token-field">
          <v-layout row>
            <link-secondary-route
              class="hc-public-token"
              :can-access="canViewCard()"
              :route-name="'card'"
              :route-params="{ cardPublicToken: activeCardPublicToken }"
              >{{ activeCardPublicToken }}</link-secondary-route
            >
            <button class="clipboard" v-clipboard:copy="activeCardPublicToken">
              <v-icon small>file_copy</v-icon>
            </button>
          </v-layout>
        </p>
      </label-text>
      <div v-if="actions.reduce((acc, val) => val.isAvailable() || acc, false)" class="card-header-divider--vertical hidden-xs-only">
        <v-divider vertical></v-divider>
      </div>
      <button-secondary-actions class="application-info--actions" :text="$t(`application.button_actions`)" :actions="actions">
        <template slot="updateapplicant">
          <modal-action :show-modal="activeModal === 'update_applicant'">
            <update-applicant-info-action
              :card-applicant="cardApplicant"
              :card-application-id="application.card_application_id"
              @close="closeModal"
              @action="resetPageState"
            />
          </modal-action>
        </template>

        <template slot="amlnote">
          <modal-action :show-modal="activeModal === 'aml'">
            <create-aml-note-action
              :card-application-id="application.card_application_id"
              :card-program-key="application.card_program_key"
              :cardholder-key="application.cardholder_key"
              :account-id="application.account_id"
              @close="closeModal"
              @action="resetPageState"
            />
          </modal-action>
        </template>

        <template slot="approve">
          <modal-action :show-modal="activeModal === 'approve'">
            <approve-application-action
              :card-application-id="application.card_application_id"
              :card-program-key="application.card_program_key"
              :cardholder-key="application.cardholder_key"
              :kyc="kyc"
              :application-kyc="application.kyc"
              :account-id="application.account_id"
              @close="closeModal"
              @action="resetPageState"
            />
          </modal-action>
        </template>

        <template slot="reject">
          <modal-action :show-modal="activeModal === 'reject'">
            <reject-application-action
              :card-application-id="application.card_application_id"
              :card-program-key="application.card_program_key"
              :cardholder-key="application.cardholder_key"
              :account-id="application.account_id"
              @close="closeModal"
              @action="resetPageState"
            />
          </modal-action>
        </template>

        <template slot="retrykyc">
          <modal-action-small :show-modal="activeModal === 'retry_kyc'">
            <retry-kyc-check-action :application-id="application.card_application_id" @close="closeModal" @action="resetPageState" />
          </modal-action-small>
        </template>

        <template slot="createcard">
          <modal-action-small :show-modal="activeModal === 'create_card'">
            <create-card-action :application-id="application.card_application_id" @close="closeModal" @action="resetPageState" />
          </modal-action-small>
        </template>
      </button-secondary-actions>
    </template>

    <template slot="contentZoneUpper">
      <h4>{{ $t('application.applicant_info.headers.basic_info') }}</h4>
      <label-text
        :label="$t(`application.applicant_info.gender`)"
        :value="$t(`gender.${application.card_applicant.gender}`)"
        :class-array="['larger-text']"
      ></label-text>
      <label-text
        :label="$t(`application.applicant_info.first_name`)"
        :value="application.card_applicant.first_name"
        :class-array="['larger-text']"
      ></label-text>
      <label-text
        :label="$t(`application.applicant_info.last_name`)"
        :value="application.card_applicant.last_name"
        :class-array="['larger-text']"
      ></label-text>
      <label-text
        :label="$t(`application.applicant_info.date_of_birth`)"
        :value="application.card_applicant.date_of_birth"
        :class-array="['larger-text']"
      ></label-text>
      <label-text
        :label="$t(`application.applicant_info.address`)"
        :value="fullAddress"
        :class-array="['larger-text', 'applicant-info--address-field']"
      ></label-text>
    </template>

    <template slot="contentZoneMiddle">
      <h4>{{ $t('application.applicant_info.headers.occupation') }}</h4>
      <label-text
        :label="$t(`application.applicant_info.status`)"
        :value="_get(application.card_applicant, 'compliance.occupation.status.text')"
        :class-array="['applicant-info--occupation-status-field']"
      ></label-text>
      <label-text
        :label="$t(`application.applicant_info.sector`)"
        :value="_get(application.card_applicant, 'compliance.occupation.sector.text')"
        :class-array="['applicant-info--occupation-sector-field']"
      ></label-text>
      <label-text
        :label="$t(`application.applicant_info.profession`)"
        :value="_get(application.card_applicant, 'compliance.occupation.profession.text') || _get(application.card_applicant, 'occupation')"
        :class-array="['applicant-info--occupation-profession-field']"
      ></label-text>

      <h4>{{ $t('application.applicant_info.headers.contact_info') }}</h4>
      <label-text :label="$t(`application.applicant_info.phone`)" :value="application.card_applicant.phone"></label-text>
      <label-text :label="$t(`application.applicant_info.mobile`)" :value="application.card_applicant.mobile"></label-text>
      <label-text :label="$t(`application.applicant_info.email`)" :value="application.card_applicant.email"></label-text>
      <label-text
        :label="$t(`application.applicant_info.language`)"
        :value="$t(`code.application.language.${application.card_applicant.language}`)"
      ></label-text>

      <h4>{{ $t('application.applicant_info.headers.card_info') }}</h4>
      <label-text :label="$t(`application.applicant_info.product_id`)" :value="application.card_product_id"></label-text>
      <label-text :label="$t(`application.applicant_info.package_id`)" :value="application.card_package_id"></label-text>
      <label-text :label="$t(`application.applicant_info.sponsor_id`)" :value="application.partner_id"></label-text>

      <h4>{{ $t('application.applicant_info.headers.other') }}</h4>
      <label-text
        :label="$t(`application.applicant_info.compliance.intent_of_use.title`)"
        :value="
          application.card_applicant.compliance.intent_of_use
            ? $t(`application.applicant_info.compliance.intent_of_use.values.${application.card_applicant.compliance.intent_of_use}`)
            : $t(`application.applicant_info.compliance.intent_of_use.values.offers_rewards`)
        "
      ></label-text>
      <label-text
        :label="$t(`application.applicant_info.settings.alerts`)"
        :value="
          application.card_applicant.settings.alerts
            ? $t(`code.application.settings.alerts.${application.card_applicant.settings.alerts}`)
            : $t(`code.application.settings.alerts.false`)
        "
      ></label-text>
      <label-text :label="$t(`application.application_info.external_id`)" :value="application.card_application_external_id"></label-text>
    </template>
  </card-data>
</template>

<script>
import _get from 'lodash.get';
import ApproveApplicationAction from '../actions/ApproveApplicationAction';
import RejectApplicationAction from '../actions/RejectApplicationAction';
import UpdateApplicantInfoAction from '../actions/UpdateApplicantInfoAction';
import RetryKycCheckAction from '../actions/RetryKycCheckAction';
import CreateCardAction from '../actions/CreateCardAction';
import CreateChangeRequestUrl from './CreateChangeRequestUrl';
import CreateAmlNoteAction from '@/components/system/AML/CreateAmlNoteAction';
import { actionErrorTrackable, security } from '@/mixins';

export default {
  name: 'applicant-info',

  mixins: [security, actionErrorTrackable],

  components: {
    UpdateApplicantInfoAction,
    CreateAmlNoteAction,
    ApproveApplicationAction,
    RejectApplicationAction,
    RetryKycCheckAction,
    CreateCardAction,
  },

  props: {
    application: {
      type: Object,
      required: false,
    },
    cards: {
      type: Array,
      required: false,
    },
    kyc: {
      type: Object,
      required: false,
    },
  },

  data() {
    const $t = this.$t.bind(this);
    return {
      activeModal: null,
      actions: [
        {
          slotName: 'updateapplicant',
          name: $t(`application.button_update_applicant`),
          action: () => (this.activeModal = 'update_applicant'),
          isAvailable: () => this.canManageApplication() && this.application.status === 'pending',
        },
        {
          slotName: 'approve',
          name: $t(`application.button_approve`),
          action: () => (this.activeModal = 'approve'),
          isAvailable: () => this.canManageApplication() && this.application.status === 'pending',
        },
        {
          slotName: 'reject',
          name: $t(`application.button_reject`),
          action: () => (this.activeModal = 'reject'),
          isAvailable: () => this.canManageApplication() && this.application.status === 'pending',
        },
        {
          slotName: 'retrykyc',
          name: $t(`application.button_retry_kyc`),
          action: () => (this.activeModal = 'retry_kyc'),
          isAvailable: () => this.isAgentInternal() && this.retryKycConditions,
        },
        {
          slotName: 'createcard',
          name: $t(`application.button_create_card`),
          action: () => (this.activeModal = 'create_card'),
          isAvailable: () => this.isAgentInternal() && this.application.status === 'approved' && !this.application.card_public_token,
        },
      ],
    };
  },

  computed: {
    cardApplicant() {
      return this.application.card_applicant;
    },

    fullAddress() {
      return `${this.application.card_applicant.address.street}, ${this.application.card_applicant.address.city}, ${this.application.card_applicant.address.state}, ${this.application.card_applicant.address.country}, ${this.application.card_applicant.address.zip}`;
    },

    cardIssued() {
      return !!this.application.card_public_token;
    },

    retryKycConditions() {
      return this.application.status !== 'rejected' && this.application.status !== 'approved' && this.application.status !== 'voided';
    },

    activeCardPublicToken() {
      return this.cards ? this.cards[0].public_token : this.application.card_public_token;
    },
  },

  methods: {
    _get,

    closeModal() {
      this.activeModal = null;
    },

    resetPageState() {
      this.activeModal = null;
    },

    getOrCreateChangeRequest() {
      this.activeModal = 'create_change_application_request';
      this.executeAction({ action: 'selfserve/getOrCreateChangeApplicationRequest' }, this.application.card_application_id);
    },
    getChangeRequest() {
      this.activeModal = 'send_change_application_request';
      this.executeAction({ action: 'selfserve/getCurrentChangeApplicationRequest' }, this.application.card_application_id);
    },
  },
};
</script>
