<template>
  <view-main title="page_title.instant_card_projects"
    ><search-instant-card-projects-form slot="content"></search-instant-card-projects-form
  ></view-main>
</template>

<script>
import { renderErrorTrackable } from '@/mixins';
import SearchInstantCardProjectsForm from './list/SearchInstantCardProjectsForm.vue';

export default {
  name: 'instant-card-projects-view',
  mixins: [renderErrorTrackable],
  components: { SearchInstantCardProjectsForm },
};
</script>

<style scoped lang="stylus"></style>
