<template>
  <card :title="$t('user.consumer_gift_card_orders')" icon="">
    <template slot="headerZoneRight">
      <label-text
        class="text-sm-center"
        :label="$t('user.details.consumer_gift_card_orders_section.count')"
        :value="accountConsumerGiftCardOrdersItemCount.toString()"
      ></label-text>
    </template>
    <template slot="content">
      <user-consumer-gift-card-orders-table
        v-if="accountConsumerGiftCardOrders.length"
        :items="accountConsumerGiftCardOrders"
      ></user-consumer-gift-card-orders-table>
      <label-none v-else>{{ $t('user.details.consumer_gift_card_orders_section.no_found') }}</label-none>
      <div class="text-xs-center">
        <v-pagination
          v-if="accountConsumerGiftCardOrdersPageCount > 1"
          class="apps-table--pagination"
          v-model="pagination"
          :length="accountConsumerGiftCardOrdersPageCount"
        ></v-pagination>
      </div>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import UserConsumerGiftCardOrdersTable from './UserConsumerGiftCardOrdersTable';
import { mapGetters } from 'vuex';

export default {
  name: 'user-consumer-gift-card-orders',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { UserConsumerGiftCardOrdersTable },
  data() {
    return {
      pagination: 1,
    };
  },
  computed: {
    ...mapGetters('account', [
      'account',
      'accountConsumerGiftCardOrders',
      'accountConsumerGiftCardOrdersPageCount',
      'accountConsumerGiftCardOrdersPageNumber',
      'accountConsumerGiftCardOrdersItemCount',
    ]),
    ...mapGetters('ui', ['currentLocale']),
  },
  watch: {
    async account() {
      await this.fetchConsumerGiftCardOrders();
    },
    accountConsumerGiftCardOrdersPageNumber() {
      this.pagination = this.accountConsumerGiftCardOrdersPageNumber;
    },
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'account/changeAccountConsumerGiftCardOrdersPage',
          success: this.success,
        },
        {
          id: this.account.id,
          page: newValue,
        },
      );
    },
  },
  methods: {
    async fetchConsumerGiftCardOrders() {
      await this.executeAction(
        { action: 'account/listConsumerGiftCardOrdersByAccountId' },
        this.account.id,
        this.accountConsumerGiftCardOrdersPageNumber,
      );
    },
  },
};
</script>
