<template>
  <view-main title="page_title.purse_details">
    <three-levels-breadcrumbs
      slot="content"
      :top-label="$t('navigation.users')"
      :top-to="{ name: 'users' }"
      :second-label="userId"
      :second-to="{ name: 'user_details', params: { userId } }"
      :last-label="$t('navigation.my_cash')"
    />
    <purse-information slot="content" />
    <purse-operations slot="content" />
  </view-main>
</template>

<script>
import { actionErrorTrackable, renderErrorTrackable } from '@/mixins';
import PurseInformation from './detail/PurseInformation.vue';
import PurseOperations from './detail/PurseOperations.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'user-purse-detail-view',
  mixins: [renderErrorTrackable, actionErrorTrackable],
  components: { PurseInformation, PurseOperations },
  props: {
    userId: {
      type: String,
      required: true,
    },
    purseId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('purse', ['purse']),
  },
  async mounted() {
    await this.fetch();
  },
  watch: {
    async purseId() {
      return this.fetch();
    },
  },
  methods: {
    async fetch() {
      const purse = await this.executeAction({ action: 'purse/getPurse' }, this.purseId);
      if (!purse) {
        this.$router.replace({ name: 'notFound' });
      }
    },
  },
};
</script>
