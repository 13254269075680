<template>
  <card-modal
    icon="fal fa-sticky-note"
    :title="$t('aml.create_aml_note_action.title')"
    context="createAml"
    @submit="submit"
    @cancel="cancel"
    cancel_text="aml.create_aml_note_action.button_cancel"
    submit_text="aml.create_aml_note_action.button_save"
  >
    <form @submit.prevent="submit" slot="content">
      <div class="main-content">
        <v-select
          class="category"
          name="category_id"
          v-model="category_id"
          v-validate="'required'"
          :error-messages="errors.collect('category')"
          data-vv-name="category"
          :data-vv-as="$t(`aml.create_aml_note_action.category`)"
          :items="noteCategories"
          item-text="name.en"
          item-value="id"
          :label="$t(`aml.create_aml_note_action.category`)"
          data-test="create-aml-note--category"
        ></v-select>

        <v-select
          class="type"
          name="type_id"
          v-model="type_id"
          v-validate="'required'"
          :error-messages="errors.collect('type_id')"
          data-vv-name="type_id"
          :data-vv-as="$t(`aml.create_aml_note_action.type`)"
          :items="getCategoryTypes(category_id)"
          item-text="name.en"
          item-value="id"
          :label="$t(`aml.create_aml_note_action.type`)"
          data-test="create-aml-note--type"
        ></v-select>

        <v-textarea
          auto-grow
          name="aml_note"
          row-height="1"
          rows="1"
          :label="$t('aml.create_aml_note_action.aml_note')"
          v-model="aml_note"
          v-validate="'required|max:4096'"
          :error-messages="errors.collect('aml_note')"
          data-vv-name="aml_note"
          :data-vv-as="$t('aml.create_aml_note_action.aml_note')"
          counter
          required
          maxlength="4096"
          data-test="create-aml-note--note"
        ></v-textarea>
      </div>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'create-aml-note-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    accountId: {
      type: String,
      required: false,
    },
    cardApplicationId: {
      type: String,
      required: false,
    },
    publicToken: {
      type: String,
      required: false,
    },
    cardholderKey: {
      type: String,
      required: false,
    },
    // cardProgramKey must remain false, because used by both card and application!
    cardProgramKey: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      aml_note: '',
      category_id: '',
      type_id: '',
    };
  },
  async created() {
    await this.executeAction({ action: 'aml/listNoteTypes' });
    await this.executeAction({ action: 'aml/listNoteCategories' });
  },
  computed: {
    ...mapGetters('aml', ['noteCategories', 'getCategoryTypes']),
  },
  methods: {
    clear() {
      this.aml_note = '';
      this.category_id = '';
      this.type_id = '';
      this.$validator.reset();
    },
    success() {
      this.clear();
      this.$emit('action');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        const payload = {
          type_id: this.type_id,
          category_id: this.category_id,
          note: this.aml_note,
          card_program_key: this.cardProgramKey,
          cardholder_key: this.cardholderKey,
          account_id: this.accountId,
          card_application_id: this.cardApplicationId,
        };
        let actionName;

        if (this.publicToken) {
          actionName = 'aml/saveCardholderAmlNote';
          payload.public_token = this.publicToken;
        } else if (this.cardApplicationId) {
          actionName = 'aml/saveCardApplicationAmlNote';
        } else {
          actionName = 'aml/saveAccountAmlNote';
          payload.account_id = this.accountId;
        }

        await this.executeAction(
          {
            action: actionName,
            name: 'createAml',
            success: this.success,
          },
          payload,
        );
      }
    },
  },
};
</script>

<style scoped lang="stylus">
.main-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.category,
.type {
  @media screen and (min-width: 601px) {
    flex: 0 1 49%;
  }
}
</style>
