<template>
  <view-main title="page_title.publishers"><search-publishers-form slot="content"></search-publishers-form></view-main>
</template>

<script>
import { renderErrorTrackable } from '@/mixins';
import SearchPublishersForm from './list/SearchPublishersForm.vue';

export default {
  name: 'publishers-list-view',
  mixins: [renderErrorTrackable],
  components: { SearchPublishersForm },
};
</script>
