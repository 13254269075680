<template>
  <div>
    <shared-publishers-table :items="items" exclude="business" />
    <div class="text-xs-center">
      <v-pagination v-if="businessPublishersPageCount > 1" v-model="pagination" :length="businessPublishersPageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';
import SharedPublishersTable from '@/components/shared_tables/SharedPublishersTable';

export default {
  name: 'business-publishers-table',
  components: { SharedPublishersTable },
  mixins: [security, actionErrorTrackable, disableWithError],
  props: {
    id: {
      type: String,
    },
    items: {
      type: Array,
    },
  },
  data() {
    return {
      pagination: 1,
    };
  },
  mounted() {
    this.pagination = this.businessPublishersPageNumber;
  },
  watch: {
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'business/listBusinessPublishers',
        },
        {
          id: this.id,
          page: newValue,
        },
      );
    },
  },
  computed: {
    ...mapGetters('business', ['businessPublishersPageNumber', 'businessPublishersPageCount', 'businessPublishersItemCount']),
  },
};
</script>
