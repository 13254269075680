import Vue from 'vue';

const service = {
  async listPartners() {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/partner/v1/partners`);
    return response.data.data.items;
  },

  async updatePartner(id, payload) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/partner/v1/partners/${id}`, payload);
    return response.data.data;
  },

  async searchPartners(page, limit, keyword) {
    const params = {};
    if (keyword) {
      params.keyword = keyword;
    }
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath('/partner/v1/partners/search', page, limit, { keyword })}`,
    );
    return response.data.data;
  },

  async searchPartnersForMembershipSubscription(page, limit, account_id, keyword) {
    const params = {};
    if (keyword) {
      params.keyword = keyword;
    }
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/partner/v1/accounts/${account_id}/partners-for-membership-subscription`, page, limit, {
        keyword,
      })}`,
    );
    return response.data.data;
  },

  async getPartner(id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/partner/v1/partners/${id}`);
    return response.data.data;
  },

  async createPartnerImagesUploadSignedUrls(partnerId, payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/partner/v1/partners/${partnerId}/images/upload`, payload);
    return response.data.data;
  },
  async uploadImage(signedUrl, image) {
    return fetch(signedUrl, {
      method: 'PUT',
      body: image,
      headers: {
        'Content-Type': image.type,
      },
    });
  },

  async listAccountMemberships(id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/partner/v1/memberships/accounts/${id}`);
    return response.data.data.items;
  },

  async subscribeMembership(payload) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/partner/v1/memberships/subscribe`, payload);
    return response.data.data;
  },

  async unsubscribeMembership(payload) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/partner/v1/memberships/unsubscribe`, payload);
    return response.data.data;
  },

  async enableMembershipFeature({ id, feature }) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/partner/v1/memberships/${id}/features/${feature}`);
    return response.data.data;
  },

  async disableMembershipFeature({ id, feature }) {
    const response = await Vue.prototype.$axios.delete(`${process.env.VUE_APP_BASE_API_URL}/partner/v1/memberships/${id}/features/${feature}`);
    return response.data.data;
  },

  async updateMembership({ id, payload }) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/partner/v1/memberships/${id}`, payload);
    return response.data.data;
  },

  async listSpacesByBrandingId(branding_id, page, limit) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/space/v1/brandings/${branding_id}/spaces`, page, limit)}`,
    );
    return response.data.data;
  },

  async listPartnerEstablishments(partner_id, page, limit, include_deactivated = null, business_id = null) {
    const response = await Vue.prototype.$axios.get(
      generatePath(`${process.env.VUE_APP_BASE_API_URL}/partner/v1/partners/${partner_id}/establishments`, page, limit, {
        include_deactivated,
        business_id,
      }),
    );
    return response.data.data;
  },

  async searchPartnerEstablishments(page, limit, keyword = null) {
    const response = await Vue.prototype.$axios.get(
      generatePath(`${process.env.VUE_APP_BASE_API_URL}/partner/v1/partners/establishments/search`, page, limit, { keyword }),
    );
    return response.data.data;
  },

  async createPartnerEstablishment(partner_id, payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/partner/v1/partners/${partner_id}/establishments`, payload);
    return response.data.data;
  },

  async deactivatePartnerEstablishment(partner_id, id) {
    const response = await Vue.prototype.$axios.put(
      `${process.env.VUE_APP_BASE_API_URL}/partner/v1/partners/${partner_id}/establishments/${id}/deactivate`,
      {},
    );
    return response.data.data;
  },

  async activatePartnerEstablishment(partner_id, id) {
    const response = await Vue.prototype.$axios.put(
      `${process.env.VUE_APP_BASE_API_URL}/partner/v1/partners/${partner_id}/establishments/${id}/activate`,
      {},
    );
    return response.data.data;
  },

  async getPartnerEstablishment(id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/partner/v1/partners/establishments/${id}`);
    return response.data.data;
  },

  async updatePartnerEstablishment(id, payload) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/partner/v1/partners/establishments/${id}`, payload);
    return response.data.data;
  },
};

function generatePath(startPath, page, limit, { keyword, include_deactivated, business_id } = {}) {
  let path = startPath;
  if (page || limit || keyword || include_deactivated || business_id) {
    path += '?';
  }

  const queryStrings = [];
  if (page) {
    queryStrings.push(['page', page]);
  }
  if (limit) {
    queryStrings.push(['limit', limit]);
  }
  if (keyword) {
    queryStrings.push(['keyword', keyword]);
  }
  if (include_deactivated) {
    queryStrings.push(['include_deactivated', include_deactivated]);
  }
  if (business_id) {
    queryStrings.push(['business_id', business_id]);
  }

  const params = new URLSearchParams(queryStrings);
  return path + params.toString();
}

export default service;
