<template>
  <card-modal
    icon=""
    :title="$t('user.deactivate_account_action.title')"
    context="deactivateAccount"
    @submit="openConfirmModal"
    @cancel="cancel"
    cancel_text="user.deactivate_account_action.button_cancel"
    submit_text="user.deactivate_account_action.button_submit"
    :submit_working="working"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-layout row wrap>
          <v-text-field
            v-validate="'required|max:256'"
            data-vv-name="reason"
            :error-messages="errors.collect('reason')"
            v-model="reason"
            :label="$t('user.deactivate_account_action.form.reason.label') + ' *'"
            counter="256"
            maxlength="256"
          ></v-text-field>
        </v-layout>
      </v-layout>

      <modal-confirm
        :show-modal="showConfirmModal"
        :title="$t('user.deactivate_account_action.confirm.title')"
        context="deactivateAccount"
        :text="
          openPurseAvailableBalance > 0
            ? $t('user.deactivate_account_action.confirm.text_with_open_purse', {
                id: user.id,
                mail: user.contact.email.value,
                openPurseAvailableBalance: this.$options.filters.currency(openPurseAvailableBalance),
              })
            : $t('user.deactivate_account_action.confirm.text', { id: user.id, mail: user.contact.email.value })
        "
        cancel_text="user.deactivate_account_action.confirm.button_cancel"
        submit_text="user.deactivate_account_action.confirm.button_submit"
        :submit_working="working"
        @submit="submit"
        @close="closeConfirmModal"
      ></modal-confirm>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';

export default {
  name: 'deactivate-action',
  $_veeValidate: {
    validator: 'new',
  },
  mixins: [actionErrorTrackable, security],
  props: {
    user: {
      type: Object,
      required: true,
    },
    openPurseAvailableBalance: {
      type: Number,
      required: true,
    },
  },
  data: function() {
    return {
      reason: '',
      showConfirmModal: false,
    };
  },
  methods: {
    clear() {
      this.resetForm();
      this.$validator.reset();
    },
    resetForm() {
      this.reason = '';
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    success() {
      this.clear();
      this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'user.deactivate_account_action.success' });
      this.closeConfirmModal();
      this.$emit('action');
      this.$emit('close');
    },
    error() {
      this.closeConfirmModal();
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        const params = {
          reason: this.reason,
        };

        await this.executeAction(
          {
            action: 'account/deactivateAccount',
            name: 'deactivateAccount',
            success: this.success,
            afterError: this.error,
          },
          params,
        );
      }
    },
    async openConfirmModal() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        this.showConfirmModal = true;
      }
    },
    closeConfirmModal() {
      this.showConfirmModal = false;
    },
  },
};
</script>
