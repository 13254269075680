<template>
  <card :title="$t('inventory.details.inventory_information_section.title')" icon="fal fa-funnel-dollar" id="inventory-informations">
    <template slot="headerZoneRight">
      <label-text :label="`${$t('inventory.details.inventory_information_section.id')}`" :value="inventory.id">
        <p slot="labelTextLink" class="label-text--value">
          <v-layout row>
            {{ inventory.id }}
            <button class="clipboard" v-clipboard:copy="inventory.id ? inventory.id : ''">
              <v-icon small>file_copy</v-icon>
            </button>
          </v-layout>
        </p>
      </label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>

      <label-text :label="`${$t('inventory.details.inventory_information_section.name')}`" :value="inventory.name"></label-text>
      <div v-if="showUpdateInventoryFeature()" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>

      <label-text :label="`${$t('inventory.details.inventory_information_section.external_id')}`" :value="inventory.external_id">
        <p slot="labelTextLink" class="label-text--value" :class="{ 'label-text-na': !inventory.external_id }">
          <v-layout row>
            {{ inventory.external_id | orNotAvailable }}
            <button v-if="inventory.external_id" class="clipboard" v-clipboard:copy="inventory.external_id ? inventory.external_id : ''">
              <v-icon small>file_copy</v-icon>
            </button>
          </v-layout>
        </p>
      </label-text>

      <div v-if="canManageOffer()" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <link-secondary-action slot="labelTextLink">
        <button-secondary-actions class="application-info--actions" :text="$t(`system.actions`)" :actions="actions"> </button-secondary-actions>
      </link-secondary-action>
    </template>
    <template slot="content">
      <modal-action :show-modal="showUpdateInventoryModal">
        <update-inventory-action
          v-if="showUpdateInventoryModal"
          :inventory="inventory"
          @close="closeUpdateInventoryModal"
          @updated="updateInventorySuccess"
        >
        </update-inventory-action
      ></modal-action>
      <modal-action :show-modal="showAdjustInventoryModal">
        <adjust-inventory-action
          v-if="showAdjustInventoryModal"
          :inventory="inventory"
          @close="closeAdjustInventoryModal"
          @updated="adjustInventorySuccess"
        >
        </adjust-inventory-action
      ></modal-action>
      <modal-action :show-modal="showClearInventoryModal">
        <clear-inventory-action v-if="showClearInventoryModal" :id="inventory.id" @close="closeClearInventoryModal" @updated="clearInventorySuccess">
        </clear-inventory-action
      ></modal-action>
      <v-layout row>
        <v-flex xs3
          ><label-text
            :label="$t(`inventory.details.inventory_information_section.initial_amount`)"
            :value="inventory.initial_amount | currency | dollarSignI18n"
          ></label-text
        ></v-flex>
        <v-flex xs3
          ><label-text
            :label="$t(`inventory.details.inventory_information_section.current_amount`)"
            :value="inventory.current_amount | currency | dollarSignI18n"
          ></label-text
        ></v-flex>
        <v-flex xs3
          ><label-text
            :label="$t(`inventory.details.inventory_information_section.available_amount`)"
            :value="inventory.available_amount | currency | dollarSignI18n"
          ></label-text
        ></v-flex>
      </v-layout>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import UpdateInventoryAction from '../actions/UpdateInventoryAction';
import AdjustInventoryAction from '../actions/AdjustInventoryAction';
import ClearInventoryAction from '../actions/ClearInventoryAction';
import { mapGetters } from 'vuex';

export default {
  name: 'inventory-information',
  components: { UpdateInventoryAction, AdjustInventoryAction, ClearInventoryAction },
  mixins: [security, actionErrorTrackable, disableWithError],
  data() {
    const $t = this.$t.bind(this);
    return {
      showUpdateInventoryModal: false,
      showAdjustInventoryModal: false,
      showClearInventoryModal: false,
      actions: [
        {
          slotName: 'editInventory',
          name: $t('inventory.details.inventory_information_section.update_inventory'),
          action: () => this.openUpdateInventoryModal(),
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => !this.showUpdateInventoryFeature(),
        },
        {
          slotName: 'adjustInventory',
          name: $t('inventory.details.inventory_information_section.adjust_inventory'),
          action: () => this.openAdjustInventoryModal(),
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => !this.showUpdateInventoryFeature(),
        },
        {
          slotName: 'clearInventory',
          name: $t('inventory.details.inventory_information_section.clear_inventory'),
          action: () => this.openClearInventoryModal(),
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => !this.showClearInventoryFeature(),
        },
      ],
    };
  },
  methods: {
    openUpdateInventoryModal() {
      this.showUpdateInventoryModal = true;
    },
    closeUpdateInventoryModal() {
      this.showUpdateInventoryModal = false;
    },
    async updateInventorySuccess() {
      await this.executeAction(
        { action: 'ui/showSuccessSnackBar' },
        { text: 'inventory.details.inventory_information_section.update_action.success' },
      );
      this.closeUpdateInventoryModal();
    },
    showUpdateInventoryFeature() {
      return this.canManageOffer(); // TODO: change permission
    },
    openAdjustInventoryModal() {
      this.showAdjustInventoryModal = true;
    },
    closeAdjustInventoryModal() {
      this.showAdjustInventoryModal = false;
    },
    async adjustInventorySuccess() {
      await this.executeAction(
        { action: 'ui/showSuccessSnackBar' },
        { text: 'inventory.details.inventory_information_section.adjust_action.success' },
      );
      this.closeAdjustInventoryModal();
    },
    showAdjustInventoryFeature() {
      return this.canManageOffer(); // TODO: change permission
    },
    openClearInventoryModal() {
      this.showClearInventoryModal = true;
    },
    closeClearInventoryModal() {
      this.showClearInventoryModal = false;
    },
    async clearInventorySuccess() {
      await this.executeAction(
        { action: 'ui/showSuccessSnackBar' },
        { text: 'inventory.details.inventory_information_section.clear_action.success' },
      );
      this.closeClearInventoryModal();
    },
    showClearInventoryFeature() {
      return this.canManageOffer() && this.inventory.available_amount > 0; // TODO: change permission
    },
  },
  computed: {
    ...mapGetters('inventory', ['types', 'inventory']),
    ...mapGetters('ui', ['currentLocale']),
  },
};
</script>
