import Vue from 'vue';

const service = {
  async getPurse(id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/virtual-money/v1/purses/${id}`);
    return response.data.data;
  },
  async getOpenPurse(accountId) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/virtual-money/v1/accounts/${accountId}/open-purse`);
    return response.data.data;
  },
  async blockPurse(id) {
    await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/virtual-money/v1/purses/${id}/block`);
  },
  async activatePurse(id) {
    await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/virtual-money/v1/purses/${id}/activate`);
  },
  async loadCredit(id, payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/virtual-money/v1/purses/${id}/load`, payload);
    return response.data.data;
  },
  async loadOpenPurse(accountId, payload) {
    const response = await Vue.prototype.$axios.post(
      `${process.env.VUE_APP_BASE_API_URL}/virtual-money/v1/accounts/${accountId}/open-purse/load`,
      payload,
    );
    return response.data.data;
  },
  async createPurse(payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/virtual-money/v1/purses`, payload);
    return response.data.data;
  },
  async listPurseOperations(id, page, limit, excluded_type = []) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/virtual-money/v1/purses/${id}/operations`, page, limit, excluded_type)}`,
    );
    return response.data.data;
  },
  async emptyOpenPurseByAccountId(account_id, payload) {
    const response = await Vue.prototype.$axios.post(
      `${process.env.VUE_APP_BASE_API_URL}/virtual-money/v1/accounts/${account_id}/open-purse/empty`,
      payload,
    );
    return response.data.data;
  },
  async purseDebitAdjustment(id, payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/virtual-money/v1/purses/${id}/adjustment/debit`, payload);
    return response.data.data;
  },
  async purseCreditAdjustment(id, payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/virtual-money/v1/purses/${id}/adjustment/credit`, payload);
    return response.data.data;
  },
};

function generatePath(startPath, page, limit, excluded_type = []) {
  let path = startPath;
  if (page || limit || excluded_type.length) {
    path += '?';
  }

  const queryStrings = [];
  if (page) {
    queryStrings.push(['page', page]);
  }
  if (limit) {
    queryStrings.push(['limit', limit]);
  }
  if (excluded_type.length) {
    queryStrings.push(['excluded_type', excluded_type]);
  }

  const params = new URLSearchParams(queryStrings);
  return path + params.toString();
}

export default service;
