import _isObject from 'lodash/isObject';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';

function displayField(field) {
  if (_isObject(field) && _isEmpty(field)) {
    return false;
  }
  return field;
}

export default {
  orderChanges(changes, fieldOrder, vueInstance) {
    const orderedChanges = [];

    for (const order of fieldOrder) {
      _forEach(changes, (change, index) => {
        if (displayField(change) && index === order) {
          orderedChanges.push(vueInstance.$t(`change_request_history_section.fields.${index}`));
        }
      });
    }
    return orderedChanges.join(', ');
  },
  buildChangeRequestUrl(token) {
    return `${process.env.VUE_APP_SELFSERVE_CONSOLE_URL}/application/${token}`;
  },
};
