<template>
  <card-modal
    icon="fal fa-store-alt"
    :title="$t('offers.details.publishers_section.update_inventory_limit_for_all_action.title')"
    context="updateInventoryLimitForAllOfferPublishers"
    @submit="submit"
    @cancel="cancel"
    cancel_text="offers.details.publishers_section.update_inventory_limit_for_all_action.button_cancel"
    submit_text="offers.details.publishers_section.update_inventory_limit_for_all_action.button_save"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-text-field
          autofocus
          :label="$t('offers.details.publishers_section.update_inventory_limit_for_all_action.inventory_limit')"
          name="inventory_limit"
          append-icon="fas fa-dollar-sign"
          v-validate="{
            required: true,
            integer: true,
            min_value: 0,
          }"
          :error-messages="errors.collect('inventory_limit')"
          v-model="currentOffer.inventory_limit"
          data-vv-name="inventory_limit"
          maxlength="50"
          :data-vv-as="$t('offers.details.publishers_section.update_inventory_limit_for_all_action.inventory_limit')"
        ></v-text-field>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapActions } from 'vuex';

export default {
  name: 'update-inventory-limit-for-all-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    offer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentOffer: {},
    };
  },
  async created() {
    this.currentOffer = Object.assign({}, this.offer);
  },
  methods: {
    ...mapActions('ui', ['addError', 'clearAllError', 'clearError']),
    clear() {
      this.currentOffer = {};
      this.$validator.reset();
    },
    success(response) {
      this.clear();
      this.$emit('updated');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      let validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        await this.executeAction(
          {
            action: 'offers/updateInventoryLimitForAllOfferPublishers',
            name: 'updateInventoryLimitForAllOfferPublishers',
            success: this.success,
          },
          {
            offer_id: this.offer.id,
            inventory_limit: this.currentOffer.inventory_limit,
          },
        );
      }
    },
  },
};
</script>
