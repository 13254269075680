<template>
  <card-modal
    icon=""
    :title="$t('publishers.details.stores_section.add_stores_from_destination_action.title')"
    context="addStoresFromPublisher"
    @submit="validate"
    @cancel="cancel"
    cancel_text="publishers.details.stores_section.add_stores_from_destination_action.button_cancel"
    submit_text="publishers.details.stores_section.add_stores_from_destination_action.button_submit"
    :enable_submit="destination && destinationStatsStoreCount > 0"
    :submit_working="working"
  >
    <form @submit.prevent="validate" slot="content">
      <v-layout column>
        <modal-info-block
          :heading="$t('publishers.details.stores_section.add_stores_from_destination_action.publisher')"
          :text="publisherInfo"
        ></modal-info-block>
        <h-autocomplete
          autofocus
          v-model="destination"
          :label="$t('publishers.details.stores_section.add_stores_from_destination_action.destination') + '*'"
          v-validate="'required'"
          :error-messages="errors.collect('destination')"
          name="destination"
          data-vv-name="destination"
          data-vv-as="destination"
          search-action="destination/autocompleteSearch"
          data-test="add-destination-stores"
          no-filter
        >
        </h-autocomplete>
        <div v-if="destination">
          <label-text
            :label="$t('publishers.details.stores_section.add_stores_from_destination_action.store_count')"
            :value="destinationStatsStoreCount ? destinationStatsStoreCount.toString() : ''"
          />
        </div>
        <modal-confirm
          :show-modal="showConfirmModal"
          context="AddStoresFromPublisher"
          :title="$t('publishers.details.stores_section.add_stores_from_destination_action.confirm.title')"
          :text="
            $t('publishers.details.stores_section.add_stores_from_destination_action.confirm.text', {
              storeCount: destinationStatsStoreCount ? destinationStatsStoreCount.toString() : '',
              publisherId: publisherObj.id,
            })
          "
          cancel_text="publishers.details.stores_section.add_stores_from_offer_action.confirm.button_cancel"
          submit_text="publishers.details.stores_section.add_stores_from_offer_action.confirm.button_submit"
          :submit_working="working"
          @submit="submit"
          @close="closeConfirmModal"
        ></modal-confirm>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'add-stores-from-destination-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    publisherObj: {
      type: Object,
    },
  },
  data() {
    return {
      showConfirmModal: false,
      destination: null,
    };
  },
  watch: {
    async destination(val) {
      if (val) {
        await this.getDestinationStatistics(val);
      }
    },
  },
  computed: {
    ...mapGetters('publishers', ['publisherPublishers']),
    ...mapGetters('destination', ['destinationStatsStoreCount']),
    publisherInfo() {
      return `${this.publisherObj.name} (${this.publisherObj.id})`;
    },
  },
  methods: {
    clear() {
      this.destination = null;
      this.$validator.reset();
    },
    async success() {
      this.clear();
      this.$emit('storesAdded');
      await this.executeAction(
        {
          action: 'publishers/getPublisher',
        },
        this.publisherObj.id,
      );
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    closeConfirmModal() {
      this.showConfirmModal = false;
    },
    openConfirmModal() {
      this.showConfirmModal = true;
    },
    async getDestinationStatistics(destinationId) {
      await this.executeAction(
        {
          action: 'destination/getDestinationStatistics',
          name: 'getDestinationStatistics',
        },
        destinationId,
      );
    },
    async validate() {
      const validationComplete = await this.$validator.validateAll();

      if (!validationComplete) {
        return;
      }

      if (!this.destination) {
        return;
      }

      this.openConfirmModal();
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (!validationComplete) {
        return;
      }

      if (!this.destination) {
        return;
      }

      const payload = { to_publisher_id: this.publisherObj.id, from_destination_id: this.destination };

      await this.executeAction(
        {
          action: 'publishers/addStoresFromDestination',
          name: 'addStoresFromDestination',
          success: this.success,
        },
        payload,
      );
    },
  },
};
</script>
