<template>
  <card-modal
    icon=""
    :title="$t('inventory.update_action.title')"
    context="updateInventory"
    @submit="submit"
    @cancel="cancel"
    cancel_text="inventory.update_action.button_cancel"
    submit_text="inventory.update_action.button_save"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-text-field
          autofocus
          :label="$t('inventory.update_action.name') + '*'"
          name="name"
          v-validate="'required|max:100'"
          :error-messages="errors.collect('name')"
          v-model="currentInventory.name"
          data-vv-name="name"
          maxlength="100"
          :data-vv-as="$t('inventory.update_action.name')"
          required
        ></v-text-field>
        <v-text-field
          type="text"
          name="external_id"
          v-validate="'max:50'"
          :error-messages="errors.collect('external_id')"
          v-model="currentInventory.external_id"
          :label="$t('inventory.update_action.external_id')"
        ></v-text-field>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'update-inventory-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    inventory: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentInventory: {},
    };
  },
  async created() {
    this.currentInventory = _.cloneDeep(this.inventory);
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    clear() {
      this.currentInventory = {};
      this.$validator.reset();
    },
    success(response) {
      this.clear();
      this.$emit('updated');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();
      if (validationComplete) {
        await this.executeAction(
          {
            action: 'inventory/updateInventory',
            name: 'updateInventory',
            success: this.success,
          },
          this.buildPayload(),
        );
      }
    },
    buildPayload() {
      const payload = Object.assign({}, _.omit(this.currentInventory, ['creation_date', 'initial_amount']));
      if (!this.currentInventory.external_id) {
        payload.external_id = null;
      }
      return payload;
    },
  },
};
</script>
