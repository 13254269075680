import Vue from 'vue';
import { safeDispatcher } from '@/utils/context/context-helper';

export default {
  namespaced: true,
  state: {
    authenticated: false,
    currentUser: {},
    currentAgent: {},
    currentPermissions: [],
    currentCardProgramKey: null,
    startupPath: null,
    appNames: {},
  },
  getters: {
    authenticated: (state) => state.authenticated,
    currentAgent: (state) => state.currentAgent,
    currentUser: (state) => state.currentUser,
    currentPermissions: (state) => state.currentPermissions,
    currentCardProgramKey: (state) => state.currentCardProgramKey,
    hasPermission: (state) => (permission) => state.currentPermissions.includes(permission),
    startupPath: (state) => state.startupPath,
  },
  mutations: {
    authenticated(state, authenticated) {
      state.authenticated = authenticated;
    },
    currentUser(state, user) {
      state.currentUser = user;
      if (user && user.email) {
        rg4js('setUser', {
          identifier: user.email,
          isAnonymous: false,
          email: user.email,
        });
      } else {
        rg4js('setUser', {
          isAnonymous: true,
        });
      }
    },
    currentAgent(state, agent) {
      state.currentAgent = agent;
    },
    currentPermissions(state, permissions) {
      state.currentPermissions = permissions;
    },
    startupPath(state, startupPath) {
      state.startupPath = startupPath;
    },
    appNames(state, appNames) {
      state.appNames = appNames;
    },
    currentCardProgramKey(state, cardProgramKey) {
      state.currentCardProgramKey = cardProgramKey;
    },
  },
  actions: {
    async getAppName({ state, commit }, key) {
      const appService = Vue.prototype.$services.app;

      if (state.appNames[key]) {
        return state.appNames[key];
      }

      try {
        const app = await appService.getAppName(key);
        if (app.apps) {
          state.appNames[app.apps[0].key] = app.apps[0].name;
          commit('appNames', state.appNames);
          return app.apps[0].name;
        }
      } catch (e) {
        console.log(e); // eslint-disable-line no-console
        return null;
      }

      return null;
    },
    async login({ commit, dispatch }, user) {
      const securityService = Vue.prototype.$services.security;

      commit('authenticated', true);
      commit('currentUser', user);

      Vue.prototype.$axios.defaults.headers.common.Authorization = `Bearer ${await Vue.prototype.$auth.getAccessToken()}`;

      const agent = await securityService.me();
      if (agent) {
        commit('currentAgent', agent);
        commit('currentPermissions', agent.permissions);
        commit('currentCardProgramKey', process.env.VUE_APP_DEFAULT_PROGRAM_KEY);

        const redirectPath = Vue.prototype.$cookies.getRedirectPathCookie();

        let startupPath = '/no-access';
        if (redirectPath) {
          console.log('Redirect to', redirectPath); // eslint-disable-line no-console
          startupPath = redirectPath;
        } else {
          if (agent.permissions.includes('view_offer')) {
            startupPath = '/users';
          } else if (agent.permissions.includes('view_task')) {
            startupPath = '/task';
          } else if (agent.permissions.includes('view_application')) {
            startupPath = '/application';
          } else if (agent.permissions.includes('view_card')) {
            startupPath = '/card';
          } else if (agent.permissions.includes('view_wallet_definition')) {
            startupPath = '/wallet-definition';
          } else if (agent.permissions.includes('view_offer')) {
            startupPath = '/offer';
          }
        }

        Vue.prototype.$cookies.deleteRedirectPathCookie();
        commit('startupPath', startupPath);

        const defaultDispatch = safeDispatcher(dispatch);
        await defaultDispatch('program/getLimits', process.env.VUE_APP_DEFAULT_PROGRAM_KEY);
      }
    },

    async logout({ commit, dispatch }) {
      try {
        await Vue.prototype.$auth.logout();
      } catch (err) {
        console.log('CATCH ERROR', err); // eslint-disable-line no-console
      }
      commit('authenticated', false);
      commit('currentUser', {});
      commit('currentPermissions', []);
      commit('currentAgent', {});

      const defaultDispatch = safeDispatcher(dispatch);
      await defaultDispatch('program/clear');
      Vue.prototype.$axios.defaults.headers.common.Authorization = null;
    },

    async checkSecurityStatus({ state, dispatch }) {
      const authenticated = await Vue.prototype.$auth.isAuthenticated();
      if (authenticated) {
        if (!state.authenticated) {
          await dispatch('login', await Vue.prototype.$auth.getUser());
        }
      } else {
        if (state.authenticated) {
          // eslint-disable-line no-lonely-if
          await dispatch('logout');
        }
      }
    },
  },
};
