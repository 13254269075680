<template>
  <div>
    <layout-notes :items="items" @delete="deleteNote" />
    <div class="text-xs-center">
      <v-pagination v-if="listStoreNotesPageCount > 1" v-model="pagination" :length="listStoreNotesPageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'store-notes-table',
  mixins: [actionErrorTrackable, security],
  props: {
    items: {
      type: Array,
    },
    storeId: {
      type: String,
    },
  },
  data() {
    return {
      pagination: 1,
    };
  },
  mounted() {
    this.pagination = this.listStoreNotesPageNumber;
  },
  watch: {
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'store/listStoreNotes',
        },
        {
          store_id: this.storeId,
          page: newValue,
        },
      );
    },
  },
  computed: {
    ...mapGetters('store', ['listStoreNotesPageNumber', 'listStoreNotesPageCount', 'listStoreNotesItemCount']),
  },
  methods: {
    deleteNote(noteId) {
      this.$emit('store_note_delete_action', noteId);
    },
  },
};
</script>
