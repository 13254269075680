<template>
  <card-modal
    icon=""
    :title="$t('instant_card_projects.update_action.title')"
    context="updateProject"
    @submit="submit"
    @cancel="cancel"
    cancel_text="instant_card_projects.update_action.button_cancel"
    submit_text="instant_card_projects.update_action.button_save"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-text-field
          autofocus
          :label="$t('instant_card_projects.update_action.name') + '*'"
          name="name"
          v-validate="'required|max:100'"
          :error-messages="errors.collect('name')"
          v-model="currentProject.name"
          data-vv-name="name"
          maxlength="100"
          :data-vv-as="$t('instant_card_projects.update_action.name')"
          data-test="update-project--name-fr"
          required
        ></v-text-field>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'update-project-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentProject: {},
    };
  },
  async mounted() {
    this.currentProject = _.cloneDeep(this.project);
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    clear() {
      this.currentProject = {};
      this.$validator.reset();
    },
    success(response) {
      this.clear();
      this.$emit('updated');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();
      if (validationComplete) {
        await this.executeAction(
          {
            action: 'instantCardProject/updateProject',
            name: 'updateProject',
            success: this.success,
          },
          this.currentProject,
        );
      }
    },
  },
};
</script>
