import Vue from 'vue';
import _ from 'lodash';

const defaultPage = 1;
const paginationLimit = 20;
const businessStoresPaginationLimit = 10;
const businessOffersPaginationLimit = 10;
const businessOwnerOffersPaginationLimit = 10;
const businessPublishersPaginationLimit = 10;
const businessActivationCodeBatchesPaginationLimit = 10;
const businessNotesPaginationLimit = 20;
const businessWebhooksPaginationLimit = 20;

export default {
  namespaced: true,
  state: {
    businesses: [],
    business: { system_status: {}, address: {}, contact: {} },
    businessStores: [],
    businessPublishers: [],
    businessOffers: [],
    businessOwnerOffers: [],
    businessActivationCodeBatches: [],
    businessWebhooks: [],
    reportAccesses: [],
    business_notes: [],
    list: {
      keyword: '',
      pageNumber: 1,
      pageCount: 0,
      itemCount: 0,
    },
    listBusinessStores: {
      pageNumber: 1,
      pageCount: 0,
      itemCount: 0,
    },
    listBusinessPublishers: {
      pageNumber: 1,
      pageCount: 0,
      itemCount: 0,
    },
    listBusinessOwnerOffers: {
      pageNumber: 1,
      pageCount: 0,
      itemCount: 0,
    },
    listBusinessOffers: {
      pageNumber: 1,
      pageCount: 0,
      itemCount: 0,
    },
    listBusinessActivationCodeBatches: {
      pageNumber: 1,
      pageCount: 0,
      itemCount: 0,
    },
    listBusinessWebhooks: {
      pageNumber: 1,
      pageCount: 0,
      itemCount: 0,
    },
    business_notes_list: {
      pageNumber: 1,
      pageCount: 0,
      itemCount: 0,
    },
  },
  getters: {
    business: (state) => state.business,
    businesses: (state) => state.businesses,
    reportAccesses: (state) => state.reportAccesses,
    listKeyword: (state) => state.list.keyword,
    listPageCount: (state) => state.list.pageCount,
    listItemCount: (state) => state.list.itemCount,
    listPageNumber: (state) => state.list.pageNumber,

    businessStores: (state) => state.businessStores,
    businessStoresPageNumber: (state) => state.listBusinessStores.pageNumber,
    businessStoresPageCount: (state) => state.listBusinessStores.pageCount,
    businessStoresItemCount: (state) => state.listBusinessStores.itemCount,

    businessPublishers: (state) => state.businessPublishers,
    businessPublishersPageNumber: (state) => state.listBusinessPublishers.pageNumber,
    businessPublishersPageCount: (state) => state.listBusinessPublishers.pageCount,
    businessPublishersItemCount: (state) => state.listBusinessPublishers.itemCount,

    businessOffers: (state) => state.businessOffers,
    businessOffersPageNumber: (state) => state.listBusinessOffers.pageNumber,
    businessOffersPageCount: (state) => state.listBusinessOffers.pageCount,
    businessOffersItemCount: (state) => state.listBusinessOffers.itemCount,

    businessOwnerOffers: (state) => state.businessOwnerOffers,
    businessOwnerOffersPageNumber: (state) => state.listBusinessOwnerOffers.pageNumber,
    businessOwnerOffersPageCount: (state) => state.listBusinessOwnerOffers.pageCount,
    businessOwnerOffersItemCount: (state) => state.listBusinessOwnerOffers.itemCount,

    businessActivationCodeBatches: (state) => state.businessActivationCodeBatches,
    businessActivationCodeBatchesPageNumber: (state) => state.listBusinessActivationCodeBatches.pageNumber,
    businessActivationCodeBatchesPageCount: (state) => state.listBusinessActivationCodeBatches.pageCount,
    businessActivationCodeBatchesItemCount: (state) => state.listBusinessActivationCodeBatches.itemCount,

    businessWebhooks: (state) => state.businessWebhooks,
    businessWebhooksPageNumber: (state) => state.listBusinessWebhooks.pageNumber,
    businessWebhooksPageCount: (state) => state.listBusinessWebhooks.pageCount,
    businessWebhooksItemCount: (state) => state.listBusinessWebhooks.itemCount,

    businessNotes: (state) => state.business_notes,
    listBusinessNotesPageCount: (state) => state.business_notes_list.pageCount,
    listBusinessNotesItemCount: (state) => state.business_notes_list.itemCount,
    listBusinessNotesPageNumber: (state) => state.business_notes_list.pageNumber,
  },
  mutations: {
    businesses(state, businesses) {
      state.businesses = businesses;
    },
    business(state, business) {
      if (!business) {
        business = { system_status: {}, address: {}, contact: {} };
      }
      state.business = business;
    },
    reportAccess(state, reportAccess) {
      state.reportAccesses.push(reportAccess);
    },
    reportAccesses(state, reportAccesses) {
      state.reportAccesses = _.sortBy(reportAccesses, 'email');
    },
    listKeyword(state, keyword) {
      state.list.keyword = keyword;
    },
    listPageCount(state, pageCount) {
      state.list.pageCount = pageCount;
    },
    listItemCount(state, itemCount) {
      state.list.itemCount = itemCount;
    },
    listPage(state, page) {
      state.list.pageNumber = page;
    },
    businessStores(state, stores) {
      state.businessStores = stores;
    },
    businessStoresPageCount(state, pageCount) {
      state.listBusinessStores.pageCount = pageCount;
    },
    businessStoresItemCount(state, itemCount) {
      state.listBusinessStores.itemCount = itemCount;
    },
    businessStoresPage(state, page) {
      state.listBusinessStores.pageNumber = page;
    },
    businessPublishers(state, stores) {
      state.businessPublishers = stores;
    },
    businessPublishersPageCount(state, pageCount) {
      state.listBusinessPublishers.pageCount = pageCount;
    },
    businessPublishersItemCount(state, itemCount) {
      state.listBusinessPublishers.itemCount = itemCount;
    },
    businessPublishersPage(state, page) {
      state.listBusinessPublishers.pageNumber = page;
    },
    businessOffers(state, offers) {
      state.businessOffers = offers;
    },
    businessOffersPageCount(state, pageCount) {
      state.listBusinessOffers.pageCount = pageCount;
    },
    businessOffersItemCount(state, itemCount) {
      state.listBusinessOffers.itemCount = itemCount;
    },
    businessOffersPage(state, page) {
      state.listBusinessOffers.pageNumber = page;
    },
    businessOwnerOffers(state, offers) {
      state.businessOwnerOffers = offers;
    },
    businessOwnerOffersPageCount(state, pageCount) {
      state.listBusinessOwnerOffers.pageCount = pageCount;
    },
    businessOwnerOffersItemCount(state, itemCount) {
      state.listBusinessOwnerOffers.itemCount = itemCount;
    },
    businessOwnerOffersPage(state, page) {
      state.listBusinessOwnerOffers.pageNumber = page;
    },
    businessActivationCodeBatches(state, activationCodeBatches) {
      state.businessActivationCodeBatches = activationCodeBatches;
    },
    businessActivationCodeBatchesPageCount(state, pageCount) {
      state.listBusinessActivationCodeBatches.pageCount = pageCount;
    },
    businessActivationCodeBatchesItemCount(state, itemCount) {
      state.listBusinessActivationCodeBatches.itemCount = itemCount;
    },
    businessActivationCodeBatchesPage(state, page) {
      state.listBusinessActivationCodeBatches.pageNumber = page;
    },
    businessWebhooks(state, webhooks) {
      state.businessWebhooks = webhooks;
    },
    businessWebhooksPageCount(state, pageCount) {
      state.listBusinessWebhooks.pageCount = pageCount;
    },
    businessWebhooksItemCount(state, itemCount) {
      state.listBusinessWebhooks.itemCount = itemCount;
    },
    businessWebhooksPage(state, page) {
      state.listBusinessWebhooks.pageNumber = page;
    },
    businessNotes(state, notes) {
      state.business_notes = notes;
    },
    listBusinessNotesPageCount(state, pageCount) {
      state.business_notes_list.pageCount = pageCount;
    },
    listBusinessNotesItemCount(state, itemCount) {
      state.business_notes_list.itemCount = itemCount;
    },
    listBusinessNotesPage(state, page) {
      state.business_notes_list.pageNumber = page;
    },
  },
  actions: {
    async autocompleteSearch({}, { page, limit, keyword }) {
      const businessService = Vue.prototype.$services.business;

      return businessService.searchBusinesses(page, limit, keyword);
    },
    async autocompleteSearchWithWebhooks({}, { page, limit, keyword }) {
      const businessService = Vue.prototype.$services.business;

      return businessService.searchBusinessWithWebhooks(page, limit, keyword);
    },
    async createBusiness({ commit }, params) {
      const businessService = Vue.prototype.$services.business;
      return businessService.createBusiness(params);
    },
    async listBusinesses({ state, commit }, { keyword, page, limit } = {}) {
      const businessService = Vue.prototype.$services.business;
      const thePage = page || state.list.pageNumber || defaultPage;
      const theLimit = limit || paginationLimit;
      const theKeyword = keyword || '';

      const listResults = await businessService.listBusinesses(thePage, theLimit, theKeyword);

      commit('listKeyword', theKeyword);
      commit('listPage', thePage);
      commit('listPageCount', listResults.page_count);
      commit('listItemCount', listResults.item_count);
      commit('businesses', listResults.items);
    },
    async createBusinessNote({ dispatch }, { business_id, note }) {
      const businessService = Vue.prototype.$services.business;
      await businessService.createBusinessNote(business_id, { note });
      await dispatch('listBusinessNotes', { business_id });
    },
    async deleteBusinessNote({ dispatch }, { business_id, note_id, reason }) {
      const businessService = Vue.prototype.$services.business;
      await businessService.deleteBusinessNote(business_id, note_id, { reason });
      await dispatch('listBusinessNotes', { business_id });
    },
    async listBusinessNotes({ state, commit }, { business_id, page, limit } = {}) {
      const businessService = Vue.prototype.$services.business;
      const thePage = page || state.business_notes_list.pageNumber;
      const theLimit = limit || businessNotesPaginationLimit;
      const theBusinessId = business_id || '';

      const listResults = await businessService.listBusinessNotes(thePage, theLimit, theBusinessId);

      commit('listBusinessNotesPage', thePage);
      commit('listBusinessNotesPageCount', listResults.page_count);
      commit('listBusinessNotesItemCount', listResults.item_count);
      commit('businessNotes', listResults.items);
    },
    async listBusinessStores({ state, commit }, { id, page, limit } = {}) {
      const businessService = Vue.prototype.$services.business;
      const thePage = page || state.listBusinessStores.pageNumber || defaultPage;
      const theLimit = limit || businessStoresPaginationLimit;
      const theSortOrder = 'creation_date';

      const listResults = await businessService.listBusinessStores(id, theSortOrder, thePage, theLimit);

      commit('businessStores', listResults.items);
      commit('businessStoresPage', thePage);
      commit('businessStoresPageCount', listResults.page_count);
      commit('businessStoresItemCount', listResults.item_count);
    },
    async changeBusinessStoresPage({ commit, state }, { id, page }) {
      commit('businessStoresPage', page);

      const businessService = Vue.prototype.$services.business;
      const limit = businessStoresPaginationLimit;
      const theSortOrder = 'creation_date';

      const result = await businessService.listBusinessStores(id, theSortOrder, page, limit);

      commit('businessStoresPageCount', result.page_count);
      commit('businessStores', result.items);
    },
    async listBusinessPublishers({ state, commit }, { id, page, limit } = {}) {
      const businessService = Vue.prototype.$services.business;
      const thePage = page || state.listBusinessPublishers.pageNumber || defaultPage;
      const theLimit = limit || businessPublishersPaginationLimit;

      const listResults = await businessService.listBusinessPublishers(id, thePage, theLimit);

      commit('businessPublishers', listResults.items);
      commit('businessPublishersPage', thePage);
      commit('businessPublishersPageCount', listResults.page_count);
      commit('businessPublishersItemCount', listResults.item_count);
    },
    async changeBusinessPublishersPage({ commit, state }, { id, page }) {
      commit('businessPublishersPage', page);

      const businessService = Vue.prototype.$services.business;
      const limit = businessPublishersPaginationLimit;

      const result = await businessService.listBusinessPublishers(id, page, limit);

      commit('businessPublishersPageCount', result.page_count);
      commit('businessPublishers', result.items);
    },
    async listBusinessOwnerOffers({ state, commit }, { id, page, limit } = {}) {
      const businessService = Vue.prototype.$services.business;
      const thePage = page || state.listBusinessOwnerOffers.pageNumber || defaultPage;
      const theLimit = limit || businessOwnerOffersPaginationLimit;
      const theSortOrder = 'creation_date';

      const listResults = await businessService.listBusinessOwnerOffers(id, theSortOrder, thePage, theLimit);

      commit('businessOwnerOffers', listResults.items);
      commit('businessOwnerOffersPage', thePage);
      commit('businessOwnerOffersPageCount', listResults.page_count);
      commit('businessOwnerOffersItemCount', listResults.item_count);
    },
    // NOTE (SC): This remains for the business contributor offers (vs listBusinessOwnerOffers)
    async listBusinessOffers({ state, commit }, { id, page, limit } = {}) {
      const businessService = Vue.prototype.$services.business;
      const thePage = page || state.listBusinessOffers.pageNumber || defaultPage;
      const theLimit = limit || businessOffersPaginationLimit;
      const theSortOrder = 'creation_date';

      const listResults = await businessService.listBusinessOffers(id, theSortOrder, thePage, theLimit);

      commit('businessOffers', listResults.items);
      commit('businessOffersPage', thePage);
      commit('businessOffersPageCount', listResults.page_count);
      commit('businessOffersItemCount', listResults.item_count);
    },
    async listBusinessActivationCodeBatches({ state, commit }, { id, page, limit } = {}) {
      const businessService = Vue.prototype.$services.business;
      const thePage = page || state.listBusinessActivationCodeBatches.pageNumber || defaultPage;
      const theLimit = limit || businessActivationCodeBatchesPaginationLimit;

      const listResults = await businessService.listBusinessActivationCodeBatches(id, thePage, theLimit);

      commit('businessActivationCodeBatches', listResults.items);
      commit('businessActivationCodeBatchesPage', thePage);
      commit('businessActivationCodeBatchesPageCount', listResults.page_count);
      commit('businessActivationCodeBatchesItemCount', listResults.item_count);
    },
    async listBusinessWebhooks({ state, commit }, { id, page, limit, sort_order = 'creation_date' } = {}) {
      const businessService = Vue.prototype.$services.business;
      const thePage = page || state.listBusinessWebhooks.pageNumber || defaultPage;
      const theLimit = limit || businessWebhooksPaginationLimit;

      const listResults = await businessService.listBusinessWebhooks(id, sort_order, thePage, theLimit);

      commit('businessWebhooks', listResults.items);
      commit('businessWebhooksPage', thePage);
      commit('businessWebhooksPageCount', listResults.page_count);
      commit('businessWebhooksItemCount', listResults.item_count);
    },
    async activateBusinessWebhook({}, { webhookId, businessId }) {
      const businessService = Vue.prototype.$services.business;

      await businessService.activateBusinessWebhook(webhookId, businessId);
    },
    async disableBusinessWebhook({}, { webhookId, businessId }) {
      const businessService = Vue.prototype.$services.business;

      await businessService.disableBusinessWebhook(webhookId, businessId);
    },
    async addBusinessWebhook({}, payload) {
      const businessService = Vue.prototype.$services.business;

      const { businessId, name, uri, authorization } = payload;

      return await businessService.addBusinessWebhook(businessId, { name, uri, authorization });
    },
    async updateBusinessWebhook({}, payload) {
      const businessService = Vue.prototype.$services.business;

      const { webhookId, businessId, name, uri, authorization } = payload;

      return await businessService.updateBusinessWebhook(webhookId, businessId, { name, uri, authorization });
    },
    async removeBusinessWebhook({}, { webhookId, businessId }) {
      const businessService = Vue.prototype.$services.business;

      return await businessService.removeBusinessWebhook(webhookId, businessId);
    },
    async clearListBusinesses({ commit }) {
      commit('businesses', []);
    },
    async getBusiness({ commit }, id) {
      const businessService = Vue.prototype.$services.business;
      const business = await businessService.getBusiness(id);

      commit('business', business);
      return business;
    },
    async updateBusiness({ dispatch }, params) {
      const businessService = Vue.prototype.$services.business;
      const { id, ...payload } = params;
      delete payload.bank_account_key;
      delete payload.status;
      delete payload.creation_by_provider;
      delete payload.creation_by_app_key;
      delete payload.last_update_by_app_key;
      delete payload.last_update_date;
      delete payload.creation_by_account_id;
      const result = await businessService.updateBusiness(id, payload);
      await dispatch('getBusiness', id);
      return result;
    },
    async linkBankAccountToBusiness({ dispatch, commit }, { id, ...payload }) {
      const businessService = Vue.prototype.$services.business;
      const result = await businessService.linkBankAccountToBusiness(id, payload);
      await dispatch('getBusiness', id);
      return result;
    },
    async unlinkBankAccountFromBusiness({ dispatch, commit }, { id }) {
      const businessService = Vue.prototype.$services.business;
      const result = await businessService.unlinkBankAccountFromBusiness(id);
      await dispatch('getBusiness', id);
      return result;
    },
    async addReportAccess({ commit }, payload) {
      const businessService = Vue.prototype.$services.business;
      const reportAccess = await businessService.addBusinessEmailAccess(payload);

      commit('reportAccess', reportAccess);
    },
    async removeReportAccess({ dispatch }, { id, business_id }) {
      const businessService = Vue.prototype.$services.business;
      const result = await businessService.removeBusinessEmailAccess(id);

      await dispatch('getBusiness', business_id);
      return result;
    },
    async listReportAccesses({ commit }, business_id) {
      const businessService = Vue.prototype.$services.business;
      const reportAccesses = await businessService.listBusinessEmailAccess(business_id);

      commit('reportAccesses', reportAccesses);
    },
    async setBusinessAsVerified({ dispatch }, id) {
      const businessService = Vue.prototype.$services.business;
      const business = await businessService.setBusinessAsVerified(id);

      await dispatch('getBusiness', id);
      return business;
    },
    async setBusinessAsUnverified({ dispatch }, id) {
      const businessService = Vue.prototype.$services.business;
      const business = await businessService.setBusinessAsUnverified(id);

      await dispatch('getBusiness', id);
      return business;
    },
  },
};
