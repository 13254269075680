<template>
  <card :title="$t('offers.vouchers')" icon="">
    <template slot="headerZoneRight">
      <!--        TODO: Change voucher count value-->
      <label-text class="text-sm-center" :label="$t('offers.details.vouchers_section.voucher_count')" :value="listItemCount || '0'"></label-text>
      <div v-if="showAddOfferVoucherFeature() && isBonusOffer" class="card-header-divider--vertical hidden-xs-only">
        <v-divider vertical></v-divider>
      </div>
      <label-text v-if="showAddOfferVoucherFeature() && isBonusOffer" :label="`${$t('offers.details.vouchers_section.action')}`" value="3">
        <link-secondary-action slot="labelTextLink" @click="openAddBonusOfferVoucherModal">
          {{ $t('offers.details.vouchers_section.add_voucher') }}
        </link-secondary-action>
      </label-text>
    </template>
    <template slot="content">
      <modal-action :show-modal="showAddBonusOfferVoucherModal"
        ><create-bonus-offer-voucher-action
          :offer-id="offer.id || ''"
          :hello-show="showAddBonusOfferVoucherModal"
          @close="closeAddBonusOfferVoucherModal"
          @created="addBonusOfferVoucherSuccess"
        ></create-bonus-offer-voucher-action
      ></modal-action>
      <modal-confirm
        v-if="offerVoucherKeyToChangeStatus"
        :show-modal="showChangeStatusOfferVoucherModal"
        :title="$t(`offers.details.vouchers_section.${changeStatusAction}_action.confirm.title`)"
        context="PublishOfferVoucher"
        :text="
          $t(`offers.details.vouchers_section.${changeStatusAction}_action.confirm.text`, {
            offerVoucherName: `${offerVoucherToChangeStatus.name[currentLocale]}`,
          })
        "
        :cancel_text="`offers.details.vouchers_section.${changeStatusAction}_action.confirm.button_cancel`"
        :submit_text="`offers.details.vouchers_section.${changeStatusAction}_action.confirm.button_submit`"
        :submit_working="working"
        @submit="changeStatusOfferVoucher"
        @close="closeChangeStatusOfferVoucherModal"
      ></modal-confirm>
      <offer-vouchers-table v-if="dataReady && vouchers.length" :items="vouchers" :offer-id="offer.id"></offer-vouchers-table>
      <label-none v-if="vouchers.length === 0">{{ $t('card.comments_section.no_found') }}</label-none>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import OfferVouchersTable from './OfferVouchersTable';
import CreateBonusOfferVoucherAction from '../actions/CreateBonusOfferVoucherAction';
import { mapGetters } from 'vuex';

export default {
  name: 'offer-vouchers',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { OfferVouchersTable, CreateBonusOfferVoucherAction },
  data() {
    return {
      keyword: '',
      page: 1,
      dataReady: false,
      showAddBonusOfferVoucherModal: false,
      showChangeStatusOfferVoucherModal: false,
      showUpdateOfferVoucherModal: false,
      offerVoucherKeyToUpdate: null,
      offerVoucherKeyToChangeStatus: null,
      offerVoucherToUpdate: { name: {} },
      offerVoucherToChangeStatus: { name: {} },
      changeStatusAction: '',
    };
  },
  computed: {
    ...mapGetters('offers', ['offer']),
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('voucher', ['vouchers', 'listKeyword', 'listPageNumber', 'listPageCount', 'listItemCount']),
    isBonusOffer() {
      return this.offer.type === 'bonus';
    },
  },
  async created() {
    await this.fetchVouchers();
    this.page = this.listPageNumber;
    this.keyword = this.listKeyword;
  },
  watch: {
    async offer() {
      await this.fetchVouchers();
      this.keyword = '';
      this.page = 1;
    },
  },
  methods: {
    async clear() {
      this.keyword = '';
      this.page = 1;
      this.dataReady = false;
    },
    openAddBonusOfferVoucherModal() {
      this.showAddBonusOfferVoucherModal = true;
    },
    closeAddBonusOfferVoucherModal() {
      this.showAddBonusOfferVoucherModal = false;
    },
    async addBonusOfferVoucherSuccess() {
      await this.fetchVouchers();
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'offers.details.vouchers_section.create_action.success' });
      this.closeAddBonusOfferVoucherModal();
    },
    showAddOfferVoucherFeature() {
      return this.canManageOffer() && this.offer.system_status.status !== 'draft';
    },
    async fetchVouchers() {
      this.dataReady = false;
      await this.executeAction({ action: 'voucher/listVouchersByOffer' }, { offerId: this.offer.id, keyword: this.keyword, page: 1 });
      this.dataReady = true;
    },
  },
};
</script>
