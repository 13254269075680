<template>
  <card :title="$t('business.details.publishers_section.title')" icon="">
    <template slot="headerZoneRight">
      <label-text
        class="text-sm-center"
        :label="$t('business.details.publishers_section.count')"
        :value="businessPublishersItemCount || '0'"
      ></label-text>
    </template>
    <template slot="content">
      <business-publishers-table v-if="businessPublishers.length" :items="businessPublishers" :id="businessId"></business-publishers-table>
      <label-none v-if="!businessPublishers.length">{{ $t('system.none') }}</label-none>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import BusinessPublishersTable from './BusinessPublishersTable';
import { mapGetters } from 'vuex';

export default {
  name: 'business-publishers',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { BusinessPublishersTable },
  data() {
    return {
      pagination: 1,
    };
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  async created() {
    return this.fetchBusinessPublishers();
  },
  watch: {
    async business() {
      return this.fetchBusinessPublishers();
    },
    BusinessPublishersPageNumber() {
      this.pagination = this.BusinessPublishersPageNumber;
    },
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'business/changeBusinessPublishersPage',
          success: this.success,
        },
        {
          id: this.businessId,
          page: newValue,
        },
      );
    },
  },
  computed: {
    ...mapGetters('business', [
      'business',
      'businessPublishers',
      'businessPublishersPageCount',
      'businessPublishersPageNumber',
      'businessPublishersItemCount',
    ]),
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    async clear() {
      this.page = 1;
      this.$validator.reset();
    },
    async fetchBusinessPublishers() {
      return this.executeAction({ action: 'business/listBusinessPublishers' }, { id: this.business.id, page: 1 });
    },
  },
};
</script>
