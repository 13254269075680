<template>
  <view-main title="page_title.instant_card_batch_details">
    <two-levels-breadcrumbs
      slot="content"
      :top-label="$t('navigation.instant_card_batches')"
      :to="{ name: 'instant_card_batches' }"
      :last-label="batch.name"
    />
    <batch-information slot="content" :batch-id="batchId"></batch-information>
    <batch-delivery slot="content" :batch-id="batchId"></batch-delivery>
    <batch-generated-cards slot="content" :batch-id="batchId"></batch-generated-cards>
    <batch-notes slot="content" :batch-id="batchId"></batch-notes>
  </view-main>
</template>

<script>
import { actionErrorTrackable, renderErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import BatchInformation from './detail/BatchInformation.vue';
import BatchGeneratedCards from '@/components/instant_card_batch/detail/BatchGeneratedCards';
import BatchDelivery from './detail/BatchDelivery';
import BatchNotes from './detail/BatchNotes';

export default {
  name: 'instant-card-batch-detail-view',
  mixins: [renderErrorTrackable, actionErrorTrackable],
  components: { BatchInformation, BatchDelivery, BatchGeneratedCards, BatchNotes },
  props: {
    batchId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('instantCardBatch', ['batch']),
  },
  async mounted() {
    await this.fetch();
  },
  watch: {
    async batchId() {
      return this.fetch();
    },
  },
  methods: {
    async fetch() {
      const batch = await this.executeAction({ action: 'instantCardBatch/getBatch' }, this.batchId);
      if (!batch) {
        this.$router.replace({ name: 'notFound' });
      }
    },
  },
};
</script>
