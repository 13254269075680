import { DOMParser } from 'xmldom';

export default (stringContainingXmlSource) => {
  const parser = new DOMParser();
  let hasExcessXmlDeclarations = false;
  let parsedXmlDomDocument;

  checkIfHasExcessXmlDeclarations();

  if (hasExcessXmlDeclarations === true) {
    const kycResultCleaned = removeExcessXmlDeclarations(stringContainingXmlSource);
    parseToXmlDomDocument(kycResultCleaned);
  } else {
    parseToXmlDomDocument(stringContainingXmlSource);
  }

  function parseToXmlDomDocument(kycResultCleaned) {
    parsedXmlDomDocument = parser.parseFromString(kycResultCleaned, 'application/xml');
  }

  function checkIfHasExcessXmlDeclarations() {
    if (stringContainingXmlSource.match(/<\?xml version.*?>/g) !== null && stringContainingXmlSource.match(/<\?xml version.*?>/g).length > 1) {
      hasExcessXmlDeclarations = true;
    }
  }

  function removeExcessXmlDeclarations(stringContainingXmlSource) {
    return stringContainingXmlSource.replace(/(?!^)<\?xml version.*?>/g, '');
  }

  return parsedXmlDomDocument;
};
