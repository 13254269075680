<template>
  <card-modal
    icon=""
    :title="$t('business.update_action.title')"
    context="updateBusiness"
    @submit="submit"
    @cancel="cancel"
    cancel_text="business.update_action.button_cancel"
    submit_text="business.update_action.button_save"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-text-field
          autofocus
          :label="$t('business.update_action.name') + '*'"
          name="name"
          v-validate="'required|max:100'"
          :error-messages="errors.collect('name')"
          v-model="currentBusiness.name"
          data-vv-name="name"
          maxlength="100"
          :data-vv-as="$t('business.update_action.name')"
          data-test="update-business--name-fr"
          required
        ></v-text-field>
        <v-select
          name="number-of-employees"
          v-model="currentBusiness.number_of_employees"
          :error-messages="errors.collect('number-of-employees')"
          data-vv-name="number-of-employees"
          :data-vv-as="$t(`business.update_action.number_of_employees.label`)"
          :items="employeesRanges"
          item-text="name"
          item-value="abbr"
          :label="$t(`business.update_action.number_of_employees.label`)"
          data-test="update-number-of-employees"
        ></v-select>
        <h4>{{ $t('business.update_action.contact_info') }}</h4>
        <v-text-field
          name="contact_name"
          v-model="currentBusiness.contact.name"
          :label="$t('business.update_action.contact_name') + '*'"
          v-validate="'required|max:50'"
          :error-messages="errors.collect('contact_name')"
          data-vv-label="contact_name"
          maxlength="50"
          :data-vv-as="$t('business.update_action.contact_name')"
          data-test="update-business--contact-name"
          required
        ></v-text-field>
        <v-textarea
          name="email"
          v-model="currentBusiness.contact.email"
          v-validate="'required|email'"
          :label="$t(`business.update_action.email`) + '*'"
          :error-messages="errors.collect('email')"
          data-vv-name="email"
          :data-vv-as="$t(`business.update_action.email`)"
          rows="1"
          data-test="update-business--email"
          required
        ></v-textarea>
        <v-text-field
          name="phone"
          v-model="currentBusiness.contact.phone"
          v-maska="'1-###-###-####'"
          v-validate="{ required: true, regex: /^1-[0-9]{3}-[0-9]{3}-[0-9]{4}$/ }"
          :label="$t(`business.update_action.phone`) + '*'"
          :error-messages="errors.collect('phone')"
          hint="1-999-999-9999"
          data-vv-name="phone"
          :data-vv-as="$t(`business.update_action.phone`)"
          data-test="update-business--phone"
          required
        ></v-text-field>
        <v-text-field
          name="phone_extension"
          v-model="currentBusiness.contact.phone_extension"
          v-validate="{ regex: /^\d{0,10}$/ }"
          :label="$t(`business.update_action.phone_extension`)"
          :error-messages="errors.collect('phone_extension')"
          data-vv-name="phone_extension"
          :data-vv-as="$t(`business.update_action.phone_extension`)"
          data-test="update-business--phone-extension"
        ></v-text-field>
        <h4>{{ $t('business.update_action.address.title') }}</h4>
        <search-address @updated="updateStoreAddressModal"></search-address>
        <v-text-field
          name="street"
          @paste="pasteParsedAddress"
          v-model="currentBusiness.address.street"
          v-validate="'required|max:50'"
          :label="$t(`business.update_action.address.street`) + '*'"
          :error-messages="errors.collect('street')"
          data-vv-name="street"
          :data-vv-as="$t(`business.update_action.address.street`)"
          maxlength="50"
          counter
          :hint="$t(`business.update_action.address.street_hint`)"
          data-test="update-business--street"
          required
        ></v-text-field>
        <v-text-field
          name="city"
          v-model="currentBusiness.address.city"
          v-validate="'required|max:50'"
          :label="$t(`business.update_action.address.city`) + '*'"
          :error-messages="errors.collect('city')"
          data-vv-name="city"
          :data-vv-as="$t(`business.update_action.address.city`)"
          counter
          maxlength="50"
          data-test="update-business--city"
          required
        ></v-text-field>
        <v-select
          name="state"
          v-model="currentBusiness.address.state"
          v-validate="'required'"
          :error-messages="errors.collect('state')"
          data-vv-name="state"
          :data-vv-as="$t(`business.update_action.address.state`)"
          :items="stateOptions"
          item-text="name"
          item-value="abbr"
          :label="$t(`business.update_action.address.state`) + '*'"
          data-test="update-business--state"
        ></v-select>
        <v-select
          name="country"
          v-model="currentBusiness.address.country"
          v-validate="'required'"
          :error-messages="errors.collect('country')"
          data-vv-name="country"
          :data-vv-as="$t(`business.update_action.address.country`)"
          :items="countryOptions"
          item-text="name"
          item-value="abbr"
          :label="$t(`business.update_action.address.country`) + '*'"
          data-test="update-business--country"
        ></v-select>
        <v-text-field
          name="zip"
          v-mask-zip
          v-model="currentBusiness.address.zip"
          v-validate="{ required: true, regex: /^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z] [0-9][ABCEGHJ-NPRSTV-Z][0-9]$/ }"
          :label="$t(`business.update_action.address.zip`) + '*'"
          hint="X9X 9X9"
          :error-messages="errors.collect('zip')"
          data-vv-name="zip"
          :data-vv-as="$t(`business.update_action.address.zip`)"
          data-test="update-business--zip"
        ></v-text-field>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import Vue from 'vue';
import { parseAddress } from '@/utils/address-utils';
import SearchAddress from '@/components/store/actions/SearchAddress.vue';

const DEFAULT_EMPTY_ADDRESS = Object.freeze({
  street: '',
  city: '',
  zip: '',
  state: '',
  country: '',
});

export default {
  name: 'update-business-action',
  components: { SearchAddress },
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    business: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentBusiness: { contact: {}, address: {} },
      countryOptions: [{ name: this.$t('country.canada'), abbr: 'CA' }],
      stateOptions: [
        { name: this.$t('state.alberta'), abbr: 'AB' },
        { name: this.$t('state.british_columbia'), abbr: 'BC' },
        { name: this.$t('state.manitoba'), abbr: 'MB' },
        { name: this.$t('state.new_brunswick'), abbr: 'NB' },
        { name: this.$t('state.newfoundland_labrador'), abbr: 'NL' },
        { name: this.$t('state.nova_scotia'), abbr: 'NS' },
        { name: this.$t('state.northwest_territories'), abbr: 'NT' },
        { name: this.$t('state.nunavut'), abbr: 'NU' },
        { name: this.$t('state.ontario'), abbr: 'ON' },
        { name: this.$t('state.prince_edward_island'), abbr: 'PE' },
        { name: this.$t('state.quebec'), abbr: 'QC' },
        { name: this.$t('state.saskatchewan'), abbr: 'SK' },
        { name: this.$t('state.yukon'), abbr: 'YT' },
      ],
      employeesRanges: [
        { name: this.$t('business.create_action.number_of_employees.options.null'), abbr: null },
        { name: this.$t('business.update_action.number_of_employees.options.1_50'), abbr: '1_50' },
        { name: this.$t('business.update_action.number_of_employees.options.51_200'), abbr: '51_200' },
        { name: this.$t('business.update_action.number_of_employees.options.201_500'), abbr: '201_500' },
        { name: this.$t('business.update_action.number_of_employees.options.500_MORE'), abbr: '500_MORE' },
      ],
    };
  },
  async mounted() {
    this.currentBusiness = Object.assign({ contact: {}, address: {} }, _.cloneDeep(this.business));
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    updateStoreAddress(address, overrideNotMutedFieldsWithDefaultValues = false) {
      const assignAddressValues = (values) => Object.entries(values).forEach(([key, value]) => Vue.set(this.currentBusiness.address, key, value));
      const mutedValues = _.pickBy(address, (item) => !_.isEmpty(item));

      if (overrideNotMutedFieldsWithDefaultValues) {
        assignAddressValues({
          ...DEFAULT_EMPTY_ADDRESS,
          ...mutedValues,
        });
      } else {
        assignAddressValues(mutedValues);
      }
    },
    pasteParsedAddress(event) {
      const pasteString = (event.clipboardData || window.clipboardData).getData('text');

      const address = parseAddress(pasteString);
      if (address) {
        event.preventDefault();
        this.updateStoreAddress(address);
      }
    },
    updateStoreAddressModal({ address }) {
      this.updateStoreAddress(address, true);
    },
    clear() {
      this.currentBusiness = { contact: {}, address: {} };
      this.$validator.reset();
    },
    success(response) {
      this.clear();
      this.$emit('updated');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();
      if (validationComplete) {
        await this.executeAction(
          {
            action: 'business/updateBusiness',
            name: 'updateBusiness',
            success: this.success,
          },
          this.buildPayload(),
        );
      }
    },
    buildPayload() {
      const payload = Object.assign({}, _.omit(this.currentBusiness, ['creation_date']));
      if (payload.phone) {
        payload.contact.phone = this.$options.filters.formatPhone(payload.phone);
      }
      if (!_.get(this.currentBusiness, 'contact.phone_extension')) {
        payload.contact.phone_extension = null;
      }

      return payload;
    },
  },
};
</script>
