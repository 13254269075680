<template>
  <card :title="$t('business.activation_code_batches')" icon="">
    <template slot="headerZoneRight">
      <label-text
        class="text-sm-center"
        :label="$t('business.details.activation_code_batches_section.count')"
        :value="businessActivationCodeBatchesItemCount || '0'"
      ></label-text>
    </template>
    <template slot="content">
      <business-activation-code-batches-table
        v-if="businessActivationCodeBatches.length"
        @update_activation_code_batch="openUpdateActivationCodeBatchModal"
        :items="businessActivationCodeBatches"
        :business-id="business.id"
      />
      <label-none v-if="businessActivationCodeBatches.length === 0">{{ $t('system.none') }}</label-none>

      <modal-action :show-modal="showUpdateActivationCodeBatchModal" avoid-rendering>
        <update-activation-code-batch-action
          :activation-code-batch="activationCodeBatchToUpdate"
          @close="closeUpdateActivationCodeBatchModal"
          @updated="updateActivationCodeBatchSuccess"
        />
      </modal-action>
    </template>
  </card>
</template>

<script>
import { security, disableWithError, actionErrorTrackable } from '@/mixins';
import BusinessActivationCodeBatchesTable from './BusinessActivationCodeBatchesTable';
import UpdateActivationCodeBatchAction from '../../activation_code_batch/actions/UpdateActivationCodeBatchAction.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'business-activation-code-batches',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: {
    BusinessActivationCodeBatchesTable,
    UpdateActivationCodeBatchAction,
  },
  $_veeValidate: {
    validator: 'new',
  },
  data() {
    const $t = this.$t.bind(this);
    return {
      page: 1,
      showUpdateActivationCodeBatchModal: false,
      activationCodeBatchIdToUpdate: null,
      activationCodeBatchToUpdate: {},
    };
  },
  async created() {
    this.page = this.businessActivationCodeBatchesPageNumber;
  },
  watch: {
    async business() {
      await this.fetchActivationCodeBatches();
    },
  },
  computed: {
    ...mapGetters('business', [
      'business',
      'businessActivationCodeBatches',
      'businessActivationCodeBatchesPageNumber',
      'businessActivationCodeBatchesItemCount',
    ]),
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    async fetchActivationCodeBatches() {
      await this.executeAction({ action: 'business/listBusinessActivationCodeBatches' }, { id: this.business.id, page: 1 });
    },
    openUpdateActivationCodeBatchModal(id) {
      this.activationCodeBatchIdToUpdate = id;
      this.activationCodeBatchToUpdate = this.businessActivationCodeBatches.find((l) => l.id === id);
      this.showUpdateActivationCodeBatchModal = true;
    },
    closeUpdateActivationCodeBatchModal() {
      this.activationCodeBatchIdToUpdate = null;
      this.activationCodeBatchToUpdate = {};
      this.showUpdateActivationCodeBatchModal = false;
    },
    async updateActivationCodeBatchSuccess() {
      this.closeUpdateActivationCodeBatchModal();
      await this.executeAction(
        { action: 'ui/showSuccessSnackBar' },
        { text: 'offers.details.activation_code_batches_section.update_action.success' },
      );
      await this.fetchActivationCodeBatches();
    },
  },
};
</script>
