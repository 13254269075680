<template>
  <div>
    <table class="responsive-table" id="offer-activation-codes-table">
      <thead>
        <tr>
          <td>{{ $t('offers.activation_codes_table.fields.id') }}</td>
          <td>{{ $t('offers.activation_codes_table.fields.email') }}</td>
          <td>{{ $t('offers.activation_codes_table.fields.mobile_number') }}</td>
          <td>{{ $t('offers.activation_codes_table.fields.first_name') }}</td>
          <td>{{ $t('offers.activation_codes_table.fields.last_name') }}</td>
          <td>{{ $t('offers.activation_codes_table.fields.account_id') }}</td>
          <td>{{ $t('offers.activation_codes_table.fields.voucher_id') }}</td>
          <td>{{ $t('offers.activation_codes_table.fields.batch_id') }}</td>
          <td align="right">{{ $t('offers.activation_codes_table.fields.remaining') }}</td>
          <td>{{ $t('offers.activation_codes_table.fields.status') }}</td>
          <td>{{ $t('offers.activation_codes_table.fields.action') }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>
            <h5>{{ $t('offers.activation_codes_table.fields.id') }}:</h5>
            <router-link :to="{ name: 'activation_code_details', params: { activationCodeId: item.id } }">
              {{ item.id }}
            </router-link>
          </td>
          <td>
            <h5>{{ $t('offers.activation_codes_table.fields.email') }}</h5>
            <span v-if="item.recipient && item.recipient.email"> {{ item.recipient.email | truncateEllipsis(30) }} </span>
            <span v-else class="info-not-available">{{ $t('system.na') }}</span>
          </td>
          <td>
            <h5>{{ $t('offers.activation_codes_table.fields.mobile_number') }}</h5>
            <span v-if="item.recipient && item.recipient.mobile_number">
              {{ item.recipient.mobile_number | truncateEllipsis(30) | formatPhone }}
            </span>
            <span v-else class="info-not-available">{{ $t('system.na') }}</span>
          </td>
          <td>
            <h5>{{ $t('offers.activation_codes_table.fields.first_name') }}</h5>
            <span v-if="item.recipient && item.recipient.first_name"> {{ item.recipient.first_name | truncateEllipsis(20) }} </span>
            <span v-else class="info-not-available">{{ $t('system.na') }}</span>
          </td>
          <td>
            <h5>{{ $t('offers.activation_codes_table.fields.last_name') }}</h5>
            <span v-if="item.recipient && item.recipient.last_name"> {{ item.recipient.last_name | truncateEllipsis(20) }} </span>
            <span v-else class="info-not-available">{{ $t('system.na') }}</span>
          </td>
          <td>
            <h5>{{ $t('offers.activation_codes_table.fields.account_id') }}</h5>
            <router-link v-if="item.account_id" :to="{ name: 'user_details', params: { userId: item.account_id } }">
              {{ item.account_id }}
            </router-link>
            <span v-else class="info-not-available">{{ $t('system.na') }}</span>
          </td>
          <td>
            <h5>{{ $t('offers.activation_codes_table.fields.voucher_id') }}</h5>
            <router-link v-if="item.voucher_id" :to="{ name: 'voucher_details', params: { voucherId: item.voucher_id } }">
              {{ item.voucher_id }}
            </router-link>
            <span v-else class="info-not-available">{{ $t('system.na') }}</span>
          </td>
          <td>
            <h5>{{ $t('offers.activation_codes_table.fields.batch_id') }}</h5>
            <template v-if="item.batch_id">
              {{ item.batch_name ? `${item.batch_name} (${item.batch_id})` : '' }}
            </template>
            <span v-else class="info-not-available">{{ $t('system.na') }}</span>
          </td>
          <td align="right">
            <h5>{{ $t('offers.activation_codes_table.fields.remaining') }}:</h5>
            <span v-if="item.voucher.remaining_amount !== null">{{ item.voucher.remaining_amount | currency | dollarSignI18n }}</span>
            <span v-else class="info-not-available">{{ $t('system.na') }}</span>
          </td>
          <td>
            <h5>{{ $t('offers.activation_codes_table.fields.status') }}:</h5>
            <activation-code-status-view :status="item.system_status.status" />
          </td>
          <td>
            <h5>{{ $t('offers.activation_code_batches_table.fields.edit') }}</h5>
            <link-secondary-action v-if="!item.batch_id" slot="labelTextLink" @click="openUpdateActivationCodeModal(item.id)">{{
              $t('offers.activation_code_batches_table.fields.edit')
            }}</link-secondary-action>
            <span v-else class="info-not-available">{{ $t('system.na') }}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-xs-center">
      <v-pagination v-if="listPageCount > 1" v-model="pagination" :length="listPageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';
import ActivationCodeStatusView from '@/components/activation_code/ActivationCodeStatusView.vue';

export default {
  name: 'offers-activation-codes-table',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { ActivationCodeStatusView },
  props: {
    offerId: {
      type: String,
    },
    items: {
      type: Array,
    },
  },
  data() {
    return {
      pagination: 1,
    };
  },
  mounted() {
    this.pagination = this.listPageNumber;
  },
  computed: {
    ...mapGetters('activationCode', ['listKeyword', 'listPageNumber', 'listPageCount', 'listItemCount']),
  },
  watch: {
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'activationCode/listActivationCodesByOffer',
        },
        {
          keyword: this.listKeyword,
          offerId: this.offerId,
          page: newValue,
        },
      );
    },
    methods: {
      openCancelActivationCodeModal(id) {
        this.$emit('activation_code_cancel_action', id);
      },
      openResendActivationCodeModal(id) {
        this.$emit('activation_code_resend_action', id);
      },
      openRedeemActivationCodeModal(id) {
        this.$emit('activation_code_redeem_action', id);
      },
    },
  },
  methods: {
    openUpdateActivationCodeModal(id) {
      this.$emit('activation_code_update_action', id);
    },
  },
};
</script>
