<template>
  <card :title="$t('offers.activation_code_batches')" icon="">
    <template slot="headerZoneRight">
      <label-text
        class="text-sm-center"
        :label="$t('offers.details.activation_code_batches_section.count')"
        :value="listItemCount || '0'"
      ></label-text>

      <template>
        <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
        <button-secondary-actions
          class="application-info--actions"
          :text="$t('offers.details.activation_code_batches_section.actions')"
          :actions="actions"
        >
        </button-secondary-actions>
        <template slot="batchActivationCode">
          <modal-action :show-modal="showDownloadActivationCodesOfferInfosModal">
            <download-preview-pdf-action
              :offer-id="offer.id || ''"
              :hello-show="showDownloadActivationCodesOfferInfosModal"
              @close="closeDownloadActivationCodesOfferInfosModal"
              @action="closeDownloadActivationCodesOfferInfosModal"
            />
          </modal-action>
          <modal-action :show-modal="showCreateActivationCodeBatchModal">
            <create-activation-code-batch-action
              :offer-obj="offer"
              :hello-show="showCreateActivationCodeBatchModal"
              @close="closeCreateActivationCodeBatchModal"
              @created="batchActivationCodeSuccess"
            />
          </modal-action>
          <modal-action :show-modal="showCreateActivationCodeBatchWithRecipientsCsvFileModal">
            <create-activation-code-batch-with-csv-recipients-file-action
              :offer-obj="offer"
              :hello-show="showCreateActivationCodeBatchWithRecipientsCsvFileModal"
              @close="closeCreateActivationCodeBatchWithRecipientsCsvFileModal"
              @created="batchActivationCodeSuccess"
            />
          </modal-action>
          <modal-action :show-modal="showUpdateActivationCodeBatchModal">
            <update-activation-code-batch-action
              :activation-code-batch="activationCodeBatchToUpdate"
              @close="closeUpdateActivationCodeBatchModal"
              @updated="updateActivationCodeBatchSuccess"
            />
          </modal-action>
        </template>
      </template>
    </template>
    <template slot="content">
      <offers-activation-code-batches-table
        v-if="activationCodeBatches.length"
        :items="activationCodeBatches"
        :offer-id="offer.id"
        @update_activation_code_batch="openUpdateActivationCodeBatchModal"
      />
      <label-none v-if="activationCodeBatches.length === 0">{{ $t('system.none') }}</label-none>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';
import OffersActivationCodeBatchesTable from '@/components/offers/detail/OffersActivationCodeBatchesTable.vue';
import CreateActivationCodeBatchAction from '@/components/offers/actions/CreateActivationCodeBatchAction.vue';
import CreateActivationCodeBatchWithCsvRecipientsFileAction from '@/components/offers/actions/CreateActivationCodeBatchWithRecipientsCsvFileAction.vue';
import DownloadPreviewPdfAction from '@/components/offers/actions/DownloadPreviewPdfAction.vue';
import UpdateActivationCodeBatchAction from '../../activation_code_batch/actions/UpdateActivationCodeBatchAction.vue';

export default {
  name: 'offers-activation-code-batches',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: {
    OffersActivationCodeBatchesTable,
    CreateActivationCodeBatchAction,
    CreateActivationCodeBatchWithCsvRecipientsFileAction,
    DownloadPreviewPdfAction,
    UpdateActivationCodeBatchAction,
  },
  $_veeValidate: {
    validator: 'new',
  },
  data() {
    const $t = this.$t.bind(this);
    return {
      activeModal: null,
      actions: [
        {
          slotName: 'batchActivationCode',
          name: $t(`offers.details.activation_code_batches_section.download_preview_pdf_action.title`),
          action: () => {
            this.openDownloadActivationCodesOfferInfosModal();
          },
          isAvailable: () => this.isAgentInternal(),
        },
        {
          slotName: 'batchActivationCode',
          name: $t(`offers.details.activation_code_batches_section.batch_activation_code`),
          action: () => {
            this.openBatchActivationCodeModal();
          },
          isAvailable: () => this.isAgentInternal(),
        },
        {
          slotName: 'batchActivationCode',
          name: $t(`offers.details.activation_code_batches_section.batch_activation_code_with_csv_recipients_file`),
          action: () => {
            this.openBatchActivationCodeWithRecipientsCsvFileModal();
          },
          isAvailable: () => this.isAgentInternal(),
        },
      ],
      page: 1,
      showCreateActivationCodeBatchModal: false,
      showCreateActivationCodeBatchWithRecipientsCsvFileModal: false,
      showDownloadActivationCodesOfferInfosModal: false,
      showUpdateActivationCodeBatchModal: false,
      activationCodeBatchIdToUpdate: null,
      activationCodeBatchToUpdate: {},
    };
  },
  async created() {
    this.page = this.listPageNumber;
  },
  watch: {
    async offer() {
      await this.fetchActivationCodeBatches();
    },
  },
  computed: {
    ...mapGetters('offers', ['offer']),
    ...mapGetters('activationCodeBatch', ['activationCodeBatches', 'listPageNumber', 'listItemCount']),
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    openBatchActivationCodeModal() {
      this.showCreateActivationCodeBatchModal = true;
    },
    openBatchActivationCodeWithRecipientsCsvFileModal() {
      this.showCreateActivationCodeBatchWithRecipientsCsvFileModal = true;
    },
    closeCreateActivationCodeBatchModal() {
      this.showCreateActivationCodeBatchModal = false;
    },
    closeCreateActivationCodeBatchWithRecipientsCsvFileModal() {
      this.showCreateActivationCodeBatchWithRecipientsCsvFileModal = false;
    },
    openDownloadActivationCodesOfferInfosModal() {
      this.showDownloadActivationCodesOfferInfosModal = true;
    },
    closeDownloadActivationCodesOfferInfosModal() {
      this.showDownloadActivationCodesOfferInfosModal = false;
    },
    openUpdateActivationCodeBatchModal(id) {
      this.activationCodeBatchIdToUpdate = id;
      this.activationCodeBatchToUpdate = this.activationCodeBatches.find((l) => l.id === id);
      this.showUpdateActivationCodeBatchModal = true;
    },
    closeUpdateActivationCodeBatchModal() {
      this.activationCodeBatchIdToUpdate = null;
      this.activationCodeBatchToUpdate = {};
      this.showUpdateActivationCodeBatchModal = false;
    },
    async updateActivationCodeBatchSuccess() {
      this.closeUpdateActivationCodeBatchModal();
      await this.executeAction(
        { action: 'ui/showSuccessSnackBar' },
        { text: 'offers.details.activation_code_batches_section.update_action.success' },
      );
      await this.fetchActivationCodeBatches();
    },
    async batchActivationCodeSuccess() {
      await this.fetchActivationCodeBatches();
      await this.executeAction(
        { action: 'ui/showSuccessSnackBar' },
        { text: 'offers.details.activation_code_batches_section.create_action.success' },
      );
      this.closeCreateActivationCodeBatchModal();
    },
    async fetchActivationCodeBatches() {
      await this.executeAction({ action: 'activationCodeBatch/listActivationCodeBatchesByOffer' }, { offerId: this.offer.id, page: 1 });
    },
  },
};
</script>
