<template>
  <card-modal
    icon=""
    :title="$t('user.generate_reset_pswd_link_action.title')"
    context="generateResetPasswordLink"
    @cancel="cancel"
    cancel_text="user.generate_reset_pswd_link_action.button_cancel"
    data-test="user--generate-reset-pswd-link-modal"
  >
    <div slot="content">
      <v-layout row class="generate-reset-pswd-clipboard-wrap">
        {{ resetPasswordLink ? resetPasswordLink : '?' }}
        <button class="generate-reset-pswd-clipboard" v-clipboard:copy="resetPasswordLink"><v-icon small>file_copy</v-icon></button>
      </v-layout>
      <v-layout row pad-form-row-elements>
        <v-flex xs12>
          <div class="verify-warning-container">
            <div class="label-text--value red--text verify-warning-text">{{ $t('user.generate_reset_pswd_link_action.warning') }}</div>
          </div>
        </v-flex>
      </v-layout>
    </div>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'generate-reset-password-link-action',
  mixins: [actionErrorTrackable],
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('account', ['resetPasswordLink']),
  },
  methods: {
    cancel() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="stylus">
.verify-warning-container {
  color: red;
  border-style: solid;
  border-radius: 5px;
  margin-top: 10px;
}
.verify-warning-text {
  margin: 10px;
}

.generate-reset-pswd-clipboard-wrap {
  word-break: break-all;

  .generate-reset-pswd-clipboard {
    height: 16px;
    margin-left: 0.3em;
  }
}
</style>
