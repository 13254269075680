<template>
  <card-modal
    icon=""
    :title="$t('offers.offers_tracking_links.qr_code_modal.title')"
    context="QrCodeView"
    @cancel="cancel"
    cancel_text="offers.offers_tracking_links.qr_code_modal.cancel"
  >
    <div slot="content">
      <v-layout row wrap>
        <v-flex sm4 xs12>
          <label-text :label="$t('offers.offers_tracking_links.qr_code_modal.id')" :value="trackingLink.id"></label-text>
        </v-flex>
        <v-flex sm4 xs12>
          <label-text :label="$t('offers.offers_tracking_links.qr_code_modal.name')" :value="this.trackingLink.name"></label-text>
        </v-flex>
        <v-flex sm4 xs12>
          <label-text
            :label="$t('offers.offers_tracking_links.qr_code_modal.redirect_to')"
            :value="$t(`offers.offers_tracking_links.redirect_type.${trackingLink.redirect_type}`)"
          ></label-text>
        </v-flex>
      </v-layout>

      <label-text :label="$t('offers.offers_tracking_links.qr_code_modal.referrer_business')" :value="this.trackingLink.business_name"></label-text>

      <div class="short-url-and-qr-code">
        <div class="short-url">
          <label-text :label="$t(`offers.offers_tracking_links.qr_code_modal.short_url`)" :value="trackingLink.url_short">
            <p v-if="trackingLink.url_short" class="label-text--value" slot="labelTextLink">
              <v-layout row>
                <a :href="trackingLink.url_short" target="_blank">{{ trackingLink.url_short }}</a>
                <button class="clipboard" v-clipboard:copy="trackingLink.url_short ? trackingLink.url_short : ''">
                  <v-icon small>file_copy</v-icon>
                </button>
              </v-layout>
            </p>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </label-text>
        </div>
        <div class="qr-code">
          <h5 class="qr-code-title">{{ $t('offers.offers_tracking_links.qr_code_modal.qr_code') }}</h5>
          <div v-html="qrCodeImage"></div>
          <a aria-role="button" class="download-link button-subtle" @click="downloadQrCode()">{{
            $t('offers.offers_tracking_links.qr_code_modal.download_svg')
          }}</a>
        </div>
      </div>
    </div>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import _ from 'lodash';
import Vue from 'vue';

export default {
  name: 'qr-code-view',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    trackingLink: {
      type: Object,
    },
  },
  data() {
    return {
      qrCodeImage: '',
    };
  },
  async mounted() {
    await this.fetchQrCodeImage();
  },
  methods: {
    clear() {
      return true;
    },
    success() {
      this.clear();
      this.$emit('success');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async fetchQrCodeImage() {
      const qrCode = await Vue.prototype.$services.offers.generateQrCodeImage(this.trackingLink.id, this.trackingLink.offer_id, {
        url_short: this.trackingLink.url_short,
      });

      if (qrCode) {
        this.qrCodeImage = qrCode;
      }
    },
    async downloadQrCode() {
      const fileSaver = Vue.prototype.$services.fileSaver;
      const blob = new Blob([this.qrCodeImage], { type: 'svg/plain;charset=utf-8' });

      return fileSaver.saveFile(blob, `${this.trackingLink.offer_id}-qrcode-${this.trackingLink.id}.svg`);
    },
  },
};
</script>

<style scoped lang="stylus">
.short-url-and-qr-code {
  display: flex;
}
.short-url {
  width: 50%;
}
.qr-code {
  width: 48%;
}
.qr-code-title,
.download-link {
  margin-left: 24px;
}
</style>
