<template>
  <div class="bank-account-status-view">
    <span v-if="bankAccountKey || bankAccountObject" :class="`status-${bankAccount.status}`">{{
      $t(`bank_account.status.${bankAccount.status}`)
    }}</span>
    <span class="status-na" v-else>{{ $t('bank_account.status.na') }}</span>
  </div>
</template>

<script>
import { actionErrorTrackable, renderErrorTrackable } from '@/mixins';

export default {
  name: 'bank-account-status-view',
  props: {
    bankAccountKey: {
      type: String,
    },
    bankAccountObject: {
      type: Object,
    },
  },
  data: () => {
    return {
      bankAccount: {},
    };
  },
  methods: {
    async fetch(bankAccountKey) {
      await this.executeAction({ action: 'bankAccount/getBankAccount', success: this.getBankAccountsuccess }, bankAccountKey);
    },
    getBankAccountsuccess(account) {
      this.bankAccount = account ? account : {};
    },
  },
  mixins: [renderErrorTrackable, actionErrorTrackable],
  created: async function() {
    if (this.bankAccountObject) {
      this.bankAccount = this.bankAccountObject;
    }
    if (this.bankAccountKey) {
      await this.fetch(this.bankAccountKey);
    }
  },
  watch: {
    async bankAccountKey(key) {
      await this.fetch(key);
    },
    async bankAccountObject(bankAccountObject) {
      this.bankAccount = bankAccountObject;
    },
  },
};
</script>
