<template>
  <v-flex class="view-bank_account-form">
    <h1 hidden>{{ $t('bank_account.form.title') }}</h1>
    <v-card>
      <form @submit.prevent="submit" class="form-basic form-search">
        <v-layout align-center wrap class="form-search--main-wrap-v2">
          <v-flex class="form-search--inputs-zone-v2">
            <form-warning-search
              :autofocus="true"
              :searched="searched"
              :hint-message="$t('bank_account.form.total_results_message')"
              class="bank-account--search-field"
              :label="$t('bank_account.form.placeholder')"
              v-model="keyword"
              data-vv-name="search"
              v-validate="'max:50'"
              :error-messages="errors.collect('search')"
              data-test="form-bank-account--keyword"
            ></form-warning-search>
            <v-select
              name="status"
              v-model="status"
              :error-messages="errors.collect('status')"
              data-vv-name="status"
              :data-vv-as="$t('bank_account.form.status')"
              :items="statusOptions"
              item-text="name"
              item-value="value"
              clearable
              :label="$t('bank_account.form.status')"
              data-test="form-bank-account--status"
            ></v-select>
            <div class="bank-account--btn-search">
              <button-primary :text="$t('bank_account.form.submit')" :loading="working" type="submit" />
            </div>
            <v-flex class="form-search--actions-zone-v2">
              <button-primary
                data-test="bank-account--btn-add"
                id="bank-account--btn-add"
                class="bank-account-form--btn-add"
                :loading="working"
                :text="$t('bank_account.form.add')"
                @click="openCreateBankAccountModal"
              ></button-primary>
            </v-flex>
          </v-flex>
        </v-layout>
      </form>
    </v-card>

    <v-flex class="data-section">
      <alert-warning v-if="dataReady && bankAccounts && bankAccounts.length === 0" :text="$t('bank_account.no_bank_account_found')"></alert-warning>
      <card v-if="dataReady && bankAccounts && bankAccounts.length > 0" icon="fal fa-university" :title="`${$t('bank_account.form.title')}`">
        <template slot="headerZoneRight">
          <label-text class="text-sm-center" :label="$t('bank_account.form.bank_accounts_count')" :value="listItemCount || '0'"></label-text>
        </template>
        <template slot="content">
          <bank-accounts-table :items="bankAccounts" />
        </template>
      </card>
    </v-flex>
    <modal-action :show-modal="showCreateBankAccountModal"
      ><create-bank-account-action
        v-if="showCreateBankAccountModal"
        @close="closeCreateBankAccountModal"
        @created="createBankAccountSuccess"
      ></create-bank-account-action
    ></modal-action>
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable, security } from '@/mixins';
import _ from 'lodash';
import Vue from 'vue';
import BankAccountsTable from '@/components/bank_account/list/BankAccountsTable';
import CreateBankAccountAction from '../actions/CreateBankAccountAction';

export default {
  name: 'search-bank-accounts-form',
  mixins: [actionErrorTrackable, security],
  components: { BankAccountsTable, CreateBankAccountAction },
  $_veeValidate: {
    validator: 'new',
  },
  beforeDestroy() {
    this.executeAction({ action: 'bankAccount/clearListBankAccounts' });
  },
  data() {
    return {
      page: 1,
      dataReady: false,
      keyword: '',
      searched: '',
      status: '',
      showCreateBankAccountModal: false,
      statusOptions: [
        { name: this.$t('bank_account.status.unverified'), value: 'unverified' },
        { name: this.$t('bank_account.status.active'), value: 'active' },
        { name: this.$t('bank_account.status.blocked'), value: 'blocked' },
      ],
    };
  },
  async created() {
    this.page = this.listPageNumber;
    this.keyword = this.listKeyword;
    this.status = this.listStatus;

    if (this.routeHasQueryParams()) {
      this.page = 1;
    }
    if (this.$route.query.keyword) {
      this.keyword = this.$route.query.keyword;
    }
    if (this.$route.query.status) {
      this.status = this.$route.query.status;
    }

    this.routeHasQueryParams() ? this.submit(null, false, this.page) : this.submit(null, true, this.page);
  },
  computed: {
    ...mapGetters('bankAccount', ['bankAccounts', 'listKeyword', 'listPageNumber', 'listStatus', 'listItemCount']),
  },
  methods: {
    async clear() {
      this.searched = '';
      this.keyword = '';
      this.status = '';
      this.$validator.reset();
    },
    routeHasQueryParams() {
      return this.$route.query.keyword || this.$route.query.status;
    },
    openCreateBankAccountModal() {
      this.showCreateBankAccountModal = true;
    },
    closeCreateBankAccountModal() {
      this.showCreateBankAccountModal = false;
    },
    buildPayloadQueryString() {
      const payload = {};
      if (this.keyword) {
        payload.keyword = this.keyword;
      }
      if (this.status) {
        payload.status = this.status;
      }

      return payload;
    },
    async createBankAccountSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'bank_account.create_action.success' });
      this.closeCreateBankAccountModal();
    },
    async submit(event, forceSearch = false, page = 1) {
      let validationComplete;
      if (!forceSearch) {
        validationComplete = await this.$validator.validateAll();
      }

      if (validationComplete || forceSearch) {
        this.$router.replace({ query: this.buildPayloadQueryString() });
        await this.clearAllError();

        try {
          this.dataReady = false;
          const search = this.buildPayloadQueryString();
          search.page = page;
          await this.executeAction({ action: 'bankAccount/listBankAccounts' }, search);
        } finally {
          this.dataReady = true;
        }
      }
    },
  },
};
</script>
