<template>
  <card-modal
    icon=""
    :title="$t('publishers.create_action.title')"
    context="createPublishers"
    @submit="submit"
    @cancel="cancel"
    cancel_text="publishers.create_action.button_cancel"
    submit_text="publishers.create_action.button_save"
    data-test="publishers--add-modal"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-text-field
          autofocus
          :label="$t('publishers.create_action.name') + '*'"
          name="name"
          v-validate="'required|max:100'"
          :error-messages="errors.collect('name')"
          v-model="publisher.name"
          data-vv-name="name"
          maxlength="100"
          :data-vv-as="$t('publishers.create_action.name')"
          data-test="add-publishers--name"
          required
        ></v-text-field>
        <h-autocomplete
          v-model="copy_stores_from_publisher_id"
          :label="$t('publishers.create_action.copy_stores')"
          :error-messages="errors.collect('copy_stores_from_publisher_id')"
          name="copy_stores_from_publisher_id"
          data-vv-name="copy_stores_from_publisher_id"
          data-vv-as="copy_stores_from_publisher_id"
          search-action="publishers/autocompleteSearch"
          data-test="add-publishers--copy-stores-from-publisher"
          no-filter
          clearable
        >
        </h-autocomplete>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import Vue from 'vue';

export default {
  name: 'create-publishers-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  data() {
    return {
      publisher: {},
      copy_stores_from_publisher_id: '',
    };
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    clear() {
      this.publisher = {};
      this.copy_stores_from_publisher_id = '';
      this.$validator.reset();
    },
    success(response) {
      this.clear();
      this.$emit('created');
      this.$router.push({ name: 'closed_loop_details', params: { publishersId: response.id } });
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        const payload = this.publisher;
        if (this.copy_stores_from_publisher_id) {
          payload.copy_stores_from_publisher_id = this.copy_stores_from_publisher_id;
        }

        await this.executeAction(
          {
            action: 'publishers/createPublisher',
            name: 'createPublisher',
            success: this.success,
          },
          payload,
        );
      }
    },
  },
};
</script>
