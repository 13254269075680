<template>
  <view-main title="page_title.instant_card_batches"
    ><search-instant-cards-batches-form slot="content"></search-instant-cards-batches-form
  ></view-main>
</template>

<script>
import { renderErrorTrackable } from '@/mixins';
import SearchInstantCardsBatchesForm from './list/SearchInstantCardsBatchesForm.vue';

export default {
  name: 'instant-card-batches-view',
  mixins: [renderErrorTrackable],
  components: { SearchInstantCardsBatchesForm },
};
</script>

<style scoped lang="stylus"></style>
