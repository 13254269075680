import _ from 'lodash';
import zip from './zip';

const internals = {
  countries: {
    CA: {
      allowed: ['AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'NT', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'],
      forbidden: ['SK'],
    },
  },

  getForbiddenStatesFor(country) {
    return _.get(internals, ['countries', country, 'forbidden']);
  },

  getAllowedStatesFor(country) {
    return _.get(internals, ['countries', country, 'allowed']);
  },

  getValidCountries() {
    return _.keys(internals.countries);
  },
};

export default (Joi) => {
  const dependencies = Joi.extend([zip]);

  const country = Joi.string()
    .length(2)
    .valid(internals.getValidCountries());

  const state = Joi.string()
    .length(2)
    .valid(internals.getAllowedStatesFor('CA'))
    .invalid(internals.getForbiddenStatesFor('CA'));

  const base = Joi.object({
    street: Joi.string()
      .trim()
      .max(50),
    city: Joi.string()
      .trim()
      .max(50),
    zip: dependencies.string().zip(),
    country,
    state,
  });

  return {
    name: 'address',
    base,
  };
};
