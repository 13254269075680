<template>
  <table class="responsive-table">
    <thead>
      <tr>
        <td>{{ $t('space.branding_table.fields.id') }}</td>
        <td>{{ $t('space.branding_table.fields.name') }}</td>
        <td>{{ $t('space.branding_table.fields.type') }}</td>
        <td>{{ $t('space.branding_table.fields.images.showcase') }}</td>
        <td>{{ $t('space.branding_table.fields.images.horizontal_logo') }}</td>
        <td>{{ $t('space.branding_table.fields.images.big_hero') }}</td>
        <td>{{ $t('space.branding_table.fields.images.small_hero') }}</td>
        <td>{{ $t('space.branding_table.fields.images.background') }}</td>
        <td>{{ $t('space.branding_table.fields.created') }}</td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <h5>{{ $t('space.branding_table.fields.id') }}</h5>
          <router-link :to="{ name: 'branding_details', params: { brandingId: item.id } }">{{ item.id }}</router-link>
        </td>
        <td>
          <h5>{{ $t('space.branding_table.fields.name') }}</h5>
          {{ item.name }}
        </td>
        <td>
          <h5>{{ $t('space.branding_table.fields.type') }}</h5>
          {{ $t(`branding.types.${item.type}`) }}
        </td>
        <td class="center-align">
          <h5>{{ $t('space.branding_table.fields.images.showcase') }}</h5>
          <span v-if="item.images">{{ item.images.filter((image) => image.role === 'showcase_fr' || image.role === 'showcase_en').length }}</span>
        </td>
        <td class="center-align">
          <h5>{{ $t('space.branding_table.fields.images.horizontal_logo') }}</h5>
          <span v-if="item.images">{{ item.images.filter((image) => image.role.startsWith('logo_horizontal')).length }}</span>
        </td>
        <td class="center-align">
          <h5>{{ $t('space.branding_table.fields.images.big_hero') }}</h5>
          <span v-if="item.images">{{ item.images.filter((image) => image.role.startsWith('big_hero')).length }}</span>
        </td>
        <td class="center-align">
          <h5>{{ $t('space.branding_table.fields.images.small_hero') }}</h5>
          <span v-if="item.images">{{ item.images.filter((image) => image.role.startsWith('small_hero')).length }}</span>
        </td>
        <td class="center-align">
          <h5>{{ $t('space.branding_table.fields.images.background') }}</h5>
          <span v-if="item.images">{{ item.images.filter((image) => image.role.startsWith('background')).length }}</span>
        </td>
        <td>
          <h5>{{ $t('space.branding_table.fields.created') }}</h5>
          {{ item.creation_date | formatShortDateInverted | orNotAvailable }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'space-branding-table',
  props: {
    item: {
      type: Object,
    },
  },
};
</script>

<style>
@media screen and (min-width: 600px) {
  .center-align {
    text-align: center;
  }
}
</style>
