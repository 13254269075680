import Vue from 'vue';

const service = {
  async listNoteTypes() {
    let url = `${process.env.VUE_APP_BASE_API_URL}/aml/v1/note-types`;

    const response = await Vue.prototype.$axios.get(url);
    return response.data.data.items;
  },

  async getNoteType(id) {
    let url = `${process.env.VUE_APP_BASE_API_URL}/aml/v1/note-types/${id}`;

    const response = await Vue.prototype.$axios.get(url);
    return response.data.data;
  },
};

export default service;
