<template>
  <v-card v-if="paginationExists()">
    <v-layout justify-center>
      <v-flex shrink
        ><v-pagination v-model="page" @input="requestPage" :length="currentPagination.page_count" :total-visible="7" circle></v-pagination
      ></v-flex>
      <span id="results-number">{{ $tc('task.pagination.results_found', currentPagination.item_count) }}</span>
    </v-layout>
  </v-card>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'task-pagination',
  mixins: [actionErrorTrackable, security],
  data() {
    return {
      page: null,
    };
  },
  computed: {
    ...mapGetters('task', ['pagination']),
    currentPagination() {
      return this.pagination;
    },
  },
  watch: {
    currentPagination(newValue, oldValue) {
      if (this.page != newValue.page) {
        this.page = newValue.page;
      }
    },
  },
  methods: {
    requestPage() {
      if (this.isPageValid()) {
        this.$emit('page_requested', this.page);
      }
    },
    isPageValid(page) {
      return this.page !== this.currentPagination.page && this.page <= this.currentPagination.page_count;
    },
    paginationExists() {
      return this.currentPagination.page_count;
    },
  },
};
</script>
