<template>
  <v-flex class="view-inventory-form">
    <h1 hidden>{{ $t('inventory.form.title') }}</h1>
    <v-card>
      <form @submit.prevent="submit" class="form-basic form-search">
        <v-layout align-center wrap class="form-search--main-wrap-v2">
          <v-flex class="form-search--inputs-zone-v2">
            <form-warning-search
              :autofocus="true"
              :searched="searched"
              :hint-message="$t('inventory.form.total_results_message')"
              class="inventory--search-field"
              :label="$t('inventory.form.placeholder')"
              v-model="keyword"
              data-vv-name="search"
              v-validate="'max:50'"
              :max-length="50"
              :error-messages="errors.collect('search')"
            ></form-warning-search>
            <h-autocomplete
              v-model="partner_id"
              :label="$t('inventory.form.partner')"
              :error-messages="errors.collect('partner_id')"
              name="partner_id"
              data-vv-name="partner_id"
              data-vv-as="partner_id"
              search-action="partner/autocompleteSearch"
              data-test="form-inventory-partner"
              no-filter
              clearable
            >
            </h-autocomplete>
            <h-autocomplete
              v-model="business_id"
              :label="$t('inventory.form.business')"
              :error-messages="errors.collect('business_id')"
              name="business_id"
              data-vv-name="business_id"
              data-vv-as="business_id"
              search-action="business/autocompleteSearch"
              data-test="form-inventory-business"
              no-filter
              clearable
            >
            </h-autocomplete>
            <button-primary class="inventory--btn-search" :text="$t('inventory.form.submit')" :loading="working" @click="submit" type="submit" />
          </v-flex>

          <v-flex class="form-search--actions-zone-v2">
            <button-primary
              data-test="inventory--btn-add"
              id="inventory--btn-add"
              class="inventory-form--btn-add"
              :loading="working"
              :text="$t('inventory.form.add')"
              @click="openCreateInventoryModal"
            ></button-primary>
          </v-flex>
        </v-layout>
      </form>
    </v-card>

    <v-flex class="data-section">
      <alert-warning v-if="dataReady && inventories.length === 0" :text="$t('inventory.no_inventory_found')"></alert-warning>
      <card v-if="dataReady && inventories.length > 0" icon="fal fa-funnel-dollar" :title="`${$t('inventory.form.data_section.title')}`">
        <template slot="headerZoneRight">
          <label-text class="text-sm-center" :label="$t('inventory.form.data_section.inventories_count')" :value="listItemCount || '0'"></label-text>
        </template>
        <template slot="content">
          <inventories-table :items="inventories"></inventories-table>
        </template>
      </card>
    </v-flex>
    <modal-action :show-modal="showCreateInventoryModal"
      ><create-inventory-action
        v-if="showCreateInventoryModal"
        @close="closeCreateInventoryModal"
        @created="createInventorySuccess"
      ></create-inventory-action
    ></modal-action>
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable, security } from '@/mixins';
import InventoriesTable from './InventoriesTable';
import CreateInventoryAction from '../actions/CreateInventoryAction';
import _ from 'lodash';
import Vue from 'vue';
import { ignoreCaseAndAccentsRegex } from '@/utils/string-utils';

export default {
  name: 'search-inventory-form',
  mixins: [actionErrorTrackable, security],
  components: { InventoriesTable, CreateInventoryAction },
  $_veeValidate: {
    validator: 'new',
  },
  beforeDestroy() {
    this.executeAction({ action: 'inventory/clearSearchInventories' });
  },
  data() {
    return {
      page: 1,
      dataReady: false,
      keyword: '',
      searched: '',
      showCreateInventoryModal: false,
      partner_id: '',
      business_id: '',
    };
  },
  async created() {
    this.page = this.listPageNumber;
    this.keyword = this.listKeyword;
    this.partner_id = this.listPartnerId;
    this.business_id = this.listBusinessId;

    if (this.routeHasQueryParams()) {
      this.page = 1;
    }
    if (this.$route.query.keyword) {
      this.keyword = this.$route.query.keyword;
    }
    if (this.$route.query.partner_id) {
      this.partner_id = this.$route.query.partner_id;
    }
    if (this.$route.query.business_id) {
      this.business_id = this.$route.query.business_id;
    }

    this.routeHasQueryParams() ? this.submit(null, false, this.page) : this.submit(null, true, this.page);
  },
  computed: {
    ...mapGetters('inventory', ['inventories', 'listKeyword', 'listPageNumber', 'listPartnerId', 'listBusinessId', 'listItemCount']),
  },
  methods: {
    async clear() {
      this.searched = '';
      this.keyword = '';
      this.partner_id = '';
      this.business_id = '';
      this.$validator.reset();
    },
    routeHasQueryParams() {
      return this.$route.query.keyword || this.$route.query.business_id;
    },
    openCreateInventoryModal() {
      this.showCreateInventoryModal = true;
    },
    closeCreateInventoryModal() {
      this.showCreateInventoryModal = false;
    },
    buildPayloadQueryString() {
      const payload = {};
      if (this.keyword) {
        payload.keyword = this.keyword;
      }
      if (this.partner_id) {
        payload.partner_id = this.partner_id;
      }
      if (this.business_id) {
        payload.business_id = this.business_id;
      }

      return payload;
    },
    async submit(event, forceSearch = false, page = 1) {
      let validationComplete;
      if (forceSearch !== true) {
        validationComplete = await this.$validator.validateAll();
      }

      if (validationComplete || forceSearch) {
        this.$router.replace({ query: this.buildPayloadQueryString() });
        await this.clearAllError();

        try {
          this.dataReady = false;
          const search = this.buildPayloadQueryString();
          search.page = page;
          await this.executeAction({ action: 'inventory/listInventories' }, search);
        } finally {
          this.dataReady = true;
        }
      }
    },
    async createInventorySuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'inventory.create_action.success' });
      this.closeCreateInventoryModal();
    },
  },
};
</script>
