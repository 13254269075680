import _ from 'lodash';

export default (kycResultAsJSON) => {
  const currentAddressFields = ['AddressCivicMatch', 'AddressStreetNameMatch', 'AddressCityMatch', 'AddressPostalCodeMatch', 'AddressProvinceMatch'];
  let matchesForDisplay = {};
  let fullMatches = _.get(kycResultAsJSON, 'single_source.full_matches', []);
  let partialMatches = _.get(kycResultAsJSON, 'single_source.partial_matches', []);
  let missingMatches = _.get(kycResultAsJSON, 'single_source.missing_matches', []);

  if (allCurrentAddressFieldsAreFullMatches()) {
    condenseCurrentAddressFields();
    missingMatches = removeFromMissingMatches('FirstFormerAddressMatch', 'SecondFormerAddressMatch');
  } else if (fullMatches.includes('FirstFormerAddressMatch')) {
    missingMatches = removeFromMissingMatches('SecondFormerAddressMatch');
  } else if (fullMatches.includes('SecondFormerAddressMatch')) {
    missingMatches = removeFromMissingMatches('FirstFormerAddressMatch');
    partialMatches = removeFromPartialMatches('FirstFormerAddressMatch');
  } else {
    missingMatches = removeFromMissingMatches('FirstFormerAddressMatch', 'SecondFormerAddressMatch');
    partialMatches = removeFromPartialMatches('FirstFormerAddressMatch', 'SecondFormerAddressMatch');
  }

  if (firstNameAndLastNameAreFullMatches()) {
    condenseNameFields();
  }

  function allCurrentAddressFieldsAreFullMatches() {
    return currentAddressFields.every((addressField) => {
      return fullMatches.indexOf(addressField) > -1;
    });
  }

  function firstNameAndLastNameAreFullMatches() {
    return fullMatches.includes('FirstNameMatch') && fullMatches.includes('LastNameMatch');
  }

  function condenseCurrentAddressFields() {
    const fullMatchesWithAddressFieldsRemoved = _.difference(fullMatches, currentAddressFields);
    fullMatchesWithAddressFieldsRemoved.push('CurrentAddressMatch');
    fullMatches = fullMatchesWithAddressFieldsRemoved;
  }

  function condenseNameFields() {
    const fullMatchesWithNameFieldsRemoved = _.difference(fullMatches, ['FirstNameMatch', 'LastNameMatch']);
    fullMatchesWithNameFieldsRemoved.push('FullNameMatch');
    fullMatches = fullMatchesWithNameFieldsRemoved;
  }

  function removeFromMissingMatches(...args) {
    const fieldsToRemove = [...args];
    return _.difference(missingMatches, fieldsToRemove);
  }

  function removeFromPartialMatches(...args) {
    const fieldsToRemove = [...args];
    return _.difference(partialMatches, fieldsToRemove);
  }

  matchesForDisplay.fullMatches = fullMatches;
  matchesForDisplay.partialMatches = partialMatches;
  matchesForDisplay.missingMatches = missingMatches;

  return matchesForDisplay;
};
