<template>
  <card :title="$t('offer_promotions.branding_section.title')" icon="">
    <template slot="headerZoneRight" />
    <template slot="content">
      <offers-branding-table v-if="branding" :item="branding"></offers-branding-table>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import OffersBrandingTable from '../../detail/OffersBrandingTable';
import { mapGetters } from 'vuex';

export default {
  name: 'offer-promotion-branding',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { OffersBrandingTable },
  data() {
    return {
      branding: null,
    };
  },
  computed: {
    ...mapGetters('offers', ['offerPromotion']),
  },
  watch: {
    async offerPromotion() {
      await this.fetchBranding();
    },
  },
  methods: {
    async fetchBranding() {
      if (this.offerPromotion.branding_id) {
        this.branding = await this.executeAction({ action: 'branding/getBranding' }, this.offerPromotion.branding_id);
      } else {
        this.branding = null;
      }
    },
  },
};
</script>
