import { render, staticRenderFns } from "./LayoutNotes.vue?vue&type=template&id=5fc9dbcd&scoped=true"
import script from "./LayoutNotes.vue?vue&type=script&lang=js"
export * from "./LayoutNotes.vue?vue&type=script&lang=js"
import style0 from "./LayoutNotes.vue?vue&type=style&index=0&id=5fc9dbcd&prod&scoped=true&lang=stylus"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fc9dbcd",
  null
  
)

export default component.exports