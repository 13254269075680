<template>
  <table class="responsive-table">
    <thead>
      <tr>
        <td>{{ $t('offers.access_table.fields.email') }}</td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in items" :key="index">
        <td>
          <h5>{{ $t('offers.access_table.fields.email') }}:</h5>
          {{ item.email }}
        </td>
        <td>
          <link-secondary-action slot="labelTextLink" @click="openRemoveOfferAccessModal(item.id)">{{
            $t('offers.access_table.remove_link')
          }}</link-secondary-action>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'offers-access-table',
  props: {
    items: {
      type: Array,
    },
  },
  methods: {
    openRemoveOfferAccessModal(id) {
      this.$emit('offers_access_remove_action', id);
    },
  },
};
</script>
