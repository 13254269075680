<template>
  <card-modal
    icon=""
    :title="$t(`card.get_card_transaction_context.title`)"
    context="getCardTransactionContext"
    @submit="submit"
    @cancel="cancel"
    cancel_text="card.get_card_transaction_context.button_cancel"
    submit_text="card.get_card_transaction_context.button_save"
    :enable_submit="isFormChanged"
    :submit_working="working"
    :max-width="1024"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-layout row wrap pad-form-row-elements>
          <v-flex sm6 xs12>
            <v-text-field
              name="merchant_name"
              v-model="cardContext.merchant_name"
              v-validate="'max:50'"
              :label="$t(`card.get_card_transaction_context.fields.merchant_name`)"
              :error-messages="errors.collect('merchant_name')"
              data-vv-name="merchant_name"
              :data-vv-as="$t(`card.get_card_transaction_context.fields.merchant_name`)"
              maxlength="50"
            ></v-text-field>
          </v-flex>
          <v-flex sm6 xs12>
            <v-text-field
              name="merchant_id"
              v-model="cardContext.merchant_id"
              v-validate="'max:50'"
              :label="$t(`card.get_card_transaction_context.fields.merchant_id`)"
              :error-messages="errors.collect('merchant_id')"
              data-vv-name="merchant_id"
              :data-vv-as="$t(`card.get_card_transaction_context.fields.merchant_id`)"
              maxlength="50"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row wrap pad-form-row-elements>
          <v-flex sm6 xs12>
            <v-text-field
              name="city"
              v-model="cardContext.city"
              v-validate="'max:50'"
              :label="$t(`card.get_card_transaction_context.fields.city`)"
              :error-messages="errors.collect('city')"
              data-vv-name="city"
              :data-vv-as="$t(`card.get_card_transaction_context.fields.city`)"
              maxlength="50"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row wrap pad-form-row-elements>
          <v-flex sm6 xs12>
            <v-select
              name="state"
              v-model="cardContext.state"
              :error-messages="errors.collect('state')"
              data-vv-name="state"
              :data-vv-as="$t(`card.get_card_transaction_context.fields.state`)"
              :items="stateOptions"
              item-text="name"
              item-value="abbr"
              :label="$t(`card.get_card_transaction_context.fields.state`)"
            ></v-select>
          </v-flex>
          <v-flex sm6 xs12>
            <v-select
              name="country"
              v-model="cardContext.country"
              :error-messages="errors.collect('country')"
              data-vv-name="country"
              :data-vv-as="$t(`card.get_card_transaction_context.fields.country`)"
              :items="countryOptions"
              item-text="name"
              item-value="abbr"
              :label="$t(`card.get_card_transaction_context.fields.country`)"
            ></v-select>
          </v-flex>
        </v-layout>
        <v-layout row wrap pad-form-row-elements>
          <v-flex sm4 xs12>
            <v-text-field
              name="terminal_id"
              v-model="cardContext.terminal_id"
              v-validate="'max:50'"
              :label="$t(`card.get_card_transaction_context.fields.terminal_id`)"
              :error-messages="errors.collect('terminal_id')"
              data-vv-name="terminal_id"
              :data-vv-as="$t(`card.get_card_transaction_context.fields.terminal_id`)"
              maxlength="50"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout v-if="cardTransactionContext.wallets && cardTransactionContext.wallets.length" row wrap pad-form-row-elements>
          <v-flex sm12 xs12>
            <h4>{{ $t('card.get_card_transaction_context.results.title') }}</h4>
            <table class="responsive-table">
              <thead>
                <tr>
                  <td>{{ $t('card.get_card_transaction_context.results.wallet_definition_id') }}</td>
                  <td>{{ $t('card.get_card_transaction_context.results.wallet_definition_name') }}</td>
                  <td>{{ $t('card.get_card_transaction_context.results.store_id') }}</td>
                  <td>{{ $t('card.get_card_transaction_context.results.fuzzy_rating') }}</td>
                  <td>{{ $t('card.get_card_transaction_context.results.fuzzy_merchant_name_match') }}</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in cardTransactionContext.wallets" :key="index">
                  <td>
                    <h5>{{ $t('card.get_card_transaction_context.results.wallet_definition_id') }}:</h5>
                    <span>
                      <router-link :to="{ name: 'wallet_definition_details', params: { walletDefinitionId: item.wallet_definition_id } }">
                        {{ item.wallet_definition_id }}
                      </router-link>
                    </span>
                  </td>
                  <td>
                    <h5>{{ $t('card.get_card_transaction_context.results.wallet_definition_name') }}:</h5>
                    <span>
                      {{ item.wallet_definition_name }}
                    </span>
                  </td>
                  <td>
                    <h5>{{ $t('card.get_card_transaction_context.results.store_id') }}:</h5>
                    <router-link
                      v-if="item.store_id && item.store_id.startsWith('STR-')"
                      :to="{ name: 'store_details', params: { storeId: item.store_id } }"
                    >
                      {{ item.store_id }}
                    </router-link>
                    <span v-else-if="item.store_id">{{ item.store_id }}</span>
                    <label-none v-else>{{ $t('system.na') }}</label-none>
                  </td>
                  <td>
                    <h5>{{ $t('card.get_card_transaction_context.results.fuzzy_rating') }}:</h5>
                    <span v-if="item.fuzzy_rule_evaluation">{{ (item.fuzzy_rule_evaluation.rating * 100).toFixed(2) }}%</span>
                    <label-none v-else>{{ $t('system.na') }}</label-none>
                  </td>
                  <td>
                    <h5>{{ $t('card.get_card_transaction_context.results.fuzzy_merchant_name_match') }}:</h5>
                    <span v-if="item.fuzzy_rule_evaluation">{{ item.fuzzy_rule_evaluation.merchant_name }}</span>
                    <label-none v-else>{{ $t('system.na') }}</label-none>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-flex>
        </v-layout>
        <v-layout v-else row wrap pad-form-row-elements>
          <v-flex v-if="cardTransactionContext.wallets && cardTransactionContext.wallets.length === 0" sm12 xs12>
            <h4>{{ $t('card.get_card_transaction_context.results.title') }}</h4>
            <span>{{ $t('card.get_card_transaction_context.results.empty') }}</span>
          </v-flex>
        </v-layout>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import cardWalletTransactions from '@/components/card/detail/CardWalletTransactions.vue';

export default {
  name: 'get-card-transaction-context-action',
  $_veeValidate: {
    validator: 'new',
  },
  mixins: [actionErrorTrackable],
  data() {
    return {
      cardContext: {},
      countryOptions: [{ name: this.$t('country.canada'), abbr: 'CAN' }],
      stateOptions: [
        { name: this.$t('state.alberta'), abbr: 'AB' },
        { name: this.$t('state.british_columbia'), abbr: 'BC' },
        { name: this.$t('state.manitoba'), abbr: 'MB' },
        { name: this.$t('state.new_brunswick'), abbr: 'NB' },
        { name: this.$t('state.newfoundland_labrador'), abbr: 'NL' },
        { name: this.$t('state.nova_scotia'), abbr: 'NS' },
        { name: this.$t('state.northwest_territories'), abbr: 'NT' },
        { name: this.$t('state.nunavut'), abbr: 'NU' },
        { name: this.$t('state.ontario'), abbr: 'ON' },
        { name: this.$t('state.prince_edward_island'), abbr: 'PE' },
        { name: this.$t('state.quebec'), abbr: 'QC' },
        { name: this.$t('state.saskatchewan'), abbr: 'SK' },
        { name: this.$t('state.yukon'), abbr: 'YT' },
      ],
    };
  },
  computed: {
    cardWalletTransactions() {
      return cardWalletTransactions;
    },
    ...mapGetters('card', ['card', 'cardTransactionContext']),
    isFormChanged() {
      return Object.keys(this.fields).some((key) => this.fields[key].changed);
    },
  },
  methods: {
    async clear() {
      this.cardContext = {};
      this.$validator.reset();

      await this.executeAction({
        action: 'card/resetCardTransactionContext',
        name: 'getCardTransactionContext',
      });
    },
    async cancel() {
      await this.clear();
      this.$emit('close');
    },
    async submit() {
      if (this.$validator.validateAll()) {
        const payload = {
          currency: 'CAD',
          card_public_token: this.card.public_token,
          merchant_id: this.cardContext.merchant_id,
          merchant_name: this.cardContext.merchant_name,
          merchant_city: this.cardContext.city,
          merchant_region: this.cardContext.state,
          merchant_country: this.cardContext.country,
          terminal_id: this.cardContext.terminal_id,
        };

        await this.executeAction({
          action: 'card/resetCardTransactionContextResults',
          name: 'getCardTransactionContext',
        });

        await this.executeAction(
          {
            action: 'card/getCardTransactionContext',
            name: 'getCardTransactionContext',
          },
          payload,
        );
      }
    },
  },
};
</script>
