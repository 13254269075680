<template>
  <div v-if="isReady">
    <h3>{{ $t(`task.list.change_application_request.title`) }}</h3>

    <changes-viewer
      i18n-prefix="task.list.change_application_request.changes"
      :changed-fields="currentChangeApplicationRequestFields"
      :original-values="cardApplication(cardApplicationId).card_applicant"
      :changes="changeApplicationRequest.changes"
    />

    <v-layout column class="change-application-request-uploaded-documents">
      <v-flex>
        <h5 class="change-application-request-uploaded-documents--title">{{ $t(`task.list.change_application_request.documents_title`) }}</h5>
        <div v-if="changeApplicationRequest.documents.length > 0" class="change-application-request-uploaded-documents--links">
          <div v-for="(item, index) in changeApplicationRequest.documents" :key="index">
            <a @click="download(item.id, item.filename)" class="break-word-wrap button-subtle" role="button">{{ item.filename }}</a>
            <span>&nbsp;({{ item.content_byte_size | bytes }})</span>
          </div>
        </div>
        <label-none v-else>{{ $t('system.na') }}</label-none>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable, security } from '@/mixins';

export default {
  name: 'change-application-request-list-item-part',

  mixins: [actionErrorTrackable, security],

  props: {
    changeRequestId: {
      type: String,
      required: true,
    },
    cardApplicationId: {
      type: String,
      required: true,
    },
  },
  async mounted() {
    this.currentChangeApplicationRequest = await this.executeAction({ action: 'selfserve/getChangeApplicationRequest' }, this.changeRequestId);
    this.currentChangeApplicationRequestFields = await this.changeApplicationRequestFieldsFunction(this.currentChangeApplicationRequest);
    await this.executeAction({ action: 'task/getCardApplication' }, this.cardApplicationId);
  },
  async unmounted() {
    this.currentChangeApplicationRequest = {};
    this.currentChangeApplicationRequestFields = [];
  },
  data() {
    return {
      currentChangeApplicationRequest: {},
      currentChangeApplicationRequestFields: [],
    };
  },
  computed: {
    ...mapGetters('task', ['cardApplication']),
    ...mapGetters('selfserve', ['changeApplicationRequestFieldsFunction']),
    isReady() {
      return (
        !!this.currentChangeApplicationRequest &&
        !!this.currentChangeApplicationRequest.change_request &&
        !!this.currentChangeApplicationRequest.change_request.id &&
        !!this.cardApplication(this.cardApplicationId)
      );
    },
    changeApplicationRequest() {
      return this.currentChangeApplicationRequest && this.currentChangeApplicationRequest.change_request;
    },
  },
  methods: {
    async download(id, filename) {
      await this.executeAction(
        { action: 'selfserve/downloadDocument' },
        {
          id,
          filename,
        },
      );
    },
  },
};
</script>

<style scoped lang="stylus">
@import './../../../../styles/variables/custom-variables.styl';

.change-application-request-uploaded-documents--title {
  margin-bottom: 0.1em;
  font-size: $basic-font-size;
}

.change-application-request-uploaded-documents--links {
  padding-bottom: $p-margin-bottom;
}
</style>
