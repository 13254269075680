<template>
  <card-modal
    icon=""
    :title="$t(`card.update_cardholder_info_action.title`)"
    context="updateCardholder"
    @submit="submit"
    @cancel="cancel"
    cancel_text="card.update_cardholder_info_action.button_cancel"
    submit_text="card.update_cardholder_info_action.button_save"
    :enable_submit="isFormChanged"
    :submit_working="working"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-text-field
          name="street"
          v-model="currentCardholder.address.street"
          v-validate="'required|max:50'"
          :label="$t(`card.update_cardholder_info_action.fields.address.street`)"
          :error-messages="errors.collect('street')"
          data-vv-name="street"
          :data-vv-as="$t(`card.update_cardholder_info_action.fields.address.street`)"
          required
          counter
          maxlength="50"
        ></v-text-field>

        <v-layout row wrap pad-form-row-elements>
          <v-flex sm4 xs12>
            <v-text-field
              name="city"
              v-model="currentCardholder.address.city"
              v-validate="'required|max:50'"
              :label="$t(`card.update_cardholder_info_action.fields.address.city`)"
              :error-messages="errors.collect('city')"
              data-vv-name="city"
              :data-vv-as="$t(`card.update_cardholder_info_action.fields.address.city`)"
              required
              counter
              maxlength="50"
            ></v-text-field>
          </v-flex>
          <v-flex sm4 xs12>
            <v-select
              name="state"
              v-model="currentCardholder.address.state"
              v-validate="'required'"
              :error-messages="errors.collect('state')"
              data-vv-name="state"
              :data-vv-as="$t(`card.update_cardholder_info_action.fields.address.state`)"
              :items="stateOptions"
              item-text="name"
              item-value="abbr"
              :label="$t(`card.update_cardholder_info_action.fields.address.state`)"
            ></v-select>
          </v-flex>
          <v-flex sm4 xs12>
            <v-select
              name="country"
              v-validate="'required'"
              v-model="currentCardholder.address.country"
              :error-messages="errors.collect('country')"
              data-vv-name="country"
              :data-vv-as="$t(`card.update_cardholder_info_action.fields.address.country`)"
              :items="countryOptions"
              item-text="name"
              item-value="abbr"
              :label="$t(`card.update_cardholder_info_action.fields.address.country`)"
            ></v-select>
          </v-flex>
        </v-layout>

        <v-layout row wrap pad-form-row-elements>
          <v-flex sm4 xs12>
            <v-text-field
              name="zip"
              v-mask-zip
              v-validate="{ required: true, regex: /^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z] [0-9][ABCEGHJ-NPRSTV-Z][0-9]$/ }"
              v-model="currentCardholder.address.zip"
              :label="$t(`card.update_cardholder_info_action.fields.address.zip`)"
              :error-messages="errors.collect('zip')"
              data-vv-name="zip"
              :data-vv-as="$t(`card.update_cardholder_info_action.fields.address.zip`)"
            ></v-text-field>
          </v-flex>
          <v-flex sm8 xs12>
            <v-text-field
              name="email"
              v-validate="'required|email'"
              v-model="currentCardholder.email"
              :label="$t(`card.update_cardholder_info_action.fields.email`)"
              :error-messages="errors.collect('email')"
              data-vv-name="email"
              :data-vv-as="$t(`card.update_cardholder_info_action.fields.email`)"
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-layout row wrap pad-form-row-elements>
          <v-flex sm4 xs12>
            <v-text-field
              name="phone"
              v-maska="'1-###-###-####'"
              v-validate="{ required: true, regex: /^1-[0-9]{3}-[0-9]{3}-[0-9]{4}$/ }"
              v-model="currentCardholder.phone"
              :label="$t(`card.update_cardholder_info_action.fields.phone`)"
              :error-messages="errors.collect('phone')"
              data-vv-name="phone"
              :data-vv-as="$t(`card.update_cardholder_info_action.fields.phone`)"
            ></v-text-field>
          </v-flex>
          <v-flex sm4 xs12>
            <v-text-field
              name="mobile"
              v-maska="'1-###-###-####'"
              v-validate="{ regex: /^1-[0-9]{3}-[0-9]{3}-[0-9]{4}$/ }"
              v-model="currentCardholder.mobile"
              :label="$t(`card.update_cardholder_info_action.fields.mobile`)"
              :error-messages="errors.collect('mobile')"
              data-vv-name="mobile"
              :data-vv-as="$t(`card.update_cardholder_info_action.fields.mobile`)"
            ></v-text-field>
          </v-flex>
          <v-flex sm4 xs12>
            <v-select
              name="language"
              v-validate="'required'"
              v-model="currentCardholder.language"
              :error-messages="errors.collect('language')"
              data-vv-name="language"
              :data-vv-as="$t(`card.update_cardholder_info_action.fields.language`)"
              :items="languageOptions"
              item-text="name"
              item-value="abbr"
              :label="$t(`card.update_cardholder_info_action.fields.language`)"
            ></v-select>
          </v-flex>
        </v-layout>

        <v-layout>
          <v-flex sm4 xs12>
            <v-select
              name="alerts"
              v-model="currentCardholder.settings.alerts"
              v-validate="{ required: true, max: 5 }"
              :error-messages="errors.collect('alerts')"
              data-vv-name="alerts"
              :data-vv-as="$t(`card.update_cardholder_info_action.fields.settings.alerts`)"
              :items="alertsOptions"
              item-text="name"
              item-value="abbr"
              :label="$t(`card.update_cardholder_info_action.fields.settings.alerts`)"
            ></v-select>
          </v-flex>
        </v-layout>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'update-cardholder-info-action',
  $_veeValidate: {
    validator: 'new',
  },
  mixins: [actionErrorTrackable],
  props: {
    publicToken: {
      type: String,
      required: true,
    },
    cardholder: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentCardholder: mapCardholder(this.cardholder),
      countryOptions: [{ name: this.$t('country.canada'), abbr: 'CA' }],
      stateOptions: [
        { name: this.$t('state.alberta'), abbr: 'AB' },
        { name: this.$t('state.british_columbia'), abbr: 'BC' },
        { name: this.$t('state.manitoba'), abbr: 'MB' },
        { name: this.$t('state.new_brunswick'), abbr: 'NB' },
        { name: this.$t('state.newfoundland_labrador'), abbr: 'NL' },
        { name: this.$t('state.nova_scotia'), abbr: 'NS' },
        { name: this.$t('state.northwest_territories'), abbr: 'NT' },
        { name: this.$t('state.nunavut'), abbr: 'NU' },
        { name: this.$t('state.ontario'), abbr: 'ON' },
        { name: this.$t('state.prince_edward_island'), abbr: 'PE' },
        { name: this.$t('state.quebec'), abbr: 'QC' },
        { name: this.$t('state.saskatchewan'), abbr: 'SK' },
        { name: this.$t('state.yukon'), abbr: 'YT' },
      ],
      languageOptions: [
        { name: this.$t('language.french'), abbr: 'fr' },
        { name: this.$t('language.english'), abbr: 'en' },
      ],
      alertsOptions: [
        { name: this.$t('code.card.cardholder.settings.alerts.true'), abbr: true },
        { name: this.$t('code.card.cardholder.settings.alerts.false'), abbr: false },
      ],
    };
  },
  computed: {
    ...mapGetters('card', ['card']),
    isFormChanged() {
      return Object.keys(this.fields).some((key) => this.fields[key].changed);
    },
  },
  watch: {
    cardholder() {
      this.currentCardholder = mapCardholder(this.cardholder);
    },
  },
  methods: {
    clear() {
      this.resetForm();
      this.$validator.reset();
    },
    resetForm() {
      this.currentCardholder = mapCardholder(this.card.cardholder);
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    success() {
      this.clear();
      this.$emit('action');
    },
    async validationComplete() {
      let isValid = false;

      if (await this.$validator.validateAll()) {
        if (!this.currentCardholder.mobile) {
          this.currentCardholder.mobile = null;
        }
        isValid = true;
      }
      return isValid;
    },
    async submit() {
      if (await this.validationComplete()) {
        await this.executeAction(
          {
            action: 'card/updateCardholder',
            name: 'updateCardholder',
            success: this.success,
          },
          {
            token: this.publicToken,
            cardholder: this.currentCardholder,
          },
        );
      }
    },
  },
};

function mapCardholder(source) {
  return {
    address: {
      zip: source.address.zip,
      street: source.address.street,
      city: source.address.city,
      country: source.address.country,
      state: source.address.state,
    },
    phone: source.phone,
    mobile: source.mobile,
    email: source.email,
    language: source.language,
    settings: {
      alerts: source.settings.alerts,
    },
  };
}
</script>
