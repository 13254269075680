<template>
  <card :title="$t('workstation_registration.details.workstation_registration_information_section.title')" icon="fal fa-store">
    <template slot="headerZoneRight">
      <label-text
        :label="`${$t('workstation_registration.details.workstation_registration_information_section.id')}`"
        :value="workstationRegistrationRequest.id"
      >
        <p slot="labelTextLink" class="label-text--value">
          <v-layout row>
            {{ workstationRegistrationRequest.id }}
            <button class="clipboard" v-clipboard:copy="workstationRegistrationRequest.id ? workstationRegistrationRequest.id : ''">
              <v-icon small>file_copy</v-icon>
            </button>
          </v-layout>
        </p>
      </label-text>

      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text
        :label="$t('workstation_registration.details.workstation_registration_information_section.workstation_id')"
        :value="workstationRegistrationRequest.workstation_id"
      >
        <router-link
          slot="labelTextLink"
          :to="{
            name: 'workstation_details',
            params: { workstationId: workstationRegistrationRequest.workstation_id },
          }"
        >
          <span>{{ workstationRegistrationRequest.workstation_name }} ({{ workstationRegistrationRequest.workstation_id }})</span>
        </router-link>
      </label-text>

      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text
        :label="`${$t('workstation_registration.details.workstation_registration_information_section.token')}`"
        :value="workstationRegistrationRequest.token"
      >
        <p slot="labelTextLink" class="label-text--value">
          <v-layout row>
            {{ workstationRegistrationRequest.token }}
            <button class="clipboard" v-clipboard:copy="workstationRegistrationRequest.token ? workstationRegistrationRequest.token : ''">
              <v-icon small>file_copy</v-icon>
            </button>
          </v-layout>
        </p>
      </label-text>

      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="$t('workstation_registration.details.workstation_registration_information_section.status')">
        <workstation-registration-status-view slot="labelTextLink" :status="workstationRegistrationRequest.status" />
      </label-text>

      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <link-secondary-action slot="labelTextLink">
        <button-secondary-actions
          :text="$t(`workstation_registration.details.workstation_registration_information_section.actions`)"
          :actions="actions"
        >
        </button-secondary-actions>
      </link-secondary-action>

      <modal-action :show-modal="showRejectModal"
        ><reject-workstation-registration-action
          v-if="showRejectModal"
          :workstation-registration-id="workstationRegistrationRequest.id"
          @close="closeRejectModal"
          @rejected="rejectSuccess"
        ></reject-workstation-registration-action
      ></modal-action>

      <modal-confirm
        :show-modal="showApproveModal"
        :title="$t('workstation_registration.approve_action.confirm.title')"
        context="approve"
        :text="$t('workstation_registration.approve_action.confirm.text')"
        :cancel_text="'workstation_registration.approve_action.confirm.button_cancel'"
        :submit_text="'workstation_registration.approve_action.confirm.button_submit'"
        :submit_working="working"
        @submit="approve"
        @close="closeApproveModal"
      ></modal-confirm>
    </template>
    <template slot="content">
      <v-layout row>
        <v-flex xs3 v-if="workstationRegistrationRequest.request_date">
          <label-text
            :label="$t('workstation_registration.details.workstation_registration_information_section.request_date')"
            :value="workstationRegistrationRequest.request_date | formatShortDateTimeInverted"
          />
        </v-flex>
        <v-flex xs3 v-if="isApprovedOrRejected()">
          <label-text
            :label="$t('workstation_registration.details.workstation_registration_information_section.approval_date')"
            :value="workstationRegistrationRequest.approval_date | formatShortDateTimeInverted"
          />
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs3 v-if="workstationRegistrationRequest.request_date">
          <label-text
            :label="$t('workstation_registration.details.workstation_registration_information_section.request_app_key')"
            :value="workstationRegistrationRequest.request_app_key"
          >
            <p slot="labelTextLink" class="label-text--value">
              <app-name v-if="workstationRegistrationRequest.request_app_key" :app_key="workstationRegistrationRequest.request_app_key" />
              <label-none v-else>{{ $t('system.na') }}</label-none>
            </p>
          </label-text>
        </v-flex>
        <v-flex xs3 v-if="isApprovedOrRejected()">
          <label-text
            :label="$t('workstation_registration.details.workstation_registration_information_section.approval_by_app_key')"
            :value="workstationRegistrationRequest.approval_by_app_key"
          >
            <p slot="labelTextLink" class="label-text--value">
              <app-name v-if="workstationRegistrationRequest.approval_by_app_key" :app_key="workstationRegistrationRequest.approval_by_app_key" />
              <label-none v-else>{{ $t('system.na') }}</label-none>
            </p>
          </label-text>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs3 v-if="workstationRegistrationRequest.request_date">
          <label-text
            :label="$t('workstation_registration.details.workstation_registration_information_section.request_clerk_id')"
            :value="workstationRegistrationRequest.request_clerk_id"
          >
            <p slot="labelTextLink" class="label-text--value">
              <clerk-name v-if="workstationRegistrationRequest.request_clerk_id" :clerk_id="workstationRegistrationRequest.request_clerk_id" />
              <label-none v-else>{{ $t('system.na') }}</label-none>
            </p>
          </label-text>
        </v-flex>
        <v-flex xs3 v-if="isApprovedOrRejected()">
          <label-text
            :label="$t('workstation_registration.details.workstation_registration_information_section.approval_by_clerk_id')"
            :value="workstationRegistrationRequest.approval_by_clerk_id"
          >
            <p slot="labelTextLink" class="label-text--value">
              <clerk-name
                v-if="workstationRegistrationRequest.approval_by_clerk_id"
                :clerk_id="workstationRegistrationRequest.approval_by_clerk_id"
              />
              <label-none v-else>{{ $t('system.na') }}</label-none>
            </p>
          </label-text>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs3 v-if="workstationRegistrationRequest.request_date">
          <label-text
            :label="$t('workstation_registration.details.workstation_registration_information_section.request_location')"
            :value="workstationRegistrationRequest.request_ip + ' (' + (workstationRegistrationRequest.request_ip_location || 'Local Network') + ')'"
          />
        </v-flex>
        <v-flex xs3 v-if="isApprovedOrRejected()">
          <label-text
            :label="$t('workstation_registration.details.workstation_registration_information_section.approval_by_workstation_id')"
            :value="workstationRegistrationRequest.approval_by_workstation_id"
          >
            <p slot="labelTextLink" class="label-text--value">
              <workstation-name
                v-if="workstationRegistrationRequest.approval_by_workstation_id"
                :workstation_id="workstationRegistrationRequest.approval_by_workstation_id"
              />
              <label-none v-else>{{ $t('system.na') }}</label-none>
            </p>
          </label-text>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs3 v-if="workstationRegistrationRequest.request_date"></v-flex>
        <v-flex xs3 v-if="isRejected()">
          <label-text
            :label="$t('workstation_registration.details.workstation_registration_information_section.reason')"
            :value="workstationRegistrationRequest.rejected_reason"
          />
        </v-flex>
      </v-layout>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';
import WorkstationRegistrationStatusView from '../WorkstationRegistrationStatusView.vue';
import RejectWorkstationRegistrationAction from '../actions/RejectWorkstationRegistrationAction.vue';
import AppName from '@/components/system/AppName.vue';
import LabelNone from '@/components/system/Labels/LabelNone.vue';

export default {
  name: 'workstation-registration-information',
  components: { LabelNone, AppName, RejectWorkstationRegistrationAction, WorkstationRegistrationStatusView },
  mixins: [security, actionErrorTrackable, disableWithError],
  data() {
    return {
      message: '',
      showRejectModal: false,
      showApproveModal: false,
      actions: [
        {
          slotName: 'approveModal',
          name: this.$t('workstation_registration.details.workstation_registration_information_section.approve'),
          action: () => {
            this.openApproveModal();
          },
          isAvailable: () => this.canManageOffer() && this.workstationRegistrationRequest.status === 'pending', // TODO change
          isDisabled: () => !this.showApproveFeature(),
        },
        {
          slotName: 'rejectModal',
          name: this.$t('workstation_registration.details.workstation_registration_information_section.reject'),
          action: () => {
            this.openRejectModal();
          },
          isAvailable: () => this.canManageOffer(), // TODO change
          isDisabled: () => !this.showRejectFeature(),
        },
      ],
    };
  },
  computed: {
    ...mapGetters('partnerSecurity', ['workstationRegistrationRequest']),
  },
  methods: {
    openApproveModal() {
      this.showApproveModal = true;
    },
    closeApproveModal() {
      this.showApproveModal = false;
    },
    async approve() {
      await this.executeAction(
        {
          action: 'partnerSecurity/approveWorkstationRegistrationRequest',
          name: 'approve',
          success: this.approveSuccess,
        },
        this.workstationRegistrationRequest.id,
      );
    },
    async approveSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'workstation_registration.approve_action.success' });
      this.closeApproveModal();
    },
    openRejectModal(id) {
      this.showRejectModal = true;
    },
    closeRejectModal() {
      this.showRejectModal = false;
    },
    async reject() {
      await this.executeAction(
        {
          action: 'partnerSecurity/rejectWorkstationRegistrationRequest',
          name: 'reject',
          success: this.rejectSuccess,
        },
        {
          id: this.workstationRegistrationRequest.id,
          message: this.message,
        },
      );
    },
    async rejectSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'workstation_registration.reject_action.success' });
      this.closeRejectModal();
    },
    showApproveFeature() {
      return this.workstationRegistrationRequest.status !== 'approved';
    },
    showRejectFeature() {
      return this.workstationRegistrationRequest.status === 'pending' || this.workstationRegistrationRequest.status === 'created';
    },
    isRejected() {
      return this.workstationRegistrationRequest.status === 'rejected';
    },
    isApprovedOrRejected() {
      return this.workstationRegistrationRequest.status === 'rejected' || this.workstationRegistrationRequest.status === 'approved';
    },
  },
};
</script>
