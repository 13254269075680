<template>
  <div>
    <shared-stores-table :items="items" :exclude="['franchise', 'action']" />
    <div class="text-xs-center">
      <v-pagination v-if="franchiseStoresPageCount > 1" v-model="pagination" :length="franchiseStoresPageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import { mapGetters } from 'vuex';
import SharedStoresTable from '@/components/shared_tables/SharedStoresTable';

export default {
  name: 'franchise-stores-table',
  mixins: [actionErrorTrackable, security],
  components: { SharedStoresTable },
  props: {
    items: {
      type: Array,
      required: true,
    },
    franchiseId: {
      type: String,
    },
  },
  data() {
    return {
      pagination: 1,
    };
  },
  mounted() {
    this.pagination = this.franchiseStoresPageNumber;
  },
  watch: {
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'franchise/listFranchiseStores',
        },
        {
          id: this.franchiseId,
          page: newValue,
        },
      );
    },
  },
  computed: {
    ...mapGetters('franchise', ['franchiseStoresPageCount', 'franchiseStoresPageNumber']),
  },
};
</script>
