import _ from 'lodash';
import Vue from 'vue';
import dormancyType from '@/enums/dormancy-type';
import OfferTrackingLinkType from '../../enums/offer-tracking-link-type';

const defaultPage = 1;
const paginationLimit = 20;
const offerPublishersPaginationLimit = 20;
const offerInstantCardProjectsPaginationLimit = 20;
const offerWebhookListenersPaginationLimit = 20;
const offerNotesPaginationLimit = 20;
const offerTrackingLinksPaginationLimit = 20;

function percentageToFloat(percent, numDecimals) {
  if (percent) {
    const divided = percent / 100;
    return parseFloat(divided.toFixed(numDecimals));
  }
}

function convertToPercent(fraction, numFractions) {
  return (fraction * 100).toFixed(numFractions);
}

function convertOfferValuesToPercent(offer) {
  if (_.get(offer, 'incentive.bonus_percent')) {
    offer.incentive.bonus_percent = convertToPercent(offer.incentive.bonus_percent, 0);
  }

  if (_.get(offer, 'funding.bonus.publisher')) {
    offer.funding.bonus.publisher = convertToPercent(offer.funding.bonus.publisher, 2);
  }

  if (_.get(offer, 'funding.bonus.contributor')) {
    offer.funding.bonus.contributor = convertToPercent(offer.funding.bonus.contributor, 2);
  }

  if (_.get(offer, 'funding.bonus.store')) {
    offer.funding.bonus.store = convertToPercent(offer.funding.bonus.store, 2);
  }

  if (_.get(offer, 'purchase_fee.percent_fee.ratio')) {
    offer.purchase_fee.percent_fee.ratio = convertToPercent(offer.purchase_fee.percent_fee.ratio, 2);
  }

  if (_.get(offer, 'transaction_fee.percent_fee.ratio')) {
    offer.transaction_fee.percent_fee.ratio = convertToPercent(offer.transaction_fee.percent_fee.ratio, 2);
  }

  if (_.get(offer, 'corporate.percent_fee')) {
    offer.corporate.percent_fee = convertToPercent(offer.corporate.percent_fee, 2);
  }
}

function convertOfferPayload(payload) {
  if (_.get(payload, 'incentive.bonus_percent')) {
    payload.incentive.bonus_percent = percentageToFloat(payload.incentive.bonus_percent, 2);
  }

  if (_.get(payload, 'funding.bonus.publisher')) {
    payload.funding.bonus.publisher = percentageToFloat(payload.funding.bonus.publisher, 4);
  }

  if (_.get(payload, 'funding.bonus.contributor')) {
    payload.funding.bonus.contributor = percentageToFloat(payload.funding.bonus.contributor, 4);
  }

  if (_.get(payload, 'funding.bonus.store')) {
    payload.funding.bonus.store = percentageToFloat(payload.funding.bonus.store, 4);
  }

  if (_.get(payload, 'fee_payment_method') === 'monthly_automated_pad') {
    if (_.get(payload, 'purchase_fee.percent_fee.ratio')) {
      payload.purchase_fee.percent_fee.ratio = percentageToFloat(payload.purchase_fee.percent_fee.ratio, 4);
    }

    if (_.get(payload, 'transaction_fee.percent_fee.ratio')) {
      payload.transaction_fee.percent_fee.ratio = percentageToFloat(payload.transaction_fee.percent_fee.ratio, 4);
    }
  } else {
    if (payload.purchase_fee) {
      payload.purchase_fee.percent_fee.ratio = null;
      payload.purchase_fee.percent_fee.scope = null;
      payload.purchase_fee.percent_fee.payer = 'none';

      payload.purchase_fee.fixed_fee.amount = null;
      payload.purchase_fee.fixed_fee.payer = 'none';
    }

    if (payload.transaction_fee) {
      payload.transaction_fee.percent_fee.ratio = null;
      payload.transaction_fee.percent_fee.scope = null;
      payload.transaction_fee.percent_fee.payer = 'none';

      payload.transaction_fee.fixed_fee.amount = null;
      payload.transaction_fee.fixed_fee.payer = 'none';
    }
  }

  if (_.get(payload, 'corporate.percent_fee')) {
    payload.corporate.percent_fee = percentageToFloat(payload.corporate.percent_fee, 4);
  }

  if (_.get(payload, 'consumer.purchase_limit.min')) {
    payload.consumer.purchase_limit.min = Number(payload.consumer.purchase_limit.min);
  }

  if (_.get(payload, 'consumer.purchase_limit.max')) {
    payload.consumer.purchase_limit.max = Number(payload.consumer.purchase_limit.max);
  }

  if (_.get(payload, 'user_fee.dormancy.last_activity_trigger_in_months')) {
    payload.user_fee.dormancy.last_activity_trigger_in_months = Number(payload.user_fee.dormancy.last_activity_trigger_in_months);
  }

  if (_.get(payload, 'user_fee.dormancy.fixed_fee')) {
    payload.user_fee.dormancy.fixed_fee = Number(payload.user_fee.dormancy.fixed_fee);
  }

  if (_.get(payload, 'incentive.minimum_payment')) {
    payload.incentive.minimum_payment = Number(payload.incentive.minimum_payment);
  }

  if (_.get(payload, 'incentive.step')) {
    payload.incentive.step = Number(payload.incentive.step);
  }

  if (_.get(payload, 'incentive.default_payment')) {
    payload.incentive.default_payment = Number(payload.incentive.default_payment);
  }

  if (_.get(payload, 'incentive.user_inventory_limit')) {
    payload.incentive.user_inventory_limit = Number(payload.incentive.user_inventory_limit);
  }

  if (_.get(payload, 'incentive.bonus_amount')) {
    payload.incentive.bonus_amount = Number(payload.incentive.bonus_amount);
  }

  if (_.get(payload, 'incentive.payment_amount')) {
    payload.incentive.payment_amount = Number(payload.incentive.payment_amount);
  }

  if (_.get(payload, 'corporate.fixed_fee')) {
    payload.corporate.fixed_fee = Number(payload.corporate.fixed_fee);
  }

  if (_.get(payload, 'incentive.bonus_end_date')) {
    payload.incentive.bonus_end_time = '23:59';
  }

  if (_.get(payload, 'incentive.closure_end_date')) {
    payload.incentive.closure_end_time = '23:59';
  }

  if (_.get(payload, 'availability.end_date')) {
    payload.availability.end_time = '23:59';
  }

  if (_.isEmpty(payload.reference_id)) {
    payload.reference_id = null;
  }
}

export default {
  namespaced: true,
  state: {
    offers: [],
    offer: { system_status: {}, user_fee: {}, incentive: {}, branding: {}, features: {} },
    offerStatistics: {},
    offerStores: [],
    offerPublishers: [],
    offerAccesses: [],
    offerInstantCardProjects: [],
    offerVouchers: [],
    offerWebhookListeners: [],
    offer_notes: [],
    offerTrackingLinks: [],
    offerPromotion: {
      bonus: {
        duration: {},
        step: {},
        availability: {},
        funding: {},
      },
    },
    offerPromotions: [],

    incentiveRefundMethods: ['open_wallet', 'no_refund'],
    customerTypes: ['demo', 'hello', 'local_purchase', 'other', 'sports_entertainment', 'tourism', 'towers_malls', 'workplace'],
    inventoryTypes: ['limited', 'unlimited'],
    incentiveDurationModels: ['relative', 'fixed'],
    structureModels: [true, false],
    fundingPayers: ['cardholder', 'publisher', 'contributor', 'store'],
    fundingTriggers: ['upfront', 'purchase', 'transaction'],
    fundingPayerPaymentValues: {
      other: {
        payer: 'other',
        payment_trigger: 'upfront',
      },
      contributor: {
        payer: 'contributor',
        payment_trigger: 'transaction',
      },
      cardholder: {
        payer: 'cardholder',
        payment_trigger: 'transaction',
      },
      publisher: {
        payer: 'publisher',
        payment_trigger: 'transaction',
      },
      store: {
        payer: 'store',
        payment_trigger: 'transaction',
      },
      contributor_issuance: {
        payer: 'contributor',
        payment_trigger: 'issuance',
      },
    },
    bonusFundingPayers: ['cardholder', 'contributor', 'store'],
    giftCertificateFundingPayers: ['other', 'contributor', 'contributor_issuance'],

    feePaymentMethods: ['none', 'upfront', 'monthly_manual_pad', 'monthly_automated_pad'],
    purchasePercentFeeScope: ['payment', 'bonus', 'voucher'],
    purchasePercentFeePayer: ['none', 'publisher', 'contributor'],
    purchaseFixedFeePayer: ['none', 'publisher', 'contributor'],

    transactionPercentFeeScope: ['voucher', 'transaction'],
    transactionPercentFeePayer: ['none', 'publisher', 'contributor', 'store'],
    transactionFixedFeePayer: ['none', 'publisher', 'contributor', 'store'],

    voucherStructures: ['payment', 'bonus'],

    userFeeStrategies: [dormancyType.NONE, dormancyType.DORMANCY],

    offerTrackingLinkTypes: [OfferTrackingLinkType.CONSUMER, OfferTrackingLinkType.CORPORATE],

    // NOTE (SC): the business_id remains the contributor_business_id. Still needed?
    list: {
      pageNumber: defaultPage,
      pageCount: 0,
      itemCount: 0,
      keyword: '',
      partner_id: '',
      business_id: '',
      owner_business_id: '',
      status: '',
      type: '',
      visibility: '',
    },

    listOfferPublishers: {
      keyword: '',
      status: '',
      category: '',
      readiness: '',
      pageNumber: defaultPage,
      pageCount: 0,
      itemCount: 0,
    },

    listOfferInstantCardProjects: {
      pageNumber: defaultPage,
      pageCount: 0,
      itemCount: 0,
    },

    listOfferWebhookListeners: {
      pageNumber: 1,
      pageCount: 0,
      itemCount: 0,
    },

    offer_notes_list: {
      pageNumber: 1,
      pageCount: 0,
      itemCount: 0,
    },

    listOfferTrackingLinks: {
      pageNumber: 1,
      pageCount: 0,
      itemCount: 0,
    },

    listOfferPromotions: {
      pageNumber: defaultPage,
      pageCount: 0,
      itemCount: 0,
    },
  },
  getters: {
    offers: (state) => state.offers,
    offer: (state) => state.offer,
    offerPromotion: (state) => state.offerPromotion,
    offerStatsPublisherCount: (state) => _.get(state, 'offerStatistics.publisher_count', 0),
    offerStatsStoreCount: (state) => _.get(state, 'offerStatistics.store_count', 0),
    offerAccesses: (state) => state.offerAccesses,
    listPageCount: (state) => state.list.pageCount,
    listItemCount: (state) => state.list.itemCount,
    listPageNumber: (state) => state.list.pageNumber,
    listKeyword: (state) => state.list.keyword,
    listPartnerId: (state) => state.list.partner_id,
    listBusinessId: (state) => state.list.business_id,
    listOwnerBusinessId: (state) => state.list.owner_business_id,
    listStatus: (state) => state.list.status,
    listType: (state) => state.list.type,
    listVisibility: (state) => state.list.visibility,
    offerStores: (state) => state.offerStores,
    offerVouchers: (state) => state.offerVouchers,
    structureModels: (state) => state.structureModels,
    incentiveRefundMethods: (state) => state.incentiveRefundMethods,
    customerTypes: (state) => state.customerTypes,
    inventoryTypes: (state) => state.inventoryTypes,
    incentiveDurationModels: (state) => state.incentiveDurationModels,
    feePaymentMethods: (state) => state.feePaymentMethods,
    fundingPayers: (state) => state.fundingPayers,
    fundingTriggers: (state) => state.fundingTriggers,
    fundingPayerPaymentValues: (state) => state.fundingPayerPaymentValues,
    bonusFundingPayers: (state) => state.bonusFundingPayers,
    giftCertificateFundingPayers: (state) => state.giftCertificateFundingPayers,
    purchasePercentFeeScope: (state) => state.purchasePercentFeeScope,
    purchasePercentFeePayer: (state) => state.purchasePercentFeePayer,
    purchaseFixedFeePayer: (state) => state.purchaseFixedFeePayer,
    transactionPercentFeeScope: (state) => state.transactionPercentFeeScope,
    transactionPercentFeePayer: (state) => state.transactionPercentFeePayer,
    transactionFixedFeePayer: (state) => state.transactionFixedFeePayer,
    userFeeStrategies: (state) => state.userFeeStrategies,
    offerTrackingLinkTypes: (state) => state.offerTrackingLinkTypes,
    voucherStructures: (state) => state.voucherStructures,

    offerPublishers: (state) => state.offerPublishers,
    offerPublishersKeyword: (state) => state.listOfferPublishers.keyword,
    offerPublishersStatus: (state) => state.listOfferPublishers.status,
    offerPublishersCategory: (state) => state.listOfferPublishers.category,
    offerPublishersReadiness: (state) => state.listOfferPublishers.readiness,
    offerPublishersPageCount: (state) => state.listOfferPublishers.pageCount,
    offerPublishersItemCount: (state) => state.listOfferPublishers.itemCount,
    offerPublishersPageNumber: (state) => state.listOfferPublishers.pageNumber,

    offerInstantCardProjects: (state) => state.offerInstantCardProjects,
    offerInstantCardProjectsPageNumber: (state) => state.listOfferInstantCardProjects.pageNumber,
    offerInstantCardProjectsPageCount: (state) => state.listOfferInstantCardProjects.pageCount,
    offerInstantCardProjectsItemCount: (state) => state.listOfferInstantCardProjects.itemCount,

    offerWebhookListeners: (state) => state.offerWebhookListeners,
    offerWebhookListenersPageNumber: (state) => state.listOfferWebhookListeners.pageNumber,
    offerWebhookListenersPageCount: (state) => state.listOfferWebhookListeners.pageCount,
    offerWebhookListenersItemCount: (state) => state.listOfferWebhookListeners.itemCount,

    offerNotes: (state) => state.offer_notes,
    listOfferNotesPageCount: (state) => state.offer_notes_list.pageCount,
    listOfferNotesItemCount: (state) => state.offer_notes_list.itemCount,
    listOfferNotesPageNumber: (state) => state.offer_notes_list.pageNumber,

    offerTrackingLinks: (state) => state.offerTrackingLinks,
    offerTrackingLinksPageNumber: (state) => state.listOfferTrackingLinks.pageNumber,
    offerTrackingLinksPageCount: (state) => state.listOfferTrackingLinks.pageCount,
    offerTrackingLinksItemCount: (state) => state.listOfferTrackingLinks.itemCount,

    offerPromotions: (state) => state.offerPromotions,
    offerPromotionsPageNumber: (state) => state.listOfferPromotions.pageNumber,
    offerPromotionsPageCount: (state) => state.listOfferPromotions.pageCount,
    offerPromotionsItemCount: (state) => state.listOfferPromotions.itemCount,
  },
  mutations: {
    offers(state, offers) {
      state.offers = offers;
    },
    offer(state, offer) {
      if (!offer) {
        offer = { system_status: {}, user_fee: {}, incentive: {}, branding: {}, features: {} };
      }
      if (!offer.timezone) {
        offer.timezone = 'America/Toronto';
      }

      state.offer = offer;
    },
    offerPromotion(state, offerPromotion) {
      if (!offerPromotion) {
        offerPromotion = {
          bonus: {
            duration: {},
            step: {},
            availability: {},
            funding: {},
          },
        };
      }
      state.offerPromotion = offerPromotion;
    },
    offerStatistics(state, offerStatistics) {
      state.offerStatistics = offerStatistics;
    },
    offerAccess(state, offerAccess) {
      state.offerAccesses.push(offerAccess);
    },
    offerAccesses(state, offerAccesses) {
      state.offerAccesses = _.sortBy(offerAccesses, 'email');
    },
    offerStores(state, stores) {
      state.offerStores = stores;
    },
    offerVouchers(state, offerVouchers) {
      state.offerVouchers = offerVouchers;
    },
    listPageCount(state, pageCount) {
      state.list.pageCount = pageCount;
    },
    listItemCount(state, itemCount) {
      state.list.itemCount = itemCount;
    },
    listPageNumber(state, pageNumber) {
      state.list.pageNumber = pageNumber;
    },
    listKeyword(state, keyword) {
      state.list.keyword = keyword;
    },
    listPartnerId(state, partner_id) {
      state.list.partner_id = partner_id;
    },
    listBusinessId(state, business_id) {
      state.list.business_id = business_id;
    },
    listOwnerBusinessId(state, owner_business_id) {
      state.list.owner_business_id = owner_business_id;
    },
    listStatus(state, status) {
      state.list.status = status;
    },
    listType(state, type) {
      state.list.type = type;
    },
    listVisibility(state, visibility) {
      state.list.visibility = visibility;
    },
    offerPublishers(state, offerPublishers) {
      state.offerPublishers = offerPublishers;
    },
    offerPublishersKeyword(state, keyword) {
      state.listOfferPublishers.keyword = keyword;
    },
    offerPublishersStatus(state, status) {
      state.listOfferPublishers.status = status;
    },
    offerPublishersCategory(state, category) {
      state.listOfferPublishers.category = category;
    },
    offerPublishersReadiness(state, readiness) {
      state.listOfferPublishers.readiness = readiness;
    },
    offerPublishersPageCount(state, pageCount) {
      state.listOfferPublishers.pageCount = pageCount;
    },
    offerPublishersItemCount(state, itemCount) {
      state.listOfferPublishers.itemCount = itemCount;
    },
    offerPublishersPage(state, page) {
      state.listOfferPublishers.pageNumber = page;
    },
    offerInstantCardProjects(state, offerInstantCardProjects) {
      state.offerInstantCardProjects = offerInstantCardProjects;
    },
    offerInstantCardProjectsPage(state, page) {
      state.listOfferInstantCardProjects.pageNumber = page;
    },
    offerInstantCardProjectsPageCount(state, pageCount) {
      state.listOfferInstantCardProjects.pageCount = pageCount;
    },
    offerInstantCardProjectsItemCount(state, itemCount) {
      state.listOfferInstantCardProjects.itemCount = itemCount;
    },
    offerWebhookListeners(state, webhooks) {
      state.offerWebhookListeners = webhooks;
    },
    offerWebhookListenersPageCount(state, pageCount) {
      state.listOfferWebhookListeners.pageCount = pageCount;
    },
    offerWebhookListenersItemCount(state, itemCount) {
      state.listOfferWebhookListeners.itemCount = itemCount;
    },
    offerWebhookListenersPage(state, page) {
      state.listOfferWebhookListeners.pageNumber = page;
    },
    offerNotes(state, notes) {
      state.offer_notes = notes;
    },
    listOfferNotesPageCount(state, pageCount) {
      state.offer_notes_list.pageCount = pageCount;
    },
    listOfferNotesItemCount(state, itemCount) {
      state.offer_notes_list.itemCount = itemCount;
    },
    listOfferNotesPage(state, page) {
      state.offer_notes_list.pageNumber = page;
    },
    offerTrackingLinks(state, offerTrackingLinks) {
      state.offerTrackingLinks = offerTrackingLinks;
    },
    offerTrackingLinksPageCount(state, pageCount) {
      state.listOfferTrackingLinks.pageCount = pageCount;
    },
    offerTrackingLinksItemCount(state, itemCount) {
      state.listOfferTrackingLinks.itemCount = itemCount;
    },
    offerTrackingLinksPageNumber(state, page) {
      state.listOfferTrackingLinks.pageNumber = page;
    },
    offerPromotions(state, offerPromotions) {
      state.offerPromotions = offerPromotions;
    },
    offerPromotionsPageNumber(state, page) {
      state.listOfferPromotions.pageNumber = page;
    },
    offerPromotionsPageCount(state, pageCount) {
      state.listOfferPromotions.pageCount = pageCount;
    },
    offerPromotionsItemCount(state, itemCount) {
      state.listOfferPromotions.itemCount = itemCount;
    },
  },
  actions: {
    async autocompleteSearch({}, { page, limit, keyword }) {
      const offersService = Vue.prototype.$services.offers;

      return offersService.searchOffers(page, limit, keyword);
    },
    async autocompleteSearchOfferPublishersByOffer({}, { offer_id, page, limit, keyword }) {
      const offersService = Vue.prototype.$services.offers;

      return offersService.searchOfferPublishersByOffer(offer_id, page, limit, keyword);
    },
    async addStoresFromOffer({ dispatch, commit }, { offer_id, publisher_id }) {
      const offersService = Vue.prototype.$services.offers;
      await offersService.addStoresFromOffer(offer_id, { publisher_id });

      await dispatch('publishers/getPublisher', publisher_id, { root: true });
    },
    async getOfferStatistics({ commit }, id) {
      const offersService = Vue.prototype.$services.offers;
      const offerStatistics = await offersService.getOfferStatistics(id);

      commit('offerStatistics', offerStatistics);
    },
    async extendOfferVoucherExpiration({ dispatch }, params) {
      const offersService = Vue.prototype.$services.offers;
      const { id, ...payload } = params;
      const result = await offersService.extendOfferVoucherExpiration(id, payload);
      dispatch('getOffer', id);
      return result;
    },
    createBonusOffer({ commit }, params) {
      const offersService = Vue.prototype.$services.offers;
      const payload = _.cloneDeep(params);

      convertOfferPayload(payload);

      return offersService.createBonusOffer(payload);
    },
    createGiftCertificateOffer({ commit }, params) {
      const offersService = Vue.prototype.$services.offers;
      const payload = _.cloneDeep(params);

      convertOfferPayload(payload);

      return offersService.createGiftCertificateOffer(payload);
    },
    async updateBonusOffer({ dispatch }, params) {
      const offersService = Vue.prototype.$services.offers;
      const { id, ...payload } = _.cloneDeep(params);

      delete payload.availability.inventory;
      delete payload.owner_business_name;
      delete payload.contributor;
      delete payload.branding;
      delete payload.type;
      delete payload.features.hide_activation_button;
      delete payload.features.hide_purchase_button;
      delete payload.incentive.bonus_amount;
      delete payload.incentive.payment_amount;
      delete payload.publishers_count;
      delete payload.corporate;
      delete payload.consumer;
      delete payload.bonus_breakage_owner;
      delete payload.payment_breakage_owner;

      convertOfferPayload(payload);

      const result = await offersService.updateBonusOffer(id, payload);
      dispatch('getOffer', id);
      return result;
    },
    async updateGiftCertificateOffer({ dispatch }, params) {
      const offersService = Vue.prototype.$services.offers;
      const { id, ...payload } = _.cloneDeep(params);

      delete payload.availability.inventory;
      delete payload.owner_business_name;
      delete payload.contributor;
      delete payload.branding;
      delete payload.type;
      delete payload.incentive.bonus_percent;
      delete payload.incentive.minimum_payment;
      delete payload.incentive.step;
      delete payload.incentive.default_payment;
      delete payload.features.crowdfunding;
      delete payload.publishers_count;
      delete payload.corporate;
      delete payload.consumer;
      delete payload.bonus_breakage_owner;
      delete payload.payment_breakage_owner;

      convertOfferPayload(payload);

      const result = await offersService.updateGiftCertificateOffer(id, payload);
      dispatch('getOffer', id);
      return result;
    },
    async createOfferNote({ dispatch }, { offer_id, note }) {
      const offersService = Vue.prototype.$services.offers;
      await offersService.createOfferNote(offer_id, { note });
      await dispatch('listOfferNotes', { offer_id });
    },
    async deleteOfferNote({ dispatch }, { offer_id, note_id, reason }) {
      const offersService = Vue.prototype.$services.offers;
      await offersService.deleteOfferNote(offer_id, note_id, { reason });
      await dispatch('listOfferNotes', { offer_id });
    },
    async listOfferNotes({ state, commit }, { offer_id, page, limit } = {}) {
      const offersService = Vue.prototype.$services.offers;
      const thePage = page || state.offer_notes_list.pageNumber;
      const theLimit = limit || offerNotesPaginationLimit;
      const theOfferId = offer_id || '';

      const listResults = await offersService.listOfferNotes(thePage, theLimit, theOfferId);

      commit('listOfferNotesPage', thePage);
      commit('listOfferNotesPageCount', listResults.page_count);
      commit('listOfferNotesItemCount', listResults.item_count);
      commit('offerNotes', listResults.items);
    },
    async updateOfferConsumerDetails({ dispatch }, params) {
      const offersService = Vue.prototype.$services.offers;
      const payload = _.cloneDeep(params);

      convertOfferPayload(payload);

      const offer = await offersService.updateOfferConsumerDetails(payload);

      dispatch('getOffer', offer.id);
      return offer;
    },
    async updateOfferCorporateDetails({ dispatch }, params) {
      const offersService = Vue.prototype.$services.offers;
      const payload = _.cloneDeep(params);

      convertOfferPayload(payload);

      const offer = await offersService.updateOfferCorporateDetails(payload);

      dispatch('getOffer', offer.id);
      return offer;
    },
    async listOfferTrackingLinks({ state, commit }, { id, page, limit } = {}) {
      const offersService = Vue.prototype.$services.offers;
      const thePage = page || state.listOfferTrackingLinksPageCount.pageNumber || defaultPage;
      const theLimit = limit || offerTrackingLinksPaginationLimit;

      const listResults = await offersService.listOfferTrackingLinks(id, thePage, theLimit);

      commit('offerTrackingLinks', listResults.items);
      commit('offerTrackingLinksPageNumber', thePage);
      commit('offerTrackingLinksPageCount', listResults.page_count);
      commit('offerTrackingLinksItemCount', listResults.item_count);
    },
    async enableOfferTrackingLink({}, { offerTrackingLinkId, offerId }) {
      const offersService = Vue.prototype.$services.offers;

      await offersService.enableOfferTrackingLink(offerTrackingLinkId, offerId);
    },
    async disableOfferTrackingLink({}, { offerTrackingLinkId, offerId }) {
      const offersService = Vue.prototype.$services.offers;

      await offersService.disableOfferTrackingLink(offerTrackingLinkId, offerId);
    },
    async addOfferTrackingLink({}, payload) {
      const offersService = Vue.prototype.$services.offers;

      const { offerId, name, business_id, redirect_type } = payload;

      return await offersService.addOfferTrackingLink(offerId, { name, business_id, redirect_type });
    },
    async getOffer({ commit, dispatch }, id) {
      const offersService = Vue.prototype.$services.offers;
      const offer = await offersService.getOffer(id);

      convertOfferValuesToPercent(offer);

      await dispatch('activationCode/resetListActivationCodesByOffer', null, { root: true });
      await dispatch('activationCode/countActivationCodesByOffer', { offerId: id }, { root: true });

      commit('offer', offer);
      return offer;
    },
    async addOfferEmailAccess({ commit }, payload) {
      const offersService = Vue.prototype.$services.offers;
      const offerAccess = await offersService.addOfferEmailAccess(payload);

      commit('offerAccess', offerAccess);
    },
    async removeOfferEmailAccess({ dispatch }, { id, offer_id }) {
      const offersService = Vue.prototype.$services.offers;
      const result = await offersService.removeOfferEmailAccess(id);

      await dispatch('getOffer', offer_id);
      return result;
    },
    async listOfferAccesses({ commit }, offer_id) {
      const offersService = Vue.prototype.$services.offers;
      const offerAccesses = await offersService.listOfferAccess(offer_id);

      commit('offerAccesses', offerAccesses);
    },
    async listOffers(
      { commit, state },
      {
        keyword,
        partner_id,
        business_id,
        owner_business_id,
        status,
        type,
        is_single_publisher = false,
        visibility,
        corporate_enabled,
        consumer_enabled,
        page,
        limit,
      } = {},
    ) {
      const offersService = Vue.prototype.$services.offers;

      const thePage = page || state.list.pageNumber || 1;
      const theLimit = limit || paginationLimit;
      const theKeyword = keyword || '';
      const thePartnerId = partner_id || '';
      const theBusinessId = business_id || '';
      const theOwnerBusinessId = owner_business_id || '';
      const theStatus = status || '';
      const theType = type || '';
      const theVisibility = visibility || '';

      const listResults = await offersService.listOffers({
        keyword: theKeyword,
        partner_id: thePartnerId,
        business_id: theBusinessId,
        owner_business_id: theOwnerBusinessId,
        status: theStatus,
        type: theType,
        visibility: theVisibility,
        corporate_enabled,
        consumer_enabled,
        is_single_publisher,
        page: thePage,
        limit: theLimit,
      });

      const offers = listResults.items;

      offers.map((offer) => {
        convertOfferValuesToPercent(offer);
      });

      commit('offers', offers);
      commit('listPageNumber', thePage);
      commit('listPageCount', listResults.page_count);
      commit('listItemCount', listResults.item_count);
      commit('listKeyword', theKeyword);
      commit('listPartnerId', thePartnerId);
      commit('listBusinessId', theBusinessId);
      commit('listOwnerBusinessId', theOwnerBusinessId);
      commit('listStatus', theStatus);
      commit('listType', theType);
      commit('listVisibility', theVisibility);
    },
    async clearListOffers({ commit }) {
      commit('offers', []);
    },
    async listInstantCardProjectsByOffer({ commit, state }, { id, page, limit }) {
      const offersService = Vue.prototype.$services.offers;
      const thePage = page || state.listOfferInstantCardProjects.pageNumber || defaultPage;
      const theLimit = limit || offerInstantCardProjectsPaginationLimit;

      const result = await offersService.listInstantCardProjectsByOffer(id, thePage, theLimit);

      commit('offerInstantCardProjects', result.items);
      commit('offerInstantCardProjectsPage', thePage);
      commit('offerInstantCardProjectsPageCount', result.page_count);
      commit('offerInstantCardProjectsItemCount', result.item_count);
    },
    async changeOfferInstantCardProjectsPage({ commit }, { id, page }) {
      commit('offerInstantCardProjectsPage', page);

      const offersService = Vue.prototype.$services.offers;
      const limit = offerInstantCardProjectsPaginationLimit;

      const result = await offersService.listInstantCardProjectsByOffer(id, page, limit);

      commit('offerInstantCardProjectsPageCount', result.page_count);
      commit('offerInstantCardProjects', result.items);
    },
    async listOfferWebhookListeners({ state, commit }, { id, page, limit } = {}) {
      const offersService = Vue.prototype.$services.offers;
      const thePage = page || state.listOfferWebhookListeners.pageNumber || defaultPage;
      const theLimit = limit || offerWebhookListenersPaginationLimit;

      const listResults = await offersService.listOfferWebhookListeners(id, thePage, theLimit);

      commit('offerWebhookListeners', listResults.items);
      commit('offerWebhookListenersPage', thePage);
      commit('offerWebhookListenersPageCount', listResults.page_count);
      commit('offerWebhookListenersItemCount', listResults.item_count);
    },
    async removeOfferWebhookListener({}, { offerId, webhookListenerId }) {
      const offersService = Vue.prototype.$services.offers;

      return await offersService.removeOfferWebhookListener(offerId, webhookListenerId);
    },
    async addOfferWebhookListener({}, payload) {
      const offersService = Vue.prototype.$services.offers;
      const { offer_id, business_webhook_id } = payload;

      return await offersService.addOfferWebhookListener(offer_id, { business_webhook_id });
    },
    async listOfferPublishersByOffer({ commit }, { offer_id, keyword, status, category, readiness, page, limit }) {
      const offersService = Vue.prototype.$services.offers;
      const thePage = page || state.listOfferPublishers.pageNumber || defaultPage;
      const theLimit = limit || offerPublishersPaginationLimit;
      const theKeyword = keyword || null;
      const theCategory = category || null;
      const theStatus = status || null;
      const theReadiness = readiness || null;

      const result = await offersService.listOfferPublishersByOffer(offer_id, theKeyword, theStatus, theCategory, theReadiness, thePage, theLimit);

      commit('offerPublishers', result.items);
      commit('offerPublishersKeyword', theKeyword);
      commit('offerPublishersStatus', theStatus);
      commit('offerPublishersCategory', theCategory);
      commit('offerPublishersReadiness', theReadiness);
      commit('offerPublishersPage', thePage);
      commit('offerPublishersPageCount', result.page_count);
      commit('offerPublishersItemCount', result.item_count);

      return result.items;
    },
    async addPublisherToOffer({ dispatch }, params) {
      const offersService = Vue.prototype.$services.offers;
      const payload = _.omit(params, ['offer_id']);
      const result = await offersService.addPublisherToOffer(params.offer_id, payload);
      await dispatch('getOffer', params.offer_id);
      return result;
    },
    async removePublisherFromOffer({ dispatch }, { publisher_id, offer_id }) {
      const offersService = Vue.prototype.$services.offers;
      const result = await offersService.removePublisherFromOffer(offer_id, publisher_id);
      await dispatch('getOffer', offer_id);
      return result;
    },
    async deactivateOfferPublisher({ dispatch }, { publisher_id, offer_id }) {
      const offersService = Vue.prototype.$services.offers;
      const result = await offersService.deactivateOfferPublisher(offer_id, publisher_id);
      await dispatch('getOffer', offer_id);
      return result;
    },
    async activateOfferPublisher({ dispatch }, { publisher_id, offer_id }) {
      const offersService = Vue.prototype.$services.offers;
      const result = await offersService.activateOfferPublisher(offer_id, publisher_id);
      await dispatch('getOffer', offer_id);
      return result;
    },
    async updateOfferPublisher({ dispatch }, params) {
      const offersService = Vue.prototype.$services.offers;
      const payload = _.omit(params, ['offer_id', 'publisher_id']);
      const result = await offersService.updateOfferPublisher(params.offer_id, params.publisher_id, payload);
      dispatch('getOffer', params.offer_id);
      return result;
    },
    async updateInventoryLimitForAllOfferPublishers({ dispatch }, { offer_id, inventory_limit }) {
      const offersService = Vue.prototype.$services.offers;
      const result = await offersService.updateInventoryLimitForAllOfferPublishers(offer_id, { inventory_limit });
      dispatch('getOffer', offer_id);
      return result;
    },
    async updateOfferPromotion({ dispatch }, { offer_id, id, ...payloadRest }) {
      const offersService = Vue.prototype.$services.offers;
      if (_.get(payloadRest, 'bonus.reward_percent')) {
        payloadRest.bonus.reward_percent = percentageToFloat(payloadRest.bonus.reward_percent, 2);
      }
      if (_.get(payloadRest, 'bonus.user_inventory_limit')) {
        payloadRest.bonus.user_inventory_limit = Number(payloadRest.bonus.user_inventory_limit);
      }
      if (_.get(payloadRest, 'bonus.step.minimum_payment')) {
        payloadRest.bonus.step.minimum_payment = Number(payloadRest.bonus.step.minimum_payment);
      }
      if (_.get(payloadRest, 'bonus.step.step')) {
        payloadRest.bonus.step.step = Number(payloadRest.bonus.step.step);
      }
      if (_.get(payloadRest, 'bonus.step.default_payment')) {
        payloadRest.bonus.step.default_payment = Number(payloadRest.bonus.step.default_payment);
      }

      const result = await offersService.updateOfferPromotion(id, payloadRest);
      dispatch('getOfferPromotion', { offer_id, id });
      return result;
    },
    async listOfferPromotion({ state, commit }, { id, page, limit } = {}) {
      const offerService = Vue.prototype.$services.offers;

      const thePage = page || state.listOfferPromotions.pageNumber || defaultPage;
      const theLimit = limit || paginationLimit;

      const listResults = await offerService.listOfferPromotions(id, thePage, theLimit);

      commit('offerPromotions', listResults.items);
      commit('offerPromotionsPageNumber', thePage);
      commit('offerPromotionsPageCount', listResults.page_count);
      commit('offerPromotionsItemCount', listResults.item_count);
    },
    async getOfferPromotion({ commit }, { offer_id, id }) {
      const offerService = Vue.prototype.$services.offers;
      const result = await offerService.getOfferPromotion(id);
      commit('offerPromotion', result);
      return result;
    },
    async activateOfferPromotion({ dispatch }, { id }) {
      const offerService = Vue.prototype.$services.offers;
      const result = await offerService.activateOfferPromotion(id);
      await dispatch('listOfferPromotion', { id: result.offer_id });
      return result;
    },
    async deactivateOfferPromotion({ dispatch }, { id }) {
      const offerService = Vue.prototype.$services.offers;
      const result = await offerService.deactivateOfferPromotion(id);
      await dispatch('listOfferPromotion', { id: result.offer_id });
      return result;
    },
    async deleteOfferPromotion({ dispatch }, { id }) {
      const offerService = Vue.prototype.$services.offers;
      const result = await offerService.deleteOfferPromotion(id);
      await dispatch('listOfferPromotion', { id: result.offer_id });
      return result;
    },
    async addOfferPromotion({ state, commit, dispatch }, payload) {
      const offerService = Vue.prototype.$services.offers;
      const { offer_id, ...payloadRest } = payload;
      if (_.get(payloadRest, 'bonus.reward_percent')) {
        payloadRest.bonus.reward_percent = percentageToFloat(payloadRest.bonus.reward_percent, 2);
      }
      if (_.get(payloadRest, 'bonus.user_inventory_limit')) {
        payloadRest.bonus.user_inventory_limit = Number(payloadRest.bonus.user_inventory_limit);
      }
      if (_.get(payloadRest, 'bonus.minimum_payment')) {
        payloadRest.bonus.minimum_payment = Number(payloadRest.bonus.minimum_payment);
      }
      if (_.get(payloadRest, 'bonus.step.step')) {
        payloadRest.bonus.step.step = Number(payloadRest.bonus.step.step);
      }
      if (_.get(payloadRest, 'bonus.step.default_payment')) {
        payloadRest.bonus.step.default_payment = Number(payloadRest.bonus.step.default_payment);
      }

      const result = await offerService.createOfferPromotion(offer_id, payloadRest);

      await dispatch('listOfferPromotion', { id: offer_id });
      return result;
    },
  },
};
