<template>
  <div>
    <table class="responsive-table">
      <thead>
        <tr>
          <td>{{ $t('instant_card_cards.cards_table.id') }}</td>
          <td>{{ $t('instant_card_cards.cards_table.public_token') }}</td>
          <td>{{ $t('instant_card_cards.cards_table.barcode') }}</td>
          <td>{{ $t('instant_card_cards.cards_table.unique_number') }}</td>
          <td>{{ $t('instant_card_cards.cards_table.program') }}</td>
          <td>{{ $t('instant_card_cards.cards_table.project') }}</td>
          <td>{{ $t('instant_card_cards.cards_table.batch') }}</td>
          <td>{{ $t('instant_card_cards.cards_table.status') }}</td>
          <td>{{ $t('instant_card_cards.cards_table.user') }}</td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>
            <h5>{{ $t('instant_card_cards.cards_table.id') }}:</h5>
            <router-link :to="{ name: 'instant_card_details', params: { cardId: item.public_token } }">
              {{ item.id }}
            </router-link>
          </td>
          <td>
            <h5>{{ $t('instant_card_cards.cards_table.public_token') }}:</h5>
            <router-link v-if="item.status === 'active'" :to="{ name: 'card', params: { cardPublicToken: item.public_token } }">
              {{ item.public_token }}
            </router-link>
            <span v-else>{{ item.public_token }}</span>
          </td>
          <td>
            <h5>{{ $t('instant_card_cards.cards_table.barcode') }}:</h5>
            <span v-if="item.barcode">{{ item.barcode }}</span>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('instant_card_cards.cards_table.unique_number') }}:</h5>
            <span v-if="item.unique_number">{{ item.unique_number }}</span>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('instant_card_cards.cards_table.program') }}:</h5>
            <span v-if="item.partner_id">{{ item.partner_id }}</span>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('instant_card_cards.cards_table.project') }}:</h5>
            <router-link
              v-if="item.instant_card_project_id"
              :to="{ name: 'instant_card_project_details', params: { projectId: item.instant_card_project_id } }"
            >
              <span>{{ `${item.instant_card_project.name} (${item.instant_card_project_id})` }}</span>
            </router-link>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('instant_card_cards.cards_table.batch') }}:</h5>
            <router-link
              v-if="item.instant_card_batch_id"
              :to="{ name: 'instant_card_batch_details', params: { batchId: item.instant_card_batch_id } }"
            >
              <span>{{ `${item.instant_card_batch.name} (${item.instant_card_batch_id})` }}</span>
            </router-link>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('instant_card_cards.cards_table.status') }}:</h5>
            <span :class="!item.status ? 'info-not-available' : `instant-card-${item.status}`">{{
              $t(`instant_card_cards.status.${item.status}`) | orNotAvailable
            }}</span>
          </td>
          <td>
            <h5>{{ $t('instant_card_cards.cards_table.user') }}:</h5>
            <router-link v-if="item.account_id" :to="{ name: 'user_details', params: { userId: item.account_id } }">
              <span>{{ item.account_id }}</span>
            </router-link>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-xs-center">
      <v-pagination v-if="listPageCount > 1" v-model="pagination" :length="listPageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import { mapGetters } from 'vuex';
import LabelNone from '@/components/system/Labels/LabelNone';

export default {
  name: 'instant-cards-cards-table',
  mixins: [actionErrorTrackable, security],
  components: { LabelNone },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      pagination: 1,
    };
  },
  mounted() {
    this.pagination = this.listPageNumber;
  },
  computed: {
    ...mapGetters('instantCardCard', ['listPageCount', 'listPageNumber', 'listKeyword', 'listPartnerId', 'listProjectId', 'listStatus']),
  },
  watch: {
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'instantCardCard/listCards',
        },
        {
          keyword: this.listKeyword,
          partner_id: this.listPartnerId,
          project_id: this.listProjectId,
          status: this.listStatus,
          page: newValue,
        },
      );
    },
  },
};
</script>
