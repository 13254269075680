<template>
  <card-modal
    icon=""
    :title="$t('inventory.create_action.title')"
    context="createInventory"
    @submit="submit"
    @cancel="cancel"
    cancel_text="inventory.create_action.button_cancel"
    submit_text="inventory.create_action.button_save"
    data-test="inventory--add-modal"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-text-field
          autofocus
          :label="$t('inventory.create_action.name') + '*'"
          name="name"
          v-validate="'required|max:100'"
          :error-messages="errors.collect('name')"
          v-model="inventory.name"
          data-vv-name="name"
          maxlength="100"
          :data-vv-as="$t('inventory.create_action.name')"
          data-test="add-inventory--name"
          required
        ></v-text-field>
        <v-text-field
          type="text"
          name="initial_amount"
          append-icon="fas fa-dollar-sign"
          v-validate="'required|decimal|min_value:0|max:10000000'"
          :error-messages="errors.collect('initial_amount')"
          v-model="inventory.initial_amount"
          :label="$t('inventory.create_action.initial_amount')"
          data-test="add-inventory--initial-amount"
        ></v-text-field>
        <h-autocomplete
          v-model="inventory.business_id"
          :label="$t('inventory.create_action.business')"
          v-validate="'max:50'"
          :error-messages="errors.collect('business')"
          name="business"
          data-vv-name="business"
          data-vv-as="business"
          search-action="business/autocompleteSearch"
          data-test="add_inventory--business"
          no-filter
        >
        </h-autocomplete>
        <v-text-field
          type="text"
          name="external_id"
          v-validate="'max:50'"
          :error-messages="errors.collect('external_id')"
          v-model="inventory.external_id"
          :label="$t('inventory.create_action.external_id')"
          data-test="add-inventory--external_id"
        ></v-text-field>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import Vue from 'vue';

export default {
  name: 'create-inventory-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  data() {
    return {
      inventory: {},
    };
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    clear() {
      this.inventory = {};
      this.$validator.reset();
    },
    success(response) {
      this.clear();
      this.$emit('created');
      this.$router.push({ name: 'inventory_details', params: { inventoryId: response.id } });
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        const payload = this.inventory;

        await this.executeAction(
          {
            action: 'inventory/createInventory',
            name: 'createInventory',
            success: this.success,
          },
          payload,
        );
      }
    },
  },
};
</script>
