<template>
  <v-flex>
    <h4 class="aml-status-logs-table--mobile-section-heading">{{ $t('user.aml_profile.aml_status_logs_table.mobile_section_heading') }}</h4>
    <table class="responsive-table aml-status-logs-table">
      <thead>
        <tr>
          <td>{{ $t('user.aml_profile.aml_status_logs_table.history') }}</td>
          <td>{{ $t('user.aml_profile.aml_status_logs_table.change') }}</td>
          <td>{{ $t('user.aml_profile.aml_status_logs_table.user') }}</td>
          <td>{{ $t('user.aml_profile.aml_status_logs_table.list') }}</td>
          <td>{{ $t('user.aml_profile.aml_status_logs_table.view_details') }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td class="aml-status-logs-table--history">
            <h5>{{ $t('user.aml_profile.aml_status_logs_table.history_mobile') }}:</h5>
            <span :class="{ 'info-not-available': !item.created }">{{ item.created | formatDateNoTime | orNotAvailable }}</span>
          </td>

          <td class="aml-status-logs-table--change">
            <h5>{{ $t('user.aml_profile.aml_status_logs_table.change') }}:</h5>
            <span v-if="item.change">
              {{ item.change.change_from }}
              <span class="aml-status-logs-table--arrow-icon"> <i class="far fa-long-arrow-right" data-fa-transform="down-2"></i> </span>
              {{ item.change.change_to }}
            </span>
            <span v-else class="info-not-available">{{ $t('system.na') }}</span> <span :class="{ 'info-not-available': !item.change }"></span>
            <div v-if="item.change.view_summary" class="view-summary-wrap">
              <a class="small-link-text" @click="showModalDialog({ content: item.change.view_summary, typeDependent: false })">{{
                $t('user.aml_profile.aml_status_logs_table.hits_modal_button_title')
              }}</a>
            </div>
          </td>

          <td class="aml-status-logs-table--user">
            <h5>{{ $t('user.aml_profile.aml_status_logs_table.user') }}:</h5>
            <app-name v-if="item.user" :app_key="item.user"></app-name>
            <span v-else class="info-not-available">{{ $t('system.na') }}</span>
          </td>

          <td class="aml-status-logs-table--list">
            <h5>{{ $t('user.aml_profile.aml_status_logs_table.list') }}:</h5>
            <div v-if="item.list && item.list instanceof Array && item.list.length > 0" class="aml-status-logs-table--list-name">
              {{ hitListNames(item).join(', ') }}
            </div>
            <span v-else class="info-not-available">{{ $t('system.na') }}</span>
          </td>

          <td class="aml-status-logs-table--view-details">
            <h5 v-if="!item.view_details">{{ $t('user.aml_profile.aml_status_logs_table.view_details') }}:</h5>
            <span v-if="item.view_details">
              <a class="small-link-text" @click="showModalDialog({ content: item.view_details, typeDependent: true, type: item.type })">{{
                $t('user.aml_profile.aml_status_logs_table.details_modal_button_title')
              }}</a>
            </span>
            <span v-else class="info-not-available">{{ $t('system.na') }}</span>
          </td>
        </tr>
      </tbody>
    </table>

    <modal-scrollable-zone :modal-data="modalData" :show-modal="showModal" @close="showModalFlag = false">
      <template slot="modalContent">
        <pre v-highlightjs="dataForModalContent"><code class="json"></code></pre>
      </template>
    </modal-scrollable-zone>
  </v-flex>
</template>

<script>
export default {
  name: 'user-aml-status-logs-table',
  props: {
    items: {
      type: Array,
    },
    cardApplicant: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      showModalFlag: false,
      dataForModalContent: {},
      titleModal: '',
    };
  },
  computed: {
    showModal() {
      return this.showModalFlag;
    },
    modalData() {
      return {
        content: this.dataForModalContent,
        title: this.titleModal,
      };
    },
  },
  methods: {
    hitListNames(log) {
      return log.list.map((item) => {
        return item.list_name;
      });
    },
    showModalDialog({ content, typeDependent, type }) {
      this.dataForModalContent = JSON.stringify(content, null, 2);
      this.showModalFlag = true;

      if (typeDependent) {
        this.getTypeDependentTitle(type);
      } else {
        this.titleModal = `${this.modalTitleHitType('general')} - ${this.cardApplicant.first_name} ${this.cardApplicant.last_name} (DOB: ${
          this.cardApplicant.date_of_birth
        })`;
      }
    },
    getTypeDependentTitle(type) {
      if (type === 'peps') {
        this.titleModal = `${this.modalTitleHitType('peps')} - ${this.cardApplicant.first_name} ${this.cardApplicant.last_name} (DOB: ${
          this.cardApplicant.date_of_birth
        })`;
      } else {
        this.titleModal = `${this.modalTitleHitType('sanctions')} - ${this.cardApplicant.first_name} ${this.cardApplicant.last_name} (DOB: ${
          this.cardApplicant.date_of_birth
        })`;
      }
    },
    modalTitleHitType(type) {
      return this.$t(`user.aml_profile.aml_status_logs_table.details_modal_title_${type}`);
    },
  },
};
</script>
