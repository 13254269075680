<template>
  <div class="card" v-if="account.status === AccountStatus.DEACTIVATED">
    <v-alert type="error" :value="true">
      <strong>{{
        $t('user.deactivated_message', {
          reason: account.deactivation_reason,
          date: this.$options.filters.formatShortDateInverted(account.deactivation_date),
          agent: appName,
        })
      }}</strong>
    </v-alert>
  </div>

  <div v-else-if="account.blocked" class="card">
    <v-alert :value="true" type="error">
      <strong>{{
        $t('user.blocked_message', {
          reason: account.blocked_info.reason,
          date: this.$options.filters.formatShortDateInverted(account.blocked_info.date),
          agent: appName,
        })
      }}</strong>
    </v-alert>
  </div>

  <div class="card" v-else-if="account.aml_flag && account.aml_flag !== 'ok'">
    <v-alert type="error" :value="true">
      <strong>{{
        $t('user.aml_flag_message', {
          flag: accountAMLFlag,
        })
      }}</strong>
    </v-alert>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AccountStatus from '@/enums/account-status';

export default {
  name: 'user-alert',
  data() {
    return {
      appName: '?',
    };
  },
  watch: {
    async account() {
      return this.refresh();
    },
  },
  async mounted() {
    return this.refresh();
  },
  computed: {
    AccountStatus() {
      return AccountStatus;
    },
    accountAMLFlag() {
      return this.$t(`code.card.aml_flag.${this.account.aml_flag}`).toUpperCase();
    },

    ...mapGetters('account', ['account', 'accountId']),
  },
  methods: {
    ...mapActions('security', ['getAppName']),
    async refresh() {
      if (this.account && this.account.status === AccountStatus.DEACTIVATED && this.account.deactivated_by_app_key) {
        const app = await this.getAppName(this.account.deactivated_by_app_key);
        this.appName = app ? app : '?';
      } else if (this.account && this.account.blocked && this.account.blocked_info && this.account.blocked_info.by_app_key) {
        const app = await this.getAppName(this.account.blocked_info.by_app_key);
        this.appName = app ? app : '?';
      } else {
        this.appName = '?';
      }
    },
  },
};
</script>
