<template>
  <div>
    <a role="button" class="button-subtle" @click="action" v-if="!isDisabled"><slot></slot></a>
    <span class="is-disabled" v-if="isDisabled"><slot></slot></span>
  </div>
</template>

<script>
import disabler from '@/mixins/disable-with-error';

export default {
  name: 'link-secondary-action',
  mixins: [disabler],
  computed: {
    isDisabled() {
      if (this.disabled != null) {
        return this.disabled;
      } else {
        return isGlobalErrorActive;
      }
    },
  },
  props: {
    disabled: {
      type: Boolean,
    },
  },
  methods: {
    action(event) {
      this.$emit('click', event);
    },
  },
};
</script>
