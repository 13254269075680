import BaseJoi from 'joi';
import VeeValidate, { Validator } from 'vee-validate';
import fr from '../i18n/vee-validate/fr';
import phone from './../utils/validators/phone';
import { adjustValueForMinStep } from './../utils/offer-utils';
import { isRange } from 'range_check';

export default ({ app, Vue }) => {
  Validator.localize('fr', fr);
  Validator.localize('en');

  const Joi = BaseJoi.extend(phone);

  Validator.extend('validateRange', {
    name: 'validateRange',
    message: (field) => app.i18n.t('errors.validation.validate_range', { field: field }),
    validate: (value) => {
      return isRange(value);
    },
  });

  Validator.extend('slug', {
    name: 'slug',
    message: (field) => app.i18n.t('errors.validation.slug.accepted_characters', { field: field }),
    validate: (value) => /(^[a-zA-Z0-9-]*$)|(^$)/.test(value),
  });

  Validator.extend('phone', {
    name: 'phone',
    message(field, args) {
      return app.i18n.t('validation.joi.string.phone');
    },
    validate: (value) => {
      const schema = Joi.string().phone();
      return Joi.validate(value, schema)
        .then((resp) => {
          return true;
        })
        .catch((e) => {
          if (e.isJoi && e.name === 'ValidationError') {
            return false;
          }

          throw e;
        });
    },
  });

  Validator.extend('islater', {
    name: 'islater',
    getMessage(field, args) {
      return app.i18n.t('errors.dateValidation.isLater', { date: args['date'] });
    },
    validate: (value, { date } = {}) => {
      if (!date || !value) {
        return true;
      }
      let startParts = date.split('-');
      let endParts = value.split('-');
      let start = new Date(startParts[0], startParts[1] - 1, startParts[2]); // month is 0-based
      let end = new Date(endParts[0], endParts[1] - 1, endParts[2]);

      return end > start;
    },
    paramNames: ['date'],
  });

  Validator.extend('islaterOrSame', {
    name: 'islater',
    getMessage(field, args) {
      return app.i18n.t('errors.dateValidation.isLater', { date: args['date'] });
    },
    validate: (value, { date } = {}) => {
      if (!date || !value) {
        return true;
      }
      let startParts = date.split('-');
      let endParts = value.split('-');
      let start = new Date(startParts[0], startParts[1] - 1, startParts[2]); // month is 0-based
      let end = new Date(endParts[0], endParts[1] - 1, endParts[2]);

      return end >= start;
    },
    paramNames: ['date'],
  });

  Validator.extend('isbefore', {
    name: 'isbefore',
    getMessage(field, args) {
      return app.i18n.t('errors.dateValidation.isBefore', { date: args['date'] });
    },
    validate: (value, { date } = {}) => {
      if (!date || !value) {
        return true;
      }
      let startParts = date.split('-');
      let endParts = value.split('-');
      let start = new Date(startParts[0], startParts[1] - 1, startParts[2]); // month is 0-based
      let end = new Date(endParts[0], endParts[1] - 1, endParts[2]);

      return end < start;
    },
    paramNames: ['date'],
  });

  Validator.extend('isSameUserEmail', {
    name: 'isSameUserEmail',
    getMessage(field, args) {
      return app.i18n.t('errors.dateValidation.isSameUserEmail');
    },
    validate: (value, { email } = {}) => {
      if (!email || !value) {
        return true;
      }

      if (value !== email) {
        return false;
      }

      return true;
    },
    paramNames: ['email'],
  });

  Validator.extend('fundingBonusSumsTo', {
    name: 'fundingBonusSumsTo',
    getMessage(value, args) {
      return app.i18n.t('errors.dateValidation.fundingBonusSumsTo', { total: args['total'] });
    },
    validate: (value, { field1, field2, total } = {}) => {
      if (!value || !field1 || !field2 || !total) {
        return true;
      }

      return parseFloat(value) + parseFloat(field1) + parseFloat(field2) === parseFloat(total);
    },
    paramNames: ['field1', 'field2', 'total'],
  });

  Validator.extend('sumLessThanOrEqual', {
    name: 'sumLessThanOrEqual',
    getMessage(value, args) {
      return app.i18n.t('errors.sumLessThanOrEqual', { total: args['total'], totalFieldLabel: args['totalFieldLabel'] });
    },
    validate: (value, { field, total, totalFieldLabel } = {}) => {
      if (!value || !field || !total || !totalFieldLabel) {
        return true;
      }

      return parseFloat(value) + parseFloat(field) <= parseFloat(total);
    },
    paramNames: ['field', 'total', 'totalFieldLabel'],
  });

  Validator.extend('maxLines', {
    name: 'maxLines',
    getMessage(value, args) {
      return app.i18n.t('errors.maxLines', { nbLines: args['nbLines'] });
    },
    validate: (value, { nbLines } = {}) => {
      if (!value || !nbLines) {
        return true;
      }

      const count = (value.match(/\n/g) || []).length;
      return count < nbLines;
    },
    paramNames: ['nbLines'],
  });

  Validator.extend('bonusDurationBetween', {
    name: 'bonusDurationBetween',
    getMessage(value, args) {
      return app.i18n.t('errors.offerFieldValidation.bonusDurationBetween', { min: 1, max: 730 });
    },
    validate: (value, { field1 } = {}) => {
      if (!value || !field1) {
        return true;
      }

      return Number(value) >= 1 && Number(value) <= 730 && Number(value) < Number(field1);
    },
    paramNames: ['field1'],
  });

  Validator.extend('closureBetween', {
    name: 'closureBetween',
    getMessage(value, args) {
      return app.i18n.t('errors.offerFieldValidation.closureBetween', { min: 1, max: 730 });
    },
    validate: (value, { field1 } = {}) => {
      if (!value || !field1) {
        return true;
      }

      return Number(value) >= 1 && Number(value) <= 730 && Number(value) > Number(field1);
    },
    paramNames: ['field1'],
  });

  Validator.extend('minimumPaymentBetween', {
    name: 'minimumPaymentBetween',
    getMessage(value, args) {
      return app.i18n.t('errors.offerFieldValidation.minimumPaymentBetween', { min: 1, max: 1000 });
    },
    validate: (value, { field1, field2 } = {}) => {
      if (!value || !field1 || !field2) {
        return true;
      }

      return Number(value) >= 1 && Number(value) <= 1000 && Number(value) <= Number(field1) && Number(value) <= Number(field2);
    },
    paramNames: ['field1', 'field2'],
  });

  Validator.extend('limitPaymentBetween', {
    name: 'limitPaymentBetween',
    getMessage(value, args) {
      return app.i18n.t('errors.offerFieldValidation.limitPaymentBetween', { min: 1, max: 20000 });
    },
    validate: (value, { field1, field2 } = {}) => {
      if (!value || !field1 || !field2) {
        return true;
      }

      return Number(value) >= 1 && Number(value) <= 20000 && Number(value) >= Number(field1) && Number(value) >= Number(field2);
    },
    paramNames: ['field1', 'field2'],
  });

  Validator.extend('stepBetween', {
    name: 'stepBetween',
    getMessage(value, args) {
      return app.i18n.t('errors.offerFieldValidation.stepBetween', { min: 1, max: 20000 });
    },
    validate: (value, { field1 } = {}) => {
      if (!value || !field1) {
        return true;
      }

      return Number(value) >= 1 && Number(value) <= 20000 && Number(value) <= Number(field1);
    },
    paramNames: ['field1'],
  });

  Validator.extend('withinMinStep', {
    name: 'withinMinStep',
    getMessage(field, args, data) {
      return app.i18n.t('errors.offerFieldValidation.withinMinStep', { min: args.min, step: args.step, suggestion: data.value });
    },
    validate: (value, { min, step } = {}) => {
      if (!min || !step) {
        return true;
      }

      return {
        valid: adjustValueForMinStep(min, step, value) === Number(value),
        data: { value: adjustValueForMinStep(min, step, value) },
      };
    },
    paramNames: ['min', 'step'],
  });

  Validator.extend('defaultPaymentBetween', {
    name: 'defaultPaymentBetween',
    getMessage(value, args) {
      return app.i18n.t('errors.offerFieldValidation.defaultPaymentBetween', { min: 1, max: 20000 });
    },
    validate: (value, { field1, field2 } = {}) => {
      if (!value || !field1 || !field2) {
        return true;
      }

      return Number(value) >= 1 && Number(value) <= 20000 && Number(value) >= Number(field1) && Number(value) <= Number(field2);
    },
    paramNames: ['field1', 'field2'],
  });

  Validator.extend(
    'minArrayLength',
    {
      name: 'minArrayLength',
      getMessage(value, args) {
        return app.i18n.t('errors.changeRequestValidation.minArrayLength');
      },
      validate: (value = {}) => {
        if (!value) {
          return true;
        }

        return value.length > 0;
      },
    },
    // TODO: I tried removing 'required' rule in order to get this message to display instead, but it didn't work
    // {
    //   continues: true,
    // },
  );

  Vue.use(VeeValidate);
};
