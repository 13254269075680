import Vue from 'vue';

const service = {
  async getBranding(id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/brandings/${id}`);
    return response.data.data;
  },
  async createBranding(payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/brandings`, payload);
    return response.data.data;
  },
  async listCategories() {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/branding-categories`);
    return response.data.data.items;
  },
  async searchBrandings(page, limit, keyword) {
    const params = {};
    if (keyword) {
      params.keyword = keyword;
    }
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath('/publisher/v2/brandings/search', page, limit, params)}`,
    );
    return response.data.data;
  },
  async listBrandings(keyword, type, category, page, limit) {
    const params = {};
    if (keyword) {
      params.keyword = keyword;
    }
    if (type) {
      params.type = type;
    }
    if (category) {
      params.category = category;
    }
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath('/publisher/v2/brandings', page, limit, params)}`,
    );
    return response.data.data;
  },
  async listBrandingStores(id, page, limit) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v2/brandings/${id}/stores`, page, limit)}`,
    );
    return response.data.data;
  },
  async listBrandingPublishers(id, page, limit) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v2/brandings/${id}/publishers`, page, limit)}`,
    );
    return response.data.data;
  },
  async listBrandingOffers(id, page, limit) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v2/brandings/${id}/offers`, page, limit)}`,
    );
    return response.data.data;
  },
  async updateBranding(id, params) {
    const payload = params;
    delete payload.images;
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/brandings/${id}`, payload);
    return response.data.data;
  },
  async createBrandingImagesUploadSignedUrls(brandingId, payload) {
    const response = await Vue.prototype.$axios.post(
      `${process.env.VUE_APP_BASE_API_URL}/publisher/v2/brandings/${brandingId}/images/upload`,
      payload,
    );
    return response.data.data;
  },
  async addBrandingImage(branding_id, payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/brandings/${branding_id}/images`, payload);
    return response.data.data;
  },
  async uploadImage(signedUrl, image) {
    return fetch(signedUrl, {
      method: 'PUT',
      body: image,
      headers: {
        'Content-Type': image.type,
      },
    });
  },
  async removeBrandingImage({ branding_id, image_id }) {
    const response = await Vue.prototype.$axios.delete(
      `${process.env.VUE_APP_BASE_API_URL}/publisher/v2/brandings/${branding_id}/images/${image_id}`,
    );
    return response.data.data;
  },
};

function generatePath(startPath, page, limit, { keyword, type, category } = {}) {
  let path = startPath;
  if (page || limit || keyword || type || category) {
    path += '?';
  }

  const queryStrings = [];
  if (page) {
    queryStrings.push(['page', page]);
  }
  if (limit) {
    queryStrings.push(['limit', limit]);
  }
  if (keyword) {
    queryStrings.push(['keyword', keyword]);
  }
  if (type) {
    queryStrings.push(['type', type]);
  }
  if (category) {
    queryStrings.push(['category', category]);
  }

  const params = new URLSearchParams(queryStrings);
  return path + params.toString();
}

export default service;
