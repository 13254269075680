import Vue from 'vue';

const service = {
  async getAmlAssessmentCheck(assessment_check_key) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/aml/v1/assessment-checks/${assessment_check_key}/details`);
    return response.data.data;
  },
};

export default service;
