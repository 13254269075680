<template>
  <div class="enabled-disabled-status">
    <span :class="`status-${enabledAsString}`">{{ $t(`enabled_disabled_status.${enabledAsString}`) }}</span>
  </div>
</template>

<script>
export default {
  name: 'enabled-disabled-status-view',
  props: {
    enabled: {
      type: Boolean,
    },
  },
  computed: {
    enabledAsString() {
      return this.enabled ? 'enabled' : 'disabled';
    },
  },
};
</script>
