<template>
  <view-main title="page_title.publishers_details">
    <two-levels-breadcrumbs slot="content" :top-label="$t('navigation.publishers')" :to="{ name: 'closed_loops' }" :last-label="publisher.name" />
    <publishers-information slot="content" />
    <publishers-business slot="content" />
    <publishers-branding slot="content" />
    <publishers-offers slot="content" />
    <publishers-stores slot="content" />
    <publisher-notes slot="content" />
  </view-main>
</template>

<script>
import { actionErrorTrackable, renderErrorTrackable } from '@/mixins';
import PublishersInformation from './detail/PublishersInformation.vue';
import PublishersBusiness from './detail/PublishersBusiness.vue';
import PublishersBranding from './detail/PublishersBranding.vue';
import PublishersOffers from './detail/PublishersOffers.vue';
import PublishersStores from './detail/PublishersStores.vue';
import PublisherNotes from './detail/PublisherNotes.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'publishers-detail-view',
  mixins: [renderErrorTrackable, actionErrorTrackable],
  components: { PublishersInformation, PublishersBusiness, PublishersBranding, PublishersStores, PublishersOffers, PublisherNotes },
  props: {
    publishersId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('publishers', ['publisher']),
  },
  async mounted() {
    await this.fetch();
  },
  watch: {
    async publishersId() {
      return this.fetch();
    },
  },
  methods: {
    async fetch() {
      const publisher = await this.executeAction({ action: 'publishers/getPublisher' }, this.publishersId);
      if (!publisher) {
        this.$router.replace({ name: 'notFound' });
      }
    },
  },
};
</script>
