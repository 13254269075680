<template>
  <div>
    <table class="responsive-table">
      <thead>
        <tr>
          <td>{{ $t('inventory.inventories_table.id') }}</td>
          <td>{{ $t('inventory.inventories_table.name') }}</td>
          <td>{{ $t('inventory.inventories_table.owner') }}</td>
          <td>{{ $t('inventory.inventories_table.external_id') }}</td>
          <td>{{ $t('inventory.inventories_table.initial_amount') }}</td>
          <td>{{ $t('inventory.inventories_table.current_amount') }}</td>
          <td>{{ $t('inventory.inventories_table.available_amount') }}</td>
          <td>{{ $t('inventory.inventories_table.created') }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>
            <h5>{{ $t('inventory.inventories_table.id') }}:</h5>
            <span>
              <router-link :to="{ name: 'inventory_details', params: { inventoryId: item.id } }">
                {{ item.id }}
              </router-link>
            </span>
          </td>
          <td>
            <h5>{{ $t('inventory.inventories_table.name') }}:</h5>
            <span>{{ item.name }}</span>
          </td>
          <td>
            <h5>{{ $t('inventory.inventories_table.owner') }}:</h5>
            <span>
              <router-link v-if="item.business.name" :to="{ name: 'business_details', params: { businessId: item.business_id } }">
                {{ item.business.name }}
              </router-link>
              <label-none v-else>{{ $t('system.na') }}</label-none>
            </span>
          </td>
          <td align="right">
            <h5>{{ $t('inventory.inventories_table.external_id') }}:</h5>
            <span>
              <span v-if="item.external_id">{{ item.external_id }}</span>
              <label-none v-else>{{ $t('system.na') }}</label-none>
            </span>
          </td>
          <td align="right">
            <h5>{{ $t('inventory.inventories_table.initial_amount') }}:</h5>
            <span>{{ item.initial_amount | currency | dollarSignI18n }}</span>
          </td>
          <td align="right">
            <h5>{{ $t('inventory.inventories_table.current_amount') }}:</h5>
            <span>{{ item.current_amount | currency | dollarSignI18n }}</span>
          </td>
          <td align="right">
            <h5>{{ $t('inventory.inventories_table.available_amount') }}:</h5>
            <span>{{ item.available_amount | currency | dollarSignI18n }}</span>
          </td>
          <td>
            <h5>{{ $t('inventory.inventories_table.created') }}:</h5>
            <span>{{ item.creation_date | formatShortDateInverted | orNotAvailable }}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-xs-center">
      <v-pagination v-if="listPageCount > 1" v-model="pagination" :length="listPageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'inventories-table',
  mixins: [actionErrorTrackable, security],
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      pagination: 1,
    };
  },
  mounted() {
    this.pagination = this.listPageNumber;
  },
  computed: {
    ...mapGetters('inventory', ['listPageCount', 'listPageNumber', 'listKeyword', 'listPartnerId', 'listBusinessId']),
  },
  methods: {
    typesTranslated(type) {
      return this.$t(`branding.types.${type}`);
    },
  },
  watch: {
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'inventory/listInventories',
        },
        {
          page: newValue,
          keyword: this.listKeyword,
          partner_id: this.listPartnerId,
          business_id: this.listBusinessId,
        },
      );
    },
  },
};
</script>
