<template>
  <card-modal
    icon=""
    :title="$t('offers.webhook_listeners.add_modal.title')"
    context="addOfferWebhookListener"
    @submit="submit"
    @cancel="cancel"
    cancel_text="offers.webhook_listeners.add_modal.cancel"
    submit_text="offers.webhook_listeners.add_modal.submit"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <modal-info-block :heading="$t('offers.webhook_listeners.add_modal.title')" :text="offerInfo"></modal-info-block>
        <h-autocomplete
          v-model="business"
          :label="$t('offers.webhook_listeners.add_modal.fields.business') + '*'"
          v-validate="'required'"
          :error-messages="errors.collect('business')"
          name="business"
          data-vv-name="business"
          data-vv-as="business"
          search-action="business/autocompleteSearchWithWebhooks"
          data-test="add-offer-webhook-listener--business"
        >
        </h-autocomplete>
      </v-layout>
      <v-layout>
        <v-select
          name="endpoint"
          v-model="endpoint"
          v-validate="'required'"
          :error-messages="errors.collect('endpoint')"
          data-vv-name="endpoint"
          :data-vv-as="$t('offers.webhook_listeners.add_modal.fields.endpoint')"
          :items="businessWebhooks"
          item-text="name"
          item-value="id"
          :label="$t('offers.webhook_listeners.add_modal.fields.endpoint') + '*'"
          data-test="add-offer-webhook-listener--endpoint"
        ></v-select>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import Vue from 'vue';

export default {
  name: 'add-offer-webhook-listener-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    offerObj: {
      type: Object,
    },
  },
  data() {
    return {
      business: null,
      endpoint: null,
      isBusinessLoading: false,
      searchBusiness: null,
      businessSelections: [],
    };
  },
  watch: {
    offerWebhookListeners() {
      this.business = null;
    },
    async business() {
      return this.fetchBusinessWebhooks();
    },
  },
  computed: {
    ...mapGetters('offers', ['offerWebhookListeners']),
    ...mapGetters('business', ['businessWebhooks']),
    offerInfo() {
      return `${this.offerObj.branding.name} (${this.offerObj.id})`;
    },
  },
  methods: {
    async clear() {
      this.business = null;
      this.endpoint = null;
      this.businessSelections = [];
      this.$validator.reset();
    },
    success() {
      this.clear();
      this.$emit('created');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async fetchBusinessWebhooks() {
      await this.executeAction({ action: 'business/listBusinessWebhooks' }, { id: this.business, page: 1, sort_order: 'name' });
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        if (this.business) {
          const payload = { offer_id: this.offerObj.id, business_webhook_id: this.endpoint };

          await this.executeAction(
            {
              action: 'offers/addOfferWebhookListener',
              name: 'addOfferWebhookListener',
              success: () => {
                this.$emit('success');
              },
            },
            payload,
          );
        }
      }
    },
  },
};
</script>
