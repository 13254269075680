<template>
  <div class="store-status-view">
    <span v-if="storeId || storeObject" :class="`status-${store.status}`">{{ $t(`store.status.${store.status}`) }}</span>
    <span class="status-na" v-else>{{ $t('system.na') }}</span>
  </div>
</template>

<script>
import { actionErrorTrackable, renderErrorTrackable } from '@/mixins';

export default {
  name: 'store-status-view',
  props: {
    storeId: {
      type: String,
    },
    storeObject: {
      type: Object,
    },
  },
  data: () => {
    return {
      store: {},
    };
  },
  methods: {
    async fetch(storeId) {
      await this.executeAction({ action: 'store/getStore', success: this.getStoreSuccess }, storeId);
    },
    getStoreSuccess(store) {
      this.store = store ? store : {};
    },
  },
  mixins: [renderErrorTrackable, actionErrorTrackable],
  created: async function() {
    if (this.storeObject) {
      this.store = this.storeObject;
    }
    if (this.storeId) {
      await this.fetch(this.storeId);
    }
  },
  watch: {
    async storeId(id) {
      await this.fetch(id);
    },
    async storeObject(storeObject) {
      this.store = storeObject;
    },
  },
};
</script>
