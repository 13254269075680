<template>
  <card-modal
    icon=""
    :title="$t('publishers.details.stores_section.add_stores_from_offer_action.title')"
    context="addStoresFromOffer"
    @submit="validate"
    @cancel="cancel"
    cancel_text="publishers.details.stores_section.add_stores_from_offer_action.button_cancel"
    submit_text="publishers.details.stores_section.add_stores_from_offer_action.button_submit"
    :enable_submit="offer && offerStatsStoreCount > 0"
    :submit_working="working"
  >
    <form @submit.prevent="validate" slot="content">
      <v-layout column>
        <modal-info-block
          :heading="$t('publishers.details.stores_section.add_stores_from_offer_action.publisher')"
          :text="publisherInfo"
        ></modal-info-block>
        <h-autocomplete
          autofocus
          v-model="offer"
          :label="$t('publishers.details.stores_section.add_stores_from_offer_action.offer')"
          v-validate="'required'"
          :error-messages="errors.collect('offer')"
          name="offer"
          data-vv-name="offer"
          data-vv-as="offer"
          search-action="offers/autocompleteSearch"
          data-test="link-publisher-offer-stores"
          no-filter
        >
        </h-autocomplete>
        <div v-if="offer">
          <label-text
            :label="$t('publishers.details.stores_section.add_stores_from_offer_action.store_count')"
            :value="offerStatsStoreCount.toString()"
          />
        </div>
        <modal-confirm
          :show-modal="showConfirmModal"
          context="AddStoresFromOfferAction"
          :title="$t('publishers.details.stores_section.add_stores_from_offer_action.confirm.title')"
          :text="
            $t('publishers.details.stores_section.add_stores_from_offer_action.confirm.text', {
              storeCount: offerStatsStoreCount.toString(),
              publisherId: publisherObj.id,
            })
          "
          cancel_text="publishers.details.stores_section.add_stores_from_offer_action.confirm.button_cancel"
          submit_text="publishers.details.stores_section.add_stores_from_offer_action.confirm.button_submit"
          :submit_working="working"
          @submit="submit"
          @close="closeConfirmModal"
        ></modal-confirm>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import Vue from 'vue';

export default {
  name: 'add-stores-from-offer-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    publisherObj: {
      type: Object,
    },
  },
  data() {
    return {
      offer: null,
      showConfirmModal: false,
    };
  },
  watch: {
    async offer(val) {
      if (val) {
        await this.getOfferStatistics(val);
      }
    },
  },
  computed: {
    ...mapGetters('publishers', ['publisherOffers']),
    ...mapGetters('offers', ['offerStatsPublisherCount', 'offerStatsStoreCount']),
    publisherInfo() {
      return `${this.publisherObj.name} (${this.publisherObj.id})`;
    },
  },
  methods: {
    clear() {
      this.offer = null;
      this.$validator.reset();
    },
    async success() {
      this.clear();
      this.$emit('storesAdded');
      await this.executeAction(
        {
          action: 'publishers/getPublisher',
        },
        this.publisherObj.id,
      );
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async getOfferStatistics(offerId) {
      await this.executeAction(
        {
          action: 'offers/getOfferStatistics',
          name: 'getOfferStatistics',
        },
        offerId,
      );
    },
    openConfirmModal() {
      this.showConfirmModal = true;
    },
    closeConfirmModal() {
      this.showConfirmModal = false;
    },
    async validate() {
      const validationComplete = await this.$validator.validateAll();

      if (!validationComplete) {
        return;
      }

      if (!this.offer) {
        return;
      }

      this.openConfirmModal();
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (!validationComplete) {
        return;
      }

      if (!this.offer) {
        return;
      }

      const payload = { publisher_id: this.publisherObj.id, offer_id: this.offer };
      await this.executeAction(
        {
          action: 'offers/addStoresFromOffer',
          name: 'addStoresFromOffer',
          success: this.success,
        },
        payload,
      );
    },
  },
};
</script>
