<template>
  <card-data class="hc-kyc-section">
    <template slot="headerZoneLeft">
      <v-icon></v-icon>
      <h3>{{ $t('application.kyc_section.title') }}</h3>
    </template>

    <template slot="headerZoneRight">
      <modal-scrollable-zone
        v-if="kycExists()"
        :modal-data="kycDocumentModalData"
        :show-modal="showKycDocumentModal"
        @close="showKycDocumentModal = false"
      >
        <pre v-highlightjs slot="modalContent"><code class="xml">{{ application.kyc.result.raw | formatXmlDoc }}</code></pre>
      </modal-scrollable-zone>
      <label-text
        v-if="application.kyc && application.kyc.verification_date"
        :label="$t(`application.kyc_section.date_verified`)"
        :value="application.kyc.verification_date | formatDateHourMin"
      ></label-text>
      <label-text v-else :label="$t(`application.kyc_section.date_verified`)" :value="''"></label-text>

      <label-text :label="$t(`application.kyc_section.source_title`)" :value="$t(`application.kyc_section.source`)"></label-text>
      <label-text :label="$t(`application.kyc_section.document`)" :value="application.kyc.result ? 'view' : ''">
        <link-secondary-action v-if="kycExists()" slot="labelTextLink" @click="showKycDocumentModal = true">{{
          $t('application.kyc_section.document_button')
        }}</link-secondary-action>
      </label-text>

      <label-text
        v-if="kycExists()"
        :label="$t(`application.kyc_section.result`)"
        :value="$t(`application.kyc_section.kyc_result.${kyc.verdict}`)"
        :class-array="[`kyc-result-${kyc.verdict}`]"
      ></label-text>
      <label-text
        v-else
        :label="$t(`application.kyc_section.result`)"
        :value="$t(`application.kyc_section.kyc_result.error`) + (hasEquifaxErrorDetail ? ' *' : '')"
        :title="equifaxError"
        :class-array="[`kyc-result-error`]"
      ></label-text>
    </template>

    <template slot="contentZoneMiddle" v-if="kycExists()">
      <h4>{{ $t('application.kyc_section.input') }}</h4>
      <label-text :label="$t(`application.kyc_section.name`)" :value="kyc.input.name"></label-text>
      <label-text :label="$t(`application.kyc_section.date_of_birth`)" :value="kyc.input.date_of_birth | addHyphensToDate"></label-text>
      <label-text :label="$t(`application.kyc_section.address`)" :value="kyc.input.address"></label-text>

      <template v-if="Object.keys(kyc.consumer_report).length > 0">
        <h4>{{ $t('application.kyc_section.consumer_report') }}</h4>
        <label-text :label="$t(`application.kyc_section.unique_number`)" :value="kyc.consumer_report.unique_number"></label-text>
        <label-text
          :label="$t(`application.kyc_section.file_since_date`)"
          :value="kyc.consumer_report.file_since_date | calculateTimeElapsed(application.kyc.verification_date)"
          :class-array="[`file-since-${fileSinceDateClass}`]"
        ></label-text>
        <label-text :label="$t(`application.kyc_section.first_name`)" :value="kyc.consumer_report.first_name"></label-text>
        <label-text
          v-if="kyc.consumer_report.middle_name !== '<NO VALUE>'"
          :label="$t(`application.kyc_section.middle_name`)"
          :value="kyc.consumer_report.middle_name"
        ></label-text>
        <label-text :label="$t(`application.kyc_section.last_name`)" :value="kyc.consumer_report.last_name"></label-text>
        <label-text :label="$t(`application.kyc_section.also_known_as`)" :value="formatOtherNames"></label-text>
        <label-text :label="$t(`application.kyc_section.date_of_birth`)" :value="kyc.consumer_report.date_of_birth"></label-text>

        <h4 class="addresses">{{ $t('application.kyc_section.addresses_heading') }}</h4>
        <responsive-v-data-table
          :headers="addressHeaders"
          :items="addressItems"
          class="responsive-v-data-table--first-column-as-headings responsive-v-data-table--large"
        />
      </template>

      <h4 v-if="!kycExists() && (hasEquifaxErrorDetail || hasReasonDetail)">{{ $t('application.kyc_section.equifax_error') }}</h4>
      <label-text
        v-if="!kycExists() && hasEquifaxErrorDetail"
        :label="$t(`application.kyc_section.result`)"
        :value="equifaxError"
        :class-array="[`kyc-result-error`, 'kyc-result-error-text']"
      ></label-text>
      <label-text
        v-if="!kycExists() && hasReasonDetail"
        :label="$t(`application.kyc_section.equifax_reason`)"
        :value="reasonDetail"
        :class-array="[`kyc-result-error`, 'kyc-result-error-text']"
      ></label-text>

      <h4>{{ $t('application.kyc_section.single_source') }}</h4>
      <label-text
        :label="$t(`application.kyc_section.hit`)"
        :value="$t(`code.application.kyc.hit.${application.kyc.result.header.SingleSourceHit}`)"
        :class-array="[dualSourceHitWidth, `hit-${application.kyc.result.header.SingleSourceHit}`]"
      ></label-text>
      <label-text
        :label="$t(`application.kyc_section.decision`)"
        :value="$t(`code.application.kyc.decision.${convertToYesOrNo(kyc.single_source.decision)}`)"
        :class-array="[`decision-${convertToYesOrNo(kyc.single_source.decision)}`]"
      ></label-text>
      <simple-list
        :label="$t(`application.kyc_section.full_matches`)"
        :list-items="sortedFullMatches"
        :translation-prefix="'application.kyc_section.matches'"
      ></simple-list>
      <simple-list
        :label="$t(`application.kyc_section.partial_matches`)"
        :list-items="sortedPartialMatches"
        :translation-prefix="'application.kyc_section.matches'"
      ></simple-list>
      <simple-list
        :label="$t(`application.kyc_section.missing_matches`)"
        :list-items="sortedMissingMatches"
        :translation-prefix="'application.kyc_section.matches'"
      ></simple-list>

      <h4>{{ $t('application.kyc_section.dual_source') }}</h4>
      <label-text
        :label="$t(`application.kyc_section.hit`)"
        :value="displayDualSourceHitText"
        :class-array="[`hit-${interpretDualSourceHits}`]"
      ></label-text>
      <label-text
        :label="$t(`application.kyc_section.decision`)"
        :value="$t(`code.application.kyc.decision.${kyc.dual_source.meta.decision_label}`)"
        :class-array="[`decision-${kyc.dual_source.meta.decision_label}`]"
      ></label-text>
      <list-container v-for="(source, index) in kyc.dual_source.sources" :key="index">
        <template slot="heading">
          <h5 class="dual-source-sub-headings">{{ $t('application.kyc_section.dual_sources.title') + ` ${index + 1}` }}</h5>
        </template>
        <template slot="stack-vertically">
          <simple-list
            :label="$t(`application.kyc_section.dual_sources.matches`)"
            :list-items="source.meta.matches"
            :translation-prefix="'application.kyc_section.dual_sources'"
          ></simple-list>
          <label-text :label="$t(`application.kyc_section.dual_sources.source`)" :value="source.institution_name"></label-text>
          <label-text :label="$t(`application.kyc_section.dual_sources.reference_number`)" :value="source.account_number"></label-text>
          <label-text :label="$t(`application.kyc_section.dual_sources.name_as_reported`)" :value="source.name_as_reported"></label-text>
          <label-text
            :label="$t(`application.kyc_section.dual_sources.date_of_birth_as_reported`)"
            :value="source.date_of_birth_as_reported"
          ></label-text>
          <label-text :label="$t(`application.kyc_section.dual_sources.address_as_reported`)" :value="source.address_as_reported"></label-text>
        </template>
      </list-container>
    </template>
    <template slot="contentZoneMiddle" v-else>
      <label-none id="no-kyc">{{ $t('application.kyc_section.none') }}</label-none>
    </template>
  </card-data>
</template>

<script>
import ResponsiveVDataTable from '../../system/ResponsiveVDataTable';
import _ from 'lodash';
import moment from 'moment';

export default {
  name: 'kyc-info',
  components: { ResponsiveVDataTable },
  props: {
    application: {
      type: Object,
      required: false,
    },
    kyc: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      showKycResultModal: false,
      showKycDocumentModal: false,
      kycResultModalData: {
        title: 'application.kyc_section.result_modal_title',
      },
      kycDocumentModalData: {
        title: 'application.kyc_section.document_modal_title',
      },
      dualSourceHitWidth: '',
    };
  },
  computed: {
    addressHeaders() {
      return [
        {
          text: this.$t('application.kyc_section.addresses_heading'),
          sortable: false,
          value: 'title',
        },
        {
          text: this.$t('application.kyc_section.date_reported'),
          sortable: false,
          value: 'date_reported',
        },
        {
          text: this.$t('application.kyc_section.civic_number'),
          sortable: false,
          value: 'civic_number',
        },
        {
          text: this.$t('application.kyc_section.street_name'),
          sortable: false,
          value: 'street_name',
        },
        {
          text: this.$t('application.kyc_section.city'),
          sortable: false,
          value: 'city',
        },
        {
          text: this.$t('application.kyc_section.province'),
          sortable: false,
          value: 'province',
        },
        {
          text: this.$t('application.kyc_section.postal_code'),
          sortable: false,
          value: 'postal_code',
        },
      ];
    },
    addressItems() {
      return this.kyc.consumer_report.addresses;
    },
    sortedFullMatches() {
      return this.sortMatches(this.kyc.single_source.matches_cleaned.fullMatches);
    },
    sortedPartialMatches() {
      return this.sortMatches(this.kyc.single_source.matches_cleaned.partialMatches);
    },
    sortedMissingMatches() {
      return this.sortMatches(this.kyc.single_source.matches_cleaned.missingMatches);
    },

    interpretDualSourceHits() {
      const singleSourceDecision = this.kyc.single_source.decision;
      let dualSourceHitsInterpreted = 'one';

      if (this.kyc.dual_source.sources.length) {
        dualSourceHitsInterpreted = 'multiple';
      } else if (singleSourceDecision === 'Y') {
        dualSourceHitsInterpreted = 'na';
      } else if (this.kyc.dual_source.hit === 'N') {
        dualSourceHitsInterpreted = 'no';
      }

      return dualSourceHitsInterpreted;
    },

    displayDualSourceHitText() {
      switch (this.interpretDualSourceHits) {
        case 'one':
        case 'no':
          return this.$t(`code.application.kyc.hit.${this.application.kyc.result.header.DualSourceHit}`);
        case 'multiple':
          this.setSingleSourceHitColumnWidth();
          return (
            this.$t(`code.application.kyc.hit.${this.application.kyc.result.header.DualSourceHit}`) + ` (${this.kyc.dual_source.sources.length})`
          );
        case 'na':
          return this.$t('code.application.kyc.hit.na');
      }

      return undefined;
    },

    formatOtherNames() {
      if (this.kyc.consumer_report.also_known_as.length > 0) {
        const otherNames = this.kyc.consumer_report.also_known_as;
        const totalNames = otherNames.length;
        let otherNamesString = '';

        for (let i = 0; i < totalNames; i++) {
          otherNamesString += `${otherNames[i].first_name} ${otherNames[i].last_name}`;
          if (totalNames !== 1 && i < totalNames - 1) {
            otherNamesString += ', ';
          }
        }
        return otherNamesString;
      }
      return undefined;
    },

    fileSinceDateClass() {
      if (this.getYearsElapsed(this.kyc.consumer_report.file_since_date, this.application.kyc.verification_date) < 3) {
        return 'young-date';
      }
      return 'old-date';
    },
    equifaxError() {
      return this.hasEquifaxErrorDetail
        ? `Source Code: ${this.application.kyc.equifax.error.source_code}\nCode: ${this.application.kyc.equifax.error.code}\nDescription: ${this.application.kyc.equifax.error.description}`
        : '';
    },
    reasonDetail() {
      return this.hasReasonDetail ? `Code: ${this.application.kyc.reason.code}\nMessage: ${this.application.kyc.reason.message}` : '';
    },
    hasEquifaxErrorDetail() {
      return this.application.kyc.equifax && _.get(this.application.kyc.equifax.error, 'source_code', false);
    },
    hasReasonDetail() {
      return this.application.kyc.reason && _.get(this.application.kyc.reason, 'code', false);
    },
  },
  methods: {
    kycExists() {
      return !_.isEmpty(this.kyc);
    },
    convertToYesOrNo(code) {
      if (code === 'Y') {
        return 'yes';
      } else if (code === 'N') {
        return 'no';
      } else {
        return 'na';
      }
    },

    setSingleSourceHitColumnWidth() {
      this.dualSourceHitWidth = 'dual-source-hit-width';
    },

    getYearsElapsed(toDate, fromDate) {
      if (fromDate) {
        return new moment(fromDate).diff(new moment(toDate), 'years');
      }
      return new moment().diff(new moment(toDate), 'years');
    },

    sortMatches(matchesToSort) {
      const matches = _.clone(matchesToSort);
      const ordering = {};
      const sortOrder = [
        'FullNameMatch',
        'FirstNameMatch',
        'LastNameMatch',
        'DateOfBirthMatch',
        'CurrentAddressMatch',
        'AddressCivicMatch',
        'AddressStreetNameMatch',
        'AddressCityMatch',
        'AddressProvinceMatch',
        'AddressPostalCodeMatch',
        'FirstFormerAddressMatch',
        'SecondFormerAddressMatch',
      ];

      for (let i = 0; i < sortOrder.length; i++) {
        ordering[sortOrder[i]] = i;
      }

      matches.sort(function(a, b) {
        return ordering[a] - ordering[b];
      });
      return matches;
    },
  },
};
</script>
