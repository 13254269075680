<template>
  <card :title="$t('bank_account.details.notes_section.title')" icon="">
    <template slot="headerZoneRight">
      <label-text
        class="text-xs-center"
        :label="`${$t('bank_account.details.notes_section.count')}`"
        :value="listBankAccountNotesItemCount || '0'"
      ></label-text>
      <div v-if="showAddBankAccountNoteFeature()" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text v-if="showAddBankAccountNoteFeature()" :label="`${$t('bank_account.details.notes_section.action')}`" value="3">
        <link-secondary-action slot="labelTextLink" @click="openAddBankAccountNoteModal">
          {{ $t('bank_account.details.notes_section.create_note') }}
        </link-secondary-action>
      </label-text>
    </template>
    <template slot="content">
      <bank-account-notes-table
        v-if="bankAccountNotes.length"
        :items="bankAccountNotes"
        :bank-account-key="bankAccount.key"
        @bank_account_note_delete_action="openDeleteBankAccountNoteModal"
      ></bank-account-notes-table>
      <label-none v-if="bankAccountNotes.length === 0">{{ $t('bank_account.details.notes_section.no_found') }}</label-none>
      <modal-action :show-modal="showAddBankAccountNoteModal"
        ><create-bank-account-note-action
          :v-if="showAddBankAccountNoteModal"
          :bank-account-key="bankAccount.key"
          @close="closeAddBankAccountNoteModal"
          @created="addBankAccountNoteSuccess"
        ></create-bank-account-note-action
      ></modal-action>
      <modal-action :show-modal="showDeleteBankAccountNoteModal"
        ><delete-bank-account-note-action
          :v-if="showDeleteBankAccountNoteModal"
          :bank-account-key="bankAccount.key"
          :note-id="noteIdToDelete"
          @close="closeDeleteBankAccountNoteModal"
          @deleted="deleteBankAccountNoteSuccess"
        ></delete-bank-account-note-action
      ></modal-action>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import CreateBankAccountNoteAction from '../actions/CreateBankAccountNoteAction';
import DeleteBankAccountNoteAction from '../actions/DeleteBankAccountNoteAction';
import BankAccountNotesTable from './BankAccountNotesTable';
import { mapGetters } from 'vuex';

export default {
  name: 'bank-account-notes',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { BankAccountNotesTable, CreateBankAccountNoteAction, DeleteBankAccountNoteAction },
  data() {
    return {
      showAddBankAccountNoteModal: false,
      showDeleteBankAccountNoteModal: false,
      noteIdToDelete: null,
      noteToDelete: {},
    };
  },
  computed: {
    ...mapGetters('bankAccount', ['bankAccountNotes', 'bankAccount', 'listBankAccountNotesItemCount']),
    ...mapGetters('ui', ['currentLocale']),
  },
  watch: {
    async bankAccount() {
      await this.fetchBankAccountNotes();
    },
  },
  methods: {
    openAddBankAccountNoteModal() {
      this.showAddBankAccountNoteModal = true;
    },
    closeAddBankAccountNoteModal() {
      this.showAddBankAccountNoteModal = false;
    },
    openDeleteBankAccountNoteModal(noteId) {
      this.noteIdToDelete = noteId;
      this.noteToDelete = this.bankAccountNotes.find((l) => l.id === noteId);
      this.showDeleteBankAccountNoteModal = true;
    },
    closeDeleteBankAccountNoteModal() {
      this.noteIdToDelete = null;
      this.noteToDelete = {};
      this.showDeleteBankAccountNoteModal = false;
    },
    async deleteBankAccountNoteSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'bank_account.delete_note_action.success' });
      this.closeDeleteBankAccountNoteModal();
    },
    async addBankAccountNoteSuccess() {
      await this.fetchBankAccountNotes();
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'bank_account.create_note_action.success' });
      this.closeAddBankAccountNoteModal();
    },
    showAddBankAccountNoteFeature() {
      return this.canManageOffer(); // TODO change permission
    },
    async fetchBankAccountNotes() {
      await this.executeAction({ action: 'bankAccount/listBankAccountNotes' }, { bank_account_key: this.bankAccount.key });
    },
  },
};
</script>
