<template>
  <card-modal
    icon=""
    :title="$t('space.clerk_table.add_action.title')"
    context="AddClerkAction"
    @submit="submit"
    @cancel="cancel"
    :submit_working="working"
    cancel_text="space.clerk_table.add_action.cancel"
    submit_text="space.clerk_table.add_action.submit"
  >
    <form @submit.prevent="submit" slot="content">
      <v-text-field
        autofocus
        :label="$t('space.clerk_table.add_action.name') + ' *'"
        name="name"
        v-model="name"
        v-validate="{ required: true }"
        :error-messages="errors.collect('name')"
        data-vv-name="name"
        maxlength="50"
        :data-vv-as="$t('space.clerk_table.add_action.name')"
      ></v-text-field>
      <v-text-field
        :label="$t('space.clerk_table.add_action.username') + ' *'"
        name="username"
        v-model="username"
        v-validate="{ required: true }"
        :error-messages="errors.collect('username')"
        data-vv-name="username"
        maxlength="50"
        :data-vv-as="$t('space.clerk_table.add_action.username')"
      ></v-text-field>
      <v-text-field
        :label="$t('space.clerk_table.add_action.email') + (isAdmin ? ' *' : '')"
        name="email"
        v-model="email"
        v-validate="{ required: isAdmin, email: true }"
        :error-messages="errors.collect('email')"
        data-vv-name="email"
        maxlength="255"
        :data-vv-as="$t('space.clerk_table.add_action.email')"
      ></v-text-field>
      <v-select
        name="language"
        v-model="language"
        :error-messages="errors.collect('language')"
        v-validate="{ required: true }"
        data-vv-name="language"
        :data-vv-as="$t('space.clerk_table.add_action.language') + ' *'"
        :items="languageOptions"
        item-text="name"
        item-value="abbr"
        :label="$t('space.clerk_table.add_action.language')"
      ></v-select>
      <v-text-field
        v-model="passcode"
        v-validate="{ required: true }"
        hint="0000"
        :label="$t('space.clerk_table.add_action.passcode') + '*'"
        name="passcode"
        v-maska="'####'"
        maxlength="4"
        :error-messages="errors.collect('passcode')"
        data-vv-name="clerk_passcode"
        :data-vv-as="$t('space.clerk_table.add_action.passcode')"
      ></v-text-field>

      <v-select
        name="establishments"
        v-model="establishments"
        :error-messages="errors.collect('establishments')"
        data-vv-name="establishments"
        :data-vv-as="$t(`space.clerk_table.add_action.establishments`)"
        :items="activeSpacePartnerEstablishmentOptions"
        item-text="label"
        item-value="value"
        :label="$t(`space.clerk_table.add_action.establishments`)"
        multiple
      ></v-select>

      <h4>{{ $t('space.clerk_table.add_action.roles_title') }}</h4>
      <v-radio-group
        row
        v-model="roles"
        name="roles"
        v-validate="'required'"
        :error-messages="errors.collect('roles')"
        data-vv-name="roles"
        :data-vv-as="$t('space.clerk_table.add_action.roles_title')"
      >
        <v-radio
          :label="$t('space.clerk_table.add_action.roles.clerk')"
          :value="clerkRoleOptions.clerk"
          data-test="add-action--roles-clerk"
        ></v-radio>
        <v-radio
          :label="$t('space.clerk_table.add_action.roles.admin')"
          :value="clerkRoleOptions.admin"
          data-test="add-action--roles-admin"
        ></v-radio>
        <v-radio
          :label="$t('space.clerk_table.add_action.roles.superadmin')"
          :value="clerkRoleOptions.superadmin"
          data-test="add-action--roles-superadmin"
        ></v-radio>
      </v-radio-group>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'add-clerk-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    partnerId: {
      type: String,
    },
    spaceId: {
      type: String,
    },
  },
  data() {
    return {
      name: null,
      username: null,
      email: null,
      language: null,
      passcode: this.passcode,
      roles: [],
      languageOptions: [
        { name: this.$t('language.french'), abbr: 'fr' },
        { name: this.$t('language.english'), abbr: 'en' },
      ],
      establishments: [],
    };
  },
  computed: {
    ...mapGetters('space', ['activeSpacePartnerEstablishmentOptions']),
    ...mapGetters('partnerSecurity', ['clerkRoleOptions']),
    isAdmin() {
      return this.roles.includes('admin');
    },
  },
  methods: {
    clear() {
      this.name = null;
      this.username = null;
      this.passcode = null;
      this.language = null;
      this.email = null;
      this.roles = [];
      (this.establishments = []), this.$validator.reset();
    },
    success() {
      this.clear();
      this.$emit('success');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    buildPayload() {
      const payload = {
        space_id: this.spaceId,
        partner_id: this.partnerId,
        name: this.name,
        email: _.isEmpty(this.email) ? null : this.email,
        language: this.language,
        username: this.username,
        roles: this.roles,
        passcode: this.passcode,
        establishment_ids: this.establishments.map((establishment) => {
          return establishment.id;
        }),
      };

      return payload;
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        const payload = this.buildPayload();

        await this.executeAction(
          {
            action: 'partnerSecurity/addClerk',
            name: 'AddClerkAction',
            success: this.success,
          },
          payload,
        );
      }
    },
  },
};
</script>
