export default (application) => {
  if (application.kyc.reason && application.kyc.reason.code) {
    const statusMap = {
      'card.applicant.not.found': 'not_found',
      'card.applicant.not.enough.credit_informations': 'not_confirmed',
      'third_party.not_available': 'error',
      'third_party.parser_error': 'parser_error',
      'test.card': 'test',
      'card.active.already.in.card_program': 'duplicate',
    };

    return statusMap[application.kyc.reason.code] || application.kyc.reason.code;
  }
  return 'na';
};
