import Vue from 'vue';

const service = {
  async onboardPartner(partner_id, payload) {
    const response = await Vue.prototype.$axios.post(
      `${process.env.VUE_APP_BASE_API_URL}/partner-security/v1/partners/${partner_id}/onboarding`,
      payload,
    );
    return response.data.data;
  },

  async createClerk(partner_id, payload) {
    const response = await Vue.prototype.$axios.post(
      `${process.env.VUE_APP_BASE_API_URL}/partner-security/v1/partners/${partner_id}/clerks`,
      payload,
    );
    return response.data.data;
  },

  async getClerk(id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/partner-security/v1/clerks/${id}`);
    return response.data.data;
  },

  async listClerks(partner_id, page, limit) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/partner-security/v1/partners/${partner_id}/clerks`, page, limit)}`,
    );
    return response.data.data;
  },

  async updateClerk(id, params) {
    const payload = params;
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/partner-security/v1/clerks/${id}`, payload);
    return response.data.data;
  },

  async changePasscode(id, payload) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/partner-security/v1/clerks/${id}/passcode`, payload);
    return response.data.data;
  },

  async activateClerk(id) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/partner-security/v1/clerks/${id}/activate`);
    return response.data.data;
  },

  async suspendClerk(id) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/partner-security/v1/clerks/${id}/suspend`);
    return response.data.data;
  },

  async deactivateClerk(id) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/partner-security/v1/clerks/${id}/deactivate`);
    return response.data.data;
  },

  async unlockClerk(id) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/partner-security/v1/clerks/${id}/unlock`);
    return response.data.data;
  },

  async createWorkstation(partner_id, payload) {
    const response = await Vue.prototype.$axios.post(
      `${process.env.VUE_APP_BASE_API_URL}/partner-security/v1/partners/${partner_id}/workstations`,
      payload,
    );
    return response.data.data;
  },

  async searchWorkstations(page, limit, keyword, partner_id) {
    const params = {};
    if (keyword) {
      params.keyword = keyword;
    }

    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/partner-security/v1/partners/${partner_id}/workstations/search`, page, limit, params)}`,
    );
    return response.data.data;
  },

  async getWorkstation(id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/partner-security/v1/workstations/${id}`);
    return response.data.data;
  },

  async listWorkstations(partner_id, page, limit) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/partner-security/v1/partners/${partner_id}/workstations`, page, limit)}`,
    );
    return response.data.data;
  },

  async updateWorkstation(id, params) {
    const payload = params;
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/partner-security/v1/workstations/${id}`, payload);
    return response.data.data;
  },

  async activateWorkstation(id) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/partner-security/v1/workstations/${id}/activate`);
    return response.data.data;
  },

  async suspendWorkstation(id) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/partner-security/v1/workstations/${id}/suspend`);
    return response.data.data;
  },

  async deactivateWorkstation(id) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/partner-security/v1/workstations/${id}/deactivate`);
    return response.data.data;
  },

  async getWorkstationRegistrationRequest(id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/partner-security/v1/workstation-registrations/${id}`);
    return response.data.data;
  },

  async createWorkstationRegistrationRequest(partner_id, workstation_id, payload) {
    const response = await Vue.prototype.$axios.post(
      `${process.env.VUE_APP_BASE_API_URL}/partner-security/v1/partners/${partner_id}/workstations/${workstation_id}/workstation-registrations`,
      payload,
    );
    return response.data.data;
  },

  async listWorkstationRegistrationRequests(partner_id, workstation_id, page, limit) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/partner-security/v1/partners/${partner_id}/workstation-registrations`, page, limit, {
        workstation_id,
      })}`,
    );
    return response.data.data;
  },

  async approveWorkstationRegistrationRequest(id, payload) {
    const response = await Vue.prototype.$axios.post(
      `${process.env.VUE_APP_BASE_API_URL}/partner-security/v1/workstation-registrations/${id}/approve`,
      payload,
    );
    return response.data.data;
  },

  async rejectWorkstationRegistrationRequest(id, payload) {
    const response = await Vue.prototype.$axios.post(
      `${process.env.VUE_APP_BASE_API_URL}/partner-security/v1/workstation-registrations/${id}/reject`,
      payload,
    );
    return response.data.data;
  },
};

function generatePath(startPath, page, limit, { keyword, workstation_id } = {}) {
  let path = startPath;
  if (page || limit || keyword) {
    path += '?';
  }

  const queryStrings = [];
  if (page) {
    queryStrings.push(['page', page]);
  }
  if (limit) {
    queryStrings.push(['limit', limit]);
  }
  if (keyword) {
    queryStrings.push(['keyword', keyword]);
  }
  if (workstation_id) {
    queryStrings.push(['workstation_id', workstation_id]);
  }

  const params = new URLSearchParams(queryStrings);
  return path + params.toString();
}

export default service;
