<template>
  <card-modal
    icon=""
    :title="$t('space.workstation_table.add_action.title')"
    context="AddWorkstationAction"
    @submit="submit"
    @cancel="cancel"
    :submit_working="working"
    cancel_text="space.workstation_table.add_action.cancel"
    submit_text="space.workstation_table.add_action.submit"
  >
    <form @submit.prevent="submit" slot="content">
      <v-text-field
        :label="$t('space.workstation_table.add_action.name') + ' *'"
        name="name"
        autofocus
        v-model="name"
        v-validate="{ required: true }"
        :error-messages="errors.collect('name')"
        data-vv-name="name"
        maxlength="50"
        :data-vv-as="$t('space.workstation_table.add_action.name')"
      ></v-text-field>

      <v-checkbox
        name="ip_restricted"
        v-model="ip_restricted"
        :label="$t(`space.workstation_table.add_action.ip_restricted`)"
        :error-messages="errors.collect('ip_restricted')"
        data-vv-name="ip_restricted"
        :data-vv-as="$t(`space.workstation_table.add_action.ip_restricted`)"
        type="checkbox"
      ></v-checkbox>

      <v-select
        name="establishments"
        v-model="establishment_ids"
        :error-messages="errors.collect('establishments')"
        data-vv-name="establishments"
        :data-vv-as="$t(`space.workstation_table.add_action.establishments`)"
        :items="activeSpacePartnerEstablishmentOptions"
        item-text="label"
        item-value="value"
        :label="$t(`space.workstation_table.add_action.establishments`)"
        multiple
      ></v-select>
    </form>
  </card-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { actionErrorTrackable } from '@/mixins';

export default {
  name: 'add-workspace-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    partnerId: {
      type: String,
    },
    spaceId: {
      type: String,
    },
  },
  data() {
    return {
      name: null,
      ip_restricted: false,
      approbation_required: false,
      establishment_ids: [],
    };
  },
  computed: {
    ...mapGetters('space', ['activeSpacePartnerEstablishmentOptions']),
  },
  methods: {
    clear() {
      this.name = null;
      this.ip_restricted = false;
      this.approbation_required = false;
      this.establishment_ids = [];
      this.$validator.reset();
    },
    success() {
      this.clear();
      this.$emit('success');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    buildPayload() {
      const payload = {
        name: this.name,
        ip_restricted: this.ip_restricted,
        approbation_required: this.approbation_required,
        partner_id: this.partnerId,
        space_id: this.spaceId,
        establishment_ids: this.establishment_ids.map((establishment) => {
          return establishment.id;
        }),
      };

      return payload;
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        await this.executeAction(
          {
            action: 'partnerSecurity/addWorkstation',
            name: 'AddWorkstationAction',
            success: this.success,
          },
          this.buildPayload(),
        );
      }
    },
  },
};
</script>
