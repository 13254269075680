<template>
  <card :title="$t('instant_card_cards.details.batch_section.title')" icon="">
    <template slot="content">
      <v-layout row>
        <v-flex xs3>
          <label-text :label="$t(`instant_card_cards.details.batch_section.id`)" :value="card.instant_card_batch_id">
            <p v-if="card.instant_card_batch_id" class="label-text--value" slot="labelTextLink">
              <router-link :to="{ name: 'instant_card_batch_details', params: { batchId: card.instant_card_batch_id } }">{{
                card.instant_card_batch_id
              }}</router-link>
            </p>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </label-text>
        </v-flex>
        <v-flex xs3>
          <label-text
            :label="$t(`instant_card_cards.details.batch_section.name`)"
            :value="card.instant_card_batch ? card.instant_card_batch.name : ''"
          ></label-text>
        </v-flex>
        <v-flex xs3>
          <label-text
            :label="$t(`instant_card_cards.details.batch_section.owner`)"
            :value="card.instant_card_batch ? card.instant_card_batch.business_id : ''"
          >
            <p v-if="card.instant_card_batch && card.instant_card_batch.business_id" class="label-text--value" slot="labelTextLink">
              <router-link :to="{ name: 'business_details', params: { businessId: card.instant_card_batch.business_id } }">
                {{ card.instant_card_batch.business.name }} ({{ card.instant_card_batch.business_id }})</router-link
              >
            </p>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </label-text>
        </v-flex>
        <v-flex xs3>
          <label-text
            v-if="card.instant_card_batch && card.instant_card_batch.delivery_address"
            :label="$t(`instant_card_cards.details.batch_section.shipping_address`)"
            :value="fullAddress(card.instant_card_batch.delivery_address)"
          />
        </v-flex>
      </v-layout>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'batch',
  mixins: [security, actionErrorTrackable, disableWithError],
  data() {
    return {
      dataReady: false,
    };
  },
  async created() {},
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('instantCardCard', ['card']),
  },
  methods: {
    fullAddress(address) {
      return `${address.street}, ${address.city}, ${address.state}, ${address.country}, ${address.zip}`;
    },
  },
};
</script>
