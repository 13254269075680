<template>
  <view-main title="page_title.workstation_registration_details">
    <six-levels-breadcrumbs
      slot="content"
      :top-label="$t('navigation.spaces')"
      :top-to="{ name: 'spaces' }"
      :second-label="spaceName"
      :second-to="{ name: 'space_details', params: { spaceId } }"
      :third-label="$t('navigation.workstations')"
      :fourth-label="workstationRegistrationRequest.workstation_name"
      :fourth-to="{ name: 'workstation_details', params: { workstation_id: workstationRegistrationRequest.workstation_id } }"
      :fifth-label="$t('navigation.workstations_registration_request')"
      :last-label="workstationRegistrationRequest.id"
    />
    <workstation-registration-information slot="content"></workstation-registration-information>
  </view-main>
</template>

<script>
import { actionErrorTrackable, renderErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import WorkstationRegistrationInformation from './detail/WorkstationRegistrationInformation.vue';

export default {
  name: 'workstation-registration-detail-view',
  mixins: [renderErrorTrackable, actionErrorTrackable],
  components: { WorkstationRegistrationInformation },
  props: {
    workstationRegistrationRequestId: {
      type: String,
      required: true,
    },
    spaceId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      spaceName: '',
    };
  },
  computed: {
    ...mapGetters('partnerSecurity', ['workstationRegistrationRequest']),
  },
  async mounted() {
    await this.fetch();
  },
  watch: {
    async workstationRegistrationRequestId() {
      return this.fetch();
    },
  },
  methods: {
    async fetch() {
      const workstationRegistrationRequest = await this.executeAction(
        { action: 'partnerSecurity/getWorkstationRegistrationRequest' },
        this.workstationRegistrationRequestId,
      );
      if (!workstationRegistrationRequest) {
        this.$router.replace({ name: 'notFound' });
      }

      const space = await this.executeAction({ action: 'space/getSpace' }, this.spaceId);
      if (space) {
        this.spaceName = space.name;
      }
    },
  },
};
</script>
