<template>
  <card :title="$t('destination.stores.title')" icon="">
    <template slot="headerZoneRight">
      <label-text class="text-xs-center" :label="`${$t('destination.stores.count')}`" :value="destinationStoresItemCount || '0'"></label-text>
    </template>
    <template slot="content">
      <destination-stores-table
        v-if="destinationStores.length"
        :items="destinationStores"
        :destination-id="destination.id"
      ></destination-stores-table>
      <label-none v-if="destinationStores.length === 0">{{ $t('system.na') }}</label-none>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';
import DestinationStoresTable from './DestinationStoresTable.vue';

export default {
  name: 'destination-stores',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { DestinationStoresTable },
  data() {
    return {
      pagination: 1,
    };
  },
  watch: {
    async destination() {
      return this.executeAction({ action: 'destination/listDestinationStores' }, { id: this.destination.id, page: this.destinationStoresPageNumber });
    },
    destinationStoresPageNumber() {
      this.pagination = this.destinationStoresPageNumber;
    },
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'destination/changeDestinationStoresPage',
          success: this.success,
        },
        {
          id: this.destination.id,
          page: newValue,
        },
      );
    },
  },
  computed: {
    ...mapGetters('destination', ['destination', 'destinationStores', 'destinationStoresPageNumber', 'destinationStoresItemCount']),
    ...mapGetters('ui', ['currentLocale']),
  },
};
</script>
