<template>
  <card-modal
    icon=""
    :title="$t('publishers.details.offers_section.create_action.title')"
    context="addOfferToPublisher"
    @submit="submit"
    @cancel="cancel"
    cancel_text="publishers.details.offers_section.create_action.button_cancel"
    submit_text="publishers.details.offers_section.create_action.button_submit"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <modal-info-block :heading="$t('publishers.details.offers_section.create_action.publisher')" :text="publisherInfo"></modal-info-block>
        <h-autocomplete
          autofocus
          v-model="offerToAdd"
          :label="$t('publishers.details.offers_section.create_action.offer')"
          v-validate="'required'"
          :error-messages="errors.collect('offer')"
          name="offer"
          data-vv-name="offer"
          data-vv-as="offer"
          search-action="publishers/autocompleteSearchOfferToAddToPublisher"
          :extra-search-params="{ publisher_id: publisherObj.id }"
          data-test="add-publisher-offer"
          no-filter
        >
        </h-autocomplete>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default {
  name: 'add-publisher-offer-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    publisherObj: {
      type: Object,
    },
  },
  data() {
    return {
      offerToAdd: null,
    };
  },
  computed: {
    publisherInfo() {
      return `${this.publisherObj.name} (${this.publisherObj.id})`;
    },
  },
  methods: {
    clear() {
      this.offerToAdd = null;
      this.$validator.reset();
    },
    success() {
      this.clear();
      this.$emit('created');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        if (this.offerToAdd) {
          const payload = { offer_id: this.offerToAdd, publisher_id: this.publisherObj.id };

          await this.executeAction(
            {
              action: 'publishers/addOfferToPublisher',
              name: 'addOfferToPublisher',
              success: this.success,
            },
            payload,
          );
        }
      }
    },
  },
};
</script>
