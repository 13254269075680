<template>
  <v-sheet :color="backgroundColor" data-test="card-aml-document--item">
    <v-layout row wrap justify-space-between :key="document.id">
      <v-flex>
        <layout-message :date="document.created_at">
          <app-name slot="author" :app_key="document.app_key"></app-name>

          <div row align-center slot="content">
            <h5>{{ documentType }}</h5>

            <p>
              <a @click="download" class="break-word-wrap button-subtle" role="button">{{ document.filename }}</a>

              <span>&nbsp;({{ document.content_byte_size | bytes }})</span>
            </p>

            <label class="label-text--value red--text" v-if="isDeleted">
              {{ $t('application.aml_note_section.deleted_by') }}
              <inline-app-name :app_key="document.deleted.app_key" />
              ({{ document.deleted.deleted_at | formatDateHourMin }}):
              <span data-test="card-aml-document--delete-reason">{{ document.deleted.reason }}</span>
            </label>
          </div>

          <span slot="actions" v-if="!isDeleted && canUploadDocument">
            <link-secondary-action data-test="aml-document-action--delete" slot="labelTextLink" @click="deleteDocument">{{
              $t('application.aml_note_section.delete')
            }}</link-secondary-action>
          </span>

          <v-divider slot="divider" v-if="hasDivider" />
        </layout-message>
      </v-flex>
    </v-layout>
  </v-sheet>
</template>

<script>
import _get from 'lodash/get';
import { mapActions } from 'vuex';
import { actionErrorTrackable, disableWithError, security } from '@/mixins';

export default {
  name: 'aml-document',

  mixins: [actionErrorTrackable, disableWithError, security],

  props: {
    document: {
      type: Object,
      required: true,
    },

    hasDivider: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    documentType() {
      return this.$t(`aml.document_types.${this.document.document_type}`);
    },

    isDeleted() {
      return _get(this.document, 'deleted.deleted_at', false);
    },

    backgroundColor() {
      return this.isDeleted ? 'red lighten-4' : '';
    },

    canUploadDocument() {
      if (this.fromView === 'card') {
        return this.canUploadDocumentCard();
      }

      return this.canUploadDocumentApplication();
    },
  },

  methods: {
    ...mapActions('aml', ['downloadDocument', 'showDeleteDocumentModal']),

    async download() {
      await this.downloadDocument({
        id: this.document.id,
        filename: this.document.filename,
      });
    },

    async deleteDocument() {
      await this.showDeleteDocumentModal(this.document);
    },
  },
};
</script>
