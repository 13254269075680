<template>
  <v-sheet :color="backgroundColor" data-test="app-comment--item">
    <layout-message :date="comment.created">
      <app-name :app_key="comment.app_key" slot="author" />

      <v-divider slot="divider" v-if="hasDivider" />

      <span slot="actions" v-if="!isDeleted && canCommentApplication()">
        <link-secondary-action data-test="app-comment-action--delete" slot="labelTextLink" @click="deleteComment">{{
          $t('application.comments_section.delete')
        }}</link-secondary-action>
      </span>

      <div slot="content">
        <div>{{ comment.comment }}</div>

        <label class="label-text--value red--text" v-if="isDeleted">
          {{ $t('application.comments_section.deleted_by') }}
          <inline-app-name :app_key="comment.deleted.app_key" />
          ({{ comment.deleted.deleted_at | formatDateHourMin }}):
          <span data-test="app-comment--delete-reason">{{ comment.deleted.reason }}</span>
        </label>
      </div>
    </layout-message>
  </v-sheet>
</template>

<script>
import _get from 'lodash/get';
import { actionErrorTrackable, security } from '@/mixins';

export default {
  name: 'application-comment',

  mixins: [actionErrorTrackable, security],

  computed: {
    isDeleted() {
      return _get(this.comment, 'deleted.deleted_at', false);
    },

    backgroundColor() {
      return this.isDeleted ? 'red lighten-4' : '';
    },
  },

  props: {
    comment: {
      type: Object,
      required: true,
    },

    hasDivider: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    async deleteComment() {
      await this.executeAction({ action: 'application/showDeleteCommentModal' }, this.comment);
    },
  },
};
</script>
