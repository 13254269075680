import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    cardPackage: null,
    cardPackages: [],
  },
  mutations: {
    cardPackage(state, cardPackage) {
      state.cardPackage = cardPackage;
    },
    cardPackages(state, cardPackages) {
      state.cardPackages = cardPackages;
    },
  },
  getters: {
    cardPackage: (state) => state.cardPackage,
    cardPackages: (state) => state.cardPackages,
  },
  actions: {
    async getCardPackage({ commit }, id) {
      const contextService = Vue.prototype.$services.context;
      const cardPackage = await contextService.getCardPackage(id);

      commit('cardPackage', cardPackage);
    },
    async listCardPackages({ commit }, { page, limit, params }) {
      const contextService = Vue.prototype.$services.context;
      const thePage = page || 1;
      const theLimit = limit || 100;

      const result = await contextService.listCardPackages(thePage, theLimit, params);

      commit('cardPackages', result);
    },
  },
};
