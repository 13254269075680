<template>
  <card-modal
    icon=""
    :title="$t('workstation.update_action.title')"
    context="updateWorkstation"
    @submit="submit"
    @cancel="cancel"
    cancel_text="workstation.update_action.button_cancel"
    submit_text="workstation.update_action.button_save"
  >
    <form @submit.prevent="submit" slot="content">
      <v-text-field
        :label="$t('workstation.update_action.name') + ' *'"
        name="name"
        v-model="currentWorkstation.name"
        v-validate="{ required: true }"
        :error-messages="errors.collect('name')"
        data-vv-name="name"
        maxlength="50"
        :data-vv-as="$t('workstation.update_action.name')"
      ></v-text-field>

      <v-checkbox
        name="ip_restricted"
        v-model="currentWorkstation.ip_restricted"
        :label="$t(`workstation.update_action.ip_restricted`)"
        :error-messages="errors.collect('ip_restricted')"
        data-vv-name="ip_restricted"
        :data-vv-as="$t(`workstation.update_action.ip_restricted`)"
        type="checkbox"
      ></v-checkbox>

      <v-flex>
        <v-layout row>
          <v-flex xs11>
            <v-text-field
              name="allowed_cidr"
              v-model="allowed_cidr"
              v-validate="'validateRange'"
              :label="$t('workstation.update_action.allowed_cidr')"
              :error-messages="errors.collect('allowed_cidr')"
              data-vv-name="allowed_cidr"
              :data-vv-as="$t('workstation.update_action.allowed_cidr')"
            ></v-text-field>
          </v-flex>
          <v-flex xs1>
            <button type="button" class="add-cidr" @click="addCidrToArray" :disabled="disable_add_cidr">
              <v-icon small>fal fa-plus</v-icon>
            </button>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex v-if="workstation && selectedCidrs && selectedCidrs.length > 0" xs11 class="cidrs-section">
        <v-label>{{ $t('workstation.update_action.allowed_cidrs_label') }}</v-label>
        <ul class="cidrs-list">
          <li v-for="(cidr, index) in selectedCidrs" :key="index">
            {{ cidr }}
            <button type="button" class="remove-cidr" @click="removeCidrFromArray(cidr)">
              <v-icon small>fal fa-minus-circle</v-icon>
            </button>
          </li>
        </ul>
      </v-flex>
      <v-select
        name="establishments"
        v-model="currentWorkstation.establishment_ids"
        :error-messages="errors.collect('establishments')"
        data-vv-name="establishments"
        :data-vv-as="$t(`workstation.update_action.establishments`)"
        :items="activeSpacePartnerEstablishmentOptions"
        item-text="label"
        item-value="value"
        :label="$t(`workstation.update_action.establishments`)"
        multiple
      ></v-select>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'update-workstation-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    workstation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentWorkstation: {},
      selectedCidrs: _.clone(this.workstation.allowed_cidrs),
      allowed_cidr: '',
      disable_add_cidr: true,
    };
  },
  async mounted() {
    this.currentWorkstation = Object.assign({}, _.cloneDeep(this.workstation));
    this.currentWorkstation.establishment_ids = this.currentWorkstation.establishments.map((est) => {
      return {
        id: est.id,
        business_id: est.business_id,
      };
    });
  },
  watch: {
    allowed_cidr() {
      if (this.allowed_cidr && this.isCidrValid()) {
        this.disable_add_cidr = false;
      } else {
        this.disable_add_cidr = true;
      }
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('space', ['activeSpacePartnerEstablishmentOptions']),
  },
  methods: {
    clear() {
      this.currentWorkstation = {};
      this.establishment = { id: null, business_id: null };
      this.$validator.reset();
    },
    isCidrValid() {
      if (this.errors.has('workstation.allowed_cidr')) {
        return false;
      }
      return true;
    },
    addCidrToArray() {
      if (this.isCidrValid() && !this.selectedCidrs.includes(this.allowed_cidr)) {
        this.selectedCidrs.push(this.allowed_cidr);
        this.allowed_cidr = '';
      }
    },
    removeCidrFromArray(cidr) {
      const updatedCidrs = _.filter(this.selectedCidrs, (item) => {
        return item !== cidr;
      });
      this.selectedCidrs = updatedCidrs;
    },
    success(response) {
      this.clear();
      this.$emit('updated');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    buildPayload() {
      const payload = {
        id: this.currentWorkstation.id,
        name: this.currentWorkstation.name,
        ip_restricted: this.currentWorkstation.ip_restricted,
        allowed_cidrs: this.selectedCidrs,
      };

      payload.establishment_ids = this.currentWorkstation.establishment_ids.map((establishment) => {
        return establishment.id;
      });

      return payload;
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();
      if (validationComplete) {
        await this.executeAction(
          {
            action: 'partnerSecurity/updateWorkstation',
            name: 'updateWorkstation',
            success: this.success,
          },
          this.buildPayload(),
        );
      }
    },
  },
};
</script>

<style scoped lang="stylus">
.add-cidr {
  position: relative;
  top: 0.8em;
}

.remove-cidr {
  top: -3px;
  position: relative;
  margin-left: 1em;
  margin-bottom: 0.4em;
}

.cidrs-section label {
  margin: 2em 0 1em;
  display: block;
}

.cidrs-list {
  list-style: none;
  padding-left: 0;
}
</style>
