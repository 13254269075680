<template>
  <view-main title="page_title.offers_details">
    <two-levels-breadcrumbs slot="content" :top-label="$t('navigation.offers')" :to="{ name: 'offers' }" :last-label="offer.id" />
    <offers-information slot="content" />
    <offers-urls slot="content" />
    <offer-promotions v-if="!isBonusOffer" slot="content" />
    <offers-tracking-links slot="content" />
    <offers-publishers slot="content" />
    <offers-branding slot="content" />
    <offers-access slot="content" />
    <offers-activation-code-batches v-if="!isBonusOffer" slot="content" />
    <offers-activation-codes v-if="!isBonusOffer" slot="content" />
    <offers-instant-card-projects slot="content" />
    <offer-vouchers slot="content" />
    <offer-webhook-listeners slot="content" />
    <offer-notes slot="content" />
  </view-main>
</template>

<script>
import { actionErrorTrackable, renderErrorTrackable } from '@/mixins';
import OffersInformation from './detail/OffersInformation.vue';
import OffersUrls from './detail/OffersUrls.vue';
import OffersTrackingLinks from './detail/OffersTrackingLinks.vue';
import OffersBranding from './detail/OffersBranding';
import OfferVouchers from './detail/OfferVouchers.vue';
import OffersAccess from './detail/OffersAccess.vue';
import OffersPublishers from './detail/OffersPublishers.vue';
import OffersActivationCodes from './detail/OffersActivationCodes.vue';
import OffersActivationCodeBatches from './detail/OffersActivationCodeBatches.vue';
import OffersInstantCardProjects from './detail/OffersInstantCardProjects.vue';
import OfferWebhookListeners from './detail/OfferWebhookListeners.vue';
import OfferNotes from './detail/OfferNotes.vue';
import OfferPromotions from './detail/OfferPromotions.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'offers-details-view',
  mixins: [renderErrorTrackable, actionErrorTrackable],
  components: {
    OffersBranding,
    OffersInformation,
    OffersUrls,
    OffersTrackingLinks,
    OfferVouchers,
    OffersPublishers,
    OffersActivationCodes,
    OffersActivationCodeBatches,
    OffersInstantCardProjects,
    OffersAccess,
    OfferWebhookListeners,
    OfferNotes,
    OfferPromotions,
  },
  props: {
    offerId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('offers', ['offer']),
    isBonusOffer() {
      return this.offer.type === 'bonus';
    },
  },
  async mounted() {
    await this.fetch();
  },
  watch: {
    async offerId() {
      return this.fetch();
    },
  },
  methods: {
    async fetch() {
      const offer = await this.executeAction({ action: 'offers/getOffer' }, this.offerId);
      if (!offer) {
        this.$router.replace({ name: 'notFound' });
      }
    },
  },
};
</script>
