<template>
  <card :title="`${$t('business.eft_items.data_section.title')}`" icon="">
    <template slot="headerZoneRight">
      <label-text :label="`${$t('business.details.bank_account_section.action')}`" value="">
        <link-secondary-action slot="labelTextLink" @click="openGenerateManualEftModal">
          {{ $t('business.eft_items.button_generate') }}
        </link-secondary-action>
      </label-text>
    </template>
    <template slot="content">
      <label-none v-if="dataReady && businessEftItems.length === 0">{{ $t('card.comments_section.no_found') }}</label-none>
      <business-eft-items-table v-if="dataReady && businessEftItems.length > 0" :items="businessEftItems"></business-eft-items-table>
      <div class="text-xs-center">
        <v-pagination
          v-if="businessEftItemsPageCount > 1"
          class="apps-table--pagination"
          v-model="pagination"
          :length="businessEftItemsPageCount"
        ></v-pagination>
      </div>
      <modal-action :show-modal="showGenerateManualEftModal">
        <generate-manual-eft-action
          v-if="showGenerateManualEftModal"
          :business_id="businessId"
          @manualEftCreated="updateBusinessSuccess"
          @close="closeGenerateManualEftModal"
        />
      </modal-action>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, renderErrorTrackable } from '@/mixins';
import BusinessEftItemsTable from './BusinessEftItemsTable';
import GenerateManualEftAction from '../actions/GenerateManualEftAction';
import { mapGetters } from 'vuex';

export default {
  name: 'business-eft-items',
  mixins: [renderErrorTrackable, actionErrorTrackable],
  components: { BusinessEftItemsTable, GenerateManualEftAction },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dataReady: false,
      showGenerateManualEftModal: false,
      pagination: 1,
    };
  },
  async created() {
    await this.fetchBusinessEftItems();
    this.pagination = this.businessEftItemsPageNumber;
    this.dataReady = true;
  },
  computed: {
    ...mapGetters('eft', ['businessEftItems', 'businessEftItemsPageCount', 'businessEftItemsPageNumber']),
  },
  methods: {
    openGenerateManualEftModal() {
      this.showGenerateManualEftModal = true;
    },
    async updateBusinessSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'business.eft_items.manual.generate_action.successful' });

      this.closeGenerateManualEftModal();
    },
    closeGenerateManualEftModal() {
      this.showGenerateManualEftModal = false;
    },
    async fetchBusinessEftItems(page) {
      const pageNumber = page || this.businessEftItemsPageNumber;
      await this.executeAction(
        { action: 'eft/listBusinessEftItems' },
        {
          id: this.businessId,
          page: pageNumber,
        },
      );
    },
  },
  watch: {
    businessEftItemsPageNumber() {
      this.pagination = this.businessEftItemsPageNumber;
    },
    async pagination(newValue) {
      return this.fetchBusinessEftItems(newValue);
    },
  },
};
</script>
