<template>
  <table class="responsive-table wallet-rules-table">
    <thead>
      <tr>
        <td>{{ $t('card.limits_and_balances.merchant_id') }}</td>
        <td>{{ $t('card.limits_and_balances.merchant_name') }}</td>
        <td>{{ $t('card.limits_and_balances.terminal_id') }}</td>
        <td>{{ $t('card.limits_and_balances.city') }}</td>
        <td>{{ $t('card.limits_and_balances.state') }}</td>
        <td>{{ $t('card.limits_and_balances.country') }}</td>
        <td v-if="containsMerchantNameStartWithRule()">{{ $t('card.limits_and_balances.merchant_name_start_with') }}</td>
        <td v-if="containsMerchantIdStartWithRule()">{{ $t('card.limits_and_balances.merchant_id_start_with') }}</td>
        <td v-if="containsMerchantNameContainsRule()">{{ $t('card.limits_and_balances.merchant_name_contains') }}</td>
        <td>{{ $t('card.limits_and_balances.external_id') }}</td>
        <td>{{ $t('card.limits_and_balances.fuzzy_enabled') }}</td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in items" :key="index">
        <td>
          <h5>{{ $t('card.limits_and_balances.merchant_id') }}:</h5>
          <span :class="{ 'info-not-available': !item.merchant_id }">{{ item.merchant_id | orNone }}</span>
        </td>
        <td class="wallet-rules-table--merchant-name">
          <h5>{{ $t('card.limits_and_balances.merchant_name') }}:</h5>
          <span :class="{ 'info-not-available': !item.merchant_name, 'merchant-name': true }">{{ item.merchant_name | orNone }}</span>
        </td>
        <td>
          <h5>{{ $t('card.limits_and_balances.terminal_id') }}:</h5>
          <span :class="{ 'info-not-available': !item.terminal_id }">{{ item.terminal_id | orNone }}</span>
        </td>
        <td>
          <h5>{{ $t('card.limits_and_balances.city') }}:</h5>
          <span :class="{ 'info-not-available': !item.merchant_city }">{{ item.merchant_city | orNone }}</span>
        </td>
        <td>
          <h5>{{ $t('card.limits_and_balances.state') }}:</h5>
          <span :class="{ 'info-not-available': !item.merchant_state }">{{ item.merchant_state | orNone }}</span>
        </td>
        <td>
          <h5>{{ $t('card.limits_and_balances.country') }}:</h5>
          <span :class="{ 'info-not-available': !item.merchant_country }">{{ item.merchant_country | orNone }}</span>
        </td>
        <td v-if="containsMerchantNameStartWithRule()">
          <h5>{{ $t('card.limits_and_balances.merchant_name_start_with') }}:</h5>
          <span :class="{ 'info-not-available': !item.merchant_name_start_with }">{{ item.merchant_name_start_with | orNone }}</span>
        </td>
        <td v-if="containsMerchantIdStartWithRule()">
          <h5>{{ $t('card.limits_and_balances.merchant_id_start_with') }}:</h5>
          <span :class="{ 'info-not-available': !item.merchant_id_start_with }">{{ item.merchant_id_start_with | orNone }}</span>
        </td>
        <td v-if="containsMerchantNameContainsRule()">
          <h5>{{ $t('card.limits_and_balances.merchant_name_contains') }}:</h5>
          <span :class="{ 'info-not-available': !item.merchant_name_contains }">{{ item.merchant_name_contains | orNone }}</span>
        </td>
        <td>
          <h5>{{ $t('card.limits_and_balances.external_id') }}:</h5>
          <router-link
            v-if="item.external_id && item.external_id.startsWith('STR-')"
            :to="{ name: 'store_details', params: { storeId: item.external_id } }"
          >
            {{ item.external_id }}
          </router-link>
          <span v-else :class="{ 'info-not-available': !item.external_id }">{{ item.external_id | orNone }}</span>
        </td>
        <td>
          <h5>{{ $t('card.limits_and_balances.fuzzy_enabled') }}:</h5>
          <span>{{ item.fuzzy_enabled }}</span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import _reduce from 'lodash/reduce';
import _isNil from 'lodash/isNil';

export default {
  name: 'wallet-rules-table',
  props: {
    items: {
      type: Array,
    },
    wallet: {
      type: Object,
    },
    walletDefinition: {
      type: Object,
    },
  },
  methods: {
    containsMerchantNameStartWithRule() {
      return _reduce(this.items, (contains, item) => contains || !_isNil(item.merchant_name_start_with), false);
    },
    containsMerchantIdStartWithRule() {
      return _reduce(this.items, (contains, item) => contains || !_isNil(item.merchant_id_start_with), false);
    },
    containsMerchantNameContainsRule() {
      return _reduce(this.items, (contains, item) => contains || !_isNil(item.merchant_name_contains), false);
    },
  },
};
</script>
<style lang="css">
.merchant-name {
  white-space: pre-wrap;
}
</style>
