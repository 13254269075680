<template>
  <card :title="$t('establishment.details.establishment_information_section.title')" icon="fal fa-store">
    <template slot="headerZoneRight">
      <label-text :label="`${$t('establishment.details.establishment_information_section.id')}`" :value="establishment.id">
        <p slot="labelTextLink" class="label-text--value">
          <v-layout row>
            {{ establishment.id }}
            <button class="clipboard" v-clipboard:copy="establishment.id ? establishment.id : ''">
              <v-icon small>file_copy</v-icon>
            </button>
          </v-layout>
        </p>
      </label-text>

      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="$t('establishment.details.establishment_information_section.timezone')" :value="establishment.timezone" />

      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text :label="$t('establishment.details.establishment_information_section.status')">
        <establishment-status-view slot="labelTextLink" :status="establishment.status" />
      </label-text>

      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <link-secondary-action slot="labelTextLink">
        <button-secondary-actions :text="$t(`establishment.details.establishment_information_section.actions`)" :actions="actions" />
      </link-secondary-action>

      <modal-action :show-modal="showEditModal"
        ><update-establishment-action
          v-if="showEditModal"
          avoid-rendering
          :establishment="establishment"
          @close="closeEditModal"
          @updated="editSuccess"
        ></update-establishment-action
      ></modal-action>

      <modal-confirm
        :show-modal="showActivateModal"
        :title="$t('establishment.activate_action.confirm.title')"
        context="activate"
        :text="$t('establishment.activate_action.confirm.text')"
        :cancel_text="'establishment.activate_action.confirm.button_cancel'"
        :submit_text="'establishment.activate_action.confirm.button_submit'"
        :submit_working="working"
        @submit="activate"
        @close="closeActivateModal"
      ></modal-confirm>
      <modal-confirm
        :show-modal="showDeactivateModal"
        :title="$t('establishment.deactivate_action.confirm.title')"
        context="deactivate"
        :text="$t('establishment.deactivate_action.confirm.text')"
        :cancel_text="'establishment.deactivate_action.confirm.button_cancel'"
        :submit_text="'establishment.deactivate_action.confirm.button_submit'"
        :submit_working="working"
        @submit="deactivate"
        @close="closeDeactivateModal"
      ></modal-confirm>
    </template>
    <template slot="content">
      <v-layout row>
        <v-flex xs3>
          <label-text :label="`${$t('establishment.details.establishment_information_section.partner')}`" :value="establishment.partner_id">
            <p slot="labelTextLink" class="label-text--value">
              {{ establishment.partner_id }}
            </p>
          </label-text>
        </v-flex>
        <v-flex xs3>
          <label-text :label="`${$t('establishment.details.establishment_information_section.business')}`" :value="establishment.business_id">
            <p slot="labelTextLink" class="label-text--value">
              <router-link :to="{ name: 'business_details', params: { businessId: establishment.business_id } }">
                {{ establishment.business_name }} ({{ establishment.business_id }})
              </router-link>
            </p>
          </label-text>
        </v-flex>
      </v-layout>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';
import _get from 'lodash/get';
import UpdateEstablishmentAction from '../actions/UpdateEstablishmentAction.vue';
import EstablishmentStatusView from '@/components/establishment/EstablishmentStatusView';

export default {
  name: 'establishment-information',
  components: { UpdateEstablishmentAction, EstablishmentStatusView },
  mixins: [security, actionErrorTrackable, disableWithError],
  data() {
    return {
      showEditModal: false,
      showActivateModal: false,
      showDeactivateModal: false,
      showSuspendModal: false,
      actions: [
        {
          slotName: 'EditModal',
          name: this.$t('establishment.details.establishment_information_section.edit'),
          action: () => {
            this.openEditModal();
          },
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => {
            return !this.showEditFeature();
          },
        },
        {
          slotName: 'activateModal',
          name: this.$t('establishment.details.establishment_information_section.activate'),
          action: () => {
            this.openActivateModal();
          },
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => !this.showActivateFeature(),
        },
        {
          slotName: 'deactivateModal',
          name: this.$t('establishment.details.establishment_information_section.deactivate'),
          action: () => {
            this.openDeactivateModal();
          },
          isAvailable: () => this.canManageOffer(),
          isDisabled: () => !this.showDeactivateFeature(),
        },
      ],
    };
  },
  computed: {
    ...mapGetters('space', ['establishment']),
    activationPayload() {
      return {
        id: this.establishment.id,
        partner_id: this.establishment.partner_id,
      };
    },
  },
  methods: {
    openEditModal() {
      this.showEditModal = true;
    },
    closeEditModal() {
      this.showEditModal = false;
    },
    async editSuccess() {
      this.showEditModal = false;
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'establishment.update_action.success' });
      this.closeEditModal();
    },
    openActivateModal() {
      this.showActivateModal = true;
    },
    closeActivateModal() {
      this.showActivateModal = false;
    },
    async activate() {
      await this.executeAction(
        {
          action: 'space/activatePartnerEstablishment',
          name: 'activate',
          success: this.activateSuccess,
        },
        this.activationPayload,
      );
    },
    async activateSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'establishment.activate_action.success' });
      this.closeActivateModal();
    },
    openDeactivateModal(id) {
      this.showDeactivateModal = true;
    },
    closeDeactivateModal() {
      this.showDeactivateModal = false;
    },
    async deactivate() {
      await this.executeAction(
        {
          action: 'space/deactivatePartnerEstablishment',
          name: 'deactivate',
          success: this.deactivateSuccess,
        },
        this.activationPayload,
      );
    },
    async deactivateSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'establishment.deactivate_action.success' });
      this.closeDeactivateModal();
    },
    showEditFeature() {
      return true;
    },
    showActivateFeature() {
      return this.establishment.status !== 'active';
    },
    showDeactivateFeature() {
      return this.establishment.status !== 'deactivated';
    },
  },
};
</script>
