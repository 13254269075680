import Vue from 'vue';

const service = {
  async getApplicationUpdateLog(query) {
    let url = `${process.env.VUE_APP_BASE_API_URL}/applications/v1/card-applications/${query.card_application_id}/logs`;

    const response = await Vue.prototype.$axios.get(url);
    return response.data.data.items;
  },
};

export default service;
