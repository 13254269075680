<template>
  <span v-if="showLabel">
    <label-text
      v-if="account && account.contact && account.contact.email"
      :label="`${$t('account_email.label')}`"
      :value="account.contact.email.value"
    >
      <p slot="labelTextLink" class="account-email--labelTextLink">
        <router-link
          v-if="generateLinkToCard"
          :class="{ blocked: account.blocked }"
          :title="account.blocked ? $t('user.blocked') : null"
          :to="{ name: 'user_details', params: { userId: account.id } }"
        >
          {{ account.contact.email.value }} ({{ account.id }})
        </router-link>
        <span v-else :class="{ blocked: account.blocked }" :title="user.blocked ? $t('account.blocked') : null">
          {{ account.contact.email.value }} ({{ account.id }})</span
        >
      </p>
    </label-text>
  </span>
  <span v-else>
    <span v-if="account && account.contact && account.contact.email">
      <router-link
        v-if="generateLinkToCard"
        :class="{ blocked: account.blocked }"
        :title="account.blocked ? $t('user.blocked') : null"
        :to="{ name: 'user_details', params: { userId: account.id } }"
      >
        {{ account.contact.email.value }} ({{ account.id }})
      </router-link>
      <span v-else :class="{ blocked: account.blocked }" :title="user.blocked ? $t('account.blocked') : null">
        {{ account.contact.email.value }} ({{ account.id }})
      </span>
    </span>
    <label-none v-else>{{ $t('system.na') }}</label-none>
  </span>
</template>

<script>
import { actionErrorTrackable, renderErrorTrackable } from '@/mixins';
import Vue from 'vue';

export default {
  name: 'account-email',
  mixins: [renderErrorTrackable, actionErrorTrackable],
  props: {
    id: {
      type: String,
      required: false,
    },
    generateLinkToCard: {
      type: Boolean,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
  },
  data: () => {
    return {
      account: {},
    };
  },
  methods: {
    async goToCardFromCardholderId(cardholderId) {
      const card = await Vue.prototype.$services.card.getLatestCardByCardholderId(cardholderId);
      if (card) {
        this.$router.push({ name: 'card', params: { cardPublicToken: card.public_token } });
      }
    },
    async fetchAccount(id) {
      // for guarantee that account is fetched with correct id and not undefined
      this.account = {};

      if (!id) {
        return;
      }

      this.account = await Vue.prototype.$services.account.getAccount(id);
    },
  },
  watch: {
    async id(id) {
      await this.fetchAccount(id);
    },
  },
  async created() {
    await this.fetchAccount(this.id);
  },
};
</script>

<style scoped lang="stylus">
@import './../../styles/variables/custom-variables.styl';

.account-email--labelTextLink a {
  word-break: break-all;
}

.blocked {
  color: $error-default;
}
</style>
