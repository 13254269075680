<template>
  <form @submit.prevent="submit" class="form-basic form-search" data-test="wallet-definition-search-form">
    <v-layout align-center wrap>
      <v-flex xs12 sm6 md5 class="form-search--main-input-wrap">
        <slot name="main-input">
          <v-text-field
            class="form-search--search-field"
            :label="$t(inputLabel)"
            v-model="keyword"
            data-vv-name="keyword"
            :error-messages="errors.collect('keyword')"
            v-validate="rules"
            ref="keyword"
            clearable
          ></v-text-field>
        </slot>
      </v-flex>
      <v-flex xs12 sm6 md7 class="form-search--button-wrap">
        <button-primary class="form-search--btn-submit" :text="$t(submitText)" @click="submit"></button-primary>
        <slot name="buttons"></slot>
      </v-flex>
    </v-layout>
  </form>
</template>

<script>
export default {
  name: 'form-search',
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    rules: {
      type: String,
      required: false,
      default() {
        return '';
      },
    },
    inputLabel: {
      type: String,
      required: false,
    },
    submitText: {
      type: String,
      required: false,
    },
    updatedKeyword: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      keyword: this.updatedKeyword || '',
    };
  },
  watch: {
    updatedKeyword(value) {
      if (value) {
        this.keyword = value;
        this.$nextTick(async () => {
          await this.submit();
        });
      }
    },
    rules() {
      this.updateKeywordValidationRules();
    },
  },
  methods: {
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        this.$validator.reset();
        this.$emit('submit', this.keyword);
      }
    },
    updateKeywordValidationRules(rules) {
      this.$validator.fields.items[0].rules = this.rules;
      this.$validator.reset();
    },
  },
};
</script>
