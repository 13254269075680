<template>
  <div class="offer-status">
    <span v-if="status" :class="`status-${status}`">{{ $t(`offers.status.${status}`) }}</span>
  </div>
</template>

<script>
export default {
  name: 'offer-status-view',
  props: {
    status: {
      type: String,
    },
  },
};
</script>
