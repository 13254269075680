import Vue from 'vue';

const service = {
  async listChangeApplicationRequests(application_id) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}/selfserve/v1/change-requests/application/${application_id}/change-requests`,
    );
    return response.data.data.items;
  },

  async getChangeApplicationRequestById(id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/selfserve/v1/change-requests/${id}/application`);
    return response.data.data;
  },

  async getCurrentChangeApplicationRequest(application_id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/selfserve/v1/change-requests/application/${application_id}`);
    return response.data.data;
  },

  async createChangeApplicationRequest(payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/selfserve/v1/change-requests/application`, payload);
    return response.data.data;
  },

  async sendChangeApplicationRequestEmail(payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/selfserve/v1/change-requests/application/email`, payload);
    return response.data.data;
  },
};

export default service;
