<template>
  <div>
    <router-link :to="{ name: routeName, params: routeParams, query: routeQuery }" v-if="canAccess && !isGlobalErrorActive" :target="`_${target}`"
      ><slot></slot
    ></router-link>
    <span :class="{ 'is-disabled': canAccess && !isGlobalErrorActive }" v-if="!canAccess || isGlobalErrorActive"><slot></slot></span>
  </div>
</template>

<script>
import disabler from '@/mixins/disable-with-error';

export default {
  name: 'link-secondary-route',
  mixins: [disabler],
  props: {
    routeName: {
      type: String,
      required: true,
    },
    routeQuery: {
      type: Object,
      required: false,
    },
    routeParams: {
      type: Object,
      required: false,
    },
    canAccess: {
      type: Boolean,
      default: true,
    },
    target: {
      type: String,
      default: 'self',
    },
  },
};
</script>
