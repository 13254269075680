import { render, staticRenderFns } from "./ApplicationCommentsList.vue?vue&type=template&id=15b8f72b"
import script from "./ApplicationCommentsList.vue?vue&type=script&lang=js"
export * from "./ApplicationCommentsList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports