<template>
  <modal-action :show-modal="showModal">
    <card-modal
      icon="fal fa-comment"
      :title="'Delete ' + objectType"
      context="deleteWithReasonModal"
      @submit="submit"
      @cancel="closeModal"
      cancel_text="system.cancel"
      submit_text="system.submit"
      :submit_working="working"
    >
      <form @submit.prevent="submit" slot="content">
        <v-layout column>
          <v-textarea
            data-test="delete-with-reason-modal--reason"
            auto-grow
            rows="1"
            name="reason"
            row-height="1"
            label="Reason"
            v-model="reason"
            v-validate="'required|max:4096'"
            :error-messages="errors.collect('reason')"
            data-vv-name="reason"
            data-vv-as="reason"
            counter
            required
            maxlength="4096"
          ></v-textarea>
        </v-layout>
      </form>
    </card-modal>
  </modal-action>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';

export default {
  name: 'delete-with-reason-modal',

  mixins: [actionErrorTrackable, security],

  $_veeValidate: {
    validator: 'new',
  },

  props: {
    showModal: {
      type: Boolean,
      required: true,
    },

    objectType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      reason: '',
    };
  },

  methods: {
    closeModal() {
      this.$emit('close');
      this.reset();
    },

    reset() {
      this.reason = '';
      this.$validator.reset();
    },

    async submit() {
      if (await this.$validator.validateAll()) {
        const params = { reason: this.reason };
        this.$emit('confirm', params);

        this.reset();
      }
    },
  },
};
</script>
