<template>
  <div id="task-list" data-test="task-list">
    <v-expansion-panel v-model="visiblePanels" expand>
      <v-expansion-panel-content readonly hide-actions>
        <template v-slot:header>
          <v-layout row wrap>
            <v-flex md2 xs6>{{ $t(`task.list.header.user`) }}</v-flex>
            <v-flex md3 xs6>{{ $t(`task.list.header.person`) }}</v-flex>
            <v-flex md2 xs6>{{ $t(`task.list.header.id`) }}</v-flex>
            <v-flex md2 xs6>{{ $t(`task.list.header.type`) }}</v-flex>
            <v-flex md2 xs6>
              {{ $t(`task.list.header.created`) }}
              <i style="margin-left: 3px" class="far fa-chevron-down"></i>
            </v-flex>
            <v-flex md1 xs6>{{ $t(`task.list.header.status`) }}</v-flex>
            <!-- <v-flex lg2 xs6>{{ $t(`task.list.header.assignee`) }}</v-flex> -->
          </v-layout>
        </template>
      </v-expansion-panel-content>

      <v-expansion-panel-content v-for="(task, i) in tasks" :key="i" hide-actions class="task-list-item" data-test="task-list-item">
        <template v-slot:header>
          <v-layout row wrap>
            <v-flex md2 xs6 v-if="task.account_id">{{ task.account_id }}</v-flex>
            <v-flex md2 xs6 v-else>
              <label-none>{{ $t('system.na') }}</label-none>
            </v-flex>
            <v-flex md3 xs6 :data-test="task.title">{{ task.title }}</v-flex>
            <v-flex md2 xs6>{{ task.id }}</v-flex>
            <v-flex md2 xs6 :data-test="task.type">{{ $t(`task.list.field.type.${task.type}`) }}</v-flex>
            <v-flex md2 xs6>{{ task.created_at | formatDateHourMin }}</v-flex>
            <v-flex md1 xs6>{{ $t(`task.list.field.statuses.${task.status}`) }}</v-flex>
            <!--
            Hidden until we can assign a user
            <v-flex lg2 xs6>
              <app-name :app_key="task.assigned_to.app_key" slot="author" v-if="task.assigned_to"></app-name>
            </v-flex>
            -->
            <v-flex class="actions-button-container">
              <button-secondary-actions
                :actions="actions"
                type="more_vertical"
                @click.native.stop
                :context="{ index: i, taskStatus: task.status }"
                data-test="task-actions-button"
              >
                <template slot="assign_task" v-if="showAssignTaskModals[i]">
                  <modal-action :show-modal="showAssignTaskModals[i]">
                    <assign-task-action :task-id="task.id" @close="closeAssignTaskModal(i)"></assign-task-action>
                  </modal-action>
                </template>

                <template slot="resolve_task" v-if="showResolveTaskModals[i]">
                  <modal-action :show-modal="showResolveTaskModals[i]" :max-width="1200">
                    <!-- DEPRECATED PLP:  REMOVED IN HC-1753 -->
                    <!-- <resolve-aml-note-to-review-action v-if="isAmlNoteToReview(task)" :task-id="task.id" @task_resolved="closeResolveTaskModal(i, true)" @closed="closeResolveTaskModal(i)"></resolve-aml-note-to-review-action> -->

                    <resolve-aml-assessment-check-hit-action
                      v-if="isAmlAssessmentCheckHit(task)"
                      :task-id="task.id"
                      @task_resolved="closeResolveTaskModal(i, true)"
                      @closed="closeResolveTaskModal(i)"
                    />
                    <resolve-selfserve-change-application-review-action
                      v-if="isSelfserveChangeApplicationReview(task)"
                      :task-id="task.id"
                      @task_resolved="closeResolveTaskModal(i, true)"
                      @closed="closeResolveTaskModal(i)"
                    />
                  </modal-action>
                </template>
              </button-secondary-actions>
            </v-flex>
          </v-layout>
        </template>
        <!-- DEPRECATED PLP:  REMOVED IN HC-1753 -->
        <!-- <aml-note-to-review-list-item v-if="isAmlNoteToReview(task) && visiblePanels[i + 1]" :task-id="task.id" /> -->

        <selfserve-change-application-review v-if="isSelfserveChangeApplicationReview(task) && visiblePanels[i + 1]" :task-id="task.id" />
        <aml-assessment-check-hit-list-item v-if="isAmlAssessmentCheckHit(task) && visiblePanels[i + 1]" :task-id="task.id" />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { actionErrorTrackable, security } from '@/mixins';
import AssignTaskAction from './AssignTaskAction';
import AmlAssessmentCheckHitListItem from './list-items/AmlAssessmentCheckHitListItem';
import SelfserveChangeApplicationReview from './list-items/SelfserveChangeApplicationReview';
import ResolveAmlAssessmentCheckHitAction from './resolve-modals/ResolveAmlAssessmentCheckHitAction';
import ResolveSelfserveChangeApplicationReviewAction from './resolve-modals/ResolveSelfserveChangeApplicationReviewAction';

export default {
  name: 'task-list',
  mixins: [actionErrorTrackable, security],
  components: {
    AssignTaskAction,
    AmlAssessmentCheckHitListItem,
    SelfserveChangeApplicationReview,
    // AmlNoteToReviewListItem, <!-- DEPRECATED PLP:  REMOVED IN HC-1753 -->
    // ResolveAmlNoteToReviewAction, <!-- DEPRECATED PLP:  REMOVED IN HC-1753 -->

    ResolveAmlAssessmentCheckHitAction,
    ResolveSelfserveChangeApplicationReviewAction,
  },
  data() {
    const $t = this.$t.bind(this);
    return {
      visiblePanels: [],
      showAssignTaskModals: {},
      showResolveTaskModals: {},
      actions: [
        {
          slotName: 'assign_task',
          name: $t('task.list.button_assign'),
          action: this.openAssignTaskModal,
          isAvailable: () => false,
        },
        {
          slotName: 'resolve_task',
          name: $t('task.list.button_resolve'),
          action: this.openResolveTaskModal,
          isAvailable: ({ taskStatus }) => taskStatus !== 'resolved',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('task', ['tasks']),
  },
  methods: {
    // <!-- DEPRECATED PLP:  REMOVED IN HC-1753 -->
    // isAmlNoteToReview({ type }) {
    //   return ['aml_note_to_review', 'aml_fraud_monitoring_to_review', 'aml_customer_support_to_review'].includes(type);
    // },
    isAmlAssessmentCheckHit({ type }) {
      return type === 'aml_status_possible_pep' || type === 'aml_status_possible_sanction';
    },

    isSelfserveChangeApplicationReview({ type }) {
      return type === 'selfserve_change_application_review';
    },

    openAssignTaskModal({ index }) {
      Vue.set(this.showAssignTaskModals, index, true);
    },

    closeAssignTaskModal(i) {
      this.showAssignTaskModals[i] = false;
    },

    openResolveTaskModal({ index }) {
      Vue.set(this.showResolveTaskModals, index, true);
    },

    async closeResolveTaskModal(i, refreshTasks = false) {
      this.showResolveTaskModals[i] = false;
      if (refreshTasks) {
        await this.executeAction({ action: 'task/refreshTasks' });
      }
    },
  },
};
</script>
