<template>
  <view-main title="page_title.store"><search-store-form slot="content"></search-store-form></view-main>
</template>

<script>
import { renderErrorTrackable } from '@/mixins';
import SearchStoreForm from './list/SearchStoreForm.vue';

export default {
  name: 'store-view',
  mixins: [renderErrorTrackable],
  components: { SearchStoreForm },
};
</script>
