import Vue from 'vue';
import { safeDispatcher } from '@/utils/context/context-helper';

let listAccountMembershipsLock = {};

export default {
  namespaced: true,
  state: {
    partners: [],
    memberships: [],
  },

  getters: {
    partners: (state) => state.partners,
    memberships: (state) => state.memberships,
    getLocalMembershipById: (state) => (id) => state.memberships.find((membership) => membership.id === id),
  },

  mutations: {
    partners(state, partners) {
      state.partners = partners || [];
    },
    memberships(state, memberships) {
      state.memberships = memberships;
    },
  },

  actions: {
    async autocompleteSearch({}, { page, limit, keyword }) {
      const partnerService = Vue.prototype.$services.partner;

      return partnerService.searchPartners(page, limit, keyword);
    },
    async autocompleteSearchPartnersForMembershipSubscription({}, { page, limit, keyword, account_id }) {
      const partnerService = Vue.prototype.$services.partner;

      return partnerService.searchPartnersForMembershipSubscription(page, limit, account_id, keyword);
    },
    async listAccountMemberships({ commit }, accountId) {
      const partnerService = Vue.prototype.$services.partner;

      if (!listAccountMembershipsLock[accountId]) {
        try {
          listAccountMembershipsLock[accountId] = true;
          const memberships = await partnerService.listAccountMemberships(accountId);
          commit('memberships', memberships);
        } finally {
          delete listAccountMembershipsLock[accountId];
        }
      }
    },

    clearAccountMemberships({ commit }) {
      commit('memberships', []);
    },

    async getPartner({ commit }, id) {
      const partnerService = Vue.prototype.$services.partner;
      return (await partnerService.getPartner(id)) || null;
    },

    async getPartnerCardRenewPackage({ commit }, { card_program_type, partnerId, cardholderLanguage }) {
      const cardService = Vue.prototype.$services.card;
      const partnerService = Vue.prototype.$services.partner;

      const partner = await partnerService.getPartner(partnerId);
      if (!partner) {
        return null;
      }

      let cardPackage = null;
      if (card_program_type === 'gpr') {
        cardPackage = await cardService.getCardPackage(partner.renew_card_packages[cardholderLanguage]);
        if (!cardPackage) {
          return null;
        }
      } else if (card_program_type === 'instant') {
        cardPackage = await cardService.getCardPackage(partner.instant_renew_card_packages[cardholderLanguage]);
        if (!cardPackage) {
          return null;
        }
      }

      return cardPackage;
    },

    async listPartners({ commit }) {
      const partnerService = Vue.prototype.$services.partner;
      const partners = await partnerService.listPartners();

      commit(
        'partners',
        partners.sort(function(a, b) {
          return (a.name || '').toLowerCase().localeCompare((b.name || '').toLowerCase());
        }),
      );
      return partners;
    },

    async updatePartner({ dispatch }, params) {
      const partnerService = Vue.prototype.$services.partner;
      const { id, ...payload } = params;
      const result = await partnerService.updatePartner(id, payload);
      await dispatch('getPartner', id);
      return result;
    },

    async createPartnerImagesUploadSignedUrls({ commit }, { partner_id, images }) {
      const partnerService = Vue.prototype.$services.partner;
      const response = await partnerService.createPartnerImagesUploadSignedUrls(partner_id, { images });
      return response.items;
    },
    async uploadPartnerImage({ commit }, { signed_url, image }) {
      const partnerService = Vue.prototype.$services.partner;
      return partnerService.uploadImage(signed_url, image);
    },

    async subscribeMembership({ dispatch }, { account_id, partner_id, allow_communication }) {
      const defaultDispatch = safeDispatcher(dispatch);

      const partnerService = Vue.prototype.$services.partner;
      await partnerService.subscribeMembership({ account_id, partner_id, allow_communication });

      await defaultDispatch('ui/showSuccessSnackBar', { text: 'user.details.memberships_section.add_membership_action.success' });

      await defaultDispatch('partner/listAccountMemberships', account_id);
    },

    async unsubscribeMembership({ dispatch }, { account_id, partner_id }) {
      const defaultDispatch = safeDispatcher(dispatch);

      const partnerService = Vue.prototype.$services.partner;
      await partnerService.unsubscribeMembership({ account_id, partner_id });

      await defaultDispatch('ui/showSuccessSnackBar', { text: 'memberships_table.remove_membership_action.success' });
      await defaultDispatch('partner/listAccountMemberships', account_id);
    },

    async updateMembership({ dispatch }, { id, account_id, external_id, allow_communication }) {
      const defaultDispatch = safeDispatcher(dispatch);
      const payload = { external_id, allow_communication };

      const partnerService = Vue.prototype.$services.partner;
      await partnerService.updateMembership({ id, payload });

      if (account_id) {
        await defaultDispatch('ui/showSuccessSnackBar', { text: 'user.details.memberships_section.update_membership_action.success' });
        await defaultDispatch('partner/listAccountMemberships', account_id);
      }
    },
  },
};
