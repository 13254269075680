<template>
  <div v-if="currentCardApplicationExists()">
    <h3>{{ $t(`task.list.card_application.title`) }}</h3>
    <v-layout row wrap align-center justify-start class="data-card--content-zone">
      <v-flex xs12 class="d-inline-flex data-card--content-zone__upper">
        <label-text :label="$t(`task.list.card_application.id`)">
          <p slot="labelTextLink" class="label-text--value">
            <v-layout row>
              <link-secondary-route
                :can-access="canViewApplication()"
                :route-name="'application'"
                :route-params="{ applicationId: cardApplicationId }"
                target="blank"
                >{{ cardApplicationId }}</link-secondary-route
              >
            </v-layout>
          </p>
        </label-text>
        <label-text :label="$t(`task.list.card_application.date`)" :value="currentCardApplication.date_submitted | formatDateHourMin"></label-text>
        <label-text
          :label="$t(`task.list.card_application.card_applicant.first_name`)"
          :value="currentCardApplication.card_applicant.first_name"
          data-test="application-first-name"
        ></label-text>
        <label-text
          :label="$t(`task.list.card_application.card_applicant.last_name`)"
          :value="currentCardApplication.card_applicant.last_name"
          data-test="application-last-name"
        ></label-text>
        <label-text
          :label="$t(`task.list.card_application.card_applicant.birth_date`)"
          :value="currentCardApplication.card_applicant.date_of_birth"
          data-test="application-birthdate"
        ></label-text>
        <label-text
          :label="$t(`task.list.card_application.card_applicant.address`)"
          :value="fullAddress"
          data-test="application-address"
        ></label-text>
      </v-flex>

      <v-flex xs12 class="d-inline-flex data-card--content-zone__upper">
        <label-text
          v-if="currentCardApplication.card_public_token"
          :label="$t(`task.list.card_application.card_public_token`)"
          data-test="application-public-token"
        >
          <p slot="labelTextLink" class="label-text--value">
            <v-layout row>
              <link-secondary-route
                :can-access="canViewCard()"
                :route-name="'card'"
                :route-params="{ cardPublicToken: currentCardApplication.card_public_token }"
                target="blank"
                >{{ currentCardApplication.card_public_token }}</link-secondary-route
              >
            </v-layout>
          </p>
        </label-text>

        <label-text
          v-if="currentCardExists()"
          :label="$t(`task.list.card_application.card.date_issued`)"
          :value="currentCard.date_issued | formatDateHourMin"
          data-test="application-card-issue-date"
        ></label-text>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { actionErrorTrackable, security } from '@/mixins';

export default {
  name: 'card-application-list-item-part',
  mixins: [actionErrorTrackable, security],
  props: {
    cardApplicationId: {
      type: String,
      required: true,
    },
  },
  computed: {
    fullAddress() {
      if (this.currentCardApplication) {
        return `${this.currentCardApplication.card_applicant.address.street}, ${this.currentCardApplication.card_applicant.address.city}, ${this.currentCardApplication.card_applicant.address.state}, ${this.currentCardApplication.card_applicant.address.country}, ${this.currentCardApplication.card_applicant.address.zip}`;
      }

      return '';
    },
  },
  data() {
    return {
      currentCardApplication: {},
      currentCard: {},
    };
  },
  async mounted() {
    this.currentCardApplication = await this.executeAction({ action: 'task/getCardApplication' }, this.cardApplicationId);
    if (this.currentCardApplication.card_public_token) {
      this.currentCard = await this.executeAction({ action: 'task/getCard' }, this.currentCardApplication.card_public_token);
    }
  },
  methods: {
    currentCardApplicationExists() {
      return !!this.currentCardApplication.key;
    },
    currentCardExists() {
      return !!this.currentCard.public_token;
    },
  },
};
</script>
