import Vue from 'vue';

const service = {
  async listSubscriptionsByAccountId(account_id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/account/v1/accounts/${account_id}/subscriptions`);
    return response.data.data;
  },
};

export default service;
