import Vue from 'vue';

const defaultPage = 1;
const paginationLimit = 20;

export default {
  namespaced: true,
  state: {
    businessEftItems: [],
    search: {
      pageNumber: 1,
      pageCount: 0,
    },
    businessEftItemsPageNumber: defaultPage,
    businessEftItemsPageCount: 0,
  },
  mutations: {
    eftItems(state, businessEftItems) {
      state.businessEftItems = businessEftItems;
    },
    eftItemsPageNumber(state, page) {
      state.businessEftItemsPageNumber = page;
    },
    eftItemsPageCount(state, pageCount) {
      state.businessEftItemsPageCount = pageCount;
    },
  },
  getters: {
    businessEftItems: (state) => state.businessEftItems,
    businessEftItemsPageNumber: (state) => state.businessEftItemsPageNumber,
    businessEftItemsPageCount: (state) => state.businessEftItemsPageCount,
  },
  actions: {
    async listBusinessEftItems({ commit }, { id, page, limit }) {
      const thePage = page || defaultPage;
      const theLimit = limit || paginationLimit;
      const eftService = Vue.prototype.$services.eft;
      const eftItems = await eftService.listBusinessEftItems(id, thePage, theLimit);

      commit('eftItems', eftItems.items);
      commit('eftItemsPageNumber', thePage);
      commit('eftItemsPageCount', eftItems.page_count);
    },
    async generateManualEft({ commit }, payload) {
      const eftService = Vue.prototype.$services.eft;

      return eftService.generateManualEft(payload);
    },
    async generateManualPad({ commit }, { cardProgramKey, payload }) {
      const eftService = Vue.prototype.$services.eft;

      return eftService.generateManualPad(cardProgramKey, payload);
    },
  },
};
