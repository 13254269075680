<template>
  <card-modal
    icon=""
    :title="$t('store.details.publishers_section.create_action.title')"
    context="addPublisherToStore"
    @submit="submit"
    @cancel="cancel"
    cancel_text="store.details.publishers_section.create_action.button_cancel"
    submit_text="store.details.publishers_section.create_action.button_submit"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <modal-info-block :heading="$t('store.details.publishers_section.create_action.store')" :text="storeInfo"></modal-info-block>
        <h-autocomplete
          autofocus
          v-model="publisherToAdd"
          :label="$t('store.details.publishers_section.create_action.publisher')"
          v-validate="'required'"
          :error-messages="errors.collect('publisher')"
          name="publisher"
          data-vv-name="publisher"
          data-vv-as="publisher"
          search-action="publishers/autocompleteSearch"
          data-test="add-store-publisher"
          no-filter
        >
        </h-autocomplete>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import _ from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default {
  name: 'add-store-publisher-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    storeObj: {
      type: Object,
    },
  },
  data() {
    return {
      publisherToAdd: null,
    };
  },
  computed: {
    storeInfo() {
      return `${this.storeObj.name} (${this.storeObj.id})`;
    },
  },
  methods: {
    clear() {
      this.publisherToAdd = null;
      this.$validator.reset();
    },
    success() {
      this.clear();
      this.$emit('created');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        if (this.publisherToAdd) {
          const payload = { store_id: this.storeObj.id, publisher_id: this.publisherToAdd };

          await this.executeAction(
            {
              action: 'store/addPublisherToStore',
              name: 'addPublisherToStore',
              success: this.success,
            },
            payload,
          );
        }
      }
    },
  },
};
</script>
