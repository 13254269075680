<template>
  <div>
    <table class="responsive-table">
      <thead>
        <tr>
          <td>{{ $t('space.workstation_table.fields.id') }}</td>
          <td>{{ $t('space.workstation_table.fields.name') }}</td>
          <td>{{ $t('space.workstation_table.fields.ip_restricted') }}</td>
          <td>{{ $t('space.workstation_table.fields.registration_clerk') }}</td>
          <td>{{ $t('space.workstation_table.fields.last_access_date') }}</td>
          <td>{{ $t('space.workstation_table.fields.pending_registration_token') }}</td>
          <td>{{ $t('space.workstation_table.fields.date_created') }}</td>
          <td>{{ $t('space.workstation_table.fields.status') }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>
            <h5>{{ $t('space.workstation_table.fields.id') }}</h5>
            <router-link :to="{ name: 'workstation_details', params: { workstationId: item.id } }">{{ item.id }}</router-link>
          </td>
          <td>
            <h5>{{ $t('space.workstation_table.fields.name') }}</h5>
            {{ item.name }}
          </td>
          <td>
            <h5>{{ $t('space.workstation_table.fields.ip_restricted') }}</h5>
            {{ $t(`workstation.ip_restricted.${item.ip_restricted}`) }}
          </td>
          <td>
            <h5>{{ $t('space.workstation_table.fields.registration_clerk') }}</h5>
            <router-link v-if="item.registration_clerk_id" :to="{ name: 'clerk_details', params: { clerkId: item.registration_clerk_id } }">
              {{ item.registration_clerk_id }} ({{ item.registration_clerk_username }})
            </router-link>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('space.workstation_table.fields.last_access_date') }}</h5>
            <span v-if="item.last_access_date">{{
              item.last_access_date | formatShortDateTimeNoSecondsInverted({ withAgo: true, returnNullIfNoDate: true, timezone: 'Canada/Eastern' })
            }}</span>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('space.workstation_table.fields.pending_registration_token') }}</h5>
            <span v-if="item.pending_registration_request">
              {{ item.pending_registration_request.token }}
              <button class="clipboard" v-clipboard:copy="item.pending_registration_request.token">
                <v-icon small>file_copy</v-icon>
              </button>
            </span>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('space.workstation_table.fields.date_created') }}</h5>
            {{ item.creation_date | formatShortDateInverted }}
          </td>
          <td>
            <h5>{{ $t('space.workstation_table.fields.status') }}</h5>
            <workstation-status-view slot="labelTextLink" :status="item.status" />
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-xs-center">
      <v-pagination v-if="listWorkstationsPageCount > 1" v-model="pagination" :length="listWorkstationsPageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';
import WorkstationStatusView from '@/components/workstation/WorkstationStatusView';

export default {
  name: 'space-workstation-table',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { WorkstationStatusView },
  props: {
    partnerId: {
      type: String,
    },
    items: {
      type: Array,
    },
  },
  data() {
    return {
      pagination: 1,
    };
  },
  mounted() {
    this.pagination = this.listWorkstationsPageNumber;
  },
  watch: {
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'partnerSecurity/listWorkstations',
        },
        {
          partner_id: this.partnerId,
          page: newValue,
        },
      );
    },
  },
  computed: {
    ...mapGetters('partnerSecurity', ['listWorkstationsPageCount', 'listWorkstationsPageNumber']),
  },
  methods: {
    cidrsAsString(cidrs) {
      return cidrs.join(', ');
    },
  },
};
</script>
