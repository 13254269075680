<template>
  <view-main title="page_title.business_suite_pads">
    <v-flex slot="content" class="data-section">
      <h1 hidden>{{ $t('dashboards.business_suite_pads.title') }}</h1>
      <dashboard id-name="pads_report" :timerange="timerange" :params="params" />
    </v-flex>
  </view-main>
</template>

<script>
import { renderErrorTrackable } from '@/mixins';
import Dashboard from './Dashboard.vue';

export default {
  name: 'business-suite-pads-report-view',
  mixins: [renderErrorTrackable],
  components: { Dashboard },
  data() {
    return {
      business_id: null,
      timerange: null,
    };
  },
  created() {
    if (this.$route.query.timerange) {
      this.timerange = this.$route.query.timerange;
    }
    if (this.$route.query.business_id) {
      this.business_id = this.$route.query.business_id;
    }
  },
  computed: {
    params() {
      const theParams = {};

      if (this.business_id) {
        theParams.business_id = this.business_id;
      }

      return theParams;
    },
  },
};
</script>
