<template>
  <card :title="$t('space.details.workstation_section.title')" icon="">
    <template slot="headerZoneRight">
      <label-text
        class="text-sm-center"
        :label="$t('space.details.workstation_section.count')"
        :value="listWorkstationsItemCount || '0'"
      ></label-text>
      <div class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text class="text-sm-center" :label="$t('space.details.workstation_section.action')">
        <link-secondary-action slot="labelTextLink" @click="showAddWorkstationActionModal()">
          {{ $t('space.details.workstation_section.add') }}
        </link-secondary-action>
      </label-text>
    </template>
    <template slot="content">
      <modal-action :show-modal="addWorkstationModalShown" close>
        <add-workstation-action
          :partner-id="space.partner_id"
          :space-id="space.id"
          @success="workstationCreated"
          @close="closeAddWorkstationActionModal"
        ></add-workstation-action
      ></modal-action>
      <space-workstation-table v-if="dataReady && workstations.length" :items="workstations" :partner-id="space.partner_id"></space-workstation-table>
      <label-none v-if="workstations.length === 0">{{ $t('system.none') }}</label-none>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import SpaceWorkstationTable from './SpaceWorkstationTable';
import { mapGetters } from 'vuex';
import AddWorkstationAction from '../actions/AddWorkstationAction.vue';

export default {
  name: 'space-workstation',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { AddWorkstationAction, SpaceWorkstationTable },
  data() {
    return {
      page: 1,
      dataReady: false,
      addWorkstationModalShown: false,
    };
  },
  async created() {
    await this.fetchWorkstations();
    this.page = this.listWorkstationsPageNumber;
  },
  watch: {
    async space() {
      await this.fetchWorkstations();
      this.page = 1;
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('space', ['space']),
    ...mapGetters('partnerSecurity', ['workstations', 'listWorkstationsItemCount', 'listWorkstationsPageNumber']),
  },
  methods: {
    showAddWorkstationActionModal() {
      this.addWorkstationModalShown = true;
    },
    async workstationCreated() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: `space.details.workstation_section.add_action.success` });

      this.addWorkstationModalShown = false;
    },
    closeAddWorkstationActionModal() {
      this.addWorkstationModalShown = false;
    },
    async fetchWorkstations() {
      this.dataReady = false;
      if (this.space.partner_id) {
        await this.executeAction({ action: 'partnerSecurity/listWorkstations' }, { partner_id: this.space.partner_id });
      }
      this.dataReady = true;
    },
  },
};
</script>
