<template>
  <v-layout column>
    <v-flex v-for="(fieldPath, index) in uniqueChangedFields" :key="index">
      <v-layout column>
        <h5>{{ $t(`${i18nPrefix}.${fieldPath}`) }}</h5>

        <v-flex>
          <v-layout row wrap align-center>
            <v-flex class="original-value--header">
              {{ getOriginal(fieldPath) }}
            </v-flex>

            <v-flex class="arrow--header">
              <v-icon small>fa-arrow-right</v-icon>
            </v-flex>

            <v-flex class="changes--header">
              {{ getChange(fieldPath) }}
            </v-flex>
          </v-layout>
          <div class="changes-viewer--spacer"></div>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import _get from 'lodash/get';
import _map from 'lodash/map';
import _compact from 'lodash/compact';

export default {
  name: 'changes-viewer',

  props: {
    changedFields: {
      type: Array,
      required: true,
    },

    i18nPrefix: {
      type: String,
      required: true,
    },

    originalValues: {
      type: Object,
      required: true,
    },

    changes: {
      type: Object,
      required: true,
    },
  },

  methods: {
    getChange(key) {
      return _get(this.changes, key);
    },

    getOriginal(key) {
      return _get(this.originalValues, key);
    },
  },
  computed: {
    uniqueChangedFields() {
      return _compact(
        _map(this.changedFields, (path) => {
          if (_get(this.changes, path) !== _get(this.originalValues, path)) {
            return path;
          }
        }),
      );
    },
  },
};
</script>

<style scoped lang="stylus">
@import './../../styles/variables/custom-variables.styl';

.original-value--header {
  flex-grow: 0;
  word-wrap: break-word;
  text-decoration: line-through;
}

.arrow--header {
  flex-grow: 0;
  margin: 0 16px 0 16px;
}

.changes--header {
  flex-grow: 0;
  word-wrap: break-word;
  font-weight: 700 !important;
}

.changes-viewer--spacer {
  margin-bottom: $p-margin-bottom;
}
</style>
