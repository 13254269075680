<template>
  <card :title="$t('business.details.stores_section.title')" icon="">
    <template slot="headerZoneRight">
      <label-text
        class="text-xs-center"
        :label="`${$t('business.details.stores_section.store_count')}`"
        :value="businessStoresItemCount || '0'"
      ></label-text>
    </template>
    <template slot="content">
      <business-stores-table v-if="businessStores.length" :items="businessStores" :id="businessId"></business-stores-table>
      <label-none v-if="!businessStores.length">{{ $t('system.none') }}</label-none>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import BusinessStoresTable from './BusinessStoresTable';
import { mapGetters } from 'vuex';

export default {
  name: 'business-stores',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { BusinessStoresTable },
  data() {
    return {
      pagination: 1,
    };
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  async created() {
    return this.fetchBusinessStores();
  },
  watch: {
    async business() {
      return this.fetchBusinessStores();
    },
    businessStoresPageNumber() {
      this.pagination = this.businessStoresPageNumber;
    },
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'business/changeBusinessStoresPage',
        },
        {
          id: this.businessId,
          page: newValue,
        },
      );
    },
  },
  computed: {
    ...mapGetters('business', ['business', 'businessStores', 'businessStoresPageCount', 'businessStoresPageNumber', 'businessStoresItemCount']),
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    async clear() {
      this.$validator.reset();
    },
    async fetchBusinessStores() {
      return this.executeAction({ action: 'business/listBusinessStores' }, { id: this.business.id, page: 1 });
    },
  },
};
</script>
