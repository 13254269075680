import Vue from 'vue';

const service = {
  async getDocument(id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/aml/v1/documents/${id}`, { responseType: 'blob' });
    return response.data;
  },

  async deleteDocument(id, payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/aml/v1/documents/${id}/delete`, payload);
    return response.data;
  },

  async listDocument(query) {
    let url = `${process.env.VUE_APP_BASE_API_URL}/aml/v1/documents`;

    if (query) {
      const params = {};

      if (query.card_application_id) {
        params.card_application_id = query.card_application_id;
      }

      if (query.public_token) {
        params.public_token = query.public_token;
      }

      if (query.cardholder_key) {
        params.cardholder_key = query.cardholder_key;
      }

      if (query.account_id) {
        params.account_id = query.account_id;
      }

      const queryStrings = Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join('&');

      url += '?' + queryStrings;
    }

    const response = await Vue.prototype.$axios.get(url);
    return response.data.data.items;
  },
};

export default service;
