<template>
  <div>
    <table class="responsive-table" id="offer-publishers-table">
      <thead>
        <tr>
          <td>{{ $t('offers.publishers_table.fields.id') }}</td>
          <td>{{ $t('offers.publishers_table.fields.name') }}</td>
          <td align="center">{{ $t('offers.publishers_table.fields.voucher') }}</td>
          <td align="center">{{ $t('offers.publishers_table.fields.stores') }}</td>
          <td>{{ $t('offers.publishers_table.fields.status.pad') }}</td>
          <td>{{ $t('offers.publishers_table.fields.status.display') }}</td>
          <td>{{ $t('offers.publishers_table.fields.status.transactional') }}</td>
          <td>{{ $t('offers.publishers_table.fields.wallet_definition') }}</td>
          <td align="right">{{ $t('offers.publishers_table.fields.remaining') }}</td>
          <td>{{ $t('offers.publishers_table.fields.offer_publisher_status') }}</td>
          <td>{{ $t('offers.publishers_table.fields.offer_publisher_category') }}</td>
          <td>{{ $t('offers.publishers_table.fields.inventory_limit') }}</td>
          <td>{{ $t('offers.publishers_table.fields.edit') }}</td>
          <td>{{ $t('offers.publishers_table.fields.action') }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>
            <h5>{{ $t('offers.publishers_table.fields.id') }}:</h5>
            <router-link :to="{ name: 'closed_loop_details', params: { publishersId: item.publisher.id } }">
              {{ item.publisher.id }}
            </router-link>
          </td>
          <td>
            <h5>{{ $t('offers.publishers_table.fields.name') }}:</h5>
            {{ item.publisher.name }}
          </td>
          <td align="center">
            <h5>{{ $t('offers.publishers_table.fields.voucher') }}:</h5>
            {{ item.vouchers_count }}
          </td>
          <td align="center">
            <h5>{{ $t('offers.publishers_table.fields.stores') }}:</h5>
            {{ item.publisher.stores_count }}
          </td>
          <td>
            <h5>{{ $t('offers.publishers_table.fields.status.pad') }}:</h5>
            <ready-status-view :status="item.publisher.system_status.pad" />
          </td>
          <td>
            <h5>{{ $t('offers.publishers_table.fields.status.display') }}:</h5>
            <ready-status-view :status="item.publisher.system_status.display" />
          </td>
          <td>
            <h5>{{ $t('offers.publishers_table.fields.status.transactional') }}:</h5>
            <ready-status-view :status="item.publisher.system_status.transactional" />
          </td>
          <td>
            <h5>{{ $t('offers.publishers_table.fields.wallet_definition') }}:</h5>
            <router-link class="button-subtle" :to="{ name: 'wallet_definition_details', params: { walletDefinitionId: item.wallet_definition_id } }">
              {{ item.wallet_definition_id }}
            </router-link>
          </td>
          <td align="right">
            <h5>{{ $t('offers.publishers_table.fields.remaining') }}:</h5>
            <span v-if="!offer.is_single_publisher">{{ item.remaining_inventory | currency | dollarSignI18n }}</span>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('offers.publishers_table.fields.offer_publisher_status') }}:</h5>
            <offer-publisher-status-view :status="item.system_status.status" />
          </td>
          <td>
            <h5>{{ $t('offers.publishers_table.fields.offer_publisher_category') }}:</h5>
            <span v-if="item.publisher.branding.category_id">{{ item.publisher.branding.category_id }}</span>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('offers.publishers_table.fields.inventory_limit') }}:</h5>
            <span v-if="!offer.is_single_publisher">{{ item.inventory_limit | currency | dollarSignI18n }}</span>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('offers.publishers_table.fields.edit') }}:</h5>
            <link-secondary-action v-if="!offer.is_single_publisher" slot="labelTextLink" @click="openEditOfferPublisherModal(item.publisher.id)">{{
              $t('offers.publishers_table.edit_link')
            }}</link-secondary-action>
            <label-none v-else>{{ $t('system.na') }}</label-none>
          </td>
          <td>
            <h5>{{ $t('offers.publishers_table.fields.action') }}:</h5>
            <link-secondary-action v-if="item.vouchers_count === 0" slot="labelTextLink" @click="openDeleteOfferPublisherModal(item.publisher.id)">{{
              $t('offers.publishers_table.remove_link')
            }}</link-secondary-action>
            <link-secondary-action
              v-if="item.vouchers_count > 0 && item.system_status.status === 'deactivated'"
              slot="labelTextLink"
              @click="openActivateOfferPublisherModal(item.publisher.id)"
              >{{ $t('offers.publishers_table.activate_link') }}</link-secondary-action
            >
            <link-secondary-action
              v-if="item.vouchers_count > 0 && item.system_status.status !== 'deactivated'"
              slot="labelTextLink"
              @click="openDeactivateOfferPublisherModal(item.publisher.id)"
              >{{ $t('offers.publishers_table.deactivate_link') }}</link-secondary-action
            >
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-xs-center">
      <v-pagination v-if="offerPublishersPageCount > 1" v-model="pagination" :length="offerPublishersPageCount"></v-pagination>
    </div>
  </div>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import OfferPublisherStatusView from '../OfferPublisherStatusView';
import { mapGetters } from 'vuex';

export default {
  name: 'offers-publishers-table',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { OfferPublisherStatusView },
  props: {
    id: {
      type: String,
    },
    items: {
      type: Array,
    },
  },
  data() {
    return {
      pagination: 1,
    };
  },
  mounted() {
    this.pagination = this.offerPublishersPageNumber;
  },
  computed: {
    ...mapGetters('offers', [
      'offer',
      'offerPublishersKeyword',
      'offerPublishersStatus',
      'offerPublishersCategory',
      'offerPublishersReadiness',
      'offerPublishersPageNumber',
      'offerPublishersPageCount',
      'offerPublishersItemCount',
    ]),
  },
  watch: {
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'offers/listOfferPublishersByOffer',
        },
        {
          offer_id: this.id,
          keyword: this.offerPublishersKeyword,
          status: this.offerPublishersStatus,
          category: this.offerPublishersCategory,
          readiness: this.offerPublishersReadiness,
          page: newValue,
        },
      );
    },
  },
  methods: {
    openEditOfferPublisherModal(id) {
      this.$emit('offers_publisher_edit_action', id);
    },
    openDeleteOfferPublisherModal(id) {
      this.$emit('offers_publisher_delete_action', id);
    },
    openActivateOfferPublisherModal(id) {
      this.$emit('offers_publisher_activate_action', id);
    },
    openDeactivateOfferPublisherModal(id) {
      this.$emit('offers_publisher_deactivate_action', id);
    },
  },
};
</script>
