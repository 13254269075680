<template>
  <card :title="$t('publishers.details.offers_section.title')" icon="">
    <template slot="headerZoneRight">
      <label-text
        class="text-xs-center"
        :label="`${$t('publishers.details.offers_section.count')}`"
        :value="publisherOffersItemCount || '0'"
      ></label-text>
      <div v-if="showAddPublisherOfferFeature()" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text v-if="showAddPublisherOfferFeature()" :label="`${$t('publishers.details.offers_section.action')}`" value="3">
        <link-secondary-action slot="labelTextLink" @click="openAddPublisherOfferModal">
          {{ $t('publishers.details.offers_section.add_offer') }}
        </link-secondary-action>
      </label-text>
    </template>
    <template slot="content">
      <modal-action :show-modal="showAddPublisherOfferModal">
        <add-publisher-offer-action
          :publisher-obj="publisher"
          @close="closeAddPublisherOfferModal"
          @created="addPublisherOfferSuccess"
        ></add-publisher-offer-action
      ></modal-action>
      <publishers-offers-table
        v-if="dataReady && publisherOffers.length"
        :items="publisherOffers"
        :publisher-id="publisher.id"
      ></publishers-offers-table>
      <label-none v-if="publisherOffers.length === 0">{{ $t('card.comments_section.no_found') }}</label-none>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import { mapGetters } from 'vuex';
import PublishersOffersTable from './PublishersOffersTable';
import AddPublisherOfferAction from '../actions/AddPublisherOfferAction';

export default {
  name: 'publishers-offers',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { PublishersOffersTable, AddPublisherOfferAction },
  data() {
    return {
      dataReady: false,
      showAddPublisherOfferModal: false,
      showDeletePublisherOfferModal: false,
      pagination: 1,
    };
  },
  async created() {
    await this.fetchPublisherOffers();
  },
  watch: {
    async publisher() {
      return this.fetchPublisherOffers();
    },
    publisherOffersPageNumber() {
      this.pagination = this.publisherOffersPageNumber;
    },
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'publishers/changePublisherOffersPage',
          success: this.success,
        },
        {
          id: this.publisher.id,
          page: newValue,
        },
      );
    },
  },
  computed: {
    ...mapGetters('publishers', [
      'publisher',
      'publisherOffers',
      'publisherOffersPageCount',
      'publisherOffersPageNumber',
      'publisherOffersItemCount',
    ]),
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    showAddPublisherOfferFeature() {
      return this.canManageOffer();
    },
    openAddPublisherOfferModal() {
      this.showAddPublisherOfferModal = true;
    },
    closeAddPublisherOfferModal() {
      this.showAddPublisherOfferModal = false;
    },
    async addPublisherOfferSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'publishers.details.offers_section.create_action.success' });
      this.closeAddPublisherOfferModal();
    },
    async fetchPublisherOffers() {
      await this.executeAction({ action: 'publishers/listPublisherOffers' }, { id: this.publisher.id, page: this.publisherOffersPageNumber });
      this.dataReady = true;
    },
  },
};
</script>
