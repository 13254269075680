<template>
  <card-modal
    icon="fal fa-store-alt"
    :title="$t('offers.details.activation_codes_section.batch_action.title')"
    context="createActivationCodeBatchWithRecipientCsvFile"
    @submit="submit"
    @cancel="cancel"
    :submit_working="submitting"
    :submit_hidden="showSubmitButton"
    :cancel_text="cancelText"
    :submit_text="submitText"
  >
    <div slot="content">
      <form @submit.prevent="">
        <template v-if="!submitted">
          <v-flex xs12>
            <v-alert :value="true" type="error" outline v-if="recipient.analyse_data_error.length > 0">
              <h2>{{ $t('offers.details.activation_codes_section.batch_action.recipients_csv_file_error') }}</h2>
              <ul>
                <template v-for="(data, index) in recipient.analyse_data_error">
                  <li :key="index">
                    Line {{ data.line }}
                    <ul>
                      <li v-for="(detail, detailIndex) in data.details" :key="detailIndex">{{ detail.message }} [{{ detail.context.value }}]</li>
                    </ul>
                  </li>
                </template>
              </ul>
            </v-alert>
          </v-flex>
          <v-layout column>
            <modal-info-block :heading="$t('offers.details.activation_codes_section.batch_action.offer')" :text="offerInfo"></modal-info-block>
          </v-layout>
          <v-layout row wrap pad-form-row-elements>
            <v-flex xs12>
              <v-alert type="info" :value="true">
                {{ $t('offers.details.activation_codes_section.batch_action.recipients_csv_file_example_info') }} <br />
                <a style="color: unset" v-on:click="downloadCsvFileExample">{{
                  $t('offers.details.activation_codes_section.batch_action.recipients_csv_file_example_info_download')
                }}</a></v-alert
              >
              <v-text-field
                readonly
                :label="$t('offers.details.activation_codes_section.batch_action.recipients_csv_file') + '*'"
                name="recipients_csv_file"
                prepend-icon="attach_file"
                data-vv-name="recipients_csv_file"
                @click="onPickRecipientCsvFile"
                class="input-cursor"
                v-model="recipient.file_name"
                v-validate="{
                  required: true,
                }"
                :error-messages="errors.collect('recipients_csv_file')"
                :data-vv-as="$t('offers.details.activation_codes_section.batch_action.recipients_csv_file')"
              />
              <input type="file" style="display: none" ref="fileInput" accept="text/csv" @change="onRecipientCsvFilePicked" />
            </v-flex>
          </v-layout>
          <template v-if="recipient.valid">
            <v-layout>
              <v-flex>
                <v-text-field
                  :label="$t('offers.details.activation_codes_section.batch_action.reference_id')"
                  name="reference_id"
                  v-validate="'max:100'"
                  :error-messages="errors.collect('reference_id')"
                  v-model="activationCodeData.reference_id"
                  data-vv-name="reference_id"
                  maxlength="100"
                  :data-vv-as="$t('offers.details.activation_codes_section.batch_action.reference_id')"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row wrap pad-form-row-elements class="pt-4">
              <v-flex sm4 xs12>
                <v-text-field
                  :label="$t('offers.details.activation_codes_section.batch_action.payment_amount') + '*'"
                  name="payment_amount"
                  v-validate="{
                    required: true,
                    integer: true,
                    min_value: 0,
                    max_value: 1000,
                  }"
                  type="number"
                  :error-messages="errors.collect('payment_amount')"
                  v-model="activationCodeData.payment_amount"
                  data-vv-name="payment_amount"
                  maxlength="255"
                  :data-vv-as="$t('offers.details.activation_codes_section.batch_action.payment_amount')"
                ></v-text-field>
              </v-flex>
              <v-flex sm4 xs12>
                <v-text-field
                  :label="$t('offers.details.activation_codes_section.batch_action.bonus_amount') + '*'"
                  name="bonus_amount"
                  v-validate="{
                    required: true,
                    integer: true,
                    min_value: 0,
                    max_value: 1000,
                    sumLessThanOrEqual: {
                      field: activationCodeData.payment_amount,
                      total: 1000,
                      totalFieldLabel: $t('offers.details.activation_codes_section.batch_action.amount'),
                    },
                  }"
                  type="number"
                  :error-messages="errors.collect('bonus_amount')"
                  v-model="activationCodeData.bonus_amount"
                  data-vv-name="bonus_amount"
                  maxlength="255"
                  :data-vv-as="$t('offers.details.activation_codes_section.batch_action.bonus_amount')"
                ></v-text-field>
              </v-flex>
              <v-flex sm4 xs12>
                <label-text
                  :label="$t('offers.details.activation_codes_section.batch_action.amount')"
                  :value="totalAmount | currency | dollarSignI18n"
                ></label-text>
              </v-flex>
            </v-layout>
            <v-layout row wrap pad-form-row-elements>
              <v-checkbox
                name="pre_activation_required"
                v-model="activationCodeData.pre_activation_required"
                :label="$t(`offers.details.activation_codes_section.batch_action.pre_activation_required`)"
                :error-messages="errors.collect('pre_activation_required')"
                data-vv-name="pre_activation_required"
                :data-vv-as="$t(`offers.details.activation_codes_section.batch_action.pre_activation_required`)"
                type="checkbox"
              ></v-checkbox>
            </v-layout>
            <v-layout row wrap pad-form-row-elements>
              <v-flex sm6 xs12>
                <v-checkbox
                  name="reward_funded"
                  v-model="activationCodeData.bonus_amount_funded"
                  :label="$t(`offers.details.activation_codes_section.batch_action.bonus_amount_funded`)"
                  :error-messages="errors.collect('reward_funded')"
                  data-vv-name="reward_funded"
                  :data-vv-as="$t(`offers.details.activation_codes_section.batch_action.bonus_amount_funded`)"
                  type="checkbox"
                ></v-checkbox>
              </v-flex>
            </v-layout>
            <v-layout row wrap pad-form-row-elements>
              <v-flex sm6 xs12>
                <v-checkbox
                  name="payment_funded"
                  v-model="activationCodeData.payment_amount_funded"
                  :label="$t(`offers.details.activation_codes_section.batch_action.payment_amount_funded`)"
                  :error-messages="errors.collect('payment_funded')"
                  data-vv-name="payment_funded"
                  :data-vv-as="$t(`offers.details.activation_codes_section.batch_action.payment_amount_funded`)"
                  type="checkbox"
                ></v-checkbox>
              </v-flex>
            </v-layout>
            <v-layout row pad-form-row-elements>
              <v-flex sm3 xs3>
                <v-select
                  name="trigger_expiration_at_purchase"
                  v-model="activationCodeData.trigger_expiration_at_purchase"
                  v-validate="'required'"
                  :error-messages="errors.collect('trigger_expiration_at_purchase')"
                  data-vv-name="trigger_expiration_at_purchase"
                  :data-vv-as="$t(`offers.details.activation_codes_section.batch_action.trigger_expiration_at_purchase.label`)"
                  :items="trigger_expiration_at_purchase_options"
                  item-text="name"
                  :label="$t(`offers.details.activation_codes_section.batch_action.trigger_expiration_at_purchase.label`)"
                  data-test="create-activation-code-batch--trigger-expiration-at-purchase"
                ></v-select>
              </v-flex>
            </v-layout>
            <v-layout row wrap pad-form-row-elements>
              <v-flex sm3 xs3>
                <v-text-field
                  disabled
                  :label="$t('offers.details.activation_codes_section.batch_action.batch_quantity') + ' *'"
                  name="batch_quantity"
                  v-validate="{
                    required: true,
                    min_value: 0,
                    max_value: 1000,
                    integer: true,
                    max: 4,
                  }"
                  maxlength="4"
                  :error-messages="errors.collect('batch_quantity')"
                  v-model="activationCodeData.batch_quantity"
                  data-vv-name="batch_quantity"
                  type="number"
                  :data-vv-as="$t('offers.details.activation_codes_section.batch_action.batch_quantity')"
                ></v-text-field>
              </v-flex>
              <v-flex sm3 xs3>
                <label-text
                  :label="$t('offers.details.activation_codes_section.batch_action.batch_amount')"
                  :value="totalBatchAmount | currency | dollarSignI18n"
                ></label-text>
              </v-flex>
            </v-layout>
            <v-layout row wrap pad-form-row-elements>
              <v-flex sm8 xs12>
                <v-text-field
                  :label="$t('offers.details.activation_codes_section.batch_action.batch_name') + ' *'"
                  name="batch_name"
                  v-validate="{
                    required: true,
                    max: 50,
                  }"
                  maxlength="50"
                  :error-messages="errors.collect('batch_name')"
                  v-model="activationCodeData.batch_name"
                  data-vv-name="batch_name"
                  type="text"
                  :data-vv-as="$t('offers.details.activation_codes_section.batch_action.batch_name')"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex sm12 xs12>
                <h-autocomplete
                  v-model="business_id"
                  :label="$t('offers.details.activation_codes_section.batch_action.business_id') + '*'"
                  v-validate="'required'"
                  :error-messages="errors.collect('contributor')"
                  name="contributor"
                  data-vv-name="contributor"
                  data-vv-as="contributor"
                  search-action="business/autocompleteSearch"
                  data-test="create-activation-code-batch--contributor"
                >
                </h-autocomplete>
              </v-flex>
            </v-layout>
            <v-layout row wrap pad-form-row-elements>
              <v-flex sm12 xs12>
                <v-textarea
                  counter
                  :label="$t('offers.details.activation_codes_section.batch_action.personalized_message')"
                  name="personalized_message"
                  v-validate="{
                    max: 150,
                    maxLines: { nbLines: 6 },
                  }"
                  type="text"
                  :error-messages="errors.collect('personalized_message')"
                  v-model="activationCodeData.personalized_message"
                  data-vv-name="personalized_message"
                  maxlength="150"
                  :data-vv-as="$t('offers.details.activation_codes_section.batch_action.personalized_message')"
                ></v-textarea>
              </v-flex>
            </v-layout>
          </template>
        </template>
      </form>

      <template v-if="submitted">
        <v-layout row wrap>
          <v-flex xs12>
            <v-alert :value="true" color="success" icon="check_circle" outline>
              {{ $t('offers.details.activation_codes_section.batch_action.success_message') }}
            </v-alert>
          </v-flex>
          <v-flex sm10 xs12>
            <h4>{{ $t('offers.details.activation_codes_section.batch_action.offer') }}</h4>
            <p>{{ offerInfo }}</p>
            <v-layout>
              <v-flex xs6>
                <label-text :label="`${$t('offers.details.activation_codes_section.batch_action.batch_id')}`" :value="response.batch_id">
                  <p slot="labelTextLink">
                    <span class="font-weight-bold">{{ response.batch_id }}</span>
                    <button class="clipboard" v-clipboard:copy="response.batch_id">
                      <v-icon small>file_copy</v-icon>
                    </button>
                  </p>
                </label-text>
              </v-flex>
              <v-flex xs6>
                <label-text :label="`${$t('offers.details.activation_codes_section.batch_action.batch_name')}`" :value="response.batch_name">
                  <p slot="labelTextLink">
                    <span class="font-weight-bold">{{ response.batch_name }}</span>
                    <button class="clipboard" v-clipboard:copy="response.batch_name">
                      <v-icon small>file_copy</v-icon>
                    </button>
                  </p>
                </label-text>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </template>
    </div>
    <button-primary
      slot="button-zone--right"
      :text="$t('offers.details.activation_codes_section.batch_action.import')"
      v-on:click="validateCsvFile"
      v-if="showImportButton"
    />
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import Vue from 'vue';
import { mapActions } from 'vuex';

export default {
  name: 'create-activation-code-batch-with-csv-recipients-field-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    offerObj: {
      type: Object,
    },
  },
  data() {
    return {
      submitting: false,
      submitted: false,
      response: {},
      business_id: '',
      activationCodeData: {
        batch_quantity: 0,
        bonus_amount: this.offerObj.incentive.bonus_amount,
        payment_amount: this.offerObj.incentive.payment_amount,
        bonus_amount_funded: false,
        payment_amount_funded: false,
        personalized_message: null,
        trigger_expiration_at_purchase: null,
        pre_activation_required: false,
      },
      cancelText: 'offers.details.activation_codes_section.batch_action.cancel_text',
      recipient: {
        file_name: null,
        file: null,
        valid: false,
        analyse_data_error: [],
      },
    };
  },
  watch: {
    offerObj() {
      this.activationCodeData = {
        reference_id: null,
        batch_quantity: 0,
        bonus_amount: this.offerObj.incentive.bonus_amount,
        payment_amount: this.offerObj.incentive.payment_amount,
        personalized_message: null,
        bonus_amount_funded: false,
        payment_amount_funded: false,
        pre_activation_required: false,
        trigger_expiration_at_purchase: null,
      };
    },
  },
  computed: {
    trigger_expiration_at_purchase_options() {
      const options = [
        { name: this.$t('offers.details.activation_codes_section.batch_action.trigger_expiration_at_purchase.options.activation'), value: false },
      ];

      if (this.offerObj.incentive.duration_model === 'relative') {
        options.unshift({
          name: this.$t('offers.details.activation_codes_section.batch_action.trigger_expiration_at_purchase.options.purchase'),
          value: true,
        });
      }

      return options;
    },
    totalAmount() {
      return Number(this.activationCodeData.payment_amount || 0) + Number(this.activationCodeData.bonus_amount || 0);
    },
    totalBatchAmount() {
      return this.totalAmount * Number(this.activationCodeData.batch_quantity || 0);
    },
    offerInfo() {
      return `${this.offerObj.branding.name} (${this.offerObj.id})`;
    },
    showSubmitButton() {
      return !this.recipient.valid || this.submitted;
    },
    showImportButton() {
      return !this.recipient.valid;
    },
    submitText() {
      return !this.showSubmitButton ? 'offers.details.activation_codes_section.batch_action.submit_text' : null;
    },
  },
  methods: {
    ...mapActions('ui', ['addError', 'clearError']),
    downloadCsvFileExample() {
      const fileSaver = Vue.prototype.$services.fileSaver;
      const file = new Blob(['first_name,last_name,email\n'], { type: 'text/csv' });
      return fileSaver.saveFile(file, 'example.csv');
    },
    clear() {
      this.submitting = false;
      this.submitted = false;
      this.response = {};
      this.business_id = '';
      this.activationCodeData = {
        reference_id: null,
        batch_quantity: 1,
        bonus_amount: this.offerObj.incentive.bonus_amount,
        payment_amount: this.offerObj.incentive.payment_amount,
        bonus_amount_funded: false,
        payment_amount_funded: false,
        pre_activation_required: false,
        personalized_message: null,
        trigger_expiration_at_purchase: null,
      };
      this.cancelText = 'offers.details.activation_codes_section.batch_action.cancel_text';

      this.resetRecipientCsvFileError();
      this.resetRecipientCsvFile();
    },
    async resetRecipientCsvFile() {
      this.recipient.file_name = '';
      this.recipient.file = null;
      this.recipient.valid = false;

      this.activationCodeData.batch_quantity = 0;
      this.activationCodeData.trigger_expiration_at_purchase = null;

      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = null;
      }

      await this.$validator.reset();
    },
    resetRecipientCsvFileError() {
      this.clearError('createActivationCodeBatchWithRecipientCsvFile');
      this.recipient.analyse_data_error = [];
    },
    async onPickRecipientCsvFile() {
      await this.resetRecipientCsvFile();
      this.resetRecipientCsvFileError();
      this.$refs.fileInput.click();
    },
    async onRecipientCsvFilePicked(event) {
      if (event.target.files.length === 0) {
        return;
      }
      this.recipient.file = event.target.files[0];
      this.recipient.file_name = this.recipient.file.name;
    },
    async validateCsvFile() {
      const validationComplete = await this.$validator.validateAll();
      if (!validationComplete) {
        return;
      }

      try {
        const validateState = await Vue.prototype.$services.activationCodeBatch.validateRecipientCsvFile(this.recipient.file);
        this.activationCodeData.batch_quantity = validateState.recipient_count;
      } catch (err) {
        if (err.response_code !== 'invalid.recipient.csv.file') {
          return this.addError({ contextName: 'createActivationCodeBatchWithRecipientCsvFile', error: err });
        }

        this.recipient.analyse_data_error = err.response_data;
        await this.resetRecipientCsvFile();
        return;
      }

      this.recipient.valid = true;
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    buildPayload() {
      const payload = {
        offer_id: this.offerObj.id,
        batch_quantity: this.activationCodeData.batch_quantity,
        batch_name: this.activationCodeData.batch_name,
        bonus_amount: this.activationCodeData.bonus_amount,
        payment_amount: this.activationCodeData.payment_amount,
        bonus_amount_funded: this.activationCodeData.bonus_amount_funded,
        payment_amount_funded: this.activationCodeData.payment_amount_funded,
        pre_activation_required: this.activationCodeData.pre_activation_required,
        trigger_expiration_at_purchase: this.activationCodeData.trigger_expiration_at_purchase,
        recipient_file: this.recipient.file,
      };

      if (this.activationCodeData.reference_id) {
        payload.reference_id = this.activationCodeData.reference_id;
      }

      if (this.activationCodeData.personalized_message) {
        payload.personalized_message = this.activationCodeData.personalized_message;
      }

      if (this.business_id) {
        payload.business_id = this.business_id;
      }

      return payload;
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        this.submitting = true;
        const payload = this.buildPayload();

        try {
          await this.executeAction(
            {
              action: 'activationCode/createActivationCodeBatchWithRecipientCsvFile',
              name: 'createActivationCodeBatchWithRecipientCsvFile',
              success: (payload) => {
                this.cancelText = 'offers.details.activation_codes_section.batch_action.close_text';
                this.response = payload;
                this.submitted = true;
              },
            },
            payload,
          );
        } finally {
          this.submitting = false;
        }
      }
    },
  },
};
</script>
<style lang="css">
.input-cursor input {
  cursor: pointer;
}
.v-alert > div > ul {
  max-height: 500px;
  overflow-y: auto;
}
</style>
