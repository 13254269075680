<template>
  <view-main title="page_title.branding"><search-branding-form slot="content"></search-branding-form></view-main>
</template>

<script>
import { renderErrorTrackable } from '@/mixins';
import SearchBrandingForm from './list/SearchBrandingForm.vue';

export default {
  name: 'branding-view',
  mixins: [renderErrorTrackable],
  components: { SearchBrandingForm },
};
</script>
