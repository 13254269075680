<template>
  <view-main title="page_title.business"><search-business-form slot="content"></search-business-form></view-main>
</template>

<script>
import { renderErrorTrackable } from '@/mixins';
import SearchBusinessForm from './list/SearchBusinessForm.vue';

export default {
  name: 'business-view',
  mixins: [renderErrorTrackable],
  components: { SearchBusinessForm },
};
</script>
