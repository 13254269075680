var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card-modal',{attrs:{"icon":"","title":_vm.$t('inventory.adjust_action.title'),"context":"adjustInventory","cancel_text":"inventory.adjust_action.button_cancel","submit_text":"inventory.adjust_action.button_save"},on:{"submit":_vm.submit,"cancel":_vm.cancel}},[_c('form',{attrs:{"slot":"content"},on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}},slot:"content"},[_c('v-layout',{attrs:{"column":""}},[[_c('v-layout',{attrs:{"row":"","pad-form-row-elements":""}},[_c('v-flex',{attrs:{"sm3":"","xs12":""}},[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:({
                required: true,
                decimal: 2,
                is_not: 0,
                between: [-_vm.currentInventory.available_amount, 10000000 - _vm.currentInventory.current_amount],
              }),expression:"{\n                required: true,\n                decimal: 2,\n                is_not: 0,\n                between: [-currentInventory.available_amount, 10000000 - currentInventory.current_amount],\n              }"}],ref:"adjust_amount",attrs:{"autofocus":"","name":"adjust_amount","label":_vm.$t('inventory.adjust_action.adjust_amount') + '*',"append-icon":"fas fa-dollar-sign","error-messages":_vm.errors.collect('adjust_amount'),"data-vv-name":"adjust_amount","data-vv-as":_vm.$t('inventory.adjust_action.adjust_amount'),"data-test":"adjust_inventory--adjust_amount"},model:{value:(_vm.adjust_amount),callback:function ($$v) {_vm.adjust_amount=$$v},expression:"adjust_amount"}})],1),_c('v-flex',{attrs:{"sm3":"","xs12":""}},[_c('h5',[_vm._v(_vm._s(_vm.$t('inventory.adjust_action.new_current_amount')))]),_c('p',[_vm._v(_vm._s(_vm._f("dollarSignI18n")(_vm._f("currency")(this.newTotalAmount))))])]),_c('v-flex',{attrs:{"sm3":"","xs12":""}},[_c('h5',[_vm._v(_vm._s(_vm.$t('inventory.adjust_action.new_available_amount')))]),_c('p',[_vm._v(_vm._s(_vm._f("dollarSignI18n")(_vm._f("currency")(this.newAvailableAmount))))])])],1),_c('v-layout',[_c('v-flex',{attrs:{"sm12":"","xs12":""}},[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:({
                required: true,
                max: 1024,
              }),expression:"{\n                required: true,\n                max: 1024,\n              }"}],ref:"reason",attrs:{"name":"reason","label":_vm.$t('inventory.adjust_action.reason') + '*',"error-messages":_vm.errors.collect('reason'),"data-vv-name":"reason","data-vv-as":_vm.$t('inventory.adjust_action.reason'),"data-test":"adjust_inventory--reason"},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}})],1)],1)]],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }