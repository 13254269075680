import Vue from 'vue';

const service = {
  async createCard(payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v1/instant-cards`, payload);
    return response.data.data;
  },
  async preActivate(public_token) {
    const response = await Vue.prototype.$axios.put(
      `${process.env.VUE_APP_BASE_API_URL}/publisher/v1/instant-cards/${public_token}/enable-activation`,
      { purchase_channel: 'other' },
    );
    return response.data.data;
  },
  async markAsNonPreActivated(public_token) {
    const response = await Vue.prototype.$axios.put(
      `${process.env.VUE_APP_BASE_API_URL}/publisher/v1/instant-cards/${public_token}/disable-activation`,
      {},
    );
    return response.data.data;
  },
  async getCard(public_token) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/publisher/v1/instant-cards/${public_token}`);
    return response.data.data;
  },
  async listCards(page, limit, keyword, partner_id, instant_card_project_id, status) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(
        '/publisher/v1/instant-cards',
        page,
        limit,
        keyword,
        partner_id,
        instant_card_project_id,
        status,
      )}`,
    );
    return response.data.data;
  },
  async getInstantCardActivationCountryList() {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/publisher/v1/instant-card/countries`);
    return response.data.data.items;
  },
  async searchPrograms(page, limit, keyword) {
    const params = {};
    if (keyword) {
      params.keyword = keyword;
    }
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath('/publisher/v1/instant-card-programs/search', page, limit, keyword)}`,
    );
    return response.data.data;
  },
  async activateInstantCard(public_token, payload) {
    const response = await Vue.prototype.$axios.put(
      `${process.env.VUE_APP_BASE_API_URL}/publisher/v1/instant-cards/${public_token}/activate`,
      payload,
    );
    return response.data.data;
  },
  async pauseInstantCard(public_token) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/publisher/v1/instant-cards/${public_token}/pause`);
    return response.data.data;
  },
  async resumeInstantCard(public_token) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/publisher/v1/instant-cards/${public_token}/resume`);
    return response.data.data;
  },
  async destroyInstantCard(public_token) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/publisher/v1/instant-cards/${public_token}/destroy`, {});
    return response.data.data;
  },
  async linkCardToProject({ public_token, instant_card_project_id }) {
    const payload = { instant_card_project_id };
    const response = await Vue.prototype.$axios.put(
      `${process.env.VUE_APP_BASE_API_URL}/publisher/v1/instant-cards/${public_token}/instant-card-project`,
      payload,
    );
    return response.data.data;
  },
  async unlinkCardFromProject({ public_token }) {
    const response = await Vue.prototype.$axios.delete(
      `${process.env.VUE_APP_BASE_API_URL}/publisher/v1/instant-cards/${public_token}/instant-card-project`,
    );
    return response.data.data;
  },
};

function generatePath(startPath, page, limit, keyword, partner_id, instant_card_project_id, status) {
  let path = startPath;
  if (page || limit || keyword || partner_id || instant_card_project_id || status) {
    path += '?';
  }

  const queryStrings = [];
  if (page) {
    queryStrings.push(['page', page]);
  }
  if (limit) {
    queryStrings.push(['limit', limit]);
  }
  if (keyword) {
    queryStrings.push(['keyword', keyword]);
  }
  if (partner_id) {
    queryStrings.push(['partner_id', partner_id]);
  }
  if (instant_card_project_id) {
    queryStrings.push(['instant_card_project_id', instant_card_project_id]);
  }
  if (status) {
    queryStrings.push(['status', status]);
  }

  const params = new URLSearchParams(queryStrings);
  return path + params.toString();
}

export default service;
