<template>
  <card-modal
    icon=""
    :title="$t('voucher.load_action.title')"
    context="loadVoucher"
    @submit="submit"
    @cancel="cancel"
    cancel_text="voucher.transfer_action.button_cancel"
    submit_text="voucher.transfer_action.button_save"
    :submit_working="working"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-layout row pad-form-row-elements>
          <modal-info-block :heading="$t('voucher.load_action.voucher_id')" :text="voucher.id"></modal-info-block>
          <v-spacer></v-spacer>
          <modal-info-block :heading="$t('voucher.load_action.user')" :text="accountEmail"></modal-info-block>
          <v-spacer></v-spacer>
        </v-layout>
        <v-layout column>
          <v-flex sm12 xs12 v-if="toAccountId && loadableCards.length">
            <v-select
              name="card"
              v-model="cardholder_id"
              :error-messages="errors.collect('card')"
              v-validate="'required'"
              data-vv-name="card"
              :data-vv-as="$t(`voucher.transfer_action.card`)"
              :items="loadableCardOptions"
              item-text="text"
              item-value="value"
              :label="$t(`voucher.transfer_action.card`)"
            ></v-select>
          </v-flex>
        </v-layout>
      </v-layout>
    </form>
  </card-modal>
</template>
<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import _get from 'lodash/get';
import _ from 'lodash';

export default {
  name: 'load-voucher-action-to-card',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    voucher: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      toAccountId: null,
      accountEmail: '?',
      cardholder_id: null,
      loadableCardOptions: null,
    };
  },
  async created() {
    let account = null;
    if (this.voucher) {
      account = await this.executeAction({ action: 'account/getAccount' }, this.voucher.account_id);
    }
    this.accountEmail = _get(account, 'contact.email.value', '?');
    this.toAccountId = _get(account, 'id', null);
  },
  watch: {
    async toAccountId(val) {
      await this.executeAction(
        {
          action: 'account/listLoadableCardsByAccountId',
          name: 'listLoadableCardsByAccountId',
        },
        val,
      );
    },
    loadableCards() {
      this.cardholder_id = null;
      this.loadableCardOptions = _.map(this.loadableCards, (card) => {
        card.text = `${card.public_token} (${card.card_package_id}) ${card.masked_pan}`;
        card.value = card.cardholder_id;
        return card;
      });

      if (this.loadableCardOptions.length === 1) {
        this.cardholder_id = this.loadableCardOptions[0].cardholder_id;
      }
    },
  },
  computed: {
    ...mapGetters('account', ['loadableCards']),
  },
  methods: {
    clear() {
      this.toAccountId = null;
      this.reason = undefined;
      this.cardholder_id = null;
      this.loadableCardOptions = null;
      this.$validator.reset();
    },
    success() {
      this.clear();
      this.$emit('updated');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    buildPayload() {
      return {
        id: this.voucher.id,
        to_cardholder_id: this.cardholder_id,
      };
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        await this.executeAction(
          {
            action: 'voucher/loadVoucher',
            name: 'loadVoucher',
            success: this.success,
          },
          this.buildPayload(),
        );
      }
    },
  },
};
</script>
