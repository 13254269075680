<template>
  <card v-if="isBlankInstantCard" :title="$t('instant_card_cards.details.vouchers_section.title')" icon="">
    <template slot="headerZoneRight">
      <label-text
        class="text-sm-center"
        :label="$t('instant_card_cards.details.vouchers_section.voucher_count')"
        :value="instantCardVouchersItemCount.toString()"
      ></label-text>
    </template>
    <template slot="content">
      <instant-card-vouchers-table v-if="instantCardVouchers.length" :items="instantCardVouchers"></instant-card-vouchers-table>
      <label-none v-else>{{ $t('card.comments_section.no_found') }}</label-none>
      <div class="text-xs-center">
        <v-pagination
          v-if="instantCardVouchersPageCount > 1"
          class="apps-table--pagination"
          v-model="pagination"
          :length="instantCardVouchersPageCount"
        ></v-pagination>
      </div>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import InstantCardVouchersTable from './InstantCardVouchersTable';
import { mapGetters } from 'vuex';

export default {
  name: 'instant-card-vouchers',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { InstantCardVouchersTable },
  data() {
    return {
      pagination: 1,
    };
  },
  computed: {
    ...mapGetters('instantCardCard', [
      'card',
      'instantCardVouchers',
      'instantCardVouchersPageCount',
      'instantCardVouchersPageNumber',
      'instantCardVouchersItemCount',
    ]),
    ...mapGetters('ui', ['currentLocale']),
    isBlankInstantCard() {
      return _.get(this.card, 'instant_card_project.type') === 'blank';
    },
  },
  watch: {
    async card() {
      await this.fetchVouchers();
    },
    instantCardVouchersPageNumber() {
      this.pagination = this.instantCardVouchersPageNumber;
    },
    async pagination(newValue) {
      await this.executeAction(
        {
          action: 'instantCardCard/changeInstantCardVouchersPage',
          success: this.success,
        },
        {
          public_token: this.card.public_token,
          page: newValue,
        },
      );
    },
  },
  methods: {
    async fetchVouchers() {
      await this.executeAction(
        { action: 'instantCardCard/listVouchersByInstantCardPublicToken' },
        { public_token: this.card.public_token, page: this.instantCardVouchersPageNumber },
      );
    },
  },
};
</script>
