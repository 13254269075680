<template>
  <view-main title="page_title.bank_account_details">
    <two-levels-breadcrumbs
      slot="content"
      :top-label="$t('navigation.bank_accounts')"
      :to="{ name: 'bank_accounts' }"
      :last-label="bankAccount.bank_account_number"
    />
    <bank-account-information slot="content" :bank-account-key="bankAccountKey"></bank-account-information>
    <bank-account-notes slot="content" :bank-account-key="bankAccountKey"></bank-account-notes>
  </view-main>
</template>

<script>
import { actionErrorTrackable, renderErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import BankAccountInformation from './detail/BankAccountInformation.vue';
import BankAccountNotes from './detail/BankAccountNotes.vue';

export default {
  name: 'bank-account-detail-view',
  mixins: [renderErrorTrackable, actionErrorTrackable],
  components: { BankAccountInformation, BankAccountNotes },
  props: {
    bankAccountKey: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('bankAccount', ['bankAccount']),
  },
  async mounted() {
    await this.fetch();
  },
  watch: {
    async bankAccountKey() {
      return this.fetch();
    },
  },
  methods: {
    async fetch() {
      const bankAccount = await this.executeAction({ action: 'bankAccount/getBankAccount' }, this.bankAccountKey);
      if (!bankAccount) {
        this.$router.replace({ name: 'notFound' });
      }
    },
  },
};
</script>
