<template>
  <view-main title="page_title.instant_card_card_details">
    <two-levels-breadcrumbs
      slot="content"
      :top-label="$t('navigation.instant_card_cards')"
      :to="{ name: 'instant_cards' }"
      :last-label="card.public_token"
    />
    <instant-card-information slot="content" :card-id="cardId"></instant-card-information>
    <instant-card-batch slot="content" :card-id="cardId"></instant-card-batch>
    <instant-card-project slot="content" :card-id="cardId"></instant-card-project>
    <instant-card-vouchers slot="content" :card-id="cardId"></instant-card-vouchers>
  </view-main>
</template>

<script>
import { actionErrorTrackable, renderErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';
import InstantCardInformation from './detail/InstantCardInformation.vue';
import InstantCardBatch from './detail/InstantCardBatch';
import InstantCardVouchers from './detail/InstantCardVouchers';
import InstantCardProject from './detail/InstantCardProject';

export default {
  name: 'instant-card-detail-view',
  mixins: [renderErrorTrackable, actionErrorTrackable],
  components: { InstantCardInformation, InstantCardBatch, InstantCardProject, InstantCardVouchers },
  props: {
    cardId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('instantCardCard', ['card']),
  },
  async mounted() {
    await this.fetch();
  },
  watch: {
    async cardId() {
      return this.fetch();
    },
  },
  methods: {
    async fetch() {
      const card = await this.executeAction({ action: 'instantCardCard/getCard' }, this.cardId);
      if (!card) {
        this.$router.replace({ name: 'notFound' });
      }
    },
  },
};
</script>
