<template>
  <card-modal
    icon=""
    :title="$t('user.verify_email_action.title')"
    context="verifyEmailUser"
    @submit="submit"
    @cancel="cancel"
    cancel_text="user.verify_email_action.button_cancel"
    submit_text="user.verify_email_action.button_save"
    data-test="user--verify-email-modal"
    :enable_submit="email === user.contact.email.value"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <v-text-field
          autofocus
          :label="$t('user.verify_email_action.email') + '*'"
          name="email"
          autocomplete="off"
          v-validate="{ required: true, email: true, isSameUserEmail: { email: user.contact.email.value } }"
          :error-messages="errors.collect('email')"
          v-model="email"
          data-vv-name="email"
          maxlength="50"
          :data-vv-as="$t('user.verify_email_action.email')"
          data-test="verify-email-user--email"
          required
        ></v-text-field>
        <div class="verify-warning-container">
          <div class="label-text--value red--text verify-warning-text">{{ $t('user.verify_email_action.verify_warning') }}</div>
        </div>
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'verify-email-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      email: '',
    };
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    clear() {
      this.email = '';
      this.$validator.reset();
    },
    success() {
      this.clear();
      this.$emit('action');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();

      if (validationComplete) {
        await this.executeAction(
          {
            action: 'account/manualVerifyEmail',
            name: 'manualVerifyEmail',
            success: this.success,
          },
          { id: this.user.id, email: this.email },
        );
      }
    },
  },
};
</script>
<style lang="css">
.verify-warning-container {
  color: red;
  border-style: solid;
  border-radius: 5px;
}
.verify-warning-text {
  margin: 10px;
}
</style>
