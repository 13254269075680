<template>
  <view-main title="page_title.fraud_analysis">
    <v-flex slot="content" class="data-section">
      <h1 hidden>{{ $t('dashboards.fraud_analysis.title') }}</h1>
      <v-flex fill-height class="data-section">
        <dashboard id-name="fraud_analysis_report" :params="params" :timerange="timerange" />
      </v-flex>
    </v-flex>
  </view-main>
</template>

<script>
import { renderErrorTrackable } from '@/mixins';
import Dashboard from './Dashboard.vue';

export default {
  name: 'fraud-analysis-view',
  mixins: [renderErrorTrackable],
  components: { Dashboard },
  data() {
    return {
      timerange: null,
      program: null,
      aml_flag: null,
      user_id: null,
    };
  },
  created() {
    if (this.$route.query.timerange) {
      this.timerange = this.$route.query.timerange;
    }
    if (this.$route.query.user_id) {
      this.user_id = this.$route.query.user_id;
    }
    if (this.$route.query.aml_flag) {
      this.aml_flag = this.$route.query.aml_flag;
    }
    if (this.$route.query.program) {
      this.program = this.$route.query.program;
    }
  },
  computed: {
    params() {
      const theParams = {};

      if (this.user_id) {
        theParams.user_id = this.user_id;
      }

      if (this.timerange) {
        theParams.timerange = this.timerange;
      }

      if (this.aml_flag) {
        theParams.aml_flag = this.aml_flag;
      }

      if (this.program) {
        theParams.program = this.program;
      }

      return theParams;
    },
  },
};
</script>
