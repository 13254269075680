<template>
  <card :title="$t('publishers.details.notes_section.title')" icon="">
    <template slot="headerZoneRight">
      <label-text
        class="text-xs-center"
        :label="`${$t('publishers.details.notes_section.count')}`"
        :value="listPublisherNotesItemCount || '0'"
      ></label-text>
      <div v-if="showAddPublisherNoteFeature()" class="card-header-divider--vertical hidden-xs-only"><v-divider vertical></v-divider></div>
      <label-text v-if="showAddPublisherNoteFeature()" :label="`${$t('publishers.details.notes_section.action')}`" value="3">
        <link-secondary-action slot="labelTextLink" @click="openAddPublisherNoteModal">
          {{ $t('publishers.details.notes_section.create_note') }}
        </link-secondary-action>
      </label-text>
    </template>
    <template slot="content">
      <publisher-notes-table
        v-if="publisherNotes.length"
        :items="publisherNotes"
        :publisher-id="publisher.id"
        @publisher_note_delete_action="openDeletePublisherNoteModal"
      ></publisher-notes-table>
      <label-none v-if="publisherNotes.length === 0">{{ $t('publishers.details.notes_section.no_found') }}</label-none>
      <modal-action :show-modal="showAddPublisherNoteModal"
        ><create-publisher-note-action
          :v-if="showAddPublisherNoteModal"
          :publisher-id="publisher.id"
          @close="closeAddPublisherNoteModal"
          @created="addPublisherNoteSuccess"
        ></create-publisher-note-action
      ></modal-action>
      <modal-action :show-modal="showDeletePublisherNoteModal"
        ><delete-publisher-note-action
          :v-if="showDeletePublisherNoteModal"
          :publisher-id="publisher.id"
          :note-id="noteIdToDelete"
          @close="closeDeletePublisherNoteModal"
          @deleted="deletePublisherNoteSuccess"
        ></delete-publisher-note-action
      ></modal-action>
    </template>
  </card>
</template>

<script>
import { actionErrorTrackable, disableWithError, security } from '@/mixins';
import CreatePublisherNoteAction from '../actions/CreatePublisherNoteAction';
import DeletePublisherNoteAction from '../actions/DeletePublisherNoteAction';
import PublisherNotesTable from './PublisherNotesTable';
import { mapGetters } from 'vuex';

export default {
  name: 'publisher-notes',
  mixins: [security, actionErrorTrackable, disableWithError],
  components: { PublisherNotesTable, CreatePublisherNoteAction, DeletePublisherNoteAction },
  data() {
    return {
      showAddPublisherNoteModal: false,
      showDeletePublisherNoteModal: false,
      noteIdToDelete: null,
      noteToDelete: {},
    };
  },
  computed: {
    ...mapGetters('publishers', ['publisherNotes', 'publisher', 'listPublisherNotesItemCount']),
    ...mapGetters('ui', ['currentLocale']),
  },
  watch: {
    async publisher() {
      await this.fetchPublisherNotes();
    },
  },
  methods: {
    openAddPublisherNoteModal() {
      this.showAddPublisherNoteModal = true;
    },
    closeAddPublisherNoteModal() {
      this.showAddPublisherNoteModal = false;
    },
    openDeletePublisherNoteModal(noteId) {
      this.noteIdToDelete = noteId;
      this.noteToDelete = this.publisherNotes.find((l) => l.id === noteId);
      this.showDeletePublisherNoteModal = true;
    },
    closeDeletePublisherNoteModal() {
      this.noteIdToDelete = null;
      this.noteToDelete = {};
      this.showDeletePublisherNoteModal = false;
    },
    async deletePublisherNoteSuccess() {
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'publishers.delete_note_action.success' });
      this.closeDeletePublisherNoteModal();
    },
    async addPublisherNoteSuccess() {
      await this.fetchPublisherNotes();
      await this.executeAction({ action: 'ui/showSuccessSnackBar' }, { text: 'publishers.create_note_action.success' });
      this.closeAddPublisherNoteModal();
    },
    showAddPublisherNoteFeature() {
      return this.canManageOffer(); // TODO change permission
    },
    async fetchPublisherNotes() {
      await this.executeAction({ action: 'publishers/listPublisherNotes' }, { publisher_id: this.publisher.id });
    },
  },
};
</script>
