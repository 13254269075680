import Vue from 'vue';

const service = {
  async preActivate(id, payload) {
    const response = await Vue.prototype.$axios.put(
      `${process.env.VUE_APP_BASE_API_URL}/publisher/v2/activation-codes/${id}/enable-activation`,
      payload,
    );
    return response.data.data;
  },
  async markAsNonPreActivated(id) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/activation-codes/${id}/disable-activation`, {});
    return response.data.data;
  },
  async createActivationCode(offerId, payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/offers/${offerId}/activation-codes`, payload);
    return response.data.data;
  },
  async createActivationCodeBatch(offerId, payload) {
    const response = await Vue.prototype.$axios.post(
      `${process.env.VUE_APP_BASE_API_URL}/publisher/v2/offers/${offerId}/activation-codes/batch`,
      payload,
    );
    return response.data.data;
  },
  async createActivationCodeBatchWithRecipientCsvFile(offerId, payload) {
    const formData = new FormData();
    for (const key in payload) {
      formData.append(key, payload[key]);
    }

    const response = await Vue.prototype.$axios.post(
      `${process.env.VUE_APP_BASE_API_URL}/publisher/v2/offers/${offerId}/activation-codes/batch-with-recipients-csv-file`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return response.data.data;
  },
  async getActivationCode(id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/activation-codes/${id}`);
    return response.data.data;
  },
  async redeemActivationCode(id, payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/activation-codes/${id}/redeem`, payload);
    return response.data.data;
  },
  async cancelActivationCode(id, payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/activation-codes/${id}/cancel`, payload);
    return response.data.data;
  },
  async resendActivationCode(id) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/activation-codes/${id}/resend`, {});
    return response.data.data;
  },
  async listActivationCodesByOffer(offerId, page, limit, keyword, batch_id) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v2/offers/${offerId}/activation-codes`, { batch_id, page, limit, keyword })}`,
    );
    return response.data.data;
  },
  async countActivationCodesByOffer(offerId) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/offers/${offerId}/activation-codes/count`);
    return response.data.data.count;
  },
  async downloadActivationCodePDF(id, language = 'en') {
    const response = await Vue.prototype.$axios.get(
      generatePath(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/activation-codes/${id}/export/pdf`, { lang: language }),
      {
        responseType: 'blob',
      },
    );

    return { fileContent: response.data, filename: extractFileNameFromHeaders(response.headers) };
  },
  async updateActivationCode(id, payload) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/publisher/v2/activation-codes/${id}`, payload);
    return response.data.data;
  },
};

function extractFileNameFromHeaders(headers) {
  return headers['content-disposition']
    .split(';')
    .find((n) => n.includes('filename='))
    .replace('filename=', '')
    .trim()
    .slice(1, -1);
}

function generatePath(startPath, { page, limit, keyword, batch_id, lang }) {
  let path = startPath;
  if (page || limit || keyword || batch_id || lang) {
    path += '?';
  }

  const queryStrings = [];
  if (page) {
    queryStrings.push(['page', page]);
  }
  if (limit) {
    queryStrings.push(['limit', limit]);
  }
  if (keyword) {
    queryStrings.push(['keyword', keyword]);
  }
  if (batch_id) {
    queryStrings.push(['batch_id', batch_id]);
  }

  if (lang) {
    queryStrings.push(['lang', lang]);
  }

  const params = new URLSearchParams(queryStrings);
  return path + params.toString();
}

export default service;
