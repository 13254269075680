import Vue from 'vue';

const service = {
  async createProject(payload) {
    const response = await Vue.prototype.$axios.post(`${process.env.VUE_APP_BASE_API_URL}/publisher/v1/instant-card-projects`, payload);
    return response.data.data;
  },
  async getProject(id) {
    const response = await Vue.prototype.$axios.get(`${process.env.VUE_APP_BASE_API_URL}/publisher/v1/instant-card-projects/${id}`);
    return response.data.data;
  },
  async listProjects(page, limit, keyword, partner_id) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath('/publisher/v1/instant-card-projects', page, limit, keyword, partner_id)}`,
    );
    return response.data.data;
  },
  async createProjectNote(project_id, payload) {
    const response = await Vue.prototype.$axios.post(
      `${process.env.VUE_APP_BASE_API_URL}/publisher/v1/instant-card-projects/${project_id}/notes`,
      payload,
    );
    return response.data.data;
  },
  async deleteProjectNote(project_id, note_id, payload) {
    const response = await Vue.prototype.$axios.put(
      `${process.env.VUE_APP_BASE_API_URL}/publisher/v1/instant-card-projects/${project_id}/notes/${note_id}/delete`,
      payload,
    );
    return response.data.data;
  },
  async listProjectNotes(page, limit, project_id) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v1/instant-card-projects/${project_id}/notes`, page, limit)}`,
    );
    return response.data.data;
  },
  async searchPrograms(page, limit, keyword) {
    const params = {};
    if (keyword) {
      params.keyword = keyword;
    }
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath('/publisher/v1/instant-card-programs/search', page, limit, keyword)}`,
    );
    return response.data.data;
  },
  async updateProject(id, payload) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/publisher/v1/instant-card-projects/${id}`, payload);
    return response.data.data;
  },
  async updateInstantCardProjectOffer(id, payload) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/publisher/v1/instant-card-projects/offers/${id}`, payload);
    return response.data.data;
  },
  async searchOffers(id, page, limit, keyword) {
    const params = {};
    if (keyword) {
      params.keyword = keyword;
    }
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v1/instant-card-projects/${id}/offers/search`, page, limit, params.keyword)}`,
    );
    return response.data.data;
  },
  async listProjectGiftCertificates(id, page, limit) {
    const response = await Vue.prototype.$axios.get(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v1/instant-card-projects/${id}/offers`, page, limit)}`,
    );
    return response.data.data;
  },
  async activateProjectGiftCertificate(id) {
    const response = await Vue.prototype.$axios.put(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v1/instant-card-projects/offers/${id}/activate`)}`,
    );
    return response.data.data;
  },
  async deactivateProjectGiftCertificate(id) {
    const response = await Vue.prototype.$axios.put(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v1/instant-card-projects/offers/${id}/deactivate`)}`,
    );
    return response.data.data;
  },
  async removeProjectGiftCertificate(id) {
    const response = await Vue.prototype.$axios.delete(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v1/instant-card-projects/offers/${id}`)}`,
    );
    return response.data.data;
  },
  async addProjectGiftCertificate(id, payload) {
    const response = await Vue.prototype.$axios.post(
      `${process.env.VUE_APP_BASE_API_URL}${generatePath(`/publisher/v1/instant-card-projects/${id}/offers`)}`,
      payload,
    );
    return response.data.data;
  },
  async unlinkAllCardsFromInstantCardProject(id) {
    const response = await Vue.prototype.$axios.delete(`${process.env.VUE_APP_BASE_API_URL}/publisher/v1/instant-card-projects/${id}/cards/unlink`);
    return response.data.data;
  },
  async pauseProjectCards(id) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/publisher/v1/instant-card-projects/${id}/cards/pause`, {});
    return response.data.data;
  },
  async resumeProjectCards(id) {
    const response = await Vue.prototype.$axios.put(`${process.env.VUE_APP_BASE_API_URL}/publisher/v1/instant-card-projects/${id}/cards/resume`, {});
    return response.data.data;
  },
};

function generatePath(startPath, page, limit, keyword, partner_id) {
  let path = startPath;
  if (page || limit || keyword || partner_id) {
    path += '?';
  }

  const queryStrings = [];
  if (page) {
    queryStrings.push(['page', page]);
  }
  if (limit) {
    queryStrings.push(['limit', limit]);
  }
  if (keyword) {
    queryStrings.push(['keyword', keyword]);
  }
  if (partner_id) {
    queryStrings.push(['partner_id', partner_id]);
  }

  const params = new URLSearchParams(queryStrings);
  return path + params.toString();
}

export default service;
