<template>
  <card-modal
    icon=""
    :title="$t('instant_card_batches.cancel_action.title')"
    context="cancelBatch"
    @submit="submit"
    @cancel="cancel"
    cancel_text="instant_card_batches.cancel_action.button_cancel"
    submit_text="instant_card_batches.cancel_action.button_save"
  >
    <form @submit.prevent="submit" slot="content">
      <v-layout column>
        <label-text :label="`${$t('instant_card_batches.cancel_action.id')}`" :value="batch.id" />
      </v-layout>
    </form>
  </card-modal>
</template>

<script>
import { actionErrorTrackable } from '@/mixins';
import { mapGetters } from 'vuex';

export default {
  name: 'cancel-batch-action',
  mixins: [actionErrorTrackable],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    batch: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('ui', ['currentLocale']),
  },
  methods: {
    clear() {
      this.$validator.reset();
    },
    success(response) {
      this.clear();
      this.$emit('cancelled');
    },
    cancel() {
      this.clear();
      this.$emit('close');
    },
    async submit() {
      const validationComplete = await this.$validator.validateAll();
      if (validationComplete) {
        await this.executeAction(
          {
            action: 'instantCardBatch/cancelBatch',
            name: 'cancelBatch',
            success: this.success,
          },
          this.batch.id,
        );
      }
    },
  },
};
</script>
