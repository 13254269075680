var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card-modal',{attrs:{"icon":"fal fa-money-check-edit","title":_vm.$t('instant_card_projects.update_gift_certificate_action.title'),"context":"updateInstantCardProjectOffer","cancel_text":"instant_card_projects.update_gift_certificate_action.button_cancel","submit_text":"instant_card_projects.update_gift_certificate_action.button_save"},on:{"submit":_vm.submit,"cancel":_vm.cancel}},[_c('form',{attrs:{"slot":"content"},on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}},slot:"content"},[_c('v-layout',{attrs:{"column":""}},[_c('v-flex',{attrs:{"sm4":"","xs12":""}},[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('integer|min_value:0|max_value:1000'),expression:"'integer|min_value:0|max_value:1000'"}],attrs:{"label":_vm.$t('instant_card_projects.update_gift_certificate_action.payment_amount'),"name":"payment_amount","append-icon":"fas fa-dollar-sign","error-messages":_vm.errors.collect('payment_amount'),"data-vv-name":"payment_amount","data-vv-as":_vm.$t('instant_card_projects.update_gift_certificate_action.payment_amount'),"data-test":"update-project-offer--payment_amount"},model:{value:(_vm.currentProjectOffer.payment_amount),callback:function ($$v) {_vm.$set(_vm.currentProjectOffer, "payment_amount", $$v)},expression:"currentProjectOffer.payment_amount"}})],1),_c('v-flex',{attrs:{"sm4":"","xs12":""}},[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:({
            required: true,
            integer: true,
            min_value: 0,
            max_value: 1000,
            sumLessThanOrEqual: {
              field: _vm.currentProjectOffer.payment_amount,
              total: 1000,
              totalFieldLabel: _vm.$t('instant_card_projects.update_gift_certificate_action.total_amount'),
            },
          }),expression:"{\n            required: true,\n            integer: true,\n            min_value: 0,\n            max_value: 1000,\n            sumLessThanOrEqual: {\n              field: currentProjectOffer.payment_amount,\n              total: 1000,\n              totalFieldLabel: $t('instant_card_projects.update_gift_certificate_action.total_amount'),\n            },\n          }"}],attrs:{"label":_vm.$t('instant_card_projects.update_gift_certificate_action.bonus_amount') + '*',"name":"bonus_amount","append-icon":"fas fa-dollar-sign","error-messages":_vm.errors.collect('bonus_amount'),"data-vv-name":"bonus_amount","data-vv-as":_vm.$t('instant_card_projects.update_gift_certificate_action.bonus_amount'),"data-test":"update-project-offer--bonus_amount","required":""},model:{value:(_vm.currentProjectOffer.bonus_amount),callback:function ($$v) {_vm.$set(_vm.currentProjectOffer, "bonus_amount", $$v)},expression:"currentProjectOffer.bonus_amount"}})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }