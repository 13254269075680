<template>
  <view-main title="page_title.business_suite_employer_report">
    <v-flex slot="content" class="data-section">
      <h1 hidden>{{ $t('dashboards.business_suite_employer_report.title') }}</h1>
      <v-flex fill-height class="data-section">
        <dashboard id-name="employer_report" :params="params" :timerange="timerange" />
      </v-flex>
    </v-flex>
  </view-main>
</template>

<script>
import { renderErrorTrackable } from '@/mixins';
import Dashboard from './Dashboard.vue';

export default {
  name: 'business-suite-transactions-view',
  mixins: [renderErrorTrackable],
  components: { Dashboard },
  data() {
    return {
      timerange: null,
      activation_code_batch_id: null,
    };
  },
  created() {
    if (this.$route.query.timerange) {
      this.timerange = this.$route.query.timerange;
    }
    if (this.$route.query.activation_code_batch_id) {
      this.activation_code_batch_id = this.$route.query.activation_code_batch_id;
    }
  },
  computed: {
    params() {
      const theParams = {};

      if (this.activation_code_batch_id) {
        theParams.activation_code_batch_id = this.activation_code_batch_id;
      }

      return theParams;
    },
  },
};
</script>
